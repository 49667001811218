import * as xhr from "../xhr";
import * as models from "../../models/localisation";

export interface requestPayload {
    query?: string,
    offset?: number,
    activated?: boolean,
    companies?: number[],
}

export function select(requestData?: requestPayload){
    return xhr.post('localisation/list', requestData || {});
}

export function patch(requestData: models.Localisation, id: number){
    return xhr.post('localisation/edit/' + id, requestData);
}

export function put(requestData: models.Localisation){
    return xhr.post('localisation/create',  requestData);
}

export function get(id: string){
    return xhr.get('localisation/show/' + id);
}

export function order(requestData: {id: number, position: number}[]){
    return xhr.post('localisation/order',  requestData);
}

export function activate(id: number){
    return xhr.get(`localisation/${id}/activate`);
}
export function deactivate(id: number){
    return xhr.get(`localisation/${id}/deactivate`);
}