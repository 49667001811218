import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {BookingOffer, BookingOfferApplicant, BookingOffers} from "../../../../models/booking/offer";
import format from "../../../../utils/locale";
import {Granted} from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight"
import * as api from "../../../../adapters/booking/offer/applicant"
import * as offerApi from "../../../../adapters/booking/offer"
import {openSidebar} from "../../../../actions/rightSidebar";
import {
    FORM_EDIT_BOOKING_OFFER,
    FORM_NEW_BOOKING_OFFER,
    LIST_BOOKING_OFFER,
    LIST_BOOKING_OFFER_APPLICANTS
} from "../../../../constants/rightSidebar";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {cancelBookingOfferSuccess, editBookingOfferSuccess} from "../../../../actions/booking/offer";

const BookingOfferSidebarList:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const offers: BookingOffers = useSelector((state: RootStateOrAny) => state.bookingOffer.payload);
    const [status, setStatus] = useState<"all"|"pending"|"validated">(state.rightSidebar.data?.status || 'all');
    const dispatch = useDispatch();


    const _apply = (offer: BookingOffer) => {
        api.apply(offer.id).then((data) => dispatch(editBookingOfferSuccess(data.data)))
    }
    const _cancel = (offer: BookingOffer, applicant: BookingOfferApplicant) => {
        api.cancel(offer.id, applicant.id).then((data) => dispatch(editBookingOfferSuccess(data.data)))
    }
    const disable = (offer: BookingOffer) => {
        let container = document.getElementById("offer"+String(offer.id))!;
        container.classList.toggle('fadeOut')
        offerApi.disable(offer.id).then((data) => {
            dispatch(cancelBookingOfferSuccess(data.data))
            container.classList.toggle('fadeOut')
        })
    }

    return <div className={'p-1 p-md-3'}>
        <div className="d-flex mb-1 mb-md-3">
            <div className="flex-grow-0">
                <BootstrapSelect
                    notFloating={true}
                    options={[
                        {label: "Toutes les offres", value: "all"},
                        {label: "En attente", value: "pending"},
                        {label: "Validées", value: "validated"},
                    ]}
                    value={{label: (status === "validated" ? "Validées" : (status === "pending" ? "En attentes" : "Toutes les offres")), value: status}}
                    onChange={choice => {
                        switch (choice?.value){
                            case "all":
                                setStatus('all')
                                break;
                            case "pending":
                                setStatus('pending')
                                break;
                            case "validated":
                                setStatus('validated')
                                break;
                        }
                    }}
                    required={true}
                />
            </div>
            <div className="flex-grow-1">

            </div>
            <div className="flex-grow-0">
                <button className={'btn btn-outline-primary'} onClick={() => dispatch(openSidebar(FORM_NEW_BOOKING_OFFER, {next: LIST_BOOKING_OFFER}))}>
                    <i className={'bi bi-plus'}> </i> Ajouter
                </button>
            </div>
        </div>
        {offers.filter(offer => (status === "all") || (status === "pending" && !offer.applicants.find(a => a.validated)) || (status === "validated" && offer.applicants.find(a => a.validated))).map((offer, key) => <div key={key} id={`offer${offer.id}`} className="border border-1 shadow-sm mb-3 p-1 p-md-3">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <span>
                        {offer.applicants.find(a => a.validated) ? <span className={'text-success'}><i className={'bi bi-check2-circle'}> </i> Créneau attribué à {offer.applicants.find(a => a.validated)!.salary.title}</span> : <span className={'text-warning'}><i className={'bi bi-clock-history'}> </i> Créneau non attribué</span>}
                    </span>
                </div>
                <div className="flex-grow-0">
                    <div className="btn-group">
                        <button className={'btn btn-sm me-2'}>
                            <i className={'text-primary bi bi-pencil'}> </i>
                        </button>
                        <button className={'btn btn-sm'} onClick={(e) => disable(offer)}>
                            <i className={'text-danger bi bi-dash-circle'}> </i>
                        </button>
                    </div>
                </div>
            </div>
            <div className={'me-3 fw-bold'}>
                <i className={'bi bi-calendar text-primary'}> </i> {format(new Date(offer.beginAt), 'E dd MMMM uuuu HH:mm')} <i className="mx-2 bi bi-arrow-left-right"> </i> {format(new Date(offer.endAt), 'HH:mm')}
            </div>
            <div>
                <i className={'bi bi-shop text-primary'}> </i> {offer.company.title}
            </div>
            <div>
                <i className={'bi bi-people text-primary'}> </i> {offer.applicants.length} participants
            </div>
            <div>
                <i className={'bi bi-people text-primary'}> </i> {offer.salaries.length} salariés
            </div>
            <div className={'form-text'}>
                {offer.description}
            </div>
            <div className="d-flex">
                {Granted(accessRights.APPLY_BOOKING_OFFER) && <>
                    {!offer.applicants.find(a => a.validated) && <>
                        {offer.salaries.find(s => s.id === state.auth.user.currentSalary.id) && (!offer.applicants.find(a => a.salary.id === state.auth.user.currentSalary.id) ? <button onClick={() => _apply(offer)} className={'btn flex-grow-1 btn-outline-success me-2'}>
                            <i className={'bi bi-check'}> </i> Postuler
                        </button> : <button onClick={() => _cancel(offer, offer.applicants.find(a => a.salary.id === state.auth.user.currentSalary.id)!)} className={'btn flex-grow-1 btn-primary text-white me-2'}>
                            <i className={'bi bi-check2'}> </i> Déjà postulé
                        </button>)}
                    </>}
                </>}
                {Granted(accessRights.EDIT_BOOKING_OFFER) && <button
                    onClick={() => dispatch(openSidebar(LIST_BOOKING_OFFER_APPLICANTS, {
                        offer: offer,
                        next: LIST_BOOKING_OFFER
                    }))}
                    className={"btn flex-grow-1 btn-outline-primary"}>
                    {offer.applicants.find(a => a.validated) ? <><i className={'bi bi-pencil'}> </i> Changer de participant</> : <><i className={'bi bi-list-check'}> </i>  Séléctionner un participant</>}
                </button>}
            </div>
        </div>)}
    </div>
}

export default BookingOfferSidebarList;