import * as models from "../../../models/template/calendar"
import * as types from "../../../constants/template/calendar"
import {EventClickArg} from "@fullcalendar/react";
import React from "react";
import {Resource} from "../../../models/template";

const templateCalendar = (state: models.initialStateInterface = models.initialState, action:
    {type: string,
        events: any[],
        resources: any[],
        workingTimes: any[],
        groupBy: number,
        error: string,
        eventClickArg?: EventClickArg,
        displayEventPop: boolean,
        resource?: Resource
        [key: string] : any}
) => {
    switch (action.type) {
        case types.FETCH_TEMPLATE_CALENDAR:
            state.loading = true
            state.events = []
            state.resources = []
            state.workingTimes = []
            return {...state}
        case types.FETCH_TEMPLATE_CALENDAR_SUCCESS:
            state.events = action.events
            state.resources = action.resources
            state.workingTimes = action.workingTimes
            state.loading = false
            return {...state}
        case types.FETCH_TEMPLATE_CALENDAR_FAILURE:
            state.events = []
            state.resources = []
            state.workingTimes = []
            state.loading = false
            return {...state}
        case types.SHOW_TEMPLATE_EVENT_POP:
            state.eventClickArg = action.eventClickArg
            state.displayEventPop = true;
            return {...state};
        case types.HIDE_TEMPLATE_EVENT_POP:
            state.eventClickArg = undefined
            state.displayEventPop = false;
            return {...state};
        case types.SET_TEMPLATE_GROUP_BY:
            state.groupBy = action.groupBy;
            state.refresh += 1;
            return {...state};
        case types.REFRESH_TEMPLATE:
            state.refresh += 1;
            return {...state};
        case types.EDIT_RESOURCE_SUCCESS:
            let index = state.resources.findIndex((e) => e.resource.id === action.resource?.id);
            if (index !== -1){
                state.resources[index].resource = action.resource;
                state.resources[index].title = action.resource?.title;
            }
            return {...state};
        default:
            return state;
    }
}

export default templateCalendar;