import React, {useState} from "react";
import {TimeClockGenerated} from "../../../../models/timeClock";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import format from "../../../../utils/locale";
import BookingTable from "../../../booking/table";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {
    FORM_EDIT_BOOKING,
    TIME_CLOCK_BOOKING_ADD,
    TIME_CLOCK_BOOKING_EDIT,
    TIME_CLOCK_SHOW
} from "../../../../constants/rightSidebar";
import * as api from "../../../../adapters/timeClock"
import * as accessRights from "../../../../constants/accessRight"
import {editTimeClockGeneratedSuccess, removeTimeClockGeneratedSuccess} from "../../../../actions/timeClock";
import {getAccountingTypeChoice} from "../../../../constants/booking";
import IsGranted from "../../../../security/isGranted";

const TimeCLockBookingShow:React.FC = () => {

    let _timeClockBooking: TimeClockGenerated = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const [timeClockBooking, setTimeClockBooking] = useState<TimeClockGenerated>(_timeClockBooking)
    const [anomalyDisabled, setAnomalyDisabled] = useState(false);
    const dispatch = useDispatch();

    return <div className={"p-1 p-md-3"}>
        {['timeClockBookingException', 'timeClockBooking', 'timeClockBookingMissing'].includes(timeClockBooking.eventType) && IsGranted(accessRights.EDIT_TIME_CLOCK) && <>
            <div className="btn-group mb-3">
                {<button className="btn btn-outline-primary me-2" onClick={() => {
                    switch (timeClockBooking.eventType) {
                        case "timeClockBookingException":
                        case "timeClockBooking":
                            dispatch(openSidebar(TIME_CLOCK_BOOKING_EDIT, {
                                beginAt: timeClockBooking.start ? new Date(timeClockBooking.start) : undefined,
                                endAt: timeClockBooking.end ? new Date(timeClockBooking.end) : undefined,
                                salary: timeClockBooking.salary,
                                company: timeClockBooking.company,
                                id: timeClockBooking.booking.id,
                                key: timeClockBooking.id,
                                next: TIME_CLOCK_SHOW,
                                data: {...timeClockBooking}
                            }))
                            break;
                        case "timeClockBookingMissing":
                            dispatch(openSidebar(TIME_CLOCK_BOOKING_ADD, {
                                beginAt: timeClockBooking.planned!.start,
                                endAt: timeClockBooking.planned!.end,
                                salary: timeClockBooking.salary,
                                company: timeClockBooking.company,
                                id: timeClockBooking.id,
                                key: timeClockBooking.id,
                                next: TIME_CLOCK_SHOW,
                                data: {...timeClockBooking}
                            }))
                            break;
                    }
                }}>
                    {['timeClockBookingException', 'timeClockBooking'].includes(timeClockBooking.eventType) && <><i
                        className={'bi bi-pencil'}> </i> Modifier</>}
                    {['timeClockBookingMissing'].includes(timeClockBooking.eventType) && <><i
                        className={'bi bi-plus'}> </i> Badger l'absence</>}
                </button>}
                {timeClockBooking.booking && <button className="btn btn-outline-danger me-2" onClick={() => {
                    dispatch(submitSidebar())
                    api.remove(timeClockBooking.booking.id!).then(data => {
                        dispatch(removeTimeClockGeneratedSuccess(timeClockBooking.id))
                        dispatch(closeSidebar());
                    })
                }}>
                    <i className={'bi bi-x-circle'}> </i> Suprimer
                </button>}
            </div>
            {timeClockBooking.isAnomaly && <>
                <div className="d-flex mb-3 align-items-center">
                    {((!timeClockBooking.start || timeClockBooking.ignoreStart) || !timeClockBooking.end) ?
                        <div className="alert alert-info flex-grow-0 me-2">
                            Veuillez renseigner l'horaire manquant pour pouvoir retirer l'anomalie
                        </div> :
                        (timeClockBooking.isException && <div className="flex-grow-0">
                            <button disabled={anomalyDisabled} className={'btn btn-outline-success'} onClick={() => {
                                setAnomalyDisabled(true)
                                api.removeAnomaly(timeClockBooking.exception!.id!).then(data => {
                                    dispatch(editTimeClockGeneratedSuccess(timeClockBooking.id, data.data))
                                    setTimeClockBooking(data.data);
                                    setAnomalyDisabled(false)
                                })
                            }}>
                                {anomalyDisabled ? <>
                                        <div className="spinner-border spinner-border-sm text-success" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </> :
                                    <><i className={'bi bi-check-all'}> </i> Retirer l'anomalie </>}
                            </button>
                        </div>)}
                </div>
            </>}
        </>}
        {/* TODO : AJOUTER LA MODIFICATION DU PLANIFIÉ */}
        {/*{timeClockBooking.planned && <>*/}
        {/*    <div className="clearfix"> </div>*/}
        {/*    <button className={'btn btn-outline-primary'} onClick={() => {*/}
        {/*        console.log(timeClockBooking.planned)*/}
        {/*        // dispatch(openSidebar(FORM_EDIT_BOOKING, {...timeClockBooking.planned}))*/}
        {/*    }}>*/}
        {/*        <i className={'bi bi-edit'}></i> Modifier le créneau planifié*/}
        {/*    </button>*/}
        {/*</>}*/}
        {timeClockBooking.eventType === "timeClockBookingException" && timeClockBooking.editedAt && timeClockBooking.editedBy && <>
            <div className="clerafix"> </div>
            <p>
                <span className={'text-primary'}>Modifié par :</span> {timeClockBooking.editedBy.title}
                <span className="clearfix"> </span>
                <span className={'form-text'}>Le {format(new Date(timeClockBooking.editedAt))} à {format(new Date(timeClockBooking.editedAt), "HH:mm")}</span>
            </p>
        </>}
        <table className={'table mb-3'}>
            <tbody>
                <tr>
                    <td className={'text-primary'}>
                        <i className={"bi bi-clock"}> </i> Début
                    </td>
                    <td>
                        {timeClockBooking.ignoreStart ? <i className={'bi bi-x'}> </i> : (timeClockBooking.start ? format(new Date(timeClockBooking.start), 'E dd MMMM uuuu HH:mm') : <i className={'bi bi-x'}> </i>)}
                    </td>
                </tr>
                {timeClockBooking.eventType === "timeClockBookingException" && <tr className={'form-text'}>
                    <td>
                        Avant modification
                    </td>
                    <td>
                        {timeClockBooking.booking.beginAt ? format(new Date(timeClockBooking.booking.beginAt), 'E dd MMMM uuuu HH:mm') : <i className={"bi bi-x"}></i>}
                    </td>
                </tr>}
                <tr>
                    <td className={'text-primary'}>
                        <i className={"bi bi-clock"}> </i> Fin
                    </td>
                    <td>
                        {timeClockBooking.end ? format(new Date(timeClockBooking.end), 'E dd MMMM uuuu HH:mm') : <i className={'bi bi-x'}> </i>}
                    </td>
                </tr>
                {timeClockBooking.eventType === "timeClockBookingException" && <tr className={'form-text'}>
                    <td>
                        Avant modification
                    </td>
                    <td>
                        {timeClockBooking.booking.endAt ? format(new Date(timeClockBooking.booking.endAt), 'E dd MMMM uuuu HH:mm') : <i className={"bi bi-x"}> </i>}
                    </td>
                </tr>}
                <tr>
                    <td className={'text-primary'}>
                        <i className={"bi bi-shop"}> </i> Point de vente
                    </td>
                    <td>
                        {timeClockBooking.company.title}
                    </td>
                </tr>
                {timeClockBooking.accountingType && timeClockBooking.eventType === "timeClockBookingMissing" && <tr>
                    <td className={'text-primary'}>
                       <i className={'bi bi-filter'}> </i> Type d'heure
                    </td>
                    <td>
                        {getAccountingTypeChoice(timeClockBooking.accountingType)?.label}
                    </td>
                </tr>}
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-card-text'}> </i> Description
                    </td>
                    <td className={'text-light-s'}>
                        {timeClockBooking.description}
                    </td>
                </tr>
            </tbody>
        </table>
        <BookingTable salary={timeClockBooking.salary.id} start={timeClockBooking.start ? new Date(timeClockBooking.start) : (timeClockBooking.end ? new Date(timeClockBooking.end) : undefined)} end={timeClockBooking.end ? new Date(timeClockBooking.end) : (timeClockBooking.start ? new Date(timeClockBooking.start) : undefined)} />
    </div>
}

export default TimeCLockBookingShow;