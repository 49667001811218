import React, {forwardRef, useEffect, useState} from "react";
import FullCalendar, {
    CalendarApi,
    DateSelectArg,
    EventClickArg,
    EventDropArg, SlotLabelContentArg, ViewMountArg,
    VUIEvent
} from "@fullcalendar/react";
import {Link} from "react-router-dom";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, {EventResizeDoneArg} from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import * as action from "../../actions/calendar";
import * as api from "../../adapters/calendar";
import * as teamApi from "../../adapters/team";
import * as taskCalendarActions from "../../actions/task/calendar";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {
    BOOKING_HISTORY,
    FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION,
    FORM_CALENDAR_DOWNLOAD,
    FORM_CALENDAR_RESOURCE_ORDER,
    FORM_CALENDAR_SETTINGS,
    FORM_EDIT_ACTIVITY,
    FORM_EDIT_BOOKING,
    FORM_EDIT_JOB,
    FORM_EDIT_LOCALISATION,
    FORM_KEEP_NOTE_ADD,
    FORM_KEEP_NOTE_LIST,
    FORM_NEW_ABSENCE,
    FORM_NEW_BOOKING,
    FORM_NEW_BOOKING_OFFER,
    FORM_NEW_LOCALISATION,
    FORM_NEW_SALARY,
    FORM_NEW_TASK,
    FORM_NEW_TEMPLATE_FROM_CALENDAR,
    LIST_TEMPLATES_APPLY,
    NEED_LIST,
    SHOW_BOOKING_OFFER,
    TEMPLATES_APPLY_EDIT,
    FORM_EVENT_ADD,
    FORM_EVENT_EXCEPTION_EDIT,
    FORM_EVENT_EXCEPTION_ADD,
    SHOW_EVENT_OFFER,
    FORM_NEW_EVENT_OFFER
} from "../../constants/rightSidebar";
import {openSidebar} from "../../actions/rightSidebar";
import format from "../../utils/locale/index";
import DatePicker from "react-datepicker";
import {setPageTitle, setHelpProductSheetIds} from "../../actions/header";
import {
    fetchAllEventsSuccess,
    fetchResourcesSuccess,
    fetchTitleSuccess,
    refresh, refreshResourceSuccess, setCompanies,
    showEventPop, showEventPopup,
    showResourceActions, showTemplateEventPop
} from "../../actions/calendar";
import {NeedEvent, PresenceEvent, ResourceLabelContentArg, WorkingTime} from "../../models/calendar";
import EventPop from "../../utils/eventPop";
import ResourceActions from "../../utils/resourceActions";
import TaskEventPop from "../../utils/taskEventPop";
import Loader from "../../utils/loader";
import {Tooltip} from "bootstrap";
import {TemplateApplied, TemplateApplies} from "../../models/template";
import {fetchTemplatesAppliesSuccess} from "../../actions/template/applied";
import {renderEvent, resourceLaneContent} from "../../utils/calendar";
import {fetchKeepNotesSuccess} from "../../actions/keepNote";
import {KeepNote} from "../../models/keepNote";
import {Granted, GrantedAny, GrantedMany} from "../../security/granted";
import * as accessRights from "../../constants/accessRight";
import {axiosError} from "../../actions/axios";
import HasModule from "../../security/hasModule";
import {Companies, Company} from "../../models/companies";
import {LIST_TIME_CLOCK} from "../../constants/accessRight";
import {Team, Teams} from "../../models/team";
import EventAppliedPop from "../../utils/eventAppliedPop";
import rrulePlugin from "@fullcalendar/rrule";
import {TYPE_EVENT, TYPE_EVENT_EXCEPTION} from "../../constants/event";
import EventPopup from "../../utils/event/popup";
import getVersion from "../../security/getVersion";

const CalendarShow :React.FC =  () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state)

    const defaultView = useSelector((state: RootStateOrAny) => state.auth.user.calendarSettings.defaultView)
    const isPresence = useSelector((state: RootStateOrAny) => state.auth.user.calendarSettings.isPresence)
    const defaultGroupBy = useSelector((state: RootStateOrAny) => state.auth.user.calendarSettings.defaultGroupBy)
    const timePrecision = useSelector((state: RootStateOrAny) => state.auth.user.calendarSettings.timePrecision)
    const timezone = useSelector((state: RootStateOrAny) => state.auth.user.calendarSettings.timezone)
    const daysOfWeek = useSelector((state: RootStateOrAny) => state.auth.user.calendarSettings.daysOfWeek)

    const isRefresh = useSelector((state: RootStateOrAny) => state.calendar.refresh)
    const needEvents = useSelector((state: RootStateOrAny) => state.calendar.needEvents)
    const events = useSelector((state: RootStateOrAny) => state.calendar.events)
    const workingTimes = useSelector((state: RootStateOrAny) => state.calendar.workingTimes)
    const resources = useSelector((state: RootStateOrAny) => state.calendar.resources)
    const companies: Companies = useSelector((state: RootStateOrAny) => state.calendar.companies)
    const view = useSelector((state: RootStateOrAny) => state.calendar.view)
    const tasks = useSelector((state: RootStateOrAny) => state.calendar.tasks)
    const bookingOffers = useSelector((state: RootStateOrAny) => state.calendar.bookingOffers)
    const presenceEvents = useSelector((state: RootStateOrAny) => state.calendar.presenceEvents)
    const refreshResource = useSelector((state: RootStateOrAny) => state.calendar.refreshResource)
    const resourceSalary = useSelector((state: RootStateOrAny) => state.calendar.salary)
    const title = useSelector((state: RootStateOrAny) => state.calendar.title)
    const calendarRef = React.createRef<FullCalendar>();
    const [timeRange, setTimeRange] = useState({
        minTime: '09:00:00',
        maxTime: '21:00:00',
    });

    const [isOuter, setIsOuter] = useState(true);
    const [isAbsence, setIsAbsence] = useState(true);
    const [isAbsent, setIsAbsent] = useState(true);
    const [isBookingOffer, setIsBookingOffer] = useState(true);
    const [Wn, setWn] = useState<string>(format(new Date(), 'I'));
    const [viewType, setViewType] = useState('list');
    const [labourDays, setLabourDays] = useState<{date: string; title: string}[]>([])
    const [groupBy, setGroupBy] = useState(state.auth.user.calendarSettings.defaultGroupBy)

    const [pickerDate, setPickerDate] = useState(new Date());

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const templates:TemplateApplies = useSelector((state: RootStateOrAny) => state.templateApplied.payload)

    const [isSm, setIsSm] = useState(window.innerWidth < 768);
    const [nightView, setNightView] = useState(false);
    const userCompanies:Companies = state.auth.user.currentSalary.companies;
    const [selectingCompanies, setSelectingCompanies] = useState<Companies>([state.auth.user.currentSalary.companies[0]])
    const [teams, setTeams] = useState<Teams>([])
    const [job, setJob] = useState<0|1|2>(0)
    const [selectedTeams, setSelectedTeams] = useState<Teams>([])
    const [companyQuery, setCompanyQuery] = useState('')
    const [teamQuery, setTeamQuery] = useState('')

    const onValidateBsDropdown = () => {
        if (companies.filter((selected: Company) => !!selectingCompanies.find(selecting => selecting.id === selected.id)).length !== selectingCompanies.length){
            dispatch(setCompanies([...selectingCompanies]))
        }
    }

    useEffect(() => {
        let controller = new AbortController();
        if (Granted(accessRights.LIST_TEAM) && HasModule('team')){
            teamApi.list({}, controller.signal).then(data => setTeams(data.data))
        }
        return () => controller.abort();
    }, [])

    useEffect(() => {

        let _companies = !!companies.length ? [...companies] : [userCompanies[0]]

        if (!!_companies.length){
            let min:Date = _companies.map((company: Company) => new Date(company.timesheet.openAt)).sort((a: Date, b: Date) => a.getTime() - b.getTime())[0];
            let max:Date = _companies.map((company: Company) => new Date(company.timesheet.closeAt)).sort((a: Date, b: Date) => b.getTime() - a.getTime())[0];

            if (min < max){
                if (nightView){
                    let max_hours = pad(24 + min.getHours(), 2);
                    let max_minutes = pad(min.getMinutes(), 2);

                    let min_hours = pad(max.getHours(), 2);
                    let min_minutes = pad(max.getMinutes(), 2);

                    setTimeRange({
                        minTime: `${min_hours}:${min_minutes}`,
                        maxTime: `${max_hours}:${max_minutes}`,
                    });

                }else{
                    setTimeRange(
                        {
                            minTime: format(min, 'HH:mm'),
                            maxTime: format(max, 'HH:mm')
                        }
                    );
                }
            }
        }
    }, [companies, nightView, timezone])

    const pad = (num: number, size: number) => {
        let ret = num.toString();
        while (ret.length < size) ret = "0" + ret;
        return ret;
    }

    useEffect(() => {
        renderDaySeparator();
    }, [timeRange])

    useEffect(() => {
        window.addEventListener("resize", handleResize)

        let API = calendarRef.current?.getApi();

        if (API){
            if (isSm){
                calendarApiNavActions(viewType === 'list' ? 'listDay' : 'resourceTimelineDay')
            }else{
                calendarApiNavActions(view || defaultView)
            }
        }

        return () => window.removeEventListener("resize", handleResize)
    }, [isSm, viewType])

    const handleResize = () => {
        if (window.innerWidth >= 768 && isSm){
            setIsSm(false);
        }else if(window.innerWidth < 768 && !isSm){
            setIsSm(true);
        }
    }

    // ONLOAD
    useEffect(() => {
        dispatch(setPageTitle('Planning'))
        dispatch(setHelpProductSheetIds([3, 8, 9, 11, 12, 14]))

        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })

        return () => {
            tooltipTriggerList.map(t => Tooltip.getInstance(t).hide())
            dispatch(setHelpProductSheetIds([]))
        }
    }, [])

    useEffect(() => {
        let controller = new AbortController();
        let API = calendarRef.current?.getApi();
        if (API){
            refreshCalendar(controller.signal);
        }

        return () => {
            controller.abort()
        };
    }, [
        isRefresh,
        job,
        groupBy
    ])

    useEffect(() => {
        let controller = new AbortController();
        let API = calendarRef.current?.getApi();
        if (API && refreshResource)
        {
            api.fetchCalendar({
                start: format(API.view.currentStart, 'uuuu-MM-dd'),
                end: format(API.view.currentEnd, 'uuuu-MM-dd'),
                options: {
                    companies: companies.length ? companies.map((c: Company) =>  c.id!) : [],
                    view: API.view.type,
                    workingTime: true,
                    presence: isPresence,
                    resources: true,
                    sortBy: groupBy || defaultGroupBy,
                    salaries: [resourceSalary.id]
                }
            }).then((data) => {
                dispatch(refreshResourceSuccess(data.data.events, data.data.presenceEvents, data.data.workingTimes))
            })
        }

        return () => controller.abort();
    }, [refreshResource])

    const ViewDidMount = (e: ViewMountArg) => {
        renderDaySeparator();
        e.view.calendar.updateSize();
    }

    const renderDaySeparator = () => {
        const leadingZero = (num: number) => `0${num}`.slice(-2);
        const formatTime = (date: Date) =>
            [date.getHours(), date.getMinutes(), date.getSeconds()]
                .map(leadingZero)
                .join(':');

        let lanes = Array.from(document.querySelectorAll('td.fc-timeline-slot-lane') as NodeListOf<HTMLElement>);
        let dates = lanes.filter((lane) => !!lane.dataset?.date).map((lane) => new Date(lane.dataset.date!).getTime())
        const maxDate = new Date(Math.max.apply(null, dates));
        const maxTime = formatTime(maxDate)
        for (let i in lanes){
            if(typeof lanes[i] === 'object'){
                if (lanes[i].hasAttribute('data-date')){
                    if(lanes[i].getAttribute('data-date')?.split('T')[1] === maxTime){
                        lanes[i].style.cssText = 'border-right: solid #4723D9 2px !important;'
                    }else{
                        lanes[i].style.cssText = ''
                    }
                }
            }
        }
    }

    useEffect(() => {
        let API = calendarRef.current?.getApi();
        if (API && API.getOption("filterResourcesWithEvents") !== !isAbsent){
            API.setOption("filterResourcesWithEvents", !isAbsent)
        }
    }, [isAbsent])

    const eventClick = (e: EventClickArg) => {
        e.jsEvent.stopPropagation();

        if(!Granted(accessRights.EDIT_BOOKING)){
            return false;
        }

        if (['booking', 'bookingException'].includes(e.event.extendedProps.eventType)){
            dispatch(showEventPop(e, e.event.extendedProps.longResourceId, e.event.extendedProps.resourceParentId))
        }

        if ([TYPE_EVENT, TYPE_EVENT_EXCEPTION].includes(e.event.extendedProps.eventType)){
            dispatch(showEventPopup(e, e.event.extendedProps.longResourceId, e.event.extendedProps.resourceParentId))
        }

        if (['templateBookingApplied', 'templateBookingAppliedException'].includes(e.event.extendedProps.eventType)){
            dispatch(showTemplateEventPop(e, e.event.extendedProps.longResourceId, e.event.extendedProps.resourceParentId))
        }

        if (e.event.extendedProps.eventType === 'task'){
            dispatch(taskCalendarActions.showEventPop(e))
        }

        if (e.event.extendedProps.eventType === 'bookingOffer'){
            dispatch(openSidebar(SHOW_BOOKING_OFFER, {
                offer: e.event.extendedProps.offer
            }))
        }

        if (e.event.extendedProps.eventType === 'eventOffer'){
            dispatch(openSidebar(SHOW_EVENT_OFFER, {
                offer: e.event.extendedProps.offer
            }))
        }
    }

    const calendarApiNavActions = (e: string) => {

        let API = calendarRef.current?.getApi()
        if (API){
            switch (e) {
                case 'prev':
                    API.prev();
                    break;
                case 'next':
                    API.next();
                    break;
                case 'listDay':
                case 'resourceTimelineMonth':
                case 'resourceTimelineDay':
                case 'resourceTimelineWeek':
                    API.changeView(e)
                    dispatch(action.setView(e));
                    break;
            }

            dispatch(fetchTitleSuccess(API.view.title, API.view.currentStart, API.view.currentEnd))
            dispatch(refresh())
        }
    }

    // FETCH FUNCTIONS
    const refreshCalendar = (signal: AbortSignal) => {
        let API = calendarRef.current?.getApi()
        if (API){
            dispatch(action.fetchTitleSuccess(API.view.title, API.view.currentStart, API.view.currentEnd));
            setWn(format(API.getDate(), 'I'));
           return Promise.all([
               fetchCalendar(API, signal),
            ]).then(() => renderDaySeparator())
        }else {
            return false;
        }
    }

    const fetchCalendar = (API: CalendarApi, signal: AbortSignal) => {
        dispatch(action.fetchEvents())
        setIsLoading(true)
        return api.fetchCalendar({
            start: format(API.view.currentStart,'uuuu-MM-dd'),
            end: format(API.view.currentEnd,'uuuu-MM-dd'),
            options: {
                companies: companies.map((c: Company) => c.id!),
                sortBy: groupBy || defaultGroupBy,
                presence: isPresence,
                view: (API.view.type || defaultView),
                workingTime: true,
                resources: true,
                isBookingOffer: isBookingOffer,
                job: job,
                teams:  selectedTeams.map(t => t.id),
            }}, signal).then(data => {
            setIsLoading(false);
            setLabourDays(data.data.labourDays);
            dispatch(fetchTemplatesAppliesSuccess(data.data.templates))
            dispatch(fetchKeepNotesSuccess(data.data.keepNotes))
            dispatch(fetchResourcesSuccess(data.data.resources))
            dispatch(fetchAllEventsSuccess(data.data.events, data.data.presenceEvents, data.data.needEvents, data.data.workingTimes, data.data.tasks, data.data.bookingOffers))
            setTimeout(() => API.updateSize(), 500)
            }).then(() => {
                return true
            })
            .catch(error => dispatch(axiosError(error)))
    }

    const renderResource = (arg: ResourceLabelContentArg) => {
        if (arg.resource.extendedProps.hierarchy !== 'child'){
            switch (arg.resource.extendedProps.resourceType)
            {
                case "task":
                    return <>
                        <i className={'bi bi-journal-check ms-3'}> </i> Tâches
                    </>
                case "activity":
                case "localisation":
                case "job":
                    return <span className={'align-items-center col'}>
                    <span className={'col'}>
                        <b style={{backgroundColor: arg.resource.extendedProps.backgroundColor}} className={'p-1'}>{arg.resource.title}</b>
                    </span>
                    <span className={'col-auto'}>
                         <div className="color-circle col-auto"
                              onClick={() => {

                                  if (arg.resource.extendedProps.preventDefault){
                                      return false;
                                  }

                                  if(Granted(accessRights.EDIT_BOOKING)){
                                      switch (groupBy) {
                                          case 1:
                                              dispatch(openSidebar(FORM_EDIT_JOB, arg.resource.extendedProps.job))
                                              break;
                                          case 2:
                                              let _localisation = arg.resource.extendedProps.localisation;
                                              _localisation.company = arg.resource.extendedProps.company
                                              dispatch(openSidebar(FORM_EDIT_LOCALISATION, _localisation))
                                              break;
                                          case 3:
                                              let _activity = arg.resource.extendedProps.activity;
                                              _activity.company = arg.resource.extendedProps.company
                                              dispatch(openSidebar(FORM_EDIT_ACTIVITY, _activity))
                                              break;
                                      }
                                  }
                              }}
                         >
                                <div className="text-center text-white shadow" style={{backgroundColor: `${arg.resource.extendedProps.color}`, opacity: 0.8}}>
                                    {arg.resource.extendedProps.icon && <i className={arg.resource.extendedProps.icon + " align-middle"}> </i>}
                                </div>
                         </div>
                    </span>
            </span>
                case "unclassified":
                    return <>Créneaux non classé</>
                default:
                    return <>{arg.resource.title}</>
            }
        }

        if (!arg.resource.extendedProps.grouped){
            let workingTime = workingTimes?.find((workingTime: WorkingTime) => workingTime.salary.id === arg.resource.extendedProps.salary.id);

            return  <span className={'d-inline-block w-100'}>
                <div className={'d-flex px-2 align-items-center'}>
                    <span className="flex-grow-1">
                        <b>
                            {Granted(accessRights.LIST_SALARY) ? <Link to={"/salary/" + arg.resource.extendedProps.salary.id}>{arg.resource.title}</Link> : <>{arg.resource.title}</>}
                        </b>
                        <span className="clearfix"> </span>
                        {Granted(accessRights.EDIT_BOOKING) && <span className="col-12 placeholder wave">
                        <span>
                            <span className={'me-2'}>
                                 <i className={'bi bi-clock'}> </i>
                                {workingTime?.time || (isLoading ? <div className={'d-inline-block line'}> </div> : '00:00')}
                            </span>
                            {arg.view.type === 'resourceTimelineWeek' && (!!workingTime?.diffTimestamp ?
                            <span className={workingTime.diffTimestamp < 0 ? 'text-danger' : 'text-success'}>
                                {workingTime.diffTimestamp > 0 ? <i className={'bi bi-plus-circle'}> </i> :
                                    <i className={'bi bi-exclamation-circle'}> </i>} {workingTime.diffInterval}
                            </span> : <i className={"bi bi-check text-success"}> </i>)}
                        </span>
                    </span>}
                    </span>
                    {Granted(accessRights.EDIT_BOOKING) && arg.resource.extendedProps.showSalaryDropdown && <span className="flex-grow-0">
                            <button type="button" onClick={(e) => {
                                e.stopPropagation();
                                dispatch(showResourceActions(arg, e))
                            }} className="btn btn-sm btn-light">
                            <i className={'bi bi-plus-circle text-primary'}> </i>
                        </button>
                    </span>}
                </div>
            </span>
        }

        return <></>
    }

    const renderResourceGroup = (arg: any) => {
        let array = arg.groupValue.split(':')
        return <b className="text-primary text-uppercase">Sem.{array[array.length - 1]} {array[array.length - 3]} </b>
    }

    const eventResize = (e: EventResizeDoneArg) => {
        dispatch(action.editEvent(e));
        if (Granted(accessRights.EDIT_BOOKING)){
            switch (e.event.extendedProps.eventType){
                case TYPE_EVENT:
                    dispatch(openSidebar(FORM_EVENT_EXCEPTION_ADD, {
                        eventId: e.event.extendedProps.eventId,
                        salary: e.event.extendedProps.salary,
                        start: e.event.start,
                        initialStart: e.oldEvent.start,
                        end: e.event.end,
                        company: e.event.extendedProps.company,
                        localisation: e.event.extendedProps.localisation,
                        activity: e.event.extendedProps.activity,
                        accountingType: e.event.extendedProps.accountingType,
                        resourceId: e.event.extendedProps.longResourceId,
                        parentId: e.event.extendedProps.resourceParentId,
                    }))
                    break;
                case TYPE_EVENT_EXCEPTION:
                    dispatch(openSidebar(FORM_EVENT_EXCEPTION_EDIT, {
                        eventId: e.event.extendedProps.eventId,
                        exceptionId: e.event.extendedProps.exceptionId,
                        salary: e.event.extendedProps.salary,
                        start: e.event.start,
                        initialStart: e.oldEvent.start,
                        end: e.event.end,
                        daysOfWeek: e.event.extendedProps.weekDays,
                        company: e.event.extendedProps.company,
                        localisation: e.event.extendedProps.localisation,
                        activity: e.event.extendedProps.activity,
                        resourceId: e.event.extendedProps.longResourceId,
                        parentId: e.event.extendedProps.resourceParentId
                    }))
                    break;
                case 'templateBookingAppliedException':
                case 'templateBookingApplied':
                    dispatch(openSidebar(FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION, {
                        start: e.event.start,
                        end: e.event.end,
                        accountingType: e.event.extendedProps.accountingType,
                        company: e.event.extendedProps.company,
                        activity: e.event.extendedProps.activity,
                        localisation: e.event.extendedProps.localisation,
                        salary: e.event.extendedProps.salary,
                        instanceToken: e.event.extendedProps.instanceToken,
                        instanceId: e.event.extendedProps.instanceId,
                        isAppliedException: e.event.extendedProps.isAppliedException,
                        bookingInstanceId: e.event.extendedProps.bookingInstanceId,
                        exceptionInstanceId: e.event.extendedProps.exceptionInstanceId,
                        description: e.event.extendedProps.description,
                        templateApplied: e.event.extendedProps.templateApplied,
                    }))
                    break;
                default:
                    dispatch(openSidebar(FORM_EDIT_BOOKING, {
                        start: e.event.start,
                        end: e.event.end,
                        company: e.event.extendedProps.company,
                        activity: e.event.extendedProps.activity,
                        localisation: e.event.extendedProps.localisation,
                        accountingType: e.event.extendedProps.accountingType,
                        salary: e.event.extendedProps.salary,
                        instanceToken: e.event.extendedProps.instanceToken,
                        instanceId: e.event.extendedProps.instanceId,
                        isException: e.event.extendedProps.isException,
                        exceptionInstanceId: e.event.extendedProps.exceptionInstanceId,
                        bookingInstanceId: e.event.extendedProps.bookingInstanceId,
                        bookingGroupId: e.event.extendedProps.bookingGroupId,
                        bookingGroupTitle: e.event.extendedProps.bookingGroupTitle,
                        description: e.event.extendedProps.description,
                        recurrence: e.event.extendedProps.recurrence,
                        resourceId: e.event.extendedProps.longResourceId,
                        parentId: e.event.extendedProps.resourceParentId
                    }))
                    break;
            }
        }
    }

    const eventDrop = (e: EventDropArg) => {
        if (Granted(accessRights.EDIT_BOOKING)) {

            let target =  e.newResource || e.event.getResources()[0];

            switch (e.event.extendedProps.eventType){
                case TYPE_EVENT:
                case TYPE_EVENT_EXCEPTION:
                    dispatch(action.editEvent(e))
                    dispatch(openSidebar(FORM_EVENT_ADD, {
                        salary: target?.extendedProps.salary,
                        start: e.event.start,
                        end: e.event.end,
                        daysOfWeek: e.event.extendedProps.weekDays,
                        company: e.event.extendedProps.company,
                        activity: e.event.extendedProps.activity,
                        localisation: e.event.extendedProps.localisation,
                        accountingType: e.event.extendedProps.accountingType,
                        resourceId: target?.id,
                        parentId: target?._resource.parentId
                    }))
                    break;
                case 'templateBookingAppliedException':
                case 'templateBookingApplied':
                    dispatch(action.editEvent(e))
                    dispatch(openSidebar(FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION, {
                        start: e.event.start,
                        end: e.event.end,
                        accountingType: e.event.extendedProps.accountingType,
                        company: e.event.extendedProps.company,
                        activity: e.event.extendedProps.activity,
                        localisation: e.event.extendedProps.localisation,
                        salary: e.event.extendedProps.salary,
                        instanceToken: e.event.extendedProps.instanceToken,
                        instanceId: e.event.extendedProps.instanceId,
                        isAppliedException: e.event.extendedProps.isAppliedException,
                        bookingInstanceId: e.event.extendedProps.bookingInstanceId,
                        exceptionInstanceId: e.event.extendedProps.exceptionInstanceId,
                        description: e.event.extendedProps.description,
                        templateApplied: e.event.extendedProps.templateApplied,
                        resourceId: e.event.getResources()[0].id,
                        parentId: e.event.getResources()[0]._resource.parentId
                    }))
                    break;
                case 'booking':
                case 'bookingException':
                    dispatch(openSidebar(FORM_NEW_BOOKING, {
                        beginAt: e.event.start,
                        endAt: e.event.end,
                        company: e.event.extendedProps.company,
                        activity: e.event.extendedProps.activity,
                        localisation: e.event.extendedProps.localisation,
                        accountingType: e.event.extendedProps.accountingType,
                        salary: e.newResource ? e.newResource?.extendedProps.salary : e.event.extendedProps.salary,
                        instanceToken: e.event.extendedProps.instanceToken,
                        instanceId: e.event.extendedProps.instanceId,
                        isException: e.event.extendedProps.isException,
                        exceptionInstanceId: e.event.extendedProps.exceptionInstanceId,
                        bookingInstanceId: e.event.extendedProps.bookingInstanceId,
                        description: e.event.extendedProps.description,
                        recurrence: e.event.extendedProps.recurrence,
                        resourceId: e.event.extendedProps.longResourceId,
                        parentId: e.event.extendedProps.resourceParentId
                    }))
                    e.revert();
                    break;
                default:
                    e.revert();
            }
        }
    }

    const getMaxEnd = (e: DateSelectArg) => {
        if (e.resource){
            let resourceEvents = e.resource.getEvents().filter(ev => e.start?.getDay() === ev.end?.getDay());
            if (resourceEvents?.length && e.end){
                let maxEnd = Math.max.apply(null, [...resourceEvents.map(e => e.end!.getTime())]);
                if (maxEnd){
                    return new Date(maxEnd);
                }
            }
        }

        return null;
    }

    const onSelect = (e: DateSelectArg) => {

        let _start: Date = e.start;
        let _end: Date = e.end;
        if (Granted(accessRights.EDIT_BOOKING) && e.resource?._resource.parentId){
            switch (e.view.type){
                case 'resourceTimelineMonth':
                    let maxEnd = getMaxEnd(e);

                    if (maxEnd){
                        _start = new Date(maxEnd);
                        _end = new Date(maxEnd.getTime() + (60 * 60 * 1000))
                    }else{
                        _start = new Date(e.start.getTime() + (9*60*60*1000))
                        _end = new Date(e.start.getTime() + (10*60*60*1000))
                    }

                    break;
                case 'resourceTimelineWeek':
                    if (timePrecision.replace(' ', '') === JSON.stringify({day: 1}).replace(' ', '')) {
                        let maxEnd = getMaxEnd(e);

                        if (maxEnd){
                            _start = new Date(maxEnd);
                            _end = new Date(maxEnd.getTime() + (60 * 60 * 1000))
                        }else{
                            _start = new Date(e.start.getTime() + (9*60*60*1000))
                            _end = new Date(e.start.getTime() + (10*60*60*1000))
                        }
                    }
                    break;
            }

            switch (getVersion()) {
                case 1:
                    dispatch(openSidebar(FORM_EVENT_ADD, {
                        salary: e.resource?.extendedProps.salary,
                        start: _start,
                        end: _end,
                        company: e.resource?.extendedProps.company,
                        localisation: e.resource?.extendedProps.localisation,
                        activity: e.resource?.extendedProps.activity,
                        resourceId: e.resource?.id,
                        parentId: e.resource?._resource.parentId,
                    }))
                    break;
                default:
                    dispatch(openSidebar(FORM_NEW_BOOKING, {
                        salary: e.resource?.extendedProps.salary,
                        beginAt: _start,
                        endAt: _end,
                        company: e.resource?.extendedProps.company,
                        localisation: e.resource?.extendedProps.localisation,
                        activity: e.resource?.extendedProps.activity,
                        resourceId: e.resource?.id,
                        parentId: e.resource?._resource.parentId,
                    }))
                    break;
            }
        }
    }

    type ButtonProps = JSX.IntrinsicElements["button"];
    const ViewTitleContainer = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
        const {onClick} = props;
        return <button style={{minWidth: 240}} className="btn text-primary text-capitalize" onClick={onClick} ref={ref}>
                   <i className={'bi bi-calendar-range me-2'}> </i> {title}
               </button>
    });

    const CalendarTitle = () => {
        return <DatePicker
            selected={pickerDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode={'select'}
            onChange={(date) => {
                if (date instanceof Date){
                    setPickerDate(date)
                    let API = calendarRef.current?.getApi()
                    if (API){
                        API.gotoDate(date)
                        dispatch(fetchTitleSuccess(API.view.title, API.view.currentStart, API.view.currentEnd))
                        dispatch(refresh())
                    }
                }
            }}
            customInput={<ViewTitleContainer />}
        />
    }

    const RenderResourceAreaHeader:React.FC = (props) => {

        return (
            <div className={'btn-group'}>
                    <span className="dropdown">
                                <button className="btn btn-outline-primary dropdown-toggle dropdown-toggle-no-after-content" type="button" id="dropdownFilter"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className={'bi bi-filter'}> </i> Filtre
                                </button>
                                <ul className="dropdown-menu position-fixed" aria-labelledby="dropdownFilter">
                                    {groupBy === 1 && <li className={'dropdown-item'}
                                         onClick={() => setIsOuter(prevState => !prevState)}>
                                        <i className={isOuter ? 'bi bi-check text-success' : 'bi bi-x text-danger'}> </i> Afficher
                                        les créneaux sur un autre point de vente
                                    </li>}
                                    {groupBy === 1 && <li className={'dropdown-item'}
                                                          onClick={() => setIsAbsent(prevState => !prevState)}>
                                        <i className={isAbsent ? 'bi bi-check text-success' : 'bi bi-x text-danger'}> </i> Afficher
                                        les ETP sans créneau
                                    </li>}
                                    <li className={'dropdown-item'} onClick={() => setIsAbsence(prevState => !prevState)}>
                                       <i className={isAbsence ? 'bi bi-check text-success' : 'bi bi-x text-danger'}> </i> Afficher les créneaux absent
                                    </li>
                                    {HasModule('bookingOffer') && <li className={'dropdown-item'}
                                         onClick={() => setIsBookingOffer(prevState => !prevState)}>
                                        <i className={isBookingOffer ? 'bi bi-check text-success' : 'bi bi-x text-danger'}> </i> Afficher
                                        les propositions de créneaux
                                    </li>}
                                </ul>
                    </span>
                    <button className={'btn btn-outline-primary'} onClick={() => dispatch(openSidebar(FORM_CALENDAR_RESOURCE_ORDER, {type: groupBy}))}>
                        <i className={'bi bi-sort-alpha-up-alt'}> </i> Ordre
                    </button>
            </div>
        )
    }

    const NavLinkDayClick = (date: Date, e: VUIEvent) => {
        let API = calendarRef.current?.getApi()
        if (API){
            switch (API.view.title) {
                case 'resourceTimelineMonth':
                    API.changeView('resourceTimelineWeek', date)
                    dispatch(action.setView('resourceTimelineWeek'));
                    break;
                case 'resourceTimelineWeek':
                    API.changeView('resourceTimelineDay', date)
                    dispatch(action.setView('resourceTimelineDay'));
                    break;
            }
            if (['resourceTimelineMonth','resourceTimelineWeek'].includes(API.view.title)){
                dispatch(fetchTitleSuccess(API.view.title, API.view.currentStart, API.view.currentEnd));
                dispatch(refresh());
            }
        }
    }

    const slotLabelContent  = (e: SlotLabelContentArg) => {

        let labourDay = labourDays.find(ld => ld.date === format(e.date, 'uuuu-MM-dd'))

        if (e.level === 0 && e.view.type === 'resourceTimelineDay'){
            return <span className={'text-primary'}>{format(new Date(e.date.getTime()), 'HH:mm')}</span>
        }
        if (e.level === 0 && e.view.type === 'resourceTimelineWeek'){
            return <div className={'text-center position-relative'}>
                    <span className={format(e.date, 'uuuu-MM-dd') === format(new Date(), 'uuuu-MM-dd') ? 'rounded-pill bg-primary text-capitalize py-1 px-3 text-white' : 'text-primary text-capitalize'}>
                        {format(e.date, 'EEEE dd')}
                    </span>
                <div className="clearfix"> </div>
                    {labourDay && <>
                        <span className="clearfix"> </span>
                        <small className="text-success text-capitalize">
                            {labourDay.title}
                        </small>
                    </>}
                <div className={'text-dark position-absolute'} style={{right: 5, top: 5}}>
                    {state.keepNote.payload.filter((keepNote: KeepNote) => format(new Date(keepNote.dueDate)) === format(e.date)).length > 0 && <span className={'py-1 px-2 me-2'}
                           onClick={(ev) => {
                               ev.stopPropagation();
                               dispatch(openSidebar(FORM_KEEP_NOTE_LIST, {dueDate: e.date}))
                           }}>
                        <span className="bg-primary rounded px-1 text-white">
                            {state.keepNote.payload.filter((keepNote: KeepNote) => format(new Date(keepNote.dueDate)) === format(e.date)).length}
                        </span>
                    </span>}
                    <span className={'p-1 shadow'}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                dispatch(openSidebar(FORM_KEEP_NOTE_ADD, {dueDate: e.date}))
                            }}>
                        <i className={'bi bi-pen'}> </i>
                    </span>
                </div>
            </div>
        }
        if (e.level === 0 && e.view.type === 'resourceTimelineMonth'){
            if (format(e.date, 'uuuu-MM-dd') === format(new Date(), 'uuuu-MM-dd')){
                return <span className={'rounded-pill bg-primary p-1 text-white'}>{e.text}</span>
            }else{
                return <span className={'text-primary'}>{e.text}</span>
            }
        }
        if (e.level === 1 && e.view.type === 'resourceTimelineWeek'){
            return <span className={'text-primary'}>{format(new Date(e.date.getTime()), 'HH:mm')}</span>
        }
        return <span className={'text-primary'}>{e.text}</span>
    }

    const handleCompanyChange = (company: Company) => {
        let _selectingCompanies = [...selectingCompanies]
        let index = selectingCompanies.findIndex(c => c.id === company.id);
        if (index !== -1){
            _selectingCompanies.splice(index, 1)
            setSelectingCompanies([..._selectingCompanies])
        }else{
            setSelectingCompanies([..._selectingCompanies, company]);
        }
    }

    return (
        <>
            <div className={'mt-2 container-fluid'}>
                <div className="row">
                    <div className="col-3 col-md-auto mb-2">
                        <div className="dropdown" id={"companyDropdownContainer"} >
                            <button className="btn bg-white w-100 shadow-sm dropdown-toggle text-primary me-2" type="button"
                                    id="companyDropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                <i className={'bi bi-shop'}> </i>
                                <span className={'position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary'}>
                                            {selectingCompanies.length || 1}
                                        </span>
                            </button>
                            <ul className="dropdown-menu p-3 list-unstyled" aria-labelledby="companyDropdown">
                                <li className={'p-0 mb-3'} style={{minWidth: 300}}>
                                    <div className="input-group">
                                                <span className="input-group-text br-0 bg-white text-primary">
                                                    <i className={'bi bi-search'}> </i>
                                                </span>
                                        <input id={"companyQuery"}
                                               placeholder={"Rechercher un point de vente"}
                                               onChange={(e) => setCompanyQuery(e.target.value)} type="search" className="form-control br-0"/>
                                    </div>
                                </li>
                                {userCompanies.filter(c => {
                                    return !companyQuery || c.title.toLowerCase().includes(companyQuery.toLowerCase())
                                }).map((c, i) => <li key={i} className={'dropdown-item'}>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input"
                                               onChange={() => handleCompanyChange(c)}
                                               type="checkbox"
                                               defaultChecked={selectingCompanies.includes(c)}
                                               id="flexSwitchCheckDefault" />
                                        <label className="form-check-label"
                                               htmlFor="flexSwitchCheckDefault">{c.title}</label>
                                    </div>
                                </li>)}
                                <li>
                                    <button className={'btn w-100 btn-outline-primary'} onClick={() => onValidateBsDropdown()}>
                                        <i className={'bi bi-check'}></i> Valider
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {Granted(accessRights.LIST_TEAM) && HasModule('team') && <div className="col-3 col-md-auto">
                        <div className="dropdown" id={"teamDropdownContainer"}>
                            <button className="btn bg-white w-100 shadow-sm dropdown-toggle text-primary me-2" type="button"
                                    id="teamDropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                    aria-expanded="false">
                                <i className={'bi bi-people'}> </i>
                                <span
                                    className={'position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary'}>
                                            {selectedTeams.length}
                                        </span>
                            </button>
                            <ul className="dropdown-menu p-3 list-unstyled" aria-labelledby="teamDropdown">
                                <li>
                                    Équipes
                                </li>
                                <li className={'p-0 mb-3'} style={{minWidth: 300}}>
                                    <div className="input-group">
                                                <span className="input-group-text br-0 bg-white text-primary">
                                                    <i className={'bi bi-search'}> </i>
                                                </span>
                                        <input id={"teamQuery"} onChange={(e) => setTeamQuery(e.target.value)} type="search"
                                               className="form-control br-0"/>
                                    </div>
                                </li>
                                {teams.filter(t => {
                                    return !teamQuery || t.title.toLowerCase().includes(teamQuery.toLowerCase())
                                }).map(t => <li className={'dropdown-item'}
                                                onClick={() => {
                                                    let index = selectedTeams.findIndex(_t => _t.id === t.id)
                                                    if (index !== -1){
                                                        let _selectedTeams = [...selectedTeams]
                                                        _selectedTeams.splice(index, 1);
                                                        setSelectedTeams([..._selectedTeams])
                                                    }else{
                                                        setSelectedTeams([...selectedTeams, t])
                                                    }
                                                }}>
                                    {t.title} {selectedTeams?.map(_t => _t.id).includes(t.id) && <i className={'bi bi-check text-primary'}> </i>}
                                </li>)}
                                <li className={'dropdown-item'}>
                                    <button className={'btn btn-outline-primary w-100'} onClick={() => dispatch(refresh())}>
                                        <i className={'bi bi-check'}></i> Valider
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>}
                    {Granted(accessRights.LIST_JOB) && <div className="col-3 col-md-auto">
                        <div className="dropdown" id={"jobDropdownContainer"}>
                            <button className="btn bg-white w-100 shadow-sm dropdown-toggle text-primary me-2" type="button"
                                    id="teamDropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                    aria-expanded="false">
                                <i className={'bi bi-person-square'}> </i>
                            </button>
                            <ul className="dropdown-menu p-3 list-unstyled" aria-labelledby="teamDropdown">
                               <li className="dropdown-item" onClick={() => setJob(0)}>
                                   {job === 0 && <i className={'bi bi-check text-primary'}></i>} Tous les métiers
                               </li>
                               <li className="dropdown-item" onClick={() => setJob(1)}>
                                   {job === 1 && <i className={'bi bi-check text-primary'}></i>} Front office
                               </li>
                               <li className="dropdown-item" onClick={() => setJob(2)}>
                                   {job === 2 && <i className={'bi bi-check text-primary'}></i>} Back office
                               </li>
                            </ul>
                        </div>
                    </div>}
                    <div className="col-3 d-md-none mb-2">
                        <div className="btn-group">
                            <button className={'btn btn-outline-primary' + (viewType === 'timelineResource' ? " active text-white " : '')} onClick={() => setViewType('timelineResource')}>
                                <i className={'bi bi-calendar-week'}></i>
                            </button>
                            <button className={'btn btn-outline-primary' + (viewType === 'list' ? " active text-white " : '')} onClick={() => setViewType('list')}>
                                <i className={'bi bi-list'}></i>
                            </button>
                        </div>
                    </div>
                    {['resourceTimelineDay', 'resourceTimelineWeek', 'listWeek'].includes((view || defaultView)) && <div className="col-auto">
                        <button className="btn d-none d-md-block text-primary" type="button">
                            Sem. {Wn}
                        </button>
                    </div>}
                    <div className="col-auto d-none d-md-block">
                        <button disabled={isLoading} className="btn bg-white d-none d-md-block shadow-sm text-primary dropdown-toggle-no-after-content dropdown-toggle border calendar-view-selector" type="button" id="defaultDropdown"
                                data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            {(view || defaultView) === 'resourceTimelineDay' ? 'Jour' : ((view || defaultView) === 'resourceTimelineWeek' ? 'Semaine' : 'Mois')}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                            <li>
                                    <button disabled={isLoading} className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineDay')}>
                                        Jour
                                    </button>
                            </li>
                            <li>
                                    <button disabled={isLoading} className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineWeek')}>
                                        Semaine
                                    </button>
                            </li>
                            <li>
                                    <button disabled={isLoading} className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineMonth')}>
                                        Mois
                                    </button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-auto mb-2 text-center">
                        <div className="btn-group mx-2">
                            <button disabled={isLoading} className={`btn bg-white rounded-pill shadow`} onClick={() => calendarApiNavActions('prev')}>
                                <i className="bi bi-chevron-left"> </i>
                            </button>
                            <CalendarTitle />
                            <button disabled={isLoading} className={`btn bg-white rounded-pill shadow`} onClick={() => calendarApiNavActions('next')}>
                                <i className="bi bi-chevron-right"> </i>
                            </button>
                        </div>
                    </div>
                    <div className="col d-none d-md-block">

                    </div>
                    <div className="col-auto mb-2 d-none d-md-block">
                        <div className="btn-group">
                            <button disabled={isLoading} data-bs-toggle="tooltip" data-bs-placement="top" title="Trier par collaborateur" onClick={() => setGroupBy(1)} className={'btn btn-outline-primary' + ((groupBy || defaultGroupBy) === 1 ? ' active text-white ' : '')}>
                                <i className={'bi bi-people'}> </i>
                            </button>
                            <button disabled={isLoading} data-bs-toggle="tooltip" data-bs-placement="top" title="Trier par emplacements" onClick={() => setGroupBy(2)} className={'btn btn-outline-primary' + ((groupBy || defaultGroupBy) === 2 ? ' active text-white ' : '')}>
                                <i className={'bi bi-geo-alt'}> </i>
                            </button>
                            <button disabled={isLoading} data-bs-toggle="tooltip" data-bs-placement="top" title="Trier par activités" onClick={() => setGroupBy(3)} className={'btn btn-outline-primary' + ((groupBy || defaultGroupBy) === 3 ? ' active text-white ' : '')}>
                                <i className={'bi bi-bookmark'}> </i>
                            </button>
                        </div>
                    </div>
                    <div className="col-auto align-items-center d-none d-md-flex">
                        <i className={'bi bi-sun btn'}> </i>
                        <div className="form-check form-switch">
                            <input onChange={e => setNightView(e.target.checked)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                        </div>
                        <i className={'bi bi-moon-stars btn'}> </i>
                    </div>
                    <div className="col-12 col-md-auto text-center mb-2">
                        <div className="button-group">
                            {HasModule("timeClock") && Granted(LIST_TIME_CLOCK) && <>
                                <Link to={"/time_clock/calendar"}
                                      data-bs-toggle="tooltip" data-bs-placement="top"
                                      title="Planning des badgeages"
                                      className={"btn bg-white text-primary shadow-sm mx-1"}
                                >
                                    <i className={'bi bi-tablet-landscape'}> </i>
                                </Link>
                            </>}
                            {Granted(accessRights.EDIT_BOOKING) && <>
                                <button data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Historique des modifications"
                                        onClick={() => dispatch(openSidebar(BOOKING_HISTORY))}
                                        className={'btn bg-white text-primary shadow-sm mx-1'}>
                                    <i className="bi bi-clock-history"> </i>
                                </button>
                                {[2, 3].includes(groupBy) &&
                                    <button data-bs-toggle="tooltip" data-bs-placement="top" title="Manque présence"
                                            onClick={() => dispatch(openSidebar(NEED_LIST))}
                                            className={'btn bg-white text-primary position-relative shadow-sm mx-1'}>
                                        <i className="bi bi-exclamation-circle"> </i>
                                        {needEvents.filter((needEvent: NeedEvent) => needEvent.type === 1).length > 0 &&
                                            <span
                                                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {needEvents.filter((needEvent: NeedEvent) => needEvent.type === 1).length}
                                    </span>}
                                    </button>}
                            </>}
                            {GrantedAny([accessRights.EDIT_TEMPLATE, accessRights.LIST_TEMPLATE]) && <span className={"d-none d-md-inline-block"} tabIndex={0} data-bs-toggle="tooltip" data-bs-placement="top" title="Template">
                                    <button
                                        className={'btn position-relative bg-white text-primary shadow-sm dropdown-toggle dropdown-toggle-no-after-content mx-1'}
                                        data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
                                        id={"templateActionDropdown"}>
                                    <i className={'bi bi-front'}> </i>
                                        {templates.length > 0 && <span
                                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                                                {templates.length}
                                            </span>}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="templateActionDropdown">
                                        {GrantedMany([accessRights.EDIT_BOOKING, accessRights.LIST_TEMPLATE]) && <li>
                                            <button className={'dropdown-item'} onClick={() => {
                                                dispatch(openSidebar(LIST_TEMPLATES_APPLY, {
                                                    start: calendarRef.current?.getApi().view.currentStart,
                                                    end: calendarRef.current?.getApi().view.currentEnd,
                                                }))
                                            }}>
                                                <i className={'bi bi-calendar2-check me-2'}> </i> Appliquer un template
                                            </button>
                                        </li>}
                                        {Granted(accessRights.EDIT_TEMPLATE) && <li>
                                            <button className={'dropdown-item'}
                                                    onClick={() => dispatch(openSidebar(FORM_NEW_TEMPLATE_FROM_CALENDAR, {
                                                        weekNumber: format(calendarRef.current!.getApi().view.currentStart, 'w'),
                                                        year: format(calendarRef.current!.getApi().view.currentStart, 'uuuu'),
                                                        job: "0",
                                                        company: companies[0] || null
                                                    }))}>
                                                <i className={'bi bi-calendar2-plus me-2'}> </i> Convertir le planning
                                                en template
                                            </button>
                                        </li>}
                                        <li><hr className="dropdown-divider"/></li>
                                        {Granted(accessRights.LIST_TEMPLATE) && templates.length ? templates.map((templateApplied: TemplateApplied) => <li
                                            onClick={() => dispatch(openSidebar(TEMPLATES_APPLY_EDIT, templateApplied))}
                                            className={'dropdown-item'}>
                                            <i className={'bi bi-front'}> </i>
                                            {templateApplied.template.title}
                                            <div className="clearfix"> </div>
                                            {format(new Date(templateApplied.start), 'dd/MM/uuuu')}
                                            <i className={'bi bi-arrow-left-right mx-2'}> </i>
                                            {format(new Date(templateApplied.end), 'dd/MM/uuuu')}
                                        </li>) : <li className={'dropdown-item form-text'}>Aucun template appliqué</li>}
                                    </ul>
                                </span>}
                            <button data-bs-toggle="tooltip" data-bs-placement="top" title="Télécharger" onClick={() => dispatch(openSidebar(FORM_CALENDAR_DOWNLOAD, {
                                view: view || defaultView,
                                company: companies.length ? companies[0] : null,
                                year: calendarRef.current?.getApi().getDate().getFullYear().toString(),
                                month: format(calendarRef.current!.getApi().getDate(), 'M'),
                                week: format(calendarRef.current!.getApi().getDate(), 'w'),
                            }))} className={'btn bg-white text-primary shadow-sm mx-1'}>
                                <i className="bi bi-download"> </i>
                            </button>

                            {GrantedAny([accessRights.EDIT_BOOKING, accessRights.EDIT_TASK_GROUP, accessRights.EDIT_ABSENCE, accessRights.EDIT_LOCALISATION, accessRights.EDIT_ACTIVITY]) && <span data-bs-toggle="tooltip" data-bs-placement="top" title="Ajouter">
                                    <button
                                        className={'btn bg-white text-primary shadow-sm dropdown-toggle dropdown-toggle-no-after-content mx-1'}
                                        data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
                                        id={"addActionDropdown"}>
                                    <i className={'bi bi-plus-lg'}> </i>
                                </button>
                                    <ul className="dropdown-menu" aria-labelledby="addActionDropdown">
                                        {Granted(accessRights.EDIT_BOOKING) && <li>
                                            {getVersion() !== 1 ? <button className={'dropdown-item'}
                                                                    onClick={() => dispatch(openSidebar(FORM_NEW_BOOKING))}>
                                                <i className={'bi bi-plus me-2'}> </i> Ajouter un créneau
                                            </button> : <button className={'dropdown-item'}
                                                                onClick={() => dispatch(openSidebar(FORM_EVENT_ADD, {
                                                                    start: calendarRef.current?.getApi().view.activeStart,
                                                                    end: calendarRef.current?.getApi().view.activeStart,
                                                                }))}>
                                                <i className={'bi bi-plus me-2'}> </i> Ajouter un créneau
                                            </button>}
                                        </li>}
                                        {Granted(accessRights.EDIT_TASK_GROUP) && <li>
                                            <button className={'dropdown-item'}
                                                    onClick={() => dispatch(openSidebar(FORM_NEW_TASK))}>
                                                <i className={'bi bi-journal-plus me-2'}> </i> Ajouter une tâche
                                            </button>
                                        </li>}
                                        {Granted(accessRights.EDIT_ABSENCE) && <li>
                                            <button className={'dropdown-item'}
                                                    onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE))}>
                                                <i className={'bi bi-calendar-plus me-2'}> </i> Ajouter une absence
                                            </button>
                                        </li>}
                                        {Granted(accessRights.EDIT_LOCALISATION) && <li>
                                            <button className={'dropdown-item'}
                                                    onClick={() => dispatch(openSidebar(FORM_NEW_LOCALISATION))}>
                                                <i className={'bi bi-geo-alt me-2'}> </i> Ajouter un emplacement
                                            </button>
                                        </li>}
                                        {Granted(accessRights.EDIT_ACTIVITY) && <li>
                                            <button className={'dropdown-item'}
                                                    onClick={() => dispatch(openSidebar(FORM_NEW_LOCALISATION))}>
                                                <i className={'bi bi-bookmark-plus me-2'}> </i> Ajouter une activité
                                            </button>
                                        </li>}
                                        {Granted(accessRights.EDIT_SALARY) && <li>
                                            <button className={'dropdown-item'}
                                                    onClick={() => dispatch(openSidebar(FORM_NEW_SALARY))}>
                                                <i className={'bi bi-person-plus me-2'}> </i> Ajouter un collaborateur
                                            </button>
                                        </li>}
                                        {HasModule('bookingOffer') && Granted(accessRights.EDIT_BOOKING_OFFER) && <> {
                                            getVersion() !== 1 ? <li>
                                                <button className={'dropdown-item'}
                                                        onClick={() => dispatch(openSidebar(FORM_NEW_BOOKING_OFFER))}>
                                                    <i className={'bi bi-plus-circle-dotted me-2'}> </i> Ajouter une
                                                    proposition de créneau
                                                </button>
                                            </li> : <li>
                                                <button className={'dropdown-item'}
                                                        onClick={() => dispatch(openSidebar(FORM_NEW_EVENT_OFFER))}>
                                                    <i className={'bi bi-plus-circle-dotted me-2'}> </i> Ajouter une
                                                    proposition de créneau
                                                </button>
                                            </li>} </>}
                                </ul>
                                </span>}
                            <button data-bs-toggle="tooltip" data-bs-placement="top" title="Parametres" onClick={() => dispatch(openSidebar(FORM_CALENDAR_SETTINGS))} className={'btn bg-white text-primary shadow-sm ms-1'}>
                                <i className="bi bi-gear"> </i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-grow-1">
                <FullCalendar
                    key={'calendar'}
                    ref={calendarRef}
                    plugins={[resourceTimelinePlugin, listPlugin, interactionPlugin, rrulePlugin]}
                    initialView={window.innerWidth >= 768 ? (view.length ? view : defaultView) : (viewType === 'list' ? 'listDay' : 'resourceTimelineDay')}
                    resourceAreaWidth={"20%"}
                    height={'100%'}
                    eventMouseEnter={(e) => {
                        let API = calendarRef.current?.getApi();
                        if (API) {
                            switch (e.event.extendedProps.eventType) {
                                case 'presence':
                                    let event: any = e.event.extendedProps;
                                    event.children.map((c: any) => {
                                        let el = document.getElementById(c.instanceToken) as HTMLDivElement;
                                        if (el && el.parentElement) {
                                            if (el.parentElement.parentElement) {
                                                el.parentElement.parentElement.classList.toggle('fc-highlight-primary')
                                            }
                                        }
                                    })
                                    break;
                                case 'booking':
                                case 'bookingException':
                                case 'templateBooking':
                                case 'templateBookingException':
                                case 'templateBookingAppliedException':
                                case 'event':
                                case 'eventException':
                                    let el = e.el.querySelector(`[id="title${e.event.id}"]`) as HTMLSpanElement;
                                    if (el) {
                                        el.textContent = e.event.extendedProps.alternativeTitle
                                    }
                                    break;
                            }
                        }
                    }}
                    eventMouseLeave={(e) => {
                        let API = calendarRef.current?.getApi();
                        if (API) {
                            switch (e.event.extendedProps.eventType) {
                                case 'presence':
                                    let event: any = e.event.extendedProps;
                                    event.children.map((c: any) => {
                                        let el = document.getElementById(c.instanceToken) as HTMLDivElement;
                                        if (el && el.parentElement) {
                                            if (el.parentElement.parentElement) {
                                                el.parentElement.parentElement.classList.toggle('fc-highlight-primary')
                                            }
                                        }
                                    })
                                    break;
                                case 'booking':
                                case 'bookingException':
                                case 'templateBooking':
                                case 'templateBookingException':
                                case 'templateBookingAppliedException':
                                case 'event':
                                case 'eventException':
                                    let el = e.el.querySelector(`[id="title${e.event.id}"]`) as HTMLSpanElement;
                                    if (el) {
                                        el.textContent = e.event.title
                                    }
                                    break;
                            }
                        }
                    }}
                    editable={Granted(accessRights.EDIT_BOOKING)}
                    select={onSelect}

                    resourceOrder={'order1,order2,order3,order4'}
                    eventOverlap={false}
                    lazyFetching={false}
                    resourceGroupLabelContent={renderResourceGroup}
                    headerToolbar={false}
                    views={{
                        resourceTimelineDay: {
                            slotDuration: {minutes: 30}
                        },
                        resourceTimelineWeek: {
                            slotDuration: JSON.parse(timePrecision),
                        },
                        resourceTimelineMonth: {
                            slotDuration: {day: 1}
                        }
                    }}
                    hiddenDays={daysOfWeek}
                    slotMinTime={timeRange.minTime}
                    slotMaxTime={timeRange.maxTime}
                    selectable={Granted(accessRights.EDIT_BOOKING)}
                    eventClick={eventClick}
                    eventResize={eventResize}
                    eventDrop={eventDrop}
                    locales={allLocales}
                    locale={'fr'}
                    stickyHeaderDates={true}
                    displayEventTime={true}
                    schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                    stickyFooterScrollbar={true}
                    expandRows={false}
                    resourceAreaHeaderContent={() => <RenderResourceAreaHeader />}
                    resourceLabelContent={renderResource}
                    resourceLaneContent={resourceLaneContent}
                    resourceLabelClassNames={(arg: ResourceLabelContentArg) => arg.resource.extendedProps.classNames}
                    resourceGroupField={"groupId"}
                    eventResourceEditable={true}
                    eventSources={[tasks, isBookingOffer ? bookingOffers : [], presenceEvents, events.filter((e: any) => (isOuter || !e.isOuterEvent) && (isAbsence || !e.absence))]}
                    resources={resources}
                    eventContent={renderEvent}
                    slotLabelContent={slotLabelContent}
                    handleWindowResize={true}
                    navLinks={true}
                    navLinkDayClick={NavLinkDayClick}
                    viewDidMount={ViewDidMount}
                    slotEventOverlap={false}
                    refetchResourcesOnNavigate={true}
                />
            </div>
            <EventPopup />
            <EventPop />
            <EventAppliedPop />
            <TaskEventPop />
            <ResourceActions />
            {isLoading && <Loader type={'grow'} />}
        </>
    )
}

export default CalendarShow;
