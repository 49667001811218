import React, {forwardRef, useEffect, useState} from "react";
import FullCalendar, {EventClickArg, EventContentArg, VUIEvent} from "@fullcalendar/react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import resourceTimeline from "@fullcalendar/resource-timeline";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import * as api from "../../../adapters/task/calendar";
import format from "../../../utils/locale";
import {openSidebar} from "../../../actions/rightSidebar";
import {
    FORM_NEW_TASK, FORM_NEW_TASK_GROUP, FORM_NEW_TASK_GROUP_CUSTOM_FIELD
} from "../../../constants/rightSidebar";
import {setPageTitle} from "../../../actions/header";
import * as actions from "../../../actions/task/calendar";
import {fetchTitleSuccess, refresh} from "../../../actions/task/calendar";
import * as type from "../../../models/task/calendar";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import TaskEventPop from "../../../utils/taskEventPop";
import {axiosError} from "../../../actions/axios";
import BootstrapSelect from "../../../utils/bootstrapSelect";


const TaskGroupCalendar:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const calendar: type.initialStateInterface = useSelector((state: RootStateOrAny) => state.taskCalendar)
    const calendarRef = React.createRef<FullCalendar>();
    const [filteredResources, setFilteredResources] = useState<any>([])
    const [resourcesQuery, setResourcesQuery] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [view, setView] = useState('resourceTimelineWeek')
    const [pickerDate, setPickerDate] = useState(new Date());
    const [groups, setGroups] = useState<number[]>([]);

    useEffect(() => {
        dispatch(setPageTitle('Tâches', 'Planning'))
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        let API = calendarRef.current?.getApi();
        if (API){
            refreshCalendar(controller.signal);
            dispatch(fetchTitleSuccess(API.view.title))
        }
        // return () => controller.abort();
    }, [calendar.refresh, groups])

    // Filter resources
    useEffect(() => {
        if (!resourcesQuery){
            setFilteredResources([...calendar.resources])
        }else{
            let filtered = calendar.resources.filter((data: any) => {
                return data.title.toLowerCase().includes(resourcesQuery.toLowerCase()) ||
                    data.groupTitle.toLowerCase().includes(resourcesQuery.toLowerCase())
            });
            setFilteredResources([...filtered])
        }
    }, [resourcesQuery])

    const eventClick = (e: EventClickArg) => {
        e.jsEvent.stopPropagation()
        dispatch(actions.showEventPop(e))
    }

    const calendarApiNavActions = (action: string) => {

        let API = calendarRef.current?.getApi()
        if (API){
            switch (action) {
                case 'prev':
                    API.prev();
                    break;
                case 'next':
                    API.next();
                    break;
                case 'resourceTimelineDay':
                    API.changeView('resourceTimelineDay')
                    break;
                case 'resourceTimelineWeek':
                    API.changeView('resourceTimelineWeek')
                    break;
                case 'resourceTimelineMonth':
                    API.changeView('resourceTimelineMonth')
                    break;
            }

            setView(API.view.type)
            dispatch(fetchTitleSuccess(API.view.title))
            dispatch(refresh());
        }
    }

    // FETCH FUNCTIONS
    const refreshCalendar = (signal: AbortSignal) => {
        if (isLoading) return false;

        let API = calendarRef.current?.getApi()
        if (API){
            let start = API.view.currentStart;
            let end = API.view.currentEnd;
            setIsLoading(true);
            Promise.all([
                fetchEvents(start, end, signal),
                fetchResources(start, end, signal)
            ]).then(() => setIsLoading(false))
        }

    }

    const fetchResources = (start: Date, end: Date, signal: AbortSignal) => {
       return api.fetchResources({start: format(start,'uuuu-MM-dd'), end: format(end,'uuuu-MM-dd'), options: {
               taskGroups: groups,
           }}).then(data => {
            dispatch(actions.fetchResourcesSuccess(data.data))
            setFilteredResources(data.data);
        }).then(() => true).catch(error => {
           dispatch(axiosError(error))
           return false;
       })
    }

    const fetchEvents = (start: Date, end: Date, signal: AbortSignal) => {
       return api.fetchEvents({start: format(start,'uuuu-MM-dd'), end: format(end,'uuuu-MM-dd'), options: {
               taskGroups: groups,
           }}, signal).then(data => {
           dispatch(actions.fetchEventsSuccess(data.data))
        }).then(() => true)
           .catch(error => {
           dispatch(axiosError(error))
           return false;
       })
    }

    const ViewTitle = () => {
        return <span className={'btn px-2 text-center text-capitalize text-primary'}>
                    {calendar.title}
               </span>
    }

    const renderResourceGroup = (arg: any) => {
        let title = arg.groupValue.substring(arg.groupValue.indexOf('title:') + 6);
        return {
            html: '<b class="text-primary text-uppercase">'+ title +'</b>'
        }
    }

    const renderEvent = (e: EventContentArg) => {
        return <b>{e.timeText}</b>
    }

    type ButtonProps = JSX.IntrinsicElements["button"];
    const ViewTitleContainer = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
        const {onClick} = props;
        return <button style={{minWidth: 240}} className="btn text-primary fs-5 text-capitalize" onClick={onClick} ref={ref}>
            {calendar.title}
        </button>
    });

    const CalendarTitle = () => {
        return <DatePicker
            selected={pickerDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode={'select'}
            onChange={(date) => {
                if (date instanceof Date){
                    setPickerDate(date)
                    let API = calendarRef.current?.getApi()
                    if (API){
                        API.gotoDate(date)
                        dispatch(fetchTitleSuccess(API.view.title))
                        dispatch(refresh());
                    }
                }
            }}
            customInput={<ViewTitleContainer />}
        />
    }

    const renderResourceAreaHeader = (arg: any) => {

        arg.el.innerHTML = '<div id="resourceSearch" class="input-group">\n' +
            '                   <span class="input-group-text" id="search-addon">\n' +
            '                       <i class="bi bi-search"> </i>\n' +
            '                   </span>\n' +
            '               </div>'

        let resourceSearch = document.createElement('input') as HTMLInputElement;
        resourceSearch.type = 'text'
        resourceSearch.className = 'form-control'
        resourceSearch.placeholder = 'Rechercher un groupe, une tâche';
        resourceSearch.onkeyup = (e) => {
            let el = e.target as HTMLInputElement;
            let query = el.value;
            setResourcesQuery(query);
        }

        arg.el.getElementsByClassName('input-group')[0].append(resourceSearch);
    }

    const NavLinkDayClick = (date: Date, e: VUIEvent) => {
        let API = calendarRef.current?.getApi()
        if (API){
            switch (view) {
                case 'resourceTimelineMonth':
                    API.changeView('resourceTimelineWeek', date)
                    setView('resourceTimelineWeek')
                    break;
                case 'resourceTimelineWeek':
                    API.changeView('resourceTimelineDay', date)
                    setView('resourceTimelineDay')
                    break;
            }
            dispatch(fetchTitleSuccess(API.view.title))
            dispatch(refresh());
        }
    }

    return (
        <div className={'container-fluid flex-grow-1'}>
            <div className={'w-100 py-2'}>
                <div className="row">
                    <div className="col-auto">
                        <Link to={"/task_group"} className={"btn btn-outline-primary"}>
                            <i className={'bi bi-list-ul'}> </i> Liste
                        </Link>
                    </div>
                    <div className="col-auto">
                        <BootstrapSelect
                            notFloating={true}
                            fetchEntity={'taskGroup'}
                            placeholder={'Séléctionner un groupe de tâche'}
                            isMultiple={true}
                            onChangeMultiple={(choices) => setGroups(choices.map(c => Number(c.value)))}
                        />
                    </div>
                    <div className="col">
                        <div className="btn-group calendar-view-container">
                            <button className="btn bg-white text-primary dropdown-toggle-no-after-content dropdown-toggle border calendar-view-selector" type="button" id="defaultDropdown"
                                    data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                {view === 'resourceTimelineDay' ? 'Jour' : (view === 'resourceTimelineWeek' ? 'Semaine' : 'Mois')}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                <li>
                                    <span className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineDay')}>
                                        Jour
                                    </span>
                                </li>
                                <li>
                                    <span className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineWeek')}>
                                        Semaine
                                    </span>
                                </li>
                                <li>
                                    <span className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineMonth')}>
                                        Mois
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-group ml-2">
                            <button className={`btn`} onClick={() => calendarApiNavActions('prev')}>
                                <i className="bi bi-chevron-left"> </i>
                            </button>
                            <CalendarTitle />
                            <button className={`btn`} onClick={() => calendarApiNavActions('next')}>
                                <i className="bi bi-chevron-right"> </i>
                            </button>
                        </div>
                    </div>
                    <div className="col d-none d-md-block"> </div>
                    <div className="col-md-auto">
                        <button className={'btn bg-white text-primary shadow-sm mx-1'}>
                            <i className="bi bi-download"> </i>
                        </button>
                        <button className={'btn bg-white text-primary shadow-sm dropdown-toggle dropdown-toggle-no-after-content mx-1'}
                                data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" id={"addActionDropdown"}>
                            <i className={'bi bi-plus-lg'}> </i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="addActionDropdown">
                            <li>
                                <button className={'dropdown-item'} onClick={() => dispatch(openSidebar(FORM_NEW_TASK))}>
                                    <i className={'bi bi-plus me-2'}> </i> Ajouter une tâche
                                </button>
                            </li>
                            <li>
                                <button className={'dropdown-item'} onClick={() => dispatch(openSidebar(FORM_NEW_TASK_GROUP))}>
                                    <i className={'bi bi-journal-plus me-2'}> </i>  Ajouter un groupe de tâche
                                </button>
                            </li>
                            <li>
                                <button className={'dropdown-item'} onClick={() => dispatch(openSidebar(FORM_NEW_TASK_GROUP_CUSTOM_FIELD))}>
                                    <i className={'bi bi-journal-plus me-2'}> </i>  Ajouter un champ personnalisé
                                </button>
                            </li>
                        </ul>
                        <button className={'btn bg-white text-primary shadow-sm ms-1'}>
                            <i className="bi bi-gear"> </i>
                        </button>
                    </div>
                </div>
            </div>
            <FullCalendar
                plugins={[ resourceTimeline, dayGridPlugin, interactionPlugin ]}
                ref={calendarRef}
                headerToolbar={false}
                initialView={view}
                views={{
                    resourceTimelineDay: {
                        slotDuration: '00:30:00'
                    },
                    resourceTimelineWeek: {
                        slotDuration: {day: 1}
                    },
                    resourceTimelineMonth: {
                        slotDuration: {day: 1}
                    }
                }}
                progressiveEventRendering={true}
                resourceGroupLabelContent={renderResourceGroup}
                slotMinTime={'09:00:00'}
                slotMaxTime={'21:00:00'}
                selectable
                eventClick={eventClick}
                locales={allLocales}
                locale={'fr'}
                stickyHeaderDates={true}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                stickyFooterScrollbar={true}
                expandRows={false}
                refetchResourcesOnNavigate={true}
                displayEventTime={true}
                displayEventEnd={true}
                resourceAreaHeaderDidMount={renderResourceAreaHeader}
                eventResourceEditable={true}
                resourceGroupField={'groupId'}
                events={calendar.events}
                height={'100%'}
                resources={filteredResources}
                navLinkDayClick={NavLinkDayClick}
            />
            <div className={'fc-overlay' + (!isLoading ? ' d-none' : '')}>
                <div className="position-relative top-50 translate-middle-y">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <TaskEventPop />
        </div>
    )
}

export default TaskGroupCalendar;