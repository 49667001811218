import * as types from "../../../constants/event/offer";
import * as models from "../../../models/event/offer";

export const addEventOffer = () => {
    return {
        type: types.ADD_EVENT_OFFER,
    }
}

export const addEventOfferSuccess = (eventOffer: models.EventOffer) => {
    return {
        type: types.ADD_EVENT_OFFER_SUCCESS,
        eventOffer: eventOffer,
    }
}
export const removeEventOfferSuccess = (eventOffer: models.EventOffer) => {
    return {
        type: types.ADD_EVENT_OFFER_SUCCESS,
        eventOffer: eventOffer,
    }
}

export const addEventOfferFailure = (error: string) => {
    return {
        type: types.ADD_EVENT_OFFER_FAILURE,
        eventOffer: null,
        eventOffers: null,
        error: error
    }
}

export const editEventOffer = () => {
    return {
        type: types.EDIT_EVENT_OFFER,
    }
}

export const cancelEventOfferSuccess = (eventOffer: models.EventOffer) => {
    return {
        type: types.CANCEL_EVENT_OFFER_SUCCESS,
        eventOffer: eventOffer
    }
}

export const editEventOfferSuccess = (eventOffer: models.EventOffer) => {
    return {
        type: types.EDIT_EVENT_OFFER_SUCCESS,
        eventOffer: eventOffer
    }
}

export const editEventOfferFailure = (error: string) => {
    return {
        type: types.EDIT_EVENT_OFFER_FAILURE,
        eventOffer: null,
        eventOffers: null,
        error: error
    }
}

export const fetchEventOffers = () => {
    return {
        type: types.FETCH_EVENT_OFFERS,
    }
}

export const fetchEventOffersFailure = (error: string) => {
    return {
        type: types.FETCH_EVENT_OFFERS_FAILURE,
        eventOffer: null,
        eventOffers: [],
        error: error
    }
}

export const fetchEventOffersSuccess = (eventOffers: models.EventOffers) => {
    return {
        type: types.FETCH_EVENT_OFFERS_SUCCESS,
        eventOffer: null,
        eventOffers: eventOffers
    }
}


export const showEventOffer = () => {
    return {
        type: types.SHOW_EVENT_OFFER,
    }
}
export const showEventOfferSuccess = (eventOffer: models.EventOffer) => {
    return {
        type: types.SHOW_EVENT_OFFER_SUCCESS,
        eventOffer: eventOffer
    }
}
export const showEventOfferFailure = (error: string) => {
    return {
        type: types.SHOW_EVENT_OFFER_FAILURE,
        eventOffer: null,
        eventOffers: null,
        error: error
    }
}