import React from "react";

export interface initialStateInterface {
    show: boolean,
    value: string,
    target: string
}

export const initialState: initialStateInterface = {
    show: false,
    value: '',
    target: ''
};