import React, {useEffect, useState} from "react";
import format from "../../../../utils/locale";
import * as api from "../../../../adapters/timeClock";
import * as accessRight from "../../../../constants/accessRight";
import SmallLoader from "../../../../utils/loader/small";
import {Granted} from "../../../../security/granted";
import {Company} from "../../../../models/companies";
import {Salary} from "../../../../models/salary";
import * as fileApi from "../../../../adapters/timeClock/file";
import Loader from "../../../../utils/loader";

interface Interface {
    handleEventChange: (ev: any) => any
    close: () => any,
    setCalendarSignedSidebarProps: React.Dispatch<any>,
    timeClockId: number,
    salary: Salary,
    start: Date|null,
    _start?: string,
    end: Date|null,
    _end?: string,
    company: Company;
    isAnomaly: boolean;
    files?: any[]
}

const SignedShow:React.FC<Interface> = (props) => {

    const {handleEventChange, close, setCalendarSignedSidebarProps, salary, company, end, _end, timeClockId, start, _start, isAnomaly, files} = props;

    const [removing, setRemoving] = useState<boolean>(false)

    const removeEvent = () => {
        setRemoving(true)
        api.remove(timeClockId).then((resp) => removeEventSuccess(resp.data));
    }

    const [_files, setFiles] = useState<{id: number, loaded: boolean, url: string}[]>(files?.map(f => ({id: f.id, loaded: false, url: ""})) || []);

    useEffect(() => {
        _files.map(f => addImage(f.id))
    }, [])

    async function addImage(id: number)
    {
        let url: string = "";
        await fileApi.download(timeClockId, id).then(data => {
            let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
            url = URL.createObjectURL(newBlob);

            let index = _files.findIndex(f => f.id === id)

            setFiles(prev => {
                prev[index] = {id: prev[index].id, loaded: true, url: url}

                return [...prev];
            })
        })
    }

    const removeEventSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => close())
            .then(() => setRemoving(false))
            .then(() => true)
    }

    return <>
        {<>
            <div className="offcanvas-header">
            <h5 id="offcanvasCalendarSignedShowLabel">Badgeage de {salary.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
            <div className="offcanvas-body">
            <h4>
                Détails du badgeage
            </h4>
            <div className="p-1 p-md-3">
                <div className="table w-100">
                    <tbody>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch"}></i> Début
                        </td>
                        <td>
                            {start ? format(start, "E dd MMMM uuuu HH:mm") : <i className={"bi bi-x"}></i>}
                            {_start && Granted(accessRight.EDIT_TIME_CLOCK, salary.id) && <div className={'form-text'}>Avant bornage : {format(new Date(_start), "E dd MMMM uuuu HH:mm")}</div>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch-fill"}></i> Fin
                        </td>
                        <td>
                            {end ? format(end, "E dd MMMM uuuu HH:mm") : <i className={"bi bi-x"}></i>}
                            {_end && Granted(accessRight.EDIT_TIME_CLOCK, salary.id) && <div className={'form-text'}>Avant bornage : {format(new Date(_end), "E dd MMMM uuuu HH:mm")}</div>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-shop'}></i> Établissement
                        </td>
                        <td>
                            {company?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    {_files.length > 0 && <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-camera'}></i> Photos
                        </td>
                        <td>
                            <div className="row">
                                {_files.map(f => {
                                    if(!f.loaded){
                                       return <div className={"col position-relative"}>
                                            <SmallLoader />
                                        </div>
                                    }else{
                                        return <div className={"col"}><img src={f.url} alt="" className={'w-100'}/></div>
                                    }
                                })}
                            </div>
                        </td>
                    </tr>}
                    </tbody>
                </div>
            </div>
                {Granted(accessRight.EDIT_TIME_CLOCK, salary.id) && <>
                    {isAnomaly && <div className="col-12 mb-3">
                        {(!start || !end) && <div className={'alert alert-info'}>
                            <i className={'bi bi-info-circle'}></i> Veuillez renseigner un début et une fin pour retirer l'anomalie
                        </div>}
                    </div>}
                    <div className="row">
                    <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarSignedSidebarProps((prev: any) => ({
                                    sidebarType: "SIGNED_EDIT",
                                    trigger: prev.trigger + 1,
                                    company: company,
                                    end: end,
                                    start: start,
                                    isAnomaly: isAnomaly,
                                    salary: salary,
                                    timeClockId: timeClockId
                                }))}
                        >
                            Modifier <i className={"bi bi-pencil text-primary"}></i>
                        </button>
                    </div>
                    <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'} onClick={() => removeEvent()}>
                            {removing ? <SmallLoader/> : <>Supprimer <i
                                className={"bi bi-dash-circle text-danger"}></i></>}
                        </button>
                    </div>
                </div>
                </>}
        </div>
        </>}
    </>
}

export default SignedShow;