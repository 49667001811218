import {post, get} from "../../xhr";
import * as models from "../../../models/booking/group"


export function create(requestData: models.Payload){
    return post('booking/group/create',  requestData);
}

export function edit(id: number, requestData: models.Payload){
    return post('booking/group/edit/' + id,  requestData);
}

export function list(requestData: {salary: number, query?: string}){
    return post('booking/group/list',  requestData);
}

export function show(id: number){
    return get('booking/group/show/' + id);
}

export function cancel(id: number){
    return get('booking/group/cancel/' + id);
}