import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import EmptyList from "../../../components/common/EmptyList";

import * as models from "../../../models/report";

import { getMonthOption } from "../../../constants/global";

import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";

import LoaderList from "../../../utils/loaderList";
import format from "../../../utils/locale";

const ReportList:React.FC = () => {
    const dispatch = useDispatch();

    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.report);

    useEffect(() => {
        dispatch(setPageTitle("Rapports"))
        dispatch(setHelpProductSheetIds([15]))

        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [])

    return (
        <div id={"listContainer"} className={"col-12 col-md-3 col-lg-2 h-100 bg-white border-end overflow-auto top-0"}>
            <div className="col-12 btn-group bg-white position-sticky top-0 py-1 z10">
                <button className="btn btn-light" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasReportAdd" aria-controls="offcanvasReportAdd">
                    <i className={'bi bi-plus-circle'}></i> Ajouter
                </button>
            </div>

            {state.isLoading &&
                <LoaderList title={true} listLength={20} description={true} leftCircle={false} />
            }

            {!state.isLoading &&
                <>
                    {state.payload.length === 0 &&
                        <EmptyList />
                    }

                    {state.payload.length > 0 &&
                        <ul className={'list-group list-group-flush'}>
                            {state.payload.filter(r => r.activated).sort((a, b) => a.year !== b.year ? b.year - a.year : b.month - a.month).map((report, index) =>
                                <Link to={"/report/" + report.id} key={index} className={'list-group-item list-group-item-action ' + (state.single?.id === report.id ? "bg-primary text-white" : "")}>
                                    <div className="d-flex">
                                        <div className="flex-grow-1 d-flex flex-column">
                                            <div>
                                                {getMonthOption(String(report.month))?.label} {report.year}
                                            </div>
                                            <div>
                                                {report.closedAt ? <>Cloturé le {format(new Date(report.closedAt))}</> : <><i className={'bi bi-unlock'}></i> Ouvert</>}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </ul>
                    }
                </>
            }
        </div>
    )
}

export default ReportList;