import React from "react";
import * as models from "../../../../models/synthesis";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import timeFormat from "../../../../utils/timeFormat"
import * as actions from "../../../../actions/synthesis";
import {Link} from "react-router-dom";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";

const SynthesisAbsenceTable:React.FC = (props) => {

    const dispatch = useDispatch();
    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);
    
    return <table className="table sticky-table table-striped align-middle text-center">
            <colgroup>
                <col span={1} style={{backgroundColor: '#fff'}} />
                <col span={5} style={{backgroundColor: '#a3cfbb'}} />
                <col span={2} style={{backgroundColor: '#f1aeb5'}} />
                <col span={2} style={{backgroundColor: '#e9ecef'}} />
            </colgroup>
            <thead>
                <tr>
                    <th>
                        #
                    </th>
                    <th colSpan={5}>
                        Payées
                    </th>
                    <th colSpan={2}>
                        Non payées
                    </th>
                    <th colSpan={2}>
                        Indemnisées
                    </th>
                </tr>
                <tr>
                    <th>
                        Collaborateur
                    </th>
                    <th>
                        Congés payés (j)
                    </th>
                    <th>
                        Jours fériés (h)
                    </th>
                    <th>
                        Repos (h)
                    </th>
                    <th>
                        RTT (j)
                    </th>
                    <th>
                        Autres (j)
                    </th>

                    <th>
                        Congés sans solde (h)
                    </th>
                    <th>
                        Autres (h)
                    </th>

                    <th>
                        Arrêt maladie (j)
                    </th>
                    <th>
                        Autres (h)
                    </th>
                </tr>
            </thead>
            <tbody>
            {state.payload.map((s) => <tr key={s.id}>
                <td className={'d-flex align-items-center'}>
                    <Link target={"_blank"} to={`/salary/${s.salary.id}`} className={'text-primary mb-0 flex-grow-1'}>
                        <i className="bi bi-box-arrow-up-right"></i> {s.salary.title}
                    </Link>
                    <button title={"Détail par jour"} onMouseEnter={e => onMouseEnterTooltip(e.target)} className="btn bg-primary text-white flex-grow-0" data-bs-toggle="modal" data-bs-target="#synthesisSignature" onClick={() => dispatch(actions.showSynthesisSuccess(s))}>
                        <i className={'bi bi-list-ul'}></i>
                    </button>
                </td>
                <td>
                    {s.paidVacation || <i className={'bi bi-x'}></i>}
                </td>
                <td>
                    {timeFormat(s.labourDayNonWorkingTime)}
                </td>
                <td>
                    {timeFormat(s.recovery)}
                </td>
                <td>
                    {s.rtt || <i className={'bi bi-x'}></i>}
                </td>
                <td>
                    {s.paidElse || <i className={'bi bi-x'}></i>}
                </td>

                <td>
                    {timeFormat(s.unpaidVacation)}
                </td>
                <td>
                    {timeFormat(s.unpaidElse)}
                </td>

                <td>
                    {s.sickLeave || <i className={'bi bi-x'}></i>}
                </td>
                <td>
                    {timeFormat(s.compensatedElse)}
                </td>
            </tr>)}
            </tbody>
        </table>
}

export default SynthesisAbsenceTable;