import { Companies } from "../companies";
import { Salary } from "../salary";
import { AccessLevels } from "../accessLevel";

export interface CompanyGroup {
    id: number,
    type: 1|2|3|4|5|6|7|8|9,
    title: string,
    version: number,
    billingVersion: number|null,
    billingRate: number|null,
    timeClockSettings?: TimeClockSettings,
    absenceSettings?: AbsenceSettings,
    notifications?: Notifications,
    modules?: ModuleSettings,
    accountingSettings?: AccountingSettings,
    companies?: Companies,
    salaries?: Salary[],
    salariesCount?: number,
    createdAt?: string,
    activated?: boolean,
    sellsyId?: number,
}

export interface Notification {
    id: number,
    underscoreCase: string,
    population: AccessLevels,
    isManager: boolean,
    isEmail: boolean,
    isPushNotification: boolean,
    isSms: boolean,
    activated: boolean,
}

export type Notifications = Notification[]

export interface TimeClockSettings {
    beforeSchedule: boolean,
    afterSchedule: boolean,
    break: boolean,
    pointBreak: boolean,
    floatingBreak: boolean,
    lateAfter: number,
    advanceBefore: number,
    shouldTakePicture: boolean,
    pictureFrequency: number
}

export interface AbsenceSettings {
    countdown: number,
    acquisition: number,
    period: number,
    resetRTT: boolean,
    accountedType: number,
    accountedTime: number,
    decrementPeriod: number,
    returnDay: boolean
}

export interface GeneralSettings {
    lang: string,
    visibleUntil: number,
    timezone: string,
}

export interface AccountingSettings {
    absenceDisabledCounter: boolean,
    silaeExport: boolean,
    closingDay: number,
    labourDaysClosed: string[],
    labourDaysWorked: number[],
    regionalLabourDays: number[],
    accountedAbsenceType: string[],
    fields: string[],
    overtimeMarkUp: boolean,
}

export interface ModuleSettings {
    timeClock: boolean,
    payroll: boolean,
    task: boolean,
    slotExchange: boolean,
    label: boolean,
    accounting: boolean,
    bookingOffer: boolean,
    team: boolean,
    document: boolean,
    expense: boolean,
    smsNotifications: boolean,
}

export type CompanyGroups = CompanyGroup[];

export interface initialStateInterface {
    payload: CompanyGroups,
    current: CompanyGroup|null,
    current_id: string|null,
    isLoading: boolean,
    error: object|null
}

export const initialState: initialStateInterface = {
    payload: [],
    current: null,
    current_id: localStorage.getItem('current_group_id'),
    isLoading: false,
    error: null
};