import React, {useEffect, useState} from "react";
import {Salary} from "../../../../models/salary";
import * as api from "../../../../adapters/salary/template";
import {SalaryTemplate, SalaryTemplates} from "../../../../models/salary/template";
import format from "../../../../utils/locale";
import Loader from "../../../../utils/loader";
import SalaryTemplateAdd from "../add";

const SalaryTemplateList:React.FC<{salary: Salary, setTemplate: React.Dispatch<SalaryTemplate>, template?: SalaryTemplate, templates: SalaryTemplates, setTemplates: React.Dispatch<any>, date?: Date}> = (props) => {

    const {salary, setTemplate, template, templates, setTemplates, date} = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.list(salary.id).then(resp => {
            setTemplates(resp.data);
            if (date){
                let _template = resp.data.find((t: SalaryTemplate) => new Date(t.start) <= date && (!t.end || date < new Date(t.end)))
                if (_template){
                    setTemplate(_template)
                }
            }
            setLoading(false)
        })
    }, [salary])

    const Toolbar = () => <div className={'card shadow-sm position-relative flex-grow-0 mb-3'}>
        <div className="btn-group">
            <button className={'btn btn-light'} data-bs-toggle="offcanvas" data-bs-target="#offcanvasSalaryTemplateAdd" aria-controls="offcanvasSalaryTemplateAdd" >
                <i className={'bi bi-plus'}></i> Ajouter
            </button>
        </div>
    </div>

    return <div className={"h-100 d-flex flex-column"}>
        <Toolbar />
        <div className={'card shadow-sm position-relative flex-grow-1'}>
            {loading && <Loader />}
            <ul className="list-group list-group-flush">
                {templates.map(t => <li className={'list-group-item list-group-item-action cursor-pointer' + (template?.id === t.id ? ' bg-primary text-white' : '')} onClick={() => setTemplate(t)}>
                    <h4>
                        {t.title}
                    </h4>
                    <p>
                        {format(new Date(t.start), 'dd/MM/uuuu')} <i className={'bi bi-arrow-left-right'}></i> {t.end ? <>{format(new Date(t.end), 'dd/MM/uuuu')}</> : <i className={'bi bi-infinity'}></i>}
                    </p>
                </li>)}
            </ul>
        </div>
        <SalaryTemplateAdd salary={salary} templates={templates} setTemplates={setTemplates} />
    </div>
}

export default SalaryTemplateList;