export const MERCURE_PUBLIC_URL = "https://hub.sivan.io:3000/.well-known/mercure";
export const API_DEV_URl = "http://127.0.0.1:8000/api/"
export const API_DEV_LOCAL_URl = "http://192.168.1.174:8000/api/"
export const API_PROD_URl = "https://api.sivan.io/api/"
export const CHANNEL_SUBSCRIBER = "https://api.sivan.io/api/channel/listen/";
export const JWT = "eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdLCJzdWJzY3JpYmUiOlsiKiJdfX0.pm8JV4wLrO1hs-aJtu6LhZfbbOb6Nqk8xIgw7kr4K6I"
export const PAGINATION = 20;


export const CURRENCY_EUR = 1;

export const CURRENCY_NAMES: any = {
    1: 'EUR (€)'
}

export const getCurrencyChoices = () => {
    return [
        {value: CURRENCY_EUR, label: CURRENCY_NAMES[CURRENCY_EUR]}
    ]
}
export const getCurrencyChoice = (currency: number) => {
    return getCurrencyChoices().find(c => c.value === currency)
}

export const CurrencyIcon = (currency: number) => {
    switch (currency){
        case CURRENCY_EUR:
            return <i className={'bi bi-currency-euro'}></i>
    }

    return <></>
}

export const getDayOptions = () => [
        {label: 'Lundi', value: '1'},
        {label: 'Mardi', value: '2'},
        {label: 'Mercredi', value: '3'},
        {label: 'Jeudi', value: '4'},
        {label: 'Vendredi', value: '5'},
        {label: 'Samedi', value: '6'},
        {label: 'Dimanche', value: '7'},
    ]

export const getTemplateDayOptions = () => [
        {label: 'Lun.', value: '1', dt: new Date('2021-10-04 00:00:00')},
        {label: 'Mar.', value: '2', dt: new Date('2021-10-05 00:00:00')},
        {label: 'Mer.', value: '3', dt: new Date('2021-10-06 00:00:00')},
        {label: 'Jeu.', value: '4', dt: new Date('2021-10-07 00:00:00')},
        {label: 'Ven.', value: '5', dt: new Date('2021-10-08 00:00:00')},
        {label: 'Sam.', value: '6', dt: new Date('2021-10-09 00:00:00')},
        {label: 'Dim.', value: '0', dt: new Date('2021-10-10 00:00:00')},
    ]

export const getDayOption = (d: string) => getDayOptions().find(o => o.value === d);
export const getTemplateDayOption = (d: string) => getTemplateDayOptions().find(o => o.value === d);

export const getMonthOptions = () => [
        {label: 'Janvier', value: '1'},
        {label: 'Fevrier', value: '2'},
        {label: 'Mars', value: '3'},
        {label: 'Avril', value: '4'},
        {label: 'Mai', value: '5'},
        {label: 'Juin', value: '6'},
        {label: 'Juillet', value: '7'},
        {label: 'Aout', value: '8'},
        {label: 'Septembre', value: '9'},
        {label: 'Octobre', value: '10'},
        {label: 'Novembre', value: '11'},
        {label: 'Décembre', value: '12'},
    ]

export const getMonthOption = (d: string) => getMonthOptions().find(o => o.value === d);

export function getISOWeeks(y: number) {
    let d,
        isLeap;

    d = new Date(y, 0, 1);
    isLeap = new Date(y, 1, 29).getMonth() === 1;

    return d.getDay() === 4 || isLeap && d.getDay() === 3 ? 53 : 52
}


export const range = (start: number, end: number) => {
    let ret = []
    for (let i = start; i <= end; i++){
        ret.push(i)
    }
    return ret;
}