import React, {useEffect, useState} from "react";
import {DateSelectArg, EventClickArg} from "@fullcalendar/react";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Offcanvas} from "bootstrap";
import {Timetable, TimetableEvents} from "../../../../models/timetable";
import TimetableEventSelect from "../add/select";
import TimetableEventEdit from "../edit";
import TimetableEventShow from "../show";
import TimetableCopyWeek from "../copy";

interface InterfaceEventShow {
    sidebarType: 'EVENT_SHOW',
    setCalendarEventSidebarProps: React.Dispatch<TimetableEventSidebarInterface>,
    arg: EventClickArg,
}

interface InterfaceEventEdit {
    sidebarType: 'EVENT_EDIT',
    arg: EventClickArg,
}

interface InterfaceEventSelect {
    sidebarType: 'EVENT_SELECT',
    arg: DateSelectArg,
}

interface InterfaceEventCopy {
    sidebarType: 'EVENT_COPY',
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type TimetableEventSidebarInterface = {trigger: number} & (Interface | InterfaceEventEdit | InterfaceEventSelect | InterfaceEventShow | InterfaceEventCopy)

const TimetableEventSidebar:React.FC<TimetableEventSidebarInterface & {salary: Salary, company: Company, week: number, timetable: Timetable, events: TimetableEvents, setEvents: React.Dispatch<TimetableEvents>}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()

    useEffect(() => {
        const el = document.getElementById('offcanvasTimetableEvent')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        let elem = document.getElementById('offcanvasTimetableEvent')!
        offcanvas.show(elem)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "EVENT_EDIT":
                    return <TimetableEventEdit timetable={props.timetable} salary={props.salary} week={props.week} handleSubmitSuccess={handleEventEdit} events={props.events} arg={props.arg} />
                case "EVENT_SELECT":
                    return <TimetableEventSelect salary={props.salary}  arg={props.arg} timetable={props.timetable} events={props.events} week={props.week} company={props.company} handleSubmitSuccess={handleEventAdd} />
                case "EVENT_SHOW":
                    return <TimetableEventShow  setEvents={props.setEvents} close={close} arg={props.arg} salary={props.salary} setCalendarEventSidebarProps={props.setCalendarEventSidebarProps} timetable={props.timetable} week={props.week} company={props.company} />
                case "EVENT_COPY":
                    return <TimetableCopyWeek salary={props.salary}  timetable={props.timetable} close={close} week={props.week} company={props.company} setEvents={props.setEvents} key={props.week} />
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTimetableEvent" aria-labelledby="offcanvasTimetableEventLabel">
        <Content />
    </div>

    function handleEventAdd(ev: any){
        props.setEvents([...props.events, ev])
        close();
    }

    function handleEventEdit(ev: any){
        props.setEvents([...props.events.filter(e => e.id !== ev.id), ev])
        close();
    }
}

export default TimetableEventSidebar;