import {post, get} from "../xhr";
import * as models from "../../models/task";

interface listPayload {
    query?: string,
    offset?: number
}
export function list(requestData: listPayload, taskGroupId: string){
    return post('task/list/' + taskGroupId, requestData || {});
}

export function edit(requestData: models.Task, id: number){
    return post('task/edit/' + id, requestData);
}

export function create(requestData: models.PostTask){
    return post('task/create',  requestData);
}

export function createException(requestData: models.PostTask, taskId: number){
    return post(`task/exception/create/${taskId}`, requestData);
}

export function editException(requestData: models.PostTask, taskId: number){
    return post(`task/exception/edit/${taskId}`,  requestData);
}

export function show(id: string){
    return get('task/show/' + id);
}

export function cancel(id: string){
    return get('task/cancel/' + id);
}

export function cancelOnce(id: string, exDate: string){
    return post( `task/exception/cancel_once/${id}`, {exDate: exDate});
}
