import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Field, FieldProps, Form, Formik} from "formik";
import * as actions from "../../../actions/template";
import * as api from "../../../adapters/template";
import {showAlertSuccess} from "../../../actions/alert";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import * as Yup from "yup";
import {Company} from "../../../models/companies";
import FormError from "../../../utils/formError";
import validationClass from "../../../utils/validationClass";
import {SingleCompanySelected, SingleCompanySelector} from "../../../utils/companySelector/single";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {axiosError} from "../../../actions/axios";

const TemplateAdd:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const [displayCompanySelector, setDisplayCompanySelector] = useState(false);

    const initialSchema = Yup.object().shape({
        title: Yup.string().required('Veuillez précier un titre'),
        description: Yup.string().nullable(),
        company: Yup.number().required('Veuillez choisir un point de vente'),
        visibility: Yup.string().required('Visibilité requise')
    })

    const initialValues = {
        title: '',
        description: null,
        company: undefined,
        visibility: 1
    }

    return (
        <div className='w-100'>
            <Formik
                initialValues={initialValues}
                validationSchema={initialSchema}
                onSubmit={values => {
                    // same shape as initial values
                    dispatch(submitSidebar());
                    api.create(values).then(data => {
                        dispatch(actions.addTemplateSuccess(data.data))
                        dispatch(showAlertSuccess(data.data.title + ' à bien été ajouter !'))
                        dispatch(closeSidebar());
                    }).catch(error => dispatch(axiosError(error)))
                }}
            >
                {({ values, setValues, errors, touched }) => (
                    <Form className="p-3">
                        <div className="col-12 mb-3">
                            <div className="form-floating">
                                <Field
                                    placeholder={' '}
                                    className={'form-control' + validationClass(errors, touched, 'title')}
                                    name="title"/>
                                <label className="form-label" htmlFor="title">
                                    Titre <span className="required">*</span>
                                </label>
                            </div>
                            <FormError errors={errors} touched={touched} field={'title'} />
                        </div>

                        <div className="col-12 mb-3">
                            <div className="form-floating">
                                <Field
                                    placeholder={' '}
                                    className={'form-control' + validationClass(errors, touched, 'description')}
                                    name="description"/>
                                <label className="form-label" htmlFor="title">
                                    Description
                                </label>
                            </div>
                            <FormError errors={errors} touched={touched} field={'description'} />
                        </div>

                        <div className="col-12 mb-3">
                            <Field name={'company'}>
                                {({
                                      field, // { name, value, onChange, onBlur }
                                      form: { touched, errors, setFieldValue, values, isValid }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                      meta,
                                  }: FieldProps) => (
                                    <>
                                      <BootstrapSelect
                                          fetchEntity={"company"}
                                          onChange={(choice) => setFieldValue('company', choice?.value)}
                                          label={'Point de vente'}
                                          required={true}
                                      />
                                    </>
                                )}
                            </Field>
                        </div>
                        <div className="col-12 mb-3">
                            <label className="form-label" htmlFor="visibility">
                                Visibilité <span className="required">*</span>
                            </label>
                            <div className="form-check mb-2">
                                <Field className='form-check-input' defaultChecked={true} value={'1'} type={'radio'} name="visibility"/>
                                <label htmlFor="">
                                    Privée
                                </label>
                            </div>
                            <div className="form-check mb-2">
                                <Field className='form-check-input' value={'2'} type={'radio'} name="visibility"/>
                                <label htmlFor="">
                                    Publique
                                </label>
                            </div>
                            <FormError errors={errors} touched={touched} field={'visibility'} />
                        </div>
                        <button className='btn btn-outline-primary w-100' type="submit">
                            <i className={'bi bi-plus-circle'}> </i>
                            Ajouter
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default TemplateAdd;