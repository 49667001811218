import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import { ResponsiveBar } from '@nivo/bar'
import {Counter, CounterWithdrawn} from "../../../../models/counter";
import {openSidebar} from "../../../../actions/rightSidebar";
import {FORM_COUNTER_SHOW, FORM_COUNTER_WITHDRAWN_EDIT} from "../../../../constants/rightSidebar";
import format from "../../../../utils/locale";

const CounterSingleChart:React.FC = () => {

    const dispatch = useDispatch();
    const single = useSelector((state: RootStateOrAny) => state.counter.single)
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const [barContainerWidth, setBarContainerWidth] = useState<number>(0)

    useEffect(() => {
        let container = document.getElementById('barContainer')!
        setBarContainerWidth(container.clientWidth);
    }, [])

    if (!single) return <></>

    return <>
        {<div className="col-12 h-100">
            <div className="h-100 w-100 overflow-auto" id={'barContainer'}>
                <div className="h-100" style={{width: barContainerWidth > single.counters?.length * 30 ? barContainerWidth : single.counters?.length * 30}}>
                    <ResponsiveBar
                        data={[...single.countersWithdrawn.map((c: CounterWithdrawn) => ({
                            id: c.id,
                            value: c.value,
                            salary: c.salary,
                            yearWeek: c.yearWeek,
                            withdrawnDecimal: c.decimal,
                            withdrawnTime: c.time,
                            year: c.year,
                            weekNumber: c.weekNumber,
                            description: c.description,
                            weekStart: c.weekStart,
                            weekEnd: c.weekEnd,
                        })),
                            ...single.counters.map((c: Counter) => ({
                                id: c.id,
                                value: c.value,
                                salary: single.salary,
                                yearWeek: c.yearWeek,
                                markUpDecimal: c.markUpDecimal,
                                markUpTime: c.markUpTime,
                                decimal: c.decimal,
                                time: c.time,
                                year: c.year,
                                weekNumber: c.weekNumber,
                                weekStart: c.weekStart,
                                weekEnd: c.weekEnd,
                            }))].sort((a, b) => {
                            if (a.year !== b.year) return a.year - b.year;

                            return a.weekNumber - b.weekNumber;
                        })}
                        enableGridY={true}
                        enableGridX={true}
                        onClick={(data) => {
                            switch (data.id)
                            {
                                case "withdrawnDecimal":
                                    dispatch(openSidebar(FORM_COUNTER_WITHDRAWN_EDIT, {...data.data}))
                                    break;
                                case "markUpDecimal":
                                case "decimal":
                                    dispatch(openSidebar(FORM_COUNTER_SHOW, {...data.data}))
                                    break;
                            }
                        }}
                        groupMode={"stacked"}
                        keys={[auth.user.currentSalary.companyGroup.accountingSettings.overtimeMarkUp ? 'markUpDecimal' : 'decimal', 'withdrawnDecimal']}
                        indexBy={'yearWeek'}
                        margin={{top: 60, right: 15, bottom: 15, left: 35}}
                        padding={0.1}
                        innerPadding={0}
                        valueScale={{type: 'linear'}}
                        indexScale={{type: 'band', round: true}}
                        colors={['#727cf5', '#20c997']}
                        labelTextColor={'#fff'}
                        borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        axisBottom={null}
                        axisTop={{
                            tickRotation: 45,
                            legend: null,
                            ticksPosition: 'after',
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: null,
                        }}
                        labelSkipWidth={25}
                        labelSkipHeight={15}
                        tooltip={({id, value, color, data}) => {
                            switch (id) {
                                case "withdrawnDecimal":
                                    return <div className={'shadow'}
                                                style={{
                                                    borderRadius: 3,
                                                    padding: 7,
                                                    color,
                                                    background: '#fff',
                                                }}
                                    >
                                        <div className={'text-primary'}>Semaine {data.weekNumber} / Année {data.year}</div>
                                        <div className={'text-success'}>
                                            Retiré : {data.withdrawnTime}
                                        </div>
                                        <div className="form-text">
                                            {data.description}
                                        </div>
                                    </div>
                                case "markUpDecimal":
                                case "decimal":
                                    return <div className={'shadow'}
                                                style={{
                                                    borderRadius: 3,
                                                    padding: 7,
                                                    color,
                                                    background: '#fff',
                                                }}
                                    >
                                        <span className={'text-primary'}>Semaine {data.weekNumber} / Année {data.year}</span>
                                        <br/>
                                        <div className="text-primary">
                                            {format(new Date(data.weekStart))} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(data.weekEnd))}
                                        </div>
                                        <br/>
                                        <span className={'text-primary'}>
                                    Compteur : {data.time}
                                </span>
                                        {data.value > 0 && auth.user.currentSalary.companyGroup.accountingSettings.overtimeMarkUp && <>
                                            <div className="clearfix"> </div>
                                            <span className={'text-primary'}>
                                        Compteur majoré : {data.markUpTime}
                                    </span>
                                        </>}
                                    </div>
                                default:
                                    return <></>
                            }
                        }}
                        valueFormat={(decimal) => `${decimal}`}
                    />
                </div>
            </div>
        </div>}
        </>
}

export default CounterSingleChart;