/**
 * Base components for the SlateEditor component.
 */
import React from "react";
import ReactDOM from "react-dom";
import { useSlate } from "slate-react";
import {
  isBlockActive,
  toggleBlock,
  isMarkActive,
  toggleMark,
  isLinkActive,
  insertLink
} from "./helpers";

export const Button = React.forwardRef(
  (props: any, ref) => (
    <span
      {...props}
      ref={ref}
      className={props.className}
      style={{
          cursor: "pointer",
          color: props.reversed
          ? props.active
              ? "white"
              : "#aaa"
          : props.active
              ? "black"
              : "#ccc"}}
    />
  )
);

export const Icon = React.forwardRef((props: any, ref) => (
  <i
    {...props}
    ref={ref}
    className={"bi bi-" + props.icon}
    style={{
        fontSize: 18,
        verticalAlign: "text-bottom"
    }}
  />
));

export const Menu = React.forwardRef((props: any, ref) => (
  <div
    {...props}
    ref={ref}
    className={props.className}
  />
));

export const Portal = (props: any) => {
  return ReactDOM.createPortal(props.children, document.body);
};

export const Toolbar = React.forwardRef((props: any, ref) => (
  <Menu
    {...props}
    ref={ref}
    className={props.className}
    style={{
        position: "sticky",
        padding: 10,
        borderBottom: '2px solid #eee',
        top: 0,
        backgroundColor: "white",
        zIndex: 10
    }}
  />
));

export const BlockButton = (props: any) => {
  const editor = useSlate();
  return (
    <Button
        className={'me-2'}
        active={isBlockActive(editor, props.format)}
        onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, props.format);
      }}
    >
      <Icon {...props} />
    </Button>
  );
};

export const MarkButton = (props: any) => {
  const editor = useSlate();
  return (
    <Button
      className={'me-2'}
      active={isMarkActive(editor, props.format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, props.format);
      }}
    >
      <Icon {...props} />
    </Button>
  );
};

export const LinkButton = (props: any) => {
  const editor = useSlate();
  return (
    <Button
        className={'me-2'}
      active={isLinkActive(editor)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link:");
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <Icon {...props}> </Icon>
    </Button>
  );
};
