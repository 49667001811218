import {get} from "../../../xhr";

export function apply(offerId: number){
    return get(`booking/offer/${offerId}/applicant/apply`);
}

export function cancel(offerId: number, applicantId: number){
    return get(`booking/offer/${offerId}/applicant/${applicantId}/cancel`);
}

export function validate(offerId: number, applicantId: number){
    return get(`booking/offer/${offerId}/applicant/${applicantId}/validate`);
}

export function invalidate(offerId: number, applicantId: number){
    return get(`booking/offer/${offerId}/applicant/${applicantId}/invalidate`);
}