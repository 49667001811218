import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/keepNote";
import * as action from "../../../actions/keepNote";
import format from "../../../utils/locale";
import {openSidebar} from "../../../actions/rightSidebar";
import {
    FORM_KEEP_NOTE_ADD,
    FORM_KEEP_NOTE_LIST,
} from "../../../constants/rightSidebar";
import {axiosError} from "../../../actions/axios";
import * as accessRights from "../../../constants/accessRight";
import {Granted} from "../../../security/granted";


const DashboardKeepNote:React.FC = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state.keepNote)
    let today = new Date();
    let end = new Date(today.setDate(today.getDate() + 7))

    useEffect(() => {
        const controller = new AbortController();

        dispatch(action.fetchKeepNotes());
        api.list({start: format(new Date(), 'uuu-MM-dd'), end: format(end, 'uuu-MM-dd')}, controller.signal)
            .then(data => dispatch(action.fetchKeepNotesSuccess(data.data)))
            .catch(error => dispatch(axiosError(error)))

        return () => controller.abort();
    }, [])

    return <>
        <div className="shadow-sm btn-group w-100 bg-white">
            <div className="card p-3 flex-grow-1 cursor-pointer" onClick={() => dispatch(openSidebar(FORM_KEEP_NOTE_LIST))}>
                <div className="d-flex align-items-center">
                    <div className="col">
                        <span className={'h4 text-primary'}>
                            <i className={'bi bi-journal-bookmark'}> </i>
                            Notes
                        </span>
                        <div className="clearfix"> </div>
                        <span className="form-text">
                        +7 jours
                    </span>
                    </div>
                    <div className="col-auto align-middle align-items-center">
                        {state.isLoading ? <div className="spinner-border align-middle text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <span className={'text-primary h2'}>{state.payload.length}</span>}
                    </div>
                </div>
            </div>
            {Granted(accessRights.EDIT_KEEP_NOTE) && <button className="flex-grow-0 btn btn-outline-primary" onClick={() => dispatch(openSidebar(FORM_KEEP_NOTE_ADD, {dueDate: new Date()}))}>
                <i className={'bi bi-plus fs-4'}></i>
            </button>}
        </div>
    </>
}

export default DashboardKeepNote;