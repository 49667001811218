import {Tasks} from "../task";
import {TaskGroupCustomFields} from "./customField";

export interface TaskGroup {
    id?: number,
    title: string,
    description: string|null,
    color?: string,
    tasks?: Tasks,
    customFields?: TaskGroupCustomFields
}

export type TaskGroups = TaskGroup[];


export interface initialStateInterface {
    payload: TaskGroups,
    single: TaskGroup|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};