export const ADD_TIME_CLOCK = 'ADD_TIME_CLOCK';
export const ADD_TIME_CLOCK_SUCCESS = 'ADD_TIME_CLOCK_SUCCESS';
export const ADD_TIME_CLOCK_FAILURE = 'ADD_TIME_CLOCK_FAILURE';

export const ADD_TIME_CLOCK_INFORMATION = 'ADD_TIME_CLOCK_INFORMATION';
export const ADD_TIME_CLOCK_INFORMATION_SUCCESS = 'ADD_TIME_CLOCK_INFORMATION_SUCCESS';
export const ADD_TIME_CLOCK_INFORMATION_FAILURE = 'ADD_TIME_CLOCK_INFORMATION_FAILURE';

export const EDIT_TIME_CLOCK = 'EDIT_TIME_CLOCK';
export const EDIT_TIME_CLOCK_SUCCESS = 'EDIT_TIME_CLOCK_SUCCESS';
export const EDIT_TIME_CLOCK_FAILURE = 'EDIT_TIME_CLOCK_FAILURE';

export const FETCH_TIME_CLOCKS = 'FETCH_TIME_CLOCKS';
export const FETCH_TIME_CLOCKS_SUCCESS = 'FETCH_TIME_CLOCKS_SUCCESS';
export const FETCH_TIME_CLOCKS_FAILURE = 'FETCH_TIME_CLOCKS_FAILURE';

export const SHOW_TIME_CLOCK = 'SHOW_TIME_CLOCK';
export const SHOW_TIME_CLOCK_SUCCESS = 'SHOW_TIME_CLOCK_SUCCESS';
export const SHOW_TIME_CLOCK_FAILURE = 'SHOW_TIME_CLOCK_FAILURE';

export const REFRESH_TIME_CLOCK = 'REFRESH_TIME_CLOCK';

export const REMOVE_TIME_CLOCK_SUCCESS = 'REMOVE_TIME_CLOCK_SUCCESS';

export const TYPE_TIME_CLOCK = "timeClock"
export const TYPE_TIME_CLOCK_EXCEPTION = "timeClockException"