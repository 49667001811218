import * as types from "../../constants/reporting";
import * as models from "../../models/reporting";

export const fetchReporting = () => {
    return {
        type: types.FETCH_REPORTING
    }
}

export const fetchReportingFailure = (error: string) => {
    return {
        type: types.FETCH_REPORTING_FAILURE,
        error: error
    }
}

export const fetchReportingSuccess = (reporting: models.PeriodsReporting, singleReporting?: models.PeriodReporting) => {
    return {
        type: types.FETCH_REPORTING_SUCCESS,
        payload: reporting,
        single: singleReporting
    }
}


export const showReporting = () => {
    return {
        type: types.SHOW_REPORTING
    }
}

export const showReportingFailure = (error: string) => {
    return {
        type: types.SHOW_REPORTING_FAILURE,
        error: error
    }
}

export const showReportingSuccess = (reporting: models.PeriodReporting) => {
    return {
        type: types.SHOW_REPORTING_SUCCESS,
        single: reporting,
    }
}

export const refreshSingleReporting = (reporting: models.PeriodReporting) => {
    return {
        type: types.REFRESH_SINGLE,
        single: reporting,
    }
}
