import * as React from "react";

import LoginForm from "../../../components/auth/login";

const LoginPage : React.FC = () => {
    return (
        <div className="container-fluid text-center">
            <LoginForm />
        </div>
    );
}

export default LoginPage;