import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {EventClickArg} from "@fullcalendar/react";
import {closeSidebar} from "../../../actions/rightSidebar";
import * as API from "../../../adapters/booking";
import format from "../../../utils/locale";
import {hideEventPop, refresh, refreshResource} from "../../../actions/calendar";
import {showAlertSuccess} from "../../../actions/alert";
import {calendarInitialStateInterface} from "../../../models/calendar";

const BookingCancel:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)

    const [calendar, setCalendar] = useState<calendarInitialStateInterface>(state.calendar)
    const [e, setEvent] = useState<EventClickArg>(state.calendar.eventClickArg)

    const [cancelType, setCancelType] = useState<1|2|3>(1);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(hideEventPop())
    }, [])

    const cancelBooking = () => {

        dispatch(closeSidebar());

        switch (cancelType){
            case 1:
                API.cancelOnce(e.event.extendedProps.bookingInstanceId, e.event.extendedProps.instanceToken).then(() => cancelValidation())
                break;
            case 3:
                API.cancelAfter(e.event.extendedProps.bookingInstanceId, format(e.event.start!, 'uuuu-MM-dd')).then(() => cancelValidation())
                break;
            case 2:
                API.cancel(e.event.extendedProps.bookingInstanceId).then(() => cancelValidation())
                break;
        }
    }

    const cancelValidation = () => {
        if (calendar.resourceId && calendar.parentId && e.event.extendedProps.salary){
            dispatch(refreshResource(calendar.resourceId, calendar.parentId, e.event.extendedProps.salary));
        }else{
            dispatch(refresh());
        }
        dispatch(showAlertSuccess('Créneau supprimé'))
        dispatch(closeSidebar())
    }

    return <>
        <div className="w-100 p-3">
            <div className="col-12 text-center mb-2">
                Êtes vous sûr de vouloir supprimer le créneau suivant ?
            </div>
            <div className="clearfix"> </div>
            <div className="card p-2 mb-2">
                <h4>
                    <i className={"bi bi-person"}></i> {e.event.extendedProps.salary.title}
                </h4>
                <div className="col-12 mb-2">
                    <div className={"col"}><i className={'bi bi-calendar'}></i> {format(e.event.start!, "E dd MMMM uuuu HH:mm")} - {format(e.event.end!, "HH:mm")}</div>
                    <div className="col">
                       <i className={"bi bi-hourglass"}></i> {e.event.extendedProps.timeCaption}
                    </div>
                </div>
            </div>
            {e.event.extendedProps.recurrence.type !== 1 && <>
                <div className="col-12 mb-2">
                    <p className={'text-primary'}>
                        <i className="bi bi-arrow-repeat"> </i>
                        Créneau récurrent
                        <span className="clearfix"> </span>
                        <span>{e.event.extendedProps.recurrence.description}</span>
                    </p>

                    <ul className={'list-unstyled align-middle'}>
                        <li className={'mb-1'}>
                            <button className={'btn w-100 ' + (cancelType === 1 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {
                                e.stopPropagation();
                                setCancelType(1)
                            }}>
                                {cancelType === 1 && <i className={'bi bi-check'}> </i>}   Une seule fois
                            </button>
                        </li>
                        <li className={'mb-1'}>
                            <button className={'btn w-100 ' + (cancelType === 3 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {
                                e.stopPropagation();
                                setCancelType(3)
                            }}>
                                {cancelType === 3 && <i className={'bi bi-check'}> </i>} Cette occurence et toutes les suivantes
                            </button>
                        </li>
                        {/*<li className={'mb-1'}>*/}
                        {/*    <button className={'btn w-100 ' + (cancelType === 3 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {*/}
                        {/*        e.stopPropagation();*/}
                        {/*        setCancelType(3)*/}
                        {/*    }}>*/}
                        {/*        {cancelType === 3 && <i className={'bi bi-check'}> </i>} Cette occurence et toutes les suivantes le {format(e.event.start!, "eeee")} seulement*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        <li className={'mb-1'}>
                            <button className={'btn w-100 ' + (cancelType === 2 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {
                                e.stopPropagation();
                                setCancelType(2)
                            }}>
                                {cancelType === 2 && <i className={'bi bi-check'}> </i>} Toutes les occurences
                            </button>
                        </li>
                        {/*<li className={'mb-1'}>*/}
                        {/*    <button className={'btn w-100 ' + (cancelType === 2 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {*/}
                        {/*        e.stopPropagation();*/}
                        {/*        setCancelType(2)*/}
                        {/*    }}>*/}
                        {/*        {cancelType === 2 && <i className={'bi bi-check'}> </i>} Toutes les occurences le {format(e.event.start!, "eeee")} seulement*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </>}
            {e.event.extendedProps.bookingGroupId && cancelType === 3 && <div className={'col-12 mb-2'}>
                <i className={'bi bi-exclamation-circle text-warning'}></i> Attention, ce créneau est rataché à l'emploi du temps <span className={'text-primary'}>{e.event.extendedProps.bookingGroupTitle}</span>,
                en retirant les occurences suivantes, les autres créneaux ratachés s'arreteront en même temps
            </div>}
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <button onClick={(e) => {
                            e.stopPropagation()
                            cancelBooking()
                        }} className={'btn w-100 btn-outline-danger w-100'}>
                            Valider
                        </button>
                    </div>
                    <div className="col-6">
                        <button type={'button'} onClick={(e) => {
                            e.stopPropagation()
                            dispatch(closeSidebar())
                        }} className={'btn w-100 btn-light w-100'}>
                            Annuler
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default BookingCancel;