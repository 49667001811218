import {MULTIPART_FORM_DATA, post, get} from "../../xhr";


export function add(requestData: FormData, expenseId: number){
    return post(`expense/${expenseId}/file/add`, requestData, {headers: [MULTIPART_FORM_DATA], responseType: "json"})
}

export function remove(expenseId: number, fileId: number){
    return get(`expense/${expenseId}/file/${fileId}/remove`)
}

export function download(expenseId: number, fileId: number){
    return get(`expense/${expenseId}/file/${fileId}/download`, {responseType: "arraybuffer"})
}