import {openSidebar} from "../../../../../actions/rightSidebar";
import {FORM_MANAGER_ADD, FORM_MANAGER_EDIT} from "../../../../../constants/rightSidebar";
import * as accessRights from "../../../../../constants/accessRight";
import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Manager, Salary} from "../../../../../models/salary";
import * as api from "../../../../../adapters/salary/manager";
import SmallLoader from "../../../../../utils/loader/small";
import {editSalarySuccess} from "../../../../../actions/salary";
import {Granted} from "../../../../../security/granted";

const ManagerShow:React.FC = () => {

    const dispatch = useDispatch();
    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single)
    const [removing, setRemoving] = useState<number>()

    const handleRemove = (m: Manager) => {
        setRemoving(m.id)
        api.remove(m.id)
            .then(resp => dispatch(editSalarySuccess(resp.data)))
            .then(() => setRemoving(undefined))
    }

    return <div className="col-12 mb-3">
        <div className="card shadow-sm h-100 p-1 p-md-3">
            <div className="d-flex align-items-center mb-3">
                <div className="flex-grow-1">
                    <h4>
                        Manageurs
                    </h4>
                </div>
                <div className="flex-grow-0">
                    <button className={'btn btn-light'} onClick={() => dispatch(openSidebar(FORM_MANAGER_ADD, {target: salary}))}>
                        <i className={'bi bi-plus-circle text-primary'}></i>
                    </button>
                </div>
            </div>

            <ul className="list-group list-group flush">
                {salary.managers.map(m => <li className={'list-group-item d-flex'}>
                    <div className="flex-grow-1">
                        <h4>
                            {m.manager.title}
                        </h4>
                    </div>
                    {Granted(accessRights.EDIT_SALARY, salary.id) && <div className="flex-grow-0">
                        <div className="btn-group">
                            <button className="btn btn-light me-2" onClick={() => dispatch(openSidebar(FORM_MANAGER_EDIT, m))}>
                                <i className={'bi bi-pencil text-primary'}></i>
                            </button>
                            <button className="btn btn-light" onClick={() => handleRemove(m)}>
                                {removing === m.id ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i></>}
                            </button>
                        </div>
                    </div>}
                </li>)}
            </ul>
        </div>
    </div>
}

export default ManagerShow;