import React, {useEffect, useState} from "react";
import {Salary} from "../../../models/salary";
import Timetable from "../../salary/template";
import {Modal} from "bootstrap";

export interface CalendarTimetableInterface {salary?: Salary, date?: Date}

const CalendarTimetable:React.FC<CalendarTimetableInterface & {handleEventChange: (e: any) => any}> = (props) => {    
    const [open, setOpen] = useState(false)

    useEffect(() => {
        let el = document.getElementById("timetableModal")!
        let modal = new Modal(el)!

        if (props.salary){
            handleOpening(modal)
        }

        el.addEventListener('hidden.bs.modal', handleClosing)

        return () => el.removeEventListener('hidden.bs.modal', handleClosing)
    }, [props.salary])

    function handleClosing(){
        if (props.salary){
            props.handleEventChange({salary: props.salary})
        }

        setOpen(false)
    }
    function handleOpening(modal: Modal){
        modal.show();
        setOpen(true)
    }

    return <div className="modal" id={'timetableModal'} tabIndex={-1}>
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Emploi du temps de {props.salary?.title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {open && props.salary && <Timetable salary={props.salary} date={props.date}/>}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
}

export default CalendarTimetable;