import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../adapters/timeClock";
import * as reportingApi from "../../../../adapters/reporting";
import DatePicker from "react-datepicker";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import FormError from "../../../../utils/formError";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {
    addTimeClockGeneratedSuccess,
    editTimeClockGeneratedSuccess
} from "../../../../actions/timeClock";
import {REPORTING_SHOW_WEEK, TIME_CLOCK_SHOW} from "../../../../constants/rightSidebar";
import {refreshSingleReporting} from "../../../../actions/reporting";
import BookingTable from "../../../booking/table";
import {Salary} from "../../../../models/salary";
import * as accessRightsTypes from "../../../../constants/accessRight";
import SalarySelector from "../../../salary/selector";


const TimeClockBookingAdd:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const data = state.rightSidebar.data;
    const [params, setParams] = useState<any>(data?.salary ? {salaries:[data.salary.id]} : {})

    const initialValues = {
        beginAt: data?.beginAt ? new Date(data.beginAt) : undefined,
        endAt: data?.endAt ? new Date(data.endAt) : undefined,
        salary: data?.salary?.id,
        company: data?.company?.id,
        description: ""
    };

    const initialSchema = Yup.object().shape({
        beginAt: Yup.date().required('Début requis !'),
        endAt: Yup
            .date()
            .nullable()
            .test("is-greater", "La date de fin doit être après le début", function(value: any) {
                const { beginAt } = this.parent;
                return (!value || (value && beginAt <= value))
            }),
        salary: Yup.number().required('Veuillez choisir un collaborateur'),
        company: Yup.number().required('Veuillez choisir un point de vente'),
        description: Yup.string().nullable()
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: initialSchema,
        onSubmit: (values) => {
            dispatch(submitSidebar());
            api.create(values).then(data => {

                if (state.rightSidebar.data?.id){
                    dispatch(editTimeClockGeneratedSuccess(state.rightSidebar.data.key, data.data))
                }else{
                    dispatch(addTimeClockGeneratedSuccess(data.data))
                }

                if (state.rightSidebar.data?.next){
                    switch (state.rightSidebar.data?.next){
                        case REPORTING_SHOW_WEEK:
                            reportingApi.fetch({
                                start: state.rightSidebar.data.start,
                                end: state.rightSidebar.data.end,
                                options: {
                                    salaries: [data.data.salary.id]
                                }
                            }).then(_data => {
                                dispatch(refreshSingleReporting(_data.data[0]))
                                dispatch(openSidebar(state.rightSidebar.data.next, state.rightSidebar.data.data))
                            })
                            break;
                        case TIME_CLOCK_SHOW:
                            dispatch(openSidebar(state.rightSidebar.data.next, {...data.data}))
                            break;
                        default:
                            dispatch(openSidebar(state.rightSidebar.data.next, state.rightSidebar.data.data))
                    }
                }else{
                    dispatch(closeSidebar())
                }
            })
        }
    })

    return <>
        <form className={'p-3'} onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="col-md-12 mb-3">
                    <div className="input-group">
                        <DatePicker
                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                            customInput={<DateCustomInput label={'Début'} icon={'text-primary bi bi-calendar-check'} />}
                            name={'beginAt'}
                            id={'beginAt'}
                            selected={formik.values.beginAt}
                            onChange={(date: Date) => {
                                formik.setFieldValue(`beginAt`, date)
                            }}
                            maxTime={formik.values.endAt}
                            selectsStart
                            showTimeInput={true}
                            timeIntervals={15}
                            timeCaption="Heure de début"
                            dateFormat="E dd MMM uuuu HH:mm"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                        />
                        <span className="input-group-text">
                                            <i className={'bi bi-arrow-left-right'}> </i>
                                        </span>
                        <DatePicker
                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'endAt')}
                            customInput={<DateCustomInput label={'Fin'} icon={'text-primary bi bi-calendar-check'} />}
                            name={'endAt'}
                            id={'endAt'}
                            selected={formik.values.endAt}
                            onChange={(date: Date) => {
                                formik.setFieldValue(`endAt`, date)
                            }}
                            selectsEnd
                            startDate={formik.values.beginAt}
                            endDate={formik.values.endAt}
                            minDate={formik.values.beginAt}
                            minTime={formik.values.beginAt}
                            showTimeInput={true}
                            timeIntervals={15}
                            timeCaption="Heure de fin"
                            dateFormat="E dd MMM uuuu HH:mm"
                            popperPlacement={"top-end"}
                            required={false}
                            isClearable={true}
                            showPopperArrow={false}
                        />
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'beginAt'} />
                    <FormError errors={formik.errors} touched={formik.touched} field={'endAt'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <SalarySelector
                    required={true}
                    fetchOptions={true}
                    value={data?.salary}
                    onChange={(s: Salary) => {
                        formik.setFieldValue('salary', s!.id)
                        setParams({salaries: [s.id]})
                    }}
                    accessRight={accessRightsTypes.EDIT_BOOKING}
                />
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    label={'Point de vente'}
                    enableReinitialize={true}
                    required={true}
                    labelIcon={'text-primary bi bi-shop'}
                    fetchEntity={'company'}
                    fetchParams={params}
                    className={validationClass(formik.errors, formik.touched, 'company')}
                    value={data?.company && data?.company.id === formik.values.company ? {label: data.company.title, value: data.company.id} : undefined}
                    onChange={(choice) => formik.setFieldValue('company', choice?.value)}
                />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <textarea className={'form-control'} name={'description'} onChange={formik.handleChange}> </textarea>
                    <label htmlFor="floatingTextarea">Description</label>
                </div>
            </div>
            <button disabled={formik.isSubmitting} className={'btn btn-outline-primary w-100'}>
                <i className={'bi bi-check'}> </i> Valider
            </button>
        </form>
        <BookingTable start={formik.values.beginAt || formik.values.endAt} end={formik.values.endAt || formik.values.beginAt} salary={formik.values.salary} />
    </>
}

export default TimeClockBookingAdd;