import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import * as api from "../../../adapters/job"
import * as actions from "../../../actions/job"
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EDIT_JOB} from "../../../constants/rightSidebar";
import {setPageTitle} from "../../../actions/header";
import {Job} from "../../../models/job";
import * as accessRights from "../../../constants/accessRight";
import {showJob} from "../../../actions/job";
import BgSingle from "../../../utils/background/single";
import BookingDefaultList from "../../booking/default/list";
import {Granted} from "../../../security/granted";

const JobShow:React.FC = () => {

    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const state = useSelector((state: RootStateOrAny) => state.job);
    const job: Job = useSelector((state: RootStateOrAny) => state.job.payload.find((a: Job) => a.id!.toString() === params.id))

    useEffect(() => {
        dispatch(showJob());
        if (job){
            dispatch(setPageTitle('Métiers', job.title))
            dispatch(actions.showJobSuccess(job))
        }else if (params.id){
            api.show(params.id).then(data => {
                dispatch(setPageTitle('Métiers', data.data.title))
                dispatch(actions.showJobSuccess(data.data))
            })
        }
    }, [params.id, job])

    if (!state.single || !params.id){
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!params.id ? " d-none d-md-block" : "")}>
        <div className="p-1 p-md-3">
            <div className="w-100 text-end d-block d-md-none">
                <Link to={"/job"} className={'btn btn-sm bg-white shadow-sm m-1'}>
                    <i className={'bi bi-chevron-left'}> </i> Retour
                </Link>
            </div>
            <div className="col-12 p-1 p-md-3 card shadow-sm mb-1 mb-md-3">
                <div className="d-flex">
                    <div className="flex-grow-1 pe-3">
                        <table className={"table table-borderless"}>
                            <tbody>
                            <tr>
                                <td className={'text-primary'}>
                                    Type
                                </td>
                                <td>
                                    {Number(state.single.type) === 1 ? "Front Office" : "Back Office"}
                                </td>
                            </tr>
                            <tr>
                                <td className={'text-primary'}>
                                    Couleur
                                </td>
                                <td style={{backgroundColor: state.single.color}}>

                                </td>
                            </tr>
                            <tr>
                                <td className={'text-primary'}>
                                    Effectif
                                </td>
                                <td>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex-grow-0">
                        {Granted(accessRights.EDIT_JOB) && <div className="btn-group">
                            <button className={'btn btn-light'}
                                    onClick={() => dispatch(openSidebar(FORM_EDIT_JOB, state.single))}>
                                <i className={'bi bi-pencil text-primary'}> </i> Modifier
                            </button>
                            {state.single.activated ? <button className={'btn btn-light'} onClick={() => deactivate()}>
                                <i className={'bi bi-dash-circle text-danger'}> </i> Désactiver
                            </button> : <button className={'btn btn-light'} onClick={() => activate()}>
                                <i className={'bi bi-check-circle text-success'}> </i> Activer
                            </button>}
                        </div>}
                    </div>
                </div>
            </div>
            <div className="col-12 p-1 p-md-3 card shadow-sm">
                <BookingDefaultList />
            </div>
        </div>
    </div>

    function deactivate(){
        api.deactivate(state.single.id)
            .then((resp) => dispatch(actions.editJobSuccess(resp.data)))
    }

    function activate(){
        api.activate(state.single.id)
            .then((resp) => dispatch(actions.editJobSuccess(resp.data)))
    }
}

export default JobShow;