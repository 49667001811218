import React, {useEffect, useState} from "react";
import CalendarKeepNoteEdit from "../edit";
import CalendarKeepNoteAdd from "../add";
import CalendarKeepNoteShow from "../show";
import {Offcanvas} from "bootstrap";
import {KeepNote} from "../../../../models/keepNote";
import format from "../../../../utils/locale";
import {EventClickArg} from "@fullcalendar/react";

interface InterfaceKeepNoteEdit {
    sidebarType: 'KEEP_NOTE_EDIT',
    keepNoteId: number,
    dueDate: Date,
    description: string
    isPrivate: boolean
}

interface InterfaceKeepNoteAdd {
    sidebarType: 'KEEP_NOTE_ADD',
    dueDate: Date,
}

interface InterfaceKeepNoteShow {
    sidebarType: 'KEEP_NOTE_SHOW',
    arg: EventClickArg,
    setCalendarKeepNoteSidebarProps: React.Dispatch<any>
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarKeepNoteSidebarInterface = {trigger: number} & (Interface | InterfaceKeepNoteEdit | InterfaceKeepNoteAdd | InterfaceKeepNoteShow)

const CalendarKeepNoteSidebar:React.FC<CalendarKeepNoteSidebarInterface & {setCalendarData: React.Dispatch<any>}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarKeepNote')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarKeepNote')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    function transformNote(keepNote: KeepNote)
    {
        return {
            title: keepNote.description,
            start: format(new Date(keepNote.dueDate), 'uuuu-MM-dd'),
            allDay: true,
            id: keepNote.id,
            private: keepNote.private,
            createdBy: keepNote.createdBy,
            createdAT: keepNote.createdAt,
            backgroundColor: "#fff",
            borderColor: "#000",
            textColor: "#000",
            eventType: "keepNote",
            resourceId: "keepNotes",
            className: 'fc-timeline-event-keepNote'
        };
    }

    function handleKeepNoteAdd(keepNote: any)
    {
        props.setCalendarData((prev: any) => {
            return {...prev, keepNotes: [...prev.keepNotes, transformNote(keepNote)]}
        })

        return true;
    }

    function handleKeepNoteRemove(arg: EventClickArg)
    {
        props.setCalendarData((prev: any) => {
            return {...prev, keepNotes: [...prev.keepNotes.filter((k: any) => k.id != arg.event.id)]}
        })

        return true;
    }

    function handleKeepNoteEdit(keepNote: any)
    {
        props.setCalendarData((prev: any) => {

            let index = prev.keepNotes.findIndex((k:any) => k.id == keepNote.id)

            if (index !== -1){
                prev.keepNotes[index] = transformNote(keepNote)
            }

            return {...prev}
        })

        return true;
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "KEEP_NOTE_EDIT":
                    return <CalendarKeepNoteEdit close={close} handleSubmitSuccess={handleKeepNoteEdit} keepNoteId={props.keepNoteId} dueDate={props.dueDate} isPrivate={props.isPrivate} description={props.description} />
                case "KEEP_NOTE_ADD":
                    return <CalendarKeepNoteAdd close={close} dueDate={props.dueDate} handleSubmitSuccess={handleKeepNoteAdd} />
                case "KEEP_NOTE_SHOW":
                    return <CalendarKeepNoteShow close={close} arg={props.arg} handleRemove={handleKeepNoteRemove} setCalendarKeepNoteSidebarProps={props.setCalendarKeepNoteSidebarProps} />
               }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarKeepNote" aria-labelledby="offcanvasCalendarKeepNoteLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: CalendarKeepNoteSidebarInterface & {setCalendarData: React.Dispatch<any>}, nextProps: CalendarKeepNoteSidebarInterface & {setCalendarData: React.Dispatch<any>}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarKeepNoteSidebar, areEqual);
