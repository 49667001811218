import React, {ChangeEvent, useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Field, FieldProps, Form, Formik} from "formik";
import * as jobActions from "../../../../actions/job";
import * as jobApi from "../../../../adapters/job";
import * as actions from "../../../../actions/template/resource";
import * as api from "../../../../adapters/template/resource";
import {showAlertSuccess} from "../../../../actions/alert";
import {closeSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import * as Yup from "yup";
import Select from "react-select";
import {Job} from "../../../../models/job";
import {Salary} from "../../../../models/salary";
import FormError from "../../../../utils/formError";
import {SingleSalarySelected, SingleSalarySelector} from "../../../../utils/salarySelector/single";
import validationClass from "../../../../utils/validationClass";
import * as calendarActions from "../../../../actions/template/calendar";
import {axiosError} from "../../../../actions/axios";

const TemplateResourceEdit:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const [displaySalarySelector, setDisplaySalarySelector] = useState(false)
    const [selectSalary, setSelectSalary] = useState(!!state.rightSidebar.data.salary);

    const [initialValues, setInitialValues] = useState<any>({
        salary: undefined,
    });
    const [initialSchema, setInitialSchema] = useState<any>(Yup.object().shape({
        salary: Yup.number().required('Veuillez choisir un collaborateur'),
    }));

    useEffect(() => {
            jobApi.list()
                .then(data => dispatch(jobActions.fetchJobsSuccess(data.data)))
                .catch(error => dispatch(axiosError(error)))
            ;
            if (state.rightSidebar.data.salary){
                setInitialValues({
                    salary: state.rightSidebar.data.salary.id,
                });
            }else{
                setInitialValues({
                    title: state.rightSidebar.data.title,
                    job: state.rightSidebar.data.job?.id,
                });
                setInitialSchema(Yup.object().shape({
                    title: Yup.string().required('Champs requis !'),
                    job: Yup.number().required('Veuillez choisir un métier'),
                }))
            }
    }, [])

    const handleSelectSalaryChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _selectSalary = !e.target.checked;
        if (_selectSalary){
            setInitialValues({
                salary: state.rightSidebar.data.salary?.id,
            })
            setInitialSchema(Yup.object().shape({
                salary: Yup.number().required('Veuillez choisir un collaborateur'),
            }))
        }else{
            setInitialValues({
                job: state.rightSidebar.data.job?.id,
                title: state.rightSidebar.data.title,
            })
            setInitialSchema(Yup.object().shape({
                job: Yup.number().required('Veuillez choisir un métier'),
                title: Yup.string().required('Veuillez préciser un titre'),
            }))
        }
    }

    return (
        <>
            <div className='w-100'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={initialSchema}
                    enableReinitialize={true}
                    onSubmit={values => {
                        dispatch(submitSidebar());
                        api.edit(values, state.rightSidebar.data.template.id, state.rightSidebar.data.id).then(data => {
                            dispatch(calendarActions.refresh())
                            dispatch(showAlertSuccess(data.data.title + ' à bien été modifiée !'))
                            dispatch(closeSidebar());
                        }).catch(error => {
                            dispatch(actions.addResourceFailure(error.data.response.message))
                        })
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form className="p-3">
                            {selectSalary && <div className="col-12 mb-3">
                                    <SingleSalarySelected
                                        onDelete={() => setFieldValue('salary', undefined)}
                                        onOpen={() => setDisplaySalarySelector(true)}
                                        initialValue={values.salary}
                                        validationClass={validationClass(errors, touched, 'salary')}
                                        initialSalary={state.rightSidebar.data.salary}
                                    />
                                    <FormError errors={errors} touched={touched} field={'salary'}/>
                                {displaySalarySelector && <SingleSalarySelector
                                    onSubmit={(salary: Salary) => setFieldValue('salary', salary.id)}
                                    onClose={() => setDisplaySalarySelector(false)}
                                    initialValue={values.salary}
                                    companies={[state.rightSidebar.data.company]}
                                />}
                            </div>}
                            <div className="col-12 mb-3">
                                <div className="form-check">
                                    <input defaultChecked={!selectSalary} className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={(e) => {
                                        handleSelectSalaryChange(e)
                                        setSelectSalary(!e.target.checked)
                                    }} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault" >
                                            Je ne souhaite pas préciser de collaborateur
                                        </label>
                                </div>
                            </div>
                            {!selectSalary && <>
                                <div className="col-12 mb-3">
                                    <div className="form-floating">
                                        <Field as={'select'} className={'form-select' + validationClass(errors, touched, 'job')} name={'job'}
                                               children={state.job.payload.map((job: Job) => <option selected={state.rightSidebar.data.job?.id === job.id} value={job.id}>{job.title}</option>)}
                                        />
                                        <label className="form-label" htmlFor="job">
                                            Métier <span className="required">*</span>
                                        </label>
                                        <FormError errors={errors} touched={touched} field={'job'} />
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="form-floating">
                                        <Field defaultValue={state.rightSidebar.data.title} type={'text'} name={'title'} className={'form-control' + validationClass(errors, touched, 'title')} />
                                        <label htmlFor="">
                                            Titre <span className="required">*</span>
                                        </label>
                                        <FormError errors={errors} touched={touched} field={'title'} />
                                    </div>
                                </div>
                            </>}
                            {/*{JSON.stringify(values, null, 2)}*/}
                            {/*{JSON.stringify(errors, null, 2)}*/}
                            <button className='btn btn-outline-primary w-100' type="submit">
                                <i className={'bi bi-check-circle'}> </i> Enregistrer
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default TemplateResourceEdit;