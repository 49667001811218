import * as types from "../../constants/absence";
import * as models from "../../models/absence";

export const addAbsence = () => {
    return {
        type: types.ADD_ABSENCE,
    }
}

export const addAbsenceSuccess = (absence: models.Absence) => {
    return {
        type: types.ADD_ABSENCE_SUCCESS,
        absence: absence,
    }
}

export const addAbsenceFailure = (error: string) => {
    return {
        type: types.ADD_ABSENCE_FAILURE,
        absence: null,
        absences: null,
        error: error
    }
}

export const editAbsence = () => {
    return {
        type: types.EDIT_ABSENCE,
    }
}

export const cancelAbsenceSuccess = (absence: models.Absence) => {
    return {
        type: types.CANCEL_ABSENCE_SUCCESS,
        absence: absence
    }
}

export const editAbsenceSuccess = (absence: models.Absence) => {
    return {
        type: types.EDIT_ABSENCE_SUCCESS,
        absence: absence
    }
}

export const editAbsenceFailure = (error: string) => {
    return {
        type: types.EDIT_ABSENCE_FAILURE,
        absence: null,
        absences: null,
        error: error
    }
}

export const fetchAbsences = () => {
    return {
        type: types.FETCH_ABSENCES,
    }
}

export const fetchAbsencesFailure = (error: string) => {
    return {
        type: types.FETCH_ABSENCES_FAILURE,
        absence: null,
        absences: [],
        error: error
    }
}

export const fetchAbsencesSuccess = (absences: models.Absences) => {
    return {
        type: types.FETCH_ABSENCES_SUCCESS,
        absence: null,
        absences: absences
    }
}


export const showAbsence = () => {
    return {
        type: types.SHOW_ABSENCE,
    }
}
export const showAbsenceSuccess = (absence: models.Absence) => {
    return {
        type: types.SHOW_ABSENCE_SUCCESS,
        absence: absence
    }
}
export const showAbsenceFailure = (error: string) => {
    return {
        type: types.SHOW_ABSENCE_FAILURE,
        absence: null,
        absences: null,
        error: error
    }
}