import React, {useEffect, useState} from "react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import FullCalendar from "@fullcalendar/react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../../adapters/calendar";
import * as calendarTypes from "../../../../constants/calendar";
import format from "../../../../utils/locale";
import {renderEvent} from "../../../../utils/calendar";
import {axiosError} from "../../../../actions/axios";
import {setHours, setMinutes} from "date-fns";

const AbsenceCalendarShow: React.FC = () => {

    const [events, setEvents] = useState<any[]>([]);
    const [viewTitle, setViewTitle] = useState<string>('');
    const state = useSelector((state: RootStateOrAny) => state.absence)
    const calendarRef = React.createRef<FullCalendar>();
    const [timeRange, setTimeRange] = useState({
        minTime: format(setMinutes(setHours(new Date(), 0), 0), 'HH:mm:ss'),
        maxTime: "1." + format(setMinutes(setHours(new Date(), 0), 0), 'HH:mm:ss'),
    });

    useEffect(() => {
        const API = calendarRef.current?.getApi();

        let start = new Date(state.single.start);
        let end = new Date(state.single.end);
        end.setDate(end.getDate() + 1)

        if (API){
            API.gotoDate(start)
            setViewTitle(API.view.title)
            api.test({
                start: format(start, 'uuuu-MM-dd'),
                end: format(end, 'uuuu-MM-dd'),
                calendar: calendarTypes.CALENDAR_EVENTS_SINGLE,
                options: {
                    salary: state.single.salary.id
                }
            }).then((data) => {
                setEvents([...data.data.events || []])
                setTimeRange({
                    minTime: format(new Date(data.data.timeRange.minTime), "HH:mm:ss"),
                    maxTime: (new Date(data.data.timeRange.maxTime).getDate() - new Date(data.data.timeRange.minTime).getDate()) + '.' + format(new Date(data.data.timeRange.maxTime), "HH:mm:ss"),
                })
            }).then(() => true)
        }


    }, [state.single])

    const next = () => {
        const API = calendarRef.current?.getApi();

        if (API)
        {
            if (API.view.activeEnd < new Date(state.single.end)){
                API.next();
                setViewTitle(API.view.title);
            }
        }
    }

    const prev = () => {
        const API = calendarRef.current?.getApi();

        if (API)
        {
            if (API.view.activeStart > new Date(state.single.start)){
                API.prev();
                setViewTitle(API.view.title);
            }
        }
    }

    return <>
        <div className="button-group mb-3">
            <button onClick={() => prev()} className={'btn btn-light rounded-pill shadow-sm'}>
                <i className={'bi bi-chevron-left'}> </i>
            </button>
            <button className={'btn text-primary'}>
                {viewTitle}
            </button>
            <button onClick={() => next()} className={'btn btn-light rounded-pill shadow-sm'}>
                <i className={'bi bi-chevron-right'}> </i>
            </button>
        </div>
        <div className="w-100 flex-grow-1">
            <FullCalendar
                plugins={[ timeGridPlugin, dayGridPlugin ]}
                ref={calendarRef}
                allDaySlot={false}
                headerToolbar={false}
                displayEventTime={true}
                displayEventEnd={true}
                defaultAllDay={false}
                initialView={'timeGridWeek'}
                initialDate={new Date(state.single.start)}
                visibleRange={{
                    start: format(new Date(state.single.start), 'uuuu-MM-dd'),
                    end: format(new Date(state.single.end), 'uuuu-MM-dd')
                }}
                events={events}
                locales={allLocales}
                eventContent={renderEvent}
                locale={'fr'}
                height={'100%'}
                slotMinTime={timeRange.minTime}
                slotMaxTime={timeRange.maxTime}
                showNonCurrentDates={false}
            />
        </div>
    </>
}

export default AbsenceCalendarShow;