import {get, post} from "../../xhr";

export function list(id: number){
    return get(`salary/template/list/${id}`);
}

export function show(id: number){
    return get(`salary/template/${id}/show`);
}

export function copy(id: number, target: number){
    return get(`salary/template/${id}/copy/${target}`);
}

export function copyEvents(id: number, week: number, targets: number[]){
    return post(`salary/template/${id}/copy_events/${week}`, {targets: targets});
}

export function clean(id: number, week: number){
    return get(`salary/template/${id}/clean_events/${week}`);
}

export function deactivate( id: number){
    return get(`salary/template/${id}/deactivate`);
}

export function create(requestData: {title: string, start: Date, end?: Date, salary: number}){
    return post(`salary/template/create`, requestData);
}

export function duplicate(requestData: {title: string, start: Date, end?: Date, salary: number}, id: number){
    return post(`salary/template/${id}/duplicate`, requestData);
}

export function edit(requestData: {title: string}, id: number){
    return post(`salary/template/${id}/edit`, requestData);
}

export function workingTime(id: number, week: number){
    return get(`salary/template/${id}/working_time/${week}`);
}