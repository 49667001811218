import * as xhr from "../xhr";
import {APPLICATION_PDF} from "../xhr";

export function fetch(requestData?: {
    start: string,
    end: string,
    options?: {
        map?: 1|2|3;
        salaries?: number[];
        companies?: number[];
        isCounter?: boolean,
        query?: string;
        offset?: number;
    }
}){
    return xhr.post('reporting/fetch', requestData || {});
}

export function exportDays(requestData: {
    start: string;
    end: string;
}, salary: number)
{
    return xhr.post(`reporting/export/days/${salary}`, requestData, {headers: [APPLICATION_PDF], responseType: 'arraybuffer'});
}

export function exportDaysGrouped(requestData: {
    start: string;
    end: string;
}, salary: number)
{
    return xhr.post(`reporting/export/days_grouped/${salary}`, requestData, {headers: [APPLICATION_PDF], responseType: 'arraybuffer'});
}

export function exportPeriods(requestData: { start: string; end: string; company: number, jobs?: number[]})
{
    return xhr.post(`reporting/export/periods`, requestData, {responseType: 'blob'});
}

export function exportSilae(requestData: { start: string; end: string; company?: number; team?: number; type?: string})
{
    return xhr.post(`reporting/export/silae`, requestData, {responseType: 'blob'});
}

export function exportSignature(requestData: { start: string; end: string; salary: number;}, options?:xhr.Options)
{
    return xhr.post(`reporting/export/signature`, requestData, options);
}