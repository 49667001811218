import * as React from "react";

import DocumentAdd from "../../../components/document/add";

const DocumentAddPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <DocumentAdd />
        </div>
    );
}

export default DocumentAddPage;