import * as React from "react";

import TemplateList from "../../components/template/list/test";
import TemplateShow from "../../components/template/show/test";

const TemplatePage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <TemplateList />
            <TemplateShow />
        </div>
    );
}

export default TemplatePage;