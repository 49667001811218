import React, {useEffect, useState} from "react";
import {Salary} from "../../../models/salary";
import * as api from "../../../adapters/timetable";
import {Timetable, Timetables} from "../../../models/timetable";
import format from "../../../utils/locale";
import Loader from "../../../utils/loader";
import TimetableAdd from "../add";

const TimetableList:React.FC<{salary: Salary, setTimetable: React.Dispatch<Timetable>, timetable?: Timetable, timetables: Timetables, setTimetables: React.Dispatch<any>, date?: Date}> = (props) => {

    const {salary, setTimetable, timetable, timetables, setTimetables, date} = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.list(salary.id).then(resp => {
            setTimetables(resp.data);

            if (date){
                let _timetable = resp.data.find((t: Timetable) => new Date(t.start) <= date && (!t.end || date < new Date(t.end)))
                if (_timetable){
                    setTimetable(_timetable)
                }
            }
            setLoading(false)
        })
    }, [salary])

    const Toolbar = () => <div className={'card shadow-sm position-relative flex-grow-0 mb-3 position-sticky top-0'}>
        <div className="btn-group">
            <button className={'btn btn-light'} data-bs-toggle="offcanvas" data-bs-target="#offcanvasTimetableAdd" aria-controls="offcanvasTimetableAdd" >
                <i className={'bi bi-plus'}></i> Ajouter
            </button>
        </div>
    </div>

    return <div className={"h-100 d-flex flex-column overflow-auto"}>
        <Toolbar />
        <div className={'card shadow-sm position-relative flex-grow-1'}>
            {loading && <Loader />}
            <ul className="list-group list-group-flush overflow-auto">
                {timetables.map(t => <li className={'list-group-item list-group-item-action cursor-pointer' + (timetable?.id === t.id ? ' bg-primary text-white' : '')} onClick={() => setTimetable(t)}>
                    <h4>
                        {t.title}
                    </h4>
                    <p>
                        {format(new Date(t.start), 'dd/MM/uuuu')} <i className={'bi bi-arrow-left-right'}></i> {t.end ? <>{format(new Date(t.end), 'dd/MM/uuuu')}</> : <i className={'bi bi-infinity'}></i>}
                    </p>
                </li>)}
            </ul>
        </div>
        <TimetableAdd salary={salary} timetables={timetables} setTimetables={setTimetables} />
    </div>
}

export default TimetableList;