import SlateEditor from "./SlateEditor";
import {BaseEditor} from "slate";
import {ReactEditor} from "slate-react";
import {HistoryEditor} from "slate-history";

export * from "./SlateEditor";
export default SlateEditor;


type CustomElement = { type: string; children: CustomText[] }
type CustomText = { text: string; bold?: true, italic?: boolean, variable?: boolean }

declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & HistoryEditor
        Element: CustomElement
        Text: CustomText
    }
}