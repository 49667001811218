import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import FormError from "../../../../utils/formError";
import DatePicker from "react-datepicker";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import {setHours, setMinutes, setSeconds} from "date-fns";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Salary} from "../../../../models/salary";
import format from "../../../../utils/locale";
import {Companies, Company} from "../../../../models/companies";
import {Localisations} from "../../../../models/localisation";
import {
    GroupBookingInputGroup,
    Payload,
    GroupBookingsInputGroup
} from "../../../../models/booking/group";
import * as API from "../../../../adapters/booking/group";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../../actions/alert";
import {refresh} from "../../../../actions/calendar";
import {toHms} from "../../../../utils/locale/format";
import {Activities} from "../../../../models/activity";
import BookingDefaultPopupListButton from "../../default/popup/button";
import BookingDefaultPopupList from "../../default/popup";
import {ACCOUNTING_TYPE_WORK_ACCOUNTED, AccountingTypes, getAccountingTypeChoice} from "../../../../constants/booking";

const BookingGroupAdd:React.FC = () => {
    const state = useSelector((state: RootStateOrAny) => state);

    const salary: Salary = state.rightSidebar.data?.salary;
    const dispatch = useDispatch();
    const [companies, setCompanies] = useState<Companies>([salary.companies[0]]);
    const [localisations, setLocalisations] = useState<Localisations>([]);
    const [activities, setActivities] = useState<Activities>([]);
    const [errors, setErrors] = useState<{shift: number, target: number, error: string}[]>([]);
    const [defaultBookingIndex, setDefaultBookingIndex] = useState<number>();

    let template: GroupBookingInputGroup = {
        beginAt: setSeconds(setMinutes(new Date(), 0), 0),
        endAt: setSeconds(setMinutes(new Date(), 30), 0),
        salary: salary.id,
        company: salary.companies[0].id!,
        accountingType: ACCOUNTING_TYPE_WORK_ACCOUNTED,
        localisation: salary.defaultLabels?.find(d => d.company.id === salary.companies[0].id!)?.localisation?.id || "",
        activity: salary.defaultLabels?.find(d => d.company.id === salary.companies[0].id!)?.activity?.id || "",
        recurrence: {
            type: '2',
            weekLetters: [],
            daysOfWeek: [format(new Date(), "i")],
            separationCount: undefined,
        }
    };

    const [initialValues, setInitialValues] = useState<Payload>({
        title : "Sans titre",
        salary: state.rightSidebar.data.salary.id,
        start: new Date(),
        finish: new Date(),
        bookings: [
            {...template}
        ]
    });

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Veuillez choisir un titre'),
        salary: Yup.number().required('Veuillez choisir un collaborateur'),
        start: Yup.date().required('Veuillez choisir une date de début'),
        finish: Yup.date().required('Veuillez choisir une date de fin'),
        bookings: Yup.array().of(
            Yup.object().shape({
                beginAt: Yup.date().required("Veuillez séléctionner un début"),
                endAt: Yup.date().required("Veuillez séléctionner une fin")
                    .test("is-greater", "La fin du créneau être après le début", function(value: any) {
                    const { beginAt } = this.parent;
                    return value && beginAt <= value
                }),
                company: Yup.number().required("Veuillez séléctionner un point de vente"),
                accountingType: Yup.number().required("Veuillez séléctionner un type d'heure"),
                localisation: Yup.number().nullable(),
                activity: Yup.number().nullable(),
                recurrence: Yup.object().shape({
                    type: Yup.number().required(),
                    daysOfWeek: Yup.array().of(
                        Yup.number()
                    ).min(1, 'Veuillez préciser au moins un jour de la semaine'),
                    separationCount: Yup.number().test("is-not-null", "Veuillez préciser la récurrence par semaine", function(value: any) {
                        const { type } = this.parent;
                        return (type === 3 && !!value) || (type === 2);
                    }),
                    weekLetters: Yup.array().of(Yup.number()).test("is-length", "Veuillez séléctionner au moins une semaine", function(value: any) {
                        const { type } = this.parent;
                        return (type === 2 && !!value?.length) || (type === 3);
                    })
                })
            })
        )
    });


    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar())
            API.create(values).then(data => {
                dispatch(showAlertSuccess('Emploi du temps ajouté'))
                dispatch(refresh())
                if (state.rightSidebar.data?.next){
                    dispatch(openSidebar(state.rightSidebar.data?.next, {...state.rightSidebar.data?.data}))
                }else{
                    dispatch(closeSidebar());
                }
            })
        }
    })

    useEffect(() => {
        checkData()
    }, [formik.values])

    const checkData = () => {
        let _bookings:GroupBookingsInputGroup = {...formik.values.bookings};
        let array: [Date, Date, number, string[], string[]][] = [];

        for(let i in _bookings){
            if (_bookings[i].recurrence.type === "2"){
                array.push([
                    _bookings[i].beginAt,
                    _bookings[i].endAt,
                    Number(i),
                    _bookings[i].recurrence.weekLetters!,
                    _bookings[i].recurrence.daysOfWeek
                ])
            }
        }

        checkDateTimeOverlap(array)
    }

    const handleTypeChange = (type: "2"|"3", index: number) => {
        let _values = {...formik.values}
        _values.bookings[index].recurrence.type = type;

        switch (type){
            case "2":
                delete _values.bookings[index].recurrence.separationCount;
                break;
            case "3":
                delete _values.bookings[index].recurrence.weekLetters;
                break;
        }

        setInitialValues({..._values})
    }

    const addBooking = () => {
        let company = {...salary.companies[0]}
        let _initialValues = {...formik.values};
        _initialValues.bookings.push({
            ...template
        })
        let _companies =  [...companies, company]
        setCompanies([..._companies])
        handleCompanyChange(company, _companies.length-1);
        setInitialValues({..._initialValues});
    }

    const duplicateBooking = (index: number) => {
        let _initialValues = {...formik.values};
        _initialValues.bookings.push({
            ..._initialValues.bookings[index]
        })

        if (companies[index]){
            setCompanies([...companies, companies[index]])
        }

        if (localisations[index]){
            setLocalisations([...localisations, localisations[index]])
        }

        if (activities[index]){
            setActivities([...activities, activities[index]])
        }

        setInitialValues({..._initialValues});
    }


    const handleCompanyChange = (company: Company, index: number) => {
        if (salary.defaultLabels?.find(d => d.company.id === company.id!)?.localisation){
            let _localisations = [...localisations]
            _localisations[index] = salary.defaultLabels.find(d => d.company.id === company.id!)!.localisation
            setLocalisations([..._localisations])
        }

        if (salary.defaultLabels?.find(d => d.company.id === company.id!)?.activity){
            let _activities = [...activities]
            _activities[index] = salary.defaultLabels?.find(d => d.company.id === company.id!)!.activity
            setActivities([..._activities])
        }
    }

    const removeBooking = (index: number) => {
        let _initialValues = {...formik.values};

        _initialValues.bookings.splice(index, 1);
        companies.splice(index, 1);

        setCompanies([...companies])
        setInitialValues({..._initialValues});
    }

    const getWeekLetters = (company: Company) => {

        if (!company) return [];

        let length = company.timesheet.recurrence;
        let ret: string[] = [];
        for (let i = 0; i < length; i++){
            ret.push(String.fromCharCode(65 + i));
        }

        return ret;
    }

    const checkDateTimeOverlap = (dateTimes: [Date, Date, number, string[], string[]][])=> {
        let _errors:{shift: number, target: number, error: string}[] = [];
        dateTimes.forEach((time,i) => {
            let  st1 = time[0];
            let  et1 = time[1];
            let wl1 = time[3]
            let wd1 = time[4]

            dateTimes.forEach((time2,j) => {
                let wl2 = time2[3]
                let wd2 = time2[4]

                if(i !== j &&  wl1?.filter(value => wl2?.includes(value)).length &&  wd1?.filter(value => wd2.includes(value)).length){
                    let st2 = time2[0];
                    let et2 = time2[1];

                    if (st1 <= et2 && et1 >= st2) {
                        _errors.push({shift: i, target: j, error: `Le créneau ${i+1} est incompatible avec le ${j+1}`})
                    }
                }
            })
        });

        setErrors([..._errors]);
    }

    const Counter:React.FC<{index: number}> = (props) => {

        let {index} = props;

        let booking = {...formik.values.bookings[index]};

        if (booking){
            let s = (booking.endAt.getTime() - booking.beginAt.getTime())/1000
            return <>{toHms(s)}</>
        }

        return <></>
    }

    const WeekCounter:React.FC = () => {

        if (!salary.information?.contractWorkingTime){
            return <> </>
        }

        let bookings = formik.values.bookings.filter((booking: GroupBookingInputGroup) => booking.recurrence.type === "2");

        let ret: {week: string, value: number}[] = [];

        if (bookings.length){
            for(let i in bookings){
                let s = (bookings[i].endAt.getTime() - bookings[i].beginAt.getTime())/1000
                let wls = bookings[i].recurrence.weekLetters
                if (wls){
                    for (let j in wls){
                        let wl = wls[j]
                        let index = ret.findIndex(item => item.week === wl);
                        if (index !== -1){
                            ret[index].value += s * bookings[i].recurrence.daysOfWeek.length;
                        }else{
                            ret.push({week: wl, value: s * bookings[i].recurrence.daysOfWeek.length})
                        }
                    }
                }

            }
        }

        let sc = salary.information.contractWorkingTime * 3600;

        return <div className={'d-flex w-100 bg-white p-1 position-sticky top-0 z10 text-center'}>
            <div className="p-2 bg-light me-2">
                Heures contrat : {toHms(sc)}
            </div>
            {ret.map(item => <div className={'p-2 bg-light text-uppercase me-2' + (item.value < sc ? ' text-danger ' : (item.value > sc ? ' text-primary ' : ' text-success '))}>
                {String.fromCharCode(96 + Number(item.week))} | {toHms(item.value)} <i className={'bi ' + (item.value < sc ? ' bi-dash-circle ' : (item.value > sc ? ' bi-plus-circle ' : ' bi-check-circle '))}> </i>
                {!!(item.value - sc) && <>{toHms(item.value - sc)}</>}
            </div>)}
        </div>
    }

    return <>
        <form onSubmit={formik.handleSubmit} className="w-100 p-1 p-md-3">
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="form-floating">
                        <input type="text" onChange={formik.handleChange} name={'title'} className="form-control"/>
                        <label htmlFor="">
                           <i className={'bi bi-body-text text-primary'}> </i> Titre
                        </label>
                    </div>
                    <FormError touched={formik.touched} errors={formik.errors} field={'title'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="input-group mb-3">
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                        title={'Début'}
                        ariaLabelledBy={'bi bi-calendar-check text-primary'}
                        customInput={<DateCustomInput label={'Début de la période'} required={true} />}
                        selected={formik.values.start}
                        onChange={(date) => formik.setFieldValue('start', date)}
                        selectsStart
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        startDate={formik.values.start}
                        endDate={formik.values.finish}
                        dateFormat="E dd MMM uuuu"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                    <span className="input-group-text">
                        <i className={'bi bi-arrow-left-right'}> </i>
                    </span>
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'finish')}
                        title={'Fin'}
                        ariaLabelledBy={'bi bi-calendar-check text-primary'}
                        customInput={<DateCustomInput label={'Fin de la période'} />}
                        selected={formik.values.finish}
                        onChange={(date) => formik.setFieldValue('finish', date)}
                        selectsEnd
                        startDate={formik.values.start}
                        endDate={formik.values.finish}
                        minDate={formik.values.start}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="E dd MMM uuuu"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                </div>
                <FormError touched={formik.touched} errors={formik.errors} field={'finish'} />
                <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
            </div>
            {formik.values.salary && <div className="row">
                <WeekCounter />
                {initialValues.bookings.map((booking, index) => <div key={index} className={'p-1 p-md-3 col-12'}>
                    <div className={'border-1 border shadow-sm p-1 p-md-3' + (errors.find(error => error.shift === index) ? ' border-danger' : '')}>
                        <div className="d-flex align-items-center mb-3">
                            <div className="flex-grow-1">
                                Créneau N°{index + 1} - <Counter index={index} />
                                <div className="clearfix"> </div>
                                {errors.find(error => error.shift === index) && <span className={'form-text text-danger'}>
                                    <i className={'bi bi-exclamation-circle'}> </i> {errors.find(error => error.shift === index)?.error}
                                </span>}
                            </div>
                            <div className="flex-grow-0">
                                <BookingDefaultPopupListButton onClick={e => (setDefaultBookingIndex(index))}  />
                                <button type={'button'} className="btn btn-sm btn-outline-primary me-2"
                                        onClick={() => duplicateBooking(index)}>
                                    <i className={'bi bi-arrow-repeat'}> </i>
                                </button>
                                {(!!index || initialValues.bookings.length > 1) &&
                                <button type={'button'} className="btn btn-sm btn-outline-danger"
                                        onClick={() => removeBooking(index)}>
                                    <i className={'bi bi-x'}> </i>
                                </button>}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <div className="col-md-12 mb-3">
                                    <div className="input-group">
                                        <DatePicker
                                            className={'form-control ' + validationClass(formik.errors, formik.touched, `bookings[${index}].beginAt`)}
                                            customInput={<DateCustomInput label={'Début'}
                                                                          icon={'bi bi-calendar-check text-primary'}/>}
                                            selected={formik.values.bookings[index].beginAt}
                                            onChange={(date: Date) => {
                                                formik.setFieldValue(`bookings[${index}].beginAt`, setSeconds(date, 0))
                                                formik.setFieldTouched(`bookings[${index}].beginAt`, true)
                                            }}
                                            selectsStart
                                            startDate={formik.values.start}
                                            maxDate={formik.values.finish}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Heure de début"
                                            dateFormat="E HH:mm"
                                        />
                                        <span className="input-group-text">
                                <i className={'bi bi-arrow-left-right mx-1'}> </i>
                            </span>
                                        <DatePicker
                                            className={'form-control ' + validationClass(formik.errors, formik.touched, `bookings[${index}].endAt`)}
                                            customInput={<DateCustomInput label={'Fin'}
                                                                          icon={'bi bi-calendar-check text-primary'}/>}
                                            showTimeSelect
                                            selected={formik.values.bookings[index].endAt}
                                            onChange={(date: Date) => {
                                                formik.setFieldValue(`bookings[${index}].endAt`, setSeconds(date, 0))
                                                formik.setFieldTouched(`bookings[${index}].endAt`, true)
                                            }}
                                            selectsEnd
                                            startDate={formik.values.bookings[index].beginAt}
                                            endDate={formik.values.bookings[index].endAt}
                                            minDate={formik.values.bookings[index].beginAt}
                                            minTime={setHours(setMinutes(new Date(),
                                                new Date(formik.values.bookings[index].endAt).setHours(0, 0, 0, 0) === new Date(formik.values.bookings[index].beginAt).setHours(0, 0, 0, 0) ? new Date(formik.values.bookings[index].beginAt).getMinutes() : 0
                                                ),
                                                new Date(formik.values.bookings[index].endAt).setHours(0, 0, 0, 0) === new Date(formik.values.bookings[index].beginAt).setHours(0, 0, 0, 0) ? new Date(formik.values.bookings[index].beginAt).getHours() : 0
                                            )}
                                            maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                            timeIntervals={15}
                                            dateFormat="E HH:mm"
                                        />
                                    </div>
                                    <FormError touched={formik.touched} errors={formik.errors}
                                               field={`bookings[${index}].beginAt`}/>
                                    <FormError touched={formik.touched} errors={formik.errors}
                                               field={`bookings[${index}].endAt`}/>
                                </div>
                                <div className="col-12 mb-3">
                                    <BootstrapSelect
                                        label={"Type d'heure"}
                                        value={getAccountingTypeChoice(formik.values.bookings[index].accountingType)}
                                        options={AccountingTypes}
                                        onChange={(choice) => formik.setFieldValue(`bookings[${index}].accountingType`, choice!.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <BootstrapSelect
                                        label={"Point de vente"}
                                        labelIcon={'bi bi-shop text-primary'}
                                        required={true}
                                        enableReinitialize={true}
                                        options={salary.companies.map(company => ({label: company.title, value: company.id!}))}
                                        className={validationClass(formik.errors, formik.touched, `bookings[${index}].company`)}
                                        value={companies[index] ? {label: companies[index].title, value: companies[index].id!} : undefined}
                                        onChange={(choice) => {
                                            formik.setFieldValue(`bookings[${index}].company`, choice?.value)
                                            let company = salary.companies.find(c => c.id === choice?.value);
                                            if (company){
                                                let _companies = companies;
                                                _companies[index] = company;
                                                handleCompanyChange(company, index)
                                                setCompanies([..._companies]);
                                            }
                                        }}
                                    />
                                    <FormError errors={formik.errors} touched={formik.touched} field={`bookings[${index}].company`} />
                                </div>
                                {formik.values.bookings[index].company && <>
                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <BootstrapSelect
                                                    label={"Localisation"}
                                                    options={companies[index].localisations?.map(l => ({label: l.title, value: l.id!}))}
                                                    className={validationClass(formik.errors, formik.touched, `bookings[${index}].localisation`)}
                                                    value={localisations[index] ? {label: localisations[index].title, value: localisations[index].id!} : undefined}
                                                    labelIcon={'bi bi-geo text-primary'}
                                                    required={false}
                                                    enableReinitialize={true}
                                                    onChange={(choice) => {
                                                        formik.setFieldValue(`bookings[${index}].localisation`, choice?.value)
                                                        let localisation = companies[index].localisations?.find(c => c.id === choice?.value);
                                                        if (localisation){
                                                            let _localisations = localisations;
                                                            _localisations[index] = localisation;
                                                            setLocalisations([..._localisations]);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <BootstrapSelect
                                                    label={"Activité"}
                                                    options={companies[index].activities?.map(l => ({label: l.title, value: l.id!}))}
                                                    className={validationClass(formik.errors, formik.touched, `bookings[${index}].activity`)}
                                                    value={activities[index] ? {label: activities[index].title, value: activities[index].id!} : undefined}
                                                    labelIcon={'bi bi-geo text-primary'}
                                                    required={false}
                                                    enableReinitialize={true}
                                                    onChange={(choice) => {
                                                        formik.setFieldValue(`bookings[${index}].activity`, choice?.value)
                                                        let activity = companies[index].activities?.find(c => c.id === choice?.value);
                                                        if (activity){
                                                            let _activities = activities;
                                                            _activities[index] = activity;
                                                            setActivities([..._activities]);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="flex-grow-0 border-end mx-1"> </div>
                            <div className="flex-grow-1 text-center">
                                {formik.values.bookings[index].company && companies[index] && <>
                                    <div className="col-12 mb-3">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name={`bookings[${index}].recurrence.type`}
                                                   id={`type1-${index}`}
                                                   value="2"
                                                   defaultChecked={true}
                                                   onChange={(e) => handleTypeChange("2", index)}
                                            />
                                            <label className="form-check-label" htmlFor={`type1-${index}`}>
                                                Semaines X
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name={`bookings[${index}].recurrence.type`}
                                                   id={`type2-${index}`}
                                                   value="3"
                                                   onChange={(e) => handleTypeChange("3", index)}
                                            />
                                            <label className="form-check-label" htmlFor={`type2-${index}`}>
                                                Personnalisé
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className={'mb-2'}>
                                            Jours de la semaine
                                        </div>
                                        {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, _index: number) => {
                                            return (
                                                <>
                                                    <input key={_index}
                                                           type="checkbox"
                                                           onChange={formik.handleChange}
                                                           name={`bookings[${index}].recurrence.daysOfWeek`}
                                                           checked={formik.values.bookings[index].recurrence.daysOfWeek.includes(`${_index + 1}`)}
                                                           className="btn-check"
                                                           id={`btn-check-${index}-${_index}`}
                                                           autoComplete="off"
                                                           value={`${_index + 1}`}
                                                    />
                                                    <label key={index + 7} className="btn btn-sm rounded-pill btn-outline-primary me-1"
                                                           htmlFor={`btn-check-${index}-${_index}`}>
                                                        {day}
                                                    </label>
                                                </>
                                            )
                                        })}
                                        <FormError touched={formik.touched} errors={formik.errors} field={`bookings[${index}].recurrence.daysOfWeek`}/>
                                    </div>
                                    {formik.values.bookings[index].recurrence.type === "2" && companies[index] && <div className="col-12 mb-3">
                                        <div className={'mb-2'}>
                                            <i className={'bi bi-arrow-repeat text-primary'}> </i> Répéter toutes les semaines
                                        </div>
                                        {getWeekLetters(companies[index]).map((week: string, _index: number) => {
                                            return (
                                                <>
                                                    <input
                                                        key={_index}
                                                        type="checkbox"
                                                        onChange={formik.handleChange}
                                                        defaultChecked={formik.values.bookings[index].recurrence.weekLetters?.includes(`${_index + 1}`)}
                                                        name={`bookings[${index}].recurrence.weekLetters`}
                                                        className="btn-check"
                                                        id={`btn-check-wl-${index}-${_index}`}
                                                        autoComplete="off"
                                                        value={`${_index + 1}`}
                                                    />
                                                    <label key={_index} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-check-wl-${index}-${_index}`}>
                                                        {week}
                                                    </label>
                                                </>
                                            )
                                        })}
                                        <FormError touched={formik.touched} errors={formik.errors} field={`bookings[${index}].recurrence.weekLetters`}/>
                                    </div>}
                                    {formik.values.bookings[index].recurrence.type === "3" && <div className="col-12 mb-3">
                                        <div className="bg-light p-1 mb-3">
                                            <div className={'form-text'}>
                                                <i className={'bi bi-arrow-repeat text-primary'}> </i> Répéter toutes les
                                            </div>
                                            <div className="input-group">
                                                <input
                                                    className={"form-control"}
                                                    onChange={formik.handleChange}
                                                    type="number"
                                                    defaultValue={1}
                                                    name={`bookings[${index}].recurrence.separationCount`}
                                                />
                                                <div className="input-group-text">
                                                    Semaines
                                                </div>
                                            </div>
                                        </div>
                                        <FormError errors={formik.errors} touched={formik.touched} field={`bookings[${index}].recurrence.separationCount`} />
                                    </div>}
                                </>}
                            </div>
                        </div>
                    </div>
                </div>)}
                <div className="col-12 p-1 p-md-3">
                    <button type={'button'} className={"btn btn-outline-primary w-100 h-100"} onClick={() => addBooking()}>
                        <i className={'bi bi-plus-circle'}> </i> Ajouter un créneau
                    </button>
                </div>
            </div>}
            <button disabled={!!errors.length} type={"submit"} className={'btn btn-outline-success w-100'}>
                <i className={'bi bi-check-all'}> </i> Valider
            </button>
        </form>
        <BookingDefaultPopupList
            job={salary.information!.job}
            _start={formik.values.start}
            _end={formik.values.finish}
            onSelect={e => {
                if (defaultBookingIndex !== undefined){
                    let _initialValues = {...formik.values}
                    let _input = {..._initialValues.bookings[defaultBookingIndex]}
                    _input.beginAt = e.beginAt;
                    _input.endAt = e.endAt;
                    _input.recurrence.type = "2";
                    if (e.recurrence.daysOfWeek){
                        _input.recurrence.daysOfWeek = e.recurrence.daysOfWeek.map(d => String(d))
                    }

                    _initialValues.bookings[defaultBookingIndex] = {..._input}
                    setInitialValues({..._initialValues})
                }
            }}
        />
    </>
}

export default BookingGroupAdd;