import React, {useEffect} from "react";
import {setPageTitle} from "../../../actions/header";
import {useDispatch} from "react-redux";

const Error500 : React.FC = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle("Erreur 500"))
    }, [])

    return (
        <div className={'w-100 h-100 py-5 text-center align-middle'}>
            <div className="w-100 mt-5 text-danger">
                <i className="bi bi-exclamation-circle fs-1"> </i>
            </div>
            <h1 className={'display-4'}>
                Une erreur est survenue.
            </h1>
            <p className="text-light-s">
                Si l'erreur persiste, contactez nous sur <span className={'text-primary'}>contact@sivan.io</span>
            </p>
        </div>
    );
}

export default Error500;