import * as React from "react";

import TaskGroupCalendar from "../../../components/taskGroup/calendar";

const TaskGroupCalendarPage : React.FC = () => {
    return (
        <div className='h-100 d-flex flex-column'>
            <TaskGroupCalendar />
        </div>
    );
}

export default TaskGroupCalendarPage;