import React, {useState} from "react";
import * as models from "../../../../models/synthesis";
import * as reportModels from "../../../../models/report";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import timeFormat from "../../../../utils/timeFormat"
import * as actions from "../../../../actions/synthesis";
import * as api from "../../../../adapters/report";
import * as paidApi from "../../../../adapters/paid";
import {Link} from "react-router-dom";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";
import SmallLoader from "../../../../utils/loader/small";
import {Company} from "../../../../models/companies";

const SynthesisPaidTable:React.FC<{company?: Company, setMaj: React.Dispatch<any>}> = (props) => {

    const dispatch = useDispatch();
    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);
    const report: reportModels.Report = useSelector((state: RootStateOrAny) => state.report.single);

    function paidValue(s: models.Synthesis)
    {
        paidApi.paidValue(s.id, {month: report.month, year: report.year, value: s.overtime})
            .then(() => dispatch(actions.updateSyntheses([s])))
    }

    function paidValueLowMajoration(s: models.Synthesis)
    {
        paidApi.paidLow(s.id, {month: report.month, year: report.year, value25: s.overtime25})
            .then(() => dispatch(actions.updateSyntheses([s])))
    }

    function paidValueHighMajoration(s: models.Synthesis)
    {
        paidApi.paidHigh(s.id, {month: report.month, year: report.year, value50: s.overtime50})
            .then(() => dispatch(actions.updateSyntheses([s])))
    }

    return <>
        <table className="table text-nowrap sticky-table table-striped align-middle text-center">
            <thead>
            <tr>
                <th>
                    Collaborateur
                </th>
                <th>
                    Compteur initial
                </th>
                <th>
                    Total validé
                </th>
                <th>
                    H. payées taux normal
                </th>
                <th style={{backgroundColor: "#9ec5fe"}}>
                    H. payées 25%
                </th>
                <th style={{backgroundColor: "#a3cfbb"}}>
                    H. payées 50%
                </th>
                <th title={"Compteur initial + Compteur période - H. payées = Compteur final."} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                    Compteur final <i className={'bi bi-info-circle'}></i>
                </th>
                <th>
                    H. taux normal
                </th>
                <th style={{backgroundColor: "#9ec5fe"}}>
                    HS. 25%
                </th>
                <th style={{backgroundColor: "#a3cfbb"}}>
                    HS. 50%
                </th>
            </tr>
            </thead>
            <tbody>
            {state.payload.map((s) => {
                let updating = !!state.updating.find(u => u.id === s.id);

                return <>
                    <tr key={s.id}>
                        <td>
                            <div className="d-flex align-items-center w-100">
                                <Link target={"_blank"} to={`/salary/${s.salary.id}`}
                                      className={'text-primary mb-0 flex-grow-1'}>
                                    <i className="bi bi-box-arrow-up-right"></i> {s.salary.title}
                                </Link>
                                <button title={"Détail par jour"} onMouseEnter={e => onMouseEnterTooltip(e.target)}
                                        className="btn bg-primary text-white flex-grow-0" data-bs-toggle="modal"
                                        data-bs-target="#synthesisSignature"
                                        onClick={() => dispatch(actions.showSynthesisSuccess(s))}>
                                    <i className={'bi bi-list-ul'}></i>
                                </button>
                            </div>
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : <>{!!s.initialCounter && <i className={s.initialCounter < 0 ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}></i>} {timeFormat(s.initialCounter, 'hour', false)}</>}
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : timeFormat(s.counterSaved, 'hour', true)}
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : <>
                                <button className={'btn btn-sm'} onMouseDown={() => props.setMaj("none")} onClick={() => dispatch(actions.showSynthesisSuccess(s))}
                                        type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasReportPaid" aria-controls="offcanvasReportPaid"
                                >
                                    {timeFormat(s.paid)} <i className={'bi bi-pencil text-primary'}></i>
                                </button>
                            </>}
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#9ec5fe"}}>
                            {updating ? <div className="line w-100"></div> : <>
                                <button className={'btn btn-sm'} onMouseDown={() => props.setMaj("low")} onClick={() => dispatch(actions.showSynthesisSuccess(s))}
                                        type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasReportPaid" aria-controls="offcanvasReportPaid"
                                >
                                    {timeFormat(s.paidLowMajoration)} <i className={'bi bi-pencil text-primary'}></i>
                                </button>
                            </>}
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#a3cfbb"}}>
                            {updating ? <div className="line w-100"></div> : <>
                                <button className={'btn btn-sm'} onMouseDown={() => props.setMaj("high")} onClick={() => dispatch(actions.showSynthesisSuccess(s))}
                                        type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasReportPaid" aria-controls="offcanvasReportPaid"
                                >
                                    {timeFormat(s.paidHighMajoration)} <i className={'bi bi-pencil text-primary'}></i>
                                </button>
                            </>}
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : <>
                                {!!s.finalCounter &&
                                    <i className={s.finalCounter < 0 ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}></i>} {timeFormat(s.finalCounter, 'hour', false)}
                            </>}
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : <span className={'text-nowrap'}>{timeFormat(s.overtime)} {!!s.overtime && s.paid !== s.overtime && <i className={'bi bi-arrow-right-circle'} title={"Payer les heures non majorées"} onClick={() => paidValue(s)} onMouseEnter={e => onMouseEnterTooltip(e.target)}></i>} </span>}
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#9ec5fe"}}>
                            {updating ? <div className="line w-100"></div> : <span className={'text-nowrap'}>{timeFormat(s.overtime25)} {!!s.overtime25 && <i className={'bi bi-arrow-right-circle'} title={"Payer les heures majorées à 25%"} onClick={() => paidValueLowMajoration(s)} onMouseEnter={e => onMouseEnterTooltip(e.target)}></i>} </span>}
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#a3cfbb"}}>
                            {updating ? <div className="line w-100"></div> : <span className={'text-nowrap'}>{timeFormat(s.overtime50)} {!!s.overtime50 && <i title={"Payer les heures majorées à 50%"} onClick={() => paidValueHighMajoration(s)} onMouseEnter={e => onMouseEnterTooltip(e.target)} className={'bi bi-arrow-right-circle'}></i>}</span>}
                        </td>
                    </tr>
                </>
            })}
            </tbody>
        </table>
    </>
}

export default SynthesisPaidTable;