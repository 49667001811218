export default function(items: any[], item: any){    
    let index = items.findIndex((a: any) => a.id === item.id);

    if (index !== -1) {
        items[index] = { ...items[index], ...item };
    }

    return items;
}

