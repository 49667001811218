/* eslint-disable no-param-reassign */
// import isUrl from "is-url";
import {BaseEditor, Editor, Element} from "slate";

import { wrapLink } from "./helpers";
import {ReactEditor} from "slate-react";
import {HistoryEditor} from "slate-history";

export function withLinks(editor: BaseEditor & ReactEditor & HistoryEditor): Editor {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: Element): boolean => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text: string): void => {
    if (text && validURL(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer): void => {
    const text = data.getData("text/plain");

    if (text && validURL(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}

function validURL(str: string) {
  let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

  return pattern.test(str);
}
