import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Loading from '../../../../common/Loading'
import EmptyList from '../../../../common/EmptyList'
import AddPaidVacationDrawer from './AddPaidVacationDrawer'

import { Salary } from '../../../../../models/salary'
import { ISalaryPaidVacationModel } from '../../../../../models/salary/paidVacation/ISalaryPaidVacationModel'

import * as accessRights from "../../../../../constants/accessRight"
import { Granted } from "../../../../../security/granted"

import * as api from "../../../../../adapters/absence"
import { getSalaryPaidVacationsList, deleteSalaryPaidVacation } from '../../../../../adapters/salary/paidVacation'

import { showAlertDanger } from '../../../../../actions/alert'
import { axiosError } from '../../../../../actions/axios'

import moment from "../../../../../utils/moment.utils"

interface IProps {
  salary: Salary;
  setCounter: (data: any) => void;
}

const PaidVacations: React.FC<IProps> = ({ salary, setCounter }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [salaryPaidVacationsList, setSalaryPaidVacationsList] = useState<ISalaryPaidVacationModel[]>([])

  const fetchCounterData = () => {
    api.counter(salary.id.toString()).then(data => {
      setCounter(data.data);
    }).catch(error => dispatch(axiosError(error)))
  }

  const fetchPaidVacations = async (noLoading?: string) => {
    try {
      if (!noLoading) setIsLoading(true)
      const { data } = await getSalaryPaidVacationsList(salary.id)
      setSalaryPaidVacationsList(data)
    } catch (error) {
      dispatch(showAlertDanger('Impossible de récupérer les congés supplémentaires'))
    } finally {
      setIsLoading(false)
    }
  }

  const onDeletePaidVacation = async (paidVacationId: number) => {
    try {
      await deleteSalaryPaidVacation(salary.id, paidVacationId)
      fetchPaidVacations('noLoading')
      fetchCounterData()
    } catch (error) {
      dispatch(showAlertDanger('Impossible de supprimer le congé'))
    }
  }

  useEffect(() => {
    fetchPaidVacations()
  }, [])

  return (
    <div>
      {isLoading &&
        <Loading />
      }

      {!isLoading &&
        <div>
          <table className={'mt-3 table mb-0 overflow-auto'}>
            <thead>
              <tr>
                <th>
                    Durée (jours)
                </th>
                <th>
                    Disponible à partir du
                </th>
                <th>
                    Motif
                </th>
                <th className='text-end'>
                    Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {salaryPaidVacationsList.length === 0 &&
                <tr>
                  <td colSpan={100}>
                    <EmptyList
                      text="Aucun congé supplémentaire n’a été attribué"
                    />
                  </td>
                </tr>
              }

              {salaryPaidVacationsList.map(paidVacation => (
                <tr key={paidVacation.id} className='py-1'>
                  <td>
                    {paidVacation.duration} jour(s)
                  </td>
                  <td>
                    {moment(paidVacation.availableStart).format('DD MMMM YYYY')}
                  </td>
                  <td>
                    {paidVacation?.reason || '-'}
                  </td>
                  <td className='d-flex justify-content-end'>
                    <i
                      className='bi bi-trash text-danger'
                      style={{ cursor: 'pointer' }}
                      onClick={() => onDeletePaidVacation(paidVacation.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {Granted(accessRights.EDIT_ABSENCE, salary.id) &&
            <div className="pt-3 row justify-content-end">
              <div className="col-auto">
                <button
                  className={'btn btn-outline-primary'}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#addPaidVacationDrawer"
                  aria-controls="addPaidVacationDrawer"
                >
                  <i className={'bi bi-plus'}> </i> Ajouter
                </button>
              </div>
            </div>
          }
        </div>
      }

      <AddPaidVacationDrawer
        drawerId="addPaidVacationDrawer"
        salaryId={salary.id}
        onSubmitSuccess={() => {
          fetchPaidVacations()
          fetchCounterData()
        }}
      />
    </div>
  )
}

export default PaidVacations