import React from "react";
import {Salary} from "../../salary";
import {EventClickArg} from "@fullcalendar/react";
import {ViewApi} from "@fullcalendar/common";
import {ResourceApi} from "@fullcalendar/resource-common";
import {Resource} from "../index";

export interface ResourceLabelContentArg {
    resource: ResourceApi;
    date?: Date;
    view: ViewApi;
}

export interface initialStateInterface {
    events: any[],
    resources: any[],
    workingTimes: WorkingTimes,
    groupBy: number,
    eventClickArg?: EventClickArg,
    displayEventPop: boolean,
    refresh: number,
    loading: boolean,
}

export interface WorkingTime {
    resources: string[],
    time: string,
    timestamp: number
}

export type WorkingTimes = WorkingTime[];

export const initialState: initialStateInterface = {
    events: [],
    resources: [],
    workingTimes: [],
    displayEventPop: false,
    groupBy: 1,
    refresh: 0,
    loading: false,
};