import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Loader from "../../../utils/loader";
import * as messageApi from "../../../adapters/channel/message"
import * as api from "../../../adapters/channel"
import * as reactionApi from "../../../adapters/channel/message/reaction"
import {Channel, Message, MessageReaction, Messages} from "../../../models/channel";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import './index.scss';
import {useFormik} from "formik";
import * as Yup from "yup";
import format from "../../../utils/locale/index";
import {Link} from "react-router-dom";
import {Tooltip} from "bootstrap";
import {setPageTitle} from "../../../actions/header";
import {CHANNEL_SUBSCRIBER, MERCURE_PUBLIC_URL, PAGINATION} from "../../../constants/global";
import {axiosError} from "../../../actions/axios";

export const ChannelShow:React.FC = () => {

    const param: {id: string} = useParams();
    const state = useSelector((state:RootStateOrAny) => state)
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [scrollTop, setScrollTop] = useState(false);
    const [isLast, setIsLast] = useState(false);
    const [sent, setSent] = useState<number[]>([]);
    const [sentError, setSentError] = useState<number[]>([]);
    const [isLoadingMessages, setIsLoadingMessages] = useState(false);
    const [messages, setMessages] = useState<Messages>([])
    const [channel, setChannel] = useState<Channel>()
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            body: ''
        },
        validationSchema: Yup.object().shape({
            body: Yup.string().required(),
            attachments: Yup.array().of(Yup.mixed().test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true // attachment is optional
                return value[0].size <= 2000000
            })).nullable().max(5)
        }),
        onSubmit: (values, formikHelpers) => {

            let input = document.getElementById('body') as HTMLTextAreaElement;
            input.value = '';

            let pendingId = Date.now();
            setMessages([{
                owner: state.auth.user.currentSalary,
                body: values.body,
                attachments: [],
                createdAt: new Date().toDateString(),
                messageReactions: [],
                pending: true,
                pendingId: pendingId,
                error: false
            }, ...messages]);

            messageApi.create(values, param.id).then(data => {
                setSent([...sent, pendingId])
            }).catch((error) => {
                dispatch(axiosError(error))
                setSentError([...sentError, pendingId])
            });
            formikHelpers.resetForm(
                {}
            )
        },
    });

    useEffect(() => {
        scrollMessageContainer()
    }, [messages])

    useEffect(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }, [messages])

    useEffect(() => {
        if (param.id){
            let _channel = state.channel.payload.find((channel: Channel) => channel.id === Number(param.id))
            let url = new URL(MERCURE_PUBLIC_URL)
            if (_channel){
                setChannel({..._channel});
                dispatch(setPageTitle('Messagerie', _channel.title))
                url.searchParams.append('topic', CHANNEL_SUBSCRIBER + _channel.token)
                let _eventSource = new EventSource(String(url))
                _eventSource.onmessage = (e) => newMessage(e)
            }else{
                api.show(param.id).then(data => {
                    setChannel(data.data);
                    dispatch(setPageTitle('Messagerie', data.data.title))
                    url.searchParams.append('topic', CHANNEL_SUBSCRIBER + data.data.token)
                    let _eventSource = new EventSource(String(url))
                    _eventSource.onmessage = (e) => newMessage(e)
                }).catch(error => dispatch(axiosError(error)))
            }
        }
    }, [param.id])

    useEffect(() => {
        if (param.id){
            setIsLoading(true);
            messageApi.list(param.id).then(data => {

                setIsLast(data.data.length < PAGINATION);

                setMessages(data.data);
                setOffset((prevState => prevState + 1))
            })
                .then(() => {
                    setIsLoading(false);
                }).then(() => {
                    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
                    tooltipTriggerList.map(function (tooltipTriggerEl) {
                        return new Tooltip(tooltipTriggerEl)
                    })
                }).catch(error => dispatch(axiosError(error)))
        }


    }, [param.id]);

    useEffect(() => {
        if (scrollTop){
            setIsLoading(true)
            messageApi.list(param.id).then(data => {
                setIsLoading(false);
                setMessages(data.data);
                setOffset((prevState => prevState + 1))
                let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
                tooltipTriggerList.map(function (tooltipTriggerEl) {
                    return new Tooltip(tooltipTriggerEl)
                })
            }).catch(error => dispatch(axiosError(error)))
        }
    }, [scrollTop]);

    const handleReactionClick = (type: number, message: Message) => {

        if (!message.id) return false;

        let _messages = [...messages]

        let messageIndex = _messages.findIndex(x => x.id === message.id);

        let exist = _messages[messageIndex].messageReactions.find((e) => e.owner.id === state.auth.user.currentSalary.id)

        let pendingId = Date.now();
        let item:MessageReaction = {
            type: type,
            owner: state.auth.user.currentSalary,
            pendingId: pendingId
        };

        if (exist){
            let reactionIndex =  _messages[messageIndex].messageReactions.findIndex(x => x.id === exist!.id)
            _messages[messageIndex].messageReactions[reactionIndex] = item;

            setMessages([..._messages])

            reactionApi.edit({type}, message.id, exist.id!).then(data => {
                _messages[messageIndex].messageReactions[reactionIndex] = data.data;
                setMessages([..._messages])
            }).catch(error => dispatch(axiosError(error)))
        }else{
            _messages[messageIndex].messageReactions.push(item)

            setMessages([..._messages])

            reactionApi.create({type}, message.id).then(data => {
                let reactionIndex = _messages[messageIndex].messageReactions.findIndex(x => x.pendingId === pendingId);
                _messages[messageIndex].messageReactions[reactionIndex] = data.data
                setMessages([..._messages])
            }).catch(error => dispatch(axiosError(error)))
        }
    }

    interface reactionCounterProps {
        message: Message
    }

    const ReactionCounters = (props: reactionCounterProps) => {

        const {message} = props;

        if (!message.messageReactions.length) return (<span> </span>);

        let reactionsTab:number[] = [];
        let reactionsTabCount:number[] = [];
        let reactionsTabTooltip:string[] = [];

        message.messageReactions.forEach((reaction) => {
            if (!reactionsTab.includes(reaction.type)){
                reactionsTab.push(reaction.type)
                reactionsTabCount[reaction.type] = 1;
            }else{
                reactionsTabCount[reaction.type]++;
            }

            if (reactionsTabTooltip[reaction.type] !== undefined ){
                reactionsTabTooltip[reaction.type] += reaction.owner.firstname + ' ' + reaction.owner.lastname + ' <br>'
            }else{
                reactionsTabTooltip[reaction.type] = reaction.owner.firstname + ' ' + reaction.owner.lastname + ' <br>'
            }
        })

        return (
            <>
                {reactionsTab.includes(1) && <span><i className={"bi bi-emoji-smile"} data-bs-toggle="tooltip" data-bs-html="true" title={reactionsTabCount[1] + ' personne(s) <br>' + reactionsTabTooltip[1]}> </i></span>}
                {reactionsTab.includes(2) && <span><i className={"bi bi-hand-thumbs-up"} data-bs-toggle="tooltip" data-bs-html="true" title={reactionsTabCount[2] + ' personne(s) <br>' + reactionsTabTooltip[2]}> </i></span>}
                {reactionsTab.includes(3) && <span><i className={"bi bi-hand-thumbs-up"} data-bs-toggle="tooltip" data-bs-html="true" title={reactionsTabCount[3] + ' personne(s) <br>' + reactionsTabTooltip[3]}> </i></span>}
                {reactionsTab.includes(4) && <span><i className={"bi bi-emoji-heart-eyes"} data-bs-toggle="tooltip" data-bs-html="true" title={reactionsTabCount[4] + ' personne(s) <br>' + reactionsTabTooltip[4]}> </i></span>}
                {reactionsTab.includes(5) && <span><i className={"bi bi-emoji-laughing"} data-bs-toggle="tooltip" data-bs-html="true" title={reactionsTabCount[5] + ' personne(s) <br>' + reactionsTabTooltip[5]}> </i></span>}
            </>
        );
    }

    const [newEvent, setNewEvent] = useState<any>()
    useEffect(() => {
        if (newEvent){
            let item = newEvent.item;
            let type = newEvent.type;
            let message:number|undefined;

            if (item.owner.id !== state.auth.user.currentSalary.id){
                switch (type){
                    case 'new_message':
                        setMessages([{...item}, ...messages])
                        break;
                    case 'new_reaction':
                        message = messages.findIndex((message) => message.id === item.message.id)
                        if (message) messages[message].messageReactions.push(item)
                        setMessages([...messages])
                        break;
                    case 'edit_reaction':
                        message = messages.findIndex((message) => message.id === item.message.id)
                        if (message){
                            let reactionIndex = messages[message].messageReactions.findIndex((reaction) => reaction.id === item.id)
                            messages[message].messageReactions[reactionIndex] = item;
                            setMessages([...messages])
                        }
                        break;
                }
            }
        }
    }, [newEvent])

    const newMessage = (event: MessageEvent) => {
        let data = JSON.parse(event.data)
        setNewEvent(data)
    }



    const scrollMessageContainer = () => {
        let element = document.getElementById("messageContainer");
        if (element){
            element.scrollTop = element.scrollHeight;
        }
    }

    if (!channel)
    {
        return <div className={'h-100 d-none d-md-block w-100 position-relative'} style={
            {
                backgroundImage: "url(/img/background/sprinkle.svg)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }
        }>
            {isLoading && <Loader />}
        </div>
    }

    return <>
        <div id={'singleContainer'} className={"col col-md-9 h-100 px-0" + (param.id ? "" : " d-none d-md-block")}>
            <div className="channel-container">
                <div className="top-box flex-grow-0 p-2 border-bottom d-flex align-items-center">
                    <span className={'me-2 flex-grow-0'}>
                       <i className={'bi bi-people'}> </i> {channel.salaries.length}
                    </span>
                    <span className={"mb-0 flex-grow-1"}>
                        {channel?.description}
                    </span>
                    <Link to={"/channel"} className={"btn d-md-none btn-sm btn-light"}>
                        <i className={"bi bi-chevron-left"}> </i> Retour
                    </Link>
                </div>
                <div className="message-box flex-grow-1 border-1 bg-white p-2 p-md-4" id={"messageContainer"}>
                    {isLoadingMessages && <div className={"w-100 position-relative pt-4"}>
                        <Loader/>
                    </div>}
                    {messages.slice(0).reverse().map((message: Message) => {

                        let reverse = message.owner.id !== state.auth.user.currentSalary.id;
                        let pending = message.pendingId && !sent.includes(message.pendingId);
                        let error = message.pendingId && sentError.includes(message.pendingId);
                        let reaction = message.messageReactions.find((reaction) => reaction.owner.id === state.auth.user.currentSalary.id)

                        return (
                            <>
                                <div className={"row mb-2 " + (reverse ? ' flex-row-reverse' : '')}>
                                    <div className="col">
                                        <div className={"row align-items-center " + (reverse ? ' flex-row-reverse' : '')}>
                                            <div className={"col display-hover " + (reverse ? ' text-start' : ' text-end')}>
                                                    {message.id && <span className={(reverse ? ' dropend' : ' dropstart')}>
                                                    <button className="btn btn-sm bg-light shadow-sm rounded-pill no-content bg-white dropdown-toggle" type="button"
                                                            id={`reaction${message.id}`} data-bs-toggle="dropdown" aria-expanded="false">
                                                        {reaction?.type === 1 &&  <i className={"bi bi-emoji-smile"}> </i>}
                                                        {reaction?.type === 2 &&  <i className={"bi bi-hand-thumbs-up"}> </i>}
                                                        {reaction?.type === 3 &&  <i className={"bi bi-hand-thumbs-up"}> </i>}
                                                        {reaction?.type === 4 &&  <i className={"bi bi-emoji-heart-eyes"}> </i>}
                                                        {reaction?.type === 5 &&  <i className={"bi bi-emoji-laughing"}> </i>}
                                                        {!reaction &&  <i className={"bi bi-three-dots"}> </i>}
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-message-reaction" aria-labelledby={`reaction${message.id}`}>
                                                        <span className={'btn btn-sm'} onClick={(event) => handleReactionClick(1, message)}>
                                                            <i className={"bi bi-emoji-smile " + (reaction?.type === 1 && "active")}> </i>
                                                        </span>
                                                        <span className={'btn btn-sm'} onClick={(event) => handleReactionClick(2, message)}>
                                                            <i className={"bi bi-hand-thumbs-up " + (reaction?.type === 2 && "active")}> </i>
                                                        </span>
                                                        <span className={'btn btn-sm'} onClick={(event) => handleReactionClick(3, message)}>
                                                            <i className={"bi bi-hand-thumbs-up " + (reaction?.type === 3 && "active")}> </i>
                                                        </span>
                                                        <span className={'btn btn-sm'} onClick={(event) => handleReactionClick(4, message)}>
                                                            <i className={"bi bi-emoji-heart-eyes "  + (reaction?.type === 4 && "active")}> </i>
                                                        </span>
                                                        <span className={'btn btn-sm'} onClick={(event) => handleReactionClick(5, message)}>
                                                            <i className={"bi bi-emoji-laughing "  + (reaction?.type === 5 && "active")}> </i>
                                                        </span>
                                                    </ul>
                                                </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-auto" + (reverse ? " pe-5" : " ps-5")}>
                                        <div className={"border br-5 shadow-sm p-1 p-md-2 " + (pending ? " bg-light " : ' ') + (message.owner.id !== state.auth.user.currentSalary.id ? " bg-primary text-white radius-bottom-start-0" : " radius-bottom-end-0 bg-success text-white")}>
                                            {message.owner.id !== state.auth.user.currentSalary.id && <div className="fs-10">
                                            {message.owner.firstname} {message.owner.lastname} | {format(new Date(message.createdAt), 'HH:mm')}
                                            </div>}
                                                <div className="body">
                                                    {message.body}
                                                    <span className="clearfix"> </span>
                                                    {pending && <span className={"form-text"}> <i className={"bi bi-send"}> </i> Envoi en cours...</span>}
                                                    {error && <span className={"form-text text-danger"}> <i className={"bi bi-x"}> </i> Message non distribué</span>}
                                                </div>
                                        </div>
                                        <div className="clearfix"> </div>
                                        {message.messageReactions.length ? <div id={'messageReactionsContainer' + message.id} className={'w-100 form-text text-end'}>
                                            <ReactionCounters message={message} />
                                        </div> : ''}
                                    </div>
                                </div>
                                <div className="clearfix"> </div>
                            </>
                        )
                    })}
                </div>
                <form onSubmit={formik.handleSubmit} className="flex-grow-0 input-box">
                    <div className="input-group h-100">
                        {/*<span className="input-group-text bg-white radius-0">*/}
                        {/*    <i className={"bi bi-file-image"}> </i>*/}
                        {/*</span>*/}
                        <textarea id={'body'} name={'body'}
                                  onChange={formik.handleChange} className={"form-control h-100 bg-white radius-0"} >
                        </textarea>
                        <button type={"submit"} className="input-group-text bg-white radius-0">
                            <i className={"bi bi-send me-2"}> </i> <span className={"d-none d-md-block"}> </span> Envoyer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>
}

export default ChannelShow;