import { get, post } from "../xhr";

export function list(requestData: { query: string, offset: number }) {
    return post('sellsy/contact/list', requestData);
}

export function getContactById(requestData: { sellsyId: number }) {
    return post('sellsy/contact/data', requestData);
}

export function listContactCompanies(requestData: { contactId: number }) {
    return post('sellsy/contact/companies/list', requestData);
}

export function getModelsList() {
    return get('sellsy/models/list');
}