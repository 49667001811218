import {Salary} from "../salary";
import {Company} from "../companies";
import {Localisation} from "../localisation";
import {Activity} from "../activity";

export interface BookingRecurrence {
    type: 1|2|3,
    start: string,
    end: string,
    daysOfWeek?: number[],
    weekLetters?: number[],
    separationCount?: number,
    description?: string
}

export interface Booking {
    id?: number,
    start: string,
    end: string,
    salary: Salary,
    company: Company,
    accountingType: 1|2|3|4,
    localisation: Localisation|null,
    activity: Activity|null,
    recurrence: BookingRecurrence,
}

export type Bookings = Booking[];

export const BOOKING_RECURRENCE_CHOICES = [
    {label: 'Une seule fois', value: 1},
    {label: 'Semaines X', value: 2},
    {label: 'Personnalisé', value: 3},
];

export const getBookingRecurrenceChoice = (type: number) => [
    {label: 'Une seule fois', value: 1},
    {label: 'Semaines X', value: 2},
    {label: 'Personnalisé', value: 3},
][type - 1];