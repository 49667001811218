import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import validationClass from "../../../../../utils/validationClass";
import DateCustomInput from "../../../../../utils/customInput";
import format from "../../../../../utils/locale";
import FormError from "../../../../../utils/formError";
import {useFormik} from "formik";
import * as api from "../../../../../adapters/timetable/event";
import * as Yup from "yup";
import {Company} from "../../../../../models/companies";
import {Timetable, TimetableEvent, TimetableEvents} from "../../../../../models/timetable";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {editTimetableSuccess} from "../../../../../actions/timetable";
import {
    ACCOUNTING_TYPE_WORK_ACCOUNTED,
    AccountingTypes,
    getAccountingTypeChoice
} from "../../../../../constants/booking";
import {refresh, refreshResource} from "../../../../../actions/calendar";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {Localisation} from "../../../../../models/localisation";
import {Activity} from "../../../../../models/activity";
import {setSeconds} from "date-fns";
import {Salary} from "../../../../../models/salary";

const TimetableEventEditForm:React.FC<{
    salary: Salary,
    start: Date,
    end: Date,
    eventId: number,
    daysOfWeek: number[],
    week: number,
    company: Company,
    timetable: Timetable,
    activity?: Activity,
    localisation?: Localisation,
    handleSubmitSuccess: (ev: any) => any,
    events: TimetableEvents }> = (props) => {

    const {start, timetable, salary, end, daysOfWeek, events, week, company, activity, localisation, handleSubmitSuccess, eventId } = props;

    const initialValues = {
        start: new Date(start),
        end: new Date(end),
        company: company.id!,
        activity: activity ? String(activity.id) : "",
        localisation: localisation ? String(localisation.id) : "",
        accountingType: ACCOUNTING_TYPE_WORK_ACCOUNTED,
        daysOfWeek: daysOfWeek.map((d: number) => String(d)),
    }

    const validationSchema = Yup.object().shape({
        start: Yup.date().required(),
        end: Yup.date().required().test("is-greater", "La fin doit être après le début", function(value: any) {
            const { start } = this.parent;

            if (!value) return false;

            if (start.getTime() > value.getTime()) {

                let clone = setSeconds(new Date(value), 0)
                clone.setDate(value.getDate() + 1)

                return clone.getTime() > start.getTime();
            }

            return value && start < value
        }),
        accountingType: Yup.number().required(),
        activity: Yup.number().nullable(),
        company: Yup.number().required(),
        localisation: Yup.number().nullable(),
        daysOfWeek: Yup.array().of(Yup.number()).min(1)
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.edit(values, eventId).then(resp => {
                formikHelpers.setSubmitting(false)
                handleSubmitSuccess(resp.data)
            })
        }
    })

    const overlap = () => events.filter(e => e.id !== eventId && e.week === week && !!e.daysOfWeek.filter(d => formik.values.daysOfWeek?.includes(String(d))).length && format(new Date(e.start), 'HH:mm') < format(formik.values.end, 'HH:mm') && format(new Date(e.end), 'HH:mm') > format(formik.values.start, 'HH:mm'))

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-md-12">
            <div className="row timetable-add-booking-form">
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                        customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`start`, date)
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        startDate={formik.values.start}
                        maxDate={formik.values.end}
                        showTimeInput={true}
                        renderDayContents={(dayOfMonth, date) => <span>{format(date!, 'E')}</span>}
                        renderCustomHeader={() => <></>}
                        openToDate={new Date("2019/07/01")}
                        minDate={new Date("2019/07/01")}
                        dateFormat="E HH:mm"

                    />
                </div>
                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                </div>
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeInput={true}
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`end`, date)
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        startDate={formik.values.start}
                        endDate={formik.values.end}
                        minDate={formik.values.start}
                        renderDayContents={(dayOfMonth, date) => <span>{format(date!, 'E')}</span>}
                        renderCustomHeader={() => <></>}
                        openToDate={new Date("2019/07/01")}
                        maxDate={new Date("2019/07/07")}
                        dateFormat="E HH:mm"
                        popperPlacement="bottom-end"
                    />
                </div>
            </div>
            <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
            <FormError touched={formik.touched} errors={formik.errors} field={'end'} />
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                label={"Type d'heure"}
                enableReinitialize={true}
                value={getAccountingTypeChoice(formik.values.accountingType)}
                options={AccountingTypes}
                onChange={(choice) => formik.setFieldValue('accountingType', choice!.value)}
                required={true}
            />
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting || timetable.type === 1}
                label={'Établissement'}
                required={true}
                value={{label: company.title, value: company.id!}}
                //options={salary.companies.map(c => ({label: c.title, value: c.id!}))}
                fetchEntity="company"
                fetchParams={{ salaries: [salary.id] }}
                onChange={(c) => formik.setFieldValue('company', c?.value)}
            />
        </div>
        <div className="row">
            <div className="col-md-6 mb-3">
                <BootstrapSelect
                    disabled={formik.isSubmitting}
                    label={'Emplacement'}
                    enableReinitialize={true}
                    value={formik.values.localisation && company?.localisations?.find(l => l.id === Number(formik.values.localisation)) ? {label: company.localisations?.find(l => l.id === Number(formik.values.localisation))!.title, value: company.localisations?.find(l => l.id === Number(formik.values.localisation))!.id!} : undefined}
                    //options={company.localisations!.map((l: Localisation) => ({label: l.title, value: l.id!}))}
                    fetchEntity="localisation"
                    fetchParams={{ companies: [formik.values.company] }}
                    onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                />
            </div>
            <div className="col-md-6 mb-3">
                <BootstrapSelect
                    disabled={formik.isSubmitting}
                    label={'Activité'}
                    enableReinitialize={true}
                    value={formik.values.activity && company?.activities?.find(l => l.id === Number(formik.values.activity)) ? {label: company.activities?.find(l => l.id === Number(formik.values.activity))!.title, value: company.activities?.find(l => l.id === Number(formik.values.activity))!.id!} : undefined}
                    //options={company.activities!.map((l: Activity) => ({label: l.title, value: l.id!}))}
                    fetchEntity="activity"
                    fetchParams={{ companies: [formik.values.company] }}
                    onChange={(c) => formik.setFieldValue('activity', c?.value)}
                />
            </div>
        </div>
        <div className="col-12 text-center mb-3">
            <div className="col-12 mb-2">
                <label>
                    Jours de la semaine
                </label>
            </div>
            {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                return (
                    <>
                        <input key={index}
                               disabled={formik.isSubmitting}
                               type="checkbox"
                               onChange={formik.handleChange}
                               name={'daysOfWeek'}
                               checked={formik.values.daysOfWeek?.includes(`${index}`)}
                               className="btn-check"
                               id={`btn-edit-check-${index}`}
                               autoComplete="off"
                               value={`${index}`} />
                        <label key={index} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-edit-check-${index}`}>
                            {day}
                        </label>
                    </>
                )
            })}
            <FormError touched={formik.touched} errors={formik.errors} field={'daysOfWeek'} />
        </div>
        {overlap().map(e => <>
            <div className="form-text text-danger mb-2">
                <i className={'bi bi-exclamation-circle'}></i>  Créneau déjà existant {e.description}
            </div>
            <div className="clearfix"> </div>
        </>)}
        <button disabled={!!overlap().length} type={'submit'} className={'btn btn-outline-primary w-100'}>
            {formik.isSubmitting ? <>
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></> : <><i className={'bi bi-check'}></i> Valider</>}
        </button>
    </form>
}

export default TimetableEventEditForm;