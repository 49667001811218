import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import * as model from "../../../models/expense";
import * as actions from "../../../actions/expense";
import * as types from "../../../constants/expense";
import * as api from "../../../adapters/expense";
import {axiosError} from "../../../actions/axios";
import BgSingle from "../../../utils/background/single";
import format from "../../../utils/locale";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EXPENSE_EDIT, FORM_NEW_EXPENSE_FILE} from "../../../constants/rightSidebar";
import * as globals from "../../../constants/global";
import * as accessRights from "../../../constants/accessRight";
import Loader from "../../../utils/loader";
import * as fileApi from "../../../adapters/expense/file";
import {showAlertDanger, showAlertSuccess} from "../../../actions/alert";
import FileSaver from "file-saver";
import {Granted} from "../../../security/granted";

const ExpenseShow:React.FC = () => {

    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const expenses:model.Expenses = useSelector((state: RootStateOrAny) => state.expense.payload)
    const expense:model.Expense = useSelector((state: RootStateOrAny) => state.expense.single)

    const [downloading, setDownloading] = useState<number>();
    const [deleting, setDeleting] = useState<number>();
    const [justificatifUrl, setJustificatifUrl] = useState<string>()
    const [loadingFile, setLoadingFile] = useState<boolean>(false)
    const [statusLoading, setStatusLoading] = useState(false)

    useEffect(() => {
        if (params.id){
            let single = expenses.find((e) => e.id!.toString() === params.id)
            if (single){
                dispatch(actions.showExpenseSuccess(single))
            }else{
                dispatch(actions.showExpense());
                api.get(params.id)
                    .then(data => dispatch(actions.showExpenseSuccess(data.data)))
                    .catch(error => dispatch(axiosError(error)))
            }
        }
    }, [params.id, expenses])

    const displayFile = (id: number) => {

        if (!expense) return false;

        let file = expense.files?.find((file => file.id === id))
        if (file){
            setLoadingFile(true)
            fileApi.download(expense.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                let url = URL.createObjectURL(newBlob);
                setJustificatifUrl(url)
                setLoadingFile(false)

            }).catch(error => dispatch(axiosError(error)))
        }else{
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const downloadJustification = (id: number) => {

        if (!expense) return false;

        let file = expense.files?.find((file => file.id === id))
        if (file){
            setDownloading(id);
            fileApi.download(expense.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                FileSaver.saveAs(newBlob, file!.title)
                setDownloading(undefined)
            }).catch(error => dispatch(axiosError(error)))
        }else{
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const manageStatus = (status: number) => {
        setStatusLoading(true)
        api.editStatus({status: status}, expense.id).then(resp => dispatch(actions.editExpenseSuccess(resp.data))).then(() => setStatusLoading(false))
    }

    const deleteJustification = (id: number) => {

        if (!expense) return false;

        let file = expense.files?.find((file => file.id === id))
        if (file){
            setDeleting(id);
            fileApi.remove(expense.id!, id).then(data => {
                let files = [...expense.files!]
                files.splice(files.findIndex(file => file.id === id), 1);
                expense.files = [...files];
                setDeleting(undefined)
                dispatch(showAlertSuccess('Fichier supprimé'))
            }).catch(error => dispatch(axiosError(error)))
        }else{
            dispatch(showAlertDanger('Une erreur est survenue lors de la supression ! Fichier introuvable.'))
        }
    }

    if (!expense){
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return <>
        <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!params.id ? " d-none d-md-block" : "")}>
        <div className="p-1 p-md-3 h-100">
            <div className="d-flex flex-column h-100">
                <div className="col-12 flex-grow-0">
                    <div className="p-3 card shadow-sm mb-3">
                        <div className="d-flex">
                            <div className="d-flex flex-column flex-grow-1">
                                <h4>
                                    {expense.title}
                                </h4>
                                <h4>
                                    <i className={'bi bi-person'}></i> {expense.salary.title}
                                </h4>
                                <p className={"text-light-s"}>
                                    Créé par {expense.createdBy.title} le {format(new Date(expense.createdAt), 'E dd MMMM uuuu')} à {format(new Date(expense.createdAt), 'HH:mm')}
                                </p>
                            </div>
                            <div className="flex-grow-0 d-flex align-items-center">
                                <div className="dropdown me-3">
                                    <button disabled={statusLoading || !Granted(accessRights.EDIT_EXPENSE, undefined, expense)} className={"btn btn-light dropdown-toggle" + (!Granted(accessRights.EDIT_EXPENSE, undefined, expense) ? " dropdown-toggle-no-after-content " : '')} data-bs-toggle="dropdown" aria-expanded="false" type={'button'}>
                                        <i className={types.getStatusIcon(expense.status)}></i> {types.STATUS_NAMES[expense.status]}
                                    </button>
                                    <ul className="dropdown-menu">
                                        {types.getStatusChoices().filter(c => c.value !== expense.status).map(c => <li
                                            onClick={() => manageStatus(c.value)}
                                            className={'dropdown-item'}>
                                            <i className={types.getStatusIcon(c.value)}></i> {types.STATUS_NAMES[c.value]}
                                        </li>)}
                                    </ul>
                                </div>
                                {Granted(accessRights.EDIT_EXPENSE, undefined, expense) && <button className={'btn btn-light'}
                                         onClick={() => dispatch(openSidebar(FORM_EXPENSE_EDIT, {...expense}))}>
                                    <i className={'bi bi-pencil text-primary'}></i>
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row flex-grow-1">
                    <div className="col-md-6 h-100">
                        <div className="p-3 card shadow-sm mb-3 h-100">
                            <h4>
                                Informations
                            </h4>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td>
                                        Type de dépense
                                    </td>
                                    <td>
                                        {types.TYPE_NAMES[expense.type]}
                                    </td>
                                </tr>

                                {expense.type === types.TYPE_MILEAGE && <>
                                    <tr>
                                        <td>
                                            Véhicule
                                        </td>
                                        <td>
                                            {types.VEHICLE_NAMES[expense.vehicle]}
                                        </td>
                                    </tr>
                                    {[types.VEHICLE_CAR, types.VEHICLE_MOTORCYCLE].includes(expense.vehicle) && <tr>
                                        <td>
                                            Puissance du Véhicule
                                        </td>
                                        <td>
                                            {types.getVehiclePowerName(expense.vehicle, expense.vehiclePower)}
                                        </td>
                                    </tr>}
                                    <tr>
                                        <td>
                                            Distance
                                        </td>
                                        <td>
                                            {expense.distance} KM
                                        </td>
                                    </tr>
                                </>}
                                <tr>
                                    <td>
                                        Montant de la dépense
                                    </td>
                                    <td>
                                        {expense.amount} {globals.CurrencyIcon(expense.currency)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Date de la dépense
                                    </td>
                                    <td>
                                        {format(new Date(expense.dueDate))}
                                    </td>
                                </tr>
                                {expense.type === types.TYPE_MEAL && <>
                                    <tr>
                                        <td>
                                            Commercant (facultatif)
                                        </td>
                                        <td>
                                            {expense.shop}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Déjeuner client
                                        </td>
                                        <td>
                                            {expense.clientMeal ? <i className={'bi bi-check text-success'}></i> : <i className={'bi bi-x text-danger'}></i>}
                                        </td>
                                    </tr>
                                </>}
                                <tr>
                                    <td>
                                        Commentaire
                                    </td>
                                    <td>
                                        {expense.comment}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6 h-100">
                        <div className="p-3 card shadow-sm mb-3 h-100">
                            <div className="d-flex">
                                <div className="flex-grow-1 mb-3">
                                    <h4>
                                        Justificatifs
                                    </h4>
                                </div>
                                <div className="flex-grow-0">
                                    {Granted(accessRights.EDIT_EXPENSE, undefined, expense) && <button onClick={() => dispatch(openSidebar(FORM_NEW_EXPENSE_FILE, {
                                        expense: expense
                                    }))} className={'btn btn-sm w-100 btn-outline-primary ms-2'}>
                                        <i className={'bi bi-plus'}> </i> <span>Ajouter un fichier</span>
                                    </button>}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className={'table table-sm table-striped align-middle'}>
                                    <tbody>
                                    {expense.files?.map((file, index) => <>
                                        <tr>
                                            <td>
                                                {file.title}
                                            </td>
                                            <td>
                                                <button onClick={() => displayFile(file.id)} type="button" className="btn btn-light me-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#justificatifModal">
                                                    <i className={'bi bi-eye'}> </i>
                                                </button>
                                            </td>
                                            <td>
                                                <button className={"btn btn-light me-2"} onClick={() => downloadJustification(file.id)}>
                                                    {downloading === file.id ? <div className="spinner-grow spinner-grow-sm" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div> : <i className="bi bi-download text-primary"> </i>}
                                                </button>
                                            </td>
                                            <td>
                                                {Granted(accessRights.EDIT_EXPENSE, undefined, expense) && <button className={"btn btn-light me-2"} onClick={() => deleteJustification(file.id)}>
                                                    {deleting === file.id ?
                                                        <div className="spinner-grow spinner-grow-sm"
                                                             role="status">
                                                                        <span
                                                                            className="visually-hidden">Loading...</span>
                                                        </div> :
                                                        <i className="bi bi-dash-circle text-danger"> </i>}
                                                </button>}
                                            </td>
                                        </tr>
                                    </>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="modal" id="justificatifModal" tabIndex={-1}>
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content h-100">
                <div className="modal-body d-flex flex-column" id={'#modalBody'}>
                    {loadingFile && <Loader />}
                    {!loadingFile && justificatifUrl && <iframe src={justificatifUrl} width={'100%'} className={'flex-grow-1'} frameBorder={0}>
                    </iframe>}
                </div>
            </div>
        </div>
    </div>
    </>
}

export default ExpenseShow;