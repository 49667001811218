import {post, get} from "../xhr";
import {Salaries} from "../../models/salary";

interface PostChannel {
    title: string,
    description: string|null,
    salaries: Salaries
}

export function list(){
    return get('channel/list');
}

export function edit(requestData: PostChannel, id: number){
    return post('channel/edit/' + id, requestData);
}

export function create(requestData: PostChannel){
    return post('channel/create',  requestData);
}

export function show(id: string){
    return get('channel/show/' + id);
}