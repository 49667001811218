import * as types from "../../../constants/auth";
import * as models from "../../../models/auth";

export const addUserInformation = () => {
    return {
        type: types.ADD_USER_INFORMATION,
    }
}

export const addUserInformationSuccess = (userInformation: models.UserInformation) => {
    return {
        type: types.ADD_USER_INFORMATION_SUCCESS,
        userInformation: userInformation,
    }
}

export const editUserInformation = () => {
    return {
        type: types.ADD_USER_INFORMATION,
    }
}

export const editUserInformationSuccess = (userInformation: models.UserInformation) => {
    return {
        type: types.ADD_USER_INFORMATION_SUCCESS,
        userInformation: userInformation,
    }
}