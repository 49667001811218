import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {SalarySelector} from "../../../../../utils/salarySelector";
import {closeSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import * as api from "../../../../../adapters/localisation/salaries"
import {showAlertSuccess} from "../../../../../actions/alert";
import {editLocalisationSuccess} from "../../../../../actions/localisation";
import {Localisation} from "../../../../../models/localisation";


const LocalisationSalariesAdd:React.FC = () => {

    const dispatch = useDispatch();
    const localisation : Localisation= useSelector((state: RootStateOrAny) => state.rightSidebar.data);

    const onSubmit = (ids: number[]) => {
        dispatch(submitSidebar());
        api.salaries(localisation.id!, ids).then((data) => {
            dispatch(editLocalisationSuccess(data.data))
            dispatch(showAlertSuccess("Effectif mis à jour"))
            dispatch(closeSidebar())
        })
    }

    return (<>
        <SalarySelector
            onSubmit={onSubmit}
            onClose={() => dispatch(closeSidebar())}
            initialValues={localisation.salaries!.map((salary) => salary.id!)} />
    </>)
}

export default LocalisationSalariesAdd;