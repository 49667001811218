import {get} from "../../../xhr";

export function apply(offerId: number){
    return get(`event/offer/${offerId}/application/apply`);
}

export function cancel(offerId: number, applicationId: number){
    return get(`event/offer/${offerId}/application/${applicationId}/cancel`);
}

export function validate(offerId: number, applicationId: number){
    return get(`event/offer/${offerId}/application/${applicationId}/validate`);
}

export function invalidate(offerId: number, applicationId: number){
    return get(`event/offer/${offerId}/application/${applicationId}/invalidate`);
}