export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_FAILURE = 'ADD_ACTIVITY_FAILURE';

export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
export const EDIT_ACTIVITY_SUCCESS = 'EDIT_ACTIVITY_SUCCESS';
export const EDIT_ACTIVITY_FAILURE = 'EDIT_ACTIVITY_FAILURE';

export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';

export const SHOW_ACTIVITY = 'SHOW_ACTIVITY';
export const SHOW_ACTIVITY_SUCCESS = 'SHOW_ACTIVITY_SUCCESS';
export const SHOW_ACTIVITY_FAILURE = 'SHOW_ACTIVITY_FAILURE';
