import React, {useEffect, useState} from "react";
import {Company} from "../../../../../models/companies";
import {useFormik} from "formik";
import {setHours, setMinutes} from "date-fns";
import * as Yup from "yup";
import * as api from "../../../../../adapters/event/offer";
import {showAlertSuccess} from "../../../../../actions/alert";
import {SingleCompanySelected, SingleCompanySelector} from "../../../../../utils/companySelector/single";
// import {SalarySelected, SalarySelector} from "../../../../../utils/salarySelector";
import DatePicker from "react-datepicker";
import validationClass from "../../../../../utils/validationClass";
import DateCustomInput from "../../../../../utils/customInput";
import FormError from "../../../../../utils/formError";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {useDispatch} from "react-redux";
import {Localisation} from "../../../../../models/localisation";
import {Activity} from "../../../../../models/activity";
import SmallLoader from "../../../../../utils/loader/small";
import {Salaries} from "../../../../../models/salary";
import SalarySelector from "../../../../salary/selector";
import format from "../../../../../utils/locale";

interface Interface {
    handleSubmitSuccess: (ev:any) => any,
    _company?: Company,
    localisation?: Localisation,
    activity?: Activity,
    salaries?: Salaries,
    start?: Date,
    end?: Date,
}

const OfferAddForm:React.FC<Interface> = (props) => {

    const {_company, localisation, activity, salaries, handleSubmitSuccess, start, end} = props;

    const dispatch = useDispatch();
    const [displayCompanySelector, setDisplayCompanySelector] = useState<boolean>(false);
    const [displaySalarySelector, setDisplaySalarySelector] = useState<boolean>(false);
    const [company, setCompany] = useState<Company|undefined>(_company);

    const formik = useFormik({
        initialValues: {
            description: "",
            start: start || setMinutes(new Date(), 0),
            end: end || setMinutes(new Date(), 30),
            company: _company?.id,
            localisation: localisation?.id,
            activity: activity?.id,
            salaries: salaries?.map(s => s.id) || [],
        },
        validationSchema: Yup.object().shape({
            description: Yup.string(),
            start: Yup.date().required(),
            end: Yup.date().required(),
            company: Yup.number().required(),
            localisation: Yup.number(),
            activity: Yup.number(),
            salaries: Yup.array().of(Yup.number()).min(1, "Veuillez séléctionner au moins un collaborateur"),
        }),
        onSubmit: (values, formikHelpers) => {
            api.create(values).then(resp => {
                Promise.all([
                    handleSubmitSuccess(resp.data)
                ]).then(() => {
                    dispatch(showAlertSuccess('Offre de créneau ajoutée'));
                    formikHelpers.setSubmitting(false)
                })
            });
        }
    })

    return <div className={'p-1 p-md-3'}>
        {displayCompanySelector && <SingleCompanySelector
            onSubmit={(company) => {
                setCompany(company)
                formik.setFieldValue('company', company.id)
                setDisplayCompanySelector(false)
            }}
            onClose={() => setDisplayCompanySelector(false)}
            initialValue={company?.id}
        />}
        <form className={(displayCompanySelector || displaySalarySelector) ? 'd-none' : 'd-block'} onSubmit={formik.handleSubmit}>
            <div className="col-md-12 mb-3">
                <div className="input-group">
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                        customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`start`, date)
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        endDate={formik.values.end}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                    <span className="input-group-text">
                                        <i className={'bi bi-arrow-left-right mx-1'}> </i>
                                    </span>
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`end`, date)
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        startDate={formik.values.start}
                        minDate={formik.values.start}
                        minTime={setHours(setMinutes(new Date(),
                                new Date(formik.values.end).setHours(0,0,0,0) === new Date(formik.values.start).setHours(0,0,0,0) ? new Date(formik.values.start).getMinutes() : 0
                            ),
                            new Date(formik.values.end).setHours(0,0,0,0) === new Date(formik.values.start).setHours(0,0,0,0) ? new Date(formik.values.start).getHours() : 0
                        )}
                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                        timeIntervals={15}
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                </div>
                <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
                <FormError touched={formik.touched} errors={formik.errors} field={'end'} />
            </div>
            <div className="col-12 mb-3">
                <SingleCompanySelected
                    onDelete={() => setDisplayCompanySelector(true)}
                    onOpen={() => setDisplayCompanySelector(true)}
                    initialValue={company?.id}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'company'} />
            </div>
            {company && <div className={'d-flex'}><div className="col-md-6 mb-3">
                <BootstrapSelect
                    label={"Emplacement"}
                    //options={company.localisations?.map(l => ({label: l.title, value: l.id!}))}
                    fetchEntity="localisation"
                    onChange={(choice) => formik.setFieldValue('localisation', choice?.value)}
                    required={false}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'localisation'} />
            </div>
                <div className="col-md-6 mb-3">
                    <BootstrapSelect
                        label={"Activité"}
                        //options={company.activities?.map(a => ({label: a.title, value: a.id!}))}
                        fetchEntity="activity"
                        onChange={(choice) => formik.setFieldValue('activity', choice?.value)}
                        required={false}
                    />
                    <FormError touched={formik.touched} errors={formik.errors} field={'activity'} />
                </div></div>}
            <div className="col-12 mb-3">
                <SalarySelector
                    fetchOptions={true}
                    available={true}
                    companies={company ? [company.id!] : undefined}
                    beginAt={formik.values.start}
                    endAt={formik.values.end}
                    multiple={true}
                    values={salaries}
                    teams={true}
                    onChange={(s) => formik.setFieldValue('salaries', s.map(s => s.id))}
                />
               <FormError touched={formik.touched} errors={formik.errors} field={'salaries'} />
            </div>
            <div className="col-12 mb-3">
                <textarea onChange={formik.handleChange} className={'form-control'} placeholder={'Description'} name="description" id="description">

                </textarea>
                <FormError touched={formik.touched} errors={formik.errors} field={'description'} />
            </div>
            <button type={"submit"} className={'btn w-100 btn-light'}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check text-success'}> </i> Ajouter</>}
            </button>
        </form>
    </div>
}

export default OfferAddForm;