import React, {BaseSyntheticEvent, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../utils/customInput";
import FormError from "../../../utils/formError";
import format from "../../../utils/locale";
import * as API from "../../../adapters/reporting";
import FileSaver from "file-saver";
import {ObjectSchema} from "yup";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {Choice} from "../../../utils/form/model";
import {Salary} from "../../../models/salary";
import * as accessRights from "../../../constants/accessRight";
import SalarySelector from "../../salary/selector";

const ExportReporting:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const [initialValues, setInitialValues] = useState<any>({
        start: state.rightSidebar.data?.start || new Date(),
        end: state.rightSidebar.data?.end || new Date(),
        type: 1,
        company: null
    });
    const [salary, setSalary] = useState<Choice>()

    const [validationSchema, setValidationSchema] = useState<ObjectSchema<any>>(Yup.object().shape({
        start: Yup.date().required("Veuillez préciser un début de période"),
        end: Yup.date().required("Veuillez préciser une fin de période"),
        company: Yup.number().nullable()
    }));

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, helpers) => {
            helpers.setSubmitting(true)

            switch (values.type){
                case 1:
                    API.exportPeriods({
                        start: format(values.start, 'uuuu-MM-dd'),
                        end: format(values.end, 'uuuu-MM-dd'),
                        company: values.company,
                        jobs: values.jobs
                    }).then(data => {
                        let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                        FileSaver.saveAs(newBlob, `export-periods-${values.company}-${format(values.start, 'uuuu-MM-dd')}-${format(values.end, 'uuuu-MM-dd')}.xlsx`)
                        helpers.setSubmitting(false)
                    })
                    break;
                case 2:
                    API.exportDays({
                        start: format(values.start, 'uuuu-MM-dd'),
                        end: format(values.end, 'uuuu-MM-dd'),
                    }, values.salary).then(data => {
                        let newBlob = new Blob([data.data], { type: 'application/pdf;charset=utf-8' })
                        FileSaver.saveAs(newBlob, `reporting-days-${salary?.label}-${format(values.start, 'uuuu-MM-dd')}-${format(values.end, 'uuuu-MM-dd')}.pdf`)
                        helpers.setSubmitting(false);
                    })
                    break;
                case 3:
                    API.exportDaysGrouped({
                        start: format(values.start, 'uuuu-MM-dd'),
                        end: format(values.end, 'uuuu-MM-dd'),
                    }, values.salary).then(data => {
                        let newBlob = new Blob([data.data], { type: 'application/pdf;charset=utf-8' })
                        FileSaver.saveAs(newBlob, `reporting-days-${salary?.label}-${format(values.start, 'uuuu-MM-dd')}-${format(values.end, 'uuuu-MM-dd')}.pdf`)
                        helpers.setSubmitting(false);
                    })
                    break;
            }
        }
    });

    const handleTypeChange = (value: number) => {
        let _validationSchema = {...validationSchema.fields};
        let _initialValues = {...formik.values};

        if (value === 1){
            _initialValues.company = null;
            _validationSchema.company = Yup.number().nullable()
            _initialValues.jobs = null;
            _validationSchema.jobs = Yup.array().of(Yup.string()).nullable()
            delete _initialValues.salary
            delete _validationSchema.salary
        }else if(_initialValues.type === 1){
            _initialValues.salary = null;
            _validationSchema.salary = Yup.number().required("Veuillez séléctionner un collaborateur");
            delete _initialValues.company
            delete _validationSchema.company
            delete _initialValues.jobs
            delete _validationSchema.jobs
        }

        _initialValues.type = value;
        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    return <>
        <div className="p-1 p-md-3">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <DatePicker
                            className={"form-control"}
                            selected={formik.values.start}
                            customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                            selectsStart
                            onChange={(dt: Date) => formik.setFieldValue('start', dt)}
                            endDate={formik.values.end}
                            dateFormat="E dd MMM uuuu"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                        />
                        <span className={'input-group-text'}>
                            <i className={'bi bi-arrow-left-right'}> </i>
                        </span>
                        <DatePicker
                            startDate={formik.values.start}
                            className={"form-control"}
                            selected={formik.values.end}
                            customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-x text-primary'}/>}
                            selectsEnd
                            onChange={(dt: Date) => formik.setFieldValue('end', dt)}
                            dateFormat="E dd MMM uuuu"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                        />
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={"start"} />
                    <FormError errors={formik.errors} touched={formik.touched} field={"end"} />
                </div>
                <div className="col-12 mb-3">
                    <label className={"mb-3"}>
                        Type de téléchargement
                    </label>
                    <FormError errors={formik.errors} touched={formik.touched} field={'type'} />
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => handleTypeChange(1)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked={true}/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Périodique
                            </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => handleTypeChange(2)} type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Détail par jour + suivi compteur (semaines complètes)
                            </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => handleTypeChange(3)} type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                Détail par jour (date à date)
                            </label>
                    </div>
                </div>
                {formik.values.type === 1 && <>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                                label={"Point de vente"}
                                placeholder={'Séléctionner un point de vente'}
                                fetchEntity={"company"}
                                required={false}
                                onChange={(choice) => formik.setFieldValue("company", choice!.value)}
                            />
                        <FormError errors={formik.errors} touched={formik.touched} field={'company'} />
                    </div>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            label={"Métiers"}
                            fetchEntity={"job"}
                            required={false}
                            isMultiple={true}
                            onChangeMultiple={(choices) => formik.setFieldValue("jobs", choices?.map(choice => choice.value))}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'jobs'} />
                    </div>
                </>}
                {[2,3].includes(formik.values.type) && <div className="col-12 mb-3">
                    <SalarySelector
                        fetchOptions={true}
                        required={true}
                        onChange={(choice: Salary) => formik.setFieldValue('salary', choice.id)}
                        accessRight={accessRights.EXPORT_REPORTING}
                    />

                    <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
                </div>}
                <button disabled={formik.isSubmitting} type={"submit"} className={'btn btn-outline-primary w-100'}>
                    {formik.isSubmitting ? <>
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> Téléchargement en cours...</> : <><i className={'bi bi-check'}> </i> Télécharger</>}
                </button>
            </form>
        </div>
    </>
}

export default ExportReporting;