import React from "react";
import {Activity} from "../../../../../models/activity";
import {Localisation} from "../../../../../models/localisation";
import * as Yup from "yup";
import {useFormik} from "formik";
import format from "../../../../../utils/locale";
import DatePicker from "react-datepicker";
import validationClass from "../../../../../utils/validationClass";
import DateCustomInput from "../../../../../utils/customInput";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {AccountingTypes} from "../../../../../constants/booking";
import {Template} from "../../../../../models/template";
import SmallLoader from "../../../../../utils/loader/small";
import FormError from "../../../../../utils/formError";
import * as api from "../../../../../adapters/template/event";
import {showAlertSuccess} from "../../../../../actions/alert";
import {useDispatch} from "react-redux";

interface Interface {
    template: Template
    resource?: {
        title: string,
        id: number
    },
    start?: Date,
    end?: Date,
    activity?: Activity
    localisation?: Localisation,
    handleSubmitSuccess: (ev: any, previousEv?: any) => any
}

const EventAddForm:React.FC<Interface> = (props) => {


    const {template, resource, start, end, activity, localisation, handleSubmitSuccess} = props;

    const dispatch = useDispatch();

    const initialValues: any = {
        resource: resource?.id,
        start: start,
        end: end,
        accountingType: 1,
        activity: activity?.id,
        localisation: localisation?.id,
        daysOfWeek: [String(Number(format(start || new Date(), 'i')) - 1)],
    }

    const validationSchema = Yup.object().shape({
        resource: Yup.number().required(),
        start: Yup.date().required(),
        end: Yup.date().required(),
        accountingType: Yup.number().required(),
        activity: Yup.number().nullable(),
        localisation: Yup.number().nullable(),
    })

    const formik = useFormik({
       initialValues: initialValues,
       validationSchema: validationSchema,
       onSubmit: (values, formikHelpers) => {
           api.create(values).then(resp => {
               Promise.all([
                   handleSubmitSuccess(resp.data)
               ]).then(() => {
                   dispatch(showAlertSuccess('Créneau ajouté'));
                   formikHelpers.setSubmitting(false)
               })
           });
       }
    });

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                required={true}
                label={"Resource"}
                options={template.resources.map(r => ({label: r.title, value: r.id}))}
                value={resource ? {label: resource.title, value: resource.id} : undefined}
                onChange={(c) => formik.setFieldValue('resource', c?.value)}
            />
            <FormError touched={formik.touched} errors={formik.errors} field={'resource'} />
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                        customInput={<DateCustomInput label={'Heure de début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`start`, date)
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        startDate={formik.values.start}
                        maxDate={formik.values.end}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                </div>
                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                </div>
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Heure de fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`end`, date)
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                </div>
            </div>
            <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
            <FormError touched={formik.touched} errors={formik.errors} field={'end'} />
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                required={true}
                label={"Type d'heure"}
                options={AccountingTypes}
                value={AccountingTypes[0]}
                onChange={(c) => formik.setFieldValue('accountingType', c?.value)}
            />
            <FormError touched={formik.touched} errors={formik.errors} field={'accountingType'} />
        </div>
        <div className="row">
            <div className="col-md-6 mb-3">
                <BootstrapSelect
                    className={validationClass(formik.errors, formik.touched, 'localisation')}
                    disabled={formik.isSubmitting}
                    //options={template.company.localisations?.map(l => ({label: l.title, value: l.id!}))}
                    fetchEntity="localisation"
                    onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                    label={"Emplacement"}
                    value={localisation ? {label: localisation.title, value: localisation.id!} : undefined}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'localisation'} />
            </div>
            <div className="col-md-6 mb-3">
                <BootstrapSelect
                    className={validationClass(formik.errors, formik.touched, 'activity')}
                    disabled={formik.isSubmitting}
                    //options={template.company.activities?.map(a => ({label: a.title, value: a.id!}))}
                    fetchEntity="activity"
                    onChange={(c) => formik.setFieldValue('activity', c?.value)}
                    label={"Activité"}
                    value={activity ? {label: activity.title, value: activity.id!} : undefined}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'activity'} />
            </div>
        </div>
        <div className="col-12 mb-3 text-center">
            <div className="col-12 mb-3">
                <label>
                    Jours de la semaine
                </label>
            </div>
            {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                return (
                    <>
                        <input key={day}
                               disabled={formik.isSubmitting}
                               type="checkbox"
                               onChange={formik.handleChange}
                               name={'daysOfWeek'}
                               checked={formik.values.daysOfWeek.includes(`${index}`)}
                               className="btn-check"
                               id={`btn-check-${index}`}
                               autoComplete="off"
                               value={`${index}`} />
                        <label key={index} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-check-${index}`}>
                            {day}
                        </label>
                    </>
                )
            })}
            <FormError touched={formik.touched} errors={formik.errors} field={'daysOfWeek'} />
        </div>
        <button className={'btn btn-light w-100'}>
            {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-plus-circle text-primary'}></i> Ajouter</>}
        </button>
    </form>
}

export default EventAddForm;