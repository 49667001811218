import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Salaries, Salary} from "../../../models/salary";
import Loader from "../../../utils/loader";
import {available} from "../../../adapters/salary";
import format from "../../../utils/locale"
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import * as API from "../../../adapters/booking"
import * as eventAPI from "../../../adapters/event"
import * as eventExceptionAPI from "../../../adapters/event/exception"
import * as jobApi from "../../../adapters/job";
import Select from "react-select";
import {Job, Jobs} from "../../../models/job";
import {refresh} from "../../../actions/calendar";
import {PAGINATION} from "../../../constants/global";

const BookingSubstitute: React.FC = () => {

    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const [substitutes, setSubstitutes] = useState<Salaries>([])
    const [filteredSubstitutes, setFilteredSubstitutes] = useState<Salaries>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isJobsLoading, setIsJobsLoading] = useState(true)
    const [isLast, setIsLast] = useState(false)
    const [filteredJobs, setFilteredJobs] = useState<number[]>([data.salary.information.job.id])
    const [offset, setOffset] = useState<number>(0)
    const [jobs, setJobs] = useState<Jobs>([])
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();
    let company = data.company.id
    let jobFetchTimer: ReturnType<typeof setTimeout>;

    useEffect(() => {
        Promise.all([
            jobApi.list().then(data => {
                setJobs(data.data)
                setFilteredJobs(data.data.map((job: Job) => job.id))
                setIsJobsLoading(false)
                setOffset(1)
            }),
            available({beginAt:  format(data.start, 'uuuu-MM-dd HH:mm:ss'), endAt: format(data.end, 'uuuu-MM-dd HH:mm:ss'), options: {companies: [company]}})
                .then(data => {
                    setSubstitutes([...data.data])
                    let ret = [...data.data];
                    if (filteredJobs.length){
                        ret = [...ret.filter((salary: Salary) => filteredJobs.includes(salary.information?.job.id!))]
                    }

                    setFilteredSubstitutes([...ret]);
                })
        ]).then(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        if (query || filteredJobs.length){
            let ret = [...substitutes];
            if (query){
                ret = [...ret.filter((salary: Salary) => (salary.firstname + ' ' + salary.lastname).toLowerCase().includes(query.toLowerCase()))]
            }
            if (filteredJobs.length){
                ret = [...ret.filter((salary: Salary) => filteredJobs.includes(salary.information?.job.id!))]
            }

            setFilteredSubstitutes([...ret]);
        }else{
            setFilteredSubstitutes([...substitutes]);
        }
    }, [query, filteredJobs])

    const handleClick = (id: number) => {

        dispatch(submitSidebar());

        switch (data.eventType)
        {
            case 'booking':
                API.createException({
                    accountingType: data.accountingType,
                    salary: data.salary.id,
                    substitute: id,
                    activity: data.activity?.id,
                    localisation: data.localisation?.id,
                    company: data.company.id,
                    beginAt: data.start,
                    endAt: data.end
                }, data.instanceId, data.instanceToken).then(() => handleSuccess())
                break;
            case 'bookingException':
                API.substituteException(data.instanceId, id).then(() => handleSuccess())
                break;
            case 'event':
                eventExceptionAPI.create({
                    accountingType: data.accountingType,
                    salary: data.salary.id,
                    substitute: id,
                    activity: data.activity?.id,
                    localisation: data.localisation?.id,
                    company: data.company.id,
                    start: data.start,
                    end: data.end,
                    exdate: data.start,
                }, data.eventId).then(() => handleSuccess())
                break;
            case 'eventException':
                eventExceptionAPI.edit({
                    accountingType: data.accountingType,
                    salary: data.salary.id,
                    substitute: id,
                    activity: data.activity?.id,
                    localisation: data.localisation?.id,
                    company: data.company.id,
                    start: data.start,
                    end: data.end
                }, data.exceptionId).then(() => handleSuccess())
                break;
        }
    }

    const handleSuccess = () => {
        dispatch(refresh());
        dispatch(showAlertSuccess('Créneau remplacé'))
        dispatch(closeSidebar());
    }

    if (isLoading) return <Loader/>

    return <>
        <div className="p-3">
            <div className="d-flex align-items-center">
                <div className="col">
                    <h4>{data.salary.title}</h4>
                    <div className="clearfix"> </div>
                    <span>
                        <i className={'bi bi-calendar'}> </i>
                        {format(data.start, 'E dd MMMM HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(data.end, 'E dd MMMM HH:mm')}
                    </span>
                </div>
                <div className="col-auto">
                    <span>
                       <i className={'bi bi-person-bounding-box'}> </i> {data.salary.information.job.title}
                    </span>
                    <div className="clearfix"> </div>
                    <span>
                        <i className={'bi bi-shop'}> </i> {data.company.title}
                    </span>
                </div>
            </div>
            <hr/>
            <div className="col-12 mb-2">
                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <span className="input-group-text" id="search-addon">
                                <i className="bi bi-search"> </i>
                            </span>
                            <input type="search" onChange={(e) => {
                                setQuery(e.target.value);
                            }} className="form-control bg-white" placeholder="Rechercher un collaborateur" aria-describedby="search-addon" />
                        </div>
                    </div>
                    <div className="col">
                        <Select
                            name={'jobs'}
                            isMulti={true}
                            defaultValue={{label: data.salary.information.job.title, value: data.salary.information.job.id}}
                            placeholder={'Séléctionner un ou plusieurs métiers'}
                            id={"jobSelect"}
                            isLoading={isJobsLoading}
                            loadingMessage={(e) => {
                                return 'Chargement des métiers...'
                            }}
                            onChange={(choices) => {
                                let values: number[];
                                values = choices.map(choice => choice.value!);
                                setFilteredJobs(values)
                            }}
                            closeMenuOnSelect={true}
                            onMenuClose={() => setJobs([...jobs])}
                            onKeyDown={(event) => {
                                clearTimeout(jobFetchTimer);

                                let el = event.target as HTMLInputElement;

                                jobFetchTimer = setTimeout(() => {
                                    setIsJobsLoading(true)
                                    jobApi.list({query: el.value, offset: 0}).then(data => {
                                        setJobs(data.data)
                                        setIsLast(data.data.length < PAGINATION);
                                        setOffset(1)
                                        setIsJobsLoading(false)
                                    })
                                }, 1500)
                            }}
                            onMenuScrollToBottom={() => {
                                if (!isLast){
                                    setIsJobsLoading(true)
                                    jobApi.list({offset: offset}).then(data => {
                                        setJobs([...jobs, data.data])
                                        setIsLast(data.data.length < PAGINATION);
                                        setOffset(prevState => prevState + 1)
                                        setIsJobsLoading(false)
                                    })
                                }
                            }}
                            hideSelectedOptions={true}
                            className="basic-single"
                            classNamePrefix="select"
                            options={jobs.map(job => { return {label: job.title, value: job.id}})}
                        />
                    </div>
                </div>
            </div>
            <ul className={'list-group list-group-flush'}>
                {filteredSubstitutes.map((salary: Salary) => <li style={{cursor: 'pointer'}} onClick={() => handleClick(salary.id!)} className={'btn-light list-group-item'}>
                   <div className="d-flex align-items-center">
                       <div className="col">
                           {salary.title}
                           <div className="clearfix"> </div>
                           <span className="text-light-s">
                               {salary.information?.job.title}
                           </span>
                       </div>
                       <div className="col-auto">
                           <div className="color-circle col-auto">
                               <div className="p-1 shadow" style={{backgroundColor: `${salary.information?.job.color}`, opacity: 0.8}}> </div>
                           </div>
                       </div>
                   </div>
                </li>)}
            </ul>
        </div>
    </>

}

export default BookingSubstitute;