import * as xhr from "../../xhr";

export function edit(requestData: any, teamId: number, managerId: number){
    return xhr.post(`team/${teamId}/manager/edit/${managerId}`, requestData);
}

export function create(requestData: any, teamId: number){
    return xhr.post(`team/${teamId}/manager/create`,  requestData);
}

export function remove(teamId: number, managerId: number){
    return xhr.get(`team/${teamId}/manager/remove/${managerId}`);
}
