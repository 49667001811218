import * as types from "../../constants/accessLevel"
import * as models from "../../models/accessLevel"
import replace from "../../utils/arrayReplace"

const accessLevel = (state: models.initialStateInterface = models.initialState, action: {type: string, accessLevel: models.AccessLevel, accessLevels: models.AccessLevels, error: string}) => {

    switch (action.type) {
        case types.FETCH_ACCESS_LEVELS:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.FETCH_ACCESS_LEVELS_SUCCESS:
            return { payload: action.accessLevels, single: state.single, isLoading: false, error: null };
        case types.FETCH_ACCESS_LEVELS_FAILURE:
            return { payload: action.accessLevels, single: state.single, isLoading: false, error: action.error };

        case types.ADD_ACCESS_LEVEL:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.ADD_ACCESS_LEVEL_SUCCESS:
            return { payload: [...state.payload, action.accessLevel], single: state.single, isLoading: false, error: null };
        case types.EDIT_ACCESS_LEVEL:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_ACCESS_LEVEL_SUCCESS:
            return { payload: replace(state.payload, action.accessLevel), single: action.accessLevel, isLoading: false, error: null };

        case types.SHOW_ACCESS_LEVEL:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_ACCESS_LEVEL_SUCCESS:
            return { payload: state.payload, single: action.accessLevel, isLoading: false, error: null };
        case types.SHOW_ACCESS_LEVEL_FAILURE:
            return { payload: state.payload, single: null, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default accessLevel;