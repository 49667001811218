import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Salaries, Salary} from "../../../../models/salary";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {
    TaskGroupCustomField,
    TaskGroupCustomFieldValue,
    TaskGroupCustomFieldValues
} from "../../../../models/taskGroup/customField";
import {TaskGroup} from "../../../../models/taskGroup";
import DatePicker from "react-datepicker";
import {CustomInput} from "../../../../constants/taskGroup/customField";
import * as api from "../../../../adapters/task";
import {showAlertSuccess} from "../../../../actions/alert";
import {Task} from "../../../../models/task";
import FormError from "../../../../utils/formError";
import SalarySelector from "../../../salary/selector";
import DateCustomInput from "../../../../utils/customInput";
import SmallLoader from "../../../../utils/loader/small";
import * as accessRights from "../../../../constants/accessRight";

interface Interface {
    task: {
        title: string,
        exDate: Date,
        description: string,
        allDay: boolean,
        taskGroup: TaskGroup,
        salaries: Salaries,
    },
    customFieldsValues: TaskGroupCustomFieldValues,
    start: Date,
    end: Date|null,
    id: number,
    isException: boolean,
    handleSubmitSuccess: (ev: any) => any
}

const TaskEditForm: React.FC<Interface> = (props) => {

    const {task, start, end, customFieldsValues, isException, id, handleSubmitSuccess} = props;
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState<any>({
        task: {
            beginAt: start,
            title: task.title,
            description: task.description,
            allDay: task.allDay,
            salaries: task.salaries.map((salary: Salary) => salary.id),
        },
        customFields: {}
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        task: Yup.object().shape({
            title: Yup.string().required('Champs requis'),
            description: Yup.string().nullable(),
            allDay: Yup.boolean().required(),
            beginAt: Yup.date().required(),
            salaries: Yup.array().of(Yup.number()).nullable(),
        }),
        customFields: Yup.object().shape({})
    }));

    useEffect(() => {

        let _taskValidationSchema = {...validationSchema.fields.task.fields}
        let _customFieldsValidationSchema: any = {}
        let _initialValues = {...initialValues}

        if (!task.allDay){
            _initialValues.task.endAt =  new Date(end!)
            _taskValidationSchema.endAt =  Yup.date().required('Fin de créneau requis')
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { beginAt } = this.parent;
                    return value && beginAt <= value
                })
        }

        if (task.taskGroup.customFields?.length){
            task.taskGroup.customFields?.map((customField: TaskGroupCustomField) => {
                switch (customField.type){
                    case 1:
                        _customFieldsValidationSchema[customField.id!] = Yup.string().nullable()
                        _initialValues.customFields[customField.id!] = customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                        break;
                    case 2:
                        _customFieldsValidationSchema[customField.id!] = Yup.string().nullable()
                        _initialValues.customFields[customField.id!] = customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                        break;
                    case 3:
                        _customFieldsValidationSchema[customField.id!] = Yup.number().nullable()
                        _initialValues.customFields[customField.id!] = customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                        break;
                    case 4:
                        _customFieldsValidationSchema[customField.id!] = Yup.date().nullable()
                        let dateValue = customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value;
                        _initialValues.customFields[customField.id!] = dateValue ? new Date(dateValue) : null;
                        break;
                    case 5:
                        _customFieldsValidationSchema[customField.id!] = Yup.string().nullable()
                        _initialValues.customFields[customField.id!] = customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                        break;
                    case 6:
                        _customFieldsValidationSchema[customField.id!] = Yup.array().of(Yup.number()).nullable()
                        _initialValues.customFields[customField.id!] = customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || [];
                        break;
                }
            })
        }

        setInitialValues(_initialValues);
        formik.setFieldValue('task', initialValues.task)
        formik.setFieldValue('customFields', initialValues.customFields)
        setValidationSchema(Yup.object().shape({
            task: Yup.object().shape({..._taskValidationSchema}),
            customFields: Yup.object().shape({..._customFieldsValidationSchema})
        }));
    }, [])

    const handleAllDayChange = (allDay: boolean) => {
        let _taskValidationSchema = {...validationSchema.fields.task.fields}
        let _customFieldsValidationSchema = {...validationSchema.fields.customFields.fields}
        let _values = {...formik.values};

        _values.task.allDay = allDay;

        if (allDay){
            delete _taskValidationSchema.endAt
            delete _values.task.endAt;
        }else{
            _values.task.endAt = new Date(_values.task.beginAt)
            _taskValidationSchema.endAt = Yup.date().required();
        }

        setInitialValues({..._values})
        setValidationSchema(Yup.object().shape({
            task: Yup.object().shape({..._taskValidationSchema}),
            customFields: Yup.object().shape({..._customFieldsValidationSchema})
        }))
    }

    const handleSubmit = (data: Task, helpers: FormikHelpers<any>) => {
        Promise.all([
            handleSubmitSuccess(data),
        ]).then(() => dispatch(showAlertSuccess('Tâche modifiée')))
        .then(() => helpers.setSubmitting(false))
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, helpers) => {
           if (isException){            
                api.editException(values, id).then((resp) => {
                    handleSubmit(resp.data, helpers)
                })
           }else{
               values.task.exDate = task.exDate;
               api.createException(values, id).then((resp) => {
                   handleSubmit(resp.data, helpers)
               })
           }
       }
    });

   return <>
       <div className="p-3">
           <div className="col-12 mb-2">
               <form onSubmit={formik.handleSubmit}>
                   <div className="col-12 mb-2">
                       <div className="form-floating">
                           <input
                               defaultValue={task.title}
                               type="text"
                               className={'form-control'}
                               name={'task.title'}
                               onChange={formik.handleChange}
                           />
                           <label htmlFor="">
                                Titre
                           </label>
                       </div>
                       <FormError errors={formik.errors} touched={formik.touched} field={'task.title'} />
                   </div>
                   <div className="col-12 mb-2">
                       <label htmlFor="">
                           Description
                       </label>
                       <textarea
                           defaultValue={task.description}
                           className={'form-control'}
                           name={'task.description'}
                           onChange={formik.handleChange} >
                       </textarea>
                       <FormError errors={formik.errors} touched={formik.touched} field={'task.description'} />
                   </div>
                   <div className="col-12 mb-2">
                       <div className="form-check">
                           <input onChange={(e) => {
                               handleAllDayChange(e.target.checked)
                           }} defaultChecked={task.allDay} className="form-check-input" type="checkbox" value="" id="allDay" />
                           <label className="form-check-label" htmlFor="allDay">
                               Toute la journée
                           </label>
                       </div>
                   </div>
                   <div className="col-12 mb-2">
                       {formik.values.task.allDay ? <>
                           <DatePicker
                               customInput={<DateCustomInput label={'Date'}  />}
                               className={'form-control'}
                               onChange={(date) => formik.setFieldValue('task.beginAt', date)}
                               selected={formik.values.task.beginAt}
                               dateFormat={'E dd MMM uuuu'}
                           />
                           <FormError errors={formik.errors} touched={formik.touched} field={'task.beginAt'} />
                       </> : <>
                           <div className="input-group">
                               <DatePicker
                                   showTimeSelect
                                   timeIntervals={15}
                                   timeCaption="Heure"
                                   className={'form-control'}
                                   selected={formik.values.task.beginAt}
                                   selectsStart
                                   endDate={formik.values.task.endAt}
                                   onChange={(date) => formik.setFieldValue('task.beginAt', date)}
                                   dateFormat={'E dd MMM uuuu HH:mm'}
                                   customInput={<DateCustomInput label={'Début'}  />}
                               />
                               <span className="input-group-text">
                           <i className="bi bi-arrow-left-right"> </i>
                       </span>
                               <DatePicker
                                   showTimeSelect
                                   timeIntervals={15}
                                   timeCaption="Heure"
                                   className={'form-control'}
                                   selected={formik.values.task.endAt}
                                   selectsEnd startDate={formik.values.task.beginAt}
                                   onChange={(date) => formik.setFieldValue('task.endAt', date)}
                                   dateFormat={'E dd MMM uuuu HH:mm'}
                                   customInput={<DateCustomInput label={'Fin'}  />}
                               />
                           </div>
                           <FormError errors={formik.errors} touched={formik.touched} field={'task.beginAt'} />
                           <FormError errors={formik.errors} touched={formik.touched} field={'task.endAt'} />
                       </>}
                   </div>
                   <div className="col-md-12 mb-2">
                       <SalarySelector
                           fetchOptions={true}
                           multiple={true}
                           onChange={(v: Salaries) => formik.setFieldValue('task.salaries', v.map(s => s.id))}
                           teams={true}
                           values={task.salaries}
                           available={false}
                           accessRight={accessRights.EDIT_TASK}
                       />
                       <FormError errors={formik.errors} touched={formik.touched} field={'task.salaries'} />
                   </div>
                   {task.taskGroup.customFields?.map((customField) => <>
                       <div className="col-12 mb-2">
                           <label htmlFor="">
                               {customField.title}
                           </label>
                           <CustomInput
                               customField={customField}
                               setFieldValue={formik.setFieldValue}
                               values={formik.values}
                           />
                       </div>
                   </>)}
                   <button type={'submit'} className={'btn btn-light w-100'}>
                       {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check text-success'}></i> Enregistrer</>}
                   </button>
               </form>
           </div>
       </div>
   </>
}

export default TaskEditForm;