import React from "react";

export interface initialStateInterface {
    title: string,
    show: boolean,
    isLoading: boolean,
    item: string,
    data: any,
    props: any,
}

export const initialState: initialStateInterface = {
    title: '',
    show: false,
    isLoading: false,
    item: '',
    data: {},
    props: {},
};