import * as types from "../../constants/activity"
import * as models from "../../models/activity"
import replace from "../../utils/arrayReplace"

const activity = (state: models.initialStateInterface = models.initialState, action: {type: string, activity: models.Activity, activities: models.Activities, error: string}) => {
    switch (action.type) {
        case types.FETCH_ACTIVITIES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_ACTIVITIES_SUCCESS:
            return { payload: action.activities, single: state.single, isLoading: false, error: null };
        case types.FETCH_ACTIVITIES_FAILURE:
            return { payload: action.activities, single: state.single, isLoading: false, error: action.error };

        case types.ADD_ACTIVITY:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.ADD_ACTIVITY_SUCCESS:
            return { payload: [...state.payload, action.activity], single: action.activity, isLoading: false, error: null };

        case types.EDIT_ACTIVITY:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_ACTIVITY_SUCCESS:
            return { payload: replace(state.payload, action.activity), single: action.activity, isLoading: false, error: null };
        case types.SHOW_ACTIVITY:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.SHOW_ACTIVITY_SUCCESS:
            return { payload: state.payload, single: action.activity, isLoading: false, error: null };
        case types.SHOW_ACTIVITY_FAILURE:
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default activity;