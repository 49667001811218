import {post, get} from "../../xhr";

export function edit(requestData: any, id: number){
    return post('template/applied/edit/' + id, requestData);
}

export function create(requestData: {year: number, week: number}, templateId: number){
    return post(`template/applied/create/${templateId}`,  requestData);
}

export function editStatus(status: 1|2, id: number){
    return get(`template/applied/edit_status/${id}/${status}`);
}

export function preview(id: number){
    return get(`template/applied/preview/${id}`);
}
export function activate(id: number){
    return get(`template/applied/activate/${id}`);
}

export function cancel(id: number){
    return get(`template/applied/delete/${id}`);
}
