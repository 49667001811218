import React, {useEffect, useState} from "react";
import * as types from "../../../../constants/notification"
import {Notifications} from "../../../../models/companyGroup";
import * as api from "../../../../adapters/notification";
import * as accessLevelApi from "../../../../adapters/accessLevel";
import {AxiosResponse} from "axios";
import {AccessLevels} from "../../../../models/accessLevel";
import Loader from "../../../../utils/loader";
import HasModule from "../../../../security/hasModule";

const NotificationSettings:React.FC = () => {

    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);

    const [notifications, setNotifications] = useState<Notifications>([]);
    const [accessLevels, setAccessLevels] = useState<AccessLevels>([]);

    useEffect(() => {
        api.list()
            .then(resp => setNotifications(resp.data))
            .then(() => setLoading(false))
    }, [])

    useEffect(() => {
        accessLevelApi.select()
            .then(resp => setAccessLevels(resp.data))
    }, [])

    function handleChangeSuccess(resp: AxiosResponse){
        setNotifications(prev => [...prev.filter(p => p.id !== resp.data.id), resp.data]);
        setDisabled(false)
    }

    function handleActivation(id: number, checked: boolean){
        setDisabled(true)
        if (checked){
            api.activate(id)
                .then(resp => handleChangeSuccess(resp))
        }else{
            api.deactivate(id)
                .then(resp => handleChangeSuccess(resp))
        }
    }

    function handleEmailChange(id: number, checked: boolean){
        setDisabled(true)
        if (checked){
            api.setEmail(id)
                .then(resp => handleChangeSuccess(resp))
        }else{
            api.unsetEmail(id)
                .then(resp => handleChangeSuccess(resp))
        }
    }

    function handlePushNotificationChange(id: number, checked: boolean){
        setDisabled(true)
        if (checked) {
            api.setPushNotification(id)
                .then(resp => handleChangeSuccess(resp))
        } else {
            api.unsetPushNotification(id)
                .then(resp => handleChangeSuccess(resp))
        }
    }

    function handleSmsNotificationChange(id: number, checked: boolean){
        setDisabled(true)
        if (checked) {
            api.setSmsNotification(id)
                .then(resp => handleChangeSuccess(resp))
        } else {
            api.unsetSmsNotification(id)
                .then(resp => handleChangeSuccess(resp))
        }
    }

    function handleManagerChange(id: number, checked: boolean){
        setDisabled(true)
        if (checked){
            api.setManager(id)
                .then(resp => handleChangeSuccess(resp))
        }else{
            api.unsetManager(id)
                .then(resp => handleChangeSuccess(resp))
        }
    }

    function handlePopulationChange(id: number, accessLevelId: number, checked: boolean){
        setDisabled(true)
        if (checked){
            api.addPopulation(id, accessLevelId)
                .then(resp => handleChangeSuccess(resp))
        }else{
            api.removePopulation(id, accessLevelId)
                .then(resp => handleChangeSuccess(resp))
        }
    }

    if (loading){
        return <Loader />
    }

    return <div className="row mt-3">
        <div className="col-4">
            <div className="list-group" id="list-tab" role="tablist">
                {types.getAllNotifications().map((g, i) => <a className={"list-group-item list-group-item-action " + (i === 0 ? 'active' : '')} id={`list-${g.underscoreCase}-list`} data-bs-toggle="list"
                   href={`#list-${g.underscoreCase}`} role="tab" aria-controls="list-home">{g.title}</a>)}
            </div>
        </div>
        <div className="col-8">
            <div className="tab-content" id="nav-tabContent">
                {types.getAllNotifications().map((g, i) => <div className={"tab-pane fade show " + (i === 0 ? 'active' : '')} id={`list-${g.underscoreCase}`} role="tabpanel"
                     aria-labelledby={`list-${g.underscoreCase}-list`}>
                    <ul className={"list-group"}>
                        {g.notifications.map((n: any) => {
                            let notification = notifications.find(_n => n.id === _n.underscoreCase)!

                            if (!notification) {
                                return <li className={'list-group-item'}>
                                    {n.id} not found
                                </li>
                            }

                            return <li className={'list-group-item'}>
                                <div className="p-1 p-md-3">
                                    <div className="form-check form-switch mb-3">
                                        <input onChange={(e) => handleActivation(notification.id, e.currentTarget.checked)} className="form-check-input" type="checkbox" id={n.id} defaultChecked={notification.activated} disabled={disabled}/>
                                        <label className="form-check-label" htmlFor={n.id}>{n.title}</label>
                                    </div>

                                    {notification.activated &&
                                        <>
                                            <div className="col-12 mb-3">
                                                {n.email &&
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            onChange={e => handleEmailChange(notification.id, e.currentTarget.checked)}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={n.id + "isEmail"}
                                                            defaultChecked={notification.isEmail}
                                                            disabled={disabled}
                                                        />
                                                        <label className="form-check-label" htmlFor={n.id + "isEmail"}>
                                                            Envoyer par email
                                                        </label>
                                                    </div>
                                                }
                                                {n.push &&
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            onChange={e => handlePushNotificationChange(notification.id, e.currentTarget.checked)}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={n.id + "isPushNotification"}
                                                            defaultChecked={notification.isPushNotification}
                                                            disabled={disabled}
                                                        />
                                                        <label className="form-check-label" htmlFor={n.id + "isPushNotification"}>
                                                            Envoyer par notification push
                                                        </label>
                                                    </div>
                                                }
                                                {n.sms && HasModule('smsNotifications') &&
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            onChange={e => handleSmsNotificationChange(notification.id, e.currentTarget.checked)}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={n.id + "isSms"}
                                                            defaultChecked={notification.isSms}
                                                            disabled={disabled}
                                                        />
                                                        <label className="form-check-label" htmlFor={n.id + "isSms"}>
                                                            Envoyer par sms (0,04€ HT/sms)
                                                        </label>
                                                    </div>
                                                }
                                            </div>

                                            {n.population && <div className={"col-12 mt-3 d-flex flex-column"}>
                                                <div className="col-12 mb-3">
                                                    <p className={'text-primary'}>
                                                        <i className={'bi bi-people'}></i> Envoyer aux collaborateurs ayant un des rôles ci-dessous :
                                                    </p>
                                                    {accessLevels.map(a => <div className="form-check">
                                                        <input disabled={disabled} onChange={e => handlePopulationChange(notification.id, a.id, e.currentTarget.checked)} className="form-check-input" checked={!!notification.population.find(p => p.id === a.id)} type="checkbox" id={`notification${notification.id}accessLevel${a.id}`} />
                                                        <label className="form-check-label" htmlFor={`notification${notification.id}accessLevel${a.id}`}>{a?.title === 'Manager' ? 'Manageur' : a.title}</label>
                                                    </div>)}
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input onChange={(e) => handleManagerChange(notification.id, e.currentTarget.checked)} className="form-check-input" type="checkbox" id={"isManager"+n.id} defaultChecked={notification.isManager} disabled={disabled}/>
                                                    <label className="form-check-label" htmlFor={"isManager" + n.id}>
                                                        Notifier le(s) manageur(s) assigné(s)
                                                    </label>
                                                </div>
                                            </div>}
                                        </>
                                    }

                                    {n.populationDescription &&
                                        <div className="form-text">
                                            {n.populationDescription}
                                        </div>
                                    }
                                </div>
                            </li>
                        })}
                    </ul>
                </div>)}
            </div>
        </div>
    </div>
}

export default NotificationSettings;