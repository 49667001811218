import {setSeconds} from "date-fns";

export default function duration (start?: Date|null, end?: Date|null, breakDuration?: number|null){

    if (!end || !start) return '';

    setSeconds(end, 0)
    setSeconds(start, 0)

    let s = setSeconds(end, 0).getTime() - setSeconds(start, 0).getTime();
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs - (breakDuration || 0)) / 60;
    let min = s % 60;
    let h = (s - min) / 60;

    return String(h).padStart(2, '0') + ':' + String(min).padStart(2, '0');
}