import { FunctionComponent, useState } from "react"
import { useDispatch, useSelector, RootStateOrAny } from "react-redux"

import EmptyList from "../../../common/EmptyList"
import AddOrUpdateSalaryRepartitionForm from "./add"

import { Salary, SalaryRepartition } from "../../../../models/salary"

import { FLAT_RATE_HC } from "../../../../constants/salary"

import { showSalarySuccess } from "../../../../actions/salary"
import { updateSalaryRepartition } from "../../../../adapters/salary/repartition"

import { showAlertDanger } from "../../../../actions/alert"

import moment from "../../../../utils/moment.utils"

const SalaryRepartitions: FunctionComponent = () => {
    const dispatch = useDispatch()

    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single)

    const [repartitionToEdit, setRepartitionToEdit] = useState<SalaryRepartition|undefined>(undefined)
    
    const isAvailable = salary?.information?.flatRate === FLAT_RATE_HC;
    const activeRepartitionsList = salary?.repartitions?.filter(el => !el.archived) || [];

    const onArchivedSalaryRepartition = async (salaryRepartition: SalaryRepartition) => {
        try {
            const { data } = await updateSalaryRepartition(salary.id, salaryRepartition.id, {
                ...salaryRepartition,
                archived: true
            })
            dispatch(showSalarySuccess(data))
        } catch (error) {
            dispatch(showAlertDanger('Impossible d’archiver la répartition du salarié'))
        }
    }

    return (
        <div className="card shadow p-3">
            <div className="d-flex justify-content-between">
                <h4 className="mb-3">
                    RÉPARTITION DES HEURES CONTRAT
                </h4>

                <button
                    className="btn btn-primary text-white"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#addOrUpdateRepartitionDrawer"
                    aria-controls="addOrUpdateRepartitionDrawer"
                    onClick={() => setRepartitionToEdit(undefined)}
                >
                    + Ajouter une répartition
                </button>
            </div>

            {!isAvailable &&
                <EmptyList
                    text="Fonctionnalité non disponible"
                />
            }

            {isAvailable &&
                <div>
                    <div className="pt-4">
                        {activeRepartitionsList.length === 0 &&
                            <EmptyList
                                text="Aucune répartition des heures contrat pour ce salarié"
                            />
                        }
                        
                        {activeRepartitionsList.length > 0 &&
                            <div>
                                {activeRepartitionsList.map((repartition: SalaryRepartition) => (
                                    <div
                                        key={repartition.id}
                                        className="mb-2 p-2 bg-white border rounded-1 d-flex"
                                    >
                                        <div className="col-10">
                                            <p className="m-0">
                                                {repartition?.endDate ? `Du ${moment(repartition.startDate).format('DD MMMM YYYY')} au ${moment(repartition.endDate).format('DD MMMM YYYY')}` : `À partir du ${moment(repartition.startDate).format('DD MMMM YYYY')}`}
                                            </p>
                                            <p className="text-muted m-0">
                                                {`Répartition sur ${repartition.contractWorkingTimeRepartitionTrigger} semaine${repartition?.contractWorkingTimeRepartitionTrigger && repartition.contractWorkingTimeRepartitionTrigger > 1 ? 's' : ''} (${repartition?.contractWorkingTimeRepartitionValues?.slice(0, repartition?.contractWorkingTimeRepartitionTrigger || 0)?.map(el => el + 'h')})`}
                                            </p>
                                            <p className="text-muted m-0">
                                                {`Semaine en cours: ${repartition?.contractWorkingTimeRepartitionCurrentWeek}`}
                                            </p>
                                        </div>

                                        <div className="col-2 d-flex flex-column">
                                            <button
                                                className="mb-1 btn btn-outline-primary"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#addOrUpdateRepartitionDrawer"
                                                aria-controls="addOrUpdateRepartitionDrawer"
                                                onClick={() => setRepartitionToEdit(repartition)}
                                            >
                                                <i className="bi bi-pencil" /> Modifier
                                            </button>
                                            <button
                                                className="btn btn-outline-danger"
                                                onClick={() => onArchivedSalaryRepartition(repartition)}
                                            >
                                                <i className="bi bi-archive" /> Archiver
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            }
            
            <div
                className="offcanvas offcanvas-end p-4"
                id="addOrUpdateRepartitionDrawer"
                tabIndex={-1}
                aria-labelledby="addOrUpdateRepartitionDrawerLabel"
            >
                <AddOrUpdateSalaryRepartitionForm
                    salary={salary}
                    repartitionData={repartitionToEdit}
                    onSuccess={() => setRepartitionToEdit(undefined)}
                />
            </div>
        </div>
    )
}

export default SalaryRepartitions