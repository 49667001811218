import * as types from "../../constants/timeClock";
import * as models from "../../models/timeClock";

export const addTimeClock = () => {
    return {
        type: types.ADD_TIME_CLOCK,
    }
}

export const addTimeClockInformation = () => {
    return {
        type: types.ADD_TIME_CLOCK,
    }
}

export const addTimeClockGeneratedSuccess = (timeClockGenerated: models.TimeClockGenerated) => {
    return {
        type: types.ADD_TIME_CLOCK_SUCCESS,
        timeClockGenerated: timeClockGenerated,
    }
}

export const addTimeClockFailure = (error: string) => {
    return {
        type: types.ADD_TIME_CLOCK_FAILURE,
        timeClock: null,
        timeClocks: null,
        error: error
    }
}

export const editTimeClock = () => {
    return {
        type: types.EDIT_TIME_CLOCK,
    }
}

export const editTimeClockGeneratedSuccess = (key: string, timeClockGenerated: models.TimeClockGenerated) => {
    return {
        type: types.EDIT_TIME_CLOCK_SUCCESS,
        timeClockGenerated: timeClockGenerated,
        key: key
    }
}
export const removeTimeClockGeneratedSuccess = (key: string) => {
    return {
        type: types.REMOVE_TIME_CLOCK_SUCCESS,
        key: key
    }
}

export const fetchTimeClocks = () => {
    return {
        type: types.FETCH_TIME_CLOCKS,
    }
}

export const fetchTimeClocksFailure = (error: string) => {
    return {
        type: types.FETCH_TIME_CLOCKS_FAILURE,
        timeClock: null,
        timeClocks: [],
        error: error
    }
}

export const fetchTimeClocksSuccess = (timeClocks: models.TimeClocks) => {
    return {
        type: types.FETCH_TIME_CLOCKS_SUCCESS,
        timeClock: null,
        timeClocks: timeClocks
    }
}


export const refreshTimeClock = () => {
    return {
        type: types.REFRESH_TIME_CLOCK,
    }
}
export const showTimeClockSuccess = (timeClockGenerated: models.TimeClockGenerated) => {
    return {
        type: types.SHOW_TIME_CLOCK_SUCCESS,
        timeClockGenerated: timeClockGenerated
    }
}
export const showTimeClockFailure = (error: string) => {
    return {
        type: types.SHOW_TIME_CLOCK_FAILURE,
        timeClock: null,
        timeClocks: null,
        error: error
    }
}