import * as xhr from "../../xhr";

export function create(requestData: any, event: number){
    return xhr.post(`event/exception/create/${event}`, requestData);
}

export function edit(requestData: any, exception: number){
    return xhr.post(`event/exception/edit/${exception}`, requestData);
}

export function cancel(exception: number){
    return xhr.get(`event/exception/cancel/${exception}`);
}

export function removeSub(exception: number){
    return xhr.get(`event/exception/remove_sub/${exception}`);
}