import {Salaries, Salary} from "../../../models/salary";
import React, {useEffect, useState} from "react";
import * as salaryApi from "../../../adapters/salary";
import {useDispatch} from "react-redux";
import * as actions from "../../../actions/salary";
import {Jobs} from "../../../models/job";
import {Companies} from "../../../models/companies";
import {PAGINATION} from "../../../constants/global";

interface SingleSalarySelectorProps {
    onSubmit: (s: Salary) => any,
    onClose: any,
    initialValue?: number,
    required?: boolean,
    jobs?: Jobs,
    companies?: Companies,
    salaries?: Salaries
}

export const SingleSalarySelector:React.FC<SingleSalarySelectorProps> = (props) => {
    const {onSubmit, onClose, initialValue, required, companies, salaries, jobs} = props;

    const [offset, setOffset] = useState(0)
    const [isLast, setIsLast] = useState(false)
    const [query, setQuery] = useState<string|undefined>()
    const [isLoading, setIsLoading] = useState(true)
    const [isFiltering, setIsFiltering] = useState(false)
    const [error, setError] = useState<string|undefined>()
    const [fetchedSalaries, setFetchedSalaries] = useState<Salaries>([])
    const dispatch = useDispatch();
    const [scrollBottom, setScrollBottom] = useState(false)

    const jobsArray = jobs ? jobs.map((job) => job.id!) : [];
    const companiesArray = companies ? companies.map((companies) => companies.id!) : [];
    const salariesArray = salaries ? salaries.map((salaries) => salaries.id!) : [];

    useEffect(() => {
        let container = document.getElementsByClassName('offcanvas-body')[0]!
        container.addEventListener("scroll", onScrollBottom)

        setIsLoading(true);

        salaryApi.list({
            query: query,
            offset: offset,
            jobs: jobsArray,
            salaries: salariesArray,
            companies: companiesArray,
        }).then(data => {
            fetchSuccess([...data.data], 1)
        })

        return () => {
            container.removeEventListener("scroll", onScrollBottom)
        }
    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                dispatch(actions.fetchSalaries())
                setIsFiltering(true);
                setOffset(0)
                salaryApi.list({
                    query: query,
                    offset: 0,
                    jobs: jobsArray,
                    salaries: salariesArray,
                    companies: companiesArray,
                }).then(data => {
                    fetchSuccess([...data.data])

                })
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if (!isLoading){
            if(offset > 0 && !isLast){
                setIsLoading(true)
                salaryApi.list({
                    query: query,
                    offset: offset,
                    jobs: jobsArray,
                    salaries: salariesArray,
                    companies: companiesArray,
                }).then(data => {
                    fetchSuccess([...fetchedSalaries, ...data.data])
                })
            }
        }
    }, [scrollBottom])


    const fetchSuccess = (items: Salaries, offset?: number) => {
        setIsLast(items.length < PAGINATION);
        setFetchedSalaries(items)
        setOffset(prevState => (offset ? offset : prevState + 1))
        setIsLoading(false)
    }

    const onScrollBottom = () => {
        let elem = document.getElementsByClassName('offcanvas-body')[0]!;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom((prevState) => !prevState)
        }
    }

    return (
        <div className={'listSelector col-12'} id={"CompanySelector"}>
            <div className="w-100 d-flex p-2 sticky-top bg-white">
                <div className="input-group flex-grow-1">
                                    <span className="input-group-text" id="search-addon">
                                        {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : <i className="bi bi-search"> </i>}
                                    </span>
                    <input type="search" onChange={(e) => {
                        setQuery(e.target.value)
                    }} className="form-control" placeholder="Rechercher un collaborateur" aria-describedby="search-addon" />
                </div>
                <button type={'button'} className={'btn btn-light ms-2'} onClick={() => {
                    if (required) {
                        setError('Veuillez séléctionner un collaborateur.')
                    }else{
                        onClose()
                    }
                }}>
                    <i className={'bi bi-x-lg'}> </i>
                </button>
            </div>
            {error && <div className={'alert alert-danger my-2 w-100'}>
               <i className={'bi bi-exclamation-circle'}> </i> {error}
            </div>}
            <ul className={'list-group list-group-flush'}>
                {fetchedSalaries.map((salary: Salary, index: number) => {
                    return (
                        <li className={'list-group-item list-group-item-action ' + (initialValue === salary.id && 'active')} onClick={() => {
                            onSubmit(salary)
                            onClose()
                        }} key={index}>
                            <div className="form-check-label w-100 d-flex align-items-center" >
                                {initialValue === salary.id && <span className={'fs-3 pe-3'}>
                                    <i className={'bi bi-check'}> </i>
                                </span>}
                                <span className="col">
                                    <span>
                                        <b>
                                            {salary.firstname} {salary.lastname}
                                        </b>
                                    </span>
                                    <span className="clearfix"> </span>
                                    <span>
                                        {salary.information?.job.title}
                                    </span>
                                </span>
                                <span className="col-auto">
                                        <div className="color-circle col-auto">
                                            <div className="p-1 shadow" style={{backgroundColor: `${salary.information?.job.color}`}}> </div>
                                        </div>
                                    </span>
                            </div>
                        </li>
                    )
                })}
                {isLoading && <li className={'list-group-item text-center'}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </li>}
            </ul>
            {!isLast && !isLoading && <button type={'button'} onClick={() => setScrollBottom((prevState) => !prevState)} className={'btn mb-2 btn-outline-primary w-100'}>
                Charger plus de collaborateurs
            </button>}
        </div>
    )
}

interface SingleSalarySelectedProps {
    onDelete: any,
    onOpen: any,
    required?: boolean,
    initialValue?: number,
    initialSalary?: Salary,
    validationClass?: string,
    disabled?: boolean
}

export const SingleSalarySelected:React.FC<SingleSalarySelectedProps> = (props) => {

    const {
        onDelete,
        onOpen,
        initialValue,
        initialSalary,
        validationClass,
        required,
        disabled
    } = props;


    const [salary, setSalary] = useState<Salary|undefined>(initialSalary)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (initialValue && initialSalary?.id !== initialValue){
            setIsLoading(true)
            salaryApi.show(initialValue).then(data => {
                setSalary(data.data)
                setIsLoading(false)
            })
        }
    }, [initialValue])

    useEffect(() => {
        setSalary(initialSalary);
    }, [initialSalary])

    return (
        <div className="form-floating">
            <div className={"form-control " + validationClass}>
                <div className="col-12">
                       {
                        initialValue &&
                            <span className={'me-2'}>
                                <span>
                                    {salary && !isLoading ? salary.title :
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>}
                                </span>
                                {!required && <span className={'ps-2'} onClick={() => {
                                    onDelete(initialValue)
                                    setSalary(undefined)
                                }}>
                                    <i className={'bi bi-x text-danger'}> </i>
                                </span>}
                            </span>
                        }
                    <span className={'w-100'}>
                        <button disabled={disabled === true} type={'button'} style={{lineHeight: '1rem'}} className={'btn p-0 btn-light'} onClick={onOpen}>
                            {!initialValue && "Séléctionner un collaborateur"} <i className={'text-primary ' + (initialValue ? 'bi bi-pencil-square' : 'bi bi-plus-lg')}> </i>
                        </button>
                    </span>
                </div>
            </div>
            <label>
                <i className={'bi bi-person text-primary'}> </i> Collaborateur {required && <span className={"required"}>*</span>}
            </label>
        </div>
    )
}
