import * as React from "react";

import JobList from "../../components/job/list";
import JobShow from "../../components/job/show";

const JobPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <JobList />
            <JobShow />
        </div>
    );
}

export default JobPage;