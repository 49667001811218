import * as types from '../../../constants/expense';

export const mileageAmountCalculator = (vehicle: number, vehicleEngine: number, distance: number, vehiclePower?: number):number => {

    let amount = 0;

    switch (vehicle)
    {
        case types.VEHICLE_CAR:

            switch (vehiclePower){
                case types.VEHICLE_CAR_POWER_3:
                    if (distance <= 5000){
                        amount = distance * 0.502;
                    }else if (distance <= 20000){
                        amount = distance * 0.3 + 1007;
                    }else{
                        amount = distance * 0.35;
                    }
                    break;
                case types.VEHICLE_CAR_POWER_4:
                    if (distance <= 5000){
                        amount = distance * 0.575;
                    }else if (distance <= 20000){
                        amount = distance * 0.323 + 1262;
                    }else{
                        amount = distance * 0.387;
                    }
                    break;
                case types.VEHICLE_CAR_POWER_5:
                    if (distance <= 5000){
                        amount = distance * 0.603;
                    }else if (distance <= 20000){
                        amount = distance * 0.339 + 1320;
                    }else{
                        amount = distance * 0.405;
                    }
                    break;
                case types.VEHICLE_CAR_POWER_6:
                    if (distance <= 5000){
                        amount = distance * 0.631;
                    }else if (distance <= 20000){
                        amount = distance * 0.355 + 1382;
                    }else{
                        amount = distance * 0.425;
                    }
                    break;
                case types.VEHICLE_CAR_POWER_7:
                    if (distance <= 5000){
                        amount = distance * 0.661;
                    }else if (distance <= 20000){
                        amount = distance * 0.374 + 1435;
                    }else{
                        amount = distance * 0.446;
                    }
                    break;
            }

            break;
        case types.VEHICLE_MOTORCYCLE:

            switch (vehiclePower){
                case types.VEHICLE_MOTORCYCLE_POWER_2:
                    if (distance <= 3000){
                        amount = distance * 0.375;
                    }else if (distance <= 6000){
                        amount = distance * 0.094 + 845;
                    }else{
                        amount = distance * 0.234;
                    }
                    break;
                case types.VEHICLE_MOTORCYCLE_POWER_5:
                    if (distance <= 3000){
                        amount = distance * 0.444;
                    }else if (distance <= 6000){
                        amount = distance * 0.078 + 1099;
                    }else{
                        amount = distance * 0.261;
                    }
                    break;
                case types.VEHICLE_MOTORCYCLE_POWER_5_AND_MORE:
                    if (distance <= 3000){
                        amount = distance * 0.575;
                    }else if (distance <= 6000){
                        amount = distance * 0.075 + 1502;
                    }else{
                        amount = distance * 0.325;
                    }
                    break;
            }

            break;
        case types.VEHICLE_MOPED:
        case types.VEHICLE_BIKE:
            if (distance <= 3000){
                amount = distance * 0.299;
            }else if (distance <= 6000){
                amount = distance * 0.07 + 458;
            }else{
                amount = distance * 0.162;
            }
            break;
    }

    if (Number(vehicleEngine) === types.VEHICLE_ENGINE_ELECTRIC){
        amount = amount * 1.2;
    }

    return Number((amount).toFixed(2));
}