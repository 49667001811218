import * as types from "../../constants/salary";
import * as models from "../../models/salary";

export const addSalary = () => {
    return {
        type: types.ADD_SALARY,
    }
}

export const addSalaryInformation = () => {
    return {
        type: types.ADD_SALARY,
    }
}

export const addSalarySuccess = (salary: models.Salary) => {
    return {
        type: types.ADD_SALARY_SUCCESS,
        salary,
    }
}

export const addSalaryInformationSuccess = (salary: models.Salary) => {
    return {
        type: types.ADD_SALARY_INFORMATION_SUCCESS,
        salary,
    }
}

export const addSalaryFailure = (error: string) => {
    return {
        type: types.ADD_SALARY_FAILURE,
        salary: null,
        salaries: null,
        error
    }
}

export const addSalaryInformationFailure = (error: string) => {
    return {
        type: types.ADD_SALARY_INFORMATION_FAILURE,
        salary: null,
        salaries: null,
        error
    }
}

export const editSalary = () => {
    return {
        type: types.EDIT_SALARY,
    }
}

export const editSalarySuccess = (salary: models.Salary) => {
    return {
        type: types.EDIT_SALARY_SUCCESS,
        salary: salary,
    }
}

export const fetchSalaries = () => {
    return {
        type: types.FETCH_SALARIES,
    }
}

export const fetchSalariesFailure = (error: string) => {
    return {
        type: types.FETCH_SALARIES_FAILURE,
        salary: null,
        salaries: [],
        error
    }
}

export const fetchSalariesSuccess = (salaries: models.Salaries) => {
    return {
        type: types.FETCH_SALARIES_SUCCESS,
        salary: null,
        salaries
    }
}


export const showSalary = () => {
    return {
        type: types.SHOW_SALARY,
    }
}
export const showSalarySuccess = (salary: models.Salary) => {
    return {
        type: types.SHOW_SALARY_SUCCESS,
        salary: salary
    }
}
export const showSalaryFailure = (error: string) => {
    return {
        type: types.SHOW_SALARY_FAILURE,
        salary: null,
        salaries: null,
        error: error
    }
}