import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import * as salaryApi from "../../../../../adapters/salary";
import format from "../../../../../utils/locale";
import Loader from "../../../../../utils/loader";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {Salaries, Salary} from "../../../../../models/salary";
import {showAlertSuccess} from "../../../../../actions/alert";
import {useDispatch} from "react-redux";
import * as api from "../../../../../adapters/template/applied/exception";

interface Interface {
    arg: EventClickArg,
    handleSubmitSuccess: (ev: any) => any,
    close: () => any,
}

const TemplateEventSubstitute:React.FC<Interface> = (props) => {

    const {arg, handleSubmitSuccess, close} = props;

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [jobs, setJobs] = useState<number[]>([])
    const [salaries, setSalaries] = useState<(Salary & {rate: string, counter: string, counterDuration: number})[]>([])

    useEffect(() => {

        if (arg){
            setLoading(true)
            salaryApi.available({
                beginAt: format(arg.event.start!, 'uuuu-MM-dd HH:mm:ss'),
                endAt: format(arg.event.end!, 'uuuu-MM-dd HH:mm:ss'),
                options: {
                    companies: [arg.event.extendedProps.company.id],
                    jobs: jobs
                }
            }).then(resp => setSalaries(resp.data))
                .then(() => setLoading(false))
        }


    }, [arg, jobs])

    const handleSelectChange = (salary: Salary) => {
        setSubmitting(true)

        api.sub(arg.event.extendedProps.templateId, arg.event.extendedProps.eventId, arg.event.extendedProps.exDay, salary.id, {
            salary: arg.event.extendedProps.salary.id,
            start: arg.event.start,
            end: arg.event.end,
            accountingType: arg.event.extendedProps.accountingType,
            activity: arg.event.extendedProps.activity?.id,
            localisation: arg.event.extendedProps.localisation?.id,
            comment: arg.event.extendedProps.comment
        })
            .then((resp) => Promise.all([
                    handleSubmitSuccess(resp.data)
                ]).then(() => {
                    dispatch(showAlertSuccess('Remplacement ajouté'));
                }).then(() => true))
            .then(() => close())
            .then(() => setSubmitting(false))
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventLabel">Remplacer le créneau de {arg?.event.extendedProps.salary.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        {submitting && <div className={'offcanvas-body position-relative'}>
            <Loader />
        </div>}
        {!submitting && <div className="offcanvas-body">
            <table className={'table'}>
                <tbody>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-clock'}></i> Horaires
                    </td>
                    <td>
                        {format(arg.event.start!, 'E dd MMMM uuuu HH:mm')} <i
                        className={'bi bi-arrow-left-right'}></i> {format(arg.event.end!, 'E dd MMMM uuuu HH:mm')}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-shop'}></i> Établissement
                    </td>
                    <td>
                        {arg.event.extendedProps.company.title}
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="col-12">
                <BootstrapSelect
                    fetchEntity={'job'}
                    placeholder={'Selectionner des options'}
                    label={'Métiers'}
                    isMultiple={true}
                    onChangeMultiple={choices => setJobs(choices?.map(c => Number(c.value)) || [])}
                />
            </div>
            <div className="col-12 position-relative">
                {loading && <Loader/>}
                {!loading && <ul className={'list-group list-group-flush'}>
                    {salaries.map(salary => <li className={'list-group-item list-group-item-action'}
                                                onClick={() => handleSelectChange(salary)}>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 d-flex flex-column">
                                <h4 className={'mb-0'}>
                                    {salary.title}
                                </h4>
                                <p className={'form-text mb-0'}>
                                    {salary.information?.job.title}
                                </p>
                            </div>
                            <div className="flex-grow-0 d-flex flex-column text-end">
                            <span>
                                {salary.rate} <i className={'bi bi-currency-euro'}></i>
                            </span>
                                <span>
                                {salary.counterDuration < 0 ?
                                    <i className={'bi bi-arrow-down text-danger'}></i> : (salary.counterDuration > 0 ?
                                        <i className={'bi bi-arrow-up text-primary'}></i> :
                                        <i className={'bi bi-check text-success'}></i>)} {salary.counter}
                            </span>
                            </div>
                        </div>
                    </li>)}
                </ul>}
            </div>
        </div>}
    </>
}

export default TemplateEventSubstitute;