import React, {LegacyRef, useEffect, useRef, useState} from "react";
import {Salaries, Salary} from "../../../models/salary";
import * as api from "../../../adapters/salary";
import * as teamApi from "../../../adapters/team";
import SmallLoader from "../../../utils/loader/small";
import {PAGINATION} from "../../../constants/global";
import format from "../../../utils/locale";
import {Team, Teams} from "../../../models/team";
import * as accessRights from "../../../constants/accessRight";
import arrayEquals from "../../../utils/arrayEqual";
import getUser from "../../../security/getUser";
import {Company} from "../../../models/companies";
import {Granted} from "../../../security/granted";
import Scrollbar from "../../../utils/scrollbar";

interface Interface {
    disabled?: boolean,
    required?: boolean,
    initiallyOpen?: boolean,
    multiple?: boolean,
    label?: string,
    enableReinitialize?: boolean,
    placeholder?: string
    className?: string
}

interface WithOptions {
    fetchOptions?: false,
    options: Salaries,
}

interface WithoutOptions {
    fetchOptions: true,
    available?: false,
    companies?: number[],
    jobs?: number[],
    accessRight?: string,
}

interface Available {
    fetchOptions: true,
    available: true,
    beginAt: Date,
    endAt: Date
    companies?: number[],
    jobs?: number[]
}

interface Multiple {
    multiple: true,
    onChange: (salaries: Salaries) => any,
    values?: Salaries,
    teams?: boolean,
    onBlur?: (e: any, salaries: Salaries) => any;
}

interface SingleRequired {
    multiple?: false,
    required: true
    onChange: (salary: Salary) => any,
    value?: Salary,
    onBlur?: (e: any, salary?: Salary) => any;
}

interface SingleNotRequired {
    multiple?: false,
    required?: false
    onChange: (salary?: Salary) => any,
    value?: Salary,
    onBlur?: (e: any, salary?: Salary) => any;
}

const SalarySelector: React.FC<Interface & (WithOptions | WithoutOptions | Available) & (Multiple | (SingleRequired | SingleNotRequired))> = (props) => {

    const [options, setOptions] = useState<Salaries>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [teamLoading, setTeamLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(props.initiallyOpen || false);
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [values, setValues] = useState<Salaries>(props.multiple ? (props.values ? props.values : []) : [])
    const [value, setValue] = useState<Salary|undefined>(props.multiple ? undefined : props.value)

    const [query, setQuery] = useState<string>();
    const [offset, setOffset] = useState<number>(0);
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [isLast, setIsLast] = useState(false)
    const [teams, setTeams] = useState<Teams>([])
    const [selectedTeams, setSelectedTeams] = useState<Teams>([])
    const [displayAllValues, setDisplayAllValues] = useState(false);

    const formRef = React.createRef<HTMLFormElement>();
    const bodyRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [extraCompany, setExtraCompany] = useState<Company>()
    let _companies = props.fetchOptions ? props.companies : null;
    let beginAt = props.fetchOptions && props.available ? props.beginAt : null;
    let endAt = props.fetchOptions && props.available ? props.endAt : null;
    let _jobs = props.fetchOptions ? props.jobs : null;
    let _options = !props.fetchOptions ? props.options : null;
    let _initialValue = props.multiple ? props.values : props.value;

    const [offsetTop, setOffsetTop] = useState<number>(0);

    useEffect(() => {
        if (isOpen && bodyRef.current && headerRef.current){
            let bodyDimension = bodyRef.current.getBoundingClientRect();
            let headerDimension = headerRef.current.getBoundingClientRect();

            let oversize = headerDimension.height + headerDimension.top + bodyDimension.height > window.innerHeight;

            if (oversize){
                setOffsetTop(0 - bodyDimension.height)
            }else{
                setOffsetTop(headerDimension.height)
            }
        }
    }, [isOpen, options])

    useEffect(() => {
        if (isOpen && !displayFilter && inputRef.current){
            inputRef.current.focus()
        }
    }, [isOpen, displayFilter])

    useEffect(() => {
        if (props.fetchOptions && !loading){
            fetch({offset: 0, query: query})
        }
    }, [_companies, _jobs, beginAt, endAt])

    useEffect(() => {
        if (props.enableReinitialize){
            if (props.multiple){
                setValues(props.values || [])
            }else{
                setValue(props.value)
            }
        }
    }, [_initialValue])

    useEffect(() => {
        if (!loading){
            fetch({offset: 0, query: query})
        }
    }, [extraCompany])

    useEffect(() => {
        if (props.fetchOptions){
            fetch({offset: 0, query: ""})
            if (props.multiple){
                if (props.teams && Granted(accessRights.LIST_TEAM)){
                    setTeamLoading(true)
                    teamApi.list({})
                        .then(resp => setTeams(resp.data))
                        .then(() => setTeamLoading(false))
                }
            }
        }else{
            setLoading(false)
            setOptions(props.options.filter(s => !query || s.title.toLowerCase().includes(query!.toLowerCase())))
        }
    }, [_options])

    function getRoute(params: {query?: string, offset: number})
    {
        if (props.fetchOptions){

            let __companies = [...(props.companies || [])]

            if (extraCompany){
                __companies.push(extraCompany.id!)
            }

            if (props.available){
                return api.available({beginAt: format(props.beginAt, 'uuuu-MM-dd HH:mm:ss'), endAt: format(props.endAt, 'uuuu-MM-dd HH:mm:ss'), options: {companies: __companies, jobs: props?.jobs}, query: params.query})
            }

            return api.list({companies: __companies, query: params.query, offset: params.offset, accessRight: props.accessRight})
        }
    }

    function fetch(params: {query: string|undefined, offset: number}){
        if (props.fetchOptions){
            setLoading(true);
            getRoute(params)!
                .then(resp => {
                    if (params.offset === 0){
                        setOptions(resp.data)
                    }else{
                        setOptions(prev => [...prev, ...resp.data])
                    }

                    setIsLast(resp.data.length < PAGINATION)
                })
                .then(() => setOffset(params.offset + 1))
                .then(() => setIsScrollBottom(false))
                .then(() => setLoading(false))
        }
    }

    const handleChangeQuery = (_query: string) => {
        if (timer){
            clearTimeout(timer)
        }

        setTimer(setTimeout(() => {
            setQuery(_query)
            fetch({query: _query, offset: 0})
        },1000))
    }

    const [isScrollBottom, setIsScrollBottom] = useState(false)
    useEffect(() => {
        if (isScrollBottom && !isLast){
            fetch({query: query || "", offset: offset})
        }

    }, [isScrollBottom])

    const scrollBottom = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight && !isScrollBottom && !isLast) {
            setIsScrollBottom(true)
        }
    }

    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        if (props.multiple && hasLoaded){
            props.onChange([...values])
        }else if (!props.multiple && hasLoaded){
            if (props.required && value){
                props.onChange(value)
            }else if(!props.required){
                props.onChange(value)
            }
        }else{
            setHasLoaded(true);
        }
    }, [values, value])

    const handleChange = (salary: Salary) => {
        if (props.multiple){
            if (values.find(s => s.id === salary.id)){
                removeValue(salary)
            }else{
                addValue(salary)
            }
        }else{
            setValue(salary)
            setIsOpen(false)
        }

        clearQuery();
    }

    function clearQuery(){
        if (query){
            setQuery('')
            formRef.current?.reset();
            fetch({offset: 0, query: ""})
        }
    }

    const Label = () => <label className={"w-100 form-text"}>
        {props.label ? props.label : (props.multiple ? <><i className={"bi bi-people"}></i> Collaborateurs</> : <><i className={"bi bi-person"}></i> Collaborateur</>)}
    </label>

    function handleTeamClick(team: Team){
        let selected = !!(selectedTeams.find(st => st.id === team.id))

        if (selected){
            setSelectedTeams(prev => [...prev.filter(pt => pt.id !== team.id)]);
            setValues(prev => [...prev.filter(ps => !(team.salaries.find(ts => ts.id === ps.id)))]);
        }else{
            setSelectedTeams(prev => [...prev, team]);
            let toAdd = team.salaries.filter(ts => !(values.find(vs => vs.id === ts.id)));
            setValues(prev => [...prev, ...toAdd]);
        }
    }

    const handleExtraCompanyChange = (c: Company) => {

        if (extraCompany?.id === c.id){
            setExtraCompany(undefined);
        }else{
            setExtraCompany(c);
        }

        setDisplayFilter(false)
    }

    const Filters = () => <>
        <ul className={'list-group list-group-flush overflow-auto'} style={{maxHeight: 400}}>
            {getUser().currentSalary.companies.map((c: Company) => <li
                className={"list-group-item list-group-item-action" + (extraCompany?.id === c.id ? " active " : "")}
                onMouseDown={(e) => {
                    e.preventDefault();
                    handleExtraCompanyChange(c)
                }}
            >
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1 d-flex flex-column">
                        <h4 className={'mb-0'}>
                            {c.title}
                        </h4>
                    </div>
                </div>
            </li>)}
        </ul>
    </>

    return <div className={'w-100 position-relative'}>
        <div className={"col-12"} ref={headerRef}>
            <div className="form-select"
                 onClick={() => setIsOpen(true)}
            >
                <Label />
                {props.multiple ? <>
                    {values.length ? <>{values.slice(0, displayAllValues ? values.length : 3).map(v => <span className={'me-1 text-nowrap'}
                                                                                                             style={{fontSize: 12}}>{v.title}
                        <button type={"button"} className={'btn btn-sm bg-light'} contentEditable={false}
                                onMouseDown={e => e.preventDefault()} onClick={() => removeValue(v)}> <i
                            className={'bi bi-x text-danger'}></i> </button>
                    </span>)} {values.length > 3 && (displayAllValues ? <span className={'badge bg-warning cursor-pointer'} onClick={() => setDisplayAllValues(false)}> <i className={'bi bi-arrows-angle-contract'}></i> réduire </span> : <span className={'badge bg-primary cursor-pointer'} onClick={() => setDisplayAllValues(true)}><i className={'bi bi-arrows-angle-expand'}></i> +{values.length - 3}</span>)}</> : <>Selectionner des options</>}
                </> : <>
                    {value ? <>{value.title} {props.required && <button type={"button"} className={'btn btn-sm bg-light'} onClick={() => setValue(undefined)}></button>}</> : <>Séléctionner une option</>}
                </>}
            </div>
            {props.multiple && props.teams && <div className={'w-100'}>
                {teamLoading && <span className={'form-text'}><SmallLoader/> Chargement des équipes</span>}
                {!teamLoading && <>
                    {teams.length ? teams.map(t => <span onClick={() => handleTeamClick(t)} className={"me-1 badge cursor-pointer " + (selectedTeams.findIndex(st => st.id === t.id) !== -1 ? " bg-primary " : " bg-light border border-1 text-dark ")}>{t.title}</span>) : <span className={'form-text'}> <i className={'bi bi-search'}></i> Aucune équipe trouvé </span>}
                </>}
            </div>}
        </div>
        <div ref={bodyRef} style={{zIndex: 1000, maxHeight: 300, top: offsetTop, display: isOpen ? 'block' : 'none'}} className="w-100 position-absolute w-100 shadow bg-white">
            <div className="col-12 position-sticky top-0 bg-white border" style={{zIndex: 10}}>
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <form ref={formRef} className="input-group">
                            {props.fetchOptions && !_companies && <>
                                    <span className={'input-group-text position-relative cursor-pointer' + (displayFilter ? " flex-grow-1 " : " flex-grow-0 ")} onMouseDown={(e) => {
                                        e.preventDefault()
                                        setDisplayFilter(prev => !prev)
                                    }}>
                                    {displayFilter ? <><div className={'btn btn-light d-flex align-items-center cursor-pointer'}><i className={'bi bi-chevron-left'}></i></div><h4 className={'mb-0 ms-2'}>Filtrer par établissement</h4></> : <><i className={'bi text-primary ' + (extraCompany ? "bi-filter-circle-fill" : "bi-filter-circle")}></i></>}
                                </span>

                            </>}
                            {!displayFilter && <>
                                <input type="text" ref={inputRef} className={'form-control'} placeholder={'Rechercher...'}
                                       onBlur={() => setIsOpen(false)}
                                       onChange={(e) => handleChangeQuery(e.target.value)}
                                />
                            </>}
                            <span className="input-group-text cursor-pointer flex-grow-0" onMouseDown={(e) => {
                                e.preventDefault();
                                setIsOpen(false)
                            }}>
                                    <i className={'bi bi-x'}></i>
                                </span>
                        </form>
                    </div>
                </div>
            </div>
            {!displayFilter && <Scrollbar triggerResize={options.length} scrollBottom={scrollBottom}>
                    <ul className={'list-group list-group-flush'}>
                    {loading && <li className={"list-group-item"}><SmallLoader/> Chargement...</li>}
                    {options.map((salary) => <li
                        className={"list-group-item list-group-item-action " + (!props.multiple && value?.id === salary.id ? " active text-white " : "")}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            handleChange(salary)
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 d-flex flex-column">
                                <h4 className={'mb-0'}>
                                    {salary.title}
                                </h4>
                                <small>
                                    {salary.information?.job.title}
                                </small>
                            </div>
                            <div className="flex-grow-0">
                                {props.multiple && <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           checked={!!(values.find(v => v.id === salary.id))}/>
                                </div>}
                            </div>
                        </div>
                    </li>)}
                    </ul>
                    {isScrollBottom && props.fetchOptions && !isLast && <li className={"list-group-item"}><SmallLoader/> Chargement de plus de résultats</li>}
                </Scrollbar>}
            {displayFilter && !_companies && <Filters />}
        </div>
    </div>

    function addValue(salary: Salary)
    {
        setValues(prev => [...prev, salary])
    }

    function removeValue(salary: Salary)
    {
        setValues(prev => [...prev.filter(s => s.id !== salary.id)])
    }
}

function areEqual(prevProps: Interface & (WithOptions | WithoutOptions | Available) & (Multiple | (SingleRequired | SingleNotRequired)), nextProps: Interface & (WithOptions | WithoutOptions | Available) & (Multiple | (SingleRequired | SingleNotRequired))){

    let _companies = prevProps.fetchOptions ? prevProps.companies : undefined;
    let _jobs = prevProps.fetchOptions ? prevProps.jobs : undefined;
    let _options = !prevProps.fetchOptions ? prevProps.options : undefined;
    let _initialValue = prevProps.multiple ? prevProps.values : prevProps.value;
    let _beginAt = prevProps.fetchOptions && prevProps.available ? prevProps.beginAt : undefined;
    let _endAt = prevProps.fetchOptions && prevProps.available ? prevProps.endAt : undefined;

    let companies = nextProps.fetchOptions ? nextProps.companies : undefined;
    let jobs = nextProps.fetchOptions ? nextProps.jobs : undefined;
    let options = !nextProps.fetchOptions ? nextProps.options : undefined;
    let initialValue = nextProps.multiple ? nextProps.values : nextProps.value;
    let beginAt = nextProps.fetchOptions && nextProps.available ? nextProps.beginAt : undefined;
    let endAt = nextProps.fetchOptions && nextProps.available ? nextProps.endAt : undefined;

    return arrayEquals(_companies, companies) && _jobs === jobs && arrayEquals(_options, options) && isSame(_initialValue, initialValue) && beginAt === _beginAt && endAt === _endAt;
}

function isSame(value1: any, value2: any){
    if (Array.isArray(value1) && Array.isArray(value2)){
        return arrayEquals(value1, value2)
    }

    return value1?.id === value2?.id;
}

export default React.memo(SalarySelector, areEqual);