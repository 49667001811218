import React, {useEffect, useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import * as calendarTypes from "../../../../constants/calendar"

interface Interface {
    loading: boolean;
    signed: any[],
    handleClick: (origin: string) => any
}

const DashboardTimeClockMissing:React.FC<Interface> = (props) => {

    const {loading, signed, handleClick} = props;
    const state = useSelector((state: RootStateOrAny) => state);

    const [signedMissing, setSignedMissing] = useState<any[]>([])

    useEffect(() => {
        setSignedMissing([...signed.filter((s: any) => s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING)])
    }, [signed])

    return <>
        <div className="card p-3 shadow-sm cursor-pointer" onClick={() => handleClick('missing')}>
            <div className="d-flex align-items-center">
                <div className="col">
                    <span className={'h4 text-danger'}>
                        <i className={'bi bi-exclamation-circle'}> </i>
                        Absences
                    </span>
                    <div className="clearfix"> </div>
                    <span className="form-text">
                        + {state.auth.user.currentSalary.companyGroup.timeClockSettings?.lateAfter} minutes
                    </span>
                </div>
                <div className="col-auto align-middle align-items-center">
                    {loading ? <div className="spinner-border align-middle text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : <span className={'text-danger h2'}>{signedMissing.length}</span>}
                </div>
            </div>
        </div>
    </>
}

export default DashboardTimeClockMissing;