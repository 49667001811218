import {post, get} from "../../../xhr";

interface PostPayload {
    resource: number,
    salary: number,
    templateApplied: number
}

export function create(requestData: PostPayload){
    return post(`template/applied/resource/create`, requestData);
}

export function edit(requestData: PostPayload, id:number){
    return post(`template/applied/resource/edit/${id}`, requestData);
}

export function cancel(id: number){
    return get(`template/applied/resource/delete/${id}`);
}