import {get, post} from "../../xhr";

export function setting(requestData: {setting: string, value: any}, id: number){
    return post(`salary/${id}/setting`, requestData);
}

export function disable(id: number){
    return get('salary/disable/' + id);
}

export function enable(id: number){
    return get('salary/enable/' + id);
}