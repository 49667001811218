import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {showAlertDanger, showAlertSuccess} from "../../../actions/alert";
import {closeSidebar} from "../../../actions/rightSidebar";
import * as api from "../../../adapters/salary";
import StyledDropzone from "../../../utils/fileUpload";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import FileSaver from "file-saver";
import {axiosError} from "../../../actions/axios";

const SalaryImport:React.FC = () => {

    const dispatch = useDispatch();
    const [files, setFiles] = useState<File[]>([])
    const [companies, setCompanies] = useState<number[]>([])
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);


    const handleSubmit = () => {

        if (!files.length){
            dispatch(showAlertDanger('Veuillez ajouter un fichier'))
            return false;
        }
        if (!companies.length){
            dispatch(showAlertDanger('Veuillez ajouter au moins une entreprise'))
            return false;
        }

        setSubmitting(true);

        let formData = new FormData();
        formData.append('companies', JSON.stringify(companies));
        formData.append('file', files[0]);

        api._import(formData).then((data) => {
            setSubmitting(false);

            if (data.data.errors.length){
                setErrors(data.data.errors);
            }else{
                dispatch(showAlertSuccess('Import efféctué'))
                dispatch(closeSidebar());
            }
        })
    }

    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const _importFile = () => {
        setIsDownloading(true);
        api.importFile().then(data => {
            let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
            FileSaver.saveAs(newBlob, "fichier-import.xlsx")
            setIsDownloading(false);
        }).catch(error => dispatch(axiosError(error)))
    }

    return  <>
        <div className="p-3">
            <div className="col-12 mb-3 d-flex align-items-center">
                <div className="col">
                    Vous n'avez pas le fichier d'import ?
                </div>
                <div className="col-auto">
                    <button
                        disabled={isDownloading}
                        onClick={() => _importFile()}
                        className={'btn btn-outline-primary'}>
                        <i className={'bi bi-download'}> </i> Fichier d'import
                    </button>
                </div>
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    label={"Points de vente"}
                    labelIcon={'bi bi-shop text-primary'}
                    fetchEntity={"company"}
                    isMultiple={true}
                    onChangeMultiple={(choices) => setCompanies(choices?.map(choice => Number(choice.value)) || [])}
                />
            </div>
            <div className="col-12 mb-3">
                <StyledDropzone style={{height: 200}} acceptedFilesProps={['.csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel"']}  handleUploadedFile={(files: File[]) => setFiles(files)} />
            </div>
            <button type={'button'} onClick={() => handleSubmit()} disabled={!files.length || !companies.length || submitting} className={'btn w-100 btn-primary mb-3'}>
                {submitting ? <><div className="spinner-grow spinner-grow-sm text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> Import en cours... </> : 'Importer'}
            </button>
            {!!errors.length && <div className="col-12">
                <div className="alert alert-danger">
                    <i className={'bi bi-exclamation-circle'}> </i> Les lignes suivantes n'ont pas pu être importée :
                    <ul className={'list-group list-group-flush'}>
                        {errors.map((error: any) => <li className={'list-group-item text-danger'}>
                            Ligne {error.row}
                            <div className="clearfix"> </div>
                            {error.message}
                        </li>)}
                    </ul>
                </div>
            </div>}
        </div>
    </>
}

export default SalaryImport;