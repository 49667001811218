import {AccessLevel} from "../accessLevel";
import {CompanyGroups} from "../companyGroup";
import {Salaries, Salary} from "../salary";

export interface authResponse{
    token: string,
    user: User,
    message: string,
}

export interface userLogin{
    username: string,
    password: string
}

export interface AdminRegister{
    firstname: string,
    lastname: string,
    birthdate: Date,
    companyGroup: string,
    tel: string,
    email: string,
    password: string,
    repeatPassword: string
}

export interface User {
    id?: number,
    email: string,
    activated: boolean,
    roles: string[],
    accessLevel: AccessLevel,
    information?: UserInformation,
    calendarSettings: UserCalendarSettings,
    companyGroups: CompanyGroups,
    salaries: Salaries,
    currentSalary: Salary|null,
    createdAt: string,
    companyGroupSalaries?: Salaries,
}

export type Users = User[];

export interface UserInformation {
    id?: number,
    firstname: string,
    lastname: string,
    tel: string
}

export interface UserCalendarSettings {
    id?: number,
    isTasks: boolean,
    groupEtp: boolean,
    defaultGroupEtp: boolean,
    isNeed: boolean,
    isAbsenceRequest: boolean,
    isTimeClock: boolean,
    isPresence: boolean,
    daysOfWeek: number[],
    timePrecision: string,
    defaultView: string,
    defaultGroupBy: number,
    timezone: string,
    ratio: string,
    displayAbsence: boolean,
    displayEmpty: boolean,
    displayBackgroundEdit: boolean,
    displayOuter: boolean,
}


export interface initialStateInterface {
    token: string|null,
    user: User|null,
    initialUser: User|null,
    isLoading: boolean,
    message: string|null
}

export interface initialStateUserInterface {
    single: User|null,
    payload: Users,
    isLoading: boolean,
    error: string|null,
}

export const initialState: initialStateInterface = {
    token: localStorage.getItem('token'),
    user: localStorage.getItem('impersonated') ? JSON.parse(localStorage.getItem('impersonated')!) : (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null),
    initialUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null,
    isLoading: false,
    message: null
};

export const initialStateUser: initialStateUserInterface = {
    single: null,
    payload: [],
    isLoading: false,
    error: null,
};
