import * as types from "../../../constants/admin/user"
import * as models from "../../../models/auth"
import replace from "../../../utils/arrayReplace"

const user = (state: models.initialStateUserInterface = models.initialStateUser, action: {
    type: string,
    user: models.User,
    users: models.Users,
    error: string
}) => {    
    switch (action.type) {
        case types.FETCH_USERS:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.FETCH_USERS_SUCCESS:
            return { payload: action.users, single: state.single, isLoading: false, error: null };
        case types.FETCH_USERS_FAILURE:
            return { payload: action.users, single: state.single, isLoading: false, error: action.error };

        case types.ADD_USER:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.ADD_USER_SUCCESS:
            return { payload: [...state.payload, action.user], single: state.single, isLoading: false, error: null };
        case types.EDIT_USER:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_USER_SUCCESS:
            return { payload: replace(state.payload, action.user), single: action.user, isLoading: false, error: null };

        case types.SHOW_USER:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_USER_SUCCESS:
            return { payload: state.payload, single: action.user, isLoading: false, error: null };
        case types.SHOW_USER_FAILURE:
            return { payload: state.payload, single: null, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default user;