import * as types from "../../constants/channel"
import * as models from "../../models/channel"
import replace from "../../utils/arrayReplace"

const channel = (state: models.initialStateInterface = models.initialState, action:
    {type: string, channel: models.Channel, channels: models.Channels, error: string, [key: string] : any}
) => {
    switch (action.type) {
        case types.FETCH_CHANNELS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_CHANNELS_SUCCESS:
            return { payload: action.channels, single: state.single, isLoading: false, error: null };
        case types.FETCH_CHANNELS_FAILURE:
            return { payload: action.channels, single: state.single, isLoading: false, error: action.error };

        case types.ADD_CHANNEL:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_CHANNEL_SUCCESS:
            return { payload: [...state.payload, action.channel], single: action.channel, isLoading: false, error: null };

        case types.EDIT_CHANNEL:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_CHANNEL_SUCCESS:
            return { payload:  replace(state.payload, action.channel), single: action.channel, isLoading: false, error: null };

        case types.SHOW_CHANNEL:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_CHANNEL_SUCCESS:
            return { payload: state.payload, single: action.channel, isLoading: false, error: null };
        case types.SHOW_CHANNEL_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default channel;