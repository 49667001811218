import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import * as api from "../../../adapters/auth";
import FormError from "../../../utils/formError";
import {AxiosError} from "axios";
import * as actions from "../../../actions/auth";
import {showAlertSuccess} from "../../../actions/alert";
import {useDispatch} from "react-redux";

const PasswordRequest:React.FC = () => {

    const params: {email?: string} = useParams()
    const [confirmEmail, setConfirmEmail] = useState<boolean>(false)
    const [confirmedEmail, setConfirmedEmail] = useState<string>()
    const [error, setError] = useState<string>()
    const [code, setCode] = useState("")
    const [currentKey, setCurrentKey] = useState(0)
    const [isValidating, setIsValidating] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (code.length){
            let next = document.getElementById("digit-" + code.length) as HTMLInputElement

            if (next){
                next.focus()
            }

            if (code.length === 6){
                verifyCode();
            }
        }

    }, [code])

    const verifyCode = () => {
        setIsValidating(true)
        setError("");
        api.requestPasswordValidation({
            code: code,
            email: confirmedEmail!,
        })
            .then(data => {
                setIsValidating(false);
                if (data.data.success){
                    setIsValidated(true)
                }else{
                    setIsValidating(false);
                    setIsValidated(false);
                    setCode("")
                    setError(data.data.message);
                    if (data.data.code === "expired"){
                        setConfirmEmail(false)
                        setConfirmedEmail(undefined)
                    }
                }
            }).catch(error => {
                if (error.response){
                    setError(error.response.data.message);
                    setIsValidating(false);
                    setIsValidated(false);
                    setCode("")
                }
        })
    }

    const formik = useFormik({
        initialValues: {email: params.email || ""},
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Email non valide').required('Veuillez renseigner votre email')
        }),
        onSubmit: (values, formikHelpers) => {
            setError("");
            api.requestPassword(values)
                .then(data => {
                    setConfirmedEmail(values.email)
                    setConfirmEmail(true)
                    formikHelpers.setSubmitting(false);
                }).catch((error: AxiosError) => {
                    if (error.response?.status === 400){
                        setError(error.response.data.message);
                    }
                formikHelpers.setSubmitting(false);
            })
        }
    });

    const formikPwd = useFormik({
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().min(6, '6 caractères minimum').required("Champs requis"),
            repeatPassword: Yup.string().min(6, '6 caractères minimum').required("Champs requis"),
        }),
        onSubmit: (values, formikHelpers) => {
            api.passwordReset({...values, ...{code: code!, email: confirmedEmail!}})
                .then(data => {
                    if (data.data.success){
                        dispatch(actions.loginSuccess(data.data.auth));
                        dispatch(showAlertSuccess('Mot de passe mis à jour'));
                    }else{
                        setError(data.data.message);
                        formikHelpers.setSubmitting(false)
                        if (data.data.code === "expired"){
                            setConfirmEmail(false)
                            setConfirmedEmail(undefined)
                        }
                    }
            }).catch((error: AxiosError) => {
                if (error.response?.status){
                    setError(error.response.data.message)
                }
                formikHelpers.setSubmitting(false)
            })
        },
    });

    const onKeyPress = (e: any, index: number) => {

        e.preventDefault();

        if ([0,1,2,3,4,5,6,7,8,9].includes(Number(e.key))){
            if (code.length === index){
                setCode(code + e.key)
            }else if(code.length > index){
                let _array = code.split('');
                _array[index] = e.key;
                setCode(_array.join(''))
            }
        }
    }

    return <>
        <div className="container mx-auto my-1 my-md-3">
            <div className="card shadow-sm">
                <div className="card-header bg-white d-flex align-items-center">
                    <h4 className={'mb-0 flex-grow-1'}>
                        Mot de passe oublié
                    </h4>
                    <img src={process.env.PUBLIC_URL + "/logo/ICON_SIVAN_BLEU.svg"} className={'card_logo'} alt=""/>
                </div>
                <div className="card-body">
                    {error && <div className={'alert alert-danger'}>
                        <i className={'bi bi-exclamation-triangle'}> </i> {error}
                    </div>}
                    {!confirmEmail ? <form onSubmit={formik.handleSubmit} className={'mb-3'}>

                        <div className="form-floating mb-3">
                            <input
                                defaultValue={params.email}
                                disabled={formik.isSubmitting} name={'email'}
                                onChange={formik.handleChange} type="text"
                                className={'form-control'}
                            />
                            <label htmlFor="">
                                <i className={"bi bi-envelope"}> </i> Votre email
                            </label>
                            <FormError errors={formik.errors} touched={formik.touched} field={'email'}/>
                        </div>
                        <button disabled={formik.isSubmitting} type={'submit'} className={'btn btn-outline-primary w-100'}>
                            {formik.isSubmitting ? <>
                                <div className="spinner-border spinner-border-sm text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> </> : <><i className={'bi bi-check'}> </i> Valider</>}
                        </button>
                    </form> : (!isValidated ? <>
                        <p>
                            Un code de confirmation vous à été envoyé sur <span className="text-primary">{confirmedEmail}</span>
                            <span className="clearfix"> </span>
                            Veuillez le saisir dans les cases ci-dessous.
                        </p>
                        <form>
                            <div className="input-group">
                                <input
                                    type="number"
                                    className={"digit-input shadow-sm border me-2"}
                                    pattern="[0-9]*"
                                    min={0}
                                    max={9}
                                    maxLength={1}
                                    inputMode="numeric"
                                    id="digit-0"
                                    autoFocus={true}
                                    required
                                    disabled={code.length < 0 || isValidating}
                                    value={code[0] || ''}
                                    onKeyDown={(e) => onKeyPress(e, 0)}
                                />
                                <input
                                    type="number"
                                    className={"digit-input shadow-sm border me-2"}
                                    pattern="[0-9]*"
                                    min={0}
                                    max={9}
                                    maxLength={1}
                                    inputMode="numeric"
                                    id="digit-1"
                                    required
                                    disabled={code.length < 1 || isValidating}
                                    value={code[1] || ''}
                                    onKeyDown={(e) => onKeyPress(e, 1)}
                                />
                                <input
                                    type="number"
                                    className={"digit-input shadow-sm border me-2"}
                                    pattern="[0-9]*"
                                    min={0}
                                    max={9}
                                    maxLength={1}
                                    inputMode="numeric"
                                    id="digit-2"
                                    required
                                    disabled={code.length < 2 || isValidating}
                                    value={code[2] || ''}
                                    onKeyDown={(e) => onKeyPress(e, 2)}
                                />
                            <div className="input-group-text bg-white border-0 me-2">
                                <i className={'bi bi-dash'}> </i>
                            </div>
                                <input
                                    type="number"
                                    className={"digit-input shadow-sm border me-2"}
                                    pattern="[0-9]*"
                                    min={0}
                                    max={9}
                                    maxLength={1}
                                    inputMode="numeric"
                                    id="digit-3"
                                    required
                                    disabled={code.length < 3 || isValidating}
                                    value={code[3] || ''}
                                    onKeyDown={(e) => onKeyPress(e, 3)}
                                />
                                <input
                                    type="number"
                                    className={"digit-input shadow-sm border me-2"}
                                    pattern="[0-9]*"
                                    min={0}
                                    max={9}
                                    maxLength={1}
                                    inputMode="numeric"
                                    id="digit-4"
                                    required
                                    disabled={code.length < 4 || isValidating}
                                    value={code[4] || ''}
                                    onKeyDown={(e) => onKeyPress(e, 4)}
                                />
                                <input
                                    type="number"
                                    className={"digit-input shadow-sm border me-2"}
                                    pattern="[0-9]*"
                                    min={0}
                                    max={9}
                                    maxLength={1}
                                    inputMode="numeric"
                                    id="digit-5"
                                    required
                                    disabled={code.length < 5 || isValidating}
                                    value={code[5] || ''}
                                    onKeyDown={(e) => onKeyPress(e, 5)}
                                />
                                {isValidating && <div className="input-group-text border-0 bg-white">
                                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> Validation en cours...
                                </div>}
                            </div>
                        </form>
                    </> : <>
                        <form onSubmit={formikPwd.handleSubmit}>
                            <div className="form-floating mb-3">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    disabled={formikPwd.isSubmitting}
                                    onChange={formikPwd.handleChange}
                                />
                                <label htmlFor="password">Nouveau mot de passe</label>
                                <FormError errors={formikPwd.errors} touched={formikPwd.touched} field={"password"} />
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    type="password"
                                    className="form-control"
                                    disabled={formikPwd.isSubmitting}
                                    onChange={formikPwd.handleChange}
                                />
                                <label htmlFor="repeatPassword">Confirmez le mot de passe</label>
                                <FormError errors={formikPwd.errors} touched={formikPwd.touched} field={"repeatPassword"} />
                            </div>
                            <button disabled={formikPwd.isSubmitting} type="submit" className='btn btn-outline-primary w-100'>
                                {formikPwd.isSubmitting ? <>
                                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> </> : <><i className={'bi bi-check'}> </i> Valider</>}
                            </button>
                        </form>
                    </>)}
                </div>
            </div>
        </div>
    </>
}

export default PasswordRequest;