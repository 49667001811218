import React from "react";
import SalaryDocumentGenerated from "./generated";
import {useDispatch} from "react-redux";

const SalaryDocument: React.FC = () => {

    return <div className={"container-fluid h-100"}>
        <div className="row h-100">
            <div className="card shadow-sm p-1 p-md-3 h-100">
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <h4>
                            Générer un document
                        </h4>
                    </div>
                </div>
                <p className={"form-text"}>
                    Générer un des documents suivant avec les informations du collaborateur
                </p>
                <SalaryDocumentGenerated />
            </div>
        </div>
    </div>
}

export default SalaryDocument;