import React, {ChangeEvent, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {ModuleSettings} from "../../../../models/companyGroup";
import * as API from "../../../../adapters/companyGroup";
import {editUserCompanyGroupSuccess} from "../../../../actions/auth";
import {axiosError} from "../../../../actions/axios";
import HasRole from "../../../../security/hasRole";
import HasPreviousRole from "../../../../security/hasPreviousRole";
import HasModule from "../../../../security/hasModule";

const ModuleConfig: React.FC = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);
    const settings:ModuleSettings = state.auth.user.currentSalary.companyGroup.modules;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (setting: string, value: any) => {
        setIsLoading(true);
        API.modules({
            setting: setting,
            value: value
        }).then((data) => {
            dispatch(editUserCompanyGroupSuccess(data.data))
            setIsLoading(false);
        })
    }

    return <>
        <div className="col-12 mb-2">
            <div className="p-3">
                <div className="card p-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Badgeuse <span className={'form-text'}> <i className={'bi bi-star gold'}> </i> Option premium <span className={'text-success'}>10€/mois</span>  </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez utiliser la badgeuse
                                </div>
                            </div>
                            <div className="col-auto">
                                {(HasRole("ROLE_SUPER_ADMIN") || HasPreviousRole("ROLE_SUPER_ADMIN")) ? <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('time_clock', e.target.checked)}
                                           disabled={isLoading} defaultChecked={settings.timeClock}
                                           className="form-check-input" type="checkbox"/>
                                </div> : <>{!HasModule('timeClock') ? <button onClick={(e) => {
                                    e.currentTarget.disabled = true;
                                    e.currentTarget.textContent = "Envoi de la demande";
                                    API.requestModule("time_clock").then(data => {
                                        e.currentTarget.textContent = "Demande envoyée";
                                        e.currentTarget.className = "btn btn-success";
                                    }).catch(error => dispatch(axiosError(error)))
                                }} className={'btn btn-outline-primary'}>
                                    Demander l'option
                                </button> : <button className={'btn btn-light text-success'}>Option activé <i className={'bi bi-check2-circle'}></i></button>}</>}
                            </div>
                        </div>
                    </div>
                    {state?.auth?.user?.currentSalary?.companyGroup?.sellsyId &&
                        <>
                            <hr className={'bg-primary'} />
                            <div className="col-12">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="">
                                            Notifications par SMS <span className={'form-text'}> <i className={'bi bi-star gold'}> </i> Option premium <span className={'text-success'}>0,04€ HT/sms</span>  </span>
                                        </label>
                                        <div className="form-text">
                                            Activer si vous souhaitez recevoir certaines notifications par SMS (retards, absences)
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {(HasRole("ROLE_SUPER_ADMIN") || HasPreviousRole("ROLE_SUPER_ADMIN")) ? <div className="form-check form-switch">
                                            <input onChange={(e) => handleChange('sms_notifications', e.target.checked)}
                                                disabled={isLoading} defaultChecked={settings.smsNotifications}
                                                className="form-check-input" type="checkbox"/>
                                        </div> : <>{!HasModule('smsNotifications') ? <button onClick={(e) => {
                                            e.currentTarget.disabled = true;
                                            e.currentTarget.textContent = "Envoi de la demande";
                                            API.requestModule("sms_notifications").then(data => {
                                                e.currentTarget.textContent = "Demande envoyée";
                                                e.currentTarget.className = "btn btn-success";
                                            }).catch(error => dispatch(axiosError(error)))
                                        }} className={'btn btn-outline-primary'}>
                                            Demander l'option
                                        </button> : <button className={'btn btn-light text-success'}>Option activé <i className={'bi bi-check2-circle'}></i></button>}</>}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Documents <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Générez automatiquement tous vos documents légaux
                                </div>
                            </div>
                            <div className="col-auto">
                                {(HasRole("ROLE_SUPER_ADMIN") || HasPreviousRole("ROLE_SUPER_ADMIN")) ?
                                    <div className="form-check form-switch">
                                        <input
                                            onChange={(e) => handleChange('document', e.target.checked)}
                                            disabled={isLoading}
                                            defaultChecked={settings.document}
                                            className="form-check-input" type="checkbox"
                                        />
                                    </div>
                                    :
                                    <>
                                        {!HasModule('document') ?
                                            <button
                                                onClick={(e) => {
                                                    e.currentTarget.disabled = true;
                                                    e.currentTarget.textContent = "Envoi de la demande";
                                                    API.requestModule("document").then(data => {
                                                        e.currentTarget.textContent = "Demande envoyée";
                                                        e.currentTarget.className = "btn btn-success";
                                                    }).catch(error => dispatch(axiosError(error)))
                                                }}
                                                className={'btn btn-outline-primary'}
                                            >
                                                Demander l'option
                                            </button>
                                            :
                                            <button className={'btn btn-light text-success'}>
                                                Option activé <i className={'bi bi-check2-circle'}> </i>
                                            </button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Proposition de créneau <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez proposer des créneaux à une liste de collaborateurs
                                </div>
                            </div>
                            <div className="col-auto">
                                {(HasRole("ROLE_SUPER_ADMIN") || HasPreviousRole("ROLE_SUPER_ADMIN")) ? <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('booking_offer', e.target.checked)} disabled={isLoading} defaultChecked={settings.bookingOffer} className="form-check-input" type="checkbox" />
                                </div>: <>{!HasModule('bookingOffer') ? <button onClick={(e) => {
                                    e.currentTarget.disabled = true;
                                    e.currentTarget.textContent = "Envoi de la demande";
                                    API.requestModule("booking_offer").then(data => {
                                        e.currentTarget.textContent = "Demande envoyée";
                                        e.currentTarget.className = "btn btn-success";
                                    }).catch(error => dispatch(axiosError(error)))
                                }} className={'btn btn-outline-primary'}>
                                    Demander l'option
                                </button> : <button className={'btn btn-light text-success'}>Option activé <i className={'bi bi-check2-circle'}></i></button>}</>}
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Échange de créneaux <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez activer l'échange de créneaux entre collaborateurs
                                </div>
                            </div>
                            <div className="col-auto">
                                {(HasRole("ROLE_SUPER_ADMIN") || HasPreviousRole("ROLE_SUPER_ADMIN")) ?
                                    <div className="form-check form-switch">
                                        <input
                                            onChange={(e) => handleChange('slot_exchange', e.target.checked)}
                                            disabled={isLoading}
                                            defaultChecked={settings.slotExchange}
                                            className="form-check-input" type="checkbox"
                                        />
                                    </div>
                                    :
                                    <>
                                        {!HasModule('slotExchange') ?
                                            <button
                                                onClick={(e) => {
                                                    e.currentTarget.disabled = true;
                                                    e.currentTarget.textContent = "Envoi de la demande";
                                                    API.requestModule("slot_exchange").then(data => {
                                                        e.currentTarget.textContent = "Demande envoyée";
                                                        e.currentTarget.className = "btn btn-success";
                                                    }).catch(error => dispatch(axiosError(error)))
                                                }}
                                                className={'btn btn-outline-primary'}
                                            >
                                                Demander l'option
                                            </button>
                                            :
                                            <button className={'btn btn-light text-success'}>
                                                Option activé <i className={'bi bi-check2-circle'}> </i>
                                            </button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Équipes <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez pouvoir répartir vos collaborateurs par équipes et associer des droits d'accès ciblés
                                </div>
                            </div>
                            <div className="col-auto">
                                {/*<div className="form-check form-switch">*/}
                                {/*    <input onChange={(e) => handleChange('team', e.target.checked)} disabled={isLoading} defaultChecked={settings.team} className="form-check-input" type="checkbox" />*/}
                                {/*</div>*/}
                                {(HasRole("ROLE_SUPER_ADMIN") || HasPreviousRole("ROLE_SUPER_ADMIN")) ? <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('team', e.target.checked)} disabled={isLoading} defaultChecked={settings.team} className="form-check-input" type="checkbox" />
                                </div>: <>{!HasModule("team") ? <button onClick={(e) => {
                                    e.currentTarget.disabled = true;
                                    e.currentTarget.textContent = "Envoi de la demande";
                                    API.requestModule("team").then(data => {
                                        e.currentTarget.textContent = "Demande envoyée";
                                        e.currentTarget.className = "btn btn-success";
                                    }).catch(error => dispatch(axiosError(error)))
                                }} className={'btn btn-outline-primary'}>
                                    Demander l'option
                                </button>: <button className={'btn btn-light text-success'}>Option activé <i className={'bi bi-check2-circle'}></i></button>}</>}
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Suivi masse salariale <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez accéder au suivi de la masse salariale
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('payroll', e.target.checked)} disabled={isLoading} defaultChecked={settings.payroll} className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Localisations & activités <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez préciser le lieu et l'activité des collaborateurs durant leurs créneaux
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('label', e.target.checked)} disabled={isLoading} defaultChecked={settings.label} className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Tâches <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez utiliser les tâches
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('task', e.target.checked)} disabled={isLoading} defaultChecked={settings.task} className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Rapports <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez accéder aux rapports & exports de temps de travail
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('accounting', e.target.checked)} disabled={isLoading} defaultChecked={settings.accounting} className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'} />
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Notes de frais <span className={'form-text'}> <i className={'bi bi-star text-primary'}> </i> Gratuit </span>
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez utiliser les notes de frais
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input onChange={(e) => handleChange('expense', e.target.checked)} disabled={isLoading} defaultChecked={settings.expense} className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ModuleConfig;