import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/activity";
import * as actions from "../../../actions/activity";
import * as models from "../../../models/activity";
import {Link, useParams} from "react-router-dom";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_ACTIVITY} from "../../../constants/rightSidebar";
import {setPageTitle} from "../../../actions/header";
import {Granted} from "../../../security/granted";
import * as accessRights from "../../../constants/accessRight";
import {axiosError} from "../../../actions/axios";

const ActivityList: React.FC = () => {
    const state = useSelector((state: RootStateOrAny)  => state.activity);    
    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const [query, setQuery] = useState<string>();

    useEffect(() => {

        dispatch(setPageTitle('Activités'))

        api.select().then(data => {
            dispatch(actions.fetchActivitiesSuccess(data.data))
        }).catch(error => dispatch(axiosError(error)))
    }, [])

    return <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
            <div className="d-flex flex-column position-sticky top-0 bg-white" style={{zIndex: 100}}>
                <div className="col">
                    <div className="col">
                        <div className="input-group">
                                    <span className="input-group-text bg-white border-0" id="search-addon">
                                        <i className="bi bi-search"> </i>
                                    </span>
                            <input type="search" onChange={(e) => {
                                setQuery(e.target.value)
                            }} className="form-control bg-white border-0" placeholder="Rechercher..." aria-describedby="search-addon" />
                        </div>
                    </div>
                </div>
                {Granted(accessRights.EDIT_ACTIVITY) && <div className="col">
                    <button onClick={() => dispatch(openSidebar(FORM_NEW_ACTIVITY))}
                            className='btn btn-outline-primary w-100'>
                        <i className={'bi bi-plus-circle'}> </i> Ajouter
                    </button>
                </div>}
            </div>
            {state.payload.length ? state.payload.filter((Activity: models.Activity) => !query || Activity.title.toLowerCase().includes(query.toLowerCase())).map((Activity: models.Activity, index: number) => ([
                    <Link to={"/activity/" + Activity.id} key={index} className={"list-group-item list-group-item-action" + (Number(params.id) === Activity.id && " active")}>
                        <div className="d-flex align-items-center">
                            <div className={'flex-grow-1'}>
                                {Activity.title}
                                <div className="clearfix"> </div>
                                <div className="text-light-s">
                                    <i className={'bi bi-shop'}> </i> {Activity.company.title}
                                </div>
                            </div>
                            <div className="flex-grow-0">
                                <div className="color-circle">
                                    <div className="shadow" style={{backgroundColor: `${Activity.color}`, opacity: 0.8}}>
                                        {Activity.icon ? <i className={Activity.icon}> </i> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-0">
                            </div>
                        </div>
                    </Link>
                ]))
                :
                <button key={0} type="button" className="list-group-item list-group-item-action" aria-current="true" disabled>
                    Aucune Activité
                </button>
            }
        </div>
}

export default ActivityList;