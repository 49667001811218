import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import FileSaver from "file-saver";
import EmptyList from "../../components/common/EmptyList";
import SalaryFileListItem from "../../components/salary/common/SalaryFileListItem";

import { Salary, SalaryFile, SalaryFiles } from "../../models/salary";

import * as api from "../../adapters/salary/file";
import * as fileApi from "../../adapters/salary/file";
import { setPageTitle } from "../../actions/header";
import { axiosError } from "../../actions/axios";

import getUser from "../../security/getUser";
import Loader from "../../utils/loader";

const MyStorage: React.FC = () => {
    const dispatch = useDispatch();

    const salary: Salary = getUser().currentSalary;

    const [files, setFiles] = useState<SalaryFiles>([])
    const [loading, setLoading] = useState<boolean>(true)

    const [downloading, setDownloading] = useState<number>();
    const [fileUrl, setJustificatifUrl] = useState<string>()
    const [loadingFile, setLoadingFile] = useState<boolean>(false)

    const displayFile = (file: SalaryFile) => {
        setLoadingFile(true)
        fileApi.download(salary.id!, file.id).then(data => {
            let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
            let url = URL.createObjectURL(newBlob);
            setJustificatifUrl(url)
            setLoadingFile(false)
        }).catch(error => dispatch(axiosError(error)))
    }

    const downloadFile = (file: SalaryFile) => {
        if (!salary) return false;

        setDownloading(file.id);
        fileApi.download(salary.id, file.id).then(data => {
            let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
            FileSaver.saveAs(newBlob, file!.title)
            setDownloading(undefined)
        }).catch(error => dispatch(axiosError(error)))
    }

    useEffect(() => {
        setLoading(true);
        api.list(salary.id).then(resp => setFiles(resp.data))
            .then(() => setLoading(false))
    }, [salary.id])

    useEffect(() => {
        dispatch(setPageTitle("Mon stockage"))
    }, [])

    return (
        <div className={"container-fluid h-100"}>
            <div className="row h-100">
                <div className="card shadow-sm p-1 p-md-3 h-100">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h4>
                                Mon espace de stockage
                            </h4>
                            <p className={"form-text"}>
                                Retrouvez ici les documents que vous avez stocké
                            </p>
                        </div>
                    </div>

                    {loading &&
                        <><Loader /> Chargement des fichiers...</>
                    }

                    {!loading &&
                        <div>
                            {files.length === 0 && <EmptyList />}
                            {files.map((file, index) => (
                                <SalaryFileListItem
                                    key={index}
                                    data={file}
                                    onDisplay={() => displayFile(file)}
                                    onDownload={() => downloadFile(file)}
                                    downloading={downloading === file.id}
                                />
                            ))}

                            <div className="modal" id="fileModal" tabIndex={-1}>
                                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content h-100">
                                        <div className="modal-body d-flex flex-column" id={'#modalBody'}>
                                            {loadingFile && <Loader />}
                                            {!loadingFile && fileUrl && <iframe src={fileUrl} width={'100%'} className={'flex-grow-1'} frameBorder={0}>
                                            </iframe>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MyStorage;