export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const SET_SIDEBAR_ITEM = 'SET_SIDEBAR_ITEM';
export const SUBMIT_SIDEBAR = 'SUBMIT_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const VALIDATE_SIDEBAR = 'VALIDATE_SIDEBAR';
export const VALIDATE_SIDEBAR_FAILURE = 'VALIDATE_SIDEBAR_FAILURE';

export const FORM_NEW_JOB = 'FORM_NEW_JOB';
export const FORM_EDIT_JOB = 'FORM_EDIT_JOB';

export const FORM_NEW_COMPANY = 'FORM_NEW_COMPANY';
export const FORM_EDIT_COMPANY = 'FORM_EDIT_COMPANY';

export const FORM_NEW_ACCESS_LEVEL = 'FORM_NEW_ACCESS_LEVEL';
export const FORM_EDIT_ACCESS_LEVEL = 'FORM_EDIT_ACCESS_LEVEL';

export const FORM_NEW_ABSENCE = 'FORM_NEW_ABSENCE';
export const FORM_NEW_ABSENCE_FILE = 'FORM_NEW_ABSENCE_FILE';
export const FORM_EDIT_ABSENCE = 'FORM_EDIT_ABSENCE';

export const FORM_NEW_LOCALISATION = 'FORM_NEW_LOCALISATION';
export const FORM_EDIT_LOCALISATION = 'FORM_EDIT_LOCALISATION';
export const FORM_LOCALISATION_SALARIES = 'FORM_LOCALISATION_SALARIES';

export const FORM_NEW_ACTIVITY = 'FORM_NEW_ACTIVITY';
export const FORM_EDIT_ACTIVITY = 'FORM_EDIT_ACTIVITY';
export const FORM_ACTIVITY_SALARIES = 'FORM_ACTIVITY_SALARIES';

export const FORM_NEW_SALARY = 'FORM_NEW_SALARY';
export const FORM_EDIT_SALARY = 'FORM_EDIT_SALARY';

export const FORM_NEW_SALARY_INFORMATION = 'FORM_NEW_SALARY_INFORMATION';
export const FORM_EDIT_SALARY_INFORMATION = 'FORM_EDIT_SALARY_INFORMATION';

export const FORM_NEW_SALARY_STOCKED_DOCUMENT = 'FORM_NEW_SALARY_STOCKED_DOCUMENT';

export const LIST_DEFAULT_BOOKING = 'LIST_DEFAULT_BOOKING';
export const FORM_NEW_DEFAULT_BOOKING = 'FORM_NEW_DEFAULT_BOOKING';
export const FORM_EDIT_DEFAULT_BOOKING = 'FORM_EDIT_DEFAULT_BOOKING';

export const BOOKING_HISTORY = 'BOOKING_HISTORY';
export const FORM_NEW_BOOKING = 'FORM_NEW_BOOKING';
export const BOOKING_CANCEL = 'BOOKING_CANCEL';
export const FORM_NEW_BOOKING_GROUP = 'FORM_NEW_BOOKING_GROUP';
export const FORM_NEW_BOOKING_GROUP_FROM_BOOKING = 'FORM_NEW_BOOKING_GROUP_FROM_BOOKING';
export const FORM_EDIT_BOOKING_GROUP = 'FORM_EDIT_BOOKING_GROUP';
export const FORM_EDIT_BOOKING_GROUP_RELOAD = 'FORM_EDIT_BOOKING_GROUP_RELOAD';
export const LIST_BOOKING_GROUP = 'LIST_BOOKING_GROUP';
export const LIST_SALARY_TEMPLATE = 'LIST_SALARY_TEMPLATE';
export const COPY_SALARY_TEMPLATE = 'COPY_SALARY_TEMPLATE';
export const DUPLICATE_SALARY_TEMPLATE = 'DUPLICATE_SALARY_TEMPLATE';
export const SHOW_SALARY_TEMPLATE = 'SHOW_SALARY_TEMPLATE';
export const FORM_EDIT_BOOKING = 'FORM_EDIT_BOOKING';
export const FORM_EDIT_BOOKING_RECURRENCE = 'FORM_EDIT_BOOKING_RECURRENCE';
export const FORM_EDIT_BOOKING_EXCEPTION = 'FORM_EDIT_BOOKING_EXCEPTION';
export const FORM_EDIT_BOOKING_SUBSTITUTE = 'FORM_EDIT_BOOKING_SUBSTITUTE';

export const FORM_EVENT_ADD = "FORM_EVENT_ADD";
export const FORM_EVENT_EXCEPTION_EDIT = "FORM_EVENT_EXCEPTION_EDIT";
export const FORM_EVENT_EXCEPTION_ADD = "FORM_EVENT_EXCEPTION_ADD";
export const FORM_EVENT_CANCEL = "FORM_EVENT_CANCEL";

export const FORM_NEW_TASK_GROUP = 'FORM_NEW_TASK_GROUP';
export const FORM_EDIT_TASK_GROUP = 'FORM_EDIT_TASK_GROUP';

export const FORM_NEW_TASK = 'FORM_NEW_TASK';
export const FORM_EDIT_TASK = 'FORM_EDIT_TASK';
export const FORM_EDIT_TASK_EXCEPTION = 'FORM_EDIT_TASK_EXCEPTION';

export const FORM_NEW_TASK_GROUP_CUSTOM_FIELD = 'FORM_NEW_TASK_GROUP_CUSTOM_FIELD';
export const FORM_EDIT_TASK_GROUP_CUSTOM_FIELD = 'FORM_EDIT_TASK_GROUP_CUSTOM_FIELD';

export const FORM_NEW_TEMPLATE = 'FORM_NEW_TEMPLATE';
export const FORM_NEW_TEMPLATE_FROM_CALENDAR = 'FORM_NEW_TEMPLATE_FROM_CALENDAR';
export const FORM_EDIT_TEMPLATE = 'FORM_EDIT_TEMPLATE';
export const TEMPLATE_LIST = 'TEMPLATE_LIST';

export const FORM_NEW_TEMPLATE_RESOURCE = 'FORM_NEW_TEMPLATE_RESOURCE';
export const FORM_EDIT_TEMPLATE_RESOURCE = 'FORM_EDIT_TEMPLATE_RESOURCE';

export const FORM_NEW_TEMPLATE_BOOKING = 'FORM_NEW_TEMPLATE_BOOKING';
export const FORM_EDIT_TEMPLATE_BOOKING = 'FORM_EDIT_TEMPLATE_BOOKING';
export const FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION = 'FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION';
export const FORM_EDIT_TEMPLATE_APPLIED_BOOKING_EXCEPTION = 'FORM_EDIT_TEMPLATE_APPLIED_BOOKING_EXCEPTION';

export const FORM_NEW_USER_INFORMATION = 'FORM_NEW_USER_INFORMATION';
export const FORM_EDIT_USER_INFORMATION = 'FORM_EDIT_USER_INFORMATION';

export const FORM_NEW_CHANNEL = 'FORM_NEW_CHANNEL';
export const FORM_EDIT_CHANNEL = 'FORM_EDIT_CHANNEL';

export const FORM_COMPANY_SELECTOR = 'FORM_COMPANY_SELECTOR';
export const FORM_CALENDAR_DOWNLOAD = 'FORM_CALENDAR_DOWNLOAD';
export const FORM_CALENDAR_RESOURCE_ORDER = 'FORM_CALENDAR_RESOURCE_ORDER';

export const FORM_FILE_ADD = 'FORM_FILE_ADD';

export const FORM_CALENDAR_SETTINGS = 'FORM_CALENDAR_SETTINGS';
export const FORM_CALENDAR_FILTERS = 'FORM_CALENDAR_FILTERS';

export const LIST_TEMPLATES_APPLY = 'LIST_TEMPLATES_APPLY';

export const TEMPLATES_APPLY_EDIT = 'TEMPLATES_APPLY_EDIT';

export const TIME_CLOCK_LIST = 'TIME_CLOCK_LIST';
export const TIME_CLOCK_SHOW = 'TIME_CLOCK_SHOW';

export const PENDING_ABSENCE_LIST = 'PENDING_ABSENCE_LIST';

export const TIME_CLOCK_BOOKING_ADD = 'TIME_CLOCK_BOOKING_ADD';
export const TIME_CLOCK_BOOKING_EDIT = 'TIME_CLOCK_BOOKING_EDIT';

export const FORM_COUNTER_EDIT = 'FORM_COUNTER_EDIT';
export const FORM_COUNTER_SHOW = 'FORM_COUNTER_SHOW';

export const FEED_LIST = 'FEED_LIST';

export const NEED_LIST = 'NEED_LIST';

export const FORM_KEEP_NOTE_LIST = 'FORM_KEEP_NOTE_LIST';
export const FORM_KEEP_NOTE_ADD = 'FORM_KEEP_NOTE_ADD';
export const FORM_KEEP_NOTE_EDIT = 'FORM_KEEP_NOTE_EDIT';

export const LOCALISATION_NEED_ADD = "LOCALISATION_NEED_ADD";
export const ACTIVITY_NEED_ADD = "ACTIVITY_NEED_ADD";

export const PLANNER_FORM = "PLANNER_FORM";

export const COUNTER_SETTINGS = "COUNTER_SETTINGS";

export const ABSENCE_SETTINGS = "ABSENCE_SETTINGS";
export const ACCOUNTING_SETTINGS = "ACCOUNTING_SETTINGS";
export const EMAIL_SETTINGS = "EMAIL_SETTINGS";
export const GENERAL_SETTINGS = "GENERAL_SETTINGS";
export const MODULE_SETTINGS = "MODULE_SETTINGS";
export const TIME_CLOCK_SETTINGS = "TIME_CLOCK_SETTINGS";

export const SALARY_IMPORT = "SALARY_IMPORT";

export const REPORTING_SHOW_WEEK = "REPORTING_SHOW_WEEK";
export const REPORTING_SHOW_WEEK_LOAD = "REPORTING_SHOW_WEEK_LOAD";
export const FORM_EXPORT_REPORTING = "FORM_EXPORT_REPORTING";

export const EDIT_PASSWORD_FORM = "EDIT_PASSWORD_FORM";

export const FORM_NEW_TEAM = "FORM_NEW_TEAM";
export const FORM_EDIT_TEAM = "FORM_EDIT_TEAM";
export const FORM_TEAM_SALARY = "FORM_TEAM_SALARY";
export const FORM_TEAM_ADD_MANAGER = "FORM_TEAM_ADD_MANAGER";
export const FORM_TEAM_EDIT_MANAGER = "FORM_TEAM_EDIT_MANAGER";

export const FORM_NEW_BOOKING_OFFER = "FORM_NEW_BOOKING_OFFER";
export const FORM_EDIT_BOOKING_OFFER = "FORM_EDIT_BOOKING_OFFER";
export const LIST_BOOKING_OFFER = "LIST_BOOKING_OFFER";
export const SHOW_BOOKING_OFFER = "SHOW_BOOKING_OFFER";
export const LIST_BOOKING_OFFER_APPLICANTS = "LIST_BOOKING_OFFER_APPLICANTS";

export const FORM_NEW_EVENT_OFFER = "FORM_NEW_EVENT_OFFER";
export const FORM_EDIT_EVENT_OFFER = "FORM_EDIT_EVENT_OFFER";
export const LIST_EVENT_OFFER = "LIST_EVENT_OFFER";
export const SHOW_EVENT_OFFER = "SHOW_EVENT_OFFER";
export const LIST_EVENT_OFFER_APPLICANTS = "LIST_EVENT_OFFER_APPLICANTS";

export const COUNTER_SIDEBAR = "COUNTER_SIDEBAR";
export const FORM_COUNTER_WITHDRAWN_ADD = "FORM_COUNTER_WITHDRAWN_ADD";
export const FORM_COUNTER_WITHDRAWN_EDIT = "FORM_COUNTER_WITHDRAWN_EDIT";

export const SALARY_DEFAULT_LABEL_SELECTOR = "SALARY_DEFAULT_LABEL_SELECTOR";

export const FORM_NEW_SLOT_EXCHANGE = "FORM_NEW_SLOT_EXCHANGE";
export const FORM_NEW_SLOT_EXCHANGE_VALIDATE = "FORM_NEW_SLOT_EXCHANGE_VALIDATE";

export const FORM_NEW_EVENT_EXCHANGE = "FORM_NEW_EVENT_EXCHANGE";
export const FORM_NEW_EVENT_EXCHANGE_VALIDATE = "FORM_NEW_EVENT_EXCHANGE_VALIDATE";

export const FORM_NEW_SALARY_TEMPLATE = 'FORM_NEW_SALARY_TEMPLATE';
export const FORM_NEW_SALARY_TEMPLATE_EVENT = 'FORM_NEW_SALARY_TEMPLATE_EVENT';
export const FORM_EDIT_SALARY_TEMPLATE_EVENT = 'FORM_EDIT_SALARY_TEMPLATE_EVENT';

export const LIST_SALARY_TEMPLATE_RECURRENCE = 'LIST_SALARY_TEMPLATE_RECURRENCE';
export const FORM_NEW_SALARY_TEMPLATE_RECURRENCE = 'FORM_NEW_SALARY_TEMPLATE_RECURRENCE';
export const FORM_EDIT_SALARY_TEMPLATE_RECURRENCE = 'FORM_EDIT_SALARY_TEMPLATE_RECURRENCE';

export const FORM_EXPENSE_ADD = 'FORM_EXPENSE_ADD';
export const FORM_EXPENSE_EDIT = 'FORM_EXPENSE_EDIT';
export const FORM_NEW_EXPENSE_FILE = 'FORM_NEW_EXPENSE_FILE';


export const FORM_MANAGER_ADD = 'FORM_MANAGER_ADD';
export const FORM_MANAGER_EDIT = 'FORM_MANAGER_EDIT';


