export const ADD_EVENT_OFFER = 'ADD_EVENT_OFFER';
export const ADD_EVENT_OFFER_SUCCESS = 'ADD_EVENT_OFFER_SUCCESS';
export const ADD_EVENT_OFFER_FAILURE = 'ADD_EVENT_OFFER_FAILURE';

export const EDIT_EVENT_OFFER = 'EDIT_EVENT_OFFER';
export const EDIT_EVENT_OFFER_SUCCESS = 'EDIT_EVENT_OFFER_SUCCESS';
export const EDIT_EVENT_OFFER_FAILURE = 'EDIT_EVENT_OFFER_FAILURE';

export const FETCH_EVENT_OFFERS = 'FETCH_EVENT_OFFERS';
export const FETCH_EVENT_OFFERS_SUCCESS = 'FETCH_EVENT_OFFERS_SUCCESS';
export const FETCH_EVENT_OFFERS_FAILURE = 'FETCH_EVENT_OFFERS_FAILURE';

export const SHOW_EVENT_OFFER = 'SHOW_EVENT_OFFER';
export const SHOW_EVENT_OFFER_SUCCESS = 'SHOW_EVENT_OFFER_SUCCESS';
export const SHOW_EVENT_OFFER_FAILURE = 'SHOW_EVENT_OFFER_FAILURE';

export const CANCEL_EVENT_OFFER_SUCCESS = 'CANCEL_EVENT_OFFER_SUCCESS';