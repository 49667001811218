import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/billing"
import Loader from "../../../../../utils/loader";
import format from "../../../../../utils/locale";
import BillingSubscriptionUpdate from "../edit";

interface Subscription {
    id: number;
    amount: number;
    startDate: string;
    endDate: string;
    intervalType: "monthly"|"annual";
    intervalCount: number;
    modelId: number;
    autoRenewal: boolean;
    canceled: boolean;
    capacity: {
        used: number,
        max: number
    }
}

const BillingSubscriptionShow = ({company}: {company: string}) => {

    const [subscription, setSubscription] = useState<Subscription>();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        api.subscription(Number(company))
            .then(r => setSubscription(r.data))
            .then(() => setLoading(false))
    }, [company])

    if (loading){
        return <div className={"col-12 py-5 text-center"}>
            <Loader />
        </div>
    }

    if (!subscription){
        return <div className={"col-12 py-5 text-center"}>
            <div className="display-4">
                Aucun abonnement actif
            </div>
            <button className="btn btn-light"
                    data-bs-toggle="modal" data-bs-target="#billingSubscriptionUpdateModal"
            >
                <i className={'bi bi-pencil'}></i> Souscrire un abonnement
            </button>
            <BillingSubscriptionUpdate key={company} company={company} setSubscription={setSubscription} />
        </div>
    }

    return <>
        <div className="p-3">
            <div className="col-12 mb-3">
                <div className="p-3 bg-light">
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>
                                    Montant
                                </th>
                                <th>
                                    Interval de facturation
                                </th>
                                <th>
                                    Date de début
                                </th>
                                <th>
                                    Date de fin
                                </th>
                                <th>
                                    Renouvelement automatique
                                </th>
                                <th>
                                    Espace occupé
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                {subscription.amount.toFixed(2)}€ HT
                            </td>
                            <td>
                                {subscription.intervalType === "annual" ? "Annuel" : "Mensuel"}
                            </td>
                            <td>
                                {format(new Date(subscription.startDate))}
                            </td>
                            <td>
                                {format(new Date(subscription.endDate))}
                            </td>
                            <td>
                                {subscription.autoRenewal ? "Oui" : "Non"}
                            </td>
                            <td>
                                {subscription.capacity.used} / {subscription.capacity.max}
                            </td>
                            <td>
                                <button className="btn btn-light"
                                        data-bs-toggle="modal" data-bs-target="#billingSubscriptionUpdateModal"
                                >
                                    <i className={'bi bi-pencil'}></i> Mettre à niveau
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <BillingSubscriptionUpdate key={company} company={company} _model={subscription.modelId} setSubscription={setSubscription} />
    </>
}

export default BillingSubscriptionShow;