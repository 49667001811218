import {Middleware} from "redux";
import {RootStateOrAny} from "react-redux";
import {showAlertDanger} from "../../actions/alert";
import {AXIOS_ERROR} from "../../constants/axios";
import {AxiosError} from "axios";
import {logout} from "../../actions/auth";

export const errorHandler: Middleware<
    {},
    RootStateOrAny
    > = store => next => action => {
    try {
        if (action.type === AXIOS_ERROR){
            let error: AxiosError = action.error;

            if (error.response){
                switch (error.response.status){
                    case 400:
                        store.dispatch(showAlertDanger(error.response?.data?.message || "Une erreur est survenue"));
                        break;
                    case 401:
                        sessionStorage.setItem('session_expired', 'Votre session a expiré');
                        store.dispatch(logout());
                        break;
                    case 403:
                        window.location.href = "#/403";
                        break;
                    case 500:
                        window.location.href = "#/500";
                        break;
                }
            }
        }

        return next(action)
    } catch (err) {
        console.error('Caught an exception!', err)
        throw err
    }
}