import * as types from "../../constants/counter";
import * as models from "../../models/counter";

export const fetchReporting = () => {
    return {
        type: types.FETCH_COUNTERS
    }
}

export const fetchCountersFailure = (error: string) => {
    return {
        type: types.FETCH_COUNTERS_FAILURE,
        error: error
    }
}

export const fetchCountersSuccess = (totalCounters: models.TotalCounters) => {
    return {
        type: types.FETCH_COUNTERS_SUCCESS,
        payload: totalCounters,
    }
}


export const showCounter = () => {
    return {
        type: types.SHOW_COUNTER
    }
}

export const showCounterFailure = (error: string) => {
    return {
        type: types.SHOW_COUNTER_FAILURE,
        error: error
    }
}

export const showCounterSuccess = (totalCounter?: models.TotalCounter|any) => {
    return {
        type: types.SHOW_COUNTER_SUCCESS,
        single: totalCounter,
    }
}

export const editCounterSuccess = (totalCounter: models.TotalCounter) => {
    return {
        type: types.EDIT_COUNTER_SUCCESS,
        single: totalCounter,
    }
}

export const refreshCounter = () => {
    return {
        type: types.REFRESH_COUNTER
    }
}
