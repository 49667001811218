export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAILURE = 'ADD_DOCUMENT_FAILURE';

export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const EDIT_DOCUMENT_SUCCESS = 'EDIT_DOCUMENT_SUCCESS';
export const EDIT_DOCUMENT_FAILURE = 'EDIT_DOCUMENT_FAILURE';

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';

export const SHOW_DOCUMENT = 'SHOW_DOCUMENT';
export const SHOW_DOCUMENT_SUCCESS = 'SHOW_DOCUMENT_SUCCESS';
export const SHOW_DOCUMENT_FAILURE = 'SHOW_DOCUMENT_FAILURE';

export const CANCEL_DOCUMENT_SUCCESS = 'CANCEL_DOCUMENT_SUCCESS';
