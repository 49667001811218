import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as action from "../../../../actions/counter";
import { ResponsiveBar } from '@nivo/bar'
import {Modal} from "bootstrap";

const CounterChartMultiple:React.FC<{payload: any[]}> = (props) => {

    const {payload} = props;
    const dispatch = useDispatch();
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const [barContainerHeight, setBarContainerHeight] = useState<number>(0)

    useEffect(() => {
        let container = document.getElementById('barContainer')!
        setBarContainerHeight(container.clientHeight - 30);
    }, [])

    return <>
        <div className="col flex-grow-1">
            <div className="w-100 overflow-auto"
                 id={'barContainer'}
                 style={{height: 150 + (barContainerHeight > payload.length * 40 ? barContainerHeight : payload.length * 40)}}
            >
                <ResponsiveBar
                    layout={'horizontal'}
                    data={payload}
                    enableGridY={true}
                    enableGridX={true}
                    onClick={(data) => {
                        dispatch(action.showCounterSuccess(data.data))
                        let modalEl = document.getElementById('exampleModal')!
                        let modal = new Modal(modalEl);
                        modal.toggle();
                    }}
                    groupMode={"stacked"}
                    keys={[auth.user.currentSalary.companyGroup.accountingSettings.overtimeMarkUp ? 'totalMarkUp' : 'total', 'totalWithdrawn']}
                    indexBy={'salary.title'}
                    margin={{top: 50, right: 15, bottom: 15, left: 150}}
                    padding={0.1}
                    innerPadding={0}
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={['#727cf5', '#20c997']}
                    labelTextColor={'#fff'}
                    borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                    axisBottom={null}
                    axisLeft={{
                        tickRotation: 0,
                        legend: null,
                        ticksPosition: 'before',
                        tickPadding: 10,
                        // renderTick: CustomTopTick
                    }}
                    axisTop={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: null,
                    }}
                    labelSkipWidth={25}
                    labelSkipHeight={15}
                    tooltip={({id, value, color, data}) => (
                        <div className={'shadow'}
                             style={{
                                 borderRadius: 3,
                                 padding: 7,
                                 color,
                                 background: '#fff',
                             }}
                        >
                            <span className={'text-primary'}>{data.salary.title}</span>
                            <br/>
                            <strong>
                                <span className={'text-primary'}>
                                    Compteur : {auth.user.currentSalary.companyGroup.accountingSettings.overtimeMarkUp ? data.totalMarkUp : data.total}
                                </span>
                                <span className="clearfix"> </span>
                                {data.totalWithdrawn && <span className={'text-success'}>
                                    Retiré : {data.totalWithdrawn}
                                </span>}
                            </strong>
                        </div>
                    )}
                    valueFormat={(value) => `${value}`}
                />
            </div>
            </div>
        </>
}

export default CounterChartMultiple;