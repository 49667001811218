import * as types from "../../constants/localisation"
import * as models from "../../models/localisation"
import replace from "../../utils/arrayReplace"

const localisation = (state: models.initialStateInterface = models.initialState, action: {type: string, localisation: models.Localisation, localisations: models.Localisations, error: string}) => {
    switch (action.type) {
        case types.FETCH_LOCALISATIONS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_LOCALISATIONS_SUCCESS:
            return { payload: action.localisations, single: state.single, isLoading: false, error: null };
        case types.FETCH_LOCALISATIONS_FAILURE:
            return { payload: action.localisations, single: state.single, isLoading: false, error: action.error };

        case types.ADD_LOCALISATION:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.ADD_LOCALISATION_SUCCESS:
            return { payload: [...state.payload, action.localisation], single: action.localisation, isLoading: false, error: null };

        case types.EDIT_LOCALISATION:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_LOCALISATION_SUCCESS:
            return { payload: replace(state.payload, action.localisation), single: action.localisation, isLoading: false, error: null };
        case types.SHOW_LOCALISATION:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.SHOW_LOCALISATION_SUCCESS:
            return { payload: state.payload, single: action.localisation, isLoading: false, error: null };
        case types.SHOW_LOCALISATION_FAILURE:
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default localisation;