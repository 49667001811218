import {get, post} from "../../xhr";

interface BookingOfferPayload {
    description: string,
    beginAt: Date,
    endAt: Date,
    salaries: number[],
    localisation?: number,
    activity?: number,
}

export function list(requestData: {start?: Date, end?: Date}, signal: AbortSignal){
    return post('booking/offer/list', requestData, {signal: signal});
}

export function edit(requestData: BookingOfferPayload, id: number){
    return post('booking/offer/edit/' + id, requestData);
}

export function create(requestData: BookingOfferPayload){
    return post('booking/offer/create',  requestData);
}

export function disable(id: number){
    return get('booking/offer/disable/' + id);
}

export function show(id: number){
    return get('booking/offer/show/' + id);
}

export function enable(id: number){
    return get('booking/offer/enable/' + id);
}