import React, {useEffect, useState} from "react";
import BookingAddForm from "../../../booking/add/form";
import {CalendarApi} from "@fullcalendar/react";
import {setHours, setMinutes} from "date-fns";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Activity} from "../../../../models/activity";
import {Localisation} from "../../../../models/localisation";

const BookingAdd:React.FC<{api: () => CalendarApi, close: () => any, hasApi: () => boolean, handleSubmitSuccess: (ev: any[]) => any, salary?: Salary, company?: Company, localisation?: Localisation, activity?: Activity}> = (props) => {

    const {hasApi, api, handleSubmitSuccess, salary, company, activity, localisation, close} = props;

    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [salary, company, activity, localisation])

    useEffect(() => {
        if (hasApi()){
            setStart(setMinutes(setHours(api()?.getDate(), (new Date()).getHours()), 0))
            setEnd(setMinutes(setHours(api()?.getDate(), (new Date()).getHours() + 1), 0))
        }
    }, [])


    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarBookingAddLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <BookingAddForm
                key={key}
                start={start}
                end={end}
                initialSalary={salary}
                initialCompany={company}
                localisation={localisation}
                activity={activity}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default BookingAdd;