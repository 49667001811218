import React, {useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import format from "../../../../../utils/locale";
import {getAccountingTypeChoice} from "../../../../../constants/booking";
import * as calendarTypes from "../../../../../constants/calendar";
import * as api from "../../../../../adapters/template/applied/exception";
import * as absenceApi from "../../../../../adapters/absence";
import {TYPE_EVENT, TYPE_EVENT_EXCEPTION} from "../../../../../constants/event";
import SmallLoader from "../../../../../utils/loader/small";

interface Interface {
    arg: EventClickArg,
    handleEventChange: (ev: any) => any,
    calendar: string,
    setCalendarAbsenceSidebarProps: React.Dispatch<any>,
    setCalendarTemplateSidebarProps: React.Dispatch<any>,
    close: () => any,
    setCalendarSignedSidebarProps: React.Dispatch<any>,
}

const CalendarTemplateEventShow:React.FC<Interface> = (props) => {

    const {arg, handleEventChange, calendar, setCalendarAbsenceSidebarProps, setCalendarSignedSidebarProps, setCalendarTemplateSidebarProps, close} = props;

    const [removingEvent, setRemovingEvent] = useState<boolean>(false)
    const [removingAbsence, setRemovingAbsence] = useState<boolean>(false)

    const removeEvent = () => {
        setRemovingEvent(true)
        api.cancel(arg.event.extendedProps.templateId, arg.event.extendedProps.eventId, arg.event.extendedProps.exDay, {
            salary: arg.event.extendedProps.salary.id,
            start: arg.event.start,
            end: arg.event.end,
            accountingType: arg.event.extendedProps.accountingType,
            activity: arg.event.extendedProps.activity?.id,
            localisation: arg.event.extendedProps.localisation?.id,
            comment: arg.event.extendedProps.comment
        })
            .then(resp => removeEventSuccess(resp.data))
    }

    const [removingSub, setRemovingSub] = useState(false)
    const cancelSub = () => {
        setRemovingSub(true)
        api.unsub(arg.event.extendedProps.templateId, arg.event.extendedProps.exceptionId)
            .then(resp => removeSubSuccess(resp.data))
    }

    const removeAbsence = () => {
        if (arg.event.extendedProps.absence){
            setRemovingAbsence(true);
            absenceApi.cancel(arg.event.extendedProps.absence.id).then((data) => removeAbsenceSuccess(data.data))
        }
    }

    const removeEventSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => close())
            .then(() => setRemovingEvent(false))
            .then(() => true)
    }

    const removeSubSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => close())
            .then(() => setRemovingSub(false))
            .then(() => true)
    }

    const removeAbsenceSuccess = (ev: any) => {
        if(arg){
            return Promise.all([
                handleEventChange(ev)
            ])
                .then(() => arg.event.setExtendedProp('absence', null))
                .then(() => setRemovingAbsence(false))
                .then(() => true)
        }
    }

    const setLabourDayWorked = (worked: boolean) => {
        api.labourDay(arg.event.extendedProps.templateId, arg.event.extendedProps.eventId, arg.event.extendedProps.exDay, worked, {
            salary: arg.event.extendedProps.salary.id,
            start: arg.event.start,
            end: arg.event.end,
            accountingType: arg.event.extendedProps.accountingType,
            activity: arg.event.extendedProps.activity?.id,
            localisation: arg.event.extendedProps.localisation?.id,
            comment: arg.event.extendedProps.comment
        })
            .then(resp => handleEventChange(resp.data))
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventLabel">Créneau de {arg.event.extendedProps.salary.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        {<div className="offcanvas-body">
            <h4>
                Détails du créneau
            </h4>
            <div className="mb-3">
                <div className="table w-100">
                    <tbody>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch"}></i> Début
                        </td>
                        <td>
                            {format(arg.event.start!, "E dd MMMM uuuu HH:mm")}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch-fill"}></i> Fin
                        </td>
                        <td>
                            {format(arg.event.end!, "E dd MMMM uuuu HH:mm")}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-clock'}></i> Type d'heure
                        </td>
                        <td>
                            {getAccountingTypeChoice(arg.event.extendedProps.accountingType)?.label}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-shop'}></i> Établissement
                        </td>
                        <td>
                            {arg.event.extendedProps.company?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-geo-alt'}></i> Emplacement
                        </td>
                        <td>
                            {arg.event.extendedProps.localisation?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-bookmark'}></i> Activité
                        </td>
                        <td>
                            {arg.event.extendedProps.activity?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    </tbody>
                </div>

                {arg.event.extendedProps.substitution && <div className={'alert alert-info  d-flex align-items-center'}>
                    <div className="flex-grow-1">
                        <i className={'bi bi-arrow-down-up'}></i> Remplacement de {arg.event.extendedProps.substituted.title}
                    </div>
                    <div className="flex-grow-0">
                        <button disabled={removingSub} type={"button"} className={'btn btn-light'} onClick={() => cancelSub()}>
                            {removingSub ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Annuler</>}
                        </button>
                    </div>
                </div>}

                {!arg.event.extendedProps.substitution && arg.event.extendedProps.substitute && <div className={'alert alert-info d-flex align-items-center'}>
                    <div className="flex-grow-1">
                        <i className={'bi bi-arrow-down-up'}></i> Remplacer par {arg.event.extendedProps.substitute.title}
                    </div>
                    <div className="flex-grow-0">
                        <button disabled={removingSub} type={"button"} className={'btn btn-light'} onClick={() => cancelSub()}>
                            {removingSub ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Annuler</>}
                        </button>
                    </div>
                </div>}

                <div className="row mb-3">
                    <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarTemplateSidebarProps((prev: any) => ({
                                    sidebarType: 'TEMPLATE_EVENT_EDIT',
                                    trigger: prev.trigger + 1,
                                    arg: arg,
                                }))}
                        >
                            <i className={"bi bi-pencil text-primary"}></i> Modifier
                        </button>
                    </div>
                    <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'} onClick={() => removeEvent()}>
                            {removingEvent ? <SmallLoader/> : <><i className={"bi bi-dash-circle text-danger"}></i> Supprimer </>}
                        </button>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    {!arg.event.extendedProps.substitution && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarTemplateSidebarProps((prev: any) => ({
                                    sidebarType: 'TEMPLATE_EVENT_SUBSTITUTE',
                                    trigger: prev.trigger + 1,
                                    arg: arg,
                                }))}
                        >
                            <i className={"bi bi-arrow-repeat text-primary"}></i> Remplacer
                        </button>
                    </div>}
                </div>
            </div>

            {calendarTypes.CALENDAR_SIGNED_GLOBAL === calendar && <>
                <div className="col-12 mb-3">
                    <button onClick={() => {
                        setCalendarSignedSidebarProps((prev: any) => ({
                            sidebarType: "SIGNED_ADD",
                            trigger: prev.trigger + 1,
                            company: arg.event.extendedProps.company,
                            salary: arg.event.extendedProps.salary,
                            start: arg.event.start!,
                            end: arg.event.end!,
                        }))
                    }} type={'button'} className={'btn btn-light w-100'} >
                        <i className={'bi bi-check2-all text-primary'}></i> Ajouter le créneau aux badgeages
                    </button>
                </div>
            </>}

            {arg.event.extendedProps.isLabourDay && <div className="col-12 mb-3">
                <hr/>
                <h4>
                    Jour férié
                </h4>
                <p className="form-text">
                    Cochez la case ci dessous si le collaborateur travail ce jour.
                </p>
                <div className="form-check form-switch">
                    <input className="form-check-input" defaultChecked={!!arg.event.extendedProps.labourDayWorked}
                           onChange={(e) => setLabourDayWorked(e.target.checked)} type="checkbox" id="labourDayWorked"/>
                    <label className="form-check-label" htmlFor="labourDayWorked">Jours fériés travaillé</label>
                </div>
            </div>}

            {arg.event.extendedProps.absence && <div>
                <hr/>
                <h4>
                    Absence
                </h4>
                <table className={'table w-100'}>
                    <tbody>
                    <tr>
                        <td>
                            Type
                        </td>
                        <td>
                            {arg.event.extendedProps.absence.typeName}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Début
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.absence.start))}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Fin
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.absence.end))}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Nb de jours
                        </td>
                        <td>
                            {arg.event.extendedProps.absence.nbDays}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="row mb-3">
                    <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarAbsenceSidebarProps((prev: any) => ({
                                    sidebarType: 'ABSENCE_EDIT',
                                    trigger: prev.trigger + 1,
                                    arg: arg,
                                }))}
                        >
                            <i className={"bi bi-pencil text-primary"}></i> Modifier
                        </button>
                    </div>
                    <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'} onClick={() => removeAbsence()}>
                            {removingAbsence ? <SmallLoader/> : <><i
                                className={"bi bi-dash-circle text-danger"}></i> Supprimer</>}
                        </button>
                    </div>
                </div>
            </div>}
        </div>}
    </>
}

export default CalendarTemplateEventShow;