import React, { useState } from 'react'

import EmptyList from "../../../../../common/EmptyList"
import DatePicker from "react-datepicker"

import { Subscription } from '../../../../../../models/companies'

import { SUBSCRIPTIONS_MODELS_NORMAL_RATE, SUBSCRIPTIONS_MODELS_REDUCED_RATE } from "../../../../../../constants/subscriptions"

import DateCustomInput from "../../../../../../utils/customInput"
import moment from "../../../../../../utils/moment.utils"

interface IProps {
  subscriptions: Subscription[];
  companyGroupBillingRate: number|null;
  subscriptionIsLoading: boolean;
  onCreateSubscription: (data: { subscriptionModelId: number, subscriptionStartDate: Date }) => void;
  onUpdateSubscription: (data: { subscriptionModelId: number, subscriptionStartDate: Date }) => void;
}

const CompanySubscriptionsItem: React.FC<IProps> = ({ subscriptions, companyGroupBillingRate, subscriptionIsLoading, onCreateSubscription, onUpdateSubscription }) => {    
  const [selectedSubscriptionModel, setSelectedSubscriptionModel] = useState<number|undefined>(undefined)
  const [subscriptionStartDate, setSubsciptionStartDate] = useState<Date>(new Date())
  const [showUpdateSubscription, setShowUpdateSubscription] = useState<number|undefined>(undefined)
  const [selectedToUpdateSubscriptionModel, setSelectedToUpdateSubscriptionModel] = useState<number|undefined>(undefined)

  const subscriptionModelsOptions = companyGroupBillingRate === 2 ? SUBSCRIPTIONS_MODELS_REDUCED_RATE : SUBSCRIPTIONS_MODELS_NORMAL_RATE;
  const hasActiveSubscription = subscriptions.findIndex(sub => !sub.canceled) !== -1

  const onSubmit = async () => {    
    if (selectedSubscriptionModel && subscriptionStartDate) {
      onCreateSubscription({
        subscriptionModelId: selectedSubscriptionModel,
        subscriptionStartDate
      })
    }
  }

  return (
    <div className="p-3">
      <div>
        {subscriptions.length === 0 &&
          <EmptyList text="Cette entreprise n’a aucun abonnement associé" />
        }

        {subscriptions.map(subscription => {
          const model = SUBSCRIPTIONS_MODELS_NORMAL_RATE.concat(SUBSCRIPTIONS_MODELS_REDUCED_RATE).find(model => model.model_id === subscription.modelId)

          return (
            <div key={subscription.id} className="my-1 py-3 px-4 border rounded-2 container g-0">
              <div className="row align-items-center">
                <div className="col-5">
                  <p className="m-0">
                    {model ? `${subscription.amount.toFixed(2)}€ (entre ${model.min} et ${model.max} salariés)` : '???'}
                  </p>
                  <p className="m-0 text-muted">
                    {subscription?.capacity?.used} comptes utilisés sur {subscription?.capacity?.max} max 
                  </p>
                  {!subscription?.canceled &&
                    <div className="d-flex">
                      <p className="m-0 text-primary" style={{ cursor: 'pointer' }} onClick={() => setShowUpdateSubscription(showUpdateSubscription ? undefined : subscription.id)}>
                        Modifier l’abonnement
                      </p>
                    </div>
                  }
                </div>

                <div className="col-5">
                  <p className="m-0 text-muted">
                    Début: <span className="text-body">{moment(subscription.startDate).format('DD MMMM YYYY')}</span>
                  </p>
                  <p className="m-0 text-muted">
                    Fin: <span className="text-body">{moment(subscription?.endDate).format('DD MMMM YYYY')}</span>
                  </p>
                </div>

                <div className="col-2 d-flex justify-content-end">
                  <span className={`mb-1 badge ${subscription?.canceled ? 'bg-secondary' : 'bg-success'}`}>
                      {subscription?.canceled ? 'Annulé' : 'Actif'}
                  </span>
                </div>
              </div>

              {showUpdateSubscription === subscription.id &&
                <div className="p-3 border-t">
                  <p>
                    Modifier l’abonnement en cours
                  </p>
          
                  <div className="row">
                    <div className="col-5">
                      <select
                        className="form-select py-3"
                        value={selectedSubscriptionModel}
                        onChange={(e: any) => {            
                          setSelectedToUpdateSubscriptionModel(e.target.value === "0" ? undefined : parseInt(e.target.value))
                        }}
                      >
                        <option selected value={0}>Modèles d’abonnement</option>
                        {subscriptionModelsOptions.map(subscriptionModel => (
                          <option value={subscriptionModel.model_id}>
                            {`${subscriptionModel.amount.toFixed(2)}€ (entre ${subscriptionModel.min} et ${subscriptionModel.max} salariés)`}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-5">
                      <DatePicker
                        customInput={<DateCustomInput label={'Date de début'} />}
                        className={"form-control"}
                        onChange={(date: Date) => {
                            setSubsciptionStartDate(date)
                        }}
                        dateFormat={"E dd MMM uuuu"}
                        selected={subscriptionStartDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        startDate={subscriptionStartDate}
                      />
                    </div>
          
                    <div className="col-2 d-flex justify-content-end align-items-center">
                      <button
                        className="btn btn-primary text-white"
                        disabled={subscriptionIsLoading || !selectedToUpdateSubscriptionModel || !subscriptionStartDate}
                        onClick={() => {
                          if (selectedToUpdateSubscriptionModel && subscriptionStartDate) {
                            onUpdateSubscription({
                              subscriptionModelId: selectedToUpdateSubscriptionModel,
                              subscriptionStartDate
                            })
                            setShowUpdateSubscription(undefined)
                          }
                        }}
                      >
                        Valider
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        })}
      </div>

      {!hasActiveSubscription &&
        <div className="mt-2 p-3 bg-light border rounded">
          <p>
            Ajouter un nouvel abonnement
          </p>

          <div className="row">
            <div className="col-5">
              <select
                className="form-select py-3"
                value={selectedSubscriptionModel}
                onChange={(e: any) => {            
                  setSelectedSubscriptionModel(e.target.value === "0" ? undefined : parseInt(e.target.value))
                }}
              >
                <option selected value={0}>Modèles d’abonnement</option>
                {subscriptionModelsOptions.map(subscriptionModel => (
                  <option value={subscriptionModel.model_id}>
                    {`${subscriptionModel.amount.toFixed(2)}€ (entre ${subscriptionModel.min} et ${subscriptionModel.max} salariés)`}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-5">
              <DatePicker
                customInput={<DateCustomInput label={'Date de début'} />}
                className={"form-control"}
                onChange={(date: Date) => {
                    setSubsciptionStartDate(date)
                }}
                dateFormat={"E dd MMM uuuu"}
                selected={subscriptionStartDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                startDate={subscriptionStartDate}
              />
            </div>

            <div className="col-2 d-flex justify-content-end align-items-center">
              <button
                className="btn btn-primary text-white"
                disabled={subscriptionIsLoading || !selectedSubscriptionModel || !subscriptionStartDate}
                onClick={onSubmit}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CompanySubscriptionsItem