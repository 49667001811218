export const ADD_ACCESS_LEVEL = 'ADD_ACCESS_LEVEL';
export const ADD_ACCESS_LEVEL_SUCCESS = 'ADD_ACCESS_LEVEL_SUCCESS';
export const ADD_ACCESS_LEVEL_FAILURE = 'ADD_ACCESS_LEVEL_FAILURE';

export const EDIT_ACCESS_LEVEL = 'EDIT_ACCESS_LEVEL';
export const EDIT_ACCESS_LEVEL_SUCCESS = 'EDIT_ACCESS_LEVEL_SUCCESS';
export const EDIT_ACCESS_LEVEL_FAILURE = 'EDIT_ACCESS_LEVEL_FAILURE';

export const FETCH_ACCESS_LEVELS = 'FETCH_ACCESS_LEVELS';
export const FETCH_ACCESS_LEVELS_SUCCESS = 'FETCH_ACCESS_LEVELS_SUCCESS';
export const FETCH_ACCESS_LEVELS_FAILURE = 'FETCH_ACCESS_LEVELS_FAILURE';

export const SHOW_ACCESS_LEVEL = 'SHOW_ACCESS_LEVEL';
export const SHOW_ACCESS_LEVEL_SUCCESS = 'SHOW_ACCESS_LEVEL_SUCCESS';
export const SHOW_ACCESS_LEVEL_FAILURE = 'SHOW_ACCESS_LEVEL_FAILURE';
