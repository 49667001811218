import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import SlateEditor from "../editor";

import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import * as api from "../../../adapters/document";
import FormError from "../../../utils/formError";
import { showAlertSuccess } from "../../../actions/alert";

const DocumentAdd:React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle("Document", "Nouveau document"))
        dispatch(setHelpProductSheetIds([18]))

        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            title: "",
            value: []
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            value: Yup.array().of(Yup.object()).required()
        }),
        onSubmit: values => {
            api.create(values).then(() => {
                dispatch(showAlertSuccess('Document Ajouté'))
                window.location.href = "#/document"
            });
        }
    })

    return (
        <div className={'container-fluid py-3'}>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input
                            id={'title'}
                            className="form-control"
                            name={'title'}
                            type={'text'}
                            placeholder={' '}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="title">
                            Nom du document
                        </label>
                        <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
                    </div>
                </div>

                <div className="col-12 mb-3">
                    <SlateEditor
                        toolbar={true}
                        disabled={formik.isSubmitting}
                        setValue={formik.setFieldValue}
                        value={formik.values.value}
                        onChange={(value => {
                            formik.setFieldValue('value', value)
                        })}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'value'} />
                </div>

                <button type={"submit"} className={'btn btn-outline-primary w-100'}>
                    <i className={'bi bi-plus'}> </i> Ajouter
                </button>
            </form>
        </div>
    )
}

export default DocumentAdd;