import Voter from "../voter";
import {User} from "../../models/auth";
import AccessRightChecker from "../accessRightChecker";
import * as accessRights from "../../constants/accessRight";
import AbsenceVoter from "../voter/absence";
import getUser from "../getUser";
import EventOfferVoter from "../voter/eventOffer";
import ExpenseVoter from "../voter/expense";
import TaskVoter from "../voter/task";

export const Granted = (right: string, salary?: number, object?: any) => {
    let storage = localStorage.getItem('user')

    if (storage){
        let user: User = JSON.parse(storage)
                
        if (!user.currentSalary){
            return false;
        }

        if (AccessRightChecker.hasGlobalRight(right)){
            return true;
        }

        if (salary){
            return AccessRightChecker.grantedFor(right, salary);
        }else if(!object){

            if (AccessRightChecker.hasManagerRight(right)){
                return true;
            }

            switch (right)
            {
                case "HAS_ACCOUNT":
                    return true;
                case accessRights.LIST_ABSENCE:
                    return AccessRightChecker.hasGlobalRight(accessRights.REQUEST_ABSENCE);
                case accessRights.LIST_EXPENSE:
                    return AccessRightChecker.hasGlobalRight(accessRights.REQUEST_EXPENSE);
                case accessRights.LIST_BOOKING_OFFER:
                    return AccessRightChecker.hasGlobalRight(accessRights.EDIT_EXPENSE);
                case accessRights.LIST_TASK_GROUP:
                    return AccessRightChecker.hasGlobalRight(accessRights.LIST_SELF_TASK);
                case accessRights.LIST_TIME_CLOCK:
                    return AccessRightChecker.hasGlobalRight(accessRights.LIST_SELF_TIME_CLOCK);
                case accessRights.LIST_COUNTER:
                    return AccessRightChecker.hasGlobalRight(accessRights.LIST_SELF_COUNTER);
                case accessRights.LIST_REPORTING:
                    return AccessRightChecker.hasGlobalRight(accessRights.LIST_SELF_REPORTING);
                case accessRights.CLOCK_IN_FROM_APP:
                    return user.currentSalary.settings.timeClockFromApp;
            }
        }else if(object){

            if (AbsenceVoter.supports(right, object)){
                return new AbsenceVoter().voteOnAttribute(right, object, getUser())
            }else if (EventOfferVoter.supports(right, object)){
                return new EventOfferVoter().voteOnAttribute(right, object, getUser())
            }else if (ExpenseVoter.supports(right, object)){
                return new ExpenseVoter().voteOnAttribute(right, object, getUser())
            }else if (TaskVoter.supports(right, object)){
                return new TaskVoter().voteOnAttribute(right, object, getUser())
            }

            return false;
        }

        return false;
    }

    return false;
}

export const GrantedAny = (rights: string[], salary?: number) => {

    for (let i in rights){
        if (Granted(rights[i], salary)){
            return true;
        }
    }

    return false;
}

export const GrantedMany = (rights: string[], salary?: number) => {

    for (let i in rights){
        if (!Granted(rights[i], salary)){
            return false;
        }
    }

    return true;
}