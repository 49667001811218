import {Salary} from "../salary";

export interface KeepNote{
    readonly id: number,
    description: string,
    dueDate: string
    private: boolean,
    createdBy: Salary,
    createdAt?: string,
}

export type KeepNotes = KeepNote[];

export interface initialStateInterface {
    payload: KeepNotes,
    single: KeepNote|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};