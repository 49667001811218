import * as accessRights from "../../../constants/accessRight";
import * as models from "../../../models/absence";
import * as constants from "../../../constants/absence";
import {User} from "../../../models/auth";
import {Salary} from "../../../models/salary";
import accessRightChecker from "../../accessRightChecker";

class AbsenceVoter {

    static supports(right: string, object?: models.Absence)
    {
        return [
            accessRights.LIST_ABSENCE,
            accessRights.EDIT_ABSENCE,
            accessRights.REQUEST_ABSENCE,
            accessRights.DELETE_ABSENCE,
            accessRights.EDIT_ABSENCE_FILE,
        ].includes(right) && object
    }

    voteOnAttribute(right: string, object: models.Absence, user: User){

        let currentSalary = user.currentSalary;

        if (!currentSalary){
            return false;
        }

        switch (right){
            case accessRights.EDIT_ABSENCE_FILE:
            case accessRights.EDIT_ABSENCE:
                return this.canEdit(currentSalary, object)
            case accessRights.LIST_ABSENCE:
                return this.canList(currentSalary, object)
            default:
                return accessRightChecker.grantedFor(right, object.salary.id)
        }
    }

    canEdit(salary: Salary, absence: models.Absence){

        if (absence.salary.id === salary.id && !accessRightChecker.granted(accessRights.VALIDATE_SELF_ABSENCE))
        {
            return false;
        }

        if (accessRightChecker.grantedFor(accessRights.EDIT_ABSENCE, absence.salary.id)){
            return true;
        }

        return accessRightChecker.granted(accessRights.VALIDATE_SELF_ABSENCE) && salary.id === absence.salary.id;
    }

    canList(salary: Salary, absence: models.Absence)
    {
        if (accessRightChecker.grantedFor(accessRights.LIST_ABSENCE, absence.salary.id)){
            return true;
        }

        return accessRightChecker.hasGlobalRight(accessRights.REQUEST_ABSENCE) && absence.salary.id === salary.id;
    }
}

export default AbsenceVoter;