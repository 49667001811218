import React, {useEffect, useState} from "react";
import {Salary} from "../../../../../models/salary";
import {SingleSalarySelected, SingleSalarySelector} from "../../../../../utils/salarySelector/single";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {SalaryTemplate} from "../../../../../models/salary/template";
import * as api from "../../../../../adapters/salary/template";
import {Offcanvas} from "bootstrap";
import SmallLoader from "../../../../../utils/loader/small";
import SalarySelector from "../../../selector";

const SalaryTemplateImport:React.FC<{targetSalary: Salary, targetTemplate: SalaryTemplate, setTemplate: React.Dispatch<any>}> = (props) => {

    const [_salary, setSalary] = useState<Salary>()
    const [template, setTemplate] = useState<any>()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    useEffect(() => {
        const el = document.getElementById('offcanvasSalaryTemplateImport')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function importTemplate(){
        setIsSubmitting(true)
        api.copy(template, props.targetTemplate.id).then(resp => {
            props.setTemplate(resp.data)
            close()
            setIsSubmitting(false);
        })
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasSalaryTemplateImport" aria-labelledby="offcanvasSalaryTemplateImportLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasSalaryTemplateImportLabel">Importer un emploi du temps</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className="col-12 mb-3">
                <SalarySelector
                    fetchOptions={true}
                    required={true}
                    onChange={(s: Salary) => {
                        setSalary(s)
                        setTemplate(undefined)
                    }}
                />
            </div>
            {_salary && <div className={'col-12 mb-3'}>
                <BootstrapSelect
                    required={true}
                    enableReinitialize={true}
                    onChange={(c) => setTemplate(c?.value)}
                    fetchEntity={'template'}
                    fetchParams={{salary: _salary.id}}
                    label={'Template à importer'}
                />
            </div>}
            {_salary && template && <>
                <button className={'btn btn-light w-100'} onClick={() => importTemplate()}>
                    {isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </>}
        </div>
    </div>
}

export default SalaryTemplateImport;