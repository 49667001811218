import React, {useEffect, useState} from "react";
import {PeriodsReporting} from "../../../models/reporting";
import {Link} from "react-router-dom";
import {openSidebar} from "../../../actions/rightSidebar";
import {REPORTING_SHOW_WEEK} from "../../../constants/rightSidebar";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {getAvailableFields} from "../../../constants/reporting";
import {refreshSingleReporting, showReportingSuccess} from "../../../actions/reporting";
import * as accessRights from "../../../constants/accessRight";
import * as counterApi from "../../../adapters/counter";
import {axiosError} from "../../../actions/axios";
import * as reportingApi from "../../../adapters/reporting";
import {AccountingSettings} from "../../../models/companyGroup";
import {Tooltip} from "bootstrap";
import {Granted} from "../../../security/granted";

interface ReportingPeriodViewProps {
    displaySalaryColumn: boolean,
    periodsReporting: PeriodsReporting,
    startDate: string,
    endDate: string
}

const ReportingPeriodView:React.FC<ReportingPeriodViewProps> = (props) => {

    const {periodsReporting, displaySalaryColumn, startDate, endDate} = props;
    const [timesheetExpanded, setTimesheetExpanded] = useState(false);
    const [absenceExpanded, setAbsenceExpanded] = useState(false);
    const [counterExpanded, setCounterExpanded] = useState(true);
    const [wageExpanded, setWageExpanded] = useState(false);
    const [savingCounter, setSavingCounter] = useState<number>();
    const state = useSelector((state: RootStateOrAny) => state);
    const settings:AccountingSettings = state.auth.user.currentSalary.companyGroup.accountingSettings;

    const dispatch = useDispatch();

    const saveCounter = (id: number) => {
        setSavingCounter(id)
        counterApi.saveSingle({start: startDate, end: endDate}, id)
            .then(() => {
                reportingApi.fetch({
                    start: startDate,
                    end: endDate,
                    options: {
                        salaries: [id]
                    }
                }).then(_data => {
                    dispatch(refreshSingleReporting(_data.data[0]))
                    setSavingCounter(undefined)
                })
            })
            .catch(error => dispatch(axiosError(error)))
        ;
    }

    const getCollSpan = (type: "timesheet"|"counter"|"absence"|"wage") => {
        let allFields = getAvailableFields();

        let typeFields = allFields.find(af => af.camelCase === type)

        if (typeFields){
            return typeFields.fields.filter(f => settings.fields.includes(f.camelCase)).length
        }

        return 0;
    }

    const hasAny = (type: "timesheet"|"counter"|"absence"|"wage") => {
        let allFields = getAvailableFields();

        let typeFields = allFields.find(af => af.camelCase === type)

        if (typeFields){
            return !!typeFields.fields.filter(f => settings.fields.includes(f.camelCase)).length
        }

        return false;
    }

    useEffect(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })

        return () => {
            tooltipTriggerList.map(t => Tooltip.getInstance(t).hide())
        }
    }, [settings.fields])

    return <>
            <table className={'table mb-0 text-nowrap table-hover table-bordered text-center align-middle'}>
                <colgroup>
                    {displaySalaryColumn && <col span={1} style={{backgroundColor: '#fff'}} />}
                    {hasAny('timesheet') && <col span={timesheetExpanded ? getCollSpan('timesheet') + 1 : 1} style={{backgroundColor: '#f8f9fa'}}/>}
                    {hasAny('counter') && <col
                        span={counterExpanded ? (getCollSpan("counter") + ((Granted(accessRights.EDIT_COUNTER) && settings.fields.includes('counterPeriod')) ? 1 : 0) + (periodsReporting.length > 0 && settings.fields.includes('counterWeek') ? periodsReporting[0].weeks.length - 1 : 0)) : 1}
                        style={{backgroundColor: '#cfe2ff'}}/>}
                    {hasAny("absence") && <col span={absenceExpanded ? getCollSpan('absence') : 1} style={{backgroundColor: '#cff4fc'}}/>}
                    {Granted(accessRights.READ_FLAT_RATE) && hasAny('wage') && <col span={wageExpanded ? getCollSpan('wage') : 1} style={{backgroundColor: '#e2d9f3'}}/>}
                </colgroup>
                <thead className={'top-0 position-sticky'} style={{zIndex: 10}}>
                <tr>
                    {displaySalaryColumn && <th className={'px-3 bg-white left-0 position-sticky'} colSpan={1}>
                    </th>}
                    {hasAny('timesheet') && <th onClick={() => setTimesheetExpanded((prevState => !prevState))}
                         style={{backgroundColor: '#f8f9fa'}}
                         colSpan={timesheetExpanded ? getCollSpan('timesheet') + 1 : 1}>
                        <span className="px-3 text-nowrap">
                            Temps de travail
                            <i className={'ms-3 btn btn-sm btn-outline-primary ' + (timesheetExpanded ? 'bi bi-arrows-angle-contract' : 'bi bi-arrows-fullscreen')}> </i>
                        </span>
                    </th>}
                    {hasAny('counter') && <th onClick={() => setCounterExpanded((prevState => !prevState))}
                         style={{backgroundColor: '#cfe2ff'}}
                         colSpan={counterExpanded ? (getCollSpan("counter") + ((Granted(accessRights.EDIT_COUNTER) && settings.fields.includes('counterPeriod')) ? 1 : 0) + (settings.fields.includes('counterWeek') && periodsReporting.length > 0 && periodsReporting[0].weeks.length > 0 ? periodsReporting[0].weeks.length - 1 : 0)) : 1}>
                        <span className="px-3 text-nowrap">
                            Compteur
                            <i className={'ms-3 btn btn-sm btn-outline-primary ' + (counterExpanded ? 'bi bi-arrows-angle-contract' : 'bi bi-arrows-fullscreen')}> </i>
                        </span>
                    </th>}
                    {hasAny('absence') && <th onClick={() => setAbsenceExpanded((prevState => !prevState))}
                         style={{backgroundColor: '#cff4fc'}} colSpan={absenceExpanded ? getCollSpan('absence') : 1}>
                        <span className="px-3 text-nowrap">
                            Absences
                            <i className={'ms-3 btn btn-sm btn-outline-primary ' + (absenceExpanded ? 'bi bi-arrows-angle-contract' : 'bi bi-arrows-fullscreen')}> </i>
                        </span>
                    </th>}
                    {Granted(accessRights.READ_FLAT_RATE) && hasAny('wage') && <th onClick={() => setWageExpanded((prevState => !prevState))} style={{backgroundColor: '#e2d9f3'}}
                         colSpan={wageExpanded ? getCollSpan('wage') : 1}>
                        <span className="px-3 text-nowrap">
                            Données financière
                            <i className={'ms-3 btn btn-sm btn-outline-primary ' + (wageExpanded ? 'bi bi-arrows-angle-contract' : 'bi bi-arrows-fullscreen')}> </i>
                        </span>
                    </th>}
                </tr>
                <tr style={{zIndex: 10}}>
                    {displaySalaryColumn && <th className={'px-3 bg-white left-0 position-sticky'} style={{zIndex: 10}}>
                        Collaborateur
                    </th>}
                    {hasAny('timesheet') && (timesheetExpanded ?
                        <>
                            <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures contrat  <i className={'bi bi-info-circle'}> </i>
                            </th>
                            {settings.fields.includes('workingTime') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures total  <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('overTimeLow') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures supplémentaires {"<"} 8h <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('overTimeHigh') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures supplémentaires {">"} 8h <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('overTime') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures supplémentaires <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('delta') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Delta <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('nightTime') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures de nuit <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('sundayWorkingTime') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures dimanche <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('labourWorkingTime') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vol. heures jours fériés <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('fullDays') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Jours complets <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {settings.fields.includes('shift') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>
                                Vacations <i className={'bi bi-info-circle'}> </i>
                            </th>}
                            {/*{settings.fields.includes('unaccountedAbsence') && <th style={{backgroundColor: '#f8f9fa'}} className={'px-3'}>*/}
                            {/*    Temps d'absence décompté <i className={'bi bi-info-circle'}> </i>*/}
                            {/*</th>}*/}
                        </>
                        : <th> </th>)}

                    {(counterExpanded ? <>
                        {settings.fields.includes('counterInitial') && <th style={{backgroundColor: '#cfe2ff'}} className={'px-3'}>
                            Compteur début de période <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('counterWeek') && periodsReporting.length > 0 && periodsReporting[0].weeks.map(week => <th style={{backgroundColor: '#cfe2ff'}} className={'px-3'}>
                            S.{week.weekNumber}
                        </th>)}
                        {settings.fields.includes('counterPeriod') && <th style={{backgroundColor: '#cfe2ff'}} className={'px-3'}>
                            Total période <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('counterTotal') && <th style={{backgroundColor: '#cfe2ff'}} className={'px-3'}>
                            Total fin de période <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {Granted(accessRights.EDIT_COUNTER) && settings.fields.includes('counterPeriod') && <th style={{backgroundColor: '#cfe2ff'}}>
                            Enregistrer
                        </th>}
                    </> : <th> </th>)}

                    {hasAny('absence') && (absenceExpanded ? <>
                        {settings.fields.includes('paidVacation') && <th style={{backgroundColor: '#cff4fc'}} className={'px-3'}>
                            Congés payés <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('sickLeave') && <th style={{backgroundColor: '#cff4fc'}} className={'px-3'}>
                            Arrêt maladie <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('recovery') && <th style={{backgroundColor: '#cff4fc'}} className={'px-3'}>
                            Repos <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('rtt') && <th style={{backgroundColor: '#cff4fc'}} className={'px-3'}>
                            RTT <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('unpaidVacation') && <th style={{backgroundColor: '#cff4fc'}} className={'px-3'}>
                            Congés sans solde <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('specialEvent') && <th style={{backgroundColor: '#cff4fc'}} className={'px-3'}>
                            Évènement exceptionnel <i className={'bi bi-info-circle'}> </i>
                        </th>}
                    </> : <th> </th>)}

                    {Granted(accessRights.READ_FLAT_RATE) && hasAny('wage') && (wageExpanded ? <>
                        {settings.fields.includes('flatRate') && <th style={{backgroundColor: '#e2d9f3'}} className={'px-3'}>
                            Taux horaire brut € <i className={'bi bi-info-circle'}> </i>
                        </th>}
                        {settings.fields.includes('monthlyRate') && <th style={{backgroundColor: '#e2d9f3'}} className={'px-3'}>
                            Salaire brut € <i className={'bi bi-info-circle'}> </i>
                        </th>}
                    </> :  <th> </th>)}
                </tr>
                </thead>
                <tbody>
                {periodsReporting.map((reporting, index) => <>
                    <tr key={index}>
                        {displaySalaryColumn && <td style={{cursor: "pointer"}} className={'position-sticky left-0 bg-white'}>
                            <b>
                                <Link to={`/reporting/${reporting.salary.id}/${startDate}/${endDate}`} >{reporting.salary.title}</Link>
                            </b>
                            <div className="clearfix"> </div>
                            <span>
                            {reporting.salary.information?.job.title}
                        </span>
                        </td>}

                        {hasAny('timesheet') && (timesheetExpanded ? <>
                            <td>
                                {reporting.salary.information?.contractWorkingTime +'h' || <i className={'bi bi-x'}> </i>}
                            </td>
                            {settings.fields.includes('workingTime') && <td>
                                {reporting.workingTime || <i className={'bi bi-x'}> </i>}
                            </td>}
                            {settings.fields.includes('overTimeLow') && <td>
                                {reporting.overTimeLow || <i className={'bi bi-x'}> </i>}
                            </td>}
                            {settings.fields.includes('overTimeHigh') && <td>
                                {reporting.overTimeHigh || <i className={'bi bi-x'}> </i>}
                            </td>}
                            {settings.fields.includes('overTime') && <td>
                                {reporting.overTime || <i className={'bi bi-x'}> </i>}
                            </td>}
                            {settings.fields.includes('delta') && <td>
                                {reporting.delta || <i className={'bi bi-x'}> </i>}
                            </td>}
                            {settings.fields.includes('nightTime') && <td>
                                <span className={"form-text"}>
                                    6h <i className={'bi bi-arrow-left-right'}> </i> 8h | 20h <i
                                    className={'bi bi-arrow-left-right'}> </i> 22h :
                                </span> {reporting.nightTime?.low || <i className={'bi bi-x'}> </i>}
                                <div className="clearfix"></div>
                                <span className="form-text">
                                    22h <i className={'bi bi-arrow-left-right'}> </i> 6h :
                                </span> {reporting.nightTime?.high || <i className={'bi bi-x'}> </i>}
                            </td>}
                            {settings.fields.includes('sundayWorkingTime') && <td>
                                {reporting.sundayWorkingTime || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('labourWorkingTime') && <td>
                                {reporting.labourWorkingTime || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('fullDays') && <td>
                                {reporting.fullDays || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('shift') && <td>
                                {reporting.shift || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {/*{settings.fields.includes('unaccountedAbsence') && <td>*/}
                            {/*    {reporting.unaccountedAbsence || <i className={"bi bi-x"}> </i>}*/}
                            {/*</td>}*/}
                        </> : <td onClick={() => setTimesheetExpanded(prevState => !prevState)}>
                            <span className={'text-light-s'}>
                                {reporting.salary.information?.contractWorkingTime +'h' || <i className={'bi bi-x'}> </i>}
                            </span>
                        </td>)}

                        {hasAny('counter') && (counterExpanded ? <>
                            {settings.fields.includes('counterInitial') && <td>
                                {reporting.counterTotalInitial || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('counterWeek') && reporting.weeks.map(week =>  <td>
                                <button disabled={savingCounter === reporting.salary.id} className={"btn btn-sm"} onClick={() => {
                                    dispatch(showReportingSuccess(reporting))
                                    dispatch(openSidebar(REPORTING_SHOW_WEEK, {
                                        currentWeek: week.weekNumber,
                                        start: startDate,
                                        end: endDate
                                    }))
                                }}>
                                    {week.counterSaved ? [week.counterSaved, <i className={'bi bi-check text-success'}> </i>] : <i className={'bi bi-x'}> </i>}
                                    <span className="clearfix"> </span>
                                    {week.counter !== week.counterSaved && <span>
                                        <span className={'form-text text-danger'}>à date :</span> {week.counter}
                                    </span>}
                                </button>
                            </td>)}
                            {settings.fields.includes('counterPeriod') && <td>
                                {!!reporting.counterSaved ? [<i
                                    className={reporting.counterSaved[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>, reporting.counterSaved,
                                    <i className={'bi bi-check text-success'}> </i>] : <i className={'bi bi-x'}> </i>}
                                <div className="clearfix"></div>
                                {reporting.counterSaved !== reporting.counter && !!reporting.counter && <span>
                                       <span className={'form-text text-danger'}>à date :</span> {reporting.counter}
                                    </span>}
                            </td>}
                            {settings.fields.includes('counterTotal') && <td>
                                {reporting.counterSavedTotal ? [<i
                                    className={reporting.counterSavedTotal[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>, reporting.counterSavedTotal,
                                    <i className={'bi bi-check text-success'}> </i>] : <i className={'bi bi-x'}> </i>}
                                <div className="clearfix"></div>
                                {reporting.counterTotal !== reporting.counterSavedTotal && <span>
                                       <span
                                           className={'form-text text-danger'}>à date :</span> {reporting.counterTotal}
                                    </span>}
                            </td>}
                            {settings.fields.includes('counterPeriod') && <td>
                                {Granted(accessRights.EDIT_COUNTER) &&
                                    <button disabled={!!savingCounter} className={'btn'}
                                            onClick={() => saveCounter(reporting.salary.id)}>
                                        {savingCounter === reporting.salary.id ?
                                            <div className="spinner-border spinner-border-sm text-success"
                                                 role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> : <i className={'bi bi-check2-circle text-success'}> </i>}
                                    </button>}
                            </td>}
                        </> : <td onClick={() => setCounterExpanded(prevState => !prevState)}>
                            {settings.fields.includes('counterPeriod') && <span className="text-light-s">
                                {reporting.counter !== '00:00' &&
                                    <i className={reporting.counter[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>} {reporting.counter}
                            </span>}
                        </td>)}
                        {hasAny('absence') && (absenceExpanded ? <>
                            {settings.fields.includes('paidVacation') && <td>
                                {reporting.paidVacation || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('sickLeave') && <td>
                                {reporting.sickLeave || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('recovery') && <td>
                                {reporting.recovery || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('rtt') && <td>
                                {reporting.rtt || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('unpaidVacation') && <td>
                                {reporting.unpaidVacation || <i className={"bi bi-x"}> </i>}
                            </td>}
                            {settings.fields.includes('specialEvent') && <td>
                                {reporting.specialEvent || <i className={"bi bi-x"}> </i>}
                            </td>}
                        </> : <td onClick={() => setAbsenceExpanded(prevState => !prevState)}> </td>)}
                        {Granted(accessRights.READ_FLAT_RATE) && hasAny('wage') && (wageExpanded ? <>
                            {settings.fields.includes('flatRate') && <td>
                                {reporting.salary.information?.hourlyRate ? <>{reporting.salary.information.hourlyRate} €</> :
                                    <i className={"bi bi-x"}></i>}
                            </td>}
                            {settings.fields.includes('monthlyRate') && <td>
                                {reporting.monthlyRate ? <>{reporting.monthlyRate} €</> :
                                    <i className={"bi bi-x"}> </i>}
                            </td>}
                        </> : <td onClick={() => setWageExpanded(prevState => !prevState)}>
                            {settings.fields.includes('monthlyRate') && <>
                                {reporting.monthlyRate ? <>{reporting.monthlyRate} €</> :
                                    <i className={"bi bi-x"}> </i>}
                            </>}
                        </td>)}
                    </tr>
                </>)}
                </tbody>
            </table>
    </>
}

export default ReportingPeriodView;