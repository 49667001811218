export const ADD_APPLIED_RESOURCE = 'ADD_APPLIED_RESOURCE';
export const ADD_APPLIED_RESOURCE_SUCCESS = 'ADD_APPLIED_RESOURCE_SUCCESS';
export const ADD_APPLIED_RESOURCE_FAILURE = 'ADD_APPLIED_RESOURCE_FAILURE';

export const ADD_APPLIED_RESOURCE_INFORMATION = 'ADD_APPLIED_RESOURCE_INFORMATION';
export const ADD_APPLIED_RESOURCE_INFORMATION_SUCCESS = 'ADD_APPLIED_RESOURCE_INFORMATION_SUCCESS';
export const ADD_APPLIED_RESOURCE_INFORMATION_FAILURE = 'ADD_APPLIED_RESOURCE_INFORMATION_FAILURE';

export const EDIT_APPLIED_RESOURCE = 'EDIT_APPLIED_RESOURCE';
export const EDIT_APPLIED_RESOURCE_SUCCESS = 'EDIT_APPLIED_RESOURCE_SUCCESS';
export const EDIT_APPLIED_RESOURCE_FAILURE = 'EDIT_APPLIED_RESOURCE_FAILURE';

export const FETCH_APPLIED_RESOURCES = 'FETCH_APPLIED_RESOURCES';
export const FETCH_APPLIED_RESOURCES_SUCCESS = 'FETCH_APPLIED_RESOURCES_SUCCESS';
export const FETCH_APPLIED_RESOURCES_FAILURE = 'FETCH_APPLIED_RESOURCES_FAILURE';

export const SHOW_APPLIED_RESOURCE = 'SHOW_APPLIED_RESOURCE';
export const SHOW_APPLIED_RESOURCE_SUCCESS = 'SHOW_APPLIED_RESOURCE_SUCCESS';
export const SHOW_APPLIED_RESOURCE_FAILURE = 'SHOW_APPLIED_RESOURCE_FAILURE';
