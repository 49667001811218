import React, { useEffect, useState } from "react";

import TimetableList from "./list";
import TimetableShow from "./show";

import { Salary } from "../../models/salary";
import * as models from "../../models/timetable";
import { Timetables } from "../../models/timetable";

const Timetable: React.FC<{salary: Salary, date?: Date}> = (props) => {
    const {salary, date} = props;

    const [timetable, setTimetable] = useState<models.Timetable>();
    const [timetables, setTimetables] = useState<Timetables>([]);

    useEffect(() => {
        if (timetable) {
            setTimetable(undefined)
        }
    }, [salary])

    return (
        <div className={'col-12 h-100'}>
            <div className="row h-100">
                <div className="col-3 h-100">
                    <TimetableList
                        salary={salary}
                        setTimetables={setTimetables}
                        timetables={timetables}
                        setTimetable={setTimetable}
                        timetable={timetable}
                        date={date}
                    />
                </div>

                <div className="col-9 h-100">
                    <TimetableShow
                        timetable={timetable}
                        salary={salary}
                        setTimetables={setTimetables}
                        setTimetable={setTimetable}
                        timetables={timetables}
                    />
                </div>
            </div>
        </div>
    )
}

export default Timetable;