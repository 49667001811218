import {post, get} from "../../../xhr";

interface PostReaction {
    type: number
}

export function create(requestData: PostReaction, messageId: number){
    return post('channel/message/'+ messageId +'/reaction/create',  requestData);
}

export function edit(requestData: PostReaction, messageId: number, reactionId: number){
    return post('channel/message/'+ messageId +'/reaction/edit/' + reactionId,  requestData);
}

export function remove(messageId: number, reactionId: number){
    return get('channel/message/'+ messageId +'/reaction/remove/' + reactionId);
}