import * as React from "react";

import AccountList from "../../../components/auth/account";

const AccountListPage : React.FC = () => {
    return (
        <AccountList />
    )
}

export default AccountListPage;