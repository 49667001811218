import {Salary} from "../salary";

export interface Document {
    id: number,
    title: string,
    value: string,
    createdBy: Salary,
    editedBy: Salary,
    createdAt: string,
}


export type Documents = Document[];


export interface initialStateInterface {
    payload: Documents;
    single?: Document;
    isLoading: boolean;
    error: string|null;
    hasChanged: boolean;
}

export const initialState: initialStateInterface = {
    payload: [],
    isLoading: false,
    error: null,
    hasChanged: false
};