import React, {useEffect, useState} from "react";
import TaskAddForm from "../../../../task/add/form";
import {Salary} from "../../../../../models/salary";
import {DateSelectArg} from "@fullcalendar/react";

const TaskSelect:React.FC<{close: () => any, handleSubmitSuccess: (ev: any[]) => any, salary?: Salary, arg: DateSelectArg}> = (props) => {

    const {handleSubmitSuccess, salary, close, arg} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [salary])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarTaskSelectLabel">Ajouter une tâche</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <TaskAddForm
                key={key}
                start={arg.start}
                end={arg.end}
                salaries={salary ? [salary] : []}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default TaskSelect;