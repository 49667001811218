import {Localisation} from "../../localisation";
import {Activity} from "../../activity";
import {Booking} from "../index";
import {Salaries, Salary} from "../../salary";
import {Company} from "../../companies";

export interface BookingOfferApplicant {
    id: number,
    salary: Salary,
    createdAt: string,
    booking: Booking,
    validated: boolean,
}

export type BookingOfferApplicants = BookingOfferApplicant[];

export interface BookingOffer {
    id: number,
    beginAt: string,
    endAt: string,
    description: string,
    salaries: Salaries,
    applicants: BookingOfferApplicants,
    company: Company,
    localisation?: Localisation,
    activity?: Activity,
}

export type BookingOffers = BookingOffer[];


export interface initialStateInterface {
    payload: BookingOffers,
    single: BookingOffer|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};