import {post, get} from "../../xhr";

export function edit(requestData: any, id: number){
    return post('template/event/edit/' + id, requestData);
}

export function create(requestData: any){
    return post('template/event/create',  requestData);
}

export function createException(requestData: any){
    return post('template/event/exception/create',  requestData);
}

export function editException(requestData: any, exceptionId: number){
    return post(`template/event/exception/edit/${exceptionId}`,  requestData);
}

export function cancelOccurrence(eventId: number, exDay: number){
    return get(`template/event/cancel_occurrence/${eventId}/${exDay}`);
}
