import React, {useEffect, useState} from "react";
import SignedEditForm from "../form";
import {Offcanvas} from "bootstrap";
import {EventResizeDoneArg} from "@fullcalendar/interaction";

const SignedResizeEdit:React.FC<{arg?: EventResizeDoneArg, handleSubmitSuccess: (ev: any) => any, close: () => any}> = (props) => {

    const {arg, handleSubmitSuccess, close} = props;
    const [key, setKey] = useState<number>(0);

    useEffect(() => {
        let elem = document.getElementById('offcanvasCalendarSigned')!
        elem.addEventListener('hide.bs.offcanvas',  cancelResize)

        return () => elem.removeEventListener('hide.bs.offcanvas',  cancelResize)
    }, [arg])

    const cancelResize = () => {
        if (arg && arg.oldEvent){
            arg.event.setDates(arg.oldEvent.start!, arg.oldEvent.end!);
        }
    }

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1)
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarSignedResizeEditLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <SignedEditForm
                key={key}
                values={{
                    salary: arg.event.extendedProps.salary,
                    start: arg.event.start,
                    end: arg.event.end,
                    company: arg.event.extendedProps.company,
                    timeClockId: arg.event.extendedProps.timeClockId,
                    description: arg.event.extendedProps.description
                }}
                handleSubmitSuccess={handleSubmit}
            />}
        </div>
    </>
}

export default SignedResizeEdit;