import {Salaries, Salary} from "../salary";

export interface TeamManager {
    id: number,
    salary: Salary,
    rights: string[]
}

export type TeamManagers = TeamManager[];

export interface Team {
    id: number,
    title: string,
    salaries: Salaries,
    managers: TeamManagers
}

export type Teams = Team[];

export interface initialStateInterface {
    payload: Teams,
    single: Team|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};

