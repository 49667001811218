import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import format from "../../../../utils/locale";
import {getAccountingTypeChoice} from "../../../../constants/booking";
import * as exceptionApi from "../../../../adapters/booking/exception";
import * as bookingApi from "../../../../adapters/booking";
import * as absenceApi from "../../../../adapters/absence";
import * as calendarTypes from "../../../../constants/calendar";
import {TYPE_EVENT, TYPE_EVENT_EXCEPTION} from "../../../../constants/event";
import SmallLoader from "../../../../utils/loader/small";
import {EVENT_TYPE_BOOKING, EVENT_TYPE_BOOKING_EXCEPTION} from "../../../../constants/calendar";
import {closeSidebar} from "../../../../actions/rightSidebar";
import * as API from "../../../../adapters/booking";
import Granted from "../../../../security/isGranted";
import * as accessRights from "../../../../constants/accessRight";

interface Interface {
    arg: EventClickArg,
    handleEventChange: (ev: any) => any,
    calendar: string,
    setCalendarSidebarBookingProps: React.Dispatch<any>,
    setCalendarSidebarSignedProps: React.Dispatch<any>,
    setCalendarAbsenceSidebarProps: React.Dispatch<any>,
    close: () => any
}


const BookingShow:React.FC<Interface> = (props) => {

    const {arg, handleEventChange, calendar, close, setCalendarSidebarBookingProps, setCalendarAbsenceSidebarProps, setCalendarSidebarSignedProps} = props;

    const [confirmCancel, setConfirmCancel] = useState<boolean>(false)
    const [cancelType, setCancelType] = useState<number>(1)
    const [removingBooking, setRemovingBooking] = useState<boolean>(false)
    const [removingAbsence, setRemovingAbsence] = useState<boolean>(false)

    const removeBooking = () => {
        if (arg){
            setRemovingBooking(true)
            switch (cancelType){
                case 1:
                    API.cancelOnce(arg.event.extendedProps.bookingId, arg.event.extendedProps.instanceToken).then(resp => removeBookingSuccess(resp.data))
                    break;
                case 3:
                    API.cancelAfter(arg.event.extendedProps.bookingId, format(arg.event.start!, 'uuuu-MM-dd')).then(resp => removeBookingSuccess(resp.data))
                    break;
                case 2:
                    API.cancel(arg.event.extendedProps.bookingId).then(resp => removeBookingSuccess(resp.data))
                    break;
            }
        }
    }

    const removeAbsence = () => {
        if (arg.event.extendedProps.absence){
            setRemovingAbsence(true);
            absenceApi.cancel(arg.event.extendedProps.absence.id).then((data) => removeAbsenceSuccess(data.data))
        }
    }

    const removeBookingSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => close())
            .then(() => setRemovingBooking(false))
            .then(() => true)
    }

    const removeAbsenceSuccess = (ev: any) => {
        if(arg){
            return Promise.all([
                handleEventChange(ev)
            ])
                .then(() => arg.event.setExtendedProp('absence', null))
                .then(() => setRemovingAbsence(false))
                .then(() => true)
        }
    }

    const setLabourDayWorked = (worked: boolean) => {
        if (arg){
            switch (arg.event.extendedProps.eventType){
                case EVENT_TYPE_BOOKING:
                    break;
                case EVENT_TYPE_BOOKING_EXCEPTION:
                    break;
            }
        }
    }

    if (confirmCancel && arg){
        return <>
            <div className="offcanvas-header">
                <h5 id="offcanvasCalendarBookingShowLabel">Créneau de {arg.event.extendedProps.salary.title}</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className="w-100 p-3">
                    <div className="col-12 text-center mb-2">
                        Êtes vous sûr de vouloir supprimer le créneau suivant ?
                    </div>
                    <div className="clearfix"> </div>
                    <div className="card p-2 mb-2">
                        <h4>
                            <i className={"bi bi-person"}></i> {arg.event.extendedProps.salary.title}
                        </h4>
                        <div className="col-12 mb-2">
                            <div className={"col"}><i className={'bi bi-calendar'}></i> {format(arg.event.start!, "E dd MMMM uuuu HH:mm")} - {format(arg.event.end!, "HH:mm")}</div>
                            <div className="col">
                                <i className={"bi bi-hourglass"}></i> {arg.event.extendedProps.timeCaption}
                            </div>
                        </div>
                    </div>
                    {arg.event.extendedProps.recurrence.type !== 1 && <>
                        <div className="col-12 mb-2">
                            <p className={'text-primary'}>
                                <i className="bi bi-arrow-repeat"> </i>
                                Créneau récurrent
                                <span className="clearfix"> </span>
                                <span>{arg.event.extendedProps.recurrence.description}</span>
                            </p>

                            <ul className={'list-unstyled align-middle'}>
                                <li className={'mb-1'}>
                                    <button className={'btn w-100 ' + (cancelType === 1 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {
                                        e.stopPropagation();
                                        setCancelType(1)
                                    }}>
                                        {cancelType === 1 && <i className={'bi bi-check'}> </i>}   Une seule fois
                                    </button>
                                </li>
                                <li className={'mb-1'}>
                                    <button className={'btn w-100 ' + (cancelType === 3 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {
                                        e.stopPropagation();
                                        setCancelType(3)
                                    }}>
                                        {cancelType === 3 && <i className={'bi bi-check'}> </i>} Cette occurence et toutes les suivantes
                                    </button>
                                </li>
                                <li className={'mb-1'}>
                                    <button className={'btn w-100 ' + (cancelType === 2 ? 'btn-primary text-white' : 'btn-outline-primary')} onClick={(e) => {
                                        e.stopPropagation();
                                        setCancelType(2)
                                    }}>
                                        {cancelType === 2 && <i className={'bi bi-check'}> </i>} Toutes les occurences
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </>}
                    {arg.event.extendedProps.bookingGroupId && cancelType === 3 && <div className={'col-12 mb-2'}>
                        <i className={'bi bi-exclamation-circle text-warning'}></i> Attention, ce créneau est rataché à l'emploi du temps <span className={'text-primary'}>{arg.event.extendedProps.bookingGroupTitle}</span>,
                        en retirant les occurences suivantes, les autres créneaux ratachés s'arreteront en même temps
                    </div>}
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <button disabled={removingBooking} onClick={(e) => removeBooking()} className={'btn w-100 btn-outline-danger w-100'}>
                                    Valider
                                </button>
                            </div>
                            <div className="col-6">
                                <button disabled={removingBooking} type={'button'} onClick={(e) => setConfirmCancel(false)} className={'btn w-100 btn-light w-100'}>
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarBookingShowLabel">Créneau de {arg.event.extendedProps.salary.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        {!arg && <div className="offcanvas-body">
            <h4>
                Aucun créneau séléctionné
            </h4>
        </div>}
        {arg && <div className="offcanvas-body">
            <h4>
                Détails du créneau
            </h4>
            <div className="mb-3">
                <div className="table w-100">
                    <tbody>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch"}></i> Début
                        </td>
                        <td>
                            {format(arg.event.start!, "E dd MMMM uuuu HH:mm")}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch-fill"}></i> Fin
                        </td>
                        <td>
                            {format(arg.event.end!, "E dd MMMM uuuu HH:mm")}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-clock'}></i> Type d'heure
                        </td>
                        <td>
                            {getAccountingTypeChoice(arg.event.extendedProps.accountingType)?.label}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-shop'}></i> Établissement
                        </td>
                        <td>
                            {arg.event.extendedProps.company?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-geo-alt'}></i> Emplacement
                        </td>
                        <td>
                            {arg.event.extendedProps.localisation?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-bookmark'}></i> Activité
                        </td>
                        <td>
                            {arg.event.extendedProps.activity?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    </tbody>
                </div>

                <div className="row mb-3">
                    {Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id) && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarSidebarBookingProps((prev: any) => ({
                                    sidebarType: 'BOOKING_EDIT',
                                    isOpen: true,
                                    arg: arg,
                                    trigger: prev.trigger + 1
                                }))}
                        >
                            Modifier <i className={"bi bi-pencil text-primary"}></i>
                        </button>
                    </div>}
                    <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'} onClick={() => setConfirmCancel(true)}>
                            Supprimer <i className={"bi bi-dash-circle text-danger"}></i>
                        </button>
                    </div>
                </div>
            </div>

            {Granted(accessRights.EDIT_TIME_CLOCK, arg.event.extendedProps.salary.id) && calendarTypes.CALENDAR_SIGNED_GLOBAL === calendar && <>
                <div className="col-12 mb-3">
                    <button onClick={() => {

                        setCalendarSidebarBookingProps({
                            sidebarType: 'EMPTY',
                            trigger: 0
                        })

                        setCalendarSidebarSignedProps((prev: any) => ({
                            sidebarType: 'SIGNED_ADD',
                            trigger: prev.trigger + 1,
                            company: arg.event.extendedProps.company,
                            salary: arg.event.extendedProps.salary,
                            start: arg.event.start,
                            end: arg.event.end
                        }))

                    }} type={'button'} className={'btn btn-light w-100'} data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasCalendarSignedAdd" aria-controls="offcanvasCalendarSignedAdd">
                        <i className={'bi bi-check2-all text-primary'}></i> Ajouter le créneau aux badgeages
                    </button>
                </div>
            </>}

            {arg.event.extendedProps.isLabourDay && <div className="col-12 mb-3">
                <hr/>
                <h4>
                    Jour férié
                </h4>
                <p className="form-text">
                    Cochez la case ci dessous si le collaborateur travail ce jour.
                </p>
                <div className="form-check form-switch">
                    <input disabled={!Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id)} className="form-check-input" defaultChecked={!!arg.event.extendedProps.labourDayWorked}
                           onChange={(e) => setLabourDayWorked(e.target.checked)} type="checkbox" id="labourDayWorked"/>
                    <label className="form-check-label" htmlFor="labourDayWorked">Jours fériés travaillé</label>
                </div>
            </div>}

            {arg.event.extendedProps.absence && <div>
                <hr/>
                <h4>
                    Absence
                </h4>
                <table className={'table w-100'}>
                    <tbody>
                    <tr>
                        <td>
                            Type
                        </td>
                        <td>
                            {arg.event.extendedProps.absence.typeName}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Début
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.absence.start))}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Fin
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.absence.end))}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Nb de jours
                        </td>
                        <td>
                            {arg.event.extendedProps.absence.nbDays}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="row mb-3">
                    {Granted(accessRights.EDIT_ABSENCE, arg.event.extendedProps.salary.id) && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => {
                                    setCalendarSidebarBookingProps({
                                        sidebarType: "EMPTY",
                                        trigger: 0
                                    })
                                    setCalendarAbsenceSidebarProps((prev: any) => ({
                                        sidebarType: 'ABSENCE_EDIT',
                                        trigger: prev.trigger + 1,
                                        arg: arg,
                                    }))
                                }}
                        >
                            Modifier <i className={"bi bi-pencil text-primary"}></i>
                        </button>
                    </div>}
                    {Granted(accessRights.DELETE_ABSENCE, arg.event.extendedProps.salary.id) && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'} onClick={() => removeAbsence()}>
                            {removingAbsence ? <SmallLoader/> : <>Supprimer <i
                                className={"bi bi-dash-circle text-danger"}></i></>}
                        </button>
                    </div>}
                </div>
            </div>}

        </div>}
    </>
}

export default BookingShow;