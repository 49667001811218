import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import * as api from "../../../../adapters/eventExchange";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import format from "../../../../utils/locale";
import {refresh} from "../../../../actions/calendar";

const EventExchangeAddValidation:React.FC = () => {

    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const dispatch = useDispatch();

    const initialValues: any = {
        event: data.event.eventId,
        exDate: data.event.initialStart,
        target: data.target.eventId,
        targetExDate: data.target.initialStart,
    }

    const validationSchema = Yup.object().shape({
        event: Yup.number().required(),
        exDate: Yup.date().required(),
        target: Yup.number().required(),
        targetExDate: Yup.date().required()
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar());
            api.create(values).then(() => {
                dispatch(refresh());
                dispatch(closeSidebar());
            })
        }
    });

    return <div className={'p-1 p-md-3 h-100 d-flex flex-column'}>
        <div className="flex-grow-1 d-flex align-items-center col-12">
            <div className="row align-items-center w-100">
                <div className="col-12 col-md mb-3">
                    <div className="border border-1 shadow-sm h-100">
                        <div className="card">
                            <div className="card-body">
                                <h4>
                                    <i className={'bi bi-person'}></i> {data.event.salary.title}
                                </h4>
                                <p>
                                    <i className={'bi bi-shop'}></i> {data.event.company.title}
                                </p>
                                <p>
                                    <i className={'bi bi-calendar'}></i>  {format(new Date(data.event.start), "E dd MMMM uuuu HH:mm")} <i className={'mx-1 bi bi-arrow-left-right'}></i> {format(new Date(data.event.end), "E dd MMMM uuuu HH:mm")}
                                </p>
                                <p>
                                    <i className={'bi bi-clock'}></i> {data.event.timeCaption}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md text-center flex-grow-0 my-3">
                    <i className={'bi bi-shuffle fs-4'}></i>
                </div>
                <div className="col-12 col-md mb-3">
                    <div className="border border-1 shadow-sm h-100">
                            <div className="card">
                                <div className="card-body">
                                    <h4>
                                        <i className={'bi bi-person'}></i> {data.target.salary.title}
                                    </h4>
                                    <p>
                                        <i className={'bi bi-shop'}></i> {data.target.company.title}
                                    </p>
                                    <p>
                                        <i className={'bi bi-calendar'}></i>  {format(new Date(data.target.start), "E dd MMMM uuuu HH:mm")} <i className={'mx-1 bi bi-arrow-left-right'}></i> {format(new Date(data.target.end), "E dd MMMM uuuu HH:mm")}
                                    </p>
                                    <p>
                                        <i className={'bi bi-clock'}></i> {data.target.timeCaption}
                                        <i className={'ms-2 ' + (!data.target.timeDiff ? 'bi bi-check text-success' : (data.target.timeDiff > 0 ? 'bi bi-arrow-up text-primary' : "bi bi-arrow-down text-danger"))}></i> {!!data.target.timeDiff && <>{data.target.timeDiffCaption}</>}
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex-grow-0">
            <div className="row">
                <div className="col">
                    <button onClick={() => dispatch(openSidebar(data.next, data.data))} className={'btn btn-outline-danger w-100'}>
                        <i className={'bi bi-check'}></i> Annuler
                    </button>
                </div>
                <div className="col">
                    <button onClick={() => formik.submitForm()} className={'btn btn-outline-success w-100'}>
                        <i className={'bi bi-check'}></i> Valider
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default EventExchangeAddValidation;