import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import * as actions from "../../../../actions/auth/profile";
import * as api from "../../../../adapters/auth/profile";
import {showAlertSuccess} from "../../../../actions/alert";
import {closeSidebar} from "../../../../actions/rightSidebar";
import {axiosError} from "../../../../actions/axios";

const initialSchema = Yup.object().shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    tel: Yup.string().nullable()
})

const ProfileInformationAdd:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state.auth)

    const initialValues = {
        firstname: state.user.salary ? state.user.salary.firstname : '',
        lastname: state.user.salary ? state.user.salary.lastname : '',
        tel: state.user.salary ? state.user.salary.tel : '',
    };

    return (
        <div className='w-100'>
            <Formik
                initialValues={initialValues}
                validationSchema={initialSchema}
                onSubmit={values => {
                    // same shape as initial values
                    dispatch(actions.addUserInformation());
                    api.create(values).then(data => {
                        dispatch(actions.addUserInformationSuccess(data.data))
                        dispatch(showAlertSuccess('Informations ajoutées !'))
                        dispatch(closeSidebar());
                    }).catch(error => dispatch(axiosError(error)))
                }}
            >
                {({ errors, touched }) => (
                    <Form className="">
                        <label className="form-label" htmlFor="firstname">
                            Prénom
                        </label>
                        <Field className='form-control mb-2' name="firstname"/>
                        {errors.firstname && touched.firstname ? (<div className='text-danger form-text' >{errors.firstname}</div>) : null}

                        <label className="form-label" htmlFor="title">
                            Nom
                        </label>
                        <Field className='form-control mb-2' name="lastname"/>
                        {errors.lastname && touched.lastname ? (<div className='text-danger form-text' >{errors.lastname}</div>) : null}

                        <label className="form-label" htmlFor="tel">
                            Tél
                        </label>
                        <Field className='form-control mb-2' name="tel"/>
                        {errors.tel && touched.tel ? (<div className='text-danger form-text' >{errors.tel}</div>) : null}

                        <button className='btn btn-outline-primary w-100' type="submit">Ajouter</button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ProfileInformationAdd;