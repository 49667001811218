import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import FileSaver from "file-saver";
import EmptyList from "../../../../common/EmptyList";

import { Documents } from "../../../../../models/document";

import * as api from "../../../../../adapters/document";
import { showAlertDanger } from "../../../../../actions/alert";

const SalaryDocumentGenerated:React.FC = () => {
    const dispatch = useDispatch();

    const state = useSelector((state:RootStateOrAny) => state.salary);

    const [documents, setDocuments] = useState<Documents>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let controller = new AbortController();
        setIsLoading(true)
        api.list({}, controller.signal)
            .then(data => {
                setDocuments(data.data)
                setIsLoading(false)
            })
        return () => controller.abort();
    }, [state.single.id])

    const download = (id: number) => {
        let doc = documents.find((file => file.id === id))
        if (doc) {
            api.download(state.single.id, doc.id).then(data => {
                let newBlob = new Blob([data.data], { type: 'application/pdf;charset=utf-8' })
                FileSaver.saveAs(newBlob, doc!.title)
            })
                .catch(error => {
                    console.log(error)
                })
        } else {
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    return (
        <>
            {isLoading &&
                <div className={'d-flex align-items-center w-100 text-center'}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="text-light-s ms-3">
                        Chargement en cours...
                    </div>
                </div>
            }

            {!isLoading &&
                <ul className="list-group list-group-flush">
                    {documents.length === 0 && <EmptyList />}
                    {documents.map(d => <li className={'list-group-item d-flex align-items-center'}>
                        <div className="flex-grow-1">
                            <h4>
                                {d.title}
                            </h4>
                        </div>
                        <div className="flex-grow-0">
                            <button onClick={() => download(d.id)} className={'btn btn-outline-primary'}>
                                <i className={'bi bi-download'}> </i> Générer
                            </button>
                        </div>
                    </li>)}
                </ul>
            }
        </>
    )
}

export default SalaryDocumentGenerated;