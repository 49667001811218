import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import * as api from "../../../../adapters/slotExchange";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import format from "../../../../utils/locale";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {refresh} from "../../../../actions/calendar";

const SlotExchangeAddValidation:React.FC = () => {

    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const dispatch = useDispatch();

    const initialValues: any = {
        exchange: data.exchange.bookingInstanceId,
        exchangeInstanceToken: data.exchange.instanceToken,
        exchanged: data.exchanged.bookingInstanceId,
        exchangedInstanceToken: data.exchanged.instanceToken,
        accountingType: 1
    }

    const validationSchema = Yup.object().shape({
        exchange: Yup.number().required(),
        exchangeInstanceToken: Yup.string().required(),
        exchanged: Yup.number().required(),
        exchangedInstanceToken: Yup.string().required()
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar());
            api.create(values).then(() => {
                dispatch(refresh());
                dispatch(closeSidebar());
            })
        }
    });

    return <div className={'p-1 p-md-3 h-100 d-flex flex-column'}>
        <div className="flex-grow-1 d-flex align-items-center col-12">
            <div className="row align-items-center w-100">
                <div className="col-12 col-md mb-3">
                    <div className="border border-1 shadow-sm h-100">
                        <div className="card">
                            <div className="card-body">
                                <h4>
                                    <i className={'bi bi-person'}></i> {data.exchange.salary.title}
                                </h4>
                                <p>
                                    <i className={'bi bi-shop'}></i> {data.exchange.company.title}
                                </p>
                                <p>
                                    <i className={'bi bi-calendar'}></i>  {format(new Date(data.exchange.start), "E dd MMMM uuuu HH:mm")} <i className={'mx-1 bi bi-arrow-left-right'}></i> {format(new Date(data.exchange.end), "E dd MMMM uuuu HH:mm")}
                                </p>
                                <p>
                                    <i className={'bi bi-clock'}></i> {data.exchange.timeCaption}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md text-center flex-grow-0 my-3">
                    <i className={'bi bi-shuffle fs-4'}></i>
                </div>
                <div className="col-12 col-md mb-3">
                    <div className="border border-1 shadow-sm h-100">
                            <div className="card">
                                <div className="card-body">
                                    <h4>
                                        <i className={'bi bi-person'}></i> {data.exchanged.salary.title}
                                    </h4>
                                    <p>
                                        <i className={'bi bi-shop'}></i> {data.exchanged.company.title}
                                    </p>
                                    <p>
                                        <i className={'bi bi-calendar'}></i>  {format(new Date(data.exchanged.start), "E dd MMMM uuuu HH:mm")} <i className={'mx-1 bi bi-arrow-left-right'}></i> {format(new Date(data.exchanged.end), "E dd MMMM uuuu HH:mm")}
                                    </p>
                                    <p>
                                        <i className={'bi bi-clock'}></i> {data.exchanged.timeCaption}
                                        <i className={'ms-2 ' + (!data.exchanged.timeDiff ? 'bi bi-check text-success' : (data.exchanged.timeDiff > 0 ? 'bi bi-arrow-up text-primary' : "bi bi-arrow-down text-danger"))}></i> {!!data.exchanged.timeDiff && <>{data.exchanged.timeDiffCaption}</>}
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 flex-grow-1">
            <div className="bg-primary p-1 p-md-3 mb-3">
                <p className={'text-white'}>
                    <i className={'bi bi-info-circle'}></i>
                    Si un créneau est échangé avec un créneau sur une semaine différente, cela risque de retirer des heures sur la semaine en cours et ajouter des heures supplémentaires sur la semaine concernée.
                    <div className="clearfix"></div>
                    Pour éviter cela, séléctionnez la 2ème option ci-dessous.
                </p>
                <BootstrapSelect
                    required={true}
                    label={"Comment souhaitez-vous interpreter l'échange sur le calcul du volume d'heure ?"}
                    options={[
                        {label: "Compter le report en heures supplémentaires si besoin", value: 1},
                        {label: "Integrer le report dans la semaine en cours", value: 2},
                    ]}
                    value={{label: "Prendre en compte l'impact du changement sur le calcul du temps de travail", value: 1}}
                    onChange={(choice) => formik.setFieldValue('accountingType', choice?.value)}
                />

            </div>

        </div>
        <div className="flex-grow-0">
            <div className="row">
                <div className="col">
                    <button onClick={() => dispatch(openSidebar(data.next, data.data))} className={'btn btn-outline-danger w-100'}>
                        <i className={'bi bi-check'}></i> Annuler
                    </button>
                </div>
                <div className="col">
                    <button onClick={() => formik.submitForm()} className={'btn btn-outline-success w-100'}>
                        <i className={'bi bi-check'}></i> Valider
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default SlotExchangeAddValidation;