import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../adapters/company";
import validationClass from "../../../utils/validationClass";
import FormError from "../../../utils/formError";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import * as action from "../../../actions/companies";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../utils/customInput";
import {axiosError} from "../../../actions/axios";

const CompanyEdit:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);
    const data = state.rightSidebar.data

    const formik = useFormik({
        initialValues: {
            title: data.title,
            tel: data.tel,
            email: data.email,
            address: {
                city: data.address.city,
                address: data.address.address,
                zip: data.address.zip,
            },
            timesheet: {
                openAt: new Date(data.timesheet.openAt),
                closeAt: new Date(data.timesheet.closeAt),
                breakStart: data.timesheet.breakStart ? new Date(data.timesheet.breakStart) : null,
                breakFinish: data.timesheet.breakFinish ? new Date(data.timesheet.breakFinish) : null,
                recurrence: data.timesheet.recurrence,
                currentWeek: data.timesheet.todayWeek,
            }
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required('Champs requis'),
            tel: Yup.string().required('Champs requis'),
            email: Yup.string().email().required('Champs requis'),
            address: Yup.object().shape({
                city: Yup.string().required('Champs requis'),
                address: Yup.string().required('Champs requis'),
                zip: Yup.string().length(5).required('Champs requis'),
            }),
            timesheet: Yup.object().shape({
                openAt: Yup.date().required('Champs requis'),
                closeAt: Yup.date().required('Champs requis'),
                breakStart: Yup.date().nullable(),
                breakFinish: Yup.date().nullable(),
                recurrence: Yup.number().required('Champs requis'),
                currentWeek: Yup.number().required('Champs requis'),
            }),
        }),
        onSubmit: values => {
            dispatch(submitSidebar());
            api.edit(values, data.id).then(data => {
                dispatch(action.editCompanySuccess(data.data));
                dispatch(closeSidebar());
            }).catch(error => dispatch(axiosError(error)))
        }
    })


    return <>
        <form onSubmit={formik.handleSubmit} className="p-3">
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'title'}
                            id={'title'}
                            defaultValue={data.title}
                            type="text"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'title')}/>
                        <label htmlFor="title">
                           <i className={"bi bi-shop text-primary"}> </i> Nom du point de vente <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
                </div>
                <div className="col-md-6 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'tel'}
                            id={'tel'}
                            defaultValue={data.tel}
                            type="text"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'tel')}/>
                        <label htmlFor="tel">
                            <i className={"bi bi-phone text-primary"}> </i> Tél <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'tel'} />
                </div>
                <div className="col-md-6 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'email'}
                            id={'email'}
                            defaultValue={data.email}
                            type="text"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'email')}/>
                        <label htmlFor="email">
                            <i className={"bi bi-envelope text-primary"}> </i>  Email <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'email'} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'address.city'}
                            id={'address.city'}
                            defaultValue={data.address.city}
                            type="text"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'address.city')}/>
                        <label htmlFor="address.city">
                            <i className={"bi bi-geo-alt text-primary"}> </i>  Ville <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'address.city'} />
                </div>
                <div className="col-md-6 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'address.zip'}
                            id={'address.zip'}
                            defaultValue={data.address.zip}
                            type="text"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'address.zip')}/>
                        <label htmlFor="address.zip">
                            <i className={"bi bi-123 text-primary"}> </i>  Code postal <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'address.zip'} />
                </div>
                <div className="col-md-12 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'address.address'}
                            id={'address.address'}
                            defaultValue={data.address.address}
                            type="text"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'address.address')}/>
                        <label htmlFor="address.address">
                            <i className={"bi bi-house-door text-primary"}> </i>  Adresse <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'address.address'} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <DatePicker
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.openAt')}
                        customInput={<DateCustomInput label={"Ouverture"} icon={'bi bi-clock text-primary'} />}
                        selected={formik.values.timesheet.openAt}
                        onChange={(date: Date) => formik.setFieldValue('timesheet.openAt', date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Ouverture"
                        dateFormat="HH:mm"
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.openAt'} />
                </div>
                <div className="col-md-6 mb-3">
                    <DatePicker
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.openAt')}
                        customInput={<DateCustomInput label={'Fermeture'} icon={'bi bi-clock-fill text-primary'} />}
                        selected={formik.values.timesheet.closeAt}
                        onChange={(date: Date) => formik.setFieldValue('timesheet.closeAt', date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Fermeture"
                        dateFormat="HH:mm"
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.closeAt'} />
                </div>
                <div className="col-md-6 mb-3">
                    <DatePicker
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.breakStart')}
                        customInput={<DateCustomInput label={"Début de pause"} icon={'bi bi-clock text-primary'} />}
                        selected={formik.values.timesheet.breakStart}
                        onChange={(date: Date) => formik.setFieldValue('timesheet.breakStart', date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Début de pause"
                        isClearable={true}
                        dateFormat="HH:mm"
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.breakStart'} />
                </div>
                <div className="col-md-6 mb-3">
                    <DatePicker
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.breakFinish')}
                        customInput={<DateCustomInput label={'Fin de pause'} icon={'bi bi-clock-fill text-primary'} />}
                        selected={formik.values.timesheet.breakFinish}
                        onChange={(date: Date) => formik.setFieldValue('timesheet.breakFinish', date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Fin de pause"
                        isClearable={true}
                        dateFormat="HH:mm"
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.breakFinish'} />
                </div>
                <div className="col-md-12 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'timesheet.recurrence'}
                            id={'timesheet.recurrence'}
                            defaultValue={data.timesheet.recurrence}
                            type="number"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.recurrence')}/>
                        <label htmlFor="timesheet.recurrence">
                            <i className={"bi bi-arrow-repeat text-primary"}> </i>  Recurrence <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.recurrence'} />
                </div>
                <div className="col-md-12 mb-3">
                    <div className="form-floating">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={'timesheet.currentWeek'}
                            id={'timesheet.currentWeek'}
                            defaultValue={data.timesheet.currentWeek}
                            type="number"
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.currentWeek')}/>
                        <label htmlFor="timesheet.currentWeek">
                            <i className={"bi bi-check text-primary"}> </i>  Semaine en cours <span className="required">*</span>
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.currentWeek'} />
                </div>
            </div>
            <button type={"submit"} className={'btn btn-outline-primary w-100'}>
                <i className={'bi bi-check-lg'}> </i> Enregistrer
            </button>
        </form>
    </>
}

export default CompanyEdit;