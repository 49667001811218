import React, {useEffect, useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import * as api from '../../../../../../adapters/salary/template'
import Loader from "../../../../../../utils/loader";
import {Events, SalaryTemplate} from "../../../../../../models/salary/template";
import {Company} from "../../../../../../models/companies";
import {Modal} from "bootstrap";

const SalaryTemplateCopyWeek:React.FC<{
    week: number,
    template: SalaryTemplate,
    setEvents: (events: Events) => void;
    company: Company;
    modal?: Modal;
}> = (props) => {

    const {week, setEvents, template, company, modal} = props;

    const [loading, setLoading] = useState(false);
    const [targets, setTargets] = useState<number[]>([])

    useEffect(() => {
        setTargets([])
    }, [week])

    const submit = () => {
        setLoading(true)
        api.copyEvents(template.id, week, targets).then(resp => {
            setEvents(resp.data.events)
            modal?.toggle()
            setLoading(false);
        })
    }

    const handleClick = (w: number) => {
        let _targets = targets;

        if (_targets.includes(w)){
            let index = _targets.findIndex(t => t === w);
            if (index !== -1){
                _targets.splice(index, 1);
                setTargets([..._targets])
            }
        }else{
            setTargets([..._targets, w]);
        }
    }

    return <div className="modal fade" id="salaryTemplateCopyWeekModal" tabIndex={-1} aria-labelledby="salaryTemplateCopyWeekModalLabel"
                aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="salaryTemplateCopyWeekModalLabel">Dupliquer la semaine {week}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body position-relative text-center">
                    {<>
                        <h4>
                            Dupliquer sur la semaine
                        </h4>
                        <div className="btn-group mb-3">
                            {Array.from(Array(company.timesheet.recurrence).keys()).filter(i => i + 1 !== week).map((i) =>
                                <button
                                    disabled={loading}
                                    onClick={() => handleClick(i + 1)}
                                    className={'btn mx-2' + (targets.includes(i+ 1) ? " btn-primary text-white" : " btn-light")}>
                                    {i + 1}
                                </button>)}
                        </div>
                        <button onClick={() => submit()} disabled={!targets.length || loading} className={'btn btn-outline-success w-100'}>
                            <i className={'bi bi-check'}></i> Valider
                        </button>
                    </>}
                </div>
            </div>
        </div>
    </div>
}

export default SalaryTemplateCopyWeek;