import React, { useEffect, useState } from "react";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import allLocales from "@fullcalendar/core/locales-all";
import TimetableEventSidebar, { TimetableEventSidebarInterface } from "../event/sidebar";
import TimetableEdit from "../edit";
import TimetableDuplicate from "../add/duplicate";
import TimetableImport from "../add/import";

import { Salary } from "../../../models/salary";
import { Events, Timetable, Timetables } from "../../../models/timetable";
import { Company } from "../../../models/companies";

import { ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED, getAccountingTypeChoice } from "../../../constants/booking";

import * as api from "../../../adapters/timetable";

import format from "../../../utils/locale";
import Loader from "../../../utils/loader";
import SmallLoader from "../../../utils/loader/small";

interface IProps {
    salary: Salary;
    timetable?: Timetable;
    timetables: Timetables;
    setTimetables: React.Dispatch<any>;
    setTimetable: React.Dispatch<any>;
}

const TimetableShow: React.FC<IProps> = (props) => {
    const { salary, timetable, timetables, setTimetables, setTimetable } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [company, setCompany] = useState<Company>(salary.companies[0]);
    const [week, setWeek] = useState(1);
    const [nightView, setNightView] = useState(false);
    const [counter, setCounter] = useState<{ contractWorkingTime: string, workingTime: string, deltaDuration: number, delta: string }>()
    const [counterFetched, setCounterFetched] = useState(false)
    const [timeRange, setTimeRange] = useState({
        minTime: format(new Date(company.timesheet.openAt), 'HH:mm:ss'),
        maxTime: format(new Date(company.timesheet.closeAt), 'HH:mm:ss'),
    });
    const [events, setEvents] = useState<Events>([])
    const [canceling, setCanceling] = useState(false)
    const [calendarEventSidebarProps, setCalendarEventSidebarProps] = useState<TimetableEventSidebarInterface>({sidebarType: "EMPTY", trigger: 0});

    const pad = (num: number, size: number) => {
        let ret = num.toString();
        while (ret.length < size) ret = "0" + ret;
        return ret;
    }

    const cleanWeek = () => {
        let evt = [...events.filter(e => e.week !== week)];
        api.clean(timetable!.id, week).then(() => setEvents(evt))
    }

    useEffect(() => {
        if (timetable){
            setLoading(true)
            setWeek(1)
            api.show(timetable.id).then(resp => {
                setEvents(resp.data.events)
                setLoading(false);
            })
        }
    }, [timetable])

    useEffect(() => {
        if (timetable){
            setCounterFetched(false)
            api.workingTime(timetable.id, week).then(data => {
                setCounter(data.data)
                setCounterFetched(true)
            })
        }
    }, [week, events])

    useEffect(() => {
        if (nightView) {
            let max_hours = pad(24 + (new Date(company.timesheet.openAt)).getHours(), 2);
            let max_minutes = pad((new Date(company.timesheet.openAt)).getMinutes(), 2);

            let min_hours = pad((new Date(company.timesheet.closeAt)).getHours(), 2);
            let min_minutes = pad((new Date(company.timesheet.closeAt)).getMinutes(), 2);

            setTimeRange({
                minTime: `${min_hours}:${min_minutes}:00`,
                maxTime: `${max_hours}:${max_minutes}:00`,
            });
        } else {
            setTimeRange({
                minTime: format(new Date(company.timesheet.openAt), 'HH:mm:ss'),
                maxTime: (new Date(company.timesheet.closeAt).getDate() - new Date(company.timesheet.openAt).getDate()) + '.' + format(new Date(company.timesheet.closeAt), "HH:mm:ss")
            })
        }

    }, [nightView])

    if (!timetable) {
        return (
            <div className={'p-3'}>
                <h4>
                    <i className={'bi bi-chevron-left'}></i> Veuillez séléctionner un emploi du temps
                </h4>
            </div>
        )
    }

    const deactivate = () => {
        setCanceling(true)
        api.deactivate(timetable.id).then(() => {
            setTimetable(undefined)
            setTimetables([...timetables.filter(_t => _t.id !== timetable.id)])
            setCanceling(false)
        })
    }

    return (
        <div className={'card h-100 d-flex flex-column position-relative'}>
            {/* TIMETABLE DETAILS */}
            <div className={'col-12 d-flex mb-3 align-items-center flex-grow-0'}>
                <div className="flex-grow-0">
                    {format(new Date(timetable.start))} <i className={'bi bi-arrow-left-right'}></i> {timetable.end ? <>{format(new Date(timetable.end))}</> : <i className={'bi bi-infinity'}></i>}
                </div>
                
                <div className="flex-grow-1" />

                <div className="flex-grow-0">
                    <div className="btn-group">
                        <button className="btn btn-light me-2"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasTimetableEdit" aria-controls="offcanvasTimetableEdit">
                            <i className={'bi bi-pencil text-primary'}></i> Modifier
                        </button>
                        <button className="btn btn-light me-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTimetableDuplicate" aria-controls="offcanvasTimetableDuplicate">
                            <i className={'bi bi-arrow-repeat text-primary'}></i> Dupliquer
                        </button>
                        <button className="btn btn-light" onClick={() => deactivate()}>
                            {canceling ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Supprimer</>}
                        </button>
                    </div>
                </div>
            </div>
            
            {/* TIMETABLE TOOLBAR */}
            <div className="row mb-3 align-items-center flex-grow-0">
                {timetable.type === 1 &&
                    <div className="col-auto">
                        <div className="dropdown shadow-sm">
                            <button className="btn bg-white dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i className={'bi bi-shop'}></i> {company.title}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {salary.companies.map(c => <li onClick={() => setCompany(c)}
                                                            className={'dropdown-item' + (c.id === company.id ? ' active text-white' : '')}>
                                    {c.id === company.id && <i className={'bi bi-check'}></i>} {c.title}
                                </li>)}
                            </ul>
                        </div>
                    </div>
                }

                <div className="col-auto">
                    <div className="btn-group shadow-sm">
                        <button className={'btn bg-white'}>
                            Semaine
                        </button>
                        {Array.from(Array(timetable.type === 2 ? timetable.recurrence : company.timesheet.recurrence).keys()).map((i) =>
                            <button
                                onClick={() => setWeek(i + 1)}
                                className={'btn btn-outline-primary ' + (week === i + 1 ? " active text-white" : '')}>
                                {i + 1}
                            </button>
                        )}
                    </div>
                </div>

                <div className="col-auto">
                    <div className="btn-group shadow-sm">
                        <button className={'btn bg-white'}>
                            <i className={"bi bi-clock"}></i>
                        </button>
                        <button
                            className={'btn btn-light'}>
                            {counterFetched ? <>{counter ? <>{counter.workingTime} / {counter.contractWorkingTime} {counter.deltaDuration < 0 ? <><i className={'bi bi-arrow-down text-danger'}></i> {counter.delta} </> : (counter.deltaDuration === 0 ? <i className={"bi bi-check text-success"}></i> : <><><i className={'bi bi-arrow-up text-primary'}></i> {counter.delta} </></>)}</> : '00:00'}</> : <SmallLoader />}
                        </button>
                    </div>
                </div>

                <div className="col-auto">
                    <div className="d-flex">
                        <div className="form-check form-switch">
                            <input onChange={(e) => setNightView(e.target.checked)} className="form-check-input" type="checkbox" id="flexSwitchCheckNightView" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckNightView">
                                <i className={'bi bi-moon'}></i>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col" />

                <div className="col-auto">
                    <div className="dropdown">
                        <button className="btn bg-white shadow-sm text-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                            <i className={'bi bi-three-dots-vertical'}></i>
                        </button>
                        <ul className="dropdown-menu">
                            <li className="dropdown-item" onClick={() => setCalendarEventSidebarProps( prev => ({sidebarType: "EVENT_COPY", trigger: prev.trigger + 1}))}> <i className={'bi bi-arrow-repeat text-primary'}></i> Dupliquer S.{week}</li>
                            <li className="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTimetableImport" aria-controls="offcanvasTimetableImport">
                                <i className={'bi bi-upload text-primary'}></i> Importer
                            </li>
                            <li className="dropdown-item" onClick={() => cleanWeek()}> <i className={'bi bi-dash-circle text-danger'}></i> Supprimer S.{week}</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            {/* TIMETABLE */}
            <div className="col-12 flex-grow-1 position-relative">
                <FullCalendar
                    headerToolbar={false}
                    plugins={[timeGridPlugin, interactionPlugin, rrulePlugin]}
                    initialView={'timeGridWeek'}
                    allDaySlot={false}
                    height={'100%'}
                    slotMinTime={timeRange.minTime}
                    slotMaxTime={timeRange.maxTime}
                    validRange={{
                        start: '2019-07-01',
                        end: '2019-07-08'
                    }}
                    initialDate={'2019-07-01'}
                    locale={'fr'}
                    locales={allLocales}
                    selectable={true}
                    events={events.filter(e => e.week === week && (timetable!.type === 2 || e.company.id === company.id)).map(e => ({
                        rrule: {
                            freq: 'weekly',
                            byweekday: e.daysOfWeek,
                            dtstart: '2019-07-01 ' + e.startTime,
                            until: '2019-07-07 ' + e.startTime,
                        },
                        duration: e.duration * 1000,
                        backgroundColor: [ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED].includes(e.accountingType) ? '#fff' : '',
                        textColor: [ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED].includes(e.accountingType) ? '#000' : '',
                        borderColor: [ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED].includes(e.accountingType) ? '#000' : '',
                        extendedProps: {
                            event: {...e},
                            initialStart: e.start,
                            initialEnd: e.end
                        }
                    }))}
                    select={(arg) => setCalendarEventSidebarProps(prev => ({
                        sidebarType: "EVENT_SELECT",
                        trigger: prev.trigger + 1,
                        arg: arg,
                    }))}
                    eventClick={(arg) => {
                        setCalendarEventSidebarProps(prev => ({
                            sidebarType: "EVENT_SHOW",
                            trigger: prev.trigger + 1,
                            arg: arg,
                            setCalendarEventSidebarProps: setCalendarEventSidebarProps
                        }))
                    }}
                    editable
                    eventResizableFromStart
                    eventResize={(arg) => {
                        setCalendarEventSidebarProps(prev => ({
                            sidebarType: "EVENT_EDIT",
                            trigger: prev.trigger + 1,
                            arg: arg,
                            setCalendarEventSidebarProps: setCalendarEventSidebarProps
                        }))
                    }}
                    dayHeaderContent={arg => <span className={'text-primary'}>{format(arg.date, 'EEEE')}</span>}
                    eventContent={e => <>
                        <b>
                            {e.timeText}
                        </b>
                        <div className="clearfix"> </div>
                        <b>
                            <i className={'bi bi-clock'}></i> {e.event.extendedProps.event.timeCaption}
                        </b>
                        {e.event.extendedProps.event.company && <div>
                            <i className={'bi bi-shop'}></i> {e.event.extendedProps.event.company.title}
                        </div>}
                        {e.event.extendedProps.event.activity && <div>
                            <i className={'bi bi-bookmark'}></i> {e.event.extendedProps.event.activity.title}
                        </div>}
                        {e.event.extendedProps.event.localisation && <div>
                            <i className={'bi bi-geo-alt'}></i> {e.event.extendedProps.event.localisation.title}
                        </div>}
                        <div>
                            {getAccountingTypeChoice(e.event.extendedProps.event.accountingType)?.label}
                        </div>
                    </>}
                />
                {loading && <Loader />}
            </div>

            <TimetableEventSidebar
                {...calendarEventSidebarProps}
                week={week}
                company={company}
                salary={salary}
                timetable={timetable}
                events={events}
                setEvents={setEvents}
            />
            <TimetableEdit timetables={timetables} timetable={timetable} salary={salary} setTimetables={setTimetables} setTimetable={setTimetable} />
            <TimetableDuplicate setTimetables={setTimetables} setTimetable={setTimetable} salary={salary} timetable={timetable} timetables={timetables} />
            <TimetableImport targetSalary={salary} targetTimetable={timetable} setTimetable={setTimetable} />
        </div>
    )
}

export default TimetableShow;