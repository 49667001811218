import React, {ChangeEvent, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as api from "../../../../adapters/booking/recurrence";
import {refresh} from "../../../../actions/calendar";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import FormError from "../../../../utils/formError";
import {FORM_EDIT_BOOKING} from "../../../../constants/rightSidebar";
import DateCustomInput from "../../../../utils/customInput";
import validationClass from "../../../../utils/validationClass";
import {showAlertSuccess} from "../../../../actions/alert";


const BookingRecurrenceEdit:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);
    const [weekLetters, setWeekLetters] = useState<string[]>([])
    const [validationSchema, setValidationSchema] = useState<any>({})
    const [initialValues, setInitialValues] = useState<any>({})

    useEffect(() => {
        preSetRecurrenceData();

        let company = state.rightSidebar.data.company
        let ret: string[] = [];
        for (let i = 0; i < company.timesheet.recurrence; i++){
            ret.push(String.fromCharCode(65 + i));
            setWeekLetters(ret);
        }

    }, [])

    const preSetRecurrenceData = () => {
        let _initialValues:any = {};
        let _validationSchema:any = {
            type: Yup.number().required()
        };

        let type = state.rightSidebar.data.recurrence.type.toString();
        let fields: string[] = [];

        if (type !== '1'){
            _validationSchema.start = Yup.date().required();
            _validationSchema.end = Yup.date().nullable();
            _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1);
        }

        switch (type){
            case '1':
                fields = ['type'];
                break;
            case '2':
                fields = ['type', 'daysOfWeek', 'start', 'end', 'weekLetters'];
                _validationSchema.weekLetters = Yup.array().of(Yup.number()).min(1);
                break;
            case '3':
                fields = ['type', 'daysOfWeek', 'start', 'end', 'separationCount'];
                _validationSchema.separationCount = Yup.number().required();
                break;
        }

        // setValues
        for (let i in fields){
            switch (fields[i]) {
                case 'type':
                    _initialValues[fields[i]] = state.rightSidebar.data.recurrence[fields[i]].toString();
                    break;
                case 'daysOfWeek':
                case 'weekLetters':
                    _initialValues[fields[i]] = state.rightSidebar.data.recurrence[fields[i]].map((num: number) => num.toString());
                    break;
                case 'start':
                    _initialValues[fields[i]] = new Date(state.rightSidebar.data.recurrence[fields[i]]);
                    break
                case 'end':
                    _initialValues[fields[i]] = state.rightSidebar.data.recurrence[fields[i]] ? new Date(state.rightSidebar.data.recurrence[fields[i]]) : null;
                    break;
                default:
                    _initialValues[fields[i]] = state.rightSidebar.data.recurrence[fields[i]];
            }
        }

        setInitialValues({..._initialValues});
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        let elem = e.target as HTMLInputElement;
        let type = elem.value;
        let _initialValues:any = {};
        let _validationSchema:any = { type: Yup.number().required() };

        let fields: string[] = [];
        formik.values.type = type;

        switch (type){
            case '1':
                fields = ['type'];
                break;
            case '2':
                fields = ['type', 'daysOfWeek', 'start', 'end', 'weekLetters'];
                break;
            case '3':
                fields = ['type', 'daysOfWeek', 'start', 'end', 'separationCount'];
                break;
        }

        for (let i in fields){

            // VALUES
            if (formik.values.hasOwnProperty(fields[i])){
                _initialValues[fields[i]] = formik.values[fields[i]];
            }else{
                switch (fields[i]){
                    case "daysOfWeek":
                    case "weekLetters":
                        if (state.rightSidebar.data.recurrence.hasOwnProperty(fields[i])){
                            _initialValues[fields[i]] = state.rightSidebar.data.recurrence[fields[i]].map((num: number) => num.toString());
                        }else{
                            _initialValues[fields[i]] = [];
                        }
                        break;
                    case "separationCount":
                        if (state.rightSidebar.data.recurrence.hasOwnProperty(fields[i])){
                            _initialValues[fields[i]] = state.rightSidebar.data.recurrence[fields[i]];
                        }else{
                            _initialValues[fields[i]] = 1;
                        }
                        break;
                    case 'start':
                        if (state.rightSidebar.data.recurrence.hasOwnProperty(fields[i])){
                            _initialValues[fields[i]] = new Date(state.rightSidebar.data.recurrence[fields[i]]);
                        }else{
                            _initialValues[fields[i]] = new Date();
                        }
                        break
                    case 'end':
                        if (state.rightSidebar.data.recurrence.hasOwnProperty(fields[i])){
                            _initialValues[fields[i]] = state.rightSidebar.data.recurrence[fields[i]] ? new Date(state.rightSidebar.data.recurrence[fields[i]]) : null;
                        }else{
                            _initialValues[fields[i]] = null;
                        }
                        break;
                }
            }

            // VALIDATION
            switch (fields[i]){
                case "start":
                    _validationSchema.start = Yup.date().required();
                    break;
                case "end":
                    _validationSchema.end = Yup.date().nullable();
                    break;
                case "daysOfWeek":
                    _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1).required();
                    break;
                case "weekLetters":
                    _validationSchema.weekLetters = Yup.array().of(Yup.number()).min(1).required();
                    break;
                case "separationCount":
                    _validationSchema.separationCount = Yup.number().required();
                    break;
            }
        }

        setInitialValues({..._initialValues});
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(submitSidebar());
            api.edit(values, state.rightSidebar.data.recurrence.id).then((data) => {
                dispatch(refresh());
                let nextState = {...state.rightSidebar.data};
                nextState.recurrence = {...data.data};
                dispatch(showAlertSuccess('Récurrence mis à jour !'))
                dispatch(openSidebar(FORM_EDIT_BOOKING, nextState))
            });
        },
    });

    return <>
        <form className={'p-3'} onSubmit={formik.handleSubmit}>
            {state.rightSidebar.data.bookingGroupId && <div className={'alert alert-info'}>
                <i className={'bi bi-info-circle'}> </i> Modifier la récurrence détachera automatiquement le créneau du groupe auquel il est attaché.
            </div>}
            <div className="col-12 text-center">
                <label htmlFor="" className={'mb-2'}>
                    Type de récurrence *
                </label>
                <div className="clearfix"> </div>
                <div className="form-check-inline mb-3">
                    <input defaultChecked={state.rightSidebar.data.recurrence.type == 1}
                           className="btn-check"
                           type="radio"
                           name="type"
                           id="type1"
                           onChange={handleTypeChange}
                           value="1" />
                    <label className="btn btn-outline-primary" htmlFor="type1">
                        Une seule fois
                    </label>
                </div>
                <div className="form-check-inline mb-3">
                    <input defaultChecked={state.rightSidebar.data.recurrence.type == 2}
                           className="btn-check"
                           type="radio"
                           name="type"
                           onChange={handleTypeChange}
                           id="type2"
                           value="2" />
                    <label className="btn btn-outline-primary" htmlFor="type2">
                        Toutes les semaines X
                    </label>
                </div>
                <div className="form-check-inline mb-3">
                    <input defaultChecked={state.rightSidebar.data.recurrence.type == 3}
                           className="btn-check"
                           type="radio"
                           name="type"
                           onChange={handleTypeChange}
                           id="type3"
                           value="3"
                    />
                    <label className="btn btn-outline-primary" htmlFor="type3">
                        Personnalisé
                    </label>
                </div>
            </div>

            {['2', '3'].includes(formik.values.type) && <div className={'col-12 mb-3'}>
                <div className="row">
                    <div className="col-12 col-md mb-3">
                        <DatePicker
                            required={true}
                            className={'form-control'}
                            customInput={<DateCustomInput label={'Début de la récurrence'} className={validationClass(formik.errors, formik.values, 'start')} icon={'bi bi-calendar-check'} />}
                            selected={formik.values.start}
                            onChange={(date) => formik.setFieldValue('start', date)}
                            selectsStart
                            startDate={formik.values.start}
                            endDate={formik.values.end}
                            maxDate={formik.values.end}
                            dateFormat="E dd MMM uuuu"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                    <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                        <i className={'bi bi-arrow-left-right mx-1'}> </i>
                    </div>
                    <div className="col-12 col-md mb-3">
                        <DatePicker
                            className={'form-control'}
                            customInput={<DateCustomInput label={'Fin de la récurrence'} className={validationClass(formik.errors, formik.values, 'end')} icon={'bi bi-calendar-check'} />}
                            selected={formik.values.end}
                            onChange={(date) => formik.setFieldValue('end', date)}
                            selectsEnd
                            startDate={formik.values.start}
                            endDate={formik.values.end}
                            minDate={formik.values.start}
                            isClearable={true}
                            dateFormat="E dd MMM uuuu"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                </div>
            </div>}
            {['2', '3'].includes(formik.values.type) && <div className={'col-12 mb-3 text-center'}>
                <label htmlFor="" className={'mb-2'}>
                    Jours de la semaine
                </label>
                <div className="align-items-center">
                    {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                        return (
                            <>
                                <input
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    name={'daysOfWeek'}
                                    className="btn-check"
                                    id={`btn-check-${index}`}
                                    autoComplete="off"
                                    value={`${index + 1}`}
                                    defaultChecked={initialValues.daysOfWeek?.includes((index + 1).toString())}
                                />
                                <label className="btn btn-sm btn-outline-primary me-2 mb-3" htmlFor={`btn-check-${index}`}>
                                    {day}
                                </label>
                            </>
                        )
                    })}
                    <FormError touched={formik.touched} errors={formik.errors} field={'daysOfWeek'} />
                </div>
            </div>}
            {formik.values.type === '2' && <div className="col-12 mb-3 text-center">
                <label className={'mb-2'} htmlFor="">
                    Les semaines
                </label>
                <div className="align-items-center">
                    {weekLetters.map((week: string, index: number) => {
                        return (
                            <>
                                <input
                                    type="checkbox"
                                    name={'weekLetters'}
                                    onChange={formik.handleChange}
                                    className="btn-check"
                                    id={`btn-check-${week}`}
                                    defaultChecked={initialValues.weekLetters?.includes((index + 1).toString())}
                                    autoComplete="off" value={`${index + 1}`} />
                                <label className="btn btn-sm btn-outline-primary me-2 mb-3" htmlFor={`btn-check-${week}`}>
                                    {week}
                                </label>
                            </>
                        )
                    })}
                </div>
                <FormError touched={formik.touched} errors={formik.errors} field={'weekLetters'} />
            </div>}
            {formik.values.type === '3' && <>
                <div className={'col-12 mb-3'}>
                    <div className="input-group">
                        <span className="input-group-text" id="separation-addon">
                             Répeter toutes les
                        </span>
                        <input
                            type="number"
                            name={'separationCount'}
                            onChange={formik.handleChange}
                            className="form-control"
                            aria-describedby="separation-addon"
                            defaultValue={formik.values.separationCount}
                        />
                        <span className="input-group-text" id="separation-addon">
                            Semaines
                        </span>
                    </div>
                </div>
            </>}
            <button type={'submit'} className={'btn btn-outline-primary w-100'}>
                Enregistrer
            </button>
        </form>
    </>
}

export default BookingRecurrenceEdit;