import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import { RootStateOrAny, useSelector } from "react-redux"

import { HELP_PRODUCTS_SHEETS } from "../helpsData"

import { IHelpProductSheet } from "../../../@types/models/Helps/IHelpProductSheet"

const HelpModal: React.FC = () => {
  const { push } = useHistory()

  const { helpProductSheetIds } = useSelector((state: RootStateOrAny) => state.header);

  const [dataToDisplay, setDataToDiplay] = useState<IHelpProductSheet[]>([])

  const findRightDataToDisplay = (productSheetIds: number[]) => {
    setDataToDiplay(HELP_PRODUCTS_SHEETS.filter(item => productSheetIds.includes(item.id)))
  }

  useEffect(() => {
    if (helpProductSheetIds.length > 0) {
      findRightDataToDisplay(helpProductSheetIds)
    }
  }, [helpProductSheetIds])

  return (
    <div className="modal fade" id="helpInfosModal" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            Besoin d’aide ?
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div className="modal-body">
          {dataToDisplay?.map(productSheetData => (
            <div key={productSheetData.id} className={dataToDisplay.length > 1 ? 'pb-3' : undefined}>
              <h3 className="fs-5">
                {productSheetData?.title}
              </h3>

              <div className="grid my-2">
                {productSheetData?.content?.map((item, key) => (
                  <div key={key} className={`row py-3 ${key > 0 ? 'border-top' : ''}`}>
                    <div className="col">
                      <p className="mb-0" style={{ whiteSpace: 'pre-line' }}>
                        {item?.text}
                      </p>
                    </div>

                    <div className="col">
                      {item?.img &&
                        <img
                          className="w-100 mt-3"
                          src={process.env.PUBLIC_URL + item?.img}
                          alt={productSheetData?.title}
                        />
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
          >
            Retour
          </button>

          <button
            type="button"
            className="btn btn-primary text-white"
            data-bs-dismiss="modal"
            onClick={() => push('/help')}
          >
            Toutes les aides
          </button>
        </div>
      </div>
      </div>
    </div>
  )
}

export default HelpModal