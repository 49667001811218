import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik, useFormik} from "formik";
import * as actions from "../../../actions/taskGroup";
import * as api from "../../../adapters/taskGroup";
import {showAlertSuccess} from "../../../actions/alert";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import * as Yup from "yup";
import {axiosError} from "../../../actions/axios";
import ColorPicker from "../../../utils/colorPicker";

const TaskGroupSchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
    description: Yup.string().nullable(),
    color: Yup.string().required()
});

const TaskGroupEdit:React.FC = () => {

    const dispatch = useDispatch();
    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)

    const formik = useFormik({
        initialValues: {
            title: data.title,
            description: data.description,
            color: data.color,
        },
        validationSchema: TaskGroupSchema,
        onSubmit: values => {
            // same shape as initial values
            dispatch(submitSidebar());
            api.edit(values, data.id).then(resp => {
                dispatch(actions.editTaskGroupSuccess(resp.data))
                dispatch(showAlertSuccess(resp.data.title + ' à bien été mis à jour !'))
                dispatch(closeSidebar());
            }).catch(error => {
                dispatch(axiosError(error))
            })
        }
    })

    return <form onSubmit={formik.handleSubmit} className={'p-3'}>
        <div className="form-floating mb-3">
            <input onChange={formik.handleChange} type="text" className="form-control" name={'title'} defaultValue={data.title}/>
            <label htmlFor="">
                Nom du groupe <span className={'required'}>*</span>
            </label>
        </div>
        <div className="form-floating mb-3">
            <input onChange={formik.handleChange} type="text" className="form-control" name={'description'} defaultValue={data.description}/>
            <label htmlFor="">
                Description
            </label>
        </div>
        <div className="col-12 mb-3">
            <div className={'text-center w-100'}>
                <ColorPicker
                    onChange={(color: string) => formik.setFieldValue('color', color)}
                    selected={formik.values.color}
                />
            </div>
        </div>
        <button className={'btn btn-outline-primary w-100'} type={'submit'}>
            <i className={"bi bi-check"}></i> Valider
        </button>
    </form>

}

export default TaskGroupEdit;