import React, {useEffect, useState} from "react";
import {CalendarApi, DateSelectArg, EventClickArg, EventDropArg} from "@fullcalendar/react";
import CalendarBookingEdit from "../edit";
import CalendarBookingResize from "../edit/resize";
import CalendarBookingShow from "../show";
import CalendarBookingAdd from "../add";
import CalendarBookingDrop from "../add/drop";
import CalendarBookingSelect from "../add/select";
import {EventResizeDoneArg} from "@fullcalendar/interaction";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import {Offcanvas} from "bootstrap";

interface InterfaceBookingShow {
    sidebarType: 'BOOKING_SHOW',
    arg: EventClickArg,
    calendar: string,
    setCalendarSidebarBookingProps: React.Dispatch<any>,
    setCalendarSidebarSignedProps: React.Dispatch<any>,
    setCalendarAbsenceSidebarProps: React.Dispatch<any>,
}

interface InterfaceBookingEdit {
    sidebarType: 'BOOKING_EDIT',
    arg?: EventClickArg,
}

interface InterfaceBookingResize {
    sidebarType: 'BOOKING_RESIZE',
    arg?: EventResizeDoneArg,
}

interface InterfaceBookingAdd {
    sidebarType: 'BOOKING_ADD',
    api: () => CalendarApi,
    hasApi: () => boolean,
    salary?: Salary,
    company?: Company,
    localisation?: Localisation,
    activity?: Activity
}

interface InterfaceBookingDop {
    sidebarType: 'BOOKING_DROP',
    arg?: EventDropArg,
}

interface InterfaceBookingSelect {
    sidebarType: 'BOOKING_SELECT',
    arg?: DateSelectArg,
    salary?: Salary,
    company?: Company,
    activity?: Activity,
    localisation?: Localisation,
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarBookingSidebarInterface = {trigger: number} & (Interface | InterfaceBookingShow | InterfaceBookingEdit | InterfaceBookingResize | InterfaceBookingAdd | InterfaceBookingDop | InterfaceBookingSelect)

const CalendarBookingSidebar:React.FC<CalendarBookingSidebarInterface & {handleEventChange: any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarBooking')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarBooking')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }
    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "BOOKING_EDIT":
                    return <CalendarBookingEdit close={close} arg={props.arg} handleSubmitSuccess={props.handleEventChange} />
                case "BOOKING_SHOW":
                    return <CalendarBookingShow close={close} arg={props.arg} calendar={props.calendar} setCalendarSidebarBookingProps={props.setCalendarSidebarBookingProps} setCalendarSidebarSignedProps={props.setCalendarSidebarSignedProps} handleEventChange={props.handleEventChange} setCalendarAbsenceSidebarProps={props.setCalendarAbsenceSidebarProps} />
                case "BOOKING_RESIZE":
                    return <CalendarBookingResize close={close} arg={props.arg} handleSubmitSuccess={props.handleEventChange} />
                case "BOOKING_ADD":
                    return <CalendarBookingAdd close={close} api={props.api} hasApi={props.hasApi} handleSubmitSuccess={props.handleEventChange} company={props.company} localisation={props.localisation} activity={props.activity} salary={props.salary} />
                case "BOOKING_DROP":
                    return <CalendarBookingDrop close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} />
                case "BOOKING_SELECT":
                    return <CalendarBookingSelect close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} salary={props.salary} company={props.company} localisation={props.localisation} activity={props.activity}  />
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarBooking" aria-labelledby="offcanvasCalendarBookingLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: CalendarBookingSidebarInterface & {handleEventChange: any}, nextProps: CalendarBookingSidebarInterface & {handleEventChange: any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarBookingSidebar, areEqual);
