import React, {useEffect, useState} from "react";
import {CalendarApi, DateSelectArg, EventClickArg} from "@fullcalendar/react";
import CalendarSignedEdit from "../edit";
import CalendarSignedResize from "../edit/resize";
import CalendarSignedShow from "../show";
import CalendarSignedAdd from "../add";
import CalendarSignedSelect from "../add/select";
import {EventResizeDoneArg} from "@fullcalendar/interaction";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Offcanvas} from "bootstrap";
import {CalendarBookingSidebarInterface} from "../../booking/sidebar";

interface InterfaceSignedShow {
    sidebarType: 'SIGNED_SHOW',
    setCalendarSignedSidebarProps: React.Dispatch<any>,
    timeClockId: number,
    salary: Salary,
    start: Date|null,
    _start?: string,
    end: Date|null,
    _end?: string,
    company: Company;
    isAnomaly: boolean;
    files: any[];
}

interface InterfaceSignedEdit {
    sidebarType: 'SIGNED_EDIT',
    salary: Salary
    start: Date|null,
    end: Date|null,
    company: Company,
    timeClockId: number,
    description: string
}

interface InterfaceSignedResize {
    sidebarType: 'SIGNED_RESIZE',
    arg?: EventResizeDoneArg,
}

interface InterfaceSignedAdd {
    sidebarType: 'SIGNED_ADD',
    salary?: Salary,
    company?: Company,
    start?: Date,
    end?: Date,
}

interface InterfaceSignedSelect {
    sidebarType: 'SIGNED_SELECT',
    arg?: DateSelectArg,
    salary?: Salary,
    company?: Company,
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarSignedSidebarInterface = {trigger: number} & (Interface | InterfaceSignedShow | InterfaceSignedEdit | InterfaceSignedResize | InterfaceSignedAdd | InterfaceSignedSelect)

const CalendarSignedSidebar:React.FC<CalendarSignedSidebarInterface & {handleEventChange: any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarSigned')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarSigned')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }
    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "SIGNED_EDIT":
                    return <CalendarSignedEdit close={close}
                                               end={props.end}
                                               start={props.start}
                                               timeClockId={props.timeClockId}
                                               salary={props.salary}
                                               company={props.company}
                                               description={props.description}
                                               handleSubmitSuccess={props.handleEventChange} />
                case "SIGNED_SHOW":
                    return <CalendarSignedShow close={close} handleEventChange={props.handleEventChange}
                                               setCalendarSignedSidebarProps={props.setCalendarSignedSidebarProps}
                                               salary={props.salary}
                                               company={props.company}
                                               isAnomaly={props.isAnomaly}
                                               timeClockId={props.timeClockId}
                                               end={props.end}
                                               _end={props._end}
                                               start={props.start}
                                               _start={props._start}
                                               files={props.files}
                    />
                case "SIGNED_RESIZE":
                    return <CalendarSignedResize close={close} arg={props.arg} handleSubmitSuccess={props.handleEventChange} />
                case "SIGNED_ADD":
                    return <CalendarSignedAdd close={close} handleSubmitSuccess={props.handleEventChange} company={props.company} salary={props.salary} start={props.start} end={props.end} />
                case "SIGNED_SELECT":
                    return <CalendarSignedSelect close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} salary={props.salary} company={props.company} />
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarSigned" aria-labelledby="offcanvasCalendarSignedLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: CalendarSignedSidebarInterface & {handleEventChange: any}, nextProps: CalendarSignedSidebarInterface & {handleEventChange: any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarSignedSidebar, areEqual);