import React from "react";
import Loader from "../../loader";

interface BgProps {
    showLoader?: boolean,
    hasId?: boolean,
}

const BgSingle:React.FC<BgProps> = (props) => {
    const {showLoader, hasId} = props

    return <div className={'h-100 w-100 position-relative text-center ' + (hasId ? " d-none d-md-block" : "")} style={
        {
            backgroundImage: "url(/img/background/polygon.svg)",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }>
        <img src={process.env.PUBLIC_URL + "/logo/ICON_SIVAN_BLEU.svg"}
             style={{
                 height: '8rem',
                 width: "auto"
             }}
             className={'position-relative top-50 translate-middle-y'} alt="logo sivan"/>
        {showLoader && <Loader />}
    </div>
}

export default BgSingle;