import * as xhr from "../xhr";

interface listPayload {
    offset? : number,
    status? : string[],
    types? : string[],
    dates? : [string|null, string|null],
    salaries?: number[],
    companies?: number[],
}

export function list(requestData: listPayload, signal: AbortSignal){
    return xhr.post('absence/list', requestData, {signal: signal});
}

export function edit(requestData: any, id: number){
    return xhr.post('absence/edit/' + id.toString(), requestData);
}

export function editStatus(requestData: { status: 1|2|3 }, id: number){
    return xhr.post(`absence/edit/${id}/status`, requestData);
}

export function create(requestData: any, options?:xhr.Options){
    return xhr.post('absence/create',  requestData, options);
}

export function get(id: string){
    return xhr.get('absence/show/' + id);
}

export function listForSalary(id: number){
    return xhr.get('absence/list_salary/' + id);
}

export function counter(id: string){
    return xhr.get('absence/counter/' + id);
}

export function balance(absence: {start: string, end: string}, salary: number, signal: AbortSignal){
    return xhr.post('absence/balance/' + salary, absence, {signal: signal});
}

export function cancel(id: number){
    return xhr.get('absence/delete/' + id);
}

export function downloadFile(id: string, options?:xhr.Options){
    return xhr.get('absence/justification/'+ id +'/download', options);
}

export function days(id: string){
    return xhr.get('absence/days/'+ id);
}