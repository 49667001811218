import React from "react";
import {EventClickArg} from "@fullcalendar/react";
import {FormikHelpers, useFormik} from "formik";
import {EventResizeDoneArg} from "@fullcalendar/interaction";
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import validationClass from "../../../../../utils/validationClass";
import DateCustomInput from "../../../../../utils/customInput";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import * as calendarTypes from "../../../../../constants/calendar";
import * as api from "../../../../../adapters/template/applied/exception";
import {AccountingTypes, getAccountingTypeChoice} from "../../../../../constants/booking";
import {AxiosResponse} from "axios";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../../actions/alert";
import SalarySelector from "../../../../salary/selector";
import {Salary} from "../../../../../models/salary";

interface Interface {
    arg: EventClickArg | EventResizeDoneArg
    handleEventChange: (ev: any) => any,
    close: () => any,
}

const CalendarTemplateEventEdit:React.FC<Interface> = (props) => {

    const {arg, handleEventChange, close} = props;

    const dispatch = useDispatch()

    const initialValues = {
        salary: arg.event.extendedProps.salary.id,
        start: arg.event.start,
        end: arg.event.end,
        accountingType: arg.event.extendedProps.accountingType,
        localisation: arg.event.extendedProps.localisation?.id,
        activity: arg.event.extendedProps.activity?.id,
        comment : arg.event.extendedProps.comment,
    };

    const validationSchema = Yup.object().shape({
        salary: Yup.number().required(),
        start: Yup.date().required(),
        end: Yup.date().required(),
        accountingType: Yup.number().required(),
        localisation: Yup.number().nullable(),
        activity: Yup.number().nullable(),
        comment: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            switch (arg.event.extendedProps.eventType){
                case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_EXCEPTION:
                case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT:
                    api.create({...values, event: arg.event.extendedProps.eventId, exDay: arg.event.extendedProps.exDay}, arg.event.extendedProps.templateId)
                        .then(resp => handleSubmitSuccess(resp, formikHelpers))
                    break;
                case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION:
                    api.edit(values, arg.event.extendedProps.templateId, arg.event.extendedProps.exceptionId)
                        .then(resp => handleSubmitSuccess(resp, formikHelpers))
                    break;
            }
        }
    });

    const handleSubmitSuccess = (resp: AxiosResponse, formikHelpers: FormikHelpers<any>) => {
        Promise.all([
            handleEventChange(resp.data)
        ])
            .then(() => dispatch(showAlertSuccess('Créneau mis à jour.')))
            .then(() => formikHelpers.setSubmitting(false))
            .then(() => close())
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <SalarySelector
                        fetchOptions={true}
                        value={arg.event.extendedProps.salary}
                        required={true}
                        multiple={false}
                        onChange={(s: Salary|undefined) => formik.setFieldValue('salary', s?.id)}
                    />
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md mb-3">
                            <DatePicker
                                disabled={formik.isSubmitting}
                                className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                                customInput={<DateCustomInput label={'Heure de début'} icon={'bi bi-calendar-check text-primary'}/>}
                                selected={formik.values.start}
                                onChange={(date: Date) => {
                                    formik.setFieldValue(`start`, date)
                                    formik.setFieldTouched('start', true)
                                }}
                                selectsStart
                                startDate={formik.values.start}
                                maxDate={formik.values.end}
                                showTimeSelect
                                timeIntervals={15}
                                timeCaption="Heure de début"
                                dateFormat="E dd MMM uuuu HH:mm"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                            />
                        </div>
                        <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                            <i className={'bi bi-arrow-left-right mx-1'}> </i>
                        </div>
                        <div className="col-12 col-md mb-3">
                            <DatePicker
                                disabled={formik.isSubmitting}
                                className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                                customInput={<DateCustomInput label={'Heure de fin'} icon={'bi bi-calendar-check text-primary'}/>}
                                showTimeSelect
                                selected={formik.values.end}
                                onChange={(date: Date) => {
                                    formik.setFieldValue(`end`, date)
                                    formik.setFieldTouched('end', true)
                                }}
                                selectsEnd
                                timeIntervals={15}
                                dateFormat="E dd MMM uuuu HH:mm"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        disabled={formik.isSubmitting}
                        required={true}
                        label={"Type d'heure"}
                        options={AccountingTypes}
                        value={getAccountingTypeChoice(formik.values.accountingType)}
                        onChange={(c) => formik.setFieldValue('accountingType', c?.value)}
                    />
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'localisation')}
                            value={arg.event.extendedProps.localisation ? {label: arg.event.extendedProps.localisation.title, value: arg.event.extendedProps.localisation.id!} : undefined}
                            disabled={formik.isSubmitting}
                            fetchEntity={'localisation'}
                            onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                            label={"Emplacement"}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'activity')}
                            value={arg.event.extendedProps.activity ? {label: arg.event.extendedProps.activity.title, value: arg.event.extendedProps.activity.id!} : undefined}
                            disabled={formik.isSubmitting}
                            fetchEntity={'activity'}
                            onChange={(c) => formik.setFieldValue('activity', c?.value)}
                            label={"Activité"}
                        />
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <textarea onChange={formik.handleChange} defaultValue={arg.event.extendedProps.comment} className="form-control" name={'comment'} placeholder="Commentaire" id="comment"
                          style={{height: 100}}></textarea>
                        <label htmlFor="comment">Commentaire</label>
                    </div>
                </div>
                <button type={'submit'} className={'btn btn-light w-100'} disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : <><i className={'bi bi-check text-success'}></i> Valider</>}
                </button>
            </form>
        </div>
    </>
}

export default CalendarTemplateEventEdit;