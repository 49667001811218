import * as types from "../../constants/expense"
import * as models from "../../models/expense"
import replace from "../../utils/arrayReplace"
import remove from "../../utils/arrayRemove"

const expense = (state: models.initialStateInterface = models.initialState, action: {type: string, expense: models.Expense, expenses: models.Expenses, error: string}) => {

    switch (action.type) {
        case types.FETCH_EXPENSES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_EXPENSES_SUCCESS:
            return { payload: action.expenses, single: state.single, isLoading: false, error: null };
        case types.FETCH_EXPENSES_FAILURE:
            return { payload: action.expenses, single: state.single, isLoading: false, error: action.error };
        case types.ADD_EXPENSE:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_EXPENSE_SUCCESS:
            return { payload: [action.expense, ...state.payload], single: action.expense, isLoading: false, error: null };
        case types.EDIT_EXPENSE:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_EXPENSE_SUCCESS:
            return { payload: replace(state.payload, action.expense), single: action.expense, isLoading: false, error: null };
        case types.CANCEL_EXPENSE_SUCCESS:
            return { payload: remove(state.payload, action.expense), single: null, isLoading: false, error: null };
        case types.SHOW_EXPENSE:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_EXPENSE_SUCCESS:
            return { payload: state.payload, single: action.expense, isLoading: false, error: null };
        case types.SHOW_EXPENSE_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default expense;