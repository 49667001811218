import React, {useEffect, useState} from "react";
import {DateSelectArg, EventClickArg, EventDropArg} from "@fullcalendar/react";
import CalendarEventEdit from "../edit";
import CalendarEventResize from "../edit/resize";
import CalendarEventShow from "../show";
import CalendarEventAdd from "../add";
import CalendarEventDrop from "../add/drop";
import CalendarEventSelect from "../add/select";
import CalendarEventSubstitute from "../substitute";
import CalendarEventExchange from "../exchange";
import {EventResizeDoneArg} from "@fullcalendar/interaction";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import {Offcanvas} from "bootstrap";

interface InterfaceEventShow {
    sidebarType: 'EVENT_SHOW',
    arg: EventClickArg,
    calendar: string,
    setCalendarAbsenceSidebarProps: React.Dispatch<any>
    setCalendarEventSidebarProps: React.Dispatch<any>,
    setCalendarSignedSidebarProps: React.Dispatch<any>
}

interface InterfaceEventEdit {
    sidebarType: 'EVENT_EDIT',
    arg?: EventClickArg,
}

interface InterfaceEventResize {
    sidebarType: 'EVENT_RESIZE',
    arg?: EventResizeDoneArg,
}

interface InterfaceEventAdd {
    sidebarType: 'EVENT_ADD',
    dt: Date
    salary?: Salary,
    company?: Company,
    localisation?: Localisation,
    activity?: Activity,
}

interface InterfaceEventDop {
    sidebarType: 'EVENT_DROP',
    arg: EventDropArg,
}

interface InterfaceEventSelect {
    sidebarType: 'EVENT_SELECT',
    arg?: DateSelectArg,
    salary?: Salary,
    company?: Company,
    activity?: Activity,
    localisation?: Localisation,
}

interface InterfaceEventSubstitute {
    sidebarType: 'EVENT_SUBSTITUTE',
    arg: EventClickArg,
}

interface InterfaceEventExchange {
    sidebarType: 'EVENT_EXCHANGE',
    arg: EventClickArg,
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarEventSidebarInterface = {trigger: number} & (Interface | InterfaceEventSubstitute | InterfaceEventExchange | InterfaceEventShow | InterfaceEventEdit | InterfaceEventResize | InterfaceEventAdd | InterfaceEventDop | InterfaceEventSelect)

const CalendarEventSidebar:React.FC<CalendarEventSidebarInterface & {handleEventChange: any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarEvent')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarEvent')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "EVENT_EDIT":
                    return <CalendarEventEdit close={close} arg={props.arg} handleSubmitSuccess={props.handleEventChange} />
                case "EVENT_SHOW":
                    return <CalendarEventShow close={close} setCalendarSignedSidebarProps={props.setCalendarSignedSidebarProps} arg={props.arg} calendar={props.calendar} setCalendarEventSidebarProps={props.setCalendarEventSidebarProps} setCalendarAbsenceSidebarProps={props.setCalendarAbsenceSidebarProps} handleEventChange={props.handleEventChange} />
                case "EVENT_RESIZE":
                    return <CalendarEventResize close={close} arg={props.arg} handleSubmitSuccess={props.handleEventChange} />
                case "EVENT_ADD":
                    return <CalendarEventAdd close={close} dt={props.dt} handleSubmitSuccess={props.handleEventChange} company={props.company} localisation={props.localisation} activity={props.activity} salary={props.salary}  />
                case "EVENT_DROP":
                    return <CalendarEventDrop close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} />
                case "EVENT_SELECT":
                    return <CalendarEventSelect close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} salary={props.salary} company={props.company} localisation={props.localisation} activity={props.activity} />
                case "EVENT_SUBSTITUTE":
                    return <CalendarEventSubstitute close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} />
                case "EVENT_EXCHANGE":
                    return <CalendarEventExchange close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} />
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarEvent" aria-labelledby="offcanvasCalendarEventLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: CalendarEventSidebarInterface & {handleEventChange: any}, nextProps: CalendarEventSidebarInterface & {handleEventChange: any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarEventSidebar, areEqual);