import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/absence"
import * as actions from "../../../actions/absence"
import Loader from "../../../utils/loader";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_ABSENCE, PENDING_ABSENCE_LIST} from "../../../constants/rightSidebar";
import * as accessRights from "../../../constants/accessRight";
import {Absence} from "../../../models/absence";
import {axiosError} from "../../../actions/axios";
import {Granted} from "../../../security/granted";
import getUser from "../../../security/getUser";


const DashboardAbsenceRequest:React.FC = () => {


    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        const controller = new AbortController();

        dispatch(actions.fetchAbsences())
        api.list({status: ['1']}, controller.signal)
            .then(data => dispatch(actions.fetchAbsencesSuccess(data.data)))
            .catch(error => dispatch(axiosError(error)))

        return () => controller.abort();
    }, [])

    return <>
        <div className="btn-group w-100 shadow-sm bg-white">
            <div className="card p-3 flex-grow-1 cursor-pointer" onClick={() => dispatch(openSidebar(PENDING_ABSENCE_LIST))}>
                <div className="d-flex align-items-center">
                    <div className="col">
                        <span className={'h4 text-primary'}>
                            <i className={'bi bi-calendar-x'}> </i>
                            Congés
                        </span>
                        <div className="clearfix"> </div>
                        <span className="form-text">
                            <i className={'bi bi-clock-history text-warning'}></i> en attente
                        </span>
                    </div>
                    <div className="col-auto align-middle align-items-center">
                        {state.absence.isLoading ? <div className="spinner-border align-middle text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <span className={'text-primary h2'}>{state.absence.payload.filter((absence: Absence) => absence.status === 1).length}</span>}
                    </div>
                </div>
            </div>
            {Granted(accessRights.EDIT_ABSENCE) && <button className="flex-grow-0 btn btn-outline-primary"
                     onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE))}>
                <i className={'bi bi-plus fs-4'}></i>
            </button>}
            {Granted(accessRights.REQUEST_ABSENCE) && !Granted(accessRights.EDIT_ABSENCE) && <button className="flex-grow-0 btn btn-outline-primary"
                     onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE, {salary: getUser().currentSalary}))}>
                <i className={'bi bi-plus fs-4'}></i>
            </button>}
        </div>
    </>
}

export default DashboardAbsenceRequest;