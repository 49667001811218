import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux"

import Loading from "../../../../../../components/common/Loading"
import EmptyList from "../../../../../../components/common/EmptyList"

import { ISellsyContact } from '../../../../../../@types/models/Sellsy/ISellsyContact'

import { list, getContactById } from "../../../../../../adapters/sellsy"
import { linkSellsyIdToCompanyGroup } from "../../../../../../adapters/admin/billing"

import { showAlertSuccess, showAlertDanger } from "../../../../../../actions/alert"
import moment from "moment"

interface IProps {
  selectedCompanyGroupId: number;
  companyGroupSellsyId?: number;
  userEmail: string;
  data: ISellsyContact|undefined|null;
  setSellsyContact: (data: ISellsyContact|undefined|null) => void;
  onRefresh: (noLoading?: string) => void;
}

const SellsyContactCard: React.FC<IProps> = ({ selectedCompanyGroupId, companyGroupSellsyId, userEmail, data, setSellsyContact, onRefresh }) => {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState<number>(1)
  const [companyGroupIsLoading, setCompanyGroupIsLoading] = useState<boolean>(false)
  const [associatedSellsyContact, setAssociatedSellsyContact] = useState<ISellsyContact|undefined>(undefined)
  const [query, setQuery] = useState<string>(userEmail || '')

  const onFetchAssociatedSellsyContactData = async () => {
    try {
      if (companyGroupSellsyId) {
        setCompanyGroupIsLoading(true)
        const { data } = await getContactById({ sellsyId: companyGroupSellsyId })
        setAssociatedSellsyContact(data)
      }
    } catch (error) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    } finally {
      setCompanyGroupIsLoading(false)
    }
  }

  const onSearchSellsyContactData = async () => {
    try {
      if (query.length === 0) {
        setSellsyContact(undefined)
        return
      }

      setCompanyGroupIsLoading(true)
      const { data } = await list({ query, offset: 0 })

      if (data.data.length > 0) {
        setSellsyContact(data.data[0])
      } else {
        setSellsyContact(null)
      }
    } catch (error) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    } finally {
      setCompanyGroupIsLoading(false)
    }
  }

  const onLinkSellsyId = async (sellsyId: number) => {
    try {
      await linkSellsyIdToCompanyGroup(selectedCompanyGroupId, { sellsyId: sellsyId })
      onRefresh('noLoading')
      dispatch(showAlertSuccess('Groupement lié à Sellsy avec succès'))
    } catch (error) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    }
  }

  useEffect(() => {
    if (companyGroupSellsyId) {
      onFetchAssociatedSellsyContactData()
    }
  }, [companyGroupSellsyId])

  return (
    <div>
      <button
        className={`col-6 p-3 bg-white border-start border-top border-end border-bottom-0 rounded-top ${selectedTab === 1 ? 'text-primary' : 'text-muted'}`}
        onClick={() => setSelectedTab(1)}
      >
        Contact Sellsy associé
      </button>
      <button
        className={`col-6 p-3 bg-white border-start border-top border-end border-bottom-0 rounded-top ${selectedTab === 2 ? 'text-primary' : 'text-muted'}`}
        onClick={() => setSelectedTab(2)}
      >
        Rechercher contact Sellsy
      </button>

      {selectedTab === 1 &&
        <div className="col-12 p-3 bg-white border rounded-bottom">
          <div className="pb-2 row align-items-center">
            <div className="col-12">
              <p>
                Informations du contact Sellsy associé
              </p>
            </div>
          </div>

          {companyGroupIsLoading &&
            <Loading />
          }

          {!companyGroupIsLoading &&
            <div>
              {!associatedSellsyContact &&
                <EmptyList
                  text={'Aucun contact Sellsy associé'}
                />
              }

              {associatedSellsyContact &&
                <div className="container g-0 row">
                  <div className="col-4">
                      <p className="m-0 text-muted">
                        ID: <span className="text-body">{associatedSellsyContact.id}</span>
                      </p>
                      <p className="m-0 text-muted">
                        civilité: <span className="text-body">{associatedSellsyContact?.civility || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        nom: <span className="text-body">{`${associatedSellsyContact?.first_name} ${associatedSellsyContact?.last_name}`}</span>
                      </p>
                      <p className="m-0 text-muted">
                        position: <span className="text-body">{associatedSellsyContact?.position || '-'}</span>
                      </p>
                  </div>

                  <div className="col-4 px-1">
                      <p className="m-0 text-muted">
                        email: <span className="text-body">{associatedSellsyContact?.email || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        téléphone: <span className="text-body">{associatedSellsyContact?.phone_number || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        mobile: <span className="text-body">{associatedSellsyContact?.mobile_number || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        fax: <span className="text-body">{associatedSellsyContact?.fax_number || '-'}</span>
                      </p>
                  </div>

                  <div className="col-4">
                      <p className="m-0 text-muted">
                        crée le: <span className="text-body">{associatedSellsyContact?.created ? moment(associatedSellsyContact.created).format('DD MMMM YYYY') : '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        mis à jour le: <span className="text-body">{associatedSellsyContact?.updated ? moment(associatedSellsyContact.updated).format('DD MMMM YYYY') : '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        archivé: <span className="text-body">{associatedSellsyContact?.is_archived ? 'Archivé' : 'Non'}</span>
                      </p>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }

      {selectedTab === 2 &&
        <div className="col-12 p-3 bg-white border rounded-bottom">
          <div className="mb-3 pb-2 row align-items-center">
            <div className="col-5">
              <p>
                Rechercher un contact Sellsy
              </p>
            </div>

            <div className="col-7 d-flex justify-content-end">
              <input
                className="form-control"
                type="email"
                placeholder="Recherche par email"
                value={query}
                onChange={(e: any) => setQuery(e.target.value)}
              />
              <button
                className="ms-1 btn btn-primary"
                onClick={onSearchSellsyContactData}
              >
                <span className="bi bi-search text-white" />
              </button>
            </div>
          </div>

          {companyGroupIsLoading &&
            <Loading />
          }

          {!companyGroupIsLoading &&
            <div>
              {!data &&
                <EmptyList
                  text={data === null ? 'Aucun contact Sellsy n’a été trouvé' : 'Effectuez une recherche du contact par email'}
                />
              }

              {data &&
                <div className="container g-0 row">
                  <div className="col-4">
                      <p className="m-0 text-muted">
                        ID: <span className="text-body">{data.id}</span>
                      </p>
                      <p className="m-0 text-muted">
                        civilité: <span className="text-body">{data?.civility || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        nom: <span className="text-body">{`${data?.first_name} ${data?.last_name}`}</span>
                      </p>
                      <p className="m-0 text-muted">
                        position: <span className="text-body">{data?.position || '-'}</span>
                      </p>
                  </div>

                  <div className="col-4 px-1">
                      <p className="m-0 text-muted">
                        email: <span className="text-body">{data?.email || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        téléphone: <span className="text-body">{data?.phone_number || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        mobile: <span className="text-body">{data?.mobile_number || '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        fax: <span className="text-body">{data?.fax_number || '-'}</span>
                      </p>
                  </div>

                  <div className="col-4">
                      <p className="m-0 text-muted">
                        crée le: <span className="text-body">{data?.created ? moment(data.created).format('DD MMMM YYYY') : '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        mis à jour le: <span className="text-body">{data?.updated ? moment(data.updated).format('DD MMMM YYYY') : '-'}</span>
                      </p>
                      <p className="m-0 text-muted">
                        archivé: <span className="text-body">{data?.is_archived ? 'Archivé' : 'Non'}</span>
                      </p>
                  </div>

                  <div className="col-12 pt-2 d-flex justify-content-end">
                    <button
                      className="btn btn-primary text-white"
                      onClick={() => onLinkSellsyId(data.id)}
                    >
                      Lier ce contact au groupement
                    </button>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  )
}

export default SellsyContactCard