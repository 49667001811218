import { ISubscriptionModel } from "../../models/subscriptions"

export const SUBSCRIPTIONS_MODELS_NORMAL_RATE: ISubscriptionModel[] = [
  //First one of this list is for testing subscription
  {
    'min': 1,
    'max': 2,
    'amount': 0.0,
    'tax_rate': 20,
    'model_id': 46165012,
  },
  {
    'min': 1,
    'max': 8,
    'amount': 49.90,
    'tax_rate': 20,
    'model_id': 46009113
  },
  {
    'min': 9,
    'max': 16,
    'amount': 69.99,
    'tax_rate': 20,
    'model_id': 46009190
  },
  {
    'min': 17,
    'max': 24,
    'amount': 89.99,
    'tax_rate': 20,
    'model_id': 46009244
  },
  {
    'min': 25,
    'max': 32,
    'amount': 109.99,
    'tax_rate': 20,
    'model_id': 46009313
  },
  {
    'min': 33,
    'max': 40,
    'amount': 129.99,
    'tax_rate': 20,
    'model_id': 46009339
  },
  {
    'min': 41,
    'max': 48,
    'amount': 149.99,
    'tax_rate': 20,
    'model_id': 46009381
  },
];

export const SUBSCRIPTIONS_MODELS_REDUCED_RATE: ISubscriptionModel[] = [
  //First one of this list is for testing subscription
  {
    'min': 1,
    'max': 2,
    'amount': 0.0,
    'tax_rate': 20,
    'model_id': 46165012,
  },
  {
    'min': 1,
    'max': 8,
    'amount': 45.90,
    'tax_rate': 20,
    'model_id': 48841273
  },
  {
    'min': 9,
    'max': 16,
    'amount': 62.99,
    'tax_rate': 20,
    'model_id': 48868467
  },
  {
    'min': 17,
    'max': 24,
    'amount': 80.99,
    'tax_rate': 20,
    'model_id': 48868480
  },
  {
    'min': 25,
    'max': 32,
    'amount': 99.99,
    'tax_rate': 20,
    'model_id': 48868545
  },
  {
    'min': 33,
    'max': 40,
    'amount': 117.99,
    'tax_rate': 20,
    'model_id': 48868681
  },
  {
    'min': 41,
    'max': 48,
    'amount': 135.99,
    'tax_rate': 20,
    'model_id': 48868728
  },
];