import {Company} from "../companies";
import {Salary} from "../salary";
import {Absence} from "../absence";


export interface TimeClock {
    id?: number,
    beginAt: string,
    endAt: string|null,
    company: Company,
    salary: Salary,
    createdAt?: string,
    anomaly: boolean;
    anomalyCode: number|null;
    isClockIn: boolean;
    createdBy: null|{id: number, firstname: string, lastname: string};
    exception?: TimeClockException
}

export interface TimeClockGenerated {
    id: string,
    eventType: "timeClockBooking"|"timeClockBookingException"|"timeClockBookingMissing"|"timeClockBookingAbsent";
    description: string;
    start: string|null;
    end: string|null;
    salary: Salary,
    company: {id: number, title: string},
    timeCaption: string|null;
    isAbsent?: boolean;
    isMissing?: boolean;
    accountingType?: number;
    isLate?: boolean;
    absence?: Absence,
    planned: null|{
        start: string,
        end: string,
        timeCaption: string|null;
        salary: {id: number, firstname: string, lastname: string},
        company: {id: number, title: string},
    };
    isException: boolean;
    isAnomaly: boolean;
    ignoreStart?: boolean;
    booking: TimeClock,
    exception?: TimeClockException,
    editedBy?: Salary,
    editedAt?: string
}

export type TimeClocks = TimeClock[];

export interface TimeClockException {
    id?: number,
    beginAt: string,
    endAt: string|null,
    company: Company,
    salary: Salary,
    createdAt?: string,
    editedAt?: string,
    editedBy?: Salary,
}

export interface initialStateInterface {
    payload: TimeClockGenerated[],
    single: TimeClockGenerated|null,
    refresh: boolean,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    refresh: false,
    isLoading: false,
    error: null
};