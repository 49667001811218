import React, { useEffect, useState } from "react";

import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import CalendarTaskSidebar, { CalendarTaskSidebarInterface } from "../sidebar";
import allLocales from "@fullcalendar/core/locales-all";

import * as types from "../../../../constants/calendar";
import * as accessRights from "../../../../constants/accessRight";

import * as api from "../../../../adapters/calendar";

import { Granted } from "../../../../security/granted";
import { CalendarTitle, renderEvent } from "../../utils";
import format from "../../../../utils/locale";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";

interface Controlled {
    type: 'CONTROLLED'
    setCalendarTaskSidebarProps: React.Dispatch<any>,
    tasks: any[],
    initialView: string,
    initialDate: Date
}

interface Uncontrolled {
    type: 'UNCONTROLLED'
}

const CalendarTaskList:React.FC<Controlled | Uncontrolled> = (props) => {    
    const [tasks, setTasks] = useState<any[]>([])
    const [pickerDate, setPickerDate] = useState(new Date());
    const [title, setTitle] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const calendarRef = React.createRef<FullCalendar>();
    const [calendarTaskSidebarProps, setCalendarTaskSidebarProps] = useState<CalendarTaskSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [initialDate, setInitialDate] = useState<Date>(props.type === 'UNCONTROLLED' ? new Date() : props.initialDate);
    const [initialView, setInitialView] = useState<string>('listWeek');
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [view, setView] = useState<string>(initialView)

    function fetchCalendar() {
        let API = calendarRef.current?.getApi();

        if (API) {
            setEndDate(API.view.activeEnd)
            setLoading(true)
            api.test({
                calendar: types.CALENDAR_TASK,
                start: format(API.view.currentStart, 'uuuu-MM-dd'),
                end: format(API.view.activeEnd, 'uuuu-MM-dd'),
                options: {
                    sortBy: types.SORT_TASK_BY_GROUP
                }
            }).then(r => {
                setTasks(r.data.tasks)
            }).then(() => {
                setLoading(false)
            })
        }
    }

    function refreshCalendar(salaries: number[]) {
        let API = calendarRef.current?.getApi();

        if (API) {
            return api.test({
                start: format(API.view.currentStart, 'uuuu-MM-dd'),
                end: format(API.view.activeEnd, 'uuuu-MM-dd'),
                calendar: types.CALENDAR_TASK,
                options: {
                    sortBy: types.SORT_TASK_BY_GROUP,
                    salaries: salaries,
                }
            }).then((data) => {
                setTasks(prev =>[...[...prev.filter((e: any) => !(e.salaries.filter((s: any) => salaries.includes(s.id))))], ...data.data.tasks || []])
            }).then(() => true)
        } else {
            return false;
        }
    }

    function handleEventChange(ev: any) {
        let salaries: number[] = [];
        ev.salaries.map((s: any) => salaries.push(s.id))

        return refreshCalendar([...salaries, ...ev.previousSalaries || []]);
    }


    function handlePickerDateChange(dt: Date) {
        if (hasApi()) {
            setPickerDate(dt)
            getApi().gotoDate(dt)
            fetchCalendar()
        }
    }

    function getApi() {
        return calendarRef.current!.getApi();
    }

    function hasApi() {
        return !!calendarRef.current?.getApi();
    }

    function prevClick() {
        if (hasApi()){
            getApi().prev()
            setTitle(getApi().view.title)
            fetchCalendar()
        }
    }

    function nextClick() {
        if (hasApi()){
            getApi().next()
            setTitle(getApi().view.title)
            fetchCalendar()
        }
    }

    function onClick(arg: EventClickArg) {
        arg.jsEvent.stopPropagation()
        if (Granted(accessRights.LIST_TASK_GROUP)){
            if (props.type === 'UNCONTROLLED') {
                setCalendarTaskSidebarProps(prev => ({
                    sidebarType: "TASK_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarTaskSidebarProps: setCalendarTaskSidebarProps
                }))
            } else if (props.type === 'CONTROLLED') {
                props.setCalendarTaskSidebarProps((prev: any) => ({
                    sidebarType: "TASK_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarTaskSidebarProps: props.setCalendarTaskSidebarProps
                }))
            }
        }
    }

    function changeView(_view: string) {
        if (hasApi()){
            setView(_view)
            getApi().changeView(_view);
            setTitle(getApi().view.title)
            fetchCalendar()
        }
    }

    useEffect(() => {
        if (props.type === 'CONTROLLED') {
            if (props.initialDate.getTime() !== initialDate.getTime()){
                setInitialDate(props.initialDate)
            }
            if (props.initialView !== initialView){
                setInitialView(props.initialView)
            }
        }
    })

    useEffect(() => {
        if (props.type === 'UNCONTROLLED') {
            fetchCalendar()
        } else if (props.type === 'CONTROLLED') {
            if (hasApi()){
                setEndDate(getApi().view.activeEnd)
                switch (initialView){
                    case types.RESOURCE_TIMELINE_MONTH_VIEW:
                        getApi().changeView('listMonth', initialDate)
                        break;
                    case types.RESOURCE_TIMELINE_WEEK_VIEW:
                        getApi().changeView('listWeek', initialDate)
                        break;
                    case types.RESOURCE_TIMELINE_DAY_VIEW:
                        getApi().changeView('listDay', initialDate)
                        break;
                }
            }
        }
    }, [initialDate, initialView])

    const Toolbar = () => {
        return (
            <div className={'flex-grow-0'}>
                <div className="col-12 d-flex align-items-center mb-3">
                    <div className="flex-grow-1">
                        <h4 className={'text-primary'}>
                            <i className={"bi bi-list-check"}></i> Tâches
                        </h4>
                    </div>
                    <div className="flex-grow-0">
                        <div className="dropdown">
                            <button className="btn bg-white shadow-sm text-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                {view === 'listDay' && <>Jour</>}
                                {view === 'listWeek' && <>Semaine</>}
                            </button>
                            <ul className="dropdown-menu">
                                <li className={'dropdown-item'} onClick={() => changeView('listDay')}>
                                    Jour
                                </li>
                                <li className={'dropdown-item'} onClick={() => changeView('listWeek')}>
                                    Semaine
                                </li>
                            </ul>
                        </div>
                    </div>
                    {Granted(accessRights.EDIT_TASK) &&
                        <div
                            className="flex-grow-0"
                            onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}
                            title={"Ajouter une tâche"}
                        >
                            <button className={'btn bg-white shadow-sm text-primary'}
                                    onClick={() => setCalendarTaskSidebarProps(prev => ({
                                        sidebarType: "TASK_ADD",
                                        trigger: prev.trigger + 1
                                    }))}>
                                <i className={"bi bi-plus-circle"}></i>
                            </button>
                        </div>
                    }
                </div>
                <div className="col-12 mb-3">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <CalendarTitle
                                pickerDate={pickerDate}
                                handlePickerDateChange={handlePickerDateChange}
                                title={title}
                                prevClick={prevClick}
                                nextClick={nextClick}
                                endDate={endDate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'h-100 d-flex flex-column' + (props.type === 'CONTROLLED' ? ' w-20 ' : '')}>
            {props.type === 'UNCONTROLLED' &&
                <Toolbar/>
            }

            <div className="flex-grow-1">
                <FullCalendar
                    events={props.type === 'CONTROLLED' ? props.tasks : tasks}
                    plugins={[listPlugin]}
                    initialView={view}
                    ref={calendarRef}
                    headerToolbar={false}
                    height={'100%'}
                    viewDidMount={(arg) => setTitle(arg.view.title)}
                    locales={allLocales}
                    locale={'fr'}
                    eventClick={onClick}
                    eventContent={renderEvent}
                />
            </div>

            {props.type === 'UNCONTROLLED' &&
                <CalendarTaskSidebar
                    key={'taskList'}
                    referer={'list'}
                    handleEventChange={handleEventChange}
                    {...calendarTaskSidebarProps}
                />
            }
        </div>
    )
}

export default CalendarTaskList;