import * as types from "../../constants/absence"
import * as models from "../../models/absence"
import replace from "../../utils/arrayReplace"
import remove from "../../utils/arrayRemove"

const absence = (state: models.initialStateInterface = models.initialState, action: {type: string, absence: models.Absence, absences: models.Absences, error: string}) => {

    switch (action.type) {
        case types.FETCH_ABSENCES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_ABSENCES_SUCCESS:
            return { payload: action.absences, single: state.single, isLoading: false, error: null };
        case types.FETCH_ABSENCES_FAILURE:
            return { payload: action.absences, single: state.single, isLoading: false, error: action.error };
        case types.ADD_ABSENCE:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_ABSENCE_SUCCESS:
            return { payload: [action.absence, ...state.payload], single: action.absence, isLoading: false, error: null };
        case types.EDIT_ABSENCE:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_ABSENCE_SUCCESS:
            return { payload: replace(state.payload, action.absence), single: action.absence, isLoading: false, error: null };
        case types.CANCEL_ABSENCE_SUCCESS:
            return { payload: remove(state.payload, action.absence), single: null, isLoading: false, error: null };
        case types.SHOW_ABSENCE:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_ABSENCE_SUCCESS:
            return { payload: state.payload, single: action.absence, isLoading: false, error: null };
        case types.SHOW_ABSENCE_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default absence;