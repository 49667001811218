export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const FETCH_PRESENCE_EVENTS_SUCCESS = 'FETCH_PRESENCE_EVENTS_SUCCESS';

export const FETCH_NEED_EVENTS_SUCCESS = 'FETCH_NEED_EVENTS_SUCCESS';

export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';

export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';

export const FETCH_WORKING_TIMES = 'FETCH_WORKING_TIMES';
export const FETCH_WORKING_TIMES_SUCCESS = 'FETCH_WORKING_TIMES_SUCCESS';
export const FETCH_WORKING_TIMES_FAILURE = 'FETCH_WORKING_TIMES_FAILURE';

export const FETCH_TITLE_SUCCESS = 'FETCH_TITLE_SUCCESS';
export const FETCH_PAYROLL_SUCCESS = 'FETCH_TITLE_SUCCESS';

export const SET_EVENT_CLICK_ARG = 'SET_EVENT_CLICK_ARG';

export const SHOW_EVENT_POP = 'SHOW_EVENT_POP';
export const HIDE_EVENT_POP = 'HIDE_EVENT_POP';

export const SHOW_EVENT_POPUP = 'SHOW_EVENT_POPUP';
export const HIDE_EVENT_POPUP = 'HIDE_EVENT_POPUP';

export const SHOW_TEMPLATE_EVENT_POP = 'SHOW_TEMPLATE_EVENT_POP';
export const HIDE_TEMPLATE_EVENT_POP = 'HIDE_TEMPLATE_EVENT_POP';

export const SHOW_RESOURCE_ACTIONS = 'SHOW_RESOURCE_ACTIONS';
export const HIDE_RESOURCE_ACTIONS = 'HIDE_RESOURCE_ACTIONS';

export const CANCEL_EVENT = 'CANCEL_EVENT';
export const CANCEL_EVENTS = 'CANCEL_EVENTS';

export const REFRESH = 'REFRESH';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_JOB = 'SET_JOB';
export const SET_GROUP_BY = 'SET_GROUP_BY';
export const SET_VIEW = 'SET_VIEW';

export const SET_GENERATED = "SET_GENERATED";

export const CANCEL_BOOKING_OFFER = 'CANCEL_BOOKING_OFFER';
export const ADD_BOOKING_OFFER = 'ADD_BOOKING_OFFER';
export const FETCH_BOOKING_OFFERS_EVENT = 'FETCH_BOOKING_OFFERS_EVENT';
export const FETCH_BOOKING_OFFERS_EVENT_SUCCESS = 'FETCH_BOOKING_OFFERS_EVENT_SUCCESS';
export const FETCH_BOOKING_OFFERS_EVENT_FAILURE = 'FETCH_BOOKING_OFFERS_EVENT_FAILURE';

export const REFRESH_RESOURCE = 'REFRESH_RESOURCE';
export const REFRESH_RESOURCE_SUCCESS = 'REFRESH_RESOURCE_SUCCESS';
export const REFRESH_RESOURCE_FAILURE = 'REFRESH_RESOURCE_FAILURE';

export const EVENT_EDIT = 'EVENT_EDIT';
export const EVENT_EDIT_ABORT = 'EVENT_EDIT_ABORT';
export const EVENT_EDIT_VALIDATE = 'EVENT_EDIT_VALIDATE';

export const CALENDAR_EVENTS_GLOBAL = "CALENDAR_EVENTS_GLOBAL";
export const CALENDAR_EVENTS_SINGLE = "CALENDAR_EVENTS_SINGLE";
export const CALENDAR_SIGNED_GLOBAL = "CALENDAR_SIGNED_GLOBAL";
export const CALENDAR_SIGNED_REPORTING = "CALENDAR_SIGNED_REPORTING";
export const CALENDAR_SIGNED_SINGLE = "CALENDAR_SIGNED_SINGLE";
export const CALENDAR_ABSENCE = "CALENDAR_ABSENCE";
export const CALENDAR_TASK = "CALENDAR_TASK";
export const CALENDAR_TIME_SHEET = "CALENDAR_TIME_SHEET";
export const CALENDAR_REPORT_TIME_SHEET = "CALENDAR_TIME_SHEET";

export const RESOURCE_TIMELINE_DAY_VIEW = "resourceTimelineDay";
export const RESOURCE_TIMELINE_VIEW = "resourceTimeline";
export const RESOURCE_TIMELINE_WEEK_VIEW = "resourceTimelineWeek";
export const RESOURCE_TIMELINE_MONTH_VIEW = "resourceTimelineMonth";

export const LIST_DAY_VIEW = "listDay";
export const LIST_WEEK_VIEW = "listWeek";
export const LIST_MONTH_VIEW = "listMonth";

export const TIME_GRID_DAY_VIEW = "timeGridDay";
export const TIME_GRID_WEEK_VIEW = "timeGridWeek";
export const DAY_GRID_MONTH_VIEW = "dayGridMonth";

export const RESOURCE_TYPE_EVENT = 'TYPE_EVENT';
export const RESOURCE_TYPE_SIGNED = 'TYPE_SIGNED';
export const RESOURCE_TYPE_KEEP_NOTE = 'TYPE_KEEP_NOTE';
export const RESOURCE_TYPE_TASK = 'TYPE_TASK';
export const RESOURCE_TYPE_TEMPLATE = 'TYPE_TEMPLATE';
export const RESOURCE_TYPE_TEMPLATE_APPLIED = 'TYPE_TEMPLATE_APPLIED';

export const RESOURCE_HIERARCHY_GROUP = 'group';
export const RESOURCE_HIERARCHY_PARENT = 'parent';
export const RESOURCE_HIERARCHY_CHILD = 'child';
export const RESOURCE_HIERARCHY_GRAND_CHILD = 'grandChild';

export const EVENT_TYPE_EVENT = 'event';
export const EVENT_TYPE_EVENT_EXCEPTION = 'eventException';
export const EVENT_TYPE_BACKGROUND = 'eventBackground';
export const EVENT_TYPE_OUTER = 'outerEvent';

export const EVENT_TYPE_TIME_CLOCK = 'timeClock';
export const EVENT_TYPE_TIME_CLOCK_EXCEPTION = 'timeClockException';

export const EVENT_TYPE_TIME_CLOCK_MISSING = 'timeClockMissing';
export const EVENT_TYPE_ABSENCE = 'absence';

export const EVENT_TYPE_BOOKING = 'booking';
export const EVENT_TYPE_BOOKING_OUTER = 'bookingOuter';
export const EVENT_TYPE_BOOKING_EXCEPTION = 'bookingException';

export const EVENT_TYPE_TEMPLATE_EVENT = 'templateEvent';
export const EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION = 'templateEventException';
export const EVENT_TYPE_TEMPLATE_APPLIED_EVENT_EXCEPTION = 'templateAppliedEventException';

export const EVENT_TYPE_TEMPLATE_APPLIED_EVENT = 'templateAppliedEvent';
export const EVENT_TYPE_TEMPLATE_EVENT_APPLIED_EXCEPTION = 'templateAppliedEventException';
export const EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION = 'templateAppliedEventAppliedException';

export const EVENT_TYPE_OFFER_PENDING = 'eventOfferPending';
export const EVENT_TYPE_OFFER_VALIDATED = 'eventOfferValidated';

export const EVENT_TYPE_PRESENCE = 'presence';
export const EVENT_TYPE_PRESENCE_GLOBAL = 1;
export const EVENT_TYPE_PRESENCE_BY_JOB = 2;

export const EVENT_TYPE_KEEP_NOTE = 'keepNote';

export const EVENT_TYPE_TASK = 'task';
export const EVENT_TYPE_TASK_EXCEPTION = 'taskException';


export const SORT_BY_JOB = 1;
export const SORT_BY_LOCALISATION = 2;
export const SORT_BY_ACTIVITY = 3;
export const SORT_TASK_BY_GROUP = 2;

export const PRECISION_FIFTEEN_MINUTES = '{"minutes": 15}';
export const PRECISION_THIRTY_MINUTES = '{"minutes": 30}';
export const PRECISION_ONE_HOUR = '{"hour": 1}';
export const PRECISION_THREE_HOUR = '{"hour": 3}';
export const PRECISION_ONE_DAY = '{"day": 1}';

export const getCalendarTypesChoices = () => [
    {label: 'Planifié', value: CALENDAR_EVENTS_GLOBAL},
    {label: 'Taches', value: CALENDAR_TASK},
]

export const getCalendarTypesChoice = (calendar: string) => getCalendarTypesChoices().find(c => c.value === calendar) || {label: 'Planifié', value: CALENDAR_EVENTS_GLOBAL}

export const getSortByTypeChoices = () => [
    {label: 'Collaborateurs', value: SORT_BY_JOB},
    {label: 'Emplacements', value: SORT_BY_LOCALISATION},
    {label: 'Activités', value: SORT_BY_ACTIVITY},
]
export const getSortByTypeChoice = (choice: number) => getSortByTypeChoices().find(c => c.value === choice)!