import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import AbsenceAddForm from "./form"

import { showAlertSuccess } from "../../../actions/alert";
import { closeSidebar } from "../../../actions/rightSidebar";
import { refresh, refreshResource } from "../../../actions/calendar";

import "react-datepicker/dist/react-datepicker.css";

export const AbsenceAdd: React.FC = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state)    

    const handleSubmitSuccess = () => {
        if (state.rightSidebar.data?.resourceId) {
            dispatch(refreshResource(state.rightSidebar.data.resourceId, state.rightSidebar.data.parentId, state.rightSidebar.data.salary))
        } else {
            dispatch(refresh())
        }

        dispatch(closeSidebar())
        dispatch(showAlertSuccess("Absence ajoutée"));
    }

    return (
        <AbsenceAddForm
            salary={state.rightSidebar?.data?.salary}
            handleSubmitSuccess={handleSubmitSuccess}
        />
    )
}

export default AbsenceAdd;