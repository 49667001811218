import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/salary";
import * as api from "../../../adapters/salary";
import * as Yup from "yup";
import {Field, FieldProps, useFormik} from "formik";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertDanger, showAlertSuccess} from "../../../actions/alert";
import {SalaryForm} from "../form";
import validationClass from "../../../utils/validationClass";
import FormError from "../../../utils/formError";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../utils/customInput";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {Company} from "../../../models/companies";
import {Salary} from "../../../models/salary";
import {selectAccount} from "../../../actions/auth";
import {axiosError} from "../../../actions/axios";
import { checkEmailAvailable } from "../../../adapters/salary";
import PhoneInputCustom from "../../common/PhoneInputCustom";

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Veuillez préciser un prénom'),
    lastname: Yup.string().required('Veuillez préciser un nom'),
    tel: Yup.string().required('Veuillez préciser un numéro de téléphone'),
    email: Yup.string().nullable(),
    birthdate: Yup.string().required('Veuillez préciser une date de naissance'),
    address: Yup.object().shape({
        address: Yup.string().required('Veuillez préciser l\'adresse'),
        city: Yup.string().required('Veuillez préciser la ville'),
        zip: Yup.string().length(5, 'Le code postal doit faire 5 chiffres').required('Veuillez préciser le code postal'),
    }),
    companies: Yup.array().min(1, 'Veuillez choisir au moins une entreprise').required('Veuillez au moins une entreprise')
});

export const SalaryEdit: React.FC = () => {

    const dispatch = useDispatch();
    const salary = useSelector((state: RootStateOrAny) => state.rightSidebar.data.salary)
    const auth = useSelector((state: RootStateOrAny) => state.auth)

    const initialValues = {
        firstname: salary.firstname,
        lastname: salary.lastname,
        tel: salary.tel.startsWith('+') ? salary.tel.slice(1) : salary.tel.startsWith('0') ? `33${salary.tel.slice(1)}` : `33${salary.tel}`,
        email: salary.email,
        birthdate: new Date(salary.birthdate),
        address: {
          address: salary.address?.address,
          city: salary.address?.city,
          zip:  salary.address?.zip,
        },
        companies: salary.companies.map((company: Company) => company.id)
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit : async (values) => {
            try {
                if (values?.email && values.email !== salary.email) {
                    await checkEmailAvailable({ email: values.email })
                }
    
                // same shape as initial values
                dispatch(submitSidebar());

                api.edit({ ...values, tel: `+${values.tel}` }, salary.id).then(data => {
                    dispatch(actions.editSalarySuccess(data.data))
                    /*if (data.data.id === auth.user.currentSalary.id){
                        dispatch(selectAccount(data.data))
                    }*/
                    dispatch(showAlertSuccess(data.data.firstname + data.data.lastname + ' à bien été mis à jour !'))
                    dispatch(closeSidebar());
                }).catch(error => dispatch(axiosError(error)))
            } catch (error: any) {
                dispatch(showAlertDanger(error?.response?.data?.message))   
            }
        }
    });

    return (
            <form onSubmit={formik.handleSubmit} className={'h-100 d-flex flex-column p-3'}>
                <div className="flex-grow-1">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type={'text'}
                                    defaultValue={initialValues.firstname}
                                    className={'form-control ' + validationClass(formik.errors,formik.touched,'firstname')}
                                    name="firstname"
                                />
                                <label htmlFor="firstname">
                                    Prénom <span className={'required'}>*</span>
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'firstname'} />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type={'text'}
                                    className={'form-control ' + validationClass(formik.errors,formik.touched,'lastname')}
                                    defaultValue={initialValues.lastname}
                                    name="lastname"
                                />
                                <label htmlFor="lastname">
                                    Nom <span className={'required'}>*</span>
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'lastname'} />
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <DatePicker
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'birthdate')}
                                customInput={<DateCustomInput label={'Date de naissance'} />}
                                onChange={(date: Date) => {
                                    formik.setFieldValue('birthdate', date)
                                }}
                                dateFormat={"dd MMM uuuu"}
                                selected={formik.values.birthdate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                endDate={new Date()}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'birthdate'} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <PhoneInputCustom
                                    value={formik.values.tel}
                                    onChange={(phone) => formik.setFieldValue('tel', phone)}
                                />
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'tel'} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    defaultValue={initialValues.email}
                                    className={'form-control ' + validationClass(formik.errors,formik.touched,'email')}
                                    type='email'
                                    name="email"
                                />
                                <label htmlFor="email">
                                    Email <span className={'required'}>*</span>
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'email'} />
                            </div>
                        </div>
                    </div>
                    <section>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type={'text'}
                                        defaultValue={initialValues.address.zip}
                                        className={'form-control ' + validationClass(formik.errors,formik.touched,'address.zip')}
                                        name="address.zip"
                                    />
                                    <label htmlFor="address.zip">
                                        Code postal <span className={'required'}>*</span>
                                    </label>
                                    <FormError errors={formik.errors} touched={formik.touched} field={'address.zip'} />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input
                                        id={'address.city'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type={'text'}
                                        defaultValue={initialValues.address.city}
                                        className={'form-control ' + validationClass(formik.errors,formik.touched,'address.city')}
                                        name="address.city"
                                    />
                                    <label htmlFor="address.city">
                                        Ville <span className={'required'}>*</span>
                                    </label>
                                    <FormError errors={formik.errors} touched={formik.touched} field={'address.city'} />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-floating">
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type={'text'}
                                        defaultValue={initialValues.address.address}
                                        className={'form-control ' + validationClass(formik.errors,formik.touched,'address.address')}
                                        name="address.address"
                                        id="address.address"
                                    />
                                    <label htmlFor="address.address">
                                        Adresse <span className={'required'}>*</span>
                                    </label>
                                    <FormError errors={formik.errors} touched={formik.touched} field={'address.address'} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            required={true}
                            displayValue={true}
                            className={validationClass(formik.errors, formik.touched, 'companies')}
                            fetchEntity={"company"}
                            values={salary?.companies.map((company: Company) => ({label: company.title, value: company.id!}))}
                            isMultiple={true}
                            onChangeMultiple={(values) => formik.setFieldValue('companies', values.map(choice => choice.value))}
                            label={'Points de ventes'}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'companies'} />
                    </div>
                </div>
                <div className="flex-grow-0">
                    <button className='btn btn-outline-primary w-100' type="submit">
                        <i className={'bi bi-check'}> </i> Valider
                    </button>
                </div>
            </form>
    )


}

export default SalaryEdit;