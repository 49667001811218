import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../../../actions/header";
import EmailSettings from "./email";
import TimeClockConfig from "./timeClock";
import AbsenceConfig from "./absence";
import AccountingConfig from "./accounting";
import ModuleConfig from "./module";
import GeneralConfig from "./general";
import HasModule from "../../../security/hasModule";
import HasRole from "../../../security/hasRole";
import NotificationSettings from "./notification";

const CompanyGroupSettings:React.FC = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle('Réglages'))
    }, [])

    return <div className={'container-fluid'}>
        <nav className={'w-100 bg-white'}>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general"
                        type="button" role="tab" aria-controls="nav-general" aria-selected="true">
                    <i className={'bi bi-gear text-primary'}> </i> Général
                </button>
                {HasRole("ROLE_ADMIN") && <button className="nav-link" id="nav-module-tab" data-bs-toggle="tab" data-bs-target="#nav-module"
                         type="button" role="tab" aria-controls="nav-module" aria-selected="false">
                    <i className={'bi bi-columns text-primary'}> </i> Modules
                </button>}
                {HasModule('timeClock') && <button className="nav-link" id="nav-time-clock-tab" data-bs-toggle="tab" data-bs-target="#nav-time-clock"
                                                  type="button" role="tab" aria-controls="nav-time-clock" aria-selected="false">
                    <i className={'bi bi-person-badge text-primary'}> </i> Badgeuse
                </button>}
                <button className="nav-link" id="nav-accounting-tab" data-bs-toggle="tab" data-bs-target="#nav-accounting"
                        type="button" role="tab" aria-controls="nav-accounting" aria-selected="false">
                    <i className={'bi bi-file-check text-primary'}> </i> Comptabilité
                </button>
                <button className="nav-link" id="nav-absence-tab" data-bs-toggle="tab" data-bs-target="#nav-absence"
                        type="button" role="tab" aria-controls="nav-absence" aria-selected="false">
                    <i className={'bi bi-calendar-x text-primary'}> </i> Absences
                </button>
                {/*<button className="nav-link" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email"*/}
                {/*        type="button" role="tab" aria-controls="nav-email" aria-selected="false">*/}
                {/*    <i className={'bi bi-envelope text-primary'}> </i> Emails*/}
                {/*</button>*/}
                <button className="nav-link" id="nav-notification-tab" data-bs-toggle="tab" data-bs-target="#nav-notification"
                        type="button" role="tab" aria-controls="nav-notification" aria-selected="false">
                    <i className="bi bi-bell"></i> Notifications
                </button>
            </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                <GeneralConfig />
            </div>
            <div className="tab-pane fade" id="nav-module" role="tabpanel" aria-labelledby="nav-module-tab">
                <ModuleConfig />
            </div>
            <div className="tab-pane fade" id="nav-time-clock" role="tabpanel" aria-labelledby="nav-time-clock-tab">
                <TimeClockConfig />
            </div>
            <div className="tab-pane fade" id="nav-accounting" role="tabpanel" aria-labelledby="nav-accounting-tab">
                <AccountingConfig />
            </div>
            <div className="tab-pane fade" id="nav-absence" role="tabpanel" aria-labelledby="nav-absence-tab">
                <AbsenceConfig />
            </div>
            {/*<div className="tab-pane fade" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">*/}
            {/*    <EmailSettings />*/}
            {/*</div>*/}
            <div className="tab-pane fade" id="nav-notification" role="tabpanel" aria-labelledby="nav-notification-tab">
                <NotificationSettings />
            </div>
        </div>
    </div>
}

export default CompanyGroupSettings;