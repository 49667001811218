import React from "react";
import OfferEditForm from "../../../event/offer/edit/form";
import {Company} from "../../../../models/companies";
import {Activity} from "../../../../models/activity";
import {Localisation} from "../../../../models/localisation";
import {Salaries} from "../../../../models/salary";

interface Interface {
    handleSubmit: (ev: any) => any,
    close: () => any,
    company: Company,
    activity?: Activity
    localisation?: Localisation,
    start: Date,
    end: Date,
    offerId: number,
    description: string,
    salaries: Salaries,
}

const CalendarOfferEdit:React.FC<Interface> = (props) => {

    const {start, end, company, activity, localisation, handleSubmit, offerId, salaries, description, close} = props;

    const handleSubmitSuccess = (ev: any) => {
        return Promise.all([
            handleSubmit(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarOfferEditLabel">Modifier une offre de créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <OfferEditForm
                handleSubmitSuccess={handleSubmitSuccess}
                _company={company}
                activity={activity}
                localisation={localisation}
                start={start}
                end={end}
                id={offerId}
                description={description}
                salaries={salaries}
            />
        </div>
    </>
}

export default CalendarOfferEdit;