import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/counter";

const DashboardCounter:React.FC = () => {


    const [counter, setCounter] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        api.sumSingle().then(data => setCounter(data.data)).then(() => setIsLoading(false))
    }, [])

    return <>
        <div className="card p-3 shadow-sm mb-3">
            <div className="d-flex align-items-center">
                <div className="col">
                    <span className={'h4 text-primary'}>
                        <i className={'bi bi-clock'}> </i>
                        Compteur
                    </span>
                    <div className="clearfix"> </div>
                    <span className="form-text text-capitalize">
                        à date
                    </span>
                </div>
                <div className="col-auto align-middle align-items-center">
                    {isLoading ? <div className="spinner-border align-middle text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : <span className={'text-primary h2'}>
                        {!!counter && counter < 0 && <i className={"bi bi-arrow-down text-danger"}></i>}
                        {!!counter && counter > 0 && <i className={"bi bi-arrow-up text-success"}></i>}
                        {counter}h
                    </span>}
                </div>
            </div>
        </div>
    </>
}

export default DashboardCounter;