export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
export const ADD_RESOURCE_FAILURE = 'ADD_RESOURCE_FAILURE';

export const ADD_RESOURCE_INFORMATION = 'ADD_RESOURCE_INFORMATION';
export const ADD_RESOURCE_INFORMATION_SUCCESS = 'ADD_RESOURCE_INFORMATION_SUCCESS';
export const ADD_RESOURCE_INFORMATION_FAILURE = 'ADD_RESOURCE_INFORMATION_FAILURE';

export const EDIT_RESOURCE = 'EDIT_RESOURCE';
export const EDIT_RESOURCE_SUCCESS = 'EDIT_RESOURCE_SUCCESS';
export const EDIT_RESOURCE_FAILURE = 'EDIT_RESOURCE_FAILURE';

export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';

export const SHOW_RESOURCE = 'SHOW_RESOURCE';
export const SHOW_RESOURCE_SUCCESS = 'SHOW_RESOURCE_SUCCESS';
export const SHOW_RESOURCE_FAILURE = 'SHOW_RESOURCE_FAILURE';
