import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch, NavLink} from "react-router-dom";
import {
    AdminRoute,
    GuestRoute,
    PrivateRoute,
    PrivateRouteAny,
    PrivateRouteWithoutAccount, PrivateRouteWithoutCompany,
} from "./bl";
import './index.scss'
import LoginPage from "../../pages/auth/login";
import RegisterPage from "../../pages/auth/register";
import CompaniesPage from "../../pages/companies";
import {exitImpersonateUser, logout} from "../../actions/auth";
import Error404 from "../error/404";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import ConfirmationPage from "../../pages/auth/confirmation";
import PasswordConfirmationPage from "../../pages/auth/passwordConfirmation";
import AccessLevelPage from "../../pages/accessLevel";
import AbsenceCalendarPage from "../../pages/absence/calendar";
import LocalisationPage from "../../pages/localisation";
import CalendarPage from "../../pages/calendar/show";
import ProfileShowPage from "../../pages/auth/profile/show";
import ChannelPage from "../../pages/channel";
import AccountListPage from "../../pages/auth/account";
import TaskGroupCalendarPage from "../../pages/taskGroup/calendar";
import DashboardPage from "../../pages/dashboard";
import SalaryPage from "../../pages/salary";
import AbsencePage from "../../pages/absence";
import JobPage from "../../pages/job";
import CompanyGroupSettingsPage from "../../pages/companyGroup/settings";
import TimeClockPage from "../../pages/timeClock";
import ReportingListPage from "../../pages/reporting";
import ReportingShowPage from "../../pages/reporting/show";
import {GrantedAny, Granted} from "../../security/granted";
import * as accessRights from '../../constants/accessRight';
import Error403 from "../error/403";
import PasswordPage from "../../pages/auth/password";
import NoCompany from "../companies/noCompany";
import Error500 from "../error/500";
import HasModule from "../../security/hasModule";
import PasswordRequestPage from "../../pages/auth/passwordRequest";
import UserPage from "../../pages/admin/user";
import HasRole from "../../security/hasRole";
import ActivityPage from "../../pages/activity";
import TaskGroupListPage from "../../pages/taskGroup/list";
import TaskGroupShowPage from "../../pages/taskGroup/show";
import TeamPage from "../../pages/team";
import BookingOfferPage from "../../pages/booking/offer";
import hasModule from "../../security/hasModule";
import {refreshAuthSuccess} from "../../actions/auth";
import {refreshAuth} from "../../adapters/auth";
import {axiosError} from "../../actions/axios";
import TimeClockCalendarPage from "../../pages/timeClock/calendar";
import BootstrapSelect from "../../utils/bootstrapSelect";
import DocumentPage from "../../pages/document";
import DocumentAddPage from "../../pages/document/add";
import SponsorshipShowPage from "../../pages/sponsorship/show";
import AdminPage from "../../pages/admin";
import AdminSalaryPage from "../../pages/admin/salary";
import CounterPage from "../../pages/counter";
import ExportSilaePage from "../../pages/export/silae";
import ExportPage from "../../pages/export";
import Calendar from "../calendar/multiple";
import ExpensePage from "../../pages/expense";
import EventOfferPage from "../../pages/event/offer";
import Test from "../test";
import TemplatePage from "../../pages/template";
import Help from "../help";
import ReportPage from "../../pages/report";
import getVersion from "../../security/getVersion";
import MyStorage from "../storage";
import HelpItem from "../helps/HelpItem";
import HelpModal from "../../components/helps/HelpModal";
import BillingPage from "../../pages/billing";

const Navigation: React.FC = () => {

    const state = useSelector((state: RootStateOrAny)  => state);    
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isAuthUpdating, setIsAuthUpdating] = useState(false)
    const [isAuthUpdated, setIsAuthUpdated] = useState(Number(sessionStorage.getItem('authUpdated')))
    // const location = useLocation();

    useEffect(() => {
        if (!isAuthUpdated && state.auth.token){
            setIsAuthUpdating(true)
            dispatch(exitImpersonateUser())
            refreshAuth()
                .then(data => {
                    dispatch(refreshAuthSuccess(data.data))
                    setIsAuthUpdating(false)
                    setIsAuthUpdated(1)

                })
                .catch(error => dispatch(axiosError(error)))
        }

        return () => setIsAuthUpdated(0);
    }, [state.auth.token])


    // useEffect(() => {
    //     if (state.auth.user){
    //         // @ts-ignore
    //         let _hsq = window._hsq = window._hsq || [];
    //         _hsq.push(["identify", {
    //             email: state.auth.user.email,
    //             name: state.auth.user.currentSalary.title,
    //         }]);
    //     }
    // }, [state.auth.token])
    //
    // useEffect(() => {
    //     if (state.auth.user && state.auth.user.roles.some((r: string)=> ['ROLE_ADMIN', 'ROLE_CAN_CHAT'].includes(r))) {
    //         // @ts-ignore
    //         let _hsq = window._hsq = window._hsq || [];
    //         _hsq.push(['setPath', location.pathname]);
    //         // @ts-ignore
    //         window.HubSpotConversations?.widget.refresh();
    //     }else{
    //         // @ts-ignore
    //         window.HubSpotConversations?.widget.remove();
    //     }
    // }, [location.pathname])

    const simulateClick = (elem: Element) => {
        // Create our event (with options)
        let evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        // If cancelled, don't dispatch our event
        let canceled = !elem.dispatchEvent(evt);
    };

    useEffect(() => {

        let linkColor = document.querySelectorAll('.nav_link')
        let dropdowns = document.querySelectorAll('.hover-click')
        let childrenLinkColor = document.querySelectorAll('.nav_link_children')
        let colorLink = (elem: Element, elements: NodeListOf<Element>) => {
            if(elements){
                elements.forEach(l=> {
                    l.classList.remove('nav-active')
                })
                elem.classList.add('nav-active')
            }
        }

        dropdowns.forEach((l)=> l.addEventListener('mouseenter', () => simulateClick(l)))
        linkColor.forEach((l)=> l.addEventListener('click', () => colorLink(l, linkColor)))
        childrenLinkColor.forEach((l)=> l.addEventListener('click', () => colorLink(l, childrenLinkColor)))

        return () => {
            dropdowns.forEach((l)=> l.removeEventListener('mouseenter', () => simulateClick(l)))
            linkColor.forEach((l)=> l.removeEventListener('click', () => colorLink(l, linkColor)))
            childrenLinkColor.forEach((l)=> l.removeEventListener('click', () => colorLink(l, childrenLinkColor)))
        }
    }, [])

    if (state.auth.token && !isAuthUpdated){        
        if (isAuthUpdating){
            return <div className={'container-fluid text-center py-5 h-100'}>
                <div className="spinner-border text-primary justify-content-center" style={{height: 90, width: 90}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        }

        return <></>
    }

    return (
        <>
            {state.auth.token && <div className={"header" + (isOpen ? " app-p" : "")} id="appHeader">
                <div className="header_toggle ms-3" onClick={() => setIsOpen(prevState => !prevState)}>
                    <i className={"bi " + (isOpen ? 'bi-x' : 'bi-list')} id="header-toggle"> </i>
                </div>

                <div className={'text-left page-title-container w-100'}>
                    <div className="row align-items-center">
                        <div className="col d-flex align-items-center">
                            <span className={'h4 mb-0'}>
                                {state.header.pageTitle}
                            </span>
                            {state.header.pageUnderTitle && <span className={"d-none d-md-block"}>
                                 <>
                                    <i className={'bi bi-chevron-right align-self-center px-2'}> </i>
                                    <span className={'h4 mb-0'}>
                                        {state.header.pageUnderTitle}
                                    </span>
                                </>
                            </span>}
                        </div>

                        {Granted('HAS_ACCOUNT') &&
                            <>
                                <div className="col-auto">
                                    <HelpItem />
                                </div>
                                
                                {HasRole('ROLE_ADMIN') &&
                                    <div className="col-auto">
                                        <NavLink to={'/sponsorship'} className={'btn btn-success mb-0 text-white'}>
                                            <i className={'bi bi-gift'}></i> <span
                                            className="d-none d-md-inline">Je parraine !</span>
                                        </NavLink>
                                    </div>
                                }

                                {Granted(accessRights.LIST_SALARY) &&
                                    <div className="col-auto d-none d-xl-block">
                                        <div className="input-group shadow-sm">
                                            <div className="input-group-text border-0 bg-light">
                                                <i className={'bi bi-search'}> </i>
                                            </div>
                                            <BootstrapSelect
                                                displayValue={false}
                                                fetchEntity={"salary"}
                                                notFloating={true}
                                                className={'form-control bg-light border-0'}
                                                placeholder={'Rechercher...'}
                                                onChange={(choice) => {
                                                    if (choice){
                                                        window.location.href = `#/salary/${choice?.value}`
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="col-auto">
                                    <div className="dropdown">
                                        <button
                                            className="btn hover-click btn-light h4 mb-0 text-capitalize dropdown-toggle-no-after-content dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className={'bi bi-person-circle'}> </i>
                                            <span className={'d-none d-md-inline-block'}>
                                                {state.auth.user.currentSalary?.title}
                                            </span>
                                        </button>
                                        <ul className="dropdown-menu mt-1" aria-labelledby="dropdownMenuButton1">
                                            {state.auth.user.id !== state.auth.initialUser.id && <li className="dropdown-item" onClick={() => dispatch(exitImpersonateUser())}>
                                                <i className={'bi bi-box-arrow-left'}> </i> {state.auth.initialUser.information.firstname} {state.auth.initialUser.information.lastname}
                                            </li>}
                                            {HasRole('ROLE_SUPER_ADMIN') && <li className="dropdown-item">
                                                <NavLink to={'/admin'}>
                                                    <i className={'bi bi-lock'}> </i> Admin
                                                </NavLink>
                                            </li>}
                                            <li>
                                                <NavLink className="dropdown-item" to="/profile">
                                                    <i className={'bi bi-person-circle'}> </i> Mon compte
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="dropdown-item" to="/help">
                                                    <i className={'bi bi-info-circle'}> </i> Aide
                                                </NavLink>
                                            </li>
                                            {Granted(accessRights.EDIT_COMPANY_GROUP) && <li>
                                                <NavLink className="dropdown-item" to="/settings">
                                                    <i className={'bi bi-gear'}> </i> Réglages
                                                </NavLink>
                                            </li>}
                                            {Granted(accessRights.HANDLE_BILLING) && state.auth.user.currentSalary.companyGroup.billingVersion === 2 && <li>
                                                <NavLink className="dropdown-item" to="/billing">
                                                    <i className={'bi bi-gear'}> </i> Facturation
                                                </NavLink>
                                            </li>}
                                            <li>
                                                <button onClick={() => {
                                                    if (window.ReactNativeWebView){
                                                        window.ReactNativeWebView.postMessage(JSON.stringify({token: state.auth.token, action: "logout"}))
                                                    }
                                                    dispatch(logout())
                                                }} className="dropdown-item">
                                                    <i className={'bi bi-toggle-off'}> </i> Deconnexion
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        }

                        {!Granted('HAS_ACCOUNT') &&
                            <>
                                <div className="col-auto">
                                    <button className={'btn btn-light'} onClick={() => {
                                        if (window.ReactNativeWebView){
                                            window.ReactNativeWebView.postMessage(JSON.stringify({token: state.auth.token, action: "logout"}))
                                        }
                                        dispatch(logout())
                                    }}>
                                        <i className={'bi bi-toggle-off'}> </i> Deconnexion
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>}
        <div className={'app-container d-flex bg-light' + (isOpen ? " app-p" : "") + (!state.auth.token ? ' m-0 p-0 vh-100 ' : '')} id={"appContainer"}>
            {state.auth.token &&
                <>
                        <div className={"l-navbar " + (isOpen ? " nav_show" : "")} id="nav-bar">
                            <nav className="nav-left">
                                <div className={'w-100'}>
                                    <NavLink to={'/dashboard'} className="nav_logo">
                                        <img className={'bx bx-layer nav_logo-icon'} src={process.env.PUBLIC_URL + "/logo/ICON_SIVAN_BLANC.svg"} alt=""/>
                                        <span className="nav_logo-name">SIVAN</span>
                                    </NavLink>
                                    <div className="nav_list">
                                        {Granted('HAS_ACCOUNT') && <NavLink to={'/dashboard'} className="nav_link btn">
                                            <img className={'bx bx-user nav_icon'}
                                                 src={process.env.PUBLIC_URL + "/navicons/dashboard.svg"} alt=""/>
                                            <span className="nav_name">Tableau de bord</span>
                                        </NavLink>}
                                        {Granted(accessRights.LIST_BOOKING) && <NavLink to={'/calendar'} className="nav_link btn">
                                            <img className={'bx bx-user nav_icon'}
                                                 src={process.env.PUBLIC_URL + "/navicons/calendar-check.svg"} alt=""/>
                                            <span className="nav_name">Planning</span>
                                        </NavLink>}
                                        {GrantedAny([
                                            accessRights.LIST_ABSENCE,
                                            accessRights.LIST_TASK_GROUP,
                                            accessRights.LIST_SELF_TASK,
                                            accessRights.LIST_TEMPLATE,
                                            accessRights.LIST_TIME_CLOCK,
                                            accessRights.LIST_SELF_TIME_CLOCK,
                                            accessRights.LIST_SELF_SALARY_FILE,
                                        ]) && <div className="btn-group dropend hover-click">
                                            <button
                                                className="nav_link hover-click btn dropdown-toggle dropdown-toggle-no-after-content"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <img className={'bx bx-user nav_icon'}
                                                     src={process.env.PUBLIC_URL + "/navicons/settings.svg"} alt=""/>
                                                <span className="nav_name">
                                                    Gestion du temps
                                                </span>
                                            </button>
                                            <div className="dropdown-menu shadow text-white">
                                                {Granted(accessRights.LIST_ABSENCE) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/absence">
                                                    <i className={'bi bi-calendar-x'}> </i> Absences
                                                </NavLink>}
                                                {Granted(accessRights.LIST_SELF_SALARY_FILE) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/mystorage">
                                                    <i className={'bi bi-file-person'}> </i> Mes documents
                                                </NavLink>}
                                                {hasModule('task') && Granted(accessRights.LIST_TASK_GROUP) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/task_group">
                                                    <i className={'bi bi-journal'}> </i> Tâches
                                                </NavLink>}
                                                {Granted(accessRights.LIST_TEMPLATE) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/template">
                                                    <i className={'bi bi-front'}> </i> Templates
                                                </NavLink>}
                                                {Granted(accessRights.LIST_TIME_CLOCK) && HasModule('timeClock') &&
                                                <NavLink className="dropdown-item nav_link_children" to="/time_clock">
                                                    <i className={'bi bi-tablet'}> </i> Badgeuse
                                                </NavLink>}
                                            </div>
                                        </div>}
                                        {GrantedAny([
                                            accessRights.LIST_SALARY,
                                            accessRights.LIST_TEAM,
                                            accessRights.LIST_ACCESS_LEVEL,
                                        ])  &&<div className="btn-group dropend">
                                            <button
                                                className="nav_link hover-click btn dropdown-toggle dropdown-toggle-no-after-content"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <img className={'bx bx-user nav_icon'}
                                                     src={process.env.PUBLIC_URL + "/navicons/salaries.svg"} alt=""/>
                                                <span className="nav_name">Personnel</span>
                                            </button>
                                            <div className="dropdown-menu text-white">
                                                {Granted(accessRights.LIST_SALARY) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/salary">
                                                    <i className={'bi bi-person'}> </i> Collaborateurs
                                                </NavLink>}
                                                {hasModule('team') && Granted(accessRights.LIST_TEAM) && <NavLink className="dropdown-item nav_link_children" to="/team">
                                                    <i className={'bi bi-people'}> </i> Équipes
                                                </NavLink>}
                                                {Granted(accessRights.LIST_ACCESS_LEVEL) &&
                                                <NavLink className="dropdown-item nav_link_children"
                                                         to="/access_level">
                                                    <i className={'bi bi-shield'}> </i> Droits d'accès
                                                </NavLink>}
                                            </div>
                                        </div>}
                                        {GrantedAny([
                                            accessRights.LIST_REPORTING,
                                            accessRights.EXPORT_REPORTING,
                                            accessRights.LIST_COUNTER
                                        ]) && <div className="btn-group dropend">
                                            <button
                                                className="nav_link hover-click btn dropdown-toggle dropdown-toggle-no-after-content"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <img className={'bx bx-user nav_icon'}
                                                     src={process.env.PUBLIC_URL + "/navicons/doc.svg"} alt=""/>
                                                <span className="nav_name">
                                                    Rapports
                                                </span>
                                            </button>
                                            <div className="dropdown-menu text-white">
                                                {hasModule('accounting') &&  Granted(accessRights.LIST_REPORTING) && <>
                                                        {getVersion() === 1 ? <NavLink className="dropdown-item nav_link_children" to="/report">
                                                            <i className={'bi bi-file-check'}> </i> Rapports
                                                        </NavLink> : <NavLink className="dropdown-item nav_link_children" to="/reporting">
                                                            <i className={'bi bi-file-earmark-spreadsheet'}> </i> Synthèse
                                                        </NavLink>}
                                                    </>}
                                                {Granted(accessRights.LIST_COUNTER) &&
                                                    <NavLink className="dropdown-item nav_link_children" to="/counter">
                                                        <i className={'bi bi-bar-chart'}> </i> Compteurs
                                                    </NavLink>}
                                                {GrantedAny([accessRights.LIST_EXPENSE, accessRights.REQUEST_EXPENSE]) &&
                                                    <NavLink className="dropdown-item nav_link_children" to="/expense">
                                                        <i className={'bi bi-cash'}> </i> Notes de frais
                                                    </NavLink>}
                                                {Granted(accessRights.EXPORT_REPORTING) && state.auth.user.currentSalary?.companyGroup.accountingSettings.silaeExport &&
                                                    <NavLink className="dropdown-item nav_link_children" to="/export/silae">
                                                        <i className={'bi bi-file-arrow-down'}> </i> Exports Silae
                                                    </NavLink>}
                                            </div>
                                        </div>}
                                        {GrantedAny([
                                            accessRights.LIST_COMPANY,
                                            accessRights.LIST_JOB,
                                            accessRights.LIST_LOCALISATION,
                                            accessRights.LIST_ACTIVITY,
                                            accessRights.LIST_DOCUMENT,
                                        ]) && <div className="btn-group dropend">
                                            <button
                                                className="nav_link btn hover-click dropdown-toggle dropdown-toggle-no-after-content"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <img className={'bx bx-user nav_icon'}
                                                     src={process.env.PUBLIC_URL + "/navicons/company.svg"} alt=""/>
                                                <span className="nav_name">Entreprises</span>
                                            </button>
                                            <div className="dropdown-menu text-white">
                                                {Granted(accessRights.LIST_COMPANY) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/company">
                                                    <i className={'bi bi-shop'}> </i> Points de ventes
                                                </NavLink>}
                                                {Granted(accessRights.LIST_JOB) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/job">
                                                    <i className={'bi bi-person-bounding-box'}> </i> Métiers
                                                </NavLink>}
                                                {Granted(accessRights.LIST_DOCUMENT) && hasModule('document') &&
                                                    <NavLink className="dropdown-item nav_link_children"
                                                             to="/document">
                                                        <i className={'bi bi-file-earmark'}> </i> Documents
                                                    </NavLink>}
                                                {Granted(accessRights.LIST_LOCALISATION) &&
                                                <NavLink className="dropdown-item nav_link_children"
                                                         to="/localisation">
                                                    <i className={'bi bi-geo-alt'}> </i> Emplacements
                                                </NavLink>}
                                                {Granted(accessRights.LIST_ACTIVITY) &&
                                                <NavLink className="dropdown-item nav_link_children" to="/activity">
                                                    <i className={'bi bi-bookmark'}> </i> Activités
                                                </NavLink>}
                                            </div>
                                        </div>}
                                        {Granted('HAS_ACCOUNT') && <NavLink to={'/channel'} className="nav_link btn">
                                            <img className={'bx bx-layer nav_icon'}
                                                 src={process.env.PUBLIC_URL + "/navicons/chat.svg"} alt=""/>
                                            <span className="nav_name">Messagerie</span>
                                        </NavLink>}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </>}
                <>
                    <div className="d-flex flex-column flex-grow-1 w-100">
                        <Switch>
                            <Route path="/" exact
                                   render={() => {
                                       let next = localStorage.getItem('next_route')

                                       if (state.auth.token && state.auth.user.currentSalary)
                                           localStorage.removeItem('next_route')

                                       if (!state.auth.token){
                                           return <Redirect to="/login" />
                                       }else if (state.auth.token && !state.auth.user.currentSalary){
                                           return <Redirect to="/account" />
                                       }else{
                                           return <Redirect to={next ? next : '/dashboard'} />
                                       }
                                   }}
                            />

                            {/* Auth */}
                            <GuestRoute exact path="/login" component={LoginPage} />

                            <Route exact path="/logout" render={() => {
                                if (window.ReactNativeWebView){
                                    window.ReactNativeWebView.postMessage(JSON.stringify({token: state.auth.token, action: "logout"}))
                                }
                                dispatch(logout())
                                return <Redirect to= '/login' />
                            }} />
                            <GuestRoute exact path="/confirmation/:token" component={ConfirmationPage} />
                            <GuestRoute exact path="/confirmation/:token/:isPassword" component={PasswordConfirmationPage} />
                            <GuestRoute exact path="/password/request/:email?" component={PasswordRequestPage} />

                            <PrivateRouteWithoutCompany exact path="/no_company" component={NoCompany} />
                            <PrivateRoute exact path="/profile" component={ProfileShowPage} />
                            <PrivateRouteAny exact path="/password" component={PasswordPage} />
                            <PrivateRouteWithoutAccount exact path="/account" component={AccountListPage} />

                            {/* Admin */}
                            <AdminRoute exact path="/admin" component={AdminPage}  />
                            <AdminRoute exact path="/admin/register" component={RegisterPage}  />
                            <AdminRoute exact path={"/admin/user/:id?"} component={UserPage} />
                            <AdminRoute exact path={"/admin/salary/:id?"} component={AdminSalaryPage} />
                            <AdminRoute exact path={"/admin"} component={AdminPage} />

                            {/* Aide */}
                            <PrivateRoute exact path="/help" component={Help} />

                            {/* Storage */}
                            <PrivateRoute exact path="/mystorage" component={MyStorage} />

                            {/* Localisation */}
                            <PrivateRoute exact path="/localisation/:id?" component={LocalisationPage} rights={[accessRights.LIST_LOCALISATION]} />

                            {/* Counter */}
                            <PrivateRoute exact path="/counter" component={CounterPage} rights={[accessRights.LIST_COUNTER]} />

                           {/* Activity */}
                            <PrivateRoute exact path="/activity/:id?" component={ActivityPage} rights={[accessRights.LIST_ACTIVITY]} />

                            {/* Time Clock */}
                            <PrivateRoute exact path="/time_clock" component={TimeClockPage} rights={[accessRights.LIST_TIME_CLOCK, accessRights.LIST_SELF_TIME_CLOCK]} />
                            <PrivateRoute exact path="/time_clock/calendar" component={TimeClockCalendarPage} rights={[accessRights.LIST_TIME_CLOCK, accessRights.LIST_SELF_TIME_CLOCK]} />

                            {/* Reporting */}
                            <PrivateRoute exact path="/reporting/:start?/:end?" component={ReportingListPage} rights={[accessRights.LIST_REPORTING]}/>
                            <PrivateRoute exact path="/reporting/:id/:start?/:end?" component={ReportingShowPage} rights={[accessRights.LIST_REPORTING]} />

                            {/* Rapport */}
                            <PrivateRoute exact path="/report/:id?" component={ReportPage} rights={[accessRights.LIST_REPORTING]}/>

                            {/* Export */}
                            <PrivateRoute exact path="/export" component={ExportPage} rights={[accessRights.EXPORT_REPORTING]}/>
                            <PrivateRoute exact path="/export/silae" component={ExportSilaePage} rights={[accessRights.EXPORT_REPORTING]}/>

                            {/* Access Level */}
                            <PrivateRoute exact path="/access_level/:id?" component={AccessLevelPage} rights={[accessRights.LIST_ACCESS_LEVEL]}/>

                            {/* Dashboard */}
                            <PrivateRoute exact path="/dashboard" component={DashboardPage} />

                            {/* Company */}
                            <PrivateRoute exact path="/company/:id?" component={CompaniesPage} rights={[accessRights.LIST_COMPANY]} />

                            {/* Company Group */}
                            <PrivateRoute exact path={"/settings"} component={CompanyGroupSettingsPage} rights={[accessRights.EDIT_COMPANY]} />

                            {/* Billing */}
                            <PrivateRoute exact path={"/billing/:id?/:tab?"} component={BillingPage} rights={[accessRights.HANDLE_BILLING]} />

                            {/* Tasks */}
                            <PrivateRoute exact path="/task_group/calendar" component={TaskGroupCalendarPage} rights={[accessRights.LIST_TASK_GROUP, accessRights.LIST_SELF_TASK]} />
                            <PrivateRoute exact path="/task_group" component={TaskGroupListPage}  rights={[accessRights.LIST_TASK_GROUP, accessRights.LIST_SELF_TASK]}/>
                            <PrivateRoute exact path="/task_group/:id" component={TaskGroupShowPage}  rights={[accessRights.LIST_TASK_GROUP, accessRights.LIST_SELF_TASK]}/>

                            {/* Channel */}
                            <PrivateRoute exact path="/channel/:id?" component={ChannelPage} rights={[accessRights.LIST_CHANNEL]} />

                            {/* Salary */}
                            <PrivateRoute exact path="/salary/:id?" component={SalaryPage} rights={[accessRights.LIST_SALARY]} />

                            {/* Absence */}
                            <PrivateRoute exact path="/absence/calendar" component={AbsenceCalendarPage} rights={[accessRights.LIST_ABSENCE]} />
                            <PrivateRoute exact path="/absence/:id?" component={AbsencePage} rights={[accessRights.REQUEST_ABSENCE, accessRights.LIST_ABSENCE]} />

                            {/* Job */}
                            <PrivateRoute exact path="/job/:id?" component={JobPage} rights={[accessRights.LIST_JOB]} />

                            {/* Calendar */}
                            <PrivateRoute exact path="/bkp" component={CalendarPage} rights={[accessRights.LIST_BOOKING]} />
                            <PrivateRoute exact path="/calendar/:initialDate?/:initialCalendar?" component={Calendar} rights={[accessRights.LIST_BOOKING]} />

                            {/* Booking Offer */}
                            <PrivateRoute exact path="/booking/offer/:id" component={BookingOfferPage} />
                            <PrivateRoute exact path="/event/offer/:id" component={EventOfferPage} />

                            {/* Expense */}
                            <PrivateRoute exact path="/expense/:id?" component={ExpensePage} rights={[accessRights.LIST_EXPENSE, accessRights.REQUEST_EXPENSE]} />

                            {/* Document */}
                            <PrivateRoute exact path="/document/add" component={DocumentAddPage} rights={[accessRights.EDIT_DOCUMENT]} />
                            <PrivateRoute exact path="/document/:id?" component={DocumentPage}  rights={[accessRights.LIST_DOCUMENT]}  />

                            {/* Template */}
                            {/*<PrivateRoute exact path="/template" component={TemplateListPage} rights={[accessRights.LIST_TEMPLATE]} />*/}
                            <PrivateRoute exact path="/template/:id?" component={TemplatePage} rights={[accessRights.LIST_TEMPLATE]} />

                            {/* Team */}
                            <PrivateRoute exact path="/team/:id?" component={TeamPage} />

                            {/* Sponsorship */}
                            <PrivateRoute exact path="/sponsorship" component={SponsorshipShowPage} />
                            <PrivateRoute exact path="/test" component={Test} />

                            {/* ERROR */}
                            <PrivateRoute exact path="/403" component={Error403} />
                            <PrivateRoute exact path="/500" component={Error500} />
                            <PrivateRoute component={Error404} />
                        </Switch>

                        <HelpModal />
                    </div>
                </>
            </div>
        </>
    )
}

export default Navigation;