
export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE';

export const ADD_TEAM_INFORMATION = 'ADD_TEAM_INFORMATION';
export const ADD_TEAM_INFORMATION_SUCCESS = 'ADD_TEAM_INFORMATION_SUCCESS';
export const ADD_TEAM_INFORMATION_FAILURE = 'ADD_TEAM_INFORMATION_FAILURE';

export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const EDIT_TEAM_FAILURE = 'EDIT_TEAM_FAILURE';

export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';

export const SHOW_TEAM = 'SHOW_TEAM';
export const SHOW_TEAM_SUCCESS = 'SHOW_TEAM_SUCCESS';
export const SHOW_TEAM_FAILURE = 'SHOW_TEAM_FAILURE';


export const accessRights = () => [

        {underscoreCase: 'LIST_SALARY', description: 'Lister les collaborateurs'},
        {underscoreCase: 'EDIT_SALARY', description: 'Modifier les collaborateurs'},
        {underscoreCase: 'DELETE_SALARY', description: 'Archiver les collaborateurs'},

        {underscoreCase: 'LIST_BOOKING', description: 'Afficher les plannings'},
        {underscoreCase: 'LIST_BOOKING_OFFER', description: 'Afficher les offres de créneaux'},
        {underscoreCase: 'EDIT_BOOKING', description: 'Modifier les créneaux'},
        {underscoreCase: 'DELETE_BOOKING', description: 'Archiver les créneaux'},

        {underscoreCase: 'LIST_TIME_CLOCK', description: 'Lister les badgeages'},
        {underscoreCase: 'EDIT_TIME_CLOCK', description: 'Modifier les badgeages'},
        {underscoreCase: 'DELETE_TIME_CLOCK', description: 'Archiver les badgeages'},

        {underscoreCase: 'LIST_ABSENCE', description: 'Lister les absences'},
        {underscoreCase: 'EDIT_ABSENCE', description: 'Modifier les absences'},
        {underscoreCase: 'DELETE_ABSENCE', description: 'Archiver les absences'},

        {underscoreCase: 'LIST_TASK_GROUP', description: 'Lister les groupes de tâches'},
        {underscoreCase: 'EDIT_TASK', description: 'Modifier les tâches'},

        {underscoreCase: 'LIST_COUNTER', description: 'Lister les compteurs'},
        {underscoreCase: 'EDIT_COUNTER', description: 'Modifier les compteure'},
        {underscoreCase: 'DELETE_COUNTER', description: 'Archiver les compteurs'},

        {underscoreCase: 'LIST_REPORTING', description: 'Lister les rapports'},
        {underscoreCase: 'EXPORT_REPORTING', description: 'Exporter les rapports'},
]