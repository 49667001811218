import React, {useState} from "react";
import {TimeClockGenerated} from "../../../models/timeClock";
import format from "../../../utils/locale";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {openSidebar} from "../../../actions/rightSidebar";
import {TIME_CLOCK_BOOKING_ADD, TIME_CLOCK_BOOKING_EDIT} from "../../../constants/rightSidebar";
import * as accessRights from "../../../constants/accessRight";
import IsGranted from "../../../security/isGranted";
import {
    ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED,
    ACCOUNTING_TYPE_WORK_ACCOUNTED,
    ACCOUNTING_TYPE_WORK_UNACCOUNTED
} from "../../../constants/booking";
import * as api from "../../../adapters/timeClock"
import {editTimeClockGeneratedSuccess, removeTimeClockGeneratedSuccess} from "../../../actions/timeClock";
import {showAlertSuccess} from "../../../actions/alert";

const GeneratedList:React.FC<{bookings: TimeClockGenerated[], next?: string, data?:any }> = (props) => {

    const {bookings, next, data} = props;
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)

    const [cancelConfirmation, setCancelConfirmation] = useState<string>();
    const [canceling, setCanceling] = useState<boolean>(false);

    const cancelItem = (timeClock: TimeClockGenerated) => {
        setCanceling(true)
        api.remove(timeClock.booking.id!).then(() => {
            setCanceling(false)
            dispatch(removeTimeClockGeneratedSuccess(timeClock.id))
            dispatch(showAlertSuccess('Badgeage supprimé'))
            setCancelConfirmation(undefined)
        })
    }

    return <>
        <div className={'row-cols-1'}>
            {bookings.length > 0 ? bookings.map((timeClock, index) => {

                let isLate = timeClock.start && timeClock.planned && new Date(timeClock.planned.start) < new Date(timeClock.start);

                if (cancelConfirmation === timeClock.id){
                    return <div className={'card shadow-sm mb-3 p-1 p-md-3'}>
                        <h4 className={'text-danger'}>
                            Attention !
                        </h4>
                        <p>
                            Vous vous apprétez à archiver un badgeage.
                            <span className="clearfix"> </span>
                            Êtes vous sur de vouloir continuer ?
                        </p>
                        {!canceling ? <div className="btn-group">
                            <button className={'btn btn-danger me-3'} onClick={() => cancelItem(timeClock)}>
                                <i className={'bi bi-dash-circle'}> </i> Archiver
                            </button>
                            <button className={'btn btn-secondary'} onClick={() => setCancelConfirmation(undefined)}>
                                <i className={'bi bi-x-circle'}> </i> Annuler
                            </button>
                        </div> : <div className="alert alert-danger">
                            <div className="spinner-border spinner-border-sm text-danger" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Supression en cours...
                        </div>}
                    </div>
                }

                switch (timeClock.eventType)
                {
                    case "timeClockBookingException":
                    case "timeClockBooking":
                        return <div key={index} className={'card shadow-sm mb-3 p-1 p-md-3'}>
                            <div className="w-100 d-flex align-items-center">
                                <div className="col d-flex align-items-center">
                                    <i className={'bi bi-person me-3'}> </i>
                                    <span>
                                        <h4 className={'mb-1'}>
                                            {timeClock.salary.firstname} {timeClock.salary.lastname}
                                        </h4>
                                        <span className={'text-light-s'}>
                                            {timeClock.salary.information!.job.title}
                                        </span>
                                        <span className="clearfix"> </span>
                                        <span className="form-text">
                                            <i className={'bi bi-calendar-date'}> </i> {timeClock.start ? format(new Date(timeClock.start)) : (timeClock.end ? format(new Date(timeClock.end)) : (timeClock.planned?.start ? format(new Date(timeClock.planned?.start)) : ''))}
                                        </span>
                                    </span>
                                </div>
                                {IsGranted(accessRights.EDIT_TIME_CLOCK) &&  <div className="col-auto">
                                    {timeClock.isAnomaly && <>
                                    {timeClock.exception && timeClock.start && timeClock.end && <button onClick={(e) => {
                                        e.currentTarget.disabled = true;
                                        api.removeAnomaly(timeClock.exception!.id!).then(data => {
                                            dispatch(editTimeClockGeneratedSuccess(timeClock.id, data.data))
                                            dispatch(showAlertSuccess('Anomalie retirée'))
                                            e.currentTarget.disabled = false;
                                        })
                                    }} className={"btn btn-outline-success ms-2"}>
                                        <i className={'bi bi-check'}> </i> Retirer l'anomalie
                                    </button>}
                                    </>}
                                    <button onClick={() => dispatch(openSidebar(TIME_CLOCK_BOOKING_EDIT, {
                                        beginAt: timeClock.start ? new Date(timeClock.start) : null,
                                        endAt: timeClock.end ? new Date(timeClock.end) : null,
                                        salary: timeClock.salary,
                                        company: timeClock.company,
                                        id: timeClock.booking.id,
                                        key: timeClock.id,
                                        next: next,
                                        data: data
                                    }))} className={"btn btn-outline-primary ms-2"}>
                                        <i className={'bi bi-pencil'}> </i> Modifier
                                    </button>
                                    <button onClick={(e) => {
                                        setCancelConfirmation(timeClock.id)
                                    }} className={"btn btn-outline-danger ms-2"}>
                                        <i className={'bi bi-dash-circle'}> </i> Archiver
                                    </button>
                                </div>}
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col-auto flex-grow-0">
                                    <table className={'table mb-0'}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                Planifié
                                            </td>
                                            <td>
                                                {timeClock.planned ? <>
                                                    {format(new Date(timeClock.planned.start), 'HH:mm')}
                                                    <i className={'bi bi-arrow-left-right mx-2'}> </i>
                                                    {format(new Date(timeClock.planned.end), 'HH:mm')}
                                                </> : <i className={"bi bi-x"}> </i>}
                                            </td>
                                            <td>
                                                {timeClock.planned && <><i className={'bi bi-clock me-2'}> </i> {timeClock.planned.timeCaption}</>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Efféctué
                                            </td>
                                            <td>
                                                {(timeClock.start && !timeClock.ignoreStart) ? format(new Date(timeClock.start), 'HH:mm') : <span className={timeClock.booking?.anomalyCode === 2 ? 'text-danger' : 'text-primary'}>{timeClock.booking?.anomalyCode === 2 ? 'Anomalie non badgé' : 'En attente'}</span>}
                                                <i className={'bi bi-arrow-left-right mx-2'}> </i>
                                                {timeClock.end ? format(new Date(timeClock.end), 'HH:mm') : <span className={timeClock.booking?.anomalyCode === 1 ? 'text-danger' : 'text-primary'}>{timeClock.booking?.anomalyCode === 1 ? 'Anomalie non badgé' : 'En attente'}</span>}
                                            </td>
                                            <td>
                                                <i className={'bi bi-clock me-2'}> </i> {timeClock.timeCaption}
                                            </td>
                                        </tr>
                                        {timeClock.isException  && <tr className={'text-light-s'}>
                                            <td>
                                                Avant modification
                                            </td>
                                            <td>
                                                {timeClock.booking.beginAt ? format(new Date(timeClock.booking.beginAt), 'HH:mm') : <span
                                                    className={timeClock.booking?.anomalyCode === 2 ? 'text-danger' : 'text-primary'}>{timeClock.booking?.anomalyCode === 2 ? 'Anomalie non badgé' : 'En attente'}</span>}
                                                <i className={'bi bi-arrow-left-right mx-2'}> </i>
                                                {timeClock.booking.endAt ? format(new Date(timeClock.booking.endAt), 'HH:mm') : <span
                                                    className={timeClock.booking?.anomalyCode === 1 ? 'text-danger' : 'text-primary'}>{timeClock.booking?.anomalyCode === 1 ? 'Anomalie non badgé' : 'En attente'}</span>}
                                            </td>
                                        </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col flex-grow-1"> </div>
                                <div className="col-auto me-3 flex-grow-0 text-end">
                                    {isLate && <>
                                        <span className="text-warning">
                                            <i className={'bi bi-exclamation-triangle'}> </i> Retard
                                        </span>
                                        <div className="clearfix"> </div>
                                    </>}
                                {timeClock.booking?.anomaly && !timeClock.isException &&
                                    <>
                                    <span className="text-danger">
                                        <i className={'bi bi-x-circle'}> </i> Anomalie
                                    </span>
                                        <div className="clearfix"> </div>
                                        <span className={'form-text'}> Veuillez renseigner l'horaire pour retirer l'anomalie</span>
                                    </>}
                                </div>

                            </div>
                        </div>
                    case "timeClockBookingMissing":
                        return <div key={index} className={'card shadow-sm mb-3 p-1 p-md-3'}>
                            <div className="w-100 mb-3 d-flex align-items-center">
                                <div className="col d-flex align-items-center">
                                    <div className="color-circle me-2 float-none col-auto">
                                        <div className="shadow" style={{backgroundColor: `${timeClock.salary.information?.job.color}`, opacity: 0.8}}> </div>
                                    </div>
                                    <div className="d-flex flex-column text-capitalize">
                                        <div>
                                            {timeClock.salary.firstname} {timeClock.salary.lastname}
                                        </div>
                                        <div className={'form-text'}>
                                            {timeClock.salary.information!.job.title}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className={'bi bi-calendar-date'}> </i> {timeClock.start ? format(new Date(timeClock.start)) : (timeClock.end ? format(new Date(timeClock.end)) : (timeClock.planned?.start ? format(new Date(timeClock.planned?.start)) : ''))}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col-auto flex-grow-0">
                                    <table className={'table table-borderless'}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                Planifié
                                            </td>
                                            <td>
                                                {timeClock.planned ? <>
                                                    {format(new Date(timeClock.planned.start), 'HH:mm')}
                                                    <i className={'bi bi-arrow-left-right mx-2'}> </i>
                                                    {format(new Date(timeClock.planned.end), 'HH:mm')}
                                                </> : <i className={"bi bi-x"}> </i>}
                                            </td>
                                            <td>
                                                {timeClock.planned && <><i className={'bi bi-clock me-2'}> </i> {timeClock.timeCaption}</>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Efféctué
                                            </td>
                                            <td>
                                                {timeClock.accountingType === ACCOUNTING_TYPE_WORK_ACCOUNTED && IsGranted(accessRights.EDIT_TIME_CLOCK) ? <button className={'btn btn-sm btn-outline-danger'}
                                                         onClick={() => dispatch(openSidebar(TIME_CLOCK_BOOKING_ADD, {
                                                             beginAt: timeClock.planned!.start,
                                                             endAt: timeClock.planned!.end,
                                                             salary: timeClock.salary,
                                                             company: timeClock.company,
                                                             id: timeClock.id,
                                                             next: next,
                                                             data: data
                                                         }))}
                                                >
                                                    <i className={'bi bi-exclamation-circle me-2'}> </i> Badger
                                                    l'absence
                                                </button> : <i className={'bi bi-x'}> </i>}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col flex-grow-1">

                                </div>
                                {timeClock.accountingType && [ACCOUNTING_TYPE_WORK_ACCOUNTED, ACCOUNTING_TYPE_WORK_UNACCOUNTED].includes(timeClock.accountingType) && <div className="col-auto me-3 flex-grow-0">
                                    <div className="alert alert-danger">
                                        <i className={'bi bi-dash-circle'}> </i> Absent
                                    </div>
                                </div>}
                                {timeClock.accountingType === ACCOUNTING_TYPE_ABSENCE_ACCOUNTED && <div className="col-auto me-3 flex-grow-0">
                                    <div className="alert alert-info">
                                        <i className={'bi bi-dash-circle'}> </i> Absence ajouté au temps de travail
                                    </div>
                                </div>}
                                {timeClock.accountingType === ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED && <div className="col-auto me-3 flex-grow-0">
                                    <div className="alert alert-info">
                                        <i className={'bi bi-dash-circle'}> </i> Absence retiré du temps de travail
                                    </div>
                                </div>}
                            </div>
                        </div>
                    case "timeClockBookingAbsent":
                        return <div key={index} className={'card shadow-sm mb-3 p-1 p-md-3 bg-light'}>
                            <div className="w-100 d-flex align-items-center">
                                <div className="col">
                                    <i className={'bi bi-person'}> </i>  {timeClock.salary.firstname} {timeClock.salary.lastname}
                                </div>
                                <div className="col-auto">
                                    <i className={'bi bi-calendar-date'}> </i> {timeClock.start ? format(new Date(timeClock.start)) : (timeClock.end ? format(new Date(timeClock.end)) : (timeClock.planned?.start ? format(new Date(timeClock.planned?.start)) : ''))}
                                </div>
                            </div>
                            <div className="w-100 p-3 text-center">
                                <h4>
                                    {timeClock.absence!.typeName}
                                </h4>
                            </div>
                        </div>
                }
            }) : (!state.timeClock.isLoading && <>
                <div className="w-100">
                    <div className="p-5">
                        <h3 className={'text-primary'}>
                            <i className={'bi bi-search'}> </i> Aucun résultat
                        </h3>
                    </div>
                </div>
            </>)}
        </div>
    </>
}

export default GeneratedList;