import * as React from "react";

import Dashboard from "../../components/dashboard";

const DashboardPage : React.FC = () => {
    return (
        <Dashboard />
    );
}

export default DashboardPage;