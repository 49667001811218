export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_FAILURE = 'ADD_TEMPLATE_FAILURE';

export const ADD_TEMPLATE_INFORMATION = 'ADD_TEMPLATE_INFORMATION';
export const ADD_TEMPLATE_INFORMATION_SUCCESS = 'ADD_TEMPLATE_INFORMATION_SUCCESS';
export const ADD_TEMPLATE_INFORMATION_FAILURE = 'ADD_TEMPLATE_INFORMATION_FAILURE';

export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_FAILURE = 'EDIT_TEMPLATE_FAILURE';

export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';

export const SHOW_TEMPLATE = 'SHOW_TEMPLATE';
export const SHOW_TEMPLATE_SUCCESS = 'SHOW_TEMPLATE_SUCCESS';
export const SHOW_TEMPLATE_FAILURE = 'SHOW_TEMPLATE_FAILURE';


export const HIDE_TEMPLATE_SUCCESS = 'HIDE_TEMPLATE_SUCCESS';

export const VISIBILITY_PRIVATE = 1;
export const VISIBILITY_PUBLIC = 2;
