import {Task} from "../../../models/task";
import {TaskGroupCustomField, TaskGroupCustomFieldValues} from "../../../models/taskGroup/customField";
import DatePicker from "react-datepicker";
import * as React from "react";
import {Field, FieldConfig, FieldProps, FormikValues} from "formik";
import StyledDropzone from "../../../utils/fileUpload";
import {FormEvent} from "react";
import ColorPicker from "../../../utils/colorPicker";
import {Salaries, Salary} from "../../../models/salary";
import format from "../../../utils/locale";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import DateCustomInput from "../../../utils/customInput";
import SalarySelector from "../../../components/salary/selector";
import * as accessRights from "../../accessRight";

export const ADD_TASK_GROUP_CUSTOM_FIELD = 'ADD_TASK_GROUP_CUSTOM_FIELD';
export const ADD_TASK_GROUP_CUSTOM_FIELD_SUCCESS = 'ADD_TASK_GROUP_CUSTOM_FIELD_SUCCESS';
export const ADD_TASK_GROUP_CUSTOM_FIELD_FAILURE = 'ADD_TASK_GROUP_CUSTOM_FIELD_FAILURE';

export const EDIT_TASK_GROUP_CUSTOM_FIELD = 'EDIT_TASK_GROUP_CUSTOM_FIELD';
export const EDIT_TASK_GROUP_CUSTOM_FIELD_SUCCESS = 'EDIT_TASK_GROUP_CUSTOM_FIELD_SUCCESS';
export const EDIT_TASK_GROUP_CUSTOM_FIELD_FAILURE = 'EDIT_TASK_GROUP_CUSTOM_FIELD_FAILURE';

export const FETCH_TASK_GROUP_CUSTOM_FIELD_CUSTOM_FIELDS = 'FETCH_TASK_GROUP_CUSTOM_FIELD_CUSTOM_FIELDS';
export const FETCH_TASK_GROUP_CUSTOM_FIELD_CUSTOM_FIELDS_SUCCESS = 'FETCH_TASK_GROUP_CUSTOM_FIELD_CUSTOM_FIELDS_SUCCESS';
export const FETCH_TASK_GROUP_CUSTOM_FIELD_CUSTOM_FIELDS_FAILURE = 'FETCH_TASK_GROUP_CUSTOM_FIELD_CUSTOM_FIELDS_FAILURE';

export const SHOW_TASK_GROUP_CUSTOM_FIELD = 'SHOW_TASK_GROUP_CUSTOM_FIELD';
export const SHOW_TASK_GROUP_CUSTOM_FIELD_SUCCESS = 'SHOW_TASK_GROUP_CUSTOM_FIELD_SUCCESS';
export const SHOW_TASK_GROUP_CUSTOM_FIELD_FAILURE = 'SHOW_TASK_GROUP_CUSTOM_FIELD_FAILURE';

interface CustomFieldValueProps{
    customFieldsValues?: TaskGroupCustomFieldValues,
    customField: TaskGroupCustomField
}

export const CustomFieldValue:React.FC<CustomFieldValueProps> = (props) => {

    const {customField, customFieldsValues} = props;

    if (!customFieldsValues) return <span> </span>;

    let customFieldValue = customFieldsValues.find(customFieldValue => customFieldValue && customFieldValue.field.id === customField.id)

    if (!customFieldValue) return <span> </span>;

    switch (customField.type){
        case 1: // COLOR
            return <div className={'w-100 h-100'} style={{
                backgroundColor: customFieldValue.value,
                position: "absolute",
                top: 0,
                left: 0
            }}>  </div>
        case 2: // TEXT
            return <>{customFieldValue.value}</>
        case 3: // NUMBER
            return <>{customFieldValue.value}</>
        case 4: // DATE
            return <>{format(new Date(customFieldValue.value))}</>
        case 5: // FILE
            return <>{customFieldValue.value}</>
        case 6: // SALARIES
            return <ul className={'list-group list-group-flush'}>{customFieldValue.value?.map((salary: Salary) =>
                <li className={'list-group-item'}> {salary.firstname} {salary.lastname} </li>
            )}</ul>
        default :
            return <span> Type de champ invalide </span>
    }
}

interface CustomInputProps {
    customField: TaskGroupCustomField,
    setFieldValue: any,
    values: FormikValues,
}
export const CustomInput:React.FC<CustomInputProps> = (props) => {

    const {customField, setFieldValue, values} = props;

    switch (customField.type){
        case 1: // COLOR
            return <ColorPicker
                onChange={(color: string) => setFieldValue(`customFields.${customField.id}`, color)}
                selected={values.customFields[customField.id!]}
            />
        case 2: // TEXT
            return <textarea
                maxLength={255}
                rows={4}
                onChange={(e) => setFieldValue(`customFields.${customField.id}` , e.target.value)}
                className={'form-control'}
                defaultValue={values.customFields[customField.id!]||''}
            />
        case 3: // NUMBER
            return <>
                <div className="form-floating">
                    <input
                        onChange={(e) => setFieldValue(`customFields.${customField.id}` , e.target.value)}
                        type={'number'}
                        className={'form-control'}
                        defaultValue={values.customFields[customField.id!]||null}
                    />
                    <label htmlFor="">
                        {customField.title}
                    </label>
                </div>
            </>
        case 4: // DATE
            return <DatePicker
                    onChange={(date) => {
                        setFieldValue(`customFields.${customField.id}` , date)
                    }}
                    customInput={<DateCustomInput label={customField.title} />}
                    selected={values.customFields[customField.id!]||new Date()}
                    dateFormat={"E dd MMM uuuu HH:mm"}
                    className={'form-control'}
                    isClearable={true}
                />
        case 5: // FILE
            return <StyledDropzone
                        handleUploadedFile={(files: File[]) => {
                            setFieldValue(`customFields.${customField.id}`, files[0])
                        }}
                        defaultValue={values.customFields[customField.id!]||undefined}
                    />
        case 6: // SALARIES
            return <SalarySelector
                label={customField.title}
                multiple={true}
                onChange={(salaries: Salaries) => setFieldValue(`customFields.${customField.id}`, salaries.map(s => s.id))}
                fetchOptions={true}
                values={customField.fieldValues?.map(fv => fv.value)}
                accessRight={accessRights.EDIT_TASK}
            />
        default :
            return <>Une erreur est survenue</>
    }
}