import * as types from "../../constants/job"
import * as models from "../../models/job"
import replace from "../../utils/arrayReplace"

const job = (state: models.initialStateInterface = models.initialState, action: {type: string, job: models.Job, jobs: models.Jobs, error: string}) => {

    switch (action.type) {
        case types.FETCH_JOBS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_JOBS_SUCCESS:
            return { payload: action.jobs, single: state.single, isLoading: false, error: null };
        case types.FETCH_JOBS_FAILURE:
            return { payload: action.jobs, single: state.single, isLoading: false, error: action.error };

        case types.ADD_JOB:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_JOB_SUCCESS:
            return { payload: [...state.payload, action.job], single: state.single, isLoading: false, error: null };
        case types.ADD_JOB_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        case types.EDIT_JOB:
            return { payload: state.payload , isLoading: true, error: null };
        case types.EDIT_JOB_SUCCESS:
            return { payload: replace(state.payload, action.job), single: action.job, isLoading: false, error: null };
        case types.SHOW_JOB:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_JOB_SUCCESS:
            return { payload: state.payload, single: action.job, isLoading: false, error: null };
        case types.SHOW_JOB_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default job;