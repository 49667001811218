import React from "react";
import OfferAddForm from "../../../event/offer/add/form";
import {Company} from "../../../../models/companies";
import {Activity} from "../../../../models/activity";
import {Localisation} from "../../../../models/localisation";
import {Salary} from "../../../../models/salary";

interface Interface {
    handleSubmit: (ev: any) => any,
    close: () => any,
    start?: Date,
    end?: Date,
    company?: Company,
    activity?: Activity
    salary?: Salary
    localisation?: Localisation
}

const CalendarOfferAdd:React.FC<Interface> = (props) => {

    const {company, activity, localisation, salary, handleSubmit, close, start, end} = props;

    const handleSubmitSuccess = (ev: any) => {
        return Promise.all([
            handleSubmit(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarOfferAddLabel">Ajouter une offre de créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <OfferAddForm
                handleSubmitSuccess={handleSubmitSuccess}
                _company={company}
                activity={activity}
                localisation={localisation}
                salaries={salary ? [salary] : undefined}
                start={start}
                end={end}
            />
        </div>
    </>
}

export default CalendarOfferAdd;