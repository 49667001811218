import React from "react";
import {Modal} from "bootstrap";

const CalendarJobSelector:React.FC<{job: 1|2|0, setJob: (job: 1|2|0) => void;}> = (props) => {

    const {job, setJob} = props;

    const handleClick = (job: 1|2|0) => {
        setJob(job)

        let modalEl = document.getElementById('jobModal') as HTMLDivElement;
        let modal = new Modal(modalEl);
        modal.hide();
    }

    return <div className="modal fade" id="jobModal" tabIndex={-1} aria-labelledby="jobModalLabel"
                aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="jobModalLabel">Métiers</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <ul className={'list-group list-group-flush'}>
                        <li className={'list-group-item list-group-item-action'} onClick={() => handleClick(0)}>
                            {job === 0 && <i className={'bi bi-check text-primary'}></i>} Tous les métiers
                        </li>
                        <li className={'list-group-item list-group-item-action'} onClick={() => handleClick(1)}>
                            {job === 1 && <i className={'bi bi-check text-primary'}></i>} Front office
                        </li>
                        <li className={'list-group-item list-group-item-action'} onClick={() => handleClick(2)}>
                            {job === 2 && <i className={'bi bi-check text-primary'}></i>} Back office
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
}

export default CalendarJobSelector;