import * as React from "react";

import PasswordConfirmation from "../../../components/auth/passwordConfirmation";

const PasswordConfirmationPage : React.FC = () => {
    return (
        <PasswordConfirmation />
    )
}

export default PasswordConfirmationPage;