import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/auth"
import * as api from "../../../adapters/auth";
import {useFormik} from "formik";
import * as Yup from "yup";
import {closeSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";

const Password: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny)  => state.auth);

    const formik = useFormik({
        initialValues: {
            previousPassword: '',
            password: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required(),
            repeatPassword: Yup.string().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            dispatch(actions.editPassword())
            api.password(values).then(data => {
                dispatch(actions.editPasswordSuccess())
                dispatch(showAlertSuccess('Mot de passe mis à jour'))
                dispatch(closeSidebar());
            }).catch(error => {
                dispatch(actions.editPasswordFailure(error.response.data.message))
                formikHelpers.setSubmitting(false);
            })
        },
    });

    return (
        <div className="p-1 p-md-3 w-100">
                {state.error && <div className={"mb-2 alert alert-danger"}>
                    <i className={'bi bi-exclamation-triangle'}> </i> {state.error}
                </div>}
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-floating mb-3">
                        <input
                            id="previousPassword"
                            name="previousPassword"
                            type="password"
                            className="form-control"
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="previousPassword">Mot de passe actuel</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="form-control"
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="password">Mot de passe</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            id="repeatPassword"
                            name="repeatPassword"
                            type="password"
                            className="form-control"
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="repeatPassword">Confirmez le mot de passe</label>
                    </div>

                    <button disabled={formik.isSubmitting} type="submit" className='btn btn-outline-primary w-100'>
                       <i className={'bi bi-check'}> </i> Valider
                    </button>
                </form>
        </div>
    );
}

export default Password;