import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {closeSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import * as api from "../../../../adapters/booking"
import {showAlertSuccess} from "../../../../actions/alert";
import DatePicker from "react-datepicker";
import {setHours, setMinutes} from "date-fns";
import {SingleCompanySelected, SingleCompanySelector} from "../../../../utils/companySelector/single";
import {Company} from "../../../../models/companies";
import {Salary} from "../../../../models/salary";
import {SingleSalarySelected, SingleSalarySelector} from "../../../../utils/salarySelector/single";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import Select from "react-select";
import {refresh, refreshResource} from "../../../../actions/calendar";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {AccountingTypes, getAccountingTypeChoice} from "../../../../constants/booking";


const BookingExceptionEdit: React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const [displayCompanySelector, setDisplayCompanySelector] = useState(false);
    const [displaySalarySelector, setDisplaySalarySelector] = useState(false);
    const [company, setCompany] = useState<Company>();

    const formik = useFormik({
        initialValues: {
            salary: state.rightSidebar.data.e.extendedProps.salary.id,
            beginAt: state.rightSidebar.data.e.start,
            endAt: state.rightSidebar.data.e.end,
            accountingType: state.rightSidebar.data.e.extendedProps.accountingType,
            company: state.rightSidebar.data.e.extendedProps.company.id,
            localisation: state.rightSidebar.data.e.extendedProps.localisation?.id,
            activity: state.rightSidebar.data.e.extendedProps.activity?.id,
        },
        validationSchema: Yup.object().shape({
            salary: Yup.number().required(),
            beginAt: Yup.date().required(),
            endAt: Yup.date().required(),
            company: Yup.number().required(),
            localisation: Yup.number().nullable(),
            activity: Yup.number().nullable(),
            accountingType: Yup.number().required(),
        }),
        onSubmit: (values) => {
            dispatch(submitSidebar());
            api.editException(values, state.rightSidebar.data.e.extendedProps.instanceId).then((data) => {
                if (state.rightSidebar.data.resourceId){
                    dispatch(refreshResource(state.rightSidebar.data.resourceId, state.rightSidebar.data.parentId, state.rightSidebar.data.salary))
                }else{
                    dispatch(refresh());
                }
                dispatch(showAlertSuccess('Créneau modifié'))
                dispatch(closeSidebar())
            })
        }
    })


    return (
        <form onSubmit={formik.handleSubmit} className={'py-3'}>
            <div className="col-12 mb-3">
                {displaySalarySelector && <SingleSalarySelector
                    onSubmit={(salary: Salary) => {
                        formik.setFieldValue('salary', salary.id)
                        setDisplaySalarySelector(false)
                    }}
                    onClose={() => setDisplaySalarySelector(false)}
                    initialValue={formik.values.salary}
                />}
                <SingleSalarySelected
                    onDelete={() => formik.setFieldValue('salary', null)}
                    onOpen={() => setDisplaySalarySelector(true)}
                    initialValue={formik.values.salary}
                />
            </div>
            <div className="col-md-12 mb-3">
                <label htmlFor="">
                    Horaire
                </label>
                <div className="input-group">
                    <DatePicker
                        name={'beginAt'}
                        id={'beginAt'}
                        className={'form-control col-auto'}
                        selected={formik.values.beginAt}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`beginAt`, date)
                        }}
                        selectsStart
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                    <span className="input-group-text"> à </span>
                    <DatePicker
                        name={'endAt'}
                        id={'endAt'}
                        className={'form-control col-auto'}
                        selected={formik.values.endAt}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`endAt`, date)
                        }}
                        selectsEnd
                        startDate={formik.values.beginAt}
                        endDate={formik.values.endAt}
                        minDate={formik.values.beginAt}
                        minTime={setHours(setMinutes(new Date(),
                            new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getMinutes() : 0
                            ),
                            new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getHours() : 0
                        )}
                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de fin"
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                </div>
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    label={"Type d'heure"}
                    value={getAccountingTypeChoice(formik.values.accountingType)}
                    options={AccountingTypes}
                    onChange={(choice) => formik.setFieldValue('accountingType', choice!.value)}
                    required={true}
                />
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="">
                    Entreprise
                </label>
                {displayCompanySelector && <SingleCompanySelector
                    onSubmit={(company: Company) => {
                        formik.setFieldValue('company', company.id)
                        setDisplayCompanySelector(false)
                    }}
                    onClose={() => setDisplayCompanySelector(false)}
                    initialValue={formik.values.company}
                />}
                <SingleCompanySelected
                    onDelete={() => formik.setFieldValue('company', null)}
                    onOpen={() => setDisplayCompanySelector(true)}
                    initialValue={formik.values.company}
                    onShow={(company: Company) => setCompany(company)}
                />
            </div>
            <div className="col-12 mb-3">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="">
                            Localisation
                        </label>
                        <Select
                            onChange={(choice) => formik.setFieldValue(`localisation`, choice?.value)}
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={{label: state.rightSidebar.data.e.extendedProps.localisation?.title, value: state.rightSidebar.data.e.extendedProps.localisation?.id}}
                            options={company?.localisations?.map((localisation: Localisation) => {return {label: localisation.title, value: localisation.id}})}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="">
                            Activité
                        </label>
                        <Select
                            onChange={(choice) => formik.setFieldValue(`activity`, choice?.value)}
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={{label: state.rightSidebar.data.e.extendedProps.activity?.title, value: state.rightSidebar.data.e.extendedProps.activity?.id}}
                            options={company?.activities?.map((activity: Activity) => {return {label: activity.title, value: activity.id}})}
                        />
                    </div>
                </div>
            </div>
            <button className={'btn btn-outline-primary w-100'} type={'submit'} >
                Enregistrer
            </button>
            {/*{JSON.stringify(formik.values, null, 2)}*/}
        </form>
    )

}

export default BookingExceptionEdit;