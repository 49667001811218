import './index.scss';
import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector, useStore} from "react-redux";
import * as formTypes from "../../constants/rightSidebar"
import JobAdd from "../job/add";
import {Offcanvas} from "bootstrap";
import Loader from "../../utils/loader";
import {closeSidebar, openSidebar} from "../../actions/rightSidebar";
import CompaniesAdd from "../companies/add";
import AccessLevelAdd from "../accessLevel/add";
import AbsenceAdd from "../absence/add";
import AbsenceEdit from "../absence/edit";
import LocalisationAdd from "../localisation/add";
import LocalisationEdit from "../localisation/edit";
import SalaryAdd from "../salary/add";
import SalaryInformationAdd from "../salary/information/add";
import BookingAdd from "../booking/add";
import SalaryEdit from "../salary/edit";
import SalaryInformationEdit from "../salary/information/edit";
import TaskGroupAdd from "../taskGroup/add";
import TaskAdd from "../task/add";
import TaskGroupCustomFieldAdd from "../taskGroup/customField/add";
import TemplateAdd from "../template/add";
import TemplateResourceAdd from "../template/resource/add";
import TemplateBookingAdd from "../template/booking/add";
import ProfileInformationAdd from "../profile/information/add";
import ChannelAdd from "../channel/add";
import {CompanySelector} from "../../utils/companySelector";
import JobEdit from "../job/edit";
import CalendarDownload from "../calendar/download";
import LocalisationSalariesAdd from "../localisation/show/salaries/add";
import BookingExceptionEdit from "../booking/exception/edit";
import BookingSubstitute from "../booking/substitute";
import FileAdd from "../file/add";
import TaskEdit from "../task/edit";
import CalendarSettings from "../calendar/setting";
import TemplateResourceEdit from "../template/resource/edit";
import TemplateSidebarList from "../template/sidebarList";
import TemplateAppliedEdit from "../template/applied/edit";
import TimeClockSidebarList from "../timeClock/sidebarList";
import AbsenceSidebarList from "../absence/sidebarList";
import TimeClockBookingAdd from "../timeClock/booking/add";
import BookingRecurrenceEdit from "../booking/edit/recurrence";
import CounterEdit from "../counter/edit";
import KeepNoteCreate from "../keepNote/create";
import KeepNoteEdit from "../keepNote/edit";
import KeepNoteList from "../keepNote/list";
import DashboardFeedSidebar from "../dashboard/feed/sidebar";
import CalendarNeedEvents from "../calendar/needEvents";
import TemplateAddFromCalendar from "../template/addFromCalendar";
import TemplateList from "../template/dropdownList";
import TemplateEdit from "../template/edit";
import LocalisationNeedAdd from "../localisation/show/need/add";
import PlannerForm from "../planner/form";
import CounterSettings from "../counter/settings";
import SalaryImport from "../salary/import";
import CompanyEdit from "../companies/edit";
import ReportingShowWeek from "../reporting/showWeek";
import TimeClockBookingEdit from "../timeClock/booking/edit";
import ExportReporting from "../reporting/download";
import Password from "../auth/password";
import BookingGroupAdd from "../booking/group/add";
import BookingGroupList from "../booking/group/list";
import BookingGroupEdit from "../booking/group/edit";
import ActivityAdd from "../activity/add";
import ActivityEdit from "../activity/edit";
import ActivityNeedAdd from "../activity/show/need/add";
import BookingDefaultAdd from "../booking/default/add";
import BookingDefaultSidebarList from "../booking/default/sidebarList";
import BookingDefaultEdit from "../booking/default/edit";
import TeamAdd from "../team/add";
import TeamEdit from "../team/edit";
import TeamShowSalarySelector from "../team/show/salary/selector";
import BookingExceptionHistory from "../booking/exception/history";
import BookingOfferAdd from "../booking/offer/add";
import BookingOfferSidebarList from "../booking/offer/sidebarList";
import BookingOfferApplicantSidebarList from "../booking/offer/sidebarList/applicant";
import BookingOfferSidebarShow from "../booking/offer/sidebarShow";
import TeamAddManager from "../team/show/manager/add";
import TeamEditManager from "../team/show/manager/edit";
import CounterSidebar from "../counter/sidebar";
import CalendarResourceOrder from "../calendar/resource/order";
import TimeCLockBookingShow from "../timeClock/booking/show";
import CounterShow from "../counter/show";
import CounterWithdrawnAdd from "../counter/withdrawn/add";
import CounterWithdrawnEdit from "../counter/withdrawn/edit";
import TimeClockConfig from "../companyGroup/settings/timeClock";
import ModuleConfig from "../companyGroup/settings/module";
import GeneralConfig from "../companyGroup/settings/general";
import EmailSettings from "../companyGroup/settings/email";
import AccountingConfig from "../companyGroup/settings/accounting";
import AbsenceConfig from "../companyGroup/settings/absence";
import TemplateAppliedExceptionAdd from "../template/applied/exception/add";
import TemplateAppliedExceptionEdit from "../template/applied/exception/edit";
import TemplateBookingEdit from "../template/booking/edit";
import BookingEditTest from "../booking/edit/test";
import BookingGroupLoadEdit from "../booking/group/edit/reload";
import ReportingShowWeekLoad from "../reporting/showWeekLoad";
import SalaryInformationDefaultLabelSelector from "../salary/show/information/defaultLabelSelector";
import SlotExchangeAdd from "../slotExchange/add";
import SlotExchangeAddValidation from "../slotExchange/add/validate";
import BookingGroupAddFromBookingForm from "../booking/add/group";
import BookingCancel from "../booking/cancel";
import SalaryTemplateList from "../salary/show/template/list";
import SalaryTemplateShow from "../salary/show/template/show";
import SalaryTemplateCopy from "../salary/show/template/copy";
import SalaryTemplateDuplicate from "../salary/show/template/duplicate";
import EventAdd from "../event/add";
import EventExceptionEdit from "../event/exception/edit";
import EventExceptionAdd from "../event/exception/add";
import EventCancel from "../event/cancel";
import EventExchangeAddValidation from "../eventExchange/add/validate";
import EventExchangeAdd from "../eventExchange/add";
import EventOfferAdd from "../event/offer/add";
import EventOfferEdit from "../event/offer/edit";
import EventOfferSidebarList from "../event/offer/sidebarList";
import EventOfferSidebarShow from "../event/offer/sidebarShow";
import EventOfferApplicationSidebarList from "../event/offer/sidebarList/applicant";
import TaskGroupEdit from "../taskGroup/edit";
import AbsenceFileAdd from "../absence/file";
import SalaryDocumentStockedAdd from "../salary/show/storage/bucket/add";
import ExpenseAdd from "../expense/add";
import ExpenseFileAdd from "../expense/file";
import ExpenseEdit from "../expense/edit";
import SalaryManagerAdd from "../salary/show/manageur/add";
import SalaryManagerEdit from "../salary/show/manageur/edit";
import SynthesisSignatureLoad from "../synthesis/signatureLoad";

interface RightSidebarProps {
    [key: string]: any
}

export const RightSidebar: React.FC = (props: RightSidebarProps) => {

    const data = useSelector((state: RootStateOrAny)  => state.rightSidebar.data);
    const isLoading = useSelector((state: RootStateOrAny)  => state.rightSidebar.isLoading);
    const show = useSelector((state: RootStateOrAny)  => state.rightSidebar.show);
    const item = useSelector((state: RootStateOrAny)  => state.rightSidebar.item);
    const _props = useSelector((state: RootStateOrAny)  => state.rightSidebar.props);
    const dispatch = useDispatch();
    const [offcanvas, setOffcanvas] = useState<Offcanvas>();

    useEffect(() => {
        let elem = document.getElementById('offcanvasRight')!
        elem.addEventListener('hidden.bs.offcanvas', onCloseSidebarEvent)
        window.addEventListener('hashchange', closeAndDispatch)

        if (!offcanvas){
            initOffcanvas()
        }

            if (show){
                open();
            }else if (!show){
                close();
            }

            if (show && item)
                CurrentForm();

        return () => {
            elem.removeEventListener('hidden.bs.offcanvas', onCloseSidebarEvent)
            window.removeEventListener('hashchange', closeAndDispatch)
        }
    }, [show])

    const initOffcanvas = () => {
        let elem = document.getElementById('offcanvasRight')!
        setOffcanvas(new Offcanvas(elem));
    }

    const onCloseSidebarEvent = () => {
        dispatch(closeSidebar());
    }

    const close = () => {
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const closeAndDispatch = () => {
        if (show){
            dispatch(closeSidebar())
        }
    }

    const open = () => {
        if (!offcanvas) return;
        let elem = document.getElementById('offcanvasRight')!
        offcanvas.toggle(elem)
    }

    function CurrentForm () {
        switch (item){
            case formTypes.FORM_NEW_JOB:
                return <JobAdd/>
            case formTypes.FORM_EDIT_JOB:
                return <JobEdit/>
            case formTypes.FORM_NEW_COMPANY:
                return <CompaniesAdd/>
            case formTypes.FORM_EDIT_COMPANY:
                return <CompanyEdit />
            case formTypes.FORM_NEW_ACCESS_LEVEL:
                return <AccessLevelAdd/>
            case formTypes.FORM_EDIT_ACCESS_LEVEL:
                return <AccessLevelAdd/>
            case formTypes.FORM_NEW_ABSENCE:
                return <AbsenceAdd />
            case formTypes.FORM_EDIT_ABSENCE:
                return <AbsenceEdit />
            case formTypes.FORM_NEW_LOCALISATION:
                return <LocalisationAdd />
            case formTypes.FORM_NEW_ACTIVITY:
                return <ActivityAdd />
            case formTypes.FORM_EDIT_LOCALISATION:
                return <LocalisationEdit />
            case formTypes.FORM_EDIT_ACTIVITY:
                return <ActivityEdit />
            case formTypes.FORM_NEW_SALARY:
                return <SalaryAdd />
            case formTypes.FORM_EDIT_SALARY:
                return <SalaryEdit />
            case formTypes.FORM_NEW_SALARY_INFORMATION:
                return <SalaryInformationAdd />
            case formTypes.FORM_EDIT_SALARY_INFORMATION:
                return <SalaryInformationEdit />
            case formTypes.FORM_NEW_BOOKING:
                return <BookingAdd />
            case formTypes.FORM_EDIT_BOOKING:
                return <BookingEditTest />
            case formTypes.FORM_EDIT_BOOKING_RECURRENCE:
                return <BookingRecurrenceEdit />
            case formTypes.FORM_EDIT_BOOKING_EXCEPTION:
                return <BookingExceptionEdit />
            case formTypes.FORM_EDIT_BOOKING_SUBSTITUTE:
                return <BookingSubstitute />
            case formTypes.FORM_NEW_TASK_GROUP:
                return <TaskGroupAdd />
            case formTypes.FORM_EDIT_TASK_GROUP:
                return <TaskGroupEdit />
            case formTypes.FORM_NEW_TASK:
                return <TaskAdd />
            case formTypes.FORM_EDIT_TASK:
                return <TaskEdit />
            case formTypes.FORM_NEW_TASK_GROUP_CUSTOM_FIELD:
                return <TaskGroupCustomFieldAdd />
            case formTypes.FORM_EDIT_TASK_GROUP_CUSTOM_FIELD:
                return <TaskGroupCustomFieldAdd />
            case formTypes.FORM_NEW_TEMPLATE:
                return <TemplateAdd />
            case formTypes.FORM_EDIT_TEMPLATE:
                return <TemplateEdit />
            case formTypes.FORM_NEW_TEMPLATE_RESOURCE:
                return <TemplateResourceAdd />
            case formTypes.FORM_EDIT_TEMPLATE_RESOURCE:
                return <TemplateResourceEdit />
            case formTypes.FORM_NEW_TEMPLATE_BOOKING:
                return <TemplateBookingAdd />
            case formTypes.FORM_EDIT_TEMPLATE_BOOKING:
                return <TemplateBookingEdit />
            case formTypes.FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION:
                return <TemplateAppliedExceptionAdd />
            case formTypes.FORM_EDIT_TEMPLATE_APPLIED_BOOKING_EXCEPTION:
                return <TemplateAppliedExceptionEdit />
            case formTypes.FORM_NEW_USER_INFORMATION:
                return <ProfileInformationAdd />
            case formTypes.FORM_EDIT_USER_INFORMATION:
                return <ProfileInformationAdd />
            case formTypes.FORM_NEW_CHANNEL:
                return <ChannelAdd />
            case formTypes.FORM_EDIT_CHANNEL:
                return <ChannelAdd />
            case formTypes.FORM_COMPANY_SELECTOR:
                return <CompanySelector {..._props} />
            case formTypes.FORM_CALENDAR_DOWNLOAD:
                return <CalendarDownload />
            case formTypes.FORM_LOCALISATION_SALARIES:
                return <LocalisationSalariesAdd />
            case formTypes.FORM_FILE_ADD:
                return <FileAdd />
            case formTypes.FORM_CALENDAR_SETTINGS:
                return <CalendarSettings />
            case formTypes.LIST_TEMPLATES_APPLY:
                return <TemplateSidebarList />
            case formTypes.TEMPLATES_APPLY_EDIT:
                return <TemplateAppliedEdit />
            case formTypes.TIME_CLOCK_LIST:
                return <TimeClockSidebarList />
            case formTypes.PENDING_ABSENCE_LIST:
                return <AbsenceSidebarList />
            case formTypes.TIME_CLOCK_BOOKING_ADD:
                return <TimeClockBookingAdd />
            case formTypes.TIME_CLOCK_BOOKING_EDIT:
                return <TimeClockBookingEdit />
            case formTypes.FORM_COUNTER_EDIT:
                return <CounterEdit />
            case formTypes.FORM_COUNTER_SHOW:
                return <CounterShow />
            case formTypes.FORM_KEEP_NOTE_ADD:
                return <KeepNoteCreate />
            case formTypes.FORM_KEEP_NOTE_EDIT:
                return <KeepNoteEdit />
            case formTypes.FORM_KEEP_NOTE_LIST:
                return <KeepNoteList />
            case formTypes.FEED_LIST:
                return <DashboardFeedSidebar />
            case formTypes.NEED_LIST:
                return <CalendarNeedEvents />
            case formTypes.FORM_NEW_TEMPLATE_FROM_CALENDAR:
                return <TemplateAddFromCalendar />
            case formTypes.TEMPLATE_LIST:
                return <TemplateList />
            case formTypes.LOCALISATION_NEED_ADD:
                return <LocalisationNeedAdd />
            case formTypes.ACTIVITY_NEED_ADD:
                return <ActivityNeedAdd />
            case formTypes.PLANNER_FORM:
                return <PlannerForm />
            case formTypes.COUNTER_SETTINGS:
                return <CounterSettings />
            case formTypes.SALARY_IMPORT:
                return <SalaryImport />
            case formTypes.REPORTING_SHOW_WEEK:
                return <ReportingShowWeek />
            case formTypes.REPORTING_SHOW_WEEK_LOAD:
                return <SynthesisSignatureLoad />
            // case formTypes.REPORTING_SHOW_WEEK_LOAD:
            //     return <ReportingShowWeekLoad />
            case formTypes.FORM_EXPORT_REPORTING:
                return <ExportReporting />
            case formTypes.EDIT_PASSWORD_FORM:
                return <Password />
            case formTypes.FORM_NEW_BOOKING_GROUP:
                return <BookingGroupAdd />
            case formTypes.FORM_EDIT_BOOKING_GROUP:
                return <BookingGroupEdit />
            case formTypes.FORM_EDIT_BOOKING_GROUP_RELOAD:
                return <BookingGroupLoadEdit />
            case formTypes.LIST_BOOKING_GROUP:
                return <BookingGroupList />
            case formTypes.FORM_NEW_DEFAULT_BOOKING:
                return <BookingDefaultAdd />
            case formTypes.FORM_EDIT_DEFAULT_BOOKING:
                return <BookingDefaultEdit />
            case formTypes.LIST_DEFAULT_BOOKING:
                return <BookingDefaultSidebarList />
            case formTypes.FORM_NEW_TEAM:
                return <TeamAdd />
            case formTypes.FORM_EDIT_TEAM:
                return <TeamEdit />
            case formTypes.FORM_TEAM_SALARY:
                return <TeamShowSalarySelector />
            case formTypes.FORM_TEAM_ADD_MANAGER:
                return <TeamAddManager />
            case formTypes.FORM_TEAM_EDIT_MANAGER:
                return <TeamEditManager />
            case formTypes.BOOKING_HISTORY:
                return <BookingExceptionHistory />
            case formTypes.FORM_NEW_BOOKING_OFFER:
                return <BookingOfferAdd />
            case formTypes.FORM_NEW_EVENT_OFFER:
                return <EventOfferAdd />
            case formTypes.FORM_EDIT_EVENT_OFFER:
                return <EventOfferEdit />
            case formTypes.LIST_BOOKING_OFFER:
                return <BookingOfferSidebarList />
            case formTypes.LIST_EVENT_OFFER:
                return <EventOfferSidebarList />
            case formTypes.SHOW_BOOKING_OFFER:
                return <BookingOfferSidebarShow />
            case formTypes.SHOW_EVENT_OFFER:
                return <EventOfferSidebarShow />
            case formTypes.LIST_BOOKING_OFFER_APPLICANTS:
                return <BookingOfferApplicantSidebarList />
            case formTypes.LIST_EVENT_OFFER_APPLICANTS:
                return <EventOfferApplicationSidebarList />
            case formTypes.COUNTER_SIDEBAR:
                return <CounterSidebar {..._props} />
            case formTypes.FORM_CALENDAR_RESOURCE_ORDER:
                return <CalendarResourceOrder />
            case formTypes.TIME_CLOCK_SHOW:
                return <TimeCLockBookingShow />
            case formTypes.FORM_COUNTER_WITHDRAWN_ADD:
                return <CounterWithdrawnAdd />
            case formTypes.FORM_COUNTER_WITHDRAWN_EDIT:
                return <CounterWithdrawnEdit />
            case formTypes.ABSENCE_SETTINGS:
                return <AbsenceConfig />
            case formTypes.ACCOUNTING_SETTINGS:
                return <AccountingConfig />
            case formTypes.EMAIL_SETTINGS:
                return <EmailSettings />
            case formTypes.GENERAL_SETTINGS:
                return <GeneralConfig />
            case formTypes.MODULE_SETTINGS:
                return <ModuleConfig />
            case formTypes.TIME_CLOCK_SETTINGS:
                return <TimeClockConfig />
            case formTypes.SALARY_DEFAULT_LABEL_SELECTOR:
                return <SalaryInformationDefaultLabelSelector />
            case formTypes.FORM_NEW_EVENT_EXCHANGE:
                return <EventExchangeAdd />
            case formTypes.FORM_NEW_SLOT_EXCHANGE:
                return <SlotExchangeAdd />
            case formTypes.FORM_NEW_SLOT_EXCHANGE_VALIDATE:
                return <SlotExchangeAddValidation />
            case formTypes.FORM_NEW_EVENT_EXCHANGE_VALIDATE:
                return <EventExchangeAddValidation />
            case formTypes.FORM_NEW_BOOKING_GROUP_FROM_BOOKING:
                return <BookingGroupAddFromBookingForm />
            case formTypes.BOOKING_CANCEL:
                return <BookingCancel />
            // case formTypes.FORM_NEW_SALARY_TEMPLATE:
            //     return <SalaryTemplateAdd />
            case formTypes.LIST_SALARY_TEMPLATE:
                return <SalaryTemplateList />
            case formTypes.SHOW_SALARY_TEMPLATE:
                return <SalaryTemplateShow />
            case formTypes.COPY_SALARY_TEMPLATE:
                return <SalaryTemplateCopy />
            case formTypes.DUPLICATE_SALARY_TEMPLATE:
                return <SalaryTemplateDuplicate />
            case formTypes.FORM_EVENT_ADD:
                return <EventAdd />
            case formTypes.FORM_EVENT_EXCEPTION_EDIT:
                return <EventExceptionEdit />
            case formTypes.FORM_EVENT_EXCEPTION_ADD:
                return <EventExceptionAdd />
            case formTypes.FORM_EVENT_CANCEL:
                return <EventCancel />
            case formTypes.FORM_NEW_ABSENCE_FILE:
                return <AbsenceFileAdd />
            case formTypes.FORM_NEW_SALARY_STOCKED_DOCUMENT:
                return <SalaryDocumentStockedAdd />
            case formTypes.FORM_EXPENSE_ADD:
                return <ExpenseAdd />
            case formTypes.FORM_EXPENSE_EDIT:
                return <ExpenseEdit />
            case formTypes.FORM_NEW_EXPENSE_FILE:
                return <ExpenseFileAdd />
            case formTypes.FORM_MANAGER_ADD:
                return <SalaryManagerAdd />
            case formTypes.FORM_MANAGER_EDIT:
                return <SalaryManagerEdit />
            default:
                return <Loader />
        }
    }

    const CurrentTitle = () => {

        let title: any = null;
        switch (item){
            case formTypes.FORM_NEW_JOB:
                title = <>Ajouter un métier </>
                break;
            case formTypes.FORM_EDIT_JOB:
                title = "Modifier un métier"
                break;
            case formTypes.FORM_NEW_COMPANY:
                title = <>Ajouter un point de vente </>
                break;
            case formTypes.FORM_EDIT_COMPANY:
                title = <>Modifier un point de vente </>
                break;
            case formTypes.FORM_NEW_ACCESS_LEVEL:
                title = <>Ajouter un rôle </>
                break;
            case formTypes.FORM_EDIT_ACCESS_LEVEL:
                title = <>Modifier un rôle </>
                break;
            case formTypes.FORM_NEW_ABSENCE:
                title = "Ajouter une absence"
                break;
            case formTypes.FORM_EDIT_ABSENCE:
                title = "Modifier une absence"
                break;
            case formTypes.FORM_NEW_LOCALISATION:
                title = "Ajouter un emplacement"
                break;
            case formTypes.FORM_EDIT_LOCALISATION:
                title = "Modifier un emplacement"
                break;
            case formTypes.FORM_EDIT_ACTIVITY:
                title = "Modifier une activité"
                break;
            case formTypes.FORM_EDIT_SALARY:
                title = "Modifier un collaborateur"
                break;
            case formTypes.FORM_NEW_SALARY_INFORMATION:
            case formTypes.FORM_NEW_SALARY:
                title = <> Ajouter un collaborateur </>
                break;
            case formTypes.FORM_EDIT_SALARY_INFORMATION:
                title = "Modifier un collaborateur"
                break;
            case formTypes.FORM_NEW_BOOKING:
                title = <> Ajouter un créneau </>
                break;
            case formTypes.FORM_EDIT_BOOKING_SUBSTITUTE:
                title = "Remplacer un collaborateur";
                break;
            case formTypes.FORM_EDIT_BOOKING_EXCEPTION:
            case formTypes.FORM_EDIT_BOOKING:
            case formTypes.FORM_EDIT_BOOKING_RECURRENCE:
                title = "Modifier un créneau"
                break;
            case formTypes.FORM_NEW_TASK_GROUP:
                title = <> <i className="bi bi-journal-plus"> </i> Ajouter un groupe de tâche </>
                break;
            case formTypes.FORM_EDIT_TASK_GROUP:
                title = "Modifier un groupe de tâche"
                break;
            case formTypes.FORM_NEW_TASK:
                title = <> <i className="bi bi-journal-plus"> </i> Ajouter une tâche </>
                break;
            case formTypes.FORM_EDIT_TASK:
                title = "Modifier une tâche"
                break;
            case formTypes.FORM_NEW_TASK_GROUP_CUSTOM_FIELD:
                title = <> <i className="bi bi-journal-richtext"> </i> Ajouter un champ personnalisé </>
                break;
            case formTypes.FORM_EDIT_TASK_GROUP_CUSTOM_FIELD:
                title = <> <i className="bi bi-journal-richtext"> </i> Modifier un champ personnalisé </>
                break;
            case formTypes.FORM_NEW_TEMPLATE:
                title = <> <i className="bi bi-front"> </i> Ajouter un template </>
                break;
            case formTypes.FORM_EDIT_TEMPLATE:
                title = <> <i className="bi bi-pencil-square"> </i> Modifier un template </>
                break;
            case formTypes.FORM_NEW_TEMPLATE_RESOURCE:
                title = <> <i className="bi bi-person-plus"> </i> Ajouter une ressource </>
                break;
            case formTypes.FORM_EDIT_TEMPLATE_RESOURCE:
                title = <> <i className="bi bi-person-square"> </i> Modifier une ressource </>
                break;
            case formTypes.FORM_NEW_TEMPLATE_BOOKING:
                title = <> <i className="bi bi-calendar-plus"> </i> Ajouter un créneau </>
                break;
            case formTypes.FORM_EDIT_TEMPLATE_BOOKING:
                title = <> <i className="bi bi-calendar4-event"> </i> Modifier un créneau </>
                break;
            case formTypes.FORM_NEW_CHANNEL:
                title = <> <i className="bi bi-plus-lg"> </i> Nouvelle discussion </>
                break;
            case formTypes.FORM_EDIT_CHANNEL:
                title = <> <i className="bi bi-pencil-square"> </i> Modifier une discussion </>
                break;
            case formTypes.FORM_CALENDAR_DOWNLOAD:
                title = <> <i className="bi bi-download"> </i> Télécharger le planning </>
                break;
            case formTypes.FORM_LOCALISATION_SALARIES:
                title = "Gérer l'effectif"
                break;
            case formTypes.FORM_FILE_ADD:
                title = <> <i className="bi bi-file-plus"> </i> Ajouter un fichier </>
                break;
            case formTypes.FORM_CALENDAR_SETTINGS:
                title = <> <i className="bi bi-gear"> </i> Parametres du planning </>
                break;
            case formTypes.FORM_CALENDAR_FILTERS:
                title = <> <i className="bi bi-filter"> </i> Filtres </>
                break;
            case formTypes.LIST_TEMPLATES_APPLY:
                title = <> <i className="bi bi-calendar2-check"> </i> Appliquer un template </>
                break;
            case formTypes.TEMPLATES_APPLY_EDIT:
                title = <> <i className="bi bi-calendar2-check"> </i> Modifier un template appliqué </>
                break;
            case formTypes.PENDING_ABSENCE_LIST:
                title = <> <i className="bi bi-calendar-x"> </i> Absences en attente </>
                break;
            case formTypes.TIME_CLOCK_BOOKING_ADD:
                title = <> <i className="bi bi-calendar-check"> </i> Ajouter un badgeage </>
                break;
            case formTypes.FORM_COUNTER_EDIT:
                title = <>Modifier un compteur</>
                break;
            case formTypes.FORM_KEEP_NOTE_ADD:
                title = <>Ajouter une note</>
                break;
            case formTypes.FORM_KEEP_NOTE_EDIT:
                title = <>Modifier une note</>
                break;
            case formTypes.FORM_KEEP_NOTE_LIST:
                title = <>Notes</>
                break;
            case formTypes.FEED_LIST:
                title = <>Actualités</>
                break;
            case formTypes.NEED_LIST:
                title = <>Besoins présence</>
                break;
            case formTypes.FORM_NEW_TEMPLATE_FROM_CALENDAR:
                title = <>Créer un template depuis le planning</>
                break;
            case formTypes.TEMPLATE_LIST:
                title = <>Templates</>
                break;
            case formTypes.ACTIVITY_NEED_ADD:
            case formTypes.LOCALISATION_NEED_ADD:
                title = <>Ajouter un besoin</>
                break;
            case formTypes.PLANNER_FORM:
                title = <>Générer un planning</>
                break;
            case formTypes.COUNTER_SETTINGS:
                title = <>Réglages</>
                break;
            case formTypes.SALARY_IMPORT:
                title = <>Importer des collaborateurs</>
                break;
            case formTypes.TIME_CLOCK_BOOKING_EDIT:
                title = <>Modifier un badgeage</>
                break;
            case formTypes.REPORTING_SHOW_WEEK_LOAD:
            case formTypes.REPORTING_SHOW_WEEK:
                title = <>Détail semaine</>
                break;
            case formTypes.FORM_EXPORT_REPORTING:
                title = <>Exporter les rapports</>
                break;
            case formTypes.EDIT_PASSWORD_FORM:
                title = <>Modifier mon mot de passe</>
                break;
            case formTypes.FORM_NEW_BOOKING_GROUP:
                title = <>Ajouter un emploi du temps</>
                break;
            case formTypes.FORM_EDIT_BOOKING_GROUP:
            case formTypes.FORM_EDIT_BOOKING_GROUP_RELOAD:
                title = <>Modifier un emploi du temps</>
                break;
            case formTypes.LIST_BOOKING_GROUP:
                title = <>Emploi du temps</>
                break;
            case formTypes.FORM_NEW_DEFAULT_BOOKING:
                title = <>Ajouter un créneau type</>
                break;
            case formTypes.FORM_EDIT_DEFAULT_BOOKING:
                title = <>Modifier un créneau type</>
                break;
            case formTypes.LIST_DEFAULT_BOOKING:
                title = <>Créneaux type</>
                break;
            case formTypes.FORM_NEW_TEAM:
                title = <>Ajouter une équipe</>
                break;
            case formTypes.FORM_EDIT_TEAM:
                title = <>Modifier une équipe</>
                break;
            case formTypes.FORM_TEAM_SALARY:
                title = <>Collaborateurs</>
                break;
            case formTypes.BOOKING_HISTORY:
                title = <>Historique des modifications</>
                break;
            case formTypes.FORM_NEW_EVENT_OFFER:
            case formTypes.FORM_NEW_BOOKING_OFFER:
                title = <>Ajouter une proposition de créneau</>
                break;
            case formTypes.LIST_EVENT_OFFER:
            case formTypes.LIST_BOOKING_OFFER:
                title = <>Propositions de créneaux</>
                break;
            case formTypes.SHOW_EVENT_OFFER:
            case formTypes.SHOW_BOOKING_OFFER:
                title = <>Proposition de créneau</>
                break;
            case formTypes.LIST_EVENT_OFFER_APPLICANTS:
            case formTypes.LIST_BOOKING_OFFER_APPLICANTS:
                title = <>Participants à la proposition de créneau</>
                break;
            case formTypes.FORM_TEAM_ADD_MANAGER:
                title = <>Ajouter un manageur</>
                break;
            case formTypes.FORM_TEAM_EDIT_MANAGER:
                title = <>Modifier un manageur</>
                break;
            case formTypes.COUNTER_SIDEBAR:
                title = <>Compteurs</>
                break;
            case formTypes.FORM_CALENDAR_RESOURCE_ORDER:
                title = <>Ordoner le planning</>
                break;
            case formTypes.FORM_COUNTER_WITHDRAWN_ADD:
                title = <>Ajouter un retrait d'heure au compteur</>
                break;
            case formTypes.FORM_COUNTER_WITHDRAWN_EDIT:
                title = <>Modifier un retrait d'heure au compteur</>
                break;
            case formTypes.FORM_COUNTER_SHOW:
                title = <>Compteur</>
                break;
            case formTypes.FORM_EDIT_TEMPLATE_APPLIED_BOOKING_EXCEPTION:
            case formTypes.FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION:
                title = <>Modifier un créneau de template</>
                break;
            case formTypes.FORM_NEW_EVENT_EXCHANGE:
            case formTypes.FORM_NEW_SLOT_EXCHANGE:
                title = <>Échanger un créneau</>
                break;
            case formTypes.FORM_NEW_EVENT_EXCHANGE_VALIDATE:
            case formTypes.FORM_NEW_SLOT_EXCHANGE_VALIDATE:
                title = <>Confirmer l'échange de créneaux</>
                break;
            case formTypes.FORM_NEW_BOOKING_GROUP_FROM_BOOKING:
                title = <>Créer un groupe de créneau</>
                break;
            case formTypes.BOOKING_CANCEL:
                title = <>Supprimer un créneau</>
                break;
            case formTypes.FORM_NEW_SALARY_TEMPLATE:
                title = <>Ajouter un emploi du temps</>
                break;
            case formTypes.FORM_NEW_SALARY_TEMPLATE_EVENT:
                title = <>Ajouter un créneau</>
                break;
            case formTypes.FORM_EDIT_SALARY_TEMPLATE_EVENT:
                title = <>Modifier un créneau</>
                break;
            case formTypes.LIST_SALARY_TEMPLATE:
                title = <>Emploi du temps</>
                break;
            case formTypes.COPY_SALARY_TEMPLATE:
                title = <>Copier un emploi du temps</>
                break;
            case formTypes.SHOW_SALARY_TEMPLATE:
                title = <>Programmer un emploi du temps</>
                break;
            case formTypes.DUPLICATE_SALARY_TEMPLATE:
                title = <>Dupliquer un emploi du temps</>
                break;
            case formTypes.FORM_EVENT_ADD:
                title = <>Ajouter un créneau</>
                break;
            case formTypes.FORM_EVENT_EXCEPTION_ADD:
            case formTypes.FORM_EVENT_EXCEPTION_EDIT:
                title = <>Modifier un créneau</>
                break;
            case formTypes.FORM_EVENT_CANCEL:
                title = <>Supprimer un créneau</>
                break;
            case formTypes.FORM_EXPENSE_ADD:
                title = <>Ajouter une note de frais</>
                break;
            case formTypes.FORM_EXPENSE_EDIT:
                title = <>Modifier une note de frais</>
                break;
            case formTypes.FORM_MANAGER_ADD:
                title = <>Ajouter un manageur</>
                break;
            case formTypes.FORM_MANAGER_EDIT:
                title = <>Modifier un manageur</>
                break;
        }

        return title ? <div className="offcanvas-header bg-light justify-content-start text-dark d-flex align-items-center">
            {data?.next && <button type="button" className="btn" onClick={() => dispatch(openSidebar(data.next, data.data))}>
                 <i className={'h5 bi bi-chevron-left'}> </i>
            </button>}
            <span className={'h5'} id="offcanvasRightLabel">
                {title}
            </span>
            <button className={'float-end btn btn-close'} onClick={() => dispatch(closeSidebar())}>

            </button>
        </div> : <></>;
    }

    return (
        <div className={`offcanvas offcanvas-end` + (
            [
                formTypes.FORM_EDIT_BOOKING_GROUP,
                formTypes.FORM_EDIT_BOOKING_GROUP_RELOAD,
                formTypes.FORM_NEW_BOOKING_GROUP,
                formTypes.COUNTER_SIDEBAR,
                formTypes.FORM_NEW_SLOT_EXCHANGE,
                formTypes.FORM_NEW_SLOT_EXCHANGE_VALIDATE,
                formTypes.FORM_NEW_EVENT_EXCHANGE,
                formTypes.FORM_NEW_EVENT_EXCHANGE_VALIDATE,
                formTypes.SHOW_SALARY_TEMPLATE,
            ].includes(item) ? ' w-100 ' : '') + (show ? ' show ' : '')} tabIndex={-1} id="offcanvasRight"
             aria-labelledby="offcanvasRightLabel">
            <CurrentTitle />
            <div className="offcanvas-body position-relative py-0">
                {isLoading ? <div className={"p-1 p-md-3"}><Loader /></div> : <CurrentForm/>}
            </div>
        </div>
    )
}

export default RightSidebar;