import React from "react";
import * as models from "../../../../models/synthesis";
import * as actions from "../../../../actions/synthesis";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import timeFormat from "../../../../utils/timeFormat"
import {Link} from "react-router-dom";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";

const SynthesisOtherTable:React.FC = (props) => {

    const dispatch = useDispatch();
    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);

    return <table className="table sticky-table table-striped align-middle text-center">
        <thead>
        <tr>
            <th>
                Collaborateur
            </th>
            <th>
                Jours complets (j)
            </th>
            <th>
                Formation (h)
            </th>
            <th>
                Primes (€)
            </th>
            <th>
                Acomptes (€)
            </th>
        </tr>
        </thead>
        <tbody>
        {state.payload.map((s) => <tr key={s.id}>
            <td className={'d-flex align-items-center'}>
                <Link target={"_blank"} to={`/salary/${s.salary.id}`} className={'text-primary mb-0 flex-grow-1'}>
                    <i className="bi bi-box-arrow-up-right"></i> {s.salary.title}
                </Link>
                <button title={"Détail par jour"} onMouseEnter={e => onMouseEnterTooltip(e.target)} className="btn bg-primary text-white flex-grow-0" data-bs-toggle="modal" data-bs-target="#synthesisSignature" onClick={() => dispatch(actions.showSynthesisSuccess(s))}>
                    <i className={'bi bi-list-ul'}></i>
                </button>
            </td>
            <td>
                {s.fullDays.length ? s.fullDays.length : <i className={'bi bi-x'}></i>}
            </td>
            <td>
                {timeFormat(s.formation)}
            </td>
            <td>
                <i className={'bi bi-x'}></i>
            </td>
            <td>
                <i className={'bi bi-x'}></i>
            </td>
        </tr>)}
        </tbody>
    </table>
}

export default SynthesisOtherTable;