import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/reporting";
import {setPageTitle} from "../../../actions/header";
import ReportingPeriodView from "../periodView";
import format from "../../../utils/locale";
import * as api from "../../../adapters/reporting";
import * as action from "../../../actions/reporting";
import * as accessRights from "../../../constants/accessRight";
import {AccountingSettings} from "../../../models/companyGroup";
import {Link, useParams} from "react-router-dom";
import {openSidebar} from "../../../actions/rightSidebar";
import {ACCOUNTING_SETTINGS, FORM_EXPORT_REPORTING} from "../../../constants/rightSidebar";
import {axiosError} from "../../../actions/axios";
import {PAGINATION} from "../../../constants/global";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {Granted} from "../../../security/granted";

const ReportingList: React.FC = () => {

    const state = useSelector((state: RootStateOrAny)  => state.reporting);
    const [offset, setOffset] = useState<number>(0);
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const params: {start?:string, end?:string} = useParams()
    const [query, setQuery] = useState<string|undefined>();
    const [company, setCompany] = useState<number|undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFiltering, setFiltering] = useState<boolean>(false);
    const dispatch = useDispatch();
    const accountingSettings: AccountingSettings = useSelector((state:RootStateOrAny) => state.auth.user.currentSalary.companyGroup.accountingSettings)

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    useEffect(() => {
        let start:Date;
        let end:Date;

        if (params.start && params.end){
            start = new Date(params.start)
            end = new Date(params.end)
        }else{
            let date = new Date();
            if (!!accountingSettings.closingDay)
            {
                if (date.getDay() <= accountingSettings.closingDay){
                    end = new Date();
                    end.setFullYear(date.getFullYear(), date.getMonth(), accountingSettings.closingDay);

                    start = new Date(end);
                    start.setMonth(start.getMonth() - 1);
                    start.setDate(start.getDate() + 1);
                }else{
                    start = new Date();
                    start.setFullYear(date.getFullYear(), date.getMonth(), accountingSettings.closingDay);
                    start.setDate(start.getDate() + 1);

                    end = new Date(start);
                    end.setMonth(start.getMonth() + 1);
                    end.setDate(start.getDate() - 1);
                }
            }else{
                let today = new Date();
                start = new Date(today.getFullYear(), today.getMonth(), 1);

                end = new Date(start);
                end.setMonth(start.getMonth() + 1);
                end.setDate(start.getDate() - 1);
            }
        }

        setStartDate(start);
        setEndDate(end);
    }, [])

    useEffect(() => {
        dispatch(setPageTitle('Synthèse'))

        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)

        return () => {
            container.removeEventListener("scroll", onScrollBottom)
        }
    }, [])

    useEffect(() => {
        if (startDate && endDate){
            dispatch(actions.fetchReporting())
            setIsLoading(true);
            api.fetch({
                start: format(startDate!, 'uuuu-MM-dd'),
                end: format(endDate!, 'uuuu-MM-dd'), options: {query: query, offset: 0,  companies: company ? [company] : []}}).then(data => {
                setIsLast(data.data.length < PAGINATION);
                dispatch(actions.fetchReportingSuccess(data.data))
                setIsLoading(false);
                setOffset( 1);
            }).catch(error => dispatch(axiosError(error)))
        }
    }, [startDate, endDate, company])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setFiltering(true);
                setOffset(0)
                api.fetch({
                    start: format(startDate!, 'uuuu-MM-dd'),
                    end: format(endDate!, 'uuuu-MM-dd'),
                    options: {query: query, offset: 0, companies: company ? [company] : []}
                }).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchReportingSuccess(data.data))
                    setFiltering(false);
                    setOffset( 1);
                }).catch(error => dispatch(axiosError(error)))
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if (scrollBottom){
            if(offset > 0 && !isLast){
                setIsLoading(true)
                api.fetch({
                    start: format(startDate!, 'uuuu-MM-dd'),
                    end: format(endDate!, 'uuuu-MM-dd'),
                    options: {
                        query: query,
                        offset: offset,
                        companies: company ? [company] : []
                    }
                }).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchReportingSuccess([...state.payload, ...data.data]))
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                    setScrollBottom(false)
                }).catch(error => dispatch(axiosError(error)))
            }
        }
        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)

        return () => {
            container.removeEventListener("scroll", onScrollBottom)
        }
    }, [scrollBottom])

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    const goToNextMonth = () => {
        dispatch(action.fetchReporting());
        let start;
        let end;

        if (accountingSettings.closingDay){
            start = new Date(startDate!);
            start.setMonth(startDate!.getMonth() + 1);
            end = new Date(endDate!);
            end.setMonth(endDate!.getMonth() + 1);
        }else{
            start = new Date(startDate!);
            start.setMonth(startDate!.getMonth() + 1);
            end = new Date(start);
            end.setMonth(start.getMonth() + 1);
            end.setDate(start.getDate() - 1);
        }


        setStartDate(start)
        setEndDate(end)
    }

    const goToPrevMonth = () => {
        dispatch(action.fetchReporting());
        let start;
        let end;
        if (accountingSettings.closingDay){
            start = new Date(startDate!);
            start.setMonth(startDate!.getMonth() - 1);
            end = new Date(endDate!);
            end.setMonth(endDate!.getMonth() - 1);
        }else{
            start = new Date(startDate!);
            start.setMonth(startDate!.getMonth() - 1);
            end = new Date(start);
            end.setMonth(start.getMonth() + 1);
            end.setDate(start.getDate() - 1);
        }

        setStartDate(start)
        setEndDate(end)
    }

    return (
        <>
            <div className="overflow-auto px-0 w-100 h-100 d-flex flex-column flex-grow-1" id={'listContainer'}>
            <div className="d-flex flex-grow-0 p-3 g-0 align-items-center position-sticky top-0" style={{zIndex: 100}}>
                <div className="col-auto me-2 d-flex align-items-center">
                    <BootstrapSelect
                        fetchEntity={'company'}
                        isMultiple={false}
                        label={"Point de vente"}
                        labelIcon={'bi bi-shop text-primary'}
                        placeholder={'Tous les points de vente'}
                        onChange={(c) => setCompany(Number(c?.value))}
                    />
                </div>
                <div className="col">
                    <div className="form-floating shadow-sm overflow-hidden">
                        <input type="search" placeholder={"Rechercher un collaborateur"}
                               onChange={(e) => setQuery(e.target.value)}
                               className="form-control"/>
                        <label className={'text-nowrap text-ellipsis'}>
                        {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> :
                            <i className={'bi bi-search'}> </i>} Rechercher un collaborateur
                        </label>
                    </div>
                </div>
                <div className="col-auto px-2">
                    {startDate && endDate && <><button disabled={isLoading} className={'btn bg-white rounded-pill shadow'} onClick={() => goToPrevMonth()}>
                        <i className={'bi bi-chevron-left'}> </i>
                    </button>
                        <span className={'btn text-primary'}>
                            {format(startDate)} <i className={'bi bi-arrow-left-right mx-1'}> </i> {format(endDate)}
                        </span>
                        <button disabled={isLoading} className={'btn bg-white rounded-pill shadow'} onClick={() => goToNextMonth()}>
                            <i className={'bi bi-chevron-right'}> </i>
                        </button></>}
                </div>
                <div className="col-auto">
                    {Granted(accessRights.EXPORT_REPORTING) && <button
                        onClick={() => dispatch(openSidebar(FORM_EXPORT_REPORTING, {start: startDate, end: endDate}))}
                        className={'btn bg-white shadow-sm me-2'}>
                        <i className={'bi bi-download'}> </i>
                    </button>}
                    {Granted(accessRights.EDIT_COMPANY_GROUP) && <button onClick={() => dispatch(openSidebar(ACCOUNTING_SETTINGS))}
                             className={'btn bg-white shadow-sm'}>
                        <i className={'bi bi-gear'}> </i>
                    </button>}
                </div>
            </div>
                {/*{isLoading && <Loader />}*/}
                <div className="table-responsive d-flex flex-column flex-grow-1">
                    {startDate && endDate && <ReportingPeriodView startDate={format(startDate!, 'uuuu-MM-dd')} endDate={format(endDate!, 'uuuu-MM-dd')} displaySalaryColumn={true} periodsReporting={state.payload} />}
                </div>
                {!isLast && <>
                    {isLoading ? <div className={'alert alert-info mb-0'}>
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> Chargement en cours...</div> : <button disabled={scrollBottom} className={'btn br-0 btn-outline-primary w-100 mx-auto m-0'} onClick={() => setScrollBottom(true)}><i className={'bi bi-arrow-repeat'}> </i> Charger plus de collaborateurs</button>}
                </>}
            </div>
        </>
    );
}

export default ReportingList;