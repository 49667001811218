import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Salary } from "../../../../models/salary";

import * as api from "../../../../adapters/salary/settings";
import * as billingApi from "../../../../adapters/billing";

import * as action from "../../../../actions/salary";
import { axiosError } from "../../../../actions/axios";

import HasModule from "../../../../security/hasModule";
import isGranted from "../../../../security/isGranted";
import getUser from "../../../../security/getUser";
import BootstrapSelect from "../../../../utils/bootstrapSelect";

const ACCOUNTED_TYPES_OPTIONS = [
    { label: 'Heures planifiées', value: 1 },
    { label: 'Heures par jour', value: 3 },
    { label: 'Paramètre par défaut', value: 0 }
]

const SalarySettings = () => {
    const dispatch = useDispatch();

    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single)

    const [isLoading, setIsLoading]= useState<boolean>(false);
    const [acquisition, setAcquisition] = useState<number>(salary.settings.absenceAcquisition);
    const [silaeMatricule, setSilaeMatricule] = useState<string|undefined>(salary.connector?.silaeMatricule);
    const [activationError, setActivationError] = useState<string>('')
    const [billingOptions, setBillingOptions] = useState<{label: string, id: number, shouldUpgrade: boolean}[]>([])
    const [accountedTime, setAccountedTime] = useState<number|undefined>(salary.settings?.accountedTime || undefined);

    function handleBillingChange(cid: number)
    {
        billingApi.salary(cid, salary.id)
            .then(r => dispatch(action.editSalarySuccess(r.data)))
    }

    const handleChange = (setting: string, value: any) => {
        setIsLoading(true);
        api.setting({setting: setting, value: value}, salary.id).then(data => {
            dispatch(action.editSalarySuccess(data.data));
            setIsLoading(false)
        }).catch(error => dispatch(axiosError(error)))
    }

    const handleActivation = (checked: boolean) => {
        setIsLoading(true);
        if (checked){
            api.enable(salary.id).then(data => {
                dispatch(action.editSalarySuccess(data.data));
                setIsLoading(false)
            }).catch(error => setActivationError(error.response.data.message))
        }else{
            api.disable(salary.id).then(data => {
                dispatch(action.editSalarySuccess(data.data));
                setIsLoading(false)
            }).catch(error => dispatch(axiosError(error)))
        }
    }

    useEffect(() => {
        billingApi.companies().then(r => setBillingOptions(r.data))
    }, [salary.id])

    useEffect(() => {
        setSilaeMatricule(salary.connector?.silaeMatricule)
    }, [salary])

    return (
        <div className="card shadow p-3">
            <h4 className={'mb-3'}>
                <i className={'bi bi-gear'}></i> Réglages
            </h4>
            
            <div className="col-12">
                <div className="alert alert-warning flex-grow-1 justify-content-between">
                    <label>
                        Répartition des heures contrat
                    </label>
                    <div className="form-text">
                        Rendez-vous dans l’onglet "Répartition des HC" pour gérer la répartition des heures contrat 
                        du collaborateur
                    </div>
                </div>

                <hr className={'bg-primary'} />

                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <label>
                            Statut du collaborateur
                        </label>
                        <div className="form-text">
                            {!salary.activated ? <span className={'text-danger'}> <i className={'bi bi-dash-circle'}> </i> Désactivé </span> : <span className={'text-success'}> <i className={'bi bi-check-circle'}> </i> Activé </span>}
                        </div>
                    </div>
                    <div className="col-auto">
                            <input disabled={isLoading} defaultChecked={salary.activated} onChange={(e) => handleActivation(e.target.checked)} id="btn-check-outlined" className="btn-check" type="checkbox" />
                            <label className={"btn " + (salary.activated ? "btn-outline-danger" : "btn-outline-success")} htmlFor="btn-check-outlined">
                                {salary.activated ? <> <i className={'bi bi-dash-circle'}> </i> Désactiver </> : <> <i className={'bi bi-check-circle'}> </i> Activer </>}
                            </label>
                    </div>
                </div>

                {activationError &&
                    <div className={'alert alert-danger'}>
                        {activationError}
                    </div>
                }
            </div>

            {isGranted("HANDLE_BILLING") && getUser().currentSalary.companyGroup.billingVersion === 2 &&
                <>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <label>
                                    Établissement de facturation
                                </label>
                            </div>
                            <div className="col-auto">
                                <BootstrapSelect
                                    enableReinitialize={true}
                                    key={salary.id}
                                    required={true}
                                    notFloating={true}
                                    options={[...billingOptions.filter(o => !o.shouldUpgrade).map(o => ({label: o.label, value: o.id}))]}
                                    value={salary.billingCompany ? {label: salary.billingCompany.title, value: salary.billingCompany.id!} : undefined}
                                    onChange={c => handleBillingChange(c.value!)}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }

            <hr className={'bg-primary'} />

            {HasModule('timeClock') &&
                <>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Activer la badgeuse dans les rapports
                                </label>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input
                                        disabled={isLoading}
                                        defaultChecked={salary.settings.timeClockActivated}
                                        onChange={(e) => handleChange('time_clock_activated', e.target.checked)}
                                        className="form-check-input"
                                        type="checkbox"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Badger depuis l'application
                                </label>
                                <div className="form-text">
                                    Activer pour que le collaborateur puisse badger depuis l'accès utilisateur
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input key={salary.id} disabled={isLoading} defaultChecked={salary.settings.timeClockFromApp} onChange={(e) => handleChange('time_clock_from_app', e.target.checked)}
                                        className="form-check-input" type="checkbox"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'}/>
                </>
            }
            
            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <label htmlFor="">
                            Tolérance sur les arrivées et départs en retard
                        </label>
                        <div className="form-text">
                            En dessous du seuil, l’horaire est arrondie à l’heure planifiée
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="input-group">
                            <select disabled={isLoading} onChange={(e) => handleChange('late_after', e.target.value)} className="form-control" >
                                <option selected={salary.settings.lateAfter === 0} value="0">0</option>
                                <option selected={salary.settings.lateAfter === 5} value="5">5</option>
                                <option selected={salary.settings.lateAfter === 10} value="10">10</option>
                                <option selected={salary.settings.lateAfter === 15} value="15">15</option>
                                <option selected={salary.settings.lateAfter === 20} value="20">20</option>
                                <option selected={salary.settings.lateAfter === 25} value="25">25</option>
                                <option selected={salary.settings.lateAfter === 30} value="30">30</option>
                            </select>
                            <span className="input-group-text">minute(s)</span>
                        </div>
                    </div>
                </div>
            </div>

            <hr className={'bg-primary'}/>

            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <label htmlFor="">
                            Tolérance sur les arrivées et départs en avance
                        </label>
                        <div className="form-text">
                            En dessous du seuil, l’horaire est arrondie à l’heure planifiée
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="input-group">
                            <select disabled={isLoading} onChange={(e) => handleChange('advance_before', e.target.value)} className="form-control" >
                                <option selected={salary.settings.advanceBefore === 0} value="0">0</option>
                                <option selected={salary.settings.advanceBefore === 5} value="5">5</option>
                                <option selected={salary.settings.advanceBefore === 10} value="10">10</option>
                                <option selected={salary.settings.advanceBefore === 15} value="15">15</option>
                                <option selected={salary.settings.advanceBefore === 20} value="20">20</option>
                                <option selected={salary.settings.advanceBefore === 25} value="25">25</option>
                                <option selected={salary.settings.advanceBefore === 30} value="30">30</option>
                            </select>
                            <span className="input-group-text">minute(s)</span>
                        </div>
                    </div>
                </div>
            </div>

            <hr className={'bg-primary'}/>

            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <label htmlFor="">
                            Base d'aquisition mensuelle des congés payés
                        </label>
                        <div className="form-text">
                            Nombre de congés payés aquis par mois travaillés
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="input-group">
                            <input key={salary.id} onChange={(e) => setAcquisition(parseFloat(e.target.value))} disabled={isLoading} defaultValue={salary.settings.absenceAcquisition} type="number" className="form-control" />
                            <span className="input-group-text">Jours</span>
                            {salary.settings.absenceAcquisition !== acquisition && <button disabled={isLoading} onClick={(event => handleChange('absence_acquisition', acquisition))} className={'btn btn-outline-success'}>
                                Enregistrer
                            </button>}
                        </div>
                    </div>
                </div>
            </div>

            <hr className={'bg-primary'}/>

            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <label htmlFor="">
                            Matricule Silae
                        </label>
                        <div className="form-text">
                            Veuillez saisir le matricule silae pour préremplir les exports
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="input-group">
                            <input key={salary.id} onChange={(e) => setSilaeMatricule(e.target.value)} disabled={isLoading} defaultValue={salary.connector?.silaeMatricule} type="text" className="form-control" />
                            {salary.connector?.silaeMatricule !== silaeMatricule && <button disabled={isLoading} onClick={(event => handleChange('silae_matricule', silaeMatricule))} className={'btn btn-outline-success'}>
                                Enregistrer
                            </button>}
                        </div>
                    </div>
                </div>
            </div>

            <hr className={'bg-primary'}/>

            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <label htmlFor="">
                            Par défaut, comptabiliser les heures d'absences telles que
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <BootstrapSelect
                                notFloating={true}
                                value={salary.settings?.accountedType ? ACCOUNTED_TYPES_OPTIONS.find(o => o.value === salary.settings.accountedType) : undefined}
                                options={ACCOUNTED_TYPES_OPTIONS}
                                required={true}
                                onChange={(c) => handleChange('accounted_type', c?.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {salary.settings?.accountedType === 3 &&
                <>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Nombre d'heures d'absence
                                </label>
                            </div>
                            <div className="col-auto">
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        defaultValue={salary.settings?.accountedTime || undefined}
                                        type="number"
                                        disabled={isLoading}
                                        onChange={(e) => setAccountedTime(parseFloat(e.target.value))}
                                    />
                                    <span className="input-group-text">heures</span>
                                    {salary.settings?.accountedTime !== accountedTime &&
                                        <button
                                            className={'btn btn-outline-success'}
                                            disabled={isLoading}
                                            onClick={(event => handleChange('accounted_time', accountedTime))}
                                        >
                                            Enregistrer
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default SalarySettings;