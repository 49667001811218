import * as types from "../../../constants/event/offer"
import * as models from "../../../models/event/offer"
import replace from "../../../utils/arrayReplace"

const eventOffer = (state: models.initialStateInterface = models.initialState, action: {type: string, eventOffer: models.EventOffer, eventOffers: models.EventOffers, error: string}) => {

    switch (action.type) {
        case types.FETCH_EVENT_OFFERS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_EVENT_OFFERS_SUCCESS:
            return { payload: action.eventOffers, single: state.single, isLoading: false, error: null };
        case types.FETCH_EVENT_OFFERS_FAILURE:
            return { payload: action.eventOffers, single: state.single, isLoading: false, error: action.error };

        case types.ADD_EVENT_OFFER:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_EVENT_OFFER_SUCCESS:
            return { payload: [action.eventOffer, ...state.payload], single: action.eventOffer, isLoading: false, error: null };

        case types.EDIT_EVENT_OFFER:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_EVENT_OFFER_SUCCESS:
            return { payload: replace(state.payload, action.eventOffer), single: action.eventOffer, isLoading: false, error: null };
        case types.CANCEL_EVENT_OFFER_SUCCESS:
            return { payload: [...state.payload.filter(b => b.id !== action.eventOffer.id)], single: null, isLoading: false, error: null };
        case types.SHOW_EVENT_OFFER:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_EVENT_OFFER_SUCCESS:
            return { payload: state.payload, single: action.eventOffer, isLoading: false, error: null };
        case types.SHOW_EVENT_OFFER_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default eventOffer;