import * as React from "react";

import ChannelList from "../../components/channel/list";
import ChannelShow from "../../components/channel/show";

const ChannelPage : React.FC = () => {
    return (
        <div className={"w-100 d-flex flex-grow-1"}>
            <ChannelList />
            <ChannelShow />
        </div>
    );
}

export default ChannelPage;