import * as types from "../../constants/keepNote";
import * as models from "../../models/keepNote";

export const addKeepNote = () => {
    return {
        type: types.ADD_KEEP_NOTE,
    }
}

export const addKeepNoteSuccess = (keepNote: models.KeepNote) => {
    return {
        type: types.ADD_KEEP_NOTE_SUCCESS,
        keepNote: keepNote,
    }
}

export const addKeepNoteFailure = (error: string) => {
    return {
        type: types.ADD_KEEP_NOTE_FAILURE,
        keepNote: null,
        keepNotes: null,
        error: error
    }
}

export const removeKeepNote = () => {
    return {
        type: types.REMOVE_KEEP_NOTE,
    }
}

export const removeKeepNoteSuccess = (keepNote: models.KeepNote) => {
    return {
        type: types.REMOVE_KEEP_NOTE_SUCCESS,
        keepNote: keepNote,
    }
}

export const removeKeepNoteFailure = (error: string) => {
    return {
        type: types.REMOVE_KEEP_NOTE_FAILURE,
        keepNote: null,
        keepNotes: null,
        error: error
    }
}

export const editKeepNote = (keepNote: models.KeepNote) => {
    return {
        type: types.EDIT_KEEP_NOTE,
        keepNote: keepNote
    }
}



export const editKeepNoteSuccess = (KeepNote: models.KeepNote) => {
    return {
        type: types.EDIT_KEEP_NOTE_SUCCESS,
        keepNote: KeepNote,
    }
}

export const fetchKeepNotes = () => {
    return {
        type: types.FETCH_KEEP_NOTES,
    }
}

export const fetchKeepNotesFailure = (error: string) => {
    return {
        type: types.FETCH_KEEP_NOTES_FAILURE,
        keepNote: null,
        keepNotes: [],
        error: error
    }
}

export const fetchKeepNotesSuccess = (keepNotes: models.KeepNotes) => {
    return {
        type: types.FETCH_KEEP_NOTES_SUCCESS,
        keepNote: null,
        keepNotes: keepNotes
    }
}


export const showKeepNote = () => {
    return {
        type: types.SHOW_KEEP_NOTE,
    }
}
export const showKeepNoteSuccess = (keepNote: models.KeepNote) => {
    return {
        type: types.SHOW_KEEP_NOTE_SUCCESS,
        keepNote: keepNote
    }
}
export const showKeepNoteFailure = (error: string) => {
    return {
        type: types.SHOW_KEEP_NOTE_FAILURE,
        keepNote: null,
        keepNotes: null,
        error: error
    }
}