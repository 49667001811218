import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {PeriodReporting, WeekReporting, WeeksReporting} from "../../../models/reporting";
import format from "../../../utils/locale"
import {AccountingSettings, ModuleSettings, TimeClockSettings} from "../../../models/companyGroup";
import ReportingPeriodView from "../periodView";
import {Link, useParams} from "react-router-dom";
import {setPageTitle} from "../../../actions/header";
import * as action from "../../../actions/reporting";
import * as api from "../../../adapters/reporting";
import * as actions from "../../../actions/reporting";
import Loader from "../../../utils/loader";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import * as counterApi from "../../../adapters/counter";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../utils/customInput";
import * as accessRights from "../../../constants/accessRight";
import FileSaver from "file-saver";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EXPORT_REPORTING, REPORTING_SHOW_WEEK, TIME_CLOCK_BOOKING_EDIT} from "../../../constants/rightSidebar";
import {axiosError} from "../../../actions/axios";
import * as reportingApi from "../../../adapters/reporting";
import {refreshSingleReporting, showReportingSuccess} from "../../../actions/reporting";
import {Granted} from "../../../security/granted";

const ReportingShow:React.FC = () => {

    const accountingSettings: AccountingSettings = useSelector((state:RootStateOrAny) => state.auth.user.currentSalary.companyGroup.accountingSettings)
    const moduleSettings: ModuleSettings = useSelector((state: RootStateOrAny) => state.auth.user.currentSalary.companyGroup.modules);
    const params: {id: string, start?: string, end?: string} = useParams();
    const single: PeriodReporting = useSelector((state: RootStateOrAny) => state.reporting.single);
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [salaryId, setSalaryId] = useState<number>(Number(params.id));
    const dispatch = useDispatch();

    const [counterLoading, setCounterLoading] = useState(false)

    const [savingCounter, setSavingCounter] = useState<boolean>( false);
    const [savingWeekCounter, setSavingWeekCounter] = useState<number>( );

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [pendingStartDate, setPendingStartDate] = useState<Date>();
    const [pendingEndDate, setPendingEndDate] = useState<Date>();

    useEffect(() => {
        let start:Date;
        let end:Date;

        if (params.start && params.end){
            start = new Date(params.start)
            end = new Date(params.end)
        }else{
            let date = new Date();
            if (date.getDay() <= accountingSettings.closingDay){
                end = new Date();
                end.setFullYear(date.getFullYear(), date.getMonth(), accountingSettings.closingDay);

                start = new Date(end);
                start.setMonth(start.getMonth() - 1);
                start.setDate(start.getDate() + 1);
            }else{
                start = new Date();
                start.setFullYear(date.getFullYear(), date.getMonth(), accountingSettings.closingDay);
                start.setDate(start.getDate() + 1);

                end = new Date(start);
                end.setMonth(start.getMonth() + 1);
                end.setDate(start.getDate() - 1);
            }
        }

        setStartDate(start);
        setEndDate(end);
        setPendingStartDate(start)
        setPendingEndDate(end)
    }, [])

    const saveCounter = (_startDate: Date, _endDate: Date) => {
        if(_startDate && _endDate && startDate && endDate){
            setSavingCounter(true)
            counterApi.saveSingle({start: format(_startDate, "uuuu-MM-dd"), end: format(_endDate, "uuuu-MM-dd")}, single.salary.id)
                .then(data => {
                    reportingApi.fetch({
                        start: format(startDate!, "uuuu-MM-dd"),
                        end: format(endDate!, "uuuu-MM-dd"),
                        options: {
                            salaries: [single.salary.id]
                        }
                    }).then(_data => {
                        dispatch(showReportingSuccess(_data.data[0]))
                        setSavingCounter(false)
                        setSavingWeekCounter(undefined)
                    })
                })
                .catch(error => dispatch(axiosError(error)))
            ;
        }

    }

    useEffect(() => {
        if (startDate && endDate){
            setIsLoading(true);
            api.fetch({
                start: format(startDate!, 'uuuu-MM-dd'),
                end: format(endDate!, 'uuuu-MM-dd'), options: {salaries: [salaryId]}}).then(data => {
                dispatch(actions.showReportingSuccess(data.data[0]))
                dispatch(setPageTitle('Synthèse', data.data[0].salary.title))
                setIsLoading(false);
            }).catch(error => dispatch(axiosError(error)))
        }
    }, [startDate, endDate, salaryId])

    const goToNextMonth = () => {
        dispatch(action.fetchReporting());
        let start = new Date(startDate!);
        start.setMonth(startDate!.getMonth() + 1);

        let end = new Date(endDate!);
        end.setMonth(endDate!.getMonth() + 1);

        setStartDate(start)
        setEndDate(end)
        setPendingStartDate(start)
        setPendingEndDate(end)
    }

    const goToPrevMonth = () => {
        dispatch(action.fetchReporting());
        let start = new Date(startDate!);
        start.setMonth(startDate!.getMonth() - 1);
        let end = new Date(endDate!);
        end.setMonth(endDate!.getMonth() - 1);

        setStartDate(start)
        setEndDate(end)
        setPendingStartDate(start)
        setPendingEndDate(end)
    }

    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return <>
        <div className="overflow-auto w-100 h-100 d-flex flex-column flex-grow-1">
            <div className="row g-0 px-3 mt-3 d-flex flex-grow-0">
                <div className="col-auto">
                    <Link className={'btn bg-white shadow-sm'} to={`/reporting` + (params.start && params.end ? `/${params.start}/${params.end}` : '')} >
                        <i className={'bi bi-chevron-left'}> </i> Retour
                    </Link>
                </div>
                <div className="col-auto px-2">
                    {startDate && endDate && <><button className={'btn bg-white shadow-sm rounded-pill'} onClick={() => goToPrevMonth()}>
                        <i className={'bi bi-chevron-left'}> </i>
                    </button>
                        <button style={{width: 450}} className={'btn text-primary'} data-bs-toggle="modal" data-bs-target="#datePicker">
                            <i className={'bi bi-calendar'}> </i> {format(startDate)} <i className={'bi bi-arrow-left-right mx-1'}> </i> {format(endDate)}
                        </button>
                        <button className={'btn bg-white rounded-pill shadow-sm'} onClick={() => goToNextMonth()}>
                            <i className={'bi bi-chevron-right'}> </i>
                        </button></>}
                </div>
                <div className="col">

                </div>
                <div className="col-auto">
                    <BootstrapSelect
                        className={'bg-white shadow-sm border-0'}
                        fetchEntity={'salary'}
                        placeholder={'Changer de collaborateur'}
                        notFloating={true}
                        required={true}
                        value={undefined}
                        onChange={(choice) => setSalaryId(Number(choice!.value))}
                        disabled={isLoading}
                    />
                </div>
                <div className="col-auto">
                    <button className={'btn bg-white shadow-sm ms-2'} onClick={() => dispatch(openSidebar(FORM_EXPORT_REPORTING, {start: startDate, end: endDate}))}>
                        {isDownloading ? <div className="spinner-grow spinner-grow-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <i className={'bi bi-download'}> </i>}
                    </button>
                    {Granted(accessRights.EDIT_COUNTER, salaryId) && single && <button disabled={!!savingCounter} className={'btn bg-white shadow-sm ms-2'}
                                                                     onClick={() => saveCounter(startDate!, endDate!)}>
                        {savingCounter ?
                            <div className="spinner-border spinner-border-sm text-success" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : <i className={'bi bi-check2-circle text-success'}> </i>}
                    </button>}
                </div>
            </div>
            {isLoading || !single ?
                <Loader /> :
            <>
                <div className="p-3">
                    <div className="card shadow-sm p-3">
                        <div className="table-responsive d-flex flex-column flex-grow-1" id={'singleContainer'}>
                            <table className={'table table-bordered align-middle text-center mb-0'}>
                                <thead>
                                <tr>
                                    <th colSpan={100}>
                                        Détail par semaine
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        Semaine
                                    </th>
                                    <th>
                                        Heures contractuelles
                                    </th>
                                    <th>
                                        Vol. heures
                                    </th>
                                    <th>
                                        Heures supplémentaires
                                    </th>
                                    <th>
                                        Absences
                                    </th>
                                    <th>
                                        Delta
                                    </th>
                                    <th>
                                        Compteur semaine
                                    </th>
                                    <th>
                                        Total compteur
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {single.weeks.map((reporting: WeekReporting, index: number) => <>
                                    <tr
                                        key={index}
                                        className={reporting.disabled ? 'bg-light' : ''}
                                    >
                                        <td>
                                            <span className={'rounded-circle bg-primary text-white p-1'}>
                                                {reporting.weekNumber}
                                            </span>
                                        </td>
                                        <td>
                                            {!reporting.disabled && single.salary.information?.contractWorkingTime}
                                        </td>
                                        <td>
                                            {reporting.workingTime}
                                        </td>
                                        <td>
                                            {reporting.overTime || <i className={"bi bi-x"}> </i>}
                                        </td>
                                        <td>
                                            {reporting.unaccountedAbsence || <i className={"bi bi-x"}> </i>}
                                        </td>
                                        <td>
                                            {reporting.delta || <i className={"bi bi-x"}> </i>}
                                        </td>
                                        <td>
                                            {!reporting.disabled && <>
                                                {reporting.counterSaved ? [<i className={reporting.counterSaved[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>, reporting.counterSaved, <i className={'bi bi-check text-success'}> </i>] : <i className={'bi bi-x'}> </i>}
                                                <div className="clearfix"> </div>
                                                {reporting.counterSaved !== reporting.counter && <span>
                                                    <span className={'form-text text-danger'}>à date :</span> {reporting.counter}
                                                </span>}
                                            </>}
                                        </td>
                                        <td>
                                            {!reporting.disabled && <>
                                                {!reporting.disabled && <> {reporting.counterSavedTotal ? [<i className={reporting.counterSavedTotal[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>, reporting.counterSavedTotal, <i className={'bi bi-check text-success'}> </i>] : <i className={'bi bi-x'}> </i>}
                                                <div className="clearfix"> </div>
                                                {reporting.counterTotal !== reporting.counterSavedTotal && <span>
                                                   <span className={'form-text text-danger'}>à date :</span> {reporting.counterTotal}
                                                </span>}</>}
                                            </>}
                                        </td>
                                        <td>
                                            {!reporting.disabled &&<div className="button-group">
                                                 <button  className={'btn'} type="button">
                                                    <button className={"btn"} onClick={() => {
                                                        dispatch(openSidebar(REPORTING_SHOW_WEEK, {
                                                            currentWeek: reporting.weekNumber,
                                                            start: startDate,
                                                            end: endDate
                                                        }))
                                                    }}>
                                                        <i className={'bi bi-eye text-primary'}> </i>
                                                    </button>
                                                </button>
                                                {Granted(accessRights.EDIT_COUNTER, salaryId) && single && <button disabled={savingWeekCounter === reporting.weekNumber || savingCounter} className={'btn bg-white shadow-sm'}
                                                                                                           onClick={() => {
                                                                                                               setSavingWeekCounter(reporting.weekNumber)
                                                                                                               saveCounter(new Date(reporting.dates.start), new Date(reporting.dates.end))
                                                                                                           }}>
                                                    {savingWeekCounter === reporting.weekNumber ?
                                                        <div className="spinner-border spinner-border-sm text-success" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div> : <i className={'bi bi-check2-circle text-success'}> </i>}
                                                </button>}
                                            </div>}
                                        </td>
                                    </tr>
                                    {!reporting.disabled && <tr className="collapse" id={`collapse${index}`}>
                                        <td className={'text-uppercase text-primary'} colSpan={1}>
                                            {reporting.dates.start ? format(new Date(reporting.dates.start), 'dd/MM') : "no start date"}
                                            <div className="clearfix"> </div>
                                            <i className={'bi bi-arrow-down-up my-2'}> </i>
                                            <div className="clearfix"> </div>
                                            {reporting.dates.end ? format(new Date(reporting.dates.end), 'dd/MM') : "no end date"}
                                        </td>
                                        <td colSpan={8}>

                                        </td>
                                    </tr>}
                                </>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="card shadow-sm p-3">
                        <div className="table-responsive">
                            <ReportingPeriodView startDate={format(startDate!, 'uuuu-MM-dd')} endDate={format(endDate!, 'uuuu-MM-dd')} displaySalaryColumn={false} periodsReporting={[single]} />
                        </div>
                    </div>
                </div>
            </>}
        </div>

        {single && <div className="modal fade" id="datePicker" data-bs-keyboard="false" aria-labelledby="datePickerLabel"
              aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="datePickerLabel">Dates</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group">
                            <DatePicker
                                className={"form-control"}
                                selected={pendingStartDate}
                                customInput={<DateCustomInput label={'Début'}
                                                              icon={'bi bi-calendar-check text-primary'}/>}
                                selectsStart
                                onChange={(dt: Date) => setPendingStartDate(dt)}
                                endDate={pendingEndDate}
                                minDate={new Date(single.salary.information!.startDate)}
                                dateFormat="E dd MMM uuuu"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                            />
                            <span className={'input-group-text'}>
                                    <i className={'bi bi-arrow-left-right'}> </i>
                                </span>
                            <DatePicker
                                startDate={pendingStartDate}
                                className={"form-control"}
                                selected={pendingEndDate}
                                customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-x text-primary'}/>}
                                selectsEnd
                                onChange={(dt: Date) => setPendingEndDate(dt)}
                                dateFormat="E dd MMM uuuu"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">
                            <i className={'bi bi-x'}> </i> Annuler
                        </button>
                        <button type="button" onClick={() => {
                            onChange([pendingStartDate!, pendingEndDate!])
                        }} data-bs-dismiss="modal" className="btn btn-outline-primary">
                            <i className={'bi bi-check'}> </i> Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>}
    </>
}

export default ReportingShow;