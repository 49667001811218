/**
 * Keyboard shortcut related logic for the SlateEditor component
 */
import { isHotkey } from "is-hotkey";
import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";
import {toggleMark} from "./helpers";
import {HistoryEditor} from "slate-history";

export const HOTKEYS: any = {
  "ctrl+b": "bold",
  "mod+b": "bold",
  "ctrl+i": "italic",
  "mod+i": "italic",
  "ctrl+u": "underline",
  "mod+u": "underline",
  "ctrl+shift+enter": "code",
  "mod+shift+enter": "code"
};

export const toggleKeyboardShortcut = (event: KeyboardEvent, editor: BaseEditor & ReactEditor & HistoryEditor) => {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
};
