import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import FileSaver from "file-saver";
import AbsenceCalendarShow from "./calendar";
import EmptyList from "../../common/EmptyList";

import * as models from "../../../models/absence";
import * as types from "../../../constants/absence";
import { FORM_EDIT_ABSENCE, FORM_NEW_ABSENCE_FILE } from "../../../constants/rightSidebar";
import * as accessRights from "../../../constants/accessRight";
import * as calendarTypes from "../../../constants/calendar";

import { openSidebar } from "../../../actions/rightSidebar";
import * as actions from "../../../actions/absence";
import { showAlertDanger, showAlertSuccess } from "../../../actions/alert";
import { axiosError } from "../../../actions/axios";

import * as api from "../../../adapters/absence";
import * as fileApi from "../../../adapters/absence/file";
import * as absenceApi from "../../../adapters/absence";

import { Granted } from "../../../security/granted";
import format from "../../../utils/locale";
import Loader from "../../../utils/loader";
import BgSingle from "../../../utils/background/single";
import SmallLoader from "../../../utils/loader/small";

export const AbsenceShow: React.FC = () => {
    const dispatch = useDispatch();
    const params: {id: string} = useParams();

    const state = useSelector((state: RootStateOrAny) => state.absence)
    const absence: models.Absence = state.single;

    const [downloading, setDownloading] = useState<number>();
    const [deleting, setDeleting] = useState<number>();
    const [justificatifUrl, setJustificatifUrl] = useState<string>()
    const [loadingFile, setLoadingFile] = useState<boolean>(false)
    const [range, setRange] = useState({start: new Date(), end: new Date()})

    useEffect(() => {
        if (params.id){
            let single = state.payload.find((a: models.Absence) => a.id!.toString() === params.id)
            if (single){
                dispatch(actions.showAbsenceSuccess(single))
                setRange({start: new Date(single.start), end: new Date(single.end)})
            }else{
                dispatch(actions.showAbsence());
                api.get(params.id)
                    .then(data => {
                        dispatch(actions.showAbsenceSuccess(data.data))
                        setRange({start: new Date(data.data.start), end: new Date(data.data.end)})
                    })
                    .catch(error => dispatch(axiosError(error)))
            }
        }
    }, [params.id, state.payload])

    const [absenceInfoLoading, setAbsenceInfoLoading] = useState(false);
    const [nbDays, setNbDays] = useState<number>()
    const [nextWorkingDay, setNextWorkingDay] = useState<string>()

    useEffect(() => {
        setNbDays(undefined);
        setNextWorkingDay(undefined);
        if (params.id){
            setAbsenceInfoLoading(true)
            absenceApi.days(params.id).then(resp => {
                setNbDays(resp.data.nbDays);
                if (resp.data.nextWorkingDay){
                    setNextWorkingDay(resp.data.nextWorkingDay);
                }

                setAbsenceInfoLoading(false);
            })
        }
    }, [params.id])

    const displayFile = (id: number) => {
        if (!absence) return false;

        let file = absence.files?.find((file => file.id === id))
        if (file) {
            setLoadingFile(true)
            fileApi.download(absence.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                let url = URL.createObjectURL(newBlob);
                setJustificatifUrl(url)
                setLoadingFile(false)

            }).catch(error => dispatch(axiosError(error)))
        } else {
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const downloadJustification = (id: number) => {
        if (!absence) return false;

        let file = absence.files?.find((file => file.id === id))
        if (file) {
            setDownloading(id);
            fileApi.download(absence.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                FileSaver.saveAs(newBlob, file!.title)
                setDownloading(undefined)
            }).catch(error => dispatch(axiosError(error)))
        } else {
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const deleteJustification = (id: number) => {
        if (!absence) return false;

        let file = absence.files?.find((file => file.id === id))
        if (file) {
            setDeleting(id);
            fileApi.remove(absence.id!, id).then(data => {
                let files = [...absence.files!]
                files.splice(files.findIndex(file => file.id === id), 1);
                absence.files = [...files];
                setDeleting(undefined)
                dispatch(showAlertSuccess('Fichier supprimé'))
            }).catch(error => dispatch(axiosError(error)))
        } else {
            dispatch(showAlertDanger('Une erreur est survenue lors de la supression ! Fichier introuvable.'))
        }
    }

    if (!params.id || !absence) {
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return (
        <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!params.id ? " d-none d-md-block" : "")}>
            <div className="d-flex flex-grow-1 p-1 p-md-3 container-fluid bg-light flex-column h-100">
                <div className="w-100 text-end d-block d-md-none">
                    <Link to={"/absence"} className={'btn btn-sm bg-white shadow-sm m-1'}>
                        <i className={'bi bi-chevron-left'}> </i> Retour
                    </Link>
                </div>
                <div className="card shadow-sm mb-3 p-1 p-md-3 d-flex flex-grow-0">
                        <div className="row align-items-center">
                            <div className="col d-flex">
                                {absence.status === 1 && <button className={'btn py-1 rounded-pill alert alert-warning'}> En attente <i className={"bi bi-exclamation-circle"}> </i> </button>}
                                {absence.status === 2 && <button className={'btn py-1 rounded-pill alert alert-success'}> Validée <i className={"bi bi-check-circle"}> </i> </button>}
                                {absence.status === 3 && <button className={'btn py-1 rounded-pill alert alert-danger'}> Refusée <i className={"bi bi-dash-circle"}> </i> </button>}
                                {Granted(accessRights.EDIT_ABSENCE, undefined, absence) && <Link to={`/calendar/${format(new Date(absence.start), 'uuuu-MM-dd')}/${calendarTypes.CALENDAR_ABSENCE}`} className={'ms-2'} target={'_blank'}>
                                        <i className="bi bi-box-arrow-up-right"></i> Afficher dans le planning
                                    </Link>}
                            </div>
                            {Granted(accessRights.EDIT_ABSENCE, absence.salary.id) && <div className="col-auto">
                                <button className={'btn btn-outline-primary'} onClick={() => dispatch(openSidebar(FORM_EDIT_ABSENCE, {...absence}))}>
                                    <i className={'bi bi-pencil'}> </i> Modifier
                                </button>
                            </div>}
                        </div>
                    <div className="row">
                        <div className="col">
                            <span className="h4">
                                {absence.salary.title}
                            </span>
                            <div className="clearfix"> </div>
                            <span className={'h4'}>
                                {types.TYPE_NAMES[absence.type]}
                            </span>
                            <div className="clearfix"> </div>
                            <div className="h4">
                                { format(new Date(absence.start)) } - { format(new Date(absence.end)) }
                            </div>
                            <div className="h4">
                            Nb de jour(s) :  {absenceInfoLoading ? <SmallLoader /> : nbDays}
                            </div>
                            {absence.type === types.TYPE_CP && <div className="h4">
                            Reprise le : {absenceInfoLoading ? <SmallLoader/> : (nextWorkingDay ? format(new Date(nextWorkingDay)) : <i className={"bi bi-x"}></i>)}
                            </div>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-auto">
                            <div className="row mb-3">
                                <div className="col-auto">
                                    Fichiers
                                </div>
                                <div className="col">
                                    {Granted(accessRights.EDIT_ABSENCE_FILE, undefined, absence) && <button onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE_FILE, {
                                        absence: absence
                                    }))} className={'btn btn-sm w-100 btn-outline-primary ms-2'}>
                                        <i className={'bi bi-plus'}> </i> <span>Ajouter un fichier</span>
                                    </button>}
                                </div>
                            </div>
                        </div>

                        <div>
                            {absence.files!.length === 0 && <EmptyList text="Aucun fichier associé" />}
                            {absence.files!.map((file) => (
                                <div key={file.id} className="container-fluid mb-1 py-2 border rounded">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            {file.title}
                                        </div>

                                        <div className="col d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-light me-2"
                                                data-bs-toggle="modal"
                                                data-bs-target="#justificatifModal"
                                                onClick={() => displayFile(file.id)}
                                            >
                                                <i className={'bi bi-eye'}> </i>
                                            </button>

                                            <button className={"btn btn-light me-2"} onClick={() => downloadJustification(file.id)}>
                                                {downloading === file.id ?
                                                    <div className="spinner-grow spinner-grow-sm" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div> : <i className="bi bi-download text-primary"> </i>
                                                }
                                            </button>

                                            {Granted(accessRights.EDIT_ABSENCE, undefined, absence) &&
                                                <button
                                                    className={"btn btn-light me-2"}
                                                    onClick={() => deleteJustification(file.id)}
                                                >
                                                    {deleting === file.id ?
                                                        <div className="spinner-grow spinner-grow-sm"
                                                            role="status">
                                                                        <span
                                                                            className="visually-hidden">Loading...</span>
                                                        </div> :
                                                        <i className="bi bi-dash-circle text-danger"> </i>
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="card shadow-sm flex-grow-1 d-none d-md-flex p-3">
                    <AbsenceCalendarShow />
                </div>

                <div className="modal" id="justificatifModal" tabIndex={-1}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content h-100">
                            <div className="modal-body d-flex flex-column" id={'#modalBody'}>
                                {loadingFile && <Loader />}
                                {!loadingFile && justificatifUrl && <iframe src={justificatifUrl} width={'100%'} className={'flex-grow-1'} frameBorder={0}></iframe>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AbsenceShow;