import React, {useEffect, useState} from "react";
import {Offcanvas} from "bootstrap";
import {Job} from "../../../../models/job";
import EventAdd from "../add";
import {Template} from "../../../../models/template";
import {Activity} from "../../../../models/activity";
import {Localisation} from "../../../../models/localisation";
import EventEdit from "../edit";
import {EventClickArg} from "@fullcalendar/react";
import EventShow from "../show/show";

interface Interface {
    sidebarType: 'EMPTY',
}

interface InterfaceEventShow {
    sidebarType: 'EVENT_SHOW',
    arg: EventClickArg
    setTemplateEventSidebarProps: React.Dispatch<any>;
}

interface InterfaceEventAdd {
    sidebarType: 'EVENT_ADD',
    resource?: {id: number, title: string}
    start?: Date,
    end?: Date,
    activity?: Activity,
    localisation?: Localisation,
}

interface InterfaceEventEdit {
    sidebarType: 'EVENT_EDIT',
    resource: {id: number, title: string}
    start: Date,
    end: Date,
    accountingType: number,
    activity?: Activity
    localisation?: Localisation,
}

interface InterfaceEditEvent {
    eventType: 'templateEvent',
    exDay: number,
    eventId: number,
}

interface InterfaceEditEventException {
    eventType: 'templateEventException',
    exceptionId: number,
}

export type TemplateEventSidebarInterface = {trigger: number} & (Interface | InterfaceEventShow | InterfaceEventAdd | (InterfaceEventEdit & (InterfaceEditEvent | InterfaceEditEventException)))

const TemplateEventSidebar:React.FC<TemplateEventSidebarInterface & {template: Template, handleEventChange: (resource: any, previousEvent?: any) => any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasTemplateEvent')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasTemplateEvent')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "EVENT_SHOW":
                    return <EventShow close={close} arg={props.arg} setTemplateEventSidebarProps={props.setTemplateEventSidebarProps} handleSubmitSuccess={props.handleEventChange} />
                case "EVENT_ADD":
                    return <EventAdd template={props.template} close={close} handleSubmitSuccess={props.handleEventChange} resource={props.resource} start={props.start} end={props.end} activity={props.activity} localisation={props.localisation} />
                case "EVENT_EDIT":
                    switch (props.eventType){
                        case "templateEvent":
                            return <EventEdit close={close} exDay={props.exDay} resource={props.resource} start={props.start} end={props.end} accountingType={props.accountingType} handleSubmitSuccess={props.handleEventChange} template={props.template} eventType={props.eventType} eventId={props.eventId} localisation={props.localisation} activity={props.activity} />
                        case "templateEventException":
                            return <EventEdit close={close} resource={props.resource} start={props.start} end={props.end} accountingType={props.accountingType} handleSubmitSuccess={props.handleEventChange} template={props.template} eventType={props.eventType} exceptionId={props.exceptionId} localisation={props.localisation} activity={props.activity} />
                    }
                    break;
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTemplateEvent" aria-labelledby="offcanvasTemplateEventLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: TemplateEventSidebarInterface & {template: Template, handleEventChange: (resources: number[]) => any}, nextProps: TemplateEventSidebarInterface & {template: Template, handleEventChange: (resources: number[]) => any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(TemplateEventSidebar, areEqual);