import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Absence, Absences} from "../../../models/absence";
import * as api from "../../../adapters/absence"
import * as calendarTypes from "../../../constants/calendar"
import format from "../../../utils/locale"
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EDIT_ABSENCE, FORM_NEW_ABSENCE, PENDING_ABSENCE_LIST} from "../../../constants/rightSidebar";
import {editAbsence, editAbsenceSuccess} from "../../../actions/absence";
import {axiosError} from "../../../actions/axios";
import * as accessRights from "../../../constants/accessRight";
import {Granted, GrantedAny} from "../../../security/granted";
import getUser from "../../../security/getUser";
import {Link} from "react-router-dom";

const AbsenceSidebarList:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state:RootStateOrAny) => state)
    const absences: Absences = state.absence.payload.filter((absence: Absence) => absence.status === 1);
    const isLoading = state.absence.isLoading;

    const handleStatusChange = (status: 2|3, absence: Absence) => {
        dispatch(editAbsence());
        api.editStatus({status: status}, absence.id!)
            .then(data => dispatch(editAbsenceSuccess(data.data)))
            .catch(error => dispatch(axiosError(error)))
        ;
    }

    return <>
        <div className="w-100 my-2 text-end">
            {GrantedAny([accessRights.EDIT_ABSENCE, accessRights.REQUEST_ABSENCE]) && <button
                onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE, {
                    salary: getUser().currentSalary,
                    next: PENDING_ABSENCE_LIST
                }))}
                className={'btn btn-outline-primary'}>
                <i className={'bi bi-plus'}> </i> {Granted(accessRights.EDIT_ABSENCE) ? "Ajouter une absence" : "Demander une absence"}
            </button>}
        </div>
        <div className={''}>
        {absences.length ? absences.map(absence => <div className={'border rounded shadow mb-3 p-1 p-md-3'}>
            <div className="row d-flex align-items-center">
                <div className="col">
                    <i className={'bi bi-person'}> </i> {absence.salary.title}
                    <div className="form-text">
                        Demande faite le {format(new Date(absence.createdAt!), 'dd MMMM uuuu')}
                    </div>
                </div>
                <div className="col-auto">
                    {Granted(accessRights.EDIT_ABSENCE, undefined, absence) && <><button disabled={isLoading} className={'btn btn-outline-success m-2'}
                             onClick={() => handleStatusChange(2, absence)}>
                        <i className={"bi bi-check2-circle"}> </i> Valider
                    </button>
                        <button disabled={isLoading} className={'btn btn-outline-danger m-2'} onClick={() => handleStatusChange(3, absence)}>
                        <i className={"bi bi-dash-circle"}> </i> Refuser
                        </button></>}
                    {Granted(accessRights.EDIT_ABSENCE, undefined, absence) && <button disabled={isLoading} className={'btn btn-outline-primary'}
                             onClick={() => dispatch(openSidebar(FORM_EDIT_ABSENCE,
                                 {...Object.assign({}, absence, {next: PENDING_ABSENCE_LIST})}
                             ))}>
                        <i className={'bi bi-pencil-square'}> </i> Modifier
                    </button>}
                </div>
            </div>
            <div className="row d-flex align-items-center">
                <div className="col">
                    <table className={'table mb-0'}>
                        <tr>
                            <td>
                                {format(new Date(absence.start))} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(absence.end))}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {absence.typeName}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {absence.nbDays} {absence.nbDays! > 1 ? 'jours' : 'jour'}
                            </td>
                        </tr>
                    </table>
                </div>
                {Granted(accessRights.EDIT_ABSENCE, undefined, absence) && <div className="col-auto">
                    <Link to={`/calendar/${format(new Date(absence.start), 'uuuu-MM-dd')}/${calendarTypes.CALENDAR_ABSENCE}`} target={'_blank'}>
                        <i className="bi bi-box-arrow-up-right"></i> Afficher dans le planning
                    </Link>
                </div>}
            </div>
        </div>) : <span>
            {isLoading ? <>
            </> : <div className={'p-5'}>
                <h3 className="text-primary">
                    <i className={'bi bi-search'}> </i> Aucun résultat
                </h3>
            </div>
            }
        </span>}
    </div>
    </>
}

export default AbsenceSidebarList;