import React, {useState} from "react";
import {Field, FieldProps, Form, FormikErrors, FormikHandlers, FormikTouched, FormikValues} from "formik";
import {SingleCompanySelected, SingleCompanySelector} from "../../../../utils/companySelector/single";
import {SingleSalarySelected} from "../../../../utils/salarySelector/single";
import {Company} from "../../../../models/companies";
import {CirclePicker} from "react-color";
import IconPicker from "../../../../utils/iconPicker";
import ColorPicker from "../../../../utils/colorPicker";
import BootstrapSelect from "../../../../utils/bootstrapSelect";



interface FormLocalisationProps {
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    values: FormikValues,
    setValues: any
}
const FormLocalisation:React.FC<FormLocalisationProps> = (props) => {

    const {errors, touched, values, setValues} = props;

    const [displayCompanySelector, setDisplayCompanySelector] = useState(false);

    return (
        <Form className="p-3">
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <Field
                        className={'form-control' + (touched.title ? (errors.title ? ' is-invalid' : ' is-valid') : '')}
                        name="title"/>
                    <label className="form-label" htmlFor="title">
                        Titre <span className="required">*</span>
                    </label>
                    {errors.title && touched.title ? (<div className='text-danger form-text' >{errors.title}</div>) : null}
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <Field
                        className={'form-control' + (touched.description ? (errors.description ? ' is-invalid' : ' is-valid') : '')}
                        name="description"/>
                    <label className="form-label" htmlFor="description">
                        Description <span className="required">*</span>
                    </label>
                    {errors.description && touched.description ? (<div className='text-danger form-text' >{errors.description}</div>) : null}
                </div>
            </div>
            <div className="col-12 mb-3">
                <Field>
                    {({
                          field, // { name, value, onChange, onBlur }
                          form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                      }: FieldProps) => (
                          <BootstrapSelect
                            fetchEntity={"company"}
                            required={true}
                            onChange={(choice) => setFieldValue('company',choice?.value)}
                            label={"Point de vente"}
                          />
                    )}
                </Field>
            </div>
            <div className="col-12 mb-3">
                <Field>
                    {({
                          field, // { name, value, onChange, onBlur }
                          form: {touched, errors, setFieldValue}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                      }: FieldProps) =>
                        <div className={'text-center w-100'}>
                            <ColorPicker
                                onChange={(color: string) => setFieldValue('color', color)}
                                selected={values.color}
                            />
                        </div>
                    }
                </Field>
            </div>
            <div className="col-12 mb-3">
                <Field>
                    {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                      }: FieldProps) =>
                          (<>
                              <IconPicker onChange={(icon: string) => setFieldValue('icon', icon)} />
                          </>)
                    }
                </Field>
            </div>
            <button className='btn btn-outline-primary w-100' type="submit">Ajouter</button>
        </Form>
    )
}

export default FormLocalisation;