import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Salary} from "../../../../models/salary";
import * as actions from "../../../../actions/salary";
import {setPageTitle} from "../../../../actions/header";
import * as api from "../../../../adapters/admin/salary";
import {axiosError} from "../../../../actions/axios";
import BgSingle from "../../../../utils/background/single";
import {showAlertDanger, showAlertSuccess} from "../../../../actions/alert";
import {Company} from "../../../../models/companies";

const AdminSalaryShow: React.FC = () => {


    const params: {id: string} = useParams();
    const [pwd, setPwd] = useState('');
    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.payload.find((a: Salary) => a.id!.toString() === params.id))
    const state = useSelector((state: RootStateOrAny) => state.salary)
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        dispatch(actions.showSalary());
        if (salary && params.id){
            dispatch(actions.showSalarySuccess(salary));
            dispatch(setPageTitle('Admin > Collaborateurs', salary.firstname + ' ' + salary.lastname))
        }else if(!salary && params.id){
            api.show(Number(params.id)).then(data => {
                dispatch(actions.showSalarySuccess(data.data))
                dispatch(setPageTitle('Admin > Collaborateurs', data.data.firstname + ' ' + data.data.lastname))
            }).catch(error => dispatch(axiosError(error)))
        }
    }, [params.id])

    if (!state.single){
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return (
        <div id={'singleContainer'} className={"d-flex flex-column  bg-white col-lg-9 col-md-8 px-0" + (!params.id ? " d-none d-md-flex" : "")}>
           <div className="container-fluid">
               <div className="row">
                   <div className="col-md-6">
                       <div className="p-1 p-md-3">
                           <div className="card shadow-sm">
                               <h4>
                                   Informations
                               </h4>
                               <table className={'table'}>
                                   <tbody>
                                   <tr>
                                       <td className={'text-primary'}>#</td>
                                       <td>{state.single.title}</td>
                                   </tr>
                                   <tr>
                                       <td className={'text-primary'}>Email</td>
                                       <td>{state.single.email}</td>
                                   </tr>
                                   <tr>
                                       <td className={'text-primary'}>Point de vente</td>
                                       <td>
                                           {state.single.companies.map((c: Company, i: number) => <>{c.title} {i < state.single.companies.length - 1 && ","}</>)}
                                       </td>
                                   </tr>
                                   {state.single.user ? <>
                                           <tr>
                                               <td className={'text-primary'}>
                                                   Utilisateur activé
                                               </td>
                                               <td>
                                                   {state.single.user.activated ? "Oui" : "Non"}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td className={'text-primary'}>
                                                   Email
                                               </td>
                                               <td>
                                                   {state.single.user.email}
                                               </td>
                                           </tr>
                                       </>
                                       :
                                       <>
                                           <tr>
                                               <td colSpan={2}>Pas de compte associé</td>
                                           </tr>
                                       </>}
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>
                   <div className="col-md-12">
                       <div className="p-1 p-md-3">
                           <div className="card shadow-sm">
                               <h4>
                                   Actions
                               </h4>
                               <div className="d-flex align-items-center">
                                   <div className="flex-grow-1">
                                       <p>
                                           Modifier le mot de passe
                                       </p>
                                       <div className="form-text">
                                           6 caractères minimum
                                       </div>
                                   </div>
                                   <div className="flex-grow-0 d-flex">
                                       <input key={params.id} type="text" className="form-control shadow-sm"
                                              placeholder={'Nouveau mot de passe'}
                                              onChange={(e) => setPwd(e.target.value)}/>
                                       {!!pwd && pwd.length >= 6 && <button
                                           disabled={disable} className={'btn btn-success text-white'}
                                           onClick={(e) => {
                                               setDisable(true)
                                               api.updatePwd(state.single.id, {pwd: pwd}).then(() => {
                                                   dispatch(showAlertSuccess('Mot de passe modifié'))
                                                   setDisable(false)
                                               })
                                           }}
                                       >Valider</button>}
                                   </div>
                               </div>
                               <hr/>
                               {state.single.user ? <>
                                   <div className="d-flex">
                                       <div className="flex-grow-1">
                                           <p>
                                               {state.single.user?.activated ? "Désactiver le compte" : "Activer le compte"}
                                           </p>
                                       </div>
                                       <div className="flex-grow-0">
                                           <button
                                               disabled={disable} className={'btn btn-success text-white'}
                                               onClick={(e) => {
                                                   setDisable(true)
                                                   if (state.single.user?.activated){
                                                       api.disable(state.single.id).then((data) => {
                                                           dispatch(actions.editSalarySuccess(data.data))
                                                           dispatch(showAlertSuccess('Compte désactivé'))
                                                           setDisable(false)
                                                       })
                                                   }else{
                                                       api.enable(state.single.id).then((data) => {
                                                           dispatch(actions.editSalarySuccess(data.data))
                                                           dispatch(showAlertSuccess('Compte activé'))
                                                           setDisable(false)
                                                       })
                                                   }
                                               }}
                                           >{state.single.user?.activated ? "Désactiver" : "Activer"}</button>
                                       </div>
                                   </div>
                                   <hr/>
                                   <div className="d-flex">
                                       <div className="flex-grow-1">
                                           <p>
                                               Dissocier un compte
                                           </p>
                                       </div>
                                       <div className="flex-grow-0">
                                           <button
                                               disabled={disable} className={'btn btn-success text-white'}
                                               onClick={(e) => {
                                                   setDisable(true)
                                                   api.removeUser(state.single.id).then((data) => {
                                                       dispatch(actions.editSalarySuccess(data.data))
                                                       dispatch(showAlertSuccess('Compte dissocié'))
                                                       setDisable(false)
                                                   })
                                               }}
                                           >Dissocier</button>
                                       </div>
                                   </div>
                                   <hr/>
                                   <div className="d-flex">
                                       <div className="flex-grow-1">
                                           <p>
                                               Renvoyer le mail de confirmation
                                           </p>
                                       </div>
                                       <div className="flex-grow-0">
                                           <button
                                               disabled={disable}
                                               className={"btn btn-primary text-white"}
                                               onClick={(e) => {
                                                   setDisable(true)
                                                   api.sendConfirmation(state.single.id).then((data) => {
                                                       dispatch(showAlertSuccess('Email envoyé'))
                                                       setDisable(false)
                                                   })
                                               }}>
                                               Envoyer
                                           </button>
                                       </div>
                                   </div>
                                   <hr/>
                               </> : <>
                                   <div className="d-flex">
                                       <div className="flex-grow-1">
                                           <p>
                                               Associer/créer un compte
                                           </p>
                                       </div>
                                       <div className="flex-grow-0">
                                           <button
                                               disabled={disable}
                                               className={"btn btn-primary text-success"}
                                               onClick={(e) => {
                                                   setDisable(true)
                                                   api.addUser(state.single.id).then((data) => {
                                                       dispatch(actions.editSalarySuccess(data.data))
                                                       dispatch(showAlertSuccess('Compte associé'))
                                                       setDisable(false)
                                                   })
                                               }}>
                                               Associer
                                           </button>
                                       </div>
                                   </div>
                                   <hr/>
                               </>}
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default AdminSalaryShow;