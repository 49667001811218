import React, {useState} from "react";
import * as types from "../../../../constants/calendar";
import * as api from "../../../../adapters/event/offer";
import format from "../../../../utils/locale"
import SmallLoader from "../../../../utils/loader/small";
import {Granted} from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight";
import * as calendarTypes from "../../../../constants/calendar";
import {Salary} from "../../../../models/salary";
import getUser from "../../../../security/getUser";
import {EventOfferApplication} from "../../../../models/event/offer";
import * as applicationApi from "../../../../adapters/event/offer/applicant";

const CalendarOfferList:React.FC<{offers: any, handleEventChange: (ev: any) => any, setCalendarOfferSidebarProps: React.Dispatch<any>, setIsOffersOnly: React.Dispatch<any>, isOffersOnly: boolean}> = (props) => {

    const {offers, handleEventChange, setCalendarOfferSidebarProps, isOffersOnly, setIsOffersOnly} = props;
    const [isLoading, setIsLoading] = useState(false)
    const [canceling, setCanceling] = useState<number>()

    function cancel(id: number){
        setCanceling(id);
        api.disable(id)
            .then(resp => handleEventChange(resp.data))
            .then(resp => setCanceling(undefined))
    }

    function apply(o: any){
        applicationApi.apply(o.offerId)
            .then(resp => handleEventChange(resp.data))
    }

    function cancelApply(o: any){

        let application = o.applications.find((a: EventOfferApplication) => a.salary.id === getUser().currentSalary.id);

        if (application){
            applicationApi.cancel(o.offerId, application.id)
                .then(resp => handleEventChange(resp.data))
        }
    }

    return <div className={"w-20 card"}>
        <div className="card-header">
            <h4>
                Offres en attente <span className="badge bg-warning">{offers.filter((o: any) => o.eventType === types.EVENT_TYPE_OFFER_PENDING).length}</span>
            </h4>
        </div>
        <div className="card-body">
            <div className="col-12 mb-3">
                <button type={'button'} className={'btn btn-light w-100'} onClick={() => setIsOffersOnly((prev: boolean) => !prev)}>
                    {isOffersOnly ? <><i className={"bi bi-check text-success"}></i> Afficher les créneaux planifié</> : <><i className={"bi bi-x text-danger"}></i>  Ne pas afficher les créneaux planifiés</>}
                </button>
            </div>
            <ul className={'list-group list-group-flush'}>
                {offers.filter((o: any) => o.eventType === types.EVENT_TYPE_OFFER_PENDING).map((o: any) => <li className={'list-group-item'}>
                    <h4>
                        {format(new Date(o.start), 'E dd MMMM HH:mm')} <i className={'bi bi-arrow-left-right'}></i>  {format(new Date(o.end), 'HH:mm')}
                    </h4>
                    <p className={'mb-0'}>
                        {o.company.title}
                    </p>
                    {o.localisation && <p className={'mb-0'}>
                        {o.localisation.title}
                    </p>}
                    {o.activity && <p className={'mb-0'}>
                        {o.activity.title}
                    </p>}
                    <p className={'mb-0'}>
                        {o.applications.length} Participants / {o.salaries.length} Collaborateur
                    </p>

                    {Granted(accessRights.APPLY_BOOKING_OFFER) && o.salaries.find((s: Salary) => s.id === getUser().currentSalary?.id)  && <>
                        {o.applications.find((a: EventOfferApplication) => a.salary.id === getUser().currentSalary?.id) ?
                            <button className={'btn w-100 btn-light'} onClick={() => cancelApply(o)}>
                                <i className={'bi bi-x text-danger'}></i> Annuler ma participation
                            </button> : <button className={'btn w-100 btn-light'} onClick={() => apply(o)}>
                                <i className={'bi bi-check text-success'}></i> Participer à l'offre
                            </button>}
                    </>}

                    <div className={'row'}>
                        <div className="col-4">
                            <button type={'button'} className={'btn w-100 btn-light'} onClick={() => setCalendarOfferSidebarProps((prev: any) => ({
                                sidebarType: "OFFER_EDIT",
                                trigger: prev.trigger + 1,
                                company: o.company,
                                activity: o.activity,
                                localisation: o.localisation,
                                start: new Date(o.start),
                                end: new Date(o.end),
                                offerId: o.offerId,
                                salaries: o.salaries,
                                description: o.description
                            }))}>
                                <i className={'bi bi-pencil text-primary'}></i>
                            </button>
                        </div>
                        <div className="col-4">
                            <button type={'button'} className={'btn w-100 btn-light'} onClick={() => cancel(o.offerId)}>
                                {canceling === o.offerId ? <SmallLoader /> : <i className={'bi bi-dash-circle text-danger'}></i>}
                            </button>
                        </div>
                        <div className="col-4">
                            <button type={'button'} disabled={!o.applications.length} className={'btn w-100 btn-light'}>
                                <i className={'bi bi-check text-success'}></i>
                            </button>
                        </div>
                    </div>
                </li>)}
            </ul>
        </div>
    </div>
}

export default CalendarOfferList;

