import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../adapters/timetable";
import {useDispatch} from "react-redux";
import {Timetable, Timetables} from "../../../../models/timetable";
import TimetableAddForm from "../form";
import {Offcanvas} from "bootstrap";
import {showAlertSuccess} from "../../../../actions/alert";
import {Salary} from "../../../../models/salary";

const TimetableDuplicate:React.FC<{setTimetables: React.Dispatch<any>, setTimetable: React.Dispatch<any>, salary: Salary, timetable: Timetable, timetables: Timetables}> = (props) => {

    const dispatch = useDispatch();
    const {timetables, setTimetable, setTimetables, timetable, salary} = props;
    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [lastEnd, setLastEnd] = useState<Date>()

    useEffect(() => {
        const el = document.getElementById('offcanvasTimetableDuplicate')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const maxDate = (dates: Date[]) => new Date(Math.max(...dates.map(Number)));

    useEffect(() => {
        if(timetables.length > 0 && timetables.filter(t => !t.end).length  === 0) {
            setLastEnd(maxDate(timetables.map(t => new Date(t.end!))));
        }else{
            setLastEnd(undefined)
        }
    }, [timetables])

    const handleSubmit = (t: Timetable) => {
        setTimetables([...timetables, t])
        setTimetable(t)
        close()
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTimetableDuplicate" aria-labelledby="offcanvasTimetableDuplicateLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasTimetableDuplicateLabel">Dupliquer un emploi du temps</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <TimetableAddForm timetables={timetables} salary={salary} handleSubmit={handleSubmit} lastEnd={lastEnd} duplicate={true} timetable={timetable} />
            </div>
        </div>
}

export default TimetableDuplicate;