import * as types from "../../../constants/template/resource";
import * as models from "../../../models/template";

export const addResource = () => {
    return {
        type: types.ADD_RESOURCE,
    }
}

export const addResourceSuccess = (resource: models.Resource) => {
    return {
        type: types.ADD_RESOURCE_SUCCESS,
        resource: resource,
    }
}

export const addResourceFailure = (error: string) => {
    return {
        type: types.ADD_RESOURCE_FAILURE,
        resource: null,
        resources: null,
        error: error
    }
}

export const editResource = () => {
    return {
        type: types.EDIT_RESOURCE,
    }
}

export const editResourceSuccess = (resource: models.Resource) => {
    return {
        type: types.EDIT_RESOURCE_SUCCESS,
        resource: resource,
    }
}

export const fetchResources = () => {
    return {
        type: types.FETCH_RESOURCES,
    }
}

export const fetchResourcesFailure = (error: string) => {
    return {
        type: types.FETCH_RESOURCES_FAILURE,
        resource: null,
        resources: [],
        error: error
    }
}

export const fetchResourcesSuccess = (resources: models.Resources) => {
    return {
        type: types.FETCH_RESOURCES_SUCCESS,
        resource: null,
        resources: resources
    }
}

export const showResource = () => {
    return {
        type: types.SHOW_RESOURCE,
    }
}

export const showResourceSuccess = (resource: models.Resource) => {
    return {
        type: types.SHOW_RESOURCE_SUCCESS,
        resource: resource
    }
}

export const showResourceFailure = (error: string) => {
    return {
        type: types.SHOW_RESOURCE_FAILURE,
        resource: null,
        resources: null,
        error: error
    }
}