import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import format from "../../../../utils/locale";
import * as api from "../../../../adapters/counter/withdrawn"
import {closeSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {editCounterSuccess, fetchCountersSuccess, refreshCounter} from "../../../../actions/counter";
import FormError from "../../../../utils/formError";
import {Salary} from "../../../../models/salary";
import * as accessRights from "../../../../constants/accessRight";
import SalarySelector from "../../../salary/selector";

const CounterWithdrawnAdd:React.FC = () => {

    const dispatch = useDispatch();
    const data = useSelector((state:RootStateOrAny) => state.rightSidebar.data)
    const [hour, setHour] = useState<number>(0);
    const [min, setMin] = useState<number>(0);

    const initialValues: any = {
        salary: data.salary?.id,
        value: 0,
        weekNumber: data.week || format(new Date(), 'w'),
        year: data.year || (new Date()).getFullYear(),
        description: ""
    };

    const validationSchema = Yup.object().shape({
        salary: Yup.number().required(),
        value: Yup.number().required(),
        weekNumber: Yup.number().required(),
        year: Yup.number().required(),
        description: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            dispatch(submitSidebar());
            api.create(values).then(_data => {
                dispatch(editCounterSuccess(_data.data));
                dispatch(refreshCounter());
                dispatch(closeSidebar());
            })
        }
    })

    useEffect(() => {
        formik.setFieldValue('value', (hour || 0) * 3600 + (min || 0) * 60)
    }, [min, hour])

    return <>
        <form onSubmit={formik.handleSubmit} className={'p-1 p-md-3'}>
            <div className="col-12 mb-3">
                <SalarySelector
                    fetchOptions={true}
                    required={true}
                    value={data.salary}
                    onChange={(choice: Salary) => formik.setFieldValue('salary', choice.id)}
                    accessRight={accessRights.EDIT_COUNTER}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
            </div>
            <div className="row mb-3">
                <div className="col-6">
                    <BootstrapSelect
                        label={"Heures"}
                        value={{label: String(hour || 0), value: (hour || 0)}}
                        options={Array.from(Array(250).keys()).map((n) => ({label: String(n), value: n}))}
                        onChange={(c) => setHour(Number(c?.value))}
                    />
                </div>
                <div className="col-6">
                    <BootstrapSelect
                        label={"Minutes"}
                        value={{label: String(min || 0), value: (min || 0)}}
                        options={Array.from(Array(59).keys()).map((n) => ({label: String(n), value: n}))}
                        onChange={(c) => setMin(Number(c?.value))}
                    />
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'value'} />
            </div>
            <div className="row mb-3">
                <div className="col-6">
                    <BootstrapSelect
                        label={"Année"}
                        value={{label: (new Date()).getFullYear().toString(), value: (new Date()).getFullYear()}}
                        required={true}
                        options={Array.from(Array(10).keys()).map((n) => ({label: String((new Date()).getFullYear() - 5 + n), value: (new Date()).getFullYear() - 5 + n}))}
                        onChange={(c) => formik.setFieldValue('year', c?.value)}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'year'} />
                </div>
                <div className="col-6">
                    <BootstrapSelect
                        label={"Semaine"}
                        required={true}
                        value={{label: formik.values.weekNumber, value: formik.values.weekNumber}}
                        options={Array.from(Array(53).keys()).map((n) => ({label: String(n), value: n}))}
                        onChange={(c) => formik.setFieldValue('weekNumber', c?.value)}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'weekNumber'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" onChange={formik.handleChange} name={'description'} className="form-control"/>
                    <label htmlFor="">
                        Description
                    </label>
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'description'} />
            </div>
            <button className={'btn btn-outline-primary w-100'}>
                <i className={'bi bi-plus-circle'}> </i> Ajouter
            </button>
        </form>
    </>
}

export default CounterWithdrawnAdd;