import {post, get} from "../../xhr";
import * as models from "../../../models/salary";

export function edit(requestData: models.SalaryInformation, id: number){
    return post('salary/'+ id +'/information/edit', requestData);
}

export function create(id: number, requestData: models.SalaryInformation){
    return post('salary/'+ id +'/information/create',  requestData);
}

export function show(id: string){
    return get('salary/'+ id +'/information/show/');
}