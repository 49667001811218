import {useEffect, useState} from "react";
import {Salaries, Salary} from "../../../../models/salary";
import * as api from "../../../../adapters/billing";
import * as sapi from "../../../../adapters/salary/settings";
import SmallLoader from "../../../../utils/loader/small";
import * as action from "../../../../actions/salary";
import {axiosError} from "../../../../actions/axios";
import {useDispatch} from "react-redux";

const BillingSalaries = ({company}: {company: string}) => {

    const [salaries, setSalaries] = useState<Salaries>([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)
        setSalaries([]);
        api.salaries(Number(company))
            .then(r => setSalaries(r.data))
            .then(() => setLoading(false))
    }, [company])

    function deactivate(salary: Salary){
        sapi.disable(salary.id).then(data => {
           setSalaries(p => [...p.filter(s => s.id !== salary.id)])
        }).catch(error => dispatch(axiosError(error)))
    }

    return <div className={'col-12'}>
        <div className="p-3">
            {loading && <SmallLoader />}
            <ul className={'list-group'}>
                {salaries.map(s => <li className={'list-group-item'}>
                    <div className="row">
                        <div className="col">
                            {s.title}
                        </div>
                        <div className="col-auto">
                            <button className={'btn btn-light'} onClick={() => deactivate(s)}>
                               <i className={'bi bi-x text-danger'}></i> Désactiver
                            </button>
                        </div>
                    </div>
                </li>)}
            </ul>
        </div>
    </div>
}

export default BillingSalaries;