import React from "react";
import * as types from "../../constants/alert";

export const showAlertSuccess = ( value: string) => {
    return {
        type: types.SHOW_ALERT_SUCCESS,
        value
    }
};
export const showAlertInfo = (value: string) => {
    return {
        type: types.SHOW_ALERT_INFO,
        value
    }
};
export const showAlertWarning = (value: string) => {
    return {
        type: types.SHOW_ALERT_WARNING,
        value
    }
};
export const showAlertDanger = (value: string = "Une erreur s’est produite") => {
    return {
        type: types.SHOW_ALERT_DANGER,
        value
    }
};

export const hideAlert = () => {
    return {
        type: types.HIDE_ALERT
    }
};