import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {EventOffer, EventOfferApplication} from "../../../../models/event/offer";
import format from "../../../../utils/locale";
import * as accessRights from "../../../../constants/accessRight"
import * as api from "../../../../adapters/event/offer/applicant"
import * as offerApi from "../../../../adapters/event/offer"
import {openSidebar} from "../../../../actions/rightSidebar";
import {
    FORM_EDIT_EVENT_OFFER,
    FORM_NEW_EVENT_OFFER,
    LIST_EVENT_OFFER,
    LIST_EVENT_OFFER_APPLICANTS
} from "../../../../constants/rightSidebar";
import {cancelEventOfferSuccess, editEventOfferSuccess} from "../../../../actions/event/offer";
import {EventOffers} from "../../../../models/event/offer";
import {Granted} from "../../../../security/granted";

const EventOfferSidebarList:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const offers: EventOffers = useSelector((state: RootStateOrAny) => state.eventOffer.payload);
    const dispatch = useDispatch();


    const _apply = (offer: EventOffer) => {
        api.apply(offer.id).then((data) => dispatch(editEventOfferSuccess(data.data)))
    }
    const _cancel = (offer: EventOffer, applicant: EventOfferApplication) => {
        api.cancel(offer.id, applicant.id).then((data) => dispatch(editEventOfferSuccess(data.data)))
    }
    const disable = (offer: EventOffer) => {
        let container = document.getElementById("offer"+String(offer.id))!;
        container.classList.toggle('fadeOut')
        offerApi.disable(offer.id).then((data) => {
            dispatch(cancelEventOfferSuccess(data.data))
            container.classList.toggle('fadeOut')
        })
    }

    return <div className={'p-1 p-md-3'}>
        {Granted(accessRights.EDIT_BOOKING_OFFER) && <div className="d-flex mb-1 mb-md-3">
            <div className="flex-grow-1">
                <button className={'btn btn-outline-primary w-100'}
                        onClick={() => dispatch(openSidebar(FORM_NEW_EVENT_OFFER, {next: LIST_EVENT_OFFER}))}>
                    <i className={'bi bi-plus'}> </i> Ajouter
                </button>
            </div>
        </div>}
        {offers.map((offer, key) => <div key={key} id={`offer${offer.id}`} className="border border-1 shadow-sm mb-3 p-1 p-md-3">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <span>
                        {offer.applications.find(a => a.validated) ? <span className={'text-success'}><i className={'bi bi-check2-circle'}> </i> Créneau attribué à {offer.applications.find(a => a.validated)!.salary.title}</span> : <span className={'text-warning'}><i className={'bi bi-clock-history'}> </i> Créneau non attribué</span>}
                    </span>
                </div>
                <div className="flex-grow-0">
                    <div className="btn-group">
                        <button className={'btn btn-sm me-2'} onClick={() => dispatch(openSidebar(FORM_EDIT_EVENT_OFFER, {...offer}))}>
                            <i className={'text-primary bi bi-pencil'}> </i>
                        </button>
                        <button className={'btn btn-sm'} onClick={(e) => disable(offer)}>
                            <i className={'text-danger bi bi-dash-circle'}> </i>
                        </button>
                    </div>
                </div>
            </div>
            <div className={'me-3 fw-bold'}>
                <i className={'bi bi-calendar text-primary'}> </i> {format(new Date(offer.start), 'E dd MMMM uuuu HH:mm')} <i className="mx-2 bi bi-arrow-left-right"> </i> {format(new Date(offer.end), 'HH:mm')}
            </div>
            <div>
                <i className={'bi bi-shop text-primary'}> </i> {offer.company.title}
            </div>
            <div>
                <i className={'bi bi-people text-primary'}> </i> {offer.applications.length} Postulants
            </div>
            <div>
                <i className={'bi bi-people text-primary'}> </i> {offer.salaries.length} Participants
            </div>
            <div className={'form-text'}>
                {offer.description}
            </div>
            <div className="d-flex">
                {Granted(accessRights.APPLY_BOOKING_OFFER, undefined, offer) && <>
                    {!offer.applications.find(a => a.validated) && <>
                        {offer.salaries.find(s => s.id === state.auth.user.currentSalary.id) && (!offer.applications.find(a => a.salary.id === state.auth.user.currentSalary.id) ? <button onClick={() => _apply(offer)} className={'btn flex-grow-1 btn-outline-success me-2'}>
                            <i className={'bi bi-check'}> </i> Postuler
                        </button> : <button onClick={() => _cancel(offer, offer.applications.find(a => a.salary.id === state.auth.user.currentSalary.id)!)} className={'btn flex-grow-1 btn-primary text-white me-2'}>
                            <i className={'bi bi-check2'}> </i> Déjà postulé
                        </button>)}
                    </>}
                </>}
                {Granted(accessRights.EDIT_BOOKING_OFFER, undefined, offer) && <button
                    onClick={() => dispatch(openSidebar(LIST_EVENT_OFFER_APPLICANTS, {
                        offer: offer,
                        next: LIST_EVENT_OFFER
                    }))}
                    className={"btn flex-grow-1 btn-outline-primary"}>
                    {offer.applications.find(a => a.validated) ? <><i className={'bi bi-pencil'}> </i> Changer de participant</> : <><i className={'bi bi-list-check'}> </i>  Séléctionner un participant</>}
                </button>}
            </div>
        </div>)}
    </div>
}

export default EventOfferSidebarList;