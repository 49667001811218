import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as api from "../../../adapters/template";
import * as Yup from "yup";
import FormError from "../../../utils/formError";
import validationClass from "../../../utils/validationClass";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {ObjectSchema} from "yup";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {axiosError} from "../../../actions/axios";

const TemplateAddFromCalendar:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)

    const initialValues = {
        title: "",
        description: null,
        company: state.rightSidebar.data.company?.id,
        job: state.rightSidebar.data.job,
        visibility: "1",
        year: state.rightSidebar.data.year,
        weekNumber: state.rightSidebar.data.weekNumber,
    }

    const validationSchema: ObjectSchema<any> = Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().nullable(),
        company: Yup.number().required(),
        job: Yup.number().required(),
        visibility: Yup.number().required(),
        year: Yup.number().required(),
        weekNumber: Yup.number().required(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            dispatch(submitSidebar());
            // api.createFromCalendar(values).then(data => {
            //     dispatch(closeSidebar());
            // }).catch(error => dispatch(axiosError(error)))
        }
    });

    const range = (start: number, end: number) => {
        let ret = []
        for (let i = start; i <= end; i++){
            ret.push(i)
        }
        return ret;
    }
    function getISOWeeks(y: number) {
        let d,
            isLeap;

        d = new Date(y, 0, 1);
        isLeap = new Date(y, 1, 29).getMonth() === 1;

        return d.getDay() === 4 || isLeap && d.getDay() === 3 ? 53 : 52
    }

    return (
        <form className='w-100 p-3' onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name={'title'} type="text" placeholder={'Nom du template'} className="form-control"/>
                    <label htmlFor="">
                        Titre
                    </label>
                    <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name={'description'} type="text" placeholder={'Description du template'} className="form-control"/>
                    <label htmlFor="">
                        Description
                    </label>
                    <FormError errors={formik.errors} touched={formik.touched} field={'description'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    fetchEntity={"company"}
                    label={"Point de vente"}
                    required={true}
                    onChange={(choice) => formik.setFieldValue('company', choice?.value)}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'company'} />
            </div>
            <div className="col-12 mb-3">
                <div className="form-check">
                    <input onBlur={formik.handleBlur} onChange={formik.handleChange} defaultChecked={true} name={'job'} className="form-check-input" type="radio" value="0" id="all" />
                    <label className="form-check-label" htmlFor="all">
                        Tous les métiers
                    </label>
                </div>
                <div className="form-check">
                    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name={'job'} className="form-check-input" type="radio" value="1" id="fo" />
                    <label className="form-check-label" htmlFor="fo">
                        Front office
                    </label>
                </div>
                <div className="form-check">
                    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name={'job'} className="form-check-input" type="radio" value="2" id="bo" />
                    <label className="form-check-label" htmlFor="bo">
                        Back office
                    </label>
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'job'} />
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="">
                    Visibilitée
                </label>
                <div className="form-check">
                    <input onBlur={formik.handleBlur} onChange={formik.handleChange} defaultChecked={true} name={'visibility'} className="form-check-input" type="radio" value="1" id="private" />
                    <label className="form-check-label" htmlFor="private">
                       <i className={'bi bi-lock'}> </i> Privée
                    </label>
                </div>
                <div className="form-check">
                    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name={'visibility'} className="form-check-input" type="radio" value="2" id="public" />
                    <label className="form-check-label" htmlFor="public">
                        <i className={'bi bi-unlock'}> </i> Publique
                    </label>
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'visibility'} />
            </div>
            <div className="col-12 mb-3 d-flex">
                <div className="col">
                    <BootstrapSelect
                        enableReinitialize={true}
                        options={range(new Date().getFullYear() - 10, new Date().getFullYear() + 10).map((year: number) => ({label: String(year), value: year}))}
                        onChange={(choice) => formik.setFieldValue('year', String(choice?.value))}
                        disabled={formik.isSubmitting}
                        className={validationClass(formik.errors, formik.touched, 'year')}
                        value={formik.values.year ? {label: String(formik.values.year), value: formik.values.year} : undefined}
                        label={'Année'}
                        required
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'year'} />
                </div>
                <div className="col">
                    <BootstrapSelect
                        enableReinitialize={true}
                        options={range(1, getISOWeeks(formik.values.year || new Date().getFullYear())).map((week, index) => ({label: String(week), value: week}))}
                        isMultiple={false}
                        onChange={(value) => formik.setFieldValue('weekNumber', String(value?.value))}
                        disabled={formik.isSubmitting}
                        className={validationClass(formik.errors, formik.touched, 'weekNumber')}
                        value={formik.values.weekNumber ? {label: String(formik.values.weekNumber), value: formik.values.weekNumber} : undefined}
                        label={'Semaine'}
                        required
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'weekNumber'} />
                </div>
            </div>
            <button className={'btn w-100 btn-outline-primary'}>
                <i className={"bi bi-plus-circle"}> </i> Ajouter
            </button>
            <pre>
                {/*{JSON.stringify(formik.values, null, 4)}*/}
            </pre>
        </form>
    )
}

export default TemplateAddFromCalendar;