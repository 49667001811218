import * as accessRights from "../../../constants/accessRight";
import * as models from "../../../models/event/offer";
import {User} from "../../../models/auth";
import accessRightChecker from "../../accessRightChecker";
import AccessRightChecker from "../../accessRightChecker";

class EventOfferVoter {

    static supports(right: string, object?: models.EventOffer)
    {
        return [
            accessRights.LIST_BOOKING_OFFER,
            accessRights.EDIT_BOOKING_OFFER,
            accessRights.DELETE_BOOKING_OFFER,
            accessRights.APPLY_BOOKING_OFFER,
        ].includes(right) && object
    }

    voteOnAttribute(right: string, object: models.EventOffer, user: User){

        let currentSalary = user.currentSalary;

        if (!currentSalary){
            return false;
        }

        switch (right){
            case accessRights.APPLY_BOOKING_OFFER:
                return AccessRightChecker.hasGlobalRight(right) && object.salaries.map(s => s.id).includes(currentSalary.id);
            case accessRights.LIST_BOOKING_OFFER:
                return AccessRightChecker.hasGlobalRight(right) || object.salaries.map(s => s.id).includes(currentSalary.id);
            default:
                return accessRightChecker.grantedForMany(right, object.salaries.map(s => s.id))
        }
    }
}

export default EventOfferVoter;