import React, {useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import format from "../../../../utils/locale"
import * as api from "../../../../adapters/task"
import {Salary} from "../../../../models/salary";
import {CustomFieldValue} from "../../../../constants/taskGroup/customField";
import {CalendarTaskSidebarInterface} from "../sidebar";
import {StatusSelector} from "../../../task/utils";
import SmallLoader from "../../../../utils/loader/small";

const TaskShow:React.FC<{close: () => any, handleSubmitSuccess: (ev: any) => any, arg: EventClickArg, setCalendarTaskSidebarProps: React.Dispatch<any>}> = (props) => {

    const {close, arg, setCalendarTaskSidebarProps, handleSubmitSuccess} = props;
    const [canceling, setCanceling] = useState(false)
    const [statusUpdating, setStatusUpdating] = useState(false)

    const remove = () => {
        setCanceling(true)
        api.cancelOnce(arg.event.extendedProps.taskId, arg.event.extendedProps.initialStart).then(r => {
            Promise.all([
                handleSubmitSuccess(r.data)
            ])
                .then(() => setCanceling(false))
                .then(() => close())
        })
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarTaskShowLabel">
                {arg.event.title}
            </h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">

            <table className={'table'}>
                <tbody>
                    <tr>
                        <td className={'text-primary'}>
                            Group
                        </td>
                        <td>
                            {arg.event.extendedProps.group.title}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            Statut
                        </td>
                        <td>
                            <StatusSelector
                                disabled={statusUpdating}
                                exDate={arg.event.extendedProps.initialStart}
                                status={arg.event.extendedProps.status}
                                taskId={arg.event.extendedProps.taskId}
                                _handleChangeSuccess={(ret) => {
                                    setStatusUpdating(true)
                                    Promise.all([
                                        handleSubmitSuccess(ret)
                                    ]).then(() => setStatusUpdating(false))
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            Description
                        </td>
                        <td>
                            {arg.event.extendedProps.description}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            Horaire
                        </td>
                        <td>
                            {arg.event.allDay ? format(arg.event.start!) + " toute la journée" :  <>

                                {format(arg.event.start!, "E dd MMMM uuuu HH:mm")} <i className={'bi bi-arrow-left-right'}></i> {format(arg.event.end || arg.event.start!, "E dd MMMM uuuu HH:mm")}
                            </>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            Collaborateurs
                        </td>
                        <td>
                            <ul className={"list-unstyled"}>
                                {arg.event.extendedProps.salaries.map((s: Salary) => <li>{s.title}</li>)}
                            </ul>
                        </td>
                    </tr>
                    {arg.event.extendedProps.customFieldsValues && arg.event.extendedProps.customFieldsValues?.map((item: any) => <tr>
                        <td className={'text-primary'}>
                            {item.field.title}
                        </td>
                        <td className={'position-relative'}>
                            <CustomFieldValue customFieldsValues={arg.event.extendedProps.customFieldsValues} customField={item.field}/>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            <div className="row">
                <div className="col-md-6">
                    <button className="btn w-100 btn-light" onClick={() => setCalendarTaskSidebarProps((prev: CalendarTaskSidebarInterface) => ({
                        sidebarType: 'TASK_EDIT',
                        arg: arg,
                        trigger: prev.trigger + 1,
                    }))}>
                        <i className={'bi bi-pencil text-primary'}></i> Modifier
                    </button>
                </div>
                <div className="col-md-6">
                    <button className="btn w-100 btn-light" onClick={() => remove()}>
                        {canceling ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Supprimer</>}
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default TaskShow;