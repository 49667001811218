import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="w-100 py-5 d-flex flex-column align-items-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <p className="mt-2 text-muted text-center">
        Veuillez patienter..
      </p>
    </div>
  )
}

export default Loading