import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Salary, SalaryDefaultLabel} from "../../../../../models/salary";
import * as api from "../../../../../adapters/salary/defaultLabel";
import * as activityApi from "../../../../../adapters/activity";
import * as localisationApi from "../../../../../adapters/localisation";
import {Localisations} from "../../../../../models/localisation";
import {Activities} from "../../../../../models/activity";
import Loader from "../../../../../utils/loader";
import {editSalarySuccess} from "../../../../../actions/salary";
import {closeSidebar} from "../../../../../actions/rightSidebar";

const SalaryInformationDefaultLabelSelector: React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single);
    const dispatch = useDispatch();
    const [items, setItems] = useState<Localisations|Activities>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        switch (state.type){
            case 'activity':
                activityApi.select({companies: [state.company.id]}).then(data => setItems(data.data)).then(() => setIsLoading(false));
                break;
            case 'localisation':
                localisationApi.select({companies: [state.company.id]}).then(data => setItems(data.data)).then(() => setIsLoading(false));
                break;
        }
    }, [])

    const handleClick = (id: number) => {
        switch (state.type){
            case "activity":
                api.setActivity(salary.id, state.company.id, id).then((data) => dispatch(editSalarySuccess(data.data))).then(() => dispatch(closeSidebar()))
                break;
            case "localisation":
                api.setLocalisation(salary.id, state.company.id, id).then((data) => dispatch(editSalarySuccess(data.data))).then(() => dispatch(closeSidebar()))
                break;
        }
    }

    if (isLoading) return <Loader />

    return <>
        <ul className={'list-group list-group-flush'}>
            {!items.length && <><h4 className={'p-3'}> <i className={'bi bi-search'}></i> Aucune option disponible</h4></>}
            {items.map((i, index) => <li onClick={() => handleClick(i.id!)} key={index} className={'list-group-item list-group-item-action ' + (state.value?.id === i.id ? " active " : "")}>
                    <i className={i.icon} style={{color: i.color}}></i> {i.title}
                </li>)}
        </ul>
    </>
}

export default SalaryInformationDefaultLabelSelector;