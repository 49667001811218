import * as xhr from "../xhr";

interface listPayload {
    offset? : number,
    status? : number[],
    types? : number[],
    dates? : [string|null, string|null],
    salaries?: number[],
    companies?: number[],
}

export function list(requestData: listPayload, signal: AbortSignal){
    return xhr.post('expense/list', requestData, {signal: signal});
}

export function edit(requestData: any, id: number){
    return xhr.post('expense/edit/' + id.toString(), requestData);
}

export function editStatus(requestData: { status: number }, id: number){
    return xhr.post(`expense/edit/${id}/status`, requestData);
}

export function create(requestData: any, options?:xhr.Options){
    return xhr.post('expense/create',  requestData, options);
}

export function get(id: string){
    return xhr.get('expense/show/' + id);
}

export function cancel(id: number){
    return xhr.get('expense/delete/' + id);
}

export function downloadFile(id: string, options?:xhr.Options){
    return xhr.get('expense/justification/'+ id +'/download', options);
}