import * as React from "react";

import EventOfferShow from "../../../components/event/offer/show";

const EventOfferPage : React.FC = () => {
    return (
        <div id={'calendarPage'} className={'page container-fluid position-relative d-flex flex-column h-100'}>
            <EventOfferShow />
        </div>
    );
}

export default EventOfferPage;