import * as xhr from "../xhr";
import {get, post} from "../xhr";

interface requestData {
    start: Date,
    end: Date,
    week?: number,
    daysOfWeek: string[],
    accountingType: number,
    company: number,
    activity: string,
    localisation: string,
    weekNumber?: number,
    year?: number,
    salary?: number,
    template?: number,
}

export function create(requestData: requestData){
    return post(`event/create`, requestData);
}

export function edit(requestData: requestData, eventId: number){
    return post(`event/${eventId}/edit`, requestData);
}

export function cancel(eventId: number){
    return get(`event/${eventId}/cancel`);
}

export function cancelOnce(event: number, requestData: any){
    return xhr.post(`event/${event}/cancel_once`, requestData);
}

export function labourDayWorked(eventId: number, requestData: any){
    return xhr.post(`event/${eventId}/labour_day`, requestData);
}

export function sub(eventId: number, substituteId: number, requestData: {exDate: string}){
    return xhr.post(`event/${eventId}/substitute/${substituteId}`, requestData);
}