import React, {useState} from "react";
import {useDispatch} from "react-redux";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {SingleCompanySelected, SingleCompanySelector} from "../../../utils/companySelector/single";
import {Company} from "../../../models/companies";
import {setGenerated} from "../../../actions/calendar";
import {closeSidebar} from "../../../actions/rightSidebar";

const PlannerForm:React.FC = () => {

    const dispatch = useDispatch();
    const [company, setCompany] = useState<Company>();
    const [displayCompanySelector, setDisplayCompanySelector] = useState<boolean>(true);

    if (displayCompanySelector){
        return <SingleCompanySelector
            onSubmit={(company) => {
                setCompany(company)
                setDisplayCompanySelector(false)
            }}
            onClose={() => setDisplayCompanySelector(false)}
            initialValue={company?.id}
        />
    }

    return <>
        <div className="p-3">
            <div className="col-12 mb-3">
                <SingleCompanySelected
                    onDelete={() => setCompany(undefined)}
                    onOpen={() => setDisplayCompanySelector(true)}
                    initialCompany={company}
                    initialValue={company?.id}
                />
            </div>
            <div className="col-12 mb-3">
                <p>
                  <i className={"bi bi-geo-alt text-primary"}> </i>  Emplacement
                </p>
                {company && <ul className={'list-group list-group-flush'}>
                    {company.localisations?.map(item => <li onClick={() => {
                        dispatch(setGenerated(true))
                        dispatch(closeSidebar())
                    }} className={'list-group-item list-group-item-action'}>
                        <i className={item.icon}> </i> {item.title}
                    </li>)}
                </ul>}
            </div>
        </div>
    </>
}

export default PlannerForm;