import React, {SyntheticEvent} from "react";

const BookingDefaultPopupListButton: React.FC<{onClick?: (e: SyntheticEvent) => void}> = (props) => {

    const {onClick} = props

    return <button type="button" onClick={onClick} className="btn btn-sm btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#defaultBookingModal">
        <i className={'bi bi-front'}> </i>
    </button>
}

export default BookingDefaultPopupListButton;