import React, { useState, useEffect } from "react"

import { useHistory } from "react-router-dom"
import { RootStateOrAny, useSelector } from "react-redux"

interface IProps {}

const HelpItem: React.FC<IProps> = () => {
  const { push } = useHistory()

  const { helpProductSheetIds } = useSelector((state: RootStateOrAny) => state.header);

  const [hasToOpenModal, setHasToOpenModal] = useState<boolean>(false)

  useEffect(() => {
    if (helpProductSheetIds.length > 0) {
      setHasToOpenModal(true)
    } else {
      setHasToOpenModal(false)
    }
  }, [helpProductSheetIds])

  return (
    <div>
      <a
        role="button"
        data-bs-toggle={hasToOpenModal ? "modal" : undefined}
        data-bs-target={hasToOpenModal ? "#helpInfosModal" : undefined}
        onClick={hasToOpenModal ? undefined : () => push('/help')}
      >
        <i
          className="bi-question-circle"
          style={{
            fontSize: '1.8rem',
            color: '#727cf5',
          }}
        />
      </a>
    </div>
  )
}

export default HelpItem