export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';

export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';

export const SHOW_COMPANY = 'SHOW_COMPANY';
export const SHOW_COMPANY_SUCCESS = 'SHOW_COMPANY_SUCCESS';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

export const MODELS = [
    {
        'min': 1,
        'max': 2,
        'monthly': {
            'amount': 0.0,
            'tax_rate': 20,
            'model_id': 46165012
        },
        'annual': {
            'amount': 0.0,
            'tax_rate': 20,
            'model_id': 46165012
        }
    },
    {
        'min': 1,
        'max': 8,
        'monthly': {
            'amount': 49.90,
            'tax_rate': 20,
            'model_id': 46009113
        },
        'annual': {
            'amount': 499.0,
            'tax_rate': 20,
            'model_id': 46009660
        },
    },
    {
        'min': 9,
        'max': 16,
        'monthly': {
            'amount': 69.99,
            'tax_rate': 20,
            'model_id': 46009190
        },
        'annual': {
            'amount': 699.90,
            'tax_rate': 20,
            'model_id': 46009710
        },
    },
    {
        'min': 17,
        'max': 24,
        'monthly': {
            'amount': 89.99,
            'tax_rate': 20,
            'model_id': 46009244
        },
        'annual': {
            'amount': 899.90,
            'tax_rate': 20,
            'model_id': 46009793
        },
    },
    {
        'min': 25,
        'max': 32,
        'monthly': {
            'amount': 109.99,
            'tax_rate': 20,
            'model_id': 46009313
        },
        'annual': {
            'amount': 1099.90,
            'tax_rate': 20,
            'model_id': 46009832
        },
    },
    {
        'min': 33,
        'max': 40,
        'monthly': {
            'amount': 129.99,
            'tax_rate': 20,
            'model_id': 46009339
        },
        'annual': {
            'amount': 1299.90,
            'tax_rate': 20,
            'model_id': 46009872
        },
    },
    {
        'min': 41,
        'max': 48,
        'monthly': {
            'amount': 149.99,
            'tax_rate': 20,
            'model_id': 46009381
        },
        'annual': {
            'amount': 1499.90,
            'tax_rate': 20,
            'model_id': 46009924
        },
    },
]