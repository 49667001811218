import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../../adapters/admin/salary";
import * as actions from "../../../../actions/salary";
import * as models from "../../../../models/salary";
import {Link, useParams} from "react-router-dom";
import {setPageTitle} from "../../../../actions/header";
import LoaderList from "../../../../utils/loaderList";
import {axiosError} from "../../../../actions/axios";
import {PAGINATION} from "../../../../constants/global";

const AdminSalaryList: React.FC = () => {

    const state = useSelector((state: RootStateOrAny)  => state.salary);
    const [offset, setOffset] = useState<number>(0);
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [query, setQuery] = useState<string|undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFiltering, setFiltering] = useState<boolean>(false);
    const dispatch = useDispatch();
    const params: {id: string} = useParams();

    useEffect(() => {
        dispatch(setPageTitle('Admin', 'Collaborateurs'))

        dispatch(actions.fetchSalaries())
        setIsLoading(true);

        api.list({query: query, offset: 0}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            dispatch(actions.fetchSalariesSuccess(data.data))
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => dispatch(axiosError(error)))
        return () => {

        }
    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setFiltering(true);
                setOffset(0)
                api.list({query: query, offset: 0}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchSalariesSuccess(data.data))
                    setFiltering(false);
                    setOffset( 1);
                }).catch(error => dispatch(axiosError(error)))
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if(scrollBottom){
            if (offset && !isLast){
                setIsLoading(true)
                api.list({query: query, offset: offset}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchSalariesSuccess([...state.payload, ...data.data]))
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                    setScrollBottom(false)
                }).catch(error => dispatch(axiosError(error)))
            }

            setScrollBottom(false);
        }

        // let container = document.getElementById('listContainer')!
        // container.addEventListener("scroll", onScrollBottom)

        // return () => container.removeEventListener("scroll", onScrollBottom)

    }, [scrollBottom])

    return (
        <>
            <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white border-end overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
                <div className="w-100">
                    <div className="sticky-top bg-light d-flex flex-column">
                        <div className="col p-0">
                            <div className="input-group">
                                    <span className="input-group-text bg-white border-end border-0" id="search-addon">
                                        {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : <i className="bi bi-search"> </i>}
                                    </span>
                                <input type="search" onChange={(e) => {
                                    setQuery(e.target.value)
                                }} className="form-control border-0 bg-white" placeholder="Rechercher..." aria-describedby="search-addon" />
                            </div>
                        </div>
                    </div>
                    {state.payload.length ? state.payload.map((salary: models.Salary, index: number) => ([
                            <Link to={"/admin/salary/" + salary.id} key={index} className={"px-3 list-group-item list-group-item-action" + (state.single && state.single.id === salary.id ? " active" : '')}>
                                <div className="d-flex align-items-center">
                                    <div className="col">
                                        <h4 className={'mb-0'}> {salary.title}</h4>
                                        <div className="clearfix"> </div>
                                        <small>
                                            {salary.information?.job.title}
                                        </small>
                                    </div>
                                    <div className="col-auto">
                                        <div className="color-circle col-auto">
                                            <div className="p-1 shadow" style={{backgroundColor: `${salary.information?.job.color}`, opacity: 0.8}}> </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ]))
                        :
                        !isLoading ? <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
                            Aucun collaborateur
                        </button> : ''}
                    {!isLoading && !isLast && state.payload.length && <button onClick={() => setScrollBottom(true)} className={'list-group-item text-primary text-center list-group-item-action'}>
                        <i className={'bi bi-arrow-repeat'}> </i> Charger plus
                    </button>}
                    {isLoading && !state.payload.length && <LoaderList title={true} listLength={50} description={true} leftCircle={true} />}
                    {isLoading && !!state.payload.length && <LoaderList title={true} listLength={1} description={true} leftCircle={true} />}
                </div>
            </div>
        </>
    );
}

export default AdminSalaryList;