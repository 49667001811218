import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";

import { closeSidebar, submitSidebar } from "../../../actions/rightSidebar";

import * as api from "../../../adapters/team";

import * as actions from "../../../actions/team";
import { showAlertSuccess } from "../../../actions/alert";
import { axiosError } from "../../../actions/axios";

import FormError from "../../../utils/formError";

const TeamAdd:React.FC = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const initialValues = {
        title: "",
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Veuillez remplir le nom d'équipe"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar())
            api.create(values).then(_data => {
                dispatch(actions.addTeamSuccess(_data.data))
                dispatch(showAlertSuccess("Équipe ajoutée !"))
                dispatch(closeSidebar());
                push(`/team/${_data.data.id}`)
            }).catch(error => dispatch(axiosError(error)))
        }
    });

    return (
        <div className={'p-1 p-md-3'}>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} name={'title'} id={'title'} className="form-control"/>
                        <label htmlFor="title">
                        <i className={'bi bi-file-font-fill text-primary'}> </i> Titre
                        </label>
                        <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
                    </div>
                </div>
                <button className={'btn btn-outline-primary w-100'} type={'submit'}>
                    <i className={'bi bi-plus-circle'}> </i> Ajouter
                </button>
            </form>
        </div>
    )
}

export default TeamAdd;