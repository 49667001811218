import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/localisation";
import * as actions from "../../../actions/localisation";
import * as models from "../../../models/localisation";
import {Link, useParams} from "react-router-dom";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_LOCALISATION} from "../../../constants/rightSidebar";
import {setPageTitle} from "../../../actions/header";
import * as accessRights from "../../../constants/accessRight";
import {axiosError} from "../../../actions/axios";
import {Granted} from "../../../security/granted";

const LocalisationList: React.FC = () => {

    const state = useSelector((state: RootStateOrAny)  => state.localisation);
    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const [query, setQuery] = useState<string>();
    const [activated, setActivated] = useState<boolean>(true);

    useEffect(() => {

        dispatch(setPageTitle('Emplacements'))

        api.select({activated: activated}).then(data => {
            dispatch(actions.fetchLocalisationsSuccess(data.data))
        }).catch(error => dispatch(axiosError(error)))
    }, [activated])

    return <><div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
            <div className="d-flex flex-column position-sticky top-0 bg-white" style={{zIndex: 100}}>
                <div className="col">
                    <div className="col">
                        <div className="input-group">
                                    <span className="input-group-text bg-white border-0" id="search-addon">
                                        <i className="bi bi-search"> </i>
                                    </span>
                            <input type="search" onChange={(e) => {
                                setQuery(e.target.value)
                            }} className="form-control bg-white border-0" placeholder="Rechercher..." aria-describedby="search-addon" />
                        </div>
                    </div>
                </div>
                {Granted(accessRights.EDIT_LOCALISATION) && <div className="col">
                    <div className="row">
                        <div className="col">
                            <button onClick={() => dispatch(openSidebar(FORM_NEW_LOCALISATION))}
                                    className='btn btn-light w-100'>
                                <i className={'bi bi-plus-circle'}> </i> Ajouter
                            </button>
                        </div>
                        <div className="col">
                            <button data-bs-toggle="modal" data-bs-target="#filterLocalisationModal" className='btn btn-light w-100'>
                                <i className={'bi bi-filter'}> </i> Filtrer
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
            {state.payload.length ? state.payload.filter((localisation: models.Localisation) => !query || localisation.title.toLowerCase().includes(query.toLowerCase())).map((localisation: models.Localisation, index: number) => ([
                    <Link to={"/localisation/" + localisation.id} key={index} className={"list-group-item list-group-item-action" + (Number(params.id) === localisation.id && " active")}>
                        <div className="d-flex align-items-center">
                            <div className={'flex-grow-1'}>
                                {localisation.title}
                                <div className="clearfix"> </div>
                                <div className="text-light-s">
                                    <i className={'bi bi-shop'}> </i> {localisation.company.title}
                                </div>
                            </div>
                            <div className="flex-grow-0">
                                <div className="color-circle">
                                    <div className="shadow" style={{backgroundColor: `${localisation.color}`, opacity: 0.8}}>
                                        {localisation.icon ? <i className={localisation.icon}> </i> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-0">
                            </div>
                        </div>
                    </Link>
                ]))
                :
                <button key={0} type="button" className="list-group-item list-group-item-action" aria-current="true" disabled>
                    Aucune localisation
                </button>
            }
        </div>

        <div className="modal fade" id="filterLocalisationModal" tabIndex={-1} aria-labelledby="filterLocalisationModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Filtrer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 mb-3">
                            <h4>
                                Statut
                            </h4>
                            <div className="form-check">
                                <input defaultChecked={true} className="form-check-input" onChange={e => setActivated(e.target.checked)} type="radio" name="status" id="statusActivated" />
                                <label className="form-check-label" htmlFor="statusActivated">
                                    Activé
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={e => setActivated(!e.target.checked)} type="radio" name="status" id="statusDeactivated" />
                                <label className="form-check-label" htmlFor="statusDeactivated">
                                    Désactivé
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default LocalisationList;