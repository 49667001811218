import React, {useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import {User} from "../../../../../../models/auth";
import {CompanyGroup} from "../../../../../../models/companyGroup";
import format from "../../../../../../utils/locale";
import UserCompanyGroupShow from "../show";


const UserCompanyGroupList:React.FC = () => {

    const user:User = useSelector((state: RootStateOrAny) => state.user.single)
    const [single, setSingle] = useState<CompanyGroup>();

    if (single){
        return <UserCompanyGroupShow setSingle={setSingle} single={single} />
    }

    return <>
        <div className="row">
            {user.companyGroups.map((companyGroup: CompanyGroup) => <div className={'col-lg-4 col-md-6'}>
                <div className="card shadow-sm p-1 p-md-3">
                    <div className="d-flex align-items-center">
                        <h4 className={"flex-grow-1"}>
                            {companyGroup.title}
                        </h4>
                        <div className={'alert flex-grow-0' + (companyGroup.activated ? " alert-success" : " alert-danger")}>
                            {companyGroup.activated ? <> <i className={'bi bi-check2-circle'}> </i> Activé </> : <> <i className={'bi bi-dash-circle'}> </i> Désactiver </>}
                        </div>
                    </div>
                    <p className={'mb-1'}>
                        <i className={'bi bi-shop text-primary'}> </i> {companyGroup.companies?.length} Points de ventes
                    </p>
                    <p className={'mb-1'}>
                        <i className={'bi bi-people text-primary'}> </i> {companyGroup.salariesCount || 0} Collaborateurs
                    </p>
                    <p className={'mb-1'}>
                        <i className={'bi bi-calendar-check text-primary'}> </i> Créé le {companyGroup.createdAt}
                    </p>
                    <button className={"btn btn-outline-primary"} onClick={() => setSingle(companyGroup)}>
                       <i className={'bi bi-eye'}> </i> Afficher
                    </button>
                </div>
            </div>)}
        </div>
    </>
}

export default UserCompanyGroupList;