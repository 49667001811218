import {Salaries, Salary} from "../../models/salary";
import getUser from "../getUser";

class AccessRightChecker {

     static granted(right: string){
         const salary: Salary = getUser()?.currentSalary!;
         
         if (!salary) return false;

         if (salary.rights.includes(right)){
             return true;
         }

        let ret:string[] = [];

        return ret.concat(...salary.managed.map(m => m.rights)).includes(right);
    }

     static grantedFor(right: string, target: number){
         const salary: Salary = getUser().currentSalary!;

         if (!salary) return false;

        return salary.rights.concat(...salary.managed.filter(m => m.managed.id === target).map(m => m.rights)).includes(right);
     }

     static grantedForMany(right: string, targets: number[]){
         for (let i in targets){
             if (!this.grantedFor(right, targets[i])){
                 return false;
             }
         }

        return true;
    }

     static hasGlobalRight(right: string){
         const salary: Salary = getUser()?.currentSalary;
         
         if (!salary) return false;

        return salary.rights.includes(right);
    }

     static hasManagerRight(right: string){
         const salary: Salary = getUser()?.currentSalary;

         if (!salary) return false;

         let ret:string[] = [];
         return ret.concat(...salary.managed.map(m => m.rights)).includes(right);
    }
}

export default AccessRightChecker;