import * as types from "../../../constants/template/calendar";
import * as models from "../../../models/template/calendar";
import {EventClickArg} from "@fullcalendar/react";
import React from "react";
import {Resource} from "../../../models/template";

export const fetchCalendar = () => {
    return {
        type: types.FETCH_TEMPLATE_CALENDAR,
    }
}

export const fetchCalendarSuccess = (events: any[], resources: any[], workingTimes: models.WorkingTimes) => {
    return {
        type: types.FETCH_TEMPLATE_CALENDAR_SUCCESS,
        events: events,
        workingTimes: workingTimes,
        resources: resources,
    }
}

export const editResourceSuccess = (resource: Resource) => {
    return {
        type: types.EDIT_RESOURCE_SUCCESS,
        resource: resource,
    }
}

export const fetchCalendarFailure = () => {
    return {
        type: types.FETCH_TEMPLATE_CALENDAR_FAILURE,
    }
}

export const showEventPop = (eventClickArg: EventClickArg) => {
    return {
        type: types.SHOW_TEMPLATE_EVENT_POP,
        eventClickArg: eventClickArg
    }
}

export const hideEventPop = () => {
    return {
        type: types.HIDE_TEMPLATE_EVENT_POP,
    }
}

export const refresh = () => {
    return {
        type: types.REFRESH_TEMPLATE
    }
}

export const setGroupBy = (groupBy: number) => {
    return {
        type: types.SET_TEMPLATE_GROUP_BY,
        groupBy: groupBy
    }
}
