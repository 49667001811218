import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/booking/exception";
import {BookingExceptions} from "../../../../models/booking/group";
import {axiosError} from "../../../../actions/axios";
import format from "../../../../utils/locale";
import {useDispatch} from "react-redux";
import {PAGINATION} from "../../../../constants/global";

const BookingExceptionHistory:React.FC = () => {

    const [exceptions, setExceptions] = useState<BookingExceptions>([])
    const [offset, setOffset] = useState<number>(0)
    const [isLast, setIsLast] = useState<boolean>(false)
    const [scrollBottom, setScrollBottom] = useState<boolean>(false)
    const dispatch = useDispatch();

    useEffect(() => {
        api.list({offset: offset}).then(data => {
            setExceptions(data.data)
            setIsLast(data.data.length < PAGINATION);
            setOffset(prevState => prevState + 1)
        });
    }, [])

    useEffect(() => {
        if(scrollBottom && offset && !isLast){
            api.list({offset: offset}).then(data => {
                setIsLast(data.data.length < PAGINATION);
                setExceptions([...exceptions, ...data.data])
                setOffset(prevState => prevState + 1)
                setScrollBottom(false)
            }).catch(error => dispatch(axiosError(error)))
        }

        let container = document.getElementsByClassName("offcanvas-body")[0]!
        container.addEventListener("scroll", onScrollBottom)

        return () => container.removeEventListener("scroll", onScrollBottom)

    }, [scrollBottom])

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    return <div id={'listContainer'} className={'w-100 h-100'}>
        <div className={"row-cols-1 my-1 my-md-3"}>
            {exceptions.map((e, index) => <div key={index} className={'p-1 p-md-3 border border-1 shadow-sm mb-1 mb-md-3'}>
                <div className="d-flex mb-2 align-items-center">
                    <div className="flex-grow-1">
                        <i className={'bi bi-person text-primary'}> </i> {e.salary.title}
                        {e.substitute && <> <i className={'bi bi-arrow-down-up'}> </i> Remplacé par {e.substitute.title}</>}
                        <div className="clearfix"> </div>
                        <span className="form-text">
                            {format(new Date(e.createdAt), "E dd MMM uuuu HH:mm")}
                        </span>
                    </div>
                    <div className="flex-grow-0">
                        {e.isCanceled ? <span className={"alert alert-danger p-2"}><i className={'bi bi-x'}> </i> Supprimé</span> : <span className={"alert p-2 alert-primary"}><i className={'bi bi-pencil'}> </i> Modifié</span>}
                    </div>
                </div>
                <div className="w-100 mb-2">
                    Avant : {format(new Date(e.instance.beginAt), 'E dd MMM uuuu HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(e.instance.endAt), 'E dd MMM uuuu HH:mm')}
                </div>
                <hr className={'my-1'} />
                <div className="w-100">
                    Après : {format(new Date(e.beginAt), 'E dd MMM uuuu HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(e.endAt), 'E dd MMM uuuu HH:mm')}
                </div>
            </div>)}
        </div>
    </div>
}

export default BookingExceptionHistory;