import { IHelpProductSheet } from "../../@types/models/Helps/IHelpProductSheet"

export const HELP_PRODUCTS_SHEETS: IHelpProductSheet[] = [
  {
    id: 1,
    title: 'Ajouter ou désactiver un collaborateur',
    content: [
      {
        img: '/img/helps/help_ps_1_sc_1.jpg',
        text: 'Pour ajouter un nouveau collaborateur, rendez-vous dans l’onglet "Personnel", "Collaborateurs", puis cliquez sur " + "',
      },
      {
        img: '/img/helps/help_ps_1_sc_2.jpg',
        text: 'Les "bases d’absences" correspondent aux congés déjà pris (à retrouver sur la dernière fiche de paie de votre salarié). Si votre collaborateurs a des heures supplémentaires en attente d’être récupérées, renseignez les dans la case "base d’heures supplémentaires"',
      },
      {
        img: '/img/helps/help_ps_1_sc_3.jpg',
        text: 'Pour désactiver un collaborateur, rendez-vous de nouveau dans l’onglet "Personnel", "Collaborateurs", sélectionnez le collaborateur de votre choix, puis dans l’onglet "Réglages" cliquez sur "Désactiver"',
      }
    ]
  },
  {
    id: 2,
    title: 'Programmer un emploi du temps type',
    content: [
      {
        img: '/img/helps/help_ps_2_sc_1.jpg',
        text: 'Pour programmer un emploi du temps type à un collaborateur, rien de plus simple. Rendez-vous dans l’onglet "Personnel", puis "Collaborateurs". Sélectionnez le salarié de votre choix et cliquez ensuite sur l’onglet "Emploi du temps"',
      },
      {
        img: '/img/helps/help_ps_2_sc_2.jpg',
        text: 'Donnez un titre à celui-ci. Evitez les titres tels que "semaine 1" ou "semaine 2" car vous êtes sur le point de planifier toutes vos semaines sur un seul emploi du temps. Si vous souhaitez que ce dernier se prolonge le plus longtemps possible, ne renseignez pas de date de fin.',
      },
      {
        img: '/img/helps/help_ps_2_sc_3.jpg',
        text: 'Sélectionnez la semaine que vous souhaitez programmer (ici, semaine 1). En maintenant le clique gauche de votre souris, étirez alors les créneaux sur les jours de votre choix . Une page s’ouvre, vérifiez les horaires, puis validez.',
      }
    ]
  },
  {
    id: 3,
    title: 'Modifications ponctuelles',
    content: [
      {
        img: '/img/helps/help_ps_3_sc_1.jpg',
        text: 'Un collaborateur fait des heures supplémentaire et un autre part plus tôt aujourd’hui ? Pas de panique ! Nullement besoin de toucher à votre emploi du temps structurel/type ! Sur le planning général, cliquez sur le créneau à modifier et renseignez simplement les horaires réelles.',
      },
      {
        img: '/img/helps/help_ps_3_sc_2.jpg',
        text: 'Plus rapide encore, en vue "Timeline", vous avez la possibilité d’étirer directement les créneaux à votre guise.',
      },
      {
        img: '/img/helps/help_ps_3_sc_3.jpg',
        text: 'Sur le planning personnel de votre collaborateur, cliquez sur le créneau à modifier ou étirez le.',
      }
    ]
  },
  {
    id: 4,
    title: 'Répartition des heures contrat',
    content: [
      {
        img: '/img/helps/help_ps_4_sc_1.jpg',
        text: 'En officine, il est courant que certains salariés aient des heures contrat à répartir sur différentes semaines. Pour gérer cette répartition, rendez- vous dans l’onglet "Collaborateur", cliquez sur le salarié de votre choix puis “Informations” et "Réglages"',
      },
      {
        img: '/img/helps/help_ps_4_sc_2.jpg',
        text: 'Activez la répartition des heures contrat. Renseignez le nombre de semaines à répartir puis validez. Renseignez ensuite la semaine en cours puis validez. Pour finir, entrez le nombre d’heures par semaine et validez à nouveau.',
      },
      {
        img: '/img/helps/help_ps_4_sc_3.jpg',
        text: 'Dans cet exemple, votre salarié est en contrat de 35 heures. Ses heures sont réparties sur 2 semaines comme ceci : Semaine 1 = 34 heures et semaine 2 = 36 heures.',
      }
    ]
  },
  {
    id: 5,
    title: 'Programmer un congé ou une absence',
    content: [
      {
        img: '/img/helps/help_ps_5_sc_1.jpg',
        text: 'Chaque collaborateur est en mesure d’effectuer une demande de congé depuis son espace utilisateur. Pour traiter ces demandes, rendez-vous dans l’onglet "Tableau de bord". Cliquez ensuite sur l’onglet "Congés"',
      },
      {
        img: '/img/helps/help_ps_5_sc_2.jpg',
        text: 'Vous disposez également d’un onglet dédié aux absences. Pour y accéder, cliquez sur l’onglet "Gestion du temps", puis " Absences". Pour ajouter une absence, cliquez sur le bouton "Ajouter"',
      },
      {
        img: '/img/helps/help_ps_5_sc_3.jpg',
        text: 'Pour modifier le statut d’une absence, cliquez sur celle de votre choix et cliquez ensuite sur le bouton "Modifier" à droite.',
      },
      {
        img: '/img/helps/help_ps_5_sc_4.jpg',
        text: 'Pour supprimer complètement une absence, cliquez sur le collaborateur de votre choix dans l’onglet "Collaborateurs", puis "Absences". Cliquez ensuite sur les pointillés et "Supprimer"',
      },
      {
        img: '/img/helps/help_ps_5_sc_5.jpg',
        text: 'Pour visualiser une absence sur le planning avant de la valider, rendez-vous sur votre planning général. Cliquez ensuite sur l’onglet "Planifié", puis "Absences". Sélectionnez au besoin une vision à la semaine en cliquant sur l’onglet "Mois" ou "Semaine"',
      },
      {
        img: '/img/helps/help_ps_5_sc_6.jpg',
        text: 'Les demandes d’absences en attente apparaissent sur une fenêtre à droite. Acceptez, refusez ou laissez en attente au besoin.',
      }
    ]
  },
  {
    id: 6,
    title: 'Gestion des apprentis et formations',
    content: [
      {
        img: '/img/helps/help_ps_6_sc_1.jpg',
        text: 'Pour programmer un emploi du temps à vos apprentis, gardez à l’esprit que ces derniers doivent aussi avoir 35 heures planifiées par semaine (sauf exceptions). Programmez donc son planning comme vous le feriez pour un salarié en 35 heures contrat, en renseignant également ses journées de cours.',
      },
      {
        img: '/img/helps/help_ps_6_sc_2.jpg',
        text: 'Après avoir crée et planifié l’emploi du temps de votre apprenti, rendez-vous dans l’onglet "Entreprise", puis "Emplacements". Cliquez sur "Ajouter"',
      },
      {
        img: '/img/helps/help_ps_6_sc_3.jpg',
        text: 'Donnez un nom à votre emplacement. Ici, nous l’appellerons “CFA”. Pour un salarié en formation, renseignez "Formation" ou le nom de votre choix. Remplir le formulaire puis valider.',
      },
      {
        img: '/img/helps/help_ps_6_sc_4.jpg',
        text: 'De retour sur l’onglet “emploi du temps” de votre apprenti, cliquez sur les créneaux de votre choix (les créneaux qui correspondent aux journées de CFA dans notre cas). Puis, cliquez sur "modifier"',
      },
      {
        img: '/img/helps/help_ps_6_sc_5.jpg',
        text: 'Cliquez sur "Type d’heures", puis sélectionnez dans le menu déroulant "Absence comptabilisée". Dans “emplacement”, sélectionnez “CFA”',
      },
      {
        img: '/img/helps/help_ps_6_sc_6.jpg',
        text: 'Sur vos plannings, les créneaux apparaissent désormais en “absence justifiée” et seront comptabilisés correctement dans votre synthèse.',
      }
    ]
  },
  {
    id: 7,
    title: 'Programmer une tâche',
    content: [
      {
        img: '/img/helps/help_ps_7_sc_1.jpg',
        text: 'Pour programmer une tâche, cliquez sur l’onglet "Gestion du temps", puis cliquez sur "Ajouter"',
      },
      {
        img: '/img/helps/help_ps_7_sc_2.jpg',
        text: 'Créez un groupe de tâche, nommez le, puis validez.',
      },
      {
        img: '/img/helps/help_ps_7_sc_3.jpg',
        text: 'Rendez-vous ensuite sur le "Tableau de bord" puis cliquez sur "Ajouter" dans le calendrier des tâches, pour programmer celles-ci. Cliquez sur une tâche pour changer son statut.',
      }
    ]
  },
  {
    id: 8,
    title: 'Semaine en cours',
    content: [
      {
        img: '/img/helps/help_ps_8_sc_1.jpg',
        text: 'Pour identifier votre semaine en cours, rien de plus simple. Rendez-vous sur l’onglet "Planning" de votre menu de gauche. Le numéro de votre semaine en cours apparaît au dessus de la liste de vos collaborateurs.',
      },
      {
        img: '/img/helps/help_ps_8_sc_2.jpg',
        text: 'Vous retrouverez également le numéro de votre semaine en cours en cliquant sur l’onglet "Collaborateur". Sélectionnez le collaborateur de votre choix, puis cliquez sur l’icône ressemblant à une petite maison.',
      },
      {
        img: '/img/helps/help_ps_8_sc_3.jpg',
        text: 'Attention ! Le numéro de semaine affiché dans l’onglet "Entreprise", "Point de vente" n’est pas votre semaine en cours. Il s’agit de la semaine renseignée lors de la création du point de vente.',
      }
    ]
  },
  {
    id: 9,
    title: 'Télécharger le planning',
    content: [
      {
        img: '/img/helps/help_ps_9_sc_1.jpg',
        text: 'Pour télécharger le planning, rendez- vous dans l’onglet "Planning". Cliquez ensuite sur l’icône de téléchargement.',
      },
      {
        img: '/img/helps/help_ps_9_sc_2.jpg',
        text: 'Sélectionnez le format de votre choix et renseignez ce que vous souhaitez voir apparaître sur votre planning. Cliquez ensuite sur "Télécharger"',
      },
      {
        img: '/img/helps/help_ps_9_sc_3.jpg',
        text: 'Pour envoyer le planning personnel à vos salariés, cliquez sur l’onglet "Publier"',
      }
    ]
  },
  {
    id: 10,
    title: 'Gestion des droits d’accès',
    content: [
      {
        img: '/img/helps/help_ps_10_sc_1.jpg',
        text: 'Pour gérer les droits d’accès de vos collaborateurs, rien de plus simple, rendez-vous dans l’onglet “Personnel”, puis “Droit d’accès”',
      },
      {
        img: '/img/helps/help_ps_10_sc_2.jpg',
        text: 'Cliquez sur “Ajouter", si vous souhaitez créer un nouveau rôle et gérer ses droits d’accès. Sinon, cliquez simplement sur un rôle déjà existant.',
      },
      {
        img: '/img/helps/help_ps_10_sc_3.jpg',
        text: 'Glissez les boutons sur la droite pour octroyer des droits d’accès selon vos besoins.',
      }
    ]
  },
  {
    id: 11,
    title: 'Proposition de créneaux',
    content: [
      {
        img: '/img/helps/help_ps_11_sc_1.jpg',
        text: 'Il est possible de proposer des heures supplémentaires à vos salariés. Pour ce faire, rendez-vous sur le planning général puis cliquez sur “Ajouter”. Cliquez ensuite sur “Ajouter une offre de créneau”',
      },
      {
        img: '/img/helps/help_ps_11_sc_2.jpg',
        text: 'Remplissez le formulaire, entrez le point de vente et sélectionnez ensuite le ou les collaborateurs que vous souhaitez notifier.',
      },
      {
        img: '/img/helps/help_ps_11_sc_3.jpg',
        text: 'Vos collaborateurs recevront alors un mail ou une notification pour les inviter à postuler à votre offre. Pour valider et visualiser une participation, rendez- vous sur votre "Tableau de bord", puis “Proposition de créneaux" ou sur votre planning général, et cliquez sur l’onglet "Offres de créneaux".',
      }
    ]
  },
  {
    id: 12,
    title: 'Utiliser un template',
    content: [
      {
        img: '/img/helps/help_ps_12_sc_1.jpg',
        text: 'Un template est un emploi du temps "brouillon" que l’on peut appliquer sur une semaine du planning général selon ses besoins. Pour créer un template, rendez-vous dans l’onglet "Gestion du temps" et cliquez sur "Template"',
      },
      {
        img: '/img/helps/help_ps_12_sc_2.jpg',
        text: 'Vous êtes maintenant sur la page des templates. Cliquez sur "Ajouter" pour créer un template vierge. Pour créer un template à partir d’une semaine de votre planning général, cliquez sur l’onglet "Template"',
      },
      {
        img: '/img/helps/help_ps_12_sc_3.jpg',
        text: 'Cliquez ensuite sur "Exporter le planning en template". Vous le retrouverez dans l’onglet "Gestion du temps", "Template". Un fois que vos modifications seront effectuées dessus, appliquer le sur la semaine de votre choix en cliquant cette fois sur "Importer un template"',
      }
    ]
  },
  {
    id: 13,
    title: 'Notes de frais',
    content: [
      {
        img: '/img/helps/help_ps_13_sc_1.jpg',
        text: 'Pour accéder à vos notes de frais, rendez-vous dans l’onglet "Comptabilité" et cliquez sur "Notes de frais" ou retrouvez celles-ci sur votre "Tableau de bord" dans l’onglet "Notes de frais"',
      },
      {
        img: '/img/helps/help_ps_13_sc_2.jpg',
        text: 'Cliquez ensuite sur "Ajouter" pour créer une note de frais en tant qu’administrateur. Vous pouvez également cliquer sur une demande en attente pour la valider ou la refuser (vos salariés peuvent en faire la demande depuis leur application).',
      },
      {
        img: '/img/helps/help_ps_13_sc_3.jpg',
        text: 'Attention ! Ne pas confondre "frais kilométrique" qui concerne les déplacements avec un véhicule personnel et "transport" qui est spécifique aux frais engagés pour les déplacements (parkings, billets de transport).',
      }
    ]
  },
  {
    id: 14,
    title: 'Gestion de la pointeuse',
    content: [
      {
        img: '/img/helps/help_ps_14_sc_1.jpg',
        text: 'Vos collaborateurs pointent avec un code personnel que vous retrouverez dans l’onglet "Collaborateurs", puis "Informations". Ils ont également la possibilité de badger à l’aide d’un QR code à retrouver également dans l’onglet " Informations"',
      },
      {
        img: '/img/helps/help_ps_14_sc_2.jpg',
        text: 'Pour consulter les heures badgées d’un salarié, cliquez sur "Planning" dans l’onglet "Collaborateurs", puis "Planifié". Sélectionnez ensuite “Badgé”',
      },
      {
        img: '/img/helps/help_ps_14_sc_3.jpg',
        text: 'Créneau vert : le salarié a badgé correctement / Créneau orange : le salarié a badgé en retard / Créneau mention "Absent" : créneau non badgé / Créneau rouge : anomalie',
      },
      {
        img: '/img/helps/help_ps_14_sc_4.jpg',
        text: 'En tant qu’administrateur, vous avez également la possibilité de badger une absence à la place de vos salariés ou de la modifier. Pour cela, cliquez sur le créneau (badgé ou non) dans l’onglet "Planning", "Badgé", modifiez les horaires si besoin puis "Valider"',
      },
      {
        img: '/img/helps/help_ps_14_sc_5.jpg',
        text: 'Pour consulter les heures badgées de vos salariés depuis votre planning général, cliquez sur l’onglet "Planifié", puis sélectionnez alors “Badgé”',
      },
      {
        img: '/img/helps/help_ps_14_sc_6.jpg',
        text: 'Le logiciel centralise toutes les absences et retards. Pour les consulter, rendez-vous dans l’onglet "Gestion du temps", puis "Badgeuse"',
      },
      {
        img: '/img/helps/help_ps_14_sc_7.jpg',
        text: 'Il est possible de paramétrer des bornages ainsi que des tolérances sur votre pointeuse. Pour ce faire, rendez-vous sur votre planning général, puis cliquez sur le nom du compte en haut à droite. Cliquez ensuite sur "Réglages"',
      },
      {
        img: '/img/helps/help_ps_14_sc_8.jpg',
        text: 'Sélectionnez ensuite l’onglet "Badgeuse"',
      },
      {
        img: '/img/helps/help_ps_14_sc_9.jpg',
        text: 'Vous avez la possibilité d’activer la prise de photo. Cliquez simplement sur "Prendre les photos sur la badgeuse"',
      }
    ]
  },
  {
    id: 15,
    title: 'Créer un rapport',
    content: [
      {
        img: '/img/helps/help_ps_15_sc_1.jpg',
        text: `Avant de créer le rapport du mois, vous devez vous rendre dans l’onglet “collaborateurs” (personnel > collaborateurs) afin d’accéder à la fiche d’un collaborateur.
        Une fois arrivée sur sa fiche, vous devez aller sur l’onglet “rapport” (personnel > collaborateurs > fiche du collaborateur > rapport).
        Sur cet onglet rapport vous avez le compteur du mois, détaillé en 4 semaines.`,
      },
      {
        img: '/img/helps/help_ps_15_sc_2.jpg',
        text: `Une fois que vous êtes sur la partie “rapport”, vous avez la possibilité de :\n
        • Valider l’ensemble des semaines : “+ Tout valider”
        • Valider semaine par semaine : “+” dans compteur à date`,
      },
      {
        img: '/img/helps/help_ps_15_sc_3.jpg',
        text: `Une fois les heures validées, celles-ci seront automatiquement ajoutées au tableau total, qui regroupe les parties suivantes :\n
        • Initial : les heures accumulées lors des précédentes périodes
        • Validé : les heures validées du mois en cours
        • Heures payées en taux normal/25%/50% : vous pouvez payer les heures supplémentaires à des taux différents en modifiant la case concernée
        • Final : le compteur après validation et attribution des heures payées en prenant en compte le compteur initial`,
      },
      {
        img: '/img/helps/help_ps_15_sc_4.jpg',
        text: `Lorsque vous validez une valeur, celle-ci s’ajoute automatiquement au compteur initial.\n
        • Heures à rattraper : si vous souhaitez faire rattraper des heures (exemple : “-4h), vous devez VALIDER le compteur pour que celles-ci s’ajoutent au compteur et qu’elles ne soient pas retirer du salaire mais à rattraper.
        • Heures supplémentaires : si vous souhaitez envoyer des heures supplémentaires en paie, vous devez les VALIDER, et préciser le taux des HS dans temps de travail (25%,50%...)`,
      },
      {
        img: '/img/helps/help_ps_15_sc_5.jpg',
        text: `Si le collaborateur est en négatif et que vous souhaitez faire un retrait sur salaire (plutôt que de lui faire rattraper une journée), vous ne devez PAS VALIDER la valeur.
        Il en est de même lorsque le collaborateur est en positif.`,
      },
      {
        img: '/img/helps/help_ps_15_sc_6.jpg',
        text: `Dès que les compteurs individuels ont été confirmés, vous pouvez désormais créer votre rapport mensuel !
        Il suffit de se rendre dans la partie rapport (rapports > rapports) puis appuyer sur “ajouter”. Si le mois n’est pas terminé, pensez bien à inclure le prévisionnel.`,
      },
      {
        img: '/img/helps/help_ps_15_sc_7.jpg',
        text: `Vous avez 4 onglets à disposition :\n
        • Temps de travail : récapitulatif des heures travaillées
        • Absences : récapitulatif des absences payées ou non
        • Compteurs : le compteur individuel que vous avez validé en amont (des triangles orange = les heures n’ont pas été validées), vous pouvez également faire vos modifications directement sur cette partie.
        • Heures payées : récapitulatif des heures payées à taux différents (possibilité de modification)`,
      },
      {
        img: '/img/helps/help_ps_15_sc_8.jpg',
        text: `Une fois que vous avez validé et vérifié toutes les informations sur le rapport, vous devez le verrouiller pour le télécharger.\n
        Dans la partie téléchargement du rapport, vous devez sélectionner l’établissement et les collaborateurs concernés.
        Vous avez plusieurs possibilités de fichiers à télécharger :
        • Sur Excel : variables de paies, émargements, compteurs, notes de frais
        • Sur Silae : absences, matrice, activité journalière`,
      }
    ]
  },
  {
    id: 16,
    title: 'Stocker des fichiers',
    content: [
      {
        img: '/img/helps/help_ps_16_sc_1.jpg',
        text: 'Pour stocker un fichier, rendez-vous dans l’onglet "Collaborateur", sélectionnez le salarié de votre choix. Cliquez ensuite sur "Stockage" et "Ajoutez"',
      },
      {
        img: '/img/helps/help_ps_16_sc_2.jpg',
        text: 'Une page s’ouvre, il vous suffit ensuite de glisser les documents utiles (diplômes, carte vitale, fiches de paie...) dans l’encadré, puis cliquez sur "Enregistrer"',
      },
      {
        img: '/img/helps/help_ps_16_sc_3.jpg',
        text: 'Bravo, vous êtes enfin débarrassés des feuilles volantes et pouvez désormais accéder aux documents de vos collaborateurs directement depuis votre logiciel Sivan.',
      }
    ]
  },
  {
    id: 17,
    title: 'Utiliser la messagerie',
    content: [
      {
        img: '/img/helps/help_ps_17_sc_1.jpg',
        text: 'Pour créer une discussion, rendez-vous dans l’onglet "Messagerie", puis cliquez sur "Ajouter"',
      },
      {
        img: '/img/helps/help_ps_17_sc_2.jpg',
        text: 'Une page s’ouvre à droite. Donnez un titre à la discussion et sélectionnez les collaborateurs que vous souhaitez ajouter. Cliquez ensuite sur "Ajouter"',
      },
      {
        img: '/img/helps/help_ps_17_sc_3.jpg',
        text: 'Félicitation, votre discussion a bien été créée. Cliquez dessus pour écrire un message à vos collaborateurs.',
      }
    ]
  },
  {
    id: 18,
    title: 'Générer un document',
    content: [
      {
        img: '/img/helps/help_ps_18_sc_1.jpg',
        text: 'Pour générer un document, commencez par créer un modèle. Pour cela, rendez- vous dans l’onglet "Entreprise" et cliquez sur "Documents", puis "Ajouter"',
      },
      {
        img: '/img/helps/help_ps_18_sc_2.jpg',
        text: 'Créez votre document en plaçant des "variables" aux endroits utiles puis cliquez sur "Ajouer"',
      },
      {
        img: '/img/helps/help_ps_18_sc_3.jpg',
        text: 'Vous pouvez maintenant générer votre document par collaborateur dans l’onglet "Personnel", puis "Collaborateurs". Sélectionnez le salarié de votre choix et cliquez sur "Documents", puis "Générer"',
      }
    ]
  }
]