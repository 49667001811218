import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import format from "../../../../utils/locale";
import {getAccountingTypeChoice} from "../../../../constants/booking";
import * as exceptionApi from "../../../../adapters/event/exception";
import * as eventApi from "../../../../adapters/event";
import * as exchangeApi from "../../../../adapters/eventExchange";
import * as absenceApi from "../../../../adapters/absence";
import * as calendarTypes from "../../../../constants/calendar";
import {TYPE_EVENT, TYPE_EVENT_EXCEPTION} from "../../../../constants/event";
import SmallLoader from "../../../../utils/loader/small";
import {showAlertSuccess} from "../../../../actions/alert";
import {useDispatch} from "react-redux";
import * as accessRights from "../../../../constants/accessRight";
import {Granted} from "../../../../security/granted";
import {TYPE_CP} from "../../../../constants/absence";

interface Interface {
    arg: EventClickArg,
    handleEventChange: (ev: any) => any,
    calendar: string,
    setCalendarAbsenceSidebarProps: React.Dispatch<any>,
    setCalendarEventSidebarProps: React.Dispatch<any>,
    close: () => any,
    setCalendarSignedSidebarProps: React.Dispatch<any>,
}

const EventShow:React.FC<Interface> = (props) => {

    const {arg, handleEventChange, calendar, setCalendarAbsenceSidebarProps, setCalendarSignedSidebarProps, setCalendarEventSidebarProps, close} = props;

    const dispatch = useDispatch();
    const [removingEvent, setRemovingEvent] = useState<boolean>(false)
    const [removingAbsence, setRemovingAbsence] = useState<boolean>(false)

    const removeEvent = () => {
        if (arg){
            setRemovingEvent(true)
            switch (arg.event.extendedProps.eventType){
                case TYPE_EVENT:
                    eventApi.cancelOnce(arg.event.extendedProps.eventId, {
                        start: arg.event.start,
                        end: arg.event.end,
                        accountingType: arg.event.extendedProps.accountingType,
                        salary: arg.event.extendedProps.salary.id,
                        company: arg.event.extendedProps.company.id,
                        localisation: arg.event.extendedProps.localisation?.id,
                        activity: arg.event.extendedProps.activity?.id,
                        comment: arg.event.extendedProps.comment,
                        exdate: arg.event.start
                    }).then((resp) => removeEventSuccess(resp.data));
                    break;
                case TYPE_EVENT_EXCEPTION:
                    exceptionApi.cancel(arg.event.extendedProps.exceptionId).then((resp) => removeEventSuccess(resp.data));
                    break;
            }
        }
    }

    const [absenceInfoLoading, setAbsenceInfoLoading] = useState(false);
    const [nbDays, setNbDays] = useState<number>()
    const [nextWorkingDay, setNextWorkingDay] = useState<string>()
    useEffect(() => {
        setNbDays(undefined);
        setNextWorkingDay(undefined);
        if (arg.event.extendedProps.absence){
            setAbsenceInfoLoading(true)
            absenceApi.days(arg.event.extendedProps.absence.id).then(resp => {
                setNbDays(resp.data.nbDays);
                if (resp.data.nextWorkingDay){
                    setNextWorkingDay(resp.data.nextWorkingDay);
                }

                setAbsenceInfoLoading(false);
            })
        }
    }, [arg.event.extendedProps.absence])

    const [removingSub, setRemovingSub] = useState(false)
    const cancelSub = () => {
        setRemovingSub(true)
        exceptionApi.removeSub(arg.event.extendedProps.exceptionId)
            .then(() => removeSubSuccess(arg.event.extendedProps));
    }

    const [removingExchange, setRemovingExchange] = useState(false)
    const cancelExchange = () => {
        setRemovingExchange(true)
        exchangeApi.cancel(arg.event.extendedProps.exchangeId)
            .then(resp => removeExchangeSuccess({ids: [resp.data.finalEvent.salary.id, resp.data.finalTarget.salary.id]}));
    }

    const removeAbsence = () => {
        if (arg.event.extendedProps.absence){
            setRemovingAbsence(true);
            absenceApi.cancel(arg.event.extendedProps.absence.id).then((data) => removeAbsenceSuccess(data.data))
        }
    }

    const removeEventSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => dispatch(showAlertSuccess("Créneau supprimé")))
            .then(() => close())
            .then(() => setRemovingEvent(false))
            .then(() => true)
    }

    const removeSubSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => close())
            .then(() => dispatch(showAlertSuccess("Remplacement supprimé")))
            .then(() => setRemovingSub(false))
            .then(() => true)
    }

    const removeExchangeSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => close())
            .then(() => dispatch(showAlertSuccess("Échange supprimé")))
            .then(() => setRemovingSub(false))
            .then(() => true)
    }

    const removeAbsenceSuccess = (ev: any) => {
        return Promise.all([
            handleEventChange(ev)
        ])
            .then(() => setRemovingAbsence(false))
            .then(() => dispatch(showAlertSuccess('Absence supprimé')))
            .then(() => close())
    }

    const setLabourDayWorked = (worked: boolean) => {
        if (arg){
            switch (arg.event.extendedProps.eventType){
                case TYPE_EVENT:
                    exceptionApi.create( {
                        start: arg.event.start,
                        end: arg.event.end,
                        accountingType: arg.event.extendedProps.accountingType,
                        salary: arg.event.extendedProps.salary.id,
                        company: arg.event.extendedProps.company.id,
                        localisation: arg.event.extendedProps.localisation?.id,
                        activity: arg.event.extendedProps.activity?.id,
                        comment: arg.event.extendedProps.comment,
                        labourDayWorked: worked,
                        exdate: arg.event.start,
                    }, arg.event.extendedProps.eventId)
                        .then((resp) => handleEventChange(resp.data))
                        .then(() => close());
                    break;
                case TYPE_EVENT_EXCEPTION:
                    exceptionApi.edit({
                        start: arg.event.start,
                        end: arg.event.end,
                        accountingType: arg.event.extendedProps.accountingType,
                        salary: arg.event.extendedProps.salary.id,
                        company: arg.event.extendedProps.company.id,
                        localisation: arg.event.extendedProps.localisation?.id,
                        activity: arg.event.extendedProps.activity?.id,
                        labourDayWorked: worked,
                        comment: arg.event.extendedProps.comment,
                    }, arg.event.extendedProps.exceptionId)
                        .then((resp) => handleEventChange(resp.data))
                        .then(() => close());
                    break;
            }
        }
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventLabel">Créneau de {arg.event.extendedProps.salary.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        {<div className="offcanvas-body">
            <h4>
                Détails du créneau
            </h4>
            <div className="mb-3">
                <div className="table w-100">
                    <tbody>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch"}></i> Début
                        </td>
                        <td>
                            {format(arg.event.start!, "E dd MMMM uuuu HH:mm")}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={"bi bi-stopwatch-fill"}></i> Fin
                        </td>
                        <td>
                            {format(arg.event.end!, "E dd MMMM uuuu HH:mm")}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-clock'}></i> Type d'heure
                        </td>
                        <td>
                            {getAccountingTypeChoice(arg.event.extendedProps.accountingType)?.label}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-shop'}></i> Établissement
                        </td>
                        <td>
                            {arg.event.extendedProps.company?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-geo-alt'}></i> Emplacement
                        </td>
                        <td>
                            {arg.event.extendedProps.localisation?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-bookmark'}></i> Activité
                        </td>
                        <td>
                            {arg.event.extendedProps.activity?.title || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-quote'}></i> Commentaire
                        </td>
                        <td>
                            {arg.event.extendedProps.comment || <i className={"bi bi-x"}></i>}
                        </td>
                    </tr>
                    </tbody>
                </div>

                {arg.event.extendedProps.substitution && <div className={'alert alert-info  d-flex align-items-center'}>
                    <div className="flex-grow-1">
                        <i className={'bi bi-arrow-down-up'}></i> Remplacement de {arg.event.extendedProps.substituted.title}
                    </div>
                    {Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id) && <div className="flex-grow-0">
                        <button disabled={removingSub} type={"button"} className={'btn btn-light'}
                                onClick={() => cancelSub()}>
                            {removingSub ? <SmallLoader/> : <><i
                                className={'bi bi-dash-circle text-danger'}></i> Annuler</>}
                        </button>
                    </div>}
                </div>}

                {Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id) && arg.event.extendedProps.isSlotExchange && <div className={'alert alert-info  d-flex align-items-center'}>
                    <div className="flex-grow-1 form-text">
                        {arg.event.extendedProps.salary.title} <i className={'bi bi-shuffle'}></i> {arg.event.extendedProps.exchanged.title}
                    </div>
                    <div className="flex-grow-0">
                        <button disabled={removingExchange} type={"button"} className={'btn btn-light'} onClick={() => cancelExchange()}>
                            {removingExchange ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Annuler</>}
                        </button>
                    </div>
                </div>}

                {Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id) && !arg.event.extendedProps.substitution && arg.event.extendedProps.substitute && <div className={'alert alert-info d-flex align-items-center'}>
                    <div className="flex-grow-1">
                        <i className={'bi bi-arrow-down-up'}></i> Remplacé.e par {arg.event.extendedProps.substitute.title}
                    </div>
                    <div className="flex-grow-0">
                        <button disabled={removingSub} type={"button"} className={'btn btn-light'} onClick={() => cancelSub()}>
                            {removingSub ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Annuler</>}
                        </button>
                    </div>
                </div>}

                <div className="row mb-3">
                    {Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id) && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarEventSidebarProps((prev: any) => ({
                                    sidebarType: 'EVENT_EDIT',
                                    trigger: prev.trigger + 1,
                                    arg: arg,
                                }))}
                        >
                            <i className={"bi bi-pencil text-primary"}></i> Modifier
                        </button>
                    </div>}
                    {Granted(accessRights.DELETE_BOOKING, arg.event.extendedProps.salary.id) && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'} onClick={() => removeEvent()}>
                            {removingEvent ? <SmallLoader/> : <><i
                                className={"bi bi-dash-circle text-danger"}></i> Supprimer </>}
                        </button>
                    </div>}
                </div>
            </div>

            <div className="row mb-3">
                {Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id) && <div className="col">
                    {!arg.event.extendedProps.substitution && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarEventSidebarProps((prev: any) => ({
                                    sidebarType: 'EVENT_SUBSTITUTE',
                                    trigger: prev.trigger + 1,
                                    arg: arg,
                                }))}
                        >
                            <i className={"bi bi-arrow-repeat text-primary"}></i> Remplacer
                        </button>
                    </div>}
                </div>}
                {Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id) && <div className="col">
                    {!arg.event.extendedProps.substitution && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => setCalendarEventSidebarProps((prev: any) => ({
                                    sidebarType: 'EVENT_EXCHANGE',
                                    trigger: prev.trigger + 1,
                                    arg: arg,
                                }))}
                        >
                            <i className={"bi bi-shuffle text-primary"}></i> Échanger
                        </button>
                    </div>}
                </div>}
            </div>

            {Granted(accessRights.EDIT_TIME_CLOCK, arg.event.extendedProps.salary.id) && calendarTypes.CALENDAR_SIGNED_GLOBAL === calendar && <>
                <div className="col-12 mb-3">
                    <button onClick={() => {
                        setCalendarEventSidebarProps({
                            sidebarType: 'EMPTY',
                            trigger: 0
                        })

                        setCalendarSignedSidebarProps((prev: any) => ({
                            sidebarType: "SIGNED_ADD",
                            trigger: prev.trigger + 1,
                            company: arg.event.extendedProps.company,
                            salary: arg.event.extendedProps.salary,
                            start: arg.event.start!,
                            end: arg.event.end!,
                        }))
                    }} type={'button'} className={'btn btn-light w-100'} >
                        <i className={'bi bi-check2-all text-primary'}></i> Ajouter le créneau aux badgeages
                    </button>
                </div>
            </>}

            {arg.event.extendedProps.isLabourDay && <div className="col-12 mb-3">
                <hr/>
                <h4>
                    Jour férié
                </h4>
                <p className="form-text">
                    Cochez la case ci dessous si le collaborateur travail ce jour.
                </p>
                <div className="form-check form-switch">
                    <input disabled={!Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id)} className="form-check-input" defaultChecked={!!arg.event.extendedProps.labourDayWorked}
                           onChange={(e) => setLabourDayWorked(e.target.checked)} type="checkbox" id="labourDayWorked"/>
                    <label className="form-check-label" htmlFor="labourDayWorked">Jours fériés travaillé</label>
                </div>
            </div>}

            {arg.event.extendedProps.absence && <div>
                <hr/>
                <h4>
                    Absence
                </h4>
                <table className={'table w-100'}>
                    <tbody>
                    <tr>
                        <td>
                            Type
                        </td>
                        <td>
                            {arg.event.extendedProps.absence.typeName}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Début
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.absence.start))}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Fin
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.absence.end))}
                        </td>
                    </tr>
                    {arg.event.extendedProps.absence.type === TYPE_CP && <tr>
                        <td>
                            Jour de reprise prévu
                        </td>
                        <td>
                             {absenceInfoLoading ? <SmallLoader /> : (nextWorkingDay ? format(new Date(nextWorkingDay)) : <i className={'bi bi-x'}></i>)}
                        </td>
                    </tr>}
                    <tr>
                        <td>
                            Nb de jours
                        </td>
                        <td>
                            {absenceInfoLoading ? <SmallLoader /> : (nbDays ? nbDays : <i className={'bi bi-x'}></i>)}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="row mb-3">
                    {Granted(accessRights.EDIT_ABSENCE, arg.event.extendedProps.salary.id) && <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'}
                                onClick={() => {
                                    setCalendarEventSidebarProps({
                                        sidebarType: 'EMPTY',
                                        trigger: 0
                                    })

                                    setCalendarAbsenceSidebarProps((prev: any) => ({
                                        sidebarType: 'ABSENCE_EDIT',
                                        trigger: prev.trigger + 1,
                                        arg: arg,
                                    }))
                                }}
                        >
                            <i className={"bi bi-pencil text-primary"}></i> Modifier
                        </button>
                    </div>}
                    {Granted(accessRights.DELETE_ABSENCE, arg.event.extendedProps.salary.id) &&  <div className="col">
                        <button type={'button'} className={'btn btn-light w-100'} onClick={() => removeAbsence()}>
                            {removingAbsence ? <SmallLoader/> : <><i
                                className={"bi bi-dash-circle text-danger"}></i> Supprimer</>}
                        </button>
                    </div>}
                </div>
            </div>}

        </div>}
    </>
}

export default EventShow;