import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import BootstrapSelect, {Choices} from "../../../utils/bootstrapSelect";
import DatePicker from "react-datepicker";
import format from "../../../utils/locale";
import Loader from "../../../utils/loader";
import * as api from "../../../adapters/eventExchange";
import {Salaries, Salary} from "../../../models/salary";
import {axiosError} from "../../../actions/axios";
import {Company} from "../../../models/companies";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_EVENT_EXCHANGE, FORM_NEW_EVENT_EXCHANGE_VALIDATE} from "../../../constants/rightSidebar";
import * as accessRightsTypes from "../../../constants/accessRight";
import SalarySelector from "../../salary/selector";

const EventExchangeAdd:React.FC = () => {

    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data);
    const dispatch = useDispatch();
    let now = new Date(data.start)
    now.setDate(now.getDate() + 3);
    const [startDate, setStartDate] = useState<Date>(data.start);
    const [endDate, setEndDate] = useState<Date>(now);
    const [jobs, setJobs] = useState<number[]>([])
    const [salaries, setSalaries] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSameDuration, setIsSameDuration] = useState<boolean>(true)
    const [slots, setSlots] = useState<{
        start: string,
        end: string,
        timeCaption: string,
        timeDiff: number,
        timeDiffCaption: string,
        salary: Salary,
        company: Company,
        eventId: number,
        initialStart: string,
    }[]>([])

    useEffect(() => {
        if (startDate && endDate){
            setSlots([])
            setIsLoading(true)
            api.match({isSameDuration: isSameDuration, start: format(data.start, 'uuuu-MM-dd HH:mm:ss'), end: format(data.end, 'uuuu-MM-dd HH:mm:ss'), jobs: jobs, startDate: format(startDate, 'uuuu-MM-dd'), endDate: format(endDate, 'uuuu-MM-dd'), salaries: salaries}, data.salary.id)
                .then(data => {
                    setSlots(data.data)
                    setIsLoading(false);
                })
                .catch(error => dispatch(axiosError(error)));
        }
    }, [salaries, jobs, startDate, endDate, isSameDuration])

    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return <div className={'p-1 p-md-3 h-100'}>
        <div className="row h-100">
            <div className="col-md-4 h-100 d-flex flex-column">
                <div className="card shadow-sm mb-3">
                    <div className="card-header">
                        <i className={'bi bi-shuffle'}></i> Créneau à échanger
                    </div>
                    <div className="card-body">
                        <h4>
                            <i className={'bi bi-person'}></i> {data.salary.title}
                        </h4>
                        <p className={'d-none d-md-block'}>
                            <i className={'bi bi-shop'}></i> {data.company.title}
                        </p>
                        <p>
                            <i className={'bi bi-calendar'}></i>  {format(new Date(data.start), "E dd MMMM uuuu HH:mm")} <i className={'mx-1 bi bi-arrow-left-right'}></i> {format(new Date(data.end), "E dd MMMM uuuu HH:mm")}
                        </p>
                        <p className={'d-none d-md-block'}>
                            <i className={'bi bi-clock'}></i> {data.timeCaption}
                        </p>
                    </div>
                </div>

                <div className="card shadow-sm mb-3">
                    <div className="card-header">
                        <i className={'bi bi-filter'}></i> Filtrer les créneaux disponibles
                    </div>
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <div className="form-check form-switch">
                                <input onChange={(e) => setIsSameDuration(e.target.checked)} className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={true} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked"> Créneaux à durée équivalente ({data.timeCaption}h)</label>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="inline-datepicker-container bg-white">
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                />
                            </div>
                        </div>
                            <div className="col-12 mb-3 d-none d-md-block">
                                <BootstrapSelect
                                    fetchEntity={'job'}
                                    required={false}
                                    isMultiple={true}
                                    placeholder={'Tous les métiers'}
                                    onChangeMultiple={(choices: Choices) => setJobs(choices.map(c => Number(c.value)))}
                                    labelIcon={'bi bi-person-bounding-box text-primary'}
                                    label={'Métiers'}
                                />
                            </div>
                            <div className="col-12 mb-3 d-none d-md-block">
                                <SalarySelector
                                    multiple={true}
                                    fetchOptions={true}
                                    jobs={jobs}
                                    enableReinitialize={true}
                                    onChange={(choices: Salaries) => setSalaries(choices.map(c => Number(c.id)))}
                                    accessRight={accessRightsTypes.EDIT_BOOKING}
                                />
                            </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8 h-100">
                <div className="col-12 position-relative overflow-auto h-100">
                    {isLoading && <Loader />}
                    {!isLoading && !!slots.length && <h4> Créneaux disponibles</h4>}
                    {!isLoading && !slots.length && <h4 className={'text-center'}><i className={'bi bi-search'}></i> Aucun créneaux disponibles</h4>}
                    <ul className={'list-group list-group-flush'}>
                        {slots.map(slot => <li
                            className={'list-group-item list-group-item-action'}
                            onClick={() => dispatch(openSidebar(FORM_NEW_EVENT_EXCHANGE_VALIDATE, {
                                event: {...data},
                                target: {...slot},
                                data: {...data},
                                next: FORM_NEW_EVENT_EXCHANGE
                            }))}
                        >
                            <h4>
                                <i className={'bi bi-person'}></i> {slot.salary.title}
                            </h4>
                            <p>
                                <i className={'bi bi-shop'}></i> {slot.company.title}
                            </p>
                            <p>
                                <i className={'bi bi-calendar'}></i>  {format(new Date(slot.start), "E dd MMMM uuuu HH:mm")} <i className={'mx-1 bi bi-arrow-left-right'}></i> {format(new Date(slot.end), "E dd MMMM uuuu HH:mm")}
                            </p>
                            <p>
                                <i className={'bi bi-clock'}></i> {slot.timeCaption}
                                <i className={'ms-2 ' + (!slot.timeDiff ? 'bi bi-check text-success' : (slot.timeDiff > 0 ? 'bi bi-arrow-up text-primary' : "bi bi-arrow-down text-danger"))}></i> {!!slot.timeDiff && <>{slot.timeDiffCaption}</>}
                            </p>
                        </li>)}
                    </ul>
                </div>
            </div>
        </div>
    </div>
}

export default EventExchangeAdd;