import { get, post } from "../../xhr";

import { IAddSalaryPaidVacationModel } from "../../../models/salary/paidVacation/IAddSalaryPaidVacationModel";

export function getSalaryPaidVacationsList(salaryId: number){
    return get(`salary/${salaryId}/paid-vacations/list`);
}

export function addSalaryPaidVacation(salaryId: number, requestData: IAddSalaryPaidVacationModel){
    return post(`salary/${salaryId}/paid-vacations/add`, requestData);
}

export function deleteSalaryPaidVacation(salaryId: number, paidVacationId: number){
    return get(`salary/${salaryId}/paid-vacations/${paidVacationId}/delete`);
}