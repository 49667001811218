import React, {useState} from "react";
import format from "../../../../utils/locale";
import SalarySelector from "../../../salary/selector";
import {Salary} from "../../../../models/salary";
import * as resourceApi from "../../../../adapters/template/applied/resource";
import * as api from "../../../../adapters/template/applied";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../actions/alert";
import SmallLoader from "../../../../utils/loader/small";

interface Interface {
    close: () => void;
    handleEventChange: (e: any) => any;
    setCalendarData: React.Dispatch<any>;
    _template: {
        id: number;
        template: {
            title: string;
        },
        isPreview: number;
        start: string,
        end: string,
        floatingResources: {title: string, id: number}[],
        appliedResources: {title: string, id: number, resource:  {title: string, id: number}, salary: Salary}[]
    }
}

const CalendarTemplateShow:React.FC<Interface> = (props) => {

    const {close, _template} = props;

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false)
    const [canceling, setCanceling] = useState(false)
    const [template, setTemplate] = useState(_template)

    function handleFloatingResourceChange(id: number, salary?: Salary)
    {
        const salaries:number[] = [];
        let previousResource = template.appliedResources.find(ar => ar.resource.id === id);
        if (previousResource){
            salaries.push(previousResource.salary.id);
        }

        if (salary){
            salaries.push(salary.id);
        }

        setDisabled(true)
        if (salary){
            if (previousResource){
                resourceApi.edit({resource: id, salary: salary.id, templateApplied: template.id}, previousResource.id)
                    .then(resp => {
                        Promise.all([
                            props.handleEventChange({ids: salaries})
                        ]).then(() => {
                            setDisabled(false);
                            setTemplate(resp.data)
                            props.setCalendarData((prev: any) => ({...prev, templates: [...prev.templates.filter((t: any) => t.id !== template.id), resp.data]}))
                        })
                    })
            }else{
                resourceApi.create({resource: id, salary: salary.id, templateApplied: template.id})
                    .then(resp => {
                        Promise.all([
                            props.handleEventChange({ids: salaries})
                        ]).then(() => {
                            setDisabled(false);
                            setTemplate(resp.data)
                            props.setCalendarData((prev: any) => ({...prev, templates: [...prev.templates.filter((t: any) => t.id !== template.id), resp.data]}))
                        })
                    })
            }

        }else{
            resourceApi.cancel(id)
                .then(resp => {
                    Promise.all([
                        props.handleEventChange({ids: salaries})
                    ]).then(() => {
                        setDisabled(false);
                        setTemplate(resp.data)
                        props.setCalendarData((prev: any) => ({...prev, templates: [...prev.templates.filter((t: any) => t.id !== template.id), resp.data]}))
                    })
            })
        }
    }

    function handleStatusChange(isPreview: boolean)
    {
        if (isPreview){
            api.preview(template.id)
                .then(resp => {
                    setTemplate(resp.data)
                    props.setCalendarData((prev: any) => ({...prev, templates: [...prev.templates.filter((t: any) => t.id !== template.id), resp.data]}))
                })
        }else{
            api.activate(template.id)
                .then(resp => {
                    setTemplate(resp.data)
                    props.setCalendarData((prev: any) => ({...prev, templates: [...prev.templates.filter((t: any) => t.id !== template.id), resp.data]}))
                })
        }
    }

    function handleDeactivation()
    {
        setCanceling(true)
        api.cancel(template.id)
            .then(resp => {
                Promise.all([
                    props.handleEventChange(resp.data)
                ]).then(() => {
                    dispatch(showAlertSuccess('Teamplate désactivé'))
                    setCanceling(false);
                    props.close();
                })
            })
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarTemplateShowLabel">Template {template.template.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <table className={'table'}>
                <tbody>
                    <tr>
                        <td>
                            Statut
                        </td>
                        <td>
                            <div className="btn-group">
                                <button className="btn btn-light me-2" onClick={() => handleStatusChange(true)}>
                                    {template.isPreview && <i className={'bi bi-check text-success'}></i>} Prévisualisation
                                </button>
                                <button className="btn btn-light" onClick={() => handleStatusChange(false)}>
                                    {!template.isPreview && <i className={'bi bi-check text-success'}></i>} Actif
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Début
                        </td>
                        <td>
                            {format(new Date(template.start))}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Fin
                        </td>
                        <td>
                            {format(new Date(template.end))}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className="btn btn-light w-100" onClick={() => handleDeactivation()}>
                                {canceling ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Désactiver le template</>}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4>
                Ressources flottantes
            </h4>
            <ul className="list-group list-group-flush">
                {template.floatingResources.length > 0 ? template.floatingResources.map(r => <li className={'list-group-item d-flex'}>
                    <div className="flex-grow-1">
                        {r.title}
                    </div>
                    <div className="flex-grow-0">
                        <SalarySelector
                            disabled={disabled}
                            required={false}
                            fetchOptions={true}
                            multiple={false}
                            onChange={(s: Salary|undefined) => handleFloatingResourceChange(r.id, s)}
                            value={template.appliedResources.find(ar => ar.resource.id === r.id)?.salary}
                        />
                    </div>
                </li>) : <li className={'list-group-item'}><i className={'bi bi-search'}></i> Aucune ressource flottante</li>}
            </ul>
        </div>
    </>
}

export default CalendarTemplateShow;