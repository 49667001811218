import React, {useEffect, useState} from "react";
import {DateSelectArg} from "@fullcalendar/react";
import EventAddForm from "../../../../event/add/form";
import {Salary} from "../../../../../models/salary";
import {Company} from "../../../../../models/companies";
import {Activity} from "../../../../../models/activity";
import {Localisation} from "../../../../../models/localisation";
import {setMinutes} from "date-fns";

interface Interface {
    arg?: DateSelectArg,
    salary?: Salary,
    company?: Company,
    activity?: Activity,
    localisation?: Localisation,
    handleSubmitSuccess: (props: any[]) => any,
    close: () => any,
}

const EventSelect:React.FC<Interface> = (props) => {

    const {handleSubmitSuccess, close, arg, salary, company, activity, localisation} = props;
    const [key, setKey] = useState(1);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1)
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventSelectLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <EventAddForm
                key={key}
                start={arg.start}
                end={arg.end}
                initialSalary={salary}
                initialCompany={company}
                localisation={localisation}
                activity={activity}
                handleSubmitSuccess={handleSubmit}
            />}
        </div>
    </>
}

export default EventSelect;