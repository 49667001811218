import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/calendar"
import format from "../../../utils/locale"
import {setPageTitle} from "../../../actions/header";
import DatePicker from "react-datepicker";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import Loader from "../../../utils/loader";
import * as accessRights from "../../../constants/accessRight";
import {Company} from "../../../models/companies";
import * as calendarTypes from "../../../constants/calendar";
import { ResponsivePie } from '@nivo/pie'
import {Salary} from "../../../models/salary";
import SalarySelector from "../../salary/selector";
import {Granted} from "../../../security/granted";
import getUser from "../../../security/getUser";

const TimeClockList: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState(false);
    const [salary, setSalary] = useState<number>()
    const [company, setCompany] = useState<number>()
    const [isAnomaly, setIsAnomaly] = useState<boolean>(true)
    const [filter, setFilter] = useState<'all'|'late'|'missing'>('all')
    const [signed, setSigned] = useState<any[]>([])

    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end){
            fetchSigned({start: new Date(start), end: new Date(end)});
        }
    };

    useEffect(() => {
        dispatch(setPageTitle('Reporting badgeuse'))
        fetchSigned({start: new Date(), end: new Date()})
    }, [])

    return <>
        <div id={'listContainer'} className="col d-flex col-md-3 col-sm-4 h-100 px-0 overflow-auto sticky-top">
            <div className="p-2 flex-grow-1 d-flex flex-column">
                <div className="card flex-grow-1 shadow-sm">
                    <div className="card-body d-flex flex-column">
                        <div className="flex-grow-1">
                            <ResponsivePie
                                arcLabelsRadiusOffset={0.7}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                sortByValue={true}
                                arcLinkLabel={'label'}
                                enableArcLabels={true}
                                arcLabelsSkipAngle={5}
                                arcLinkLabelsSkipAngle={5}
                                arcLabel={(a) => `${a.value}%`}
                                colors={{ datum: 'data.color' }}
                                data={[
                                {
                                    "id": "late",
                                    "label": "Retards",
                                    "value": Math.round((signed.filter(s => applyChartFilters(s)).filter(s => s.isLate && !s.isAnomaly).length / signed.filter(s => applyChartFilters(s)).length) * 100),
                                    "color": "hsl(32,93%,59%)"
                                },
                                {
                                    "id": "onTime",
                                    "label": "A l'heure",
                                    "value": Math.round((signed.filter(s => applyChartFilters(s)).filter(s => !s.isLate && !(s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING) && !s.isAnomaly).length / signed.filter(s => applyChartFilters(s)).length) * 100),
                                    "color": "hsl(123,72%,51%)"
                                },
                                {
                                    "id": "missing",
                                    "label": "Absent",
                                    "value": Math.round((signed.filter(s => applyChartFilters(s)).filter(s => s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING).length / signed.filter(s => applyChartFilters(s)).length) * 100),
                                    "color": "hsl(351,90%,53%)"
                                },
                                {
                                    "id": "anomaly",
                                    "label": "Anomalies",
                                    "value": Math.round((signed.filter(s => applyChartFilters(s)).filter(s => s.isAnomaly).length / signed.filter(s => applyChartFilters(s)).length) * 100),
                                    "color": "hsl(0,0%,0%)"
                                },
                            ]} />
                        </div>
                        <div className="inline-datepicker-container bg-white flex-grow-0">
                            <DatePicker
                                disabled={loading}
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={new Date()}
                                selectsRange
                                inline
                            />
                            <hr />
                            <div className="form-check mb-1">
                                <input
                                    name={'filter'}
                                    defaultChecked={true}
                                    onChange={(e) => setFilter('all')}
                                    disabled={loading} className="form-check-input" type="radio" value="all" id="all" />
                                    <label className="form-check-label" htmlFor="all">
                                        Tous les créneaux
                                        <span className="badge bg-secondary ms-2">
                                            {signed.length}
                                        </span>
                                    </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    name={'filter'}
                                    onChange={(e) => setFilter('late')}
                                    disabled={loading} className="form-check-input" type="radio" value="late" id="late" />
                                <label className="form-check-label" htmlFor="late">
                                    Retards
                                    <span className="badge bg-secondary ms-2">
                                        {signed.filter(s => s.isLate).length}
                                    </span>
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    name={'filter'}
                                    onChange={(e) => setFilter('missing')}
                                    disabled={loading} className="form-check-input" type="radio" value="missing" id="missing" />
                                    <label className="form-check-label" htmlFor="missing">
                                        Absences
                                        <span className="badge bg-secondary ms-2">
                                            {signed.filter(s => s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING).length}
                                        </span>
                                    </label>
                            </div>
                            <hr/>
                            <div className="form-check mb-3">
                                <input disabled={loading}
                                       defaultChecked={isAnomaly}
                                       onChange={(e) => setIsAnomaly(e.target.checked)}
                                       className="form-check-input" type="radio" value="isAnomaly" id="isAnomaly" />
                                <label className="form-check-label" htmlFor="isAnomaly">
                                    Anomalies
                                    <span className="badge bg-secondary ms-2">
                                        {signed.filter(s => s.isAnomaly).length}
                                    </span>
                                </label>
                            </div>
                            <hr/>
                            {Granted(accessRights.LIST_SALARY) && <SalarySelector
                                fetchOptions={true}
                                onChange={(choice: Salary|undefined) => setSalary(Number(choice?.id))}
                                accessRight={accessRights.LIST_TIME_CLOCK}
                            />}
                            <hr/>
                            <BootstrapSelect
                                disabled={loading}
                                options={getUser().currentSalary.companies.map((company: Company) => ({label: company.title, value: company.id}))}
                                label={'Points de vente'}
                                onChange={(choice) => setCompany(Number(choice?.value))}
                            />
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id={'singleContainer'} className="d-none d-sm-block col-md-9 col-sm-8 h-100 px-0 position-relative">
            {loading ? <Loader /> : <ul className={'list-group list-group-flush'}>
                {signed.filter(s => applyFilters(s)).sort((a,b) => new Date(a.start).getTime() - new Date(b.start).getTime()).map(s => <li className={'list-group-item'}>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 d-flex flex-column">
                            <h4 className={'text-primary'}>
                               <i className={'bi bi-calendar'}></i> le {format(new Date(s.start))}
                            </h4>
                            <span>
                                <i className={'bi bi-person'}></i> {s.salary.title}
                            </span>
                            <span className={'d-flex align-items-center'}>
                              <span className="me-2" style={{height: 14, width: 14, borderRadius: '50%', backgroundColor: s.salary.information.job.color}}></span>  {s.salary.information.job.title}
                            </span>
                        </div>
                        <div className="flex-grow-0 d-flex flex-column">
                            {s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING && <>
                                <div className="d-flex align-items-center">
                                    <h4 className={"flex-grow-1 m-0"}>
                                        <i className={'bi bi-exclamation-circle text-danger'}></i> Absent
                                    </h4>
                                </div>
                                <span className={"form-text"}>
                                    <i className={'bi bi-clock'}></i> Prévu à {format(new Date(s.start), 'HH:mm')}
                                </span>
                            </> }
                            {s.isLate && <>
                                <h4>
                                    <i className={'bi bi-exclamation-triangle text-warning'}></i> Retard
                                </h4>
                                <span className={"form-text"}>
                                    <i className={'bi bi-watch'}></i> Retard de {s.lateOfCaption}
                                </span>
                            </>}
                            {!(s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING) && !s.isLate && <>
                                <h4>
                                    <i className={'bi bi-check text-success'}></i> Arrivé à {format(new Date(s.start), 'HH:mm')}
                                </h4>
                            </>}
                        </div>
                    </div>
                </li>)}
            </ul>}
        </div>
    </>

    function fetchSigned(props: {start: Date, end: Date}){

        let _end = props.end;
        _end.setDate(_end.getDate() + 1)

        setLoading(true);
        api.test({
            start: format(props.start, 'uuuu-MM-dd'),
            end: format(_end, 'uuuu-MM-dd'),
            calendar: calendarTypes.CALENDAR_SIGNED_REPORTING
        }).then((resp) => {
            setSigned(resp.data.signed)
            setLoading(false);
        })
    }

    function applyChartFilters(s: any){
        if (company && company !== s.company.id) return false;
        if (salary && salary !== s.salary.id) return false;

        return true;
    }

    function applyFilters(s: any){

        if (company && company !== s.company.id) return false;
        if (salary && salary !== s.salary.id) return false;
        if ([calendarTypes.EVENT_TYPE_BOOKING, calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION, calendarTypes.EVENT_TYPE_EVENT, calendarTypes.EVENT_TYPE_EVENT_EXCEPTION].includes(s.eventType)) return false;
        if (s.isAnomaly) return isAnomaly;

        switch (filter){
            case "late":
                return s.isLate;
            case "missing":
                return s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING;
        }

        return true;
    }
}

export default TimeClockList;