import * as types from "../../constants/task"
import * as models from "../../models/task"
import replace from "../../utils/arrayReplace"
import remove from "../../utils/arrayRemove"

const task = (state: models.initialStateInterface = models.initialState, action: {type: string, task: models.Task, tasks: models.Tasks, error: string}) => {

    switch (action.type) {
        case types.FETCH_TASKS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_TASKS_SUCCESS:
            return { payload: action.tasks, single: state.single, isLoading: false, error: null };
        case types.FETCH_TASKS_FAILURE:
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };

        case types.ADD_TASK:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_TASK_SUCCESS:
            return { payload: [...state.payload, action.task], single: action.task, isLoading: false, error: null };

        case types.EDIT_TASK:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_TASK_SUCCESS:
            return { payload:  replace(state.payload, action.task), single: action.task, isLoading: false, error: null };

        case types.DELETE_TASK_SUCCESS:
            return { payload:  remove(state.payload, action.task), single: action.task, isLoading: false, error: null };

        case types.EDIT_TASK_STATUS_SUCCESS:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };

        case types.SHOW_TASK:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_TASK_SUCCESS:
            return { payload: state.payload, single: action.task, isLoading: false, error: null };
        case types.SHOW_TASK_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default task;