import * as groupModels from "../../models/companyGroup";
import {get, post} from "../xhr";
import * as models from "../../models/job";
import * as xhr from "../xhr";

interface listPayload {
    offset?: number,
    query?: string,

    type?: number,
    status?: number,
}

export function list(requestData?: listPayload){
    return post('job/list', requestData || {});
}

export function edit(requestData: models.Job, id: number){
    return post('job/edit/' + id, requestData);
}

export function create(requestData: models.Job){
    return post('job/create', requestData);
}

export function show(id: string){
    return get('job/show/' + id);
}

export function order(requestData: {id: number, position: number}[]){
    return xhr.post('job/order',  requestData);
}

export function activate(id: number){
    return xhr.get(`job/${id}/activate`);
}

export function deactivate(id: number){
    return xhr.get(`job/${id}/deactivate`);
}
