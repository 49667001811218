import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/salary/template";
import {useDispatch} from "react-redux";
import {SalaryTemplate, SalaryTemplates} from "../../../../../models/salary/template";
import SalaryTemplateAddForm from "../form";
import {Offcanvas} from "bootstrap";
import {showAlertSuccess} from "../../../../../actions/alert";
import {Salary} from "../../../../../models/salary";

const SalaryTemplateDuplicate:React.FC<{setTemplates: React.Dispatch<any>, setTemplate: React.Dispatch<any>, salary: Salary, template: SalaryTemplate, templates: SalaryTemplates}> = (props) => {

    const dispatch = useDispatch();
    const {templates, setTemplate, setTemplates, template, salary} = props;
    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [lastEnd, setLastEnd] = useState<Date>()

    useEffect(() => {
        const el = document.getElementById('offcanvasSalaryTemplateDuplicate')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const maxDate = (dates: Date[]) => new Date(Math.max(...dates.map(Number)));

    useEffect(() => {
        if(templates.length > 0 && templates.filter(t => !t.end).length  === 0) {
            setLastEnd(maxDate(templates.map(t => new Date(t.end!))));
        }else{
            setLastEnd(undefined)
        }
    }, [templates])

    const handleSubmit = (t: SalaryTemplate) => {
        setTemplates([...templates, t])
        setTemplate(t)
        close()
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasSalaryTemplateDuplicate" aria-labelledby="offcanvasSalaryTemplateDuplicateLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasSalaryTemplateDuplicateLabel">Dupliquer un emploi du temps</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <SalaryTemplateAddForm templates={templates} salary={salary} handleSubmit={handleSubmit} lastEnd={lastEnd} duplicate={true} template={template} />
            </div>
        </div>
}

export default SalaryTemplateDuplicate;