import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as accessRights from "../../../constants/accessRight";
import * as api from "../../../adapters/counter";
import format from "../../../utils/locale";
import {closeSidebar, openSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {FORM_COUNTER_EDIT, FORM_COUNTER_SHOW, REPORTING_SHOW_WEEK_LOAD} from "../../../constants/rightSidebar";
import {editCounterSuccess} from "../../../actions/counter";
import {showAlertSuccess} from "../../../actions/alert";
import {Granted} from "../../../security/granted";

const CounterShow:React.FC = () => {

    let counter = useSelector((state: RootStateOrAny) => state.rightSidebar.data);
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const dispatch = useDispatch();

    return <div className={"p-1 p-md-3"}>
        {Granted(accessRights.EDIT_COUNTER, counter.salary.id) && <div className="col-12 mb-3">
            <button className={"btn btn-outline-primary"} onClick={() => {
                dispatch(openSidebar(FORM_COUNTER_EDIT, {...counter, ...{next: FORM_COUNTER_SHOW, data: {...counter}}}))
            }}>
                <i className={"bi bi-pencil"}> </i> Modifier
            </button>
            <button className={"btn btn-outline-danger ms-2"} onClick={() => {
                dispatch(submitSidebar())
                api.remove(counter.id).then((data) => {
                    dispatch(editCounterSuccess(data.data))
                    dispatch(showAlertSuccess('Compteur supprimé !'));
                    dispatch(closeSidebar());
                })
            }}>
                <i className={"bi bi-dash-circle"}> </i> Supprimer
            </button>
            <button className={"btn btn-outline-primary ms-2"} onClick={() => {
                dispatch(openSidebar(REPORTING_SHOW_WEEK_LOAD, {
                    salary: counter.salary,
                    startDate: new Date(counter.weekStart),
                    endDate: new Date(counter.weekEnd),
                }))
            }}>
                <i className={"text-primary bi bi-eye"}></i> Détail de la semaine
            </button>
        </div>}
        <table className={'table'}>
            <tbody>
                <tr>
                    <td className={"text-primary"}>
                        Année
                    </td>
                    <td>
                        {counter.year}
                    </td>
                </tr>
            <tr>
                <td className={"text-primary"}>
                    N° semaine
                </td>
                <td>
                    {counter.weekNumber}
                </td>
            </tr>
            <tr>
                <td className={"text-primary"}>
                    Dates
                </td>
                <td>
                    {format(new Date(counter.weekStart))} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(counter.weekEnd))}
                </td>
            </tr>
            <tr>
                <td className={"text-primary"}>
                    Valeur
                </td>
                <td>
                    {counter.time}
                </td>
            </tr>
                {auth.user.currentSalary.companyGroup.accountingSettings.overtimeMarkUp && <tr>
                    <td className={"text-primary"}>
                        Valeur Majorée
                    </td>
                    <td>
                        {counter.markUpTime}
                    </td>
                </tr>}
                {counter.value !== counter.initialValue && <tr className={'form-text'}>
                    <td>
                        Valeur avant modification
                    </td>
                    <td>
                        {counter.initialTime}
                    </td>
                </tr>}
            </tbody>
        </table>
    </div>
}

export default CounterShow;