import * as types from "../../constants/job";
import * as models from "../../models/job";

export const addJob = () => {
    return {
        type: types.ADD_JOB,
    }
}

export const addJobSuccess = (job: models.Job) => {
    return {
        type: types.ADD_JOB_SUCCESS,
        job,
    }
}

export const addJobFailure = (error: string) => {
    return {
        type: types.ADD_JOB_FAILURE,
        job: null,
        jobs: null,
        error: error
    }
}

export const editJob = (job: models.Job) => {
    return {
        type: types.EDIT_JOB,
        job
    }
}



export const editJobSuccess = (job: models.Job) => {
    return {
        type: types.EDIT_JOB_SUCCESS,
        job: job,
    }
}

export const fetchJobs = () => {
    return {
        type: types.FETCH_JOBS,
    }
}

export const fetchJobsFailure = (error: string) => {
    return {
        type: types.FETCH_JOBS_FAILURE,
        job: null,
        Jobs: [],
        error
    }
}

export const fetchJobsSuccess = (jobs: models.Jobs) => {
    return {
        type: types.FETCH_JOBS_SUCCESS,
        job: null,
        jobs
    }
}


export const showJob = () => {
    return {
        type: types.SHOW_JOB,
    }
}
export const showJobSuccess = (job: models.Job) => {
    return {
        type: types.SHOW_JOB_SUCCESS,
        job: job
    }
}
export const showJobFailure = (error: string) => {
    return {
        type: types.SHOW_JOB_FAILURE,
        job: null,
        Jobs: null,
        error: error
    }
}