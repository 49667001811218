import * as types from "../../constants/timeClock"
import * as models from "../../models/timeClock"
import replace from "../../utils/arrayReplace"

const timeClock = (state: models.initialStateInterface = models.initialState, action:
    {type: string,
        timeClock: models.TimeClock, timeClocks: models.TimeClocks,
        timeClockGenerated: models.TimeClockGenerated, timeClocksGenerated: models.TimeClockGenerated[],
        error: string, [key: string] : any}
) => {

    let index : number;
    let _payload : any[];

    switch (action.type) {
        case types.FETCH_TIME_CLOCKS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_TIME_CLOCKS_SUCCESS:
            return { payload: action.timeClocks, single: state.single, isLoading: false, error: null };
        case types.FETCH_TIME_CLOCKS_FAILURE:
            return { payload: action.timeClocks, single: state.single, isLoading: false, error: action.error };

        case types.ADD_TIME_CLOCK:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_TIME_CLOCK_SUCCESS:
            return { payload: [action.timeClockGenerated, ...state.payload], single: action.timeClockGenerated, isLoading: false, error: null };

        case types.EDIT_TIME_CLOCK:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_TIME_CLOCK_SUCCESS:

            index = state.payload.findIndex(timeClockGenerated => timeClockGenerated.id === action.key);
            _payload = [...state.payload]

            if (index !== -1){
                _payload[index] = action.timeClockGenerated
            }

            return { payload: [..._payload], single: action.timeClock, isLoading: false, error: null };

        case types.REMOVE_TIME_CLOCK_SUCCESS:

            index = state.payload.findIndex(timeClockGenerated => timeClockGenerated.id === action.key);
            _payload = [...state.payload]

            if (index !== -1){
                _payload.splice(index, 1)
            }

            return { payload: [..._payload], single: state.single, isLoading: false, error: null };

        case types.SHOW_TIME_CLOCK:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_TIME_CLOCK_SUCCESS:
            return { payload: state.payload, single: action.timeClock, isLoading: false, error: null };
        case types.SHOW_TIME_CLOCK_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        case types.REFRESH_TIME_CLOCK:
            state.refresh = true;
            return {...state} ;

        default:
            return state;
    }
}

export default timeClock;