import * as xhr from "../xhr";

interface ListPayload {
    offset? : number,
    query? : string,
}

export function list(requestData: ListPayload, signal?: AbortSignal){
    return xhr.post('team/list', requestData, {signal: signal});
}

export function edit(requestData: any, id: number){
    return xhr.post('team/edit/' + id, requestData);
}

export function show(id: number){
    return xhr.get('team/show/' + id);
}

export function create(requestData: any, options?:xhr.Options){
    return xhr.post('team/create',  requestData, options);
}

export function setSalaries(requestData: { salaries: string[] }, id: number){
    return xhr.post(`team/${id}/salaries`,  requestData);
}

export function removeSalary(id: number, salaryId: number){
    return xhr.get(`team/${id}/salaries/remove/${salaryId}`);
}

export function get(id: string){
    return xhr.get('team/show/' + id);
}

export function remove(id: number){
    return xhr.get('team/delete/' + id);
}
