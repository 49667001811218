import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as actions from "../../../actions/team"
import * as api from "../../../adapters/team"
import FormError from "../../../utils/formError";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import {axiosError} from "../../../actions/axios";

const TeamEdit:React.FC = () => {

    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const dispatch = useDispatch();

    const initialValues = {
        title: data.title,
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Veuillez remplir le titre"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar())
            api.edit(values, data.id).then(_data => {
                dispatch(actions.editTeamSuccess(_data.data))
                dispatch(showAlertSuccess("Équipe mis à jour !"))
                dispatch(closeSidebar());
            }).catch(error => dispatch(axiosError(error)))
        }
    });

    return <div className={'p-1 p-md-3'}>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input defaultValue={formik.values.title} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} name={'title'} id={'title'} className="form-control"/>
                    <label htmlFor="title">
                        <i className={'bi bi-file-font-fill text-primary'}> </i> Titre
                    </label>
                    <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
                </div>
            </div>
            <button className={'btn btn-outline-primary w-100'} type={'submit'}>
                <i className={'bi bi-check-circle'}> </i> Valider
            </button>
        </form>
    </div>
}

export default TeamEdit;