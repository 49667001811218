import {post, get} from "../xhr";
import * as models from "../../models/companies";

export interface requestPayload {
    query?: string,
    offset?: number,
    ids?: number[];
    salaries?: (number|undefined)[];
}

export function list(requestData?: requestPayload){
    return post('company/list', requestData || {});
}

export function listBilling(){
    return get('company/list-billing');
}

export function edit(requestData: any, id: number){
    return post('company/edit/' + id, requestData);
}

export function create(requestData: any){
    return post('company/create', requestData);
}

export function show(id: number){
    return get('company/show/' + id);
}

export function generateTimeClockToken(id: number){
    return get('company/token/' + id);
}
export function updateTimezone(requestData: {timezone: string}, id: number){
    return post('company/timezone/' + id, requestData);
}

export function activate(id: number){
    return get('company/activate/' + id);
}

export function deactivate(id: number){
    return get('company/deactivate/' + id);
}