import * as xhr from "../xhr";
import * as models from "../../models/accessLevel";

interface listPayload {
    offset?: number,
    query?: string
}

export function select(requestData?: listPayload){
    return xhr.post('access_level/list', requestData || {});
}

export function patch(requestData: any, id: number){
    return xhr.post('access_level/edit/' + id, requestData);
}

export function put(requestData: any){
    return xhr.post('access_level/create',  requestData);
}

export function accessRight(requestData: {underscoreCase: string}, id: number){
    return xhr.post(`access_level/${id}/access_right`,  requestData);
}

export function get(id: string){
    return xhr.get('access_level/show/' + id);
}