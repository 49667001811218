import * as types from "../../constants/rightSidebar"
import * as models from "../../models/rightSidebar"
import React from "react";

const rightSidebar = (state: models.initialStateInterface = models.initialState, action: {type: string, item: string, data: any, props:any, error: string}) => {

    switch (action.type) {
        case types.OPEN_SIDEBAR:
            state.show = true;
            state.item = action.item;
            state.data = action.data;
            state.props = action.props;
            state.isLoading = false;
            return state;
        case types.CLOSE_SIDEBAR:
            state.show = false;
            state.item = '';
            state.data = {};
            state.props = null;
            state.isLoading = false;
            return state;
        case types.SUBMIT_SIDEBAR:
            state.isLoading = true
            return state;
        default:
            return state;
    }
}

export default rightSidebar;