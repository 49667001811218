import React, {useState} from "react";
import * as accessRights from "../../../../constants/accessRight";
import {openSidebar} from "../../../../actions/rightSidebar";
import {FORM_EDIT_COMPANY} from "../../../../constants/rightSidebar";
import format from "../../../../utils/locale";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Loader from "../../../../utils/loader";
import HasModule from "../../../../security/hasModule";
import {Company} from "../../../../models/companies";
import * as api from "../../../../adapters/company";
import * as actions from "../../../../actions/companies";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {Granted, GrantedAny} from "../../../../security/granted";
import {AxiosError} from "axios";

const CompanyInformation:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state.companies)
    const company: Company = useSelector((state: RootStateOrAny) => state.companies.single)
    const [isGeneratingToken, setIsGeneratingToken] = useState<boolean>(false)
    const dispatch = useDispatch();

    if (state.isLoading){
        return <Loader />
    }

    const generateToken = () => {
        setIsGeneratingToken(true)
        api.generateTimeClockToken(company.id!)
            .then(data => {
                setIsGeneratingToken(false)
                dispatch(actions.editCompanySuccess(data.data))
            })
    }

    const updateTimezone = (t: string) => {
        api.updateTimezone({timezone: t}, company.id!).then(data => dispatch(actions.editCompanySuccess(data.data)))
    }

    return <>
        <div className="card p-1 p-md-3 shadow-sm">
            <div className="row">
                <div className="col">
                    <h4>
                        <i className={"bi bi-info-circle text-primary"}> </i> Informations
                    </h4>
                </div>
                {GrantedAny([accessRights.EDIT_COMPANY, accessRights.DELETE_COMPANY]) && <div className="col-auto">
                    {Granted(accessRights.EDIT_COMPANY) && <button className={'btn btn-outline-primary'}
                             onClick={() => dispatch(openSidebar(FORM_EDIT_COMPANY, company))}>
                        <i className={"bi bi-pencil"}> </i> Modifier
                    </button>}
                    {company.activated && Granted(accessRights.DELETE_COMPANY) && <button className={'btn btn-outline-danger ms-2'}
                             onClick={() => {
                                api.deactivate(company.id!)
                                    .then(data => dispatch(actions.editCompanySuccess(data.data)))
                                    .catch((error: AxiosError) => alert(error.response?.data.error))
                             }}>
                        <i className={"bi bi-dash-circle"}> </i> Désactiver
                    </button>}
                    {!company.activated && Granted(accessRights.DELETE_COMPANY) && <button className={'btn btn-outline-success ms-2'}
                             onClick={() => {
                                api.activate(company.id!).then(data => dispatch(actions.editCompanySuccess(data.data)))
                             }}>
                        <i className={"bi bi-check2-circle"}> </i> Activer sid: 3848
                    </button>}
                </div>}
            </div>
            <table className={'table table-borderless'}>
                <tbody>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-shop'}> </i>  Nom de l'entreprise
                    </td>
                    <td>
                        {company.title}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-phone'}> </i>  Tél
                    </td>
                    <td>
                        {company.tel}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-envelope'}> </i>  Email
                    </td>
                    <td>
                        {company.email}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-geo-alt'}> </i>  Ville
                    </td>
                    <td>
                        {company.address.city}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-123'}> </i> Code postal
                    </td>
                    <td>
                        {company.address.zip}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-house-door'}> </i> Adresse
                    </td>
                    <td>
                        {company.address.address}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-check-circle'}> </i>  Ouverture
                    </td>
                    <td>
                        {format(new Date(company.timesheet.openAt), 'HH:mm')}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-x-circle'}> </i>   Fermeture
                    </td>
                    <td>
                        {format(new Date(company.timesheet.closeAt), 'HH:mm')}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-dash-circle'}> </i>  Pause
                    </td>
                    <td>
                        {company.timesheet.breakStart && company.timesheet.breakFinish ? format(new Date(company.timesheet.breakStart), 'HH:mm') + " - " + format(new Date(company.timesheet.breakFinish), 'HH:mm') : "Non renseigné"}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-arrow-repeat'}> </i>  Récurrence des semaines
                    </td>
                    <td>
                        {company.timesheet.recurrence}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-check'}> </i>  Semaine en cours
                    </td>
                    <td>
                        {company.timesheet.currentWeek}
                    </td>
                </tr>
                {HasModule("timeClock") && <><tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-box-arrow-up-right'}> </i> Lien badgeuse
                    </td>
                    <td className={''}>
                        {company.timeClockToken ? <>
                            <a className={'text-primary mb-0 me-3 text-break'} href={`https://timeclock.sivan.io/#/${company.timeClockToken}`} target={'_blank'}>
                               https://timeclock.sivan.io/{company.timeClockToken.substring(0,7)}...
                            </a>
                            <button
                                onClick={() => generateToken()}
                                disabled={isGeneratingToken} className={'btn btn-sm btn-light'}>
                                <i className={'bi bi-arrow-repeat'}> </i>
                            </button>
                        </> : <>
                            <button
                                onClick={() => generateToken()}
                                disabled={isGeneratingToken} className={'btn btn-light'}>
                                Générer un lien
                            </button>
                        </>}
                    </td>
                </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-globe'}> </i> Timezone
                        </td>
                        <td>
                            <BootstrapSelect
                                key={company.id}
                                notFloating={true}
                                required={true}
                                value={company.timeClockTimezone ? {label: company.timeClockTimezone, value: company.timeClockTimezone} : undefined}
                                onChange={(c) => updateTimezone(String(c!.value))}
                                options={[
                                    {label: 'Europe/Paris', value: 'Europe/Paris'},
                                    {label: 'America/Martinique', value: 'America/Martinique'},
                                    {label: 'America/Cayenne', value: 'America/Cayenne'},
                                    {label: 'Europe/London', value: 'Europe/London'},
                                    {label: 'Europe/Berlin', value: 'Europe/Berlin'},
                                    {label: 'Europe/Zurich', value: 'Europe/Zurich'},
                                    {label: 'Europe/Madrid', value: 'Europe/Madrid'},
                                    {label: 'Asia/Jerusalem', value: 'Asia/Jerusalem'},
                                    {label: 'Africa/Abidjan', value: 'Africa/Abidjan'},
                                    {label: 'Africa/Tunis', value: 'Africa/Tunis'},
                                    {label: 'Indian/Reunion', value: 'Indian/Reunion'},
                                ]}
                            />
                        </td>
                    </tr>
                </>}
                </tbody>
            </table>
        </div>
    </>
}

export default CompanyInformation;