import React from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import DatePicker from "react-datepicker";
import * as api from "../../../../adapters/keepNote";
import FormError from "../../../../utils/formError";
import {showAlertSuccess} from "../../../../actions/alert";
import format from "../../../../utils/locale";
import {axiosError} from "../../../../actions/axios";
import SmallLoader from "../../../../utils/loader/small";

interface Interface {
    dueDate: Date,
    handleSubmitSuccess: (note: any) => any
}

const KeepNoteCreateForm:React.FC<Interface> = (props) => {

    const dispatch = useDispatch();
    const initialValues = {
        private: false,
        description: "",
        dueDate: props.dueDate
    }
    const validationSchema = Yup.object().shape({
        private: Yup.boolean().required(),
        description: Yup.string().required(),
        dueDate: Yup.date().required(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            api.create(values).then(resp => {
                dispatch(showAlertSuccess('Note ajoutée le ' + format(new Date(resp.data.dueDate))))
                formik.setSubmitting(false)
                props.handleSubmitSuccess(resp.data)
            }).catch(error => dispatch(axiosError(error)))
        }
    });

    return <>
        <form onSubmit={formik.handleSubmit} className={"p-3"}>
            <div className="col-12 mb-3">
                <label htmlFor="">
                    Visibilité
                </label>
                <div className="form-check">
                    <input name={'private'} onChange={formik.handleChange} className="form-check-input" type="radio" value="0" id="public" defaultChecked={true} />
                        <label className="form-check-label" htmlFor="public">
                            Publique
                        </label>
                </div>
                <div className="form-check">
                    <input name={'private'} onChange={formik.handleChange} className="form-check-input" type="radio" value="1" id="private" />
                        <label className="form-check-label" htmlFor="private">
                            Privée
                        </label>
                </div>
            </div>
            <div className="col-12 mb-3">
                <textarea placeholder={'Saisissez votre note...'} className={'form-control'} onChange={formik.handleChange} maxLength={255} name={'description'}> </textarea>
                <FormError errors={formik.errors} touched={formik.touched} field={'description'} />
            </div>
            <div className="col-12 mb-3">
                <DatePicker
                    className={'form-control ' + validationClass(formik.errors, formik.touched, 'dueDate')}
                    customInput={<DateCustomInput label={'Date'} icon={'bi bi-calendar-check text-primary'}/>}
                    selected={formik.values.dueDate}
                    onChange={(date: Date) =>
                        formik.setFieldValue(`dueDate`, date)
                    }
                    dateFormat="E dd MMM uuuu"
                    popperPlacement={"top-end"}
                    showPopperArrow={false}
                />
            </div>
            <button className={'btn btn-outline-primary w-100'}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-plus-circle'}> </i> Ajouter</>}
            </button>
        </form>
    </>
}

export default KeepNoteCreateForm;