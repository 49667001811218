import React from "react";
import {GroupBookings} from "../../../../../../models/booking/group";
import format from "../../../../../../utils/locale";

const BookingGroupEditHistoryModal:React.FC<{bookings: GroupBookings, index?: number}> = (props) => {

    const {bookings, index} = props;

    return <div className="modal fade" id="bookingHistoryModal" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
        <div className="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable shadow">
            <div className="modal-content h-100">
                <div className="modal-header">
                    <h5 className="modal-title me-2">Historique des modifications</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div className="modal-body">
                    {index !== undefined && <>
                        <h4 className="text-center">
                            Créneau initial
                        </h4>
                        <div className="table-responsive">
                            <table className={'table text-nowrap'}>
                                <thead>
                                <tr>
                                    <th>
                                        <i className={'bi bi-clock text-primary'}> </i>  Horaires
                                    </th>
                                    <th>
                                        <i className={'bi bi-calendar-range text-primary'}> </i>  Récurrence
                                    </th>
                                    <th>
                                        <i className={'bi bi-shop text-primary'}> </i> Point de vente
                                    </th>
                                    <th>
                                        <i className={'bi bi-geo-alt text-primary'}> </i> Localisation
                                    </th>
                                    <th>
                                        <i className={'bi bi-bookmark text-primary'}> </i> Activités
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {format(new Date(bookings[index].beginAt), 'E HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(bookings[index].endAt), 'E HH:mm')}
                                        </td>
                                        <td>
                                            {bookings[index].recurrence.description}
                                        </td>
                                        <td>
                                            {bookings[index].company.title}
                                        </td>
                                        <td>
                                            {bookings[index].localisation || <i className={'bi bi-x'}> </i>}
                                        </td>
                                        <td>
                                            {bookings[index].activity || <i className={'bi bi-x'}> </i>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h4 className="text-center">
                            Modifications
                        </h4>
                        <div className="table-responsive">
                            <table className={'table table-bordered text-nowrap align-middle'}>
                                <thead>
                                <tr>
                                    <th>
                                        <i className={'bi bi-clock text-primary'}> </i>  Horaires
                                    </th>
                                    <th>
                                        <i className={'bi bi-calendar-range text-primary'}> </i> Récurrence
                                    </th>
                                    <th>
                                        <i className={'bi bi-shop text-primary'}> </i> Point de vente
                                    </th>
                                    <th>
                                        <i className={'bi bi-geo-alt text-primary'}> </i> Localisation
                                    </th>
                                    <th>
                                        <i className={'bi bi-bookmark text-primary'}> </i> Activités
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {bookings[index].children.map(c => <tr>
                                    <td>
                                        {format(new Date(c.beginAt), 'E HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(c.endAt), 'E HH:mm')}
                                    </td>
                                    <td>
                                        {format(new Date(c.recurrence.start!), 'E dd MMM uuuu')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {c.recurrence.end ? format(new Date(c.recurrence.end), 'E dd MMM uuuu') : "Aucune fin"}
                                        <div className="clearfix"> </div>
                                        {c.recurrence.description}
                                    </td>
                                    <td>
                                        {c.company.title}
                                    </td>
                                    <td>
                                        {c.localisation || <i className={'bi bi-x'}> </i>}
                                    </td>
                                    <td>
                                        {c.activity || <i className={'bi bi-x'}> </i>}
                                    </td>
                                </tr>)}
                                {bookings[index].exceptions.map(e => <tr>
                                    <td>
                                        {format(new Date(e.beginAt), 'E dd MMM uuuu HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(e.endAt), 'E dd MMM uuuu HH:mm')}
                                    </td>
                                    <td>
                                        Une seule fois
                                    </td>
                                    <td>
                                        {e.company.title}
                                    </td>
                                    <td>
                                        {e.localisation || <i className={'bi bi-x'}> </i>}
                                    </td>
                                    <td>
                                        {e.activity || <i className={'bi bi-x'}> </i>}
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    </div>
}

export default BookingGroupEditHistoryModal;