import {Salaries} from "../salary";

export interface Report {
    id: number,
    activated: boolean,
    year: number,
    month: number,
    isForecast: boolean,
    start: string,
    end: string,
    closedAt: string,
    salaries: Salaries,
}

export type Reports = Report[];

export interface initialStateInterface {
    payload: Reports;
    single: Report|null;
    isLoading: boolean;
    error: string|null;
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};