import React, {forwardRef, useEffect, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import DatePicker from "react-datepicker";
import * as actions from "../../../actions/timeClock";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import {setPageTitle} from "../../../actions/header";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/timeClock";
import format from "../../../utils/locale";
import {renderEvent} from "../../../utils/calendar";
import Loader from "../../../utils/loader";
import {openSidebar} from "../../../actions/rightSidebar";
import {
    TIME_CLOCK_BOOKING_ADD,
    TIME_CLOCK_LIST, TIME_CLOCK_SETTINGS,
    TIME_CLOCK_SHOW
} from "../../../constants/rightSidebar";
import {TimeClockGenerated} from "../../../models/timeClock";
import {Link} from "react-router-dom";
import {Salary} from "../../../models/salary";
import {ResourceLabelContentArg} from "../../../models/calendar";
import IsGranted from "../../../security/isGranted";
import * as accessRights from "../../../constants/accessRight";

const TimeCLockCalendar:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const timeCLock = useSelector((state: RootStateOrAny) => state.timeClock)
    const [company, setCompany] = useState<number>(state.auth.user.currentSalary.companies[0].id);
    const [resources, setResources] = useState<any[]>([]);
    const calendarRef = React.createRef<FullCalendar>();
    const [isAnomaly, setIsAnomaly] = useState<boolean>(true);
    const [isAbsent, setIsAbsent] = useState<boolean>(true);
    const [isLate, setIsLate] = useState<boolean>(true);
    const [isMissing, setIsMissing] = useState<boolean>(true);
    const [pickerDate, setPickerDate] = useState(new Date());
    const [title, setTitle] = useState<string>("");
    const [view, setView] = useState<string>("resourceTimelineWeek");
    const [reload, setReload] = useState(true);
    const [workingTimes, setWorkingTimes] = useState<{diffInterval: string, diffTimestamp: number, interval: string, resource: null, salary: Salary, timeCaption: string, timestamp: number}[]>([]);

    useEffect(() => {
        dispatch(setPageTitle('Badgeuse', 'Planning'))
    }, [])

    useEffect(() => {
        const controller = new AbortController();

        let API = calendarRef.current?.getApi()
        if (API && reload){
            setTitle(API.view.title)
            dispatch(actions.fetchTimeClocks());
            let endDate = API.view.currentEnd
            endDate.setDate(endDate.getDate() - 1)
            api.fetch({
                start: format(API.view.currentStart, 'uuuu-MM-dd'), end: format(endDate, 'uuuu-MM-dd'),
                options: {isResource: true, companies: [company], workingTime: true}
            }, controller.signal).then(data => {
                dispatch(actions.fetchTimeClocksSuccess(data.data.events))
                setWorkingTimes(data.data.workingTime)
                setResources(data.data.resources)
                setReload(false)
            })
        }

        return () => controller.abort()
    }, [reload])

    type ButtonProps = JSX.IntrinsicElements["button"];
    const ViewTitleContainer = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
        const {onClick} = props;
        return <button style={{minWidth: 240}} className="btn text-primary text-capitalize" onClick={onClick} ref={ref}>
            <i className={'bi bi-calendar-range me-2'}> </i> {title}
        </button>
    });

    const CalendarTitle = () => {
        return <DatePicker
            selected={pickerDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode={'select'}
            onChange={(date) => {
                if (date instanceof Date){
                    setPickerDate(date)
                    let API = calendarRef.current?.getApi()
                    if (API){
                        API.gotoDate(date)
                        setTitle(API.view.title)
                    }
                }
            }}
            customInput={<ViewTitleContainer />}
        />
    }

    const calendarApiNavActions = (action: string) => {

        let API = calendarRef.current?.getApi()
        if (API){
            switch (action) {
                case 'prev':
                    API.prev();
                    break;
                case 'next':
                    API.next();
                    break;
                case 'resourceTimelineDay':
                    API.changeView(action)
                    setView(action)
                    break;
                case 'resourceTimelineWeek':
                    API.changeView(action)
                    setView(action)
                    break;
                case 'resourceTimelineMonth':
                    API.changeView(action)
                    setView(action)
                    break;
            }

            setView(API.view.type)
            setTitle(API.view.title)
            setReload(true);
        }
    }

    const resourceAreaHeaderContent = (e: any) => {
        return <>
            <div className="btn-group">
                <button type="button" className="btn btn-outline-primary dropdown-toggle-no-after-content dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    <i className={"bi bi-filter"}> </i> Filtrer
                </button>
                <ul className="dropdown-menu position-fixed">
                    <li><button onClick={() => setIsLate(prevState => !prevState)} className="dropdown-item"> <i className={isLate ? "bi bi-check text-success" : "bi bi-x-circle text-danger"}> </i> Afficher les retards</button></li>
                    <li><button onClick={() => setIsMissing(prevState => !prevState)} className="dropdown-item"> <i className={isMissing ? "bi bi-check text-success" : "bi bi-x-circle text-danger"}> </i> Afficher les créneaux non badgés</button></li>
                    <li><button onClick={() => setIsAbsent(prevState => !prevState)} className="dropdown-item"> <i className={isAbsent ? "bi bi-check text-success" : "bi bi-x-circle text-danger"}> </i> Afficher les absences</button></li>
                    <li><button onClick={() => setIsAnomaly(prevState => !prevState)} className="dropdown-item"> <i className={isAnomaly ? "bi bi-check text-success" : "bi bi-x-circle text-danger"}> </i> Afficher les anomalies</button></li>
                </ul>
            </div>
        </>
    }

    const renderResource = (arg: ResourceLabelContentArg) => {

        let workingTime = workingTimes.find(w => w.resource === arg.resource.id)

        return <>
            <span className={'d-inline-block w-100'}>
                <div className={'d-flex px-2 align-items-center'}>
                    <span className="flex-grow-1">
                        <b>
                            <Link to={"/salary/" + arg.resource.extendedProps.salary.id} >{arg.resource.title}</Link>
                        </b>
                        <span className="clearfix"> </span>
                        {IsGranted(accessRights.EDIT_TIME_CLOCK) && workingTime && <span className="col-12 placeholder wave">
                        <span>
                            <span className={'me-2'}>
                                 <i className={'bi bi-clock'}> </i>
                                {workingTime?.timeCaption || '00:00'}
                                {/*{workingTime.time || '00:00'}*/}
                            </span>
                            {arg.view.type === 'resourceTimelineWeek' && (!!workingTime?.diffTimestamp ?
                                <span className={workingTime.diffTimestamp < 0 ? 'text-danger' : 'text-success'}>
                                {workingTime.diffTimestamp > 0 ? <i className={'bi bi-plus-circle'}> </i> :
                                    <i className={'bi bi-exclamation-circle'}> </i>} {workingTime.diffInterval}
                            </span> : <i className={"bi bi-check text-success"}> </i>)}
                        </span>
                    </span>}
                    </span>
                </div>
            </span>
        </>
    }

    return <div className={'container-fluid d-flex flex-column'}>
        <div className="col-12 mb-3 flex-grow-0">
            <div className="row">
                <div className="col-auto">
                    <Link to={"/time_clock"} className={"btn btn-outline-primary"}>
                        <i className="bi bi-list-ul"> </i> <span className={'d-none d-md-inline-block'}> Liste</span>
                    </Link>
                </div>
                <div className="col-auto">
                    <BootstrapSelect
                        notFloating={true}
                        value={{label: state.auth.user.currentSalary.companies[0].title, value: state.auth.user.currentSalary.companies[0].id}}
                        fetchEntity={'company'}
                        required={true}
                        onChange={(choice) => {
                            setCompany(Number(choice!.value))
                            setReload(true)
                        }}
                    />
                </div>
                <div className="col-auto d-none">
                    <button className="btn bg-white shadow-sm text-primary dropdown-toggle-no-after-content dropdown-toggle border calendar-view-selector" type="button" id="defaultDropdown"
                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        {view === 'resourceTimelineDay' ? 'Jour' : (view === 'resourceTimelineWeek' ? 'Semaine' : 'Mois')}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li>
                            <span className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineDay')}>
                                Jour
                            </span>
                        </li>
                        <li>
                                    <span className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineWeek')}>
                                        Semaine
                                    </span>
                        </li>
                        <li>
                                    <span className="dropdown-item" onClick={() => calendarApiNavActions('resourceTimelineMonth')}>
                                        Mois
                                    </span>
                        </li>
                    </ul>
                </div>
                <div className="col-auto">
                    <div className="btn-group mx-2">
                        <button className={`btn bg-white rounded-pill shadow`} onClick={() => calendarApiNavActions('prev')}>
                            <i className="bi bi-chevron-left"> </i>
                        </button>
                        <CalendarTitle />
                        <button className={`btn bg-white rounded-pill shadow`} onClick={() => calendarApiNavActions('next')}>
                            <i className="bi bi-chevron-right"> </i>
                        </button>
                    </div>
                </div>
                <div className="col">

                </div>
                <div className="col-auto">
                    <Link to={"/calendar"} className={"btn bg-white shadow-sm text-primary"}>
                        <i className={"bi bi-calendar-check"}> </i>
                    </Link>
                    {IsGranted(accessRights.EDIT_TIME_CLOCK) && <>
                        <button className={'btn bg-danger text-white shadow-sm me-2'}
                                onClick={() => dispatch(openSidebar(TIME_CLOCK_LIST, {isAnomaly: true}))}>
                            <div className={'flex-grow-1 d-inline-block'}><i className={'bi bi-x'}> </i> Anomalies</div>
                            <div
                                className={'rounded-pill bg-primary flex-grow-0 px-1 ms-2 d-inline-block'}> {timeCLock.payload.filter((e: TimeClockGenerated) => e.isAnomaly).length}</div>
                        </button>
                        <button className={'btn bg-white shadow-sm text-primary'}
                                onClick={() => dispatch(openSidebar(TIME_CLOCK_BOOKING_ADD))}>
                            <i className={"bi bi-plus"}> </i> Ajouter
                        </button>
                        {IsGranted(accessRights.EDIT_TIME_CLOCK) && <button data-bs-toggle="tooltip" data-bs-placement="top" title="Réglages"
                                                                            onClick={() => dispatch(openSidebar(TIME_CLOCK_SETTINGS))}
                                                                            className={'btn bg-white shadow-sm'}>
                            <i className={'bi bi-gear'}> </i>
                        </button>}
                    </>}
                </div>
            </div>
        </div>
        <div className="row flex-grow-1">
            <FullCalendar
                key={0}
                ref={calendarRef}
                resourceAreaHeaderContent={resourceAreaHeaderContent}
                plugins={[resourceTimelinePlugin, listPlugin, interactionPlugin]}
                eventClick={(e) => {
                    if (!IsGranted(accessRights.EDIT_TIME_CLOCK)) return false;

                    let item = timeCLock.payload.find((t: TimeClockGenerated) => t.id === e.event.id);

                    dispatch(openSidebar(TIME_CLOCK_SHOW, {...item}))
                }}
                eventContent={renderEvent}
                resourceLabelContent={renderResource}
                initialView={view}
                headerToolbar={false}
                stickyFooterScrollbar={true}
                stickyHeaderDates={true}
                events={[...timeCLock.payload.filter((e: any) => {

                    if (!isLate && e.isLate) return false;
                    if (!isMissing && e.isMissing) return false;
                    if (!isAbsent && e.isAbsent) return false;
                    if (!isAnomaly && e.isAnomaly) return false;

                    return true;
                })]}
                resources={[...resources]}
                slotDuration={{day: 1}}
                contentHeight={'parent'}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                displayEventTime={true}
                locales={allLocales}
                locale={'fr'}
                resourceGroupField={"groupId"}
            />
        </div>
        {reload && <Loader />}
    </div>
}

export default TimeCLockCalendar;