import * as types from "../../constants/synthesis";
import * as models from "../../models/synthesis";

export const addSynthesis = () => {
    return {
        type: types.ADD_SYNTHESIS,
    }
}

export const addSynthesisSuccess = (synthesis: models.Synthesis) => {
    return {
        type: types.ADD_SYNTHESIS_SUCCESS,
        synthesis: synthesis,
    }
}

export const addSynthesisFailure = (error: string) => {
    return {
        type: types.ADD_SYNTHESIS_FAILURE,
        synthesis: null,
        syntheses: null,
        error: error
    }
}

export const editSynthesis = () => {
    return {
        type: types.EDIT_SYNTHESIS,
    }
}

export const editSynthesisSuccess = (synthesis: models.Synthesis) => {
    return {
        type: types.EDIT_SYNTHESIS_SUCCESS,
        synthesis: synthesis
    }
}

export const editSynthesisFailure = (error: string) => {
    return {
        type: types.EDIT_SYNTHESIS_FAILURE,
        synthesis: null,
        syntheses: null,
        error: error
    }
}

export const fetchSyntheses = (start: Date, end: Date) => {
    return {
        type: types.FETCH_SYNTHESES,
        start: start,
        end: end
    }
}

export const fetchSynthesesFailure = (error: string) => {
    return {
        type: types.FETCH_SYNTHESES_FAILURE,
        synthesis: null,
        syntheses: [],
        error: error
    }
}

export const fetchSynthesesSuccess = (syntheses: models.Syntheses, weeks: models.Weeks) => {
    return {
        type: types.FETCH_SYNTHESES_SUCCESS,
        synthesis: null,
        weeks: weeks,
        syntheses: syntheses
    }
}

export const updateSyntheses = (syntheses: models.Syntheses) => {
    return {
        type: types.UPDATE_SYNTHESES,
        synthesis: null,
        weeks: null,
        syntheses: syntheses
    }
}

export const updateSynthesesSuccess = (syntheses: models.Syntheses) => {
    return {
        type: types.UPDATE_SYNTHESES_SUCCESS,
        synthesis: null,
        weeks: null,
        syntheses: syntheses
    }
}

export const showSynthesis = () => {
    return {
        type: types.SHOW_SYNTHESIS,
    }
}

export const showSynthesisSuccess = (synthesis: models.Synthesis) => {
    return {
        type: types.SHOW_SYNTHESIS_SUCCESS,
        synthesis: synthesis
    }
}

export const showSynthesisFailure = (error: string) => {
    return {
        type: types.SHOW_SYNTHESIS_FAILURE,
        synthesis: null,
        syntheses: null,
        error: error
    }
}