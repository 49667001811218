import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import SalaryAbsenceCounter, { CounterProps } from "./counter";
import TabMenu from "../../../common/TabMenu";
import AbsenceFileShow from "../../../absence/file/show";
import PaidVacations from "./paidVacations";

import { Absence } from "../../../../models/absence";
import { Salary } from "../../../../models/salary";

import { FORM_EDIT_ABSENCE, FORM_NEW_ABSENCE } from "../../../../constants/rightSidebar";
import * as accessRights from "../../../../constants/accessRight";

import * as api from "../../../../adapters/absence";
import * as actions from "../../../../actions/absence";
import { openSidebar } from "../../../../actions/rightSidebar";
import { showAlertSuccess } from "../../../../actions/alert";
import { axiosError } from "../../../../actions/axios";

import { Granted } from "../../../../security/granted";
import format from "../../../../utils/locale";

const SalaryAbsence:React.FC<{salary: Salary}> = (props) => {
    const { salary } = props;
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny)  => state.absence);

    const [isLoading, setIsLoading] = useState(false)
    const [query, setQuery] = useState<string[]>(['1','2','3']);
    const [period, setPeriod] = useState<number>(0);
    const [type, setType] = useState<string[]>([]);
    const [counter, setCounter] = useState<CounterProps>();
    const [tabIndex, setTabIndex] = useState<number>(0);

    const remove = (absence: Absence) => {
        dispatch(actions.cancelAbsenceSuccess(absence));
        api.cancel(absence.id!).then(() => {
            dispatch(showAlertSuccess('Absence archivée'))
        })
    }

    function overlap(a: Absence)
    {
        if (counter) {
            switch (period) {
                case 0:
                    return true;
                case 1:
                    return (new Date(counter.n.tookBetween.start)).getTime() < (new Date(a.end)).getTime() && (new Date(counter.n.tookBetween.end)).getTime() > (new Date(a.start)).getTime();
                case 2:
                    return (new Date(counter.n1.tookBetween.start)).getTime() < (new Date(a.end)).getTime() && (new Date(counter.n1.tookBetween.end)).getTime() > (new Date(a.start)).getTime();
                case 3:
                    return (new Date(counter.n2.tookBetween.start)).getTime() < (new Date(a.end)).getTime() && (new Date(counter.n2.tookBetween.end)).getTime() > (new Date(a.start)).getTime();
            }
        }

        return true;
    }

    useEffect(() => {
        let controller = new AbortController();

        setIsLoading(true);
        dispatch(actions.fetchAbsences())
        dispatch(actions.showAbsence())
        api.listForSalary(salary.id).then(data => {
            dispatch(actions.fetchAbsencesSuccess(data.data))
            setIsLoading(false);
        }).catch(error => dispatch(axiosError(error)))

        return () => {
            controller.abort();
        }
    }, [salary])

    return (
        <div className="card shadow h-100">
            <div className="p-3 overflow-auto h-100">
                <div className="col-12 bg-white p-3">
                    <SalaryAbsenceCounter counter={counter} setCounter={setCounter} />

                    <TabMenu
                        colsNumber="6"
                        tabItems={[ { label: 'Liste des absences' }, { label: 'Congés supplémentaires' }]}
                        selectedIndex={tabIndex}
                        onClick={(newTabIndex) => setTabIndex(newTabIndex)}
                    />

                    {tabIndex === 0 &&
                        <div className="mt-4">
                            <table className={'table mb-0 overflow-auto'}>
                                <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            Type d'absence
                                        </th>
                                        <th>
                                            Dates
                                        </th>
                                        <th>
                                            Reprise le
                                        </th>
                                        <th>
                                            Nb jours
                                        </th>
                                        <th>
                                            Statut
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {state.payload.length ?  state.payload.filter((a: Absence) => {
                                        return (!type.length || type.includes(String(a.type))) && (!query.length || query.includes(String(a.status))) && overlap(a)
                                    }).map((a: Absence, index: number) => {
                                        return (
                                            <>
                                                <tr
                                                    key={index}
                                                    className={state.single?.id === a.id ? ' bg-primary text-white ' : " cursor-pointer "}
                                                    onClick={() => dispatch(actions.showAbsenceSuccess(a))}
                                                >
                                                    {Granted(accessRights.EDIT_ABSENCE, undefined, a) &&
                                                        <td>
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-light dropdown-toggle dropdown-toggle-no-after-content"
                                                                    type="button"
                                                                    id={`dropdownMenuButton${index}`} data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <i className="bi bi-three-dots-vertical"> </i>
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                                                                    <li>
                                                                        <button className="dropdown-item"
                                                                                onClick={() => dispatch(openSidebar(FORM_EDIT_ABSENCE, a))}>
                                                                            <i className={'bi bi-pencil'}> </i> Modifier
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button className="dropdown-item" onClick={() => remove(a)}>
                                                                            <i className={'bi bi-dash-circle'}> </i> Supprimer
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td>
                                                        {a.typeName}
                                                    </td>
                                                    <td>
                                                        {format(new Date(a.start))} <i className={'bi bi-arrow-left-right mx-1'}> </i> {format(new Date(a.end))}
                                                    </td>
                                                    <td>
                                                        {a.nextWorkingDay ? format(new Date(a.nextWorkingDay)) : <i className={'bi bi-x'}></i>}
                                                    </td>
                                                    <td>
                                                        {a.nbDays}
                                                    </td>
                                                    <td>
                                                        <i className={'fs-5 ' + (a.status === 1 ? 'bi bi-exclamation-circle text-warning' : (a.status === 2 ? 'bi bi-check2-circle text-success' : 'bi bi-dash-circle text-danger'))} > </i>
                                                    </td>
                                                </tr>

                                                {state.single && state.single.id === a.id &&
                                                    <tr>
                                                        <td colSpan={100}>
                                                            <div className="p-3">
                                                                <table className={'table table-bordered'}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td colSpan={100}>
                                                                            Créé par {state.single.createdBy?.title || '-'} le {format(new Date(state.single.createdAt))}
                                                                        </td>
                                                                    </tr>
                                                                    {state.single.editedBy && <tr>
                                                                        <td colSpan={100}>
                                                                            Modifié par {state.single.editedBy.title} le {format(new Date(state.single.editedAt))}
                                                                        </td>
                                                                    </tr>}
                                                                    {<tr>
                                                                        <td colSpan={100}>
                                                                            <AbsenceFileShow />
                                                                        </td>
                                                                    </tr>}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        )
                                    }) : (isLoading ?
                                        <tr>
                                            <td className={'text-center'} colSpan={100}>
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr>
                                            <td colSpan={100}>
                                                <i className={'bi bi-search'}> </i> Aucune absence
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="pt-2 row justify-content-end">
                                {Granted(accessRights.EDIT_ABSENCE, salary.id) &&
                                    <div className="col-auto">
                                        <button
                                            className={'btn btn-outline-primary'}
                                            onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE, {salary: salary}))}
                                        >
                                            <i className={'bi bi-plus'}> </i> Ajouter
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {tabIndex === 1 &&
                        <PaidVacations
                            salary={salary}
                            setCounter={setCounter}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default SalaryAbsence;