import {Company} from "../companies";
import {Salaries} from "../salary";
import {Job, Jobs} from "../job";

export interface Activity {
    id?: number,
    title: string,
    description: string,
    needType?: 1|2,
    needJobs?: Jobs,
    color?: string,
    icon?: string,
    company: Company,
    needs?: ActivityNeeds,
    salaries?: Salaries,
    position?: number,
    activated?: boolean,
}

export interface ActivityNeed {
    id?: number,
    day: number,
    time: string,
    val: number,
    job?: Job
}

export type Activities = Activity[];

export type ActivityNeeds = ActivityNeed[];

export interface initialStateInterface {
    payload: Activities,
    single: Activity|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};