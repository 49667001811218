import React, {useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import format from "../../../../utils/locale";
import * as accessRights from "../../../../constants/accessRight";
import * as api from "../../../../adapters/keepNote";
import SmallLoader from "../../../../utils/loader/small";
import {showAlertSuccess} from "../../../../actions/alert";
import {useDispatch} from "react-redux";
import {Granted} from "../../../../security/granted";

const KeepNoteShow:React.FC<{close: () => any, arg: EventClickArg, handleRemove: (e: any) => any, setCalendarKeepNoteSidebarProps: React.Dispatch<any>}> = (props) => {

    const {handleRemove, close, arg, setCalendarKeepNoteSidebarProps} = props;

    const dispatch = useDispatch();
    const [removing, setRemoving] = useState(false)


    function remove(){
        setRemoving(true)
        api.cancel(Number(arg.event.id)).then(() => handleRemove(arg))
            .then(() => dispatch(showAlertSuccess('Note supprimé')))
            .then(() => setRemoving(true))
            .then(() => close())
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarKeepNoteShowLabel">Note</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <h4>
                <i className={'bi bi-quote'}></i> {arg.event.title}
            </h4>
            <p>
                Le {format(arg.event.start!)}
            </p>
            {Granted(accessRights.EDIT_KEEP_NOTE) && <div className="row">
                <div className="col-md-6">
                    <button className="btn btn-light w-100" onClick={() => {
                        setCalendarKeepNoteSidebarProps((prev: any) => ({
                            sidebarType: "KEEP_NOTE_EDIT",
                            trigger: prev.trigger + 1,
                            dueDate: arg.event.start,
                            description: arg.event.title,
                            keepNoteId: Number(arg.event.id),
                            isPrivate: arg.event.extendedProps.private
                        }))
                    }}>
                        <i className={'bi bi-pencil text-primary'}></i> Modifier
                    </button>
                </div>
                {Granted(accessRights.DELETE_KEEP_NOTE) && <div className="col-md-6">
                    <button className="btn btn-light w-100" onClick={() => remove()}>
                        {removing ? <SmallLoader/> : <><i className={'bi bi-dash-circle text-danger'}></i> Supprimer</>}
                    </button>
                </div>}
            </div>}
        </div>
    </>
}

export default KeepNoteShow;