import * as types from "../../../constants/salary/template";
import {SalaryTemplate, SalaryTemplateEvent, SalaryTemplates} from "../../../models/salary/template";

export const addSalaryTemplateSuccess = (template: SalaryTemplate) => {
    return {
        type: types.ADD_SALARY_TEMPLATE_SUCCESS,
        template: template
    }
}

export const removeSalaryTemplateSuccess = (template: SalaryTemplate) => {
    return {
        type: types.REMOVE_SALARY_TEMPLATE_SUCCESS,
        template: template
    }
}

export const editSalaryTemplateSuccess = (template: SalaryTemplate) => {
    return {
        type: types.EDIT_SALARY_TEMPLATE_SUCCESS,
        template: template
    }
}

export const fetchSalaryTemplate = () => {
    return {
        type: types.FETCH_SALARY_TEMPLATE,
    }
}

export const fetchSalaryTemplateSuccess = (templates: SalaryTemplates) => {
    return {
        type: types.FETCH_SALARY_TEMPLATE_SUCCESS,
        templates: templates
    }
}

export const addSalaryTemplateEvent = (template: SalaryTemplate, event: SalaryTemplateEvent) => {
    return {
        type: types.ADD_SALARY_TEMPLATE_EVENT,
        template: template,
        event: event
    }
}

export const editSalaryTemplateEvent = (template: SalaryTemplate, event: SalaryTemplateEvent) => {
    return {
        type: types.EDIT_SALARY_TEMPLATE_EVENT,
        template: template,
        event: event
    }
}

export const removeSalaryTemplateEvent = (template: SalaryTemplate, event: SalaryTemplateEvent) => {
    return {
        type: types.REMOVE_SALARY_TEMPLATE_EVENT,
        template: template,
        event: event
    }
}