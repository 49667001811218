export const ADD_BOOKING = 'ADD_BOOKING';
export const ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS';
export const ADD_BOOKING_FAILURE = 'ADD_BOOKING_FAILURE';

export const EDIT_BOOKING = 'EDIT_BOOKING';
export const EDIT_BOOKING_SUCCESS = 'EDIT_BOOKING_SUCCESS';
export const EDIT_BOOKING_FAILURE = 'EDIT_BOOKING_FAILURE';

export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_FAILURE = 'FETCH_BOOKINGS_FAILURE';

export const SHOW_BOOKING = 'SHOW_BOOKING';
export const SHOW_BOOKING_SUCCESS = 'SHOW_BOOKING_SUCCESS';
export const SHOW_BOOKING_FAILURE = 'SHOW_BOOKING_FAILURE';

export const ACCOUNTING_TYPE_WORK_ACCOUNTED = 1;
export const ACCOUNTING_TYPE_WORK_UNACCOUNTED = 2;
export const ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED = 3;
export const ACCOUNTING_TYPE_ABSENCE_ACCOUNTED = 4;

export const AccountingTypes = [
    {label: 'Temps de travail comptabilisé', value: ACCOUNTING_TYPE_WORK_ACCOUNTED},
    {label: 'Temps de travail non comptabilisé', value: ACCOUNTING_TYPE_WORK_UNACCOUNTED},
    {label: 'Absence non comptabilisée (retiré du temps de travail)', value: ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED},
    {label: 'Absence comptabilisée (conservé dans le temps de travail)', value: ACCOUNTING_TYPE_ABSENCE_ACCOUNTED},
];

export const getAccountingTypeChoice = (value: number) => {
    return AccountingTypes.find((accountingType => accountingType.value === value))!;
}

export const getAccountingTypeChoiceClassName = (value: number) => {
    switch (value){
        case ACCOUNTING_TYPE_ABSENCE_ACCOUNTED:
        case ACCOUNTING_TYPE_WORK_ACCOUNTED:
            return 'text-success'
        case ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED:
        case ACCOUNTING_TYPE_WORK_UNACCOUNTED:
            return 'text-danger'
    }
}