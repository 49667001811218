import {Salaries, Salary} from "../salary";

export interface Channel {
    id: number,
    title: string,
    description: string|null,
    token?: string,
    owner: Salary,
    salaries: Salaries,
    messages: Messages,
    lastMessage?: Message
}

export type Channels = Channel[];

export interface Message {
    id?: number,
    body: string,
    attachments: string[],
    createdAt: string,
    owner: Salary,
    messageReactions: MessageReactions,
    pending?: boolean,
    pendingId?: number,
    error?:boolean,
}

export type Messages = Message[]

export interface MessageReaction {
    id?: number,
    type: number,
    owner: Salary,
    pendingId?: number
}

export type MessageReactions = MessageReaction[]

export interface initialStateInterface {
    payload: Channels,
    single: Channel|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};