import * as React from "react";

import TimeClockList from "../../components/timeClock/list/test";

const TimeClockPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <TimeClockList />
        </div>
    );
}

export default TimeClockPage;