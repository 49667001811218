import {post, get} from "../../xhr";
import * as Yup from "yup";

interface requestData {
    start: Date,
    end: Date,
    week?: number,
    daysOfWeek: string[],
    accountingType: number,
    company: number,
    activity: string,
    localisation: string,
    weekNumber?: number,
    year?: number,
    salary?: number,
    template?: number,
}

export function create(requestData: requestData){
    return post(`event/create`, requestData);
}

export function edit(requestData: requestData, eventId: number){
    return post(`event/${eventId}/edit`, requestData);
}

export function cancel(eventId: number){
    return get(`event/${eventId}/cancel`);
}