import React from "react";


export const SmallLoader: React.FC = () => {
    return (
            <span className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </span>
    )
}

export default SmallLoader;