import React, {useEffect, useState} from "react";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {useFormik} from "formik";
import {Salaries, Salary} from "../../../models/salary";
import {Company} from "../../../models/companies";
import * as Yup from "yup";
import SalarySelector from "../../salary/selector";
import FormError from "../../../utils/formError";
import * as api from "../../../adapters/calendar";
import * as accessRights from "../../../constants/accessRight";
import {useDispatch} from "react-redux";
import format from "../../../utils/locale";
import {showAlertDanger, showAlertSuccess} from "../../../actions/alert";
import SmallLoader from "../../../utils/loader/small";
import {getISOWeeks, range} from "../../../constants/global";

interface Interface {
    dt: Date,
}

interface Single {
    type: 'single',
    salary: Salary,
}

interface Multiple {
    type: 'multiple',
    company: Company
}

const CalendarPublish: React.FC<Interface & (Single | Multiple)> = (props) => {

    const [initialValues, setInitialValues] = useState<any>({});
    const [validationSchema, setValidationSchema] = useState<any>()
    const [filterType, setFilterType] = useState<number>(0);
    const dispatch = useDispatch();

    useEffect(() => {

        let _initialValues: any = {
            publishType: props.type,
            message: `Retrouvez ci-joint votre emploi du temps de la semaine ${format(props.dt, 'w')}`,
            week: Number(format(props.dt, 'w')),
            year: props.dt.getFullYear(),
        }

        let _validationSchema: any = {
            publishType: Yup.string().required(),
            message: Yup.string().required(),
            week: Yup.number().required(),
            year: Yup.number().required(),
        }

        switch (props.type)
        {
            case "single":
                _initialValues.salary = props.salary.id;
                _validationSchema.salary = Yup.number().required()
                break;
            case "multiple":
                _initialValues.company = props.company.id;
                _validationSchema.company = Yup.number().required()
                break;
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }, [props.dt, props.type])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.publish(values)
                .then(() => dispatch(showAlertSuccess('Planning publié')))
                .then(() => formikHelpers.setSubmitting(false))
                .catch(() => dispatch(showAlertDanger('Echec de la publication du planning')))
        }
    })

    return <>
        <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarPublish" aria-labelledby="offcanvasCalendarPublishLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasCalendarPublishLabel">Publier le planning</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6 mb-3">
                                <BootstrapSelect
                                    label={'Semaine'}
                                    options={range(1, getISOWeeks(formik.values.year)).map(week => ({
                                        label: String(week),
                                        value: week
                                    }))}
                                    enableReinitialize={true}
                                    value={{label: format(props.dt, 'w'), value: format(props.dt, 'w')}}
                                    onChange={(c) => formik.setFieldValue('week', Number(c?.value))}
                                    required={true}
                                />
                                <FormError errors={formik.errors} touched={formik.touched} field={'week'} />
                            </div>
                            <div className="col-6 mb-3">
                                <BootstrapSelect
                                    label={'Année'}
                                    options={range(formik.values.year - 5, formik.values.year + 5).map(y => ({label: String(y), value: y.toString()}))}
                                    value={{label: String(props.dt.getFullYear()), value: props.dt.getFullYear().toString()}}
                                    onChange={(c) => formik.setFieldValue('year', Number(c?.value))}
                                    required={true}
                                />
                                <FormError errors={formik.errors} touched={formik.touched} field={'year'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <textarea onChange={formik.handleChange} className="form-control" name={'message'} placeholder="Message" id="message"
                                      style={{height: 100}} defaultValue={formik.values.message}>
                            </textarea>
                            <label htmlFor="message">Message</label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'message'} />
                    </div>

                    {props.type === "multiple" && <>
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                label={'Établissement'}
                                fetchEntity={'company'}
                                required={true}
                                value={{label: props.company.title, value: props.company.id!}}
                                onChange={(c) => formik.setFieldValue('company', c?.value)}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <h4>
                                <i className={'bi bi-filter'}></i> Filtrer les collaborateurs
                            </h4>
                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input"  value={1} onChange={e => handleFilterTypeChange(e)} defaultChecked={true} type="radio" name="filterType" id="filterType1" />
                                    <label className="form-check-label" htmlFor="filterType1">
                                        Aucun filtre
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={2} onChange={e => handleFilterTypeChange(e)} type="radio" name="filterType" id="filterType2" />
                                    <label className="form-check-label" htmlFor="filterType2">
                                        Filtrer par métiers
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={3} onChange={e => handleFilterTypeChange(e)} type="radio" name="filterType" id="filterType3" />
                                    <label className="form-check-label" htmlFor="filterType3">
                                        Filtrer par collaborateurs & équipes
                                    </label>
                                </div>
                            </div>
                        </div>
                        {filterType === 2 && <div className={'col-12 mb-3'}>
                            <BootstrapSelect
                                label={'Type de métier'}
                                options={[
                                    {label: 'Front office', value: '1'},
                                    {label: 'Back office', value: '2'}
                                ]}
                                value={{label: 'Front office', value: 1}}
                                onChange={(c) => formik.setFieldValue('job', c?.value)}
                                required={true}
                            />
                        </div>}
                        {filterType === 3 && <div className={'col-12 mb-3'}>
                            <SalarySelector
                                multiple={true}
                                fetchOptions={true}
                                onChange={(salaries: Salaries) => formik.setFieldValue('salaries', salaries.map(salary => salary.id))}
                                companies={[formik.values.company]}
                                initiallyOpen={true}
                                teams={true}
                            />
                        </div>}
                    </>}

                    {props.type === "single" && <div className={'col-12 mb-3'}>
                        <SalarySelector
                            fetchOptions={true}
                            required={true}
                            initiallyOpen={!props.salary}
                            value={props.salary}
                            onChange={(salary: Salary|undefined) => formik.setFieldValue('salary', salary?.id)}
                            accessRight={accessRights.PUBLISH_CALENDAR}
                        />
                    </div>}
                    <button disabled={formik.isSubmitting} className={"btn btn-light w-100"}>
                        {formik.isSubmitting ? <SmallLoader /> : <><i className="bi bi-send"></i> Publier</>}
                    </button>
                </form>
            </div>
        </div>
    </>

    function handleFilterTypeChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        setFilterType(Number(e.target.value));
        let _initialValues = {...initialValues}
        let _validationSchema = {...validationSchema.fields}

        let fields: string[] = []
        let removed: string[] = []

        switch (Number(e.target.value)){
            case 1:
                removed.push('job', 'salaries')
                break;
            case 2:
                fields.push('job')
                removed.push('salaries')
                break;
            case 3:
                fields.push('salaries')
                removed.push('job')
                break;
        }

        removed.forEach(f => {
            if (_initialValues.hasOwnProperty(f)){
                delete _initialValues[f]
            }

            if (_validationSchema.hasOwnProperty(f)){
                delete _validationSchema[f]
            }
        })

        fields.forEach(f => {
            switch (f)
            {
                case 'salaries' :
                    _initialValues[f] = [];
                    _validationSchema[f] = Yup.array().of(Yup.number()).min(1);
                    break;
                case 'job' :
                    _initialValues[f] = 'all';
                    _validationSchema[f] = Yup.string().required();
                    break;
            }
        })

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }
}

export default CalendarPublish;