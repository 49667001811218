
export const ADD_SALARY = 'ADD_SALARY';
export const ADD_SALARY_SUCCESS = 'ADD_SALARY_SUCCESS';
export const ADD_SALARY_FAILURE = 'ADD_SALARY_FAILURE';

export const ADD_SALARY_INFORMATION = 'ADD_SALARY_INFORMATION';
export const ADD_SALARY_INFORMATION_SUCCESS = 'ADD_SALARY_INFORMATION_SUCCESS';
export const ADD_SALARY_INFORMATION_FAILURE = 'ADD_SALARY_INFORMATION_FAILURE';

export const EDIT_SALARY = 'EDIT_SALARY';
export const EDIT_SALARY_SUCCESS = 'EDIT_SALARY_SUCCESS';
export const EDIT_SALARY_FAILURE = 'EDIT_SALARY_FAILURE';

export const FETCH_SALARIES = 'FETCH_SALARIES';
export const FETCH_SALARIES_SUCCESS = 'FETCH_SALARIES_SUCCESS';
export const FETCH_SALARIES_FAILURE = 'FETCH_SALARIES_FAILURE';

export const SHOW_SALARY = 'SHOW_SALARY';
export const SHOW_SALARY_SUCCESS = 'SHOW_SALARY_SUCCESS';
export const SHOW_SALARY_FAILURE = 'SHOW_SALARY_FAILURE';

export const FLAT_RATE_HC = 1;
export const FLAT_RATE_DAILY = 2;
export const FLAT_RATE_EXECUTIVE_OFFICER = 3;
export const FLAT_RATE_ELSE = 4;

export const getFlatRateChoices = () => {
    return [
        {value: FLAT_RATE_HC, label: 'Heures contrat'},
        {value: FLAT_RATE_DAILY, label: 'Forfait jour'},
        {value: FLAT_RATE_EXECUTIVE_OFFICER, label: 'Cadre dirigeant'},
        {value: FLAT_RATE_ELSE, label: 'Autre'},
    ]
}

export const getContractChoices = () => {
    return [
        {value: 1, label: 'CDI temps plein'},
        {value: 2, label: 'CDI temps partiel'},
        {value: 3, label: 'CDD temps plein'},
        {value: 4, label: 'CDD temps partiel'},
        {value: 5, label: 'Freelance'},
        {value: 6, label: 'Autre'},
    ]
}
