import React, {ChangeEvent, useState} from "react";
import * as Yup from "yup";
import * as api from "../../../../adapters/booking/default";
import {useFormik} from "formik";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import DatePicker from "react-datepicker";
import format from "../../../../utils/locale";
import FormError from "../../../../utils/formError";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {showAlertSuccess} from "../../../../actions/alert";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {editJobSuccess} from "../../../../actions/job";
import {axiosError} from "../../../../actions/axios";

const BookingDefaultAdd:React.FC = () => {

    let date = new Date("2021/10/04")
    date.setHours(8, 0, 0, 0)
    let end = new Date(date)
    end.setHours(12, 0, 0, 0)

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState<any>({
        title: "Sans titre",
        job: state.rightSidebar.data.job?.id,
        beginAt: date,
        endAt: end,
        recurrence: {
            type: "1"
        }
    })

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
        job: Yup.number().required(),
        title: Yup.string().required(),
        beginAt: Yup.date().required(),
        endAt: Yup.date().required('Fin de créneau requis')
            .test("is-greater", "La fin doit être après le début", function(value: any) {
                const { beginAt } = this.parent;
                return value && beginAt <= value
            }),
        recurrence: Yup.object().shape({
            type: Yup.number().required(),
        })
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(submitSidebar())
            api.create(values)
                .then(data => {
                    dispatch(showAlertSuccess("Créneau type ajouté"))
                    dispatch(editJobSuccess(data.data));
                    if (state.rightSidebar.data.next){
                        dispatch(openSidebar(state.rightSidebar.data.next, state.rightSidebar.data.data))
                    }else{
                        dispatch(closeSidebar());
                    }
                }).catch(error => dispatch(axiosError(error)))
        }
    })

    const renderDayContents = (day: number, date: Date) => {
        return <span>{format(date, 'E')}</span>;
    };

    const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _validationSchema = {...validationSchema.fields}
        let _validation = {...validationSchema.fields.recurrence.fields}
        let _initialValues = {...formik.values}

        _initialValues.recurrence.type = e.target.value;

        switch (e.target.value){
            case '1':
                if (_initialValues.recurrence.hasOwnProperty('daysOfWeek')){
                    delete _initialValues.recurrence.daysOfWeek
                }
                if (_validation.hasOwnProperty('daysOfWeek')){
                    delete _validation.daysOfWeek
                }
                break;
            case '2':
                _initialValues.recurrence.daysOfWeek = [format(_initialValues.beginAt, 'i')]
                _validation.daysOfWeek = Yup.array().of(Yup.number()).min(1, "Veuillez séléctionner au moins un jour de la semaine")
                break;
        }
        _validationSchema.recurrence = Yup.object().shape({..._validation});
        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    return <div className={'p-1 p-md-3 template-add-booking-form'}>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    label={'Catégorie de métier'}
                    fetchEntity={'job'}
                    required={true}
                    value={state.rightSidebar.data.job ? {label: state.rightSidebar.data.job.title, value: state.rightSidebar.data.job.id} : undefined}
                    onChange={(choice) => formik.setFieldValue('job', choice?.value)}
                />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" id={'title'} className={'form-control'} name={'title'} onChange={formik.handleChange}/>
                    <label htmlFor="title">
                        Titre
                    </label>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="input-group">
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                        title={'Début'}
                        selected={formik.values.beginAt}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`beginAt`, date)
                        }}
                        renderDayContents={renderDayContents}
                        renderCustomHeader={() => <></>}
                        openToDate={new Date("2021/10/04")}
                        minDate={new Date("2021/10/04")}
                        maxDate={new Date("2021/10/10")}
                        selectsStart
                        endDate={formik.values.endAt}
                        showTimeInput={true}
                        timeInputLabel={'Heure'}
                        customInput={<DateCustomInput label={'Début'} />}
                        dateFormat="E HH:mm"
                    />
                    <span className="input-group-text">
                        <i className={'bi bi-arrow-left-right'}> </i>
                    </span>
                    <DatePicker
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'endAt')}
                        title={'Fin'}
                        customInput={<DateCustomInput label={'Fin'} />}
                        renderDayContents={renderDayContents}
                        openToDate={new Date("2021/10/04")}
                        minDate={new Date("2021/10/04")}
                        maxDate={new Date("2021/10/10")}
                        renderCustomHeader={() => <></>}
                        selected={formik.values.endAt}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`endAt`, date)
                        }}
                        selectsEnd
                        startDate={formik.values.beginAt}
                        endDate={formik.values.endAt}
                        minTime={formik.values.beginAt}
                        showTimeInput={true}
                        timeInputLabel={'Heure'}
                        timeCaption="Heure"
                        dateFormat="E HH:mm"
                    />
                </div>
            </div>
            <div className="col-12 mb-3">
                <h4>
                    <i className={'bi bi-arrow-repeat'}> </i> Récurrence
                </h4>
                <div className="form-check">
                    <input onChange={handleTypeChange} className="form-check-input" type="radio" name="recurrence.type" id="type1" value="1" defaultChecked={true} />
                        <label className="form-check-label" htmlFor="type1">
                            Une seule fois
                        </label>
                </div>
                <div className="form-check">
                    <input onChange={handleTypeChange} className="form-check-input" type="radio" name="recurrence.type" id="type2" value="2" />
                        <label className="form-check-label" htmlFor="type2">
                            Plusieurs fois
                        </label>
                </div>
            </div>
            {formik.values.recurrence.type === "2" && <div className="col-12 mb-3">
                <div className={'mb-2'}>
                    Jours de la semaine
                </div>
                {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, _index: number) => {
                    return (
                        <>
                            <input key={_index}
                                   type="checkbox"
                                   onChange={formik.handleChange}
                                   name={`recurrence.daysOfWeek`}
                                   defaultChecked={initialValues.recurrence.daysOfWeek?.includes(`${_index + 1}`)}
                                   className="btn-check"
                                   id={`btn-check-${_index}`}
                                   autoComplete="off"
                                   value={`${_index + 1}`}
                            />
                            <label key={_index + '-label'} className="btn btn-sm rounded-pill btn-outline-primary me-1"
                                   htmlFor={`btn-check-${_index}`}>
                                {formik.values.recurrence.daysOfWeek?.includes(`${_index + 1}`) && <i className={'bi bi-check'}> </i>} {day}
                            </label>
                        </>
                    )
                })}
                <FormError touched={formik.touched} errors={formik.errors} field={`recurrence.daysOfWeek`}/>
            </div>}
            <button className={"btn btn-outline-primary w-100"}>
                <i className={'bi bi-plus-circle'}> </i> Ajouter
            </button>
        </form>
    </div>
}

export default BookingDefaultAdd;