import React, {useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import * as api from "../../../../../adapters/timeClock";
import {showAlertSuccess} from "../../../../../actions/alert";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import validationClass from "../../../../../utils/validationClass";
import {Salary} from "../../../../../models/salary";
import FormError from "../../../../../utils/formError";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../../../utils/customInput";
import {Company} from "../../../../../models/companies";
import {useDispatch} from "react-redux";
import BookingTable from "../../../../booking/table";
import SmallLoader from "../../../../../utils/loader/small";
import * as accessRightsTypes from "../../../../../constants/accessRight";
import SalarySelector from "../../../../salary/selector";

interface Interface {
    start?: Date,
    end?: Date,
    initialSalary?: Salary,
    company?: Company,
    handleSubmitSuccess: (props: any[]) => any
}

const SignedAddForm:React.FC<Interface> = (props) => {

    const {initialSalary, company, start, end, handleSubmitSuccess} = props;

    const dispatch = useDispatch();
    const [salary, setSalary] = useState<Salary|undefined>(initialSalary)

    const initialValues = {
        beginAt: start,
        endAt: end,
        salary: salary?.id,
        company: company?.id,
        description: ""
    };

    const validationSchema = Yup.object().shape({
        beginAt: Yup.date().required('Début requis !'),
        endAt: Yup
            .date()
            .nullable()
            .test("is-greater", "La date de fin doit être après le début", function(value: any) {
                const { beginAt } = this.parent;
                return (!value || (value && beginAt <= value))
            }),
        salary: Yup.number().required('Veuillez choisir un collaborateur'),
        company: Yup.number().required('Veuillez choisir un point de vente'),
        description: Yup.string().nullable()
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            api.create(values).then(resp => {
                Promise.all([
                    handleSubmitSuccess(resp.data)
                ]).then(() => {
                    dispatch(showAlertSuccess('Créneau ajouté'));
                    formikHelpers.setSubmitting(false)
                })
            });
        }
    })

    return <>
        <form className={'p-1 p-md-3'} onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="col-md-12 mb-3">
                    <div className="input-group">
                        <DatePicker
                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                            customInput={<DateCustomInput label={'Début'} icon={'text-primary bi bi-calendar-check'} />}
                            name={'beginAt'}
                            id={'beginAt'}
                            selected={formik.values.beginAt}
                            onChange={(date: Date) => {
                                formik.setFieldValue(`beginAt`, date)
                            }}
                            maxTime={formik.values.endAt}
                            selectsStart
                            showTimeInput={true}
                            timeIntervals={15}
                            timeCaption="Heure de début"
                            dateFormat="E dd MMM uuuu HH:mm"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                        />
                        <span className="input-group-text">
                                            <i className={'bi bi-arrow-left-right'}> </i>
                                        </span>
                        <DatePicker
                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'endAt')}
                            customInput={<DateCustomInput label={'Fin'} icon={'text-primary bi bi-calendar-check'} />}
                            name={'endAt'}
                            id={'endAt'}
                            selected={formik.values.endAt}
                            onChange={(date: Date) => {
                                formik.setFieldValue(`endAt`, date)
                            }}
                            selectsEnd
                            startDate={formik.values.beginAt}
                            endDate={formik.values.endAt}
                            minDate={formik.values.beginAt}
                            minTime={formik.values.beginAt}
                            showTimeInput={true}
                            timeIntervals={15}
                            timeCaption="Heure de fin"
                            dateFormat="E dd MMM uuuu HH:mm"
                            popperPlacement={"top-end"}
                            required={false}
                            isClearable={true}
                            showPopperArrow={false}
                        />
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'beginAt'} />
                    <FormError errors={formik.errors} touched={formik.touched} field={'endAt'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <SalarySelector
                    required={true}
                    fetchOptions={true}
                    onChange={(s: Salary) => {
                        formik.setFieldValue('salary', s.id)
                        setSalary(s)
                    }}
                    accessRight={accessRightsTypes.EDIT_TIME_CLOCK}
                />
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    label={'Établissement'}
                    enableReinitialize={true}
                    required={true}
                    labelIcon={'text-primary bi bi-shop'}
                    //options={salary ? [...salary.companies.map((c) => ({label: c.title, value: c.id!}))] : []}
                    fetchEntity="company"
                    fetchParams={{ salaries: [formik.values.salary] }}
                    className={validationClass(formik.errors, formik.touched, 'company')}
                    value={company ? {label: company.title, value: company.id!} : undefined}
                    onChange={(choice) => formik.setFieldValue('company', choice?.value)}
                />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <textarea className={'form-control'} name={'description'} onChange={formik.handleChange}> </textarea>
                    <label htmlFor="floatingTextarea">Description</label>
                </div>
            </div>
            <button disabled={formik.isSubmitting} className={'btn btn-light w-100'}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check'}> </i> Valider</>}
            </button>
        </form>
        <BookingTable start={formik.values.beginAt || formik.values.endAt} end={formik.values.endAt || formik.values.beginAt} salary={formik.values.salary} />
    </>
}

export default SignedAddForm;