import {ModuleSettings} from "../../models/companyGroup";

const HasModule = (module: "timeClock"|"task"|"accounting"|"payroll"|"slotExchange"|"team"|"label"|"bookingOffer"|"document"|"expense"|"smsNotifications"|string) => {
    let user = localStorage.getItem('user')

    if (user){
        let data = JSON.parse(user)
        
        if (!data.currentSalary){
            return false;
        }

        const modules: ModuleSettings = data.currentSalary.companyGroup.modules;
        
        switch (module){
            case "timeClock":
                return modules.timeClock;
            case "task":
                return modules.task;
            case "accounting":
                return modules.accounting;
            case "payroll":
                return modules.payroll;
            case "slotExchange":
                return modules.slotExchange;
            case "team":
                return modules.team;
            case "label":
                return modules.label;
            case "bookingOffer":
                return modules.bookingOffer;
            case "document":
                return modules.document;
            case "expense":
                return modules.expense;
            case "smsNotifications":
                return modules.smsNotifications;
            default:
                return false;
        }
    }

    return false;
}

export default HasModule;