import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { Template } from "../../../models/template";

import * as types from "../../../constants/template";
import { FORM_NEW_TEMPLATE } from "../../../constants/rightSidebar";

import * as api from "../../../adapters/template";
import * as actions from "../../../actions/template";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { openSidebar } from "../../../actions/rightSidebar";
import { axiosError } from "../../../actions/axios";

import LoaderList from "../../../utils/loaderList";
import onMouseEnterTooltip from "../../../utils/mouseOverTooltip";

const TemplateList: React.FC = () => {
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny)  => state.template);

    const [expanded, setExpanded] = useState(!params.id);
    const [activated, setActivated] = useState(true);

    useEffect(() => {
            dispatch(actions.fetchTemplates());
            api.list({activated: activated}).then(data => {
                dispatch(actions.fetchTemplatesSuccess(data.data))
            }).catch(error => {
                dispatch(axiosError(error))
            })
    }, [activated])

    useEffect(() => {
        dispatch(setPageTitle("Templates"))
        dispatch(setHelpProductSheetIds([12]))

        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, []);

    if (!expanded) {
        return (
            <div id={"listContainer"} className={"col-auto px-0 h-100 bg-white border-end overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block " : " ")}>
                <div className="card flex-grow-0 border border-1">
                    <div className="card-body  d-flex flex-column">
                        <div className="flex-grow-0 mb-3">
                            <button onClick={() => setExpanded(prev => !prev)} className="btn btn-light">
                                {expanded ? <i className={'bi bi-arrows-angle-contract'}></i> : <i className={'bi bi-arrows-angle-expand'}></i>}
                            </button>
                        </div>

                        <div className="col mb-3">
                            <button className={'btn btn-light w-100'} onClick={() => dispatch(openSidebar(FORM_NEW_TEMPLATE))}>
                                <i className={'bi bi-plus-circle text-primary'}></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id={"listContainer"} className={"col-12 px-0 h-100 bg-white border-end overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block " : " ") + (expanded ? " col-md-4 col-lg-3 " : " col-md-1 col-lg-1 ")}>
            <div className="card flex-grow-0 border border-1">
                <div className="card-body  d-flex flex-column">
                    <div className="d-flex flex-row w-100">
                        <div className="mb-3 me-2 flex-grow-1">
                            <div className="input-group w-100">
                                <span className="input-group-text"><i className={'bi bi-search'}></i></span>
                                <input type="text" className="form-control" placeholder={"Rechercher..."}/>
                            </div>
                        </div>
                        <div className="flex-grow-0">
                            <button onClick={() => setExpanded(prev => !prev)} className="btn btn-light">
                                {expanded ? <i className={'bi bi-arrows-angle-contract'}></i> : <i className={'bi bi-arrows-angle-expand'}></i>}
                            </button>
                        </div>
                    </div>

                    <div className="col mb-3 d-flex">
                        <button className={'btn btn-light flex-grow-1 me-2'} onClick={() => dispatch(openSidebar(FORM_NEW_TEMPLATE))}>
                            <i className={'bi bi-plus-circle text-primary'}></i> Ajouter
                        </button>
                        <button className={'btn btn-light flex-grow-0'}
                                onClick={() => setActivated(prev => !prev)}
                                title={activated ? "filtrer les templates archivés" : "Filtrer les templates actifs"}
                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                        >
                            {activated ? <i className={'bi bi-check-circle text-success'}></i> : <i className={'bi bi-dash-circle text-danger'}></i>}
                        </button>
                    </div>
                </div>
            </div>

            {state.loading &&
                <LoaderList title={true} listLength={50} description={true} leftCircle={false} />
            }

            {!state.loading &&
                <ul className={'list-group list-group-flush'}>
                    {state.payload.map((t: Template) => <Link onClick={() => setExpanded(false)} to={`/template/${t.id}`} className={'list-group-item' + (params.id === String(t.id) ? ' active ' : '')}>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 flex-column">
                                <h4>
                                    {t.title}
                                </h4>
                                <p className={'mb-0'}>
                                    <i className={'bi bi-shop'}></i> {t.company.title}
                                </p>
                            </div>
                            <div className="flex-grow-0">
                                {t.visibility === types.VISIBILITY_PRIVATE ? <i className={'bi bi-lock'}></i> : <i className={'bi bi-unlock'}></i>}
                            </div>
                        </div>
                    </Link>)}
                </ul>
            }
        </div>
    )
}

export default TemplateList;