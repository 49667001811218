import {get, post} from "../xhr";

export function rate(signal: AbortSignal){
    return get(`chart/rate`, {signal: signal})
}

export function sum(requestData?: {date?: string}){
    return post(`counter/sum`, requestData || {})
}

export function sales(requestData: {year: "current"|"last"},companyId: number){
    return post(`chart/sales/${companyId}`, requestData)
}

export function counters(){
    return get(`chart/counter`)
}

export function edit(requestData: {signe: string, valueHour: number, valueMin: number, paidOverTimeHour: number, paidOverTimeMin: number}, id: number){
    return post(`counter/edit/${id}`, requestData)
}