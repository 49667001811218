import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {
    ACCOUNTING_TYPE_WORK_ACCOUNTED,
    AccountingTypes,
    getAccountingTypeChoice
} from "../../../../../../constants/booking";
import * as Yup from 'yup'
import * as api from '../../../../../../adapters/salary/template/event'
import DatePicker from "react-datepicker";
import validationClass from "../../../../../../utils/validationClass";
import DateCustomInput from "../../../../../../utils/customInput";
import FormError from "../../../../../../utils/formError";
import BootstrapSelect from "../../../../../../utils/bootstrapSelect";
import format from "../../../../../../utils/locale"
import {Localisation} from "../../../../../../models/localisation";
import {Activity} from "../../../../../../models/activity";
import {Events, Evt, SalaryTemplate} from "../../../../../../models/salary/template";
import {Company} from "../../../../../../models/companies";
import {Modal} from "bootstrap";
import {DateSelectArg} from "@fullcalendar/react";
import {refresh, refreshResource} from "../../../../../../actions/calendar";

interface SalaryTemplateEventAddInterface {
    week: number,
    company: Company,
    template: SalaryTemplate;
    events: Events;
    setEvents: (event: Events) => void;
    arg?: DateSelectArg
    modal?: Modal
}

const SalaryTemplateEventAdd:React.FC<SalaryTemplateEventAddInterface> = (props) => {

    const state = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();

    const {company, template, setEvents, events, week, arg, modal} = props;
    const [initialValues, setInitialValues] = useState({
        start:arg?.start || new Date(),
        end:arg?.end || new Date(),
        week:week,
        company: company.id!,
        activity: "",
        localisation: "",
        accountingType: ACCOUNTING_TYPE_WORK_ACCOUNTED,
        daysOfWeek: [String(Number(format(arg?.start || new Date(), 'i')) - 1)],
        template: template.id
    })

    const validationSchema = Yup.object().shape({
        start: Yup.date().required(),
        end: Yup.date().required(),
        week: Yup.number().required(),
        accountingType: Yup.number().required(),
        activity: Yup.number().nullable(),
        company: Yup.number().required(),
        localisation: Yup.number().nullable(),
        daysOfWeek: Yup.array().of(Yup.number()).min(1)
    })

    useEffect(() => {
        if (arg){
            setInitialValues({
                start: arg.start,
                end: arg.end,
                week:week,
                company: company.id!,
                activity: "",
                localisation: "",
                accountingType: ACCOUNTING_TYPE_WORK_ACCOUNTED,
                daysOfWeek: [String(Number(format(arg.start, 'i')) - 1)],
                template: template.id
            })
        }
    }, [arg])

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            api.create(values).then(resp => {
                setEvents([...events, resp.data])
                if (state.rightSidebar.data.resourceId){
                    dispatch(refreshResource(state.rightSidebar.data.resourceId, state.rightSidebar.data.parentId, state.rightSidebar.data.salary))
                }else{
                    dispatch(refresh());
                }
                modal?.toggle();
                formikHelpers.setSubmitting(false);
            })
        }
    })

    const overlap = () => events.filter(e => e.week ===week && !!e.daysOfWeek.filter(d => formik.values.daysOfWeek.includes(String(d))).length && format(new Date(e.start), 'HH:mm') < format(formik.values.end, 'HH:mm') && format(new Date(e.end), 'HH:mm') > format(formik.values.start, 'HH:mm'))

    return <div className="modal fade" id="salaryTemplateEventAddModal" tabIndex={-1} aria-labelledby="salaryTemplateEventAddModalLabel"
                aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Ajouter un créneau</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body position-relative">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="col-md-12">
                            <div className="row template-add-booking-form">
                                <div className="col-12 col-md mb-3">
                                    <DatePicker
                                        disabled={formik.isSubmitting}
                                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                                        customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                                        selected={formik.values.start}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(`start`, date)
                                            formik.setFieldTouched('start', true)
                                        }}
                                        selectsStart
                                        startDate={formik.values.start}
                                        maxDate={formik.values.end}
                                        showTimeInput={true}
                                        renderDayContents={(dayOfMonth, date) => <span>{format(date!, 'E')}</span>}
                                        renderCustomHeader={() => <></>}
                                        openToDate={new Date("2019/07/01")}
                                        minDate={new Date("2019/07/01")}
                                        dateFormat="E HH:mm"

                                    />
                                </div>
                                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                                </div>
                                <div className="col-12 col-md mb-3">
                                    <DatePicker
                                        disabled={formik.isSubmitting}
                                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                                        customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                                        showTimeInput={true}
                                        selected={formik.values.end}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(`end`, date)
                                            formik.setFieldTouched('end', true)
                                        }}
                                        selectsEnd
                                        startDate={formik.values.start}
                                        endDate={formik.values.end}
                                        minDate={formik.values.start}
                                        renderDayContents={(dayOfMonth, date) => <span>{format(date!, 'E')}</span>}
                                        renderCustomHeader={() => <></>}
                                        openToDate={new Date("2019/07/01")}
                                        maxDate={new Date("2019/07/07")}
                                        dateFormat="E HH:mm"
                                    />
                                </div>
                            </div>
                            <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
                            <FormError touched={formik.touched} errors={formik.errors} field={'end'} />
                        </div>
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                disabled={formik.isSubmitting}
                                label={"Type d'heure"}
                                value={getAccountingTypeChoice(formik.values.accountingType)}
                                options={AccountingTypes}
                                onChange={(choice) => formik.setFieldValue('accountingType', choice!.value)}
                                required={true}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <BootstrapSelect
                                    disabled={formik.isSubmitting}
                                    label={'Emplacement'}
                                    //options={company.localisations!.map((l: Localisation) => ({label: l.title, value: l.id!}))}
                                    fetchEntity="localisation"
                                    onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <BootstrapSelect
                                    disabled={formik.isSubmitting}
                                    label={'Activité'}
                                    //options={company.activities!.map((l: Activity) => ({label: l.title, value: l.id!}))}
                                    fetchEntity="activity"
                                    onChange={(c) => formik.setFieldValue('activity', c?.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 text-center mb-3">
                            <div className="col-12 mb-2">
                                <label>
                                    Jours de la semaine
                                </label>
                            </div>
                            {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                                return (
                                    <>
                                        <input key={index}
                                               disabled={formik.isSubmitting}
                                               type="checkbox"
                                               onChange={formik.handleChange}
                                               name={'daysOfWeek'}
                                               checked={formik.values.daysOfWeek.includes(`${index}`)}
                                               className="btn-check"
                                               id={`btn-check-${index}`}
                                               autoComplete="off"
                                               value={`${index}`} />
                                        <label key={index} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-check-${index}`}>
                                            {day}
                                        </label>
                                    </>
                                )
                            })}
                            <FormError touched={formik.touched} errors={formik.errors} field={'daysOfWeek'} />
                        </div>
                        {overlap().map(e => <>
                            <div className="form-text text-danger mb-2">
                                <i className={'bi bi-exclamation-circle'}></i> Créneau déjà existant {e.description}
                            </div>
                            <div className="clearfix"> </div>
                        </>)}
                        <button disabled={!!overlap().length} type={'submit'} className={'btn btn-light w-100'}>
                            {formik.isSubmitting ? <>
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div></> : <><i className={'bi bi-check'}></i> Valider</>}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
}

export default SalaryTemplateEventAdd;