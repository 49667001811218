import React, {useEffect, useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import format from "../../../../utils/locale";

interface Feed {type: "birthdate"|"trial"|"seniority", initials: string, dueDate: string, title: string, value: string}

const DashboardFeedSidebar: React.FC = () => {

    const feeds: Feed[] = useSelector((state: RootStateOrAny) => state.rightSidebar.data.payload);
    const [grouped, setGrouped] = useState<any[]>([]);

    useEffect(() => {
        setGrouped(groupByDueDate(feeds));
    }, [])

    const getIcon = (type: string) => {
        switch (type){
            case 'trial':
                return <span className={'me-2 text-success shadow-sm'}><i className={'bi bi-check-lg'}> </i></span>;
            case 'birthday':
                return <span className={'me-2 text-success shadow-sm'}><i className={'bi bi-gift'}> </i></span>;
            case 'seniority':
                return <span className={'me-2 text-primary shadow-sm'}><i className={'bi bi-calendar-plus'}> </i></span>;
            case 'start':
                return <span className={'me-2 text-success shadow-sm'}><i className={'bi bi-person-check'}> </i></span>;
            case 'end':
                return <span className={'me-2 text-danger shadow-sm'}><i className={'bi bi-person-dash'}> </i></span>;
        }
    }

    const groupByDueDate = function(xs: Feed[])
    :any[]
    // : {[key: string]: string, values: {type: "birthdate"|"trial"|"seniority", initials: string, dueDate: string, title: string, value: string}[]}[]
    {
        return Object.entries(xs.reduce(function(rv: any, x) {
            (rv[x["dueDate"]] = rv[x["dueDate"]] || []).push(x);
            return rv;
        }, {}));
    };

    return <>
        <div className={'col-12 d-flex flex-column'}>
            {grouped.map(([key, values], index) => <>
                    <div key={index} className="d-flex">
                        <div className="col-auto d-flex flex-column px-3 position-relative">
                        <div className={'bg-primary position-absolute'}
                            style={{
                                width: 2,
                                top: index === 0 ? '50%' : 0,
                                left: "calc(50% - 1px)",
                                height: (index === 0 ? '50%' : (index + 1 === grouped.length ? '50%' : '100%')),
                            }}
                        >
                        </div>
                        <span className={'bg-primary p-2 rounded-pill text-white top-50 start-50 translate-middle position-relative'}>
                            {format(new Date(key), 'dd/MM')}
                        </span>
                        </div>
                        <div className="col">
                            <div className="m-3 p-3 border">
                                <ul className={'list-group list-group-flush'}>
                                    {values.map((value: Feed) => <li className={'list-group-item'}>
                                        {getIcon(value.type)} <span className={'text-primary'}>{value.title}</span> {value.value}
                                    </li>)}
                                </ul>
                            </div>

                        </div>
                    </div>
            </>)}
        </div>
    </>
}

export default DashboardFeedSidebar;