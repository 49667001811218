import React, {useEffect} from "react";
import KeepNoteEditForm from "../../../keepNote/edit/form";

const KeepNoteEdit:React.FC<{
    close: () => any,
    handleSubmitSuccess: ((ev: any) => void),
    dueDate: Date,
    description: string,
    keepNoteId: number,
    isPrivate: boolean
}> = (props) => {

    const {handleSubmitSuccess, close, dueDate, description, isPrivate, keepNoteId} = props;

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarKeepNoteEditLabel">Modifier une note</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <KeepNoteEditForm
                handleSubmitSuccess={handleSubmit}
                dueDate={dueDate}
                description={description}
                keepNoteId={keepNoteId}
                isPrivate={isPrivate}
            />
        </div>
    </>
}

export default KeepNoteEdit;