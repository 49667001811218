import {get} from "../../xhr";

export function setActivity(salary: number, company: number, activity: number){
    return get(`salary/${salary}/default_label/activity/${company}/${activity}`);
}
export function setLocalisation(salary: number, company: number, localisation: number){
    return get(`salary/${salary}/default_label/localisation/${company}/${localisation}`);
}

export function removeActivity(salary: number,  id: number){
    return get(`salary/${salary}/default_label/remove/activity/${id}`);
}
export function removeLocalisation(salary: number, id: number){
    return get(`salary/${salary}/default_label/remove/localisation/${id}`);
}