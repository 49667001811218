import * as React from "react";

import SalaryList from "../../components/salary/list";
import SalaryShow from "../../components/salary/show";

const SalaryPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <SalaryList />
            <SalaryShow />
        </div>
    );
}

export default SalaryPage;