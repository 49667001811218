import * as xhr from "../xhr";

export function list(requestData: any, signal: AbortSignal){
    return xhr.post('document/list', requestData, {signal: signal});
}

export function show(id: number, signal: AbortSignal){
    return xhr.get(`document/show/${id}`, {signal: signal});
}

export function edit(requestData: any, id: number){
    return xhr.post('document/edit/' + id.toString(), requestData);
}

export function create(requestData: any, options?:xhr.Options){
    return xhr.post('document/create',  requestData, options);
}

export function download(salary: number, document: number){
    return xhr.get(`document/download/${document}/${salary}`, {responseType: "arraybuffer"});
}