import * as types from "../../constants/report";
import * as models from "../../models/report";

export const addReport = () => {
    return {
        type: types.ADD_REPORT,
    }
}

export const addReportSuccess = (report: models.Report) => {
    return {
        type: types.ADD_REPORT_SUCCESS,
        report: report,
    }
}

export const addReportFailure = (error: string) => {
    return {
        type: types.ADD_REPORT_FAILURE,
        report: null,
        reports: null,
        error: error
    }
}

export const editReport = () => {
    return {
        type: types.EDIT_REPORT,
    }
}

export const editReportSuccess = (report: models.Report) => {
    return {
        type: types.EDIT_REPORT_SUCCESS,
        report: report
    }
}

export const editReportFailure = (error: string) => {
    return {
        type: types.EDIT_REPORT_FAILURE,
        report: null,
        reports: null,
        error: error
    }
}

export const fetchReports = () => {
    return {
        type: types.FETCH_REPORTS,
    }
}

export const fetchReportsFailure = (error: string) => {
    return {
        type: types.FETCH_REPORTS_FAILURE,
        report: null,
        reports: [],
        error: error
    }
}

export const fetchReportsSuccess = (reports: models.Reports) => {
    return {
        type: types.FETCH_REPORTS_SUCCESS,
        report: null,
        reports: reports
    }
}


export const showReport = () => {
    return {
        type: types.SHOW_REPORT,
    }
}
export const showReportSuccess = (report: models.Report) => {
    return {
        type: types.SHOW_REPORT_SUCCESS,
        report: report
    }
}
export const showReportFailure = (error: string) => {
    return {
        type: types.SHOW_REPORT_FAILURE,
        report: null,
        reports: null,
        error: error
    }
}