import React, {useEffect, useState} from "react";
import {EventDropArg} from "@fullcalendar/react";
import EventAddForm from "../../../../event/add/form";

interface Interface {
    arg: EventDropArg,
    handleSubmitSuccess: (props: any[]) => any,
    close: () => any
}

const EventDrop:React.FC<Interface> = (props) => {

    const {arg, handleSubmitSuccess, close} = props;
    const [key, setKey] = useState(1);

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarEvent')! as HTMLDivElement;
        el.addEventListener('hide.bs.offcanvas',  cancelDrop)
        setKey(prevState => prevState + 1)

        return () => el?.removeEventListener('hide.bs.offcanvas',  cancelDrop)
    }, [arg])

    const cancelDrop = () => {
        if (arg.oldEvent){
            arg?.event.setDates(arg?.oldEvent.start!, arg.oldEvent.end);
            if (arg.oldResource){
                arg.event.setResources([arg.oldResource])
            }
        }
    }

    const handleSubmit = (ev: any) => {
        cancelDrop();

        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventDropLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <EventAddForm
                key={key}
                start={arg.event.start!}
                end={arg.event.end!}
                initialSalary={arg.newResource ? arg.newResource.extendedProps.salary : arg.event.extendedProps.salary}
                initialCompany={arg.event.extendedProps.company}
                localisation={arg.event.extendedProps.localisation}
                activity={arg.event.extendedProps.activity}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default EventDrop;