import * as types from "../../constants/localisation";
import * as models from "../../models/localisation";

export const addLocalisation = () => {
    return {
        type: types.ADD_LOCALISATION,
    }
}

export const addLocalisationSuccess = (localisation: models.Localisation) => {
    return {
        type: types.ADD_LOCALISATION_SUCCESS,
        localisation: localisation,
    }
}

export const addLocalisationFailure = (error: string) => {
    return {
        type: types.ADD_LOCALISATION_FAILURE,
        localisation: null,
        localisations: null,
        error: error
    }
}

export const editLocalisation = () => {
    return {
        type: types.EDIT_LOCALISATION,
    }
}
export const editLocalisationSuccess = (localisation: models.Localisation) => {
    return {
        type: types.EDIT_LOCALISATION_SUCCESS,
        localisation: localisation
    }
}
export const editLocalisationFailure = (error: string) => {
    return {
        type: types.EDIT_LOCALISATION,
        localisation: null,
        localisations: null,
        error: error
    }
}

export const fetchLocalisations = () => {
    return {
        type: types.FETCH_LOCALISATIONS,
    }
}

export const fetchLocalisationsFailure = (error: string) => {
    return {
        type: types.FETCH_LOCALISATIONS_FAILURE,
        localisation: null,
        localisations: [],
        error: error
    }
}

export const fetchLocalisationsSuccess = (localisations: models.Localisations) => {
    return {
        type: types.FETCH_LOCALISATIONS_SUCCESS,
        localisation: null,
        localisations: localisations
    }
}


export const showLocalisation = () => {
    return {
        type: types.SHOW_LOCALISATION,
    }
}
export const showLocalisationSuccess = (localisation: models.Localisation) => {
    return {
        type: types.SHOW_LOCALISATION_SUCCESS,
        localisation: localisation
    }
}
export const showLocalisationFailure = (error: string) => {
    return {
        type: types.SHOW_LOCALISATION_FAILURE,
        localisation: null,
        localisations: null,
        error: error
    }
}