import React, {useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import format from "../../../../utils/locale";
import {getAccountingTypeChoice} from "../../../../constants/booking";
import * as api from "../../../../adapters/template/event";
import * as calendarTypes from "../../../../constants/calendar";
import SmallLoader from "../../../../utils/loader/small";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../actions/alert";

interface Interface {
    arg: EventClickArg,
    setTemplateEventSidebarProps: React.Dispatch<any>;
    handleSubmitSuccess: (ev: any) => any,
    close: () => void,
}

const EventShow:React.FC<Interface> = (props) => {

    const {arg, setTemplateEventSidebarProps, handleSubmitSuccess, close} = props;

    const dispatch = useDispatch();
    const handleSubmit = (ev: any) => {

        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    const [removing, setRemoving] = useState(false)
    function remove()
    {
        setRemoving(true)
        api.cancelOccurrence(arg.event.extendedProps.eventId, arg.event.extendedProps.exDay)
            .then(resp => {
                dispatch(showAlertSuccess('Créneau suprimmé'))
                handleSubmit(resp.data)
            })
            .then(() => setRemoving(false))
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventEditLabel">Créneau de {arg.event.extendedProps.resource.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <table className={"table"}>
                <tbody>
                <tr>
                    <th className={'text-primary'}>
                        <i className={'bi bi-clock'}></i> Horaire
                    </th>
                    <th>
                        {format(arg.event.start!, 'E HH:mm')} <i className={'bi bi-arrow-left-right'}></i> {format(arg.event.end!, 'HH:mm')}
                    </th>
                </tr>
                <tr>
                    <th className={'text-primary'}>
                        <i className={'bi bi-clock-history'}></i> Type d'heure
                    </th>
                    <th>
                        {getAccountingTypeChoice(arg.event.extendedProps.accountingType).label}
                    </th>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-bookmark'}></i> Activité
                    </td>
                    <td>
                        {arg.event.extendedProps.activity?.title || <i className={'bi bi-x'}></i>}
                    </td>
                </tr>
                <tr>
                    <td className={'text-primary'}>
                        <i className={'bi bi-geo-alt'}></i> Emplacement
                    </td>
                    <td>
                        {arg.event.extendedProps.localisation?.title || <i className={'bi bi-x'}></i>}
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="row">
                <div className="col-6">
                    <button className="w-100 btn btn-light" onClick={() => {
                        switch (arg.event.extendedProps.eventType)
                        {
                            case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT:
                                setTemplateEventSidebarProps((prev: any) => ({
                                    sidebarType: "EVENT_EDIT",
                                    trigger: prev.trigger + 1,
                                    eventType: "templateEvent",
                                    exDay: Number(format(arg.event.start!, 'i')),
                                    eventId: arg.event.extendedProps.eventId,
                                    resource: {title: arg.event.extendedProps.resource.title, id: arg.event.extendedProps.resource.id},
                                    start: arg.event.start!,
                                    end: arg.event.end!,
                                    activity: arg.event.extendedProps.activity,
                                    localisation: arg.event.extendedProps.localisation,
                                    accountingType: arg.event.extendedProps.accountingType
                                }))
                                break;
                            case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION:
                                setTemplateEventSidebarProps((prev: any) => ({
                                    sidebarType: "EVENT_EDIT",
                                    trigger: prev.trigger + 1,
                                    eventType: calendarTypes.EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION,
                                    exceptionId: arg.event.extendedProps.exceptionId,
                                    resource: {title: arg.event.extendedProps.resource.title, id: arg.event.extendedProps.resource.id},
                                    start: arg.event.start!,
                                    end: arg.event.end!,
                                    activity: arg.event.extendedProps.activity,
                                    localisation: arg.event.extendedProps.localisation,
                                    accountingType: arg.event.extendedProps.accountingType
                                }))
                                break;
                        }
                    }}>
                        <i className={'bi bi-pencil text-primary'}></i> Modifier
                    </button>
                </div>
                <div className="col-6">
                    <button className="w-100 btn btn-light" onClick={() => remove()}>
                        {removing ? <SmallLoader /> : <><i className={'bi bi-dash-circle text-danger'}></i> Supprimer</>}
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default EventShow;