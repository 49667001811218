import * as React from "react";

import SponsorshipShow from "../../../components/sponsorship/show";

const SponsorshipShowPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <SponsorshipShow />
        </div>
);
}

export default SponsorshipShowPage;