import React, {useEffect, useState} from "react";
import format from "../../../utils/locale";
import * as calendarTypes from "../../../constants/calendar";
import {PresenceEvent} from "../../../models/calendar";
import onMouseEnterTooltip from "../../../utils/mouseOverTooltip";

interface Interface {
    presences: any[]
}

const CalendarPresence:React.FC<Interface> = (props) => {

    const {presences} = props;

    const [items, setItems] = useState<any[]>([]);
    const [currentItem, setCurrentItem] = useState<any>();

    const [displayMissing, setDisplayMissing] = useState(true);
    const [displayOver, setDisplayOver] = useState(false);
    const [displayOk, setDisplayOk] = useState(false);

    useEffect(() => {
        let _items = presences.map(p => ({title: p.resourceTitle, id: p.resourceId}))
            .filter((value, index, self) => self.findIndex(s => s.id === value.id) === index)
        ;

        setItems([..._items])
    }, [presences])

    useEffect(() => {
        if (!!items.length){
            setCurrentItem(items[0]);
        }
    }, [items])

    const groupByDay = (presenceItems: any[]) => {

        let ret: any = {};


        for (let i in presenceItems){

            if (ret[format(new Date(presenceItems[i].start))] === undefined) ret[format(new Date(presenceItems[i].start))] = [];

            ret[format(new Date(presenceItems[i].start))].push(presenceItems[i])
        }

        return ret;
    }

    const Icon = (value: number, need: number) => {

        if (value < need){
            return <i className={'text-danger bi bi-exclamation-circle'}> </i>
        }

        if (value === need){
            return <i className={'text-success bi bi-check2-circle'}> </i>
        }

        if (value > need){
            return <i className={'text-primary bi bi-plus-circle'}> </i>
        }

        return <></>
    }

    const filterTypes = (p: any) => {

        if (displayMissing && isMissing(p)) return true;
        if (displayOk && isOk(p)) return true;
        return displayOver && p.values.length > p.need;
    }

    const filterATypes = (need: number, val: number) => {

        if (displayMissing && need > val) return true;
        if (displayOk && need === val) return true;
        return displayOver && val > need;
    }

    function isMissing(i: any){

        switch (i.presenceType){
            case calendarTypes.EVENT_TYPE_PRESENCE_GLOBAL:
                let val = Number(i.values.reduce((accumulator: number, object: PresenceEvent) => {
                    return accumulator + object.value;
                }, 0));

                let need = Number(i.need || 0);

                return val < need
            case calendarTypes.EVENT_TYPE_PRESENCE_BY_JOB:
                let v: any[] = i.values
                let n: any[] = i.need

                let array = v.filter(_v => _v.id).map(_v => {
                    let val = Number(_v.values.reduce((accumulator: number, object: PresenceEvent) => {
                        return accumulator + object.value;
                    }, 0))

                    let need = (n ? (n[_v.id] || 0) : 0)

                    return {val: val, need: need, title: _v.title};
                })

                return !!array.filter(a => a.val < a.need).length
        }

        return false;
    }

    function isOk(i: any){

        switch (i.presenceType){
            case calendarTypes.EVENT_TYPE_PRESENCE_GLOBAL:
                let val = Number(i.values.reduce((accumulator: number, object: PresenceEvent) => {
                    return accumulator + object.value;
                }, 0));

                let need = Number(i.need || 0);

                return val === need
            case calendarTypes.EVENT_TYPE_PRESENCE_BY_JOB:
                let v: any[] = i.values
                let n: any[] = i.need

                let array = v.filter(_v => _v.id).map(_v => {
                    let val = Number(_v.values.reduce((accumulator: number, object: PresenceEvent) => {
                        return accumulator + object.value;
                    }, 0))

                    let need = (n ? (n[_v.id] || 0) : 0)

                    return {val: val, need: need, title: _v.title};
                })

                return array.filter(a => a.val === a.need).length === array.length
        }

        return false;
    }

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasCalendarPresences" aria-labelledby="offcanvasCalendarPresencesLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarPresencesLabel">Manque présences</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body pt-0">
            <div className="d-flex align-items-start w-100">
                <div className="nav flex-column nav-pills me-3 flex-grow-0 position-sticky top-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <div className="my-3 col-12 d-flex flex-column">
                        <div className="form-check form-switch me-2">
                            <input defaultChecked={true} onChange={(e) => setDisplayMissing(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault1" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">
                                Sous présence
                            </label>
                        </div>
                        <div className="form-check form-switch me-2">
                            <input defaultChecked={false} onChange={(e) => setDisplayOver(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">
                                Surprésence
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input defaultChecked={false} onChange={(e) => setDisplayOk(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault3" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault3">
                                Présence Ok
                            </label>
                        </div>
                    </div>
                    {items.map((i, index) => <button className={"btn mb-2 " + (i.id === currentItem?.id ? ' btn-primary text-white ' : ' btn-light')} id={`v-pills-${index}-tab`} data-bs-toggle="pill"
                                                     data-bs-target={`#v-pills-${index}`} type="button" role="tab" aria-controls={`v-pills-${index}`}
                                                     aria-selected="true"
                                                     onClick={() => setCurrentItem(i)}
                    >
                        {i.title} {!!presences.filter(p => isMissing(p) && p.resourceId === i.id).length && <span className={'badge bg-danger'}> {presences.filter(p => isMissing(p) && p.resourceId === i.id).length} </span>}
                    </button>)}
                </div>
            <div className="tab-content flex-grow-1" id="v-pills-tabContent">
                {items.map((i, index) => <div className={"tab-pane fade " + (i.id === currentItem?.id ? " show active" : "")} id={`v-pills-${index}`} role="tabpanel"
                                              aria-labelledby={`v-pills-${index}-tab`} tabIndex={0}>
                    {<>
                        {Object.entries((groupByDay(presences.filter(p => p.resourceId === currentItem?.id && !!p.need)))).map((item: [string, any]) => <>
                            <div className="col-12">
                                <div className="d-flex flex-column align-items-center bg-white">
                                    <div className="flex-grow-0 w-100 bg-primary text-center p-3 position-sticky top-0">
                                        <h4 className={'text-white'}>
                                            {item[0]}
                                        </h4>
                                    </div>
                                    <div className="flex-grow-1">
                                        <table className={'table'}>
                                            <tbody>
                                            {item[1].filter((i: any) => filterTypes(i)).map((i: any) => {
                                                switch (i.presenceType){
                                                    case calendarTypes.EVENT_TYPE_PRESENCE_GLOBAL:
                                                        let val = Number(i.values.reduce((accumulator: number, object: PresenceEvent) => {
                                                            return accumulator + object.value;
                                                        }, 0));

                                                        let need = Number(i.need || 0);

                                                        return <tr>
                                                            <td>
                                                                {format(new Date(i.start), 'HH:mm')}
                                                            </td>
                                                            <td>
                                                                {Icon(val, need)} {val} / {need}
                                                            </td>
                                                        </tr>
                                                    case calendarTypes.EVENT_TYPE_PRESENCE_BY_JOB:
                                                        let v: any[] = i.values
                                                        let n: any[] = i.need

                                                        let array = v.filter(_v => _v.id).map(_v => {
                                                            let val = Number(_v.values.reduce((accumulator: number, object: PresenceEvent) => {
                                                                return accumulator + object.value;
                                                            }, 0))

                                                            let need = (n ? (n[_v.id] || 0) : 0)

                                                            return {val: val, need: need, title: _v.title};
                                                        })

                                                        return <tr>
                                                            <td>
                                                                {format(new Date(i.start), 'HH:mm')}
                                                            </td>
                                                            <td>
                                                                <ul className={'list-unstyled'}>
                                                                    {array.filter(a => filterATypes(a.need, a.val)).map(a => <li>
                                                                        {Icon(a.val, a.need)} {a.val} / {a.need} {a.title}
                                                                    </li>)}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                }

                                                return <></>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </>}
                </div>)}
            </div>
            </div>
        </div>
    </div>
}

export default CalendarPresence;