import * as React from "react";

import RegisterForm from "../../../components/auth/register";

const RegisterPage : React.FC = () => {
    return (
        <RegisterForm />
    )

}

export default RegisterPage;