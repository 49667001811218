import React, {useState} from "react";
import {getTaskStatusChoice, getTaskStatusChoices} from "../../../constants/task";
import {Task, TaskStatus} from "../../../models/task";
import * as statusApi from "../../../adapters/task/status";
import {AxiosResponse} from "axios";

export const StatusSelector:React.FC<{status?: TaskStatus, taskId: number, exDate: string, _handleChangeSuccess: (task: Task) => any, disabled?: boolean}> = (props) => {

    const {status, taskId, exDate, _handleChangeSuccess, disabled} = props;

    const [_status, _setStatus] = useState<TaskStatus|undefined>(status);
    const [selected, setSelected] = useState<number>(status?.val || 1);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (s: number) => {
        setSelected(s)
        setLoading(true)

        if (_status){
            statusApi.edit({
                exDate: exDate,
                status: s,
            }, taskId, _status.id).then(r => handleChangeSuccess(r))
        }else{
            statusApi.create({
                exDate: exDate,
                status: s,
            }, taskId).then(r => handleChangeSuccess(r))
        }
    }

    function handleChangeSuccess(r: AxiosResponse){
        setLoading(false)
        _setStatus(r.data.status)
        _handleChangeSuccess(r.data)
    }

    return <div className="dropdown">
            <button className="btn dropdown-toggle" type="button"
                    disabled={disabled}
                    data-bs-toggle="dropdown" aria-expanded="false">
                <i className={getTaskStatusChoice(selected).icon}></i> {getTaskStatusChoice(selected).label}
            </button>
            <ul className="dropdown-menu">
                {getTaskStatusChoices().filter(s => s.value !== selected).map(s => <li className={'dropdown-item'} onClick={() => handleChange(s.value)}>
                    <i className={s.icon}></i> {s.label}
                </li>)}
            </ul>
    </div>
}