import React, {useEffect, useState} from "react";
import {SalaryTemplate, SalaryTemplates} from "../../../../models/salary/template";
import {Salary} from "../../../../models/salary";
import SalaryTemplateEditForm from "./form";
import {Offcanvas} from "bootstrap";

const SalaryTemplateEdit:React.FC<{
    templates: SalaryTemplates,
    template: SalaryTemplate,
    salary: Salary,
    setTemplates: React.Dispatch<any>,
    setTemplate: React.Dispatch<any>,
}> = (props) => {

    const {templates, salary, setTemplates, template, setTemplate } = props;
    const [offcanvas, setOffcanvas] = useState<Offcanvas>()

    useEffect(() => {
        const el = document.getElementById('offcanvasSalaryTemplateEdit')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const handleSubmit = (t: SalaryTemplate) => {

        let index = templates.findIndex(_t => _t.id === t.id)
        if (index !== -1){
            templates[index] = t;
            setTemplates([...templates])
        }
        setTemplate(t)
        close()
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasSalaryTemplateEdit" aria-labelledby="offcanvasSalaryTemplateEditLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasSalaryTemplateEditLabel">Ajouter un emploi du temps</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <SalaryTemplateEditForm templates={templates} salary={salary} handleSubmit={handleSubmit} template={template} />
        </div>
    </div>
}

export default SalaryTemplateEdit;