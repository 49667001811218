export const FETCH_COUNTERS = 'FETCH_COUNTER';
export const FETCH_COUNTERS_SUCCESS = 'FETCH_COUNTER_SUCCESS';
export const FETCH_COUNTERS_FAILURE = 'FETCH_COUNTER_FAILURE';

export const SHOW_COUNTER = 'SHOW_COUNTER';
export const SHOW_COUNTER_SUCCESS = 'SHOW_COUNTER_SUCCESS';
export const SHOW_COUNTER_FAILURE = 'SHOW_COUNTER_FAILURE';

export const EDIT_COUNTER = 'EDIT_COUNTER';
export const EDIT_COUNTER_SUCCESS = 'EDIT_COUNTER_SUCCESS';
export const EDIT_COUNTER_FAILURE = 'EDIT_COUNTER_FAILURE';

export const REFRESH_COUNTER = 'REFRESH_COUNTER';
