import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import FileSaver from "file-saver";

import * as models from "../../../models/salary";

import { FORM_NEW_SALARY, SALARY_IMPORT } from "../../../constants/rightSidebar";
import { PAGINATION } from "../../../constants/global";
import * as accessRights from "../../../constants/accessRight";

import { _export, list } from "../../../adapters/salary";
import * as actions from "../../../actions/salary";
import { openSidebar } from "../../../actions/rightSidebar";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { axiosError } from "../../../actions/axios";

import { Granted } from "../../../security/granted";
import LoaderList from "../../../utils/loaderList";
import BootstrapSelect from "../../../utils/bootstrapSelect";

const SalaryList: React.FC = () => {
    const dispatch = useDispatch();
    const params: {id: string} = useParams();

    const state = useSelector((state: RootStateOrAny)  => state.salary);

    const [offset, setOffset] = useState<number>(0);
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [query, setQuery] = useState<string|undefined>();
    const [activated, setActivated] = useState<boolean>(true);
    const [companies, setCompanies] = useState<number[]>([]);
    const [jobs, setJobs] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFiltering, setFiltering] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false)

    const __export = () => {
        setIsExporting(true);
        _export().then(data => {
            let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
            FileSaver.saveAs(newBlob, "export.xlsx")
            setIsExporting(false);
        }).catch(error => dispatch(axiosError(error)))
    }

    useEffect(() => {
        dispatch(actions.fetchSalaries())
        setIsLoading(true);

        list({query: query, activated: activated, companies: companies, jobs: jobs, offset: 0}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            dispatch(actions.fetchSalariesSuccess(data.data))
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => dispatch(axiosError(error)))

        return () => {

        }
    }, [activated, companies, jobs])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setFiltering(true);
                setOffset(0)
                list({query: query, activated: activated, companies: companies, jobs: jobs, offset: 0}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchSalariesSuccess(data.data))
                    setFiltering(false);
                    setOffset( 1);
                }).catch(error => dispatch(axiosError(error)))
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if (scrollBottom) {
            if (offset && !isLast){
                setIsLoading(true)
                list({query: query, activated: activated, companies: companies, jobs: jobs, offset: offset}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchSalariesSuccess([...state.payload, ...data.data]))
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                    setScrollBottom(false)
                }).catch(error => dispatch(axiosError(error)))
            }

            setScrollBottom(false);
        }
    }, [scrollBottom])

    useEffect(() => {
        dispatch(setPageTitle('Collaborateurs'))
        dispatch(setHelpProductSheetIds([1]))

        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [])

    return (
        <>
            <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white border-end overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
                <div className="w-100">
                    <div className="sticky-top bg-light d-flex flex-column">
                        <div className="col p-0">
                            <div className="input-group">
                                <span className="input-group-text bg-white border-end border-0" id="search-addon">
                                    {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : <i className="bi bi-search"> </i>}
                                </span>
                                <input type="search" onChange={(e) => {
                                    setQuery(e.target.value)
                                }} className="form-control border-0 bg-white" placeholder="Rechercher..." aria-describedby="search-addon" />
                            </div>
                        </div>

                        {Granted(accessRights.EDIT_SALARY) &&
                            <div className="btn-group">
                                <button data-bs-toggle="modal" data-bs-target="#filterModal"
                                        className='btn btn-outline-primary'>
                                    <i className={'bi bi-filter'}> </i>
                                </button>
                                <button onClick={() => dispatch(openSidebar(FORM_NEW_SALARY))}
                                        className='btn btn-outline-primary'>
                                    <i className={'bi bi-plus-circle'}> </i>
                                </button>
                                <button
                                    onClick={() => dispatch(openSidebar(SALARY_IMPORT))}
                                    className='btn btn-outline-primary'>
                                    <i className={'bi bi-box-arrow-in-left'}> </i>
                                </button>
                                <button disabled={isExporting} onClick={() => __export()}
                                        className='btn btn-outline-primary'>
                                    <i className={'bi bi-box-arrow-right'}> </i>
                                </button>
                            </div>
                        }
                    </div>

                    {state.payload.length ? state.payload.map((salary: models.Salary, index: number) => ([
                        <Link to={"/salary/" + salary.id} key={index} className={"px-3 list-group-item list-group-item-action" + (state.single && state.single.id === salary.id ? " active" : '')}>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    <h4 className={'mb-0'}> {salary.title}</h4>
                                    <div className="clearfix"> </div>
                                    <small>
                                        {salary.information?.job.title}
                                    </small>
                                </div>
                                <div className="col-auto">
                                    <div className="color-circle col-auto">
                                        <div className="p-1 shadow" style={{backgroundColor: `${salary.information?.job.color}`, opacity: 0.8}}> </div>
                                    </div>
                                </div>
                            </div>
                        </Link>]))
                        : !isLoading ?
                            <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
                                Aucun collaborateur
                            </button> : ''
                    }

                    {!isLoading && !isLast && state.payload.length &&
                        <button onClick={() => setScrollBottom(true)} className={'list-group-item text-primary text-center list-group-item-action'}>
                            <i className={'bi bi-arrow-repeat'}> </i> Charger plus
                        </button>
                    }

                    {isLoading && !state.payload.length &&
                        <LoaderList title={true} listLength={50} description={true} leftCircle={true} />
                    }
                    {isLoading && !!state.payload.length &&
                        <LoaderList title={true} listLength={1} description={true} leftCircle={true} />
                    }
                </div>
            </div>

            <div className="modal fade" id="filterModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Filtrer les collaborateurs</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>

                        <div className="modal-body">
                            <div className="col-12 mb-3">
                                <div className="form-check">
                                    <input onChange={(e) => setActivated(e.target.checked)} className="form-check-input" type="radio" name="activated"
                                           id="activated" defaultChecked={activated} />
                                    <label className="form-check-label" htmlFor="activated">
                                       <i className={'bi bi-check2-circle text-success'}> </i> Activé
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input onChange={(e) => setActivated(!e.target.checked)} className="form-check-input" type="radio" name="activated"
                                           id="deactivated" defaultChecked={!activated} />
                                    <label className="form-check-label" htmlFor="deactivated">
                                        <i className={'bi bi-dash-circle text-danger'}> </i> Desactivé
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <BootstrapSelect
                                    isMultiple={true}
                                    label={"Points de ventes"}
                                    placeholder={"Tous les établissements"}
                                    labelIcon={'bi bi-shop text-primary'}
                                    fetchEntity={"company"}
                                    onChangeMultiple={(choices) => setCompanies(choices.map(c => Number(c.value)))}
                                />
                            </div>

                            <div className="col-12 mb-3">
                                <BootstrapSelect
                                    isMultiple={true}
                                    label={"Métiers"}
                                    placeholder={"Tous les métiers"}
                                    labelIcon={'bi bi-person text-primary'}
                                    fetchEntity={"job"}
                                    onChangeMultiple={(choices) => setJobs(choices.map(c => Number(c.value)))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalaryList;