import {get, post} from "../../xhr";

export function create(requestData: any, target: number){
    return post('manager/create/' + target,  requestData);
}

export function edit(requestData: any, id: number){
    return post('manager/edit/' + id,  requestData);
}
export function remove(id: number){
    return get('manager/remove/' + id);
}