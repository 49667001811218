import React, {useEffect, useState} from "react";
import TimetableEventAddForm from "../form";
import {DateSelectArg} from "@fullcalendar/react";
import {Company} from "../../../../../models/companies";
import {Timetable, TimetableEvents} from "../../../../../models/timetable";
import {Salary} from "../../../../../models/salary";

const TimetableEventSelect:React.FC<{arg: DateSelectArg, salary: Salary, week: number, company: Company, timetable: Timetable, handleSubmitSuccess: (ev: any) => any, events: TimetableEvents}> = (props) => {

    const {arg, salary, timetable, company, week, events, handleSubmitSuccess} = props

    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey(prev => prev + 1)
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventDropLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <TimetableEventAddForm
                key={key}
                refreshValues={key}
                salary={salary}
                start={arg.start}
                end={arg.end}
                week={week}
                company={company}
                timetable={timetable}
                handleSubmitSuccess={handleSubmitSuccess}
                events={events}/>}
        </div>
    </>
}

export default TimetableEventSelect;