import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Granted } from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight";

import { Salary, SalaryFiles } from "../../../../models/salary";

import { FORM_NEW_SALARY_STOCKED_DOCUMENT } from "../../../../constants/rightSidebar";

import * as api from "../../../../adapters/salary/file";
import { openSidebar } from "../../../../actions/rightSidebar";

import SalaryDocumentStocked from "./bucket";
import Loader from "../../../../utils/loader";

const SalaryStorage: React.FC = () => {
    const dispatch = useDispatch();

    const salary: Salary = useSelector((state:RootStateOrAny) => state.salary.single);

    const [files, setFiles] = useState<SalaryFiles>([])
    const [loading, setLoading] = useState<boolean>(true)

    const fetchSalaryFilesList = () => {
        setLoading(true);
        api.list(salary.id).then(resp => setFiles(resp.data))
            .then(() => setLoading(false))
    }

    useEffect(() => {        
        fetchSalaryFilesList()
    }, [salary])

    return (
        <div className={"container-fluid h-100"}>
            <div className="row h-100">
                <div className="card shadow-sm p-1 p-md-3 h-100">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h4>
                                Stocker
                            </h4>
                        </div>
                        <div className={'flex-grow-0'}>
                            {(Granted(accessRights.EDIT_SALARY, salary.id)) && <button onClick={() => dispatch(openSidebar(FORM_NEW_SALARY_STOCKED_DOCUMENT))} className={'btn w-100 btn-outline-primary ms-2'}>
                                <i className={'bi bi-plus'}> </i> <span>Ajouter</span>
                            </button>}
                        </div>
                    </div>
                    <p className={"form-text"}>
                        Stocker vos documents utiles (cv, diplôme, carte vitale...)
                    </p>
                    {loading ? <><Loader /> Chargement des fichiers...</> : <SalaryDocumentStocked files={files} fetchSalaryFilesList={fetchSalaryFilesList}/>}
                </div>
            </div>
        </div>
    )
}

export default SalaryStorage;