import React from "react";
import * as types from "../../constants/rightSidebar";


export const openSidebar = (item:string, data?: any, props?: any) => {
    return {
        type: types.OPEN_SIDEBAR,
        item: item,
        data: data,
        props: props
    }
};

export const setSidebarItem = (item:string, data?: any, props?: any) => {
    return {
        type: types.SET_SIDEBAR_ITEM,
        item: item,
        data: data,
        props: props
    }
};

export const submitSidebar = () => {
    return {
        type: types.SUBMIT_SIDEBAR,
    }
};

export const closeSidebar = (force?: boolean) => {
    return {
        type: types.CLOSE_SIDEBAR,
        force: force
    }
};