export const FETCH_EVENTS = 'TASK_FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'TASK_FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'TASK_FETCH_EVENTS_FAILURE';

export const FETCH_RESOURCES = 'TASK_FETCH_RESOURCES';
export const FETCH_RESOURCES_SUCCESS = 'TASK_FETCH_RESOURCES_SUCCESS';
export const FETCH_RESOURCES_FAILURE = 'TASK_FETCH_RESOURCES_FAILURE';

export const FETCH_WORKING_TIMES = 'TASK_FETCH_WORKING_TIMES';
export const FETCH_WORKING_TIMES_SUCCESS = 'TASK_FETCH_WORKING_TIMES_SUCCESS';
export const FETCH_WORKING_TIMES_FAILURE = 'TASK_FETCH_WORKING_TIMES_FAILURE';

export const FETCH_TITLE_SUCCESS = 'TASK_FETCH_TITLE_SUCCESS';
export const FETCH_PAYROLL_SUCCESS = 'TASK_FETCH_TITLE_SUCCESS';

export const SET_EVENT_CLICK_ARG = 'TASK_SET_EVENT_CLICK_ARG';

export const SHOW_EVENT_POP = 'TASK_SHOW_EVENT_POP';
export const HIDE_EVENT_POP = 'TASK_HIDE_EVENT_POP';

export const SHOW_RESOURCE_ACTIONS = 'TASK_SHOW_RESOURCE_ACTIONS';
export const HIDE_RESOURCE_ACTIONS = 'TASK_HIDE_RESOURCE_ACTIONS';

export const CANCEL_EVENT = 'TASK_CANCEL_EVENT';
export const CANCEL_EVENTS = 'TASK_CANCEL_EVENTS';

export const REFRESH = 'TASK_REFRESH';

