import * as React from "react";

import AbsenceList from "../../components/absence/list";
import AbsenceShow from "../../components/absence/show";

const AbsencePage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <AbsenceList />
            <AbsenceShow />
        </div>
    )
}

export default AbsencePage;