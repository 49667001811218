import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import CalendarAbsenceEdit from "../edit";
import CalendarAbsenceAdd from "../add";
import {Salary} from "../../../../models/salary";
import {Offcanvas} from "bootstrap";

interface InterfaceAbsenceEdit {
    sidebarType: 'ABSENCE_EDIT',
    arg: EventClickArg,
}

interface InterfaceAbsenceAdd {
    sidebarType: 'ABSENCE_ADD',
    salary?: Salary,
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarAbsenceSidebarInterface = {trigger: number} & (Interface | InterfaceAbsenceEdit | InterfaceAbsenceAdd)

const CalendarAbsenceSidebar:React.FC<CalendarAbsenceSidebarInterface & {handleEventChange: any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarAbsence')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarAbsence')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }
    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "ABSENCE_EDIT":
                    return <CalendarAbsenceEdit close={close} arg={props.arg} handleSubmitSuccess={props.handleEventChange} />
                case "ABSENCE_ADD":
                    return <CalendarAbsenceAdd close={close} handleSubmitSuccess={props.handleEventChange} salary={props.salary} />
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarAbsence" aria-labelledby="offcanvasCalendarAbsenceLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: CalendarAbsenceSidebarInterface & {handleEventChange: any}, nextProps: CalendarAbsenceSidebarInterface & {handleEventChange: any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarAbsenceSidebar, areEqual);