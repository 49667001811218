import {get, post} from "../xhr";

export function save(requestData: {start: string, end: string}){
    return post(`counter/save`, requestData)
}

export function saveSingle(requestData: {start: string, end: string}, salaryId: number){
    return post(`counter/save/${salaryId}`, requestData)
}

export function sum(signal: AbortSignal ,requestData?: {date?: string}){
    return post(`counter/sum`, requestData || {}, {signal: signal})
}

export function sumSingle(){
    return get(`counter/sum_single`)
}

export function listTable(){
    return get(`counter/list_table`)
}

export function edit(requestData: {signe: string, valueHour: number, valueMin: number, paidOverTimeHour: number, paidOverTimeMin: number}, id: number){
    return post(`counter/edit/${id}`, requestData)
}

export function remove(id: number){
    return get(`counter/delete/${id}`)
}

export function saveMany(salary: number, requestData: {wn: number, year: number, value: number}[]){
    return post(`counter/save_many/${salary}`, requestData)
}

export function clearMany(salary: number, requestData: {wn: number, year: number}[]){
    return post(`counter/clear_many/${salary}`, requestData)
}

export function editWeek(salary: number, requestData: {wn: number, year: number}){
    return post(`counter/edit_week/${salary}`, requestData)
}