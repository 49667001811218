import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as API from "../../../../adapters/companyGroup"
import {AbsenceSettings} from "../../../../models/companyGroup";
import {editUserCompanyGroupSuccess} from "../../../../actions/auth";
import BootstrapSelect from "../../../../utils/bootstrapSelect";

const AbsenceConfig:React.FC = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state);
    const settings:AbsenceSettings = state.auth.user.currentSalary.companyGroup.absenceSettings;
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [acquisition, setAcquisition] = useState<number>(settings.acquisition);
    const [accountedTime, setAccountedTime] = useState<number>(settings.accountedTime);

    const accountedTypeOptions = [
        {label: 'Heures planifiés', value: 1},
        {label: 'Heures total', value: 2},
        {label: 'Heures par jour', value: 3},
    ];


    const handleChange = (setting: string, value: any) => {
        setIsLoading(true);
        API.absenceSetting({
            setting: setting,
            value: value
        }).then((data) => {
            dispatch(editUserCompanyGroupSuccess(data.data))
            setIsLoading(false);
        })
    }

    return <>
        <div className="p-3">
            <div className="card p-3">
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="">
                                Décompte de congés payés en
                            </label>
                            <div className="form-text">
                                Nombre de congés retenus par semaine (5 jours ouvrés, 6 jours ouvrables)
                            </div>
                        </div>
                        <div className="col-auto">
                            <select disabled={isLoading} className={'form-control'} onChange={(event => handleChange('countdown', event.target.value))}>
                                <option selected={settings.countdown === 1} value="1">
                                    Jours ouvrés
                                </option>
                                <option selected={settings.countdown === 2} value="2">
                                    Jours ouvrables
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr className={'bg-primary'}/>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="">
                                Prendre en compte le jour de reprise dans le calcul du nombre de jours de congés payés
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <input disabled={isLoading} defaultChecked={settings.returnDay} onChange={(event => handleChange('return_day', event.target.checked))} className="form-check-input" type="checkbox" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="">
                                Prendre les congés payés sur les acquis de l'année :
                            </label>
                            <div className="form-text">
                                Prendre les congés payés sur les acquis de l'année en cours (N) ou de l'année passé (N-1)
                            </div>
                        </div>
                        <div className="col-auto">
                            <select disabled={isLoading} className={'form-control'} onChange={(event => handleChange('decrement_period', event.target.value))}>
                                <option selected={settings.decrementPeriod === 1} value="1">
                                    N-1
                                </option>
                                <option selected={settings.decrementPeriod === 2} value="2">
                                    N
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr className={'bg-primary'}/>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="">
                                Base d'acquisition mensuelle des congés payés
                            </label>
                            <div className="form-text">
                                Nombre de congés payés aquis par mois travaillés
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="input-group">
                                <input onChange={(e) => setAcquisition(parseFloat(e.target.value))} disabled={isLoading} defaultValue={settings.acquisition} type="number" className="form-control" />
                                <span className="input-group-text">Jours</span>
                                {settings.acquisition !== acquisition && <button disabled={isLoading} onClick={(event => handleChange('acquisition', acquisition))} className={'btn btn-outline-success'}>
                                    Enregistrer
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={'bg-primary'}/>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="">
                                Période de référence
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <select disabled={isLoading} className={'form-control'} onChange={(event => handleChange('period', event.target.value))}>
                                    <option selected={settings.period === 1} value="1">
                                        1er Juin au 31 Mai
                                    </option>
                                    <option selected={settings.period === 3} value="3">
                                        1er Mai au 30 Avril
                                    </option>
                                    <option selected={settings.period === 2} value="2">
                                        1er Janvier au 31 Décembre
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={'bg-primary'}/>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="">
                                Reinitialiser les RTT à la fin de la période de référence
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <input disabled={isLoading} defaultChecked={settings.resetRTT} onChange={(event => handleChange('reset_rtt', event.target.checked))} className="form-check-input" type="checkbox" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={'bg-primary'}/>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="">
                                Par défaut, comptabiliser les heures d'absences telles que
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <BootstrapSelect
                                    notFloating={true}
                                    value={settings.accountedType ? accountedTypeOptions.find(o => o.value === settings.accountedType) : undefined}
                                    options={[
                                        {label: 'Heures planifiées', value: 1},
                                        {label: 'Heures par jour', value: 3},
                                    ]}
                                    required={true}
                                    onChange={(c) => handleChange('accounted_type', c?.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {settings.accountedType !== 1 && <>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Nombre d'heures d'absence
                                </label>
                            </div>
                            <div className="col-auto">
                                <div className="input-group">
                                    <input onChange={(e) => setAccountedTime(parseFloat(e.target.value))} disabled={isLoading} defaultValue={settings.accountedTime} type="number" className="form-control" />
                                    <span className="input-group-text">heures</span>
                                    {settings.accountedTime !== accountedTime && <button disabled={isLoading} onClick={(event => handleChange('accounted_time', accountedTime))} className={'btn btn-outline-success'}>
                                        Enregistrer
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    </>
}

export default AbsenceConfig;