import './loader.css'
import React from "react";


export const Loader: React.FC<{type?: "border"|"grow"}> = (props) => {

    const {type} = props;

    return (
        <div style={{zIndex: 100000000, backgroundColor:'rgba(255,255,255,0.2)'}} className={'w-100 h-100 top-0 position-absolute text-center d-flex align-items-center'}>
            <div className="col-12">
                <div className={"spinner-" + (type ? type : 'border')} style={{
                    width: '4rem',
                    height: '4rem',
                }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default Loader;