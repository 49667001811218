import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup"
import {SingleSalarySelected, SingleSalarySelector} from "../../../utils/salarySelector/single";
import {Salary} from "../../../models/salary";
import {Company} from "../../../models/companies";
import {Localisation} from "../../../models/localisation";
import {Activity} from "../../../models/activity";
import DatePicker from "react-datepicker";
import {setHours, setMinutes} from "date-fns";
import * as api from "../../../adapters/booking"
import {closeSidebar, openSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import {editEventValidate, refresh, refreshResource} from "../../../actions/calendar";
import FormError from "../../../utils/formError";
import validationClass from "../../../utils/validationClass";
import DateCustomInput from "../../../utils/customInput";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {
    FORM_EDIT_BOOKING_GROUP_RELOAD,
    REPORTING_SHOW_WEEK,
} from "../../../constants/rightSidebar";
import {Choice} from "../../../utils/form/model";
import format from "../../../utils/locale";
import {AccountingTypes, getAccountingTypeChoice} from "../../../constants/booking";
import * as reportingApi from "../../../adapters/reporting";
import {refreshSingleReporting} from "../../../actions/reporting";
import {BOOKING_RECURRENCE_CHOICES, getBookingRecurrenceChoice} from "../../../models/booking";

const BookingEditTest:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const [salary, setSalary] = useState<Salary>(state.rightSidebar.data.salary)
    const [displayConfirmationTypeSelector, setDisplayConfirmationTypeSelector] = useState(false);
    const [confirmationType, setConfirmationType] = useState<number>(1);
    const [displaySalarySelector, setDisplaySalarySelector] = useState<boolean>(false);
    // const [weeks, setWeeks] = useState<string[]>([])

    let now = new Date(state.rightSidebar.data.start)
    now.setDate(now.getDate() + 7);
    const [startDate, setStartDate] = useState<Date>(state.rightSidebar.data.start);
    const [endDate, setEndDate] = useState<Date>(now);
    const [weeks, setWeeks] = useState<string[]>([])
    const [company, setCompany] = useState<Company>(state.rightSidebar.data.company)
    const [init, setInit] = useState(false)

    const [initialValues, setInitialValues] = useState<any>({
        salary: state.rightSidebar.data.salary.id,
        company: state.rightSidebar.data.company.id,
        localisation: state.rightSidebar.data.localisation?.id,
        activity: state.rightSidebar.data.activity?.id,
        beginAt: state.rightSidebar.data.start,
        endAt: state.rightSidebar.data.end,
        accountingType: state.rightSidebar.data.accountingType,
        description: state.rightSidebar.data.description,
        recurrence: {
            type: String(state.rightSidebar.data.recurrence.type)
        }
    })

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
        salary: Yup.number().required(),
        company: Yup.number().required(),
        localisation: Yup.number().nullable(),
        activity: Yup.number().nullable(),
        beginAt: Yup.date().required(),
        endAt: Yup.date().required().test("is-greater", "La fin doit être après le début", function(value: any) {
            const { beginAt } = this.parent;
            return value && beginAt <= value
        }),
        accountingType: Yup.number().required(),
        description: Yup.string().nullable(),
        recurrence: Yup.object().shape({
            type: Yup.number().required()
        })
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(submitSidebar());
            switch (confirmationType){
                case 1:
                    if (state.rightSidebar.data.isException){
                        api.editException(values, state.rightSidebar.data.exceptionInstanceId).then((data) => submitSuccess())
                    }else{
                        api.createException(values, state.rightSidebar.data.bookingInstanceId, state.rightSidebar.data.instanceToken).then((data) => submitSuccess())
                    }
                    break;
                case 2:
                    api.split(values, state.rightSidebar.data.bookingInstanceId).then((data) => submitSuccess())
                    break;
                case 3:
                    api.edit(values, state.rightSidebar.data.bookingInstanceId).then((data) => submitSuccess())
                    break;
                case 4:
                    api.splitBetween(values, state.rightSidebar.data.bookingInstanceId, format(startDate, "uuuu-MM-dd"), format(endDate, "uuuu-MM-dd")).then((data) => submitSuccess())
                    break;
                case 5:
                    api.splitDay(values, state.rightSidebar.data.bookingInstanceId).then((data) => submitSuccess())
                    break;
                case 6:
                    api.editDay(values, state.rightSidebar.data.bookingInstanceId).then((data) => submitSuccess())
                    break;
            }
        },
    });

    useEffect(() => {
        getWeeks(company)
    }, [company])

    useEffect(() => {
        let _initialValues = {...initialValues}
        let data = init ? {..._initialValues} : {...state.rightSidebar.data}
        let _validationSchema = {...validationSchema.fields}

        let fields: string[] = [];

        if (['2','3'].includes(_initialValues.recurrence.type)){
            _validationSchema.recurrence.fields.daysOfWeek = Yup.array().of(Yup.number()).min(1);

            if (!state.rightSidebar.data.bookingGroupId){
                fields.push('start', 'end')
                _validationSchema.recurrence.fields.start = Yup.date().required();
                _validationSchema.recurrence.fields.end = Yup.date().nullable();
            }
        }

        switch (_initialValues.recurrence.type){
            case '1':
                fields.push('type');
                break;
            case '2':
                fields.push('type', 'daysOfWeek', 'weekLetters');
                _validationSchema.recurrence.fields.weekLetters = Yup.array().of(Yup.number()).min(1);
                break;
            case '3':
                fields.push('type', 'daysOfWeek', 'separationCount');
                _validationSchema.recurrence.fields.separationCount = Yup.number().required();
                break;
        }

        // setValues
        for (let i in fields){
            switch (fields[i]) {
                case 'type':
                    _initialValues.recurrence[fields[i]] = data.recurrence[fields[i]].toString();
                    break;
                case 'daysOfWeek':
                case 'weekLetters':
                    if (data.recurrence.hasOwnProperty(fields[i])){
                        _initialValues.recurrence[fields[i]] = data.recurrence[fields[i]].map((num: number) => num.toString());
                        break;
                    }

                    _initialValues.recurrence[fields[i]] = [];
                    break;
                case 'end':
                case 'start':
                    if (data.recurrence.hasOwnProperty(fields[i])) {
                        _initialValues.recurrence[fields[i]] = data.recurrence[fields[i]] ? new Date(state.rightSidebar.data.recurrence[fields[i]]) : null;
                        break;
                    }

                    _initialValues.recurrence[fields[i]] = new Date();
                    break;
                case 'separationCount':
                    if (data.recurrence.hasOwnProperty(fields[i])) {
                        _initialValues.recurrence[fields[i]] = data.recurrence[fields[i]].toString();
                        break;
                    }

                    _initialValues.recurrence[fields[i]] = String(1);
                    break;
                default:
                    if (data.recurrence.hasOwnProperty(fields[i])) {
                        _initialValues.recurrence[fields[i]] = data.recurrence[fields[i]];
                        break;
                    }
                    _initialValues.recurrence[fields[i]] = null;
            }
        }

        setInit(true);
        setInitialValues({..._initialValues});
        setValidationSchema(Yup.object().shape({..._validationSchema}))

    }, [formik.values.recurrence.type])



    const submitSuccess = () => {
        dispatch(showAlertSuccess('Créneau modifié'))
        dispatch(editEventValidate())

        if (state.rightSidebar.data?.next){
            switch (state.rightSidebar.data?.next){
                case REPORTING_SHOW_WEEK:
                    reportingApi.fetch({
                        start: state.rightSidebar.data.data.start,
                        end: state.rightSidebar.data.data.end,
                        options: {
                            salaries: [state.rightSidebar.data.salary.id]
                        }
                    }).then(_data => {
                        dispatch(refreshSingleReporting(_data.data[0]))
                        dispatch(openSidebar(state.rightSidebar.data.next, state.rightSidebar.data.data))
                    })
                    break;
            }
        }else{
            if (state.rightSidebar.data.resourceId && state.rightSidebar.data.parentId && state.rightSidebar.data.salary){
                dispatch(refreshResource(state.rightSidebar.data.resourceId, state.rightSidebar.data.parentId, state.rightSidebar.data.salary))
            }else{
                dispatch(refresh());
            }
            dispatch(closeSidebar());
        }
    }

    const getWeeks = (company: Company) => {

        let length = company.timesheet.recurrence;
        let ret: string[] = [];
        for (let i = 0; i < length; i++){
            ret.push(String.fromCharCode(65 + i));
        }

        setWeeks(ret);
    }


    if (displayConfirmationTypeSelector){
        return <div className={'p-3'}>
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <h4>
                        <i className="bi bi-arrow-repeat text-primary"> </i>
                        Créneau récurrent
                    </h4>
                    <div className="form-text">

                    </div>
                </div>
                <div className="flex-grow-0">
                    <button onClick={() => setDisplayConfirmationTypeSelector(false)} className={'btn btn-outline-primary'}>
                        Retour
                    </button>
                </div>
            </div>
            <h4 className={'mb-3'}>
                Vous souhaitez que la modification s'applique :
            </h4>
            <ul className={'list-group list-group-flush'}>
                <li className={'list-group-item list-group-item-action'} onClick={() => {
                    setConfirmationType(1)
                    formik.submitForm().then(data => console.log(data));
                }}>
                    Une seule fois
                </li>
                {<>
                    <li className={'list-group-item list-group-item-action'} onClick={() => {
                        setConfirmationType(2)
                        formik.submitForm().then(data => console.log(data));
                    }}>
                        Cette occurence et toute les suivantes
                        <div className="clearfix"> </div>
                        {state.rightSidebar.data.bookingGroupId && <span className={'form-text'}>
                            <i className={'bi bi-info-circle'}></i> Créer un nouvel emploi du temps à partir du <b>{format(new Date(formik.values.beginAt))}</b> {state.rightSidebar.data.recurrence.end && <>jusqu'au <b>{format(new Date(state.rightSidebar.data.recurrence.end))}</b></>}
                        </span>}
                    </li>
                    <li className={'list-group-item list-group-item-action'} onClick={() => {
                        setConfirmationType(5)
                        formik.submitForm().then(data => console.log(data));
                    }}>
                        Cette occurence et toute les suivantes le {format(formik.values.beginAt, 'eeee')} seulement
                        <div className="clearfix"> </div>
                        {state.rightSidebar.data.bookingGroupId && <span className={'form-text'}>
                            <i className={'bi bi-info-circle'}></i> Créer un nouvel emploi du temps à partir du <b>{format(new Date(formik.values.beginAt))}</b> {state.rightSidebar.data.recurrence.end && <>jusqu'au <b>{format(new Date(state.rightSidebar.data.recurrence.end))}</b></>}
                        </span>}
                    </li>
                </>}

                {<>
                    <li className={'list-group-item list-group-item-action'} onClick={() => {
                        setConfirmationType(3)
                        formik.submitForm().then(data => console.log(data));
                    }}>
                        Toutes les occurences
                        <div className="clearfix"></div>
                        <span className="form-text">
                             <i className={'bi bi-exclamation-triangle text-warning'}></i> Attention, en modifiant toutes les occurences, les modifications ponctuel apportés au créneau seront perdues.
                        </span>
                        <div className="clearfix"></div>
                        {!state.rightSidebar.data.bookingGroupId && <span className="form-text">
                            {formik.values.recurrence.start && formik.values.recurrence.end && <>Du {format(formik.values.recurrence.start, 'dd/MM/uuuu')} au {format(formik.values.recurrence.end, 'dd/MM/uuuu')}</>}
                            {formik.values.recurrence.start && !formik.values.recurrence.end && <>A partir
                                du {format(formik.values.recurrence.start, 'dd/MM/uuuu')}</>}
                        </span>}
                        {state.rightSidebar.data.bookingGroupId && <div className={'form-text'}>
                            <i className={'bi bi-info-circle'}></i> Modifier toutes les occurrences du créneau à partir du <b>{format(new Date(state.rightSidebar.data.recurrence.start))}</b> {state.rightSidebar.data.recurrence.end && <>jusqu'au <b>{format(new Date(state.rightSidebar.data.recurrence.end))}</b></>}
                        </div>}
                    </li>

                    <li className={'list-group-item list-group-item-action'} onClick={() => {
                        setConfirmationType(6)
                        formik.submitForm().then(data => console.log(data));
                    }}>
                        Toutes les occurences le {format(formik.values.beginAt, 'eeee')} seulement
                        <div className="clearfix"> </div>
                        <span className="form-text">
                             <i className={'bi bi-exclamation-triangle text-warning'}></i> Attention, en modifiant toutes les occurences, les modifications ponctuel apportés au créneau seront perdues.
                        </span>
                        <div className="clearfix"></div>
                        {!state.rightSidebar.data.bookingGroupId && <span className="form-text">
                            {formik.values.recurrence.start && formik.values.recurrence.end && <>Du {format(formik.values.recurrence.start, 'dd/MM/uuuu')} au {format(formik.values.recurrence.end, 'dd/MM/uuuu')}</>}
                            {formik.values.recurrence.start && !formik.values.recurrence.end && <>A partir
                                du {format(formik.values.recurrence.start, 'dd/MM/uuuu')}</>}
                        </span>}
                        {state.rightSidebar.data.bookingGroupId && <div className={'form-text'}>
                            <i className={'bi bi-info-circle'}></i> Modifier toutes les occurrences du créneau le {format(formik.values.beginAt, 'eeee')} à partir du <b>{format(new Date(state.rightSidebar.data.recurrence.start))}</b> {state.rightSidebar.data.recurrence.end && <>jusqu'au <b>{format(new Date(state.rightSidebar.data.recurrence.end))}</b></>}
                        </div>}
                    </li>
                </>}
            </ul>
        </div>
    }

    return <>
        <div className="col-12 p-3">
            <form onSubmit={formik.handleSubmit}>
                {!state.rightSidebar.data.bookingGroupId && <div className="col-12 mb-3">
                    <SingleSalarySelected
                        onDelete={() => setDisplaySalarySelector(true)}
                        onOpen={() => setDisplaySalarySelector(true)}
                        initialValue={formik.values.salary}
                        initialSalary={state.rightSidebar.data.salary}
                        validationClass={validationClass(formik.errors, formik.touched, 'salary')}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'salary'}/>
                    {displaySalarySelector && <SingleSalarySelector
                        onSubmit={(salary: Salary) => {
                            setSalary(salary)
                            formik.setFieldValue('salary', salary.id)
                            setDisplaySalarySelector(false)
                        }}
                        onClose={() => setDisplaySalarySelector(false)}
                        initialValue={formik.values.salary}
                        required={true}
                    />}
                </div>}
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md mb-3">
                            <DatePicker
                                className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                                title={'Début'}
                                ariaLabelledBy={'bi bi-calendar-check text-primary'}
                                customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                                name={'beginAt'}
                                id={'beginAt'}
                                selected={formik.values.beginAt}
                                onChange={(date: Date) => {
                                    formik.setFieldValue(`beginAt`, date)
                                }}
                                selectsStart
                                showTimeSelect
                                timeIntervals={15}
                                timeCaption="Heure de début"
                                dateFormat="E dd MMM uuuu HH:mm"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                            />
                        </div>
                        <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                            <i className={'bi bi-arrow-left-right mx-1'}> </i>
                        </div>
                        <div className="col-12 col-md mb-3">
                            <DatePicker
                                className={'form-control ' + validationClass(formik.errors, formik.touched, 'endAt')}
                                title={'Fin'}
                                ariaLabelledBy={'bi bi-calendar-check text-primary'}
                                customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                                name={'endAt'}
                                id={'endAt'}
                                selected={formik.values.endAt}
                                onChange={(date: Date) => {
                                    formik.setFieldValue(`endAt`, date)
                                }}
                                selectsEnd
                                startDate={formik.values.beginAt}
                                endDate={formik.values.endAt}
                                minDate={formik.values.beginAt}
                                minTime={setHours(setMinutes(new Date(),
                                        new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getMinutes() : 0
                                    ),
                                    new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getHours() : 0
                                )}
                                maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                showTimeSelect
                                timeIntervals={15}
                                timeCaption="Heure de fin"
                                dateFormat="E dd MMM uuuu HH:mm"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                            />
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'beginAt'} />
                        <FormError errors={formik.errors} touched={formik.touched} field={'endAt'} />
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <BootstrapSelect
                        label={"Type d'heure"}
                        value={getAccountingTypeChoice(formik.values.accountingType)}
                        options={AccountingTypes}
                        onChange={(choice) => formik.setFieldValue('accountingType', choice!.value)}
                        required={true}
                    />
                </div>
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        required={true}
                        //options={salary?.companies.map((company: Company) => ({label: company.title, value: company.id!})) || []}
                        fetchEntity="company"
                        fetchParams={{ salaries: [formik.values.salary] }}
                        value={salary?.companies.map((company: Company) => ({label: company.title, value: company.id!})).find(choice => choice.value === formik.values.company) || undefined}
                        onChange={(choice) => {
                            formik.setFieldValue('company', choice?.value)
                            formik.setFieldValue('localisation', null)
                            formik.setFieldValue('activity', null)
                            setCompany(salary!.companies.find(c => c.id === choice!.value)!)
                        }}
                        label={'Point de vente'}
                        labelIcon={'bi bi-shop text-primary me-2'}
                        className={validationClass(formik.errors, formik.touched, 'company')}
                    />
                    <FormError touched={formik.touched} errors={formik.errors} field={'company'} />
                </div>
                {company &&
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-floating">
                                    <BootstrapSelect
                                        required={false}
                                        //options={company?.localisations?.map((localisation: Localisation) => ({label: localisation.title, value: localisation.id!}))}
                                        fetchEntity="localisation"
                                        fetchParams={{ companies: [formik.values.company] }}
                                        value={company?.localisations?.map((localisation: Localisation) => ({label: localisation.title, value: localisation.id!})).find((choice: Choice) => choice.value === formik.values.localisation)}
                                        className={validationClass(formik.errors, formik.touched, 'localisation')}
                                        onChange={(e) => formik.setFieldValue('localisation', e?.value)}
                                        label={'Localisation'}
                                        enableReinitialize={true}
                                        labelIcon={'bi bi-geo-alt text-primary me-2'}
                                    />
                                    <FormError touched={formik.touched} errors={formik.errors} field={'localisation'} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-floating">
                                    <BootstrapSelect
                                        required={false}
                                        //options={company?.activities?.map((activity: Activity) => ({label: activity.title, value: activity.id!}))}
                                        fetchEntity="activity"
                                        fetchParams={{ companies: [formik.values.company] }}
                                        value={company?.activities?.map((activity: Activity) => ({label: activity.title, value: activity.id!})).find((choice: Choice) => choice.value === formik.values.activity)}
                                        className={validationClass(formik.errors, formik.touched, 'activity')}
                                        onChange={(e) => formik.setFieldValue('activity', e?.value)}
                                        enableReinitialize={true}
                                        label={'Activités'}
                                        labelIcon={'bi bi-bookmark text-primary me-2'}
                                    />
                                    <FormError touched={formik.touched} errors={formik.errors} field={'activity'} />
                                </div>
                            </div>
                        </div>
                    </div>}
                <div className="col-12 mb-3">
                    <div className="form-floating">
                                    <textarea name="description" defaultValue={formik.values.description} onChange={formik.handleChange} className="form-control" placeholder="Description"
                                              id="description"></textarea>
                        <label htmlFor="description">Description</label>
                        <FormError touched={formik.touched} errors={formik.errors} field={'description'} />
                    </div>
                </div>
                <hr/>
                <div className="col-12">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h4>
                                <i className={'bi bi-arrow-repeat text-primary me-2'}> </i> Recurrence
                            </h4>
                            {state.rightSidebar.data.bookingGroupId && <div className="alert alert-info">
                               <i className={'bi bi-info-circle'}> </i>
                                Emploi du temps <span className={'text-primary cursor-pointer text-decoration-underline'}
                                onClick={() => dispatch(openSidebar(FORM_EDIT_BOOKING_GROUP_RELOAD, {
                                    salary: state.rightSidebar.data.salary,
                                    id: state.rightSidebar.data.bookingGroupId,
                                    resourceId: state.rightSidebar.data.resourceId,
                                    parentId: state.rightSidebar.data.parentId,
                                }))}
                            >
                                {state.rightSidebar.data.bookingGroupTitle}
                            </span> à partir du {format(new Date(state.rightSidebar.data.recurrence.start))} {state.rightSidebar.data.recurrence.end ? " jusqu'au " + format(new Date(state.rightSidebar.data.recurrence.end)) : ''}
                            </div>}
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            label={'Type de récurrence'}
                            options={BOOKING_RECURRENCE_CHOICES}
                            value={getBookingRecurrenceChoice(formik.values.recurrence.type)}
                            onChange={(c) => formik.setFieldValue('recurrence.type', String(c!.value))}
                            required={true}
                        />
                    </div>
                    {['2', '3'].includes(formik.values.recurrence.type) && !state.rightSidebar.data.bookingGroupId && <div className={'col-12 mb-3'}>
                        <div className="row">
                            <div className="col-12 col-md mb-3">
                                <DatePicker
                                    required={true}
                                    className={'form-control'}
                                    customInput={<DateCustomInput label={'Début de la récurrence'} className={validationClass(formik.errors, formik.values, 'recurrence.start')} icon={'bi bi-calendar-check'} />}
                                    selected={formik.values.recurrence.start}
                                    onChange={(date) => formik.setFieldValue('recurrence.start', date)}
                                    selectsStart
                                    startDate={formik.values.recurrence.start}
                                    endDate={formik.values.recurrence.end}
                                    maxDate={formik.values.recurrence.end}
                                    dateFormat="E dd MMM uuuu"
                                    popperPlacement={"top-end"}
                                    showPopperArrow={false}                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                            <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                                <i className={'bi bi-arrow-left-right mx-1'}> </i>
                            </div>
                            <div className="col-12 col-md mb-3">
                                <DatePicker
                                    className={'form-control'}
                                    customInput={<DateCustomInput label={'Fin de la récurrence'} className={validationClass(formik.errors, formik.values, 'recurrence.end')} icon={'bi bi-calendar-check'} />}
                                    selected={formik.values.recurrence.end}
                                    onChange={(date) => formik.setFieldValue('recurrence.end', date)}
                                    selectsEnd
                                    startDate={formik.values.recurrence.start}
                                    endDate={formik.values.recurrence.end}
                                    minDate={formik.values.recurrence.start}
                                    isClearable={true}
                                    dateFormat="E dd MMM uuuu"
                                    popperPlacement={"top-end"}
                                    showPopperArrow={false}                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                    </div>}
                    <div className="row align-items-center">
                        {['2', '3'].includes(formik.values.recurrence.type) && <div className={'col-lg-6 mb-3'}>
                            <label htmlFor="" className={'mb-2'}>
                                Jours de la semaine
                            </label>
                            <div className="align-items-center">
                                {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                                    return (
                                        <>
                                            <input
                                                type="checkbox"
                                                onChange={formik.handleChange}
                                                name={'recurrence.daysOfWeek'}
                                                className="btn-check"
                                                id={`btn-check-${index}`}
                                                autoComplete="off"
                                                value={`${index + 1}`}
                                                checked={formik.values.recurrence.daysOfWeek?.includes((index + 1).toString())}
                                            />
                                            <label className="btn btn-sm btn-outline-primary me-2 mb-3" htmlFor={`btn-check-${index}`}>
                                                {day}
                                            </label>
                                        </>
                                    )
                                })}
                                <FormError touched={formik.touched} errors={formik.errors} field={'recurrence.daysOfWeek'} />
                            </div>
                        </div>}
                        {formik.values.recurrence.type === '2' && <div className="col-12 mb-3">
                            <label className={'mb-2'} htmlFor="">
                                Les semaines
                            </label>
                            <div className="align-items-center">
                                {weeks.map((week: string, index: number) => {
                                    return (
                                        <>
                                            <input
                                                type="checkbox"
                                                name={'recurrence.weekLetters'}
                                                onChange={formik.handleChange}
                                                className="btn-check"
                                                id={`btn-check-${week}`}
                                                checked={formik.values.recurrence.weekLetters?.includes((index + 1).toString())}
                                                autoComplete="off" value={`${index + 1}`} />
                                            <label className="btn btn-sm btn-outline-primary me-2 mb-3" htmlFor={`btn-check-${week}`}>
                                                {week}
                                            </label>
                                        </>
                                    )
                                })}
                            </div>
                            <FormError touched={formik.touched} errors={formik.errors} field={'recurrence.weekLetters'} />
                        </div>}
                        {formik.values.recurrence.type === '3' && <>
                            <div className={'col-12 mb-3'}>
                                <div className="input-group">
                        <span className="input-group-text" id="separation-addon">
                             Répeter toutes les
                        </span>
                                    <input
                                        type="number"
                                        name={'recurrence.separationCount'}
                                        onChange={formik.handleChange}
                                        className="form-control"
                                        aria-describedby="separation-addon"
                                        defaultValue={formik.values.recurrence.separationCount}
                                    />
                                    <span className="input-group-text" id="separation-addon">
                            Semaines
                        </span>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
                <button type={'button'} onClick={() => {
                    if (formik.isValid){
                        if (formik.values.recurrence.type > 1){
                            setDisplayConfirmationTypeSelector(true);
                        }else{
                            formik.submitForm();
                        }
                    }else{
                        formik.submitForm();
                    }
                }} className={'btn btn-outline-primary w-100'}>
                    <i className={'bi bi-check-lg me-1'}> </i> Valider
                </button>
            </form>
        </div>
    </>
}

export default BookingEditTest;