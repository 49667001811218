import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import DatePicker from "react-datepicker";

import { User } from "../../../models/auth";
import { ISubscriptionModel } from "../../../models/subscriptions";

import { SUBSCRIPTIONS_MODELS_NORMAL_RATE, SUBSCRIPTIONS_MODELS_REDUCED_RATE } from "../../../constants/subscriptions"

import * as api from "../../../adapters/company";
import * as action from "../../../actions/companies";
import * as authAction from "../../../actions/auth";
import { closeSidebar, submitSidebar } from "../../../actions/rightSidebar";
import { showAlertSuccess } from "../../../actions/alert";

import getUser from "../../../security/getUser";
import validationClass from "../../../utils/validationClass";
import FormError from "../../../utils/formError";
import DateCustomInput from "../../../utils/customInput";
import BootstrapSelect from "../../../utils/bootstrapSelect";

const CompaniesAdd : React.FC = () => {
    const dispatch = useDispatch();

    const user: User = getUser();
    const billingVersion = user.currentSalary?.companyGroup?.billingVersion;
    const billingRate = user.currentSalary?.companyGroup?.billingRate;
    const subscriptionModelsOptions = billingRate === 2 ? SUBSCRIPTIONS_MODELS_REDUCED_RATE : SUBSCRIPTIONS_MODELS_NORMAL_RATE;

    const [model, setModel] = useState<ISubscriptionModel>(billingRate === 2 ? SUBSCRIPTIONS_MODELS_REDUCED_RATE[1] : SUBSCRIPTIONS_MODELS_NORMAL_RATE[1])
    const [initialValues, setInitialValues] = useState({
        title: '',
        tel: '',
        email: '',
        address: {
            city: '',
            address: '',
            zip: '',
        },
        timesheet: {
            openAt: new Date('01 Jan 1970 08:00:00 GMT'),
            closeAt: new Date('01 Jan 1970 20:00:00 GMT'),
            breakStart: null,
            breakFinish: null,
            recurrence: 1,
            currentWeek: 1,
        },
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        title: Yup.string().required('Champs requis'),
        tel: Yup.string().required('Champs requis'),
        email: Yup.string().email().required('Champs requis'),
        address: Yup.object().shape({
            city: Yup.string().required('Champs requis'),
            address: Yup.string().required('Champs requis'),
            zip: Yup.string().length(5).required('Champs requis'),
        }),
        timesheet: Yup.object().shape({
            openAt: Yup.date().required('Champs requis'),
            closeAt: Yup.date().required('Champs requis'),
            breakStart: Yup.date().nullable(),
            breakFinish: Yup.date().nullable(),
            recurrence: Yup.number().required('Champs requis'),
            currentWeek: Yup.number().required('Champs requis'),
        }),
    }))

    useEffect(() => {
        if (billingVersion === 2) {
            formik.setFieldValue("sellsyModelId", model.model_id)
        }
    }, [model])

    useEffect(() => {
        if (billingVersion === 2) {
            setInitialValues(p => ({...p, sellsyModelId: model.model_id}));
            setValidationSchema((p: any) => Yup.object().shape({...p.fields, sellsyModelId: Yup.number().required()}))
        }
    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(submitSidebar());
            api.create(values).then(data => {
                dispatch(action.addCompanySuccess(data.data));
                dispatch(authAction.addAuthCompany(data.data))
                dispatch(showAlertSuccess(`${data.data.title} ajouté !`))
                dispatch(closeSidebar());
            })
        }
    })

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-3">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'title'}
                                id={'title'}
                                type="text"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'title')}/>
                            <label htmlFor="title">
                                <i className={"bi bi-shop text-primary"}> </i> Nom du point de vente <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'tel'}
                                id={'tel'}
                                type="text"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'tel')}/>
                            <label htmlFor="tel">
                                <i className={"bi bi-phone text-primary"}> </i> Tél <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'tel'} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'email'}
                                id={'email'}
                                type="text"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'email')}/>
                            <label htmlFor="email">
                                <i className={"bi bi-envelope text-primary"}> </i>  Email <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'email'} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'address.city'}
                                id={'address.city'}
                                type="text"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'address.city')}/>
                            <label htmlFor="address.city">
                                <i className={"bi bi-geo-alt text-primary"}> </i>  Ville <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'address.city'} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'address.zip'}
                                id={'address.zip'}
                                type="text"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'address.zip')}/>
                            <label htmlFor="address.zip">
                                <i className={"bi bi-123 text-primary"}> </i>  Code postal <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'address.zip'} />
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'address.address'}
                                id={'address.address'}
                                type="text"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'address.address')}/>
                            <label htmlFor="address.address">
                                <i className={"bi bi-house-door text-primary"}> </i>  Adresse <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'address.address'} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <DatePicker
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.openAt')}
                            customInput={<DateCustomInput label={"Ouverture"} icon={'bi bi-clock text-primary'} />}
                            selected={formik.values.timesheet.openAt}
                            onChange={(date: Date) => formik.setFieldValue('timesheet.openAt', date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Ouverture"
                            dateFormat="HH:mm"
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.openAt'} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <DatePicker
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.closeAt')}
                            customInput={<DateCustomInput label={'Fermeture'} icon={'bi bi-clock-fill text-primary'} />}
                            selected={formik.values.timesheet.closeAt}
                            onChange={(date: Date) => formik.setFieldValue('timesheet.closeAt', date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Fermeture"
                            dateFormat="HH:mm"
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.closeAt'} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <DatePicker
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.breakStart')}
                            customInput={<DateCustomInput label={"Début de pause"} icon={'bi bi-clock text-primary'} />}
                            selected={formik.values.timesheet.breakStart}
                            onChange={(date: Date) => formik.setFieldValue('timesheet.breakStart', date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Début de pause"
                            isClearable={true}
                            dateFormat="HH:mm"
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.breakStart'} />
                    </div>

                    <div className="col-md-6 mb-3">
                        <DatePicker
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.breakFinish')}
                            customInput={<DateCustomInput label={'Fin de pause'} icon={'bi bi-clock-fill text-primary'} />}
                            selected={formik.values.timesheet.breakFinish}
                            onChange={(date: Date) => formik.setFieldValue('timesheet.breakFinish', date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Fin de pause"
                            isClearable={true}
                            dateFormat="HH:mm"
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.breakFinish'} />
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'timesheet.recurrence'}
                                id={'timesheet.recurrence'}
                                defaultValue={1}
                                type="number"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.recurrence')}/>
                            <label htmlFor="timesheet.recurrence">
                                <i className={"bi bi-arrow-repeat text-primary"}> </i>  Recurrence <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.recurrence'} />
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="form-floating">
                            <input
                                placeholder={' '}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={'timesheet.currentWeek'}
                                id={'timesheet.currentWeek'}
                                defaultValue={1}
                                type="number"
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'timesheet.currentWeek')}/>
                            <label htmlFor="timesheet.currentWeek">
                                <i className={"bi bi-check text-primary"}> </i> Semaine en cours <span className="required">*</span>
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'timesheet.currentWeek'} />
                    </div>

                    {billingVersion === 2 &&
                        <>
                            <h6>
                                Facturation
                            </h6>
                            <p className={"form-text"}>
                                Chaque nouvel abonnement inclut 3 formations offertes
                            </p>
                            <div className="col-12 mb-3">
                                <BootstrapSelect
                                    label={"Modèle d'abonnement"}
                                    required={true}
                                    value={{label: `De ${model.min} à ${model.max} employés, ${model.amount.toFixed(2)}€ HT par mois`, value: ""}}
                                    enableReinitialize={true}
                                    options={subscriptionModelsOptions.map((m, key) => ({label: `De ${m.min} à ${m.max} employés, ${m.amount.toFixed(2)}€ HT par mois`, value: key}))}
                                    onChange={c => setModel(subscriptionModelsOptions[Number(c.value)])}
                                />
                            </div>
                        </>
                    }
                </div>

                <button type={"submit"} className={'btn btn-outline-primary w-100'}>
                    <i className={'bi bi-plus-circle'}> </i> Ajouter
                </button>
            </form>
        </>
    )
}

export default CompaniesAdd;