import React, {useEffect, useState} from "react";
import {Salary} from "../../../../../models/salary";
import {SingleSalarySelected, SingleSalarySelector} from "../../../../../utils/salarySelector/single";
import {SingleCompanySelected} from "../../../../../utils/companySelector/single";
import Loader from "../../../../../utils/loader";
import {SalaryTemplate, SalaryTemplates} from "../../../../../models/salary/template";
import * as api from "../../../../../adapters/salary/template";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import {addSalaryTemplateSuccess} from "../../../../../actions/salary/template";
import {SHOW_SALARY_TEMPLATE} from "../../../../../constants/rightSidebar";


const SalaryTemplateCopy:React.FC = () => {

    const [salary, setSalary] = useState<Salary>()
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState<SalaryTemplates>([]);
    const state = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();


    useEffect(() => {
        if (salary){
            setTemplates([]);
            setLoading(true);
            api.list(salary.id).then((data) => {
                setTemplates(data.data)
                setLoading(false);
            })
        }
    }, [salary])

    const copy = (t: SalaryTemplate) => {
        dispatch(submitSidebar());
        api.copy(t.id, state.rightSidebar.data.target.id).then(resp => {
            dispatch(addSalaryTemplateSuccess(resp.data));
            if (state.rightSidebar.data?.next){
                dispatch(openSidebar(state.rightSidebar.data.next, state.rightSidebar.data.data))
            }else{
                dispatch(closeSidebar())
            }
        })
    }

    if (!salary || isOpen) return <SingleSalarySelector required={true} onSubmit={(s) => setSalary(s)} onClose={() => setIsOpen(false)} />

    return <div className={"p-1 p-md-3"}>
        <SingleSalarySelected initialValue={salary.id} initialSalary={salary} onDelete={() => setSalary(undefined)} onOpen={() => setIsOpen(true)} />
        <div className="col-12 position-relative">
            {loading && <Loader />}
            {!loading && salary && !templates.length && <h4> <i className={'bi bi-search'}></i> Aucun template disponible</h4>}
            {!loading && salary && !!templates.length && <>
                <ul className={'list-group list-group-flush'}>
                    {templates.map(t => <li className={'list-group-item list-group-item-action'} onClick={() => copy(t)}>
                        {t.title}
                    </li>)}
                </ul>
            </>}
        </div>
    </div>
}

export default SalaryTemplateCopy;