import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../../../actions/salary";
import * as teamActions from "../../../../../actions/team";
import * as api from "../../../../../adapters/team";
import {list} from "../../../../../adapters/salary";
import {axiosError} from "../../../../../actions/axios";
import {Salaries, Salary} from "../../../../../models/salary";
import {Team} from "../../../../../models/team";
import {useFormik} from "formik";
import * as Yup from "yup";
import {closeSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import FormError from "../../../../../utils/formError";
import {PAGINATION} from "../../../../../constants/global";

const TeamShowSalarySelector:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny)  => state.salary);
    const team: Team = useSelector((state: RootStateOrAny)  => state.rightSidebar.data);
    const formik = useFormik({
        initialValues: {salaries: team.salaries.map(s => String(s.id))},
        validationSchema: Yup.object().shape({
            salaries: Yup.array().of(Yup.number()).required()
        }),
        onSubmit: (values) => {
            dispatch(submitSidebar());
            api.setSalaries(values, team.id)
                .then(data => {
                    dispatch(teamActions.editTeamSuccess(data.data))
                    dispatch(closeSidebar());
                })
        }
    })

    const [offset, setOffset] = useState<number>(0);
    const [jobs, setJobs] = useState<number[]>([]);
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [query, setQuery] = useState<string|undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFiltering, setFiltering] = useState<boolean>(false);


    useEffect(() => {

        dispatch(actions.fetchSalaries())
        setIsLoading(true);

        list({query: query, offset: 0, jobs: jobs}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            dispatch(actions.fetchSalariesSuccess(data.data))
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => dispatch(axiosError(error)))
        return () => {

        }
    }, [jobs])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setFiltering(true);
                setOffset(0)
                list({query: query, offset: 0, jobs: jobs}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchSalariesSuccess(data.data))
                    setFiltering(false);
                    setOffset( 1);
                }).catch(error => dispatch(axiosError(error)))
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if(scrollBottom && offset && !isLast){
            setIsLoading(true)
            list({query: query, offset: offset, jobs: jobs}).then(data => {
                setIsLast(data.data.length < PAGINATION);
                dispatch(actions.fetchSalariesSuccess([...state.payload, ...data.data]))
                setIsLoading(false)
                setOffset(prevState => prevState + 1)
                setScrollBottom(false)
            }).catch(error => dispatch(axiosError(error)))
        }

        let container = document.getElementById('choices')!
        container.addEventListener("scroll", onScrollBottom)

        return () => container.removeEventListener("scroll", onScrollBottom)

    }, [scrollBottom])

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }
    
    return <form onSubmit={formik.handleSubmit}>
        <div className="w-100 sticky-top bg-white p-2">
            <div className="row">
                <div className="col">
                    <div className="form-floating">
                        <input type="search" id="search" onChange={(e) => {
                            setQuery(e.target.value)
                        }} className="form-control" placeholder="Rechercher un collaborateur" aria-describedby="search-addon" />
                        <label htmlFor={'search'}>
                            {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : <i className="bi bi-search"> </i>} Rechercher un collaborateur
                        </label>
                    </div>
                </div>
                <div className="col-auto">
                    <BootstrapSelect
                        label={'Métiers'}
                        isMultiple={true}
                        fetchEntity={'job'}
                        onChangeMultiple={(choices) => setJobs(choices.map(c => Number(c.value)))}
                    />
                </div>
            </div>
            <FormError errors={formik.errors} touched={formik.touched} field={'salaries'} />
        </div>
        <ul id={'choices'} className={'list-group list-group-flush'}>
            {state.payload.map((salary: Salary, index: number) => <li className={'list-group-item'}>
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1 d-flex align-items-center">
                        <div className="me-2">
                            <div className="color-circle col-auto">
                                <div className="shadow-sm" style={{backgroundColor: `${salary.information?.job.color}`, opacity: 0.8}}> </div>
                            </div>
                        </div>
                        <label htmlFor={`salary${index}`} className="flex-grow-1">
                            {salary.title}
                            <div className="clearfix"> </div>
                            <span className={"text-light-s"}>
                                {salary.information?.job.title}
                            </span>
                        </label>
                    </div>
                    <div className="flex-grow-0">
                        <input className="form-check-input float-end"
                               defaultChecked={formik.values.salaries.includes(String(salary.id))}
                               onChange={formik.handleChange}
                               name={'salaries'}
                               type="checkbox"
                               value={salary.id}
                               id={`salary${index}`}
                        />
                    </div>
                </div>
            </li>)}
        </ul>
        <div className="position-sticky w-100 bg-white p-2" style={{
            bottom: 0
        }}>
            <button type={'submit'} className={'btn btn-outline-primary w-100'}>
                Valider
            </button>
        </div>
    </form>
}

export default TeamShowSalarySelector;
