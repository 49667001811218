import * as types from "../../../constants/template/applied"
import * as resourceTypes from "../../../constants/template/applied/resource"
import * as models from "../../../models/template/applied"
import replace from "../../../utils/arrayReplace"

const templateApplied = (state: models.initialStateInterface = models.initialState, action:
    {type: string, template: models.TemplateApplied, templates: models.TemplateApplies, error: string, [key: string] : any}
) => {
    switch (action.type) {
        case types.FETCH_TEMPLATE_APPLIES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_TEMPLATE_APPLIES_SUCCESS:
            return { payload: action.templates, single: state.single, isLoading: false, error: null };
        case types.FETCH_TEMPLATE_APPLIES_FAILURE:
            return { payload: action.templates, single: state.single, isLoading: false, error: action.error };

        case types.ADD_TEMPLATE_APPLIED:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_TEMPLATE_APPLIED_SUCCESS:
            return { payload: [...state.payload, action.template], single: action.template, isLoading: false, error: null };

        case types.EDIT_TEMPLATE_APPLIED:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_TEMPLATE_APPLIED_SUCCESS:
            return { payload:  replace(state.payload, action.template), single: action.template, isLoading: false, error: null };

        case types.SHOW_TEMPLATE_APPLIED:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_TEMPLATE_APPLIED_SUCCESS:
            return { payload: state.payload, single: action.template, isLoading: false, error: null };
        case types.SHOW_TEMPLATE_APPLIED_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        case resourceTypes.ADD_APPLIED_RESOURCE_SUCCESS:
            state.single!.appliedResources = [...state.single!.appliedResources!, action.resource]
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };
        case resourceTypes.EDIT_APPLIED_RESOURCE_SUCCESS:
            state.single!.appliedResources = replace(state.single!.appliedResources!, action.resource)
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default templateApplied;