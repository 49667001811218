import {useFormik} from "formik";
import * as Yup from "yup";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {Template} from "../../../../models/template";
import React from "react";
import * as api from "../../../../adapters/template";

const TemplateSchedulerForm = ({template, start, end, fetch} : {template: Template, start: Date, end: Date, fetch: (props: any) => void;}) => {

    const formik = useFormik({
       initialValues: {
           target: "localisation",
           targetId: "",
           breakDuration: 1,
           shiftDuration: 5,
           resources: []
       } as any,
       validationSchema:  Yup.object().shape({
           target: Yup.string().required(),
           targetId: Yup.number().required(),
           breakDuration: Yup.number().required(),
           shiftDuration: Yup.number().required(),
           resources: Yup.array().of(Yup.number()).min(1).required(),
       }),
        onSubmit: (values, formikHelpers) => {
           api.schedule({...values, start: start, end: end}, template.id)
               .then(() => fetch({}))}
    });

    return <>
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasTemplateSchedulerForm"
             aria-labelledby="offcanvasTemplateSchedulerFormLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasTemplateSchedulerFormLabel">Scheduler</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            required={true}
                            options={[
                                {label: "Emplacement", value: "localisation"},
                                {label: "Activité", value: "activity"},
                            ]}
                            label={"Type de référence"}
                            onChange={c => formik.setFieldValue("target", c?.value)}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            enableReinitialize={true}
                            required={true}
                            fetchEntity={formik.values.target === "localisation" ? "localisation" : "activity"}
                            fetchParams={{
                                company: template.company.id,
                            }}
                            label={formik.values.target === "localisation" ? "Emplacement" : "Activité"}
                            onChange={c => formik.setFieldValue("targetId", c?.value)}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type="number" className="form-control" defaultValue={1} id="breakDuration" name="breakDuration" onChange={formik.handleChange}/>
                            <label htmlFor="breakDuration">Temps de pause maximum</label>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input type="number" className="form-control" defaultValue={5} id="shiftDuration" name="shiftDuration" onChange={formik.handleChange}/>
                            <label htmlFor="shiftDuration">Temps de travail minimum</label>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <h6>
                            Séléctionnez le(s) collaborateur(s) à inclure
                        </h6>
                        <ul className={'list-unstyled overflow-auto'} style={{maxHeight: 400}}>
                            {template.resources.map(r => <li>
                                <div className="form-check">
                                    <input type="checkbox" onChange={formik.handleChange} name={"resources"} id={`resources_${r.id}`} value={r.id} className="form-check-input"/>
                                    <label htmlFor={`resources_${r.id}`}>
                                        {r.title}
                                    </label>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                    <button className="btn btn-light w-100">
                        <i className={'bi bi-check'}></i> Valider
                    </button>
                </form>
                <pre>
                    {JSON.stringify(formik.errors, null, 3)}
                </pre>
            </div>
        </div>
    </>
}

export default TemplateSchedulerForm;