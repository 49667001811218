import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/job";
import * as models from "../../../../models/booking/default";
import format from "../../../../utils/locale";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {axiosError} from "../../../../actions/axios";
import Loader from "../../../../utils/loader";
import {openSidebar} from "../../../../actions/rightSidebar";
import {
    FORM_EDIT_DEFAULT_BOOKING,
    FORM_NEW_BOOKING,
    FORM_NEW_DEFAULT_BOOKING,
    LIST_DEFAULT_BOOKING
} from "../../../../constants/rightSidebar";
import {Job} from "../../../../models/job";
import {DefaultBooking} from "../../../../models/booking/default";
import {setHours, setMinutes} from "date-fns";
import {fetchJobsSuccess, showJobSuccess} from "../../../../actions/job";
import BootstrapSelect from "../../../../utils/bootstrapSelect";

const BookingDefaultSidebarList:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
            api.list().then(data => {
                dispatch(fetchJobsSuccess(data.data));
                dispatch(showJobSuccess(data.data.find((job: Job) => job.id === state.rightSidebar.data.job.id)));
                setIsLoading(false);
            }).catch(error => dispatch(axiosError(error)))
    }, [])

    if (isLoading){
        return <Loader />
    }

    const getDaysArray = (s: Date,e: Date) => {
        let a: Date[] = [];
        let d: Date = new Date(s);

        for(a; d <= e; d.setDate(d.getDate()+1))
        {
            a.push(new Date(d));
        }
        return a;
    };

    const addDefaultBooking = (booking: DefaultBooking) => {

        let start: Date = state.rightSidebar.data.start;
        let end: Date = state.rightSidebar.data.end;
        let days = getDaysArray(start, end)
        let beginAt = new Date(booking.beginAt);
        let endAt = new Date(booking.endAt);

        let _s = days.find(day => day.getDay() === beginAt.getDay())!;
        let _e = days.find(day => day.getDay() === endAt.getDay())!;

        _s = setHours(setMinutes(_s, beginAt.getMinutes()), beginAt.getHours());
        _e = setHours(setMinutes(_e, endAt.getMinutes()), endAt.getHours());

        dispatch(openSidebar(FORM_NEW_BOOKING, {
            salary: state.rightSidebar.data.salary,
            beginAt: _s,
            endAt: _e,
            company: state.rightSidebar.data.company,
            recurrence: {
                type: booking.recurrence.type,
                daysOfWeek: booking.recurrence.daysOfWeek
            },
            next: LIST_DEFAULT_BOOKING,
            data: {...state.rightSidebar.data}
        }))
    }

    return <div className={'p-1 p-md-3'}>
        <div className="d-flex mb-3">
            <div className="flex-grow-1">
                <BootstrapSelect
                    notFloating={true}
                    value={{label: state.job.single.title, value: state.job.single.id!}}
                    options={state.job.payload.map((job: Job) => ({label: job.title, value: job.id!}))}
                    required={true}
                    onChange={(choice) => dispatch(showJobSuccess(state.job.payload.find((job: Job) => job.id === choice?.value)))}
                />
            </div>
            <div className="flex-grow-0 ps-2">
                <button onClick={() => dispatch(openSidebar(FORM_NEW_DEFAULT_BOOKING, {
                    job: state.job.single,
                    next: LIST_DEFAULT_BOOKING,
                    data: {
                        job: state.job.single
                    }
                }))} className={'btn btn-outline-primary'}>
                    <i className={'bi bi-plus'}> </i> Ajouter
                </button>
            </div>
        </div>
        {!!state.job.single.defaultBookings.length ? <ul className="list-group list-group-flush">
            {state.job.single.defaultBookings.map((booking: DefaultBooking) => <li className="list-group-item">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <h4>
                            {booking.title}
                        </h4>
                        <p>
                            <i className={'bi bi-calendar-check text-primary'}> </i> {format(new Date(booking.beginAt), 'E HH:mm')}
                            <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(booking.endAt), 'E HH:mm')}
                        </p>
                        <p>
                            <i className={'bi bi-arrow-repeat text-primary'}> </i> {booking.recurrence.description}
                        </p>
                    </div>
                    <div className="flex-grow-0">
                        <div className="mb-3 w-100 text-end">
                            <button onClick={() => dispatch(openSidebar(FORM_EDIT_DEFAULT_BOOKING, {
                                booking: booking,
                                job: state.job.single,
                                next: LIST_DEFAULT_BOOKING,
                                data: {
                                    job: state.job.single
                                }
                            }))}
                                    className={'btn text-primary'}>
                                <i className={'bi bi-pencil'}> </i>
                            </button>
                            <button className={'btn text-danger'}>
                                <i className={'bi bi-dash-circle'}> </i>
                            </button>
                        </div>
                        <button onClick={() => addDefaultBooking(booking)} className={'btn btn-outline-success'}>
                            <i className={'bi bi-check'}> </i> Séléctionner
                        </button>
                    </div>
                </div>
            </li>)}
        </ul> : <><i className={'bi bi-search'}> </i> Aucun créneau type pour "{state.job.single.title}"</>}
    </div>
}

export default BookingDefaultSidebarList;