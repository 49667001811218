import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/job";
import format from "../../../../utils/locale";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {axiosError} from "../../../../actions/axios";
import Loader from "../../../../utils/loader";
import {Job} from "../../../../models/job";
import {DefaultBooking, DefaultBookings} from "../../../../models/booking/default";
import {setHours, setMinutes} from "date-fns";
import {fetchJobsSuccess, showJobSuccess} from "../../../../actions/job";
import BootstrapSelect from "../../../../utils/bootstrapSelect";

const BookingDefaultPopupList:React.FC<{job: Job, _start: Date, _end: Date, onSelect: (e: {
        beginAt: Date,
        endAt: Date,
        recurrence: {
            daysOfWeek?: string[]
        },
    }) => void;}> = (props) => {

    const {job, _start, _end, onSelect} = props;
    const state = useSelector((state: RootStateOrAny) => state);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        api.list().then(data => {
            dispatch(fetchJobsSuccess(data.data));

            dispatch(showJobSuccess(data.data.find((j: Job) => j.id === job.id)));
            setIsLoading(false);
        }).catch(error => dispatch(axiosError(error)))
    }, [])

    const getDaysArray = (s: Date,e: Date) => {
        let a: Date[] = [];
        let d: Date = new Date(s);

        for(a; d <= e; d.setDate(d.getDate()+1))
        {
            a.push(new Date(d));
        }
        return a;
    };

    const selectDefaultBooking = (booking: DefaultBooking) => {

        let days = getDaysArray(_start, _end)
        let beginAt = new Date(booking.beginAt);
        let endAt = new Date(booking.endAt);

        let _s = days.find(day => day.getDay() === beginAt.getDay())!;
        let _e = days.find(day => day.getDay() === endAt.getDay())!;

        _s = setHours(setMinutes(_s, beginAt.getMinutes()), beginAt.getHours());
        _e = setHours(setMinutes(_e, endAt.getMinutes()), endAt.getHours());

        onSelect({
            beginAt: _s,
            endAt: _e,
            recurrence: {
                daysOfWeek: booking.recurrence.daysOfWeek
            },
        })
    }

    return <div className="modal fade" id="defaultBookingModal" aria-labelledby="exampleModalLabel" aria-hidden="true" tabIndex={-1}>
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable shadow">
            <div className="modal-content h-100">
                <div className="modal-header">
                    <h5 className="modal-title me-2">Créneaux type</h5>
                    <BootstrapSelect
                        notFloating={true}
                        value={{label: job.title, value: job.id!}}
                        options={state.job.payload.map((j: Job) => ({label: j.title, value: j.id!}))}
                        required={true}
                        onChange={(choice) => dispatch(showJobSuccess(state.job.payload.find((j: Job) => j.id === choice?.value)))}
                    />
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div className="modal-body">
                    {isLoading ? <Loader /> : (!!state.job.single.defaultBookings.length ? <ul className="list-group list-group-flush">
                        {state.job.single.defaultBookings.filter((db: DefaultBooking) => String(db.recurrence.type) === "2").map((booking: DefaultBooking) => <li className="list-group-item">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <h4>
                                        {booking.title}
                                    </h4>
                                    <p>
                                        <i className={'bi bi-calendar-check text-primary'}> </i> {format(new Date(booking.beginAt), 'E HH:mm')}
                                        <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(booking.endAt), 'E HH:mm')}
                                    </p>
                                    <p>
                                        <i className={'bi bi-arrow-repeat text-primary'}> </i> {booking.recurrence.description}
                                    </p>
                                </div>
                                <div className="flex-grow-0">
                                    <button onClick={() => selectDefaultBooking(booking)} data-bs-dismiss="modal" className={'btn btn-outline-success'}>
                                        <i className={'bi bi-check'}> </i> Séléctionner
                                    </button>
                                </div>
                            </div>
                        </li>)}
                    </ul> : <><i className={'bi bi-search'}> </i> Aucun créneau type pour "{state.job.single.title}"</>)}
                </div>
            </div>
        </div>
    </div>
}

export default BookingDefaultPopupList;