import React, {useEffect, useState} from "react";
import TaskAddForm from "../../../task/add/form";
import {Salary} from "../../../../models/salary";
import {setMinutes} from "date-fns";

const TaskAdd:React.FC<{close: () => any, handleSubmitSuccess: (ev: any[]) => any, salary?: Salary}> = (props) => {

    const {handleSubmitSuccess, salary, close} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [salary])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarTaskAddLabel">Ajouter une tâche</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <TaskAddForm
                key={key}
                start={setMinutes(new Date(), 0)}
                end={setMinutes(new Date(), 30)}
                salaries={salary ? [salary] : []}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default TaskAdd;