import React, { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import FileSaver from "file-saver";
import EmptyList from "../../../../common/EmptyList";
import SalaryFileListItem from "../../../common/SalaryFileListItem";

import { Salary, SalaryFiles } from "../../../../../models/salary";

import { Granted } from "../../../../../security/granted";
import * as accessRights from "../../../../../constants/accessRight";

import * as fileApi from "../../../../../adapters/salary/file";
import { axiosError } from "../../../../../actions/axios";

import Loader from "../../../../../utils/loader";
import { showAlertDanger, showAlertSuccess } from "../../../../../actions/alert";

interface IProps {
    files: SalaryFiles;
    fetchSalaryFilesList: () => void;
}

const SalaryDocumentStocked: React.FC<IProps> = ({ files, fetchSalaryFilesList }) => {
    const dispatch = useDispatch();

    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single);

    const [downloading, setDownloading] = useState<number>();
    const [deleting, setDeleting] = useState<number>();
    const [fileUrl, setJustificatifUrl] = useState<string>()
    const [loadingFile, setLoadingFile] = useState<boolean>(false)

    const displayFile = (id: number) => {
        if (!salary) return false;

        let file = files.find((file => file.id === id))
        if (file) {
            setLoadingFile(true)
            fileApi.download(salary.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                let url = URL.createObjectURL(newBlob);
                setJustificatifUrl(url)
                setLoadingFile(false)
            }).catch(error => dispatch(axiosError(error)))
        } else {
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const downloadFile = (id: number) => {
        if (!salary) return false;

        let file = salary.files?.find((file => file.id === id))
        if (file) {
            setDownloading(id);
            fileApi.download(salary.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                FileSaver.saveAs(newBlob, file!.title)
                setDownloading(undefined)
            }).catch(error => dispatch(axiosError(error)))
        } else {
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const deleteFile = (id: number) => {
        if (!salary) return false;

        let file = salary.files?.find((file => file.id === id))
        if (file) {
            setDeleting(id);
            fileApi.remove(salary.id!, id).then(data => {
                let files = [...salary.files!]
                files.splice(files.findIndex(file => file.id === id), 1);
                salary.files = [...files];
                setDeleting(undefined)
                dispatch(showAlertSuccess('Fichier supprimé'))
                fetchSalaryFilesList()
            }).catch(error => dispatch(axiosError(error)))
        } else {
            dispatch(showAlertDanger('Une erreur est survenue lors de la supression ! Fichier introuvable.'))
        }
    }

    return (
        <>
            <div>
                {files.length === 0 && <EmptyList />}
                {files.map((file, index) => (
                    <SalaryFileListItem
                        key={index}
                        data={file}
                        onDisplay={() => displayFile(file.id)}
                        onDownload={() => downloadFile(file.id)}
                        downloading={downloading === file.id}
                        onDelete={Granted(accessRights.EDIT_SALARY, salary.id) ? () => deleteFile(file.id) : undefined}
                        deleting={deleting === file.id}
                    />
                ))}
            </div>

            <div className="modal" id="fileModal" tabIndex={-1}>
                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content h-100">
                        <div className="modal-body d-flex flex-column" id={'#modalBody'}>
                            {loadingFile && <Loader />}
                            {!loadingFile && fileUrl && <iframe src={fileUrl} width={'100%'} className={'flex-grow-1'} frameBorder={0}>
                            </iframe>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalaryDocumentStocked;