import React from 'react';

import { SalaryFile } from "../../../models/salary";

interface IProps {
  data: SalaryFile;
  onDisplay?: () => void;
  onDownload?: () => void;
  downloading?: boolean;
  onDelete?: () => void;
  deleting?: boolean;
}

const SalaryFileListItem: React.FC<IProps> = ({ data, onDisplay, onDownload, downloading, onDelete, deleting }) => {
  return (
    <div className="container-fluid mb-1 py-2 border rounded">
      <div className="row align-items-center">
        <div className="col">
          {data?.title}
        </div>

        <div className="col d-flex justify-content-end">
          {onDisplay !== undefined &&
            <button
              type="button"
              className="btn btn-light me-2"
              data-bs-toggle="modal"
              data-bs-target="#fileModal"
              onClick={onDisplay}
            >
              <i className={'bi bi-eye'}></i>
            </button>
          }

          {onDownload !== undefined &&
            <button className={"btn btn-light me-2"} onClick={onDownload}>
              {downloading ?
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <i className="bi bi-download text-primary"> </i>
              }
            </button>
          }

          {onDelete !== undefined &&
            <button className={"btn btn-light me-2"} onClick={onDelete}>
              {deleting ?
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <i className="bi bi-dash-circle text-danger"> </i>
              }
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default SalaryFileListItem