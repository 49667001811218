import React from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {create} from "../../../adapters/job";
import {useDispatch} from "react-redux";
import * as actions from "../../../actions/job";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import JobForm from "../utils/form";
import {axiosError} from "../../../actions/axios";


export const JobAdd: React.FC = () => {

    const dispatch = useDispatch();

    const JobSchema = Yup.object().shape({
        title: Yup.string().required('Veuillez préciser un titre'),
        type: Yup.string().required('Veuillez préciser un type de métier'),
        color: Yup.string().required('Veuillez séléctionner une couleur'),
    });

    return (
        <div className={"p-3"}>
            <Formik
                initialValues={{
                    title: '',
                    type: '',
                    color: '',
                }}
                validationSchema={JobSchema}
                onSubmit={values => {
                    dispatch(submitSidebar());
                    create(values).then(data => {
                        dispatch(actions.addJobSuccess(data.data))
                        dispatch(closeSidebar())
                        dispatch(showAlertSuccess(`Métier ${data.data.title} ajouté !`))
                    }).catch(error => {
                        dispatch(axiosError(error))
                    })
                }}
            >
                {({ errors, touched, values }) => (
                    <JobForm errors={errors} touched={touched} values={values} />
                )}
            </Formik>
        </div>
    );

}

export default JobAdd;