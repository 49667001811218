import React, {useEffect, useState} from "react";
import format from "../../../../utils/locale";
import * as actions from "../../../../actions/synthesis"
import * as api from "../../../../adapters/calendar"
import * as counterApi from "../../../../adapters/counter"
import * as paidApi from "../../../../adapters/paid"
import * as reporting from "../../../../adapters/reporting"
import * as withdrawnApi from "../../../../adapters/counter/withdrawn"
import * as calendarTypes from "../../../../constants/calendar"
import {Salary} from "../../../../models/salary";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Synthesis, SynthesisWeek} from "../../../../models/synthesis";
import timeFormat from "../../../../utils/timeFormat";
import {getMonthOption} from "../../../../constants/global";
import SmallLoader from "../../../../utils/loader/small";
import SynthesisSignature from "../../../synthesis/signature";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Modal} from "bootstrap";
import FormError from "../../../../utils/formError";
import * as cgApi from "../../../../adapters/companyGroup";
import {editUserCompanyGroupSuccess} from "../../../../actions/auth";
import DatePicker from "react-datepicker";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import {APPLICATION_PDF} from "../../../../adapters/xhr";
import FileSaver from "file-saver";
import {Granted} from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight";
import {openSidebar} from "../../../../actions/rightSidebar";
import {COUNTER_SETTINGS} from "../../../../constants/rightSidebar";
import { showAlertDanger } from "../../../../actions/alert";

const SalaryCounterV2:React.FC = () => {

    let today = new Date();
    let isOvertimeMarkUp = useSelector((state: RootStateOrAny) => state.auth.user.currentSalary.companyGroup.accountingSettings.overtimeMarkUp)

    const dispatch = useDispatch();
    const [year, setYear] = useState(today.getFullYear());
    const [month, setMonth] = useState(today.getMonth() + 1);

    let firstDay = new Date(year, month - 1, 1);
    let lastDay = new Date(year, month, 1);


    const state = useSelector((state: RootStateOrAny) => state)
    const hasChanged = useSelector((state: RootStateOrAny) => state.hasChanged)
    let salary: Salary = state.salary.single;
    let synthesis: Synthesis = state.synthesis.single;
    let loading: boolean = state.synthesis.isLoading;
    const [modal, setModal] = useState<Modal>()
    const [paidModal, setPaidModal] = useState<Modal>()
    const [downloadModal, setDownloadModal] = useState<Modal>()
    const [withdrawnModal, setWithdrawnModal] = useState<Modal>()


    useEffect(() => {
        setModal(new Modal(document.getElementById('counterEditModal')!))
        setPaidModal(new Modal(document.getElementById('paidModal')!))
        setDownloadModal(new Modal(document.getElementById('downloadModal')!))
        setWithdrawnModal(new Modal(document.getElementById('withdrawnModal')!))
    }, [])

    const [currentWeek, setCurrentWeek] = useState<SynthesisWeek>();

    useEffect(() => {
        fetchCounters();
    }, [salary, year, month, hasChanged, isOvertimeMarkUp])

    function handleOvertimeMarkUpChange(checked: boolean){
        cgApi.accountingSetting({
            setting: "overtime_mark_up",
            value: checked
        }).then((data) => {
            dispatch(editUserCompanyGroupSuccess(data.data))
        })
    }

    function saveCounters(counters: {wn: number, year: number, value: number}[])
    {
        dispatch(actions.showSynthesis())
        counterApi.saveMany(salary.id, counters)
            .then(resp => fetchCounters());
    }

    function clearCounters(counters: {wn: number, year: number}[])
    {
        dispatch(actions.showSynthesis())
        counterApi.clearMany(salary.id, counters)
            .then(resp => fetchCounters());
    }

    const [initialValues, setInitialValues] = useState<any>({
        year: null,
        weekNumber: null,
        signe: null,
        hours: null,
        minutes: null
    })

    const [initialWithdrawnValues, setInitialWithdrawnValues] = useState<any>({
        year: null,
        weekNumber: null,
        description: null,
        hours: null,
        minutes: null
    })

    const [paidInitialValues, setPaidInitialValues] = useState<{
        year: number,
        month: number,
        hours: number,
        minutes: number,
        maj: "none"|"low"|"high"
    }>({
        year: year,
        month: month,
        hours: 0,
        minutes: 0,
        maj: "none"
    })


    const [downloadInitialValues, setDownloadInitialValues] = useState<any>({
        start: firstDay,
        end: lastDay
    })

    const [updateForm, setUpdateForm] = useState<number>(0)

    useEffect(() => {
        if (currentWeek){
            let absValue = Math.abs(currentWeek.counterSaved)
            let hours = Math.floor(absValue / 3600)
            let minutes = Math.floor((absValue - (hours * 3600)) / 60)

            setInitialValues({
                year: currentWeek.year,
                weekNumber: currentWeek.weekNumber,
                signe: currentWeek.counterSaved >= 0 ? '+' : '-',
                hours: hours,
                minutes: minutes
            })

            let withdrawnAbsValue = Math.abs(currentWeek.counterWithdrawn);
            let withdrawnHours = Math.floor(withdrawnAbsValue / 3600)
            let withdrawnMinutes = Math.floor((withdrawnAbsValue - (withdrawnHours * 3600)) / 60)

            setInitialWithdrawnValues({
                year: currentWeek.year,
                weekNumber: currentWeek.weekNumber,
                description: currentWeek.counterWithdrawnDescription,
                hours: withdrawnHours,
                minutes: withdrawnMinutes
            })

            setUpdateForm(prev => prev + 1)
        }else{
            setInitialValues({
                year: null,
                weekNumber: null,
                signe: null,
                hours: null,
                minutes: null
            })
        }
    }, [currentWeek])

    useEffect(() => {
        if (synthesis) {
            let paid = synthesis.paid;

            switch (paidInitialValues.maj){
                case "none":
                    paid = synthesis.paid;
                    break;
                case "low":
                    paid = synthesis.paidLowMajoration;
                    break;
                case "high":
                    paid = synthesis.paidHighMajoration;
                    break;
            }

            let absValue = Math.abs(paid)
            let hours = Math.floor(absValue / 3600)
            let minutes = Math.floor((absValue - (hours * 3600)) / 60)

            setPaidInitialValues(p => ({
                ...p,
                year: year,
                month: month,
                hours: hours,
                minutes: minutes,
            }))

            setUpdateForm(prev => prev + 1)
        }
    }, [salary, paidInitialValues.maj, year, month])

    useEffect(() => {
        let firstDay = new Date(year, month - 1, 1);
        let lastDay = new Date(year, month, 1);

        setDownloadInitialValues({
            salary: salary.id,
            start: firstDay,
            end: lastDay
        })

        setUpdateForm(prev => prev + 1)
    }, [salary, year, month])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            year: Yup.number().required(),
            weekNumber: Yup.number().required(),
            signe: Yup.string().required(),
            hours: Yup.number().nullable(),
            minutes: Yup.number().nullable()
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            let _value = ((values.hours || 0) * 3600) + ((values.minutes || 0) * 60);
            _value = values.signe === "+" ? _value : 0 - _value;
            editWeek({year: values.year, wn: values.weekNumber, value: _value})
        }
    })

    const formikPaid = useFormik({
        initialValues: paidInitialValues,
        validationSchema: Yup.object().shape({
            year: Yup.number().required(),
            month: Yup.number().required(),
            hours: Yup.number().nullable(),
            minutes: Yup.number().nullable(),
            maj: Yup.string().required()
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            let _value = ((values.hours || 0) * 3600) + ((values.minutes || 0) * 60);
            addPaid({year: values.year, month: values.month, value: _value, maj: values.maj})
        }
    })

    const formikDownload = useFormik({
        initialValues: downloadInitialValues,
        validationSchema: Yup.object().shape({
            start: Yup.date().required(),
            end: Yup.date().required(),
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            reporting.exportSignature({salary: salary.id, start: format(values.start, 'uuuu-MM-dd'), end: format(values.end, 'uuuu-MM-dd')}, {headers: [APPLICATION_PDF], responseType: 'arraybuffer'})
                .then(resp => {
                    let newBlob = new Blob([resp.data], { type: 'application/pdf;charset=utf-8' })
                    FileSaver.saveAs(newBlob, `emargements-${salary.title}.pdf`)
                })
                .then(() => formikHelpers.setSubmitting(false))
                .then(() => downloadModal?.hide())
                .catch((error) => {
                    dispatch(showAlertDanger('Une erreur est survenue pendant l’export du fichier'))
                })
        }
    })

    const formikWithdrawn = useFormik({
        initialValues: initialWithdrawnValues,
        validationSchema: Yup.object().shape({
            year: Yup.number().required(),
            weekNumber: Yup.number().required(),
            description: Yup.string().nullable(),
            hours: Yup.number().nullable(),
            minutes: Yup.number().nullable()
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            let _value = ((values.hours || 0) * 3600) + ((values.minutes || 0) * 60);
            addWithdrawn({year: values.year, weekNumber: values.weekNumber, value: _value, description: values.description})
        }
    })

    function addWithdrawn(withdrawn: {weekNumber: number, year: number, value: number, description: string})
    {
        dispatch(actions.showSynthesis())
        withdrawnApi.add(salary.id, withdrawn)
            .then(() => fetchCounters())
            .then(() => formikWithdrawn.setSubmitting(false))
            .then(() => withdrawnModal?.hide())
        ;
    }

    function addPaid(paid: {month: number, year: number, value: number, maj: "none"|"low"|"high"})
    {
        dispatch(actions.showSynthesis())
        paidApi.add(salary.id, paid)
            .then(() => fetchCounters())
            .then(() => formikPaid.setSubmitting(false))
            .then(() => paidModal?.hide())
        ;
    }

    function editWeek(week: {wn: number, year: number, value: number})
    {
        dispatch(actions.showSynthesis())
        counterApi.editWeek(salary.id, week)
            .then(() => fetchCounters())
            .then(() => formik.setSubmitting(false))
            .then(() => modal?.hide())
            .then(() => setCurrentWeek(undefined))
        ;
    }

    function fetchCounters(){
        dispatch(actions.showSynthesis())

        let firstDay = new Date(year, month - 1, 1);
        let lastDay = new Date(year, month, 1);

        api.test({
            start: format(firstDay, 'uuuu-MM-dd'),
            end: format(lastDay, 'uuuu-MM-dd'),
            calendar: calendarTypes.CALENDAR_TIME_SHEET,
            options: {
                salaries: [salary.id],
                year: year,
                month: month,
            }
        })
            .then(resp => dispatch(actions.showSynthesisSuccess(resp.data.timeSheet[0])))
    }

    function prevMonth()
    {
        if (month === 1){
            setYear(prev => prev - 1)
            setMonth(12)
        }else{
            setMonth(prev => prev - 1)
        }
    }

    function nextMonth()
    {
        if (month === 12){
            setYear(prev => prev + 1)
            setMonth(1)
        }else{
            setMonth(prev => prev + 1)
        }
    }

    function prevYear()
    {
        setYear(prev => prev - 1)
    }

    function nextYear()
    {
        setYear(prev => prev + 1)
    }

    return <>
        <div className="row align-items-center">
            <div className="col-auto mb-3">
                <div className="w-100 btn-group">
                    <button className="btn btn-light text-primary" disabled={loading} onClick={prevYear}>
                        <i className={'bi bi-chevron-double-left'}></i>
                    </button>
                    <button className="btn btn-light text-primary" disabled={loading} onClick={prevMonth}>
                        <i className={'bi bi-chevron-left'}></i>
                    </button>
                    <button className="btn btn-light text-primary">
                        {getMonthOption(String(month))?.label}
                    </button>
                    <button className="btn btn-light text-primary">
                        {year}
                    </button>
                    <button className="btn btn-light text-primary" disabled={loading} onClick={nextMonth}>
                        <i className={'bi bi-chevron-right'}></i>
                    </button>
                    <button className="btn btn-light text-primary" disabled={loading} onClick={nextYear}>
                        <i className={'bi bi-chevron-double-right'}></i>
                    </button>
                </div>
            </div>
            <div className="col">

            </div>
            <div className="col-auto mb-3">
                {Granted(accessRights.EDIT_COUNTER) && <button data-bs-toggle="tooltip"
                                                               onClick={() => dispatch(openSidebar(COUNTER_SETTINGS))} className={'btn btn-light'}>
                    <i className={'bi bi-gear'}> </i> Réglages
                </button>}
            </div>
            <div className="col-auto mb-3">
                <button title={"Détail par jour"} onMouseEnter={e => onMouseEnterTooltip(e.target)} className="btn btn-light" data-bs-toggle="modal" data-bs-target="#synthesisSignature">
                    <i className={'bi bi-list-ul'}></i> Détail par jour
                </button>
            </div>
            <div className="col-auto mb-3">
                <button title={"Télécharger"} onMouseEnter={e => onMouseEnterTooltip(e.target)} className="btn btn-light" data-bs-toggle="modal" data-bs-target="#downloadModal">
                    <i className={'bi bi-download'}></i> Télécharger
                </button>
            </div>
        </div>
        <div className="card mb-3 shadow-sm">
            <div className="card-header">
                <h4>
                    <i className={'bi bi-bar-chart'}></i> Compteur
                </h4>
            </div>
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-auto mb-3">
                        <div className="btn-group">
                            <button className="btn btn-light text-primary me-2" onClick={() => saveCounters(Object.values(synthesis.weeks).filter(w => w.counter).map(w => ({wn: w.weekNumber, year: w.year, value: w.counter})))}>
                                <i className={'bi bi-plus-circle text-success'}></i> Tout valider
                            </button>
                            <button className="btn btn-light text-primary" onClick={() => clearCounters(Object.values(synthesis.weeks).filter(w => w.counterSaved).map(w => ({wn: w.weekNumber, year: w.year})))}>
                                <i className={'bi bi-dash-circle text-danger'}></i> Tout retirer
                            </button>
                        </div>
                    </div>
                    <div className="col-auto mb-3">
                        <div className="form-check form-switch">
                            <input className="form-check-input" onChange={e => handleOvertimeMarkUpChange(e.currentTarget.checked)} defaultChecked={isOvertimeMarkUp} type="checkbox" role="switch" id="isOvertimeMarkUp" />
                            <label className="form-check-label" htmlFor="isOvertimeMarkUp">
                                Majorer les heures supplémentaires
                            </label>
                        </div>
                    </div>
                </div>
                <table className={'table table-bordered placeholder pulse'}>
                    <thead>
                    <tr>
                        <th colSpan={100} className={'text-center'}>
                            Compteur par semaine
                        </th>
                    </tr>
                    <tr>
                        <th>
                            {loading && <><SmallLoader /> Chargement...</>}
                        </th>
                        {(loading || !synthesis?.weeks) ? Array.from(Array(4).keys()).map(() => <td><div className="line"></div></td>) : Object.values(synthesis.weeks).map(w => <th>
                            S.{w.weekNumber}
                            <span className="clearfix"></span>
                            <span className="form-text">
                                {format(new Date(w.start), 'dd/MM')} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(w.end), 'dd/MM')}
                            </span>
                        </th>)}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <i className={'bi bi-clock-history'}></i> Compteur à date
                            <span className="clearfix"></span>
                            <span className="form-text">
                            <i className={'bi bi-info-circle'}></i> Calculé depuis l'état actuel du planning
                        </span>
                        </td>
                        {(loading || !synthesis?.weeks) ? Array.from(Array(4).keys()).map(() => 
                            <td>
                                <div className="line"></div>
                            </td>)
                        : Object.values(synthesis.weeks).map(w => 
                            <td className={'form-text'}>
                                {timeFormat(w.counter)} {!!w.counter && (w.counter !== w.counterSaved &&
                                    <>
                                        <button
                                            onClick={() => saveCounters([{year: w.year, value: w.counter, wn: w.weekNumber}])}
                                            onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}
                                            title={'Ajouter au compteur'}
                                            className={'btn btn-sm btn-light text-primary'}
                                        >
                                            <i className={'bi bi-plus-circle'}></i>
                                        </button>
                                    </>
                                )}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td>
                            <i className={'bi bi-check'}></i> Compteur validé
                            <span className="clearfix"></span>
                            <span className="form-text">
                            <i className={'bi bi-info-circle'}></i> Valeurs enregistrées dans le compteur personnel
                        </span>
                        </td>
                        {(loading || !synthesis?.weeks) ? Array.from(Array(4).keys()).map(() => 
                            <td>
                                <div className="line"></div>
                            </td>)
                        : Object.values(synthesis.weeks).map(w => 
                            <td>
                                {!(loading || !synthesis?.weeks) ?
                                    <>
                                        {timeFormat(w.counterSaved)}
                                        {!!w.counterSaved && (w.counterSaved === w.counter ?
                                            <i className={'bi bi-check text-success'}></i>
                                            : <i className={'bi bi-exclamation-triangle text-warning'}></i>
                                        )}
                                        {!!w.counterSaved && (
                                            <>
                                                <button
                                                    onClick={() => clearCounters([{year: w.year, wn: w.weekNumber}])}
                                                    onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}
                                                    title={'Retirer du compteur'}
                                                    className={'btn btn-sm btn-light text-primary'}
                                                >
                                                    <i className={'bi bi-dash-circle'}></i>
                                                </button>
                                            </>
                                        )}
                                        {!!w.counterSaved && (
                                            <>
                                                <button
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#counterEditModal"
                                                    onClick={() => setCurrentWeek(w)}
                                                    onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}
                                                    title={'Modifier'}
                                                    className={'btn btn-sm btn-light text-primary'}
                                                >
                                                    <i className={'bi bi-pencil'}></i>
                                                </button>
                                            </>
                                        )}
                                    </>
                                    : <div className={'line'}></div>
                                }
                            </td>
                        )}
                    </tr>
                    {isOvertimeMarkUp && <>
                        <tr>
                            <td>
                                <i className={'bi bi-arrow-up'}></i> H. majorée à 25%
                            </td>
                            {(loading || !synthesis?.weeks) ? Array.from(Array(4).keys()).map(() => <td><div className="line"></div></td>) : Object.values(synthesis.weeks).map(w => <td>{!(loading || !synthesis?.weeks) ? <>{timeFormat(w.overtime25)}</> : <div className={'line'}></div>}</td>)}
                        </tr>
                        <tr>
                            <td>
                                <i className={'bi bi-arrow-up-circle'}></i> H. majorée à 50%
                            </td>
                            {(loading || !synthesis?.weeks) ? Array.from(Array(4).keys()).map(() => <td><div className="line"></div></td>) : Object.values(synthesis.weeks).map(w => <td>{!(loading || !synthesis?.weeks) ? <>{timeFormat(w.overtime50)}</> : <div className={'line'}></div>}</td>)}
                        </tr>
                    </>}

                    <tr>
                        <td>
                            <i className={'bi bi-dash'}></i> Retrait personnalisé
                        </td>
                        {(loading || !synthesis?.weeks) ? Array.from(Array(4).keys()).map(() => <td><div className="line"></div></td>) : Object.values(synthesis.weeks).map(w => <td>{!(loading || !synthesis?.weeks) ? <span className={"d-flex align-items-center"}>
                        <span className="flex-grow-1">
                            {timeFormat(w.counterWithdrawn)}
                            {!!w.counterWithdrawnDescription && <>
                                <div className="clearfix"></div>
                                <span className="form-text">
                                <i className={'bi bi-quote'}></i> {w.counterWithdrawnDescription}
                            </span>
                            </>}
                        </span>
                        <span className="flex-grow-0">
                            <button data-bs-toggle="modal" data-bs-target="#withdrawnModal" onClick={() => setCurrentWeek(w)} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)} title={'Modifier'} className={'btn btn-sm btn-light text-primary'}><i className={'bi bi-pencil'}></i></button>
                        </span>
                    </span> : <div className={'line'}></div>}</td>)}
                    </tr>
                    </tbody>
                </table>
                <table className="table table-bordered placeholder pulse">
                    <thead>
                    <tr>
                        <th colSpan={100} className={'text-center'}>Total</th>
                    </tr>
                    <tr>
                        <th>
                            Initial
                        </th>
                        <th>
                            Validé
                        </th>
                        {isOvertimeMarkUp && <>
                            <th>
                                HS 25%
                            </th>
                            <th>
                                HS 50%
                            </th>
                        </>}
                        <th>
                            Retrait personnalisé
                        </th>
                        <th>
                            H. payées taux normal
                        </th>
                        <th>
                            H. payées taux 25%
                        </th>
                        <th>
                            H. payées taux 50%
                        </th>
                        <th>
                            Final
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            {(loading || !synthesis?.weeks) ? <div className={'line'}></div> : <>{timeFormat(synthesis.initialCounter)}</>}
                        </td>
                        <td>
                            {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>{timeFormat(synthesis.counterSaved)}</>}
                        </td>
                        {isOvertimeMarkUp && <>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>{timeFormat(synthesis.overtime25)}</>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>{timeFormat(synthesis.overtime50)}</>}
                            </td>
                        </>}
                        <td>
                            {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>{timeFormat(synthesis.counterWithdrawn)}</>}
                        </td>
                        <td>
                            {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                {timeFormat(synthesis.paid)} {<button data-bs-toggle="modal" onMouseDown={() => setPaidInitialValues(p => ({...p, maj: "none"}))} data-bs-target="#paidModal" className={'btn btn-light text-primary btn-sm'}><i className={'bi bi-pencil'}></i></button>}
                            </>}
                        </td>
                        <td>
                            {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                {timeFormat(synthesis.paidLowMajoration)} {<button data-bs-toggle="modal" onMouseDown={() => setPaidInitialValues(p => ({...p, maj: "low"}))} data-bs-target="#paidModal" className={'btn btn-light text-primary btn-sm'}><i className={'bi bi-pencil'}></i></button>}
                            </>}
                        </td>
                        <td>
                            {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                {timeFormat(synthesis.paidHighMajoration)} {<button data-bs-toggle="modal" onMouseDown={() => setPaidInitialValues(p => ({...p, maj: "high"}))} data-bs-target="#paidModal" className={'btn btn-light text-primary btn-sm'}><i className={'bi bi-pencil'}></i></button>}
                            </>}
                        </td>
                        <td>
                            {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>{timeFormat(synthesis.finalCounter)}</>}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="card shadow-sm mb-3">
            <div className="card-header">
                <h4>
                    <i className={'bi bi-table'}></i> Temps de travail
                </h4>
            </div>
            <div className="card-body">
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>
                                Contrat (théorique)
                            </th>
                            <th>
                                Travaillé
                            </th>
                            <th>
                                Absences ajoutées au volume d'heure
                            </th>
                            <th>
                                HS. 25%
                            </th>
                            <th>
                                HS. 50%
                            </th>
                            <th>
                                H. de nuit 20%
                            </th>
                            <th>
                                H. de nuit 40%
                            </th>
                            <th>
                                H. jour férié travaillé
                            </th>
                            <th>
                                H. dimanche
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.monthlyContractWorkingTime, 'decimal')}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.workingTime)}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.absenceAccountedTime)}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.overtime25)}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.overtime50)}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.nightTime20)}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.nightTime40)}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.labourDayWorkingTime)}
                                </>}
                            </td>
                            <td>
                                {(loading || !synthesis?.weeks) ?  <div className={'line'}></div> : <>
                                    {timeFormat(synthesis.sundayWorkingTime)}
                                </>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <SynthesisSignature />
        <div className="modal fade" id="counterEditModal" tabIndex={-1} aria-labelledby="counterEditModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="counterEditModalLabel">Modifier le compteur</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form key={updateForm} onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-check">
                                        <input key={updateForm} defaultChecked={initialValues.signe === '+'} name={'signe'} className="form-check-input" type="radio" value="+" onChange={formik.handleChange} id="signe_plus" />
                                        <label className="form-check-label" htmlFor="signe_plus">
                                            <i className={'bi bi-plus text-success'}> </i> Positif
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input key={updateForm} defaultChecked={initialValues.signe === '-'} name={'signe'} className="form-check-input" type="radio" value="-" onChange={formik.handleChange} id="signe_minus" />
                                        <label className="form-check-label" htmlFor="signe_minus">
                                            <i className={'bi bi-dash text-danger'}> </i> Négatif
                                        </label>
                                    </div>
                                    <FormError errors={formik.errors} touched={formik.touched} field={'signe'} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <BootstrapSelect
                                        key={updateForm}
                                        enableReinitialize={true}
                                        required={true}
                                        label={"Heures"}
                                        value={{label: String(initialValues.hours || 0), value: (initialValues.hours || 0)}}
                                        options={Array.from(Array(99).keys()).map((n) => ({label: String(n), value: n}))}
                                        onChange={(c) => formik.setFieldValue('hours', c?.value)}
                                    />
                                </div>
                                <div className="col-6">
                                    <BootstrapSelect
                                        key={updateForm}
                                        enableReinitialize={true}
                                        required={true}
                                        label={"Minutes"}
                                        value={{label: String(initialValues.minutes || 0), value: (initialValues.minutes || 0)}}
                                        options={Array.from(Array(59).keys()).map((n) => ({label: String(n), value: n}))}
                                        onChange={(c) => formik.setFieldValue('minutes', c?.value)}
                                    />
                                </div>
                            </div>
                            <button type={'submit'} className={'btn btn-light text-primary w-100'}>
                                <i className={'bi bi-check'}> </i> Valider
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="withdrawnModal" tabIndex={-1} aria-labelledby="withdrawnModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="withdrawnModalLabel">Retrait personnalisé</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form key={updateForm} onSubmit={formikWithdrawn.handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <BootstrapSelect
                                        key={updateForm}
                                        enableReinitialize={true}
                                        required={true}
                                        label={"Heures"}
                                        value={{label: String(initialWithdrawnValues.hours || 0), value: (initialWithdrawnValues.hours || 0)}}
                                        options={Array.from(Array(99).keys()).map((n) => ({label: String(n), value: n}))}
                                        onChange={(c) => formikWithdrawn.setFieldValue('hours', c?.value)}
                                    />
                                </div>
                                <div className="col-6">
                                    <BootstrapSelect
                                        key={updateForm}
                                        enableReinitialize={true}
                                        required={true}
                                        label={"Minutes"}
                                        value={{label: String(initialWithdrawnValues.minutes || 0), value: (initialWithdrawnValues.minutes || 0)}}
                                        options={Array.from(Array(59).keys()).map((n) => ({label: String(n), value: n}))}
                                        onChange={(c) => formikWithdrawn.setFieldValue('minutes', c?.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <textarea key={updateForm} name="description" defaultValue={initialWithdrawnValues.description} onChange={formikWithdrawn.handleChange} className="form-control" placeholder="Description"
                                              id="description"></textarea>
                                    <label htmlFor="description">Description</label>
                                    <FormError touched={formikWithdrawn.touched} errors={formikWithdrawn.errors} field={'description'} />
                                </div>
                            </div>
                            <button type={'submit'} className={'btn btn-light text-primary w-100'}>
                                <i className={'bi bi-check'}> </i> Valider
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="paidModal" tabIndex={-1} aria-labelledby="paidModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="counterEditModalLabel">
                            {paidInitialValues.maj === "none" && <>Payer des heures au taux normal</>}
                            {paidInitialValues.maj === "low" && <>Payer des heures majorées à 25%</>}
                            {paidInitialValues.maj === "high" && <>Payer des heures majorées à 50%</>}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form key={updateForm} onSubmit={formikPaid.handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <BootstrapSelect
                                        key={updateForm}
                                        enableReinitialize={true}
                                        required={true}
                                        label={"Heures"}
                                        value={{label: String(paidInitialValues.hours || 0), value: (paidInitialValues.hours || 0)}}
                                        options={Array.from(Array(99).keys()).map((n) => ({label: String(n), value: n}))}
                                        onChange={(c) => formikPaid.setFieldValue('hours', c?.value)}
                                    />
                                </div>
                                <div className="col-6">
                                    <BootstrapSelect
                                        key={updateForm}
                                        enableReinitialize={true}
                                        required={true}
                                        label={"Minutes"}
                                        value={{label: String(paidInitialValues.minutes || 0), value: (paidInitialValues.minutes || 0)}}
                                        options={Array.from(Array(59).keys()).map((n) => ({label: String(n), value: n}))}
                                        onChange={(c) => formikPaid.setFieldValue('minutes', c?.value)}
                                    />
                                </div>
                            </div>
                            <button type={'submit'} className={'btn btn-light text-primary w-100'}>
                                <i className={'bi bi-check'}> </i> Valider
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="downloadModal" tabIndex={-1} aria-labelledby="downloadModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="counterEditModalLabel">Télécharger</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form key={updateForm} onSubmit={formikDownload.handleSubmit}>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md mb-3">
                                        <DatePicker
                                            disabled={formikDownload.isSubmitting}
                                            className={'form-control ' + validationClass(formikDownload.errors, formikDownload.touched, 'start')}
                                            customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                                            selected={formikDownload.values.start}
                                            onChange={(date: Date) => {
                                                formikDownload.setFieldValue(`start`, date)
                                                formikDownload.setFieldTouched('start', true)
                                            }}
                                            key={updateForm}
                                            selectsStart
                                            startDate={formikDownload.values.start}
                                            maxDate={formikDownload.values.end}
                                            dateFormat={"E dd MMM uuuu"}
                                        />
                                    </div>
                                    <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                                        <i className={'bi bi-arrow-left-right mx-1'}> </i>
                                    </div>
                                    <div className="col-12 col-md mb-3">
                                        <DatePicker
                                            disabled={formikDownload.isSubmitting}
                                            className={'form-control ' + validationClass(formikDownload.errors, formikDownload.touched, 'end')}
                                            customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                                            selected={formikDownload.values.end}
                                            onChange={(date: Date) => {
                                                formikDownload.setFieldValue(`end`, date)
                                                formikDownload.setFieldTouched('end', true)
                                            }}
                                            key={updateForm}
                                            selectsEnd
                                            dateFormat={"E dd MMM uuuu"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type={'submit'} className={'btn btn-light text-primary w-100'}>
                                <i className={'bi bi-check'}> </i> Valider
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default SalaryCounterV2;