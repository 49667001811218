import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { User } from "../../../../../models/auth";

export const UserInformationShow:React.FC = () => {
    const user: User = useSelector((state: RootStateOrAny) => state.user.single);
    
    return (
        <div className={"card shadow-sm p-1 p-md-3"}>
            <div className={'table-responsive'}>
                <table className={'table'}>
                    <tbody>
                        <tr>
                            <td className={'text-primary'}>
                                Nom
                            </td>
                            <td>
                                {user.information?.lastname}
                            </td>
                        </tr>
                        <tr>
                            <td className={'text-primary'}>
                                Prénom
                            </td>
                            <td>
                                {user.information?.firstname}
                            </td>
                        </tr>
                        <tr>
                            <td className={'text-primary'}>
                                Tél
                            </td>
                            <td>
                                {user.information?.tel}
                            </td>
                        </tr>
                        <tr>
                            <td className={'text-primary'}>
                                Email
                            </td>
                            <td>
                                {user.email}
                            </td>
                        </tr>
                        <tr>
                            <td className={'text-primary'}>
                                Date de création
                            </td>
                            <td>
                                {user.createdAt}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserInformationShow;