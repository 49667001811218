import React, {useEffect, useState} from "react";
import OfferEditForm from "../../../event/offer/edit/form";
import {EventClickArg} from "@fullcalendar/react";
import format from "../../../../utils/locale";
import * as api from "../../../../adapters/event/offer";
import * as applicationApi from "../../../../adapters/event/offer/applicant";
import SmallLoader from "../../../../utils/loader/small";
import * as calendarTypes from "../../../../constants/calendar";
import * as accessRights from "../../../../constants/accessRight";
import {EventOfferApplication} from "../../../../models/event/offer";
import getUser from "../../../../security/getUser";
import {Salary} from "../../../../models/salary";
import {Granted} from "../../../../security/granted";

interface Interface {
    arg: EventClickArg,
    setCalendarOfferSidebarProps: React.Dispatch<any>,
    close: () => any;
    handleSubmit: (ev: any) => any;
}

const CalendarOfferShow:React.FC<Interface> = (props) => {

    const {arg, setCalendarOfferSidebarProps, close, handleSubmit} = props;

    const [canceling, setCanceling] = useState<boolean>(false)

    const handleSubmitSuccess = (ev: any) => {
        return Promise.all([
            handleSubmit(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    function cancel(){
        setCanceling(true);
        api.disable(arg.event.extendedProps.offerId)
            .then(resp => {
                handleSubmitSuccess(resp.data)
            })
            .then(() => setCanceling(false))
    }

    function apply(){
        applicationApi.apply(arg.event.extendedProps.offerId)
            .then(resp => handleSubmitSuccess(resp.data))
    }

    function cancelApply(){
        applicationApi.cancel(arg.event.extendedProps.offerId, arg.event.extendedProps.applications.find((a: EventOfferApplication) => a.salary.id === getUser().currentSalary.id).id)
            .then(resp => handleSubmitSuccess(resp.data))
    }

    return <>
        <div className="offcanvas-header">
        <h5 id="offcanvasCalendarOfferShowLabel">Offre de créneau</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        <table className="table">
            <tbody>
                <tr>
                    <td>Horaire</td>
                    <td>
                        {format(arg.event.start!, 'E dd MMMM HH:mm')} <i className={'bi bi-arrow-left-right'}></i>  {format(arg.event.end!, 'HH:mm')}
                    </td>
                </tr>
                <tr>
                    <td>Établissement</td>
                    <td>
                        {arg.event.extendedProps.company.title}
                    </td>
                </tr>
                <tr>
                    <td>Emplacement</td>
                    <td>
                        {arg.event.extendedProps.localisation?.title || <i className={'bi bi-x'}></i>}
                    </td>
                </tr>
                <tr>
                    <td>Activité</td>
                    <td>
                        {arg.event.extendedProps.activity?.title || <i className={'bi bi-x'}></i>}
                    </td>
                </tr>
            </tbody>
        </table>

        {Granted(accessRights.APPLY_BOOKING_OFFER, undefined, arg.event.extendedProps) && arg.event.extendedProps.eventType !== calendarTypes.EVENT_TYPE_OFFER_VALIDATED  && <>
            {arg.event.extendedProps.applications.find((a: EventOfferApplication) => a.salary.id === getUser().currentSalary.id) ?
                <button className={'btn w-100 btn-light'} onClick={() => cancelApply()}>
                <i className={'bi bi-x text-danger'}></i> Annuler ma participation
            </button> : <button className={'btn w-100 btn-light'} onClick={() => apply()}>
                    <i className={'bi bi-check text-success'}></i> Participer à l'offre
                </button>}
        </>}

        {Granted(accessRights.EDIT_BOOKING_OFFER, undefined, arg.event.extendedProps) && <>
            <table className={'table'}>
                <thead>
                <tr>
                    <th>
                        Collaborateurs
                    </th>
                    <th>
                        Participants
                    </th>
                    <th>
                        Séléctionné
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <i className={'bi bi-person'}></i> {arg.event.extendedProps.salaries.length}
                    </td>
                    <td>
                        <i className={'bi bi-person'}></i> {arg.event.extendedProps.applications.length}
                    </td>
                    <td>
                        {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_OFFER_VALIDATED && <>
                            <h4>
                                <i className={'bi bi-check'}></i>  {arg.event.extendedProps.salary.title}
                            </h4>
                        </>}
                        <button disabled={!arg.event.extendedProps.applications.length} type={'button'}
                                className={'btn w-100 btn-light'}
                                onClick={() => setCalendarOfferSidebarProps((prev: any) => ({
                                    sidebarType: "OFFER_APPLICATION",
                                    trigger: prev.trigger + 1,
                                    offerId: arg.event.extendedProps.offerId,
                                    applications: arg.event.extendedProps.applications,
                                }))}>
                            {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_OFFER_VALIDATED && <>Changer de participant</>}
                            {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_OFFER_PENDING && <>Séléctionner un participant</>}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className={'row'}>
                <div className="col-6">
                    <button type={'button'} className={'btn w-100 btn-light'} onClick={() => setCalendarOfferSidebarProps((prev: any) => ({
                        sidebarType: "OFFER_EDIT",
                        trigger: prev.trigger + 1,
                        company: arg.event.extendedProps.company,
                        activity: arg.event.extendedProps.activity,
                        localisation: arg.event.extendedProps.localisation,
                        start: arg.event.start,
                        end: arg.event.end,
                        offerId: arg.event.extendedProps.offerId,
                        salaries: arg.event.extendedProps.salaries,
                        description: arg.event.extendedProps.description
                    }))}>
                        <i className={'bi bi-pencil text-primary'}></i> Modifier
                    </button>
                </div>
                <div className="col-6">
                    <button type={'button'} className={'btn w-100 btn-light'} onClick={() => cancel()}>
                        {canceling ? <SmallLoader /> : <i className={'bi bi-dash-circle text-danger'}></i>} Supprimer
                    </button>
                </div>
            </div>
        </>}
    </div>
    </>
}

export default CalendarOfferShow;