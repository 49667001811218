import * as types from "../../constants/taskGroup";
import * as models from "../../models/taskGroup";

export const addTaskGroup = () => {
    return {
        type: types.ADD_TASK_GROUP,
    }
}

export const addTaskGroupInformation = () => {
    return {
        type: types.ADD_TASK_GROUP,
    }
}

export const addTaskGroupSuccess = (taskGroup: models.TaskGroup) => {
    return {
        type: types.ADD_TASK_GROUP_SUCCESS,
        taskGroup: taskGroup,
    }
}

export const addTaskGroupFailure = (error: string) => {
    return {
        type: types.ADD_TASK_GROUP_FAILURE,
        taskGroup: null,
        taskGroups: null,
        error: error
    }
}

export const editTaskGroup = () => {
    return {
        type: types.EDIT_TASK_GROUP,
    }
}

export const editTaskGroupSuccess = (taskGroup: models.TaskGroup) => {
    return {
        type: types.EDIT_TASK_GROUP_SUCCESS,
        taskGroup: taskGroup,
    }
}

export const fetchTaskGroups = () => {
    return {
        type: types.FETCH_TASK_GROUPS,
    }
}

export const fetchTaskGroupsFailure = (error: string) => {
    return {
        type: types.FETCH_TASK_GROUPS_FAILURE,
        taskGroup: null,
        taskGroups: [],
        error: error
    }
}

export const fetchTaskGroupsSuccess = (taskGroups: models.TaskGroups) => {
    return {
        type: types.FETCH_TASK_GROUPS_SUCCESS,
        taskGroup: null,
        taskGroups: taskGroups
    }
}


export const showTaskGroup = () => {
    return {
        type: types.SHOW_TASK_GROUP,
    }
}
export const showTaskGroupSuccess = (taskGroup: models.TaskGroup) => {
    return {
        type: types.SHOW_TASK_GROUP_SUCCESS,
        taskGroup: taskGroup
    }
}
export const cancelTaskGroupSuccess = (taskGroup: models.TaskGroup) => {
    return {
        type: types.CANCEL_TASK_GROUP_SUCCESS,
        taskGroup: taskGroup
    }
}
export const showTaskGroupFailure = (error: string) => {
    return {
        type: types.SHOW_TASK_GROUP_FAILURE,
        taskGroup: null,
        taskGroups: null,
        error: error
    }
}