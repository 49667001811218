import React from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import {PeriodReporting, PeriodsReporting, WeekReporting} from "../../../models/reporting";



export const CounterSidebar:React.FC<{setScrollBottom: (scrollBottom: boolean) => void, isLoading: boolean, isLast: boolean}> = (props) => {

    const reportings: PeriodsReporting = useSelector((state: RootStateOrAny) => state.reporting.payload)
    const {setScrollBottom, isLoading, isLast} = props;

    if (!reportings.length){
        return <div>
            <h4>
               <i className={'bi bi-search'}> </i> Aucun compteur
            </h4>
        </div>
    }

    return <div className={'p-1 p-md-3'}>
        <div className="table-responsive">
            <table className={"table"}>
                <thead>
                    <tr>
                        <th>
                            Collaborateur
                        </th>
                        <th>
                            initial
                        </th>
                        {reportings[0].weeks.map((week: WeekReporting) =>  <th>
                           S {week.weekNumber}
                        </th>)}
                        <th>
                           Période
                        </th>
                        <th>
                            Total
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                {reportings.map((reporting: PeriodReporting, index) => <tr>
                    <td>
                        {reporting.salary.title}
                        <div className="clearfix"> </div>
                        {!!reporting.salary.information?.contractWorkingTime && <span className={"form-text"}>{reporting.salary.information?.contractWorkingTime}h</span>}
                    </td>
                    <td>
                        {reporting.counterTotalInitial || <i className={"bi bi-x"}> </i>}
                    </td>
                    {reporting.weeks.map(week =>  <td>
                        {week.counterSaved ? [week.counterSaved, <i className={'bi bi-check text-success'}> </i>] : (week.counter || <i className={'bi bi-x'}> </i>)}
                        <div className="clearfix"> </div>
                        {!!week.counterSaved && week.counter !== week.counterSaved && <span className={'form-text text-danger'}>
                            Delta à date : {week.counter}
                        </span>}
                    </td>)}
                    <td>
                        {!!reporting.counter ? ([<i className={reporting.counter[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>, reporting.counter]) : <i className={'bi bi-x'}> </i>}
                    </td>
                    <td>
                        {(reporting.counterTotal && reporting.counterTotal !== '00:00') ? [<i className={reporting.counterTotal[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>, reporting.counterTotal] : <i className={'bi bi-x'}> </i>}
                    </td>
                    <td>
                        <div className="dropdown">
                            <button className="btn dropdown-toggle dropdown-toggle-no-after-content" type="button" id={`dropdownMenuButton${index}`}
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i className={'bi bi-three-dots-vertical'}> </i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                                <li>
                                    <button className="dropdown-item">
                                        Enregistrer les delta à date
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item">
                                        Enregistrer les delta non validé
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>)}
                {!isLoading && !isLast && <tr>
                    <td colSpan={100} className={'p-0'}>
                        <button className={'w-100 btn btn-outline-primary'} onClick={() => setScrollBottom(true)}>
                            <i className={'bi bi-arrow-repeat'}> </i> Charger plus de collaborateurs
                        </button>
                    </td>
                </tr>}
                </tbody>
            </table>
        </div>
    </div>
}

export default CounterSidebar;