import { combineReducers } from 'redux'
import {RootStateOrAny} from "react-redux";
import companies from './companies'
import auth from './auth'
import companyGroup from "./companyGroup";
import salary from "./salary";
import rightSidebar from "./rightSidebar";
import {LOGOUT, UNSELECT_ACCOUNT} from "../constants/auth";
import job from "./job";
import alertSivan from "./alert";
import accessLevel from "./accessLevel";
import axios from "./axios";
import absence from "./absence";
import localisation from "./localisation";
import taskGroup from "./taskGroup";
import template from "./template";
import channel from "./channel";
import task from "./task";
import staticBackdropModal from "./statickBackdropModal";
import header from "./header";
import calendar from "./calendar";
import taskCalendar from "./task/calendar";
import templateCalendar from "./template/calendar";
import templateApplied from "./template/applied";
import timeClock from "./timeClock";
import reporting from "./reporting";
import counter from "./counter";
import keepNote from "./keepNote";
import user from "./admin/user";
import activity from "./activity";
import team from "./team";
import document from "./document";
import bookingOffer from "./booking/offer";
import eventOffer from "./event/offer";
import expense from "./expense";
import report from "./report";
import synthesis from "./synthesis";

const appReducer = combineReducers({
    companies,
    auth,
    companyGroup,
    salary,
    job,
    accessLevel,
    absence,
    localisation,
    activity,
    taskGroup,
    task,
    taskCalendar,
    template,
    templateApplied,
    templateCalendar,
    channel,
    calendar,
    timeClock,
    reporting,
    report,
    synthesis,
    counter,
    keepNote,
    team,
    bookingOffer,
    eventOffer,
    document,
    expense,

    // UTILS
    rightSidebar,
    header,
    staticBackdropModal,
    alertSivan,
    axios,

    // ADMIN
    user
})

const rootReducer = (state: RootStateOrAny, action: any) => {
    if ([LOGOUT, UNSELECT_ACCOUNT].includes(action.type))
        return appReducer(undefined, action)

    return appReducer(state, action)
}

export default rootReducer;