import * as models from "../../models/calendar"
import * as types from "../../constants/calendar"
import {EventClickArg, EventDropArg} from "@fullcalendar/react";
import {NeedEvents, ResourceLabelContentArg} from "../../models/calendar";
import React from "react";
import {Companies} from "../../models/companies";
import {Salary} from "../../models/salary";
import {Teams} from "../../models/team";
import {EventResizeDoneArg} from "@fullcalendar/interaction";

const calendar = (state: models.calendarInitialStateInterface = models.initialState, action:
    {
        type: string,
        teams: Teams,
        start: Date,
        end: Date,
        salary: Salary,
        events: any[],
        eventsCopy: any[],
        presenceEvents: any[],
        presenceEventsCopy: any[],
        needEvents: NeedEvents,
        resources: any[],
        resourcesCopy: any[],
        bookingOffers: any[],
        tasks: any[],
        workingTimes: any[],
        title: string,
        groupBy: number,
        job: number,
        refresh: number,
        resourceId: string,
        parentId: string,
        payroll: number,
        error: string,
        eventClickArg?: EventClickArg,
        cancelEvent?: EventClickArg,
        pendingEvent?: EventResizeDoneArg|EventDropArg,
        displayEventPop: boolean,
        displayTemplateEventPop: boolean,
        isGenerated: boolean,
        resourceLabelContentArg?: ResourceLabelContentArg,
        displayResourceActions: boolean,
        resourceActionsButton: React.MouseEvent<HTMLButtonElement, MouseEvent>
        companies: Companies
        [key: string] : any
    }
) => {
    switch (action.type) {
        case types.FETCH_EVENTS:
            state.resources = [];
            state.presenceEvents = [];
            state.events = [];
            state.workingTimes = [];
            state.needEvents = [];
            state.loading = true;
            return state
        case types.FETCH_PRESENCE_EVENTS_SUCCESS:
            state.presenceEvents = action.presenceEvents;
            return state
        case types.FETCH_EVENTS_SUCCESS:
            state.events = action.events
            state.workingTimes = action.workingTimes
            state.loading = false
            return state
        case types.FETCH_ALL_EVENTS_SUCCESS:
            state.events = action.events  || []
            state.presenceEvents = action.presenceEvents || []
            state.needEvents = action.needEvents || []
            state.workingTimes = action.workingTimes  || []
            state.tasks = action.tasks  || []
            state.bookingOffers = action.bookingOffers  || []
            state.loading = false
            return state
        case types.FETCH_NEED_EVENTS_SUCCESS:
            state.needEvents = action.needEvents
            return state
        case types.FETCH_TASKS_SUCCESS:
            state.tasks = action.tasks
            return state
        case types.FETCH_RESOURCES:
            state.loading = true
            return state;
        case types.FETCH_RESOURCES_SUCCESS:
            state.loading = false
            state.resources = action.resources;
            return state;
        case types.FETCH_BOOKING_OFFERS_EVENT_SUCCESS:
            state.loading = false
            state.bookingOffers = action.bookingOffers;
            return state;
        case types.CANCEL_BOOKING_OFFER:
            state.bookingOffers = state.bookingOffers.filter(e => e.offer.id !== action.bookingOffer.id);
            return state;
        case types.ADD_BOOKING_OFFER:
            state.bookingOffers = [...state.bookingOffers, action.bookingOffer];
            return state;
        case types.FETCH_TITLE_SUCCESS:
            state.title = action.title;
            state.start = action.start;
            state.end = action.end;
            return state
        case types.SHOW_EVENT_POP:
            state.eventClickArg = action.eventClickArg
            state.resourceId = action.resourceId
            state.parentId = action.parentId
            state.displayEventPop = true;
            return state;
        case types.SHOW_EVENT_POPUP:
            state.eventClickArg = action.eventClickArg
            state.resourceId = action.resourceId
            state.parentId = action.parentId
            state.showEventPopup = true;
            return state;
        case types.EVENT_EDIT:
            state.pendingEvent = action.pendingEvent
            return state;
        case types.EVENT_EDIT_ABORT:
            state.pendingEvent!.event.setResources(state.pendingEvent!.oldEvent!.getResources())
            state.pendingEvent!.event.setDates(state.pendingEvent!.oldEvent.start!, state.pendingEvent!.oldEvent.end)
            delete state.pendingEvent
            return state;
        case types.EVENT_EDIT_VALIDATE:
            delete state.pendingEvent
            return state;
        case types.HIDE_EVENT_POP:
            state.eventClickArg = undefined
            state.displayEventPop = false;
            return state;
        case types.HIDE_EVENT_POPUP:
            state.eventClickArg = undefined
            state.showEventPopup = false;
            return state;
        case types.SHOW_TEMPLATE_EVENT_POP:
            state.eventClickArg = action.eventClickArg
            state.resourceId = action.resourceId
            state.parentId = action.parentId
            state.displayTemplateEventPop = true;
            return state;
        case types.HIDE_TEMPLATE_EVENT_POP:
            state.eventClickArg = undefined
            state.displayTemplateEventPop = false;
            return state;
        case types.SHOW_RESOURCE_ACTIONS:
            state.resourceLabelContentArg = action.resourceLabelContentArg;
            state.resourceActionsButton = action.resourceActionsButton;
            state.displayResourceActions = true;
            return state;
        case types.HIDE_RESOURCE_ACTIONS:
            state.displayResourceActions = false;
            state.resourceLabelContentArg = undefined;
            state.resourceActionsButton = undefined;
            return state;
        case types.SET_COMPANIES:
            state.companies = action.companies;
            state.refresh += 1;
            state.resources = [];
            state.presenceEvents = [];
            state.events = [];
            state.workingTimes = [];
            state.needEvents = [];
            return state;
        case types.SET_JOB:
            state.job = action.job;
            state.refresh += 1;
            state.resources = [];
            state.presenceEvents = [];
            state.events = [];
            state.workingTimes = [];
            state.needEvents = [];
            return state;
        case types.SET_GROUP_BY:
            state.groupBy = action.groupBy;
            state.refresh += 1;
            state.resources = [];
            state.presenceEvents = [];
            state.events = [];
            state.workingTimes = [];
            state.needEvents = [];
            return state;
        case types.REFRESH:
            state.refresh += 1;
            state.resources = [];
            state.presenceEvents = [];
            state.events = [];
            state.workingTimes = [];
            state.needEvents = [];
            return state;
        case types.SET_GENERATED:
            state.isGenerated = action.isGenerated;
            state.refresh += 1;
            state.resources = [];
            state.presenceEvents = [];
            state.events = [];
            state.workingTimes = [];
            state.needEvents = [];
            return state;
        case types.REFRESH_RESOURCE:
            state.salary = action.salary;
            state.resourceId = action.resourceId;
            state.parentId = action.parentId;
            state.salary = action.salary;
            state.presenceEventsCopy = [...state.presenceEvents]
            state.presenceEvents = [];
            state.refreshResource = true;
            return state;
        case types.REFRESH_RESOURCE_SUCCESS:
            state.workingTimes = [...state.workingTimes.filter(w => w.salary.id !== state.salary!.id), ...action.workingTimes];
            state.events = [...state.events.filter(e => e.salary.id !== state.salary!.id), ...action.events];

            state.presenceEvents = [
                ...state.presenceEventsCopy
                    .map((p) => {

                        let copy = {...p};

                        if (p.resourceId === state.parentId && copy.children.length && copy.children.map(c => c.longResourceId).includes(state.resourceId))
                        {
                            copy.children = [...p.children.filter(c => c.longResourceId !== state.resourceId)];
                        }

                        // IF IS ON DIFFERENT PARENT
                        if (p.resourceId !== state.parentId)
                        {
                            return copy;
                        }

                        // IF IS ON SAME PARENT BUT DIFFERENT HOURS
                        if (!(action.presenceEvents.map(f => f.uid).includes(p.uid)))
                        {
                            return copy;
                        }

                        // IS ON SAME PARENT AND SAME HOURS
                        for(let i = 0; i < action.presenceEvents.length; i++){
                            if (action.presenceEvents[i].uid === copy.uid && action.presenceEvents[i].children.length){
                                copy.children = [...copy.children, ...action.presenceEvents[i].children];
                            }
                        }

                        return copy;
                    })
            ];

            state.refreshResource = false;
            state.resourceId = '';
            state.parentId = '';

            return state;
        default:
            return state;
    }
}

export default calendar;