import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import FullCalendar, { DateSelectArg, EventClickArg, EventDropArg, EventHoveringArg } from "@fullcalendar/react";
import interactionPlugin, { EventResizeDoneArg } from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import CalendarEventSidebar, { CalendarEventSidebarInterface } from "../event/sidebar";
import CalendarBookingSidebar, { CalendarBookingSidebarInterface } from "../booking/sidebar";
import CalendarSignedSidebar, { CalendarSignedSidebarInterface } from "../signed/sidebar";
import CalendarAbsenceSidebar, { CalendarAbsenceSidebarInterface } from "../absence/sidebar";
import { Popover, Tooltip } from "bootstrap";
import CalendarOfferSidebar, { CalendarOfferSidebarInterface } from "../offer/sidebar";
import CalendarTemplateSidebar, { CalendarTemplateSidebarInterface } from "../template/sidebar";
import listPlugin from "@fullcalendar/list";
import CalendarPublish from "../publish";
import CalendarTaskSidebar, { CalendarTaskSidebarInterface } from "../task/sidebar";

import { Salary } from "../../../models/salary";
import * as calendarTypes from "../../../constants/calendar";
import * as accessRights from "../../../constants/accessRight";
import { LIST_BOOKING_GROUP } from "../../../constants/rightSidebar";

import * as api from "../../../adapters/calendar";
import { openSidebar } from "../../../actions/rightSidebar";

import { Granted, GrantedAny } from "../../../security/granted";
import HasModule from "../../../security/hasModule";
import getVersion from "../../../security/getVersion";
import getUser from "../../../security/getUser";
import format from "../../../utils/locale";
import { CalendarTitle, renderEvent } from "../utils";
import Loader from "../../../utils/loader";
import timeFormat from "../../../utils/timeFormat";
import { setHours, setMinutes } from "date-fns";

interface IProps {
    salary: Salary;
    setDate?: React.Dispatch<any>;
    start: Date;
    end?: Date;
}

const CalendarSingle:React.FC<IProps> = (props) => {
    const { salary, end, start } = props;
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state);

    const [calendar, setCalendar] = useState<string>(calendarTypes.CALENDAR_EVENTS_SINGLE);
    const [sortBy, setSortBy] = useState<1|2|3>(state.auth.user.calendarSettings.defaultGroupBy || 1);
    const [isLoading, setIsLoading] = useState(true);
    const [isNightTime, setNightTime] = useState(false);
    const [events, setEvents] = useState<any>([]);
    const [companyWeeks, setCompanyWeeks] = useState<{title: string, id: number, week: number}[]>([]);
    const [keepNotes, setKeepNotes] = useState<any>([]);
    const [tasks, setTasks] = useState<any>([]);
    const [offers, setOffers] = useState<any>([]);
    const [signed, setSigned] = useState<any[]>([]);
    const [calendarEvents, setCalendarEvents] = useState<any>([]);        
    const [timeSheets, setTimeSheets] = useState<any>([]);
    const [title, setTitle] = useState<string>('');
    const [view, setView] = useState<string>(window.innerWidth >= 768 ? calendarTypes.TIME_GRID_WEEK_VIEW : calendarTypes.LIST_WEEK_VIEW);
    const [pickerDate, setPickerDate] = useState(new Date());
    const [isOffers, setIsOffers] = useState(false)
    const [sm, setSm] = useState(window.innerWidth < 768)
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [disableDemarcation, setDisableDemarcation] = useState(false)

    const [calendarEventSidebarProps, setCalendarEventSidebarProps] = useState<CalendarEventSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarBookingSidebarProps, setCalendarBookingSidebarProps] = useState<CalendarBookingSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarSignedSidebarProps, setCalendarSignedSidebarProps] = useState<CalendarSignedSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarAbsenceSidebarProps, setCalendarAbsenceSidebarProps] = useState<CalendarAbsenceSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarOfferSidebarProps, setCalendarOfferSidebarProps] = useState<CalendarOfferSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarTemplateSidebarProps, setCalendarTemplateSidebarProps] = useState<CalendarTemplateSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarTaskSidebarProps, setCalendarTaskSidebarProps] = useState<CalendarTaskSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [timeRange, setTimeRange] = useState({
        minTime: format(setMinutes(setHours(new Date(), 0), 0), 'HH:mm:ss'),
        maxTime: "1." + format(setMinutes(setHours(new Date(), 0), 0), 'HH:mm:ss'),
    });

    const calendarRef = React.createRef<FullCalendar>();

    function fetchCalendar(overrides?: {overrideCalendar?: string, overrideView?: string, overrideSortBy?: number, overrideNightTime?: boolean}) {
        const {overrideCalendar, overrideView, overrideSortBy, overrideNightTime} = overrides || {};

        let API = calendarRef.current?.getApi()

        if (API) {
            setEndDate(API.view.activeEnd)
            setIsLoading(true)
            if (props.setDate){
                props.setDate(API.getDate());
            }

            return api.test({
                start: format(API.view.currentStart, 'uuuu-MM-dd'),
                end: format(API.view.currentEnd, 'uuuu-MM-dd'),
                calendar: overrideCalendar || calendar,
                options: {
                    view: overrideView || API.view.type,
                    sortBy: overrideSortBy || sortBy,
                    companies: salary.companies.map(c => c.id!),
                    salary: salary.id,
                    isNightTime: overrideNightTime !== undefined ? overrideNightTime : isNightTime,
                    disableDemarcation: disableDemarcation
                }
            }).then((data) => {                                      
                setTimeSheets([...data.data.eventsTimeSheet || [], ...data.data.signedTimeSheet || []])
                setSigned([...data.data.signed || []])
                setOffers([...data.data.eventOffers || []])
                setTasks([...data.data.tasks || []])
                setKeepNotes([...data.data.keepNotes || []])
                setEvents([...data.data.events || [], ...data.data.absences || []])
                setCompanyWeeks([...data.data.companyWeek?.filter((c: any) => salary.companies.map(_c => _c.id).includes(c.id)) || []])

                setTimeRange({
                    minTime: format(new Date(data.data.timeRange.minTime), "HH:mm:ss"),
                    maxTime: (new Date(data.data.timeRange.maxTime).getDate() - new Date(data.data.timeRange.minTime).getDate()) + '.' + format(new Date(data.data.timeRange.maxTime), "HH:mm:ss"),
                })
                setIsLoading(false)
            }).then(() => true)
        } else {
            return false;
        }
    }

    const handleResize = () => {
        if (window.innerWidth >= 768){
            setSm(false)
        }else if(window.innerWidth < 768){
            setSm(true)
        }
    }

    function getApi(){
        return calendarRef.current!.getApi();
    }

    function hasApi(){
        return !!calendarRef.current?.getApi();
    }

    function handlePickerDateChange(dt: Date)
    {
        if (hasApi()){
            setPickerDate(dt)
            getApi().gotoDate(dt)
            fetchCalendar()
        }
    }

    function prevClick(){
        if (hasApi()){
            getApi().prev()
            setTitle(getApi().view.title)
            fetchCalendar()
        }
    }

    function nextClick(){
        if (hasApi()){
            getApi().next()
            setTitle(getApi().view.title)
            fetchCalendar()
        }
    }

    function changeView(_view: string)
    {
        if (hasApi()){
            setView(_view)
            getApi().changeView(_view);
            setTitle(getApi().view.title)
            fetchCalendar()
        }
    }

    function handleCalendarChange(calendar: string) {
        setCalendar(calendar)

        if ([calendarTypes.CALENDAR_SIGNED_SINGLE, calendarTypes.CALENDAR_ABSENCE].includes(calendar)) {
            setSortBy(1);
        }

        fetchCalendar({overrideCalendar: calendar})
    }

    function onResize(arg: EventResizeDoneArg) {
        switch (arg.event.extendedProps.eventType){
            case calendarTypes.EVENT_TYPE_EVENT:
            case calendarTypes.EVENT_TYPE_EVENT_EXCEPTION:
                setCalendarEventSidebarProps(prev => ({
                    sidebarType: "EVENT_RESIZE",
                    trigger: prev.trigger + 1,
                    arg: arg,
                }))
                break;
            case calendarTypes.EVENT_TYPE_BOOKING:
            case calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION:
                setCalendarBookingSidebarProps(prev => ({
                    sidebarType: "BOOKING_RESIZE",
                    trigger: prev.trigger + 1,
                    arg: arg,
                }))
                break;
            case calendarTypes.EVENT_TYPE_TIME_CLOCK:
            case calendarTypes.EVENT_TYPE_TIME_CLOCK_EXCEPTION:
                setCalendarSignedSidebarProps(prev => ({
                    sidebarType: "SIGNED_RESIZE",
                    trigger: prev.trigger + 1,
                    arg: arg,
                }))
                break;
        }
    }

    function onDrop(arg: EventDropArg) {
        switch (arg.event.extendedProps.eventType){
            case calendarTypes.EVENT_TYPE_EVENT:
            case calendarTypes.EVENT_TYPE_EVENT_EXCEPTION:
                setCalendarEventSidebarProps(prev => ({
                    sidebarType: "EVENT_DROP",
                    trigger: prev.trigger + 1,
                    arg: arg,
                }))
                break;
            case calendarTypes.EVENT_TYPE_BOOKING:
            case calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION:
                setCalendarBookingSidebarProps(prev => ({
                    sidebarType: "BOOKING_DROP",
                    trigger: prev.trigger + 1,
                    arg: arg
                }))
                break;
            default:
                arg.revert();
        }
    }

    function onSelect(arg: DateSelectArg) {
        switch (calendar){
            case calendarTypes.CALENDAR_EVENTS_SINGLE:
                switch (getVersion()) {
                    case 1:
                        setCalendarEventSidebarProps(prev => ({
                            sidebarType: "EVENT_SELECT",
                            trigger: prev.trigger + 1,
                            arg: arg,
                            salary: salary,
                            company: salary.companies[0],
                        }))
                        break;
                    default:
                        setCalendarBookingSidebarProps(prev => ({
                            sidebarType: "BOOKING_SELECT",
                            trigger: prev.trigger + 1,
                            arg: arg,
                            salary: salary,
                            company: salary.companies[0],
                        }))
                        break;
                }
                break;
            case calendarTypes.CALENDAR_SIGNED_SINGLE:
                setCalendarSignedSidebarProps(prev => ({
                    sidebarType: "SIGNED_SELECT",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    salary: salary,
                    company: salary.companies[0],
                }))
        }
    }

    function onClick(arg: EventClickArg) {
        arg.jsEvent.stopPropagation()
        
        switch (arg.event.extendedProps.eventType){
            case calendarTypes.EVENT_TYPE_OFFER_PENDING:
            case calendarTypes.EVENT_TYPE_OFFER_VALIDATED:
                setCalendarOfferSidebarProps(prev => ({
                    sidebarType: "OFFER_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarOfferSidebarProps: setCalendarOfferSidebarProps
                }))
                break;
            case calendarTypes.EVENT_TYPE_EVENT:
            case calendarTypes.EVENT_TYPE_EVENT_EXCEPTION:
                setCalendarEventSidebarProps(prev => ({
                    sidebarType: "EVENT_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    calendar: calendar,
                    setCalendarAbsenceSidebarProps: setCalendarAbsenceSidebarProps,
                    setCalendarEventSidebarProps: setCalendarEventSidebarProps,
                    setCalendarSignedSidebarProps: setCalendarSignedSidebarProps,
                }))

                break;
            case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT:
            case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_EXCEPTION:
            case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION:
                setCalendarTemplateSidebarProps(prev => ({
                    sidebarType: "TEMPLATE_EVENT_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    calendar: calendar,
                    setCalendarAbsenceSidebarProps: setCalendarAbsenceSidebarProps,
                    setCalendarTemplateSidebarProps: setCalendarTemplateSidebarProps,
                    setCalendarSignedSidebarProps: setCalendarSignedSidebarProps,
                }))
                break;
            case calendarTypes.EVENT_TYPE_BOOKING:
            case calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION:
                setCalendarBookingSidebarProps(prev => ({
                    sidebarType: "BOOKING_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarSidebarSignedProps: setCalendarSignedSidebarProps,
                    calendar: calendar,
                    setCalendarSidebarBookingProps: setCalendarBookingSidebarProps,
                    setCalendarAbsenceSidebarProps: setCalendarAbsenceSidebarProps
                }))
                break;
            case calendarTypes.EVENT_TYPE_TIME_CLOCK:
            case calendarTypes.EVENT_TYPE_TIME_CLOCK_EXCEPTION:
                setCalendarSignedSidebarProps(prev => ({
                    sidebarType: "SIGNED_SHOW",
                    trigger: prev.trigger + 1,
                    setCalendarSignedSidebarProps: setCalendarSignedSidebarProps,
                    company: arg.event.extendedProps.company,
                    end: arg.event.end,
                    _end: arg.event.extendedProps._end,
                    start: arg.event.start,
                    _start: arg.event.extendedProps._start,
                    isAnomaly: arg.event.extendedProps.isAnomaly,
                    salary: arg.event.extendedProps.salary,
                    timeClockId: arg.event.extendedProps.timeClockId,
                    files: arg.event.extendedProps.files
                }))
                break;
            case calendarTypes.EVENT_TYPE_ABSENCE:
                if (Granted(accessRights.EDIT_ABSENCE, salary.id)){
                    setCalendarAbsenceSidebarProps(prev => ({
                        sidebarType: "ABSENCE_EDIT",
                        trigger: prev.trigger + 1,
                        arg: arg,
                    }))
                }
                break;
            case calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING:
                if (Granted(accessRights.EDIT_TIME_CLOCK, salary.id)){
                    setCalendarSignedSidebarProps(prev => ({
                        sidebarType: "SIGNED_ADD",
                        trigger: prev.trigger + 1,
                        company: arg.event.extendedProps.company,
                        salary: arg.event.extendedProps.salary,
                        start: arg.event.start!,
                        end: arg.event.end!,
                    }))
                }
                break;
            case calendarTypes.EVENT_TYPE_TASK:
            case calendarTypes.EVENT_TYPE_TASK_EXCEPTION:
                setCalendarTaskSidebarProps(prev => ({
                    sidebarType: "TASK_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarTaskSidebarProps: setCalendarTaskSidebarProps
                }))
                break;
        }
    }

    function handleNightTimeChange(checked: boolean)
    {
        fetchCalendar({overrideNightTime: checked})
        setNightTime(checked)
    }

    function handleEventChange(ev: any)
    {
        return fetchCalendar();
    }

    function onMouseEnterTooltip(e: EventTarget & HTMLButtonElement){
        let tooltip = new Tooltip(e, {trigger: "hover"})
        tooltip.show();

        e.addEventListener('click', () => tooltip.hide());
        e.addEventListener('mouseleave', () => tooltip.hide());
    }

    function eventMouseEnter(e: EventHoveringArg) {
        if (e.event.extendedProps.comment) {
            let tooltip = new Tooltip(e.el, {trigger: "hover", html: true, title: e.event.extendedProps.comment})
            tooltip.show();

            e.el.addEventListener('click', () => tooltip.hide());
            e.el.addEventListener('mouseleave', () => tooltip.hide());
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        if (!isLoading) {
            changeView(sm ? calendarTypes.LIST_WEEK_VIEW : calendarTypes.TIME_GRID_WEEK_VIEW)
        }
        return () => window.removeEventListener("resize", handleResize)
    }, [sm])

    useEffect(() => {
        fetchCalendar();
    }, [salary?.id, disableDemarcation])

    useEffect(() => {
        if (hasApi()) {
            getApi().gotoDate(pickerDate);
            setTitle(getApi().view.title)
        }
    }, [pickerDate])

    useEffect(() => {
        switch (calendar) {
            case calendarTypes.CALENDAR_EVENTS_SINGLE:
                setCalendarEvents([...events, ...offers.filter((o: any) => isOffers || o.eventType === calendarTypes.EVENT_TYPE_OFFER_VALIDATED), ...keepNotes, ...tasks]);
                break;
            case calendarTypes.CALENDAR_SIGNED_SINGLE:
                setCalendarEvents([...events, ...signed, ...keepNotes]);
                break;
        }

    }, [events, signed, isOffers])

    const TimeSheetSlot = () => {
        let timesheet = timeSheets.find((t: any) => t.salary?.id === salary?.id);

        if (timesheet) return (
            <button className="btn btn-light text-primary shadow-sm">
                <i className={'bi bi-clock'}></i> {timeFormat(timesheet.accountedWorkingTime)} {!!timesheet.contractWorkingTime && <>/ {timeFormat(timesheet.contractWorkingTime)}</>}
                
                {timesheet.counter !== undefined &&
                    <>
                        {timesheet.counter !== 0 ?
                            <span className={"ms-2"}>
                                {timesheet.counter > 0 ? <i className={'bi bi-arrow-up text-warning'}></i> : <i className={'bi bi-arrow-down text-danger'}></i>} {timeFormat(timesheet.counter)}
                            </span>
                            :
                            <i className={'bi bi-check text-success'}></i>
                        }
                    </>
                }
            </button>
        )

        return <></>
    }

    return (
        <>
            <div className={'row'}>
                <div className="col-auto mb-2">
                    <CalendarTitle
                        pickerDate={pickerDate}
                        endDate={endDate}
                        handlePickerDateChange={handlePickerDateChange}
                        title={title}
                        prevClick={prevClick}
                        nextClick={nextClick}
                    />
                </div>

                {HasModule('timeClock') && (Granted(accessRights.LIST_TIME_CLOCK, salary.id) || (Granted(accessRights.LIST_SELF_TIME_CLOCK, salary.id) && salary.id === getUser().currentSalary.id)) &&
                    <div className="col-auto mb-2">
                        <div className="btn-group">
                            <div className="dropdown">
                                <button
                                    className="btn btn-light text-primary shadow-sm dropdown-toggle"
                                    type="button"
                                    id="calendarDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {calendar === calendarTypes.CALENDAR_EVENTS_SINGLE && <>Planifié</>}
                                    {calendar === calendarTypes.CALENDAR_SIGNED_SINGLE && <>Badgé</>}
                                </button>

                                <ul className="dropdown-menu" aria-labelledby="calendarDropdown">
                                    <li className={'dropdown-item'} onClick={() => handleCalendarChange(calendarTypes.CALENDAR_EVENTS_SINGLE)}>
                                        {calendar === calendarTypes.CALENDAR_EVENTS_SINGLE && <i className={'bi bi-check'}></i>} Planifié
                                    </li>
                                    <li className={'dropdown-item'} onClick={() => handleCalendarChange(calendarTypes.CALENDAR_SIGNED_SINGLE)}>
                                        {calendar === calendarTypes.CALENDAR_SIGNED_SINGLE && <i className={'bi bi-check'}></i>} Badgé
                                    </li>
                                </ul>
                            </div>

                            {calendar === calendarTypes.CALENDAR_SIGNED_SINGLE &&
                                <button className={'btn btn-light'} onClick={() => setDisableDemarcation(p => !p)}>
                                    {disableDemarcation ? <><i className={"bi bi-x text-danger"}></i></> : <><i className={"bi bi-check text-success"}></i></>} Bornage
                                </button>
                            }
                        </div>
                    </div>
                }

                <div className="col-auto mb-2">
                    <div className="dropdown">
                        <button
                            className="btn btn-light text-primary shadow-sm dropdown-toggle"
                            type="button"
                            id="periodDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {view === 'timeGridDay' && <>Jour</>}
                            {view === 'timeGridWeek' && <>Semaine</>}
                            {view === 'dayGridMonth' && <>Mois</>}
                        </button>

                        <ul className="dropdown-menu" aria-labelledby="periodDropdown">
                            <li className={'dropdown-item'} onClick={() => changeView('timeGridDay')}>
                                Jour
                            </li>
                            <li className={'dropdown-item'} onClick={() => changeView('timeGridWeek')}>
                                Semaine
                            </li>
                            <li className={'dropdown-item'} onClick={() => changeView('dayGridMonth')}>
                                Mois
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-auto mb-2">
                    <button
                        tabIndex={0}
                        className="btn btn-light text-primary shadow-sm"
                        role="button"
                        data-bs-toggle="popover"
                        data-bs-trigger="focus"
                        title="Semaine en cours"
                        data-bs-content={companyWeeks.length ? companyWeeks.map(c => `<h4>${c.title}</h4><div class="clearfix"></div> <span class="text-primary">Semaine ${c.week}</span>`).join('<hr/>') : "Aucun établissement"}
                        onMouseEnter={e => {
                            let popover = new Popover(e.currentTarget, {
                                trigger: 'hover',
                                container: 'body',
                                html: true
                            })
                            popover.show();
                            e.currentTarget.addEventListener("mouseleave", () => popover.hide())
                        }}
                    >
                        <i className={'bi bi-shop'}></i>
                    </button>
                </div>

                <div className="col-auto mb-2">
                    <TimeSheetSlot />
                </div>

                <div className="col-auto mb-2">
                    <button
                        title={isNightTime ? "Horaires de jour" : "Horaires de nuit"}
                        onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}
                        className={'btn btn-light text-primary shadow-sm mx-1'}
                        onClick={() => handleNightTimeChange(!isNightTime)}
                    >
                        {isNightTime ? <i className={'bi bi-sun'}></i> : <i className={'bi bi-moon'}></i>}
                    </button>
                </div>

                {getVersion() === 0 && Granted(accessRights.EDIT_BOOKING, salary.id) &&
                    <div className="col-auto mb-2">
                        <button className="btn btn-light text-primary shadow-sm mx-1" onClick={() => dispatch(openSidebar(LIST_BOOKING_GROUP, {salary: salary}))}>
                            <i className={"bi bi-calendar-event"}></i>
                        </button>
                    </div>
                }

                {GrantedAny([accessRights.EDIT_TIME_CLOCK, accessRights.EDIT_BOOKING, accessRights.EDIT_ABSENCE, accessRights.EDIT_BOOKING_OFFER], salary.id) &&
                    <div className={'col-auto mb-2'}>
                        <div className="dropdown">
                            <button
                                className="btn btn-light text-primary shadow-sm mx-1 dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className={"bi bi-plus-circle"}></i> Ajouter
                            </button>

                            <ul className="dropdown-menu">
                                {Granted(accessRights.EDIT_BOOKING, salary.id) && calendar === calendarTypes.CALENDAR_EVENTS_SINGLE &&
                                    <li
                                        className={'dropdown-item'}
                                        onClick={() => {
                                            let API = calendarRef.current?.getApi();

                                            if (API){
                                                setCalendarEventSidebarProps(prev => ({
                                                    sidebarType: "EVENT_ADD",
                                                    dt: setHours(API!.getDate(), (new Date()).getHours()),
                                                    trigger: prev.trigger + 1,
                                                    salary: salary,
                                                    company: salary.companies[0]
                                                }))
                                            }
                                        }}
                                    >
                                    Ajouter un créneau
                                    </li>
                                }

                                {Granted(accessRights.EDIT_ABSENCE, salary.id) &&
                                    <li
                                        className={'dropdown-item'}
                                        onClick={() => setCalendarAbsenceSidebarProps(prev => ({
                                            sidebarType: "ABSENCE_ADD",
                                            trigger: prev.trigger + 1,
                                            salary: salary,
                                        }))}
                                    >
                                        Ajouter une absence
                                    </li>
                                }

                                {Granted(accessRights.EDIT_TASK) && calendar === calendarTypes.CALENDAR_EVENTS_SINGLE &&
                                    <li
                                        className={'dropdown-item'}
                                        onClick={() => setCalendarTaskSidebarProps(prev => ({
                                            sidebarType: "TASK_ADD",
                                            trigger: prev.trigger + 1,
                                            salary: salary
                                        }))}
                                    >
                                        Ajouter une tâche
                                    </li>
                                }

                                {calendarTypes.CALENDAR_SIGNED_SINGLE === calendar && Granted(accessRights.EDIT_TIME_CLOCK, salary.id) &&
                                    <li
                                        className={'dropdown-item'}
                                        onClick={() => setCalendarSignedSidebarProps(prev => ({
                                            sidebarType: "SIGNED_ADD",
                                            trigger: prev.trigger + 1,
                                            salary: salary,
                                            company: salary.companies[0]
                                        }))}
                                    >
                                        Ajouter un badgeage
                                    </li>
                                }

                                {calendarTypes.CALENDAR_EVENTS_SINGLE === calendar && Granted(accessRights.EDIT_BOOKING_OFFER, salary.id) &&
                                    <li
                                        className={'dropdown-item'}
                                        onClick={() => setCalendarOfferSidebarProps(prev => ({
                                            sidebarType: "OFFER_ADD",
                                            trigger: prev.trigger + 1,
                                            salary: salary,
                                            company: salary.companies[0]
                                        }))}
                                    >
                                        Ajouter une offre de créneau
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                }

                {Granted(accessRights.LIST_BOOKING_OFFER, salary.id) && calendar === calendarTypes.CALENDAR_EVENTS_SINGLE &&
                    <div className={'col-auto mb-2'}>
                        <button
                            type="button"
                            className={'btn shadow-sm mx-1' + (isOffers ? ' btn-primary text-white' : ' btn-light text-primary')}
                            onClick={() => setIsOffers(prev => !prev)}
                            data-bs-placement="top"
                            title="Offres de créneaux"
                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                        >
                            <i className={'bi bi-star-fill'}></i>
                            <span className={'badge bg-warning'}>
                                {offers.filter((o: any) => o.eventType === calendarTypes.EVENT_TYPE_OFFER_PENDING).length}
                            </span>
                        </button>

                        {Granted(accessRights.PUBLISH_CALENDAR) &&
                            <button
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasCalendarPublish"
                                data-bs-placement="top"
                                title="Publier"
                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                className={'btn btn-light text-primary shadow-sm mx-1'}
                            >
                                <i className="bi bi-send"> </i>
                            </button>
                        }
                    </div>}
            </div>

            <div style={{minHeight: 400}} className={'h-100'}>
                <FullCalendar
                    key={salary.id}
                    ref={calendarRef}
                    allDaySlot={!!keepNotes.length}
                    slotMinWidth={40}
                    viewDidMount={(arg) => setTitle(arg.view.title)}
                    plugins={[interactionPlugin, rrulePlugin, timeGridPlugin, dayGridPlugin, resourceTimeGridPlugin, listPlugin]}
                    lazyFetching={false}
                    headerToolbar={false}
                    displayEventEnd={true}
                    initialView={view}
                    height={'100%'}
                    events={calendarEvents}
                    resourceGroupField={'groupId'}
                    displayEventTime={true}
                    eventContent={renderEvent}
                    hiddenDays={state.auth.user.calendarSettings.daysOfWeek}
                    slotMinTime={timeRange.minTime}
                    slotMaxTime={timeRange.maxTime}
                    locales={allLocales}
                    locale={'fr'}
                    schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                    editable={Granted(accessRights.EDIT_BOOKING, salary.id)}
                    selectable={Granted(accessRights.EDIT_BOOKING, salary.id)}
                    select={onSelect}
                    eventResize={onResize}
                    eventClick={onClick}
                    eventDrop={onDrop}
                    scrollTime={null}
                    eventMouseEnter={eventMouseEnter}
                    weekNumbers={true}
                    visibleRange={{
                        start: start ?  format(start, 'uuuu-MM-dd') : undefined,
                        end:   end ? format(end, 'uuuu-MM-dd') : undefined
                    }}
                />
            </div>

            <CalendarEventSidebar handleEventChange={handleEventChange} {...calendarEventSidebarProps} />
            <CalendarBookingSidebar handleEventChange={handleEventChange} {...calendarBookingSidebarProps} />
            <CalendarSignedSidebar handleEventChange={handleEventChange} {...calendarSignedSidebarProps} />
            <CalendarAbsenceSidebar handleEventChange={handleEventChange} {...calendarAbsenceSidebarProps} />
            <CalendarOfferSidebar handleEventChange={handleEventChange} {...calendarOfferSidebarProps} />
            <CalendarTemplateSidebar handleEventChange={handleEventChange} {...calendarTemplateSidebarProps} />
            <CalendarTaskSidebar key={"calendarSingle"} referer={'single'}  handleEventChange={handleEventChange} {...calendarTaskSidebarProps} />
            <CalendarPublish key={salary.id} dt={start} type={"single"} salary={salary} />

            {isLoading && <Loader type={'grow'} />}
        </>
    );
}

export default CalendarSingle;