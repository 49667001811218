import React, {useEffect, useState} from "react";
import iconsFile from "./icon.json"

interface iconPickerProps {
    onChange: (choice: string) => void,
    selected?: string,
    icons?: string[]
}

const IconPicker:React.FC<iconPickerProps> = (props) => {
    const {onChange, icons, selected} = props;

    const [all, setAll] = useState<boolean>(false)
    const [items, setItems] = useState<string[]>([])

    useEffect(() => {
        if (!icons){
            setItems([
                'bi bi-basket3',
                'bi bi-brightness-alt-high',
                'bi bi-cart',
                'bi bi-cash-coin',
                'bi bi-clipboard-check',
                'bi bi-clock',
                'bi bi-exclamation-octagon',
                'bi bi-eyedropper',
                'bi bi-folder-check',
                'bi bi-headset',
                'bi bi-shop',
                'bi bi-mailbox',
                'bi bi-moon',
                'bi bi-people',
                'bi bi-telephone',
            ]);
        }else{
            setItems([...icons])
        }
    }, [])

    if (all){
        let allIcons: string[] = iconsFile;
        return <div className={'h-100 w-100 position-absolute bg-white top-0 end-0'}>
            <div className="row">
                {allIcons.map((iconClass, index) => {
                    return <div className={'col-4'}>
                        <input type="radio" name={'icon'} onChange={(ev) => {
                            setAll(false);
                            onChange(iconClass)
                        }} value={iconClass} className="btn-check" id={`btn-check-outlined-icon-${index}`}
                               autoComplete="off" defaultChecked={iconClass === selected}/>
                        <label className="w-100  bg-light text-center p-3 m-1" htmlFor={`btn-check-outlined-icon-${index}`}>
                            <i className={'fs-2 bi ' + iconClass}> </i>
                        </label>
                    </div>
                })}
            </div>
            <button type={'button'} onClick={() => setAll(false)} className={'btn btn-outline-primary w-100 bg-white position-sticky bottom-0'}>
                <i className={'bi bi-dash'}> </i> Voir moins d'icons
            </button>
        </div>
    }

    return (<div className={'row g-0'}>
            {items.map((iconClass, index) => {
                return <div className={'col-auto'}>
                    <input type="radio" name={'icon'} onChange={(ev) => onChange(iconClass)} value={iconClass} className="btn-check" id={`btn-check-outlined-icon-${index}`}
                           autoComplete="off" defaultChecked={iconClass === selected}/>
                    <label className="btn btn-outline-primary m-1" htmlFor={`btn-check-outlined-icon-${index}`}>
                        <i className={iconClass}> </i>
                    </label>
                </div>
            })}
            {selected && !items.includes(selected) && <div className={'col-auto'}>
                <input type="radio" name={'icon'} onChange={(ev) => onChange(selected)} value={selected} className="btn-check" id={`btn-check-outlined-icon-${selected}`}
                       autoComplete="off" checked={true}/>
                <label className="btn btn-outline-primary m-1" htmlFor={`btn-check-outlined-icon-${selected}`}>
                    <i className={selected}> </i>
                </label>
            </div>}
            <button type={'button'} onClick={() => setAll(true)} className={'btn btn-outline-primary w-auto'}>
                <i className={'bi bi-plus'}> </i> Voir plus d'icônes
            </button>
        </div>
    )
}

export default IconPicker;