import React from "react";
import {Link} from "react-router-dom";


const ExportList:React.FC = () => {



    return <div className={'container mx-auto'}>
        <div className="row mt-4">
            <div className="col-md-2">
                <Link to={"/export/silae"} className="card shadow-sm p-1 p-md-3 text-center">
                    <img src={process.env.PUBLIC_URL + "/img/silae/logo.jpg"} alt="" className="w-100"/>
                    <h4>
                        Silae
                    </h4>
                </Link>
            </div>
        </div>
    </div>
}

export default ExportList;