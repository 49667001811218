import * as types from "../../../constants/template/applied";
import * as models from "../../../models/template/applied";

export const addTemplateApplied = () => {
    return {
        type: types.ADD_TEMPLATE_APPLIED,
    }
}

export const addTemplateAppliedSuccess = (template: models.TemplateApplied) => {
    return {
        type: types.ADD_TEMPLATE_APPLIED_SUCCESS,
        template: template,
    }
}

export const addTemplateAppliedFailure = (error: string) => {
    return {
        type: types.ADD_TEMPLATE_APPLIED_FAILURE,
        template: null,
        templates: null,
        error: error
    }
}

export const editTemplateApplied = () => {
    return {
        type: types.EDIT_TEMPLATE_APPLIED,
    }
}

export const editTemplateAppliedSuccess = (template: models.TemplateApplied) => {
    return {
        type: types.EDIT_TEMPLATE_APPLIED_SUCCESS,
        template: template,
    }
}

export const fetchTemplatesApplies = () => {
    return {
        type: types.FETCH_TEMPLATE_APPLIES,
    }
}

export const fetchTemplatesAppliesFailure = (error: string) => {
    return {
        type: types.FETCH_TEMPLATE_APPLIES_FAILURE,
        template: null,
        templates: [],
        error: error
    }
}

export const fetchTemplatesAppliesSuccess = (templates: models.TemplateApplies) => {
    return {
        type: types.FETCH_TEMPLATE_APPLIES_SUCCESS,
        template: null,
        templates: templates
    }
}


export const showTemplateApplied = () => {
    return {
        type: types.SHOW_TEMPLATE_APPLIED,
    }
}
export const showTemplateAppliedSuccess = (template: models.TemplateApplied) => {
    return {
        type: types.SHOW_TEMPLATE_APPLIED_SUCCESS,
        template: template
    }
}
export const showTemplateAppliedFailure = (error: string) => {
    return {
        type: types.SHOW_TEMPLATE_APPLIED_FAILURE,
        template: null,
        templates: null,
        error: error
    }
}