import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/template";
import * as actions from "../../../actions/template";
import {Template} from "../../../models/template";
import {showTemplateSuccess} from "../../../actions/template";
import {closeSidebar} from "../../../actions/rightSidebar";
import Loader from "../../../utils/loader";
import {Link} from "react-router-dom";
import {axiosError} from "../../../actions/axios";

const TemplateSidebarList: React.FC = () => {

    const state = useSelector((state: RootStateOrAny)  => state.template);
    const dispatch = useDispatch();

    useEffect(() => {
            dispatch(actions.fetchTemplates());
            api.list().then(data => {
                dispatch(actions.fetchTemplatesSuccess(data.data))
            }).catch(error => dispatch(axiosError(error)))
    }, [])

    return <>
        {state.isLoading ? <Loader /> : <ul className={'list-group list-group-flush'}>
            {state.payload.length && state.payload.map((template: Template) =>
                    <Link to={`/template/${template.id}`} className={"list-group-item list-group-item-action" + (template.id === state.single?.id ? ' active' : '')}>
                        <div className="d-flex align-items-center">
                            <div className="col">
                            <span className={'h5'}>
                                {template.title}
                            </span>
                                <div className="clearfix"> </div>
                                <i>
                                    <small>
                                        {template.description}
                                    </small>
                                </i>
                            </div>
                            <div className="col-auto">
                                <i className={'bi bi-shop'}> </i> {template.company.title}
                                <div className="clearfix"> </div>
                                {template.visibility === 1 ? <i className={'bi bi-lock'}> </i> : <i className={'bi bi-unlock'}> </i>}
                                Par {template.owner.title}
                            </div>
                        </div>
                    </Link>)}
        </ul>}

    </>;
}

export default TemplateSidebarList;