import React, {useEffect, useState} from "react";
import {Company} from "../../../../models/companies";
import * as api from "../../../../adapters/template"
import * as appliedApi from "../../../../adapters/template/applied"
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import getUser from "../../../../security/getUser";
import {Template, Templates} from "../../../../models/template";
import SmallLoader from "../../../../utils/loader/small";
import format from "../../../../utils/locale";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../actions/alert";
import {getISOWeeks, range} from "../../../../constants/global";
import FormError from "../../../../utils/formError";

interface Interface {
    company: Company,
    start: Date,
    end: Date,
    handleSubmitSuccess: (e: any) => any;
    close: () => any;
}

const CalendarTemplateImport:React.FC<Interface> = (props) => {

    const dispatch = useDispatch()
    const [company, setCompany] = useState<number>(props.company.id!);
    const [templates, setTemplates] = useState<Templates>([]);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [year, setYear] = useState<number>(Number(format(props.start, 'uuuu')));
    const [week, setWeek] = useState<number>(Number(format(props.start, 'w')));

    useEffect(() => {
        setTemplates([])
        setLoading(true)
        api.list({companies: [company]})
            .then(data => {
                setLoading(false)
                setTemplates(data.data)
            })
    }, [company])

    const handleClick = (t: Template) => {
        setSubmitting(true)
        appliedApi.create({year: year, week: week}, t.id)
            .then(resp => {
                Promise.all([
                    props.handleSubmitSuccess(resp.data)
                ]).then(() => {
                    dispatch(showAlertSuccess('Prévisualisation activée'))
                    setSubmitting(false)
                    props.close();
                })
            })
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarTemplateImportLabel">Importer un template</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <BootstrapSelect
                            label={"Établissement"}
                            required={true}
                            value={{label: props.company.title, value: props.company.id!}}
                            options={getUser().currentSalary.companies.map((c: Company) => ({label: c.title, value: c.id}))}
                            onChange={c => setCompany(Number(c?.value))}
                        />
                    </div>
                    <div className="col">
                        <BootstrapSelect
                            label={'Semaine'}
                            options={range(1, getISOWeeks(year)).map(week => ({
                                label: String(week),
                                value: week
                            }))}
                            enableReinitialize={true}
                            value={{label: String(week), value: week}}
                            onChange={(c) => setWeek(Number(c?.value))}
                            required={true}
                        />
                    </div>
                    <div className="col">
                        <BootstrapSelect
                            label={'Année'}
                            options={range(year - 5, year + 5).map(y => ({label: String(y), value: y.toString()}))}
                            value={{label: String(year), value: year.toString()}}
                            onChange={(c) => setYear(Number(c?.value))}
                            required={true}
                        />
                    </div>
                </div>
            </div>
            <ul className={"list-group list-group-flush"}>
                {loading && <li className={'list-group-item d-flex align-items-center'}>
                    <SmallLoader /> <div className="flex-grow-1 ms-2">Chargement des templates</div>
                </li>}
                {!loading && !templates.length && <li className={'list-group-item d-flex'}>
                    <i className={'bi bi-search'}></i> Aucun template trouvé
                </li>}
                {templates.map(t => <li className={'list-group-item d-flex'}>
                    <div className="flex-grow-1">
                        <h4>
                            {t.title}
                        </h4>
                    </div>
                    <div className="flex-grow-0">
                        <button disabled={submitting} className={'btn btn-light'} onClick={() => handleClick(t)}>
                            <i className={'bi bi-eye text-success'}></i> Prévisualiser
                        </button>
                    </div>
                </li>)}
            </ul>
        </div>
    </>
}

export default CalendarTemplateImport;