import * as types from "../../constants/companies";
import * as models from "../../models/companies";

export const addCompany = () => {
    return {
        type: types.ADD_COMPANY,
    }
}

export const addCompanySuccess = (company: models.Company) => {
    return {
        type: types.ADD_COMPANY_SUCCESS,
        company,
    }
}

export const editCompany = (company: models.Company) => {
    return {
        type: types.EDIT_COMPANY,
        company: company
    }
}

export const editCompanySuccess = (company: models.Company) => {
    return {
        type: types.EDIT_COMPANY_SUCCESS,
        company: company
    }
}

export const showCompany = () => {
    return {
        type: types.SHOW_COMPANY,
    }
}

export const showCompanySuccess = (company: models.Company) => {
    return {
        type: types.SHOW_COMPANY_SUCCESS,
        company: company
    }
}

export const fetchCompaniesSuccess = (companies: models.Companies) => {
    return {
        type: types.FETCH_COMPANIES_SUCCESS,
        company: null,
        companies: companies
    }
}

export const fetchCompanies = () => {
    return {
        type: types.FETCH_COMPANIES,
    }
}

export const fetchCompaniesFailure = (error: string) => {
    return {
        type: types.FETCH_COMPANIES_FAILURE,
        company: null,
        companies : null,
        error: error
    }
}
