import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ManagerShow from "../manageur/show";
import SalarySettings from "../settings";
import SalaryRepartitions from "../repartitions";
import FileSaver from "file-saver";
import SalaryTimeClockToken from "./qr";

import { Salary } from "../../../../models/salary";

import * as types from "../../../../constants/salary";
import { FORM_EDIT_SALARY, FORM_EDIT_SALARY_INFORMATION, SALARY_DEFAULT_LABEL_SELECTOR } from "../../../../constants/rightSidebar";
import * as accessRights from "../../../../constants/accessRight";

import * as api from "../../../../adapters/salary";
import * as salaryUserApi from "../../../../adapters/salary/account";
import * as defaultLabelApi from "../../../../adapters/salary/defaultLabel";
import { editSalarySuccess } from "../../../../actions/salary";
import { openSidebar } from "../../../../actions/rightSidebar";
import { setHelpProductSheetIds } from "../../../../actions/header";
import { showAlertSuccess } from "../../../../actions/alert";
import { axiosError } from "../../../../actions/axios";

import { Granted } from "../../../../security/granted";
import HasModule from "../../../../security/hasModule";
import format from "../../../../utils/locale";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";

const SalaryInformation: React.FC = () => {
    const dispatch = useDispatch();

    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single);

    const removeActivity = (id: number) => {
        defaultLabelApi.removeActivity(salary.id, id).then(data => dispatch(editSalarySuccess(data.data)))
    }

    const removeLocalisation = (id: number) => {
        defaultLabelApi.removeLocalisation(salary.id, id).then(data => dispatch(editSalarySuccess(data.data)))
    }

    function downloadQr(){
        api.qr(salary.id).then(resp => {
            let newBlob = b64toBlob(resp.data.base64, 'image/png')
            FileSaver.saveAs(newBlob, `qr-code-${salary.id}-${salary.title}.png`)
        })
    }

    const b64toBlob = (b64Data: any, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    useEffect(() => {
        return(() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [])

    return (
        <>
            <div className="col-12">
                <div className="">
                    {!salary.user && !salary.email && <>
                        <div className="alert alert-info">
                            <i className={'bi bi-info-circle'}> </i> Vous pouvez associer un compte au collaborateur pour qu'il puisse accéder à sivan après lui avoir renseigné un email
                        </div>
                    </>}
                    {!salary.user && salary.email && <>
                        <div className="alert alert-info d-flex align-items-center">
                            <div className={'flex-grow-1'}>
                                <i className={'bi bi-info-circle'}> </i> Vous pouvez associer un compte au collaborateur pour qu'il puisse accéder à sivan
                            </div>
                            <button onClick={event => {
                                event.currentTarget.disabled = true;
                                salaryUserApi.createUser(salary.id)
                                    .then(data => {
                                        dispatch(editSalarySuccess(data.data));
                                        event.currentTarget.disabled = false;
                                    }).catch(error => dispatch(axiosError(error)))
                            }} className={"btn btn-success text-white"}>
                                Associer un compte
                            </button>
                        </div>
                    </>}
                    {salary.user && !salary.user.activated && <>
                        {salary.email !== salary.user.email && <>
                            <div className={"alert alert-warning d-flex align-items-center mb-3"}>
                                <div className="flex-grow-1">
                                    <i className={'bi bi-exclamation-triangle'}> </i> Le mail renseigné sur le collaborateur ne correspond pas à celui du compte associé
                                    <div className="clerafix"> </div>
                                    <ul>
                                        <li>
                                            Mail collaborateur : {salary.email}
                                        </li>
                                        <li>
                                            Mail compte associé : {salary.user.email}
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex-grow-0">
                                    <button onClick={event => {
                                        event.currentTarget.disabled = true;
                                        salaryUserApi.editUserEmail(salary.id)
                                            .then(data => {
                                                dispatch(editSalarySuccess(data.data));
                                                event.currentTarget.disabled = false;
                                            }).catch(error => dispatch(axiosError(error)))
                                    }} className={'btn btn-warning text-white'}>
                                        Mettre l'email à jour
                                    </button>
                                </div>
                            </div>
                        </>}
                        <div className="alert alert-danger d-flex align-items-center">
                            <div className="flex-grow-1">
                                <i className={"bi bi-x-circle"}> </i> Le compte est désactivé
                            </div>
                            <button onClick={event => {
                                event.currentTarget.disabled = true;
                                salaryUserApi.confirmUser(salary.id)
                                    .then(data => {
                                        dispatch(showAlertSuccess('Mail de confirmation envoyé !'));
                                        event.currentTarget.disabled = false;
                                    }).catch(error => dispatch(axiosError(error)))
                            }} className={"btn btn-primary text-white flex-grow-0"}>
                                <i className={'bi bi-send'}> </i> Renvoyer le mail d'activation à {salary.user.email}
                            </button>
                        </div>
                    </>}
                    {salary.user && salary.user.activated && <>
                        <div className="d-flex alert alert-success align-items-center">
                            <div className="flex-grow-1">
                                <h4 className="mb-0">
                                    <i className={'bi bi-check2-circle text-success'}> </i> Compte activé
                                </h4>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
            <div className="d-flex align-items-start">
                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button className="nav-link active" id="v-pills-perso-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-perso" type="button" role="tab" aria-controls="v-pills-perso"
                            aria-selected="true">Informations personnelles
                    </button>
                    <button className="nav-link" id="v-pills-pro-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-pro" type="button" role="tab" aria-controls="v-pills-pro"
                            aria-selected="false">Informations professionelles
                    </button>
                    <button className="nav-link" id="v-pills-etiquette-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-etiquette" type="button" role="tab" aria-controls="v-pills-etiquette"
                            aria-selected="false">Étiquettes par défaut
                    </button>
                    <button className="nav-link" id="v-pills-manageur-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-manageur" type="button" role="tab" aria-controls="v-pills-manageur"
                            aria-selected="false">Manageurs
                    </button>
                    <button
                        className="nav-link"
                        id="v-pills-settings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-settings"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-settings"
                        aria-selected="false"
                        onClick={() => dispatch(setHelpProductSheetIds([4]))}
                    >
                        Réglages
                    </button>
                    <button
                        className="nav-link"
                        id="v-pills-repartitions-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-repartitions"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-repartitions"
                        aria-selected="false"
                        onClick={() => dispatch(setHelpProductSheetIds([]))}
                    >
                        Répartition des HC
                    </button>
                </div>
                <div className="tab-content flex-grow-1" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="v-pills-perso" role="tabpanel"
                        aria-labelledby="v-pills-perso-tab">
                        <div className="p-1 p-md-3 card shadow-sm h-100">
                            <div className="row">
                                <div className="col">
                                    <h4>
                                        Informations personnelles
                                    </h4>
                                </div>
                                {Granted(accessRights.EDIT_SALARY, salary.id) && <div className="col-auto">
                                    <button className={'btn btn-outline-primary'}
                                            onClick={() => dispatch(openSidebar(FORM_EDIT_SALARY, {
                                                salary
                                            }))}>
                                        <i className={'bi bi-pencil'}> </i> Modifier
                                    </button>
                                </div>}
                            </div>
                            <table className={'table mb-0'}>
                                <tbody>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-shield'}> </i> Droits d'accès
                                    </td>
                                    <td className={'d-flex align-items-center'}>
                                        <div className="flex-grow-1">
                                            {Granted(accessRights.EDIT_ACCESS_LEVEL, salary.id) ? <>
                                                <BootstrapSelect
                                                    fetchEntity={"accessLevel"}
                                                    key={salary.id}
                                                    value={{label: salary.accessLevel.title, value: salary.accessLevel.id}}
                                                    enableReinitialize={true}
                                                    onChange={(c) => {
                                                        api.updateAccess(salary.id, Number(c!.value))
                                                            .then(resp => dispatch(editSalarySuccess(resp.data)))
                                                    }}
                                                    required={true}
                                                    notFloating={true}
                                                />
                                            </> : salary.accessLevel.title}
                                        </div>
                                        <Link to={`/access_level/${salary.accessLevel.id}`}>
                                            <i className="bi bi-box-arrow-up-right ms-3"></i>
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-phone'}> </i>  Tél
                                    </td>
                                    <td>
                                        {salary.tel}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-envelope'}> </i> Email
                                    </td>
                                    <td>
                                        {salary.email || <i className={"bi bi-x"}> </i>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-gift'}> </i>  Date de naissance
                                    </td>
                                    <td>
                                        {format(new Date(salary.birthdate))}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-house'}> </i> Adresse
                                    </td>
                                    <td>
                                        {salary.address?.address}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-geo'}> </i> Ville
                                    </td>
                                    <td>
                                        {salary.address?.city}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-sort-numeric-up'}> </i>  Code postal
                                    </td>
                                    <td>
                                        {salary.address?.zip}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-shop'}> </i> <span className={'rounded-pill text-white bg-primary p-1'}>{salary.companies.length}</span> Point de vente
                                    </td>
                                    <td>
                                        {salary.companies.map((company, index) => <span className={'text-capitalize'}>{company.title}{index + 1 < salary.companies.length && <>, </>}</span>)}
                                    </td>
                                </tr>
                                {HasModule('timeClock') && <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-tablet'}> </i>
                                        Code badgeuse
                                    </td>
                                    <td>
                                        {salary.timeClockToken}
                                        {salary.timeClockToken && <div className={"btn-group"}>
                                            <button
                                                title={"Télécharger le qr code"}
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                className={'btn btn-sm btn-light ms-2'} onClick={() => downloadQr()}>
                                                <i className={'bi bi-qr-code'}></i>
                                            </button>
                                            <button
                                                title={"Modifier le code"}
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                data-bs-toggle={"modal"}
                                                data-bs-target={"#timeClockModal"}
                                                className={'btn btn-sm btn-light ms-2'}>
                                                <i className={'bi bi-pencil'}></i>
                                            </button>
                                        </div>}
                                    </td>
                                </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-pro" role="tabpanel"
                        aria-labelledby="v-pills-pro-tab">
                        <div className="p-3 card shadow-sm h-100">
                            <div className="row">
                                <div className="col">
                                    <h4>
                                        Informations professionelles
                                    </h4>
                                </div>
                                {Granted(accessRights.EDIT_SALARY, salary.id) && <div className="col-auto">
                                    <button className={'btn btn-outline-primary'}
                                            onClick={() => dispatch(openSidebar(FORM_EDIT_SALARY_INFORMATION, {
                                                salary
                                            }))}>
                                        <i className={'bi bi-pencil'}> </i> Modifier
                                    </button>
                                </div>}
                            </div>
                            <table className={'table mb-0'}>
                                <tbody>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-person-bounding-box'}> </i>  Métier
                                    </td>
                                    <td>
                                        {salary.information?.job.title}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-file-check'}> </i>  Contrat
                                    </td>
                                    <td>
                                        {types.getContractChoices().find(choice => choice.value === salary.information?.contract)?.label}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-calendar-check'}> </i>  Date de début de contrat
                                    </td>
                                    <td>
                                        {salary.information?.startDate && format(new Date(salary.information?.startDate))}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-calendar-x'}> </i>  Date de fin de contrat
                                    </td>
                                    <td>
                                        {salary.information?.endDate ? format(new Date(salary.information?.endDate)) : <i className={"bi bi-x"}> </i>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-list-ol'}> </i> Forfait
                                    </td>
                                    <td>
                                        {types.getFlatRateChoices().find(choice => choice.value === salary.information?.flatRate)?.label}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-cash-coin'}> </i>  Taux horaire
                                    </td>
                                    <td>
                                        {salary.information?.hourlyRate} €
                                    </td>
                                </tr>
                                {salary.information?.flatRate === types.FLAT_RATE_HC && <tr>
                                    <td className={'text-primary'}>
                                        <i className={'bi bi-clock'}> </i> Heures contrat
                                    </td>
                                    <td>
                                        {salary.information.contractWorkingTime}H
                                    </td>
                                </tr>}
                                {salary.information?.flatRate === types.FLAT_RATE_DAILY && <tr>
                                    <td className={'text-primary'}>
                                        Jours ouvrés annuel
                                    </td>
                                    <td>
                                        {salary.information.dayAnnualFlatRate}
                                    </td>
                                </tr>}
                                <tr>
                                    <td className={'text-primary'}>
                                        Base compteur d'heure
                                    </td>
                                    <td>
                                        {salary.information?.overtimeBasis || 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary'}>
                                        Base congés payés
                                    </td>
                                    <td>
                                        {salary.information?.absenceBasis || 0}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-etiquette" role="tabpanel"
                        aria-labelledby="v-pills-etiquette-tab">
                        <div className="card shadow-sm h-100 p-1 p-md-3">
                            <div className="row mb-3">
                                <div className="col">
                                    <h4>
                                        Étiquettes par défaut (Emplacements & Activités)
                                    </h4>
                                </div>
                            </div>
                            <table className={"table text-center"}>
                                <thead>
                                <th>
                                    <i className={'text-primary bi bi-shop'}> </i>  Point de vente
                                </th>
                                <th>
                                    <i className={'text-primary bi bi-geo-alt'}> </i>  Emplacement
                                </th>
                                <th>
                                    <i className={'text-primary bi bi-bookmark'}> </i>  Activité
                                </th>
                                </thead>
                                <tbody>
                                {salary.companies.map(c => {
                                    let companyDefaultLabel = salary.defaultLabels?.find(d => d.company.id === c.id);

                                    return <>
                                        <tr>
                                            <td>
                                                {c.title}
                                            </td>
                                            <td>
                                                {!!companyDefaultLabel?.localisation ?
                                                    <>
                                                    {companyDefaultLabel.localisation.title}
                                                        {Granted(accessRights.EDIT_BOOKING, salary.id) && <>
                                                            <button
                                                                className={'btn btn-light'} onClick={() => {
                                                                dispatch(openSidebar(SALARY_DEFAULT_LABEL_SELECTOR, {
                                                                    type: 'localisation',
                                                                    company: c,
                                                                    value: companyDefaultLabel?.localisation
                                                                }))
                                                            }}><i className={'bi bi-pencil text-primary'}></i>
                                                            </button>
                                                            <button
                                                                onClick={() => removeLocalisation(companyDefaultLabel!.id!)}
                                                                className={'btn btn-light'}>
                                                                <i className={'bi bi-dash-circle text-danger'}></i>
                                                            </button>
                                                        </>}
                                                    </> :
                                                    Granted(accessRights.EDIT_BOOKING, salary.id) &&  <button className={'btn btn-light'} onClick={() => {
                                                    dispatch(openSidebar(SALARY_DEFAULT_LABEL_SELECTOR, {
                                                    type: 'localisation',
                                                    company: c,
                                                }))
                                                }}>
                                                    <i className={"bi bi-plus-circle text-primary"}></i></button>}
                                            </td>
                                            <td>
                                                {!!companyDefaultLabel?.activity ?
                                                    <>
                                                        {companyDefaultLabel.activity.title}
                                                        {Granted(accessRights.EDIT_BOOKING, salary.id) && <>
                                                            <button
                                                                className={'btn btn-light'} onClick={() => {
                                                                dispatch(openSidebar(SALARY_DEFAULT_LABEL_SELECTOR, {
                                                                    type: 'activity',
                                                                    company: c,
                                                                    value: companyDefaultLabel?.activity
                                                                }))
                                                            }}><i className={'bi bi-pencil text-primary'}></i>
                                                            </button>
                                                            <button
                                                                onClick={() => removeActivity(companyDefaultLabel!.id!)}
                                                                className={'btn btn-light'}>
                                                                <i className={'bi bi-dash-circle text-danger'}></i>
                                                            </button>
                                                        </>}
                                                    </> :
                                                    Granted(accessRights.EDIT_BOOKING, salary.id) &&  <button className={'btn btn-light'} onClick={() => {
                                                        dispatch(openSidebar(SALARY_DEFAULT_LABEL_SELECTOR, {
                                                            type: 'activity',
                                                            company: c,
                                                        }))
                                                    }}>
                                                        <i className={"bi bi-plus-circle text-primary"}></i></button>}
                                            </td>
                                        </tr>
                                    </>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-manageur" role="tabpanel"
                        aria-labelledby="v-pills-manageur-tab">
                        <ManagerShow />
                    </div>
                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel"
                        aria-labelledby="v-pills-settings-tab">
                        <SalarySettings/>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-repartitions"
                        role="tabpanel"
                        aria-labelledby="v-pills-repartitions-tab"
                    >
                        <SalaryRepartitions />
                    </div>
                </div>
            </div>

            <SalaryTimeClockToken salary={salary} key={salary.id} />
        </>
    )
}

export default SalaryInformation;