import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Expenses, Expense} from "../../../../models/expense";
import * as types from "../../../../constants/expense";
import * as globals from "../../../../constants/global";
import * as api from "../../../../adapters/expense";
import * as actions from "../../../../actions/expense";
import {Granted} from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight";
import format from "../../../../utils/locale";

const DashboardExpenseRequestList:React.FC = () => {

    const expenses: Expenses = useSelector((state: RootStateOrAny) => state.expense.payload);
    const dispatch = useDispatch();

    const [statusLoading, setStatusLoading] = useState(false)

    const manageStatus = (status: number, expense: Expense) => {
        setStatusLoading(true)
        api.editStatus({status: status}, expense.id).then(resp => dispatch(actions.editExpenseSuccess(resp.data))).then(() => setStatusLoading(false))
    }

    return <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasExpenseRequest" aria-labelledby="offcanvasExpenseRequestLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasExpenseRequestLabel">Demandes de note de frais</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <ul className={'list-group list-group-flush'}>
                {expenses.filter(expense => expense.status === types.STATUS_PENDING).map(expense => <li className={'list-group-item'}>
                    <div className="d-flex align-items-center">
                        <div className="col d-flex flex-column">
                            <h4 className={'mb-0'}>
                                {expense.salary.title} | <span className={'text-primary'}>{types.getTypeChoice(expense.type)?.label}</span>
                            </h4>
                            <p className={'mb-0'}> {expense.title}</p>
                            <p className={'mb-0 form-text'}> {format(new Date(expense.dueDate))}</p>
                            <div className="">
                                {expense.amount} {globals.CurrencyIcon(expense.currency)}
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex flex-column">
                                <p className="mb-0">
                                    <div className="dropdown me-3">
                                        <button disabled={statusLoading || !Granted(accessRights.EDIT_EXPENSE, undefined, expense)} className={"btn btn-light dropdown-toggle" + (!Granted(accessRights.EDIT_EXPENSE, undefined, expense) ? " dropdown-toggle-no-after-content " : '')} data-bs-toggle="dropdown" aria-expanded="false" type={'button'}>
                                            <i className={types.getStatusIcon(expense.status)}></i> {types.STATUS_NAMES[expense.status]}
                                        </button>
                                        <ul className="dropdown-menu">
                                            {types.getStatusChoices().filter(c => c.value !== expense.status).map(c => <li
                                                onClick={() => manageStatus(c.value, expense)}
                                                className={'dropdown-item'}>
                                                <i className={types.getStatusIcon(c.value)}></i> {types.STATUS_NAMES[c.value]}
                                            </li>)}
                                        </ul>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </li>)}
            </ul>
        </div>
    </div>
}

export default DashboardExpenseRequestList;