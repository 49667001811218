import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/channel";
import * as api from "../../../adapters/channel";
import * as Yup from "yup";
import {Field, FieldProps, Form, Formik, useFormik} from "formik";
import {closeSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import {SalarySelected, SalarySelector} from "../../../utils/salarySelector";
import FormError from "../../../utils/formError";
import {axiosError} from "../../../actions/axios";

const ChannelSchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
    description: Yup.string().nullable(),
    salaries: Yup.array().of(Yup.number()).required('Veuillez choisir au moins un collaborateur.')
});

export const ChannelAdd: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const [displaySalarySelector, setDisplaySalarySelector] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            salaries: [],
        },
        validationSchema: ChannelSchema,
        onSubmit: values => {
            dispatch(actions.addChannel());
            api.create(values).then(data => {
                dispatch(actions.addChannelSuccess(data.data))
                dispatch(showAlertSuccess(data.data.title + ' à bien été créée !'))
                dispatch(closeSidebar());
            }).catch(error => dispatch(axiosError(error)))
        }
    });

    if (displaySalarySelector){
        return <SalarySelector
            onSubmit={(ids: number[]) => {
                formik.setFieldValue('salaries', ids)
                setDisplaySalarySelector(false)
            }}
            onClose={() => setDisplaySalarySelector(false)}
            initialValues={formik.values.salaries}
        />;
    }


    return <form className='p-3' onSubmit={formik.handleSubmit}>
        <div className="form-floating mb-3">
            <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control mb-2' name="title"/>
            <label className="form-label" htmlFor="title">
                Objet <span className="required">*</span>
            </label>
            <FormError errors={formik.errors} touched={formik.touched} field={"title"} />
        </div>
        <div className="form-floating mb-3">
            <textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={5}
                className='mb-2 form-control h-auto' name="description"/>
            <label className="form-label" htmlFor="description">
                Description
            </label>
            <FormError errors={formik.errors} touched={formik.touched} field={"description"} />
        </div>
        <div className="col-12 mb-3">
            <SalarySelected
                onDelete={(id: number) => {
                    let _salaries: number[] = [...formik.values.salaries]
                    _salaries.splice(_salaries.indexOf(id), 1)
                    formik.setFieldValue('salaries', _salaries)
                }}
                onOpen={() => setDisplaySalarySelector(true)}
                initialValues={formik.values.salaries}
            />
        </div>
        <button className='btn btn-outline-primary w-100' type="submit">
            <i className={'bi bi-plus-circle'}> </i> Ajouter
        </button>
    </form>
}

export default ChannelAdd;