import { get, post } from "../../xhr";

import { IAddSalaryRepartition } from "../../../models/salary/repartition/IAddSalaryRepartition";
import { IUpdateSalaryRepartition } from "../../../models/salary/repartition/IUpdateSalaryRepartition";

export function addSalaryRepartition(salaryId: number, requestData: IAddSalaryRepartition){
    return post(`salary/${salaryId}/repartition/add`, requestData);
}

export function updateSalaryRepartition(salaryId: number, salaryRepartitionId: number, requestData: IUpdateSalaryRepartition){
    return post(`salary/${salaryId}/repartition/${salaryRepartitionId}/edit`, requestData);
}