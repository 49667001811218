import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/auth";
import * as actions from "../../../actions/auth";
import Loader from "../../../utils/loader";
import {axiosError} from "../../../actions/axios";
import {Salary} from "../../../models/salary";

const AccountList : React.FC = () => {
    const state = useSelector((state: RootStateOrAny)  => state);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [selecting, setSelecting] = useState<number|null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchAccounts())
        api.accounts().then(data => {
            dispatch(actions.fetchAccountsSuccess(data.data))
            if (data.data.length === 1){
                // dispatch(actions.selectAccount(data.data[Object.keys(data.data)[0]]))
            }else if(state.auth.user.currentSalary){
                let current_account = data.data.find((b :Salary) => state.auth.user.currentSalary.id == b.id)
                if (current_account.length){
                    dispatch(actions.selectAccount(current_account))
                }
            }
            setHasLoaded(true);
        }).catch(error => {
            dispatch(axiosError(error))
        })
    }, [])

    if (!hasLoaded)
        return (
            <Loader/>
        )

    return (<div className={'container mx-auto py-3'}>
        {!state.auth.user.salaries.length ?
            <div>Aucun compte trouvé</div>
            :
            <div className={"card p-3 shadow-sm"}>
                <h1 className={'text-center p-4'}>Séléctionnez un compte</h1>
                {state.auth.user.salaries.map((salary: Salary) => (
                    <div className={'col-md-4 mb-2'}>
                        <div className={'card p-4 shadow text-center'} key={salary.id}>
                            <h2>
                                {salary.companyGroup!.title}
                            </h2>
                            {!salary.companyGroup?.activated && <div className={'form-text text-danger'}>
                                <i className={'bi bi-x'}></i> Compte désactivé
                            </div>}
                            <button disabled={!!selecting || !salary.companyGroup?.activated} className={'text-uppercase w-100 btn btn-outline-primary'} onClick={() => {
                                setSelecting(salary.id)
                                api.selectAccount(salary.id).then((data) => {
                                    dispatch(actions.selectAccount(data.data))
                                }).catch(() => {
                                    setSelecting(null)
                                })
                            }}>
                                {selecting === salary.id ? <div className="spinner-grow spinner-grow-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : "Séléctionner"}
                            </button>
                        </div>
                    </div>
                ))}
            </div>}
    </div>);
}

export default AccountList;