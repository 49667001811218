import * as types from "../../constants/template"
import * as resourceTypes from "../../constants/template/resource"
import * as models from "../../models/template"
import replace from "../../utils/arrayReplace"

const template = (state: models.initialStateInterface = models.initialState, action:
    {type: string, template: models.Template, templates: models.Templates, error: string, [key: string] : any}
) => {

    switch (action.type) {
        case types.FETCH_TEMPLATES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_TEMPLATES_SUCCESS:
            return { payload: action.templates, single: state.single, isLoading: false, error: null };
        case types.FETCH_TEMPLATES_FAILURE:
            return { payload: action.templates, single: state.single, isLoading: false, error: action.error };

        case types.ADD_TEMPLATE:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_TEMPLATE_SUCCESS:
            return { payload: [...state.payload, action.template], single: action.template, isLoading: false, error: null };

        case types.EDIT_TEMPLATE:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_TEMPLATE_SUCCESS:
            return { payload:  replace(state.payload, action.template), single: action.template, isLoading: false, error: null };

        case types.SHOW_TEMPLATE:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_TEMPLATE_SUCCESS:
            return { payload: state.payload, single: action.template, isLoading: false, error: null };
        case types.SHOW_TEMPLATE_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        case resourceTypes.ADD_RESOURCE_SUCCESS:
            state.single!.resources = [...state.single!.resources, action.resource]
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };
        case resourceTypes.EDIT_RESOURCE_SUCCESS:
            state.single!.resources = replace(state.single!.resources!, action.resource)
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };


        case types.HIDE_TEMPLATE_SUCCESS:
            return { payload: [...state.payload.filter((t ) => t.id !== action.template.id)], single: null, isLoading: false, error: null };

        default:
            return state;
    }
}

export default template;