import {get, post} from "../xhr";

export function list(requestData: { start: string, end: string}, signal: AbortSignal){
    return post('keep_note/list', requestData, {signal: signal});
}

export function edit(requestData: { private: boolean, description: string, dueDate: Date }, id: number){
    return post('keep_note/edit/' + id, requestData);
}

export function create(requestData: { private: boolean, description: string, dueDate: Date }){
    return post('keep_note/create', requestData);
}

export function show(id: string){
    return get('keep_note/show/' + id);
}

export function cancel(id: number){
    return get('keep_note/delete/' + id);
}