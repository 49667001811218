import React from "react";

import { Link } from "react-router-dom";

const Admin: React.FC = () => {
    return (
        <div className="container mx-auto py-5">
            <div className="row">
                <div className="col-3 text-center">
                    <Link to={"/admin/user"} className={"btn btn-outline-primary"}>
                        <i className={'fs-1 bi bi-person'}></i>
                        <div className="clearfix"> </div>
                        <h4>
                            Utilisateurs
                        </h4>
                    </Link>
                </div>
                
                <div className="col-3 text-center">
                    <Link to={"/admin/salary"} className={"btn btn-outline-primary"}>
                        <i className={'fs-1 bi bi-people'}></i>
                        <div className="clearfix"> </div>
                        <h4>
                            Collaborateurs
                        </h4>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Admin;