import React, {useState} from "react";
import * as models from "../../../../models/synthesis";
import * as reportModels from "../../../../models/report";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import timeFormat from "../../../../utils/timeFormat"
import * as actions from "../../../../actions/synthesis";
import * as api from "../../../../adapters/report";
import * as paidApi from "../../../../adapters/paid";
import {Link} from "react-router-dom";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";
import SmallLoader from "../../../../utils/loader/small";
import {Company} from "../../../../models/companies";

const SynthesisCounterTable:React.FC<{company?: Company}> = (props) => {

    const dispatch = useDispatch();
    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);
    const report: reportModels.Report = useSelector((state: RootStateOrAny) => state.report.single);
    const [validating, setValidating] = useState(false);
    const [isValidating, setIsValidating] = useState<models.Synthesis>();
    const [isClearing, setIsClearing] = useState<models.Synthesis>();
    const [clearing, setClearing] = useState(false);
    const [currentRow, setCurrentRow] = useState<number>()
    const [currentRowDetail, setCurrentRowDetail] = useState<undefined|'accountedWorkingTime'|'counter'>(undefined)

    function validateCounters(){
        if (report.closedAt){
            setValidating(true)
            api.countersAdd(report.id)
                .then(resp => dispatch(actions.fetchSynthesesSuccess(resp.data.timeSheet, resp.data.weeks)))
                .then(() => setValidating(false))
        }
    }
    function validateSingleCounter(s: models.Synthesis){

        if (isValidating) return;

        if (report.closedAt){
            setIsValidating(s)
            api.countersAdd(report.id, { salaries: [s.id] })
                .then(resp => dispatch(actions.updateSynthesesSuccess(resp.data.timeSheet)))
                .then(() => setIsValidating(undefined))
        }
    }

    function clearCounters()
    {
        if (report.closedAt){
            setClearing(true)
            api.countersClear(report.id)
                .then(() => dispatch(actions.updateSyntheses(state.payload)))
                .then(() => setClearing(false))
        }
    }

    function paidValue(s: models.Synthesis)
    {
        paidApi.paidValue(s.id, {month: report.month, year: report.year, value: s.overtime})
            .then(() => dispatch(actions.updateSyntheses([s])))
    }

    function paidValueLowMajoration(s: models.Synthesis)
    {
        paidApi.paidLow(s.id, {month: report.month, year: report.year, value25: s.overtime25})
            .then(() => dispatch(actions.updateSyntheses([s])))
    }

    function paidValueHighMajoration(s: models.Synthesis)
    {
        paidApi.paidHigh(s.id, {month: report.month, year: report.year, value50: s.overtime50})
            .then(() => dispatch(actions.updateSyntheses([s])))
    }

    function clearSingleCounter(s: models.Synthesis)
    {
        if (isClearing) return;

        if (report.closedAt){
            setIsClearing(s)
            api.countersClear(report.id, { salaries: [s.id] })
                .then(() => dispatch(actions.updateSyntheses([s])))
                .then(() => setIsClearing(undefined))
        }
    }

    return <>
        {!report.closedAt && <div className="alert alert-info">
                    <i className={'bi bi-info-circle'}></i> Verrouillez le rapport pour mettre à jour les compteurs & renseigner les heures payées
                </div>}
            <table className="table text-nowrap sticky-table table-striped align-middle text-center">
                <colgroup>
                    <col span={2 + state.weeks?.filter(w => new Date(w.end) <= new Date(report.end)).length} style={{backgroundColor: '#fff'}} />
                    <col span={1} style={{backgroundColor: '#e9ecef'}} />
                    <col span={1} style={{backgroundColor: '#a3cfbb'}} />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            Collaborateur
                        </th>
                        <th>
                            Compteur initial
                        </th>
                        {state.weeks?.filter(w => new Date(w.end) <= new Date(report.end)).map(w => <th className={'form-text'}>
                            S.{w.weekNumber}
                        </th>)}
                        <th>
                            Total période
                        </th>
                        <th>
                            Total validé
                        </th>
                        <th title={"Compteur initial + Compteur période - H. payées = Compteur final."} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                            Compteur final <i className={'bi bi-info-circle'}></i>
                        </th>
                        <th>
                            Détail
                        </th>
                    </tr>
                </thead>
                <tbody>
                {state.payload.map((s) => {
                    let updating = !!state.updating.find(u => u.id === s.id);

                    return <>
                        <tr key={s.id}>
                            <td>
                                <div className="d-flex align-items-center w-100">
                                    <Link target={"_blank"} to={`/salary/${s.salary.id}`}
                                          className={'text-primary mb-0 flex-grow-1'}>
                                        <i className="bi bi-box-arrow-up-right"></i> {s.salary.title}
                                    </Link>
                                    <button title={"Détail par jour"} onMouseEnter={e => onMouseEnterTooltip(e.target)}
                                            className="btn bg-primary text-white flex-grow-0" data-bs-toggle="modal"
                                            data-bs-target="#synthesisSignature"
                                            onClick={() => dispatch(actions.showSynthesisSuccess(s))}>
                                        <i className={'bi bi-list-ul'}></i>
                                    </button>
                                </div>
                            </td>
                            <td className={'placeholder wave'}>
                                {updating ?
                                    <div className="line w-100"></div>
                                    :
                                    <>
                                        {!!s.initialCounter &&
                                            <i className={s.initialCounter < 0 ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}></i>
                                        } {timeFormat(s.initialCounter, 'hour', false)}
                                    </>
                                }
                            </td>
                            {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <td className={'form-text'}>
                                {timeFormat(w.counter, "hour", true)}
                            </td>)}
                            <td className={'placeholder wave'}>
                                {updating ? <div className="line w-100"></div> :
                                    <span className={"text-nowrap"}>
                                        {timeFormat(s.counter, 'hour', true)}
                                        {report.closedAt && s.counter !== s.counterSaved &&
                                            <>
                                                <button
                                                    onMouseDown={(e) => {
                                                        e.preventDefault()
                                                        validateSingleCounter(s)
                                                    }}
                                                    type={"button"}
                                                    title={"Ajouter les heures au compteur"}
                                                    onMouseEnter={e => onMouseEnterTooltip(e.target)}
                                                    className={'btn'}
                                                >
                                                    {isValidating?.id === s.id ? <SmallLoader/> : <i className={'bi bi-arrow-right-circle'}></i>}
                                                </button>
                                            </>
                                        }
                                    </span>
                                }
                            </td>
                            <td className={'placeholder wave'}>
                                {updating ? <div className="line w-100"></div> : <span className={'text-nowrap'}>
                                    {timeFormat(s.counterSaved, 'hour', true)} {report.closedAt &&
                                isClearing?.id === s.id ? <SmallLoader/> :
                                    (!!s.counterSaved && <i className={'bi bi-dash-circle text-danger'}
                                       title={"Retirer les heures du compteur"}
                                       onMouseEnter={e => onMouseEnterTooltip(e.target)}
                                       onClick={() => clearSingleCounter(s)}
                                    ></i>)}
                                    </span>}
                            </td>
                            <td className={'placeholder wave'}>
                                {updating ? <div className="line w-100"></div> : <>
                                    {!!s.finalCounter && <i className={s.finalCounter < 0 ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}></i>} {timeFormat(s.finalCounter, 'hour', false)}
                                </>}
                            </td>
                            <td onMouseDown={(e) => {
                                setCurrentRow(prev => prev === s.id ? undefined : s.id)
                            }}>
                                {currentRow === s.id ? <i className={'bi bi-chevron-up'}></i> : <i className={'bi bi-chevron-down'}></i>}
                            </td>
                    </tr>
                        {currentRow === s.id && <>
                            <tr>
                                <td>

                                </td>
                                <td colSpan={10}>
                                    <table className={'table'}>
                                        <thead>
                                        <th>
                                            #
                                        </th>
                                        {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <th><span className={'form-text'}>S.{w.weekNumber}</span></th>)}
                                        <th>

                                        </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Contrat
                                                </td>
                                                {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                    <td className={'placeholder wave'}>
                                                        {updating ? <div className="line w-100"></div> : <>
                                                            <span className={'form-text text-nowrap'}>{timeFormat(w.contractWorkingTime)}</span>
                                                        </>}
                                                    </td>
                                                </>)}
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Travaillé + Absences ajoutées au vol. d'heure
                                                </td>
                                                {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                    <td className={'placeholder wave'}>
                                                        {updating ? <div className="line w-100"></div> : <>
                                                            {w.overlapStart ? <span className={'text-nowrap'}>{timeFormat(w.overlapAccountedWorkingTime)}</span> : <span className={'text-nowrap'}>{timeFormat(w.accountedWorkingTime)}</span>}
                                                        </>}
                                                    </td>
                                                </>)}
                                                <td onClick={() => setCurrentRowDetail(prev => prev === 'accountedWorkingTime' ? undefined : 'accountedWorkingTime')}>
                                                    <i className={'bi bi-chevron-' + (currentRowDetail === 'accountedWorkingTime' ? 'up' : 'down')}></i>
                                                </td>
                                            </tr>
                                            {currentRowDetail === "accountedWorkingTime" &&  <>
                                                <tr className={'form-text btn-light'}>
                                                    <td>
                                                        Travaillé
                                                    </td>
                                                    {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                        <td className={'placeholder wave'}>
                                                            {updating ? <div className="line w-100"></div> : <>
                                                                <span
                                                                    className={'form-text text-nowrap'}>{timeFormat(w.workingTime)}</span>
                                                            </>}
                                                        </td>
                                                    </>)}
                                                </tr>
                                                <tr className={'form-text btn-light'}>
                                                    <td>
                                                        Absences ajoutées au vol. d'heure
                                                    </td>
                                                    {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                        <td className={'placeholder wave'}>
                                                            {updating ? <div className="line w-100"></div> : <>
                                                                {w.overlapStart ? <span
                                                                        className={'form-text text-nowrap'}>{timeFormat(w.overlapAbsenceAccountedTime)}</span> :
                                                                    <span
                                                                        className={'form-text text-nowrap'}>{timeFormat(w.absenceAccountedTime)}</span>}
                                                            </>}
                                                        </td>
                                                    </>)}
                                                </tr>
                                            </>}
                                            <tr>
                                                <td>
                                                    Compteur
                                                </td>
                                                {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                    <td className={'placeholder wave'}>
                                                        {updating ? <div className="line w-100"></div> : <>
                                                            <span className={'text-nowrap'}>{!!w.counter && <i className={w.counter < 0 ? 'bi bi-arrow-down text-danger' : 'bi bi-arrow-up text-success'}></i>} {timeFormat(w.counter)}</span>

                                                            {w.counterSaved !== w.counter && <>
                                                                <hr className={'my-1'}/>
                                                                <span className={'text-nowrap'}>
                                                    <i className={'bi bi-exclamation-triangle text-warning'}></i> {timeFormat(w.counterSaved)}
                                                </span>
                                                            </>}
                                                        </>}
                                                    </td>
                                                </>)}
                                                <td onClick={() => setCurrentRowDetail(prev => prev === 'counter' ? undefined : 'counter')}>
                                                    <i className={'bi bi-chevron-' + (currentRowDetail === 'counter' ? 'up' : 'down')}></i>
                                                </td>
                                            </tr>
                                            {currentRowDetail === "counter" && <>
                                                <tr className={'form-text btn-light'}>
                                                    <td>
                                                        H. normales
                                                    </td>
                                                    {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                        <td className={'placeholder wave'}>
                                                            {updating ? <div className="line w-100"></div> : <>
                                                                <span
                                                                    className={'form-text text-nowrap'}>{timeFormat(w.overtime)}</span>
                                                            </>}
                                                        </td>
                                                    </>)}
                                                </tr>
                                                <tr className={'form-text btn-light'}>
                                                    <td>
                                                        H. supplémentaires 25%
                                                    </td>
                                                    {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                        <td className={'placeholder wave'}>
                                                            {updating ? <div className="line w-100"></div> : <>
                                                                <span
                                                                    className={'form-text text-nowrap'}>{timeFormat(w.overtime25)}</span>
                                                            </>}
                                                        </td>
                                                    </>)}
                                                </tr>
                                                <tr className={'form-text btn-light'}>
                                                    <td>
                                                        H. supplémentaires 50%
                                                    </td>
                                                    {Object.values(s.weeks).filter(s => !s.overlapEnd).map(w => <>
                                                        <td className={'placeholder wave'}>
                                                            {updating ? <div className="line w-100"></div> : <>
                                                                <span
                                                                    className={'form-text text-nowrap'}>{timeFormat(w.overtime50)}</span>
                                                            </>}
                                                        </td>
                                                    </>)}
                                                </tr>
                                            </>}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </>}
                    </>
                })}
                </tbody>
            </table>
        </>
}

export default SynthesisCounterTable;