import React, {useEffect, useState} from "react";
import {get, post} from "../../adapters/xhr";
import format from "../../utils/locale";
import DatePicker from "react-datepicker";
import validationClass from "../../utils/validationClass";
import DateCustomInput from "../../utils/customInput";
import {setHours, setMinutes} from "date-fns";

const Test:React.FC = () => {

    const [data, setData] = useState<any[]>([])
    const [start, setStart] = useState<Date>(new Date())
    const [end, setEnd] = useState<Date>(new Date())

    useEffect(() => {
        post('admin/helper/julian', {start: format(start, 'uuuu-MM-dd'), end: format(end, 'uuuu-MM-dd')}).then(data => setData(data.data));
    }, [start, end])

    return <>
        <div className="row">
            <div className="col-12 col-md mb-3">
                <DatePicker
                    className={'form-control '}
                    customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                    selected={start}
                    onChange={(date: Date) => {
                        setStart(date)
                    }}
                    selectsStart
                    startDate={start}
                    maxDate={end}
                    dateFormat="E dd MMM uuuu"
                    popperPlacement={"top-end"}
                    showPopperArrow={false}
                />
            </div>
            <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                <i className={'bi bi-arrow-left-right mx-1'}> </i>
            </div>
            <div className="col-12 col-md mb-3">
                <DatePicker
                    className={'form-control'}
                    customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                    selected={end}
                    onChange={(date: Date) => {
                        setEnd(date)
                    }}
                    selectsEnd
                    startDate={start}
                    endDate={end}
                    minDate={start}
                    dateFormat="E dd MMM uuuu"
                    popperPlacement={"top-end"}
                    showPopperArrow={false}
                />
            </div>
        </div>
        <div className="col-12">
            <table className={'table table-striped'}>
                <thead>
                <tr>
                    <th>
                        Collaborateur
                    </th>
                    <th>
                        Heures contrat
                    </th>
                    <th>
                        Heures efféctué
                    </th>
                    <th>
                        Delta
                    </th>
                </tr>
                </thead>
            <tbody>
            {data.map(d => <>
                    <tr>
                        <td>
                            {d.title}
                        </td>
                        <td>
                            {d.contractWorkingTime}
                        </td>
                        <td>
                            {d.workingTime}
                        </td>
                        <td>
                            {d.delta}
                        </td>
                    </tr>
            </>)}
            </tbody>
            </table>
        </div>
    </>
}

export default Test;