import * as xhr from "../../xhr";
import {Choices} from "../../../utils/bootstrapSelect";

export function posts(id: number, requestData: {day: number, time: string, val: number, job?: number}){
    return xhr.post('activity/'+ id +'/need', requestData);
}

export function postsMany(id: number, requestData: {days: number[], start: string, end: string, val: number, job?: number}){
    return xhr.post('activity/'+ id +'/many_needs', requestData);
}

export function jobs(id: number, requestData: Choices){
    return xhr.post('activity/'+ id +'/need_jobs', requestData);
}

export function type(id: number, type: number){
    return xhr.get('activity/'+ id +'/need_type/' + type);
}

