import React, {useEffect, useState} from "react";
import format from "../../../utils/locale";
import * as calendarTypes from "../../../constants/calendar";
import {Offcanvas} from "bootstrap";

interface Interface {
    signed: any[],
    origin: string,
    setCalendarSignedSidebarProps: React.Dispatch<any>,
}

const CalendarReporting:React.FC<Interface> = (props) => {

    const {signed, origin, setCalendarSignedSidebarProps} = props;

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarReporting')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarReporting" aria-labelledby="offcanvasCalendarReportingLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarReportingLabel">
                {origin === 'anomaly' && <>Anomalies</>}
                {origin === 'missing' && <>Absences</>}
                {origin === 'late' && <>Retards</>}
            </h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <ul className={'list-group list-group-flush'}>
                {signed.filter(s => applyFilters(s)).map(s => <li className={'list-group-item list-group-item-action'}
                    onClick={() => {
                        close();
                        switch (s.end){
                            case calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING:
                                setCalendarSignedSidebarProps((prev: any) => ({
                                    sidebarType: "SIGNED_ADD",
                                    trigger: prev.trigger + 1,
                                    company: s.company,
                                    salary: s.salary,
                                    start: s.start ? new Date(s.start) : null,
                                    end: s.end ? new Date(s.end) : null,
                                }))
                                break;
                            default:
                                setCalendarSignedSidebarProps((prev: any) => ({
                                    sidebarType: "SIGNED_EDIT",
                                    trigger: prev.trigger + 1,
                                    company: s.company,
                                    salary: s.salary,
                                    start: s.start ? new Date(s.start) : null,
                                    end: s.end ? new Date(s.end) : null,
                                    isAnomaly: s.isAnomaly,
                                    timeClockId: s.timeClockId
                                }))
                                break;
                        }
                    }}
                >
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 d-flex flex-column">
                            <h4>
                                <span className="me-2" style={{height: 14, width: 14, borderRadius: '50%', backgroundColor: s.salary.information.job.color}}>
                                    <i className={'bi bi-person text-white'}></i>
                                </span> {s.salary.title}
                            </h4>
                            <span className={'d-flex align-items-center'}>
                                Le {format(new Date(s.start))}
                            </span>
                        </div>
                        <div className="flex-grow-0 d-flex flex-column">
                            {s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING && <>
                                <div className="d-flex align-items-center">
                                    <h4 className={"flex-grow-1 m-0"}>
                                        <i className={'bi bi-exclamation-circle text-danger'}></i> Absent
                                    </h4>
                                </div>
                                <span className={"form-text"}>
                                    <i className={'bi bi-clock'}></i> Prévu à {format(new Date(s.start), 'HH:mm')}
                                </span>
                            </> }
                            {s.isLate && <>
                                <h4>
                                    <i className={'bi bi-exclamation-triangle text-warning'}></i> Retard
                                </h4>
                                <span className={"form-text"}>
                                    <i className={'bi bi-watch'}></i> Retard de {s.lateOfCaption}
                                </span>
                            </>}
                            {!(s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING) && !s.isLate && <>
                                <h4>
                                    <i className={'bi bi-check text-success'}></i> Arrivé à {format(new Date(s.start), 'HH:mm')}
                                </h4>
                            </>}
                        </div>
                    </div>
                </li>)}
            </ul>
        </div>
    </div>

    function applyFilters(s: any){

        if ([calendarTypes.EVENT_TYPE_BOOKING, calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION, calendarTypes.EVENT_TYPE_EVENT, calendarTypes.EVENT_TYPE_EVENT_EXCEPTION].includes(s.eventType)) return false;
        if (s.isAnomaly && origin === 'anomaly') return true;
        if (s.isLate && origin === 'late') return true;
        if (s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING && origin === 'missing') return true;

        return false;
    }
}

export default CalendarReporting;