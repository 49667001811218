import * as types from "../../constants/companyGroup"
import * as models from "../../models/companyGroup"

const companyGroup = (state:models.initialStateInterface = models.initialState, action: {type: string, companyGroup: models.CompanyGroup, companyGroups: models.CompanyGroups, error: string}) => {

    switch (action.type) {
        case types.FETCH_COMPANY_GROUP:
            return { payload: [...state.payload], isLoading: true, current: null, current_id: state.current_id,  error: null};
        case types.FETCH_COMPANY_GROUP_SUCCESS:
            return { payload: action.companyGroups, current: null, current_id: state.current_id, isLoading: false, error: null };
        case types.FETCH_COMPANY_GROUP_FAILURE:
            return { payload: [], current: null, isLoading: false, error: action.error };
        case types.ADD_COMPANY_GROUP:
            return { payload: state.payload , isLoading: true, error: null };
        case types.ADD_COMPANY_GROUP_SUCCESS:
            return { payload:  [...state.payload, action.companyGroup ] , isLoading: false, error: null };
        case types.EDIT_COMPANY_GROUP:
            return { payload: state.payload , isLoading: true, error: null };
        case types.EDIT_COMPANY_GROUP_SUCCESS:
            state.payload = state.payload.filter(b => action.companyGroup.id !== b.id);
            return { payload:  [...state.payload, action.companyGroup ], isLoading: false, error: null };
        default:
            return state;
    }
}

export default companyGroup;