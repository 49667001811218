import React, {useEffect, useState} from "react";
import * as models from "../../../models/synthesis";
import format from "../../../utils/locale";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import timeFormat from "../../../utils/timeFormat";
import {
    ACCOUNTING_TYPE_WORK_ACCOUNTED,
    getAccountingTypeChoice,
    getAccountingTypeChoiceClassName
} from "../../../constants/booking";
import duration from "../../../utils/duration";
import Loader from "../../../utils/loader";
import * as actions from "../../../actions/synthesis";
import * as api from "../../../adapters/calendar";
import * as calendarTypes from "../../../constants/calendar";

const SynthesisSignatureLoad: React.FC = () => {

    const dispatch = useDispatch();
    const rState = useSelector((state: RootStateOrAny) => state.rightSidebar)
    const [loading, setLoading] = useState(true);
    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);
    const [displayOverlappingDays, setDisplayOverlappingDays] = useState(false)

    useEffect(() => {
        fetchCounters()
    }, [])

    function fetchCounters(){
        dispatch(actions.showSynthesis())
        let end = new Date(rState.data.endDate)
        end.setDate(end.getDate() + 1)

        api.test({
            start: format(rState.data.startDate, 'uuuu-MM-dd'),
            end: format(end, 'uuuu-MM-dd'),
            calendar: calendarTypes.CALENDAR_TIME_SHEET,
            options: {
                salaries: [rState.data.salary.id],
                // year: year,
                // month: month,
            }
        })
            .then(resp => dispatch(actions.showSynthesisSuccess(resp.data.timeSheet[0])))
            .then(() => setLoading(false))
    }

    return <>
        {loading && <Loader />}
        {state.single && !loading && <table className={"table table-sm align-middle text-center sticky-table"}>
                    <thead>

                    </thead>
                    {Object.values(state.single.weeks).map((week, index) => <tbody>
                    <tr>
                        <td className={'bg-primary text-white'}>
                            S.{week.weekNumber}
                        </td>
                        <td colSpan={2} className={'bg-primary text-white'}>
                            {format(new Date(week.start))} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(week.end))}
                        </td>
                        <td className={'bg-primary text-white'}>
                            {week.overlapStart && <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="overlapStart" onChange={e => setDisplayOverlappingDays(e.target.checked)}/>
                                <label className="form-check-label" htmlFor="overlapStart">
                                    Semaine complète
                                </label>
                            </div>}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Jour
                        </th>
                        <th>
                            Planifié
                        </th>
                        <th>
                            Travaillé
                        </th>
                        <th>
                            Delta (h)
                        </th>
                    </tr>
                    {Object.values(week.days).filter(day => !day.overlapStart || displayOverlappingDays).map(day => <tr>
                        <td>
                            <div>
                                {format(new Date(day.dt), "E dd")}
                            </div>
                            {day.isLabourDay && <div className={'text-success'}>
                                Jour férié
                            </div>}
                        </td>
                        <td>
                            <ul className={'list-unstyled mb-0'}>
                                {!!day.absence && !day.planned.length && <li>
                                    <div className="form-text">
                                        <i className={'bi bi-calendar-x'}></i> {day.absence.typeName}
                                    </div>
                                </li>}
                                {day.planned.map(e => <li>
                                    <div>
                                        {e.eventType === "eventException" && <i className={'bi bi-pencil text-primary'}></i>}
                                        {format(new Date(e.start), "HH:mm")} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(e.end), "HH:mm")} ({duration(new Date(e.start), new Date(e.end))})
                                    </div>
                                    {e.absence && <div className="form-text">
                                        <i className={'bi bi-calendar-x'}></i> {e.absence.typeName}
                                    </div>}
                                    {e.comment && <div className="form-text">
                                        <i className={'bi bi-quote'}></i> {e.comment}
                                    </div>}
                                    {e.accountingType && e.accountingType !== ACCOUNTING_TYPE_WORK_ACCOUNTED && <div className="form-text">
                                        <i className={'bi bi-clock ' + getAccountingTypeChoiceClassName(e.accountingType)}></i> {getAccountingTypeChoice(e.accountingType).label}
                                    </div>}
                                </li>)}
                            </ul>
                        </td>
                        <td>
                            <ul className={'list-unstyled mb-0'}>
                                {day.done.map(e => <li>
                                    {format(new Date(e.start), "HH:mm")} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(e.end), "HH:mm")} ({duration(new Date(e.start), new Date(e.end), e.nonBadgedBreak)})
                                    {!!e.nonBadgedBreak && <>
                                        <span className="clearfix"></span>
                                        <span className="form-text">
                                                        <i className={'bi bi-arrow-down text-danger'}></i> {timeFormat(e.nonBadgedBreak)} de pause.
                                                    </span>
                                    </>}
                                </li>)}
                            </ul>
                        </td>
                        <td>
                            {timeFormat(day.delta)}
                        </td>
                    </tr>)}
                    {week.overlapStart ? <>
                        {displayOverlappingDays ? <>
                            <tr className={'btn-light'}>
                                <th colSpan={4}>
                                    <table className={"table"}>
                                        <thead>
                                        <th>
                                            Contrat
                                        </th>
                                        <th>
                                            Travaillé
                                        </th>
                                        <th>
                                            H. d'absences ajoutées au volume d'heure
                                        </th>
                                        <th>
                                            Total
                                        </th>
                                        <th>
                                            Compteur
                                        </th>
                                        </thead>
                                        <tbody>
                                        <td>
                                            {timeFormat(week.contractWorkingTime)}
                                        </td>
                                        <td>
                                            {timeFormat(week.overlapWorkingTime)}
                                        </td>
                                        <td>
                                            {timeFormat(week.overlapAbsenceAccountedTime)}
                                        </td>
                                        <td>
                                            {timeFormat(week.overlapAccountedWorkingTime)}
                                        </td>
                                        <td>
                                            {timeFormat(week.counter)}
                                        </td>
                                        </tbody>
                                    </table>
                                </th>
                            </tr>
                        </> : <>
                            <tr className={'btn-light'}>
                                <th colSpan={4}>
                                    <table className="table">
                                        <thead>
                                        <th>
                                            Contrat
                                        </th>
                                        <th>
                                            Travaillé
                                        </th>
                                        <th>
                                            H. d'absences ajoutées au volume d'heure
                                        </th>
                                        <th>
                                            Total
                                        </th>
                                        <th>
                                            Compteur
                                        </th>
                                        </thead>
                                        <tbody>
                                        <td>
                                            <i className={'bi bi-x'}></i>
                                        </td>
                                        <td>
                                            {timeFormat(week.workingTime)}
                                        </td>
                                        <td>
                                            {timeFormat(week.absenceAccountedTime)}
                                        </td>
                                        <td>
                                            {timeFormat(week.accountedWorkingTime)}
                                        </td>
                                        <td>
                                            <i className={'bi bi-x'}></i>
                                        </td>
                                        </tbody>
                                    </table>
                                </th>

                            </tr>
                        </>}
                    </> : <>
                        <tr className={'btn-light'}>
                            <th colSpan={4}>
                                <table className="table">
                                    <thead>
                                    <th>
                                        Contrat
                                    </th>
                                    <th>
                                        Travaillé
                                    </th>
                                    <th>
                                        H. d'absences ajoutées au volume d'heure
                                    </th>
                                    <th>
                                        Total
                                    </th>
                                    <th>
                                        Compteur
                                    </th>
                                    </thead>
                                    <tbody>
                                    <td>
                                        {timeFormat(week.contractWorkingTime)}
                                    </td>
                                    <td>
                                        {timeFormat(week.workingTime)}
                                    </td>
                                    <td>
                                        {timeFormat(week.absenceAccountedTime)}
                                    </td>
                                    <td>
                                        {timeFormat(week.accountedWorkingTime)}
                                    </td>
                                    <td>
                                        {timeFormat(week.counter)}
                                    </td>
                                    </tbody>
                                </table>
                            </th>
                        </tr>
                    </>}
                    </tbody>)}
                </table>}
    </>
}

export default SynthesisSignatureLoad;