import React from 'react'
import { useDispatch } from 'react-redux'

import { Formik, Form } from "formik"
import * as Yup from "yup"
import DatePicker from "react-datepicker"
import { Offcanvas } from 'bootstrap'

import { addSalaryPaidVacation } from '../../../../../adapters/salary/paidVacation'

import CustomInput from "../../../../../utils/customInput"
import FormError from "../../../../../utils/formError"
import validationClass from "../../../../../utils/validationClass"
import moment from "../../../../../utils/moment.utils"
import { showAlertDanger } from '../../../../../actions/alert'

interface IProps {
  drawerId: string;
  salaryId: number;
  onSubmitSuccess: () => void;
}

const AddPaidVacationSchema = Yup.object().shape({
  duration: Yup.number().min(0.5, 'Une demi journée minimum').required('Veuillez préciser la durée du congé'),
  availableStart: Yup.date().required('Veuillez préciser la date de disponibilité'),
  reason: Yup.string().max(255, '255 caractères maximum'),
});

const AddPaidVacationDrawer: React.FC<IProps> = ({ drawerId, salaryId, onSubmitSuccess }) => {
  const dispatch = useDispatch()

  return (
    <div
      id={drawerId}
      aria-labelledby={`${drawerId}Label`}
      className={`offcanvas offcanvas-end`}
      tabIndex={-1}
    >
      <div className="offcanvas-header bg-light justify-content-start text-dark d-flex align-items-center">
        <span className={'h5'}>
          Ajouter un congé supplémentaire
        </span>
        <button
          className={'float-end btn btn-close'}
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>

      <div className="offcanvas-body position-relative py-0">
        <Formik
          initialValues={{
            duration: 0,
            availableStart: moment().toDate(),
            reason: '',
          }}
          validationSchema={AddPaidVacationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true)
              await addSalaryPaidVacation(salaryId, {
                ...values,
                availableStart: moment(values.availableStart).startOf('day').toDate()
              })
              onSubmitSuccess()
              resetForm()

              const offcanvasElement = document.getElementById(drawerId);
              if (offcanvasElement) {
                const bsOffcanvas = Offcanvas.getInstance(offcanvasElement);
                bsOffcanvas.hide();  
              }
            } catch (error) {
              dispatch(showAlertDanger('Impossible d’ajouter le congé supplémentaire'))
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ errors, touched, values, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit }) => (
            <Form className="pt-3 flex-grow-1 mb-3">
              <div className="col-12 mb-3">
                <div className="form-floating">
                  <input
                    type={'number'}
                    step={0.5}
                    className='form-control'
                    name={`duration`}      
                    value={values.duration}            
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label htmlFor="duration">
                    Durée du congé (en jours)*
                  </label>
                  <FormError errors={errors} touched={touched} field={'duration'} />
                </div>
              </div>

              <div className="col-12 mb-3">
                <DatePicker
                  customInput={<CustomInput label={'Date de disponibilité*'} />}
                  onChange={(date: Date) => setFieldValue('availableStart', date)}
                  dateFormat={"E dd MMM uuuu"}
                  className={'form-control' + validationClass(errors, touched, 'availableStart')}
                  selected={values.availableStart}
                />
                <FormError errors={errors} touched={touched} field={'availableStart'} />
              </div>

              <div className="col-12 mb-3">
                <div className="form-floating">
                  <input
                    type={'text'}
                    max={255}
                    className='form-control'
                    name={`reason`}                  
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label htmlFor="reason">
                    Motif (facultatif)
                  </label>
                  <FormError errors={errors} touched={touched} field={'reason'} />
                </div>
              </div>

              <div className="flex-grow-0">
                <button
                  className='btn btn-outline-primary w-100'
                  type="submit"
                  disabled={values.duration < 0.5 || !values.availableStart || isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  <i className={'bi bi-check'}> </i> Ajouter
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddPaidVacationDrawer