import {Salary} from "../salary";
import {Files} from "../file";

export enum TYPE {
    CP = 1,
    RTT,
    CSS,
    REPOS,
    MALADIE,
    EVENT
}
export enum EVENT_TYPE {
    CP = 1,
    RTT,
    CSS,
    REPOS,
    MALADIE,
    EVENT
}

export interface Absence {
    readonly id?: number,
    readonly nbDays?: number,
    salary: Salary,
    status: 1|2|3,
    type: TYPE,
    start: string|Date,
    startFormat?: string,
    startComplement?: 1|2|3,
    startComplementTime?: Date,
    end: string|Date,
    endFormat?: string,
    endComplement?: 1|2|3,
    endComplementTime?: Date,
    files?: AbsenceFiles,
    lastDayIn?: string|Date,
    nextWorkingDay?: string,
    typeShortName?:string,
    typeName?:string,
    eventType?: 1|2|3|4,
    eventPrecision?: 1|2|3|4|5|6|7|8|9,
    color?: string,
    timeAccountedType: number,
    time: number,
    readonly createdAt?: string,
}

export interface AbsenceFile {
    readonly id: number,
    title: string
}

export type AbsenceFiles = AbsenceFile[];

export interface AbsencePostFields {
    salary: Salary,
    type: string,
    start: string,
    startComplement?: string,
    end: string,
    endComplement?: string,
    fileName?: string,
    lastDayIn?: string,
    eventType?: string,
    eventPrecision?: string,
}

export type PostFieldKey = keyof AbsencePostFields;

export type Absences = Absence[];

export interface initialStateInterface {
    payload: Absences,
    single: Absence|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};

export const TIME_ACCOUNTED_TYPE = [
    {label: 'Heures planifiées', value: '1'},
    {label: 'Total heures par jour', value: '3'},
];