import * as types from "../../constants/accessLevel";
import * as models from "../../models/accessLevel";
import {AccessLevel} from "../../models/accessLevel";

export const addAccessLevel = () => {
    return {
        type: types.ADD_ACCESS_LEVEL,
    }
}

export const addAccessLevelSuccess = (accessLevel: models.AccessLevel) => {
    return {
        type: types.ADD_ACCESS_LEVEL_SUCCESS,
        accessLevel: accessLevel,
    }
}

export const addAccessLevelFailure = (error: string) => {
    return {
        type: types.ADD_ACCESS_LEVEL_FAILURE,
        accessLevel: null,
        accessLevels: null,
        error: error
    }
}

export const editAccessLevel = () => {
    return {
        type: types.EDIT_ACCESS_LEVEL,
    }
}

export const editAccessLevelSuccess = (accessLevel: models.AccessLevel) => {
    return {
        type: types.EDIT_ACCESS_LEVEL_SUCCESS,
        accessLevel: accessLevel
    }
}

export const editAccessLevelFailure = (error: string) => {
    return {
        type: types.EDIT_ACCESS_LEVEL_FAILURE,
        accessLevel: error
    }
}

export const fetchAccessLevels = () => {
    return {
        type: types.FETCH_ACCESS_LEVELS,
    }
}

export const fetchAccessLevelsFailure = (error: string) => {
    return {
        type: types.FETCH_ACCESS_LEVELS_FAILURE,
        accessLevel: null,
        accessLevels: [],
        error
    }
}

export const fetchAccessLevelsSuccess = (accessLevels: models.AccessLevels) => {
    return {
        type: types.FETCH_ACCESS_LEVELS_SUCCESS,
        accessLevel: null,
        accessLevels: accessLevels
    }
}


export const showAccessLevel = () => {
    return {
        type: types.SHOW_ACCESS_LEVEL,
    }
}
export const showAccessLevelSuccess = (accessLevel: models.AccessLevel) => {
    return {
        type: types.SHOW_ACCESS_LEVEL_SUCCESS,
        accessLevel: accessLevel
    }
}
export const showAccessLevelFailure = (error: string) => {
    return {
        type: types.SHOW_ACCESS_LEVEL_FAILURE,
        accessLevel: null,
        accessLevels: null,
        error: error
    }
}