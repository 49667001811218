import * as React from "react";

import ExpenseList from "../../components/expense/list";
import ExpenseShow from "../../components/expense/show";

const ExpensePage : React.FC = () => {
    return (
        <>
            <div className="w-100 h-100 d-flex flex-grow-1">
                <ExpenseList />
                <ExpenseShow />
            </div>
        </>
    );
}

export default ExpensePage;