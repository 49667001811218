export const ADD_KEEP_NOTE = 'ADD_KEEP_NOTE';
export const ADD_KEEP_NOTE_SUCCESS = 'ADD_KEEP_NOTE_SUCCESS';
export const ADD_KEEP_NOTE_FAILURE = 'ADD_KEEP_NOTE_FAILURE';

export const REMOVE_KEEP_NOTE = 'REMOVE_KEEP_NOTE';
export const REMOVE_KEEP_NOTE_SUCCESS = 'REMOVE_KEEP_NOTE_SUCCESS';
export const REMOVE_KEEP_NOTE_FAILURE = 'REMOVE_KEEP_NOTE_FAILURE';

export const EDIT_KEEP_NOTE = 'EDIT_KEEP_NOTE';
export const EDIT_KEEP_NOTE_SUCCESS = 'EDIT_KEEP_NOTE_SUCCESS';
export const EDIT_KEEP_NOTE_FAILURE = 'EDIT_KEEP_NOTE_FAILURE';

export const FETCH_KEEP_NOTES = 'FETCH_KEEP_NOTES';
export const FETCH_KEEP_NOTES_SUCCESS = 'FETCH_KEEP_NOTES_SUCCESS';
export const FETCH_KEEP_NOTES_FAILURE = 'FETCH_KEEP_NOTES_FAILURE';

export const SHOW_KEEP_NOTE = 'SHOW_KEEP_NOTE';
export const SHOW_KEEP_NOTE_SUCCESS = 'SHOW_KEEP_NOTE_SUCCESS';
export const SHOW_KEEP_NOTE_FAILURE = 'SHOW_KEEP_NOTE_FAILURE';
