import React, {ChangeEvent, useState} from "react";
import * as Yup from "yup";
import * as actions from "../../../../actions/staticBackdropModal"
import * as type from "../../../../constants/absence"
import {useFormik, Field} from "formik";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import FormError from "../../../../utils/formError";

interface FilterProps {
    status: number[], salaries?: number[], range?: {start: Date, end: Date}
}

interface AbsenceFiltersProps {
    validateModal: (values: FilterProps) => typeof values;
}
const AbsenceFilters:React.FC<AbsenceFiltersProps> = (props) => {

    const {validateModal} = props;

    const dispatch = useDispatch();
    const state = useSelector(((state: RootStateOrAny) => state.staticBackdropModal))
    const [hasChanged, setHasChanged] = useState(false);

    const validationSchema = Yup.object().shape({
        status: Yup.array().of(Yup.number()).min(1, "Veuillez séléctionner au moins un statut"),
        types: Yup.array().of(Yup.string()).min(1, "Veuillez séléctionner au moins un type d'absence"),
    });

    const formik = useFormik({
        initialValues: {
            status: state.data.status || ['1','2','3'],
            types: state.data.types || type.getTypeChoices().map(c => String(c.value)),
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            if (hasChanged){
                validateModal(values)
            }
            dispatch(actions.closeModal());
        },
    });

    return (
        <>
            <form className={'w-100'} onSubmit={formik.handleSubmit}>
        <div className={'modal-body'}>
            <div className="mb-3">
                <h4 className={'mb-1'}>
                    Statut
                </h4>
                <FormError errors={formik.errors} touched={formik.touched} field={'status'} />
            </div>
            <div className="form-check form-switch mb-2">
                <input
                    name={'status'}
                    type="checkbox"
                    defaultChecked={formik.values.status.includes('1')}
                    value={'1'}
                    onBlur={formik.handleBlur}
                    onChange={(e: ChangeEvent) => {
                        setHasChanged(true)
                        formik.handleChange(e)
                    }}
                    className="form-check-input"
                />
                <label className="form-check-label" htmlFor="filterAbsencePending">
                    En attente
                </label>
            </div>
            <div className="form-check form-switch mb-2">
                <input
                    name={'status'}
                    value={'2'}
                    onBlur={formik.handleBlur}
                    defaultChecked={formik.values.status.includes('2')}
                    onChange={(e: ChangeEvent) => {
                        setHasChanged(true)
                        formik.handleChange(e)
                    }}
                    className="form-check-input"
                    type="checkbox"
                />
                <label className="form-check-label" htmlFor="filterAbsenceAccepted">
                    Accepté
                </label>
            </div>
            <div className="form-check form-switch mb-2">
                <input name={'status'}
                       value={'3'}
                       onBlur={formik.handleBlur}
                       defaultChecked={formik.values.status.includes('3')}
                       onChange={(e: ChangeEvent) => {
                           setHasChanged(true)
                           formik.handleChange(e)
                       }}
                       className="form-check-input" type="checkbox"
                />
                <label className="form-check-label" htmlFor="filterAbsenceRefused">
                    Refusé
                </label>
            </div>
            <div className="mb-3">
                <h4 className={'mb-1'}>
                    Type d'absence
                </h4>
                <FormError errors={formik.errors} touched={formik.touched} field={'types'} />
            </div>
            <ul className={'list-group'}>
                {type.getTypeChoices().map((c, index) => <li key={index} className="form-check form-switch mb-2">
                    <input name={'types'}
                           value={c.value}
                           onBlur={formik.handleBlur}
                           defaultChecked={formik.values.types.includes(String(c.value))}
                           onChange={(e: ChangeEvent) => {
                               setHasChanged(true)
                               formik.handleChange(e)
                           }}
                           className="form-check-input" type="checkbox"
                    />
                    <label className="form-check-label" htmlFor="filterAbsenceRefused">
                        {c.label}
                    </label>
                </li>)}
            </ul>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                    <i className={'bi bi-x-circle text-danger'}></i>
                </button>
                <button type="submit" className="btn btn-light">
                    <i className={'bi bi-check-circle text-success'}></i>
                </button>
            </div>
            </form>
        </>
    )
}

export default AbsenceFilters;