import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Company} from "../../../../models/companies";
import {Salary} from "../../../../models/salary";
import * as api from "../../../../adapters/booking";
import DatePicker from "react-datepicker";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import Loader from "../../../../utils/loader";
import {showAlertSuccess} from "../../../../actions/alert";
import {setMinutes, setHours} from "date-fns";
import {SingleSalarySelected, SingleSalarySelector} from "../../../../utils/salarySelector/single";
import format from "../../../../utils/locale"
import FormError from "../../../../utils/formError";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {axiosError} from "../../../../actions/axios";
import {AccountingTypes, getAccountingTypeChoice} from "../../../../constants/booking";
import * as groupApi from "../../../../adapters/booking/group";
import {BookingRecurrence} from "../../../../models/booking";
import SmallLoader from "../../../../utils/loader/small";

interface Interface {
    initialSalary?: Salary,
    initialCompany?: Company,
    start?: Date,
    end?: Date,
    localisation?: Localisation,
    activity?: Activity,
    recurrence?: BookingRecurrence,
    bookingGroupId?: number,
    handleSubmitSuccess: (props: any[]) => any
}

const BookingAddForm: React.FC<Interface> = (props) => {

    const {initialCompany, initialSalary, end, start, localisation, activity, recurrence, bookingGroupId, handleSubmitSuccess} = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [displaySalarySelector, setDisplaySalarySelector] = useState<boolean>(false);
    const [weeks, setWeeks] = useState<string[]>([]);
    const [groups, setGroups] = useState<{title: string, id: number, start: string, finish: string}[]>([])
    const [salary, setSalary] = useState<Salary|undefined>(initialSalary);
    const [company, setCompany] = useState<Company|undefined>(initialCompany);

    const dispatch = useDispatch();

    useEffect(() => {
        if (salary) {
            groupApi.list({
                salary: salary.id
            }).then(data => setGroups(data.data))
        }
    }, [salary])

    const [initialValues, setInitialValues] = useState<any>({
        salary: salary?.id,
        beginAt:  start ?  start : setMinutes(new Date(), 0),
        endAt:  end ? end : setMinutes(new Date(), 30),
        accountingType: 1,
        company: company?.id,
        localisation: localisation?.id || salary?.defaultLabels?.find(d => d.company.id === company?.id)?.localisation?.id,
        activity: activity?.id || salary?.defaultLabels?.find(d => d.company.id === company?.id)?.activity?.id,
        bookingGroup: bookingGroupId || "",
        recurrence: {
            type: String(recurrence?.type || '1'),
        }
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
            salary: Yup.number(),
            beginAt: Yup.date().required('Début de créneau requis'),
            endAt: Yup.date().required('Fin de créneau requis')
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                const { beginAt } = this.parent;
                return value && beginAt <= value
            }),
            accountingType: Yup.number().required(),
            company: Yup.number().required('Veuillez choisir un point de vente'),
            localisation: Yup.number().nullable(),
            activity: Yup.number().nullable(),
            description: Yup.string().nullable(),
            bookingGroup: Yup.number().nullable(),
            recurrence: Yup.object().shape({
                type: Yup.string().required(),
            })
    }))

    useEffect(() => {
        let _initialValues:any = {...initialValues}

        if (String(_initialValues.recurrence.type) !== '1'){
            if(recurrence?.daysOfWeek){
                _initialValues.recurrence.daysOfWeek = recurrence.daysOfWeek.map((d: number) => String(d));
            }
            handleRecurrenceTypeChange(String(_initialValues.recurrence.type))
        }

        if(_initialValues.recurrence.type === '2' && recurrence?.weekLetters){
            _initialValues.recurrence.weekLetters = recurrence.weekLetters.map((d: number) => String(d));
        }

        if(_initialValues.recurrence.type === '3' && recurrence?.separationCount){
            _initialValues.recurrence.separationCount = recurrence.separationCount;
        }

        setInitialValues({..._initialValues})
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (company){
            getWeeks(company)
        }
    }, [company])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnChange: true,
        enableReinitialize: true,
        isInitialValid: false,
        onSubmit: (values, formikHelpers) => {
            api.create(values)
                .then(resp => {
                    Promise.all([
                        handleSubmitSuccess(resp.data)
                    ]).then(() => {
                        dispatch(showAlertSuccess('Créneau ajouté'));
                        formikHelpers.setSubmitting(false)
                    })
                })
                .catch(error => dispatch(axiosError(error)))
        }
    })

    const handleSalaryChange = (data: Salary) => {
        let _values = {...formik.values}

        if (company && data.companies.find(c => c.id === company.id)){
            _values.company = data.companies.find(c => c.id === company.id)!.id
        }else{
            _values.company = data.companies[0].id
        }

        formik.setValues(_values)

        setSalary(data)
        handleBookingGroupChange();
        setCompany(data.companies[0])
    }

    const handleBookingGroupChange = (id?: number|string) => {
        let recurrenceValidation = {...validationSchema.fields.recurrence.fields};
        let _values = {...formik.values};
        _values.bookingGroup = id;

        if (id){
            delete _values.recurrence.start
            delete recurrenceValidation.start
            delete _values.recurrence.end
            delete recurrenceValidation.end
        }else if(formik.values.recurrence.type !== '1'){
            _values.recurrence.start = _values.beginAt
            _values.recurrence.end = null
            recurrenceValidation.start = Yup.date().required('Champs requis');
            recurrenceValidation.end = Yup.date().nullable()
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { start } = this.parent;
                    return !value || (value && start <= value)
                })
        }

        let validation =  {
            salary: Yup.number().required('Veuillez choisir un collaborateur'),
            bookingGroup: Yup.number().nullable(),
            beginAt: Yup.date().required('Début de créneau obligatoire'),
            endAt:  Yup.date().required('Fin de créneau requis')
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { beginAt } = this.parent;
                    return value && beginAt <= value
                }),
            company: Yup.number().required('Veuillez préciser un point de vente'),
            localisation: Yup.number().nullable(),
            activity: Yup.number().nullable(),
            recurrence: Yup.object().shape({...recurrenceValidation})
        }

        setValidationSchema(Yup.object().shape({...validation}));
        setInitialValues({..._values})
    }

    const handleRecurrenceTypeChange = (type : string) => {
        let fields:string[] = [];

        let recurrenceValidation: any = {
            type: Yup.string().required(),
        }

        switch (type){
            case '1':
                fields = ['type'];
                break;
            case '2':
                recurrenceValidation.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour').required();
                recurrenceValidation.weekLetters = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins une semaine').required();
                fields = ['type', 'daysOfWeek', 'weekLetters'];
                break;
            case '3':
                recurrenceValidation.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour');
                recurrenceValidation.separationCount = Yup.number().min(1).required('Veuillez préciser une répétition de semaine');
                fields = ['type', 'daysOfWeek', 'separationCount'];
                break;
        }

        let _values = {...formik.values}
        _values.recurrence.type = type;

        if (!_values.bookingGroup && type !== '1'){
            fields.push('start', 'end')
            recurrenceValidation.start = Yup.date().required('Début de récurrence requise');
            recurrenceValidation.end = Yup.date().nullable()
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { start } = this.parent;
                    return !value || (value && start <= value)
                })
        }

        // delete unecessary values
        for(let key in _values.recurrence){
            if (!fields.includes(key) && _values.recurrence.hasOwnProperty(key)){
               delete _values.recurrence[key];
            }
        }

        // add default values
        for(let key in fields){
            if(!_values.recurrence.hasOwnProperty(fields[key])){
                switch (fields[key]){
                    case 'daysOfWeek':
                        _values.recurrence.daysOfWeek = [format(_values.beginAt, 'i')];
                        break;
                    case 'weekLetters':
                        _values.recurrence.weekLetters = [];
                        break;
                    case 'separationCount':
                        _values.recurrence.separationCount = 1;
                        break;
                    case 'start':
                        _values.recurrence.start = _values.beginAt;
                        break;
                    case 'end':
                        _values.recurrence.end = null;
                        break;
                }
            }
        }

        let validation =  {
            salary: Yup.number().required('Veuillez choisir un collaborateur'),
            bookingGroup: Yup.number().nullable(),
            beginAt: Yup.date().required('Début de créneau obligatoire'),
            endAt:  Yup.date().required('Fin de créneau requis')
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { beginAt } = this.parent;
                    return value && beginAt <= value
                }),
            company: Yup.number().required('Veuillez préciser un point de vente'),
            localisation: Yup.number().nullable(),
            activity: Yup.number().nullable(),
            recurrence: Yup.object().shape({...recurrenceValidation})
        }

        setValidationSchema(Yup.object().shape({...validation}));
        setInitialValues({..._values})
    }

    const handleWeekDayChange = (e: BaseSyntheticEvent) => {
        let checked = e.target.checked;
        let _values = {...formik.values};
        let dayElements: HTMLInputElement[]|any = document.getElementsByName("recurrence.daysOfWeek")
        let daysOfWeek_copy: string[] = [];
        dayElements.forEach((day: HTMLInputElement) => {
            day.checked = checked;
            if (checked) {
                daysOfWeek_copy.push(day.value)
            }
        })

        _values.recurrence.daysOfWeek = daysOfWeek_copy;
        setInitialValues({..._values});
    }

    const handleWeekChange = (e: BaseSyntheticEvent) => {
        let checked = e.target.checked;
        let _values = {...formik.values};
        let weekElements: HTMLInputElement[]|any = document.getElementsByName("recurrence.weekLetters")
        let weekLetters_copy: string[] = [];
        weekElements.forEach((week: HTMLInputElement) => {
            week.checked = checked;
            if (checked) {
                weekLetters_copy.push(week.value)
            }
        })
        _values.recurrence.weekLetters = weekLetters_copy;
        setInitialValues({..._values});
    }

    const getWeeks = (company: Company) => {

        if (!company) return false;

        let length = company.timesheet.recurrence;
        let ret: string[] = [];
        for (let i = 0; i < length; i++){
            ret.push(String.fromCharCode(65 + i));
        }
        setWeeks(ret);
    }

    if (isLoading){
        return <Loader />
    }


    if (!formik.values.salary || displaySalarySelector){
        return <SingleSalarySelector
            onSubmit={(data: Salary) => {
                handleSalaryChange(data)
                formik.setFieldValue('salary', data.id)
                setDisplaySalarySelector(false)
            }}
            onClose={() => setDisplaySalarySelector(false)}
            initialValue={formik.values.salary}
            required={true}
        />;
    }

    return (
                <form onSubmit={formik.handleSubmit} className="p-3 sivan_form">
                    <div className="col-12 mb-3">
                            <SingleSalarySelected
                                onDelete={() => formik.setFieldValue('salary', null)}
                                onOpen={() => {
                                    setDisplaySalarySelector(true)
                                    formik.setFieldTouched('salary', true)
                                }}
                                initialValue={formik.values.salary}
                                initialSalary={salary}
                                validationClass={validationClass(formik.errors, formik.touched, 'salary')}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-12 col-md mb-3">
                                        <DatePicker
                                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                                            customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                                            selected={formik.values.beginAt}
                                            onChange={(date: Date) => {
                                                formik.setFieldValue(`beginAt`, date)
                                                formik.setFieldTouched('beginAt', true)
                                            }}
                                            selectsStart
                                            startDate={formik.values.start}
                                            maxDate={formik.values.end}
                                            showTimeSelect
                                            popperPlacement={"top-end"}
                                            showPopperArrow={false}
                                            timeIntervals={15}
                                            timeCaption="Heure de début"
                                            dateFormat="E dd MMM uuuu HH:mm"
                                        />
                                    </div>
                                    <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                                        <i className={'bi bi-arrow-left-right mx-1'}> </i>
                                    </div>
                                    <div className="col-12 col-md mb-3">
                                        <DatePicker
                                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'endAt')}
                                            customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                                            showTimeSelect
                                            popperPlacement={"top-end"}
                                            showPopperArrow={false}
                                            selected={formik.values.endAt}
                                            onChange={(date: Date) => {
                                                formik.setFieldValue(`endAt`, date)
                                                formik.setFieldTouched('endAt', true)
                                            }}
                                            selectsEnd
                                            startDate={formik.values.beginAt}
                                            endDate={formik.values.endAt}
                                            minDate={formik.values.beginAt}
                                            minTime={setHours(setMinutes(new Date(),
                                                new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getMinutes() : 0
                                                ),
                                                new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getHours() : 0
                                            )}
                                            maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                            timeIntervals={15}
                                            dateFormat="E dd MMM uuuu HH:mm"
                                        />
                                    </div>
                                </div>
                                <FormError touched={formik.touched} errors={formik.errors} field={'beginAt'} />
                                <FormError touched={formik.touched} errors={formik.errors} field={'endAt'} />
                            </div>
                            <div className="col-12 mb-3">
                                <BootstrapSelect
                                    label={"Type d'heure"}
                                    value={getAccountingTypeChoice(formik.values.accountingType)}
                                    options={AccountingTypes}
                                    onChange={(choice) => formik.setFieldValue('accountingType', choice!.value)}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-3">
                                <BootstrapSelect
                                    required={true}
                                    //options={salary?.companies.map((company: Company) => ({label: company.title, value: company.id!})) || []}
                                    fetchEntity="company"
                                    fetchParams={{ salaries: [formik.values.salary] }}
                                    value={company ? {label: company.title, value: company.id!} : undefined}
                                    onChange={(choice) => {
                                        formik.setFieldValue('company', choice?.value)
                                        formik.setFieldValue('localisation', null)
                                        formik.setFieldValue('activity', null)
                                        setCompany(salary!.companies.find((company: Company) => company.id === choice?.value)!)
                                        if (formik.values.hasOwnProperty('weekLetters')) {
                                            formik.setFieldValue('weekLetters', []);
                                        }
                                    }}
                                    label={'Point de vente'}
                                    labelIcon={'bi bi-shop text-primary me-2'}
                                    className={validationClass(formik.errors, formik.touched, 'company')}
                                />
                                <FormError touched={formik.touched} errors={formik.errors} field={'company'} />
                            </div>
                            {formik.values.company && <>
                                <div className="col-md-6 mb-3">
                                    <BootstrapSelect
                                        required={false}
                                        enableReinitialize={true}
                                        //options={company ? company.localisations!.map((localisation: Localisation) => ({label: localisation.title, value: localisation.id!})) : []}
                                        fetchEntity="localisation"
                                        fetchParams={{ companies: [formik.values.company] }}
                                        className={validationClass(formik.errors, formik.touched, 'localisation')}
                                        onChange={(e) => formik.setFieldValue('localisation', e?.value)}
                                        label={'Emplacement'}
                                        labelIcon={'bi bi-geo-alt text-primary me-2'}
                                    />
                                    <FormError touched={formik.touched} errors={formik.errors} field={'localisation'} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <BootstrapSelect
                                        id={'activitySelector'}
                                        required={false}
                                        enableReinitialize={true}
                                        //options={company?.activities?.map((activity: Activity) => ({label: activity.title, value: activity.id!})) || []}
                                        fetchEntity="activity"
                                        fetchParams={{ companies: [formik.values.company] }}
                                        className={validationClass(formik.errors, formik.touched, 'activity')}
                                        onChange={(e) => formik.setFieldValue('activity', e?.value)}
                                        label={'Activité'}
                                        labelIcon={'bi bi-bookmark-plus text-primary me-2'}
                                    />
                                    <FormError touched={formik.touched} errors={formik.errors} field={'activity'} />
                                </div>
                            </>}
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <textarea name="description" onChange={formik.handleChange} className="form-control" placeholder="Description"
                                              id="description"></textarea>
                                    <label htmlFor="description">Description</label>
                                    <FormError touched={formik.touched} errors={formik.errors} field={'description'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                            <label className={'mb-3'} htmlFor="">
                                <i className={'bi bi-arrow-repeat text-primary me-2'}> </i>   Récurrence
                            </label>
                            <div className="col-12 text-center">
                                <div className="form-check-inline">
                                    <input name={'type'} id={'type_1'} value={'1'} type={'radio'} defaultChecked={formik.values.recurrence.type === '1'} onChange={(e) => {
                                        handleRecurrenceTypeChange('1')
                                    }} />
                                    <label className="form-check-label ms-2" htmlFor="type_1" >
                                        Une seule fois
                                    </label>
                                </div>
                                <div className="form-check-inline">
                                    <input name={'type'} id={'type_2'} value={'2'} type={'radio'} defaultChecked={formik.values.recurrence.type === '2'} onChange={(e) => {
                                        handleRecurrenceTypeChange('2')
                                    }} />
                                    <label className="form-check-label ms-2" htmlFor="type_2">
                                        Semaines X
                                    </label>
                                </div>
                                <div className="form-check-inline">
                                    <input name={'type'} id={'type_3'} value={'3'} type={'radio'} defaultChecked={formik.values.recurrence.type === '3'} onChange={(e) => {
                                        handleRecurrenceTypeChange('3')
                                    }} />
                                    <label className="form-check-label ms-2" htmlFor="type_3">
                                        Personnalisé
                                    </label>
                                </div>
                            </div>
                    </div>
                        {['2','3'].includes(formik.values.recurrence.type) &&
                        <>
                            <div className="col-12 mb-3">
                                <div className="row align-items-center">
                                    <div className="col mb-3">
                                        <BootstrapSelect
                                            label={'Séléctionner un emploi du temps'}
                                            enableReinitialize={true}
                                            value={formik.values.bookingGroup && groups.find(g => g.id == formik.values.bookingGroup) ? {value: groups.find(g => g.id == formik.values.bookingGroup)!.id, label: groups.find(g => g.id == formik.values.bookingGroup)!.title} : undefined }
                                            options={groups.map(g => ({label: g.title, value: g.id}))}
                                            onChange={(c) => handleBookingGroupChange(c?.value)}
                                        />
                                        {formik.values.bookingGroup && <div className="form-text">
                                            {groups.find(g => g.id === formik.values.bookingGroup) && <>A partir du {format(new Date(groups.find(g => g.id === formik.values.bookingGroup)!.start))}</>}
                                            {groups.find(g => g.id === formik.values.bookingGroup)?.finish && <> jusqu'au {format(new Date(groups.find(g => g.id === formik.values.bookingGroup)!.finish))}</>}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            {!formik.values.bookingGroup && <div className="col-12 mb-3">
                                <>
                                    <div className="input-group mb-3">
                                        <DatePicker
                                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'recurrence.start')}
                                            title={'Début'}
                                            ariaLabelledBy={'bi bi-calendar-check text-primary'}
                                            customInput={<DateCustomInput label={'Début de la récurrence'}
                                                                          required={true}/>}
                                            selected={formik.values.recurrence.start}
                                            onChange={(date) => formik.setFieldValue('recurrence.start', date)}
                                            selectsStart
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            startDate={formik.values.recurrence.start}
                                            endDate={formik.values.recurrence.end}
                                            dateFormat="E dd MMM uuuu"
                                            popperPlacement={"top-end"}
                                            showPopperArrow={false}
                                        />
                                        <span className="input-group-text">
                                            <i className={'bi bi-arrow-left-right'}> </i>
                                        </span>
                                        <DatePicker
                                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'recurrence.end')}
                                            title={'Fin'}
                                            ariaLabelledBy={'bi bi-calendar-check text-primary'}
                                            customInput={<DateCustomInput label={'Fin de la recurrence'}/>}
                                            selected={formik.values.recurrence.end}
                                            onChange={(date) => formik.setFieldValue('recurrence.end', date)}
                                            selectsEnd
                                            startDate={formik.values.recurrence.start}
                                            endDate={formik.values.recurrence.end}
                                            minDate={formik.values.recurrence.start}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            isClearable={true}
                                            dateFormat="E dd MMM uuuu"
                                            popperPlacement={"top-end"}
                                            showPopperArrow={false}
                                        />
                                    </div>
                                </>
                                <FormError touched={formik.touched} errors={formik.errors} field={'recurrence.end'}/>
                            </div>}
                            <div className={'col-12 mb-3 text-center'}>
                                <div className="form-check-inline mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked_days"
                                           onChange={handleWeekDayChange} />
                                    <label className="form-check-label" htmlFor="flexCheckChecked_days">
                                        Tous les jours de la semaine
                                    </label>
                                </div>
                                <div className="col-12">
                                    {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                                    return (
                                        <>
                                            <input key={index}
                                                   type="checkbox"
                                                   onChange={formik.handleChange}
                                                   name={'recurrence.daysOfWeek'}
                                                   defaultChecked={formik.values.recurrence.daysOfWeek?.includes(`${index + 1}`)}
                                                   className="btn-check"
                                                   id={`btn-check-${index}`}
                                                   autoComplete="off"
                                                   value={`${index + 1}`} />
                                            <label key={index + 7} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-check-${index}`}>
                                                {day}
                                            </label>
                                        </>
                                    )
                                })}
                                    <FormError touched={formik.touched} errors={formik.errors} field={'recurrence.daysOfWeek'} />
                                </div>
                            </div>
                        </>
                        }
                        {formik.values.recurrence.type === '3' &&
                        <div className={'col-12 mb-3'}>
                            <div className="input-group">
                                <div className="form-floating">
                                    <input
                                        min={1}
                                        defaultValue={formik.values.recurrence.separationCount || 1}
                                        type="number"
                                        onChange={formik.handleChange}
                                        name={'recurrence.separationCount'}
                                        id={'recurrence.separationCount'}
                                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'recurrence.separationCount')}
                                        aria-describedby="separation-addon" />
                                    <label htmlFor="">
                                        Répeter toutes les
                                    </label>
                                    <FormError touched={formik.touched} errors={formik.errors} field={'recurrence.separationCount'} />
                                </div>
                                <span className="input-group-text" id="separation-addon">
                                    Semaines
                                </span>
                            </div>
                        </div>}
                        {formik.values.recurrence.type === '2' &&
                        <div className={'col-12 text-center mb-3'}>
                            <div className="form-check-inline mb-3">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                       onChange={handleWeekChange} />
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    Toutes les semaines
                                </label>
                            </div>
                            <div className="col-12">
                                {weeks.map((week: string, index: number) => {
                                    return (
                                        <>
                                            <input
                                                key={index}
                                                type="checkbox"
                                                onChange={formik.handleChange}
                                                name={'recurrence.weekLetters'}
                                                className="btn-check"
                                                id={`btn-check-${week}`}
                                                defaultChecked={formik.values.recurrence.weekLetters?.includes(`${index + 1}`)}
                                                   autoComplete="off" value={`${index + 1}`} />
                                            <label key={index + weeks.length} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-check-${week}`}>
                                                {week}
                                            </label>
                                        </>
                                    )
                                })}
                                <FormError touched={formik.touched} errors={formik.errors} field={'recurrence.weekLetters'} />
                            </div>
                        </div>}
                    <button disabled={formik.isSubmitting} className='btn btn-outline-primary w-100' type="submit">
                        {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-plus-circle'}> </i> Ajouter</>}
                    </button>
                </form>
    )
}

export default BookingAddForm;