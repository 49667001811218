import {post} from "../../xhr";

interface FetchData {
    start: string,
    end: string,
    options?: {
        salaries?: number[],
        taskGroups?: number[],
    }
}

export function fetchResources(requestData: FetchData){
    return post('task/calendar/resources', requestData);
}

export function fetchEvents(requestData: FetchData, signal: AbortSignal){
    return post('task/calendar/events', requestData, {signal: signal});
}