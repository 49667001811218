import React, {useEffect, useState} from "react";
import SalaryTemplateEventAddForm from "../form";
import {DateSelectArg} from "@fullcalendar/react";
import {Company} from "../../../../../../models/companies";
import {SalaryTemplate, SalaryTemplateEvents} from "../../../../../../models/salary/template";

const SalaryTemplateEventSelect:React.FC<{arg: DateSelectArg, week: number, company: Company, template: SalaryTemplate, handleSubmitSuccess: (ev: any) => any, events: SalaryTemplateEvents}> = (props) => {

    const {arg, template, company, week, events, handleSubmitSuccess} = props

    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey(prev => prev + 1)
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventDropLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <SalaryTemplateEventAddForm
                key={key}
                start={arg.start}
                end={arg.end}
                week={week}
                company={company}
                template={template}
                handleSubmitSuccess={handleSubmitSuccess}
                events={events}/>}
        </div>
    </>
}

export default SalaryTemplateEventSelect;