import {post, get} from "../xhr";

export function match(requestData: {isSameDuration: boolean, start: string, end: string, startDate: string, endDate: string, jobs: number[], salaries: number[] }, salary: number){
    return post(`slot_exchange/match/${salary}`, requestData || {});
}

export function create(requestData: {
    exchange: number,
    exchangeInstanceToken: string,
    exchanged: number,
    exchangedInstanceToken: string,
    accountingType: number,
}){
    return post(`slot_exchange/create`, requestData);
}
export function cancel(id: number){
    return get(`slot_exchange/cancel/${id}`);
}