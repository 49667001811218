import {post, get, DISABLED_SWITCH} from "../xhr";
import * as models from "../../models/auth";

export function login(requestData: models.userLogin){
   return post('login_check', requestData);
}

export function register(requestData: models.AdminRegister){
   return post('auth/register', requestData);
}

export function confirm(token: string){
   return get('auth/confirmation/' + token);
}

export function tokenExist(token: string){
   return get('auth/confirmation/token/' + token);
}

export function password(requestData: {password: string, repeatPassword: string, previousPassword: string}){
   return post('auth/password', requestData);
}

export function passwordReset(requestData: {password: string, repeatPassword: string, code: string, email: string}){
   return post('auth/password/reset', requestData);
}

export function requestPassword(requestData: {email: string}){
   return post('auth/password/request', requestData);
}

export function requestPasswordValidation(requestData: {code: string, email: string}){
   return post('auth/password/request/validation', requestData);
}

export function confirmPassword(requestData: {repeatPassword: string, password: string}, token: string){
   return post(`auth/confirm/password/${token}`, requestData);
}

export function accounts(){
   return get('auth/accounts');
}

export function selectAccount(id: number){
   return get('auth/select_account/' + id);
}

export function refreshAuth(){
   return get('auth/refresh', {headers: [DISABLED_SWITCH]});
}


