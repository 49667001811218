export const HANDLE_BILLING = 'HANDLE_BILLING';

export const LIST_SALARY = 'LIST_SALARY';
export const LIST_SALARY_FILE = 'LIST_SALARY_FILE';
export const LIST_SELF_SALARY_FILE = 'LIST_SELF_SALARY_FILE';
export const IMPORT_SALARY = 'IMPORT_SALARY';
export const EDIT_SALARY = 'EDIT_SALARY';
export const DELETE_SALARY = 'DELETE_SALARY';

export const LIST_BOOKING = 'LIST_BOOKING';
export const LIST_SELF_BOOKING = 'LIST_SELF_BOOKING';
export const EDIT_BOOKING = 'EDIT_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const SHOW_ABOVE_MAX_VISIBILITY_DATE = 'SHOW_ABOVE_MAX_VISIBILITY_DATE';

export const PUBLISH_CALENDAR = "PUBLISH_CALENDAR";
export const DOWNLOAD_CALENDAR = "DOWNLOAD_CALENDAR";

export const LIST_BOOKING_OFFER = 'LIST_BOOKING_OFFER';
export const EDIT_BOOKING_OFFER = 'EDIT_BOOKING_OFFER';
export const DELETE_BOOKING_OFFER = 'DELETE_BOOKING_OFFER';
export const APPLY_BOOKING_OFFER = 'APPLY_BOOKING_OFFER';

export const LIST_DOCUMENT = 'LIST_DOCUMENT';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

export const LIST_KEEP_NOTE = 'LIST_KEEP_NOTE';
export const EDIT_KEEP_NOTE = 'EDIT_KEEP_NOTE';
export const DELETE_KEEP_NOTE = 'DELETE_KEEP_NOTE';

export const LIST_TIME_CLOCK = 'LIST_TIME_CLOCK';
export const LIST_SELF_TIME_CLOCK = 'LIST_SELF_TIME_CLOCK';
export const EDIT_TIME_CLOCK = 'EDIT_TIME_CLOCK';
export const DELETE_TIME_CLOCK = 'DELETE_TIME_CLOCK';
export const CLOCK_IN_FROM_APP = 'CLOCK_IN_FROM_APP';

export const LIST_COMPANY = 'LIST_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const LIST_CUSTOMER_FLOW = 'LIST_CUSTOMER_FLOW';

export const LIST_JOB = 'LIST_JOB';
export const EDIT_JOB = 'EDIT_JOB';
export const DELETE_JOB = 'DELETE_JOB';

export const LIST_LOCALISATION = 'LIST_LOCALISATION';
export const EDIT_LOCALISATION = 'EDIT_LOCALISATION';
export const DELETE_LOCALISATION = 'DELETE_LOCALISATION';

export const LIST_ABSENCE = 'LIST_ABSENCE';
export const EDIT_ABSENCE = 'EDIT_ABSENCE';
export const EDIT_ABSENCE_FILE = 'EDIT_ABSENCE_FILE';
export const DELETE_ABSENCE = 'DELETE_ABSENCE';
export const REQUEST_ABSENCE = 'REQUEST_ABSENCE';
export const VALIDATE_SELF_ABSENCE = 'VALIDATE_SELF_ABSENCE';

export const LIST_EXPENSE = 'LIST_EXPENSE';
export const EDIT_EXPENSE = 'EDIT_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const REQUEST_EXPENSE = 'REQUEST_EXPENSE';

export const LIST_TASK_GROUP = 'LIST_TASK_GROUP';
export const EDIT_TASK_GROUP = 'EDIT_TASK_GROUP';
export const DELETE_TASK_GROUP = 'DELETE_TASK_GROUP';
export const LIST_SELF_TASK = 'LIST_SELF_TASK';
export const EDIT_TASK = 'EDIT_TASK';


export const LIST_TEMPLATE = 'LIST_TEMPLATE';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

export const EDIT_TEMPLATE_APPLIED = 'EDIT_TEMPLATE_APPLIED';
export const DELETE_TEMPLATE_APPLIED = 'DELETE_TEMPLATE_APPLIED';

export const LIST_ACTIVITY = 'LIST_ACTIVITY';
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';

export const LIST_COUNTER = 'LIST_COUNTER';
export const LIST_SELF_COUNTER = 'LIST_SELF_COUNTER';
export const EDIT_COUNTER = 'EDIT_COUNTER';
export const DELETE_COUNTER = 'DELETE_COUNTER';

export const LIST_TEAM = 'LIST_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';

export const LIST_ACCESS_LEVEL = 'LIST_ACCESS_LEVEL';
export const EDIT_ACCESS_LEVEL = 'EDIT_ACCESS_LEVEL';
export const DELETE_ACCESS_LEVEL = 'DELETE_ACCESS_LEVEL';

export const LIST_CHANNEL = 'LIST_CHANNEL';
export const EDIT_CHANNEL = 'EDIT_CHANNEL';
export const DELETE_CHANNEL = 'DELETE_CHANNEL';

export const LIST_REPORTING = 'LIST_REPORTING';
export const LIST_SELF_REPORTING = 'LIST_SELF_REPORTING';
export const EXPORT_REPORTING = 'EXPORT_REPORTING';
export const READ_FLAT_RATE = 'READ_FLAT_RATE';

export const EDIT_COMPANY_GROUP = 'EDIT_COMPANY_GROUP';


export const FEED_LIST_BIRTHDAY = 'FEED_LIST_BIRTHDAY';
export const FEED_LIST_SENIORITY = 'FEED_LIST_SENIORITY';
export const FEED_LIST_TRIAL = 'FEED_LIST_TRIAL';
export const FEED_LIST_START = 'FEED_LIST_START';
export const FEED_LIST_END = 'FEED_LIST_END';
export const FEED_LIST_END_ACTIVATED = 'FEED_LIST_END_ACTIVATED';

export const availableAccessRight = {
    salary: {
        title: "Collaborateurs",
        icon: 'bi bi-person',
        module: "",
        items :[
            {
                underscoreCase: LIST_SALARY,
                description: "Afficher les collaborateurs",
            },
            {
                underscoreCase: LIST_SALARY_FILE,
                description: "Accès à l'espace de stockage",
            },
            {
                underscoreCase: LIST_SELF_SALARY_FILE,
                description: "Accès à son propre espace de stockage",
            },
            {
                underscoreCase: READ_FLAT_RATE,
                description: "Afficher les taux horaires bruts",
            },
            {
                underscoreCase: EDIT_SALARY,
                description: "Ajouter/Modifier les collaborateurs",
            },
            {
                underscoreCase: IMPORT_SALARY,
                description: "Importer des collaborateurs",
            },
            {
                underscoreCase: DELETE_SALARY,
                description: "Supprimer les collaborateurs",
            },
        ]
    },
    company: {
        title: "Points de ventes",
        icon: 'bi bi-shop',
        module: "",
        items :[
            {
                underscoreCase: LIST_COMPANY,
                description: "Afficher les points de ventes",
            },
            {
                underscoreCase: EDIT_COMPANY,
                description: "Ajouter/Modifier les points de ventes",
            },
            {
                underscoreCase: DELETE_COMPANY,
                description: "Supprimer les points de ventes",
            },
            {
                underscoreCase: LIST_CUSTOMER_FLOW,
                description: "Afficher le flux client",
            },
        ]
    },
    job: {
        title: "Métiers",
        icon: 'bi bi-person-bounding-box',
        module: "",
        items :[
            {
                underscoreCase: LIST_JOB,
                description: "Afficher les métiers",
            },
            {
                underscoreCase: EDIT_JOB,
                description: "Ajouter/Modifier les métiers",
            },
            {
                underscoreCase: DELETE_JOB,
                description: "Supprimer les métiers",
            },
        ]
    },
    localisation: {
        title: "Localisations",
        icon: 'bi bi-geo-alt',
        module: "",
        items :[
            {
                underscoreCase: LIST_LOCALISATION,
                description: "Afficher les localisations",
            },
            {
                underscoreCase: EDIT_LOCALISATION,
                description: "Ajouter/Modifier les localisations",
            },
            {
                underscoreCase: DELETE_LOCALISATION,
                description: "Supprimer les localisations",
            },
        ]
    },
    activity: {
        title: "Activités",
        icon: 'bi bi-bookmark',
        module: "",
        items :[
            {
                underscoreCase: LIST_ACTIVITY,
                description: "Afficher les activités",
            },
            {
                underscoreCase: EDIT_ACTIVITY,
                description: "Ajouter/Modifier les activités",
            },
            {
                underscoreCase: DELETE_ACTIVITY,
                description: "Supprimer les activités",
            },
        ]
    },
    booking: {
        title: "Planning",
        icon: 'bi bi-calendar-day',
        module: "",
        items :[
            {
                underscoreCase: LIST_BOOKING,
                description: "Afficher le planning général",
            },
            {
                underscoreCase: LIST_SELF_BOOKING,
                description: "Afficher son propre planning",
            },
            {
                underscoreCase: SHOW_ABOVE_MAX_VISIBILITY_DATE,
                description: "Afficher le planning au-delà de la date de visibilité",
            },
            {
                underscoreCase: EDIT_BOOKING,
                description: "Ajouter/Modifier les créneaux",
            },
            {
                underscoreCase: DELETE_BOOKING,
                description: "Supprimer les créneaux",
            },
            {
                underscoreCase: PUBLISH_CALENDAR,
                description: "Publier le planning",
            },
            {
                underscoreCase: DOWNLOAD_CALENDAR,
                description: "Télécharger le planning",
            },
        ]
    },
    absence: {
        title: "Absences",
        icon: 'bi bi-calendar-x',
        module: "",
        items :[
            {
                underscoreCase: LIST_ABSENCE,
                description: "Afficher les absences",
            },
            {
                underscoreCase: EDIT_ABSENCE,
                description: "Ajouter/Modifier les absences",
            },
            {
                underscoreCase: DELETE_ABSENCE,
                description: "Supprimer les absences",
            },
            {
                underscoreCase: REQUEST_ABSENCE,
                description: "Demander les absences",
            },
            {
                underscoreCase: VALIDATE_SELF_ABSENCE,
                description: "Valider ses propres demandes d'absences",
            },
        ]
    },
    expense: {
        title: "Notes de frais",
        icon: 'bi bi-cash',
        module: "expense",
        items :[
            {
                underscoreCase: LIST_EXPENSE,
                description: "Afficher les notes de frais",
            },
            {
                underscoreCase: EDIT_EXPENSE,
                description: "Ajouter/Modifier les notes de frais",
            },
            {
                underscoreCase: DELETE_EXPENSE,
                description: "Supprimer les notes de frais",
            },
            {
                underscoreCase: REQUEST_EXPENSE,
                description: "Demander les notes de frais",
            },
        ]
    },
    task: {
        title: "Tâches",
        icon: 'bi bi-journal',
        module: "",
        items :[
            {
                underscoreCase: LIST_TASK_GROUP,
                description: "Afficher toutes les tâches",
            },
            {
                underscoreCase: LIST_SELF_TASK,
                description: "Afficher ses propres tâches",
            },
            {
                underscoreCase: EDIT_TASK_GROUP,
                description: "Ajouter/Modifier les groupes de tâches",
            },
            {
                underscoreCase: EDIT_TASK,
                description: "Ajouter/Modifier les tâches",
            },
            {
                underscoreCase: DELETE_TASK_GROUP,
                description: "Supprimer les groupes de tâches",
            }

        ]
    },
    template: {
        title: "Templates",
        icon: 'bi bi-front',
        module: "",
        items :[
            {
                underscoreCase: LIST_TEMPLATE,
                description: "Afficher les templates",
            },
            {
                underscoreCase: EDIT_TEMPLATE,
                description: "Ajouter/Modifier les templates",
            },
            {
                underscoreCase: DELETE_TEMPLATE,
                description: "Supprimer les templates",
            },
            {
                underscoreCase: EDIT_TEMPLATE_APPLIED,
                description: "Ajouter/Modifier les templates appliqués au planning",
            },
            {
                underscoreCase: DELETE_TEMPLATE_APPLIED,
                description: "Supprimer les templates appliqués au planning",
            },
        ]
    },
    timeClock: {
        title: "Badgeages",
        icon: 'bi bi-person-badge',
        module: "timeClock",
        items :[
            {
                underscoreCase: LIST_TIME_CLOCK,
                description: "Afficher tous les badgeages",
            },
            {
                underscoreCase: LIST_SELF_TIME_CLOCK,
                description: "Afficher ses propres badgeages",
            },
            {
                underscoreCase: EDIT_TIME_CLOCK,
                description: "Ajouter/Modifier les badgeages",
            },
            {
                underscoreCase: DELETE_TIME_CLOCK,
                description: "Supprimer les badgeages",
            },
            {
                underscoreCase: CLOCK_IN_FROM_APP,
                description: "Badger depuis l'application",
            },
        ]
    },
    reporting: {
        title: "Rapports",
        icon: 'bi bi-file-check',
        module: "",
        items :[
            {
                underscoreCase: LIST_REPORTING,
                description: "Afficher les rapports",
            },
            {
                underscoreCase: LIST_SELF_REPORTING,
                description: "Afficher ses propres rapports",
            },
            {
                underscoreCase: EXPORT_REPORTING,
                description: "Exporter les rapports",
            },
        ]
    },
    counter: {
        title: "Compteurs",
        icon: 'bi bi-bar-chart',
        module: "",
        items :[
            {
                underscoreCase: LIST_COUNTER,
                description: "Afficher tous les compteurs",
            },
            {
                underscoreCase: LIST_SELF_COUNTER,
                description: "Afficher son propre compteur",
            },
            {
                underscoreCase: EDIT_COUNTER,
                description: "Ajouter/Modifier les compteurs",
            },
            {
                underscoreCase: DELETE_COUNTER,
                description: "Supprimer les compteurs",
            }
        ]
    },
    accessLevel: {
        title: "Droits d'accès",
        icon: "bi bi-shield",
        module: "",
        items :[
            {
                underscoreCase: LIST_ACCESS_LEVEL,
                description: "Afficher les droits d'accès",
            },
            {
                underscoreCase: EDIT_ACCESS_LEVEL,
                description: "Ajouter/Modifier les droits d'accès",
            },
            {
                underscoreCase: DELETE_ACCESS_LEVEL,
                description: "Supprimer les droits d'accès",
            }
        ]
    },
    team: {
        title: "Équipes",
        icon: "bi bi-people",
        module: "team",
        items :[
            {
                underscoreCase: LIST_TEAM,
                description: "Afficher les équipes",
            },
            {
                underscoreCase: EDIT_TEAM,
                description: "Ajouter/Modifier les équipes",
            },
            {
                underscoreCase: DELETE_TEAM,
                description: "Supprimer les équipes",
            }
        ]
    },
    settings: {
        title: "Réglages",
        icon: "bi bi-gear",
        module: "",
        items :[
            {
                underscoreCase: EDIT_COMPANY_GROUP,
                description: "Afficher/modifier les réglages globaux",
            },
        ]
    },
    channel: {
        title: "Messagerie",
        icon: "bi bi-envelope",
        module: "",
        items :[
            {
                underscoreCase: LIST_CHANNEL,
                description: "Accéder à la messagerie",
            },
            {
                underscoreCase: EDIT_CHANNEL,
                description: "Créer des discussions",
            },
            {
                underscoreCase: DELETE_CHANNEL,
                description: "Archiver les discussions",
            }
        ]
    },
    feed: {
        title: "Actualités",
        icon: "bi bi-journal-text",
        module: "",
        items :[
            {
                underscoreCase: FEED_LIST_BIRTHDAY,
                description: "Lister les dates anniversaires",
            },
            {
                underscoreCase: FEED_LIST_SENIORITY,
                description: "Lister les seuils d'ancienneté",
            },
            {
                underscoreCase: FEED_LIST_TRIAL,
                description: "Lister les fins de période d'essai",
            },
            {
                underscoreCase: FEED_LIST_START,
                description: "Lister les dates de début de contrat",
            },
            {
                underscoreCase: FEED_LIST_END,
                description: "Lister les dates de fin de contrat",
            },
            {
                underscoreCase: FEED_LIST_END_ACTIVATED,
                description: "Lister les collaborateurs avec un compte actif qui ont fini leur contrat",
            }
        ]
    },
    bookingOffer: {
        title: "Offres de créneaux",
        icon: "bi bi-calendar-plus",
        module: "bookingOffer",
        items :[
            {
                underscoreCase: LIST_BOOKING_OFFER,
                description: "Afficher les offres de créneaux",
            },
            {
                underscoreCase: EDIT_BOOKING_OFFER,
                description: "Ajouter/Modifier les offres de créneaux",
            },
            {
                underscoreCase: DELETE_BOOKING_OFFER,
                description: "Supprimer les offres de créneaux",
            },
            {
                underscoreCase: APPLY_BOOKING_OFFER,
                description: "Postuler aux offres de créneaux",
            },
        ]
    },
    document: {
        title: "Documents",
        icon: "bi bi-file-earmark",
        module: "document",
        items :[
            {
                underscoreCase: LIST_DOCUMENT,
                description: "Afficher",
            },
            {
                underscoreCase: EDIT_DOCUMENT,
                description: "Ajouter/Modifier",
            },
            {
                underscoreCase: DELETE_DOCUMENT,
                description: "Supprimer",
            },
        ]
    },
    keepNotes: {
        title: "Notes",
        icon: "bi bi-file-earmark",
        module: "",
        items :[
            {
                underscoreCase: LIST_KEEP_NOTE,
                description: "Afficher",
            },
            {
                underscoreCase: EDIT_KEEP_NOTE,
                description: "Ajouter/Modifier",
            },
            {
                underscoreCase: DELETE_KEEP_NOTE,
                description: "Supprimer",
            },
        ]
    },
}

export const ManagerAccessRights = {
    booking: {
        title: "Planning",
        icon: 'bi bi-calendar-day',
        module: "",
        items :[
            {
                underscoreCase: LIST_BOOKING,
                description: "Afficher le planning",
            },
            {
                underscoreCase: EDIT_BOOKING,
                description: "Ajouter/Modifier les créneaux",
            },
            {
                underscoreCase: DELETE_BOOKING,
                description: "Supprimer les créneaux",
            },
        ]
    },
    absence: {
        title: "Absences",
        icon: 'bi bi-calendar-x',
        module: "",
        items :[
            {
                underscoreCase: LIST_ABSENCE,
                description: "Afficher les absences",
            },
            {
                underscoreCase: EDIT_ABSENCE,
                description: "Ajouter/Modifier les absences",
            },
            {
                underscoreCase: DELETE_ABSENCE,
                description: "Supprimer les absences",
            }
        ]
    },
    expense: {
        title: "Notes de frais",
        icon: 'bi bi-cash',
        module: "expense",
        items :[
            {
                underscoreCase: LIST_EXPENSE,
                description: "Afficher les notes de frais",
            },
            {
                underscoreCase: EDIT_EXPENSE,
                description: "Ajouter/Modifier les notes de frais",
            },
            {
                underscoreCase: DELETE_EXPENSE,
                description: "Supprimer les notes de frais",
            }
        ]
    },
    task: {
        title: "Tâches",
        icon: 'bi bi-journal',
        module: "",
        items :[
            {
                underscoreCase: LIST_TASK_GROUP,
                description: "Afficher toutes les tâches",
            },
            {
                underscoreCase: EDIT_TASK_GROUP,
                description: "Ajouter/Modifier les groupes de tâches",
            },
            {
                underscoreCase: EDIT_TASK,
                description: "Ajouter/Modifier les tâches",
            },

        ]
    },
    timeClock: {
        title: "Badgeages",
        icon: 'bi bi-person-badge',
        module: "timeClock",
        items :[
            {
                underscoreCase: LIST_TIME_CLOCK,
                description: "Afficher tous les badgeages",
            },
            {
                underscoreCase: EDIT_TIME_CLOCK,
                description: "Ajouter/Modifier les badgeages",
            },
            {
                underscoreCase: DELETE_TIME_CLOCK,
                description: "Supprimer les badgeages",
            },
        ]
    },
    reporting: {
        title: "Rapports",
        icon: 'bi bi-file-check',
        module: "",
        items :[
            {
                underscoreCase: LIST_REPORTING,
                description: "Afficher les rapports",
            },
            {
                underscoreCase: EXPORT_REPORTING,
                description: "Exporter les rapports",
            },
        ]
    },
    counter: {
        title: "Compteurs",
        icon: 'bi bi-bar-chart',
        module: "",
        items :[
            {
                underscoreCase: LIST_COUNTER,
                description: "Afficher tous les compteurs",
            },
            {
                underscoreCase: EDIT_COUNTER,
                description: "Ajouter/Modifier les compteurs",
            },
            {
                underscoreCase: DELETE_COUNTER,
                description: "Supprimer les compteurs",
            }
        ]
    },
    bookingOffer: {
        title: "Offres de créneaux",
        icon: "bi bi-calendar-plus",
        module: "bookingOffer",
        items :[
            {
                underscoreCase: LIST_BOOKING_OFFER,
                description: "Afficher les offres de créneaux",
            },
            {
                underscoreCase: EDIT_BOOKING_OFFER,
                description: "Ajouter/Modifier les offres de créneaux",
            },
            {
                underscoreCase: DELETE_BOOKING_OFFER,
                description: "Supprimer les offres de créneaux",
            },
        ]
    },
    billing: {
        title: "Facturation",
        icon: "bi bi-cart",
        module: "billing",
        items :[
            {
                underscoreCase: HANDLE_BILLING,
                description: "Gérer la facturation (sourscrire aux abonnements, accèder aux factures)",
            },
        ]
    },
}
export const getAllManagerRights = () => [
    LIST_BOOKING,
    EDIT_BOOKING,
    DELETE_BOOKING,
    LIST_ABSENCE,
    EDIT_ABSENCE,
    DELETE_ABSENCE,
    LIST_EXPENSE,
    EDIT_EXPENSE,
    DELETE_EXPENSE,
    LIST_TASK_GROUP,
    EDIT_TASK_GROUP,
    EDIT_TASK,
    LIST_TIME_CLOCK,
    EDIT_TIME_CLOCK,
    DELETE_TIME_CLOCK,
    LIST_REPORTING,
    EXPORT_REPORTING,
    LIST_COUNTER,
    EDIT_COUNTER,
    DELETE_COUNTER,
    LIST_BOOKING_OFFER,
    EDIT_BOOKING_OFFER,
    DELETE_BOOKING_OFFER,
]

export function getAvailableRightsWithoutSubject()
{
    return [
        DOWNLOAD_CALENDAR,
        PUBLISH_CALENDAR,
        LIST_SALARY,
        LIST_BOOKING_OFFER,
        LIST_BOOKING,
        LIST_SELF_BOOKING,
        LIST_TIME_CLOCK,
        LIST_COMPANY,
        LIST_JOB,
        LIST_LOCALISATION,
        LIST_ABSENCE,
        LIST_TASK_GROUP,
        LIST_SELF_TASK,
        LIST_SELF_TIME_CLOCK,
        LIST_SELF_COUNTER,
        LIST_KEEP_NOTE,
        LIST_TEMPLATE,
        LIST_ACTIVITY,
        LIST_COUNTER,
        LIST_REPORTING,
        LIST_ACCESS_LEVEL,
        LIST_CUSTOMER_FLOW,
        LIST_CHANNEL,
        CLOCK_IN_FROM_APP,
        REQUEST_ABSENCE,
        READ_FLAT_RATE,
        IMPORT_SALARY,
        FEED_LIST_BIRTHDAY,
        FEED_LIST_SENIORITY,
        FEED_LIST_TRIAL,
        FEED_LIST_START,
        FEED_LIST_END,
        FEED_LIST_END_ACTIVATED,
        LIST_DOCUMENT,
        LIST_TEAM,
        LIST_EXPENSE,
        REQUEST_EXPENSE,
    ];
}