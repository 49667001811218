import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/timeClock"
import * as api from "../../../adapters/timeClock"
import format from "../../../utils/locale"
import {TimeClock, TimeClockGenerated} from "../../../models/timeClock";
import DatePicker from "react-datepicker";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import GeneratedList from "../generatedList";
import {closeSidebar} from "../../../actions/rightSidebar";
import {TIME_CLOCK_LIST} from "../../../constants/rightSidebar";
import {Salaries, Salary} from "../../../models/salary";
import * as accessRights from "../../../constants/accessRight";
import SalarySelector from "../../salary/selector";

const TimeClockSidebarList: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const [startDate, setStartDate] = useState<Date>(state.rightSidebar.data.start || new Date());
    const [endDate, setEndDate] = useState<Date>(state.rightSidebar.data.end || new Date());
    const [salaries, setSalaries] = useState<number[]>([])
    const [companies, setCompanies] = useState<number[]>([])
    const [isAnomaly, setIsAnomaly] = useState<boolean>(!!state.rightSidebar.data.isAnomaly)
    const [isLate, setIsLate] = useState<boolean>(!!state.rightSidebar.data.isLate)
    const [isMissing, setIsMissing] = useState<boolean>(!!state.rightSidebar.data.isMissing)
    const [filterHasChanged, setFilterHasChanged] = useState<boolean>(false)
    const [filtered, setFiltered] = useState<TimeClockGenerated[]>([])

    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        const controller = new AbortController();

        if (startDate && endDate && filterHasChanged) {
            dispatch(actions.fetchTimeClocks());
            api.fetch({
                start: format(startDate, 'uuuu-MM-dd'), end: format(endDate, 'uuuu-MM-dd'), options: {}
            }, controller.signal).then(data => {
                dispatch(actions.fetchTimeClocksSuccess(data.data.events));
            })
        }

        if (!filterHasChanged)
            setFilterHasChanged(true)

        return () => controller.abort()
    }, [startDate, endDate])

    useEffect(() => {
        if (state.timeClock.payload){
            setFiltered([...state.timeClock.payload.filter(((timeClock: TimeClockGenerated) => {

                if (salaries.length && !salaries.includes(timeClock.salary.id!)) return false;
                if (companies.length && !companies.includes(timeClock.company.id!)) return false;

                if (!isLate && !isAnomaly && !isMissing) return true;

                let late = isLate && timeClock.isLate;
                let absent = isMissing && timeClock.isMissing;
                let anomaly = isAnomaly && timeClock.isAnomaly;

                return late || absent || anomaly;
            }))])
        }
    }, [state.timeClock.payload, isLate, isMissing, isAnomaly, salaries, companies])

    return <>
        <div className="w-100 p-2 position-sticky bg-white top-0" style={{zIndex: 100}}>
            <button onClick={() => dispatch(closeSidebar())} className={'btn'}>
                <i className={'bi fs-5 bi-chevron-left'}> </i>
            </button>
            <button className="btn btn-outline-primary" type="button" data-bs-toggle="collapse"
                    data-bs-target="#timeClockFilter" aria-expanded="false" aria-controls="timeClockFilter">
                <i className={'bi bi-filter'}> </i> Filtrer
            </button>

            <div className="collapse mt-2" id="timeClockFilter">
                <div className="card card-body">
                    <div className="row g-3">
                        <div className="col-6">
                            <div className="inline-datepicker-container bg-white">
                                <DatePicker
                                    disabled={state.timeClock.isLoading}
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={new Date()}
                                    selectsRange
                                    inline
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check mb-1">
                                <input
                                    defaultChecked={isLate}
                                    onChange={(e) => setIsLate(e.target.checked)}
                                    disabled={state.timeClock.isLoading} className="form-check-input" type="checkbox" value="" id="isLate" />
                                <label className="form-check-label" htmlFor="isLate">
                                    Retards
                                    <span className="badge bg-secondary ms-2">
                                        {[...state.timeClock.payload].filter((timeClockGenerated: TimeClockGenerated) => timeClockGenerated.isLate).length}
                                    </span>
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    defaultChecked={isMissing}
                                    onChange={(e) => setIsMissing(e.target.checked)}
                                    disabled={state.timeClock.isLoading} className="form-check-input" type="checkbox" value="" id="isMissing" />
                                <label className="form-check-label" htmlFor="isMissing">
                                    Absences
                                    <span className="badge bg-secondary ms-2">
                                        {[...state.timeClock.payload].filter((timeClockGenerated: TimeClockGenerated) => timeClockGenerated.isMissing).length}
                                    </span>
                                </label>
                            </div>
                            <hr/>
                            <div className="form-check mb-3">
                                <input disabled={state.timeClock.isLoading}
                                       defaultChecked={isAnomaly}
                                       onChange={(e) => setIsAnomaly(e.target.checked)}
                                       className="form-check-input" type="checkbox" value="" id="isAnomaly" />
                                <label className="form-check-label" htmlFor="isAnomaly">
                                    Anomalies
                                    <span className="badge bg-secondary ms-2">
                                        {[...state.timeClock.payload].filter((timeClockGenerated: TimeClockGenerated) => timeClockGenerated.isAnomaly).length}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <SalarySelector
                                fetchOptions={true}
                                multiple={true}
                                disabled={state.isLoading}
                                onChange={(choices: Salaries) => setSalaries(choices.map(choice => Number(choice.id)))}
                                accessRight={accessRights.LIST_TIME_CLOCK}
                            />
                        </div>
                        <div className="col-6">
                            <BootstrapSelect
                                disabled={state.isLoading}
                                fetchEntity={"company"}
                                label={'Points de vente'}
                                isMultiple
                                onChangeMultiple={(choices) => {
                                    setCompanies(choices.map(choice => Number(choice.value)))
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="w-100 overflow-auto position-relative">
            {state.timeClock.isLoading && <div className={'w-100 my-5 text-center'}>
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <GeneratedList next={TIME_CLOCK_LIST} data={{
                isLate: isLate,
                isMissing: isMissing,
                isAnomaly: isAnomaly,
                payload: filtered,
                start: startDate,
                end: endDate
            }} bookings={filtered} />
        </div>
    </>
}

export default TimeClockSidebarList;