import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import * as models from "../../../models/document";

import { PAGINATION } from "../../../constants/global";

import { list } from "../../../adapters/document";
import * as actions from "../../../actions/document";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { axiosError } from "../../../actions/axios";

import LoaderList from "../../../utils/loaderList";

const DocumentList: React.FC = () => {
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny)  => state.document);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [query, setQuery] = useState<string>();
    const [isFiltering, setFiltering] = useState<boolean>(false)
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    useEffect(() => {
        let controller = new AbortController();

        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                dispatch(actions.fetchDocuments())
                setIsLoading(true);
                setOffset(0)
                list({query: query, offset: 0}, controller.signal).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchDocumentsSuccess(data.data))
                    setIsLoading(false);
                    setOffset( 1);
                    setFiltering(false)
                }).catch(error => {
                    dispatch(axiosError(error))
                })
            }
        }, 1000);

        return () => {
            controller.abort();
            clearTimeout(timer)
        }
    }, [query])

    useEffect(() => {
        let controller = new AbortController();
        if(scrollBottom){
            if (offset && !isLast){
                setIsLoading(true)
                list({query: query, offset: offset}, controller.signal).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchDocumentsSuccess([...state.payload, ...data.data]))
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                    setScrollBottom(false)
                }).catch(error => {
                    dispatch(axiosError(error))
                })
            }else{
                setScrollBottom(false);
            }
        }

        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)

        return () => {
            controller.abort()
            container.removeEventListener("scroll", onScrollBottom)
        }
    }, [scrollBottom])

    useEffect(() => {
        dispatch(setPageTitle('Document'))
        dispatch(setHelpProductSheetIds([18]))

        setIsLoading(true);

        let controller = new AbortController();
        dispatch(actions.fetchDocuments())
        list({offset: 0}, controller.signal).then(data => {
            dispatch(actions.fetchDocumentsSuccess(data.data))
            setIsLast(data.data.length < PAGINATION);
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => {
            dispatch(axiosError(error))
        })

        return () => {
            dispatch(setHelpProductSheetIds([]))
            controller.abort();
        }
    }, [])

    return (
        <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
            <div className="btn-group w-100 sticky-top top-0 bg-white">
                <Link to={"/document/add"} className={"btn btn-outline-primary"}>
                    <i className="bi bi-plus"> </i> <span className={'d-none d-md-inline-block'}> Ajouter</span>
                </Link>
            </div>

            <ul className={"list-group-flush px-0"}>
                {!!state.payload.length && state.payload.map((document: models.Document, index: number) => ([
                    <Link to={"/document/" + document.id} key={index} className={"list-group-item list-group-item-action" + (state.single?.id === document.id ? ' active' : '')}>
                        {document.title}
                    </Link>
                ]))}

                {isLoading ? <LoaderList title={true} listLength={!state.payload.length ? 20 : 1} description={true} leftCircle={false}/> :
                    (!state.payload.length ? <li className={"list-group-item"}>
                        Aucun document
                    </li> : '')}
                    
                {!isLoading && !isLast && state.payload.length && <button onClick={() => setScrollBottom(true)} className={'list-group-item text-primary text-center list-group-item-action'}>
                    <i className={'bi bi-arrow-repeat'}> </i> Charger plus
                </button>}
            </ul>
        </div>
    )
}


export default DocumentList;