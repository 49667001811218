import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/billing"
import Loader from "../../../../utils/loader";
import format from "../../../../utils/locale";

const BillingInvoicesShow = ({company}: {company: string}) => {

    const [payload, setPayload] = useState<{
        "pagination": {
            "limit": number,
            "total": number,
            "count": number,
            "offset": number
        },
        data: {
            id: number,
            number: string,
            due_date: string,
            pdf_link: string
        }[]
    }>({data: [], pagination: {count: 0, total: 0, offset: 0, limit: 0}});
    const [loading, setLoading] = useState(true)
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        setLoading(true)
        api.invoices(Number(company), {offset: offset})
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [company])

    if (loading){
        return <div className={"col-12 py-5 text-center"}>
            <Loader />
        </div>
    }

    return <>
        <div className="p-3">
            <div className="col-12 mb-3">
                <div className="p-3 bg-light">
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>
                                    N° de facture
                                </th>
                                <th>
                                    Date due
                                </th>
                                <th>
                                    Lien PDF
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {payload.data.map(invoice => <tr>
                                <td>
                                    {invoice.number}
                                </td>
                                <td>
                                    {format(new Date(invoice.due_date))}
                                </td>
                                <td>
                                    <a href={invoice.pdf_link} target={"_blank"}>Télécharger <i className={'bi bi-arrow-right'}></i></a>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                    {payload.pagination.total > 25 && <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            {Array.from(Array(Math.ceil(payload.pagination.total / 25)).keys()).map(i => <li className="page-item">
                                <button className="page-link" onClick={() => setOffset(i * 25)}>
                                    {i + 1}
                                </button>
                            </li>)}
                        </ul>
                    </nav>}
                </div>
            </div>
        </div>
    </>
}

export default BillingInvoicesShow;