import React, {useEffect} from "react";
import TaskEditForm from "../../../task/edit/form";
import * as calendarTypes from "../../../../constants/calendar";
import format from "../../../../utils/locale";
import {EventClickArg} from "@fullcalendar/react";

const TaskEdit:React.FC<{close: () => any, arg: EventClickArg, handleSubmitSuccess: (ev: any[]) => any}> = (props) => {

    const {handleSubmitSuccess, arg, close} = props;

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarTaskEditLabel">Modifier une tâche</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <TaskEditForm
                start={arg.event.start!}
                end={arg.event.end}
                task={{
                    taskGroup: arg.event.extendedProps.group,
                    exDate: arg.event.extendedProps.initialStart,
                    title: arg.event.title,
                    description: arg.event.extendedProps.description,
                    allDay: arg.event.allDay,
                    salaries: arg.event.extendedProps.salaries
                }}
                customFieldsValues={arg.event.extendedProps.customFieldsValues}
                id={arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_TASK ? arg.event.extendedProps.taskId : arg.event.extendedProps.exceptionId}
                handleSubmitSuccess={handleSubmit}
                isException={arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_TASK_EXCEPTION}
            />
        </div>
    </>
}

export default TaskEdit;