const getVersion = () => {
    let user = localStorage.getItem('user')

    if (user){
        let data = JSON.parse(user)

        if (!data.currentSalary){
            return false;
        }

        return data.currentSalary.companyGroup.version;
    }

    return 0;
}

export default getVersion;