import * as xhr from "../xhr";

interface listPayload {
    offset : number,
    role : string,
    query? : string,
}

export function list(requestData: listPayload, signal: AbortSignal){
    return xhr.post('user/list', requestData, {signal: signal});
}
export function show(id: number, signal?: AbortSignal){
    return xhr.get('user/show/' + id, {signal: signal});
}