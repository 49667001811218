import React, {useState} from "react";
import {BookingOffer, BookingOfferApplicant} from "../../../../../models/booking/offer";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import format from "../../../../../utils/locale";
import * as api from "../../../../../adapters/booking/offer/applicant";
import {editBookingOfferSuccess} from "../../../../../actions/booking/offer";

const BookingOfferApplicantSidebarList:React.FC = (props) => {

    const state = useSelector((state: RootStateOrAny) => state)
    const [offer, setOffer] = useState<BookingOffer>(state.rightSidebar.data.offer);
    const dispatch = useDispatch();

    const _validate = (applicant: BookingOfferApplicant) => {
        api.validate(offer.id, applicant.id).then(data => {
            setOffer(data.data);
            dispatch(editBookingOfferSuccess(data.data))
        })
    }

    const _invalidate = (applicant: BookingOfferApplicant) => {
        api.invalidate(offer.id, applicant.id).then(data => {
            setOffer(data.data);
            dispatch(editBookingOfferSuccess(data.data))
        })
    }

    return <div className={'p-1 p-md-3'}>
        <ul className={'list-group list-group-flush'}>
            {!!offer.applicants.length ? offer.applicants.map((applicant, index) => <li key={index} className={'list-group-item'}>
                <div className="d-flex align-items-center">
                    {applicant.validated && <div className={'flex-grow-0 fs-4 pe-3'}>
                        <i className={'bi bi-check2-circle text-success'}> </i>
                    </div>}
                    <div className="flex-grow-1">
                        <h4 className={'mb-1'}>
                            {applicant.salary.title} | {applicant.salary.information?.job.title}
                        </h4>
                        <div className="clearfix"> </div>
                        <span className={'text-light-s'}>
                           Participation le {format(new Date(applicant.createdAt), "E dd MMMM uuuu")} à {format(new Date(applicant.createdAt), "HH:mm")}
                        </span>
                    </div>
                    <div className="flex-grow-0">
                        {!applicant.validated ? <button onClick={() => _validate(applicant)} className={'btn btn-outline-primary'}>
                            <i className={'bi bi-check'}> </i> Valider la participation
                        </button> : <><button onClick={() => _invalidate(applicant)} className={'btn btn-outline-danger'}>
                            <i className={'bi bi-dash-circle'}> </i> Annuler
                        </button></>}
                    </div>
                </div>
            </li>) : <li className={'list-group-item'}><i className={'bi bi-search'}> </i> Aucun participant</li>}
        </ul>
    </div>
}

export default BookingOfferApplicantSidebarList;