import * as types from "../../constants/template";
import * as models from "../../models/template";

export const addTemplate = () => {
    return {
        type: types.ADD_TEMPLATE,
    }
}

export const addTemplateInformation = () => {
    return {
        type: types.ADD_TEMPLATE,
    }
}

export const addTemplateSuccess = (template: models.Template) => {
    return {
        type: types.ADD_TEMPLATE_SUCCESS,
        template: template,
    }
}

export const addTemplateInformationSuccess = (template: models.Template) => {
    return {
        type: types.ADD_TEMPLATE_INFORMATION_SUCCESS,
        template: template,
    }
}

export const addTemplateFailure = (error: string) => {
    return {
        type: types.ADD_TEMPLATE_FAILURE,
        template: null,
        templates: null,
        error: error
    }
}

export const addTemplateInformationFailure = (error: string) => {
    return {
        type: types.ADD_TEMPLATE_INFORMATION_FAILURE,
        template: null,
        templates: null,
        error: error
    }
}

export const editTemplate = () => {
    return {
        type: types.EDIT_TEMPLATE,
    }
}

export const editTemplateSuccess = (template: models.Template) => {
    return {
        type: types.EDIT_TEMPLATE_SUCCESS,
        template: template,
    }
}

export const fetchTemplates = () => {
    return {
        type: types.FETCH_TEMPLATES,
    }
}

export const fetchTemplatesFailure = (error: string) => {
    return {
        type: types.FETCH_TEMPLATES_FAILURE,
        template: null,
        templates: [],
        error: error
    }
}

export const fetchTemplatesSuccess = (templates: models.Templates) => {
    return {
        type: types.FETCH_TEMPLATES_SUCCESS,
        template: null,
        templates: templates
    }
}


export const showTemplate = () => {
    return {
        type: types.SHOW_TEMPLATE,
    }
}
export const showTemplateSuccess = (template: models.Template) => {
    return {
        type: types.SHOW_TEMPLATE_SUCCESS,
        template: template
    }
}

export const hideTemplateSuccess = (template: models.Template) => {
    return {
        type: types.HIDE_TEMPLATE_SUCCESS,
        template: template
    }
}

export const showTemplateFailure = (error: string) => {
    return {
        type: types.SHOW_TEMPLATE_FAILURE,
        template: null,
        templates: null,
        error: error
    }
}