import React, {useEffect, useState} from "react";
import {list} from "../../../adapters/job";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/job";
import '../../../utils/table/index.scss'
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_JOB} from "../../../constants/rightSidebar";
import * as models from "../../../models/job";
import {Link, useParams} from "react-router-dom";
import {setPageTitle} from "../../../actions/header";
import * as accessRights from "../../../constants/accessRight";
import {axiosError} from "../../../actions/axios";
import {PAGINATION} from "../../../constants/global";
import {Granted} from "../../../security/granted";

const JobList: React.FC = () => {

    const state = useSelector((state: RootStateOrAny)  => state.job);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [query, setQuery] = useState<string>();
    const [type, setType] = useState<number>(0);
    const [status, setStatus] = useState<number>(1);
    const [isFiltering, setFiltering] = useState<boolean>(false)
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const dispatch = useDispatch();
    const params: {id: string} = useParams();

    useEffect(() => {

        dispatch(setPageTitle('Métiers'))
        dispatch(actions.fetchJobs())

        setIsLoading(true);

        list({query: query, type: type, status: status, offset: 0}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            dispatch(actions.fetchJobsSuccess(data.data))
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => dispatch(axiosError(error)))

    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            setFiltering(true);
            setOffset(0)
            list({query: query, type: type, status: status, offset: 0}).then(data => {
                setIsLast(data.data.length < PAGINATION);
                dispatch(actions.fetchJobsSuccess(data.data))
                setFiltering(false);
                setOffset( 1);
            }).catch(error => dispatch(axiosError(error)))
        }, 1000);

        return () => clearTimeout(timer)
    }, [query, type, status])

    useEffect(() => {
        if(scrollBottom && offset > 0 && !isLast){
            setIsLoading(true)
            list({query: query, type: type, status: status, offset: offset}).then(data => {
                setIsLast(data.data.length < PAGINATION);
                dispatch(actions.fetchJobsSuccess([...state.payload, ...data.data]))
                setIsLoading(false)
                setScrollBottom(false)
                setOffset(prevState => prevState + 1)
            }).catch(error => dispatch(axiosError(error)))
        }
        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)
        return () => container.removeEventListener("scroll", onScrollBottom)
    }, [scrollBottom])

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    return <><div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
        <div className="w-100 d-flex flex-column position-sticky top-0 bg-white" style={{zIndex: 100}}>
            <div className="col">
                <div className="input-group">
                    <span className="input-group-text bg-white border-0" id="search-addon">
                        {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <i className="bi bi-search"> </i>}
                    </span>
                    <input type="search" onChange={(e) => {
                        setQuery(e.target.value)
                    }} className="form-control border-0 bg-white" placeholder="Rechercher..." aria-describedby="search-addon" />
                </div>
            </div>
            {Granted(accessRights.EDIT_JOB) && <div className="col">
                <div className="d-flex btn-group">
                    <button onClick={() => dispatch(openSidebar(FORM_NEW_JOB))} className='btn btn-light flex-grow-1'>
                        <i className={'bi bi-plus-circle'}> </i> Ajouter
                    </button>
                    <button data-bs-toggle="modal" data-bs-target="#filterModal" className='btn btn-light flex-grow-1'>
                        <i className={'bi bi-filter'}> </i> Filtrer
                    </button>
                </div>
            </div>}
        </div>
        {state.payload.length ? state.payload.map((job: models.Job, index: number) => ([
                <Link to={"/job/" + job.id} key={index} className={"list-group-item list-group-item-action" + (state.single && state.single.id === job.id ? " active" : '')}>
                    <div className="row align-items-center">
                        <div className={'col'}>
                            <div className={'h4 mb-0'}>
                                {job.title}
                            </div>
                            <div className="form-text">
                                {Number(job.type) === 1 ? "Front Office" : "Back Office"}
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="color-circle col-auto">
                                <div className="p-1 m-1 shadow" style={{backgroundColor: `${job.color}`, opacity: 0.8}}> </div>
                            </div>
                        </div>
                    </div>
                </Link>
            ]))
            :
            !isLoading ? <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
                Aucun métier {query && `pour "${query}"`}
            </button> : ''
        }
        {isLoading && <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </button>}

    </div>
        <div className="modal fade" id="filterModal" tabIndex={-1} aria-labelledby="filterModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Filtrer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 mb-3">
                            <h4>
                                Type de métier
                            </h4>
                            <div className="form-check">
                                <input defaultChecked={true} className="form-check-input" onChange={handleTypeChange} value={0} type="radio" name="type" id="typeAll" />
                                <label className="form-check-label" htmlFor="typeAll">
                                    Tous les métiers
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={handleTypeChange} value={1} type="radio" name="type" id="typeFO" />
                                <label className="form-check-label" htmlFor="typeFO">
                                    Front Office
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={handleTypeChange} value={2} type="radio" name="type" id="typeBO" />
                                <label className="form-check-label" htmlFor="typeBO">
                                    Back Office
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>
                                Statut
                            </h4>
                            <div className="form-check">
                                <input className="form-check-input" onChange={handleStatusChange} value={0} type="radio" name="status" id="statusAll" />
                                <label className="form-check-label" htmlFor="statusAll">
                                    Tous les statuts
                                </label>
                            </div>
                            <div className="form-check">
                                <input defaultChecked={true} className="form-check-input" onChange={handleStatusChange} value={1} type="radio" name="status" id="statusActivated" />
                                <label className="form-check-label" htmlFor="statusActivated">
                                    Activé
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={handleStatusChange} value={2} type="radio" name="status" id="statusDeactivated" />
                                <label className="form-check-label" htmlFor="statusDeactivated">
                                    Désactivé
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>
        </>

    function handleTypeChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        setType(Number(e.target.value))
    }

    function handleStatusChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        setStatus(Number(e.target.value))
    }
}

export default JobList;