import React, { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import SynthesisWorkingTimeTable from "./workingTime";
import SynthesisAbsenceTable from "./absence";
import SynthesisCounterTable from "./counter/test";
import SynthesisSignature from "../signature";
import SynthesisOtherTable from "./other";
import ReportPaid from "../../report/paid";
import SynthesisPaidTable from "./paid";

import * as models from "../../../models/synthesis";
import { Company } from "../../../models/companies";

import getUser from "../../../security/getUser";
import Loader from "../../../utils/loader";

const SynthesisTable:React.FC<{ company?: Company, setCompany: React.Dispatch<any>, offset: number, setOffset: React.Dispatch<any>, query: string, setQuery: React.Dispatch<any>, paginationLength: number}> = (props) => {
    const { setQuery, offset, setOffset, paginationLength } = props;

    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);
    
    const [paidMaj, setPaidMaj] = useState<"none"|"low"|"high">("none")

    return (
        <>
            <div className="col-12 flex-grow-0">
                <div className="row">
                    <div className="col-auto">
                        <div className="input-group">
                            <div className="input-group-text">
                                <i className={'bi bi-search'}></i>
                            </div>
                            <input type="search" onChange={e => setQuery(e.target.value)}  className="form-control" placeholder={'Rechercher...'}/>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="dropdown">
                            <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                <i className={'bi bi-shop text-primary'}></i> {props.company ? props.company.title : "Multi-site"}
                            </button>
                            <ul className="dropdown-menu">
                                <li onClick={() => props.setCompany(undefined)} className="dropdown-item">Multi-site</li>
                                {getUser().currentSalary.companies.map((c: Company) => <li onClick={() => props.setCompany(c)} className="dropdown-item">{c.title}</li>)}
                            </ul>
                        </div>
                    </div>

                    <div className="col">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="workingTime-tab" data-bs-toggle="tab" data-bs-target="#workingTime"
                                        type="button" role="tab" aria-controls="workingTime" aria-selected="true">
                                    <i className={'bi bi-clock'}></i> Temps de travail
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="absence-tab" data-bs-toggle="tab" data-bs-target="#absence"
                                        type="button" role="tab" aria-controls="absence" aria-selected="false">
                                    <i className={'bi bi-calendar-x'}></i> Absences
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="counter-tab" data-bs-toggle="tab" data-bs-target="#counter"
                                        type="button" role="tab" aria-controls="counter" aria-selected="false">
                                    <i className={'bi bi-stopwatch'}></i> Compteurs
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="counter-tab" data-bs-toggle="tab" data-bs-target="#paid"
                                        type="button" role="tab" aria-controls="paid" aria-selected="false">
                                    <i className={'bi bi-currency-euro'}></i> Heures payées
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={"overflow-auto flex-grow-1 position-relative"}>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="workingTime" role="tabpanel" aria-labelledby="workingTime-tab">
                        <SynthesisWorkingTimeTable />
                    </div>
                    <div className="tab-pane fade" id="absence" role="tabpanel" aria-labelledby="absence-tab">
                        <SynthesisAbsenceTable />
                    </div>
                    <div className="tab-pane fade" id="counter" role="tabpanel" aria-labelledby="counter-tab">
                        <SynthesisCounterTable company={props.company} />
                    </div>
                    <div className="tab-pane fade" id="paid" role="tabpanel" aria-labelledby="paid-tab">
                        <SynthesisPaidTable setMaj={setPaidMaj} />
                    </div>
                    <div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">
                        <SynthesisOtherTable />
                    </div>
                </div>
                {state.isLoading && <Loader />}
            </div>

            <div className="flex-grow-0 col-12 py-1">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item">
                                    <button onClick={() => setOffset((prev: number) => prev - 1)} className="page-link" disabled={offset === 0} aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </button>
                                </li>
                                {Array.from(Array(paginationLength).keys()).map(i => <li className={"page-item " + (offset === i ? "active" : "")}><button className="page-link" onClick={() => setOffset(i)}>{i + 1}</button></li>)}
                                <li className="page-item">
                                    <button onClick={() => setOffset((prev: number) => prev + 1)} className="page-link" disabled={offset === paginationLength - 1} aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            
            <SynthesisSignature />
            <ReportPaid maj={paidMaj} />
        </>
    )
}

export default SynthesisTable;