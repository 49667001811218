import * as types from "../../constants/task";
import * as models from "../../models/task";

export const addTask = () => {
    return {
        type: types.ADD_TASK,
    }
}

export const addTaskInformation = () => {
    return {
        type: types.ADD_TASK,
    }
}

export const addTaskSuccess = (task: models.Task) => {
    return {
        type: types.ADD_TASK_SUCCESS,
        task: task,
    }
}

export const addTaskFailure = (error: string) => {
    return {
        type: types.ADD_TASK_FAILURE,
        task: null,
        tasks: null,
        error: error
    }
}

export const editTask = () => {
    return {
        type: types.EDIT_TASK,
    }
}

export const editTaskSuccess = (task: models.Task) => {
    return {
        type: types.EDIT_TASK_SUCCESS,
        task: task,
    }
}

export const deleteTaskSuccess = (task: models.Task) => {
    return {
        type: types.DELETE_TASK_SUCCESS,
        task: task,
    }
}

export const editTaskStatusSuccess = (status: models.TaskStatus) => {
    return {
        type: types.EDIT_TASK_STATUS_SUCCESS,
        status: status,
    }
}

export const fetchTasks = () => {
    return {
        type: types.FETCH_TASKS,
    }
}

export const fetchTasksFailure = (error: string) => {
    return {
        type: types.FETCH_TASKS_FAILURE,
        task: null,
        tasks: [],
        error: error
    }
}

export const fetchTasksSuccess = (tasks: models.Tasks) => {
    return {
        type: types.FETCH_TASKS_SUCCESS,
        task: null,
        tasks: tasks
    }
}


export const showTask = () => {
    return {
        type: types.SHOW_TASK,
    }
}
export const showTaskSuccess = (task: models.Task) => {
    return {
        type: types.SHOW_TASK_SUCCESS,
        task: task
    }
}
export const showTaskFailure = (error: string) => {
    return {
        type: types.SHOW_TASK_FAILURE,
        task: null,
        tasks: null,
        error: error
    }
}