import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import * as models from "../../../models/channel";

import { FORM_NEW_CHANNEL } from "../../../constants/rightSidebar";

import * as api from "../../../adapters/channel"
import * as actions from "../../../actions/channel"
import { openSidebar } from "../../../actions/rightSidebar";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { axiosError } from "../../../actions/axios";

import format from "../../../utils/locale";

export const ChannelList: React.FC = () => {
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state.channel)

    useEffect(() => {
        dispatch(setPageTitle('Messagerie'))
        dispatch(setHelpProductSheetIds([17]))
        
        dispatch(actions.fetchChannels());
        api.list()
            .then(data => dispatch(actions.fetchChannelsSuccess(data.data)))
            .catch(error => dispatch(axiosError(error)))
        
        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [])

    return (
        <div id={'listContainer'} className={"col col-md-3 flex-grow-1 px-0 overflow-auto sticky-top" + (params.id ? " d-none d-md-block" : "")}>
            <div className="w-100 border-end border-2 h-100 bg-white">
                <div className="col-12">
                    <div className="input-group">
                    <span className="input-group-text border-0 bg-white">
                        <i className={"bi bi-search"}> </i>
                    </span>
                        <input type="text" placeholder={'Rechercher...'} className={"form-control border-0"}/>
                    </div>
                </div>

                <div className="col-12">
                    <button onClick={() => dispatch(openSidebar(FORM_NEW_CHANNEL))}  className='btn btn-outline-primary w-100'>
                        <i className={'bi bi-plus-circle'}> </i> Ajouter
                    </button>
                </div>

                {state.payload.length ? state.payload.map((channel: models.Channel) => ([
                        <Link to={{ pathname:'/channel/' + channel.id}} className="px-3 list-group-item list-group-item-action text-break">
                            {channel.title}
                            <span className="clearfix"> </span>
                            {channel.lastMessage &&
                            <small className={'d-block text-truncate'}>
                                {format(new Date(channel.lastMessage.createdAt), 'dd/MM HH:mm')} | {channel.lastMessage.body}
                            </small>}
                        </Link>
                    ]))
                    :
                    <button key={0} type="button" className="list-group-item list-group-item-action" aria-current="true" disabled>
                        Aucun résultat
                    </button>
                }
            </div>
        </div>
    )
}

export default ChannelList;