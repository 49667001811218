import {get} from "../xhr";

export function activate(id: number){
    return get(`notification/${id}/activate`)
}

export function deactivate(id: number){
    return get(`notification/${id}/deactivate`)
}

export function setEmail(id: number){
    return get(`notification/${id}/set_email`)
}

export function unsetEmail(id: number){
    return get(`notification/${id}/unset_email`)
}

export function setPushNotification(id: number){
    return get(`notification/${id}/set_push_notification`)
}

export function unsetPushNotification(id: number){
    return get(`notification/${id}/unset_push_notification`)
}

export function setSmsNotification(id: number){
    return get(`notification/${id}/set_sms_notification`)
}

export function unsetSmsNotification(id: number){
    return get(`notification/${id}/unset_sms_notification`)
}

export function setManager(id: number){
    return get(`notification/${id}/set_manager`)
}

export function unsetManager(id: number){
    return get(`notification/${id}/unset_manager`)
}

export function list(){
    return get(`notification/list`)
}

export function addPopulation(id: number, accessLevelId: number){
    return get(`notification/${id}/add_population/${accessLevelId}`)
}

export function removePopulation(id: number, accessLevelId: number){
    return get(`notification/${id}/remove_population/${accessLevelId}`)
}