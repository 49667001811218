import {post} from "../xhr";
import * as xhr from "../xhr";

interface FetchData {
    start: string,
    end: string,
    options?: {
        view?: string,
        explodeRecurrence?: boolean,
        isSingle?: boolean,
        resources?: boolean,
        workingTime?: boolean,
        isGenerated?: boolean,
        companies?: number[],
        sortBy?: number,
        jobs?: number[],
        job?: number,
        presence?: boolean,
        salaries?: number[],
        teams?: number[],
        isBookingOffer?: boolean,
    }
}

interface FetchCalendarData {
    start: string,
    end: string,
    calendar: string,
    options?: {
        view?: string,
        timePrecision?: string,
        explodeRecurrence?: boolean,
        isSingle?: boolean,
        resources?: boolean,
        workingTime?: boolean,
        isGenerated?: boolean,
        companies?: number[],
        sortBy?: number,
        jobs?: number[],
        job?: number,
        presence?: boolean,
        salaries?: number[],
        salary?: number,
        year?: number,
        month?: number,
        teams?: number[],
        outerEvents?: boolean,
        isBookingOffer?: boolean,
        isNightTime?: boolean,
        disableDemarcation?: boolean
    }
}

export function fetchCalendar(requestData: FetchData, signal?: AbortSignal){
    return post('calendar/fetch', requestData, {signal: signal});
}

export function test(requestData: FetchCalendarData, signal?: AbortSignal){
    return post(`calendar/test`, requestData, {signal: signal});
}

export function downloadTest(requestData: any, options?:xhr.Options){
    return post(`calendar/download_test`, requestData, options);
}

export function publish(requestData: any){
    return post(`calendar/publish`, requestData);
}

export function fetch(requestData: FetchData, signal?: AbortSignal){
    return post('calendar/events', requestData, {signal: signal});
}

interface downloadPayload {
    type: number,
    year: number,
    week?: number,
    month?: number,
    company: number,
    format: number,
    job: number|string,
    jobs?: number[]
}
export function download(requestData: downloadPayload, options?:xhr.Options){
    return post('calendar/download', requestData, options);
}

export function setting(requestData: { setting: string, value: any }){
    return post('calendar/setting', requestData);
}

export function send(requestData: {start: string, end: string}, salaryId: number){
    return post(`calendar/send/${salaryId}`, requestData);
}