import React, {forwardRef, useState} from "react";
import {ResourceLabelContentArg} from "../../../models/calendar";
import * as accessRights from "../../../constants/accessRight";
import {Link} from "react-router-dom";
import {EventContentArg} from "@fullcalendar/react";
import format from "../../../utils/locale";
import {ResourceApi} from "@fullcalendar/resource-common";
import {PresenceEvent} from "../../../models/calendar";
import {TYPE_EVENT_EXCEPTION, TYPE_EVENT_RECURRING} from "../../../constants/event";
import DatePicker from "react-datepicker";
import * as calendarTypes from "../../../constants/calendar";
import * as taskTypes from "../../../constants/task";
import onMouseEnterTooltip from "../../../utils/mouseOverTooltip";
import {RESOURCE_HIERARCHY_PARENT} from "../../../constants/calendar";
import SmallLoader from "../../../utils/loader/small";
import {TemplateResourceSidebarInterface} from "../../template/resource/sidebar";
import {Granted, GrantedAny} from "../../../security/granted";
import getVersion from "../../../security/getVersion";
import getMaxVisibilityDate from "../../../security/getMaxDate";
import duration from "../../../utils/duration";
import {setHours} from "date-fns";
import {Syntheses} from "../../../models/synthesis";
import timeFormat from "../../../utils/timeFormat";

export const renderResourceGroup = (arg: any) => {

    return '';

    let array = arg.groupValue.split(':')

    if (arg.groupValue === 'Notes')
    {
        return <b className="text-primary text-uppercase">Notes</b>
    }

    if (arg.groupValue.substring(0, 11) === 'taskGroupId'){
        return <b className="text-primary text-uppercase">
            {arg.groupValue.substring(arg.groupValue.indexOf("title:") + 6, arg.groupValue.length)}
        </b>
    }

    return <b className="text-primary text-uppercase">Sem.{array[array.length - 1]} {array[array.length - 3]} </b>
}

// export const RenderTimeSheet:React.FC<{timeSheets: any, arg: ResourceLabelContentArg}> = (props) => {
export const RenderTimeSheet:React.FC<{timeSheets: Syntheses, arg: ResourceLabelContentArg}> = (props) => {
    const {timeSheets, arg} = props;

    // let timeSheet = timeSheets.find((t: any) => t.type === arg.resource.extendedProps.resourceType && t.salary.id === arg.resource.extendedProps.salary.id);
    let timeSheet = timeSheets.find((t: any) => t.id === arg.resource.extendedProps.salary.id);

    if (!timeSheet) return <>Not found</>

    return <>
        <span className={'me-2'}>
            {/*<i className={'bi bi-clock'}> </i> {timeSheet.accountedWorkingTime}*/}
            {/*{arg.view.type === 'resourceTimelineWeek' && <span className={'ms-2'}>*/}
            {/*    {timeSheet.periodCounterDuration === 0 && <i className={'bi bi-check text-success'}></i>}*/}
            {/*    {timeSheet.periodCounterDuration !== 0 && <>*/}
            {/*        {timeSheet.periodCounterDuration > 0 ? <i className={'bi bi-plus-circle text-success'}> </i> : <i className={'bi bi-exclamation-circle text-danger'}> </i>} {timeSheet.periodCounter}*/}
            {/*    </>}*/}
            {/*</span>}*/}
            <i className={'bi bi-clock'}> </i> {timeFormat(timeSheet.accountedWorkingTime)}
            {arg.view.type === 'resourceTimelineWeek' && timeSheet.counter !== undefined && <span className={'ms-2'}>
                {timeSheet.counter === 0 && <i className={'bi bi-check-circle text-success'}></i>}
                {timeSheet.counter !== 0 && <>
                    {timeSheet.counter > 0 ? <i className={'bi bi-plus-circle text-success'}> </i> : <i className={'bi bi-exclamation-circle text-danger'}> </i>} {timeFormat(timeSheet.counter)}
                </>}
            </span>}
        </span>
    </>
}

export const RenderTemplateResource:React.FC<{arg : ResourceLabelContentArg, sortBy: number, timeSheets: any[], removeResource: (resource: {id: number}) => any, setTemplateResourceSidebarProps: React.Dispatch<any>}> = (props) => {

    const {arg, timeSheets, sortBy, removeResource, setTemplateResourceSidebarProps} = props;

    const [confirm, setConfirm] = useState(false);
    const [removing, setRemoving] = useState(false);

    switch (arg.resource.extendedProps.hierarchy)
    {
        case calendarTypes.RESOURCE_HIERARCHY_PARENT:
            return <span className={'align-items-center col'}>
                            <span className={'col'}>
                                <b style={{backgroundColor: arg.resource.extendedProps.backgroundColor}} className={'p-1'}>{arg.resource.title}</b>
                            </span>
                            <span className={'col-auto'}>
                                 <div className="color-circle col-auto">
                                        <div className="text-center text-white shadow" style={{backgroundColor: `${arg.resource.extendedProps.color}`, opacity: 0.8}}>
                                            {arg.resource.extendedProps.icon && <i className={arg.resource.extendedProps.icon + " align-middle"}> </i>}
                                        </div>
                                 </div>
                            </span>
                    </span>
        case calendarTypes.RESOURCE_HIERARCHY_CHILD:
            let timeSheet = timeSheets.find(t => Number(t.resource.id) === Number(arg.resource.id));

            return <span>
                <span>
                    <span className="flex-grow-1">
                        <span className={'text-capitalize'}>{arg.resource.title}</span>
                        {timeSheet && <>
                            <span className="clearfix"></span>
                            <span className={'me-2'}>
                            <i className={'bi bi-clock'}> </i> {timeFormat(timeSheet.accountedWorkingTime)}
                                {arg.view.type === 'resourceTimelineWeek' && <span className={'ms-2'}>
                                {timeSheet.counter === 0 && <i className={'bi bi-check text-success'}></i>}
                                    {timeSheet.counter !== 0 && <>
                                        {timeSheet.counter > 0 ? <i className={'bi bi-plus-circle text-success'}> </i> : <i className={'bi bi-exclamation-circle text-danger'}> </i>} {timeFormat(timeSheet.counter)}
                                    </>}
                            </span>}
                        </span>
                        </>}
                        {/*{timeSheet && <>*/}
                        {/* <span className="clearfix"></span>*/}
                        {/* <span className={'me-2'}>*/}
                        {/*    <i className={'bi bi-clock'}> </i> {timeSheet.accountedWorkingTime}*/}
                        {/*     {arg.view.type === 'resourceTimelineWeek' && <span className={'ms-2'}>*/}
                        {/*        {timeSheet.counter === 0 && <i className={'bi bi-check text-success'}></i>}*/}
                        {/*         {timeSheet.counter !== 0 && <>*/}
                        {/*             {timeSheet.counter > 0 ? <i className={'bi bi-plus-circle text-success'}> </i> : <i className={'bi bi-exclamation-circle text-danger'}> </i>} {timeFormat(timeSheet.counter)}*/}
                        {/*         </>}*/}
                        {/*    </span>}*/}
                        {/*</span>*/}
                        {/*</>}*/}
                    </span>
                    <span className="ms-auto flex-grow-0">
                        <span className="btn-group">
                            {!confirm && <button className="btn btn-sm btn-light me-1"
                                onClick={() => {
                                    if(arg.resource.extendedProps.floating){
                                        setTemplateResourceSidebarProps((prev: TemplateResourceSidebarInterface) => ({
                                            sidebarType: 'RESOURCE_EDIT',
                                            trigger: prev.trigger + 1,
                                            floating: arg.resource.extendedProps.floating,
                                            job: arg.resource.extendedProps.job,
                                            title: arg.resource.title,
                                            contractWorkingTime: arg.resource.extendedProps.contractWorkingTime,
                                            resource: arg.resource
                                        }))
                                    }else{
                                        setTemplateResourceSidebarProps((prev: TemplateResourceSidebarInterface) => ({
                                            sidebarType: 'RESOURCE_EDIT',
                                            trigger: prev.trigger + 1,
                                            floating: arg.resource.extendedProps.floating,
                                            salary: arg.resource.extendedProps.salary,
                                            resource: arg.resource
                                        }))
                                    }
                                }}
                            >
                                <i className={"bi bi-pencil text-primary"}></i>
                            </button>}
                            {confirm && <button onClick={() => setConfirm(false)} className="btn btn-sm btn-light me-1">
                                Annuler
                            </button>}
                            <button className="btn btn-sm btn-light" onClick={() => {
                              if (confirm){
                                  setRemoving(true)
                                  removeResource({id: Number(arg.resource.id)})
                              }else {
                                  setConfirm(true)
                              }
                            }}>
                                 {!removing ? <><i className={"bi bi-dash-circle text-danger"}></i> {confirm && <>Supprimer</>}</> : <SmallLoader />}
                            </button>
                        </span>
                    </span>
                </span>
            </span>
    }

    return <>{arg.resource.title}</>
}

// export const RenderResource:React.FC<{ arg: ResourceLabelContentArg, sortBy: number, timeSheets?: any, setCalendarEventSidebarProps?: React.Dispatch<any>, setCalendarSignedSidebarProps?: React.Dispatch<any>, setCalendarAbsenceSidebarProps?: React.Dispatch<any>, setCalendarTimeTableProps?: React.Dispatch<any>}> = (props) => {
export const RenderResource:React.FC<{ arg: ResourceLabelContentArg, sortBy: number, timeSheets?: Syntheses, setCalendarEventSidebarProps: React.Dispatch<any>, setCalendarSignedSidebarProps: React.Dispatch<any>, setCalendarAbsenceSidebarProps: React.Dispatch<any>, setCalendarTimeTableProps: React.Dispatch<any>}> = (props) => {

    const {arg, sortBy, timeSheets, setCalendarAbsenceSidebarProps, setCalendarSignedSidebarProps, setCalendarEventSidebarProps, setCalendarTimeTableProps} = props;

    if (arg.resource.extendedProps.hierarchy === calendarTypes.RESOURCE_HIERARCHY_GROUP){
        return <span>
            <span className={'text-primary resource-group-title'}>
                <b><i className={'bi bi-shop'}></i> {arg.resource.title}</b>
            </span>
            {arg.resource.extendedProps.underTitle && <>
                <div className="clearfix"></div>
                <span className="form-text">
                    {arg.resource.extendedProps.underTitle}
                </span>
            </>}
        </span>
    }

    switch (arg.resource.extendedProps.resourceType){
        case calendarTypes.RESOURCE_TYPE_EVENT:
        case calendarTypes.RESOURCE_TYPE_SIGNED:
            switch (arg.resource.extendedProps.hierarchy){
                case calendarTypes.RESOURCE_HIERARCHY_GRAND_CHILD:
                    return <span className={'form-text'}>
                        {arg.resource.title}
                    </span>
                case calendarTypes.RESOURCE_HIERARCHY_CHILD:
                    if (arg.resource.extendedProps.grouped) return <></>
                    return <span className={'d-inline-block w-100'}>
                        <span className={'d-flex align-items-center'}>
                            <span className="flex-grow-1">
                                <b className={'text-primary resource-title'}>
                                    {Granted(accessRights.LIST_SALARY, arg.resource.extendedProps.salary.id) ? <Link to={"/salary/" + arg.resource.extendedProps.salary.id}>{arg.resource.title}</Link> : <>{arg.resource.title}</>}
                                </b>
                                <span className="clearfix"> </span>
                                {Granted(accessRights.LIST_REPORTING, arg.resource.extendedProps.salary.id) && timeSheets && <span>
                                    <RenderTimeSheet timeSheets={timeSheets} arg={arg} />
                                </span>}
                            </span>
                            {GrantedAny([accessRights.EDIT_BOOKING, accessRights.EDIT_TIME_CLOCK, accessRights.EDIT_ABSENCE], arg.resource.extendedProps.salary?.id) && <span className="flex-grow-0">
                                <span className="dropdown">
                                    <button type="button" className="btn btn-sm dropdown-toggle dropdown-toggle-no-after-content" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className={'bi bi-three-dots-vertical'}> </i>
                                    </button>
                                    <ul className="dropdown-menu position-fixed">
                                        {setCalendarSignedSidebarProps !== undefined && arg.resource.extendedProps.resourceType === 'TYPE_SIGNED' && Granted(accessRights.EDIT_TIME_CLOCK, arg.resource.extendedProps.salary.id) &&
                                            <li className={'dropdown-item'}
                                                onClick={() => setCalendarSignedSidebarProps((prev: any) => ({
                                                    sidebarType: "SIGNED_ADD",
                                                    trigger: prev.trigger + 1,
                                                    salary: arg.resource.extendedProps.salary,
                                                    company: arg.resource.extendedProps.company
                                                }))}
                                            >
                                            Ajouter un badgeage
                                        </li>}
                                        {setCalendarEventSidebarProps !== undefined && Granted(accessRights.EDIT_BOOKING, arg.resource.extendedProps.salary?.id) && getVersion() === 1 && <li className={'dropdown-item'}
                                             onClick={() => setCalendarEventSidebarProps((prev: any) => ({
                                                 sidebarType: "EVENT_ADD",
                                                 trigger: prev.trigger + 1,
                                                 dt: setHours(arg.view.calendar.getDate(), (new Date()).getHours()),
                                                 salary: arg.resource.extendedProps.salary,
                                                 company: arg.resource.extendedProps.company,
                                                 localisation: arg.resource.extendedProps.localisation,
                                                 activity: arg.resource.extendedProps.activity
                                             }))}
                                        >
                                            Planifier un créneau
                                        </li>}
                                        {setCalendarTimeTableProps  !== undefined && Granted(accessRights.EDIT_BOOKING, arg.resource.extendedProps.salary.id) && getVersion() === 1 && <li className={'dropdown-item'}
                                             onClick={() => setCalendarTimeTableProps((prev: any) => ({
                                                 salary: arg.resource.extendedProps.salary,
                                                 date: arg.view.calendar.getDate(),
                                             }))}
                                        >
                                            Modifier la structure
                                        </li>}
                                        {setCalendarAbsenceSidebarProps  !== undefined && Granted(accessRights.EDIT_ABSENCE, arg.resource.extendedProps.salary.id) && <li className={'dropdown-item'}
                                             onClick={() => setCalendarAbsenceSidebarProps((prev: any) => ({
                                                 sidebarType: "ABSENCE_ADD",
                                                 trigger: prev.trigger + 1,
                                                 salary: arg.resource.extendedProps.salary,
                                             }))}
                                        >
                                            Ajouter une absence
                                        </li>}
                                    </ul>
                                </span>
                            </span>}
                        </span>
                    </span>
                case calendarTypes.RESOURCE_HIERARCHY_PARENT:
                    return <span className={'px-2 align-items-center col'}>
                            <span className={'col'}>
                                <b style={{backgroundColor: arg.resource.extendedProps.backgroundColor}} className={'p-1'}>{arg.resource.title}</b>
                            </span>
                            <span className={'col-auto'}>
                                 <div className="color-circle col-auto">
                                        <div className="text-center text-white shadow" style={{backgroundColor: `${arg.resource.extendedProps.color}`, opacity: 0.8}}>
                                            {arg.resource.extendedProps.icon && <i className={arg.resource.extendedProps.icon + " align-middle"}> </i>}
                                        </div>
                                 </div>
                            </span>
                    </span>
                default:
                    return <>{arg.resource.title}</>
            }
        case calendarTypes.RESOURCE_TYPE_KEEP_NOTE:
            return <></>
        case calendarTypes.RESOURCE_TYPE_TASK:
            switch (sortBy){
                case 1:
                    switch (arg.resource.extendedProps.hierarchy){
                        case 'child':
                            return <span className={'d-inline-block w-100'}>
                                <div className={'d-flex px-2 align-items-center'}>
                                    <span className="flex-grow-1">
                                        <b className={'text-primary'}>
                                            {Granted(accessRights.LIST_SALARY, arg.resource.extendedProps.salary.id) ? <Link to={"/salary/" + arg.resource.extendedProps.salary.id}>{arg.resource.title}</Link> : <>{arg.resource.title}</>}
                                        </b>
                                    </span>
                                </div>
                            </span>
                        case 'parent':
                            return <span className={'align-items-center col'}>
                                <span className={'col'}>
                                    <b style={{backgroundColor: arg.resource.extendedProps.backgroundColor}} className={'p-1'}>{arg.resource.title}</b>
                                </span>
                                <span className={'col-auto'}>
                                     <div className="color-circle col-auto">
                                            <div className="text-center text-white shadow" style={{backgroundColor: `${arg.resource.extendedProps.color}`, opacity: 0.8}}>
                                                {arg.resource.extendedProps.icon && <i className={arg.resource.extendedProps.icon + " align-middle"}> </i>}
                                            </div>
                                     </div>
                                </span>
                            </span>
                    }
                    return <>{arg.resource.title}</>
                case 2:
                    switch (arg.resource.extendedProps.hierarchy){
                        case 'grandChild':
                            return  <span> <i className={'bi bi-bookmark'}></i> {arg.resource.title}</span>
                        case 'child':
                            return  <span>{arg.resource.title}</span>
                        case 'parent':
                            return  <span className={'h4'}> <i className={'bi bi-bookmark text-primary'}></i> {arg.resource.title}</span>
                    }
            }
    }

    return <>{arg.resource.title}</>
}

export function renderEvent(arg: EventContentArg, displayEventTime?: boolean) {        
    switch (arg.event.extendedProps.eventType) {
        case calendarTypes.EVENT_TYPE_TIME_CLOCK:
        case calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING:
        case calendarTypes.EVENT_TYPE_TIME_CLOCK_EXCEPTION:
            return <div>
                <b className={'time-caption'}>
                    {arg.event.extendedProps.files?.length > 0 && <i className={'bi bi-camera'}></i>}
                    {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                    {[calendarTypes.EVENT_TYPE_TIME_CLOCK, calendarTypes.EVENT_TYPE_TIME_CLOCK_EXCEPTION].includes(arg.event.extendedProps.eventType) && (arg.event.extendedProps._start || arg.event.extendedProps._end) && <i className="bi bi-chevron-bar-contract me-2"></i>}
                    {arg.event.title}
                </b>
                <div className="clearfix"></div>
                <span>
                    {arg.event.extendedProps.isAnomaly && <i className={'bi bi-x-circle'}> </i>} {arg.timeText} | {duration(arg.event.start, arg.event.end)}
                </span>
                <div className="clearfix"> </div>
                {['timeGridWeek', 'timeGridDay'].includes(arg.view.type) && arg.event.extendedProps.description && <span>
                   <i className={'bi bi-info-circle'}> </i> {arg.event.extendedProps.description}
                </span>}
            </div>
        case calendarTypes.EVENT_TYPE_PRESENCE:
            let className = '';
            switch (arg.event.extendedProps.presenceType){
                case calendarTypes.EVENT_TYPE_PRESENCE_GLOBAL:
                    let counter = Number(arg.event.extendedProps.values.reduce((accumulator: number, object: PresenceEvent) => {
                        return accumulator + object.value;
                    }, 0));

                    let need = Number(arg.event.extendedProps.need || 0);

                    className = (need && need > counter ? ' badge text-danger ' : (need && need < counter ? ' badge text-primary ' : ' badge text-success '));

                    return <div className={'text-center w-100 btn-light ' + className}>
                                <b className={'py-2'}>
                                    {counter}{!!need && <>/{need}</>}
                                </b>
                            </div>
                case calendarTypes.EVENT_TYPE_PRESENCE_BY_JOB:
                    let v: any[] = arg.event.extendedProps.values
                    let n: any[] = arg.event.extendedProps.need

                    let array = v.filter(_v => _v.id).map(_v => {
                        let val = Number(_v.values.reduce((accumulator: number, object: PresenceEvent) => {
                            return accumulator + object.value;
                        }, 0))

                        let need = (n ? (n[_v.id] || 0) : 0)

                        if(val < need) return 0;
                        if(val > need) return 2;

                        return 1;
                    })

                    className = array.includes(0) ? 'text-danger' : (array.includes(2) ? 'text-primary' : 'text-success')
                    let icon = array.includes(0) ? 'bi bi-x-lg text-danger' : (array.includes(2) ? 'bi bi-plus-lg text-primary' : 'bi bi-check-lg text-success')

                    return <div
                        data-bs-placement="top"
                        title={v.filter(_v => _v.id).map(_v => {

                            let _vv = Number(_v.values.reduce((accumulator: number, object: PresenceEvent) => {
                                return accumulator + object.value;
                            }, 0));

                            let _vn = (n ? (n[_v.id] || 0) : 0);

                            return _v.title + " <span class='"+ (_vv < _vn ? 'text-danger' : (_vv > _vn ? 'text-primary' : 'text-success')) +"'>" + _vv + "/" + (n ? (n[_v.id] || 0) : 0) + "</span>"
                        }).join('<br>')}
                        onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                        className={'text-center w-100 badge btn-light ' + className}>
                                <b className={'py-2'}>
                                    <i className={icon}></i>
                                </b>
                            </div>
            }
            break;
        case calendarTypes.EVENT_TYPE_EVENT:
        case calendarTypes.EVENT_TYPE_EVENT_EXCEPTION:            
            switch (arg.view.type){                
                case calendarTypes.RESOURCE_TIMELINE_DAY_VIEW:
                    return <span className={'h-100 w-100'} id={arg.event.id}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0 shift-title"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitution && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) && arg.event.extendedProps.absence.typeShortName} </b></span>}
                                    {arg.event.extendedProps.comment && <span><i className={'bi bi-quote'}></i></span>}
                                </div>
                            </div>
                            <div className="clearfix"> </div>
                        {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                        {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_EVENT && (arg.event.extendedProps.type === TYPE_EVENT_RECURRING ? <i className={'bi bi-arrow-repeat me-1'}> </i> : <i className="bi bi-plus-circle-fill me-1"></i>)}
                            <b className={'time-caption'}>{arg.timeText} | {duration(arg.event.start, arg.event.end)}</b>
                            {arg.event.extendedProps.localisation && <span className={'float-end rounded-pill shadow-sm px-1 mx-1'}
                                                                           style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i> <i>{arg.event.extendedProps.localisation.title}</i>
                                </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end shadow-sm px-1 mx-1'}
                                                                       style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i> <i>{arg.event.extendedProps.activity.title}</i>
                                </span>}
                        </span>
                case calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW:
                    return (
                        <span className={'h-100 w-100'} id={arg.event.id}>
                            <div className="d-flex">
                                <div className={'col d-flex'}>
                                    <div className="d-flex flex-grow-0">
                                        {arg.event.extendedProps.localisation && <div className={'rounded-circle text-center me-2'}
                                                                                      style={{backgroundColor: arg.event.extendedProps.localisation?.color, width: 15, height: 15}}>
                                            <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                        </div>}
                                        {arg.event.extendedProps.activity && <div className={'rounded-circle text-center me-2'}
                                                                                  style={{backgroundColor: arg.event.extendedProps.activity?.color, width: 15, height: 15}}>
                                            <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                        </div>}
                                    </div>
                                    <div className="flex-grow-1">
                                        <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitution && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) && arg.event.extendedProps.absence.typeShortName} </b></span>}
                                    {arg.event.extendedProps.comment && <span><i className={'bi bi-quote'}></i></span>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {arg.event.extendedProps.eventType === TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_EVENT && (arg.event.extendedProps.type === TYPE_EVENT_RECURRING ? <i className={'bi bi-arrow-repeat me-1'}> </i> : <i className="bi bi-plus-circle-fill me-1"></i>)}
                                    <b className={'time-caption'}>{arg.timeText} | {duration(arg.event.start, arg.event.end)}</b>
                                </div>
                            </div>
                        </span>
                    )
                case calendarTypes.RESOURCE_TIMELINE_VIEW:
                case calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW:
                    return <b className={'text-center'}>{arg.event.extendedProps.absence ? <><i className={'bi bi-x'}></i> {Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) && arg.event.extendedProps.absence.typeShortName}</> : (displayEventTime ? arg.timeText : duration(arg.event.start, arg.event.end))}</b>
                case calendarTypes.TIME_GRID_DAY_VIEW:
                case calendarTypes.TIME_GRID_WEEK_VIEW:
                    return <>
                        <b>
                            {arg.event.extendedProps.eventType === TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_EVENT && (arg.event.extendedProps.type === TYPE_EVENT_RECURRING ? <i className={'bi bi-arrow-repeat me-1'}> </i> : <i className="bi bi-plus-circle-fill me-1"></i>)}
                            {arg.event.extendedProps.exchange && <i className={'bi bi-shuffle'}> </i>}
                            {arg.event.extendedProps.substitution && <i className={'bi bi-arrow-down-up'}> </i>}
                            {arg.timeText}
                            <span className="clearfix"> </span>
                            {(arg.event.end && arg.event.start) ? format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm') : arg.event.extendedProps.exceptionId}
                        </b>
                        {arg.event.extendedProps.exchanged && <>{<span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>}</>}
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.absence && <>
                            <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.comment && <>
                            <i className={'bi bi-quote'}></i> {arg.event.extendedProps.comment}
                            <div className="clearfix"></div>
                        </>}
                    </>
                case calendarTypes.DAY_GRID_MONTH_VIEW:
                    return <div className={'w-100'} style={{backgroundColor: arg.event.backgroundColor, color: arg.event.textColor}}>
                        <b>
                            {arg.event.extendedProps.eventType === TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_EVENT && (arg.event.extendedProps.type === TYPE_EVENT_RECURRING ? <i className={'bi bi-arrow-repeat me-1'}> </i> : <i className="bi bi-plus-circle-fill me-1"></i>)}
                            {arg.event.extendedProps.exchange && <i className={'bi bi-shuffle'}> </i>}
                            {arg.event.extendedProps.substitution && <i className={'bi bi-arrow-down-up'}> </i>}
                            {arg.timeText}
                        </b>
                        {arg.event.extendedProps.exchanged && <>{<span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>}</>}
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.absence && <>
                            <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.comment && <>
                            <i className={'bi bi-quote'}></i> {arg.event.extendedProps.comment}
                            <div className="clearfix"></div>
                        </>}
                    </div>
                case 'listDay':
                case 'listWeek':
                    return <>
                        <div className={'text-break'}>
                            {arg.event.extendedProps.comment && <span><i className={'bi bi-quote'}></i></span>}
                            {arg.event.extendedProps.substitution && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                            {arg.event.extendedProps.exchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                            <span>
                                {arg.event.extendedProps.salary.title}
                            </span>
                            {arg.event.extendedProps.absence && <>
                                <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            </>}
                            {arg.event.extendedProps.localisation && <span className={'float-end'}>
                                <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end'}>
                                <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            </span>}
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                <i className={'bi bi-shop'}></i> {arg.event.extendedProps?.company?.title}
                            </div>
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                {arg.event.extendedProps.salary.information.job.title}
                            </div>
                        </div>
                    </>
            }
            break;
        case calendarTypes.EVENT_TYPE_BOOKING:
        case calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION:
        case calendarTypes.EVENT_TYPE_BOOKING_OUTER:
            switch (arg.view.type){
                case calendarTypes.RESOURCE_TIMELINE_DAY_VIEW:
                    return (
                        <span className={'h-100 w-100'} id={arg.event.extendedProps.instanceToken}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitute && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) && arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                            </div>
                            <div className="clearfix"> </div>
                            {arg.event.extendedProps.eventType === 'booking' && arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.eventType === 'booking' && arg.event.extendedProps.recurrence.type !== 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                            <b className={'time-caption'}>{arg.timeText} - {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}</b>
                            {arg.event.extendedProps.localisation && <span className={'float-end rounded-pill shadow-sm px-1 mx-1'}
                                                                           style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i> <i>{arg.event.extendedProps.localisation.title}</i>
                                </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end shadow-sm px-1 mx-1'}
                                                                       style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i> <i>{arg.event.extendedProps.activity.title}</i>
                                </span>}
                        </span>
                    )
                case calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW:
                    return (
                        <span className={'h-100 w-100'} id={arg.event.extendedProps.instanceToken}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitute && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) && arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {arg.event.extendedProps.eventType === 'bookingException' && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.eventType === 'booking' && arg.event.extendedProps.recurrence.type !== 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                                    <b className={'time-caption'}>{arg.timeText}</b>
                                </div>
                                {arg.event.extendedProps.localisation && <div className={'col-auto rounded-pill shadow-sm px-1 mx-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                </div>}
                                {arg.event.extendedProps.activity && <div className={'col-auto shadow-sm px-1 mx-1'}
                                                                          style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                </div>}
                            </div>
                        </span>
                    )
                case calendarTypes.RESOURCE_TIMELINE_VIEW:
                case calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW:
                    return (
                        <>
                            <b className={'w-100 text-center'}>{displayEventTime ? arg.timeText : duration(arg.event.start, arg.event.end)}</b>
                        </>
                    )
                case calendarTypes.DAY_GRID_MONTH_VIEW:
                    return <div className={'w-100'} style={{backgroundColor: arg.event.backgroundColor, color: arg.event.textColor}}>
                        <b>
                            {arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.isSlotExchange && <i className={'bi bi-shuffle'}> </i>}
                            {arg.event.extendedProps.substitute && <i className={'bi bi-arrow-down-up'}> </i>}
                            {!arg.event.extendedProps.isException && arg.event.extendedProps.recurrence?.type > 1 && <i className={'bi bi-arrow-repeat'}> </i>}
                            {arg.timeText}
                        </b>
                        {arg.event.extendedProps.exchanged && <>{<span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>}</>}
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.absence && <>
                            <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.comment && <>
                            <i className={'bi bi-quote'}></i> {arg.event.extendedProps.comment}
                            <div className="clearfix"></div>
                        </>}
                    </div>
                case calendarTypes.TIME_GRID_DAY_VIEW:
                case calendarTypes.TIME_GRID_WEEK_VIEW:
                    return <>
                        <b>
                            {arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.isSlotExchange && <i className={'bi bi-shuffle'}> </i>}
                            {arg.event.extendedProps.substitute && <i className={'bi bi-arrow-down-up'}> </i>}
                            {!arg.event.extendedProps.isException && arg.event.extendedProps.recurrence?.type > 1 && <i className={'bi bi-arrow-repeat'}> </i>}
                            {arg.timeText}
                            <span className="clearfix"> </span>
                            {arg.event.start && arg.event.end && <>{format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}</>}
                        </b>
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.absence && <>
                            <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            <div className="clearfix"> </div>
                        </>}
                    </>
                case 'listDay':
                case 'listWeek':
                    return <>
                        <div className={'text-break'}>
                            {arg.event.extendedProps.substitute && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                            {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                            <span>
                                {arg.event.extendedProps.salary.title}
                            </span>
                            {arg.event.extendedProps.absence && <>
                                <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            </>}
                            {arg.event.extendedProps.localisation && <span className={'float-end'}>
                                <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end'}>
                                <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            </span>}
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                <i className={'bi bi-shop'}></i> {arg.event.extendedProps?.company?.title}
                            </div>
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                {arg.event.extendedProps.salary.information.job.title}
                            </div>
                        </div>
                    </>
            }
            break;
        case calendarTypes.EVENT_TYPE_OFFER_VALIDATED:
        case calendarTypes.EVENT_TYPE_OFFER_PENDING:
            switch (arg.view.type){
                case calendarTypes.TIME_GRID_WEEK_VIEW:
                case calendarTypes.TIME_GRID_DAY_VIEW:
                    return <>
                        <b>
                            {arg.timeText}
                            <span className="clearfix"> </span>
                            {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}
                        </b>
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                    </>
                case calendarTypes.RESOURCE_TIMELINE_DAY_VIEW:
                case calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW:
                    return <>
                        <div className="d-flex">
                            <div className={'col'}>
                                <div className={'col'}>
                                   <i className={'bi bi-star'}></i> <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="col">
                                <b className={'time-caption'}>{arg.timeText}</b>
                            </div>
                            {arg.event.extendedProps.localisation && <div className={'col-auto rounded-pill shadow-sm px-1 mx-1'}
                                                                          style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                            </div>}
                            {arg.event.extendedProps.activity && <div className={'col-auto shadow-sm px-1 mx-1'}
                                                                      style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                            </div>}
                        </div>
                    </>
                case calendarTypes.RESOURCE_TIMELINE_VIEW:
                case calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW:
                    return <div> <i className={'bi bi-star'}> </i> <b>{arg.event.extendedProps.absence && Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) ? arg.event.extendedProps.absence.typeShortName : arg.timeText}</b></div>
            }
            break;
        case 'templateBookingException':
        case 'templateBooking':
            switch (arg.view.type){
                case 'resourceTimelineDay':
                    return <>
                        <div className="d-flex">
                            <div className={'col'}>
                                <span className={"mb-0"}>{arg.event.extendedProps.resource.job.title}</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="col">
                                {arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                                {arg.event.extendedProps.recurrence.type > 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                                <b className={'time-caption'}>{arg.timeText} - {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}</b>
                            </div>
                            {arg.event.extendedProps.localisation && <div className={'col-auto px-1'}
                                                                          style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                {<span>
                                        <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                    </span>}
                                <span>{arg.event.extendedProps.localisation?.title || ''}</span>
                            </div>}
                            {arg.event.extendedProps.activity && <div className={'col-auto px-1'}
                                                                      style={{backgroundColor: arg.event.extendedProps.activity.color}}>
                                {<span>
                                        <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                    </span>}
                                <span>{arg.event.extendedProps.activity.title || ''}</span>
                            </div>}
                        </div>
                    </>
                case 'resourceTimelineWeek':
                    return (
                        <>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span className={"mb-0"}>{arg.event.extendedProps.resource.job.title}</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.recurrence.type > 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                                    <b className={'time-caption'}>{arg.timeText}</b>
                                </div>
                                {arg.event.extendedProps.localisation && <div className={'col-auto rounded-circle px-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                </div>}
                                {arg.event.extendedProps.activity && <div className={'col-auto rounded-circle px-1'}
                                                                          style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                </div>}
                            </div>
                        </>
                    )
            }
            break;
        case calendarTypes.EVENT_TYPE_ABSENCE:
            return <>
                <div className="d-flex flex-column">
                    <div className={"flex-grow-0"}>
                        <h4>
                            {arg.event.title}
                        </h4>
                    </div>
                    <div className="flex-grow-0">
                        {format(new Date(arg.event.extendedProps.absence.start))} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(arg.event.extendedProps.absence.end))}
                    </div>
                </div>
            </>
        case calendarTypes.EVENT_TYPE_KEEP_NOTE:
            return <>
                {arg.event.extendedProps.private && <i className={'bi bi-lock-fill'}></i>}
                {arg.event.title}
            </>
        case calendarTypes.EVENT_TYPE_TASK:
        case calendarTypes.EVENT_TYPE_TASK_EXCEPTION:
            // switch (arg.view.type){
            //
            // }
            return <>
                <i className={taskTypes.getTaskStatusChoice(arg.event.extendedProps.status?.val).icon}></i> {arg.timeText} {arg.event.title}
            </>
        case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT:
        case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_EXCEPTION:
        case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION:
        case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT:
        case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION:
            switch (arg.view.type){
                case calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW:
                    return <span className={'h-100 w-100'} id={arg.event.id}>
                            <div className="d-flex">
                                <div className={'col d-flex'}>
                                    <div className="d-flex flex-grow-0">
                                        {arg.event.extendedProps.localisation && <div className={'rounded-circle text-center me-2'}
                                                                                      style={{backgroundColor: arg.event.extendedProps.localisation?.color, width: 15, height: 15}}>
                                            <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                        </div>}
                                        {arg.event.extendedProps.activity && <div className={'rounded-circle text-center me-2'}
                                                                                  style={{backgroundColor: arg.event.extendedProps.activity?.color, width: 15, height: 15}}>
                                            <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                        </div>}
                                    </div>
                                    <div className="flex-grow-1">
                                        <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                    </div>
                                    {arg.event.extendedProps.substitution && <div className={'flex-grow-0'}><i className={'bi bi-arrow-down-up'}> </i> </div>}
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) && arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                                    <b className={'time-caption'}> <i className={'bi bi-front'}></i> {arg.timeText} | {duration(arg.event.start, arg.event.end)}</b>
                                </div>
                            </div>
                        </span>
                case calendarTypes.RESOURCE_TIMELINE_DAY_VIEW:
                    return <span className={'h-100 w-100'} id={arg.event.id}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence) && arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                                {arg.event.extendedProps.substitution && <div className={'flex-grow-0'}><i className={'bi bi-arrow-down-up'}> </i> </div>}
                            </div>
                            <div className="clearfix"> </div>
                        {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                            <b className={'time-caption'}><i className={'bi bi-front'}></i> {arg.timeText} | {duration(arg.event.start, arg.event.end)}</b>
                            {arg.event.extendedProps.localisation && <span className={'float-end rounded-pill shadow-sm px-1 mx-1'}
                                                                           style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                        <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i> <i>{arg.event.extendedProps.localisation.title}</i>
                                    </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end shadow-sm px-1 mx-1'}
                                                                       style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                        <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i> <i>{arg.event.extendedProps.activity.title}</i>
                                    </span>}
                        </span>
                case calendarTypes.RESOURCE_TIMELINE_VIEW:
                case calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW:
                    return <b>{(arg.event.extendedProps.absence && Granted(accessRights.LIST_ABSENCE, arg.event.extendedProps.absence)) ? arg.event.extendedProps.absence.typeShortName : (displayEventTime ? arg.timeText : duration(arg.event.start, arg.event.end))}</b>
                case calendarTypes.LIST_WEEK_VIEW:
                case calendarTypes.LIST_DAY_VIEW:
                    return <></>
                case calendarTypes.TIME_GRID_DAY_VIEW:
                case calendarTypes.TIME_GRID_WEEK_VIEW:
                    return <>
                        <b>
                            {arg.event.extendedProps.eventType === calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.exchange && <i className={'bi bi-shuffle'}> </i>}
                            {arg.event.extendedProps.substitution && <i className={'bi bi-arrow-down-up'}> </i>}
                            <i className={'bi bi-front'}></i> {arg.timeText}
                            <span className="clearfix"> </span>
                            {(arg.event.end && arg.event.start) ? format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm') : arg.event.extendedProps.exceptionId}
                        </b>
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.absence && <>
                            <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.comment && <>
                            <i className={'bi bi-quote'}></i> {arg.event.extendedProps.comment}
                            <div className="clearfix"></div>
                        </>}
                    </>
            }
            return <>
                <i className={taskTypes.getTaskStatusChoice(arg.event.extendedProps.status?.val).icon}></i> {arg.timeText} {arg.event.title}
            </>

    }

    return <></>
}

export function resourceLaneContent(e: { resource: ResourceApi }){
    if (e.resource.extendedProps.hierarchy === 'parent' && e.resource.extendedProps.backgroundColor){
        let lane = document.querySelector(`.fc-timeline-lane.fc-resource[data-resource-id="${e.resource.id}"]`) as HTMLTableColElement;
        if (lane){
            lane.style.backgroundColor = e.resource.extendedProps.backgroundColor;
        }
    }else if(e.resource.extendedProps.hierarchy === 'grandChild'){
        let lane = document.querySelector(`.fc-timeline-lane.fc-resource[data-resource-id="${e.resource.id}"]`) as HTMLTableColElement;
        if (lane){
            lane.style.backgroundColor = "#e9ecef";
        }
    }
}

export const ResourceAreaHeaderContent:React.FC<{
    isAbsence: boolean,
    setIsAbsence: React.Dispatch<any>,
    filterEventsWithResources: boolean,
    setFilterEventsWithResources: React.Dispatch<any>,
    setIsBackgroundEvents: React.Dispatch<any>,
    isBackgroundEvents: boolean,
    setIsOuterEvent: React.Dispatch<any>
    isOuterEvents: boolean,
}> = (props) => {
    const {isAbsence, setIsAbsence, filterEventsWithResources, setFilterEventsWithResources, setIsBackgroundEvents, isBackgroundEvents, setIsOuterEvent, isOuterEvents} = props;

    return <span className="dropdown">
        <button className="btn bg-light shadow-sm text-primary dropdown-toggle" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
            <i className={"bi bi-filter"}></i> Filtrer
        </button>
        <ul className="dropdown-menu position-fixed" aria-labelledby="dropdownMenuButton1">
            <li className={"dropdown-item"}>
                <button className={'btn btn-sm'}
                        onClick={() => setIsAbsence((prevState: boolean) => !prevState)}>
                    <i className={isAbsence ? "bi bi-check text-success" : "bi bi-x text-danger"}></i> Afficher les créneaux en absence
                </button>
            </li>
            <li className={"dropdown-item"}>
                <button className={'btn btn-sm'}
                        onClick={() => setFilterEventsWithResources((prevState: boolean) => !prevState)}>
                    <i className={!filterEventsWithResources ? "bi bi-check text-success" : "bi bi-x text-danger"}></i> Afficher les collaborateurs sans créneau
                </button>
            </li>
            <li className={"dropdown-item"}>
                <button className={'btn btn-sm'}
                        onClick={() => setIsBackgroundEvents((prevState: boolean) => !prevState)}>
                    <i className={isBackgroundEvents ? "bi bi-check text-success" : "bi bi-x text-danger"}></i> Afficher les modifications en fond d'écran
                </button>
            </li>
            <li className={"dropdown-item"}>
                <button className={'btn btn-sm'}
                        onClick={() => setIsOuterEvent((prevState: boolean) => !prevState)}>
                    <i className={isOuterEvents ? "bi bi-check text-success" : "bi bi-x text-danger"}></i> Afficher les créneaux sur un autre établissement
                </button>
            </li>
        </ul>
    </span>
}

const ViewTitleContainer = forwardRef<any, any>((props, ref) => {
    const { onClick, viewTitle, prevClick, nextClick, endDate, handlePickedDateChange } = props;

    let maxDate = getMaxVisibilityDate();

    return (
        <div className={'d-flex'}>
            <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Précédent"
                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                className={'btn btn-light rounded-circle shadow flex-grow-0 shadow-sm'}
                onClick={() => prevClick()}
            >
                <i className="bi bi-caret-left text-primary"></i>
            </button>
            <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Calendrier"
                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                style={{maxWidth: 250}}
                className="btn dropdown-toggle text-capitalize flex-grow-1 text-primary"
                onClick={onClick}
                ref={ref}
            >
                <i className={'bi bi-calendar-range me-2'}> </i> {viewTitle}
            </button>
            <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Suivant"
                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                className={'btn btn-light rounded-circle flex-grow-0 shadow-sm'}
                onClick={() => nextClick()}
                disabled={maxDate && maxDate <= endDate}
            >
                <i className="bi bi-caret-right text-primary"></i>
            </button>
            <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Aujourd'hui"
                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                className={'btn btn-light flex-grow-0 ms-2 shadow-sm'}
                onClick={() => handlePickedDateChange(new Date())}
            >
                <i className="bi bi-calendar-event text-primary"></i>
            </button>
        </div>
    )
});

export const CalendarTitle: React.FC<{pickerDate: Date, handlePickerDateChange: (pickerDate: Date) => void, title: string, prevClick: () => void, nextClick: () => void, endDate: Date}> = (props) => {
    const { handlePickerDateChange, pickerDate, title, prevClick, nextClick, endDate } = props;

    return (
        <DatePicker
            selected={pickerDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode={'select'}
            onChange={(date) => {
                if (date instanceof Date){
                    handlePickerDateChange(date)
                }
            }}
            customInput={
                <ViewTitleContainer
                    viewTitle={title}
                    handlePickedDateChange={handlePickerDateChange}
                    prevClick={prevClick}
                    nextClick={nextClick}
                    endDate={endDate}
                />
            }
        />
    )
}


export const renderDaySeparator = () => {
    const leadingZero = (num: number) => `0${num}`.slice(-2);
    const formatTime = (date: Date) =>
        [date.getHours(), date.getMinutes(), date.getSeconds()]
            .map(leadingZero)
            .join(':');

    let lanes = Array.from(document.querySelectorAll('td.fc-timeline-slot-lane') as NodeListOf<HTMLElement>);
    let dates = lanes.filter((lane) => !!lane.dataset?.date).map((lane) => new Date(lane.dataset.date!).getTime())
    const maxDate = new Date(Math.max.apply(null, dates));
    const maxTime = formatTime(maxDate)
    for (let i in lanes){
        if(typeof lanes[i] === 'object'){
            if (lanes[i].hasAttribute('data-date')){
                if(lanes[i].getAttribute('data-date')?.split('T')[1] === maxTime){
                    lanes[i].style.cssText = 'border-right: solid #4723D9 2px !important;'
                }else{
                    lanes[i].style.cssText = ''
                }
            }
        }
    }
}