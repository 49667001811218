import * as React from "react";

import AdminSalaryList from "../../../components/admin/salary/list";
import AdminSalaryShow from "../../../components/admin/salary/show";

const AdminSalaryPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <AdminSalaryList />
            <AdminSalaryShow />
        </div>
    );
}

export default AdminSalaryPage;