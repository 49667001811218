import * as React from "react";

import CompaniesList from "../../components/companies/list";
import CompanyShow from "../../components/companies/show";

const CompaniesPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <CompaniesList />
            <CompanyShow />
        </div>
    );
}

export default CompaniesPage;