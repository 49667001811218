import React, {useEffect, useState} from "react";
import * as jobApi from "../../../../adapters/job"
import * as localisationApi from "../../../../adapters/localisation"
import * as activityApi from "../../../../adapters/activity"
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Loader from "../../../../utils/loader";
import {Job} from "../../../../models/job";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import {refresh} from "../../../../actions/calendar";

export const CalendarResourceOrder:React.FC = () => {

    const [items, setItems] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const dispatch = useDispatch();
    const state =  useSelector((state: RootStateOrAny) => state)
    const [type, setType] = useState<1|2|3>(state.rightSidebar.data.type || 1);

    useEffect(() => {
        setIsLoading(true)
        switch (type){
            case 1:
                jobApi.list().then(data => setItems(data.data.sort((a: Job, b: Job) => a.position! - b.position!))).then(() => setIsLoading(false));
                break;
            case 2:
                localisationApi.select().then(data => setItems(data.data.sort((a: Localisation, b: Localisation) => a.position! - b.position!))).then(() => setIsLoading(false));
                break;
            case 3:
                activityApi.select().then(data => setItems(data.data.sort((a: Activity, b: Activity) => a.position! - b.position!))).then(() => setIsLoading(false));
                break;
        }
    }, [type])

    const onDragStart = (e: any) => {
        e
            .dataTransfer
            .setData('text/plain', e.target.id);

        e.target.style.textColor = '#6c757d';
    }

    const onDragEnd = (e: any) => {
        e
            .dataTransfer
            .clearData();

        e.target.style.textColor = '';
    }

    function onDrop(event: any) {
        const id = event
            .dataTransfer
            .getData('text');

        const draggableElement = document.getElementById(id);
        const dropzone = event.currentTarget;

        if (id === dropzone.id){
            dropzone.style.borderBottom = "";
        }

        if (id !== dropzone.id && dropzone.parentNode.id === "dragAndDropzone" && draggableElement){
            dropzone.parentNode.insertBefore(draggableElement, dropzone.nextSibling);
            dropzone.style.borderBottom = "";
            reOrderItems();
            draggableElement.style.border = "solid #20c997 1px";
            setTimeout(() => draggableElement.style.border = '', 1000)
        }

        event
            .dataTransfer
            .clearData();
    }

    function reOrderItems() {
       let items = document.getElementsByClassName('draggable') as HTMLCollectionOf<HTMLDivElement>;
       let data = [];
        for (let i = 0; i < items.length; i++) {
            data.push({
                id: Number(items[i].dataset.target),
                position: i + 1
            })
            items[i].getElementsByClassName('counter')![0].textContent = String(i + 1);
        }

        handleDataChange(data);
    }

    const handleDataChange = (data: {id: number, position: number}[]) => {
        setIsUpdating(true);
        switch (type){
            case 1:
                jobApi.order(data).then(() => setIsUpdating(false));
                break;
            case 2:
                localisationApi.order(data).then(() => setIsUpdating(false));
                break;
            case 3:
                activityApi.order(data).then(() => setIsUpdating(false));
                break;
        }
    }

    function onDragOver(e: any) {
        e.preventDefault();
        e.currentTarget.style.borderBottom = "solid #727cf5 1px";
    }

    function onDragLeave(e: any) {
        e.currentTarget.style.borderBottom = "";
    }

    return <div className={'p-1 p-md-3 d-flex flex-column h-100'}>
        <div className="btn-group">
            <button className={'btn btn-outline-primary' + (type === 1 ? ' active text-white ' : '')} onClick={() => setType(1)}>
                <i className={'bi bi-people'}> </i> Métiers
            </button>
            <button className={'btn btn-outline-primary' + (type === 2 ? ' active text-white ' : '')} onClick={() => setType(2)}>
                <i className={'bi bi-bookmark'}> </i> Emplacements
            </button>
            <button className={'btn btn-outline-primary' + (type === 3 ? ' active text-white ' : '')} onClick={() => setType(3)}>
                <i className={'bi bi-geo-alt'}> </i> Activités
            </button>
        </div>
        <span className="form-text p-2 flex-grow-0">
            Cliquez glissez les éléments pour choisir la position
        </span>
        {isLoading ? <Loader /> : <><div className={'col-12 flex-grow-1'} id={'dragAndDropzone'}>
            <div className="p-2"
                 onDragLeave={onDragLeave}
                 onDragOver={onDragOver}
                 onDrop={onDrop}
            >
            </div>
            {items.map((item, index) => <div
                id={`draggable-${index}`}
                draggable={true}
                data-target={item.id}
                style={{backgroundColor: "white"}}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                className={'p-1 d-flex align-items-center draggable mb-1'}>
                <div className={'flex-grow-1'}>
                    <i className={'bi bi-arrow-down-up'}> </i> {item.title}
                </div>
                <span className={'flex-grow-0 counter'}>
                    {index + 1}
                </span>
            </div>)}
        </div>
            {state.rightSidebar.data.type === type && <button disabled={isUpdating} onClick={() => dispatch(refresh())}
                     className={'btn btn-outline-primary flex-grow-0 w-100'}>
                <i className={'bi bi-arrow-repeat'}> </i> Recharger le planning
            </button>}</>}
    </div>
}

export default CalendarResourceOrder;