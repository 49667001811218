import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import * as models from "../../../models/absence";

import { FORM_NEW_ABSENCE } from "../../../constants/rightSidebar";
import { FORM_FILTER_ABSENCE } from "../../../constants/staticBackdropModal";
import { PAGINATION } from "../../../constants/global";
import { CALENDAR_ABSENCE } from "../../../constants/calendar";
import * as accessRights from "../../../constants/accessRight";

import { list } from "../../../adapters/absence";
import * as actions from "../../../actions/absence";
import { openSidebar } from "../../../actions/rightSidebar";
import { openModal } from "../../../actions/staticBackdropModal";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { axiosError } from "../../../actions/axios";

import { Granted } from "../../../security/granted";
import getUser from "../../../security/getUser";
import format from "../../../utils/locale/index";
import LoaderList from "../../../utils/loaderList";

const AbsenceList: React.FC = () => {
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny)  => state.absence);
    const auth = useSelector((state: RootStateOrAny)  => state.auth);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [query, setQuery] = useState<string[]>();
    const [types, setTypes] = useState<string[]>();
    const [isFiltering, setFiltering] = useState<boolean>(false)
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    const validateFilter = (values: {status: string[], types: string[]}) => {
        setFiltering(true);
        setTypes([...values.types]);
        setQuery([...values.status]);
    }

    useEffect(() => {
        let controller = new AbortController();

        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                dispatch(actions.fetchAbsences())
                setIsLoading(true);
                setOffset(0)
                list({status: query, types: types, offset: 0}, controller.signal).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchAbsencesSuccess(data.data))
                    setIsLoading(false);
                    setOffset( 1);
                    setFiltering(false)
                }).catch(error => {
                    dispatch(axiosError(error))
                })
            }
            }, 1000);

        return () => {
            controller.abort();
            clearTimeout(timer)
        }
    }, [query])

    useEffect(() => {
        let controller = new AbortController();
        if(scrollBottom){
            if (offset && !isLast){
                setIsLoading(true)
                list({status: query, offset: offset}, controller.signal).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchAbsencesSuccess([...state.payload, ...data.data]))
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                    setScrollBottom(false)
                }).catch(error => {
                    dispatch(axiosError(error))
                })
            }else{
                setScrollBottom(false);
            }
        }

        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)

        return () => {
            controller.abort()
            container.removeEventListener("scroll", onScrollBottom)
        }
    }, [scrollBottom])

    useEffect(() => {
        dispatch(setPageTitle('Absence'))
        dispatch(setHelpProductSheetIds([5]))

        setIsLoading(true);

        let controller = new AbortController();
        dispatch(actions.fetchAbsences())
        list({status: ['1','2','3'], offset: 0}, controller.signal).then(data => {
            dispatch(actions.fetchAbsencesSuccess(data.data))
            setIsLast(data.data.length < PAGINATION);
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => {
            dispatch(axiosError(error))
        })

        return () => {
            dispatch(setHelpProductSheetIds([]))
            controller.abort();
        }
    }, [])

    return (
        <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
            <div className="btn-group w-100 sticky-top top-0 bg-white">
                <button
                    className={'btn btn-outline-primary'}
                    disabled={isFiltering}
                    onClick={() => dispatch(openModal(FORM_FILTER_ABSENCE, { status: query, types: types }, { validateModal: validateFilter }))}
                >
                    {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : <><i className="bi bi-filter"> </i> <span className={'d-none d-md-inline-block'}> Filtrer</span> </>}
                </button>

                {(Granted(accessRights.EDIT_ABSENCE) || Granted(accessRights.REQUEST_ABSENCE)) &&
                    <button
                        className='btn btn-outline-primary'
                        onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE, {salary: getUser().currentSalary}))}
                    >
                        <i className="bi bi-plus"> </i> <span className={'d-none d-md-inline-block'}>
                            {Granted(accessRights.EDIT_ABSENCE) ? "Ajouter" : "Demander"}
                        </span>
                    </button>
                }

                {Granted(accessRights.LIST_ABSENCE) &&
                    <Link
                        className={"btn btn-outline-primary"}
                        to={"/calendar/" + format(new Date(), 'uuuu-MM-dd') + "/" + CALENDAR_ABSENCE}
                    >
                        <i className="bi bi-calendar-week"> </i> <span className={'d-none d-md-inline-block'}> Planning</span>
                    </Link>
                }
            </div>

            <ul className={"list-group-flush px-0"}>
                {!!state.payload.length && state.payload.map((absence: models.Absence, index: number) => ([
                    <Link to={"/absence/" + absence.id} key={index} className={"list-group-item list-group-item-action" + (state.single?.id === absence.id ? ' active' : '')}>
                        <i className={'me-2 ' + (absence.status === 1 ? 'bi bi-exclamation-circle text-warning' : (absence.status === 2 ? 'bi bi-check2-circle text-success' : 'bi bi-dash-circle text-danger'))} > </i>
                        {absence.salary?.firstname} {absence.salary?.lastname} - {absence.typeName}
                        <div className="clearfix"> </div>
                        <small>
                            {format(new Date(absence.start))} - {format(new Date(absence.end))}
                        </small>
                    </Link>
                ]))}

                {isLoading ?
                    <LoaderList
                        title={true}
                        listLength={!state.payload.length ? 20 : 1}
                        description={true}
                        leftCircle={false}
                    /> :
                    (!state.payload.length ? <li className={"list-group-item"}>
                        Aucune abences
                    </li> : '')
                }

                {!isLoading && !isLast && state.payload.length &&
                    <button
                        className={'list-group-item text-primary text-center list-group-item-action'}
                        onClick={() => setScrollBottom(true)}
                    >
                        <i className={'bi bi-arrow-repeat'}> </i> Charger plus
                    </button>
                }
            </ul>
        </div>
    )
}

export default AbsenceList;