import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../../../actions/header";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../utils/customInput";
import * as api from "../../../adapters/reporting";
import format from "../../../utils/locale";
import FileSaver from "file-saver";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {Choice} from "../../../utils/form/model";

const ExportSilae:React.FC = () => {

    const dispatch = useDispatch();
    let now = new Date()
    now.setDate(now.getDate() - 30);
    const [startDate, setStartDate] = useState<Date>(now);
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [exporting, setExporting] = useState(false);
    const [type, setType] = useState<string>()
    const [company, setCompany] = useState<Choice|null>()
    const [team, setTeam] = useState<Choice|null>()

    useEffect(() => {
        dispatch(setPageTitle('Exports Silae'))
    }, [])

    const validate = () => {
        setExporting(true);
        api.exportSilae({
            start: format(startDate, 'uuuu-MM-dd'),
            end: format(endDate, 'uuuu-MM-dd'),
            type: type,
            company: company?.value,
            team: team?.value,
        }).then(data => {
            let newBlob = new Blob([data.data], { type: data.headers["content-type"] })

            let fileName: string = 'export-silae-';

            switch (type){
                case 'SHEET_TYPE_ABSENCE':
                    fileName += 'absence-'
                    break;
                case 'SHEET_TYPE_TIME_CLOCK':
                    fileName += 'aj-'
                    break;
            }

            if (company){
                fileName += company.label + '-'
            }

            if (team){
                fileName += team.label + '-'
            }

            fileName += format(startDate, 'uuuu-MM-dd') + '-' + format(endDate, 'uuuu-MM-dd')

            FileSaver.saveAs(newBlob, `${fileName}.xlsx`)
            setExporting(false)
        })
    }

    return <div className={'container mx-auto h-100'}>
        <div className="row mt-5">
            <div className="col-md-4">
                <div className="card shadow-sm p-1 p-md-3 text-center">
                    <h4>Absences</h4>
                    <button className={'btn btn-outline-primary'} data-bs-toggle="modal" data-bs-target="#datePicker" onClick={() => setType('SHEET_TYPE_ABSENCE')}>
                        <i className={'bi bi-download'}></i> Exporter
                    </button>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card shadow-sm p-1 p-md-3 text-center">
                    <h4>Activité journalière</h4>
                    <button className={'btn btn-outline-primary'} data-bs-toggle="modal" data-bs-target="#datePicker" onClick={() => setType('SHEET_TYPE_TIME_CLOCK')}>
                        <i className={'bi bi-download'}></i> Exporter
                    </button>
                </div>
            </div>
        </div>
        <div className="modal fade" id="datePicker" data-bs-keyboard="false" aria-labelledby="datePickerLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="datePickerLabel">
                            <i className={'bi bi-filter'}></i> Filtrer
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                fetchEntity={'company'}
                                label={'Point de vente'}
                                required={false}
                                onChange={c => setCompany(c)}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                fetchEntity={'team'}
                                label={'Équipe'}
                                required={false}
                                onChange={c => setTeam(c)}
                                disabled={exporting}
                            />
                        </div>
                        <div className="input-group">
                            <DatePicker
                                className={"form-control"}
                                selected={startDate}
                                customInput={<DateCustomInput label={'Début'}
                                                              icon={'bi bi-calendar-check text-primary'}/>}
                                selectsStart
                                onChange={(dt: Date) => setStartDate(dt)}
                                endDate={endDate}
                                dateFormat="E dd MMM uuuu"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                                disabled={exporting}
                            />
                            <span className={'input-group-text'}>
                                <i className={'bi bi-arrow-left-right'}> </i>
                            </span>
                            <DatePicker
                                startDate={startDate}
                                className={"form-control"}
                                selected={endDate}
                                customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-x text-primary'}/>}
                                selectsEnd
                                onChange={(dt: Date) => setEndDate(dt)}
                                dateFormat="E dd MMM uuuu"
                                popperPlacement={"top-end"}
                                showPopperArrow={false}
                                disabled={exporting}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button disabled={exporting} type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">
                            <i className={'bi bi-x'}> </i> Annuler
                        </button>
                        <button disabled={exporting} type="button" className={'btn btn-light text-success'} onClick={() => validate()}>
                            {exporting ? <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : <><i className={'bi bi-download'}> </i> Exporter</>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ExportSilae;