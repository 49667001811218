import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {openSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_SALARY_INFORMATION} from "../../../constants/rightSidebar";
import {User} from "../../../models/auth";
import getUser from "../../../security/getUser";
import FormError from "../../../utils/formError";
import DatePicker from "react-datepicker";
import validationClass from "../../../utils/validationClass";
import DateCustomInput from "../../../utils/customInput";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import * as api from "../../../adapters/billing";
import {Link} from "react-router-dom";
import SmallLoader from "../../../utils/loader/small";
import { checkEmailAvailable } from "../../../adapters/salary";
import { showAlertDanger } from "../../../actions/alert";
import PhoneInputCustom from "../../common/PhoneInputCustom";

export const SalaryAdd: React.FC = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state)
    const user: User = getUser();
    const billingVersion = user.currentSalary?.companyGroup?.billingVersion;

    const [loading, setLoading] = useState(billingVersion === 2)
    const [billingCompanies, setBillingCompanies] = useState<{label: string, value: number, shouldUpgrade: boolean, id: number}[]>([])
    const [initialValues, setInitialValues] = useState({
        firstname: '',
        lastname: '',
        tel: '',
        email: '',
        birthdate: new Date(),
        address: {
          address: '',
          city: '',
          zip: '',
        },
        accessLevel: state.accessLevel.payload[0]?.id,
        companies: [state.auth.user.currentSalary.companies[0].id],
    })
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
        firstname: Yup.string().required('Veuillez préciser un prénom'),
        lastname: Yup.string().required('Veuillez préciser un nom'),
        tel: Yup.string().required('Veuillez préciser un numéro de téléphone'),
        email: Yup.string(),
        birthdate: Yup.date().required('Veuillez préciser une date de naissance'),
        address: Yup.object().shape({
            address: Yup.string().required('Veuillez préciser l\'adresse'),
            city: Yup.string().required('Veuillez préciser la ville'),
            zip: Yup.string().length(5, 'Le code postal doit faire 5 chiffres').required('Veuillez préciser le code postal'),
        }),
        accessLevel: Yup.number().required('Veuillez choisir un droit d\'accès'),
        companies: Yup.array().min(1, 'Veuillez choisir au moins une entreprise').required('Veuillez au moins une entreprise')
    }));

    useEffect(() => {
        if (billingVersion === 2){
            setInitialValues(p =>   ({...p, billingCompany: null}))
            setValidationSchema(p =>   (Yup.object().shape({...p.fields, billingCompany: Yup.number().required()})));
            setLoading(true)
            api.companies().then((data) => {
                setBillingCompanies(data.data);
                setLoading(false)
            })
        }
    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                if (values?.email) {
                    await checkEmailAvailable({ email: values.email })
                }
                dispatch(submitSidebar());
                dispatch(openSidebar(FORM_NEW_SALARY_INFORMATION, {salary: values}));
            } catch (error: any) {
                dispatch(showAlertDanger(error?.response?.data?.message))
            }
        }
    })

    if (state.error){
        return (
            <div className='w-100 text-center alert alert-danger'>
                {state.error}
            </div>
        )
    }

    return (
            <div className={'p-3'}>
                <div className="position-relative my-4 mx-auto w-50">
                    <div className="progress" style={{height: 1}}>
                        <div className="progress-bar" role="progressbar" style={{width: '50%'}} aria-valuenow={0}
                             aria-valuemin={0} aria-valuemax={100}> </div>
                    </div>
                    <button type="button"
                            className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill"
                            style={{width: 32, height:32}}>1
                    </button>
                    <button type="button"
                            className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-light rounded-pill"
                            style={{width: 32, height:32}}>2
                    </button>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <h6>
                        Informations personnelles
                    </h6>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input type="text" className="form-control" name={"firstname"} id={"firstname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <label htmlFor="firstname">
                                    Prénom <span className={'required'}>*</span>
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'firstname'} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input type="text" className="form-control" name={"lastname"} id={"lastname"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <label htmlFor="firstname">
                                    Nom <span className={'required'}>*</span>
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'lastname'} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <PhoneInputCustom
                                    value={formik.values.tel}
                                    onChange={(phone) => formik.setFieldValue('tel', phone)}
                                />
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'tel'} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input type="email" className="form-control" name={"email"} id={"email"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <label htmlFor="email">
                                    Email (optionnel)
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'email'} />
                        </div>
                        <div className="col-12 mb-3">
                            <DatePicker
                                className={'form-control' + validationClass(formik.errors, formik.touched, 'birthdate')}
                                popperPlacement="top-start"
                                customInput={<DateCustomInput label={'Date de naissance'} />}
                                onChange={(date: Date) => {
                                    formik.setFieldValue('birthdate', date)
                                }}
                                dateFormat={"dd MMM uuuu"}
                                selected={formik.values.birthdate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                endDate={new Date()}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'birthdate'} />
                        </div>
                    </div>
                    <h6>
                        Adresse
                    </h6>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type={'text'}
                                    className={'form-control ' + validationClass(formik.errors,formik.touched,'address.zip')}
                                    name="address.zip"
                                />
                                <label htmlFor="address.zip">
                                    Code postal <span className={'required'}>*</span>
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'address.zip'} />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-floating">
                                <input
                                    id={'address.city'}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type={'text'}
                                    className={'form-control ' + validationClass(formik.errors,formik.touched,'address.city')}
                                    name="address.city"
                                />
                                <label htmlFor="address.city">
                                    Ville <span className={'required'}>*</span>
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'address.city'} />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="form-floating">
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type={'text'}
                                    className={'form-control ' + validationClass(formik.errors,formik.touched,'address.address')}
                                    name="address.address"
                                    id="address.address"
                                />
                                <label htmlFor="address.address">
                                    Adresse <span className={'required'}>*</span>
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'address.address'} />
                            </div>
                        </div>
                    </div>
                    <h6>
                        Accès
                    </h6>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                className={validationClass(formik.errors, formik.touched, 'accessLevel')}
                                fetchEntity={"accessLevel"}
                                onChange={(value) => formik.setFieldValue('accessLevel', Number(value?.value))}
                                label={'Rôle'}
                                required={true}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'accessLevel'} />
                        </div>
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                required={true}
                                className={validationClass(formik.errors, formik.touched, 'companies')}
                                fetchEntity={"company"}
                                isMultiple={true}
                                onChangeMultiple={(values) => formik.setFieldValue('companies', values.map(choice => choice.value))}
                                label={'Établissements'}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'companies'} />
                        </div>
                    </div>
                    {billingVersion === 2 && <>
                        <h6>
                            Séléctionner un établissement pour la facturation
                        </h6>
                        <FormError errors={formik.errors} touched={formik.touched} field={"billingCompany"} />
                        <div className="col-12 mb-3">
                            {!billingCompanies.length && (loading ? <SmallLoader /> : <p className={'text-danger'}>Aucune entreprise disponible</p>)}
                            {billingCompanies.map(bc => <div className="form-check">
                                <input disabled={bc.shouldUpgrade} name={"billingCompany"} onChange={formik.handleChange} className="form-check-input" type="radio" value={bc.id} id={`billingCompany_${bc.id}`} />
                                <label className="form-check-label" htmlFor={`billingCompany_${bc.id}`}>
                                    {bc.label}
                                </label>
                                <span className="clearfix"></span>
                                {bc.shouldUpgrade && <Link to={`/billing/${bc.id}/subscription`}>
                                    Établissement complet. Mettre à niveau l'abonnement <i className={'bi bi-arrow-right'}></i>
                                </Link>}
                            </div>)}
                        </div>
                    </>}
                    <button className="btn w-100 btn-light" type={"submit"}>
                        Suivant <i className={'bi bi-arrow-right'}></i>
                    </button>
                </form>
            </div>
    )


}

export default SalaryAdd;