import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Salary} from "../../../models/salary";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {TaskGroupCustomField, TaskGroupCustomFieldValue} from "../../../models/taskGroup/customField";
import {TaskGroup} from "../../../models/taskGroup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {SalarySelected, SalarySelector} from "../../../utils/salarySelector";
import {CustomInput} from "../../../constants/taskGroup/customField";
import Loader from "../../../utils/loader";
import * as taskGroupApi from "../../../adapters/taskGroup";
import * as api from "../../../adapters/task";
import {fetchTaskGroupsSuccess} from "../../../actions/taskGroup";
import TaskRecurrenceEdit from "../recurrence/edit";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import {editTaskSuccess} from "../../../actions/task";
import * as calendarActions from "../../../actions/task/calendar";
import {Task} from "../../../models/task";
import FormError from "../../../utils/formError";

const TaskEdit: React.FC = () => {

    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const dispatch = useDispatch();
    const taskGroup: TaskGroup = data.group
    const [isLoading, setIsLoading] = useState(false);
    const [displaySalarySelector, setDisplaySalarySelector] = useState(false);
    const [editRecurrence, setEditRecurrence] = useState(false);
    const [editType, setEditType] = useState<false|1|2>(false);
    const [displayEditTypeSelector, setDisplayEditTypeSelector] = useState(false);

    const [initialValues, setInitialValues] = useState<any>({
        task: {
            title: data.title,
            description: data.description,
            allDay: data.allDay||false,
            salaries: data.salaries?.map((salary: Salary) => salary.id),
        },
        customFields: {}
    })

    useEffect(() => {
        if (editType){
            console.log(taskForm.errors)
            taskForm.submitForm()
        }
    }, [editType])

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        task: Yup.object().shape({
            title: Yup.string().required('Champs requis'),
            description: Yup.string().nullable(),
            allDay: Yup.boolean().required(),
            beginAt: Yup.date().required(),
            salaries: Yup.array().of(Yup.number()).nullable(),
        }),
        customFields: Yup.object().shape({})
    }));

    useEffect(() => {
        let _validationSchema = {...validationSchema}
        let _initialValues = {...initialValues}

        if (data.allDay){
            _initialValues.task.beginAt =  new Date(data.beginAt)
        }else{
            _initialValues.task.beginAt =  new Date(data.beginAt)
            _initialValues.task.endAt =  new Date(data.endAt)
            _validationSchema.fields.task.fields.endAt =  Yup.date().required('Fin de créneau requis')
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { beginAt } = this.parent;
                    return value && beginAt <= value
                })
        }

        setInitialValues({..._initialValues});
        setValidationSchema(Yup.object().shape(_validationSchema));
    }, [])

    useEffect(() => {
        if (taskGroup){
            setIsLoading(true);
            let _validationSchema: any = {...validationSchema.fields}
            let _initialValues: any = {...initialValues}

            if (_validationSchema.customFields?.fields.length){
                for (let i in Object(_validationSchema.customFields.fields).keys()){
                    delete _validationSchema.customFields.fields[i]
                }
            }

            if (_initialValues.customFields?.length){
                for (let j in Object(_initialValues.customFields).keys()){
                    delete _initialValues.customFields[j]
                }
            }

            if (taskGroup.customFields!.length){
                taskGroup.customFields!.map((customField: TaskGroupCustomField) => {
                    switch (customField.type){
                        case 1:
                            _validationSchema.customFields.fields[customField.id!] = Yup.string().nullable()
                            _initialValues.customFields[customField.id!] = data.customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                            break;
                        case 2:
                            _validationSchema.customFields.fields[customField.id!] = Yup.string().nullable()
                            _initialValues.customFields[customField.id!] = data.customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                            break;
                        case 3:
                            _validationSchema.customFields.fields[customField.id!] = Yup.number().nullable()
                            _initialValues.customFields[customField.id!] = data.customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                            break;
                        case 4:
                            _validationSchema.customFields.fields[customField.id!] = Yup.date().nullable()
                            let dateValue = data.customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value;
                            _initialValues.customFields[customField.id!] = dateValue ? new Date(dateValue) : null;
                            break;
                        case 5:
                            _validationSchema.customFields.fields[customField.id!] = Yup.string().nullable()
                            _initialValues.customFields[customField.id!] = data.customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || null;
                            break;
                        case 6:
                            _validationSchema.customFields.fields[customField.id!] = Yup.array().of(Yup.number()).nullable()
                            _initialValues.customFields[customField.id!] = data.customFieldsValues.find((customFieldValue: TaskGroupCustomFieldValue) => customFieldValue.field.id === customField.id)?.value || [];
                    }

                })
            }
            setValidationSchema(Yup.object().shape(_validationSchema));
            setInitialValues({..._initialValues});
            setIsLoading(false);
        }
    }, [])

    const handleAllDayChange = (allDay: boolean) => {
        let _validationSchema = {...validationSchema}
        let _taskValidationSchema = {..._validationSchema.fields.task.fields}
        let _values = {...taskForm.values};

        _values.task.allDay = allDay;

        if (allDay){
            delete _values.task.endAt;
            delete _taskValidationSchema.endAt
        }else{
            _values.task.endAt = _values.task.beginAt
            _taskValidationSchema.endAt = Yup.date().required();
        }

        _validationSchema.fields.task = Yup.object().shape({..._taskValidationSchema})

        setInitialValues({..._values})
        setValidationSchema(Yup.object().shape({..._validationSchema.fields}))
    }

    const handleSubmitSuccess = (data: Task, helpers: FormikHelpers<any>) => {
        setEditType(false);
        helpers.setSubmitting(false)
        dispatch(calendarActions.refresh())
        dispatch(editTaskSuccess(data))
        dispatch(showAlertSuccess('Tâche modifiée'))
        dispatch(closeSidebar());
    }

    const taskForm = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
       onSubmit: (values, helpers) => {
           dispatch(submitSidebar())

           switch (editType){
               case 1:
                   if (data.isException){
                       api.editException(values, data.exceptionId).then((data) => {
                           handleSubmitSuccess(data.data, helpers)
                       })
                   }else{
                       api.createException(values, data.id).then((data) => {
                           handleSubmitSuccess(data.data, helpers)
                       })
                   }
                   break;
               case 2:
                   api.edit(values, data.id).then((data) => {
                       handleSubmitSuccess(data.data, helpers)
                   })
                   break;
           }
       }
    });

    // return <></>

    if (displayEditTypeSelector){
        return <div>
            <div className={'mb-5 py-3'}>
                <button onClick={() => setDisplayEditTypeSelector(false)} className={'btn btn-outline-primary'}>
                    Retour
                </button>
            </div>
            <h5>
                <i className="bi bi-arrow-repeat"> </i>
                Tâche récurrente
            </h5>
            <p>
                {data.recurrence.description}
            </p>
            <h3 className={'mt-5 mb-3'}>
                Vous souhaitez modifier le créneau
            </h3>
            <ul className={'list-unstyled align-middle'}>
                <li className={'mb-3'}>
                    <button className={'btn btn-outline-primary'} onClick={() => setEditType(1)}>
                        Une seule fois
                    </button>
                </li>
                <li className={'mb-3'}>
                    <button className={'btn btn-outline-primary'} onClick={() => setEditType(2)}>
                        Toutes les occurences
                    </button>
                </li>
            </ul>
        </div>
    }

    if (isLoading) return <Loader />

    if (editRecurrence) return <TaskRecurrenceEdit setEditRecurrence={setEditRecurrence} />

   return <>
       <div className="p-3">
           <div className="col-12 mb-2">
               <div className="row">
                   <div className="col">
                       <h4>
                           <i className="bi bi-arrow-repeat"> </i> Récurrence
                       </h4>
                       {data.recurrence.description}
                   </div>
                   <div className="col-auto">
                       <button className="btn btn-light" onClick={() => setEditRecurrence(true)}>
                           <i className={'bi bi-pencil-square'}> </i>
                       </button>
                   </div>
               </div>
           </div>
           <hr/>
           <div className="col-12 mb-2">
               <h4>
                   Tâche
               </h4>
               <form onSubmit={taskForm.handleSubmit}>
                   <div className="col-12 mb-2">
                       <div className="form-floating">
                           <input
                               defaultValue={data.title}
                               type="text"
                               className={'form-control'}
                               name={'task.title'}
                               onChange={taskForm.handleChange}
                           />
                           <label htmlFor="">
                                Titre
                           </label>
                       </div>
                       <FormError errors={taskForm.errors} touched={taskForm.touched} field={'task.title'} />
                   </div>
                   <div className="col-12 mb-2">
                       <label htmlFor="">
                           Description
                       </label>
                       <textarea
                           defaultValue={data.description}
                           className={'form-control'}
                           name={'task.description'}
                           onChange={taskForm.handleChange} >
                       </textarea>
                       <FormError errors={taskForm.errors} touched={taskForm.touched} field={'task.description'} />
                   </div>
                   <div className="col-12 mb-2">
                       <div className="form-check">
                           <input onChange={(e) => {
                               handleAllDayChange(e.target.checked)
                           }} defaultChecked={data.allDay} className="form-check-input" type="checkbox" value="" id="allDay" />
                           <label className="form-check-label" htmlFor="allDay">
                               Toute la journée
                           </label>
                       </div>
                   </div>
                   <div className="col-12 mb-2">
                       {taskForm.values.task.allDay ? <>
                           <DatePicker
                               className={'form-control'}
                               onChange={(date) => taskForm.setFieldValue('task.beginAt', date)}
                               selected={taskForm.values.task.beginAt}
                               dateFormat={'E dd MMM uuuu'}
                           />
                           <FormError errors={taskForm.errors} touched={taskForm.touched} field={'task.beginAt'} />
                       </> : <>
                           <div className="input-group">
                               <DatePicker
                                   showTimeSelect
                                   timeIntervals={15}
                                   timeCaption="Heure"
                                   className={'form-control'} selected={taskForm.values.task.beginAt}
                                   selectsStart
                                   endDate={taskForm.values.task.endAt}
                                   onChange={(date) => taskForm.setFieldValue('task.beginAt', date)}
                                   dateFormat={'E dd MMM uuuu HH:mm'}  />
                               <span className="input-group-text">
                           <i className="bi bi-arrow-left-right"> </i>
                       </span>
                               <DatePicker
                                   showTimeSelect
                                   timeIntervals={15}
                                   timeCaption="Heure"
                                   className={'form-control'}
                                   selected={taskForm.values.task.endAt}
                                   selectsEnd startDate={taskForm.values.task.beginAt}
                                   onChange={(date) => taskForm.setFieldValue('task.endAt', date)}
                                   dateFormat={'E dd MMM uuuu HH:mm'}  />
                           </div>
                           <FormError errors={taskForm.errors} touched={taskForm.touched} field={'task.beginAt'} />
                           <FormError errors={taskForm.errors} touched={taskForm.touched} field={'task.endAt'} />
                       </>}
                   </div>
                   <div className="col-md-12 mb-2">
                       <label htmlFor="">
                           Collaborateurs
                       </label>
                       <SalarySelected
                           onDelete={(id: number) => {
                               let salaries = [...taskForm.values.task.salaries]
                               salaries.splice(salaries.indexOf(id), 1)
                               taskForm.setFieldValue('task.salaries', salaries)
                           }}
                           onOpen={() => setDisplaySalarySelector(true)}
                           initialValues={taskForm.values.task.salaries}
                       />
                       <FormError errors={taskForm.errors} touched={taskForm.touched} field={'task.salaries'} />
                       {displaySalarySelector && <SalarySelector
                           onSubmit={(ids: number[]) => {
                               taskForm.setFieldValue('task.salaries', ids)
                               setDisplaySalarySelector(false)
                           }}
                           onClose={() => setDisplaySalarySelector(false)}
                           initialValues={taskForm.values.task.salaries}
                       />}
                   </div>
                   {taskGroup.customFields?.map((customField) => <>
                       <div className="col-12 mb-2">
                           <label htmlFor="">
                               {customField.title}
                           </label>
                           <CustomInput
                               customField={customField}
                               setFieldValue={taskForm.setFieldValue}
                               values={taskForm.values}
                           />
                       </div>
                   </>)}
                   <button type={'button'} onClick={() => {
                       if (data.recurrence.type > 1 && data.instanceToken && !data.preventException){
                           setDisplayEditTypeSelector(true)
                       }else{
                           setEditType(2);
                       }
                   }} className={'btn btn-outline-primary w-100'}>
                       Enregistrer
                   </button>
               </form>
           </div>
       </div>
   </>
}

export default TaskEdit;