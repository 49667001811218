import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {ErrorMessage, Field, FieldProps, Form, Formik, FormikValues} from "formik";
import * as actions from "../../../../actions/template/booking";
import * as api from "../../../../adapters/template/booking";
import {showAlertSuccess} from "../../../../actions/alert";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import {setHours, setMinutes} from "date-fns";
import format from "../../../../utils/locale";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import DateCustomInput from "../../../../utils/customInput";
import validationClass from "../../../../utils/validationClass";
import FormError from "../../../../utils/formError";
import {Resource} from "../../../../models/template";
import {FORM_NEW_TEMPLATE_RESOURCE} from "../../../../constants/rightSidebar";
import {refresh} from "../../../../actions/template/calendar";
import {axiosError} from "../../../../actions/axios";

const baseSchema = {
    resource: Yup.number().required(),
    beginAt: Yup.date().required(),
    endAt: Yup.date().required('Fin de créneau requis')
        .test("is-greater", "La fin doit être après le début", function(value: any) {
            const { beginAt } = this.parent;
            return value && beginAt <= value
        }),
    localisation: Yup.number().nullable(),
    activity: Yup.number().nullable(),
    recurrence: Yup.object().shape({
        type: Yup.number().required(),
    })
};

const TemplateBookingAdd:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({...baseSchema}));
    let date: Date;
    let end: Date;
    if(state.rightSidebar.data.beginAt && state.rightSidebar.data.endAt){
        date =  state.rightSidebar.data.beginAt
        end =  state.rightSidebar.data.endAt
    }else{
        date = new Date()
        date.setHours(date.getHours(), 0, 0, 0)
        end = new Date(date)
        end.setHours(end.getHours() + 1, 0, 0, 0)
    }

    const [initialValues, setInitialValues] = useState<any>({
        resource: state.rightSidebar.data.resourceId ? state.rightSidebar.data.resourceId :  (state.rightSidebar.data.resources.length ? state.rightSidebar.data.resources[0].id : null),
        beginAt: date,
        endAt: end,
        localisation: state.rightSidebar.data.localisation?.id || null,
        activity: state.rightSidebar.data.activity?.id || null,
        recurrence: {
            type: "1"
        }
    })

    const renderDayContents = (day: number, date: Date) => {
        return <span>{format(date, 'E')}</span>;
    };

    const handleTypeChange = (e: BaseSyntheticEvent, values: FormikValues) => {
        let type = e.target.value
        let _validationSchema = {...baseSchema};
        let _recurrenceSchema:any = {};
        let _initialValues = {...values}

        switch (type) {
            case '1':
                _recurrenceSchema = {type: Yup.string().required()}
                _initialValues.recurrence.type = '1';
                if (_initialValues.recurrence.hasOwnProperty('daysOfWeek')){
                    delete _initialValues.recurrence.daysOfWeek;
                }
                break;
            case '2':
                _recurrenceSchema = {
                    type: Yup.string().required(),
                    daysOfWeek: Yup.array().of(Yup.number()).min(1, 'Un jour de la semaine minimum').required('Veuillez choisir le(s) jour(s)'),
                }
                _initialValues.recurrence.type = '2';
                _initialValues.recurrence.daysOfWeek = [format(_initialValues.beginAt, 'i')];
        }

        _validationSchema.recurrence = Yup.object().shape({..._recurrenceSchema})
        setValidationSchema(Yup.object().shape({..._validationSchema}));
        setInitialValues({..._initialValues});
    }

    const handleWeekDayChange = (e: BaseSyntheticEvent, values: FormikValues) => {
        let checked = e.target.checked;
        let _values = {...values};
        let dayElements: HTMLInputElement[]|any = document.getElementsByName("recurrence.daysOfWeek")
        let ret:string[] = []
        dayElements.forEach((day: HTMLInputElement) => {
            day.checked = checked
            if (checked)
                ret.push(day.value)
        })

        _values.recurrence.daysOfWeek = ret
        setInitialValues({..._values})
    }

    if (!state.rightSidebar.data.resources.length){
        return <div className={'mt-4 text-center'}>
                <h4>
                    Veuillez ajouter une ressource pour pouvoir ajouter un créneau
                    <button onClick={() => dispatch(openSidebar(FORM_NEW_TEMPLATE_RESOURCE, {id: state.rightSidebar.data.id}))} className={'btn btn-outline-primary'}>
                        <i className={'bi bi-person-plus'}> </i> Ajouter un resource
                    </button>
                </h4>
        </div>
    }

    return (
        <>
            <div className='p-3'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={values => {
                        dispatch(submitSidebar());
                        api.create(values).then(data => {
                            dispatch(refresh())
                            dispatch(showAlertSuccess(' Créneau ajouté !'))
                            dispatch(closeSidebar());
                        }).catch(error => dispatch(axiosError(error)))
                    }}
                >
                    {({ values, errors, touched, setValues }) => (
                        <Form className="p-3 template-add-booking-form">
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <Field
                                        as={'select'}
                                        name={'resource'}
                                        className={'form-select' + validationClass(errors, touched, 'resource')}
                                        children={state.rightSidebar.data.resources.map((resource: Resource) => <option value={resource.id}>{resource.title}</option>)}
                                    />
                                    <label htmlFor="">
                                        Resource <span className="required">*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="input-group">
                                    <Field>
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                              meta,
                                          }: FieldProps) => (
                                            <>
                                                    <DatePicker
                                                        className={'form-control ' + validationClass(errors, touched, 'beginAt')}
                                                        title={'Début'}
                                                        selected={values.beginAt}
                                                        onChange={(date: Date) => {
                                                            setFieldValue(`beginAt`, date)
                                                        }}
                                                        renderDayContents={renderDayContents}
                                                        renderCustomHeader={() => <></>}
                                                        openToDate={new Date("2021/10/04")}
                                                        minDate={new Date("2021/10/04")}
                                                        maxDate={new Date("2021/10/10")}
                                                        selectsStart
                                                        endDate={values.endAt}
                                                        showTimeInput={true}
                                                        timeInputLabel={'Heure'}
                                                        customInput={<DateCustomInput label={'Début'} />}
                                                        dateFormat="E HH:mm"
                                                    />
                                            </>
                                        )}
                                    </Field>
                                    <span className="input-group-text">
                                        <i className={'bi bi-arrow-left-right'}> </i>
                                    </span>
                                    <Field>
                                        {({
                                              field, // { name, value, onChange, onBlur }
                                              form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                              meta,
                                          }: FieldProps) => (
                                            <>
                                                <DatePicker
                                                    className={'form-control' + validationClass(errors, touched, 'endAt')}
                                                    title={'Fin'}
                                                    customInput={<DateCustomInput label={'Fin'} />}
                                                    renderDayContents={renderDayContents}
                                                    openToDate={new Date("2021/10/04")}
                                                    minDate={new Date("2021/10/04")}
                                                    maxDate={new Date("2021/10/10")}
                                                    renderCustomHeader={() => <></>}
                                                    selected={values.endAt}
                                                    onChange={(date: Date) => {
                                                        setFieldValue(`endAt`, date)
                                                    }}
                                                    selectsEnd
                                                    startDate={values.beginAt}
                                                    endDate={values.endAt}
                                                    minTime={setHours(setMinutes(new Date(),
                                                        new Date(values.endAt).setHours(0,0,0,0) === new Date(values.beginAt).setHours(0,0,0,0) ? new Date(values.beginAt).getMinutes() : 0
                                                        ),
                                                        new Date(values.endAt).setHours(0,0,0,0) === new Date(values.beginAt).setHours(0,0,0,0) ? new Date(values.beginAt).getHours() : 0
                                                    )}
                                                    maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                                    showTimeInput={true}
                                                    timeInputLabel={'Heure'}
                                                    timeCaption="Heure"
                                                    dateFormat="E HH:mm"
                                                />
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <FormError errors={errors} touched={touched} field={'beginAt'} />
                                <FormError errors={errors} touched={touched} field={'endAt'} />
                            </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating">
                                            <Field
                                                as={'select'}
                                                className={'form-select' + validationClass(errors, touched, 'localisation')}
                                                required={false}
                                                name={`localisation`}
                                                children={[<option selected value={''}>Aucun </option>, state.rightSidebar.data.company?.localisations?.map((localisation: Localisation) => <option value={localisation.id}>{localisation.title}</option>)]}
                                            />
                                            <label htmlFor={`localisation`}>
                                                Localisation
                                            </label>
                                            <FormError errors={errors} touched={touched} field={'localisation'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating">
                                            <Field
                                                as={'select'}
                                                className={'form-select' + validationClass(errors, touched, 'activity')}
                                                required={false}
                                                name={`activity`}
                                                children={[<option value={''} selected>Aucun </option>,state.rightSidebar.data.company.activities?.map((activity: Activity) => <option value={activity.id}>{activity.title}</option>)]}
                                            />
                                            <label htmlFor={`activity`}>
                                                Activité
                                            </label>
                                            <FormError errors={errors} touched={touched} field={'activity'} />
                                        </div>
                                    </div>
                                </div>
                            <div className="col-12 mb-3">
                                <h4>
                                    <i className={'bi bi-arrow-repeat'}> </i> Recurrence
                                </h4>
                                <div className="w-100 text-center py-2">
                                    <div className="form-check form-check-inline">
                                        <Field>
                                            {({
                                                  field, // { name, value, onChange, onBlur }
                                                  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                  meta,
                                              }: FieldProps) => (
                                                <input name={'recurrence.type'} value={'1'} id={'type_1'} type={'radio'} defaultChecked={true} onChange={(e) => {
                                                    handleTypeChange(e, values)
                                                }} />
                                            )}
                                        </Field>
                                        <label className="form-check-label ms-2" htmlFor="type_1" >
                                            Une seule fois
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <Field>
                                            {({
                                                  field, // { name, value, onChange, onBlur }
                                                  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                  meta,
                                              }: FieldProps) => (
                                                <input name={'recurrence.type'} value={'2'} id={'type_2'} type={'radio'} onChange={(e) => {
                                                    handleTypeChange(e, values)
                                                }} />
                                            )}
                                        </Field>
                                        <label className="form-check-label ms-2" htmlFor="type_2">
                                            Plusieurs fois
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {values.recurrence.type === '2' &&
                            <>
                                <div className={'col-12 mb-3'}>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked_days"
                                               onChange={(e) => handleWeekDayChange(e, values)} />
                                        <label className="form-check-label" htmlFor="flexCheckChecked_days">
                                            Tous les jours de la semaine
                                        </label>
                                    </div>
                                    {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                                        return (
                                            <>
                                                <Field
                                                    type="checkbox"
                                                    name={'recurrence.daysOfWeek'}
                                                    className="btn-check" id={`btn-check-${index}`} autoComplete="off" value={`${index + 1}`} />
                                                <label className="btn btn-sm btn-outline-primary me-2 mb-2" htmlFor={`btn-check-${index}`}>
                                                    {day}
                                                </label>
                                            </>
                                        )
                                    })}
                                    <FormError errors={errors} touched={touched} field={'recurrence.daysOfWeek'} />
                                </div>
                            </>}

                            <button className='btn btn-outline-primary w-100' type="submit">
                                <i className={'bi bi-plus-circle'}> </i>
                                Ajouter
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default TemplateBookingAdd;