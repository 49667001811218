import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import * as models from "../../../models/taskGroup";

import * as accessRights from "../../../constants/accessRight";
import { FORM_EDIT_TASK_GROUP, FORM_NEW_TASK_GROUP } from "../../../constants/rightSidebar";
import { PAGINATION } from "../../../constants/global";

import * as api from "../../../adapters/taskGroup";
import * as actions from "../../../actions/taskGroup";
import { openSidebar } from "../../../actions/rightSidebar";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { showAlertSuccess } from "../../../actions/alert";

import IsGranted from "../../../security/isGranted";
import Loader from "../../../utils/loader";

const TaskGroupList: React.FC = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny)  => state.taskGroup);

    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState<string>()
    const [offset, setOffset] = useState<number>(0)
    const [isLast, setIsLast] = useState<boolean>(false)
    const [scrollBottom, setScrollBottom] = useState<boolean>(false)

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    const deactivate = (group: models.TaskGroup) => {
        api.deactivate(group.id!).then(() => {
            dispatch(actions.cancelTaskGroupSuccess(group));
            dispatch(showAlertSuccess('Groupe de tâche désactivé'));
        })
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                dispatch(actions.fetchTaskGroups())
                setIsLoading(true);
                setOffset(0)
                api.list({query: query, offset: 0}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchTaskGroupsSuccess(data.data))
                    setIsLoading(false);
                    setOffset( 1);
                }).catch(error => {
                    dispatch(actions.fetchTaskGroupsFailure(error.response.data.message))
                })
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {

        if(scrollBottom && offset > 0 && !isLast){
            setIsLoading(true)
            api.list({query: query, offset: offset}).then(data => {
                setIsLast(data.data.length < PAGINATION);
                dispatch(actions.fetchTaskGroupsSuccess([...state.payload, ...data.data]))
                setIsLoading(false)
                setScrollBottom(false);
                setOffset(prevState => prevState + 1)
            }).catch(error => {
                dispatch(actions.fetchTaskGroupsFailure(error.response.data.message))
            })
        }

        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)

       return () => container.removeEventListener("scroll", onScrollBottom)
    }, [scrollBottom])

    useEffect(() => {
        dispatch(setPageTitle('Tâches'))
        dispatch(setHelpProductSheetIds([7]))

        dispatch(actions.fetchTaskGroups());
        api.list({query: query, offset: 0}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            dispatch(actions.fetchTaskGroupsSuccess(data.data))
            if (!state.single && !!data.data.length){
                dispatch(actions.showTaskGroupSuccess(data.data[0]))
            }

            setOffset( 1);
            }).catch(error => {
                dispatch(actions.fetchTaskGroupsFailure(error.response.data.message))
            })

        return () => {
            dispatch(setHelpProductSheetIds([]))
        }
    }, [])

    return (
        <div id={'listContainer'} className={"container mx-auto"}>
            <div className="row py-1 py-md-3 align-items-center">
                <div className="col">
                    <div className="input-group shadow-sm flex-grow-1 me-3">
                        <div className="input-group-text bg-white border-0">
                            <i className={'bi bi-search'}> </i>
                        </div>
                        <input onChange={(e) => {
                            setQuery(e.target.value)
                        }} type="search" className={'form-control border-0'}/>
                    </div>
                </div>

                <div className="col-auto">
                    {IsGranted(accessRights.EDIT_TASK_GROUP) &&
                        <button
                            className='btn btn-outline-primary'
                            onClick={() => dispatch(openSidebar(FORM_NEW_TASK_GROUP))}
                        >
                            <i className={'bi bi-plus-circle'}> </i> Ajouter
                        </button>
                    }
                </div>
            </div>

            {state.isLoading ?
                <Loader />
                :
                <div className={'row'}>
                    {!!state.payload.length && state.payload.map((taskGroup: models.TaskGroup, index: number) =>
                        <div key={index} className={"col-md-4 mb-3"}>
                            <div className="card shadow-sm p-1 p-md-3">

                                <div className="d-flex align-items-center mb-3">
                                    <div className="flex-grow-1">
                                        <div className={'h4 d-flex align-items-center'}>
                                        <div className={'rounded-circle me-2'} style={{height: 20, width: 20, backgroundColor: taskGroup.color}}></div>  <div>{taskGroup.title}</div>
                                        </div>
                                    </div>

                                    <div className={'flex-grow-0'}>
                                        {IsGranted(accessRights.EDIT_TASK_GROUP) &&
                                            <button
                                                className={'btn btn-light me-2'}
                                                onClick={() => dispatch(openSidebar(FORM_EDIT_TASK_GROUP, {...taskGroup}))}
                                            >
                                                <i className={'bi bi-pencil text-primary'}></i>
                                            </button>
                                        }

                                        {IsGranted(accessRights.DELETE_TASK_GROUP) &&
                                            <button
                                                className={'btn btn-light'}
                                                onClick={() => deactivate(taskGroup)}
                                            >
                                                <i className={'bi bi-dash-circle text-danger'}></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default TaskGroupList;