import {post, get} from "../../xhr";
import * as models from "../../../models/taskGroup/customField";

export function list(){
    return get('task/group/list');
}

export function edit(requestData: models.TaskGroupCustomField, id: number){
    return post('task/group/custom/field/edit/' + id, requestData);
}

export function create(requestData: models.TaskGroupCustomField){
    return post('task/group/custom/field/create',  requestData);
}

export function show(id: string){
    return get('task/group/custom/field/show/' + id);
}