import React, {useEffect, useRef, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {EventClickArg} from "@fullcalendar/react";
import {openSidebar} from "../../../actions/rightSidebar";
import {
    FORM_EDIT_ABSENCE,
    FORM_EDIT_BOOKING_SUBSTITUTE, FORM_EVENT_CANCEL, FORM_EVENT_EXCEPTION_ADD,
    FORM_EVENT_EXCEPTION_EDIT, FORM_NEW_EVENT_EXCHANGE,
    SHOW_SALARY_TEMPLATE
} from "../../../constants/rightSidebar";
import format from "../../locale";
import {hideEventPopup, refresh, refreshResource} from "../../../actions/calendar";
import * as api from "../../../adapters/booking";
import * as exceptionApi from "../../../adapters/event/exception";
import * as eventExchangeApi from "../../../adapters/eventExchange";
import {BookingExceptionPayload} from "../../../adapters/booking";
import {getAccountingTypeChoice} from "../../../constants/booking";
import {calendarInitialStateInterface} from "../../../models/calendar";
import {Tooltip} from "bootstrap";
import {TYPE_EVENT, TYPE_EVENT_EXCEPTION} from "../../../constants/event";
import SmallLoader from "../../loader/small";

const EventPopup:React.FC = () => {

    const e:EventClickArg = useSelector((state: RootStateOrAny) => state.calendar.eventClickArg)
    const calendar: calendarInitialStateInterface = useSelector((state: RootStateOrAny) => state.calendar)
    const show:boolean = useSelector((state: RootStateOrAny) => state.calendar.showEventPopup)
    const eventPopRef = useRef<HTMLDivElement>(null)
    const [cancelType, setCancelType] = useState<1|2|3>(1);
    const [isLoading, setIsLoading] = useState(false)
    const [labourDayLoading, setLabourDayLoading] = useState(false)
    const [positionsLoaded, setPositionsLoaded] = useState(false)
    const [positions, setPositions] = useState<DOMRect>()
    const dispatch = useDispatch();

    useEffect(() => {
        if (positions){
            eventPopPosition();
        }
    }, [positions, cancelType])

    // useEffect(() => {
    //     let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    //     tooltipTriggerList.map(function (tooltipTriggerEl) {
    //         return new Tooltip(tooltipTriggerEl)
    //     })
    //
    //     return () => {
    //         tooltipTriggerList.map(t => Tooltip.getInstance(t).hide())
    //     }
    // }, [show, positionsLoaded])

    useEffect(() => {
        document.addEventListener('click', handleBodyClick)

        if (e){
            setPositions(e?.el.getBoundingClientRect())
        }

        return () => {
            document.removeEventListener('click', handleBodyClick)
        }
    }, [e])


    const setLabourDayWorked = (e: EventClickArg, worked: boolean) => {
        setLabourDayLoading(true);
        let values: BookingExceptionPayload = {
            labourDayWorked: worked,
            accountingType: e.event.extendedProps.accountingType,
            salary: e.event.extendedProps.salary.id,
            company: e.event.extendedProps.company.id,
            activity: e.event.extendedProps.activity?.id,
            localisation: e.event.extendedProps.localisation?.id,
            endAt: e.event.end!,
            beginAt: e.event.start!,
            description: e.event.extendedProps.description,
            substitute: e.event.extendedProps.substitute?.id,
            comment: e.event.extendedProps.comment,
        }

        if (e.event.extendedProps.isException){
            api.editException(values, e.event.extendedProps.exceptionInstanceId).then((data) => {
               dispatch(refresh())
                setLabourDayLoading(false);
            })
        }else{
            api.createException(values, e.event.extendedProps.bookingInstanceId, e.event.extendedProps.instanceToken).then((data) => {
                dispatch(refresh())
                setLabourDayLoading(false);
            })
        }
    }



    const eventPopPosition = () => {
        setPositionsLoaded(false)
        let popup = eventPopRef.current
        if (show && popup && e && positions){
            if ((popup.clientHeight/2) + (positions.top + (positions.height/2)) > window.innerHeight){
                let diff = (popup.clientHeight/2) + (positions.top + (positions.height/2)) - window.innerHeight;
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) - diff - 15 + 'px';
            }else if ((popup.clientHeight/2) > positions.top + (positions.height/2)){
                let diff = (popup.clientHeight/2) - (positions.top + (positions.height/2));
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) + diff + 15 + 'px';
            }else{
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) + 'px';
            }

            if (positions.left > (320 + 5) || (window.innerWidth - positions.left - positions.width) > (320 + 5)){
                if (positions.left > (window.innerWidth - positions.left - positions.width)){
                    // LEFT
                    popup.style.left = (positions.left - 320 - 5) + 'px'
                }else{
                    //RIGHT
                    popup.style.left = (positions.left + positions.width + 5) + 'px'
                }
            }else{
                popup.style.left = (window.innerWidth - 320) / 2 + 'px';
            }
        }
        setPositionsLoaded(true)
    }

    const handleBodyClick = (evt: MouseEvent) => {

        let popup = eventPopRef.current

        if (popup && show){
            let dimensions = popup.getBoundingClientRect();

            let [xStart, xEnd] = [dimensions.left, dimensions.left + dimensions.width];
            let [yStart, yEnd] = [dimensions.top, dimensions.top + dimensions.height];

            let clickOutsideX = !(xStart <= evt.clientX && xEnd >= evt.clientX);
            let clickOutsideY = !(yStart <= evt.clientY && yEnd >= evt.clientY);

            if (clickOutsideX || clickOutsideY){
                dispatch(hideEventPopup())
            }
        }
    }

    const [removingSub, setRemovingSub] = useState(false);
    const removeSub = () => {
        setRemovingSub(true)
        exceptionApi.removeSub(e.event.extendedProps.exceptionId).then(() => {
            setRemovingSub(false);
            dispatch(refresh());
            dispatch(hideEventPopup())
        })
    }

    const EventPopContent = () => {
        if (show){
            return <>
                <div className="col-12">
                    <button className={'m-1 btn btn-close float-end'}  onClick={() => dispatch(hideEventPopup())} />
                    <button className={'m-1 btn btn-sm text-primary'}
                            // data-bs-toggle="tooltip" data-bs-placement="top" title="Modifier"
                            onClick={() => {
                        dispatch(hideEventPopup())
                        switch (e.event.extendedProps.eventType) {
                            case TYPE_EVENT:
                                dispatch(openSidebar(FORM_EVENT_EXCEPTION_ADD, {
                                    eventId: e.event.extendedProps.eventId,
                                    salary: e.event.extendedProps.salary,
                                    accountingType: e.event.extendedProps.accountingType,
                                    start: e.event.start,
                                    initialStart: e.event.start,
                                    end: e.event.end,
                                    daysOfWeek: e.event.extendedProps.weekDays,
                                    company: e.event.extendedProps.company,
                                    localisation: e.event.extendedProps.localisation,
                                    activity: e.event.extendedProps.activity,
                                    resourceId: calendar.resourceId,
                                    parentId: calendar.parentId,
                                    comment: e.event.extendedProps.comment,
                                }))
                                break;
                            case TYPE_EVENT_EXCEPTION:
                                dispatch(openSidebar(FORM_EVENT_EXCEPTION_EDIT, {
                                    eventId: e.event.extendedProps.eventId,
                                    exceptionId: e.event.extendedProps.exceptionId,
                                    salary: e.event.extendedProps.salary,
                                    start: e.event.start,
                                    end: e.event.end,
                                    initialStart: e.event.start,
                                    company: e.event.extendedProps.company,
                                    localisation: e.event.extendedProps.localisation,
                                    activity: e.event.extendedProps.activity,
                                    resourceId: calendar.resourceId,
                                    parentId: calendar.parentId,
                                    comment: e.event.extendedProps.comment,
                                }))
                        }
                    }}>
                        <i className={'bi bi-pencil'}> </i>
                    </button>
                    <button className={'m-1 btn btn-sm text-primary'}
                            onClick={() => {
                        dispatch(openSidebar(FORM_EDIT_BOOKING_SUBSTITUTE, {
                            start: e.event.start,
                            end: e.event.end,
                            accountingType: e.event.extendedProps.accountingType,
                            company: e.event.extendedProps.company,
                            activity: e.event.extendedProps.activity,
                            localisation: e.event.extendedProps.localisation,
                            salary: e.event.extendedProps.salary,
                            eventType: e.event.extendedProps.eventType,
                            eventId: e.event.extendedProps.eventId,
                            exceptionId: e.event.extendedProps.exceptionId,
                            comment: e.event.extendedProps.comment,
                            resourceId: calendar.resourceId,
                            parentId: calendar.parentId,
                        }));
                        dispatch(hideEventPopup())
                    }}>
                        <i className={'bi bi-arrow-down-up'}> </i>
                    </button>
                    {!e.event.extendedProps.exchange && !e.event.extendedProps.exchanged && <button className={'m-1 btn btn-sm text-primary'}
                                                                      // data-bs-toggle="tooltip" data-bs-placement="top" title="Échanger"
                                                                      onClick={() => {
                        dispatch(openSidebar(FORM_NEW_EVENT_EXCHANGE, {
                            start: e.event.start,
                            end: e.event.end,
                            eventId: e.event.extendedProps.eventId,
                            initialStart: e.event.extendedProps.initialStart || e.event.start,
                            salary: e.event.extendedProps.salary,
                            company: e.event.extendedProps.company,
                            timeCaption: e.event.extendedProps.timeCaption,
                        }))
                        dispatch(hideEventPopup())
                    }}>
                        <i className={'bi bi-shuffle'}></i>
                    </button>}
                    <button className={'m-1 btn btn-sm text-danger'}
                            // data-bs-toggle="tooltip" data-bs-placement="top" title="Supprimer"
                            onClick={(ev) => {
                                ev.stopPropagation()
                                dispatch(openSidebar(FORM_EVENT_CANCEL, {
                                    start: e.event.start,
                                    end: e.event.end,
                                    eventType: e.event.extendedProps.eventType,
                                    eventId: e.event.extendedProps.eventId,
                                    exceptionId: e.event.extendedProps.exceptionId,
                                    salary: e.event.extendedProps.salary,
                                    accountingType: e.event.extendedProps.accountingType,
                                    company: e.event.extendedProps.company,
                                    activity: e.event.extendedProps.activity,
                                    localisation: e.event.extendedProps.localisation,
                                    comment: e.event.extendedProps.comment,
                                    resourceId: calendar.resourceId,
                                    parentId: calendar.parentId,
                                }))
                                dispatch(hideEventPopup())
                            }}>
                        <i className={'bi bi-dash-circle'}> </i>
                    </button>
                </div>
                {e.event.extendedProps.labourDay && <>
                    <h4 className={'text-center text-primary w-100 mb-2'}>
                        {e.event.extendedProps.labourDay.title}
                    </h4>
                    <div className="form-check mb-2">
                        <input
                            onChange={(ev) => setLabourDayWorked(e, ev.target.checked)}
                            defaultChecked={e.event.extendedProps.labourDayWorked}
                            className="form-check-input" type="checkbox" id="labourDay" />
                        <label className="form-check-label" htmlFor="labourDay">
                            Jour férié travaillé
                        </label>
                    </div>
                    <hr/>
                </>}
                {e.event.extendedProps.isSlotExchange && <>
                    <hr/>
                    <div className="col-12">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h4 className={'text-primary'}>
                                    Échange de créneau
                                </h4>
                            </div>
                            <div className="flex-grow-0">
                                <button className={'btn btn-sm'} onClick={() => {
                                    dispatch(hideEventPopup());
                                    eventExchangeApi.cancel(e.event.extendedProps.eventExchangeId).then(() => {
                                        dispatch(refresh());
                                    })
                                }}>
                                    <i  className={'bi bi-x text-danger'}></i> Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
                <div className="col-12">
                    {e.event.extendedProps.templateId && <div className="col-12">
                        <h4 className={'text-primary'}>
                            Emploi du temps : {e.event.extendedProps.templateTitle}
                        </h4>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <i className={'bi bi-calendar text-primary'}> </i>{format(new Date(e.event.extendedProps.templateStart), "dd/MM/uuuu")} <i className={'bi bi-arrow-left-right'}></i> {e.event.extendedProps.templateEnd ? format(new Date(e.event.extendedProps.templateEnd), "dd/MM/uuuu") : <>Non renseigné</>}
                            </div>
                            <div className="flex-grow-0">
                                <button className={'btn btn-sm'} onClick={() => {
                                    dispatch(openSidebar(SHOW_SALARY_TEMPLATE, {
                                        id: e.event.extendedProps.templateId,
                                        salary: e.event.extendedProps.salary,
                                    }))
                                    dispatch(hideEventPopup())
                                }}>
                                    <i className={"bi bi-pencil text-primary"}></i> Modifier
                                </button>
                            </div>
                        </div>
                    </div>}
                </div>
                <hr/>
                <div className="col-12">
                    <h4 className={'text-primary'}>
                        Collaborateur
                    </h4>
                    <div className="col-12">
                        <i className={'bi bi-person'}></i> {e.event.extendedProps.salary.firstname} {e.event.extendedProps.salary.lastname}
                        <div className="clearfix"> </div>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                {e.event.extendedProps.salary.information.job.title}
                            </div>
                            <div className="flex-grow-0">
                                <div className="col-auto">
                                    <div className="color-circle">
                                        <div className="p-1 m-1 shadow" style={{
                                            opacity: '0.8',
                                            backgroundColor: e.event.backgroundColor
                                        }}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i className={'bi bi-telephone'}> </i> {e.event.extendedProps.salary.tel}
                    </div>
                </div>
                {e.event.extendedProps.substitute && !e.event.extendedProps.substitution && <>
                    <hr/>
                    <div className="col-12">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h4 className={'text-primary'}>
                                    Remplacant
                                </h4>
                            </div>
                            <div className="flex-grow-0">
                                <button className={'btn btn-sm btn-outline-danger'} onClick={() => removeSub()}>
                                    {removingSub ? <SmallLoader /> : "Annuler"}
                                </button>
                            </div>
                        </div>
                        <div className="col-12">
                            <i className={'bi bi-person'}></i> {e.event.extendedProps.substitute.firstname} {e.event.extendedProps.substitute.lastname}
                            <div className="clearfix"></div>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    {e.event.extendedProps.substitute.information.job.title}
                                </div>
                                <div className="flex-grow-0">
                                    <div className="col-auto">
                                        <div className="color-circle">
                                            <div className="p-1 m-1 shadow" style={{
                                                opacity: '0.8',
                                                backgroundColor: e.event.backgroundColor
                                            }}>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <i className={'bi bi-telephone'}> </i> {e.event.extendedProps.substitute.tel}
                        </div>
                    </div>
                </>}
                <hr/>
                <div className="col-12">
                            <h4 className={'text-primary'}>
                                Lieu et activité
                            </h4>
                            <i className="bi bi-shop text-primary"> </i> <span>{e.event.extendedProps.company.title}</span>
                            <div className="clearfix"> </div>
                            <i className={e.event.extendedProps.localisation?.icon}> </i>
                            <span>{e.event.extendedProps.localisation?.title || ''}</span>
                            <div className="clearfix"> </div>
                            <i className={e.event.extendedProps.activity?.icon}> </i>
                            <span>{e.event.extendedProps.activity?.title || ''}</span>
                            <div className="clearfix"> </div>
                            {e.event.extendedProps.description && <span className={'form-text'}>
                                <i className={'bi bi-card=text'}> </i> {e.event.extendedProps.description}
                            </span>}
                </div>
                <hr/>
                <div className="col-12">
                    <h4 className={'text-primary'}>
                        Horaire
                    </h4>
                    <span>
                        <i className={'bi bi-clock text-primary'}> </i> {format(e.event.start!, "HH:mm")} - {format(e.event.end!, "HH:mm")} - {e.event.extendedProps.timeCaption}
                    </span>
                    <div className="clearfix"> </div>
                    {e.event.extendedProps.accountingType && <span>{getAccountingTypeChoice(e.event.extendedProps.accountingType)?.label}</span>}
                    <span className="clearfix"> </span>
                    <span> <i className={'bi bi-arrow-repeat text-primary'}> </i> {e.event.extendedProps.description}</span>
                </div>
                {e.event.extendedProps.absence && <>
                    <hr/>
                    <div className={'col-12'}>
                    <h4 className={"text-primary"}>
                        Absence
                    </h4>
                    <div className="d-flex w-100 align-items-center">
                        <div className={"h4 mb-0 flex-grow-1"}>{e.event.extendedProps.absence.typeName}</div>
                        <div className="flex-grow-0">
                            <button className={'btn'} onClick={() => {
                                dispatch(openSidebar(FORM_EDIT_ABSENCE, {...e.event.extendedProps.absence, ...{
                                        resourceId: calendar.resourceId,
                                        parentId: calendar.parentId,
                                    }}))
                                dispatch(hideEventPopup())
                            }}>
                                <i className="bi bi-pencil text-primary"> </i>
                            </button>
                        </div>
                    </div>
                    <div className="clearfix"> </div>
                    <p className="form-text">
                        Du {format(new Date(e.event.extendedProps.absence.start)) + ' au ' + format(new Date(e.event.extendedProps.absence.end))}
                    </p>
                </div></>}
            </>
        }else{
            return <></>
        }
    }

    if (!e) return <></>

   return <div ref={eventPopRef} id="eventPop" className={"card shadow p-3 position-fixed fade " + (positionsLoaded && show ? 'show' : 'hide')} style={{width:320, transition: '0.2s', zIndex: (show ? 10000 : -1)}}>
            {isLoading ?
                <div className={'w-100 text-center'}>
                    <div className="spinner-border my-5 text-primary" style={{
                        width: '4rem',
                        height: '4rem',
                    }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                 : <EventPopContent />}
         </div>
}

export default EventPopup;