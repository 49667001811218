import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/calendar";
import format from "../../../utils/locale";

const BookingTable:React.FC<{start?: Date, end?: Date, salary?: number}> = (props) => {

    const {start, end, salary} = props

    const [isLoading, setIsLoading] = useState(false)
    const [events, setEvents] = useState<any[]>([]);

    useEffect(() => {
        if (start && end && salary){
            setIsLoading(true)
            let controller = new AbortController();
            api.fetchCalendar({start: format(start, "uuuu-MM-dd"), end: format(end, "uuuu-MM-dd"), options: {isSingle: true, explodeRecurrence: true, salaries: [salary]}}, controller.signal)
                .then(data => {
                    setEvents(data.data.events);
                    setIsLoading(false);
                })
        }
    }, [start, end])

    if (!salary || !start || !end){
        return <></>
    }

    if (isLoading){
        return <div className={'w-100 p-3 d-flex align-items-center'}>
            <div className="spinner-border text-primary me-2" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> <div className={'text-light-s'}>Chargement des horaires plannifiés...</div>
        </div>
    }

    return <table className={"table table-sm"}>
        <thead>
            <tr>
               <th colSpan={4}>
                   <h4>Planifié</h4>
               </th>
            </tr>
            <tr>
                <th>
                    Horaire
                </th>
                <th>
                    Durée
                </th>
                <th>
                    Point de vente
                </th>
            </tr>
        </thead>
        <tbody>
        {!events.length && <tr>
            <td colSpan={4}>
                <i className={"bi bi-search"}> </i> Aucun horaire planifié
            </td>
        </tr>}
            {events.map((e: any) => <tr>
                <td>
                    {format(new Date(e.start), 'HH:mm')} <i className={'bi bi-arrow-left-right mx-1'}></i> {format(new Date(e.end), 'HH:mm')}
                </td>
                <td>
                    {e.timeCaption}
                </td>
                <td>
                    {e.company.title}
                </td>
            </tr>)}
        </tbody>
    </table>
}

export default BookingTable;