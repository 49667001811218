import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as accessRights from "../../../constants/accessRight";
import * as api from "../../../adapters/company";
import * as salaryApi from "../../../adapters/salary";
import * as actions from "../../../actions/companies";
import * as authActions from "../../../actions/auth";
import * as salaryActions from "../../../actions/salary";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_COMPANY} from "../../../constants/rightSidebar";
import {Company} from "../../../models/companies";
import {Salary} from "../../../models/salary";
import {Granted, GrantedMany} from "../../../security/granted";

const NoCompany:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state.companies);
    const salary: Salary = useSelector((state: RootStateOrAny) => state.auth.user.currentSalary);

    useEffect(() => {
        dispatch(actions.fetchCompanies());
        api.list().then(data => dispatch(actions.fetchCompaniesSuccess(data.data)))
    }, [])

    const updateSalary = (id: number) => {
        dispatch(salaryActions.editSalary());
        salaryApi.edit({
            firstname: salary.firstname,
            lastname: salary.lastname,
            tel: salary.tel,
            email: salary.email,
            birthdate: new Date(salary.birthdate),
            companies: [...salary.companies.map(c => c.id!), id],
            address: salary.address!,
        }, salary.id).then(data => {
            dispatch(salaryActions.editSalarySuccess(data.data))
            dispatch(authActions.selectAccount(data.data))
        })
    }

    return <>
        <div className="container mx-auto py-1 py-md-4">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex w-100 align-items-center mb-3">
                        <div className={'flex-grow-1'}>
                            <h4 className={'mb-1'}>
                                <i className={'bi bi-exclamation-circle text-danger'}> </i>  Aucun point de vente accessible
                            </h4>
                        </div>
                        {Granted(accessRights.EDIT_COMPANY) && <>
                            <button onClick={() => dispatch(openSidebar(FORM_NEW_COMPANY))} className={'btn btn-outline-primary flex-grow-0'}>
                                <i className={'bi bi-plus-circle'}> </i> Créer un point de vente
                            </button>
                        </>}
                    </div>
                    {state.payload.length && GrantedMany([accessRights.EDIT_SALARY, accessRights.LIST_COMPANY]) && <>
                        <p className="text-light-s">
                            Veuillez séléctionner un point de vente dans la liste ci-dessous.
                        </p>
                        <ul className={'list-group list-group-flush'}>
                        {!!state.payload.length && state.payload.map((company: Company, index: number) => <li
                            key={index} className={'list-group-item'}>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <label className="form-check-label h4" htmlFor={`flexSwitchCheck${index}`}>
                                        <i className={'text-primary bi bi-shop'}> </i> {company.title}
                                    </label>
                                </div>
                                <div className="flex-grow-0">
                                    <div className="form-check form-switch">
                                        <input
                                            onChange={() => updateSalary(company.id!)}
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`flexSwitchCheck${index}`}/>
                                    </div>
                                </div>
                            </div>
                        </li>)}
                    </ul></>}
                </div>
            </div>
        </div>
    </>
}

export default NoCompany;