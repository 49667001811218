import React, {useEffect, useState} from "react";
import {EventDropArg} from "@fullcalendar/react";
import BookingAddForm from "../../../../booking/add/form";
import {Offcanvas} from "bootstrap";

interface Interface {
    arg?: EventDropArg,
    handleSubmitSuccess: (props: any[]) => any,
    close: () => any,
}

const BookingDrop:React.FC<Interface> = (props) => {

    const {arg, handleSubmitSuccess, close} = props;
    const [key, setKey] = useState(1);

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarBooking')! as HTMLDivElement;
        el.addEventListener('hide.bs.offcanvas',  cancelDrop)

        return () => el.removeEventListener('hide.bs.offcanvas',  cancelDrop)
    }, [arg])

    const cancelDrop = () => {
        if (arg && arg.oldEvent){
            arg?.event.setDates(arg.oldEvent.start!, arg.oldEvent.end);
        }
    }

    const handleSubmit = (ev: any) => {
        if (arg && arg.oldEvent){
            arg?.event.setDates(arg.oldEvent.start!, arg.oldEvent.end);
        }

        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarBookingDropLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <BookingAddForm
                key={key}
                start={arg.oldEvent.start!}
                end={arg.oldEvent.end!}
                initialSalary={arg.newResource ? arg.newResource.extendedProps.salary : arg.oldResource?.extendedProps.salary}
                initialCompany={arg.oldResource?.extendedProps.company}
                localisation={arg.oldResource?.extendedProps.localisation}
                activity={arg.oldResource?.extendedProps.activity}
                handleSubmitSuccess={handleSubmit}
            />}
        </div>
    </>
}

export default BookingDrop;