import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as actions from "../../../actions/companies";
import {setPageTitle} from "../../../actions/header";
import * as api from "../../../adapters/company";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Company} from "../../../models/companies";
import * as accessRights from "../../../constants/accessRight";
import * as types from "../../../constants/companyGroup";
import {Link} from "react-router-dom";
import CompanyInformation from "./information";
import CompanyFlow from "./flow";
import {axiosError} from "../../../actions/axios";
import BgSingle from "../../../utils/background/single";
import {Granted} from "../../../security/granted";

const CompanyShow: React.FC = () => {

    let params: {id: string} = useParams()
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState<string>("info")
    const company: Company = useSelector((state: RootStateOrAny) => state.companies.payload.find((a: Company) => a.id!.toString() == params.id))
    const state = useSelector((state: RootStateOrAny) => state.companies)
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    
    useEffect(() => {
        dispatch(actions.showCompany());
        if (company && params.id){
            dispatch(actions.showCompanySuccess(company));
            dispatch(setPageTitle('Entreprises', company.title))
        }else if(!company && params.id){
            api.show(parseInt(params.id)).then(data => {
                dispatch(actions.showCompanySuccess(data.data))
                dispatch(setPageTitle('Entreprises', data.data.title))
            }).catch(error => dispatch(axiosError(error)))
        }
    }, [params.id])

    if (!state.single) {
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    const NavMenu = () => {
        return <div className={'w-100 position-sticky top-0 d-flex'} style={{zIndex: 1000}}>
            <ul className="nav bg-light nav-tabs flex-grow-1">
                <li className="nav-item">
                    <button onClick={() => setCurrentTab('info')}
                            className={"nav-link text-primary" + (currentTab === 'info' ? ' active' : '')}
                            aria-current="page">
                        <i className={'bi bi-calendar-check'}> </i>
                        <h4 className={'d-none d-md-inline-block'}> Informations</h4>
                    </button>
                </li>
                {Granted(accessRights.LIST_CUSTOMER_FLOW) && false && <li className="nav-item">
                    <button onClick={() => setCurrentTab('flow')}
                            className={"nav-link text-primary" + (currentTab === 'flow' ? ' active' : '')}>
                        <i className={'bi bi-bar-chart'}> </i>
                        <h4 className={'d-none d-md-inline-block'}> Flux client</h4>
                    </button>
                </li>}
            </ul>
            <Link to={'/company'} className={'btn btn-sm btn-light flex-grow-0 d-block d-md-none m-1'}>
                <i className={'bi bi-chevron-left'}> </i> Retour
            </Link>
        </div>
    }

    return <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!params.id ? " d-none d-md-block" : "")}>
            <NavMenu />
            <div className="p-1 p-md-3 d-flex flex-column flex-grow-1">
                {currentTab === 'info' && <CompanyInformation />}
                {/*{currentTab === 'flow' && Granted(accessRights.LIST_CUSTOMER_FLOW) && auth.user.currentSalary.companyGroup.type === types.TYPE_PHARMACY && <CompanyFlow />}*/}
            </div>
        </div>
}

export default CompanyShow;