import {Salary} from "../salary";

export interface Expense {
    readonly id: number,
    title: string,
    comment: string,
    salary: Salary,
    status: number,
    type: number,
    dueDate: string,
    files: ExpenseFiles,
    typeName:string,
    amount: number,
    currency: number,
    shop: string|null,
    clientMeal: boolean,
    vehicle: number,
    vehiclePower: number,
    distance: number,
    readonly createdAt: string,
    readonly createdBy: Salary,
}

export interface ExpenseFile {
    readonly id: number,
    title: string
}

export type ExpenseFiles = ExpenseFile[];

export type Expenses = Expense[];

export interface initialStateInterface {
    payload: Expenses,
    single: Expense|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};