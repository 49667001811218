import React from "react";
import {EventClickArg, EventContentArg} from "@fullcalendar/react";
import format from "../locale";
import {ResourceApi} from "@fullcalendar/resource-common";
import {
    ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED,
    ACCOUNTING_TYPE_WORK_ACCOUNTED,
    ACCOUNTING_TYPE_WORK_UNACCOUNTED,
    getAccountingTypeChoice
} from "../../constants/booking";
import {PresenceEvent} from "../../models/calendar";
import {TYPE_EVENT_EXCEPTION, TYPE_EVENT_RECURRING} from "../../constants/event";


export function renderEvent(arg: EventContentArg) {
    switch (arg.event.extendedProps.eventType) {
        case 'timeClockBookingMissing':
            return <>
                {arg.event.extendedProps.accountingType === ACCOUNTING_TYPE_WORK_ACCOUNTED && <i className={"bi bi-exclamation-circle text-danger"}> </i>}
                {arg.event.extendedProps.accountingType === ACCOUNTING_TYPE_WORK_UNACCOUNTED && <i className={"bi bi-exclamation-circle text-danger"}> </i>}
                {arg.event.extendedProps.accountingType === ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED && <i className={"bi bi-exclamation-circle text-danger"}> </i>}
                {arg.event.extendedProps.accountingType === ACCOUNTING_TYPE_ABSENCE_ACCOUNTED && <i className={"bi bi-check text-success"}> </i>}
                {arg.timeText} - {arg.event.start && arg.event.end && format(new Date(arg.event.end.getTime() - arg.event.start.getTime() - (3600 * 1000)), 'HH:mm')}
                <div className="clearfix"> </div>
                <span className={'form-text text-white'}>
                    {getAccountingTypeChoice(arg.event.extendedProps.accountingType)?.label}
                </span>
            </>
        case 'timeClockBookingAbsent':
            return <>
                {arg.timeText} - {arg.event.extendedProps.absence.typeShortName}
            </>
        case 'timeClockBooking':
        case 'timeClockBookingException':
            return <>
                <b className={'time-caption'}>
                    {arg.event.extendedProps.eventType === 'timeClockBookingException' && <i className={'bi bi-pencil'}> </i>}
                    {arg.event.extendedProps.isAnomaly && <i className={'bi bi-x-circle'}> </i>} {arg.timeText} | {arg.event.start && arg.event.end && format(new Date(arg.event.end.getTime() - arg.event.start.getTime() - (3600 * 1000)), 'HH:mm')}
                </b>
                {arg.event.extendedProps.planned && <>
                    <div className="clearfix"> </div>
                    <span className={'time-caption text-light-s'}> <i className={'bi bi-clock-history'}> </i> {format(new Date(arg.event.extendedProps.planned.start), 'HH:mm')} - {format(new Date(arg.event.extendedProps.planned.end), 'HH:mm')} | {arg.event.extendedProps.planned.timeCaption}</span>
                </>}
                <div className="clearfix"> </div>
                {['timeGridWeek', 'timeGridDay'].includes(arg.view.type) && arg.event.extendedProps.description && <span>
                   <i className={'bi bi-info-circle'}> </i> {arg.event.extendedProps.description}
                </span>}
            </>
        case 'presence':

            let counter = Number(arg.event.extendedProps.children.reduce((accumulator: number, object: PresenceEvent) => {
                return accumulator + object.value;
            }, 0));

            let need = Number(arg.event.extendedProps.need || 0);
            let className = need && need > counter ? 'text-danger' : (need && need < counter ? 'text-primary' : 'text-success')

            return <span className={'text-center w-100 ' + className}>
                <b className={'py-2'}>
                    {counter}
                    {!!need && <>/{need}</>}
                </b>
            </span>
        case 'templateBookingApplied':
        case 'templateBookingAppliedException':
        case 'bookingException':
        case 'booking':
            switch (arg.view.type){
                case 'resourceTimelineDay':
                    return (
                        <span className={'h-100 w-100'} id={arg.event.extendedProps.instanceToken}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitute && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                            </div>
                            <div className="clearfix"> </div>
                            {['templateBookingApplied', 'templateBookingAppliedException'].includes(arg.event.extendedProps.eventType) && <i className={'bi bi-front'}> </i>}
                            {arg.event.extendedProps.eventType === 'templateBookingAppliedException' && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.eventType === 'booking' && arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.eventType === 'booking' && arg.event.extendedProps.recurrence.type !== 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                            <b className={'time-caption'}>{arg.timeText} - {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}</b>
                            {arg.event.extendedProps.localisation && <span className={'float-end rounded-pill shadow-sm px-1 mx-1'}
                                                                           style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i> <i>{arg.event.extendedProps.localisation.title}</i>
                                </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end shadow-sm px-1 mx-1'}
                                                                           style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i> <i>{arg.event.extendedProps.activity.title}</i>
                                </span>}
                        </span>
                    )
                case 'resourceTimelineWeek':
                    return (
                        <span className={'h-100 w-100'} id={arg.event.extendedProps.instanceToken}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitute && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {['templateBookingApplied', 'templateBookingAppliedException'].includes(arg.event.extendedProps.eventType) && <i className={'bi bi-front'}> </i>}
                                    {arg.event.extendedProps.eventType === 'templateBookingAppliedException' && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.eventType === 'bookingException' && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.eventType === 'booking' && arg.event.extendedProps.recurrence.type !== 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                                    <b className={'time-caption'}>{arg.timeText}</b>
                                </div>
                                {arg.event.extendedProps.localisation && <div className={'col-auto rounded-pill shadow-sm px-1 mx-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                </div>}
                                {arg.event.extendedProps.activity && <div className={'col-auto shadow-sm px-1 mx-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                </div>}
                            </div>
                        </span>
                    )
                case 'resourceTimelineMonth':
                    return (
                        <>
                            {['templateBooking','templateBookingException'].includes(arg.event.extendedProps.eventType) && <i className={'bi bi-front'}> </i>}
                            <b className={'w-100 text-center'}>{arg.event.extendedProps.timeCaption}</b>
                        </>
                    )
                case 'timeGridWeek':
                case 'timeGridDay':
                    return <>
                        <b>
                            {arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.isSlotExchange && <i className={'bi bi-shuffle'}> </i>}
                            {arg.event.extendedProps.substitute && <i className={'bi bi-arrow-down-up'}> </i>}
                            {!arg.event.extendedProps.isException && arg.event.extendedProps.recurrence?.type > 1 && <i className={'bi bi-arrow-repeat'}> </i>}
                            {arg.timeText}
                            <span className="clearfix"> </span>
                            {arg.event.start && arg.event.end && <>{format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}</>}
                        </b>
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.absence && <>
                            <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            <div className="clearfix"> </div>
                        </>}
                    </>
                case 'listDay':
                case 'listWeek':
                    return <>
                        <div className={'text-break'}>
                            {arg.event.extendedProps.substitute && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                            {arg.event.extendedProps.isSlotExchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                            <span>
                                {arg.event.extendedProps.salary.title}
                            </span>
                            {arg.event.extendedProps.absence && <>
                                <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            </>}
                            {arg.event.extendedProps.localisation && <span className={'float-end'}>
                                <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end'}>
                                <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            </span>}
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                <i className={'bi bi-shop'}></i> {arg.event.extendedProps?.company?.title}
                            </div>
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                {arg.event.extendedProps.salary.information.job.title}
                            </div>
                        </div>
                    </>
            }
            break;
        case 'event':
        case 'eventException':
            switch (arg.view.type){
                case 'resourceTimelineDay':
                    return (
                        <span className={'h-100 w-100'} id={arg.event.id}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.exchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitution && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                            </div>
                            <div className="clearfix"> </div>
                            {arg.event.extendedProps.eventType === TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.type === TYPE_EVENT_RECURRING && <i className={'bi bi-arrow-repeat'}> </i>}
                            <b className={'time-caption'}>{arg.timeText} - {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}</b>
                            {arg.event.extendedProps.localisation && <span className={'float-end rounded-pill shadow-sm px-1 mx-1'}
                                                                           style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i> <i>{arg.event.extendedProps.localisation.title}</i>
                                </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end shadow-sm px-1 mx-1'}
                                                                           style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i> <i>{arg.event.extendedProps.activity.title}</i>
                                </span>}
                        </span>
                    )
                case 'resourceTimelineWeek':
                    return (
                        <span className={'h-100 w-100'} id={arg.event.id}>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>
                                </div>
                                <div className="col-auto">
                                    {arg.event.extendedProps.exchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                                    {arg.event.extendedProps.substitution && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                                    {arg.event.extendedProps.absence && <span><i className={arg.event.extendedProps.absence.status === 1 ? 'bi bi-clock text-warning' : 'bi bi-check2-circle text-success'}> </i> <b> {arg.event.extendedProps.absence.typeShortName} </b></span>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {arg.event.extendedProps.eventType === TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.type === TYPE_EVENT_RECURRING && <i className={'bi bi-arrow-repeat'}> </i>}
                                    <b className={'time-caption'}>{arg.timeText}</b>
                                </div>
                                {arg.event.extendedProps.localisation && <div className={'col-auto rounded-pill shadow-sm px-1 mx-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                </div>}
                                {arg.event.extendedProps.activity && <div className={'col-auto shadow-sm px-1 mx-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                </div>}
                            </div>
                        </span>
                    )
                case 'resourceTimelineMonth':
                    return (
                        <>
                            <b className={'w-100 text-center'}>{arg.event.extendedProps.timeCaption}</b>
                        </>
                    )
                case 'timeGridWeek':
                case 'timeGridDay':
                    return <>
                        <b>
                            {arg.event.extendedProps.eventType === TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil'}> </i>}
                            {arg.event.extendedProps.type === TYPE_EVENT_RECURRING && <i className={'bi bi-arrow-repeat'}> </i>}
                            {arg.event.extendedProps.exchange && <i className={'bi bi-shuffle'}> </i>}
                            {arg.event.extendedProps.substitution && <i className={'bi bi-arrow-down-up'}> </i>}
                            {arg.timeText}
                            <span className="clearfix"> </span>
                            {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}
                        </b>
                        {arg.event.extendedProps.exchanged && <>{<span id={`title${arg.event.id}`} className={"mb-0"}>{arg.event.title}</span>}</>}
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.absence && <>
                            <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.accountingType !== ACCOUNTING_TYPE_WORK_ACCOUNTED && <>
                            {getAccountingTypeChoice(arg.event.extendedProps.accountingType)?.label}
                        </>}
                    </>
                case 'listDay':
                case 'listWeek':
                    return <>
                        <div className={'text-break'}>
                            {arg.event.extendedProps.substitution && <span><i className={'bi bi-arrow-down-up'}> </i> </span>}
                            {arg.event.extendedProps.exchange && <span><i className={'bi bi-shuffle'}> </i> </span>}
                            <span>
                                {arg.event.extendedProps.salary.title}
                            </span>
                            {arg.event.extendedProps.absence && <>
                                <i className={'bi bi-check-circle text-success'}> </i> {arg.event.extendedProps.absence.typeName}
                            </>}
                            {arg.event.extendedProps.localisation && <span className={'float-end'}>
                                <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            </span>}
                            {arg.event.extendedProps.activity && <span className={'float-end'}>
                                <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            </span>}
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                <i className={'bi bi-shop'}></i> {arg.event.extendedProps?.company?.title}
                            </div>
                            <div className="clearfix"> </div>
                            <div className="text-light-s">
                                {arg.event.extendedProps.salary.information.job.title}
                            </div>
                            {arg.event.extendedProps.accountingType !== ACCOUNTING_TYPE_WORK_ACCOUNTED && <>
                                <div className="form-text">
                                    {getAccountingTypeChoice(arg.event.extendedProps.accountingType)?.label}
                                </div>
                            </>}
                        </div>
                    </>
            }
            break;
        case 'task':
            switch (arg.view.type){
                case 'resourceTimelineDay':
                case 'resourceTimelineWeek':
                    return <div className={'text-nowrap text-truncate'}> <i className={'bi bi-journal'}> </i> <i>{arg.timeText} {arg.event.title}</i></div>
                case 'resourceTimelineMonth':
                    return <div> <i className={'bi bi-journal'}> </i></div>
            }
            break;
        case 'eventOffer':
        case 'bookingOffer':
            switch (arg.view.type){
                case 'timeGridWeek':
                case 'timeGridDay':
                    return <>
                        <b>
                            {arg.timeText}
                            <span className="clearfix"> </span>
                            {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}
                            <span className="clearfix"> </span>
                            <i className={'bi bi-people'}> </i> {arg.event.extendedProps.applicantCount} participants
                        </b>
                        <div className="clearfix"> </div>
                        {arg.event.extendedProps.company && <>
                            <i className={'bi bi-shop'}> </i> {arg.event.extendedProps.company.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.localisation && <>
                            <i className={'bi bi-geo-alt'}> </i> {arg.event.extendedProps.localisation.title}
                            <div className="clearfix"> </div>
                        </>}
                        {arg.event.extendedProps.activity && <>
                            <i className={'bi bi-bookmark'}> </i> {arg.event.extendedProps.activity.title}
                            <div className="clearfix"> </div>
                        </>}
                    </>
                case 'resourceTimelineDay':
                case 'resourceTimelineWeek':
                    return <>
                        <div className="d-flex">
                            <div className={'col'}>
                                <span className={"mb-0"}>{arg.event.title}</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="col">
                                <b className={'time-caption'}>{arg.timeText}</b>
                            </div>
                            {arg.event.extendedProps.localisation && <div className={'col-auto rounded-pill shadow-sm px-1 mx-1'}
                                                                          style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                            </div>}
                            {arg.event.extendedProps.activity && <div className={'col-auto shadow-sm px-1 mx-1'}
                                                                      style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                            </div>}
                        </div>
                    </>
                case 'resourceTimelineMonth':
                    return <div> <i className={'bi bi-plus-circle-dotted'}> </i></div>
            }
            break;
        case 'templateBookingException':
        case 'templateBooking':
            switch (arg.view.type){
                case 'resourceTimelineDay':
                    return <>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span className={"mb-0"}>{arg.event.extendedProps.resource.job.title}</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.recurrence.type > 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                                    <b className={'time-caption'}>{arg.timeText} - {format(new Date(arg.event.end!.getTime() - arg.event.start!.getTime() - (3600 * 1000)), 'HH:mm')}</b>
                                </div>
                                {arg.event.extendedProps.localisation && <div className={'col-auto px-1'}
                                      style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    {<span>
                                        <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                    </span>}
                                    <span>{arg.event.extendedProps.localisation?.title || ''}</span>
                                </div>}
                                {arg.event.extendedProps.activity && <div className={'col-auto px-1'}
                                      style={{backgroundColor: arg.event.extendedProps.activity.color}}>
                                    {<span>
                                        <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                    </span>}
                                    <span>{arg.event.extendedProps.activity.title || ''}</span>
                                </div>}
                            </div>
                        </>
                case 'resourceTimelineWeek':
                    return (
                        <>
                            <div className="d-flex">
                                <div className={'col'}>
                                    <span className={"mb-0"}>{arg.event.extendedProps.resource.job.title}</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    {arg.event.extendedProps.isException && <i className={'bi bi-pencil'}> </i>}
                                    {arg.event.extendedProps.recurrence.type > 1 && !arg.event.extendedProps.isException && <i className={'bi bi-arrow-repeat'}> </i>}
                                    <b className={'time-caption'}>{arg.timeText}</b>
                                </div>
                                {arg.event.extendedProps.localisation && <div className={'col-auto rounded-circle px-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.localisation?.color}}>
                                    <i className={` ${arg.event.extendedProps.localisation.icon} text-white`}> </i>
                                </div>}
                                {arg.event.extendedProps.activity && <div className={'col-auto rounded-circle px-1'}
                                                                              style={{backgroundColor: arg.event.extendedProps.activity?.color}}>
                                    <i className={` ${arg.event.extendedProps.activity.icon} text-white`}> </i>
                                </div>}
                            </div>
                        </>
                    )
            }
            break;
    }
}

export function resourceLaneContent(e: { resource: ResourceApi }){
    if (e.resource.extendedProps.isTopLevel && e.resource.extendedProps.backgroundColor){
        let lane = document.querySelector(`.fc-timeline-lane.fc-resource[data-resource-id="${e.resource.id}"]`) as HTMLTableColElement;
        if (lane){
            lane.style.backgroundColor = e.resource.extendedProps.backgroundColor;
        }
    }
}