import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Salary} from "../../../../models/salary";
import {BookingGroups} from "../../../../models/booking/group";
import * as api from "../../../../adapters/booking/group";
import format from "../../../../utils/locale";
import {openSidebar} from "../../../../actions/rightSidebar";
import {FORM_EDIT_BOOKING_GROUP, FORM_NEW_BOOKING_GROUP, LIST_BOOKING_GROUP} from "../../../../constants/rightSidebar";
import Loader from "../../../../utils/loader";
import {refresh} from "../../../../actions/calendar";

const BookingGroupList:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const salary: Salary = state.rightSidebar.data.salary;
    const [bookingGroups, setBookingGroups] = useState<BookingGroups>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isCanceling, setIsCanceling] = useState<boolean>(false)
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        api.list({salary: salary.id})
            .then(data => {
                setIsLoading(false);
                setBookingGroups(data.data)
            })
    }, [])

    const cancelGroup = (id: number, index: number) => {
        setIsCanceling(true)
        api.cancel(id).then(() => {
            dispatch(refresh());
            let _bookingGroups = [...bookingGroups]
            _bookingGroups.splice(index, 1)
            setBookingGroups([..._bookingGroups])
            setIsCanceling(false);
        })
    }

    return <div className={'w-100 p-1 p-md-3'}>
        <div className="d-flex">
            <div className="flex-grow-1 me-2">
                <div className="input-group w-100 mb-1 mb-md-3">
                <span className="input-group-text bg-light">
                    <i className={'bi bi-search'}> </i>
                </span>
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="flex-grow-0">
                <button className={'btn btn-outline-primary'} onClick={() => dispatch(openSidebar(FORM_NEW_BOOKING_GROUP, {
                    salary: salary,
                    next: LIST_BOOKING_GROUP,
                    data: {salary: salary}
                }))}>
                    <i className={'bi bi-plus'}> </i> Ajouter
                </button>
            </div>
        </div>
        {isLoading && <Loader />}
        <ul className={'list-group list-group-flush'}>
            {bookingGroups.map((group, index) => <li className={'list-group-item'}>
                <div className="w-100 d-flex">
                    <div className="flex-grow-1">
                        <h4 className={'mb-0'}>
                            {group.title}
                        </h4>
                        <div className={'form-text'}>
                            <i className={'bi bi-calendar-check'}> </i> {format(new Date(group.start))} <i className={'mx-2 bi bi-arrow-left-right'}> </i> {format(new Date(group.finish))}
                        </div>
                    </div>
                    <div className="flex-grow-0">
                        <div className="btn-group">
                            <button onClick={() => dispatch(openSidebar(FORM_EDIT_BOOKING_GROUP, {...group,
                                next: LIST_BOOKING_GROUP,
                                data: {salary: salary}}
                            ))} className={'btn btn-outline-primary m-1'}>
                                <i className={"bi bi-pencil"}> </i>
                            </button>
                            <button disabled={isCanceling} onClick={() => cancelGroup(group.id!, index)} className={'btn btn-outline-danger m-1'}>
                                <i className={"bi bi-x-circle"}> </i>
                            </button>
                        </div>

                    </div>
                </div>

            </li>)}
        </ul>

    </div>
}

export default BookingGroupList;