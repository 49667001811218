import React, {useEffect, useState} from "react";
import {Company} from "../../../../models/companies";
import {Offcanvas} from "bootstrap";
import {Salary} from "../../../../models/salary";
import CalendarTemplateShow from "../show";
import CalendarTemplateImport from "../import";
import {EventClickArg} from "@fullcalendar/react";
import CalendarTemplateEventShow from "../event/show";
import CalendarTemplateEventEdit from "../event/edit";
import TemplateEventSubstitute from "../event/substitute";
import CalendarTemplateExport from "../export";


interface InterfaceTemplateShow {
    sidebarType: 'TEMPLATE_SHOW',
    setCalendarData: React.Dispatch<any>,
    template: {
        id: number;
        template: {
            title: string;
        }
        isPreview: number;
        start: string,
        end: string,
        floatingResources: {title: string, id: number}[],
        appliedResources: {title: string, id: number, resource:  {title: string, id: number}, salary: Salary}[]
    }
}

interface InterfaceTemplateImport {
    sidebarType: 'TEMPLATE_IMPORT',
    start: Date,
    end: Date,
    company: Company
}

interface InterfaceTemplateExport {
    sidebarType: 'TEMPLATE_EXPORT',
    start: Date,
    company: Company
}

interface InterfaceTemplateEventShow {
    sidebarType: 'TEMPLATE_EVENT_SHOW',
    arg: EventClickArg,
    calendar: string,
    setCalendarAbsenceSidebarProps: React.Dispatch<any>,
    setCalendarTemplateSidebarProps: React.Dispatch<any>,
    setCalendarSignedSidebarProps: React.Dispatch<any>,
}

interface InterfaceTemplateEventEdit {
    sidebarType: 'TEMPLATE_EVENT_EDIT',
    arg: EventClickArg,
}


interface InterfaceTemplateEventSubstitute {
    sidebarType: 'TEMPLATE_EVENT_SUBSTITUTE',
    arg: EventClickArg,
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarTemplateSidebarInterface = {trigger: number} & (Interface | InterfaceTemplateShow | InterfaceTemplateExport | InterfaceTemplateImport | InterfaceTemplateEventShow | InterfaceTemplateEventEdit | InterfaceTemplateEventSubstitute)

const CalendarTemplateSidebar:React.FC<CalendarTemplateSidebarInterface & {handleEventChange: (e: any) => any}> = (props) => {
    const [offcanvas, setOffcanvas] = useState<Offcanvas>();
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarTemplate')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarTemplate')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }


    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "TEMPLATE_SHOW":
                    return <CalendarTemplateShow close={close} handleEventChange={props.handleEventChange} setCalendarData={props.setCalendarData} _template={props.template} />;
                case "TEMPLATE_IMPORT":
                    return <CalendarTemplateImport company={props.company} start={props.start} end={props.end} handleSubmitSuccess={props.handleEventChange} close={close} />;
                case "TEMPLATE_EXPORT":
                    return <CalendarTemplateExport close={close} company={props.company} start={props.start} />
                case "TEMPLATE_EVENT_SUBSTITUTE":
                    return <TemplateEventSubstitute arg={props.arg} handleSubmitSuccess={props.handleEventChange} close={close} />
                case "TEMPLATE_EVENT_EDIT":
                    return <CalendarTemplateEventEdit arg={props.arg} handleEventChange={props.handleEventChange} close={close} />
                case "TEMPLATE_EVENT_SHOW":
                    return <CalendarTemplateEventShow arg={props.arg} handleEventChange={props.handleEventChange} calendar={props.calendar} setCalendarAbsenceSidebarProps={props.setCalendarAbsenceSidebarProps} setCalendarTemplateSidebarProps={props.setCalendarTemplateSidebarProps} close={close} setCalendarSignedSidebarProps={props.setCalendarSignedSidebarProps} />
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarTemplate" aria-labelledby="offcanvasCalendarTemplateLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: CalendarTemplateSidebarInterface & {handleEventChange: (e: any) => any}, nextProps: CalendarTemplateSidebarInterface & {handleEventChange: (e: any) => any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarTemplateSidebar, areEqual);