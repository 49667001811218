import {post, get} from "../../xhr";
import * as models from "../../../models/template";

export function edit(requestData: models.Resource, templateId: number, id: number){
    return post('template/'+ templateId +'/resource/edit/' + id, requestData);
}

export function create(requestData: any, id: number){
    return post('template/'+ id +'/resource/create',  requestData);
}

export function cancel(templateId: number, id: number){
    return get('template/'+ templateId +'/resource/cancel/' + id);
}

export function salaries(templateId: number){
    return get('template/'+ templateId +'/resource/salaries/');
}

export function addSalary(templateId: number, salaryId: number){
    return get(`template/${templateId}/resource/salary/${salaryId}/add`);
}

export function removeSalary(templateId: number, salaryId: number){
    return get(`template/${templateId}/resource/salary/${salaryId}/remove`);
}

export function remove(templateId: number, resourceId: number){
    return get(`template/${templateId}/resource/${resourceId}/remove`);
}