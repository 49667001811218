import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {setPageTitle} from "../../../actions/header";

const SponsorshipShow:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)

    useEffect(() => {
        dispatch(setPageTitle('Parrainage'))
    }, [])

    return <>
        <div className="container mx-auto my-3">
            <div className="col-12 mb-3 mb-md-5">
                <div className="col-12 text-center mb-3 mb-md-5">
                    <h1 className={'text-uppercase text-dark'}>
                        Gagnez deux mois offerts ! <i className={"bi bi-gift text-blue-s"}></i>
                    </h1>
                    <p className="lead text-blue-s">
                        + Un mois offert pour le parrainé
                    </p>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="d-flex align-items-center h-100">
                            <div className="flex-grow-1 bg-blue-s text-white text-center p-2 rounded-2 shadow-sm h-100 d-flex align-items-center">
                                <div>

                                <i className={'bi bi-share display-1'}></i>
                                <div className="clearfix"> </div>
                                <h4>
                                    Partagez votre code
                                </h4>

                                </div>
                            </div>
                            <div className="flex-grow-0 mx-1">
                                <i className={'bi bi-arrow-right text-blue-s fs-1'}></i>
                            </div>
                            <div className="flex-grow-1 bg-blue-s text-white text-center p-2 rounded-2 shadow-sm h-100 d-flex align-items-center">
                                <div>

                                <i className={'bi bi-person-check display-1'}></i>
                                <div className="clearfix"> </div>
                                <h4>
                                    Générez un abonnement
                                </h4>

                                </div>
                            </div>
                            <div className="flex-grow-0 mx-1">
                                <i className={'bi bi-arrow-right text-blue-s fs-1'}></i>
                            </div>
                            <div className="flex-grow-1 bg-blue-s text-white text-center p-2 rounded-2 shadow-sm h-100 d-flex align-items-center">
                                <div>

                                <i className={'bi bi-gift display-1'}></i>
                                <div className="clearfix"> </div>
                                <h4>
                                    Gagnez 2 mois offerts !
                                </h4>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <h4 className={'text-blue-s'}>
                            Quels sont les avantages ?
                        </h4>
                        <p>
                            Bénéficiez de <b>2 mois offerts</b> sur SIVAN si la personne parrainée adhère à SIVAN.
                            <span className="clearfix"> </span>
                            La personne parrainée quant à elle, bénéficiera <b>d’un mois offert</b> sur SIVAN.
                        </p>
                        <h4 className={'text-blue-s'}>
                            Comment parrainer quelqu’un ?
                        </h4>
                        <p>
                            <b>Partagez votre code ci-dessous</b> à la personne que vous souhaitez parrainer.
                            <span className="clearfix"> </span>
                            Elle n’aura plus qu’à renseigner votre code lors de la demande de démo SIVAN sur le site <b>sivan.io</b>.
                            <span className="clearfix"> </span>
                            Le parrainage sera immédiatement pris en compte et validé dès la signature.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-3 text-center">
                <div className="bg-blue-s shadow-sm rounded-2 text-white">
                    <div className={'p-5'}>
                        <h2>
                            Mon code de parainnage
                        </h2>
                        <div className="w-auto mx-auto bg-light rounded-2 p-3 text-blue-s">
                            <h1>
                                SIV{state.auth.user.id}
                            </h1>
                        </div>
                    </div>
                    <div className="row d-none">
                        <div className="col-12 text-center">
                            <h4>
                                <i className={'bi bi-share'}></i> Partager
                            </h4>
                        </div>
                        <div className="col-auto mx-auto">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="p-3 text-center">
                                        <a className={'btn bg-white text-blue-s text-nowrap'} href="sms:?&body=Share%20this%20message%20on%20sms">
                                            <i className={"bi bi-whatsapp"}> </i> Par message
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="p-3 text-center">
                                            <a className={'btn bg-white text-blue-s text-nowrap'} href={`mailto:?subject=Mon code de parainnage pour Sivan.io&body=Tu peux gagner un mois offert sur sivan grâce à mon code de parrainage : SIV${state.auth.user.id} ou via ce lien : https://sivan.io/demo.php?code=SIV${state.auth.user.id}. Tu peux jeter un oeuil au site de Sivan pour plus d'information : https;//sivan.io`} title="Share by Email">
                                                <i className={"bi bi-envelope"}> </i> Par Mail
                                            </a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="p-3 text-center">
                                        <button onClick={(e) => {
                                            /* Get the text field */
                                            let copyText = document.createElement("input");
                                            copyText.value = `https://sivan.io/demo.php?code=SIV${state.auth.user.id}`
                                            copyText.hidden = true;

                                            /* Select the text field */
                                            copyText.select();
                                            copyText.setSelectionRange(0, 99999); /* For mobile devices */

                                            /* Copy the text inside the text field */
                                            navigator.clipboard.writeText(copyText.value);

                                            alert("Lien copié")

                                        }} className={'btn bg-white text-blue-s text-nowrap'}>
                                            <i className={"bi bi-link"}> </i> Copier le lien
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default SponsorshipShow;