import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { HELP_PRODUCTS_SHEETS } from "../helps/helpsData"

import { setPageTitle } from "../../actions/header";

const Help: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle("Aide"))
    }, [])

    return (
        <div className={'container text-center'}>
            <h4 className={'mt-5'}>
                Vous avez besoin d’aide sur une fonctionnalité ? Cherchez la réponse dans la section ci-dessous
            </h4>

            <div className="grid my-5">
                <div className="accordion accordion-flush" id="helpsAccordion">
                    {HELP_PRODUCTS_SHEETS.map(item => (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.id}`} aria-expanded="false" aria-controls={`#collapse${item.id}`}>
                                {item?.title}
                            </button>
                            </h2>
                            <div id={`collapse${item.id}`} className="accordion-collapse collapse hidden" aria-labelledby="headingOne" data-bs-parent="#helpsAccordion">
                            <div className="accordion-body">
                                <div className="grid row my-2">
                                    {item?.content?.map((content, key) => (
                                        <div key={key} className={`col-4 ${key > 2 ? 'mt-5' : ''}`}>
                                            <div className="col">
                                                <p className="mb-0">
                                                    {content?.text}
                                                </p>
                                            </div>

                                            <div className="col">
                                                {content?.img &&
                                                    <img
                                                    className="w-100 mt-3"
                                                    src={process.env.PUBLIC_URL + content?.img}
                                                    alt={item?.title}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <p>
                Si vous n’avez pas trouvé la solution, faites nous parvenir vos demandes à <span className="text-primary">contact@sivan-gestion.com</span>
            </p>
        </div>
    )
}

export default Help;