import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/salary";
import * as settingsApi from "../../../adapters/salary/settings";
import * as accessRights from "../../../constants/accessRight";
import format from "../../../utils/locale";
import {useDispatch} from "react-redux";
import {FEED_LIST} from "../../../constants/rightSidebar";
import {openSidebar} from "../../../actions/rightSidebar";
import {axiosError} from "../../../actions/axios";
import {Granted} from "../../../security/granted";

const DashboardFeed:React.FC = () => {

    const dispatch = useDispatch();
    const [feeds, setFeeds] = useState<{type: "birthdate"|"trial"|"seniority"|"start"|"end"|"endActivated", initials: string, dueDate: string, title: string, value: string, salaryId: number}[]>([]);
    let start = new Date();
    let end = new Date((new Date()).setDate((new Date().getDate() + 15)));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const controller = new AbortController();

        api.feed({start: format(start, 'uuuu-MM-dd'), end: format(end, 'uuuu-MM-dd')}, controller.signal).then(data => {
            setFeeds(data.data)
            setIsLoading(false)
        }).catch(error => dispatch(axiosError(error)))

        return () => controller.abort();
    }, [])

    const removeFeed = (index: number) => {
        let _feeds = [...feeds]
        _feeds.splice(index, 1)
        setFeeds([..._feeds]);
    }

    const getIcon = (type: string) => {
        switch (type){
            case 'trial':
                return <span className={'p-1 px-2 bg-light rounded-pill border border-success border-1 text-success shadow-sm'}><i className={'bi bi-check-lg'}> </i></span>;
            case 'birthday':
                return <span className={'p-1 px-2 bg-light rounded-pill border border-success border-1 text-success shadow-sm'}><i className={'bi bi-gift'}> </i></span>;
            case 'seniority':
                return <span className={'p-1 px-2 bg-light rounded-pill border border-primary border-1 text-primary shadow-sm'}><i className={'bi bi-calendar-plus'}> </i></span>;
            case 'start':
                return <span className={'p-1 px-2 bg-light rounded-pill border border-success border-1 text-success shadow-sm'}><i className={'bi bi-person-check'}> </i></span>;
            case 'end':
                return <span className={'p-1 px-2 bg-light rounded-pill border border-danger border-1 text-danger shadow-sm'}><i className={'bi bi-person-dash'}> </i></span>;
            case 'endActivated':
                return <span className={'p-1 px-2 bg-light rounded-pill border border-danger border-1 text-danger shadow-sm'}><i className={'bi bi-exclamation-circle'}> </i></span>;
        }
    }

    return <>
        <div className="d-flex w-100 flex-grow-0">
            <div className="col">
                <span className={'h4 text-primary'}>
                    <i className={'bi bi-journal-text'}> </i>
                    Actualités
                </span>
                <div className="clearfix"> </div>
                <span className="form-text">
                    +15 jours
                </span>
            </div>
            <div className="col-auto">
                <span className={'h4 text-primary'}>
                    {!isLoading && feeds.length}
                </span>
            </div>
        </div>
        <div className="clearfix"> </div>
            {isLoading ?
                <>
                    <div className="d-flex w-100 flex-grow-1 text-center align-items-center">
                        <div style={{width: "4rem", height:"4rem"}} className="spinner-border mx-auto text-center text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </>
                : (feeds.length ?
                    <ul className={'list-group list-group-flush flex-grow-1 h-100 overflow-auto'}>
                        {feeds.slice(0, 8).map((feed, index) => <li className={'list-group-item ps-0'}>
                            <div className={'d-flex align-items-center'}>
                                {getIcon(feed.type)}
                                <span className={'ms-2 form-text'}>
                                    {feed.title}
                                    <span className="clearfix"> </span>
                                    {feed.value}
                                </span>
                                {feed.type === "endActivated" && Granted(accessRights.EDIT_SALARY, feed.salaryId) && <span className="ms-2">
                                    <button className={'btn btn-sm'} onClick={() => settingsApi.disable(feed.salaryId).then(() => removeFeed(index))}>
                                        <i className={'bi bi-dash-circle text-danger'}></i>
                                    </button>
                                </span>}
                            </div>
                        </li>)}
                    </ul> : <> <i className={'bi bi-search'}> </i> <span>Aucune actualité</span> </>)}
            {!isLoading && feeds.length > 8 && <button
                onClick={() => dispatch(openSidebar(FEED_LIST, {payload: feeds}))}
                className={'btn btn-outline-primary w-100'}> <i className={'bi bi-plus-circle'}> </i> Voir plus </button>}
    </>
}

export default DashboardFeed;