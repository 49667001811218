export const ADD_TASK = 'ADD_TASK';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';

export const EDIT_TASK = 'EDIT_TASK';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAILURE = 'EDIT_TASK_FAILURE';
export const FETCH_TASKS = 'FETCH_TASKS';

export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';
export const SHOW_TASK = 'SHOW_TASK';

export const SHOW_TASK_SUCCESS = 'SHOW_TASK_SUCCESS';
export const SHOW_TASK_FAILURE = 'SHOW_TASK_FAILURE';
export const EDIT_TASK_STATUS_SUCCESS = 'EDIT_TASK_STATUS_SUCCESS';

export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';


export const STATUS_PENDING = 1;
export const STATUS_VALIDATED = 2;
export const STATUS_STUCK = 3;

export function getTaskStatusChoices(){
    return [
        {label: "En attente", value: STATUS_PENDING, icon: "bi bi-clock-history text-warning"},
        {label: "Validé", value: STATUS_VALIDATED, icon: "bi bi-check2-circle text-success"},
        {label: "Bloqué", value: STATUS_STUCK, icon: "bi bi-exclamation-circle text-danger"},
    ]
}

export function getTaskStatusChoice(value?: number){
    return getTaskStatusChoices().find(s => s.value === value) || getTaskStatusChoices()[0]
}