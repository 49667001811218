import React from "react";
import {FormikErrors, FormikTouched, getIn} from "formik";

const FormError = (props: {errors: FormikErrors<any>, touched: FormikTouched<any>, field: string}) => {
    const {errors, touched, field} = props;

    let touched_bool = getIn(touched, field)
    let error_str = getIn(errors, field)

    return touched_bool && error_str ? <>
        <div className="clearfix"> </div>
        <span className={'form-text text-danger'}>
            <i className={'bi bi-exclamation-circle'}> </i>
            {error_str}
        </span></> : <></>
}

export default FormError;