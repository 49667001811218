import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { FORM_NEW_TEAM } from "../../../constants/rightSidebar";
import { Team } from "../../../models/team";

import { openSidebar } from "../../../actions/rightSidebar";

import LoaderList from "../../../utils/loaderList";

interface IProps {
    isLoading: boolean;
    list: Team[];
    query: string;
    setQuery: (text: string) => void;
}

const TeamList:React.FC<IProps> = ({ isLoading, list, query, setQuery }) => {
    const dispatch = useDispatch()
    const params: { id: string } = useParams();

    return (
        <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white border-end overflow-auto top-0 sticky-top"}>
            <div className="w-100">
                <div className="sticky-top bg-light d-flex flex-column">
                    <div className="col">
                        <button onClick={() => dispatch(openSidebar(FORM_NEW_TEAM))} className={'btn btn-outline-primary w-100'}>
                            <i className={'bi bi-plus'}> </i> Ajouter
                        </button>
                    </div>
                    <div className="col p-0">
                        <div className="input-group">
                                <span className="input-group-text bg-white border-end border-0" id="search-addon">
                                    {isLoading ? <div className="spinner-grow spinner-grow-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : <i className="bi bi-search"> </i>}
                                </span>
                            <input type="search" onChange={(e) => {
                                setQuery(e.target.value)
                            }} className="form-control border-0 bg-white" placeholder="Rechercher..." aria-describedby="search-addon" />
                        </div>
                    </div>
                </div>

                {list.length ? list.filter((t: Team) => !query || t.title.toLowerCase().includes(query.toLowerCase())).map((item: Team, index: number) => ([
                        <Link to={"/team/" + item.id} key={index} className={"px-3 list-group-item list-group-item-action" + (params?.id && parseInt(params.id) === item.id ? " active" : '')}>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    <h4 className={'mb-0'}> {item.title}</h4>
                                    <div className="clearfix"> </div>
                                    <p className={'mb-0'}>
                                        <i className={'bi bi-people'}> </i> {item.salaries.length}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    ]))
                    :
                    !isLoading ? <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
                        Aucune équipe
                    </button> : ''}
                {isLoading && !list.length && <LoaderList title={true} listLength={50} description={true} leftCircle={true} />}
                {isLoading && !!list.length && <LoaderList title={true} listLength={1} description={true} leftCircle={true} />}
            </div>
        </div>
    );
}

export default TeamList;