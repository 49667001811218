import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../../actions/salary";
import * as api from "../../../../adapters/salary/information";
import * as Yup from "yup";
import {useFormik} from "formik";
import {closeSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {showAlertDanger, showAlertSuccess} from "../../../../actions/alert";
import * as types from "../../../../constants/salary";
import DatePicker from "react-datepicker";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import validationClass from "../../../../utils/validationClass";
import FormError from "../../../../utils/formError";
import DateCustomInput from "../../../../utils/customInput";
import {ObjectSchema} from "yup";
import {selectAccount} from "../../../../actions/auth";

export const SalaryInformationEdit: React.FC = () => {

    const dispatch = useDispatch();
    const salary = useSelector((state: RootStateOrAny) => state.rightSidebar.data.salary)
    const auth = useSelector((state: RootStateOrAny) => state.auth)

    let startDate = new Date(salary.information?.startDate);
    startDate.setHours(0, 0, 0, 0);

    let endDate = salary.information?.endDate ? new Date(salary.information?.endDate) : new Date();
    endDate.setHours(0,0,0,0)

    const [initialValues, setInitialValues] = useState<any>({
        job: salary.information?.job.id,
        contract: salary.information?.contract,
        startDate: startDate,
        endDate: salary.information?.endDate ? endDate : null,
        flatRate: salary.information?.flatRate,
        dayAnnualFlatRate: salary.information?.dayAnnualFlatRate,
        hourlyRate: salary.information?.hourlyRate,
        socialSecurityNumber: salary.information?.socialSecurityNumber,
        nightWorker: salary.information?.nightWorker,
        absenceBasis: salary.information?.absenceBasis,
        absenceBasisN1: salary.information?.absenceBasisN1,
        overtimeBasis: salary.information?.overtimeBasis,
    })

    const [validationSchema, setValidationSchema] = useState<ObjectSchema<any>>(Yup.object().shape({
        job: Yup.string().required('Veuillez préciser un métier'),
        contract: Yup.number().required('Veuillez préciser un contract'),
        startDate: Yup.string().required('Veuillez préciser une date de début de contract'),
        endDate: Yup.string().nullable().optional(),
        flatRate: Yup.number().required('Veuillez choisir un métier'),
        dayAnnualFlatRate: Yup.number().nullable().optional(),
        hourlyRate: Yup.number().nullable().optional(),
        socialSecurityNumber: Yup.number().nullable().optional(),
        nightWorker: Yup.boolean(),
        absenceBasis: Yup.number().nullable().optional(),
        absenceBasisN1: Yup.number().nullable().optional(),
        overtimeBasis: Yup.number().nullable().optional(),
    }));

    useEffect(() => {
        let _initialValues = {...initialValues}
        let _validationSchema = {...validationSchema.fields}

        switch (salary.information.flatRate){
            case types.FLAT_RATE_HC:
                _initialValues.contractWorkingTime = salary.information.contractWorkingTime;
                _validationSchema.contractWorkingTime = Yup.number().required();
                break;
            case types.FLAT_RATE_DAILY:
                _initialValues.dayAnnualFlatRate = salary.information.dayAnnualFlatRate;
                _validationSchema.dayAnnualFlatRate = Yup.number().required();
                break;
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }, [])


    const handleFlatRateChange = (type: number) => {
        let _initialValues = {...initialValues}
        let _validationSchema = {...validationSchema.fields}

        _initialValues.flatRate = type;

        switch (type){
            case types.FLAT_RATE_HC:
                _initialValues.contractWorkingTime = salary.information.contractWorkingTime;
                _validationSchema.contractWorkingTime = Yup.number().required();
                delete _validationSchema.dayAnnualFlatRate;
                break;
            case types.FLAT_RATE_DAILY:
                _initialValues.dayAnnualFlatRate = salary.information.dayAnnualFlatRate;
                _validationSchema.dayAnnualFlatRate = Yup.number().required();
                delete _validationSchema.contractWorkingTime;
                break;
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            // same shape as initial values
            dispatch(submitSidebar());
            api.edit(values, salary.id).then(data => {
                dispatch(actions.editSalarySuccess(data.data));
                if (data.data.id === auth.user.currentSalary.id){                    
                    dispatch(selectAccount(data.data))
                }
                dispatch(showAlertSuccess('Les informations de ' + data.data.title + ' ont bien été mises à jour !'))
                dispatch(closeSidebar());
            }).catch(error => {
                dispatch(actions.addSalaryInformationFailure(error.data.response.message))
                dispatch(showAlertDanger('Un erreur est survenue'))
            })
        }
    })

    return (
        <form className={'p-3 h-100 d-flex flex-column'} onSubmit={formik.handleSubmit}>
            <div className="flex-grow-1 mb-3">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            label={'Métier'}
                            fetchEntity={"job"}
                            onChange={(choice) => {
                                formik.setFieldValue('job', choice?.value)
                            }}
                            required={true}
                            className={validationClass(formik.errors, formik.touched, 'job')}
                            value={{label: salary.information.job.title, value: salary.information.job.id}}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'job'} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            value={types.getContractChoices().find(option => option.value === salary.information.contract)}
                            options={types.getContractChoices()}
                            onChange={(choice) => formik.setFieldValue('contract', choice?.value)}
                            required={true}
                            className={validationClass(formik.errors, formik.touched, 'contract')}
                            label={'Contrat'}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'contract'} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <DatePicker
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'startDate')}
                            customInput={<DateCustomInput label={'Début de contrat'} />}
                            onChange={(date: Date) => {
                                formik.setFieldValue('startDate', date)
                            }}
                            dateFormat={"dd MMM uuuu"}
                            selected={formik.values.startDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'startDate'} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <DatePicker
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'endDate')}
                            customInput={<DateCustomInput label={'Fin de contrat'} />}
                            onChange={(date: Date) => {
                                formik.setFieldValue('endDate', date)
                            }}
                            isClearable={true}
                            startDate={formik.values.startDate}
                            dateFormat={"dd MMM uuuu"}
                            selected={formik.values.endDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'endDate'} />
                    </div>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            label={'Type de facturation'}
                            onChange={(choice) => {
                                handleFlatRateChange(Number(choice!.value))
                            }}
                            className={validationClass(formik.errors, formik.touched, 'flatRate')}
                            value={types.getFlatRateChoices().find(option => option.value === salary.information.flatRate)}
                            options={types.getFlatRateChoices()}
                            required={true}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'flatRate'} />
                    </div>
                    {types.FLAT_RATE_HC == formik.values.flatRate &&
                    <div className="col-12 mb-3">
                        <div className="form-floating">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                step={'any'}
                                defaultValue={initialValues.contractWorkingTime}
                                name={`contractWorkingTime`}
                                type={'number'}
                                className={'form-control ' + validationClass(formik.errors,formik.touched,'contractWorkingTime')}
                            />
                            <label htmlFor="contractWorkingTime">
                                Heures contractuelles
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'contractWorkingTime'} />
                    </div>}
                    {types.FLAT_RATE_DAILY == formik.values.flatRate &&
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue={initialValues.dayAnnualFlatRate}
                                type={'number'}
                                className={'form-control ' + validationClass(formik.errors,formik.touched,'dayAnnualFlatRate')}
                                name={`dayAnnualFlatRate`}
                            />
                            <label htmlFor="dayAnnualFlatRate">
                                Nombre de jours par an
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'dayAnnualFlatRate'} />
                    </div>}
                    {(types.FLAT_RATE_HC == formik.values.flatRate || types.FLAT_RATE_ELSE == formik.values.flatRate) &&
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue={initialValues.hourlyRate}
                                type={'number'}
                                step={'any'}
                                className={'form-control ' + validationClass(formik.errors,formik.touched,'hourlyRate')}
                                name={`hourlyRate`}
                            />
                            <label htmlFor="hourlyRate">
                                Taux horaire brut
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'hourlyRate'} />
                    </div>}
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue={initialValues.socialSecurityNumber}
                                type={'text'}
                                className={'form-control ' + validationClass(formik.errors,formik.touched,'socialSecurityNumber')}
                                name={`socialSecurityNumber`}
                            />
                            <label htmlFor="socialSecurityNumber">
                                N° de sécurité sociale
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'socialSecurityNumber'} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-check form-switch">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue={initialValues.nightWorker}
                                type={'checkbox'}
                                className={'form-check-input'}
                                defaultChecked={false}
                                name={`nightWorker`}
                            />
                            <label className="form-check-label" htmlFor={`nightWorker`}>
                                Travailleur de nuit
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'nightWorker'} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue={initialValues.absenceBasis}
                                type={'number'}
                                step={'any'}
                                className={'form-control ' + validationClass(formik.errors,formik.touched,'absenceBasis')}
                                name={`absenceBasis`}
                            />
                            <label htmlFor="absenceBasis">
                                Base d'absence
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'absenceBasis'} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue={initialValues.absenceBasisN1}
                                type={'number'}
                                step={'any'}
                                className={'form-control ' + validationClass(formik.errors,formik.touched,'absenceBasisN1')}
                                name={`absenceBasisN1`}
                            />
                            <label htmlFor="absenceBasisN1">
                                Base d'absence N-1
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'absenceBasisN1'} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue={initialValues.overtimeBasis}
                                type={'number'}
                                step={'any'}
                                className={'form-control ' + validationClass(formik.errors,formik.touched,'overtimeBasis')}
                                name={`overtimeBasis`}
                            />
                            <label htmlFor="overtimeBasis">
                                Base d'heures supplémentaires
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'overtimeBasis'} />
                    </div>
                </div>
            </div>
            <div className="flex-grow-0">
                <button className='btn btn-outline-primary w-100' type="submit">
                    <i className={'bi bi-check'}> </i> Valider
                </button>
            </div>
        </form>
    )
}

export default SalaryInformationEdit;