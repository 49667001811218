import * as types from "../../../constants/task/calendar";
import * as models from "../../../models/task/calendar";
import {EventClickArg} from "@fullcalendar/react";
import {ResourceLabelContentArg} from "../../../models/task/calendar";
import React from "react";

export const fetchEvents = () => {
    return {
        type: types.FETCH_EVENTS,
    }
}

export const fetchEventsSuccess = (events: any[]) => {
    return {
        type: types.FETCH_EVENTS_SUCCESS,
        events: events,
    }
}

export const fetchResources = () => {
    return {
        type: types.FETCH_RESOURCES,
    }
}

export const fetchResourcesSuccess = (resources: any[]) => {
    return {
        type: types.FETCH_RESOURCES_SUCCESS,
        resources: resources
    }
}

export const fetchTitleSuccess = (title: string) => {
    return {
        type: types.FETCH_TITLE_SUCCESS,
        title: title
    }
}

export const showEventPop = (eventClickArg: EventClickArg) => {
    return {
        type: types.SHOW_EVENT_POP,
        eventClickArg: eventClickArg
    }
}

export const hideEventPop = () => {
    return {
        type: types.HIDE_EVENT_POP,
    }
}

export const showResourceActions = (resourceLabelContentArg: ResourceLabelContentArg, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return {
        type: types.SHOW_RESOURCE_ACTIONS,
        resourceLabelContentArg: resourceLabelContentArg,
        resourceActionsButton: e

    }
}

export const hideResourceActions = () => {
    return {
        type: types.HIDE_RESOURCE_ACTIONS,
    }
}

export const cancelEvent = (e: EventClickArg) => {
    return {
        type: types.CANCEL_EVENT,
        cancelEvent: e
    }
}

export const cancelEvents = (e: EventClickArg) => {
    return {
        type: types.CANCEL_EVENTS,
        cancelEvent: e
    }
}

export const refresh = () => {
    return {
        type: types.REFRESH
    }
}

export const updateEventStatus = () => {
    return {
        type: types.REFRESH
    }
}
