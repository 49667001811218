import React, {SyntheticEvent} from "react";

const BookingGroupEditHistoryButton: React.FC<{onClick?: (e: SyntheticEvent) => void, count?: number}> = (props) => {

    const {onClick, count} = props

    return <button type="button" onClick={onClick} className="btn btn-sm btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#bookingHistoryModal">
        <i className={'bi bi-clock-history'}> </i> {count}
    </button>
}

export default BookingGroupEditHistoryButton;