import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/calendar";
import format from "../../../utils/locale";
import * as calendarTypes from "../../../constants/calendar"
import DashboardTimeClockMissing from "./missing";
import DashboardTimeClockLate from "./late";
import TimeClockDashboardList from "./list";
import {Offcanvas} from "bootstrap";

const DashboardTimeClock: React.FC = () => {

    const [signed, setSigned]  = useState<any[]>([])
    const [loading, setIsLoading] = useState<boolean>(true)
    const [origin, setOrigin] = useState('');
    let today = new Date();
    let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))

    useEffect(() => {
        fetchSigned();
    }, [])

    return <>
        <div className="mb-3 col-12 col-md-6 col-lg">
            <DashboardTimeClockMissing loading={loading} signed={signed} handleClick={handleClick} />
        </div>
        <div className="mb-3 col-12 col-md-6 col-lg">
            <DashboardTimeClockLate loading={loading} signed={signed} handleClick={handleClick} />
        </div>
        <TimeClockDashboardList loading={loading} signed={signed} origin={origin} refresh={refresh} />
    </>

    function fetchSigned(){
        setIsLoading(true);
        api.test({
            start: format(today, 'uuuu-MM-dd'),
            end: format(tomorrow, 'uuuu-MM-dd'),
            calendar: calendarTypes.CALENDAR_SIGNED_REPORTING
        }).then((resp) => {
            setSigned(resp.data.signed)
            setIsLoading(false);
        })
    }

    function refresh(salaryId: number){
        api.test({
            start: format(today, 'uuuu-MM-dd'),
            end: format(tomorrow, 'uuuu-MM-dd'),
            calendar: calendarTypes.CALENDAR_SIGNED_REPORTING,
            options: {
                salary: salaryId
            }
        }).then((resp) => {
            setSigned([...signed.filter(s => s.salary.id !== salaryId), ...resp.data.signed])
        })
    }

    function handleClick(origin: string){
        setOrigin(origin)
        let el = document.getElementById('offcanvasTimeClockDashboardList')
        if (el){
            let offcanvas = new Offcanvas(el);
            offcanvas.toggle(el);
        }
    }
}

export default DashboardTimeClock;