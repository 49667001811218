import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {setPageTitle} from "../../../../actions/header";
import LoaderList from "../../../../utils/loaderList";
import * as api from "../../../../adapters/user";
import {axiosError} from "../../../../actions/axios";
import {User, Users} from "../../../../models/auth";
import * as actions from "../../../../actions/admin/user";
import {PAGINATION} from "../../../../constants/global";

const UserList: React.FC = () => {

    const [role, setRole] = useState<string>("ROLE_ADMIN")
    const [offset, setOffset] = useState<number>(0);
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [query, setQuery] = useState<string|undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFiltering, setFiltering] = useState<boolean>(false);
    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const users: Users = useSelector((state: RootStateOrAny) => state.user.payload);

    useEffect(() => {
        dispatch(setPageTitle('Admin', 'utilisateurs'))
        let abortController = new AbortController();

        setIsLoading(true);
        api.list({query: query, offset: 0, role: role}, abortController.signal).then(data => {            
            setIsLast(data.data.length < PAGINATION);
            setIsLoading(false);
            setOffset( 1);
            dispatch(actions.fetchUsersSuccess(data.data));
        }).catch(error => dispatch(axiosError(error)))

        return () => abortController.abort();
    }, [])

    useEffect(() => {
        let controller = new AbortController();
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setFiltering(true);
                setOffset(0)
                api.list({query: query, offset: 0, role: role}, controller.signal).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchUsersSuccess(data.data));
                    setFiltering(false);
                    setOffset( 1);
                }).catch(error => dispatch(axiosError(error)))
            }
        }, 1000);

        return () => {
            clearTimeout(timer)
            controller.abort()
        }
    }, [query])

    useEffect(() => {
        const controller = new AbortController();

        if(scrollBottom && offset > 0 && !isLast){
            setIsLoading(true)
            api.list({query: query, offset: offset, role: role}, controller.signal).then(data => {
                setIsLast(data.data.length < PAGINATION);
                dispatch(actions.fetchUsersSuccess([...users, ...data.data]));
                setIsLoading(false)
                setOffset(prevState => prevState + 1)
                setScrollBottom(false)
            }).catch(error => dispatch(axiosError(error)))
        }

        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)

        return () => {
            container.removeEventListener("scroll", onScrollBottom)
            controller.abort();
        }

    }, [scrollBottom])

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    return (
        <>
            <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white border-end overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
                <div className="w-100">
                    <div className="sticky-top bg-light d-flex flex-column">
                        <div className="col p-0">
                            <div className="input-group">
                                    <button disabled={isFiltering} data-bs-toggle="modal" data-bs-target="#filterModal" className="input-group-text bg-white border-end border-0" id="search-addon">
                                        {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : <i className="bi bi-filter"> </i>}
                                    </button>
                                <input type="search" onChange={(e) => {
                                    setQuery(e.target.value)
                                }} className="form-control border-0 bg-white" placeholder="Rechercher..." aria-describedby="search-addon" />
                                <Link to={"/admin/register"} className={"btn btn-outline-primary"}>
                                    <i className="bi bi-plus-circle"> </i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {!!users.length ? users.map((user: User, index: number) => ([
                            <Link to={"/admin/user/" + user.id} key={index} className={"px-3 text-truncate list-group-item list-group-item-action" + (Number(params.id) === user.id ? " active" : '')}>
                                <div className="d-flex align-items-center">
                                    <div className="col">
                                        <h4 className={'mb-0'}> {user.information?.firstname} {user.information?.lastname} </h4>
                                        <div className="clearfix"> </div>
                                        <p className={'mb-0 text-light-s'}>
                                            {user.email}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        ]))
                        :
                        !isLoading ? <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
                            Aucun collaborateur
                        </button> : ''}
                    {!isLoading && !isLast && users.length && <button onClick={() => setScrollBottom(true)} className={'list-group-item text-primary text-center list-group-item-action'}>
                        <i className={'bi bi-arrow-repeat'}> </i> Charger plus
                    </button>}
                    {isLoading && !users.length && <LoaderList title={true} listLength={50} description={true} leftCircle={true} />}
                    {isLoading && !!users.length && <LoaderList title={true} listLength={1} description={true} leftCircle={true} />}
                </div>
            </div>
            <div className="modal fade" id="filterModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Filtrer les utilisateurs</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 mb-3">
                                <label htmlFor="">
                                    Type d'utilisateurs
                                </label>
                                <div className="clearfix"> </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                           id="inlineRadio1" value="option1" />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Clients</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                           id="inlineRadio2" value="option2" />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Collaborateurs</label>
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                           id="inlineRadio1" value="option1" />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Utilisateurs activé</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                           id="inlineRadio2" value="option2" />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Utilsateur désactivé</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-primary">Filtrer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserList;