import * as React from "react";

import UserList from "../../../components/admin/user/list";
import UserShow from "../../../components/admin/user/show";

const UserPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <UserList />
            <UserShow />
        </div>
    );
}

export default UserPage;