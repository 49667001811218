import React, {forwardRef, useEffect, useRef, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import FullCalendar from "@fullcalendar/react";
import resourceTimeline from "@fullcalendar/resource-timeline";
import allLocales from "@fullcalendar/core/locales-all";
import {Absence, Absences} from "../../../models/absence";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EDIT_ABSENCE, FORM_NEW_ABSENCE} from "../../../constants/rightSidebar";
import * as actions from "../../../actions/absence";
import * as absenceAPI from "../../../adapters/absence";
import {showAlertDanger} from "../../../actions/alert";
import {setPageTitle} from "../../../actions/header";
import Loader from "../../../utils/loader";
import {fetchTitleSuccess, refresh} from "../../../actions/calendar";
import DatePicker from "react-datepicker";
import format from "../../../utils/locale";
import {Link} from "react-router-dom";
import {axiosError} from "../../../actions/axios";
import {Granted} from "../../../security/granted";
import * as accessRights from "../../../constants/accessRight";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapSelect, {Choices} from "../../../utils/bootstrapSelect";
import {Company} from "../../../models/companies";

const AbsenceCalendar: React.FC = () => {

    const absences = useSelector((state: RootStateOrAny)  => state.absence.payload);
    const state = useSelector((state: RootStateOrAny)  => state);
    const refreshAbsences: number =  useSelector((state: RootStateOrAny)  => state.calendar.refresh);
    const [title, setTitle] =  useState<string>('');
    const [pickerDate, setPickerDate] = useState<Date>(new Date());
    const [companies, setCompanies] = useState<number[]>([]);
    const calendarRef = React.createRef<FullCalendar>();

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(setPageTitle('Absences', 'Planning'))

    }, [])

    useEffect(() => {
        let controller = new AbortController();
        dispatch(actions.fetchAbsences())
        let API = calendarRef.current?.getApi();
        if (API){
            setTitle(API.view.title)
            absenceAPI.list({dates: [format(API.view.currentStart, 'uuuu-MM-dd'), format(API.view.currentEnd, 'uuuu-MM-dd')], companies: companies}, controller.signal).then(data => {
                dispatch(actions.fetchAbsencesSuccess(data.data))
            }).catch(error => {
                dispatch(axiosError(error))
            })
        }

        return () => controller.abort();
    }, [refreshAbsences, companies])


    const calendarApiNavActions = (action: string) => {

        let API = calendarRef.current?.getApi()
        if (API){
            switch (action) {
                case 'prev':
                    API.prev();
                    break;
                case 'next':
                    API.next();
                    break;
            }

            setTitle(API.view.title)
            dispatch(refresh())
        }
    }

    type ButtonProps = JSX.IntrinsicElements["button"];
    const ViewTitleContainer = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
        const {onClick} = props;
        return <div className={'btn-group'}>
            <button className={'btn bg-white rounded-pill'} onClick={() => calendarApiNavActions('prev')}>
                <i className={'bi bi-chevron-left'}> </i>
            </button>
            <button style={{minWidth: 180}} className="btn text-primary fs-5 text-capitalize" onClick={onClick} ref={ref}>
                {title}
            </button>
            <button className={'btn bg-white rounded-pill'} onClick={() => calendarApiNavActions('next')}>
                <i className={'bi bi-chevron-right'}> </i>
            </button>
        </div>
    });

    const CalendarTitle = () => {
        return <DatePicker
            selected={pickerDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode={'select'}
            onChange={(date) => {
                if (date instanceof Date){
                    setPickerDate(date)
                    let API = calendarRef.current?.getApi()
                    if (API){
                        API.gotoDate(date)
                        setTitle(API.view.title)
                        dispatch(refresh())
                    }
                }
            }}
            customInput={<ViewTitleContainer />}
        />
    }

    return <div className={"d-flex flex-column h-100 container-fluid"}>
        <div className="d-flex my-3 flex-grow-0">
            <div className="col-auto">
                <Link to={"/absence"} className={'btn btn-outline-primary'}>
                    <i className={'bi bi-list-ul'}> </i> Liste
                </Link>
            </div>
            <div className="col">
                <CalendarTitle />
            </div>
            <div className="col-auto">
                <div className="btn-group">
                    <BootstrapSelect
                        className={'text-nowrap bg-white'}
                        placeholder={'Points de vente'}
                        notFloating={true}
                        isMultiple={true}
                        onChangeMultiple={(choices: Choices) => setCompanies(choices?.map(choice => Number(choice.value)) || [])}
                        options={state.auth.user.currentSalary.companies.map((c: Company) => ({label: c.title, value: c.id}))}
                    />
                    <button className={'btn bg-white shadow-sm text-primary mx-1'}>
                        <i className={'bi bi-download'}> </i>
                    </button>
                    <button className={'btn bg-white shadow-sm text-primary mx-1'} onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE))}>
                        <i className={'bi bi-plus-lg'}> </i>
                    </button>
                </div>
            </div>
        </div>
        <div className="flex-grow-1 row">
            <FullCalendar
                resourceLaneDidMount={() => (calendarRef.current?.getApi().updateSize())}
                editable={Granted(accessRights.EDIT_ABSENCE)}
                ref={calendarRef}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                resourceOrder={'orderBy1, orderBy2'}
                height={'parent'}
                resourceAreaWidth={"20%"}
                plugins={[ resourceTimeline, interactionPlugin ]}
                initialView={"resourceTimelineMonth"}
                resourceAreaHeaderContent={() => <>{absences.length} absences</>}
                resourceLabelContent={(e) => {
                    let count = 0;
                    absences.filter((absence: Absence) => absence.salary.id === parseInt(e.resource.id)).map((absence: Absence) => count += absence.nbDays!);
                    return <>
                        <span className="d-flex w-100 align-items-center">
                            <span className="col-auto flex-grow-0 me-2">
                                <span className="color-circle col-auto">
                                    <div className="shadow-sm" style={{backgroundColor: `${e.resource.extendedProps.jobColor}`, opacity: 0.8}}> </div>
                                </span>
                            </span>
                            <span className="col  flex-grow-1">
                                <span className={"h4 mb-0"}>{e.resource.title}</span>
                                <span className="clearfix"> </span>
                                {count} jour(s)
                            </span>
                            <span className="col-auto  flex-grow-0">
                                <span onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE, {salary: e.resource.extendedProps.salary}))} className={'btn btn-light text-primary'}>
                                    <i className={'bi bi-plus-circle'}> </i>
                                </span>
                            </span>
                        </span>
                    </>
                }}
                eventClick={(e) => {
                    if (Granted(accessRights.EDIT_ABSENCE, e.event.extendedProps.item.salary.id)){
                        dispatch(openSidebar(FORM_EDIT_ABSENCE, e.event.extendedProps.item))
                    }
                }}
                locales={allLocales}
                locale={'fr'}
                stickyHeaderDates={true}
                stickyFooterScrollbar={true}
                displayEventTime={false}
                headerToolbar={false}
                slotDuration={{day: 1}}
                resources={absences.map((absence: Absence) => {
                    return {
                        id: absence.salary!.lastname!,
                        title: absence.salary.title,
                        salary: absence.salary,
                        jobColor: absence.salary.information?.job.color,
                        orderBy1: absence.salary.information?.job.id,
                        orderBy2: absence.salary.lastname
                    }
                })}
                events={absences.map((absence: Absence) => {

                    let end = new Date(absence.end);
                    end.setDate(end.getDate() + 1);

                    return {
                        id: absence.id,
                        title: absence.typeName + ' - ' + absence.nbDays + ' jour(s)',
                        start: absence.start,
                        end: format(end, 'uuuu-MM-dd'),
                        resourceId: absence.salary?.lastname,
                        item: absence,
                        backgroundColor: absence.color,
                        borderColor: absence.color,
                        classNames: ['fc-timeline-event', 'shadow']
                    }
                })}
                handleWindowResize={true}
                progressiveEventRendering={true}
            />
        </div>
    </div>
}

export default AbsenceCalendar;