import * as accessRights from "../../../constants/accessRight";
import * as models from "../../../models/expense";
import * as constants from "../../../constants/expense";
import {User} from "../../../models/auth";
import {Salary} from "../../../models/salary";
import accessRightChecker from "../../accessRightChecker";

class ExpenseVoter {

    static supports(right: string, object?: models.Expense)
    {
        return [
            accessRights.LIST_EXPENSE,
            accessRights.EDIT_EXPENSE,
            accessRights.DELETE_EXPENSE,
        ].includes(right) && object
    }

    voteOnAttribute(right: string, object: models.Expense, user: User){

        let currentSalary = user.currentSalary;

        if (!currentSalary){
            return false;
        }

        switch (right){
            case accessRights.EDIT_EXPENSE:
                return this.canEdit(currentSalary, object)
            case accessRights.LIST_EXPENSE:
                return this.canList(currentSalary, object)
            default:
                return accessRightChecker.grantedFor(right, object.salary.id)
        }
    }

    canEdit(salary: Salary, expense: models.Expense){
        if (accessRightChecker.grantedFor(accessRights.EDIT_EXPENSE, expense.salary.id)){
            return true;
        }

        return accessRightChecker.hasGlobalRight(accessRights.REQUEST_EXPENSE) && salary.id === expense.salary.id && expense.status === constants.STATUS_PENDING;
    }

    canList(salary: Salary, expense: models.Expense)
    {
        if (accessRightChecker.grantedFor(accessRights.LIST_EXPENSE, expense.salary.id)){
            return true;
        }

        return accessRightChecker.hasGlobalRight(accessRights.REQUEST_EXPENSE) && expense.salary.id === salary.id;
    }
}

export default ExpenseVoter;