import {get, post} from "../xhr";

export function subscription(company: number){
    return get(`billing/${company}/subscription`);
}

export function model(company: string, model: number){
    return get(`billing/${company}/subscription/${model}`);
}

export function companies(){
    return get(`billing/companies`);
}

export function invoices(company: number, requestData: {offset: number}){
    return post(`billing/${company}/invoices`, requestData);
}

export function salaries(company: number){
    return get(`billing/${company}/salaries`);
}

export function salary(company: number, salary: number){
    return get(`billing/company/${company}/salary/${salary}`);
}