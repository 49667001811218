import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import * as Yup from "yup";
import {ErrorMessage, Field, FieldProps, Form, Formik, FormikValues, useFormik} from "formik";
import * as actions from "../../../actions/task";
import * as calendarActions from "../../../actions/task/calendar";
import * as taskGroupApi from "../../../adapters/taskGroup";
import * as api from "../../../adapters/task";
import {showAlertInfo, showAlertSuccess} from "../../../actions/alert";
import {closeSidebar, openSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {TaskGroup, TaskGroups} from "../../../models/taskGroup";
import DatePicker from "react-datepicker";
import {PostTask, TaskRecurrence} from "../../../models/task";
import {TaskGroupCustomField} from "../../../models/taskGroup/customField";
import Loader from "../../../utils/loader";
import {CustomInput} from "../../../constants/taskGroup/customField";
import format from '../../../utils/locale'
import BootstrapSelect from "../../../utils/bootstrapSelect";
import FormError from "../../../utils/formError";
import DateCustomInput from "../../../utils/customInput";
import {FORM_NEW_TASK_GROUP} from "../../../constants/rightSidebar";
import {axiosError} from "../../../actions/axios";
import {Salaries, Salary} from "../../../models/salary";
import * as accessRights from "../../../constants/accessRight";
import SalarySelector from "../../salary/selector";

const validationSchema =
    Yup.object().shape({
        task: Yup.object().shape({
            taskGroup: Yup.number().required('Veuillez choisir un groupe de tâche'),
            title: Yup.string().required('Veuillez préciser un titre'),
            description: Yup.string().nullable(),
            allDay: Yup.boolean().required("Champs requis"),
            beginAt: Yup.date().required("Champs requis"),
            endAt:  Yup.date().required('Fin de créneau requis')
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { beginAt } = this.parent;
                    return value && beginAt <= value
                }),
            salaries: Yup.array().of(Yup.number()).min(1, "Veuillez ajouter au moins un collaborateur'").required("Champs requis"),
            recurrence: Yup.object().shape({
                type: Yup.number().required('Veuillez choisir un type de récurrence'),
            })
        }),
        customFields: Yup.object().shape({}),
    });

const TaskAdd: React.FC = () => {

    const dispatch = useDispatch();
    const [validation, setValidation] = useState(validationSchema);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTaskGroup, setCurrentTaskGroup] = useState<TaskGroup>();
    const state = useSelector((state: RootStateOrAny) => state);
    const [taskGroups, setTaskGroups] = useState<TaskGroups>([]);
    let now = new Date()
    const [initialValues, setInitialValues] = useState<PostTask>({
        task: {
            taskGroup: null,
            title: '',
            description: null,
            allDay: false,
            beginAt: new Date(now.setHours(0,0)),
            endAt: new Date(now.setHours(0, 30)),
            salaries: [],
            recurrence : {
                type: 1,
            }
        },
        customFields: {}
    })


    useEffect(() => {

        let _initialValues = {...initialValues};

        taskGroupApi.list().then((data) => {
            if (!data.data.length){
                dispatch(showAlertInfo("Veuillez ajouter un groupe de tâches pour pouvoir ajouter des tâches"));
                dispatch(openSidebar(FORM_NEW_TASK_GROUP))
            }else{
                setTaskGroups(data.data);

                if (state.rightSidebar.data?.salaries){
                    _initialValues.task.salaries = state.rightSidebar.data.salaries
                }

                if (state.taskGroup.single){
                    setCurrentTaskGroup(state.taskGroup.single)
                    _initialValues.task.taskGroup = state.taskGroup.single.id
                }else{
                    setCurrentTaskGroup(data.data[0])
                    _initialValues.task.taskGroup = data.data[0].id
                }
                setInitialValues({..._initialValues});
            }
        })
    }, [])

    useEffect(() => {
        if (currentTaskGroup){
            let _validationSchema = {...validation.fields}

            if (_validationSchema.customFields.fields.length){
                for (let i in Object(_validationSchema.customFields.fields).keys()){
                    delete _validationSchema.customFields.fields[i]
                }
                for (let j in Object(initialValues.customFields).keys()){
                    delete initialValues.customFields[j]
                }
            }

            if (currentTaskGroup.customFields!.length){
                currentTaskGroup.customFields!.map((customField: TaskGroupCustomField) => {
                    switch (customField.type){
                        case 1:
                            _validationSchema.customFields.fields[customField.id!] = Yup.string().nullable()
                            initialValues.customFields[customField.id!] = '';
                            break;
                        case 2:
                            _validationSchema.customFields.fields[customField.id!] = Yup.string().nullable()
                            initialValues.customFields[customField.id!] = '';
                            break;
                        case 3:
                            _validationSchema.customFields.fields[customField.id!] = Yup.number().nullable()
                            initialValues.customFields[customField.id!] = null;
                            break;
                        case 4:
                            _validationSchema.customFields.fields[customField.id!] = Yup.date().nullable()
                            initialValues.customFields[customField.id!] = new Date();
                            break;
                        case 5:
                            _validationSchema.customFields.fields[customField.id!] = Yup.string().nullable()
                            initialValues.customFields[customField.id!] = '';
                            break;
                        case 6:
                            _validationSchema.customFields.fields[customField.id!] = Yup.array().of(Yup.number()).nullable()
                            initialValues.customFields[customField.id!] = [];
                    }
                })
            }

            setValidation(Yup.object().shape({..._validationSchema}));
            setIsLoading(false);
        }
    }, [currentTaskGroup])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validation,
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(submitSidebar())
            api.create(values).then(data => {
                dispatch(calendarActions.refresh())
                dispatch(actions.addTaskSuccess(data.data))
                dispatch(showAlertSuccess(data.data.title + ' à bien été ajouter !'))
                dispatch(closeSidebar());
            }).catch(error => dispatch(axiosError(error)))
        }
    });

    const handleRecurrenceTypeChange = (type: number) => {

        let _validationSchema: any = {};
        let _recurrenceValues: TaskRecurrence = {...formik.values.task.recurrence};
        let _values = {...formik.values};
        _recurrenceValues.type = type;

        let recurrenceFields:string[] = [];
        switch (type){
            case 1:
                // ONCE
                recurrenceFields = ['type'];
                _validationSchema.type = Yup.number().required();
                break;
            case 2:
                // EACH WEEK
                recurrenceFields = ['type', 'start', 'end', 'daysOfWeek'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour de la semaine');
                break;
            case 3:
                // EACH MONTH
                recurrenceFields = ['type', 'start', 'end', 'dayOfMonth'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.dayOfMonth = Yup.number().required('Veuillez choisir un jour du mois');
                break;
            case 4:
                // EACH YEAR
                recurrenceFields = ['type', 'start', 'end', 'dayOfMonth', 'monthOfYear'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.dayOfMonth = Yup.number().required('Veuillez choisir un jour du mois');
                _validationSchema.monthOfYear = Yup.number().required('Veuillez choisir un mois de l\'année');
                break;
            case 5:
                // CUSTOM
                recurrenceFields = ['type', 'start', 'end', 'daysOfWeek', 'separationCount'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour de la semaine');
                _validationSchema.separationCount = Yup.number().required('Veuillez préciser une récurrence')
                break;
        }

        // DELETE UNECESSARY VALUES
        for (let key in _recurrenceValues){
            if (!recurrenceFields.includes(key)){
                switch (key){
                    case "start":
                        delete _recurrenceValues.start
                        break;
                    case "end":
                        delete _recurrenceValues.end
                        break;
                    case "dayOfMonth":
                        delete _recurrenceValues.dayOfMonth
                        break;
                    case "daysOfWeek":
                        delete _recurrenceValues.daysOfWeek
                        break;
                    case "monthOfYear":
                        delete _recurrenceValues.monthOfYear
                        break;
                    case "separationCount":
                        delete _recurrenceValues.separationCount
                        break;
                }
            }
        }

        // SET DEFAULT VALUES
        recurrenceFields.forEach((field) => {
            if (!_recurrenceValues.hasOwnProperty(field)){
                switch (field) {
                    case 'start':
                        _recurrenceValues.start = _values.task.beginAt
                        break;
                    case 'end':
                        _recurrenceValues.end = undefined
                        break;
                    case 'daysOfWeek':
                        _recurrenceValues.daysOfWeek = [Number(format(_values.task.beginAt || new Date(), 'i'))];
                        break;
                    case 'dayOfMonth':
                        _recurrenceValues.dayOfMonth = Number(format(_values.task.beginAt || new Date(), 'd'));
                        break;
                    case 'monthOfYear':
                        _recurrenceValues.monthOfYear = Number(format(_values.task.beginAt || new Date(), 'M'));
                        break;
                    case 'separationCount':
                        _recurrenceValues.separationCount = 1;
                        break;
                }
            }
        })

        // VALIDATION SCHEMA

        validation.fields.task.fields.recurrence.fields = _validationSchema
        _values.task.recurrence = _recurrenceValues;

        setValidation(validation)
        setInitialValues({..._values})
    }

    const getDaysOfMonth = () => {
        let ret = [];
        for(let i = 1; i <= 31; i++){
            ret.push({label: "Le " + i, value: i})
        }
        return ret;
    }

    const getMonthsOfYear = () => {
        let months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

        let ret = [];
        for (let i = 0; i < 12; i++){
            ret.push({label: months[i], value: i+1})
        }

        return ret;
    }

    const handleAllDayChange = (e: BaseSyntheticEvent) => {
        let _values = {...formik.values}
        let _validationSchema: any = {...validationSchema.fields}
        _values.task.allDay = e.target.checked;
        if (e.target.checked){
            delete _values.task.endAt;
            delete _validationSchema.task.fields.endAt;
        }else{
            _values.task.endAt = _values.task.beginAt;
            _validationSchema.task.fields.endAt = Yup.date().required('Fin de créneau requis')
                .test("is-greater", "La fin doit être après le début", function(value: any) {
                    const { beginAt } = this.parent;
                    return value && beginAt <= value
                })
        }
        setValidation(Yup.object().shape({..._validationSchema}));
        setInitialValues(_values);
    }

    if (isLoading){
        return <Loader />
    }

        return <form onSubmit={formik.handleSubmit}>
                <div className={'p-1 p-md-3'}>
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                required={true}
                                label={"Groupe"}
                                onChange={(choice) => formik.setFieldValue("task.taskGroup", choice?.value)}
                                value={state.taskGroup.single && {label: state.taskGroup.single.title, value: state.taskGroup.single.id}}
                                options={taskGroups.map((taskGroup: TaskGroup) => ({label: taskGroup.title, value: taskGroup.id!}))}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'task.taskGroup'} />
                        </div>
                        <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input
                                    onChange={formik.handleChange}
                                    onFocus={formik.handleBlur}
                                    className='form-control'
                                    placeholder={'Titre'}
                                    name="task.title"/>
                                <label className="form-label" htmlFor="task.title">
                                    Titre <span className={"required"}>*</span>
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'task.title'} />
                        </div>
                        <div className="col-12 mb-3">
                            <div className="form-floating">
                                <input className='form-control'
                                       onChange={formik.handleChange}
                                       onFocus={formik.handleBlur}
                                       name="task.description"
                                       placeholder={'Description'}
                                />
                                <label className="form-label" htmlFor="task.description">
                                    Description
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'task.description'} />
                        </div>
                        <div className="col-12 mb-3">
                            <div className="form-check">
                                <input type={'checkbox'}
                                       name={'task.allDay'}
                                       id={'allDay'}
                                       onChange={(e: BaseSyntheticEvent) => handleAllDayChange(e)}
                                       defaultChecked={false}
                                />
                                <label className="form-check-label ms-2" htmlFor="allDay" >
                                    Toute la journée
                                </label>
                            </div>
                            {!formik.values.task.allDay ?
                                <div className="col-md-12 mb-3">
                                    <div className="input-group">
                                        <DatePicker
                                            className={'form-control col-auto'}
                                            selected={formik.values.task.beginAt}
                                            onChange={(date: Date) => {
                                                formik.setFieldValue(`task.beginAt`, date)
                                            }}
                                            customInput={<DateCustomInput label={'Début'} />}
                                            selectsStart
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Heure"
                                            dateFormat="E dd MMM uuuu HH:mm"
                                            popperPlacement={"top-end"}
                                            showPopperArrow={false}
                                        />
                                        <span className="input-group-text"> <i className="bi bi-arrow-left-right"> </i> </span>
                                        <DatePicker
                                            className={'form-control col-auto'}
                                            customInput={<DateCustomInput label={'Fin'} />}
                                            selected={formik.values.task.endAt}
                                            onChange={(date: Date) => formik.setFieldValue(`task.endAt`, date)}
                                            selectsEnd
                                            startDate={formik.values.task.beginAt}
                                            endDate={formik.values.task.endAt}
                                            minDate={formik.values.task.beginAt}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Heure de fin"
                                            dateFormat="E dd MMM uuuu HH:mm"
                                            popperPlacement={"top-end"}
                                            showPopperArrow={false}
                                        />
                                    </div>
                                    <FormError errors={formik.errors} touched={formik.touched} field={'task.beginAt'} />
                                    <FormError errors={formik.errors} touched={formik.touched} field={'task.endAt'} />
                                </div>
                                :
                                <>
                                    <DatePicker
                                        className={'form-control col-auto'}
                                        customInput={<DateCustomInput label={"date"} />}
                                        selected={formik.values.task.beginAt}
                                        onChange={(date: Date) => formik.setFieldValue(`task.beginAt`, date)}
                                        selectsStart
                                        dateFormat="E dd MMM uuuu"
                                        popperPlacement={"top-end"}
                                        showPopperArrow={false}
                                    />
                                    <FormError errors={formik.errors} touched={formik.touched} field={'task.beginAt'} />
                                </>
                            }
                        </div>
                        <div className="col-12 mb-3">
                            <SalarySelector
                                fetchOptions={true}
                                required={true}
                                multiple={true}
                                onChange={(choices: Salaries) => formik.setFieldValue('task.salaries', choices.map(c => c.id))}
                                accessRight={accessRights.EDIT_COUNTER}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'task.salaries'} />
                        </div>
                        {currentTaskGroup && currentTaskGroup.customFields?.map((customField: TaskGroupCustomField, index) =>
                            <div key={index} className={'col-12 mb-3'}>
                                <CustomInput
                                    customField={customField}
                                    setFieldValue={formik.setFieldValue}
                                    values={formik.values}
                                />
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <BootstrapSelect
                                    label={"Recurrence"}
                                    onChange={(choice) => handleRecurrenceTypeChange(Number(choice?.value))}
                                    value={{label: "Une seule fois", value: 1}}
                                    required={true}
                                    options={[
                                        {label: "Une seule fois", value: 1},
                                        {label: "Chaques semaine", value: 2},
                                        {label: "Une fois par mois", value: 3},
                                        {label: "Une fois par an", value: 4},
                                        {label: "Personnalisé", value: 5},
                                    ]}
                                />
                                <FormError errors={formik.errors} touched={formik.touched} field={'task.recurrence.type'} />
                            </div>
                            {Number(formik.values.task.recurrence.type) !== 1 &&
                            <div className="col-12 mb-3">
                                <div className="input-group">
                                    <DatePicker
                                        customInput={<DateCustomInput label={"Début de récurrence"} />}
                                        dateFormat="E dd MMM uuuu"
                                        popperPlacement={"top-end"}
                                        showPopperArrow={false}
                                        className={'form-control'}
                                        selected={formik.values.task.recurrence.start}
                                        onChange={(date) => formik.setFieldValue('task.recurrence.start', date)}
                                        selectsStart
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        startDate={formik.values.task.recurrence.start}
                                        endDate={formik.values.task.recurrence.end}
                                    />
                                    <span className="input-group-text">
                                            <i className={'bi bi-arrow-left-right'}> </i>
                                        </span>
                                    <DatePicker
                                        customInput={<DateCustomInput label={"Fin de récurrence"} />}
                                        dateFormat="E dd MMM uuuu"
                                        popperPlacement={"top-end"}
                                        showPopperArrow={false}
                                        className={'form-control'}
                                        selected={formik.values.task.recurrence.end}
                                        onChange={(date) => formik.setFieldValue('task.recurrence.end', date)}
                                        selectsEnd
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        startDate={formik.values.task.recurrence.start}
                                        endDate={formik.values.task.recurrence.end}
                                        minDate={formik.values.task.recurrence.start}
                                        isClearable={true}
                                    />
                                </div>
                            </div>}

                                {[2,5].includes(Number(formik.values.task.recurrence.type)) && <>
                                    <div className="col-12 mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked_days" />
                                            <label className="form-check-label" htmlFor="flexCheckChecked_days">
                                                Tous les jours de la semaine
                                            </label>
                                        </div>
                                        {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                                            return (
                                                <>
                                                    <input type="checkbox" onChange={formik.handleChange} name={'task.recurrence.daysOfWeek'} className="btn-check" id={`btn-check-${index}`} autoComplete="off" value={`${index + 1}`} />
                                                    <label className="btn btn-sm btn-outline-primary me-2 mb-3" htmlFor={`btn-check-${index}`}>
                                                        {day}
                                                    </label>
                                                </>
                                            )
                                        })}
                                        <FormError errors={formik.errors} touched={formik.touched} field={"task.recurrence.daysOfWeek"} />
                                    </div>
                                </>}

                                {[3,4].includes(formik.values.task.recurrence.type) && <>
                                    <div className="col-12 mb-3">
                                        <BootstrapSelect
                                            label={"Jour du mois"}
                                            value={{label: format(formik.values.task.beginAt || new Date(), "dd"), value: formik.values.task.beginAt?.getDay() || (new Date().getDay())}}
                                            options={getDaysOfMonth().map(opt => ({label: opt.label, value: opt.value}))}
                                            required={true}
                                            onChange={(choice) => formik.setFieldValue('task.recurrence.dayOfMonth', choice?.value)}
                                        />
                                        <FormError errors={formik.errors} touched={formik.touched} field={"task.recurrence.dayOfMonth"} />
                                    </div>
                                </>}


                                {formik.values.task.recurrence.type == 4 && <>
                                    <div className="col-12 mb-3">
                                        <BootstrapSelect
                                            label={"Mois de l'année"}
                                            value={{label: format(formik.values.task.beginAt || new Date(), "MMMM"), value: formik.values.task.beginAt?.getMonth() || (new Date().getMonth())}}
                                            options={getMonthsOfYear().map(opt => ({label: opt.label, value: opt.value}))}
                                            required={true}
                                            onChange={(choice) => formik.setFieldValue('task.recurrence.monthOfYear', choice?.value)}
                                        />
                                        <FormError errors={formik.errors} touched={formik.touched} field={"task.recurrence.monthOfYear"} />
                                    </div>
                                </>}
                                {formik.values.task.recurrence.type == 5 && <>
                                    <div className="col-12 mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type={'number'} defaultValue={1} name={'task.recurrence.separationCount'} className={'form-control'} />
                                                    <label htmlFor="task.recurrence.separationCount">
                                                        Répéter toute les
                                                    </label>
                                                </div>
                                                <span className={'input-group-text'}>Semaine(s)</span>
                                            </div>
                                        <FormError errors={formik.errors} touched={formik.touched} field={"task.recurrence.separationCount"} />
                                    </div>
                                </>}
                        </div>
                        <button className='btn btn-outline-primary w-100' type="submit">
                            <i className={'bi bi-plus-circle'}> </i> Ajouter
                        </button>
                </div>
            </form>
}

export default TaskAdd;