import * as React from "react";

import ReportingList from "../../components/reporting/list";

const ReportingListPage : React.FC = () => {
    return (
        <ReportingList />
    );
}

export default ReportingListPage;