import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/event/offer"
import * as actions from "../../../actions/event/offer"
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_ABSENCE, FORM_NEW_EVENT_OFFER, LIST_EVENT_OFFER} from "../../../constants/rightSidebar";
import {axiosError} from "../../../actions/axios";
import {EventOffer} from "../../../models/event/offer";
import * as accessRights from "../../../constants/accessRight";
import {Granted} from "../../../security/granted";

const DashboardEventOffer:React.FC = () => {


    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);

    let now = new Date()
    now.setDate(now.getDate() + 30);

    useEffect(() => {
        const controller = new AbortController();

        dispatch(actions.fetchEventOffers())
        api.list({}, controller.signal)
            .then(data => dispatch(actions.fetchEventOffersSuccess(data.data)))
            .catch(error => dispatch(axiosError(error)))

        return () => controller.abort();
    }, [])

    return <>
        <div className="btn-group w-100 shadow-sm bg-white mb-3">
            <div className="card p-3 flex-grow-1 cursor-pointer" onClick={() => dispatch(openSidebar(LIST_EVENT_OFFER, {status: 'pending'}))}>
                <div className="d-flex align-items-center">
                    <div className="col">
                    <span className={'h4 text-primary'}>
                        <i className={'bi bi-calendar-x'}> </i>
                        Propositions de créneaux
                    </span>
                        <div className="clearfix"> </div>
                        <span className="form-text">
                        +30 jours
                    </span>
                    </div>
                    <div className="col-auto align-middle align-items-center">
                        {state.eventOffer.isLoading ? <div className="spinner-border align-middle text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <span className={'text-primary h2'}>{state.eventOffer.payload.filter((eventOffer: EventOffer) => !(eventOffer.applications.find(a => a.validated))).length}</span>}
                    </div>
                </div>
            </div>
            {Granted(accessRights.EDIT_BOOKING_OFFER) && <button className="flex-grow-0 btn btn-outline-primary" onClick={() => dispatch(openSidebar(FORM_NEW_EVENT_OFFER))}>
                <i className={'bi bi-plus fs-4'}></i>
            </button>}
        </div>

    </>
}

export default DashboardEventOffer;