import React, { useState, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import TeamList from "../../components/team/list";
import TeamShow from "../../components/team/show";
import BgSingle from "../../utils/background/single";

import { Team } from "../../models/team";

import { setPageTitle } from "../../actions/header";
import * as actions from "../../actions/team";
import { axiosError } from "../../actions/axios";

import * as api from "../../adapters/team";


const TeamPage : React.FC = () => {
    const dispatch = useDispatch()
    const params: { id: string } = useParams();

    const { payload } = useSelector((state: RootStateOrAny) => state.team)
    
    const [loading, setLoading] = useState<boolean>(false)
    const [query, setQuery] = useState<string>('')
    const [teamData, setTeamData] = useState<Team|null>(null)

    const fetchTeamsList = () => {
        dispatch(setPageTitle('Équipes'))
        dispatch(actions.fetchTeams())
        setLoading(true);

        api.list(query?.length > 0 ? { query } : {}).then(data => {
            dispatch(actions.fetchTeamsSuccess(data.data))
            setLoading(false);
        }).catch(error => dispatch(axiosError(error)))
    }

    useEffect(() => {
        fetchTeamsList()
    }, [query])

    useEffect(() => {
        if (params?.id) {
            setTeamData(payload.find((team: Team) => team.id === parseInt(params.id)))
        } else {
            setTeamData(null)
        }
    }, [params])

    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <TeamList
                isLoading={loading}
                list={payload}
                query={query}
                setQuery={setQuery}
            />
            {!teamData &&
                <BgSingle />
            }
            {teamData &&
                <TeamShow
                    teamData={teamData}
                    fetchTeamsList={fetchTeamsList}
                />
            }
        </div>
    );
}

export default TeamPage;