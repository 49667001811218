import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Companies, Company} from "../../models/companies";
import * as api from "../../adapters/company";
import * as actions from "../../actions/companies";
import {PAGINATION} from "../../constants/global";

interface CompanySelectorProps {
    onSubmit: any,
    onClose: any,
    initialValues: number[],
    choices?: Companies,
    max?: number
}

export const CompanySelector:React.FC<CompanySelectorProps> = (props) => {

    const {onSubmit, onClose, initialValues, max, choices} = props;

    const [offset, setOffset] = useState(0)
    const [isLast, setIsLast] = useState(false)
    const [query, setQuery] = useState<string|undefined>()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')
    const companies = useSelector((state: RootStateOrAny) => state.companies.payload)
    const [selectedValues, setSelectedValues] = useState<number[]>([...initialValues])
    const dispatch = useDispatch();
    const [scrollBottom, setScrollBottom] = useState(false)

    useEffect(() => {

        let container = document.getElementsByClassName('offcanvas-body')[0]!
        container.addEventListener("scroll", onScrollBottom)

        setIsLoading(true);

        dispatch(actions.fetchCompanies())

        api.list({query: query, offset: 0}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            dispatch(actions.fetchCompaniesSuccess(data.data))
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => {
            dispatch(actions.fetchCompaniesFailure(error.response.data.message))
        })

        return () => {
            container.removeEventListener("scroll", onScrollBottom)
        }
    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                dispatch(actions.fetchCompanies())
                setIsLoading(true);
                setOffset(0)
                api.list({query: query, offset: 0}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchCompaniesSuccess(data.data))
                    setIsLoading(false);
                    setOffset( 1);
                }).catch(error => {
                    dispatch(actions.fetchCompaniesFailure(error.response.data.message))
                })
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if (!isLoading){
            if(offset > 0 && !isLast){
                setIsLoading(true)
                api.list({query: query, offset: offset}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchCompaniesSuccess([...companies, ...data.data]))
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                }).catch(error => {
                    dispatch(actions.fetchCompaniesSuccess(error.response.data.message))
                })
            }
        }
    }, [scrollBottom])

    const onScrollBottom = () => {
        let elem = document.getElementsByClassName('offcanvas-body')[0]!;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom((prevState) => !prevState)
        }
    }

   return (
       <div className={'listSelector col-12'} id={"CompanySelector"}>
           <div className="w-100 p-2 sticky-top bg-white">
               <div className="row">
                   <div className="col">
                       <h3>
                          <i className={"bi bi-shop text-primary"}> </i> Points de vente
                       </h3>
                   </div>
                   <div className="col-auto">
                       <button type={'button'} className={'btn btn-light'} onClick={onClose}>
                           <i className={'bi bi-x-lg'}> </i>
                       </button>
                   </div>
                   {error && <div className={'text-danger col-12'}> {error} </div>}
               </div>
               <input type="search" className={'form-control w-100 my-2'} onChange={(e) =>
                   setQuery(e.target.value)} placeholder={'Rechercher un point de vente'}
               />
           </div>
           <ul className={'list-group list-group-flush'}>
               {companies.map((company: Company, index: number) => {
                   return (
                       <li className={'list-group-item'} key={index}>
                           <div className="form-check">
                               <input className="form-check-input" defaultChecked={company.id ? selectedValues.includes(company.id) : false} onChange={(e) => {
                                   setError('')

                                   if(selectedValues.includes(parseInt(e.target.value))){
                                       selectedValues.splice(selectedValues.indexOf(parseInt(e.target.value)), 1)
                                       setSelectedValues([
                                           ...selectedValues
                                       ]);
                                   }else{
                                       if (max && selectedValues.length >= max){
                                           setError('Maximum ' + max + ' entreprises')
                                           e.target.checked = false;
                                       }else{
                                         setSelectedValues([...selectedValues, parseInt(e.target.value)])
                                       }
                                   }
                               }} type="checkbox" value={company.id} id={`salary${index}`} />
                               <label className="form-check-label" htmlFor={`salary${index}`} >
                                   {company.title}
                               </label>
                           </div>
                       </li>
                   )
               })}
               {isLoading && <li className={'list-group-item text-center'}>
                   <div className="spinner-border text-primary" role="status">
                       <span className="visually-hidden">Loading...</span>
                   </div>
               </li>}
           </ul>
           {!isLast && !isLoading && <button type={'button'} onClick={() => setScrollBottom((prevState) => !prevState)} className={'btn mb-2 btn-outline-primary w-100'}>
               Charger plus d'entreprises
           </button>}
           <div className="position-sticky w-100 bg-white p-2" style={{bottom: 0}}>
               <button type={'button'} className={'btn btn-outline-primary w-100'} onClick={() => onSubmit(selectedValues)}>
                  <i className={"bi bi-check"}> </i> Valider
               </button>
           </div>
       </div>
   )
}

interface CompanySelectedProps {
    onDelete: any,
    onOpen: any,
    initialValues: number[],
    btnClassName?: string,
    max?: number,
    validationClass?: string,
}

export const CompanySelected: React.FC<CompanySelectedProps> = (props) => {

    const {
        onDelete,
        onOpen,
        initialValues,
        btnClassName,
        validationClass,
        max
    } = props;

    const [companies, setCompanies] = useState<Companies>([])
    const [isLoading, setIsLoading] = useState(!!initialValues)

    useEffect(() => {
        if (initialValues.length){
            setIsLoading(true);
            api.list({ids: initialValues}).then(data => {
                setCompanies(data.data)
                setIsLoading(false);
            })
        }
    }, [initialValues]);

    return (
        <div className={"form-control h-auto " + validationClass}>
            <div className="col-12">
                {initialValues.map((id: number, index) => {
                    let company = companies.find((company: Company) => company.id === id)!
                    return (
                        <span key={index} className={'c'}>
                                            <span>
                                                {company && !isLoading ? company.title : <div className="spinner-grow spinner-grow-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>}
                                            </span>
                                            <span className={'ps-2'} onClick={() => onDelete(company.id)}>
                                                <i className={'bi bi-x'}> </i>
                                            </span>
                                        </span>
                    )
                })}
                <span>
                    <button type={'button'} style={{lineHeight: '1rem'}} className={'btn p-0 ' + (btnClassName||'btn-light')} onClick={onOpen}>
                        {!initialValues.length && "Séléctionner une entreprise"} {max && initialValues.length >= max ? <i className={'bi bi-pencil-square'}> </i> : <i className={'bi bi-plus-lg'}> </i>}
                    </button>
                </span>
            </div>
        </div>
    )
}


