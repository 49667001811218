import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import CalendarSingle from "../../calendar/single";
import Timetable from "../../timetable";
import SalaryInformation from "./information";
import SalaryAbsence from "./absence";
import SalaryCounter from "./counter/index";
import SalaryCounterV2 from "./counter/test";
import SalaryStorage from "./storage";
import SalaryDocument from "./document";

import * as accessRights from "../../../constants/accessRight";

import * as api from "../../../adapters/salary";
import * as billingApi from "../../../adapters/billing";
import * as actions from "../../../actions/salary";
import * as action from "../../../actions/salary";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { axiosError } from "../../../actions/axios";

import { Granted } from "../../../security/granted";
import getVersion from "../../../security/getVersion";
import hasModule from "../../../security/hasModule";
import HasModule from "../../../security/hasModule";
import BgSingle from "../../../utils/background/single";

const SalaryShow: React.FC = () => {
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state.salary)
    
    const [currentTab, setCurrentTab] = useState<string>('calendar')
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [billingOptions, setBillingOptions] = useState<{label: string, id: number, shouldUpgrade: boolean}[]>([])

    function handleBillingChange(cid: number)
    {
        billingApi.salary(cid, state.single.id)
            .then(r => dispatch(action.editSalarySuccess(r.data)))
    }

    useEffect(() => {
        if (params.id){
            dispatch(actions.showSalary());
            api.show(Number(params.id)).then(data => {                
                dispatch(actions.showSalarySuccess(data.data))
                dispatch(setPageTitle('Collaborateurs', data.data.firstname + ' ' + data.data.lastname))
                dispatch(setHelpProductSheetIds([3, 8, 14]))
            }).catch(error => dispatch(axiosError(error)))
        }

        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [params.id])

    useEffect(() => {
        if (state.single?.locked){
            billingApi.companies().then(r => setBillingOptions(r.data))
        }
    }, [state.single?.id])

    if (!state.single){
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    const NavMenu = () => {
        return (
            <div className={'w-100 position-sticky top-0 d-flex'} style={{zIndex: 1000}}>
                <ul className="nav bg-light nav-tabs flex-grow-1">
                    {Granted(accessRights.LIST_BOOKING, state.single.id) && <li className="nav-item">
                        <button
                            className={"nav-link text-primary" + (['calendar', 'timeClock'].includes(currentTab) ? ' active' : '')}
                            aria-current="page"                            
                            onClick={() => {
                                dispatch(setHelpProductSheetIds([3, 8, 14]))
                                setCurrentTab('calendar')
                            }}
                        >
                            <i className={'bi bi-calendar-check'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Planning</h4>
                        </button>
                    </li>}
                    {getVersion() === 1 && Granted(accessRights.EDIT_BOOKING, state.single.id) && <li className="nav-item">
                        <button
                            className={"nav-link text-primary" + ('timetable' === currentTab ? ' active' : '')}
                            aria-current="page"
                            onClick={() => {
                                dispatch(setHelpProductSheetIds([2, 6]))
                                setCurrentTab('timetable')
                            }}
                        >
                            <i className={'bi bi-calendar-event'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Emploi du temps</h4>
                        </button>
                    </li>}
                    {Granted(accessRights.LIST_SALARY, state.single.id) && <li className="nav-item">
                        <button
                            className={"nav-link text-primary" + (currentTab === 'information' ? ' active' : '')}
                            onClick={() => {
                                dispatch(setHelpProductSheetIds([]))
                                setCurrentTab('information')
                            }}
                        >
                            <i className={'bi bi-info-circle'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Informations</h4>
                        </button>
                    </li>}
                    {Granted(accessRights.LIST_ABSENCE, state.single.id) && <li className="nav-item">
                        <button
                            className={"nav-link text-primary" + (currentTab === 'absence' ? ' active' : '')}
                            onClick={() => {
                                dispatch(setHelpProductSheetIds([5]))
                                setCurrentTab('absence')
                            }}
                        >
                            <i className={'bi bi-calendar-x'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Absences</h4>
                        </button>
                    </li>}
                    {Granted(accessRights.LIST_REPORTING, state.single.id) && <li className="nav-item">
                        <button
                            className={"nav-link text-primary" + (currentTab === 'counter' ? ' active' : '')}
                            onClick={() => {
                                dispatch(setHelpProductSheetIds([15]))
                                setCurrentTab('counter')
                            }}
                        >
                            <i className="bi bi-table"> </i>
                            <h4 className={'d-none d-md-inline-block'}> Rapport</h4>
                        </button>
                    </li>}
                    {Granted(accessRights.LIST_SALARY_FILE, state.single.id) && <li className="nav-item">
                        <button
                            className={"nav-link text-primary" + (currentTab === 'storage' ? ' active' : '')}
                            onClick={() => {
                                dispatch(setHelpProductSheetIds([16]))
                                setCurrentTab('storage')
                            }}
                        >
                            <i className={'bi bi-sd-card'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Stockage</h4>
                        </button>
                    </li>}
                    {Granted(accessRights.LIST_DOCUMENT, state.single.id) && HasModule('document') && <li className="nav-item">
                        <button
                            className={"nav-link text-primary" + (currentTab === 'document' ? ' active' : '')}
                            onClick={() => {
                                dispatch(setHelpProductSheetIds([18]))
                                setCurrentTab('document')
                            }}
                        >
                            <i className={'bi bi-file-earmark'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Documents</h4>
                        </button>
                    </li>}
                </ul>

                <Link to={'/salary'} className={'btn btn-sm btn-light flex-grow-0 d-block d-md-none m-1'}>
                    <i className={'bi bi-chevron-left'}> </i> Retour
                </Link>
            </div>
        )
    }

    return (
        <div id={'singleContainer'} className={"d-flex flex-column bg-white col-lg-9 col-md-8 h-100 px-0" + (!params.id ? " d-none d-md-flex" : "")}>
            {state.single.locked ?
                <div className={'w-100 h-100 py-5 px-3'}>
                    <h3 className={'mb-5'}>
                        L'établissement <span className={'text-primary'}>{state.single.billingCompany.title}</span> n'a plus d'abonnement actif.
                    </h3>

                    <Link className={'btn btn-light mb-3'} to={`/billing/${state.single.billingCompany.id}`}>
                        Souscrire un abonnement pour <span className={'text-primary'}>{state.single.billingCompany.title}</span>
                    </Link>
                    <p className={'mb-3'}>
                        Ou
                    </p>
                    <p>
                        Facturer <span className={'text-primary'}>{state.single.title}</span> sur un autre établissement:
                    </p>
                    <ul className={'list-group'}>
                        {billingOptions.map(b => <li className={'list-group-item d-flex'}>
                            <div className="form-check flex-grow-1">
                                <input type="checkbox" onChange={() => handleBillingChange(b.id)} disabled={b.shouldUpgrade} className="form-check-input"/>
                                <label htmlFor="">
                                    {b.label}
                                </label>
                            </div>
                            {b.shouldUpgrade && <Link className={"text-decoration-underline flex-grow-0"} to={`/billing/${b.id}`}>
                                Mettre à niveau <i className={'bi bi-arrow-right'}></i>
                            </Link>}
                        </li>)}
                    </ul>
                </div>
                :
                <>
                    <NavMenu />

                    <div className="container-fluid py-3 position-relative d-flex flex-column flex-grow-1 bg-light h-100 overflow-auto">
                        {Granted(accessRights.LIST_BOOKING, state.single.id) && currentTab === 'calendar' && <CalendarSingle salary={state.single} setDate={setCurrentDate} start={currentDate} />}
                        {Granted(accessRights.EDIT_BOOKING, state.single.id) && getVersion() === 1 && currentTab === 'timetable' && <Timetable salary={state.single} date={currentDate} />}
                        {Granted(accessRights.LIST_SALARY, state.single.id) && currentTab === 'information' && <SalaryInformation />}
                        {Granted(accessRights.LIST_ABSENCE, state.single.id) && currentTab === 'absence' && <SalaryAbsence salary={state.single} />}
                        {Granted(accessRights.LIST_COUNTER, state.single.id) && currentTab === 'counter' &&  (getVersion() === 1 ? <SalaryCounterV2 /> : <SalaryCounter />)}
                        {Granted(accessRights.LIST_SALARY_FILE, state.single.id) && currentTab === 'storage' && <SalaryStorage />}
                        {hasModule('document') && Granted(accessRights.LIST_DOCUMENT, state.single.id) && currentTab === 'document' && <SalaryDocument />}
                    </div>
                </>
            }
        </div>
    )
}

export default SalaryShow;