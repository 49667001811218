import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/localisation";
import * as api from "../../../adapters/localisation";
import Loader from "../../../utils/loader";
import '../../../utils/checkboxAndRadios/index.scss'
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {showAlertSuccess} from "../../../actions/alert";
import {Company} from "../../../models/companies";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import FormLocalisation from "../utils/form";
import {axiosError} from "../../../actions/axios";

const LocalisationSchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
    company: Yup.number().required('Champ requis'),
    icon: Yup.string().nullable(),
    color: Yup.string().nullable(),
});

export const LocalisationEdit: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

    }, [state.rightSidebar.data.id])

    if (isLoading) return <Loader />

    return (
        <div className="w-100 h-100 position-relative p-3">
                    <Formik
                        initialValues={{
                            title: state.rightSidebar.data.title,
                            description: state.rightSidebar.data.description,
                            company: state.rightSidebar.data.company.id,
                            icon: state.rightSidebar.data.icon,
                            color: state.rightSidebar.data.color
                        }}
                        validationSchema={LocalisationSchema}
                        onSubmit={values => {

                            dispatch(submitSidebar());
                            api.patch(values, parseInt(state.rightSidebar.data.id)).then(data => {
                                dispatch(actions.editLocalisationSuccess(data.data))
                                dispatch(closeSidebar())
                                dispatch(showAlertSuccess(data.data.title + " à bien été modifié !"))
                            }).catch(error => dispatch(axiosError(error)))
                        }}
                    >
                        {({ errors, touched, values, setValues }) => (
                            <FormLocalisation
                                errors={errors}
                                touched={touched}
                                values={values}
                                setValues={setValues}
                            />
                        )}
                    </Formik>
        </div>
    )
}

export default LocalisationEdit;