import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import format from "../../../utils/locale";
import {KeepNote, KeepNotes} from "../../../models/keepNote";
import * as api from "../../../adapters/keepNote";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_KEEP_NOTE_EDIT, FORM_KEEP_NOTE_LIST} from "../../../constants/rightSidebar";
import {removeKeepNoteSuccess} from "../../../actions/keepNote";
import {showAlertSuccess} from "../../../actions/alert";
import {axiosError} from "../../../actions/axios";

const KeepNoteList:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state.keepNote);
    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const filterDate = useSelector((state: RootStateOrAny) => state.rightSidebar.data?.dueDate)
    const [isRemoving, setIsRemoving] = useState<boolean>(false);
    const [removingKeepNote, setRemovingKeepNote] = useState<KeepNote>();

    const remove = (keepNote: KeepNote) => {
        setIsRemoving(true);
        setRemovingKeepNote(keepNote);
        api.cancel(keepNote.id).then(data => {
            dispatch(removeKeepNoteSuccess(keepNote))
            dispatch(showAlertSuccess('Note archivée'))
            setIsRemoving(false)
        }).catch(error => dispatch(axiosError(error)))
    }

    return <>
        <ul className={'list-group list-group-flush'}>
            {state.payload.filter((keeNote: KeepNote) => {
                if (!filterDate) return true;
                return format(new Date(keeNote.dueDate)) === format(filterDate)
            }).map((keepNote: KeepNote) => {
                let dueDateFormat: string;
                let tomorrow = new Date((new Date()).setDate((new Date().getDate() + 1)))
                switch (format(new Date(keepNote.dueDate))){
                    case format(new Date()):
                        dueDateFormat = "Aujourd'hui";
                        break;
                    case format(tomorrow):
                        dueDateFormat = "Demain";
                        break;
                    default:
                        dueDateFormat = "Le " + format(new Date(keepNote.dueDate));
                }

                return <li className={'list-group-item d-flex align-items-center'}>
                    <div className="col">
                        <figure>
                            <blockquote className="blockquote">
                                <p>{keepNote.description}</p>
                            </blockquote>
                            <figcaption className="blockquote-footer">
                                Échéance <b>{dueDateFormat}</b> par <cite
                                title="Source Title">{keepNote.createdBy.title}</cite>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-auto">
                        <button className={'btn btn-light'}>
                            <i className={keepNote.private ? "bi bi-lock-fill" : "bi bi-unlock-fill"}> </i>
                        </button>
                        <button className={'btn btn-light text-primary ms-3'}
                                onClick={() => dispatch(openSidebar(FORM_KEEP_NOTE_EDIT, {
                                    next: FORM_KEEP_NOTE_LIST,
                                    data: data,
                                    id: keepNote.id,
                                    private: keepNote.private,
                                    description: keepNote.description,
                                    dueDate: new Date(keepNote.dueDate)
                                }))}>
                            <i className={'bi bi-pencil-square'}> </i>
                        </button>
                        <button disabled={isRemoving} className={'btn btn-light text-danger ms-3'}
                                onClick={() => remove(keepNote)}>
                            {removingKeepNote === keepNote ?
                                <div className="spinner-border spinner-border-sm text-danger" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                : <i className={'bi bi-trash'}> </i>}
                        </button>
                    </div>
                </li>
            })}
        </ul>
    </>
}

export default KeepNoteList;