import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";


const EmailSettings: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)


    return <>
        <div className="col-12 mb-2">
            <div className="p-3">
                <div className="card p-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Envoi automatique des plannings
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez envoyer les plannings automatiquement une fois par semaine
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Gestion des absences
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez informer vos collaborateurs par email en cas de gestion d'absence
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default EmailSettings;