import * as types from "../../constants/companies"
import * as models from "../../models/companies"
import replace from "../../utils/arrayReplace"

const companies = (state:models.initialStateInterface = models.initialState, action: {type: string, company: models.Company, companies: models.Companies, error: string}) => {

    let companies;
    switch (action.type) {

        case types.FETCH_COMPANIES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_COMPANIES_SUCCESS:
            return { payload: action.companies, single: state.single, isLoading: false, error: null };
        case types.FETCH_COMPANIES_FAILURE:
            return { payload: [], single: state.single, isLoading: false, error: action.error };

        case types.ADD_COMPANY:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.ADD_COMPANY_SUCCESS:
            companies = [
                ...state.payload,
                action.company
            ];
            return { payload: companies, single: action.company, isLoading: false, error: null };

        case types.EDIT_COMPANY:
            return { payload: state.payload, single: action.company, isLoading: true, error: null };
        case types.EDIT_COMPANY_SUCCESS:
            return { payload:  replace(state.payload, action.company), single: action.company, isLoading: false, error: null };

        case types.SHOW_COMPANY:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.SHOW_COMPANY_SUCCESS:
            return { payload:  state.payload, single: action.company, isLoading: false, error: null };
        default:
            return state;
    }
}

export default companies;