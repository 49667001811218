import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import FullCalendar, { DateSelectArg, EventClickArg, EventDropArg, EventHoveringArg } from "@fullcalendar/react";
import interactionPlugin, { EventResizeDoneArg } from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import CalendarCompanySelector from "../modal/company";
import allLocales from "@fullcalendar/core/locales-all";
import CalendarTeamSelector from "../modal/team";
import CalendarJobSelector from "../modal/job";
import CalendarSetting from "../modal/settings";
import CalendarPresence from "../presence";
import CalendarOrder from "../order";
import CalendarReporting from "../reporting";
import CalendarEventSidebar, { CalendarEventSidebarInterface } from "../event/sidebar";
import CalendarBookingSidebar, { CalendarBookingSidebarInterface } from "../booking/sidebar";
import CalendarSignedSidebar, { CalendarSignedSidebarInterface } from "../signed/sidebar";
import CalendarAbsenceList from "../absence/list";
import CalendarAbsenceSidebar, { CalendarAbsenceSidebarInterface } from "../absence/sidebar";
import CalendarOfferList from "../offer/list";
import CalendarOfferSidebar, { CalendarOfferSidebarInterface } from "../offer/sidebar";
import CalendarKeepNoteSidebar, { CalendarKeepNoteSidebarInterface } from "../keepNote/sidebar";
import CalendarTaskSidebar, { CalendarTaskSidebarInterface } from "../task/sidebar";
import CalendarTaskList from "../task/list";
import CalendarDownload from "../download/test";
import CalendarPublish from "../publish";
import CalendarTemplateSidebar, {CalendarTemplateSidebarInterface} from "../template/sidebar";
import CalendarTimetable, { CalendarTimetableInterface } from "../timetable";
import listPlugin from "@fullcalendar/list";
import { Tooltip } from "bootstrap";

import { Companies, Company } from "../../../models/companies";
import { Teams } from "../../../models/team";
import { Syntheses } from "../../../models/synthesis";
import * as calendarTypes from "../../../constants/calendar";
import * as accessRights from "../../../constants/accessRight";
import { ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED } from "../../../constants/booking";

import * as api from "../../../adapters/calendar";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";

import { Granted, GrantedAny } from "../../../security/granted";
import hasModule from "../../../security/hasModule";
import getVersion from "../../../security/getVersion";
import getMaxVisibilityDate from "../../../security/getMaxDate";
import format from "../../../utils/locale";
import { RenderResource, resourceLaneContent, CalendarTitle, renderEvent, ResourceAreaHeaderContent, renderDaySeparator } from "../utils";
import Loader from "../../../utils/loader";
import onMouseEnterTooltip from "../../../utils/mouseOverTooltip";
import { setHours, setMinutes } from "date-fns";

interface ICalendarProps {
    isNightTime: boolean;
    loading: boolean;
    resources: any[];
    events: any[];
    offers: any[];
    keepNotes: any[];
    signed: any[];
    tasks: any[];
    absences: any[];
    presences: any[];
    timeSheets: Syntheses;
    templates: any[];
    initialDate: Date;
    start: Date;
    end: Date;
    slotMinTime: string;
    slotMaxTime: string;
    maxDate?: Date;
}

const CalendarMultiple: React.FC = () => {
    const params: { initialDate?: string, initialCalendar?: string } = useParams();
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state)

    const calendarRef = React.createRef<FullCalendar>();

    const [calendar, setCalendar] = useState<string>(params.initialCalendar || calendarTypes.CALENDAR_EVENTS_GLOBAL);
    const [calendarData, setCalendarData] = useState<ICalendarProps>({
        isNightTime: false,
        loading: true,
        resources: [],
        events: [],
        offers: [],
        keepNotes: [],
        signed: [],
        tasks: [],
        absences: [],
        presences: [],
        timeSheets: [],
        templates: [],
        initialDate: new Date(params.initialDate || new Date()),
        start: new Date(),
        end: new Date(),
        maxDate: getMaxVisibilityDate(),
        slotMinTime: '08:00:00',
        slotMaxTime: '20:00:00',
    });

    const [events, setEvents] = useState<any[]>([])
    const [resources, setResources] = useState<any[]>([])

    const [view, setView] = useState<string>(window.innerWidth >= 768 ? (calendar === calendarTypes.CALENDAR_ABSENCE ? calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW : state.auth.user.calendarSettings.defaultView) : calendarTypes.LIST_DAY_VIEW);
    const [displayEventTime, setDisplayEventTime] = useState(view !== calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW);
    const [sm, setSm] = useState(window.innerWidth < 768)
    const [ratio, setRatio] = useState<"s"|"m"|"l">(state.auth.user.calendarSettings.ratio || "l");
    const [smViewType, setSmViewType] = useState<'list'|'timeline'>('list')
    const [title, setTitle] = useState<string>('');

    const getDefaultCompaniesToDisplay = () => {
        let newTab: Company[] = []

        const localSettings = localStorage.getItem('defaultCompanies')
        
        if (localSettings !== null) {
            const parsedTab = JSON.parse(localSettings)
            newTab = state.auth.user.currentSalary.companies.filter((company: Company) => parsedTab.includes(company.id))
        } else {
            newTab.push(state.auth.user.currentSalary.companies[0])
        }

        return newTab
    }    

    const [companies, setCompanies] = useState<Companies>(getDefaultCompaniesToDisplay());
    const [job, setJob] = useState<1|2|0>(0);
    const [teams, setTeams] = useState<Teams>([]);
    const [sortBy, setSortBy] = useState<1|2|3>(calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL ? (state.auth.user.calendarSettings.defaultGroupBy || 1) : 1);
    const [isOuterEvents, setOuterEvents] = useState<boolean>(state.auth.user.calendarSettings.displayOuter);
    const [presence, setPresence] = useState<boolean>(state.auth.user.calendarSettings.isPresence);
    const [daysOfWeek, setDaysOfWeek] = useState<number[]>(state.auth.user.calendarSettings.daysOfWeek);
    const [displayEvents, setDisplayEvents] = useState<boolean>(true);

    const [isAbsence, setIsAbsence] = useState(state.auth.user.calendarSettings.displayAbsence);
    const [isOffers, setIsOffers] = useState(false)
    const [isOffersOnly, setIsOffersOnly] = useState(false)
    const [displayTaskSidebar, setDisplayTaskSidebar] = useState<boolean>( false)
    const [timePrecision, setTimePrecision] = useState(state.auth.user.calendarSettings.timePrecision)
    const [filterEventsWithResources, setFilterEventsWithResources] = useState(!state.auth.user.calendarSettings.displayEmpty)

    const [calendarEventSidebarProps, setCalendarEventSidebarProps] = useState<CalendarEventSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarOfferSidebarProps, setCalendarOfferSidebarProps] = useState<CalendarOfferSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarBookingSidebarProps, setCalendarBookingSidebarProps] = useState<CalendarBookingSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarSignedSidebarProps, setCalendarSignedSidebarProps] = useState<CalendarSignedSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarAbsenceSidebarProps, setCalendarAbsenceSidebarProps] = useState<CalendarAbsenceSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarKeepNoteSidebarProps, setCalendarKeepNoteSidebarProps] = useState<CalendarKeepNoteSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarTaskSidebarProps, setCalendarTaskSidebarProps] = useState<CalendarTaskSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarTemplateSidebarProps, setCalendarTemplateSidebarProps] = useState<CalendarTemplateSidebarInterface>({sidebarType: 'EMPTY', trigger: 0});
    const [calendarTimetableProps, setCalendarTimeTableProps] = useState<CalendarTimetableInterface>({});

    const [reportingOrigin, setReportingOrigin] = useState<string>('')
    const [isBackgroundEvents, setIsBackgroundEvents] = useState(state.auth.user.calendarSettings.displayBackgroundEdit);
    const [pickerDate, setPickerDate] = useState(new Date(params.initialDate || new Date()));

    function fetchCalendar(overrides?: { overrideCalendar?: string, overrideView?: string, overrideTimePrecision?: string, overridePresence?: boolean, overrideSortBy?: number, salary?: number }){
        const { overrideCalendar, overrideView, overrideTimePrecision, overridePresence, overrideSortBy } = overrides || {};

        const API = calendarRef.current?.getApi();

        if (API) {
            setCalendarData(prev => ({
                loading: true,
                resources: [],
                events: [],
                offers: [],
                keepNotes: [],
                signed: [],
                tasks: [],
                absences: [],
                presences: [],
                timeSheets: [],
                templates: [],
                initialDate: API.getDate(),
                start: API.view.currentStart,
                end: API.view.currentEnd,
                isNightTime: prev.isNightTime,
                slotMinTime: prev.slotMinTime,
                slotMaxTime: prev.slotMaxTime
            }));

            api.test({
                start: format(API.view.currentStart, 'uuuu-MM-dd'),
                end: format(API.view.currentEnd, 'uuuu-MM-dd'),
                calendar: overrideCalendar || calendar,
                options: {
                    view: overrideView || API.view.type,
                    outerEvents: true,
                    isNightTime: calendarData.isNightTime,
                    job: job,
                    sortBy: overrideSortBy || sortBy,
                    companies: companies.map(c => c.id!),
                    timePrecision: overrideTimePrecision || timePrecision,
                    presence: overridePresence || presence,
                    teams: teams.map(t => t.id)
                }
            }).then((data) => {                
                let slotMaxTime =  (new Date(data.data.timeRange.maxTime).getDate() - new Date(data.data.timeRange.minTime).getDate()) + '.' + format(new Date(data.data.timeRange.maxTime), "HH:mm:ss");
                if (slotMaxTime === "1.00:00:00") {
                    slotMaxTime = '24:00:00';
                }

                setCalendarData(prev => ({
                    loading: false,
                    resources: data.data.resources || [],
                    events: data.data.events || [],
                    signed: data.data.signed || [],
                    absences: data.data.absences || [],
                    presences: data.data.presences || [],
                    timeSheets: [...data.data.eventsTimeSheet || [], ...data.data.signedTimeSheet || []],
                    offers: data.data.eventOffers || [],
                    tasks: data.data.tasks || [],
                    keepNotes: data.data.keepNotes || [],
                    templates: data.data.templateApplied || [],
                    initialDate: API.getDate(),
                    start: API.view.currentStart,
                    end: API.view.currentEnd,
                    maxDate: prev.maxDate,
                    isNightTime: prev.isNightTime,
                    slotMinTime: format(new Date(data.data.timeRange.minTime), "HH:mm:ss"),
                    slotMaxTime: slotMaxTime,
                }))
            })
        }
    }

    const refreshCalendar = (salaries: number[]) => {
        return api.test({
            start: format(calendarData.start, 'uuuu-MM-dd'),
            end: format(calendarData.end, 'uuuu-MM-dd'),
            calendar: calendar,
            options: {
                view: view,
                sortBy: sortBy,
                isNightTime: calendarData.isNightTime,
                companies: companies.map(c => c.id!),
                timePrecision: timePrecision,
                presence: presence,
                outerEvents: true,
                salaries: salaries,
            }
        }).then((data) => {
            setCalendarData(prev => ({
                loading: false,
                resources: [
                    ...[...prev.resources.filter((r: any) => !([...data.data.resources || []].map((_r: any) => _r.id).includes(r.id)))],
                    ...data.data.resources
                ],
                keepNotes: prev.keepNotes,
                timeSheets: [...prev.timeSheets.filter((t: any) => !(salaries.includes(t.salary.id))),...data.data.eventsTimeSheet || [], ...data.data.signedTimeSheet || []],
                presences: [...prev.presences.map((p: any) => {
                    let _p = {...p};
                    _p.values = p.values.filter((v: any) => !(salaries.includes(v.salaryId)));

                    let allValues: any[] = [];
                    for (let i in data.data.presences){
                        if (data.data.presences[i].resourceId === p.resourceId && data.data.presences[i].start === p.start){
                            allValues = [...allValues, ...data.data.presences[i].values];
                        }
                    }

                    _p.values = [..._p.values, ...allValues]

                    return _p;
                })],
                offers: [...[...prev.offers.filter((e: any) => !(e.salaries.filter((s: any) => salaries.includes(s.id)).length))], ...data.data.eventOffers || []],
                absences: [...prev.absences.filter((a: any) => !(salaries.includes(a.absence.salary.id))), ...data.data.absences || []],
                signed: [...[...prev.signed.filter((e: any) => !(salaries.includes(e.salary.id)))], ...data.data.signed || []],
                events: [...[...prev.events.filter((e: any) => !(salaries.includes(e.salary.id)))], ...data.data.events || []],
                tasks: [...[...prev.tasks.filter((e: any) => !(e.salaries.filter((s: any) => salaries.includes(s.id)).length))], ...data.data.tasks || []],
                templates: data.data.templateApplied,
                initialDate: prev.initialDate,
                start: prev.start,
                end: prev.end,
                maxDate: prev.maxDate,
                isNightTime: prev.isNightTime,
                slotMinTime: prev.slotMinTime,
                slotMaxTime: prev.slotMaxTime,
            }))
        }).then(() => true)
    }

    const handleResize = () => {
        if (window.innerWidth >= 768) {
            setSm(false)
        } else if (window.innerWidth < 768) {
            setSm(true)
        }
    }

    const getResources = () => {
        let ret:any[] = [];

        if (calendarData.tasks.length > 0 && calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL) {
            ret.push({
                groupId: "Notes",
                id: "keepNotes",
                order1: 0,
                resourceType: "TYPE_KEEP_NOTE",
                title: "Notes"
            })
        }

        return [...ret, ...calendarData.resources.filter((r: any) => {
            return !(!state.auth.user.calendarSettings.isTasks && r.resourceType === calendarTypes.RESOURCE_TYPE_TASK);
        })];
    }

    const setItems = () => {
        setResources(() => getResources())
        setEvents(() => getEvents())

        const API = calendarRef.current?.getApi();

        setTimeout(() => {

            if (API){
                API.updateSize();
            }

        }, 1000)
    }

    const getEvents = () => {
        if (calendar === calendarTypes.CALENDAR_ABSENCE) {
            if (displayEvents) {
                return [...calendarData.events, ...calendarData.absences, ...calendarData.presences]
            } else {
                return calendarData.absences
            }
        } else if (calendar === calendarTypes.CALENDAR_SIGNED_GLOBAL) {
            if (displayEvents) {
                return [...calendarData.signed, ...calendarData.events]
            } else {
                return [...calendarData.signed];
            }
        } else if (calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL) {

            if (isOffers && isOffersOnly) {
                return [...calendarData.presences, ...calendarData.offers]
            } else {
                return [
                    ...calendarData.events.filter((e: any) => (isBackgroundEvents || e.eventType !== calendarTypes.EVENT_TYPE_BACKGROUND) && (isAbsence || (!e.absence && !([ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED, ACCOUNTING_TYPE_ABSENCE_ACCOUNTED].includes(e.accountingType)))) && (isOuterEvents || !e.isOuter)),
                    ...calendarData.presences,
                    ...calendarData.offers.filter((o:any) => isOffers || o.eventType === calendarTypes.EVENT_TYPE_OFFER_VALIDATED),
                    ...calendarData.keepNotes,
                    ...calendarData.tasks.filter(() => state.auth.user.calendarSettings.isTasks),
                    ...calendarData.absences
                ]
            }
        } else if (calendar === calendarTypes.CALENDAR_TASK) {
            return [
                ...calendarData.tasks,
                ...calendarData.events
            ]
        }

        return [];
    }

    const handleEventChange = (ev: any) => {
        let salaries: number[] = [];

        if (ev.salary) {
            salaries.push(ev.salary.id);
        }

        if (ev.substitute) {
            salaries.push(ev.substitute.id);
        }

        if (ev.substituted) {
            salaries.push(ev.substituted.id);
        }

        if (ev.salaries) {
            ev.salaries.map((s: any) => salaries.push(s.id))
        }

        if (ev.ids) {
            ev.ids.map((id: number) => salaries.push(id))
        }

        return refreshCalendar([...salaries, ...ev.previousSalaries || []]);
    }

    function handlePickerDateChange(dt: Date)
    {
        let API = calendarRef.current?.getApi()
        if (API){
            setPickerDate(dt)
            API.gotoDate(dt)
            fetchCalendar()
        }
    }

    function prevClick(){
        let API = calendarRef.current?.getApi()
            if (API){
                API.prev()
            setTitle(API.view.title)
            fetchCalendar()
        }
    }

    function nextClick(){
        let API = calendarRef.current?.getApi()
            if (API){
                API.next()
            setTitle(API.view.title)
            fetchCalendar()
        }
    }

    function changeView(_view: string)
    {
        let API = calendarRef.current?.getApi()
        if (API){
            setView(_view)
            API.changeView(_view);
            setTitle(API.view.title)
            fetchCalendar()
        }
    }

    function handleTimePrecisionChange(_timePrecision: string){
        setTimePrecision(_timePrecision)
        if (calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && view === 'resourceTimelineWeek'){
            fetchCalendar({overrideTimePrecision: _timePrecision})
        }
    }

    function handlePresenceChange(_presence: boolean){
        setPresence(_presence)
        if (_presence && calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL){
            fetchCalendar({overridePresence: _presence})
        }
    }

    function handleCalendarChange(calendar: string) {
        let API = calendarRef.current?.getApi()

        if (API){

            setCalendar(calendar)

            let overrideView: undefined|string = undefined;
            let overrideSortBy: undefined|number;
            let overrideTimePrecision: undefined|string;

            if ([calendarTypes.CALENDAR_SIGNED_GLOBAL, calendarTypes.CALENDAR_ABSENCE, calendarTypes.CALENDAR_TASK].includes(calendar)){
                setSortBy(1);
                overrideSortBy = 1;
            }else{
                setSortBy(state.auth.user.calendarSettings.defaultGroupBy);
                overrideSortBy = state.auth.user.calendarSettings.defaultGroupBy;
                overrideTimePrecision = state.auth.user.calendarSettings.timePrecision;
            }

            if ([calendarTypes.CALENDAR_ABSENCE, calendarTypes.CALENDAR_TASK].includes(calendar) && (!sm || smViewType === "timeline")){
                setView(calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW);
                overrideView = calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW
                API.changeView(calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW);
                setTitle(API.view.title)
            }

            if ([calendarTypes.CALENDAR_EVENTS_GLOBAL, calendarTypes.CALENDAR_SIGNED_GLOBAL].includes(calendar) && (!sm || smViewType === "timeline")){
                setView(state.auth.user.calendarSettings.defaultView);
                API.changeView(state.auth.user.calendarSettings.defaultView);
                overrideView = state.auth.user.calendarSettings.defaultView
                setTitle(API.view.title)
            }

            fetchCalendar({overrideCalendar: calendar,
                overrideView: overrideView,
                overrideSortBy: overrideSortBy,
                overrideTimePrecision: overrideTimePrecision,
            })
        }
    }

    function handleSortByChange(_sortBy: 1|2|3) {
        setSortBy(_sortBy)
        fetchCalendar({overrideSortBy: _sortBy})
    }

    function onResize(arg: EventResizeDoneArg){
        switch (arg.event.extendedProps.eventType){
            case calendarTypes.EVENT_TYPE_EVENT:
            case calendarTypes.EVENT_TYPE_EVENT_EXCEPTION:
                if (Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id)){
                    setCalendarEventSidebarProps(prev => ({
                        sidebarType: "EVENT_RESIZE",
                        trigger: prev.trigger + 1,
                        arg: arg
                    }))
                }
                break;
            case calendarTypes.EVENT_TYPE_BOOKING:
            case calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION:
                if (Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id)){
                    setCalendarBookingSidebarProps(prev => ({
                        sidebarType: "BOOKING_RESIZE",
                        trigger: prev.trigger + 1,
                        arg: arg,
                    }))
                }
                break;
            case calendarTypes.EVENT_TYPE_TIME_CLOCK:
            case calendarTypes.EVENT_TYPE_TIME_CLOCK_EXCEPTION:
                if (Granted(accessRights.EDIT_TIME_CLOCK, arg.event.extendedProps.salary.id)){
                    setCalendarSignedSidebarProps(prev => ({
                        sidebarType: "SIGNED_RESIZE",
                        trigger: prev.trigger + 1,
                        arg: arg,
                    }))
                }
                break;
        }
    }

    function onDrop(arg: EventDropArg){
        switch (arg.event.extendedProps.eventType){
            case calendarTypes.EVENT_TYPE_EVENT:
            case calendarTypes.EVENT_TYPE_EVENT_EXCEPTION:
                if (Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id)){
                    setCalendarEventSidebarProps(prev => ({
                        sidebarType: "EVENT_DROP",
                        trigger: prev.trigger + 1,
                        arg: arg,
                    }))
                }
                break;
            case calendarTypes.EVENT_TYPE_BOOKING:
            case calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION:
                if (Granted(accessRights.EDIT_BOOKING, arg.event.extendedProps.salary.id)){
                    setCalendarBookingSidebarProps(prev => ({
                        sidebarType: "BOOKING_DROP",
                        trigger: prev.trigger + 1,
                        arg: arg
                    }))
                }
                break;
            default:
                arg.revert();
        }
    }

    function onSelect(arg: DateSelectArg) {
        let resource = arg.resource;

        if (arg){
            let slotDuration: any = arg.view.getOption('slotDuration')
            if (slotDuration.days === 1){
                arg.start = setHours(setMinutes(new Date(arg.start), 0), (new Date()).getHours());
                arg.end = setHours(setMinutes(new Date(arg.start), 30), (new Date()).getHours());
            }
        }

        if (resource){
            switch (resource.extendedProps.resourceType){
                case calendarTypes.RESOURCE_TYPE_EVENT:

                    if (calendar !== calendarTypes.CALENDAR_EVENTS_GLOBAL && !displayEvents) return;

                    switch (getVersion()) {
                        case 1:
                            if (isOffers){
                                if (Granted(accessRights.EDIT_BOOKING_OFFER, arg.resource?.extendedProps.salary?.id)){
                                    setCalendarOfferSidebarProps(prev => ({
                                        sidebarType: "OFFER_ADD",
                                        trigger: prev.trigger + 1,
                                        start: arg.start,
                                        end: arg.end,
                                        salary: arg.resource?.extendedProps.salary,
                                        company: arg.resource?.extendedProps.company,
                                        localisation: arg.resource?.extendedProps.localisation,
                                        activity: arg.resource?.extendedProps.activity,
                                    }))
                                }
                            }else{
                                if (Granted(accessRights.EDIT_BOOKING, arg.resource?.extendedProps.salary?.id)){
                                    setCalendarEventSidebarProps(prev => ({
                                        sidebarType: "EVENT_SELECT",
                                        trigger: prev.trigger + 1,
                                        arg: arg,
                                        salary: arg.resource?.extendedProps.salary,
                                        company: arg.resource?.extendedProps.company,
                                        localisation: arg.resource?.extendedProps.localisation,
                                        activity: arg.resource?.extendedProps.activity,
                                    }))
                                }
                            }
                            break;
                        default:
                            if (Granted(accessRights.EDIT_BOOKING, arg.resource?.extendedProps.salary?.id)){
                                setCalendarBookingSidebarProps(prev => ({
                                    sidebarType: "BOOKING_SELECT",
                                    trigger: prev.trigger + 1,
                                    arg: arg,
                                    salary: arg.resource?.extendedProps.salary,
                                    company: arg.resource?.extendedProps.company,
                                    localisation: arg.resource?.extendedProps.localisation,
                                    activity: arg.resource?.extendedProps.activity,
                                }))
                            }
                            break;
                    }
                    break;
                case calendarTypes.RESOURCE_TYPE_SIGNED:
                    if (Granted(accessRights.EDIT_TIME_CLOCK, arg.resource?.extendedProps.salary?.id)){
                        setCalendarSignedSidebarProps(prev => ({
                            sidebarType: "SIGNED_SELECT",
                            trigger: prev.trigger + 1,
                            arg: arg,
                            salary: arg.resource?.extendedProps.salary,
                            company: arg.resource?.extendedProps.company
                        }))
                    }
                    break;
                case calendarTypes.RESOURCE_TYPE_KEEP_NOTE:
                    if (Granted(accessRights.EDIT_KEEP_NOTE, arg.resource?.extendedProps.salary?.id)){
                        setCalendarKeepNoteSidebarProps(prev => ({
                            sidebarType: "KEEP_NOTE_ADD",
                            trigger: prev.trigger + 1,
                            dueDate: arg.start
                        }))
                    }
                    break;
                case calendarTypes.RESOURCE_TYPE_TASK:
                    if (Granted(accessRights.EDIT_TASK, arg.resource?.extendedProps.salary?.id)){
                        setCalendarTaskSidebarProps(prev => ({
                            sidebarType: "TASK_SELECT",
                            trigger: prev.trigger + 1,
                            arg: arg
                        }))
                    }
                    break;
            }
        }
    }

    function onClick(arg: EventClickArg) {
            
        arg.jsEvent.stopPropagation()

        switch (arg.event.extendedProps.eventType){
            case calendarTypes.EVENT_TYPE_EVENT:
            case calendarTypes.EVENT_TYPE_EVENT_EXCEPTION:
                setCalendarEventSidebarProps(prev => ({
                    sidebarType: "EVENT_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    calendar: calendar,
                    setCalendarAbsenceSidebarProps: setCalendarAbsenceSidebarProps,
                    setCalendarEventSidebarProps: setCalendarEventSidebarProps,
                    setCalendarSignedSidebarProps: setCalendarSignedSidebarProps,
                }))
                break;
            case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT:
            case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_EXCEPTION:
            case calendarTypes.EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION:
                setCalendarTemplateSidebarProps(prev => ({
                    sidebarType: "TEMPLATE_EVENT_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    calendar: calendar,
                    setCalendarAbsenceSidebarProps: setCalendarAbsenceSidebarProps,
                    setCalendarTemplateSidebarProps: setCalendarTemplateSidebarProps,
                    setCalendarSignedSidebarProps: setCalendarSignedSidebarProps,
                }))
                break;
            case calendarTypes.EVENT_TYPE_BOOKING:
            case calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION:
                setCalendarBookingSidebarProps(prev => ({
                    sidebarType: "BOOKING_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarSidebarSignedProps: setCalendarSignedSidebarProps,
                    calendar: calendar,
                    setCalendarSidebarBookingProps: setCalendarBookingSidebarProps,
                    setCalendarAbsenceSidebarProps: setCalendarAbsenceSidebarProps
                }))
                break;
            case calendarTypes.EVENT_TYPE_TIME_CLOCK:
            case calendarTypes.EVENT_TYPE_TIME_CLOCK_EXCEPTION:
                setCalendarSignedSidebarProps(prev => ({
                    sidebarType: "SIGNED_SHOW",
                    trigger: prev.trigger + 1,
                    setCalendarSignedSidebarProps: setCalendarSignedSidebarProps,
                    company: arg.event.extendedProps.company,
                    end: arg.event.end,
                    _end: arg.event.extendedProps._end,
                    start: arg.event.start,
                    _start: arg.event.extendedProps._start,
                    isAnomaly: arg.event.extendedProps.isAnomaly,
                    salary: arg.event.extendedProps.salary,
                    timeClockId: arg.event.extendedProps.timeClockId,
                    files: arg.event.extendedProps.files
                }))
                break;
            case calendarTypes.EVENT_TYPE_ABSENCE:
                if (Granted(accessRights.EDIT_ABSENCE, undefined, arg.event.extendedProps.absence)){
                    setCalendarAbsenceSidebarProps(prev => ({
                        sidebarType: "ABSENCE_EDIT",
                        trigger: prev.trigger + 1,
                        arg: arg,
                    }))
                }
                break;
            case calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING:
                if (Granted(accessRights.EDIT_TIME_CLOCK, arg.event.extendedProps.salary.id)){
                    setCalendarSignedSidebarProps(prev => ({
                        sidebarType: "SIGNED_ADD",
                        trigger: prev.trigger + 1,
                        company: arg.event.extendedProps.company,
                        salary: arg.event.extendedProps.salary,
                        start: arg.event.start!,
                        end: arg.event.end!,
                    }))
                }
                break;
            case calendarTypes.EVENT_TYPE_OFFER_PENDING:
            case calendarTypes.EVENT_TYPE_OFFER_VALIDATED:
                setCalendarOfferSidebarProps(prev => ({
                    sidebarType: "OFFER_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarOfferSidebarProps: setCalendarOfferSidebarProps
                }))
                break;
            case calendarTypes.EVENT_TYPE_KEEP_NOTE:
                setCalendarKeepNoteSidebarProps(prev => ({
                    sidebarType: "KEEP_NOTE_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarKeepNoteSidebarProps: setCalendarKeepNoteSidebarProps
                }))
                break;
            case calendarTypes.EVENT_TYPE_TASK:
            case calendarTypes.EVENT_TYPE_TASK_EXCEPTION:
                setCalendarTaskSidebarProps(prev => ({
                    sidebarType: "TASK_SHOW",
                    trigger: prev.trigger + 1,
                    arg: arg,
                    setCalendarTaskSidebarProps: setCalendarTaskSidebarProps
                }))
                break;
        }
    }

    function eventMouseEnter(e: EventHoveringArg) {
        switch (e.event.extendedProps.eventType) {
            case calendarTypes.EVENT_TYPE_PRESENCE:
                let event: any = e.event.extendedProps;
                switch (event.presenceType) {
                    case calendarTypes.EVENT_TYPE_PRESENCE_GLOBAL:
                        event.values.map((c: any) => {
                            let el = document.getElementById(c.eventId) as HTMLDivElement;
                            if (el && el.parentElement) {
                                if (el.parentElement.parentElement) {
                                    el.parentElement.parentElement.classList.toggle('fc-highlight-primary')
                                }
                            }
                        })
                        break;
                    case calendarTypes.EVENT_TYPE_PRESENCE_BY_JOB:
                        event.values.map((cx: any) => cx.values.map((c: any) => {
                            let el = document.getElementById(c.eventId) as HTMLDivElement;
                            if (el && el.parentElement) {
                                if (el.parentElement.parentElement) {
                                    el.parentElement.parentElement.classList.toggle('fc-highlight-primary')
                                }
                            }
                        }))
                        break;
                }
                break;
            default:
                let el = e.el.querySelector(`[id="title${e.event.id}"]`) as HTMLSpanElement;
                if (el) {
                    el.textContent = e.event.extendedProps.alternativeTitle
                }

                if (e.event.extendedProps.comment && e.event.extendedProps.salary && Granted(accessRights.EDIT_BOOKING, e.event.extendedProps.salary.id))
                {
                    let tooltip = new Tooltip(e.el, {trigger: "hover", html: true, title: e.event.extendedProps.comment})
                    tooltip.show();

                    e.el.addEventListener('click', () => tooltip.hide());
                    e.el.addEventListener('mouseleave', () => tooltip.hide());
                }
                break;
        }
    }

    function eventMouseLeave(e: EventHoveringArg){
        switch (e.event.extendedProps.eventType) {
            case calendarTypes.EVENT_TYPE_PRESENCE:
                let event: any = e.event.extendedProps;

                switch (event.presenceType){
                    case calendarTypes.EVENT_TYPE_PRESENCE_GLOBAL:
                        event.values.map((c: any) => {
                            let el = document.getElementById(c.eventId) as HTMLDivElement;
                            if (el && el.parentElement) {
                                if (el.parentElement.parentElement) {
                                    el.parentElement.parentElement.classList.toggle('fc-highlight-primary')
                                }
                            }
                        })
                        break;
                    case calendarTypes.EVENT_TYPE_PRESENCE_BY_JOB:
                        event.values.map((cx: any) => cx.values.map((c: any) => {
                            let el = document.getElementById(c.eventId) as HTMLDivElement;
                            if (el && el.parentElement) {
                                if (el.parentElement.parentElement) {
                                    el.parentElement.parentElement.classList.toggle('fc-highlight-primary')
                                }
                            }
                        }))
                        break;
                }
                break;
            default:
                let el = e.el.querySelector(`[id="title${e.event.id}"]`) as HTMLSpanElement;
                if (el) {
                    el.textContent = e.event.title
                }
                break;
        }
    }

    useEffect(() => {
        const API = calendarRef.current?.getApi();

        if (API) {
            if (view === calendarTypes.RESOURCE_TIMELINE_DAY_VIEW) {
                API.setOption('slotMinWidth', 30)
                API.setOption('resourceAreaWidth', "15%")
            } else if (view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW) {
                switch (ratio) {
                    case 's':
                        API.setOption('slotMinWidth', 20)
                        API.setOption('resourceAreaWidth', "5%")
                        break;
                    case 'm':
                        API.setOption('slotMinWidth', 30)
                        API.setOption('resourceAreaWidth', "10%")
                        break;
                    case 'l':
                        API.setOption('slotMinWidth', 40)
                        API.setOption('resourceAreaWidth', "15%")
                        break;
                }
            } else if (view === calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW) {
                API.setOption('resourceAreaWidth', "15%")
                switch (calendar) {
                    case calendarTypes.CALENDAR_ABSENCE:
                        if (displayEventTime && displayEvents) {
                            API.setOption('slotMinWidth', 80)
                        } else {
                            API.setOption('slotMinWidth', 40)
                        }
                        break;
                    default:
                        if (displayEventTime) {
                            API.setOption('slotMinWidth', 80)
                        } else {
                            API.setOption('slotMinWidth', 40)
                        }
                        break;
                }
            }
        }
    }, [ratio, view, displayEventTime])

    useEffect(() => {
        if (sm) {
            switch (smViewType) {
                case "list":
                    if ([calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW, calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW, calendarTypes.RESOURCE_TIMELINE_DAY_VIEW].includes(view)){
                        switch (view) {
                            case calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW:
                                changeView(calendarTypes.LIST_MONTH_VIEW);
                                break;
                            case calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW:
                                changeView(calendarTypes.LIST_WEEK_VIEW);
                                break;
                            case calendarTypes.RESOURCE_TIMELINE_DAY_VIEW:
                                changeView(calendarTypes.LIST_DAY_VIEW);
                                break;
                        }
                    }
                    break;
                case "timeline":
                    if ([calendarTypes.LIST_MONTH_VIEW, calendarTypes.LIST_WEEK_VIEW, calendarTypes.LIST_DAY_VIEW].includes(view)){
                        switch (view) {
                            case calendarTypes.LIST_MONTH_VIEW:
                                changeView(calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW);
                                break;
                            case calendarTypes.LIST_WEEK_VIEW:
                                changeView(calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW);
                                break;
                            case calendarTypes.LIST_DAY_VIEW:
                                changeView(calendarTypes.RESOURCE_TIMELINE_DAY_VIEW);
                                break;
                        }
                    }
                    break;
            }
        }
    }, [smViewType])

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        if (!calendarData.loading) {
            changeView(sm ? calendarTypes.LIST_DAY_VIEW : state.auth.user.calendarSettings.defaultView)
        }
        return () => window.removeEventListener("resize", handleResize)
    }, [sm])

    useEffect(() => {
        let timeout = setTimeout(renderDaySeparator, 250);
        return () => clearTimeout(timeout)
    }, [timePrecision, calendarData.loading, companies])

    useEffect(() => {
        setItems();
    }, [calendarData.resources, isOuterEvents, calendarData.events, calendarData.keepNotes, state.auth.user.calendarSettings.isTasks, isOffersOnly, isOffers, isBackgroundEvents, isAbsence, displayEvents])

    useEffect(() => {
        dispatch(setPageTitle('Planning'))
        fetchCalendar();
    }, [
        companies, job, teams, calendarData.isNightTime,
        state.auth.user.calendarSettings.isTasks,
        state.auth.user.calendarSettings.defaultGroupEtp,
        state.auth.user.calendarSettings.isPresence,
        state.auth.user.calendarSettings.isAbsenceRequest,
    ])

    useEffect(() => {
        let API = calendarRef.current?.getApi()
        if (API) {
            API.gotoDate(pickerDate);
            setTitle(API.view.title)
        }
    }, [pickerDate])

    useEffect(() => {
        dispatch(setHelpProductSheetIds([3, 8, 9, 11, 12, 14]))

        const smViewTypeSetting = localStorage.getItem('smViewType')
        if (smViewTypeSetting === "list" || smViewTypeSetting === "timeline") {
            setSmViewType(smViewTypeSetting)
        }

        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [])

    const Toolbar = () => {
        return (
            <nav className="navbar navbar-expand-md bg-white calendar-toolbar">
                <div className="container-fluid align-items-center">
                    <div className="navbar-brand">
                        <CalendarTitle pickerDate={pickerDate} handlePickerDateChange={handlePickerDateChange} title={title}  prevClick={prevClick} nextClick={nextClick} endDate={calendarData.end} />
                    </div>

                    <button
                        className="navbar-toggler border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className={'bi bi-list'}></i>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <div className="navbar-nav w-100">
                            <div className="row w-100">
                                <div className="col-12 col-md-auto px-md-1 d-block d-md-none">
                                    <h4>
                                        Affichage
                                    </h4>
                                    <div className="btn-group">
                                        <button
                                            onClick={() => {
                                                localStorage.setItem('smViewType', 'list')
                                                setSmViewType('list')
                                            }}
                                            className={'btn ' + (smViewType === 'list' ? 'bg-primary text-white' : 'bg-white text-primary')}>
                                            Liste
                                        </button>
                                        <button
                                            onClick={() => {
                                                localStorage.setItem('smViewType', 'timeline')
                                                setSmViewType('timeline')
                                            }}
                                            className={'btn ' + (smViewType === 'timeline' ? 'bg-primary text-white' : 'bg-white text-primary')}>
                                            Timeline
                                        </button>
                                    </div>
                                </div>

                                <div className="col-12 col-md-auto px-md-1">
                                    <h4 className={"d-block d-md-none w-100 mt-3"}>
                                        Période
                                    </h4>
                                    <div className="btn-group">
                                        <div className="dropdown btn-group">
                                            {view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW &&
                                                <div className="dropdown">
                                                    <button className="btn btn-light text-primary shadow-sm dropdown-toggle dropdown-toggle-no-after-content" type="button" id="periodDropdown"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className={'bi bi-arrows-angle-expand'}></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="periodDropdown">
                                                        <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"minutes": 15}')}>
                                                            15 min
                                                        </li>
                                                        <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"minutes": 30}')}>
                                                            30 min
                                                        </li>
                                                        <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"hour": 1}')}>
                                                            1h
                                                        </li>
                                                        <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"hour": 3}')}>
                                                            3h
                                                        </li>
                                                        <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"day": 1}')}>
                                                            1 Jour
                                                        </li>
                                                    </ul>
                                                </div>
                                            }

                                            <button
                                                className="btn btn-light text-primary shadow-sm dropdown-toggle"
                                                type="button"
                                                id="periodDropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {[calendarTypes.LIST_DAY_VIEW, calendarTypes.RESOURCE_TIMELINE_DAY_VIEW].includes(view) && <>Jour</>}
                                                {[calendarTypes.LIST_WEEK_VIEW, calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW].includes(view) && <>Semaine</>}
                                                {[calendarTypes.LIST_MONTH_VIEW, calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW].includes(view) && <>Mois</>}
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="periodDropdown">
                                                <li className={'dropdown-item'} onClick={() => changeView((sm && smViewType === 'list') ? 'listDay' : 'resourceTimelineDay')}>
                                                    Jour
                                                </li>
                                                <li className={'dropdown-item'} onClick={() => changeView((sm && smViewType === 'list') ? 'listWeek' : 'resourceTimelineWeek')}>
                                                    Semaine
                                                </li>
                                                <li className={'dropdown-item'} onClick={() => changeView((sm && smViewType === 'list') ? 'listMonth' : 'resourceTimelineMonth')}>
                                                    Mois
                                                </li>
                                            </ul>

                                            {view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW && timePrecision !== calendarTypes.PRECISION_ONE_DAY &&
                                                <>
                                                    <button
                                                        className="btn btn-light text-primary shadow-sm dropdown-toggle dropdown-toggle-no-after-content"
                                                        type="button"
                                                        id="periodDropdown"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="bi bi-aspect-ratio"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="periodDropdown">
                                                        <li className={'dropdown-item'} onClick={() => setRatio("s")}>
                                                            Taille S
                                                        </li>
                                                        <li className={'dropdown-item'} onClick={() => setRatio("m")}>
                                                            Taille M
                                                        </li>
                                                        <li className={'dropdown-item'} onClick={() => setRatio("l")}>
                                                            Taille L
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                        </div>

                                        {view === calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW &&
                                            <>
                                                <button
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={displayEventTime ? "Afficher la durée des créneaux" : "Afficher l'horaire des créneaux"}
                                                    onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                    onClick={() => setDisplayEventTime(prev => !prev)}
                                                    className={'btn btn-light text-primary shadow-sm'}>
                                                    {displayEventTime ? <i className="bi bi-watch"></i> : <i className="bi bi-hourglass"></i>}
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>

                                {GrantedAny([accessRights.LIST_TIME_CLOCK, accessRights.LIST_TASK_GROUP, accessRights.LIST_ABSENCE]) &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <h4 className={"d-block d-md-none w-100 mt-3"}>
                                            Type de planning
                                        </h4>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-light text-primary shadow-sm dropdown-toggle"
                                                type="button"
                                                id="calendarDropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className={'bi bi-calendar me-2'}></i>
                                                {calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && <>Planifié</>}
                                                {calendar === calendarTypes.CALENDAR_SIGNED_GLOBAL && <>Badgé</>}
                                                {calendar === calendarTypes.CALENDAR_ABSENCE && <>Absences</>}
                                                {calendar === calendarTypes.CALENDAR_TASK && <>Tâches</>}
                                            </button>

                                            <ul className="dropdown-menu" aria-labelledby="calendarDropdown">
                                                <li className={'dropdown-item'} onClick={() => handleCalendarChange(calendarTypes.CALENDAR_EVENTS_GLOBAL)}>
                                                    {calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && <i className={'bi bi-check'}></i>} Planifié
                                                </li>

                                                {hasModule('timeClock') && Granted(accessRights.LIST_TIME_CLOCK) &&
                                                    <li className={'dropdown-item'} onClick={() => handleCalendarChange(calendarTypes.CALENDAR_SIGNED_GLOBAL)}>
                                                        {calendar === calendarTypes.CALENDAR_SIGNED_GLOBAL &&
                                                            <i className={'bi bi-check'}></i>
                                                        } Badgé
                                                    </li>
                                                }

                                                {Granted(accessRights.LIST_ABSENCE) &&
                                                    <li className={'dropdown-item'} onClick={() => handleCalendarChange(calendarTypes.CALENDAR_ABSENCE)}>
                                                        {calendar === calendarTypes.CALENDAR_ABSENCE &&
                                                            <i className={'bi bi-check'}></i>
                                                        } Absence
                                                    </li>
                                                }

                                                {hasModule('task') &&
                                                    <li className={'dropdown-item'} onClick={() => handleCalendarChange(calendarTypes.CALENDAR_TASK)}>
                                                        {calendar === calendarTypes.CALENDAR_TASK &&
                                                            <i className={'bi bi-check'}></i>
                                                        } Tâches
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }

                                {Granted(accessRights.READ_FLAT_RATE) &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <h4 className={"d-block d-md-none w-100 mt-3"}>
                                            Infos
                                        </h4>
                                        <div className="btn-group">
                                            {[calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW, calendarTypes.LIST_WEEK_VIEW].includes(view) &&
                                                <button
                                                    className="btn btn-light text-primary shadow-sm"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title="Numéro de semaine"
                                                    onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                >
                                                    <span className={'d-md-none'}>Semaine en cours</span><span className={'d-none d-md-inline'}>S.</span> {format(calendarData.start, 'w')}
                                                </button>
                                            }
                                            <button
                                                className="btn btn-light text-primary shadow-sm"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Masse salariale"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                            <span className={'d-md-none'}>Masse salariale</span>  {Math.round(calendarData.timeSheets.reduce((accumulator: number, object: any) => {
                                                    return accumulator + (object.rate || 0);
                                                }, 0))} <i className={'bi bi-currency-euro'}></i>
                                            </button>
                                        </div>
                                    </div>
                                }

                                {(!sm || smViewType === 'timeline') &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <h4 className={'d-md-none'}>
                                            Heures de jours/nuit
                                        </h4>
                                        <button
                                            type="button"
                                            className={'btn btn-light shadow-sm mx-1'}
                                            onClick={() => setCalendarData(prev => ({
                                                ...prev,
                                                isNightTime: !prev.isNightTime
                                            }))}
                                            data-bs-placement="top"
                                            title={calendarData.isNightTime ? "Afficher les horaires de jour" : "Afficher les horaires de nuit"}
                                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                        >
                                            {calendarData.isNightTime ? <i className={'bi bi-moon text-primary'}></i> : <i className={'bi bi-sun text-primary'}></i>}
                                        </button>
                                    </div>
                                }

                                <h4 className={'d-md-none'}>
                                    Filtrer
                                </h4>
                                {(!sm || smViewType === 'timeline') && [calendarTypes.CALENDAR_ABSENCE, calendarTypes.CALENDAR_SIGNED_GLOBAL].includes(calendar) &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <button
                                            disabled={calendarData.loading}
                                            onClick={() => setDisplayEvents(prevState => !prevState)}
                                            className={'btn btn-light text-primary shadow-sm'}
                                        >
                                            <i className={displayEvents ? 'bi bi-check text-success' : 'bi bi-x text-danger'}> </i> Planifié
                                        </button>
                                    </div>
                                }

                                {(!sm || smViewType === 'timeline') && calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <div className="btn-group">
                                            <button
                                                disabled={calendarData.loading}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Trier par collaborateur"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                onClick={(e) => handleSortByChange(1)}
                                                className={'btn ' + (sortBy === 1 ? " btn-primary text-white" : ' btn-light hover-primary')}
                                            >
                                                <i className={'bi bi-people'}> </i> <span className={'d-md-none'}>Métiers</span>
                                            </button>
                                            <button
                                                disabled={calendarData.loading}
                                                data-bs-toggle="tooltip"
                                                onClick={() => handleSortByChange(2)}
                                                data-bs-placement="top"
                                                title="Trier par emplacements"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                className={'btn ' + (sortBy === 2 ? " btn-primary text-white" : ' btn-light hover-primary')}
                                            >
                                                <i className={'bi bi-geo-alt'}> </i> <span className={'d-md-none'}>Emplacement</span>
                                            </button>
                                            <button
                                                disabled={calendarData.loading}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Trier par activités" onClick={() => handleSortByChange(3)}
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                className={'btn ' + (sortBy === 3 ? " btn-primary text-white" : ' btn-light hover-primary')}
                                            >
                                                <i className={'bi bi-bookmark'}> </i> <span className={'d-md-none'}>Activités</span>
                                            </button>
                                        </div>
                                    </div>
                                }

                                {(!sm || smViewType === 'timeline') && calendar === calendarTypes.CALENDAR_TASK &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <div className="btn-group">
                                            <button
                                                disabled={calendarData.loading}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Trier par collaborateur"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                onClick={(e) => handleSortByChange(1)}
                                                className={'btn ' + (sortBy === 1 ? " bg-primary text-white" : ' hover-primary')}
                                            >
                                                <i className={'bi bi-people'}> </i> <span className={'d-md-none'}>Métiers</span>
                                            </button>
                                            <button
                                                disabled={calendarData.loading}
                                                data-bs-toggle="tooltip"
                                                onClick={() => handleSortByChange(2)}
                                                data-bs-placement="top"
                                                title="Trier par groupe de tâche"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                className={'btn ' + (sortBy === 2 ? " bg-primary text-white" : ' hover-primary')}
                                            >
                                                <i className={'bi bi-bookmark'}> </i> <span className={'d-md-none'}>Groupe de tâche</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className="col-12 col-md-auto px-md-1">
                                    <button
                                        type="button"
                                        className="btn btn-light text-primary shadow-sm"
                                        data-bs-toggle="modal"
                                        data-bs-target="#companyModal"
                                        data-bs-placement="top"
                                        title="Établissements"
                                        onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                    >
                                        <i className={'bi bi-shop'}></i>
                                        <span className="ms-2 badge bg-primary text-white">
                                            {companies.length} <span className={'d-md-none'}>Établissement</span>
                                         </span>
                                    </button>
                                </div>
                                <div className="col-12 col-md-auto px-md-1">
                                    <button
                                        title={job === 0 ? "Tous les métiers" : (job === 1 ? "Front office" : "Back office")}
                                        data-bs-placement="top"
                                        onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                        type="button" className="btn btn-light text-primary shadow-sm" data-bs-toggle="modal" data-bs-target="#jobModal">
                                        <i className={'bi bi-person-circle'}></i> <span className={'d-md-none'}>Métiers</span>
                                    </button>
                                </div>

                                {Granted(accessRights.LIST_TEAM) &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <button
                                            type="button"
                                            className="btn btn-light text-primary shadow-sm"
                                            data-bs-toggle="modal"
                                            data-bs-target="#teamModal"
                                            data-bs-placement="top"
                                            title="Équipes"
                                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                        >
                                            <i className={'bi bi-people'}></i>
                                            <span className="ms-2 badge bg-primary text-white">
                                                {teams.length} <span className={'d-md-none'}>Équipes</span>
                                            </span>
                                        </button>
                                    </div>
                                }

                                <div className={'col mb-2'}>

                                </div>

                                {Granted(accessRights.EDIT_TEMPLATE) &&
                                    <div className={'col-12 col-md-auto px-md-1'}>
                                        <h4 className={'d-md-none'}>
                                            Templates
                                        </h4>
                                        <div className="dropstart">
                                            <button
                                                className="btn btn-light text-primary shadow-sm dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className={"bi bi-front"}></i>
                                                <span className="ms-2 badge bg-primary text-white">
                                                    {calendarData.templates.length} <span className={'d-md-none'}>Templates appliqués</span>
                                                </span>
                                            </button>
                                            
                                            <ul className="dropdown-menu">
                                                <li
                                                    className={'dropdown-item'}
                                                    onClick={() => setCalendarTemplateSidebarProps(prev => ({
                                                        sidebarType: "TEMPLATE_EXPORT",
                                                        trigger: prev.trigger + 1,
                                                        start: calendarData.start,
                                                        company: companies[0],
                                                    }))}
                                                >
                                                    <i className={'bi bi-arrow-up-square'}></i> Exporter le planning en template
                                                </li>
                                                <li
                                                    className={'dropdown-item'}
                                                    onClick={() => setCalendarTemplateSidebarProps(prev => ({
                                                        sidebarType: "TEMPLATE_IMPORT",
                                                        trigger: prev.trigger + 1,
                                                        start: calendarData.start,
                                                        end: calendarData.end,
                                                        company: companies[0],
                                                    }))}
                                                >
                                                    <i className={'bi bi-arrow-down-square'}></i> Importer un template
                                                </li>
                                                <li className="dropdown-item-text form-text">
                                                    Templates apliqués
                                                </li>
                                                {calendarData.templates.length ? calendarData.templates.map(t =>
                                                    <li
                                                        onClick={() =>  setCalendarTemplateSidebarProps(prev => ({
                                                            sidebarType: "TEMPLATE_SHOW",
                                                            trigger: prev.trigger + 1,
                                                            template: t,
                                                            setCalendarData: setCalendarData
                                                        }))}
                                                        className="dropdown-item"
                                                    >
                                                        <i className={'bi bi-front text-primary'}></i> {t.template.title}
                                                        <div className="clearfix"> </div>
                                                        {format(new Date(t.start))} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(t.end))}
                                                    </li>) :
                                                    <li className={'dropdown-item'}><i className={'bi bi-search'}></i> Aucun template</li>}
                                            </ul>
                                        </div>
                                    </div>
                                }

                                {calendar === calendarTypes.CALENDAR_SIGNED_GLOBAL &&
                                    <div className="col-12 col-md-auto px-md-1">
                                        <h4 className={'d-md-none'}>
                                            Reporting badgeuse
                                        </h4>
                                        <div className="btn-group">
                                            <button
                                                onClick={() => setReportingOrigin('anomaly')}
                                                className={'btn btn-light shadow-sm hover-primary'}
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasCalendarReporting"
                                                aria-controls="offcanvasCalendarReporting"
                                                data-bs-placement="top"
                                                title="Anomalies"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                                <i className={'bi bi-x'}></i> {calendarData.signed.filter(s => s.isAnomaly).length} <span className={'d-md-none'}>Anomalies</span>
                                            </button>
                                            <button
                                                onClick={() => setReportingOrigin('missing')}
                                                className={'btn btn-light shadow-sm hover-primary'}
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasCalendarReporting"
                                                aria-controls="offcanvasCalendarReporting"
                                                data-bs-placement="top"
                                                title="Non badgé"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                                <i className={'bi bi-exclamation-circle text-danger'}></i> {calendarData.signed.filter(s => !s.anomaly && s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING).length} <span className={'d-md-none'}>Absent</span>
                                            </button>
                                            <button
                                                onClick={() => setReportingOrigin('late')}
                                                className={'btn btn-light shadow-sm hover-primary'}
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasCalendarReporting"
                                                aria-controls="offcanvasCalendarReporting"
                                                data-bs-placement="top"
                                                title="Retards"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                                <i className={'bi bi-exclamation-triangle text-warning'}></i> {calendarData.signed.filter(s => s.isLate).length} <span className={'d-md-none'}>Retard</span>
                                            </button>
                                        </div>
                                    </div>
                                }

                                <div className="col-12 col-md-auto px-md-1">
                                    <h4 className={'d-md-none'}>
                                        Actions
                                    </h4>
                                    <div className="btn-group">
                                        {Granted(accessRights.EDIT_BOOKING) &&
                                            <button className="btn btn-light d-none d-md-block text-primary shadow-sm mx-1" type="button"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasCalendarOrder"
                                                aria-controls="offcanvasCalendarOrder"
                                                data-bs-placement="top"
                                                title="Ordonner le planning"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                                <i className={'bi bi-arrow-down-up'}></i>
                                            </button>
                                        }

                                        {GrantedAny([accessRights.EDIT_BOOKING, accessRights.EDIT_ABSENCE, accessRights.EDIT_BOOKING_OFFER]) &&
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-light text-primary shadow-sm mx-1 dropdown-toggle"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i className={"bi bi-plus-circle"}></i> <span className={'d-none d-md-inline'}>Ajouter</span>
                                                </button>

                                                <ul className="dropdown-menu">
                                                    {Granted(accessRights.EDIT_BOOKING) &&
                                                        <li className={'dropdown-item'}
                                                            onClick={() => {
                                                                let API = calendarRef.current?.getApi();
                                                                if (API){
                                                                    setCalendarEventSidebarProps(prev => ({
                                                                        sidebarType: "EVENT_ADD",
                                                                        trigger: prev.trigger + 1,
                                                                        dt: setHours(API!.getDate(), (new Date()).getHours())
                                                                    }))
                                                                }

                                                            }}
                                                        >
                                                            Ajouter un créneau
                                                        </li>
                                                    }

                                                    {Granted(accessRights.EDIT_ABSENCE) &&
                                                        <li className={'dropdown-item'}
                                                            onClick={() => setCalendarAbsenceSidebarProps(prev => ({
                                                                sidebarType: "ABSENCE_ADD",
                                                                trigger: prev.trigger + 1,
                                                            }))}
                                                        >
                                                            Ajouter une absence
                                                        </li>
                                                    }
                                                    {calendarTypes.CALENDAR_SIGNED_GLOBAL === calendar && Granted(accessRights.EDIT_TIME_CLOCK) &&
                                                        <li className={'dropdown-item'}
                                                            onClick={() => setCalendarSignedSidebarProps(prev => ({
                                                                sidebarType: "SIGNED_ADD",
                                                                trigger: prev.trigger + 1,
                                                            }))}
                                                        >
                                                            Ajouter un badgeage
                                                        </li>
                                                    }
                                                    {calendarTypes.CALENDAR_EVENTS_GLOBAL === calendar && Granted(accessRights.EDIT_BOOKING_OFFER) &&
                                                        <li className={'dropdown-item'}
                                                            onClick={() => setCalendarOfferSidebarProps(prev => ({
                                                                sidebarType: "OFFER_ADD",
                                                                trigger: prev.trigger + 1,
                                                                company: companies[0]
                                                            }))}
                                                        >
                                                            Ajouter une offre de créneau
                                                        </li>
                                                    }
                                                    {calendarTypes.CALENDAR_EVENTS_GLOBAL === calendar && Granted(accessRights.EDIT_TASK) &&
                                                        <li className={'dropdown-item'}
                                                            onClick={() => setCalendarTaskSidebarProps(prev => ({
                                                                sidebarType: "TASK_ADD",
                                                                trigger: prev.trigger + 1
                                                            }))}
                                                        >
                                                            Ajouter une tâche
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        }

                                        {Granted(accessRights.LIST_BOOKING_OFFER) && calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL &&
                                            <button
                                                type="button"
                                                className={'btn d-none d-md-block shadow-sm mx-1' + (isOffers ? ' btn-primary text-white' : ' btn-light text-primary')}
                                                onClick={() => setIsOffers(prev => !prev)}
                                                data-bs-placement="top"
                                                title="Offres de créneaux"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                                <i className={'bi bi-star-fill'}></i> <span className={'badge bg-warning'}>{calendarData.offers.filter((o: any) => o.eventType === calendarTypes.EVENT_TYPE_OFFER_PENDING).length}</span>
                                            </button>
                                        }

                                        {Granted(accessRights.LIST_TASK_GROUP) && calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && sortBy === calendarTypes.SORT_BY_JOB &&
                                            <button
                                                type="button"
                                                className={'btn d-none d-md-block shadow-sm mx-1' + (displayTaskSidebar ? ' btn-primary text-white' : ' btn-light text-primary')}
                                                onClick={() => setDisplayTaskSidebar(prev => !prev)}
                                                data-bs-placement="top"
                                                title="Afficher les tâches"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                                <i className={'bi bi-list-check'}></i> <span className={'badge' + (!state.auth.user.calendarSettings.isTasks ? ' btn-primary text-white' : ' btn-light text-primary')}>{calendarData.tasks.length}</span>
                                            </button>
                                        }

                                        {calendarData.presences.length > 0 && sortBy !== 1 && calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL &&
                                            <button type="button"
                                            className={'btn btn-light text-primary shadow-sm mx-1'}
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasCalendarPresences" aria-controls="offcanvasCalendarPresences"
                                            data-bs-placement="top"
                                            title="Manque de remplissage"
                                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                            >
                                                <i className={'bi bi-arrow-down'}></i> <span className={'badge bg-danger text-white'}>{calendarData.presences.filter((p: any) => p.values.length < p.need).length}</span>
                                            </button>
                                        }
                                        {[calendarTypes.CALENDAR_EVENTS_GLOBAL, calendarTypes.CALENDAR_TASK].includes(calendar) &&
                                            <>
                                                {Granted(accessRights.DOWNLOAD_CALENDAR) &&
                                                    <button
                                                        data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasCalendarDownload"
                                                        data-bs-placement="top"
                                                        title="Télécharger"
                                                        onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                        className={'btn btn-light text-primary shadow-sm mx-1'}>
                                                        <i className="bi bi-download"> </i>
                                                    </button>
                                                }
                                                {Granted(accessRights.PUBLISH_CALENDAR) &&
                                                    <button
                                                        data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasCalendarPublish"
                                                        data-bs-placement="top"
                                                        title="Publier"
                                                        onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                        className={'btn btn-light text-primary shadow-sm mx-1'}>
                                                        <i className="bi bi-send"> </i>
                                                    </button>
                                                }
                                            </>
                                        }
                                        <button
                                            type="button"
                                            className={'btn btn-light text-primary shadow-sm mx-1'}
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasCalendarSettings"
                                            data-bs-placement="top"
                                            title="Paramètres du planning"
                                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                        >
                                            <i className={'bi bi-gear'}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }

    return (
        <>
            <Toolbar />

            <div className="d-flex h-100">
                <div className={`flex-grow-1 ` + (view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW ? `fc-ratio-${ratio}` : ``)}>
                    <FullCalendar
                        scrollTimeReset={false}
                        key={'calendar'}
                        ref={calendarRef}
                        initialDate={calendarData.initialDate}
                        slotMinWidth={30}
                        viewDidMount={(arg) => setTitle(arg.view.title)}
                        plugins={[interactionPlugin, rrulePlugin, resourceTimelinePlugin, listPlugin]}
                        lazyFetching={false}
                        headerToolbar={false}
                        initialView={view}
                        eventMinWidth={10}
                        height={'100%'}
                        resourceAreaWidth={'15%'}
                        events={events}
                        resources={[...resources, {
                            title: "Notes",
                            id: "keepNotes",
                            order1: 0,
                            resourceType: "TYPE_KEEP_NOTE",
                        }]}
                        displayEventTime={true}
                        filterResourcesWithEvents={filterEventsWithResources}
                        resourceLabelContent={(arg) => <RenderResource
                            arg={arg}
                            sortBy={sortBy}
                            timeSheets={calendarData.timeSheets}
                            setCalendarSignedSidebarProps={setCalendarSignedSidebarProps}
                            setCalendarAbsenceSidebarProps={setCalendarAbsenceSidebarProps}
                            setCalendarEventSidebarProps={setCalendarEventSidebarProps}
                            setCalendarTimeTableProps={setCalendarTimeTableProps}
                        />}
                        resourceLaneContent={resourceLaneContent}
                        resourceAreaHeaderContent={() => <ResourceAreaHeaderContent
                            isAbsence={isAbsence}
                            setIsAbsence={setIsAbsence}
                            filterEventsWithResources={filterEventsWithResources}
                            setFilterEventsWithResources={setFilterEventsWithResources}
                            isBackgroundEvents={isBackgroundEvents}
                            setIsBackgroundEvents={setIsBackgroundEvents}
                            setIsOuterEvent={setOuterEvents}
                            isOuterEvents={isOuterEvents}
                        />}
                        eventContent={e => renderEvent(e, displayEventTime)}
                        views={{
                            resourceTimelineDay: {
                                slotDuration: {minutes: 15},
                                titleFormat: { year: 'numeric', month: 'short', day: '2-digit', weekday: "short" }
                            },
                            resourceTimelineWeek: {
                                slotDuration: JSON.parse(timePrecision),
                            },
                            resourceTimelineMonth: {
                                slotDuration: {day: 1}
                            }
                        }}
                        hiddenDays={daysOfWeek}
                        slotMinTime={calendarData.slotMinTime}
                        slotMaxTime={calendarData.slotMaxTime}
                        resourceOrder={'order1, order2, order3, order4'}
                        locales={allLocales}
                        locale={'fr'}
                        schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                        editable={Granted(accessRights.EDIT_BOOKING)}
                        selectable={Granted(accessRights.EDIT_BOOKING)}
                        select={onSelect}
                        eventResize={onResize}
                        eventClick={onClick}
                        visibleRange={{
                            end: getMaxVisibilityDate()
                        }}
                        eventDrop={onDrop}
                        eventMouseEnter={eventMouseEnter}
                        eventMouseLeave={eventMouseLeave}
                        progressiveEventRendering={true}
                        slotLabelContent={(e) => {
                            if (Granted(accessRights.EDIT_KEEP_NOTE)) {
                                switch (view) {
                                    case calendarTypes.RESOURCE_TIMELINE_DAY_VIEW:
                                        return <>{e.text}</>
                                    case calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW:
                                        return <>
                                            {e.text}
                                            {e.level === 0 && <button
                                                data-bs-placement="top"
                                                title="Ajouter une note"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                type={'button'} className={'btn btn-sm'}
                                                onClick={() => setCalendarKeepNoteSidebarProps((prevState: any) => ({
                                                    sidebarType: "KEEP_NOTE_ADD",
                                                    trigger: prevState.trigger + 1,
                                                    dueDate: e.date
                                                }))}
                                            >
                                                <i className={'bi bi-plus-circle'}></i>
                                            </button>}
                                        </>
                                    case calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW:
                                        return <>
                                            {e.text}
                                            <div className="clearfix"> </div>
                                            {e.level === 0 && <button
                                                data-bs-placement="top"
                                                title="Ajouter une note"
                                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                                type={'button'} className={'btn btn-sm'}
                                                onClick={() => setCalendarKeepNoteSidebarProps((prevState: any) => ({
                                                    sidebarType: "KEEP_NOTE_ADD",
                                                    trigger: prevState.trigger + 1,
                                                    dueDate: e.date
                                                }))}
                                            >
                                                <i className={'bi bi-plus-circle'}></i>
                                            </button>}
                                        </>
                                }
                            }
                        }}
                    />
                </div>

                {calendar === calendarTypes.CALENDAR_ABSENCE && !sm &&
                    <CalendarAbsenceList
                        absences={calendarData.absences}
                        handleEventChange={handleEventChange}
                    />
                }
                {calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && !sm && isOffers &&
                    <CalendarOfferList
                        offers={calendarData.offers}
                        isOffersOnly={isOffersOnly}
                        setIsOffersOnly={setIsOffersOnly}
                        handleEventChange={handleEventChange}
                        setCalendarOfferSidebarProps={setCalendarOfferSidebarProps}
                    />
                }
                {calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && !sm && sortBy === calendarTypes.SORT_BY_JOB && displayTaskSidebar &&
                    <CalendarTaskList
                        type={'CONTROLLED'}
                        tasks={calendarData.tasks}
                        initialView={view}
                        initialDate={calendarData.initialDate}
                        setCalendarTaskSidebarProps={setCalendarTaskSidebarProps}
                    />
                }
            </div>

            <CalendarCompanySelector
                companies={companies}
                setCompanies={setCompanies}
            />
            <CalendarTeamSelector teams={teams} setTeams={setTeams} />
            <CalendarJobSelector job={job} setJob={setJob} />
            <CalendarOrder sortBy={sortBy} resources={calendarData.resources} setCalendarData={setCalendarData} />
            <CalendarSetting
                daysOfWeek={daysOfWeek}
                setDaysOfWeek={setDaysOfWeek}
                handleTimePrecisionChange={handleTimePrecisionChange}
                handlePresenceChange={handlePresenceChange}
                handleGroupEtpChange={() => {
                    if (calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && sortBy !== 1){
                        fetchCalendar();
                    }
                }}
                fetchCalendar={() => fetchCalendar()}
            />

            <CalendarEventSidebar key={'CalendarEventSidebar'} handleEventChange={handleEventChange} {...calendarEventSidebarProps} />
            <CalendarOfferSidebar key={'CalendarOfferSidebar'} handleEventChange={handleEventChange} {...calendarOfferSidebarProps} />
            <CalendarBookingSidebar key={'CalendarBookingSidebar'} handleEventChange={handleEventChange} {...calendarBookingSidebarProps} />
            <CalendarSignedSidebar key={'CalendarSignedSidebar'} handleEventChange={handleEventChange} {...calendarSignedSidebarProps} />
            <CalendarAbsenceSidebar key={'CalendarAbsenceSidebar'} handleEventChange={handleEventChange} {...calendarAbsenceSidebarProps} />
            <CalendarKeepNoteSidebar key={'CalendarKeepNoteSidebar'} setCalendarData={setCalendarData} {...calendarKeepNoteSidebarProps} />
            <CalendarTaskSidebar key={'CalendarTaskSidebar'} referer={'multiple'} handleEventChange={handleEventChange} {...calendarTaskSidebarProps} />
            <CalendarTemplateSidebar key={'CalendarTemplateSidebar'} handleEventChange={handleEventChange} {...calendarTemplateSidebarProps} />
            <CalendarTimetable handleEventChange={handleEventChange} {...calendarTimetableProps} />

            <CalendarReporting signed={calendarData.signed} origin={reportingOrigin} setCalendarSignedSidebarProps={setCalendarSignedSidebarProps} />
            <CalendarPresence presences={calendarData.presences} />
            <CalendarDownload company={companies[0]} sortBy={sortBy} presence={presence} calendar={calendar} dt={calendarData.start} view={view} isTasks={state.auth.user.calendarSettings.isTasks} />
            <CalendarPublish dt={calendarData.start} type={"multiple"} company={companies[0]} />
            
            {calendarData.loading && <Loader type={'grow'} />}
        </>
    )
}


export default CalendarMultiple;