import {post, get} from "../../xhr";
import * as models from "../../../models/template";

export function list(id: number){
    return get('template/'+ id +'/events');
}

export function edit(requestData: any, id: number){
    return post('template/booking/edit/' + id, requestData);
}

export function create(requestData: models.Booking){
    return post('template/booking/create',  requestData);
}

export function createException(requestData: any, bookingId: number, instanceToken:string){
    return post(`template/booking/exception/create/${bookingId}/${instanceToken}`,  requestData);
}

export function editException(requestData: any, bookingExceptionId: number){
    return post(`template/booking/exception/edit/${bookingExceptionId}`,  requestData);
}

export function cancel(id: number){
    return get(`template/booking/cancel/${id}`)
}

export function cancelOnce(id: number, day: number){
    return post(`template/booking/cancel_once/${id}`, {day: day})
}

export function cancelException(id: number){
    return get(`template/booking/exception/cancel/${id}`)
}