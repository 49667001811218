import React, { useEffect, useState } from "react";

import AbsenceAddForm from "../../../absence/add/form";

import { Salary } from "../../../../models/salary";

const AbsenceAdd: React.FC<{close: () => any,salary?: Salary, handleSubmitSuccess: (abs: any) => any}> = (props) => {
    const { salary, handleSubmitSuccess, close } = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (abs: any) => {
        return Promise.all([
            handleSubmitSuccess(abs)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [salary])

    return (
        <>
            <div className="offcanvas-header">
                <h5 id="offcanvasCalendarAbsenceLabel">
                    Ajouter une absence
                </h5>

                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>

            <div className="offcanvas-body">
                <AbsenceAddForm
                    key={key}
                    handleSubmitSuccess={handleSubmit}
                    salary={salary}
                />
            </div>
        </>
    )
}

export default AbsenceAdd;