import {Resource, Template} from "../index";
import {Salary} from "../../salary";

export interface TemplateApplied {
    id: number,
    start: string,
    end: string,
    template: Template,
    appliedResources: AppliedResources,
    isPreview: boolean,
    isApplied: boolean
}

export type TemplateApplies = TemplateApplied[];

export interface AppliedResource {
    readonly id?: number,
    resource: Resource,
    salary: Salary
}
export type AppliedResources = AppliedResource[];

export interface initialStateInterface {
    payload: TemplateApplies,
    single: TemplateApplied|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};