import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {AppliedResource, Resource, TemplateApplied} from "../../../../models/template";
import format from "../../../../utils/locale"
import {SingleSalarySelected, SingleSalarySelector} from "../../../../utils/salarySelector/single";
import {Salary} from "../../../../models/salary";
import * as appliedResourceApi from "../../../../adapters/template/applied/resource"
import * as templateAppliedApi from "../../../../adapters/template/applied"
import {showAlertSuccess} from "../../../../actions/alert";
import {editTemplateApplied, editTemplateAppliedSuccess} from "../../../../actions/template/applied";
import {refresh} from "../../../../actions/calendar";
import {closeSidebar} from "../../../../actions/rightSidebar";


const TemplateAppliedEdit:React.FC = () => {

    const dispatch = useDispatch();
    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const isLoading = useSelector((state: RootStateOrAny) => state.templateApplied.isLoading)
    const templateApplied: TemplateApplied = useSelector((state: RootStateOrAny) => state.templateApplied.payload.find((templateApplied: TemplateApplied) => templateApplied.id === data.id))!
    const [displaySalarySelector, setDisplaySalarySelector] = useState<boolean>(false);
    const [currentResource, setCurrentResource] = useState<Resource>();

    const removeAppliedResource = (appliedResource?: AppliedResource) => {
        if (!appliedResource) return false;

        dispatch(editTemplateApplied());
        appliedResourceApi.cancel(appliedResource.id!).then(data => {
            dispatch(editTemplateAppliedSuccess(data.data))
            dispatch(refresh());
        })

    }

    const addAppliedResource = (salary: Salary) => {
        if (!currentResource) return false;

        dispatch(editTemplateApplied());
        appliedResourceApi.create({
            resource: currentResource.id!,
            salary: salary.id!,
            templateApplied: templateApplied.id
        }).then(data => {
            dispatch(editTemplateAppliedSuccess(data.data))
            dispatch(refresh());
        })
    }

    const editAppliedResource = (salary: Salary, appliedResource: AppliedResource) => {
        if (!currentResource) return false;

        dispatch(editTemplateApplied());
        appliedResourceApi.edit({
            salary: salary.id!,
            resource: currentResource.id!,
            templateApplied: templateApplied.id
        }, appliedResource.id!).then(data => {
            dispatch(editTemplateAppliedSuccess(data.data))
            dispatch(refresh());
        })
    }

    const editStatus = (status: 1|2) => {
        dispatch(editTemplateApplied());
        templateAppliedApi.editStatus(status, templateApplied.id).then(data => {
            dispatch(editTemplateAppliedSuccess(data.data))
            dispatch(refresh());
            dispatch(showAlertSuccess(`Status de ${data.data.template.title} mis à jour !`))
        })
    }

    return <>
        <div className="col-12">
            <div className="p-3">
                <h2>
                    {templateApplied.isPreview && <i className={"bi bi-eye text-primary"}> </i>}
                    {templateApplied.isApplied && <i className={"bi bi-check2-circle text-success"}> </i>}
                    {templateApplied.template.title}
                </h2>
                <p>
                   <i className={'bi bi-calendar2-check me-2'}> </i> {format(new Date(templateApplied.start))} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(templateApplied.end))}
                </p>
            </div>
            <div className="p-3">
                <div className="row">
                    <div className="col-auto">
                        <div className="button-group">
                            <button disabled={templateApplied.isPreview || isLoading} onClick={() => editStatus(1)} className={'btn me-3 ' + (templateApplied.isPreview ? "btn-primary" : 'btn-outline-primary')}>
                                <i className={'bi bi-eye'}> </i> Prévisualisation
                            </button>
                            <button disabled={templateApplied.isApplied || isLoading} onClick={() => editStatus(2)} className={'btn me-3 ' + (templateApplied.isApplied ? "btn-success" : 'btn-outline-success')}>
                                <i className={'bi bi-check2-circle'}> </i> Valider
                            </button>
                            <button disabled={isLoading} onClick={() => {
                                templateAppliedApi.cancel(templateApplied.id).then(() => {
                                    dispatch(refresh())
                                    dispatch(closeSidebar());
                                })
                            }} className={'btn btn-outline-danger'}>
                                <i className={'bi bi-dash-circle'}> </i> Désactiver
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {templateApplied.template.resources?.filter((resource: Resource) => !resource.salary)?.length && <div className="p-3">
                <h4 className={'mb-3'}>
                   <i className={'bi bi-person-x me-2'}> </i> Resources sans collaborateurs
                </h4>
                {templateApplied.template.resources?.filter((resource: Resource) => !resource.salary).map((resource: Resource) => {
                    let appliedResource = templateApplied.appliedResources.find((appliedResource) => appliedResource.resource.id === resource.id);

                    return [<div className={"row"}>
                        <div className="col">
                            <i className={'bi bi-person'}> </i> {resource.title}
                            <div className="clearfix"> </div>
                            <i className={'bi bi-person-bounding-box'}> </i> {resource.job.title}
                        </div>
                        <div className="col-auto">
                            <SingleSalarySelected initialValue={appliedResource?.salary.id}
                                                  initialSalary={appliedResource?.salary}
                                                  onDelete={() => removeAppliedResource(appliedResource)}
                                                  onOpen={() => {
                                                      setCurrentResource(resource)
                                                      setDisplaySalarySelector(true)
                                                  }}
                                                  disabled={isLoading}
                            />
                        </div>
                    </div>, <hr/>]
                })}
            </div>}
        </div>
        {displaySalarySelector && <SingleSalarySelector
            onSubmit={(salary: Salary) => {
                setDisplaySalarySelector(false)
                let appliedResource = templateApplied.appliedResources.find(appliedResource => appliedResource.resource.id === currentResource?.id);
                if (appliedResource){
                    editAppliedResource(salary, appliedResource)
                }else{
                    addAppliedResource(salary)
                }
            }}
            onClose={() => {
                setCurrentResource(undefined)
                setDisplaySalarySelector(false)
            }}
            companies={[templateApplied.template.company]}
            initialValue={templateApplied.appliedResources.find(appliedResource => appliedResource.resource.id === currentResource?.id)?.id}
        />}
    </>

}

export default TemplateAppliedEdit;