import React, { useEffect, useState } from "react";

import TimetableAddForm from "./form";

import { Timetable, Timetables } from "../../../models/timetable";
import { Salary } from "../../../models/salary";

import { Offcanvas } from "bootstrap";

interface IProps {
    timetables: Timetables;
    salary: Salary;
    setTimetables: React.Dispatch<any>;
}

const TimetableAdd:React.FC<IProps> = (props) => {
    const {timetables, salary, setTimetables } = props;

    const [offcanvas, setOffcanvas] = useState<Offcanvas>();
    const [lastEnd, setLastEnd] = useState<Date>();

    const maxDate = (dates: Date[]) => new Date(Math.max(...dates.map(Number)));

    const close = () => {
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const handleSubmit = (t: Timetable) => {
        setTimetables([...timetables, t])
        close()
    }

    useEffect(() => {
        if(timetables.length > 0 && timetables.filter(t => !t.end).length  === 0) {
            setLastEnd(maxDate(timetables.map(t => new Date(t.end!))));
        }else{
            setLastEnd(undefined)
        }
    }, [timetables])

    useEffect(() => {
        const el = document.getElementById('offcanvasTimetableAdd')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    return (
        <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTimetableAdd" aria-labelledby="offcanvasTimetableAddLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasTimetableAddLabel">
                    Ajouter un emploi du temps
                </h5>
                
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>

            <div className="offcanvas-body">
                <TimetableAddForm
                    timetables={timetables}
                    salary={salary}
                    handleSubmit={handleSubmit}
                    lastEnd={lastEnd}
                    duplicate={false}
                />
            </div>
        </div>
    )
}

export default TimetableAdd;