import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as models from "../../../models/companies";
import {list} from "../../../adapters/company";
import * as actions from "../../../actions/companies";
import '../../../utils/table/index.scss'
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_NEW_COMPANY} from "../../../constants/rightSidebar";
import {Link, useParams} from "react-router-dom";
import {setPageTitle} from "../../../actions/header";
import * as accessRights from "../../../constants/accessRight";
import {axiosError} from "../../../actions/axios";
import {PAGINATION} from "../../../constants/global";
import {Granted} from "../../../security/granted";

const BillingList = () => {
    const state = useSelector((state: RootStateOrAny)  => state.companies);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFiltering, setIsFiltering] = useState<boolean>(false);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [query, setQuery] = useState<string>();
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const dispatch = useDispatch();
    const params: {id: string} = useParams();

    useEffect(() => {

        dispatch(setPageTitle('Facturation'))
        dispatch(actions.fetchCompanies())

        setIsLoading(true);

        list({query: query, offset: 0}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            dispatch(actions.fetchCompaniesSuccess(data.data))
            setIsLoading(false);
            setOffset( 1);
        }).catch(error => dispatch(axiosError(error)))

    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setIsFiltering(true);
                setOffset(0)
                list({query: query, offset: 0}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    dispatch(actions.fetchCompaniesSuccess(data.data))
                    setIsFiltering(false);
                    setOffset( 1);
                }).catch(error => dispatch(axiosError(error)))
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if(scrollBottom && offset > 0 && !isLast){
            setIsLoading(true)
            list({query: query, offset: offset}).then(data => {
                if (data.data.length < 50){
                    setIsLast(true);
                }else{
                    setIsLast(false);
                }
                dispatch(actions.fetchCompaniesSuccess([...state.payload, ...data.data]))
                setIsLoading(false)
                setScrollBottom(false);
                setOffset(prevState => prevState + 1)
            }).catch(error => dispatch(axiosError(error)))
        }

        let container = document.getElementById('listContainer')!
        container.addEventListener("scroll", onScrollBottom)
        return () => container.removeEventListener("scroll", onScrollBottom)

    }, [scrollBottom])

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    return <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
            <div className="d-flex flex-column">
                <div className="w-100">
                    <div className="input-group">
                        <span className="input-group-text bg-white border-end border-0" id="search-addon">
                            {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : <i className="bi bi-search"> </i>}
                        </span>
                        <input type="search" onChange={(e) => {
                            setQuery(e.target.value)
                        }} className="form-control border-0 bg-white" placeholder="Rechercher..." aria-describedby="search-addon" />
                    </div>
                </div>
            </div>
                {state.payload.length ? state.payload.map((company: models.Company, index: number) => ([
                        <Link to={"/billing/" + company.id} key={index} className={"px-3 list-group-item list-group-item-action" + (state.single?.id === company.id ? ' active' : '')}>
                            {company.title}
                        </Link>
                    ]))
                    :
                    !isLoading && <button key={0} type="button" className="list-group-item list-group-item-action" aria-current="true" disabled>
                        Aucune entreprise
                    </button>
                }

                {isLoading && <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </button>}
    </div>
}

export default BillingList;