import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import * as actions from "../../../actions/document";
import * as models from "../../../models/document";
import * as api from "../../../adapters/document";
import * as accessRights from "../../../constants/accessRight";
import {Link} from "react-router-dom";
import {axiosError} from "../../../actions/axios";
import BgSingle from "../../../utils/background/single";
import {setPageTitle} from "../../../actions/header";
import SlateEditor from "../editor";
import {useFormik} from "formik";
import * as Yup from "yup";
import {showAlertSuccess} from "../../../actions/alert";
import {Granted} from "../../../security/granted";
import {editDocumentSuccess} from "../../../actions/document";

export const DocumentShow: React.FC = () => {

    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const doc: models.Document|undefined = useSelector((state: RootStateOrAny) => state.document.payload.find((a: models.Document) => a.id!.toString() === params.id))
    const [hasChanged, setHasChanged] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [initialValue, setInitialValue] = useState([]);

    useEffect(() => {
        let controller = new AbortController();
        dispatch(actions.showDocument());
        setIsLoading(!!params.id)
        if (doc){
            dispatch(actions.showDocumentSuccess(doc))
            setInitialValue(JSON.parse(doc.value))
            setHasChanged(false)
            dispatch(setPageTitle("Document", doc.title))
            setIsLoading(false)
        }else if(params.id){
            api.show(Number(params.id), controller.signal)
                .then(data => {
                    setHasChanged(false)
                    setIsLoading(false)
                    dispatch(actions.showDocumentSuccess(data.data))
                    setInitialValue(JSON.parse(data.data.value))
                    dispatch(setPageTitle("Document", data.data.title))
                })
                .catch(error => dispatch(axiosError(error)))
        }

        return () => controller.abort()
    }, [params.id])

    const formik = useFormik({
        initialValues: {
            title: doc?.title,
            value: doc ? JSON.parse(doc.value) : []
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            value: Yup.array().of(Yup.object()).required()
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.edit(values, doc!.id)
                .then(data => dispatch(editDocumentSuccess(data.data)))
                .then(() => dispatch(showAlertSuccess('Document mis à jour')))
                .then(() => formikHelpers.setSubmitting(false))
        }
    })

    if (!params.id || !doc) {
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!params.id ? " d-none d-md-block" : "")}>
        <div className="d-flex flex-grow-1 p-1 p-md-3 container-fluid bg-light flex-column h-100">
            <div className="w-100 text-end d-block d-md-none">
                <Link to={"/document"} className={'btn btn-sm bg-white shadow-sm m-1'}>
                    <i className={'bi bi-chevron-left'}> </i> Retour
                </Link>
            </div>
            <div className="card shadow-sm mb-3 p-1 p-md-3 d-flex flex-grow-0">
                <form onSubmit={formik.handleSubmit}>
                    <SlateEditor
                        toolbar={Granted(accessRights.EDIT_DOCUMENT)}
                        isSubmitting={formik.isSubmitting}
                        toolbarSubmit={Granted(accessRights.EDIT_DOCUMENT)}
                        disabled={!Granted(accessRights.EDIT_DOCUMENT)}
                        initialValue={initialValue}
                        onChange={(value => {
                            if(Granted(accessRights.EDIT_DOCUMENT)){
                                formik.setFieldValue('value', value)
                            }
                        })}
                        setValue={formik.setFieldValue}
                        value={formik.values.value}
                    />
                </form>
            </div>
        </div>
    </div>
}

export default DocumentShow;