import React, {useEffect, useState} from "react";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import FormError from "../../../../utils/formError";
import * as exceptionApi from "../../../../adapters/event/exception";
import DatePicker from "react-datepicker";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {AccountingTypes, getAccountingTypeChoice} from "../../../../constants/booking";
import {showAlertSuccess} from "../../../../actions/alert";
import * as types from "../../../../constants/calendar";
import {Activity} from "../../../../models/activity";
import {Localisation} from "../../../../models/localisation";
import * as accessRightsTypes from "../../../../constants/accessRight";
import SalarySelector from "../../../salary/selector";

interface Interface {
    values: {
        start: Date
        end: Date
        salary: Salary
        company: Company
        accountingType: number
        activity?: Activity
        localisation?: Localisation
        eventType: string
        eventId?: number
        exceptionId?: number,
        comment?: string
    },
    handleSubmitSuccess: (ev: any) => any;
}

const EventEditForm:React.FC<Interface> = (props) => {

    const {values, handleSubmitSuccess} = props;

    const dispatch = useDispatch();
    const [salary, setSalary] = useState<Salary>(values.salary)
    const [company, setCompany] = useState<Company>(values.company)

    const initialValues = {
        salary: salary.id,
        start: values.start,
        end: values.end,
        accountingType: values.accountingType,
        company: values.company.id,
        activity: values.activity?.id,
        localisation: values.localisation?.id,
        comment: values.comment || '',
    }

    const validationSchema = Yup.object().shape({
        salary: Yup.number().required('Veuillez séléctionner un collaborateur dans la liste ci-dessus'),
        start: Yup.date().required(),
        end: Yup.date().required().test("is-greater", "La fin doit être après le début", function(value: any) {
            const { start } = this.parent;
            return value && start < value
        }),
        company: Yup.number().required(),
        activity: Yup.number().nullable(),
        localisation: Yup.number().nullable(),
        accountingType: Yup.number().required(),
        comment: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (_values, formikHelpers) => {
            switch (values.eventType){
                case types.EVENT_TYPE_EVENT:
                    exceptionApi.create({..._values, exdate: values.start}, values.eventId!).then(resp => {
                        Promise.all([
                            handleSubmitSuccess(resp.data)
                        ]).then(() => {
                            dispatch(showAlertSuccess('Créneau mis à jour'));
                            formikHelpers.setSubmitting(false)
                        })
                    })
                    break;
                case types.EVENT_TYPE_EVENT_EXCEPTION:
                    exceptionApi.edit(_values, values.exceptionId!).then(resp => {
                        Promise.all([
                            handleSubmitSuccess(resp.data)
                        ]).then(() => {
                            dispatch(showAlertSuccess('Créneau ajouté'));
                            formikHelpers.setSubmitting(false)
                        })
                    })
                    break;
            }
        }
    })

    return <form className={'p-1 p-md-3'} id={"eventEditForm"} onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <SalarySelector
                required={true}
                fetchOptions={true}
                disabled={formik.isSubmitting}
                value={salary}
                onChange={(s: Salary) => {
                    formik.setFieldValue('salary', s.id)
                    setSalary(s)
                }}
                accessRight={accessRightsTypes.EDIT_BOOKING}
            />
            <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                        customInput={<DateCustomInput label={'Heure de début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`start`, date)
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        startDate={formik.values.start}
                        maxDate={formik.values.end}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                </div>
                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                </div>
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Heure de fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`end`, date)
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        timeIntervals={15}
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                    />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                required={true}
                label={"Type d'heure"}
                options={AccountingTypes}
                value={getAccountingTypeChoice(formik.values.accountingType)}
                onChange={(c) => formik.setFieldValue('accountingType', c?.value)}
            />
        </div>
        {<>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    required={true}
                    label={"Établissement"}
                    className={validationClass(formik.errors, formik.touched, 'company')}
                    //options={salary?.companies ? [...salary.companies.map(c => ({label: c.title, value: c.id!}))] : []}
                    fetchEntity="company"
                    fetchParams={{ salaries: [formik.values.salary] }}
                    value={company ? {label: company.title, value: company.id!} : undefined}
                    onChange={(c) => formik.setFieldValue('company', c?.value)}
                    onChangeObject={(c: Company) => setCompany(company)}
                />
            </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'localisation')}
                            value={values.localisation ? {label: values.localisation.title, value: values.localisation.id!} : undefined}
                            disabled={formik.isSubmitting}
                            fetchEntity={'localisation'}
                            fetchParams={{ companies: [formik.values.company] }}
                            onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                            label={"Emplacement"}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'activity')}
                            value={values.activity ? {label: values.activity.title, value: values.activity.id!} : undefined}
                            disabled={formik.isSubmitting}
                            fetchEntity={'activity'}
                            fetchParams={{ companies: [formik.values.company] }}
                            onChange={(c) => formik.setFieldValue('activity', c?.value)}
                            label={"Activité"}
                        />
                    </div>
                </div>
        </>}
        <div className="col-12 mb-3">
            <div className="form-floating">
                <textarea onChange={formik.handleChange} defaultValue={values.comment} className="form-control" name={'comment'} placeholder="Commentaire" id="comment"
                          style={{height: 100}}></textarea>
                <label htmlFor="comment">Commentaire</label>
            </div>
        </div>
        <button type={'submit'} className={'btn btn-light w-100'} disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> : <><i className={'bi bi-check text-success'}></i> Valider</>}
        </button>
    </form>
}

export default EventEditForm;