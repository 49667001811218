import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import * as api from "../../../adapters/calendar"
import * as authActions from "../../../actions/auth"
import * as calendarActions from "../../../actions/calendar"
import {UserCalendarSettings} from "../../../models/auth";
import BootstrapSelect from "../../../utils/bootstrapSelect";

const CalendarSettings = () => {

    const dispatch = useDispatch();
    const settings: UserCalendarSettings = useSelector((state:RootStateOrAny) => state.auth.user.calendarSettings);
    const [isLoading, setIsLoading] = useState(false);
    const [daysOfWeek, setDaysOfWeek] = useState(settings.daysOfWeek);

    const handleChange = (setting: string, value: any) => {
        setIsLoading(true)
        api.setting({setting: setting, value: value}).then((data) => {
            setIsLoading(false)
            dispatch(authActions.refreshUserSuccess(data.data.user))
            if (!['days_of_week', 'default_view', 'default_group_by'].includes(setting)){
                dispatch(calendarActions.refresh());
            }
        })
    }

    return <>
        <div className="p-3">
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Timezone
                        </label>
                    </div>
                    <div className="col-auto">
                        <BootstrapSelect
                            notFloating={true}
                            required={true}
                            disabled={isLoading}
                            value={settings?.timezone ? {label: settings.timezone, value: settings.timezone} : undefined}
                            options={[
                                {label: 'Europe/Paris', value: 'Europe/Paris'},
                                {label: 'America/Martinique', value: 'America/Martinique'},
                                {label: 'America/Cayenne', value: 'America/Cayenne'},
                                {label: 'Europe/London', value: 'Europe/London'},
                                {label: 'Europe/Berlin', value: 'Europe/Berlin'},
                                {label: 'Europe/Zurich', value: 'Europe/Zurich'},
                                {label: 'Europe/Madrid', value: 'Europe/Madrid'},
                                {label: 'Asia/Jerusalem', value: 'Asia/Jerusalem'},
                                {label: 'Africa/Abidjan', value: 'Africa/Abidjan'},
                                {label: 'Africa/Tunis', value: 'Africa/Tunis'},
                                {label: 'Indian/Reunion', value: 'Indian/Reunion'},
                            ]}
                            onChange={choice => handleChange('timezone', choice?.value)}
                        />
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'}/>
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Grouper les créneaux des emplacements & activités
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input disabled={isLoading} onChange={(e) => handleChange('group_etp', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.defaultGroupEtp} />
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'}/>
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Afficher les tâches
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input disabled={isLoading} onChange={(e) => handleChange('is_task', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.isTasks} />
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Afficher le décompte de présence
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input disabled={isLoading} onChange={(e) => handleChange('is_presence', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.isPresence} />
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Afficher les demandes d'absences
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input disabled={isLoading} onChange={(e) => handleChange('is_absence_request', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.isAbsenceRequest} />
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Précision du découpage horaire en affichage semaine
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <select disabled={isLoading} onChange={(e) => handleChange('time_precision', e.target.value)} className={'form-control'}>
                                <option value='{"minutes": 15}' selected={settings.timePrecision === '{"minutes": 15}'} >
                                    1/4h
                                </option>
                                <option value='{"minutes": 30}' selected={settings.timePrecision === '{"minutes": 30}'} >
                                    1/2h
                                </option>
                                <option value='{"hour": 1}' selected={settings.timePrecision === '{"hour": 1}'} >
                                    1h
                                </option>
                                <option value='{"hour": 3}' selected={settings.timePrecision === '{"hour": 3}'} >
                                    3h
                                </option>
                                <option value='{"day": 1}' selected={settings.timePrecision === '{"day": 1}'} >
                                    1 jour
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Jours de la semaine à exclure
                        </label>
                    </div>
                    <div className="col-auto">
                        {['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'].map((day: string, index: number) => {
                            return (
                                <>
                                    <input
                                        disabled={isLoading}
                                        defaultChecked={settings.daysOfWeek?.includes(index)}
                                        onChange={(e) => {
                                            let _daysOfWeek = [...daysOfWeek]
                                            if (e.target.checked && !_daysOfWeek.includes(parseInt(e.target.value))){
                                                _daysOfWeek = [..._daysOfWeek, parseInt(e.target.value)]
                                            }else{
                                                let index = _daysOfWeek.findIndex((day) => day === parseInt(e.target.value))
                                                if (index !== -1){
                                                    _daysOfWeek.splice(index, 1);
                                                }
                                            }
                                            setDaysOfWeek([..._daysOfWeek])
                                            handleChange('days_of_week', _daysOfWeek)
                                        }}
                                        type="checkbox"
                                        name={'daysOfWeek'}
                                        className="btn-check"
                                        id={`daysOfWeek_${index}`}
                                        autoComplete="off"
                                        value={index}
                                    />
                                    <label className="btn btn-sm btn-outline-primary me-1 mb-1" htmlFor={`daysOfWeek_${index}`}>
                                        {day}
                                    </label>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Vue par défaut
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <select disabled={isLoading} onChange={(e) => handleChange('default_view', e.target.value)} className={'form-control'}>
                                <option value="resourceTimelineDay" selected={settings.defaultView === "resourceTimelineDay"} >
                                    Jour
                                </option>
                                <option value='resourceTimelineWeek' selected={settings.defaultView === "resourceTimelineWeek"} >
                                    Semaine
                                </option>
                                <option value='resourceTimelineMonth' selected={settings.defaultView === "resourceTimelineMonth"} >
                                    Mois
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Grouper par défaut par
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <select disabled={isLoading} onChange={(e) => handleChange('default_group_by', e.target.value)} className={'form-control'}>
                                <option value='1' selected={settings.defaultGroupBy === 1} >
                                    Métiers
                                </option>
                                <option value='2' selected={settings.defaultGroupBy === 2} >
                                    Emplacements
                                </option>
                                <option value='3' selected={settings.defaultGroupBy === 3} >
                                    Activités
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Ratio d'affichage
                        </label>
                    </div>
                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <select disabled={isLoading} onChange={(e) => handleChange('ratio', e.target.value)} className={'form-control'}>
                                <option value='s' selected={settings.ratio === 's'} >
                                    S
                                </option>
                                <option value='m' selected={settings.ratio === 'm'} >
                                    M
                                </option>
                                <option value='l' selected={settings.ratio === 'l'} >
                                    L
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={'bg-primary'} />
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <label htmlFor="">
                            Par défaut, souhaitez-vous :
                        </label>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="col">
                            <label htmlFor="">
                                Afficher les créneau en absence ?
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <input disabled={isLoading} onChange={(e) => handleChange('display_absence', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.displayAbsence} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="col">
                            <label htmlFor="">
                                Afficher les collaborateurs sans créneau ?
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <input disabled={isLoading} onChange={(e) => handleChange('display_empty', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.displayEmpty} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="col">
                            <label htmlFor="">
                                Afficher les modifications en fond ?
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <input disabled={isLoading} onChange={(e) => handleChange('display_background_edit', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.displayBackgroundEdit} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="col">
                            <label htmlFor="">
                                Afficher les créneaux sur un autre établissement ?
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="form-check form-switch">
                                <input disabled={isLoading} onChange={(e) => handleChange('display_outer', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.displayOuter} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CalendarSettings;