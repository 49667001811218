import React, {useState} from "react";
import {AccountingSettings} from "../../../models/companyGroup";
import * as API from "../../../adapters/companyGroup";
import {editUserCompanyGroupSuccess} from "../../../actions/auth";
import {refreshCounter} from "../../../actions/counter";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";


const CounterSettings:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state:RootStateOrAny) => state)
    const settings:AccountingSettings = state.auth.user.currentSalary.companyGroup.accountingSettings;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (setting: string, value: any) => {
        setIsLoading(true);
        API.accountingSetting({
            setting: setting,
            value: value
        }).then((data) => {
            dispatch(editUserCompanyGroupSuccess(data.data))
            dispatch(refreshCounter())
            setIsLoading(false);
        })
    }

    return <div className="col-12 p-3">
        <div className="row">
            <div className="col">
                <label htmlFor="">
                    Majorer les heures supplémentaires
                </label>
                <div className="form-text">
                    Activer si vous souhaitez appliquer la majorations aux heures supplémentaires
                </div>
            </div>
            <div className="col-auto">
                <div className="form-check form-switch">
                    <input onChange={(e) => handleChange('overtime_mark_up', e.target.checked)}  defaultChecked={settings.overtimeMarkUp} disabled={isLoading} className="form-check-input" type="checkbox" />
                </div>
            </div>
        </div>
    </div>
}

export default CounterSettings;