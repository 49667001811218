/**
 * Toolbar elements that modify the look and formatting
 * of the text in the SlateEditor component.
 */
import React from "react";

export function Element(props: any){
  switch (props.element.type) {
    case "block-quote":
      return <blockquote {...props.attributes}>{props.children}</blockquote>;

    case "bulleted-list":
      return <ul {...props.attributes}>{props.children}</ul>;

    case "heading-one":
      return <h1 {...props.attributes}>{props.children}</h1>;

    case "heading-two":
      return <h2 {...props.attributes}>{props.children}</h2>;

    case "heading-three":
      return <h3 {...props.attributes}>{props.children}</h3>;

    case "link":
      return (
        <a {...props.attributes} href={props.element.url}>
          {props.children}
        </a>
      );

    case "break":
      return <div className={'clearfix'}>{props.children}</div>;

    case "list-item":
      return <li {...props.attributes} className={'mb-2'}>{props.children}</li>;

    case "numbered-list":
      return <ol {...props.attributes}>{props.children}</ol>;

    case "start":
      return <div className={'text-start'} {...props.attributes}>{props.children}</div>;

    case "end":
      return <div className={'text-end'} {...props.attributes}>{props.children}</div>;

    case "center":
      return <div className={'text-center'} {...props.attributes}>{props.children}</div>;

    default:
      return <p {...props.attributes}>{props.children}</p>;
  }
}

export function Leaf(props: any) {
  let children = props.children

  if (props.leaf.bold) {
    children = <strong>{props.children}</strong>;
  }

  if (props.leaf.italic) {
    children = <em>{props.children}</em>;
  }

  if (props.leaf.underline) {
    children = <u>{props.children}</u>;
  }

  return <span {...props.attributes}>{children}</span>;
}
