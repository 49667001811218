import {forwardRef} from "react";
import * as React from "react";

interface OtherProps {
    icon?: string,
    label?: string,
}

type InputProps = JSX.IntrinsicElements["input"] & OtherProps;

const DateCustomInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {



    return <>
        <div className="form-floating">
            <input placeholder={' '} readOnly={window.innerWidth < 768} {...props} ref={ref} autoComplete={"off"}/>
            <label htmlFor="">
                {props.icon && <i className={props.icon}> </i>} {props.label}
                {props.required && <span className={'required'}>*</span>}
            </label>
        </div>
    </>
})

export default DateCustomInput;