import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import * as actions from "../../../actions/accessLevel";
import * as api from "../../../adapters/accessLevel";
import '../../../utils/checkboxAndRadios/index.scss'
import {setPageTitle} from "../../../actions/header";
import {AccessLevel} from "../../../models/accessLevel";
import * as accessRightsConstants from "../../../constants/accessRight";
import {Granted} from "../../../security/granted";
import {Link} from "react-router-dom";
import {editAccessLevelSuccess} from "../../../actions/accessLevel";
import BgSingle from "../../../utils/background/single";
import HasModule from "../../../security/hasModule";

export const AccessLevelShow: React.FC = (props) => {

    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const state = useSelector((state: RootStateOrAny) => state.accessLevel)
    const accessLevel: AccessLevel = useSelector((state: RootStateOrAny) => state.accessLevel.payload.find((a: AccessLevel) => a.id!.toString() === params.id))
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(!!params.id)
        if (accessLevel){
            dispatch((actions.showAccessLevelSuccess(accessLevel)))
            dispatch(setPageTitle('Droits d\'accès', accessLevel.title))
            setIsLoading(false)
        }else if(params.id){
            api.get(params.id).then(data => {
                actions.showAccessLevelSuccess(data.data)
                dispatch(setPageTitle('Droits d\'accès', data.data.title))
                setIsLoading(false)
            })
        }
    }, [params.id])


    const addOrRemove = (underscoreCase: string) => {
        setIsLoading(true);
        api.accessRight({underscoreCase: underscoreCase}, accessLevel.id).then(data => {
            dispatch(editAccessLevelSuccess(data.data))
            setIsLoading(false);
        })
    }

    if (!accessLevel || !params.id){
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!params.id ? " d-none d-md-block" : "")}>
        <div className="p-1 p-md-3">
            <div className="w-100 d-block d-md-none my-1 text-end">
                <Link to={"/access_level"} className={'btn bg-white shadow-sm'}>
                    <i className={"bi bi-chevron-left"}> </i> Retour
                </Link>
            </div>
            <div className={"card flex-row shadow-sm d-flex p-1 p-md-3"}>
                <div className="list-group me-3 flex-grow-0" id="list-tab" role="tablist">
                    {Object.entries(accessRightsConstants.availableAccessRight).map(([key, obj], index) => {

                        if (obj.module !== ""){
                            if (!HasModule(obj.module)){
                                return <></>
                            }
                        }

                        return <>
                            <a href={`#list-${index}`}
                               className={"list-group-item list-group-item-action" + (!index ? " active" : "")}
                               id={`${index}`} data-bs-toggle="list" role="tab" aria-controls={`list-${index}`}>
                                <i className={obj.icon}> </i> {obj.title}
                            </a>
                        </>
                    })}
                </div>
                <div className="tab-content flex-grow-1" id="nav-tabContent">
                    {Object.entries(accessRightsConstants.availableAccessRight).map(([key, obj], index) => {

                        if (obj.module !== ""){
                            if (!HasModule(obj.module)){
                                return <></>
                            }
                        }

                        return <>
                            <div key={index}
                                 className={"tab-pane fade show" + (!index ? " active" : "")} id={`list-${index}`}
                                 role="tabpanel" aria-labelledby={`list-${index}-list`}>
                                <ul className="list-group list-group-flush">
                                    {obj.items.map((item, key) => <>
                                        <li key={key} className="list-group-item">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor={`accessRight${key}`}>
                                                        {item.description}
                                                    </label>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            disabled={isLoading || !Granted(accessRightsConstants.EDIT_ACCESS_LEVEL)}
                                                            checked={state.single?.rights.includes(item.underscoreCase)}
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                addOrRemove(item.underscoreCase)
                                                            }}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`accessRight${key}`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </>)}
                                </ul>
                            </div>
                        </>
                    })}
                </div>
            </div>
        </div>
    </div>
}

export default AccessLevelShow;