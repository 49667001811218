import React, {useState} from "react";
import {Company} from "../../../../../models/companies";
import {EventClickArg} from "@fullcalendar/react";
import {SalaryTemplateEventSidebarInterface} from "../sidebar";
import {Salary} from "../../../../../models/salary";
import format from "../../../../../utils/locale";
import {SalaryTemplate, SalaryTemplateEvents} from "../../../../../models/salary/template";
import {getAccountingTypeChoice} from "../../../../../constants/booking";
import * as api from "../../../../../adapters/salary/template/event";
import {refresh, refreshResource} from "../../../../../actions/calendar";
import SmallLoader from "../../../../../utils/loader/small";

interface Interface {
    company: Company,
    salary: Salary,
    template: SalaryTemplate,
    week: number,
    arg: EventClickArg,
    setCalendarEventSidebarProps: React.Dispatch<any>,
    setEvents: React.Dispatch<any>,
    close: () => any
}

const SalaryTemplateEventShow:React.FC<Interface> = (props) => {

    const {setCalendarEventSidebarProps, arg, company, week, salary, template, setEvents, close} = props;

    const [canceling, setCanceling] = useState(false)

    const cancel = () => {
        setCanceling(true)
        api.cancel(arg!.event.extendedProps.event.id).then(() => {
            setEvents((prev: SalaryTemplateEvents) => ([...prev.filter(e => e.id !== arg!.event.extendedProps.event.id)]))
            close()
            setCanceling(false)
        })
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventDropLabel">Créneau de {salary.title}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <table className={'table'}>
                <tbody>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-clock'}></i> Début
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.initialStart), 'HH:mm')}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-clock-fill'}></i> Fin
                        </td>
                        <td>
                            {format(new Date(arg.event.extendedProps.initialEnd), 'HH:mm')}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            Type d'heure
                        </td>
                        <td>
                            {getAccountingTypeChoice(arg.event.extendedProps.event.accountingType)?.label}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-paragraph'}></i> Description
                        </td>
                        <td>
                            {arg.event.extendedProps.description}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-paragraph'}></i> Semaine
                        </td>
                        <td>
                            {week}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-shop'}></i> Établissement
                        </td>
                        <td>
                            {company.title}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-bookmark'}></i> Activité
                        </td>
                        <td>
                            {arg.event.extendedProps.event.activity?.title || <i className={'bi bi-x'}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            <i className={'bi bi-shop'}></i> Localisation
                        </td>
                        <td>
                            {arg.event.extendedProps.event.localisation?.title || <i className={'bi bi-x'}></i>}
                        </td>
                    </tr>
                    <tr>
                        <td className={'text-primary'}>
                            Emploi du temps
                        </td>
                        <td>
                            {template.title}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="row">
                <div className="col-6">
                    <button className="btn w-100 btn-light" onClick={() => setCalendarEventSidebarProps((prev: any) => ({
                        sidebarType: "EVENT_EDIT",
                        trigger: prev.trigger + 1,
                        arg: arg
                    }))}>
                        <i className={'bi bi-pencil text-primary'}></i> Modifier
                    </button>
                </div>
                <div className="col-6">
                    <button className="btn w-100 btn-light" onClick={() => cancel()}>
                        {canceling ? <SmallLoader /> : <><i className={'bi bi-x text-danger'}></i> Supprimer</>}
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default SalaryTemplateEventShow;