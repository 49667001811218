import React from "react";
import * as Yup from "yup";
import * as api from "../../../../../adapters/template/resource";
import {useFormik} from "formik";
import SmallLoader from "../../../../../utils/loader/small";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../../actions/alert";
import {Template} from "../../../../../models/template";

interface Interface {
    template: Template,
    handleSubmitSuccess: (ev: any[]) => any
}

const TemplateResourceAddForm:React.FC<Interface> = (props) => {

    const dispatch = useDispatch();
    const initialValues = {
        title: "",
        job: null,
        contractWorkingTime: null,
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Champs requis'),
        job: Yup.number().required('Champs requis'),
        contractWorkingTime: Yup.number().nullable(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            api.create(values, props.template.id)
                .then(resp => {
                    Promise.all([
                        props.handleSubmitSuccess(resp.data)
                    ]).then(() => {
                        dispatch(showAlertSuccess('Resource ajouté'));
                        formikHelpers.setSubmitting(false)
                    })
                })
        }
    })

    return <form onSubmit={formik.handleSubmit}>
        <div className="form-floating mb-3">
            <input type="text" className="form-control" name={"title"} id={"title"} onChange={formik.handleChange} />
            <label htmlFor="title">
                Libellé
            </label>
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                fetchEntity={"job"}
                label={"Métier"}
                onChange={c => formik.setFieldValue('job', c?.value)}
                required={true}
            />
        </div>
        <div className="form-floating mb-3">
            <input type="number" className="form-control" step={"any"} name={"contractWorkingTime"} id={"contractWorkingTime"} onChange={formik.handleChange} />
            <label htmlFor="contractWorkingTime">
                Heures contrat
            </label>
        </div>
        <button className="w-100 btn btn-light" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <SmallLoader /> : <><i className={"bi bi-check"}></i></>} Ajouter
        </button>
    </form>
}

export default TemplateResourceAddForm;