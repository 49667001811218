import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import Loading from "../../../../../components/common/Loading"
import EmptyList from "../../../../../components/common/EmptyList"
import SellsyContactCard from "./_components/SellsyContactCard"
import CompanyGroupCompaniesCard from "./_components/CompanyGroupCompaniesCard"
import CompanySalariesCard from "./_components/CompanySalariesCard"

import { User } from "../../../../../models/auth";
import { CompanyGroup } from "../../../../../models/companyGroup"
import { ISellsyContact } from '../../../../../@types/models/Sellsy/ISellsyContact'

import { show } from "../../../../../adapters/user"
import { showAlertDanger } from "../../../../../actions/alert";

interface IProps {
    userId: number;
}

const UserBilling: React.FC<IProps> = ({ userId }) => {
    const dispatch = useDispatch()

    const [userIsLoading, setUserIsLoading] = useState<boolean>(false)
    const [userData, setUserData] = useState<User|undefined>(undefined)    
    const [selectedCompanyGroup, setSelectedCompanyGroup] = useState<CompanyGroup|undefined>(undefined)
    const [sellsyContact, setSellsyContact] = useState<ISellsyContact|undefined|null>(undefined)
        
    const onFetchUserData = async (noLoading?: string) => {
        try {
            if (!noLoading) {
                setUserIsLoading(true)
            }
            const { data } = await show(userId)
            
            setUserData({
                ...data.user,
                companyGroupSalaries: data.salaries
            })
        } catch (error) {
            dispatch(showAlertDanger('Impossible de récupérer les données de l’utilisateur'))
        } finally {
            setUserIsLoading(false)
        }
    }

    useEffect(() => {
        if (userId) {
            onFetchUserData()
        }
    }, [userId])

    return (
        <div>
            {userIsLoading &&
                <Loading />
            }

            {!userIsLoading && userData &&
                <div>
                    <div className="container g-0 row">
                        {userData?.companyGroups.map(companyGroup => (
                            <button
                                className={`col-4 p-3 bg-white border ${selectedCompanyGroup?.id === companyGroup.id ? 'border-primary' : ''} rounded d-flex flex-column align-items-baseline`}
                                onClick={() => {
                                    setSelectedCompanyGroup(companyGroup)
                                }}
                            >
                                <p className="m-0">
                                    {companyGroup?.title || '???'}
                                </p>
                                <span className={`my-2 badge ${companyGroup?.sellsyId ? 'bg-success' : 'bg-danger'}`}>
                                    {companyGroup?.sellsyId ? 'Lié à Sellsy' : 'Non lié à Sellsy'}
                                </span>
                                <p className="m-0 text-muted">
                                    SellsyId : <span className="text-body">{companyGroup?.sellsyId || '-'}</span>
                                </p>
                                <p className="m-0 text-muted">
                                    Nb Salariés : <span className="text-body">{userData?.companyGroupSalaries?.filter(salary => salary.activated)?.length || "-"}</span>
                                </p>
                                <p className="m-0 text-muted">
                                    Version plateforme : <span className="text-body">{companyGroup?.version || '-'}</span>
                                </p>
                                <p className="m-0 text-muted">
                                    Version abonnement : <span className="text-body">{companyGroup?.billingVersion || '-'}</span>
                                </p>
                            </button>
                        ))}
                    </div>

                    <div className="container g-0 py-3">
                        <div>
                            {!selectedCompanyGroup &&
                                <EmptyList text={"Sélectionnez un groupement d’entreprises"} />
                            }

                            {selectedCompanyGroup?.id &&
                                <div>
                                    <SellsyContactCard
                                        selectedCompanyGroupId={selectedCompanyGroup.id}
                                        companyGroupSellsyId={selectedCompanyGroup?.sellsyId}
                                        userEmail={userData.email}
                                        data={sellsyContact}
                                        setSellsyContact={setSellsyContact}
                                        onRefresh={(noLoading?: string) => onFetchUserData(noLoading)}
                                    />

                                    {selectedCompanyGroup?.sellsyId &&
                                        <div className="my-2">
                                            <CompanyGroupCompaniesCard
                                                selectedCompanyGroup={selectedCompanyGroup}
                                                setSelectedCompanyGroup={setSelectedCompanyGroup}
                                                companies={userData.companyGroups.find(group => group.id === selectedCompanyGroup.id)?.companies || []}
                                                sellsyContactId={selectedCompanyGroup.sellsyId}
                                                companyGroupBillingRate={selectedCompanyGroup?.billingRate}
                                                onRefresh={onFetchUserData}
                                            />

                                            <CompanySalariesCard
                                                salaries={userData?.companyGroupSalaries?.filter(salary => salary.activated) || []}
                                                companies={userData.companyGroups.find(group => group.id === selectedCompanyGroup.id)?.companies || []}
                                                onRefresh={onFetchUserData}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default UserBilling;