import React, {useEffect, useState} from "react";
import ResourceEditForm from "./form";
import {Template} from "../../../../models/template";
import {Job} from "../../../../models/job";
import {Salary} from "../../../../models/salary";

interface Interface {
    template: Template,
    setTemplate: React.Dispatch<any>,
    close: () => any,
    handleSubmitSuccess: (ev: any, previous?: any) => any
    resource: {id: number}
}

interface InterfaceFloating {
    floating: true,
    job: Job,
    title: string,
    contractWorkingTime: number,
}

interface InterfaceSalary {
    floating: false,
    salary: Salary,
}

const ResourceEdit:React.FC<Interface & (InterfaceSalary | InterfaceFloating)> = (props) => {

    const {handleSubmitSuccess, template, close, setTemplate} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {

        let copy = {...template};
        copy.resources = [...template.resources.filter(r => r.id === ev.id), ev]
        setTemplate({...copy})

        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [template])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarResourceEditLabel">Modifier une ressource</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {props.floating && <ResourceEditForm
                key={key}
                template={template}
                handleSubmitSuccess={handleSubmit}
                resource={props.resource}
                floating={props.floating}
                job={props.job}
                contractWorkingTime={props.contractWorkingTime}
                title={props.title}
            />}
            {!props.floating && <ResourceEditForm
                key={key}
                template={template}
                handleSubmitSuccess={handleSubmit}
                resource={props.resource}
                floating={props.floating}
                salary={props.salary}
            />}
        </div>
    </>
}

export default ResourceEdit;