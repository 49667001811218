import * as types from "../../constants/auth";
import * as models from "../../models/auth"
import {CompanyGroup} from "../../models/companyGroup";
import {Salaries, Salary} from "../../models/salary";
import {User} from "../../models/auth";
import {Company} from "../../models/companies";

export const login = () => {
    return {
        type: types.LOGIN,
    }
}

export const loginSuccess = (data :models.authResponse) => {
    return {
        type: types.LOGIN_SUCCESS,
        data
    }
}

export const refreshAuthSuccess = (data :models.authResponse) => {
    return {
        type: types.REFRESH_AUTH_SUCCESS,
        data: data
    }
}

export const refreshUserSuccess = (data :models.User) => {
    return {
        type: types.REFRESH_USER_SUCCESS,
        user: data
    }
}

export const register = () => {
    return {
        type: types.REGISTER,
    }
}

export const registerSuccess = (data :models.authResponse) => {
    return {
        type: types.REGISTER_SUCCESS,
        data: data
    }
}

export const registerFailure = (data :string) => {
    return {
        type: types.REGISTER_FAILURE,
        data
    }
}

export const loginFailure = (data :string) => {
    return {
        type: types.LOGIN_FAILURE,
        message: data
    }
}

export const confirm = () => {
    return {
        type: types.CONFIRM,
    }
}

export const confirmTokenExist = () => {
    return {
        type: types.CONFIRM_TOKEN_EXIST,
    }
}

export const confirmSuccess = (data :models.authResponse) => {
    return {
        type: types.CONFIRM_SUCCESS,
        data: data
    }
}
export const editSelfSalary = (salary :Salary) => {
    return {
        type: types.EDIT_SELF_SALARY,
        salary: salary
    }
}

export const confirmFailure = (message: string) => {
    return {
        type: types.CONFIRM_FAILURE,
        message: message
    }
}

export const editPassword = () => {
    return {
        type: types.EDIT_PASSWORD,
    }
}

export const editPasswordSuccess = () => {
    return {
        type: types.EDIT_PASSWORD_SUCCESS,
    }
}

export const editPasswordFailure = (message: string) => {
    return {
        type: types.EDIT_PASSWORD_FAILURE,
        message: message
    }
}

export const logout = () => {
    return {
        type: types.LOGOUT,
    }
}

export const fetchAccounts = () => {
    return {
        type: types.FETCH_ACCOUNTS,
    }
}

export const fetchAccountsSuccess = (salaries: Salaries) => {
    return {
        type: types.FETCH_ACCOUNTS_SUCCESS,
        accounts: salaries
    }
}

export const selectAccount = (salary: Salary) => {
    return {
        type: types.SELECT_ACCOUNT,
        account: salary
    }
}

export const unselectAccount = () => {
    return {
        type: types.UNSELECT_ACCOUNT,
    }
}

export const editUserCompanyGroupSuccess = (companyGroup: CompanyGroup) => {
    return {
        type: types.EDIT_USER_COMPANY_GROUP_SUCCESS,
        companyGroup: companyGroup
    }
}

export const editUserCalendarSettingSuccess = (calendarSettings: models.UserCalendarSettings) => {
    return {
        type: types.EDIT_USER_CALENDAR_SETTING_SUCCESS,
        calendarSettings: calendarSettings
    }
}

export const impersonateUser = (user: models.User) => {
    return {
        type: types.IMPERSONATE_USER,
        impersonated: user
    }
}

export const exitImpersonateUser = () => {
    return {
        type: types.EXIT_IMPERSONATE_USER,
    }
}

export const addAuthCompany = (c: Company) => {
    return {
        type: types.ADD_AUTH_COMPANY,
        company: c
    }
}

