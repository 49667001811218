import * as React from "react";

import CompanyGroupSettings from "../../../components/companyGroup/settings";

const CompanyGroupSettingsPage : React.FC = () => {
    return (
            <CompanyGroupSettings />
    );
}

export default CompanyGroupSettingsPage;