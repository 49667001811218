import * as types from "../../constants/channel";
import * as models from "../../models/channel";

export const addChannel = () => {
    return {
        type: types.ADD_CHANNEL,
    }
}

export const addChannelInformation = () => {
    return {
        type: types.ADD_CHANNEL,
    }
}

export const addChannelSuccess = (channel: models.Channel) => {
    return {
        type: types.ADD_CHANNEL_SUCCESS,
        channel: channel,
    }
}

export const addChannelInformationSuccess = (channel: models.Channel) => {
    return {
        type: types.ADD_CHANNEL_INFORMATION_SUCCESS,
        channel: channel,
    }
}

export const addChannelFailure = (error: string) => {
    return {
        type: types.ADD_CHANNEL_FAILURE,
        channel: null,
        channels: null,
        error: error
    }
}

export const addChannelInformationFailure = (error: string) => {
    return {
        type: types.ADD_CHANNEL_INFORMATION_FAILURE,
        channel: null,
        channels: null,
        error: error
    }
}

export const editChannel = () => {
    return {
        type: types.EDIT_CHANNEL,
    }
}

export const editChannelSuccess = (channel: models.Channel) => {
    return {
        type: types.EDIT_CHANNEL_SUCCESS,
        channel: channel,
    }
}

export const fetchChannels = () => {
    return {
        type: types.FETCH_CHANNELS,
    }
}

export const fetchChannelsFailure = (error: string) => {
    return {
        type: types.FETCH_CHANNELS_FAILURE,
        channel: null,
        channels: [],
        error: error
    }
}

export const fetchChannelsSuccess = (channels: models.Channels) => {
    return {
        type: types.FETCH_CHANNELS_SUCCESS,
        channel: null,
        channels: channels
    }
}


export const showChannel = () => {
    return {
        type: types.SHOW_CHANNEL,
    }
}
export const showChannelSuccess = (channel: models.Channel) => {
    return {
        type: types.SHOW_CHANNEL_SUCCESS,
        channel: channel
    }
}
export const showChannelFailure = (error: string) => {
    return {
        type: types.SHOW_CHANNEL_FAILURE,
        channel: null,
        channels: null,
        error: error
    }
}