import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Activity, ActivityNeed, ActivityNeeds} from "../../../../models/activity";
import * as api from "../../../../adapters/activity/needs";
import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import Loader from "../../../../utils/loader";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {editActivitySuccess} from "../../../../actions/activity";
import {Job} from "../../../../models/job";
import {openSidebar} from "../../../../actions/rightSidebar";
import {ACTIVITY_NEED_ADD} from "../../../../constants/rightSidebar";
import {Granted} from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight";
import {axiosError} from "../../../../actions/axios";

const ActivityNeedTable = () => {

    const dispatch = useDispatch();
    let activity: Activity = useSelector((state: RootStateOrAny) => state.activity.single)
    const [needs, setNeeds] = useState<ActivityNeeds>([...activity.needs!]);
    const [dateRange, setDateRange] = useState<[Date|null, Date|null]>([null, null])
    const [start, end] = dateRange;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let _start = new Date(activity.company.timesheet.openAt)
        let _end = new Date(activity.company.timesheet.closeAt)
        setDateRange([_start, _end])
        setNeeds([...activity.needs!])

        return () => {
            setDateRange([null,null]);
        }
    }, [activity])

    const getTimeOccurrences = () => {
        let times = [];

        if (start && end){
            let _start = new Date(),
                _end = new Date();

            _start.setTime(start.getTime())
            _end.setTime(end.getTime())

            do {
                times.push(_start.getHours().toLocaleString('fr', {minimumIntegerDigits:2}) + ':' + _start.getMinutes().toLocaleString('fr', {minimumIntegerDigits:2}))
                _start.setTime(_start.getTime() + (30*60000));
            } while (_start < _end)

        }

        return times;
    }

    function arraySearch(arr: ActivityNeeds, day: number, time: string, job?: Job) {
        for (let i=0; i<arr.length; i++)
            if (arr[i]['day'] === day && arr[i]['time'] === time && ((!job && !arr[i]['job']) || (job && job.id === arr[i]['job']?.id)))
                return i;
        return false;
    }

    const addOrEdit = (day : number, time: string, val: number, job?: Job) => {

        let key = arraySearch(needs, day, time, job)
        if (key !== false){
            needs[key].val = val
        }else{
            needs.push({day: day, time: time, val: val, job: job})
        }

        setNeeds([...needs]);

        api.posts(activity.id!, {day: day, job: job?.id, val: val, time: time})
    }

    const handleTypeChange = (e: BaseSyntheticEvent) => {
        setIsLoading(true)
        api.type(activity.id!, e.target.value).then(data => {
            dispatch(editActivitySuccess(data.data))
            setIsLoading(false)
        }).catch(error => dispatch(axiosError(error)));
    }

    if (!start || !end){
        return <Loader />
    }

    return (
        <>
            <div className="card p-1 p-md-3 mb-3 w-100">
                <div className="row">
                    {Granted(accessRights.EDIT_ACTIVITY) && <div className="col">
                        <div className="form-check form-check-inline">
                            <input onChange={handleTypeChange} defaultChecked={activity.needType === 1}
                                   className="form-check-input" type="radio" name="type" id="global"
                                   value="1"/>
                            <label className="form-check-label" htmlFor="global">
                                Besoin Global
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input onChange={handleTypeChange} defaultChecked={activity.needType === 2}
                                   className="form-check-input" type="radio" name="type" id="byJob"
                                   value="2"/>
                            <label className="form-check-label" htmlFor="byJob">
                                Besoin par métier
                            </label>
                        </div>
                    </div>}
                    {Granted(accessRights.EDIT_ACTIVITY) && <div className="col-auto">
                        <button className={'btn btn-outline-primary'}
                                onClick={() => dispatch(openSidebar(ACTIVITY_NEED_ADD, {
                                    activity: activity,
                                    times: getTimeOccurrences()
                                }))}
                        >
                            <i className={'bi bi-plus'}> </i> Ajouter un besoin
                        </button>
                    </div>}
                    {Granted(accessRights.EDIT_ACTIVITY) && activity.needType === 2 && <div className="col-12 mt-2">
                        <BootstrapSelect
                            label={"Métiers"}
                            fetchEntity={"job"}
                            className={'bg-white'}
                            isMultiple={true}
                            disabled={isLoading}
                            onChangeMultiple={(choices) => {
                                setIsLoading(true)
                                api.jobs(activity.id!, choices).then(data => {
                                    dispatch(editActivitySuccess(data.data))
                                    setIsLoading(false)
                                }).catch(error => dispatch(axiosError(error)))
                            }}
                            values={activity.needJobs ? activity.needJobs.map(job => ({label: job.title, value: job.id!})) : []}
                        />
                    </div>}
                </div>
            </div>
            {isLoading ? <Loader /> :
                <div className={'table-responsive shadow-sm bg-white'} >
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th className={'position-sticky top-0 left-0 bg-white z100'}>Heure</th>
                        {activity.needType === 2 && <th style={{left: '3.8rem'}} className={'position-sticky top-0 bg-white z100'}>Métiers</th>}
                        <th className={'position-sticky top-0 bg-white z10'}>Lun.</th>
                        <th className={'position-sticky top-0 bg-white z10'}>Mar.</th>
                        <th className={'position-sticky top-0 bg-white z10'}>Mer.</th>
                        <th className={'position-sticky top-0 bg-white z10'}>Jeu.</th>
                        <th className={'position-sticky top-0 bg-white z10'}>Ven.</th>
                        <th className={'position-sticky top-0 bg-white z10'}>Sam.</th>
                        <th className={'position-sticky top-0 bg-white z10'}>Dim.</th>
                    </tr>
                    </thead>
                    <tbody>

                    {getTimeOccurrences().map((time: string) => {

                        switch (activity.needType){
                            case 1:
                                return (
                                    <tr>
                                        <td className={''}>
                                            <span className={"position-sticky top-0"}>
                                                {time}
                                            </span>
                                        </td>
                                        {[1,2,3,4,5,6,7].map((day: number) => {

                                            let item = needs.find((need: ActivityNeed) => (need.day === day && need.time === time && !need.job))
                                            let val = item?.val;

                                            return (
                                                <td className={'p-0 text-center'}>
                                                    <div className="w-100 d-flex align-items-center">
                                                        {Granted(accessRights.EDIT_ACTIVITY) && <span
                                                            onClick={() => addOrEdit(day, time, (val || 0) - 1)}
                                                            // onClick={() => handleChange(day + '_' + time, (val || 0) - 1)}
                                                            className={'d-none d-md-block btn' + (val && val > 0 ? " text-danger" : ' text-light')}>
                                                    <i className={'bi bi-dash-circle'}> </i>
                                                </span>}
                                                        <span className={'flex-grow-1'}>
                                                    {val ? val : <i className={'bi bi-x'}> </i>}
                                                </span>
                                                        {Granted(accessRights.EDIT_ACTIVITY) && <span
                                                            onClick={() => addOrEdit(day, time, (val || 0) + 1)}
                                                            className={'d-none d-md-block btn text-success'}>
                                                    <i className={'bi bi-plus-circle'}> </i>
                                                </span>}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            case 2:
                                return (
                                    <tr>
                                        <td style={{width: '3.8rem'}} className={'position-sticky left-0 bg-white'}>
                                            <span className={"position-sticky top-0"}>
                                                {time}
                                            </span>
                                        </td>
                                        <td style={{left: '3.8rem'}} className={'p-0 position-sticky bg-white'}>
                                            <table className={'table mb-0 px-0'}>
                                                <tbody>
                                                {activity.needJobs?.map(job => <tr>
                                                    <td className={'form-text'}>
                                                        {job.title}
                                                    </td>
                                                </tr>)}
                                                </tbody>

                                            </table>
                                        </td>

                                        {[1,2,3,4,5,6,7].map((day: number) => {

                                            let items = needs.filter((need: ActivityNeed) => (need.day === day && need.time === time))

                                            return (
                                                <td className={'p-0'}>
                                                    <table className={'table mb-0 px-0'}>
                                                        <tbody>
                                                            {activity.needJobs?.map(job => {

                                                                let val = items.find(item => item.job?.id === job.id!)?.val || 0

                                                                return <>
                                                                    <tr>
                                                                        <td
                                                                            onClick={() => addOrEdit(day, time, (val || 0) - 1, job)}
                                                                            // onClick={() => handleChange(day + '_' + time, (val || 0) - 1, job)}
                                                                            className={'d-none d-md-block ' + (val && val > 0 ? " text-danger" : ' text-light')}>
                                                                            <i className={'bi bi-dash-circle'}> </i>
                                                                        </td>
                                                                        <td>
                                                                            {val ? val : <i className={'bi bi-x'}> </i>}
                                                                        </td>
                                                                        <td
                                                                            onClick={() => addOrEdit(day, time, (val || 0) + 1, job)}
                                                                            // onClick={() => handleChange(day + '_' + time, (val || 0) + 1, job)}
                                                                            className={'d-none d-md-block text-success'}>
                                                                            <i className={'bi bi-plus-circle'}> </i>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>)}
                                            )}
                                    </tr>
                                )
                        }

                        return <> </>
                    })}
                    </tbody>
                </table></div>}
        </>
    )
}

export default ActivityNeedTable;