import {Companies, Company} from "../companies";
import {Job} from "../job";
import {AccessLevel} from "../accessLevel";
import {CompanyGroup} from "../companyGroup";
import {Activity} from "../activity";
import {Localisation} from "../localisation";
import {User} from "../auth";
import {SalaryTemplates} from "./template";
import {SalaryTemplateRecurrences} from "./template/recurrence";

export interface Salary {
    readonly id: number,
    accessLevel: AccessLevel,
    firstname: string,
    lastname: string,
    title: string,
    tel: string,
    email: string,
    timeClockToken: number,
    companyGroup?: CompanyGroup,
    birthdate: string|Date,
    companies: Companies,
    billingCompany: Company|null,
    address?: SalaryAddress,
    defaultLabels?: SalaryDefaultLabels,
    information?: SalaryInformation,
    files?: SalaryFiles,
    settings: SalarySettings,
    repartitions: SalaryRepartition[];
    managers: Managers,
    managed: Managers,
    templates: SalaryTemplates,
    recurrences?: SalaryTemplateRecurrences,
    rights: string[],
    locked: boolean,
    connector?: {
        id: number,
        silaeMatricule: string
    }
    createdAt: string,
    activated: boolean,
    user?: User
}

export interface Manager {
    id: number,
    manager: {id: number, title: string},
    managed: {id: number, title: string},
    rights: string[]
}

export type Managers = Manager[];

export interface SalaryFile {
    readonly id: number,
    title: string
}

export type SalaryFiles = SalaryFile[];


export interface SalaryAddress {
    address: string,
    city: string,
    zip: number
}

export interface SalaryDefaultLabel {
    id: number,
    company: Company,
    activity: Activity,
    localisation: Localisation
}

export type SalaryDefaultLabels = SalaryDefaultLabel[];

export interface SalaryInformation {
    id?: number,
    job: Job,
    contract: number,
    startDate: Date|string
    endDate: Date|string|null,
    flatRate: number,
    contractWorkingTime?: number,
    dayAnnualFlatRate?: number|null,
    hourlyRate?: number|null,
    socialSecurityNumber?: string|null,
    nightWorker: boolean,
    absenceBasis?: number|null,
    absenceBasisN1?: number|null,
    overtimeBasis?: number|null
}

export interface SalarySettings {
    lateAfter: number;
    advanceBefore: number;
    timeClockFromApp: boolean;
    timeClockActivated: boolean;
    absenceAcquisition: number;
    customAbsenceAcquisitions: { start: string; end: string; variation: number; }[]|null;
    contractWorkingTimeRepartition: boolean;
    contractWorkingTimeCurrentRepartition: number;
    contractWorkingTimeRepartitionTrigger: number;
    contractWorkingTimeRepartitionCurrentWeek: number;
    contractWorkingTimeRepartitionValues: number[];
    accountedType: number;
    accountedTime: number|null;
}

export interface SalaryRepartition {
    id: number;
    contractWorkingTimeRepartition: boolean;
    contractWorkingTimeRepartitionTrigger: number|null;
    contractWorkingTimeRepartitionCurrentWeek: number|null;
    contractWorkingTimeRepartitionCurrentWeekEditedAt: Date|null;
    contractWorkingTimeRepartitionValues: number[]|null;
    startDate: Date;
    endDate: Date;
    archived: boolean;
}

export type Salaries = Salary[];

export interface initialStateInterface {
    payload: Salaries,
    single: Salary|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};