import {post, get} from "../../../xhr";

interface Interface {
    start: Date;
    end: Date;
    accountingType: number;
    comment: string;
    salary: number;
    activity: number;
    localisation: number;
}

export function create(requestData: any, templateAppliedId: number){
    return post(`template/applied/${templateAppliedId}/event/create`, requestData);
}

export function edit(requestData: any, templateAppliedId: number, templateEventExceptionId: number){
    return post(`template/applied/${templateAppliedId}/event/edit/${templateEventExceptionId}`, requestData);
}

export function cancel(templateAppliedId: number, templateEventId: number, exDay: number, requestData: any){
    return post(`template/applied/${templateAppliedId}/event/${templateEventId}/cancel`, {...requestData, exDay: exDay});
}

export function sub(templateAppliedId: number, templateEventId: number, exDay: number, salaryId: number, requestData: any){
    return post(`template/applied/${templateAppliedId}/event/${templateEventId}/sub/${salaryId}`, {...requestData, exDay: exDay});
}

export function unsub(templateAppliedId: number, exceptionId: number){
    return get(`template/applied/${templateAppliedId}/event/unsub/${exceptionId}`);
}

export function labourDay(templateAppliedId: number, templateEventId: number, exDay: number, worked: boolean, requestData: any){
    return post(`template/applied/${templateAppliedId}/event/${templateEventId}/labour_day`, {...requestData, worked: worked, exDay: exDay});
}