import * as types from "../../constants/taskGroup"
import * as models from "../../models/taskGroup"
import replace from "../../utils/arrayReplace"
import arrayRemove from "../../utils/arrayRemove";

const taskGroup = (state: models.initialStateInterface = models.initialState, action: {type: string, taskGroup: models.TaskGroup, taskGroups: models.TaskGroups, error: string}) => {

    switch (action.type) {
        case types.FETCH_TASK_GROUPS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_TASK_GROUPS_SUCCESS:
            return { payload: action.taskGroups, single: state.single, isLoading: false, error: null };
        case types.FETCH_TASK_GROUPS_FAILURE:
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };

        case types.ADD_TASK_GROUP:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_TASK_GROUP_SUCCESS:
            return { payload: [...state.payload, action.taskGroup], single: action.taskGroup, isLoading: false, error: null };

        case types.EDIT_TASK_GROUP:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_TASK_GROUP_SUCCESS:
            return { payload:  replace(state.payload, action.taskGroup), single: action.taskGroup, isLoading: false, error: null };
        case types.SHOW_TASK_GROUP:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_TASK_GROUP_SUCCESS:
            return { payload: state.payload, single: action.taskGroup, isLoading: false, error: null };
        case types.SHOW_TASK_GROUP_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        case types.CANCEL_TASK_GROUP_SUCCESS:
            return { payload: arrayRemove(state.payload, action.taskGroup), isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default taskGroup;