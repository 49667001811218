import {RootStateOrAny, useSelector} from "react-redux";
import React from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import {GrantedAny} from "../../security/granted";

export const GuestRoute = ({component, ...rest}: any) => {

    const state = useSelector((state: RootStateOrAny)  => state);
    const isAuthenticated = !!state.auth.token;

    const routeComponent = (props: any) => (
        !isAuthenticated
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

export const AdminRoute = ({component, rights, ...rest}: any) => {
    const state = useSelector((state: RootStateOrAny)  => state);
    const isAuthenticated = !!state.auth.token;
    const location = useLocation();

    const RouteComponent = (props: any) => {
        if (!isAuthenticated){
            localStorage.setItem('next_route', location.pathname);
            return <Redirect to={{pathname: '/login'}} />
        }else if (!state.auth.initialUser.roles.includes('ROLE_SUPER_ADMIN')) {
            return <Redirect to={{pathname: '/403'}} />
        }else{
            return React.createElement(component, props)
        }
    }

    return <Route {...rest} render={RouteComponent}/>;
}

export const PrivateRoute = ({component, rights, ...rest}: any) => {

    const state = useSelector((state: RootStateOrAny)  => state);
    const isAuthenticated = !!state.auth.token;
    const hasCurrentSalary = !!state.auth.user?.currentSalary;
    const hasCurrentCompany = !!state.auth.user?.currentSalary?.companies.length;
    const location = useLocation();

    const RouteComponent = (props: any) => {
        if (!isAuthenticated){
            localStorage.setItem('next_route', location.pathname);
            return <Redirect to={{pathname: '/login'}} />
        }else if (rights && !GrantedAny(rights)) {
            return <Redirect to={{pathname: '/403'}} />
        }else if (hasCurrentSalary && !hasCurrentCompany){
            return <Redirect to={{pathname: '/no_company'}} />
        }else if (hasCurrentSalary){
            return React.createElement(component, props)
        }else{
            localStorage.setItem('next_route', location.pathname);
                return <Redirect to={{pathname: '/account'}} />
        }
    }

    return <Route {...rest} render={RouteComponent}/>;
};

export const PrivateRouteWithoutCompany = ({component, rights, ...rest}: any) => {

    const state = useSelector((state: RootStateOrAny)  => state);
    const isAuthenticated = !!state.auth.token;
    const hasCurrentSalary = !!state.auth.user?.currentSalary;
    const hasCurrentCompany = !!state.auth.user?.currentSalary?.companies.length;
    const location = useLocation();

    const RouteComponent = (props: any) => {
        if (!isAuthenticated){
            localStorage.setItem('next_route', location.pathname);
            return <Redirect to={{pathname: '/login'}} />
        }else if (rights && !GrantedAny(rights)) {
            return <Redirect to={{pathname: '/403'}} />
        }else if (hasCurrentSalary && !hasCurrentCompany){
            localStorage.setItem('next_route', location.pathname);
            return React.createElement(component, props)
        }else{
            return <Redirect to={{pathname: '/'}} />
        }
    }

    return <Route {...rest} render={RouteComponent}/>;
};

export const PrivateRouteWithoutAccount = ({component, ...rest}: any) => {

    const state = useSelector((state: RootStateOrAny)  => state);
    const isAuthenticated = !!state.auth.token;
    const hasCurrentSalary = !!state.auth.user?.currentSalary;

    const RouteComponent = (props: any) => {
        if (!isAuthenticated){
            return <Redirect to={{pathname: '/login'}} />
        }else if (!hasCurrentSalary){
            return React.createElement(component, props)
        }else{
            return <Redirect to={{pathname: '/'}} />
        }
    }

    return <Route {...rest} render={RouteComponent}/>;
};

export const PrivateRouteAny = ({component, ...rest}: any) => {

    const state = useSelector((state: RootStateOrAny)  => state);
    const isAuthenticated = !!state.auth.token;

    const RouteComponent = (props: any) => {
        if (isAuthenticated){
            return <Redirect to={{pathname: '/login'}} />
        }else{
            return React.createElement(component, props)
        }
    }

    return <Route {...rest} render={RouteComponent}/>;
};