import * as accessRights from "../../../constants/accessRight";
import * as models from "../../../models/task";
import {User} from "../../../models/auth";
import accessRightChecker from "../../accessRightChecker";

class TaskVoter {

    static supports(right: string, object?: models.Task)
    {
        return [
            accessRights.EDIT_TASK,
        ].includes(right) && object
    }

    voteOnAttribute(right: string, object: models.Task, user: User){

        let currentSalary = user.currentSalary;

        if (!currentSalary){
            return false;
        }

        return accessRightChecker.grantedForMany(right, object.salaries.map(s => s.id))
    }
}

export default TaskVoter;