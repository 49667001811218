import {post, get} from "../xhr";
import * as models from "../../models/template";

export function list(requestData?: any){
    return post('template/list', requestData || {});
}

export function edit(requestData: any, id: number){
    return post('template/edit/' + id, requestData);
}

export function cancel(id: number){
    return get('template/delete/' + id);
}

export function activate(id: number){
    return get(`template/${id}/activate`);
}

export function deactivate(id: number){
    return get(`template/${id}/deactivate`);
}

export function create(requestData: any){
    return post('template/create',  requestData);
}

export function make(requestData:any){
    return post('template/make',  requestData);
}

export function show(id: string){
    return get('template/show/' + id);
}
export function simulate(id: number, checked: boolean){
    return get('template/' + (checked ? 'simulate' : 'cancel_simulation') + '/' + id);
}

export function fetchCalendar(requestData: {start: string, end: string, view: string, groupBy: number}, id: number){
    return post('template/'+ id +'/calendar',  requestData);
}

export function fetch(requestData: {start: Date, end: Date, view: string, sortBy: number, timePrecision: string, presence: boolean, resources?: number[]}, id: number, signal?: AbortSignal){
    return post(`template/${id}/fetch`,  requestData, {signal: signal});
}

export function schedule(requestData: {start: Date, end: Date, target: string, targetId: number, breakDuration: number, shiftDuration: number, resources: number[]}, id: number)
{
    return post(`template/${id}/schedule`,  requestData);
}