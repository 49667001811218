import {Salary} from "../salary";

export type FetchResponse = TotalCounters

// export type TotalCounters = TotalCounter[];
//
// export interface TotalCounter {
//     value: number,
//     valueLabel: string,
//
//     initialValue: number,
//     initialValueLabel: string,
//
//     paidOverTime: number,
//     paidOverTimeLabel: string
//
//     salaryShortName: string,
//     salaryFullName: string,
//     salaryId: number,
//
//     counters: Counters
// }
//
// export interface Counter {
//     id: number,
//     weekNumber: number,
//     year: number,
//     start: string,
//     end: string,
//
//     initialValue: number,
//     initialValueHour: number,
//     initialValueMin: number,
//     initialValueLabel: string,
//
//     value: number,
//     valueLabel: string,
//     valueHour: number,
//     valueMin: number,
//
//     paidOverTime: number,
//     paidOverTimeLabel: string,
//     paidOverTimeHour: number,
//     paidOverTimeMin: number,
//
//     valueNoMarkUpHour: number,
//     valueNoMarkUpMin: number
// }
//
// export type Counters = Counter[];

export interface TotalCounter {
    id: number,
    salary: Salary,
    total: number,
    totalWithdrawn: number,
    totalPaid: number,
    totalCounter: number,
    counters: Counters,
    countersWithdrawn: CountersWithdrawn,
}

export type TotalCounters = TotalCounter[];

export interface Counter {
    id: number,
    salary: Salary,
    weekNumber: number,
    weekStart: string,
    weekEnd: string,
    year: number,
    yearWeek: string,
    value: number,
    decimal: number,
    time: string,
    markUpTime: string,
    markUpDecimal: number,
    initialTime: string,
    initialValue: number,
}

export type Counters = Counter[];

export interface CounterWithdrawn {
    id: number,
    salary: Salary,
    weekNumber: number,
    weekStart: string,
    weekEnd: string,
    yearWeek: string,
    year: number,
    value: number,
    decimal: number,
    time: string,
    description : string
}

export type CountersWithdrawn = CounterWithdrawn[];

export interface initialStateInterface {
    payload: TotalCounters;
    single: TotalCounter|null;
    isLoading: boolean;
    error: string|null;
    hasChanged: boolean;
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null,
    hasChanged: false
};