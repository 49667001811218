import React, {useEffect, useRef, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {EventClickArg} from "@fullcalendar/react";
import {openSidebar} from "../../actions/rightSidebar";
import {
    FORM_EDIT_ABSENCE,
    FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION
} from "../../constants/rightSidebar";
import format from "../locale";
import {hideEventPop, hideTemplateEventPop, refresh} from "../../actions/calendar";
import {showAlertSuccess} from "../../actions/alert";
import * as api from "../../adapters/template/applied/exception";
import {getAccountingTypeChoice} from "../../constants/booking";
import {calendarInitialStateInterface} from "../../models/calendar";

const EventAppliedPop:React.FC = () => {

    const e:EventClickArg = useSelector((state: RootStateOrAny) => state.calendar.eventClickArg)
    const displayEventPop:boolean = useSelector((state: RootStateOrAny) => state.calendar.displayTemplateEventPop)
    const eventPopRef = useRef<HTMLDivElement>(null)
    const calendar: calendarInitialStateInterface = useSelector((state: RootStateOrAny) => state.calendar)
    const [displayCancelConfirmation, setDisplayCancelConfirmation] = useState(false);
    const [cancelType, setCancelType] = useState<1|2>(1);
    const [isLoading, setIsLoading] = useState(false)
    const [labourDayLoading, setLabourDayLoading] = useState(false)
    const [positionsLoaded, setPositionsLoaded] = useState(false)
    const [positions, setPositions] = useState<DOMRect>()
    const dispatch = useDispatch();

    useEffect(() => {
        if (positions){
            eventPopPosition();
        }
    }, [positions])

    useEffect(() => {

        setCancelType(1);
        document.addEventListener('click', handleBodyClick)

        if (e){
            setPositions(e?.el.getBoundingClientRect())
        }

        return () => {
            document.removeEventListener('click', handleBodyClick)
        }
    }, [e, displayCancelConfirmation])

    useEffect(() => {
        setDisplayCancelConfirmation(false)
    }, [e, displayEventPop])

    const setLabourDayWorked = (e: EventClickArg, worked: boolean) => {
        setLabourDayLoading(true);
        // api.labourDay(e.event.extendedProps.templateAppliedId, e.event.extendedProps.bookingInstanceId, e.event.extendedProps.exceptionInstanceId, worked).then(() => {
        //     dispatch(refresh());
        //     dispatch(showAlertSuccess('Créneau mis à jour'))
        // })
    }

    const eventPopPosition = () => {
        setPositionsLoaded(false)
        let popup = eventPopRef.current
        if (displayEventPop && popup && e && positions){
            if ((popup.clientHeight/2) + (positions.top + (positions.height/2)) > window.innerHeight){
                let diff = (popup.clientHeight/2) + (positions.top + (positions.height/2)) - window.innerHeight;
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) - diff - 15 + 'px';
            }else if ((popup.clientHeight/2) > positions.top + (positions.height/2)){
                let diff = (popup.clientHeight/2) - (positions.top + (positions.height/2));
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) + diff + 15 + 'px';
            }else{
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) + 'px';
            }

            if (positions.left > (320 + 5) || (window.innerWidth - positions.left - positions.width) > (320 + 5)){
                if (positions.left > (window.innerWidth - positions.left - positions.width)){
                    // LEFT
                    popup.style.left = (positions.left - 320 - 5) + 'px'
                }else{
                    //RIGHT
                    popup.style.left = (positions.left + positions.width + 5) + 'px'
                }
            }else{
                popup.style.left = (window.innerWidth - 320) / 2 + 'px';
            }
        }
        setPositionsLoaded(true)
    }

    const handleBodyClick = (evt: MouseEvent) => {

        let popup = eventPopRef.current

        if (popup && displayEventPop){
            let dimensions = popup.getBoundingClientRect();

            let [xStart, xEnd] = [dimensions.left, dimensions.left + dimensions.width];
            let [yStart, yEnd] = [dimensions.top, dimensions.top + dimensions.height];

            let clickOutsideX = !(xStart <= evt.clientX && xEnd >= evt.clientX);
            let clickOutsideY = !(yStart <= evt.clientY && yEnd >= evt.clientY);

            if (clickOutsideX || clickOutsideY){
                dispatch(hideEventPop())
            }
        }
    }

    const EventPopContent = () => {
        if (displayEventPop){
            return <>
                {e.event.extendedProps.absence && <div className={'col-12 mb-2 border-bottom'}>
                    <span className={"h4 mb-0"}>{e.event.extendedProps.absence.typeName}</span>
                    <span className="">
                        <button className={'btn'} onClick={() => {
                            dispatch(openSidebar(FORM_EDIT_ABSENCE, e.event.extendedProps.absence))
                            dispatch(hideTemplateEventPop())
                        }}>
                        <i className="bi bi-pencil-square text-primary"> </i>
                    </button>
                    </span>
                    <div className="clearfix"> </div>
                    <p className="form-text">
                        Du {format(new Date(e.event.extendedProps.absence.start)) + ' au ' + format(new Date(e.event.extendedProps.absence.end))}
                    </p>
                </div>}
                <div className="col-12">
                    <button className={'m-1 btn btn-close float-end'}  onClick={() => dispatch(hideTemplateEventPop())} />
                    <button className={'m-1 btn btn-sm text-primary'} onClick={() => {
                        dispatch(hideTemplateEventPop())
                        dispatch(openSidebar(FORM_NEW_TEMPLATE_APPLIED_BOOKING_EXCEPTION, {
                            start: e.event.start,
                            end: e.event.end,
                            accountingType: e.event.extendedProps.accountingType,
                            company: e.event.extendedProps.company,
                            activity: e.event.extendedProps.activity,
                            localisation: e.event.extendedProps.localisation,
                            salary: e.event.extendedProps.salary,
                            instanceToken: e.event.extendedProps.instanceToken,
                            instanceId: e.event.extendedProps.instanceId,
                            isAppliedException: e.event.extendedProps.isAppliedException,
                            bookingInstanceId: e.event.extendedProps.bookingInstanceId,
                            exceptionInstanceId: e.event.extendedProps.exceptionInstanceId,
                            description: e.event.extendedProps.description,
                            templateAppliedId: e.event.extendedProps.templateAppliedId,
                            resourceId: calendar.resourceId,
                            parentId: calendar.parentId,
                        }))
                    }}>
                        <i className={'bi bi-pencil'}> </i>
                    </button>
                    <button className={'m-1 btn btn-sm text-danger'} onClick={(e) => {
                        e.stopPropagation()
                        setDisplayCancelConfirmation(true)
                    }}>
                        <i className={'bi bi-dash-circle'}> </i>
                    </button>
                </div>
                {e.event.extendedProps.labourDay && <>
                    <h4 className={'text-center text-primary w-100 mb-2'}>
                        {e.event.extendedProps.labourDay.title}
                    </h4>
                    <div className="form-check mb-2">
                        <input
                            onChange={(ev) => setLabourDayWorked(e, ev.target.checked)}
                            defaultChecked={e.event.extendedProps.labourDayWorked}
                            className="form-check-input" type="checkbox" id="labourDay" />
                        <label className="form-check-label" htmlFor="labourDay">
                            Jour férié travaillé
                        </label>
                    </div>
                </>}
                <div className="col-12 mb-2">
                    <div className="row">
                        <div className="col">
                            {e.event.extendedProps.salary.title} | {e.event.extendedProps.salary.information.job.title}
                            <div className="clearfix"> </div>
                            <i className={'bi bi-front text-primary'}></i> {e.event.extendedProps.templateTitle}
                            {/*<button className={'btn btn-sm'}*/}
                            {/*    onClick={() => dispatch(openSidebar(TEMPLATES_APPLY_EDIT, e.event.extendedProps.templateApplied))}*/}
                            {/*>*/}
                            {/*    <i className={'bi bi-pencil text-primary'}></i>*/}
                            {/*</button>*/}
                            <div className="clearfix"> </div>
                            {e.event.extendedProps.description && <span className={'form-text'}>
                                <i className={'bi bi-card=text text-primary'}> </i> {e.event.extendedProps.description}
                            </span>}
                            <div className="clearfix"> </div>
                            <i className={'bi bi-telephone text-primary'}> </i> {e.event.extendedProps.salary.tel}
                            <div className="clearfix"> </div>
                            <i className="bi bi-shop text-primary"> </i> <span>{e.event.extendedProps.company.title}</span>
                            <div className="clearfix"> </div>
                            <i className={e.event.extendedProps.localisation?.icon}> </i>
                            <span>{e.event.extendedProps.localisation?.title || ''}</span>
                            <div className="clearfix"> </div>
                            <i className={e.event.extendedProps.activity?.icon}> </i>
                            <span>{e.event.extendedProps.activity?.title || ''}</span>
                        </div>
                        <div className="col-auto">
                            <div className="color-circle">
                                <div className="p-1 m-1 shadow" style={{
                                    opacity: '0.8',
                                    backgroundColor: e.event.backgroundColor
                                }}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <span>
                        <i className={'bi bi-clock text-primary'}> </i> {e.event.extendedProps.beginAt} - {e.event.extendedProps.endAt} - {e.event.extendedProps.timeCaption}
                    </span>
                    <div className="clearfix"> </div>
                    {e.event.extendedProps.accountingType && <span>{getAccountingTypeChoice(e.event.extendedProps.accountingType)?.label}</span>}
                    <span className="clearfix"> </span>
                    {e.event.extendedProps.groupTitle && <span>
                        <i className={'bi bi-calendar text-primary'}> </i>{e.event.extendedProps.groupTitle}
                    </span>}
                </div>
            </>
        }else{
            return <></>
        }
    }

    const cancelBooking = () => {
        setIsLoading(true);
        // api.cancel(e.event.extendedProps.templateAppliedId, e.event.extendedProps.bookingInstanceId, e.event.extendedProps.exceptionInstanceId)
        //     .then(() => {
        //         dispatch(hideTemplateEventPop())
        //         dispatch(refresh());
        //         dispatch(showAlertSuccess('Créneau supprimé'))
        //         setIsLoading(false);
        //     })
    }

    const CancelConfirmation = () => {
        return <>
            <div className="w-100 p-3">
                <div className="col-12 text-center mb-2">
                    Êtes vous sûr de vouloir supprimer le créneau suivant ?
                </div>
                <div className="clearfix"> </div>
                <div className="card p-2 mb-2">
                    {e.event.extendedProps.salary.title}
                    <div className="col-12 mb-2">
                        <span>{e.event.extendedProps.beginAt} - {e.event.extendedProps.endAt} - {e.event.extendedProps.timeCaption}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <button onClick={(e) => {
                                e.stopPropagation()
                                cancelBooking()
                            }} className={'btn btn-outline-danger w-100'}>
                                Oui
                            </button>
                        </div>
                        <div className="col-6">
                            <button onClick={(e) => {
                                e.stopPropagation()
                                setDisplayCancelConfirmation(false)
                            }} className={'btn btn-light w-100'}>
                                Non
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    if (!e) return <></>

   return <div ref={eventPopRef} id="eventPop" className={"card shadow p-3 position-fixed fade " + (positionsLoaded && displayEventPop ? 'show' : 'hide')} style={{width:320, transition: '0.2s', zIndex: (displayEventPop ? 9999 : -1)}}>
            {isLoading ?
                <div className={'w-100 text-center'}>
                    <div className="spinner-border my-5 text-primary" style={{
                        width: '4rem',
                        height: '4rem',
                    }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                 : (displayCancelConfirmation ? <CancelConfirmation /> : <EventPopContent />)}
         </div>
}

export default EventAppliedPop;