import React from 'react'
import { useDispatch } from "react-redux"

import EmptyList from "../../../../../common/EmptyList"
import CompanySalaryLinkItem from "./CompanySalaryLinkItem"

import { Salary } from "../../../../../../models/salary"
import { Company } from '../../../../../../models/companies'

import { linkBillingCompanyToSalary } from "../../../../../../adapters/admin/billing"
import { showAlertSuccess, showAlertInfo, showAlertDanger } from "../../../../../../actions/alert"

interface IProps {
  salaries: Salary[];
  companies: Company[];
  onRefresh: (noLoading?: string) => void;
}

const CompanySalariesCard: React.FC<IProps> = ({ salaries, companies, onRefresh }) => {
  const dispatch = useDispatch()

  const onLinkSalaryToBillingCompany = async (salaryId: number, companyId: number) => {
    try {
      await linkBillingCompanyToSalary(salaryId, companyId)
      onRefresh('noLoading')
      dispatch(showAlertSuccess('L’entreprise a été liée au salarié'))
    } catch (error) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    }
  }

  return (
    <div className="mt-2 col-12 p-3 bg-white border rounded">
      <div className="mb-3 pb-2 border-bottom d-flex justify-content-between">
        <p className="m-0 fw-bold">
          Salariés du compte
        </p>
      </div>

      {salaries.length === 0 &&
        <EmptyList text="Aucun salarié associé à ce groupement" />
      }

      {salaries.length > 0 &&
        <div>
          {salaries.map(salary => (
            <CompanySalaryLinkItem
              key={salary.id}
              salary={salary}
              companies={companies}
              onLink={onLinkSalaryToBillingCompany}
            />
          ))}
        </div>
      }
    </div>
  )
}

export default CompanySalariesCard