import {get, MULTIPART_FORM_DATA, post} from "../xhr";

export function create(requestData: FormData, entity: string){
    return post(`file/create/${entity}`, requestData, {headers: [MULTIPART_FORM_DATA], responseType: "json"})
}

export function _delete(fileId: number){
    return get(`file/delete/${fileId}`)
}

export function download(fileId: number){
    return get(`file/download/${fileId}`, {responseType: "arraybuffer"})
}
