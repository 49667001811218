import React, {useState} from "react";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import FormError from "../../../../../utils/formError";
import * as api from "../../../../../adapters/timeClock";
import DatePicker from "react-datepicker";
import validationClass from "../../../../../utils/validationClass";
import DateCustomInput from "../../../../../utils/customInput";
import {Salary} from "../../../../../models/salary";
import {Company} from "../../../../../models/companies";
import {showAlertSuccess} from "../../../../../actions/alert";
import SmallLoader from "../../../../../utils/loader/small";
import * as accessRightsTypes from "../../../../../constants/accessRight";
import SalarySelector from "../../../../salary/selector";

interface Interface {
    values: {
        start?: Date|null
        end?: Date|null
        salary: Salary
        company: Company
        timeClockId: number
        description: string
    },
    handleSubmitSuccess: (ev: any) => any;
}

const SignedEditForm:React.FC<Interface> = (props) => {

    const {values, handleSubmitSuccess} = props;

    const dispatch = useDispatch();
    const [salary, setSalary] = useState<Salary>(values.salary)

    const initialValues = {
        beginAt: values.start,
        endAt: values.end,
        salary: salary.id,
        company: values.company.id!,
        description: values.description
    };

    const validationSchema = Yup.object().shape({
        beginAt: Yup.date().required('Début requis !'),
        endAt: Yup
            .date()
            .required('Fin requise !')
            .test("is-greater", "La date de fin doit être après le début", function(value: any) {
                const { beginAt } = this.parent;
                return value && beginAt <= value
            }),
        salary: Yup.number().required('Veuillez choisir un collaborateur'),
        company: Yup.number().required('Veuillez choisir un point de vente'),
        description: Yup.string().nullable()
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (_values, formikHelpers) => {
            api.edit(_values, values.timeClockId).then((resp) => {
                Promise.all([
                    handleSubmitSuccess(resp.data)
                ]).then(() => {
                    dispatch(showAlertSuccess('Créneau ajouté'));
                    formikHelpers.setSubmitting(false)
                })
            })
        }
    })

    return <>
        <form className={'p-3'} onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="col-md-12 mb-3">
                    <div className="input-group">
                        <DatePicker
                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                            customInput={<DateCustomInput label={'Début'} icon={'text-primary bi bi-calendar-check'} />}
                            name={'beginAt'}
                            id={'beginAt'}
                            selected={formik.values.beginAt}
                            onChange={(date: Date) => {
                                formik.setFieldValue(`beginAt`, date)
                            }}
                            selectsStart
                            showTimeInput={true}
                            timeIntervals={15}
                            timeCaption="Heure de début"
                            dateFormat="E dd MMM uuuu HH:mm"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                        />
                        <span className="input-group-text">
                                                <i className={'bi bi-arrow-left-right'}> </i>
                                            </span>
                        <DatePicker
                            className={'form-control ' + validationClass(formik.errors, formik.touched, 'endAt')}
                            customInput={<DateCustomInput label={'Fin'} icon={'text-primary bi bi-calendar-check'} />}
                            name={'endAt'}
                            id={'endAt'}
                            selected={formik.values.endAt}
                            onChange={(date: Date) => {
                                formik.setFieldValue(`endAt`, date)
                            }}
                            selectsEnd
                            startDate={formik.values.beginAt}
                            endDate={formik.values.endAt}
                            minDate={formik.values.beginAt}
                            showTimeInput={true}
                            timeIntervals={15}
                            timeCaption="Heure de fin"
                            dateFormat="E dd MMM uuuu HH:mm"
                            popperPlacement={"top-end"}
                            showPopperArrow={false}
                        />
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'beginAt'} />
                    <FormError errors={formik.errors} touched={formik.touched} field={'endAt'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <SalarySelector
                    required={true}
                    value={salary}
                    fetchOptions={true}
                    onChange={(s: Salary) => formik.setFieldValue('salary', s.id)}
                    accessRight={accessRightsTypes.EDIT_TIME_CLOCK}
                />
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    label={'Établissement'}
                    enableReinitialize={true}
                    required={true}
                    labelIcon={'text-primary bi bi-shop'}
                    //options={salary ? salary.companies.map(c => ({label: c.title, value: c.id!!})) : undefined}
                    fetchEntity="company"
                    fetchParams={{ salaries: [formik.values.salary] }}
                    className={validationClass(formik.errors, formik.touched, 'company')}
                    value={values.company ? {label: values.company.title, value: values.company.id!} : undefined}
                    onChange={(choice) => formik.setFieldValue('company', choice?.value)}
                />
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <textarea className={'form-control'}  name={'description'} onChange={formik.handleChange}>
                        {values.description}
                    </textarea>
                    <label htmlFor="floatingTextarea">Description</label>
                </div>
            </div>
            <button className={'btn btn-outline-primary w-100'} type={"submit"}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check'}> </i> Valider</>}
            </button>
        </form>
     </>
}

export default SignedEditForm;