import React from "react";
import * as Yup from 'yup';
import {useFormik} from "formik";
import SalarySelector from "../../../selector";
import {Salary} from "../../../../../models/salary";
import SmallLoader from "../../../../../utils/loader/small";
import * as accessRightsTypes from "../../../../../constants/accessRight";
import * as api from "../../../../../adapters/salary/manager";
import * as actions from "../../../../../actions/salary";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {closeSidebar} from "../../../../../actions/rightSidebar";

const SalaryManagerAdd:React.FC = () => {

    const props = useSelector((state: RootStateOrAny) => state.rightSidebar.data);

    const dispatch = useDispatch();
    const initialValues = {
        salary: '',
        rights: accessRightsTypes.getAllManagerRights()
    }

    const validationSchema = Yup.object().shape({
        salary: Yup.number().required(),
        rights: Yup.array().of(Yup.string()).required()
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            api.create(values, props.target.id).then(data => {
                dispatch(actions.editSalarySuccess(data.data));
                dispatch(closeSidebar());
            })
        }
    })

    return <>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <SalarySelector
                    required={true}
                    fetchOptions={true}
                    onChange={(s: Salary) => formik.setFieldValue('salary', s!.id)}
                    accessRight={accessRightsTypes.EDIT_SALARY}
                />
            </div>
            <div className="col-12 mb-3">
                {Object.values(accessRightsTypes.ManagerAccessRights).map(accessRight => <div className={'col-12 mb-3'}>
                    <h4>
                        {accessRight.title}
                    </h4>
                    <ul className={'list-group'}>
                        {accessRight.items.map(right => <li className={'list-group-item'}>
                            <div className="form-check form-switch">
                                <input className="form-check-input" defaultChecked={true} name={'rights'} value={right.underscoreCase} onChange={formik.handleChange} type="checkbox" id={right.underscoreCase} />
                                    <label className="form-check-label" htmlFor={right.underscoreCase}>{right.description}</label>
                            </div>
                        </li>)}
                    </ul>
                </div>)}
            </div>
            <button className={'btn btn-light w-100'}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check text-success'}></i> Valider</>}
            </button>
        </form>
    </>
}

export default SalaryManagerAdd;