import * as types from "../../constants/expense";
import * as models from "../../models/expense";

export const addExpense = () => {
    return {
        type: types.ADD_EXPENSE,
    }
}

export const addExpenseSuccess = (expense: models.Expense) => {
    return {
        type: types.ADD_EXPENSE_SUCCESS,
        expense: expense,
    }
}

export const addExpenseFailure = (error: string) => {
    return {
        type: types.ADD_EXPENSE_FAILURE,
        expense: null,
        expenses: null,
        error: error
    }
}

export const editExpense = () => {
    return {
        type: types.EDIT_EXPENSE,
    }
}

export const cancelExpenseSuccess = (expense: models.Expense) => {
    return {
        type: types.CANCEL_EXPENSE_SUCCESS,
        expense: expense
    }
}

export const editExpenseSuccess = (expense: models.Expense) => {
    return {
        type: types.EDIT_EXPENSE_SUCCESS,
        expense: expense
    }
}

export const editExpenseFailure = (error: string) => {
    return {
        type: types.EDIT_EXPENSE_FAILURE,
        expense: null,
        expenses: null,
        error: error
    }
}

export const fetchExpenses = () => {
    return {
        type: types.FETCH_EXPENSES,
    }
}

export const fetchExpensesFailure = (error: string) => {
    return {
        type: types.FETCH_EXPENSES_FAILURE,
        expense: null,
        expenses: [],
        error: error
    }
}

export const fetchExpensesSuccess = (expenses: models.Expenses) => {
    return {
        type: types.FETCH_EXPENSES_SUCCESS,
        expense: null,
        expenses: expenses
    }
}


export const showExpense = () => {
    return {
        type: types.SHOW_EXPENSE,
    }
}
export const showExpenseSuccess = (expense: models.Expense) => {
    return {
        type: types.SHOW_EXPENSE_SUCCESS,
        expense: expense
    }
}
export const showExpenseFailure = (error: string) => {
    return {
        type: types.SHOW_EXPENSE_FAILURE,
        expense: null,
        expenses: null,
        error: error
    }
}