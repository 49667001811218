import * as React from "react";

import Export from "../../components/export";

const ExportPage : React.FC = () => {
    return (
        <Export />
    );
}

export default ExportPage;