export const ADD_ABSENCE = 'ADD_ABSENCE';
export const ADD_ABSENCE_SUCCESS = 'ADD_ABSENCE_SUCCESS';
export const ADD_ABSENCE_FAILURE = 'ADD_ABSENCE_FAILURE';

export const EDIT_ABSENCE = 'EDIT_ABSENCE';
export const EDIT_ABSENCE_SUCCESS = 'EDIT_ABSENCE_SUCCESS';
export const EDIT_ABSENCE_FAILURE = 'EDIT_ABSENCE_FAILURE';

export const FETCH_ABSENCES = 'FETCH_ABSENCES';
export const FETCH_ABSENCES_SUCCESS = 'FETCH_ABSENCES_SUCCESS';
export const FETCH_ABSENCES_FAILURE = 'FETCH_ABSENCES_FAILURE';

export const SHOW_ABSENCE = 'SHOW_ABSENCE';
export const SHOW_ABSENCE_SUCCESS = 'SHOW_ABSENCE_SUCCESS';
export const SHOW_ABSENCE_FAILURE = 'SHOW_ABSENCE_FAILURE';

export const CANCEL_ABSENCE_SUCCESS = 'CANCEL_ABSENCE_SUCCESS';

export const TYPE_CP = 1;
export const TYPE_RTT = 2;
export const TYPE_CSS = 3;
export const TYPE_REPOS = 4;
export const TYPE_MALADIE = 5;
export const TYPE_EVENT = 6;
export const TYPE_CT = 7;
export const TYPE_FORM = 8;
export const TYPE_MAT = 9;
export const TYPE_PAT = 10;
export const TYPE_PAR = 11;
export const TYPE_CHILD_DISEASE = 12;
export const TYPE_WORK_ACCIDENT = 13;
export const TYPE_UNJUSTIFIED = 14;

export const TYPE_NAMES = {
    1: 'Congé payé',
    2: 'RTT',
    3: 'Congé sans solde',
    4: 'Repos',
    5: 'Arrêt maladie',
    6: 'Évènement exceptionnel',
    8: 'Formation',
    9: 'Congé maternité',
    10: 'Congé paternité',
    11: 'Congé parental',
    12: 'Enfant malade',
    13: 'Accident de travail',
    14: 'Absence non justifiée'
};

export const COMPLEMENT_ALL_DAY = 1;
export const COMPLEMENT_MORNING = 2;
export const COMPLEMENT_AFTERNOON = 3;

export const COMPLEMENT_NAMES = {
    1: 'Toute la journée',
    2: 'Matin',
    3: 'Après midi',
}

export const EVENT_TYPE_WEDDING = 1;
export const EVENT_TYPE_BIRTH = 2;
export const EVENT_TYPE_DEATH = 3;
export const EVENT_TYPE_ELSE = 4;

export const EVENT_TYPE_NAMES = {
    1: 'Mariage/Pacs',
    2: 'Naissance/Adoption',
    3: 'Décés',
    4: 'Autre',
}

export const STATUS_PENDING = 1;
export const STATUS_ACCEPTED = 2;
export const STATUS_REFUSED = 3;

export const STATUS_NAMES = {
    1: 'En attente',
    2: 'Accepté',
    3: 'Refusé',
}

export const EVENT_TYPE_PRECISION_WEDDING_WEDDING_SALARY = 1;
export const EVENT_TYPE_PRECISION_WEDDING_PACS_SALARY = 2;
export const EVENT_TYPE_PRECISION_WEDDING_WEDDING_CHILD = 3;
export const EVENT_TYPE_PRECISION_WEDDING_WEDDING_SIBLING = 4;
export const EVENT_TYPE_PRECISION_WEDDING_WEDDING_SIBLING_IN_LAW = 5;
export const EVENT_TYPE_PRECISION_WEDDING_ASCENDANT = 6;
export const EVENT_TYPE_PRECISION_WEDDING_GRANDCHILD = 7;

let eventPrecisionWedding: number[] = [
    EVENT_TYPE_PRECISION_WEDDING_WEDDING_SALARY,
    EVENT_TYPE_PRECISION_WEDDING_PACS_SALARY,
    EVENT_TYPE_PRECISION_WEDDING_WEDDING_CHILD,
    EVENT_TYPE_PRECISION_WEDDING_WEDDING_SIBLING,
    EVENT_TYPE_PRECISION_WEDDING_WEDDING_SIBLING_IN_LAW,
    EVENT_TYPE_PRECISION_WEDDING_ASCENDANT,
    EVENT_TYPE_PRECISION_WEDDING_GRANDCHILD
];

let eventPrecisionWeddingName: string[] = [];
eventPrecisionWeddingName[EVENT_TYPE_PRECISION_WEDDING_WEDDING_SALARY] = "Mariage du salarié";
eventPrecisionWeddingName[EVENT_TYPE_PRECISION_WEDDING_PACS_SALARY] = "Pacs du salarié";
eventPrecisionWeddingName[EVENT_TYPE_PRECISION_WEDDING_WEDDING_CHILD] = "Mariage d'un enfant";
eventPrecisionWeddingName[EVENT_TYPE_PRECISION_WEDDING_WEDDING_SIBLING] = "Mariage d'un frère ou d'une soeur";
eventPrecisionWeddingName[EVENT_TYPE_PRECISION_WEDDING_WEDDING_SIBLING_IN_LAW] = "Marage d'un beau frère ou d'une belle soeur";
eventPrecisionWeddingName[EVENT_TYPE_PRECISION_WEDDING_ASCENDANT] = "Mariage d'un ascendant";
eventPrecisionWeddingName[EVENT_TYPE_PRECISION_WEDDING_GRANDCHILD] = "Mariage d'un petit-enfant";

export const getEventPrecisionChoices = (eventType: number) => {
    switch (eventType){
        case EVENT_TYPE_WEDDING:
            return [...eventPrecisionWedding.map((val => {return {value: val, label: eventPrecisionWeddingName[val]}}))]
        case EVENT_TYPE_BIRTH:
            return [...eventPrecisionBirth.map((val => {return {value: val, label: eventPrecisionBirthName[val]}}))]
        case EVENT_TYPE_DEATH:
            return [...eventPrecisionDeath.map((val => {return {value: val, label: eventPrecisionDeathName[val]}}))]
        case EVENT_TYPE_ELSE:
            return [...eventPrecisionElse.map((val => {return {value: val, label: eventPrecisionElseName[val]}}))]
        default:
            return [
                {label: "Aucune option", value: 0}
            ]
    }
}

export const getTypeChoices = () => {
    return [
        {value: TYPE_CP, label: TYPE_NAMES[TYPE_CP]},
        {value: TYPE_RTT, label: TYPE_NAMES[TYPE_RTT]},
        {value: TYPE_CSS, label: TYPE_NAMES[TYPE_CSS]},
        {value: TYPE_REPOS, label: TYPE_NAMES[TYPE_REPOS]},
        {value: TYPE_MALADIE, label: TYPE_NAMES[TYPE_MALADIE]},
        {value: TYPE_EVENT, label: TYPE_NAMES[TYPE_EVENT]},
        {value: TYPE_FORM, label: TYPE_NAMES[TYPE_FORM]},
        {value: TYPE_MAT, label: TYPE_NAMES[TYPE_MAT]},
        {value: TYPE_PAT, label: TYPE_NAMES[TYPE_PAT]},
        {value: TYPE_PAR, label: TYPE_NAMES[TYPE_PAR]},
        {value: TYPE_CHILD_DISEASE, label: TYPE_NAMES[TYPE_CHILD_DISEASE]},
        {value: TYPE_WORK_ACCIDENT, label: TYPE_NAMES[TYPE_WORK_ACCIDENT]},
        {value: TYPE_UNJUSTIFIED, label: TYPE_NAMES[TYPE_UNJUSTIFIED]}
    ]
}

export const getTypeChoice = (choice: number) => {
    return getTypeChoices().find(c => c.value === choice)!
}

export const getComplementChoices = () => {
    return [
        {value: COMPLEMENT_ALL_DAY, label: COMPLEMENT_NAMES[COMPLEMENT_ALL_DAY]},
        {value: COMPLEMENT_MORNING, label: COMPLEMENT_NAMES[COMPLEMENT_MORNING]},
        {value: COMPLEMENT_AFTERNOON, label: COMPLEMENT_NAMES[COMPLEMENT_AFTERNOON]},
    ];
}

export const getEventTypeChoices = () => {
    return [
        {value: EVENT_TYPE_WEDDING, label: EVENT_TYPE_NAMES[EVENT_TYPE_WEDDING]},
        {value: EVENT_TYPE_BIRTH, label: EVENT_TYPE_NAMES[EVENT_TYPE_BIRTH]},
        {value: EVENT_TYPE_DEATH, label: EVENT_TYPE_NAMES[EVENT_TYPE_DEATH]},
        {value: EVENT_TYPE_ELSE, label: EVENT_TYPE_NAMES[EVENT_TYPE_ELSE]},
    ];
}

export const getStatusChoices = () => {
    return [
        {value: STATUS_PENDING, label: STATUS_NAMES[STATUS_PENDING]},
        {value: STATUS_ACCEPTED, label: STATUS_NAMES[STATUS_ACCEPTED]},
        {value: STATUS_REFUSED, label: STATUS_NAMES[STATUS_REFUSED]},
    ];
}

export const EVENT_TYPE_PRECISION_BIRTH_BIRTH = 1;
export const EVENT_TYPE_PRECISION_BIRTH_ADOPTION = 2;

let eventPrecisionBirth: number[] = [
    EVENT_TYPE_PRECISION_BIRTH_BIRTH,
    EVENT_TYPE_PRECISION_BIRTH_ADOPTION
]

let eventPrecisionBirthName: string[] = [];
eventPrecisionBirthName[EVENT_TYPE_PRECISION_BIRTH_BIRTH] = "Naissance";
eventPrecisionBirthName[EVENT_TYPE_PRECISION_BIRTH_ADOPTION] = "Adoption";


export const EVENT_TYPE_PRECISION_DEATH_PARENT_IN_LAW = 1;
export const EVENT_TYPE_PRECISION_DEATH_PARTNER = 2;
export const EVENT_TYPE_PRECISION_DEATH_CHILD = 3;
export const EVENT_TYPE_PRECISION_DEATH_SIBLING = 4;
export const EVENT_TYPE_PRECISION_DEATH_GRAND_PARENTS = 5;
export const EVENT_TYPE_PRECISION_DEATH_PARENTS = 6;
export const EVENT_TYPE_PRECISION_DEATH_SIBLING_IN_LAW = 7;
export const EVENT_TYPE_PRECISION_DEATH_CHILD_IN_LAW = 8;
export const EVENT_TYPE_PRECISION_DEATH_GRANDCHILD = 9;

let eventPrecisionDeath: number[] = [
    EVENT_TYPE_PRECISION_DEATH_PARENT_IN_LAW,
    EVENT_TYPE_PRECISION_DEATH_PARTNER,
    EVENT_TYPE_PRECISION_DEATH_CHILD,
    EVENT_TYPE_PRECISION_DEATH_SIBLING,
    EVENT_TYPE_PRECISION_DEATH_GRAND_PARENTS,
    EVENT_TYPE_PRECISION_DEATH_PARENTS,
    EVENT_TYPE_PRECISION_DEATH_SIBLING_IN_LAW,
    EVENT_TYPE_PRECISION_DEATH_CHILD_IN_LAW,
    EVENT_TYPE_PRECISION_DEATH_GRANDCHILD
]

let eventPrecisionDeathName: string[] = [];
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_PARENT_IN_LAW] = "Décès du beau père ou de la belle mère";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_PARTNER] = "Décès du conjoint, concubin ou du partenaire lié par un PACS";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_CHILD] = "Décès d’un enfant";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_SIBLING] = "Décès d’un frère ou d’une sœur";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_GRAND_PARENTS] = "Décès d'un grand-parent";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_PARENTS] = "Décès du père ou de la mère";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_SIBLING_IN_LAW] = "Décès d'un beau-frère ou d'une belle-soeur";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_CHILD_IN_LAW] = "Décès d'un gendre ou d'une belle-fille";
eventPrecisionDeathName[EVENT_TYPE_PRECISION_DEATH_GRANDCHILD] = "Décès d'un petit-enfant";

export const EVENT_TYPE_PRECISION_ELSE_CHILD_HANDICAP = 1;
export const EVENT_TYPE_PRECISION_ELSE_MOVE = 2;
export const EVENT_TYPE_PRECISION_ELSE_CALL_OF_DUTY = 3;
export const EVENT_TYPE_PRECISION_ELSE_PARTNER_DISEASE = 4;
export const EVENT_TYPE_PRECISION_ELSE_PROFESSIONAL_EXAM = 5;
export const EVENT_TYPE_PRECISION_ELSE_CHILD_CEREMONY = 6;

let eventPrecisionElse: number[] = [
    EVENT_TYPE_PRECISION_ELSE_CHILD_HANDICAP,
    EVENT_TYPE_PRECISION_ELSE_MOVE,
    EVENT_TYPE_PRECISION_ELSE_CALL_OF_DUTY,
    EVENT_TYPE_PRECISION_ELSE_PARTNER_DISEASE,
    EVENT_TYPE_PRECISION_ELSE_PROFESSIONAL_EXAM,
    EVENT_TYPE_PRECISION_ELSE_CHILD_CEREMONY,
]

let eventPrecisionElseName: string[] = [];
eventPrecisionElseName[EVENT_TYPE_PRECISION_ELSE_CHILD_HANDICAP] = "Handicap survenant chez un enfant";
eventPrecisionElseName[EVENT_TYPE_PRECISION_ELSE_MOVE] = "Déménagement";
eventPrecisionElseName[EVENT_TYPE_PRECISION_ELSE_CALL_OF_DUTY] = "Appel de préparation à la défense";
eventPrecisionElseName[EVENT_TYPE_PRECISION_ELSE_PARTNER_DISEASE] = "Conjoint malade ou hospitalisé";
eventPrecisionElseName[EVENT_TYPE_PRECISION_ELSE_PROFESSIONAL_EXAM] = "Examen professionnel consécutif à une période de formation continue";
eventPrecisionElseName[EVENT_TYPE_PRECISION_ELSE_CHILD_CEREMONY] = "Cérémonie d'un enfant (communion solennelle...)";

