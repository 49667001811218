import * as React from "react";

import Confirmation from "../../../components/auth/confirmation";

const ConfirmationPage : React.FC = () => {
    return (
        <Confirmation />
    )
}

export default ConfirmationPage;