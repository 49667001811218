import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import {setHours, setMinutes} from "date-fns";
import FormError from "../../../../utils/formError";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {Company} from "../../../../models/companies";
import {SingleCompanySelected, SingleCompanySelector} from "../../../../utils/companySelector/single";
import {SalarySelected, SalarySelector} from "../../../../utils/salarySelector";
import * as api from "../../../../adapters/event/offer";
import * as actions from "../../../../actions/event/offer";
import {closeSidebar} from "../../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../../actions/alert";
import {Salary} from "../../../../models/salary";

const EventOfferEdit:React.FC = () => {

    const dispatch = useDispatch();
    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data);
    const [displayCompanySelector, setDisplayCompanySelector] = useState<boolean>(false);
    const [displaySalarySelector, setDisplaySalarySelector] = useState<boolean>(false);
    const [company, setCompany] = useState<Company>(data?.company);

    const formik = useFormik({
        initialValues: {
            description: data.description,
            start: new Date(data.start),
            end: new Date(data.end),
            company: data?.company?.id,
            localisation: data?.localisation?.id,
            activity: data?.activity?.id,
            salaries: data.salaries.map((s: Salary) => s.id),
        },
        validationSchema: Yup.object().shape({
            description: Yup.string(),
            start: Yup.date().required(),
            end: Yup.date().required(),
            company: Yup.number().required(),
            localisation: Yup.number(),
            activity: Yup.number(),
            salaries: Yup.array().of(Yup.number()).min(1, "Veuillez séléctionner au moins un collaborateur"),
        }),
        onSubmit: (values, formikHelpers) => {
            api.edit(values, data.id).then(resp => {
                dispatch(actions.editEventOfferSuccess(resp.data))
                dispatch(showAlertSuccess('Offre de créneau mis à jour'))
                dispatch(closeSidebar())
            });
        }
    })

    return <div className={'p-1 p-md-3'}>
        {displayCompanySelector && <SingleCompanySelector
            onSubmit={(company) => {
                setCompany(company)
                formik.setFieldValue('company', company.id)
                setDisplayCompanySelector(false)
            }}
            onClose={() => setDisplayCompanySelector(false)}
            initialValue={company?.id}
        />}
        {displaySalarySelector && <SalarySelector
            fetchParams={{
                beginAt: formik.values.start,
                endAt: formik.values.end,
                companies: [formik.values.company],
                isAvailable: true,
            }}
            initialValues={formik.values.salaries}
            onClose={() => setDisplaySalarySelector(false)}
            onSubmit={(salaries: number[]) => {
                formik.setFieldValue('salaries', salaries)
                setDisplaySalarySelector(false);
            }}
        />}
        <form className={(displayCompanySelector || displaySalarySelector) ? 'd-none' : 'd-block'} onSubmit={formik.handleSubmit}>
            <div className="col-md-12 mb-3">
                <div className="input-group">
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                        customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`start`, date)
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        endDate={formik.values.end}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                    <span className="input-group-text">
                                        <i className={'bi bi-arrow-left-right mx-1'}> </i>
                                    </span>
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`end`, date)
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        startDate={formik.values.start}
                        minDate={formik.values.start}
                        minTime={setHours(setMinutes(new Date(),
                            new Date(formik.values.end).setHours(0,0,0,0) === new Date(formik.values.start).setHours(0,0,0,0) ? new Date(formik.values.start).getMinutes() : 0
                            ),
                            new Date(formik.values.end).setHours(0,0,0,0) === new Date(formik.values.start).setHours(0,0,0,0) ? new Date(formik.values.start).getHours() : 0
                        )}
                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                        timeIntervals={15}
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                </div>
                <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
                <FormError touched={formik.touched} errors={formik.errors} field={'end'} />
            </div>
            <div className="col-12 mb-3">
                <SingleCompanySelected
                    onDelete={() => setDisplayCompanySelector(true)}
                    onOpen={() => setDisplayCompanySelector(true)}
                    initialValue={company?.id}
                />
            </div>
            {company && <div className={'d-flex'}><div className="col-md-6 mb-3">
                <BootstrapSelect
                    label={"Emplacement"}
                    value={data.localisation ? {label: data.localisation.title, value: data.localisation.id} : undefined}
                    //options={company.localisations?.map(l => ({label: l.title, value: l.id!}))}
                    fetchEntity="localisation"
                    onChange={(choice) => formik.setFieldValue('localisation', choice?.value)}
                    required={false}
                />
            </div>
                <div className="col-md-6 mb-3">
                    <BootstrapSelect
                        label={"Activité"}
                        value={data.activity ? {label: data.activity.title, value: data.activity.id} : undefined}
                        //options={company.activities?.map(a => ({label: a.title, value: a.id!}))}
                        fetchEntity="activity"
                        onChange={(choice) => formik.setFieldValue('activity', choice?.value)}
                        required={false}
                    />
                </div>
            </div>}
            <div className="col-12 mb-3">
                <SalarySelected
                    onDelete={(salary: number) => {
                        let ids: number[] = formik.values.salaries;
                        let index = ids.findIndex(id => id === salary)
                        if (index !== -1){
                            ids.splice(index, 1);
                        }
                        formik.setFieldValue('salaries', ids)
                    }}
                    onOpen={() => setDisplaySalarySelector(true)}
                    initialValues={formik.values.salaries}
                />
            </div>
            <div className="col-12 mb-3">
                <textarea onChange={formik.handleChange} className={'form-control'} placeholder={'Description'} name="description" id="description">
                    {data.description}
                </textarea>
            </div>
            <button type={"submit"} className={'btn w-100 btn-outline-primary'}>
                <i className={'bi bi-plus-circle'}> </i> Ajouter
            </button>
        </form>
    </div>
}

export default EventOfferEdit;