import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/auth"
import * as api from "../../../adapters/auth";
import {useFormik} from "formik";
import * as Yup from "yup";

const PasswordConfirmation: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny)  => state.auth);
    const params: {token: string} = useParams();
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        dispatch(actions.confirm());
        api.tokenExist(params.token).then((data) => {
            setEmail(data.data.email);
            dispatch(actions.confirmTokenExist());
        }).catch((error) => {
            dispatch(actions.confirmFailure(error.response.data.message))
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            password: '',
            repeatPassword: ''
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required(),
            repeatPassword: Yup.string().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.confirmPassword(values, params.token).then(data => {
                dispatch(actions.confirmSuccess(data.data));
            }).catch(error => {
                dispatch(actions.confirmFailure(error.response.data.message))
                formikHelpers.setSubmitting(false);
            })
        }
    })

    if (state.isLoading){
        return <>
            <div className="container">
                <div className="col-12 p-1 p-md-3">
                    <div className="card shadow-sm p-2 p-md-5">
                        <div className="alert alert-info">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> Récupération des informations
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

        return <>
            <div className="container">
                <div className="col-12 p-1 p-md-3">
                    <div className="card shadow-sm p-1 p-md-3">
                        {state.error && <div className="alert alert-danger mb-2">
                          <i className={'bi bi-exclamation-triangle'}> </i>  {state.error}
                        </div>}
                        {email && <>
                            <h4>
                                Choisissez votre mot de passe pour confirmer votre compte
                            </h4>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                    />
                                    <label htmlFor="password">Mot de passe</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        id="repeatPassword"
                                        name="repeatPassword"
                                        type="password"
                                        className="form-control"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                    />
                                    <label htmlFor="repeatPassword">Confirmez le mot de passe</label>
                                </div>

                                <button disabled={formik.isSubmitting} type="submit" className='btn btn-outline-primary w-100'>
                                    <i className={'bi bi-check'}> </i> Valider
                                </button>
                            </form>
                        </>}
                    </div>
                </div>
            </div>
        </>
}

export default PasswordConfirmation;