import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import {setHours, setMinutes} from "date-fns";
import FormError from "../../../../utils/formError";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {Company} from "../../../../models/companies";
import {SingleCompanySelected, SingleCompanySelector} from "../../../../utils/companySelector/single";
import {SalarySelected, SalarySelector} from "../../../../utils/salarySelector";
import * as api from "../../../../adapters/booking/offer";
import {closeSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../../actions/alert";
import {addBookingOfferSuccess} from "../../../../actions/booking/offer";

const BookingOfferAdd:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);
    const [displayCompanySelector, setDisplayCompanySelector] = useState<boolean>(false);
    const [displaySalarySelector, setDisplaySalarySelector] = useState<boolean>(false);
    const [company, setCompany] = useState<Company>(state.rightSidebar.data?.company);

    const formik = useFormik({
        initialValues: {
            description: "",
            beginAt: setMinutes(new Date(), 0),
            endAt: setMinutes(new Date(), 30),
            company: state.rightSidebar.data?.company?.id,
            localisation: state.rightSidebar.data?.localisation?.id,
            activity: state.rightSidebar.data?.activity?.id,
            salaries: [],
        },
        validationSchema: Yup.object().shape({
            description: Yup.string(),
            beginAt: Yup.date().required(),
            endAt: Yup.date().required(),
            company: Yup.number().required(),
            localisation: Yup.number(),
            activity: Yup.number(),
            salaries: Yup.array().of(Yup.number()).min(1, "Veuillez séléctionner au moins un collaborateur"),
        }),
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar())
            api.create(values).then(data => {
                dispatch(addBookingOfferSuccess(data.data));
                dispatch(showAlertSuccess('Offre de créneau ajoutée'))
                dispatch(closeSidebar())
            });
        }
    })

    return <div className={'p-1 p-md-3'}>
        {displayCompanySelector && <SingleCompanySelector
            onSubmit={(company) => {
                setCompany(company)
                formik.setFieldValue('company', company.id)
                setDisplayCompanySelector(false)
            }}
            onClose={() => setDisplayCompanySelector(false)}
            initialValue={company?.id}
        />}
        {displaySalarySelector && <SalarySelector
            fetchParams={{
                beginAt: formik.values.beginAt,
                endAt: formik.values.endAt,
                companies: [formik.values.company],
                isAvailable: true,
            }}
            initialValues={formik.values.salaries}
            onClose={() => setDisplaySalarySelector(false)}
            onSubmit={(salaries: number[]) => {
                formik.setFieldValue('salaries', salaries)
                setDisplaySalarySelector(false);
            }}
        />}
        <form className={(displayCompanySelector || displaySalarySelector) ? 'd-none' : 'd-block'} onSubmit={formik.handleSubmit}>
            <div className="col-md-12 mb-3">
                <div className="input-group">
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                        customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.beginAt}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`beginAt`, date)
                            formik.setFieldTouched('beginAt', true)
                        }}
                        selectsStart
                        endDate={formik.values.endAt}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}                    />
                    <span className="input-group-text">
                                        <i className={'bi bi-arrow-left-right mx-1'}> </i>
                                    </span>
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'endAt')}
                        customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.endAt}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`endAt`, date)
                            formik.setFieldTouched('endAt', true)
                        }}
                        selectsEnd
                        startDate={formik.values.beginAt}
                        minDate={formik.values.beginAt}
                        minTime={setHours(setMinutes(new Date(),
                            new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getMinutes() : 0
                            ),
                            new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getHours() : 0
                        )}
                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                        timeIntervals={15}
                        dateFormat="E dd MMM uuuu HH:mm"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                </div>
                <FormError touched={formik.touched} errors={formik.errors} field={'beginAt'} />
                <FormError touched={formik.touched} errors={formik.errors} field={'endAt'} />
            </div>
            <div className="col-12 mb-3">
                <SingleCompanySelected
                    onDelete={() => setDisplayCompanySelector(true)}
                    onOpen={() => setDisplayCompanySelector(true)}
                    initialValue={company?.id}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'company'} />
            </div>
            {company && <div className={'d-flex'}><div className="col-md-6 mb-3">
                <BootstrapSelect
                    label={"Emplacement"}
                    options={company.localisations?.map(l => ({label: l.title, value: l.id!}))}
                    onChange={(choice) => formik.setFieldValue('localisation', choice?.value)}
                    required={false}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'localisation'} />
            </div>
                <div className="col-md-6 mb-3">
                    <BootstrapSelect
                        label={"Activité"}
                        options={company.activities?.map(a => ({label: a.title, value: a.id!}))}
                        onChange={(choice) => formik.setFieldValue('activity', choice?.value)}
                        required={false}
                    />
                    <FormError touched={formik.touched} errors={formik.errors} field={'activity'} />
                </div></div>}
            <div className="col-12 mb-3">
                <SalarySelected
                    onDelete={(salary: number) => {
                        let ids = formik.values.salaries;
                        let index = ids.findIndex(id => id === salary)
                        if (index !== -1){
                            ids.splice(index, 1);
                        }
                        formik.setFieldValue('salaries', ids)
                    }}
                    onOpen={() => setDisplaySalarySelector(true)}
                    initialValues={formik.values.salaries}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'salaries'} />
            </div>
            <div className="col-12 mb-3">
                <textarea onChange={formik.handleChange} className={'form-control'} placeholder={'Description'} name="description" id="description">

                </textarea>
                <FormError touched={formik.touched} errors={formik.errors} field={'description'} />
            </div>
            <button type={"submit"} className={'btn w-100 btn-outline-primary'}>
                <i className={'bi bi-plus-circle'}> </i> Ajouter
            </button>
            <hr/>
            {/*<div className="col-12 text-center">*/}
            {/*    <h3>*/}
            {/*        Besoin d'un renfort ?*/}
            {/*    </h3>*/}
            {/*    <p>*/}
            {/*        Trouvez de l'interim chez notre partenaire*/}
            {/*    </p>*/}
            {/*    <button className={'btn bg-primary w-100'}>*/}
            {/*        <img src={process.env.PUBLIC_URL + "/logo/247.svg"} style={{width: 200, height: 'auto'}} alt=""/>*/}
            {/*    </button>*/}
            {/*</div>*/}
        </form>
    </div>
}

export default BookingOfferAdd;