export const setPageTitle = (pageTitle: string, pageUnderTitle?: string) => {
    return {
        type: 'SET_PAGE_TITLE',
        pageTitle: pageTitle,
        pageUnderTitle: pageUnderTitle
    }
};

export const setHelpProductSheetIds = (productSheetIds: number[]) => {
    return {
        type: 'SET_HELP_PRODUCT_SHEET_IDS',
        helpProductSheetIds: productSheetIds
    }
};