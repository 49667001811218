import React, {useEffect, useState} from "react";
import format from "../../../../utils/locale";
import * as calendarTypes from "../../../../constants/calendar";
import Loader from "../../../../utils/loader";

interface Interface {
    loading: boolean,
    signed: any[],
    refresh: (salary: number) => void,
    origin: string
}

const TimeClockDashboardList:React.FC<Interface> = (props) => {

    const {loading, signed, refresh, origin} = props;

    const [onTime, setOnTime] = useState(false);
    const [late, setLate] = useState(origin === 'late');
    const [missing, setMissing] = useState(origin === 'missing');
    const [clockInId, setClockInId] = useState<string>();

    useEffect(() => {
        if (origin){
            switch (origin){
                case 'late':
                    setLate(true);
                    setMissing(false);
                    setOnTime(false);
                    break;
                case 'missing':
                    setMissing(true);
                    setLate(false);
                    setOnTime(false);
                    break;
            }
        }
    }, [origin])

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTimeClockDashboardList" aria-labelledby="offcanvasTimeClockDashboardListLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasTimeClockDashboardListLabel">Reporting badgeage</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className="input-group mb-3">
                <div className="form-check form-switch me-3">
                    <input key={origin} className="form-check-input" type="checkbox" role="switch" id="onTime" defaultChecked={origin === 'onTime'} onChange={(e) => setOnTime(e.target.checked)} />
                        <label className="form-check-label" htmlFor="onTime">
                            à l'heure
                        </label>
                </div>
                <div className="form-check form-switch me-3">
                    <input key={origin} className="form-check-input" type="checkbox" role="switch" id="late" defaultChecked={origin === 'late'} onChange={(e) => setLate(e.target.checked)} />
                        <label className="form-check-label" htmlFor="late">
                            En retard
                        </label>
                </div>
                <div className="form-check form-switch me-3">
                    <input key={origin} className="form-check-input" type="checkbox" role="switch" id="missing" defaultChecked={origin === 'missing'} onChange={(e) => setMissing(e.target.checked)} />
                        <label className="form-check-label" htmlFor="missing">
                            Absent
                        </label>
                </div>
            </div>
            <ul className={'list-group list-group-flush'}>
                {loading ? <Loader /> : signed.filter(s => applyFilters(s)).map(s => <li className={'list-group-item'}>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 d-flex flex-column">
                            <h4>
                               <i className={'bi bi-person'}></i> {s.salary.title}
                            </h4>
                            <span className={'d-flex align-items-center'}>
                              <span className="me-2" style={{height: 14, width: 14, borderRadius: '50%', backgroundColor: s.salary.information.job.color}}></span>  {s.salary.information.job.title}
                            </span>
                        </div>
                        <div className="flex-grow-0 d-flex flex-column">
                            {s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING && <>
                                <div className="d-flex align-items-center">
                                    <h4 className={"flex-grow-1 m-0"}>
                                        <i className={'bi bi-exclamation-circle text-danger'}></i> Absent
                                    </h4>
                                </div>
                                <span className={"form-text"}>
                                    <i className={'bi bi-clock'}></i> Prévu à {format(new Date(s.start), 'HH:mm')}
                                </span>
                            </>}
                            {s.isLate && <>
                                <h4>
                                    <i className={'bi bi-exclamation-triangle text-warning'}></i> Retard
                                </h4>
                                <span className={"form-text"}>
                                    <i className={'bi bi-watch'}></i> Retard de {s.lateOfCaption}
                                </span>
                            </>}
                            {!(s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING) && !s.isLate && <>
                                <h4>
                                    <i className={'bi bi-check text-success'}></i> Arrivé à {format(new Date(s.start), 'HH:mm')}
                                </h4>
                            </>}
                        </div>
                    </div>
                </li>)}
            </ul>
        </div>
    </div>

    function applyFilters(s: any){

        if ([calendarTypes.EVENT_TYPE_BOOKING, calendarTypes.EVENT_TYPE_BOOKING_EXCEPTION, calendarTypes.EVENT_TYPE_EVENT, calendarTypes.EVENT_TYPE_EVENT_EXCEPTION].includes(s.eventType)) return false;
        if (s.isLate) return late;
        if (s.eventType === calendarTypes.EVENT_TYPE_TIME_CLOCK_MISSING) return missing;

        return onTime;
    }
}

export default TimeClockDashboardList;