export const EVENT_ADD = "EVENT_ADD";
export const EVENT_EDIT = "EVENT_EDIT";

export const ABSENCE_REQUEST = "ABSENCE_REQUEST";
export const ABSENCE_STATUS = "ABSENCE_STATUS";

export const TASK_ADD = "TASK_ADD";
export const TASK_EDIT = "TASK_EDIT";
export const TASK_STATUS = "TASK_STATUS";

export const ADD_EVENT_OFFER = "ADD_EVENT_OFFER";
export const EDIT_EVENT_OFFER = "EDIT_EVENT_OFFER";
export const APPLY_EVENT_OFFER = "APPLY_EVENT_OFFER";
export const VALIDATE_EVENT_OFFER = "VALIDATE_EVENT_OFFER";

export const EXPENSE_REQUEST = "EXPENSE_REQUEST";
export const EXPENSE_STATUS = "EXPENSE_STATUS";

export const TIME_CLOCK_LATE = "TIME_CLOCK_LATE";
export const TIME_CLOCK_MISSING = "TIME_CLOCK_MISSING";

// notifier les modification de planning au fil de l'eau

// TODO : Notifier la messagerie
// TODO : Notifier les demandes d'échange de créneaux

export const getAllNotifications = () => {
    return [
        {
            title: "Créneaux",
            underscoreCase: 'events',
            module: "none",
            notifications: [
                {
                    id: EVENT_ADD,
                    title: "Notifier les ajouts",
                    population: false,
                    push: true,
                    email: true,
                    populationDescription: "Cible le collaborateur assigné au créneau"
                },
                {
                    id: EVENT_EDIT,
                    title: "Notifier les modifications/suppressions",
                    population: false,
                    push: true,
                    email: true,
                    populationDescription: "Cible le collaborateur assigné au créneau"
                },
            ]
        },
        {
            title: "Absences",
            underscoreCase: 'absence',
            module: "none",
            notifications: [
                {
                    id: ABSENCE_REQUEST,
                    title: "Notifier les demandes d'absence",
                    push: true,
                    email: true,
                    population: true
                },
                {
                    id: ABSENCE_STATUS,
                    title: "Notifier les changements de statut",
                    push: true,
                    email: true,
                    population: false,
                    populationDescription: "Cible le collaborateur assigné à l'absence"
                },
            ]
        },
        {
            title: "Tâches",
            module: "tasks",
            underscoreCase: 'task',
            notifications: [
              {
                  id: TASK_ADD,
                  title: "Notifier les ajouts",
                  push: true,
                    email: true,
                  population: false,
                  populationDescription: "Cible les collaborateurs assignés à la tâche"
              },
              {
                  id: TASK_EDIT,
                  title: "Notifier les modifications",
                  push: true,
                    email: true,
                  population: false,
                  populationDescription: "Cible les collaborateurs assignés à la tâche"
              },
              {
                  id: TASK_STATUS,
                  title: "Notifier changements de statut",
                  push: true,
                    email: true,
                  population: true,
              }
          ]
        },
        {
            title: "Offre de créneaux",
            underscoreCase: 'offer',
            notifications: [
                  {
                      id: ADD_EVENT_OFFER,
                      title: "Notifier les ajouts",
                      push: true,
                    email: true,
                      population: false,
                      populationDescription: "Cible les collaborateurs assignés à l'offre de créneau"
                  },
                  {
                      id: EDIT_EVENT_OFFER,
                      title: "Notifier les modifications",
                      push: true,
                    email: true,
                      population: false,
                      populationDescription: "Cible les collaborateurs assignés à l'offre de créneau"
                  },
                  {
                      id: APPLY_EVENT_OFFER,
                      title: "Notifier les applications",
                      push: true,
                    email: true,
                      population: true,
                  },
                  {
                      id: VALIDATE_EVENT_OFFER,
                      title: "Notifier la validation d'une application",
                      push: true,
                    email: true,
                      population: false,
                      populationDescription: "Cible les collaborateurs ayant postulés à l'offre de créneau"
                  },
            ]
        },
        {
            title: "Notes de frais",
            module: "expense",
            underscoreCase: 'expense',
            notifications: [
                {
                    id: EXPENSE_REQUEST,
                    title: "Notifier les demandes de note de frais",
                    push: true,
                    email: true,
                    population: true
                },
                {
                    id: EXPENSE_STATUS,
                    title: "Notifier les changements de statut",
                    push: true,
                    email: true,
                    population: false,
                    populationDescription: "Cible le collaborateur assigné aux notes de frais"
                },
            ]
        },
        {
            title: "Badgeuse",
            module: "timeClock",
            underscoreCase: 'timeClock',
            notifications: [
                {
                    id: TIME_CLOCK_MISSING,
                    title: "Notifier les absences",
                    push: true,
                    email: true,
                    sms: true,
                    population: true,
                },
                {
                    id: TIME_CLOCK_LATE,
                    title: "Notifier les retards",
                    push: true,
                    email: true,
                    sms: true,
                    population: true,
                },
            ]
        },
    ]
}