import React, {useEffect, useState} from "react";
import EventAddForm from "./form";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import {Template} from "../../../../models/template";

interface Interface {
    template: Template
    close: () => any,
    handleSubmitSuccess: (ev: any[]) => any,
    resource?: {
        title: string
        id: number,
    },
    localisation?: Localisation,
    activity?: Activity,
    start?: Date
    end?: Date
}

const EventAdd:React.FC<Interface> = (props) => {

    const {handleSubmitSuccess, close, template} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventAddLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <EventAddForm
                key={key}
                resource={props.resource}
                start={props.start}
                end={props.end}
                localisation={props.localisation}
                activity={props.activity}
                template={template}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default EventAdd;