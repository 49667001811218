import * as types from "../../constants/report"
import * as models from "../../models/report"
import replace from "../../utils/arrayReplace"

const report = (state: models.initialStateInterface = models.initialState, action: {type: string, report: models.Report, reports: models.Reports, error: string}) => {

    switch (action.type) {
        case types.FETCH_REPORTS:
            return { payload: [], single: null, isLoading: true, error: null };
        case types.FETCH_REPORTS_SUCCESS:
            return { payload: action.reports, single: state.single, isLoading: false, error: null };
        case types.FETCH_REPORTS_FAILURE:
            return { payload: action.reports, single: state.single, isLoading: false, error: action.error };
        case types.ADD_REPORT:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_REPORT_SUCCESS:
            return { payload: [action.report, ...state.payload], single: action.report, isLoading: false, error: null };
        case types.EDIT_REPORT:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_REPORT_SUCCESS:
            return { payload: replace(state.payload, action.report), single: action.report, isLoading: false, error: null };
        case types.SHOW_REPORT:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_REPORT_SUCCESS:
            return { payload: state.payload, single: action.report, isLoading: false, error: null };
        case types.SHOW_REPORT_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default report;