import React, {useEffect, useState} from "react";
import EventEditForm from "../../../event/edit/form";
import {EventClickArg} from "@fullcalendar/react";

const CalendarEventEdit:React.FC<{arg?: EventClickArg, handleSubmitSuccess: (ev: any) => any, close: () => any}> = (props) => {    
    const {arg, handleSubmitSuccess, close} = props;
    
    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventEditLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <EventEditForm
                key={key}
                values={{
                    salary: arg.event.extendedProps.salary,
                    start: arg.event.start!,
                    end: arg.event.end!,
                    accountingType: arg.event.extendedProps.accountingType,
                    company: arg.event.extendedProps.company,
                    activity: arg.event.extendedProps.activity,
                    localisation: arg.event.extendedProps.localisation,
                    eventType: arg.event.extendedProps.eventType,
                    eventId: arg.event.extendedProps.eventId,
                    exceptionId: arg.event.extendedProps.exceptionId,
                    comment: arg.event.extendedProps.comment,
                }}
                handleSubmitSuccess={handleSubmit}
            />}
        </div>
    </>
}

export default CalendarEventEdit;