import React, { useState } from "react"
import { useSelector, RootStateOrAny } from "react-redux"

import { SUBSCRIPTIONS_MODELS_NORMAL_RATE, SUBSCRIPTIONS_MODELS_REDUCED_RATE } from "../../../../../constants/subscriptions"

import * as api from "../../../../../adapters/billing"

const BillingSubscriptionUpdate = ({company, _model, setSubscription}: {company: string, _model?: number, setSubscription: React.Dispatch<any>}) => {
    const state = useSelector((state: RootStateOrAny) => state);
    const companyGroup = state.auth.user.currentSalary.companyGroup;

    const AVAILABLE_MODELS = companyGroup?.billingRate === 2 ? SUBSCRIPTIONS_MODELS_REDUCED_RATE : SUBSCRIPTIONS_MODELS_NORMAL_RATE;
    
    const [error, setError] = useState("");
    const [model, setModel] = useState<number|undefined>(_model || undefined)
    const [pending, setPending] = useState<number|undefined>(undefined)
    const [loading, setLoading] = useState(false);

    function handleChange(){
        if (pending && pending !== model){
            setLoading(true)
            api.model(company, pending)
                .then(r => {
                    setSubscription(r.data)
                    setModel(r.data.modelId)
                })
                .then(() => setLoading(false))
                .catch(e => setError(e.response.data.message))
        }
    }

    return <div className="modal fade" id="billingSubscriptionUpdateModal" tabIndex={-1} aria-labelledby="billingSubscriptionUpdateModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="billingSubscriptionUpdateModalLabel">Mettre à niveau mon abonnement</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {error && <div className={"col-12 mb-3 alert alert-danger"}>
                        {error}
                    </div>}
                    <div className="row row-cols-3">
                        {AVAILABLE_MODELS.map(m =>
                            <div
                                className="col"
                                onClick={model !== m.model_id ? () => setPending(Number(m.model_id)) : undefined}
                            >
                                <div className={"py-5 px-2 text-center rounded " + (model === Number(m.model_id) ? " bg-white shadow-sm border border-1 border-success " : ( pending === m.model_id ? " bg-primary shadow-sm border border-1 border-primary text-white " : " bg-light "))}>
                                    <h3>
                                        {m.min} à {m.max} salariés
                                    </h3>
                                    <p>
                                        {m.amount.toFixed(2)}€ HT par mois
                                    </p>
                                    {model === m.model_id &&
                                        <p>
                                            <i className={'bi bi-check2-all text-success'}></i> Actif
                                        </p>
                                    }
                                    {pending === m.model_id &&
                                        <p>
                                            <i className={'bi bi-check-circle text-success'}></i> Séléctionné
                                        </p>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="col-12 mb-3 alert alert-info">
                        <ul>
                            <li>
                                En cliquant sur souscrire, le montant de votre abonnement sera mis à jour à la prochaine échéance.
                            </li>
                        </ul>
                    </div>
                    <button className="ms-auto btn btn-light" onClick={() => handleChange()} disabled={!pending || pending === model}>
                        Souscrire
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default BillingSubscriptionUpdate;