export const ADD_CHANNEL = 'ADD_CHANNEL';
export const ADD_CHANNEL_SUCCESS = 'ADD_CHANNEL_SUCCESS';
export const ADD_CHANNEL_FAILURE = 'ADD_CHANNEL_FAILURE';

export const ADD_CHANNEL_INFORMATION = 'ADD_CHANNEL_INFORMATION';
export const ADD_CHANNEL_INFORMATION_SUCCESS = 'ADD_CHANNEL_INFORMATION_SUCCESS';
export const ADD_CHANNEL_INFORMATION_FAILURE = 'ADD_CHANNEL_INFORMATION_FAILURE';

export const EDIT_CHANNEL = 'EDIT_CHANNEL';
export const EDIT_CHANNEL_SUCCESS = 'EDIT_CHANNEL_SUCCESS';
export const EDIT_CHANNEL_FAILURE = 'EDIT_CHANNEL_FAILURE';

export const FETCH_CHANNELS = 'FETCH_CHANNELS';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';

export const SHOW_CHANNEL = 'SHOW_CHANNEL';
export const SHOW_CHANNEL_SUCCESS = 'SHOW_CHANNEL_SUCCESS';
export const SHOW_CHANNEL_FAILURE = 'SHOW_CHANNEL_FAILURE';
