import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import UserInformationShow from "./information";
import UserCompanyGroupList from "./companyGroup/list";
import UserBilling from "./billing";

import { User } from "../../../../models/auth";

import * as api from "../../../../adapters/user";
import * as actions from "../../../../actions/admin/user";
import * as authActions from "../../../../actions/auth";
import { setPageTitle } from "../../../../actions/header";
import { axiosError } from "../../../../actions/axios";

import BgSingle from "../../../../utils/background/single";

const UserShow: React.FC = () => {
    const params:{id: string} = useParams();
    const dispatch = useDispatch();

    const user: User = useSelector((state: RootStateOrAny) => state.user.payload.find((a: User) => String(a.id) === params.id))
    const state = useSelector((state: RootStateOrAny) => state.user)    
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    
    const [tab, setTab] = useState("information");

    useEffect(() => {
        let controller = new AbortController();

        dispatch(actions.showUser());

        if (user && params.id) {            
            dispatch(actions.showUserSuccess(user));
            dispatch(setPageTitle('Admin / Utilisateurs', user.information?.firstname + ' ' + user.information?.lastname))
        } else if (!user && params.id) {
            api.show(Number(params.id), controller.signal).then(data => {                
                dispatch(actions.showUserSuccess(data.data))
                dispatch(setPageTitle('Admin / Utilisateurs', data.data.information?.firstname + ' ' + data.data.information?.lastname))
            }).catch(error => dispatch(axiosError(error)))
        }
    }, [params.id])

    if (!state.single) {
        return (
            <BgSingle showLoader={!!params.id} hasId={!!params.id} />
        )
    }

    const Nav = () => {
        return (
            <div className={'w-100 position-sticky top-0 d-flex'} style={{zIndex: 1000}}>
                <ul className="nav bg-light nav-tabs flex-grow-1">
                    <li className="nav-item">
                        <button onClick={() => setTab('information')}
                                className={"nav-link text-primary" + (tab === 'information' ? ' active' : '')}
                                aria-current="page">
                            <i className={'bi bi-info-circle'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Informations</h4>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button onClick={() => setTab('account')}
                                className={"nav-link text-primary" + (tab === 'account' ? ' active' : '')}
                                aria-current="page">
                            <i className={'bi bi-file-earmark-spreadsheet'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Synthèse</h4>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button onClick={() => setTab('billing')} className={"nav-link text-primary" + (tab === 'billing' ? ' active' : '')} aria-current="page">
                            <i className={'bi bi-credit-card'}> </i>
                            <h4 className={'d-none d-md-inline-block'}> Facturation</h4>
                        </button>
                    </li>
                </ul>

                <Link to={'/admin/user'} className={'btn btn-sm btn-light flex-grow-0 d-block d-md-none m-1'}>
                    <i className={'bi bi-chevron-left'}> </i> Retour
                </Link>
            </div>
        )
    }

    return (
        <div className="container-fluid h-100 w-100 overflow-auto py-1 py-md-3">
            <div className="card shadow-sm p-1 p-md-3 mb-3">
                <button className={'btn btn-outline-success mb-3 ' + (auth.user.id === state.single.id ? ' active' : '')} onClick={() => auth.user.id !== state.single.id ? dispatch(authActions.impersonateUser(state.single)) : dispatch(authActions.exitImpersonateUser())}>
                    {auth.user.id === state.single.id ? <><i className={'bi bi-check'}> </i> Compte actif</> : <><i className={'bi bi-arrow-right'}> </i> Accéder au compte</>}
                </button>

                <Nav />
            </div>

            {tab === "information" && <UserInformationShow />}
            {tab === "account" && <UserCompanyGroupList />}
            {tab === "billing" && user?.id &&
                <UserBilling
                    userId={user.id}
                />
            }
        </div>
    )
}

export default UserShow;

