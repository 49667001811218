import React, {useEffect, useState} from "react";
import format from "../../../../utils/locale";
import * as Yup from "yup";
import {useFormik} from "formik";
import * as api from "../../../../adapters/event";
import {showAlertSuccess} from "../../../../actions/alert";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import validationClass from "../../../../utils/validationClass";
import {Salary} from "../../../../models/salary";
import FormError from "../../../../utils/formError";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../../utils/customInput";
import {AccountingTypes} from "../../../../constants/booking";
import {Company} from "../../../../models/companies";
import {Activity} from "../../../../models/activity";
import {Localisation} from "../../../../models/localisation";
import {useDispatch} from "react-redux";
import * as accessRightsTypes from "../../../../constants/accessRight";
import SalarySelector from "../../../salary/selector";
import {setSeconds} from "date-fns";
import moment from "../../../../utils/moment.utils";

interface Interface {
    start?: Date,
    end?: Date,
    initialSalary?: Salary,
    initialCompany?: Company,
    activity?: Activity,
    localisation?: Localisation,
    handleSubmitSuccess: (props: any[]) => any
}

const EventAddForm:React.FC<Interface> = (props) => {

    const {initialSalary, initialCompany, start, end, localisation, activity, handleSubmitSuccess} = props;

    const dispatch = useDispatch();
    const [salary, setSalary] = useState<Salary|undefined>(initialSalary)
    //const [company, setCompany] = useState<Company|undefined>(initialCompany)

    const initialValues: any = {
        salary: salary?.id,
        start: start || new Date(),
        end: end || new Date(),
        accountingType: 1,
        company: initialCompany?.id,
        activity: activity?.id,
        localisation: localisation?.id,
        daysOfWeek: [String(Number(format(start || new Date(), 'i')) - 1)],
        weekNumber: moment(start).isoWeek(),
        year: moment(start).weekYear(),
        comment: ""
    }

    const validationSchema = Yup.object().shape({
        salary: Yup.number().required('Veuillez séléctionner un collaborateur dans la liste ci-dessus'),
        start: Yup.date().required(),
        end: Yup.date().required().test("is-greater", "La fin doit être après le début", function(value: any) {
            const { start } = this.parent;

            if (!value) return false;

            if (start.getTime() > value.getTime()) {

                let clone = setSeconds(new Date(value), 0)
                clone.setDate(value.getDate() + 1)

                return clone.getTime() > start.getTime();
            }

            return value && start < value
        }),
        company: Yup.number().required(),
        activity: Yup.number().nullable(),
        localisation: Yup.number().nullable(),
        accountingType: Yup.number().required(),
        daysOfWeek: Yup.array().of(Yup.number()).min(1),
        weekNumber: Yup.number().required(),
        year: Yup.number().required(),
        comment: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            api.create(values).then(resp => {
                Promise.all([
                    handleSubmitSuccess(resp.data)
                ]).then(() => {
                    dispatch(showAlertSuccess('Créneau ajouté'));
                    formikHelpers.setSubmitting(false)
                })
            });
        }
    })

    return <form className={'p-1 p-md-3'} onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <SalarySelector
                required={true}
                disabled={formik.isSubmitting}
                fetchOptions={true}
                value={salary}
                onChange={(s: Salary) => {
                    formik.setFieldValue('salary', s.id)
                    setSalary(s)
                }}
                accessRight={accessRightsTypes.EDIT_BOOKING}
            />
            <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                        customInput={<DateCustomInput label={'Heure de début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`start`, date)
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        startDate={formik.values.start}
                        maxDate={formik.values.end}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                </div>
                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                </div>
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Heure de fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`end`, date)
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                required={true}
                label={"Type d'heure"}
                options={AccountingTypes}
                value={AccountingTypes[0]}
                onChange={(c) => formik.setFieldValue('accountingType', c?.value)}
            />
        </div>
        {<>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    required={true}
                    label={"Établissement"}
                    className={validationClass(formik.errors, formik.touched, 'company')}
                    //options={salary ? [...salary.companies.map(c => ({label: c.title, value: c.id!}))] : undefined}
                    fetchEntity="company"
                    fetchParams={{ salaries: [formik.values.salary] }}
                    value={initialCompany ? {label: initialCompany.title, value: initialCompany.id!} : undefined}
                    onChange={(c) => formik.setFieldValue('company', c?.value)}
                    //onChangeObject={(c: Company) => setCompany(c)}
                    initialObjectsValues={salary?.companies ? [...salary.companies] : undefined}
                    enableReinitialize={true}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'company'} />
            </div>
            {<div className="row">
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'localisation')}
                            disabled={formik.isSubmitting}
                            //options={company ? company.localisations?.map(l => ({label: l.title, value: l.id!})) : []}
                            fetchEntity="localisation"
                            fetchParams={{ companies: [formik.values.company] }}
                            onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                            label={"Emplacement"}
                            enableReinitialize={true}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'activity')}
                            disabled={formik.isSubmitting}
                            //options={company ? company.activities?.map(a => ({label: a.title, value: a.id!})) : []}
                            fetchEntity="activity"
                            fetchParams={{ companies: [formik.values.company] }}
                            onChange={(c) => formik.setFieldValue('activity', c?.value)}
                            label={"Activité"}
                            enableReinitialize={true}
                        />
                    </div>
                </div>}
        </>}
        <div className="col-12 mb-3 text-center">
            <div className="col-12 mb-3">
                <label>
                    Jours de la semaine
                </label>
                <div className="col-12">
                    <p className="form-text">
                        Semaine {formik.values.weekNumber}, Année {formik.values.year}
                    </p>
                </div>
            </div>
            {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                return (
                    <>
                        <input key={day}
                               disabled={formik.isSubmitting}
                               type="checkbox"
                               onChange={formik.handleChange}
                               name={'daysOfWeek'}
                               checked={formik.values.daysOfWeek.includes(`${index}`)}
                               className="btn-check"
                               id={`btn-check-${index}`}
                               autoComplete="off"
                               value={`${index}`} />
                        <label key={index} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-check-${index}`}>
                            {day}
                        </label>
                    </>
                )
            })}
            <FormError touched={formik.touched} errors={formik.errors} field={'daysOfWeek'} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <textarea onChange={formik.handleChange} className="form-control" name={'comment'} placeholder="Commentaire" id="comment"
                          style={{height: 100}}></textarea>
                <label htmlFor="comment">Commentaire</label>
            </div>
        </div>
        <button type={'submit'} className={'btn btn-light w-100'} disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> : <><i className={'bi bi-check text-success'}></i> Valider</>}
        </button>
    </form>
}

export default EventAddForm;