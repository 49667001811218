import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import * as actions from "../../../actions/localisation";
import * as companyActions from "../../../actions/companies";
import * as api from "../../../adapters/localisation";
import * as companyApi from "../../../adapters/company";
import Loader from "../../../utils/loader";
import * as Yup from "yup";
import {Formik} from "formik";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertDanger, showAlertSuccess} from "../../../actions/alert";
import FormLocalisation from "../utils/form";
import {axiosError} from "../../../actions/axios";

const LocalisationSchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
    description: Yup.string().required('Champ requis').max(255),
    color: Yup.string().nullable(),
    icon: Yup.string().nullable(),
    company: Yup.number().required('Champs requis'),
});

export const LocalisationAdd: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)

    return (
            <div className='w-100'>
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    company: state.companies.single?.id || null,
                }}
                validationSchema={LocalisationSchema}
                onSubmit={values => {
                    // same shape as initial values
                    dispatch(submitSidebar());
                    api.put(values).then(data => {
                        dispatch(actions.addLocalisationSuccess(data.data))
                        dispatch(closeSidebar());
                        dispatch(showAlertSuccess(data.data.title + ' à bien été ajouter !'))
                    }).catch(error => dispatch(axiosError(error)))
                }}
            >
                {({ errors, touched, values, setValues }) => (
                    <FormLocalisation
                        errors={errors}
                        touched={touched}
                        values={values}
                        setValues={setValues}
                    />
                )}
            </Formik>
            </div>
    )


}

export default LocalisationAdd;