import React from "react";
import {EventClickArg} from "@fullcalendar/react";
import {ViewApi} from "@fullcalendar/common";
import {ResourceApi} from "@fullcalendar/resource-common";

export interface ResourceLabelContentArg {
    resource: ResourceApi;
    date?: Date;
    view: ViewApi;
}

export interface initialStateInterface {
    events: any[],
    resources: any[],
    title: string,
    eventClickArg?: EventClickArg,
    displayEventPop: boolean,
    refresh: boolean,
    loading: boolean,
}
export const initialState: initialStateInterface = {
    events: [],
    resources: [],
    displayEventPop: false,
    title: "",
    refresh: false,
    loading: false,
};