import { FunctionComponent } from "react"
import { useDispatch } from "react-redux"

import { Formik, Form } from "formik"
import * as Yup from "yup"
import DatePicker from "react-datepicker"
import { Offcanvas } from "bootstrap";

import { Salary, SalaryRepartition } from "../../../../../models/salary"

import { addSalaryRepartition, updateSalaryRepartition } from "../../../../../adapters/salary/repartition"
import { showSalarySuccess } from "../../../../../actions/salary"

import DateCustomInput from "../../../../../utils/customInput"
import moment from "moment"
import { showAlertSuccess, showAlertDanger } from "../../../../../actions/alert"

interface IProps {
  salary: Salary;
  repartitionData?: SalaryRepartition;
  onSuccess: () => void;
}

const validations = Yup.object().shape({
  contractWorkingTimeRepartition: Yup.boolean()
    .required(),
  contractWorkingTimeRepartitionTrigger: Yup.number()
    .min(2, 'La répartition doit être définie sur 2 semaines au minimum')
    .required('Veuillez préciser la répartition'),
  contractWorkingTimeRepartitionCurrentWeek: Yup.number()
    .required('Veuillez préciser la semaine en cours'),
  startDate: Yup.date()
    .required('Veuillez indiquer la date de début'),
});

const AddOrUpdateSalaryRepartitionForm: FunctionComponent<IProps> = ({ salary, repartitionData, onSuccess }) => {
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{
        contractWorkingTimeRepartition: true,
        contractWorkingTimeRepartitionTrigger: repartitionData?.contractWorkingTimeRepartitionTrigger || 2,
        contractWorkingTimeRepartitionCurrentWeek: repartitionData?.contractWorkingTimeRepartitionCurrentWeek || 1,
        contractWorkingTimeRepartitionValues: repartitionData?.contractWorkingTimeRepartitionValues || [],
        startDate: repartitionData?.startDate ? moment(repartitionData.startDate).toDate() : moment().startOf('d').toDate(),
        endDate: repartitionData?.endDate ? moment(repartitionData.endDate).toDate() : undefined,
      }}
      validationSchema={validations}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true)

          for (const value of values.contractWorkingTimeRepartitionValues) {
            if (isNaN(value) || value < 0) {
              return dispatch(showAlertDanger('Format des heures semaines incorrecte'))
            } else if (values.endDate && moment(values.endDate).isBefore(moment(values.startDate))) {
              return dispatch(showAlertDanger('L’heure de fin doit être après le début'))
            }
          }

          if (repartitionData) {
            const { status, data } = await updateSalaryRepartition(salary.id, repartitionData.id, {
              ...values,
              contractWorkingTimeRepartitionCurrentWeekEditedAt: moment().toDate()
            });
  
            if (status === 203) {
              dispatch(showAlertDanger(data.error));
              return
            }
  
            dispatch(showSalarySuccess(data));
            dispatch(showAlertSuccess('Répartition mise à jour avec succès'))
            onSuccess()
          } else {
            const { status, data } = await addSalaryRepartition(salary.id, {
              ...values,
              contractWorkingTimeRepartitionCurrentWeekEditedAt: moment().toDate()
            });
  
            if (status === 203) {
              dispatch(showAlertDanger(data.error));
              return
            }
  
            dispatch(showSalarySuccess(data));
            dispatch(showAlertSuccess('Nouvelle répartition ajoutée'))
            resetForm()
          }

          const drawerElement = document.getElementById('addOrUpdateRepartitionDrawer');
          if (drawerElement) {
            const drawerInstance = Offcanvas.getInstance(drawerElement);
            drawerInstance.hide();  
          }
        } catch (error) {          
          dispatch(showAlertDanger('Impossible d’ajouter la répartition'))
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <Form className="w-100">
          <div className="w-100 mb-2 p-2 bg-primary">
            <p className="m-0 text-white">
              {repartitionData ? "Modifier la répartition" : "Ajouter une nouvelle répartition"}
            </p>
          </div>

          <div className="py-2 col-12 d-flex align-items-center">
            <div className="col-10">
              <label>
                Répartir sur X semaines (2 minimum)
              </label>
              <div className="form-text">
                (ex: 1 = Semaine A, 2 = Semaine A + Semaine B, etc)
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <input
                  name="contractWorkingTimeRepartitionTrigger"
                  className="form-control"
                  type="number"
                  min={2}
                  value={values.contractWorkingTimeRepartitionTrigger || ''}
                  disabled={isSubmitting}
                  onChange={(e) => {
                    if (Number(e.target.value) <= 20) {
                      if (Number(e.target.value) < values.contractWorkingTimeRepartitionValues.length) {
                        setFieldValue('contractWorkingTimeRepartitionValues', [ ...values.contractWorkingTimeRepartitionValues.slice(0, Number(e.target.value) - 1)])
                      }

                      setFieldValue('contractWorkingTimeRepartitionTrigger', Number(e.target.value))
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="py-2 col-12 d-flex align-items-center">
            <div className="col-10">
              <label>
                Semaine en cours
              </label>
            </div>

            <div className="col-2">
              <div className="input-group">
                <input
                  name="contractWorkingTimeRepartitionCurrentWeek"
                  className="form-control"
                  type="number"
                  min={1}
                  value={values.contractWorkingTimeRepartitionCurrentWeek}
                  disabled={isSubmitting}
                  onChange={handleChange('contractWorkingTimeRepartitionCurrentWeek')}
                />
              </div>
            </div>
          </div>

          <ul className={'list-group list-group-flush'}>
            {Array.from(Array(values.contractWorkingTimeRepartitionTrigger).keys()).map((value, index) =>
              <li className={'list-group-item'}>
                <div className="w-100 d-flex align-items-center">
                    <div className="col-8">
                      Heures semaine {index + 1}
                    </div>

                    <div className="col-4">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className={'bi bi-clock'}></i>
                        </span>

                        <input
                          className="form-control"
                          type="text"
                          value={typeof values.contractWorkingTimeRepartitionValues[index] === 'number' && values.contractWorkingTimeRepartitionValues[index] >= 0 ? values.contractWorkingTimeRepartitionValues[index].toString() : ''}
                          disabled={isSubmitting}
                          onChange={(e) => {
                            let newTab: (number|null)[] = [ ...values.contractWorkingTimeRepartitionValues ];
                            if (/^\d+$/.test(e.target.value)) {
                              newTab[index] = Number(e.target.value);
                            } else if (e.target.value === '') {
                              newTab[index] = -1;
                            }
                            setFieldValue('contractWorkingTimeRepartitionValues', newTab);
                          }}
                        />
                      </div>
                    </div>
                </div>
              </li>
            )}
          </ul>
          
          <div className="py-2">
            <p className="m-0">
              Période
            </p>
            <div className="py-2 col-12 d-flex align-items-center">
              <div className="col-6 pe-1">
                <DatePicker
                  className={'form-control'}
                  title={'Début'}
                  ariaLabelledBy={'bi bi-calendar-check text-primary'}
                  customInput={<DateCustomInput label={'Date de début'} required={true}/>}
                  selected={values.startDate}
                  selectsStart
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  startDate={values.startDate}
                  endDate={values.endDate}
                  dateFormat="E dd MMM uuuu"
                  popperPlacement={"top-end"}
                  showPopperArrow={false}
                  disabled={isSubmitting}
                  onChange={(date) => setFieldValue('startDate', date)}
                />
              </div>
              <div className="col-6 ps-1">
                <DatePicker
                  className={'form-control'}
                  title={'Fin'}
                  ariaLabelledBy={'bi bi-calendar-check text-primary'}
                  customInput={<DateCustomInput label={'Date de fin'}/>}
                  selected={values.endDate}
                  selectsEnd
                  startDate={values.startDate}
                  endDate={values.endDate}
                  minDate={values.startDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  isClearable={true}
                  dateFormat="E dd MMM uuuu"
                  popperPlacement={"top-end"}
                  showPopperArrow={false}
                  disabled={isSubmitting}
                  onChange={(date) => setFieldValue('endDate', date ? moment(date?.toString()).endOf('d').toDate() : undefined)}
                />
              </div>
            </div>
          </div>

          <div className="pt-4 col-12 d-flex justify-content-end">
            <button
              className="btn btn-success"
              type="submit"
              disabled={isSubmitting || values.contractWorkingTimeRepartitionTrigger < 2 || !values.contractWorkingTimeRepartitionCurrentWeek || values.contractWorkingTimeRepartitionValues.length !== values.contractWorkingTimeRepartitionTrigger || !values.startDate}
              onClick={() => handleSubmit()}
            >
              {repartitionData ? "Enregistrer" : "Ajouter la répartition"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddOrUpdateSalaryRepartitionForm