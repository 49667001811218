import React from "react";
import { useDispatch } from "react-redux";

import { TotalCounters } from "../../../../models/counter";

import { showCounterSuccess } from "../../../../actions/counter";

const CounterTableMultiple: React.FC<{payload: TotalCounters}> = (props) => {
    const { payload } = props;
    const dispatch = useDispatch();

    return (
        <table className={'table table-striped'}>
            <thead>
                <tr>
                    <th>
                        Collaborateur
                    </th>
                    <th>
                        Compteur
                    </th>
                    <th>
                        Retiré
                    </th>
                    <th>
                        Payé
                    </th>
                    <th>
                        Valeur
                    </th>
                    <th>
                        #
                    </th>
                </tr>
            </thead>

            <tbody>
                {payload.map((item) =>
                    <tr>
                        <td>
                            {item.salary.title}
                        </td>
                        <td>
                            {item.totalCounter || <i className={'bi bi-x'}></i>}
                        </td>
                        <td>
                            {item.totalWithdrawn || <i className={'bi bi-x'}></i>}
                        </td>
                        <td>
                            {item.totalPaid || <i className={'bi bi-x'}></i>}
                        </td>
                        <td>
                            {item.total} {!!item.total && <i className={item.total > 0 ? "bi bi-arrow-up text-success" : "bi bi-arrow-down text-danger"}></i>}
                        </td>
                        <td>
                            <button className={'btn btn-sm btn-light'} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => dispatch(showCounterSuccess(item))}>
                                <i className={'bi bi-eye'}></i> Détail
                            </button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default CounterTableMultiple;