import React, {useEffect, useState} from "react";
import EventAddForm from "../../../event/add/form";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Activity} from "../../../../models/activity";
import {Localisation} from "../../../../models/localisation";
import {setMinutes} from "date-fns";

const EventAdd:React.FC<{close: () => any, handleSubmitSuccess: (ev: any[]) => any, dt: Date, salary?: Salary, company?: Company, localisation?: Localisation, activity?: Activity}> = (props) => {

    const {handleSubmitSuccess, salary, company, activity, localisation, close, dt} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [salary, company, activity, localisation])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventAddLabel">Ajouter un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <EventAddForm
                key={key}
                start={setMinutes(new Date(dt), 0)}
                end={setMinutes(new Date(dt), 30)}
                initialSalary={salary}
                initialCompany={company}
                localisation={localisation}
                activity={activity}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default EventAdd;