export const ADD_TASK_GROUP = 'ADD_TASK_GROUP';
export const ADD_TASK_GROUP_SUCCESS = 'ADD_TASK_GROUP_SUCCESS';
export const ADD_TASK_GROUP_FAILURE = 'ADD_TASK_GROUP_FAILURE';

export const EDIT_TASK_GROUP = 'EDIT_TASK_GROUP';
export const EDIT_TASK_GROUP_SUCCESS = 'EDIT_TASK_GROUP_SUCCESS';
export const EDIT_TASK_GROUP_FAILURE = 'EDIT_TASK_GROUP_FAILURE';

export const FETCH_TASK_GROUPS = 'FETCH_TASK_GROUPS';
export const FETCH_TASK_GROUPS_SUCCESS = 'FETCH_TASK_GROUPS_SUCCESS';
export const FETCH_TASK_GROUPS_FAILURE = 'FETCH_TASK_GROUPS_FAILURE';

export const SHOW_TASK_GROUP = 'SHOW_TASK_GROUP';
export const SHOW_TASK_GROUP_SUCCESS = 'SHOW_TASK_GROUP_SUCCESS';
export const SHOW_TASK_GROUP_FAILURE = 'SHOW_TASK_GROUP_FAILURE';

export const CANCEL_TASK_GROUP_SUCCESS = 'CANCEL_TASK_GROUP_SUCCESS';



