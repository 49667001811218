import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {ErrorMessage, Field, FieldProps, Form, Formik, FormikValues, useFormik} from "formik";
import * as actions from "../../../../actions/template/booking";
import * as api from "../../../../adapters/template/booking";
import {showAlertSuccess} from "../../../../actions/alert";
import {closeSidebar, openSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import {setHours, setMinutes} from "date-fns";
import format from "../../../../utils/locale";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import DateCustomInput from "../../../../utils/customInput";
import validationClass from "../../../../utils/validationClass";
import FormError from "../../../../utils/formError";
import {Resource} from "../../../../models/template";
import {FORM_NEW_TEMPLATE_RESOURCE} from "../../../../constants/rightSidebar";
import {refresh} from "../../../../actions/template/calendar";
import TemplateBookingRecurrenceEdit from "./recurrence";

const baseSchema:any = {
    resource: Yup.number().required(),
    beginAt: Yup.date().required(),
    endAt: Yup.date().required('Fin de créneau requis')
        .test("is-greater", "La fin doit être après le début", function(value: any) {
            const { beginAt } = this.parent;
            return value && beginAt <= value
        }),
    localisation: Yup.number().nullable(),
    activity: Yup.number().nullable(),
};

const TemplateBookingEdit:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const [displayConfirmation, setDisplayConfirmation] = useState(false)
    const [confirmation, setConfirmation] = useState(1)
    const [editRecurrence, setEditRecurrence] = useState(false)
    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({...baseSchema}));
    let date = new Date()
    date.setHours(date.getHours(), 0,0,0)
    let end = new Date(date)
    end.setHours(end.getHours() + 1, 0,0,0)
    const [initialValues, setInitialValues] = useState<any>({
        resource: state.rightSidebar.data.resource.id,
        beginAt: state.rightSidebar.data.beginAt,
        endAt: state.rightSidebar.data.endAt,
        localisation: state.rightSidebar.data.localisation?.id,
        activity: state.rightSidebar.data.activity?.id,
    })

    const renderDayContents = (day: number, date: Date) => {
        return <span>{format(date, 'E')}</span>;
    };

    const submitSuccess = () => {
        dispatch(refresh())
        dispatch(showAlertSuccess(' Créneau modifié !'))
        dispatch(closeSidebar());
    }

    const formik = useFormik( {
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar());
            switch (confirmation) {
                case 1:
                    if (state.rightSidebar.data.isException){
                        api.editException(values, state.rightSidebar.data.exceptionInstanceId).then(() => submitSuccess())
                    }else{
                        api.createException(values, state.rightSidebar.data.bookingInstanceId, state.rightSidebar.data.instanceToken).then(() => submitSuccess())
                    }
                    break;
                case 2:
                    api.edit(values, state.rightSidebar.data.bookingInstanceId).then(() => submitSuccess())
                    break;
            }
        }
    })

    if (displayConfirmation){
        return <div className={'p-3'}>
            <div className={'mb-5'}>
                <button onClick={() => setDisplayConfirmation(false)} className={'btn btn-outline-primary'}>
                    Retour
                </button>
            </div>
            <h5>
                <i className="bi bi-arrow-repeat text-primary"> </i>
                Créneau récurrent
            </h5>
            <p>
                {state.rightSidebar.data.recurrence.description}
            </p>
            <h3 className={'mt-5 mb-3'}>
                Vous souhaitez modifier le créneau
            </h3>
            <ul className={'list-unstyled align-middle'}>
                <li className={'mb-3'}>
                    <button className={'btn btn-outline-primary'} onClick={() => {
                        setConfirmation(1)
                        formik.submitForm();
                    }}>
                        Une seule fois
                    </button>
                </li>
                <li className={'mb-3'}>
                    <button className={'btn btn-outline-primary'} onClick={() => {
                        setConfirmation(2)
                        formik.submitForm();
                    }}>
                        Toutes les occurences
                    </button>
                </li>
            </ul>
        </div>
    }

    if (editRecurrence){
        return <TemplateBookingRecurrenceEdit setEditRecurrence={setEditRecurrence} />
    }

    return (
        <>
            <div className='p-3'>
                      <form onSubmit={formik.handleSubmit} className="template-add-booking-form">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col">
                                        <i className={'bi bi-arrow-repeat'}> </i> Récurrence
                                        <div className="clearfix"> </div>
                                        <span>
                                        {state.rightSidebar.data.recurrence.description}
                                    </span>
                                    </div>
                                    <div className="col-auto">
                                        <button className={'btn btn-light'} onClick={() => setEditRecurrence(true)}>
                                            <i className={'bi bi-pencil-square'}> </i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="col-12 mb-3">
                                <div className="form-floating">
                                    <select
                                        name={'resource'}
                                        className={'form-select' + validationClass(formik.errors, formik.touched, 'resource')}
                                        onChange={formik.handleChange}
                                    >
                                        {state.rightSidebar.data.resources.map((resource: Resource) => <option value={resource.id}>{resource.title}</option>)}
                                    </select>
                                    <label htmlFor="">
                                        Resource <span className="required">*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="input-group">
                                    <DatePicker
                                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                                        title={'Début'}
                                        selected={formik.values.beginAt}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(`beginAt`, date)
                                        }}
                                        renderDayContents={renderDayContents}
                                        renderCustomHeader={() => <></>}
                                        openToDate={new Date("2021/10/04")}
                                        minDate={new Date("2021/10/04")}
                                        maxDate={new Date("2021/10/10")}
                                        selectsStart
                                        startDate={formik.values.beginAt}
                                        endDate={formik.values.endAt}
                                        showTimeInput={true}
                                        timeInputLabel={'Heure'}
                                        customInput={<DateCustomInput />}
                                        dateFormat="E HH:mm"
                                    />
                                    <span className="input-group-text">
                                        <i className={'bi bi-arrow-left-right'}> </i>
                                    </span>
                                    <DatePicker
                                        className={'form-control' + validationClass(formik.errors, formik.touched, 'endAt')}
                                        title={'Fin'}
                                        customInput={<DateCustomInput />}
                                        renderDayContents={renderDayContents}
                                        openToDate={new Date("2021/10/04")}
                                        minDate={new Date("2021/10/04")}
                                        maxDate={new Date("2021/10/10")}
                                        renderCustomHeader={() => <></>}
                                        selected={formik.values.endAt}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(`endAt`, date)
                                        }}
                                        selectsEnd
                                        startDate={formik.values.beginAt}
                                        endDate={formik.values.endAt}
                                        minTime={setHours(setMinutes(new Date(),
                                            new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getMinutes() : 0
                                            ),
                                            new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getHours() : 0
                                        )}
                                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                        showTimeInput={true}
                                        timeInputLabel={'Heure'}
                                        timeCaption="Heure"
                                        dateFormat="E HH:mm"
                                    />
                                </div>
                                <FormError errors={formik.errors} touched={formik.touched} field={'beginAt'} />
                                <FormError errors={formik.errors} touched={formik.touched} field={'endAt'} />
                            </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating">
                                            <select
                                                onChange={formik.handleChange}
                                                className={'form-select' + validationClass(formik.errors, formik.touched, 'localisation')}
                                                required={false}
                                                name={`localisation`}
                                            >
                                                {[<option selected={!state.rightSidebar.data.localisation} value={''}>Aucun </option>, state.rightSidebar.data.company.localisations?.map((localisation: Localisation) => <option selected={state.rightSidebar.data.localisation?.id === localisation.id} value={localisation.id}>{localisation.title}</option>)]}
                                            </select>
                                            <label htmlFor={`localisation`}>
                                                Localisation
                                            </label>
                                            <FormError errors={formik.errors} touched={formik.touched} field={'localisation'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating">
                                            <select
                                                onChange={formik.handleChange}
                                                className={'form-select' + validationClass(formik.errors, formik.touched, 'activity')}
                                                required={false}
                                                name={`activity`}
                                            >
                                                {[<option value={''} selected={!state.rightSidebar.data.activity}>Aucun </option>,state.rightSidebar.data.company.activities?.map((activity: Activity) => <option selected={state.rightSidebar.data.activity?.id === activity.id} value={activity.id}>{activity.title}</option>)]}
                                            </select>
                                            <label htmlFor={`activity`}>
                                                Activité
                                            </label>
                                            <FormError errors={formik.errors} touched={formik.touched} field={'activity'} />
                                        </div>
                                    </div>
                                </div>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (state.rightSidebar.data.recurrence.type === 1){
                                        formik.submitForm()
                                    }else{
                                        setDisplayConfirmation(true)
                                    }
                                }}
                                className='btn btn-outline-primary w-100' type="submit">
                                <i className={'bi bi-check'}> </i>
                                Enregistrer
                            </button>
                        </form>
            </div>
        </>
    )
}

export default TemplateBookingEdit;