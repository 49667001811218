import * as React from "react";

import ProfileShow from "../../../../components/profile/show/index";

const ProfileShowPage : React.FC = () => {
    return (
        <ProfileShow />
    )
}

export default ProfileShowPage;