interface InitalStateModel {
    pageTitle: string;
    pageUnderTitle: string;
    helpProductSheetIds: number[];
}

interface ActionModel {
    type: string;
    item: string;
    pageTitle: string;
    pageUnderTitle: string;
    helpProductSheetIds: number[];
}

const initialState = {
    pageTitle: '',
    pageUnderTitle: '',
    helpProductSheetIds: []
}

const header = (state: InitalStateModel = initialState, action: ActionModel) => {
    switch (action.type) {
        case 'SET_PAGE_TITLE':
            return {
                ...state,
                pageTitle: action.pageTitle,
                pageUnderTitle: action.pageUnderTitle
            };

        case 'SET_HELP_PRODUCT_SHEET_IDS':
            return {
                ...state,
                helpProductSheetIds: action.helpProductSheetIds
            };
            
        default:
            return state;
    }
}

export default header;