import * as types from "../../constants/synthesis"
import * as models from "../../models/synthesis"
import arrayReplaceMany from "../../utils/arrayReplaceMany";

const synthesis = (state: models.initialStateInterface = models.initialState, action: {type: string, synthesis: models.Synthesis, syntheses: models.Syntheses, weeks: models.Weeks, start: Date, end: Date, error: string}) => {

    switch (action.type) {
        case types.FETCH_SYNTHESES:
            return { updating: [], payload: [], single: null, weeks: state.weeks, start: action.start, end: action.end, isLoading: true, error: null };
        case types.FETCH_SYNTHESES_SUCCESS:
            return { updating: [], payload: action.syntheses, single: state.single, weeks: action.weeks, start: state.start, end: state.end, isLoading: false, error: null };
        case types.UPDATE_SYNTHESES:
            return { updating: action.syntheses, payload: state.payload, single: state.single, weeks: state.weeks, start: state.start, end: state.end, isLoading: false, error: null };
        case types.UPDATE_SYNTHESES_SUCCESS:
            return { updating: [], payload: arrayReplaceMany(state.payload, action.syntheses), single: state.single, weeks: state.weeks, start: state.start, end: state.end, isLoading: false, error: null };
        case types.SHOW_SYNTHESIS:
            return { updating: [], payload: state.payload, single: null, weeks: state.weeks, start: state.start, end: state.end, isLoading: true, error: null };
        case types.SHOW_SYNTHESIS_SUCCESS:
            return { updating: [], payload: state.payload, single: action.synthesis, weeks: state.weeks, start: state.start, end: state.end, isLoading: false, error: null };
        default:
            return state;
    }
}

export default synthesis;