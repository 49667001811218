import { format } from 'date-fns'
import fr from 'date-fns/locale/fr'

const locales = {fr}

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export default function (date: Date, formatStr = "E dd MMMM uuuu") {
    return format(date, formatStr, {
        locale: locales["fr"] // or global.__localeId__
    })
}
