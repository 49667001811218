import React from "react";
import {Link, useParams} from "react-router-dom";
import BgSingle from "../../../utils/background/single";
import BillingSubscriptionShow from "./subscription/show";
import BillingInvoicesShow from "./invoices";
import BillingSalaries from "./salaries";

const BillingShow = () => {

    const {id, tab = "subscription"}: {id: string, tab: "subscription"|'invoices'|'salaries'} = useParams()

    if (!id) {
        return <BgSingle showLoader={false} hasId={false} />
    }

    return <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!id ? " d-none d-md-block" : "")}>
        <ul className="nav nav-underline">
            <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to={`/billing/${id}/subscription`}>
                    Abonnement
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={`/billing/${id}/invoices`}>
                    Factures
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={`/billing/${id}/salaries`}>
                    Salariés
                </Link>
            </li>
        </ul>
        {tab === "subscription" && <BillingSubscriptionShow company={id} />}
        {tab === "invoices" && <BillingInvoicesShow company={id} />}
        {tab === "salaries" && <BillingSalaries company={id} />}
    </div>
}

export default BillingShow;