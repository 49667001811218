export default function(items: any[], _items: any[]){

    for (let i in _items){
        let index = items.findIndex((a: any) => a.id === _items[i].id);

        if (index !== -1) {
            items[index] = _items[i];
        }
    }

    return items;
}

