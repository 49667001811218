import {get, post} from "../xhr";

export function list(){
    return get(`report/list`);
}

export function show(id: number){
    return get(`report/${id}/show`);
}

export function fetch(id: number, requestData: {salaries: number[], companies: number[], query?: string, offset?: number}){
    return post(`report/${id}/fetch`, requestData);
}

export function open(id: number){
    return get(`report/${id}/open`);
}

export function close(id: number){
    return get(`report/${id}/close`);
}

export function create(requestData: any){
    return post(`report/create`, requestData);
}

export function edit(requestData: any, id: number){
    return post(`report/${id}/edit`, requestData);
}

export function countersAdd(id: number, requestData?: {salaries: number[]}){
    return post(`report/${id}/counter/add`, requestData || {});
}

export function isValid(requestData : {month: number, year: number, start: string, end : string}, report?: number){

    if (report){
        return post(`report/validate/${report}`, requestData);
    }

    return post(`report/validate`, requestData);
}

export function countersClear(id: number, requestData?: {salaries: number[]}){
    return post(`report/${id}/counter/clear`, requestData || {});
}

export function deactivate(id: number){
    return get(`report/${id}/deactivate`);
}

export function paid(requestData: {value: number}, report: number, salary: number){
    return post(`report/${report}/paid/${salary}`, requestData);
}

export function _export(requestData: any, id: number){
    return post(`report/${id}/export`, requestData, { responseType: "arraybuffer" } );
}