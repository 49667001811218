import {post, get} from "../xhr";

export function match(requestData: {isSameDuration: boolean, start: string, end: string, startDate: string, endDate: string, jobs: number[], salaries: number[] }, salary: number){
    return post(`event_exchange/match/${salary}`, requestData || {});
}
export function list(requestData: {
    isSameDuration: boolean,
    start: string,
    end: string,
    jobs: number[],
    event: {
        start: string,
        end: string,
        company: number,
        salary: number
    }}){
    return post(`event_exchange/list`, requestData);
}

export function create(requestData: {
    event: number,
    exDate: string,
    target: number,
    targetExDate: string,
}){
    return post(`event_exchange/create`, requestData);
}
export function cancel(id: number){
    return get(`event_exchange/cancel/${id}`);
}