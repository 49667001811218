import React from 'react';
import './App.scss';
import Navigation from "./components/navigation";
import RightSidebar from "./components/rightSidebar";
import AlertSivan from "./components/alert";
import StaticBackdropModal from "./utils/staticBackdropModal";

function App() {
    return (
            <div className="App">
                <Navigation />
                <RightSidebar />
                <StaticBackdropModal />
                <AlertSivan />
            </div>
    );
}

export default App;
