import React from "react";
import SignedEditForm from "./form";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";

const SignedEdit:React.FC<{handleSubmitSuccess: (ev: any) => any; close: () => any,
    salary: Salary
    start: Date|null,
    end: Date|null,
    company: Company,
    timeClockId: number,
    description: string
}> = (props) => {

    const {handleSubmitSuccess, close, salary, end, description, company, timeClockId, start} = props;

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarSignedEditLabel">Modifier un badgeage</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <SignedEditForm
                values={{
                    salary: salary,
                    start: start,
                    end: end,
                    company: company,
                    timeClockId: timeClockId,
                    description: description
                }}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default SignedEdit;