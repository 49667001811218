import * as React from "react";
import {login} from "../../../adapters/auth";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/auth"
import {Link} from "react-router-dom";
import FormError from "../../../utils/formError";
import {useState} from "react";

declare global {
    interface Window { ReactNativeWebView: any; ReactNativeWebview: any; }
}

const LoginForm : React.FC = () => {

    const dispatch = useDispatch();
    const [error, setError] = useState(sessionStorage.getItem('session_expired'));

    const SignupSchema = Yup.object().shape({
        password: Yup.string()
            .required('Mot de passe requis'),
        username: Yup.string().email('Email non valide').required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: SignupSchema,
        onSubmit: (values, formikHelpers) => {
            login(values).then((data) => {
                if (window.ReactNativeWebView){
                    window.ReactNativeWebView.postMessage(JSON.stringify({token: data.data.token, action: "login"}))
                }                
                dispatch(actions.loginSuccess(data.data))
            }).catch((error) => {
                if (error.response){
                    setError(error.response.data.message)
                }

                formikHelpers.setSubmitting(false);
            })
        },
    });

    return (<div className="position-relative card shadow-sm p-1 p-md-3 my-3 my-md-5 col col-md-6 mx-auto">
                <>
                    <img className={'auth_logo mx-auto mb-3'} src={process.env.PUBLIC_URL + "/logo/LOGO_SIVAN_BLEU.svg"} alt=""/>
                    {error && <div className="alert alert-danger">
                        <i className={'bi bi-exclamation-triangle'}> </i> {error}
                    </div>}
                    <form className="loginForm mb-3" onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <input
                                id="username"
                                name="username"
                                type="email"
                                placeholder={"email"}
                                className="form-control"
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="username" className="form-label text-start w-100">
                                Email <span className="required">*</span>
                            </label>
                            <FormError errors={formik.errors} touched={formik.touched} field={'username'} />
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                placeholder={"password"}
                                className="form-control"
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="password" className="form-label text-start w-100">
                                Mot de passe <span className="required">*</span>
                            </label>
                            <FormError errors={formik.errors} touched={formik.touched} field={'password'} />
                        </div>
                        <button disabled={formik.isSubmitting} type="submit" className='btn btn-outline-primary w-100'>{formik.isSubmitting ? <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : "Connexion"}</button>
                    </form>
                    <Link to={"/password/request" + (formik.values.username ? `/${formik.values.username}`: "") } className={'btn btn-link'}>
                       Mot de passe oublié
                    </Link>
            </>
        </div>);
}

export default LoginForm;