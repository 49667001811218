import * as React from "react";

import BillingList from "../../components/billing/list";
import BillingShow from "../../components/billing/show";

const BillingPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <BillingList />
            <BillingShow />
        </div>
    );
}

export default BillingPage;