import React, {useEffect, useState} from "react";
import {NeedEvent, NeedEvents} from "../../../models/calendar";
import {RootStateOrAny, useSelector} from "react-redux";
import format from "../../../utils/locale";
import {Choice} from "../../../utils/form/model";


const CalendarNeedEvents:React.FC = () => {

    const needEvents:NeedEvents = useSelector((state: RootStateOrAny) => state.calendar.needEvents)
    const [grouped, setGrouped] = useState<any[][]>([]);
    const [filters, setFilters] = useState<number[]>([1]);
    const [selectedItem, setSelectedItem] = useState<number>();
    const [currentItem, setCurrentItem] = useState<any>();
    const [allItems, setAllItems] = useState<Choice[]>([]);

    useEffect(() => {
        if(needEvents.length){
            let items = [...needEvents.map(needEvent => needEvent.item)];

            items.filter((val, index, self) => self.indexOf(val) === index);
            setCurrentItem(items[0])
            setSelectedItem(items[0].id!)

            let ret:any[] = []
            for (let i in items){
                ret[items[i].id!] = [...groupByDay(needEvents.filter(needEvent => needEvent.item.id === items[i].id))]
            }
            setGrouped([...ret]);
            setAllItems(groupItems([...needEvents]));
        }
    }, [])

    const groupByDay = function(xs: NeedEvents)
        :any[]
    {
        return Object.entries(xs.reduce(function(rv: any, x) {
            (rv[x["day"]] = rv[x["day"]] || []).push(x);
            return rv;
        }, {}));
    };

    const groupItems = function(xs: NeedEvents)
    {
        let ret: { label: string, value: number }[] = [];
        for (let i in xs){
            if (!ret.find(item => item.label === xs[i].item.title && item.value === xs[i].item.id!)){
                ret.push({label: xs[i].item.title, value: xs[i].item.id!})
            }
        }
        return ret;
    };

    const getIcon = (type: 1|2|3) => {
        switch (type) {
            case 1:
                return <i className={'text-danger bi bi-exclamation-circle'}> </i>
            case 2:
                return <i className={'text-success bi bi-check2-circle'}> </i>
            case 3:
                return <i className={'text-primary bi bi-plus-circle'}> </i>
        }
    }

    const handleChange = (checked: boolean, value: 1|2|3) => {
        if (checked && !filters.includes(value)){
            setFilters([...filters, value])
        }else if(filters.includes(value)){
            setFilters([...filters.filter(filterVal => filterVal !== value)])
        }
    }

    if (!allItems.length){
        return <div className={'col p-3'}>
            <h4>
                <i className={"bi bi-search"}> </i> Aucun manque
            </h4>
        </div>
    }

    return <>
        <div className={'col-12 d-flex flex-column flex-grow-1'}>
            <div className="w-100 p-3 position-sticky bg-white top-0" style={{zIndex: 100}}>
                <div className="form-check form-check-inline form-switch">
                    <input
                        defaultChecked={filters.includes(1)}
                        onChange={(e) => handleChange(e.target.checked, 1)}
                        className="form-check-input" type="checkbox" id="missing" />
                        <label className="form-check-label" htmlFor="missing">
                            {getIcon(1)}
                        </label>
                </div>
                <div className="form-check form-check-inline form-switch">
                    <input
                        defaultChecked={filters.includes(2)}
                        onChange={(e) => handleChange(e.target.checked, 2)}
                        className="form-check-input" type="checkbox" id="equal" />
                        <label className="form-check-label" htmlFor="equal">
                            {getIcon(2)}
                        </label>
                </div>
                <div className="form-check form-check-inline form-switch">
                    <input
                        defaultChecked={filters.includes(3)}
                        onChange={(e) => handleChange(e.target.checked, 3)}
                        className="form-check-input" type="checkbox" id="over" />
                        <label className="form-check-label" htmlFor="over">
                            {getIcon(3)}
                        </label>
                </div>
            </div>
            <div className="d-flex flex-column">
                <div className="flex-grow-0">
                    <ul className={'nav nav-tabs'}>
                        {allItems.map(choice => <li
                            onClick={() => setSelectedItem(choice.value)}
                            className={'nav-item nav-link ' + (selectedItem === choice.value ? ' active ' : '')}>
                            {choice.label}
                            <span className={'bg-danger text-white ms-1 p-1 rounded-pill'}>
                                {needEvents.filter(e => e.item.id === choice.value && filters.includes(e.type)).length}
                            </span>
                        </li>)}
                    </ul>
                </div>
                <div className="flex-grow-1">
                    {grouped[Number(selectedItem)].map(([key, values], index) => <>
                        {values.filter((value: NeedEvent) => filters.includes(value.type) && (!selectedItem || (selectedItem && value.item.id === selectedItem))).length > 0 && <div key={index} className="d-flex">
                            <div className="col-auto d-flex flex-column px-3 position-relative">
                                <span
                                    className={'bg-primary shadow-sm p-2 rounded-pill text-white top-50 start-50 translate-middle position-relative'}>
                                    {format(new Date(key), 'E dd/MM')}
                                </span>
                            </div>
                            <div className="col">
                                <div className="m-3 p-3 border shadow-sm">
                                    <ul className={'list-group list-group-flush'}>
                                        {values.filter((value: NeedEvent) => filters.includes(value.type) && (!selectedItem || (selectedItem && value.item.id === selectedItem))).map((value: NeedEvent) =>
                                            <li className={'list-group-item'}>
                                                {getIcon(value.type)} <span
                                                className={'text-primary'}>{value.time}</span> | {value.value} / {value.need}
                                                <span className={'float-end'}>
                                        <div className={'p-1 text-center text-white'}
                                             style={{backgroundColor: value.item.color}}>
                                            <i className={value.item.icon}> </i> {value.item.title}
                                        </div>
                                    </span>
                                            </li>)}
                                    </ul>
                                </div>

                            </div>
                        </div>}
                    </>)}
                </div>
            </div>
        </div>
    </>
}

export default CalendarNeedEvents;