import React, {useEffect, useRef} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {ResourceLabelContentArg} from "../../models/calendar";
import {openSidebar} from "../../actions/rightSidebar";
import {
    FORM_EVENT_ADD,
    FORM_NEW_ABSENCE,
    FORM_NEW_BOOKING,
    FORM_NEW_TASK,
    LIST_BOOKING_GROUP,
    LIST_DEFAULT_BOOKING, LIST_SALARY_TEMPLATE
} from "../../constants/rightSidebar";
import {hideResourceActions} from "../../actions/calendar";
import getVersion from "../../security/getVersion";

const ResourceActions:React.FC = () => {

    const dispatch = useDispatch();
    const e:ResourceLabelContentArg = useSelector((state:RootStateOrAny) => state.calendar.resourceLabelContentArg)
    const displayResourceActions:boolean = useSelector((state:RootStateOrAny) => state.calendar.displayResourceActions)
    const resourceActionButton:React.MouseEvent<HTMLButtonElement, MouseEvent>  = useSelector((state:RootStateOrAny) => state.calendar.resourceActionsButton)
    const resourceActionRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.addEventListener('click', handleBodyClick)

        if (displayResourceActions && resourceActionButton){
            let dropdown = resourceActionRef.current!
            let button = resourceActionButton.target as HTMLButtonElement
            let positions = button.getBoundingClientRect();

                if (dropdown.clientHeight + (positions.top - positions.height) > window.innerHeight){
                    let diff = dropdown.clientHeight + (positions.top - positions.height) - window.innerHeight;
                    dropdown.style.top = (positions.top - positions.height - diff - 15) + 'px';
                }else{
                    dropdown.style.top = (positions.top - positions.height) + 'px';
                }
            dropdown.style.left = (positions.left + positions.width + 5) + 'px'
        }

        return () => document.removeEventListener('click', handleBodyClick)
    }, [displayResourceActions, e])

    const handleBodyClick = (evt: MouseEvent) => {

        let dropdown = resourceActionRef.current
        if (displayResourceActions && dropdown){
            let dimensions = dropdown.getBoundingClientRect();

            let [xStart, xEnd] = [dimensions.left, dimensions.left + dimensions.width];
            let [yStart, yEnd] = [dimensions.top, dimensions.top + dimensions.height];

            let clickOutsideX = !(xStart <= evt.clientX && xEnd >= evt.clientX);
            let clickOutsideY = !(yStart <= evt.clientY && yEnd >= evt.clientY);

            if (clickOutsideX || clickOutsideY){
                dispatch(hideResourceActions())
            }
        }
    }

    const ActionsDropdown = () => {
            return <>
                <ul className={'list-group list-group-flush'}>
                    {getVersion() !== 1 && <button className={'list-group-item list-group-item-action'} onClick={() => {
                        dispatch(openSidebar(FORM_NEW_BOOKING, {
                            salary: e.resource.extendedProps.salary,
                            resourceId: e.resource.id,
                            parentId: e.resource._resource.parentId
                        }))
                        dispatch(hideResourceActions())
                    }}>
                        <i className={'bi bi-plus me-2'}> </i> Ajouter un créneau
                    </button>}
                    {getVersion() === 1 && <button className={'list-group-item list-group-item-action'} onClick={() => {
                        dispatch(openSidebar(FORM_EVENT_ADD, {
                            salary: e.resource.extendedProps.salary,
                            resourceId: e.resource.id,
                            parentId: e.resource._resource.parentId,
                            start: new Date(e.view.activeStart.getTime() + 9*3600000),
                            end: new Date(e.view.activeStart.getTime() + 10*3600000),
                        }))
                        dispatch(hideResourceActions())
                    }}>
                        <i className={'bi bi-plus me-2'}> </i> Ajouter un créneau
                    </button>}
                    <button className={'list-group-item list-group-item-action'} onClick={() => {
                        dispatch(openSidebar(LIST_DEFAULT_BOOKING, {
                            salary: e.resource.extendedProps.salary,
                            job: e.resource.extendedProps.salary.information.job,
                            company: e.resource.extendedProps.company,
                            start: e.view.activeStart,
                            end: e.view.activeEnd,
                        }))
                        dispatch(hideResourceActions())
                    }}>
                        <i className={'bi bi-front me-2'}> </i>   Ajouter un créneau type
                    </button>
                    {getVersion() !== 1 && <button className={'list-group-item list-group-item-action'} onClick={() => {
                        dispatch(openSidebar(LIST_BOOKING_GROUP, {salary: e.resource.extendedProps.salary}))
                        dispatch(hideResourceActions())
                    }}>
                        <i className={'bi bi-person-plus me-2'}> </i> Emploi du temps
                    </button>}
                    {getVersion() === 1 && <button className={'list-group-item list-group-item-action'} onClick={() => {
                        dispatch(openSidebar(LIST_SALARY_TEMPLATE, {salary: e.resource.extendedProps.salary}))
                        dispatch(hideResourceActions())
                    }}>
                        <i className={'bi bi-person-plus me-2'}> </i> Emploi du temps
                    </button>}
                    <button className={'list-group-item list-group-item-action'} onClick={() => {
                        dispatch(openSidebar(FORM_NEW_TASK, {salary: e.resource.extendedProps.salary}))
                        dispatch(hideResourceActions())
                    }}>
                        <i className={'bi bi-journal-plus me-2'}> </i>   Ajouter une tâche
                    </button>
                    <button className={'list-group-item list-group-item-action'} onClick={() => {
                        dispatch(openSidebar(FORM_NEW_ABSENCE, {
                            salary: e.resource.extendedProps.salary,
                            resourceId: e.resource.id,
                            parentId: e.resource._resource.parentId
                        }))
                        dispatch(hideResourceActions())
                    }}>
                        <i className={'bi bi-calendar-plus me-2'}> </i>   Ajouter une absence
                    </button>
                </ul>
            </>
    }


    return <div ref={resourceActionRef} id="salaryDropdown" className={"card shadow p-3 position-fixed fade " + (displayResourceActions ? 'show' : 'hide')} style={{width:320, transition: '0.2s', zIndex: (displayResourceActions ? 9999 : -1)}}>
                <ActionsDropdown />
           </div>

}

export default ResourceActions;