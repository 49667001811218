import {MULTIPART_FORM_DATA, post, get} from "../../xhr";

export function list(salaryId: number){
    return get(`salary/${salaryId}/file/list`)
}

export function add(requestData: FormData, salaryId: number){
    return post(`salary/${salaryId}/file/add`, requestData, {headers: [MULTIPART_FORM_DATA], responseType: "json"})
}

export function remove(salaryId: number, fileId: number){
    return get(`salary/${salaryId}/file/${fileId}/remove`)
}

export function download(salaryId: number, fileId: number){
    return get(`salary/${salaryId}/file/${fileId}/download`, {responseType: "arraybuffer"})
}