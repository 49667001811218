/**
 * Helper functions for the SlateEditor component.
 */
import { BaseEditor, Editor, Transforms, Range} from "slate";
import {ReactEditor} from "slate-react";
import {HistoryEditor} from "slate-history";

export const LIST_TYPES = ["numbered-list", "bulleted-list"];
export const ALIGNMENT_TYPES = ["start", "end", "center"];

/**
 * Checks whether a format block is active or not in the editor.tsx.
 * @param editor The current Slate editor.tsx
 * @param format The format block that is being checked
 */
export const isBlockActive = (editor: BaseEditor & ReactEditor & HistoryEditor, format: any) => {

  const _it = Editor.nodes(editor, {
    match: (n: any) => {
      return n.type === format
    }
  })[Symbol.iterator]().next();

  const match = _it.done ? undefined : _it.value;

  return !!match;
};

/**
 * Toggles a format block to be on / off.
 * @param editor The current Slate editor.tsx
 * @param format The format block that is being checked
 */
export const toggleBlock = (editor: BaseEditor & ReactEditor & HistoryEditor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  const isAlignment = ALIGNMENT_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n: any) => {
      return LIST_TYPES.includes(n.type) || (ALIGNMENT_TYPES.includes(n.type) && ALIGNMENT_TYPES.includes(format))
    },
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format
  });

  if (!isActive && (isList || isAlignment)) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

/**
 * Checks whether a format mark is active or not in the editor.tsx.
 * @param editor The current Slate editor.tsx
 * @param format The format mark that is being checked
 */
export const isMarkActive = (editor: BaseEditor & ReactEditor & HistoryEditor, format: string | number) => {
  const marks: any = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

/**
 * Toggles a format mark to be on / off.
 * @param editor The current Slate editor.tsx
 * @param format The format mark that is being checked
 */
export const toggleMark = (editor: BaseEditor & ReactEditor & HistoryEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

/**
 * Check whether the link button is active or not in the editor.tsx.
 * @param editor The current Slate editor.tsx
 */
export const isLinkActive = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
  const _it = Editor.nodes(editor, { match: (n: any) => n.type === "link" })[Symbol.iterator]().next();
  const match = _it.done ? undefined : _it.value;

  return !!match;
};

/**
 * Unwraps a link node from the editor.tsx.
 * @param editor The current Slate editor.tsx
 */
export const unwrapLink = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
  Transforms.unwrapNodes(editor, { match: (n: any) => n.type === "link" });
};

/**
 * Wraps a link node to the editor.tsx.
 * @param editor The current Slate editor.tsx
 * @param url The url to wrap into a node
 */
export const wrapLink = (editor: BaseEditor & ReactEditor & HistoryEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : []
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

/**
 * This will insert a link into the Slate editor.tsx.
 * @param editor The current Slate editor.tsx
 * @param url The url to insert
 */
export const insertLink = (editor: BaseEditor & ReactEditor & HistoryEditor, url: any) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
