import * as types from "../../constants/salary"
import * as templateTypes from "../../constants/salary/template"
import * as templateRecurrenceTypes from "../../constants/salary/template/recurrence"
import * as models from "../../models/salary"
import replace from "../../utils/arrayReplace"
import {SalaryTemplate, SalaryTemplateEvent, SalaryTemplates} from "../../models/salary/template";

const salary = (state: models.initialStateInterface = models.initialState, action: {
    type: string,
    salary: models.Salary,
    salaries: models.Salaries,
    error: string,
    template?: SalaryTemplate,
    templates?: SalaryTemplates,
    event?: SalaryTemplateEvent
}) => {

    switch (action.type) {
        case types.FETCH_SALARIES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_SALARIES_SUCCESS:
            return { payload: action.salaries, single: state.single, isLoading: false, error: null };
        case types.FETCH_SALARIES_FAILURE:
            return { payload: action.salaries, single: state.single, isLoading: false, error: action.error };

        case types.ADD_SALARY:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_SALARY_SUCCESS:
            return { payload: [...state.payload, action.salary], single: action.salary, isLoading: false, error: null };

        case types.EDIT_SALARY:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_SALARY_SUCCESS:
            return { payload:  replace(state.payload, action.salary), single: { ...state.single, ...action.salary}, isLoading: false, error: null };
        case types.SHOW_SALARY:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_SALARY_SUCCESS:
            return { payload: state.payload, single: action.salary, isLoading: false, error: null };
        case types.SHOW_SALARY_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        case templateTypes.ADD_SALARY_TEMPLATE_SUCCESS:
            state.single!.templates.push(action.template!)
            return { payload: state.payload, single: state.single, isLoading: false, error: null };
        case templateTypes.FETCH_SALARY_TEMPLATE:
            state.single!.templates = [];
            return { payload: state.payload, single: state.single, isLoading: false, error: null };
        case templateTypes.FETCH_SALARY_TEMPLATE_SUCCESS:
            state.single!.templates = action.templates!;
            return { payload: state.payload, single: state.single, isLoading: false, error: null };

            case templateTypes.ADD_SALARY_TEMPLATE_EVENT:
            return { payload: state.payload, single: addEvent(state.single!, action.template!, action.event!), isLoading: false, error: null };
        case templateTypes.EDIT_SALARY_TEMPLATE_EVENT:
            return { payload: state.payload, single: replaceEvent(state.single!, action.template!, action.event!), isLoading: false, error: null };
        case templateTypes.REMOVE_SALARY_TEMPLATE_EVENT:
            return { payload: state.payload, single: removeEvent(state.single!, action.template!, action.event!), isLoading: false, error: null };
        case templateTypes.REMOVE_SALARY_TEMPLATE_SUCCESS:
            return { payload: state.payload, single: removeTemplate(state.single!, action.template!), isLoading: false, error: null };


        default:
            return state;
    }
}

const addEvent = (salary: models.Salary, template: SalaryTemplate, event: SalaryTemplateEvent) => {

    let templateKey = salary.templates.findIndex(t => t.id === template.id);

    if (templateKey !== -1){
        salary.templates[templateKey].events.push(event);
    }

    return {...salary};
}
const replaceEvent = (salary: models.Salary, template: SalaryTemplate, event: SalaryTemplateEvent) => {

    let templateKey = salary.templates.findIndex(t => t.id === template.id);

    if (templateKey !== -1){
        let eventKey = salary.templates[templateKey].events.findIndex(e => e.id === event.id);
        if (eventKey !== -1){
            salary.templates[templateKey].events[eventKey] = event;
        }
    }

    return {...salary};
}

const removeEvent = (salary: models.Salary, template: SalaryTemplate, event: SalaryTemplateEvent) => {

    let templateKey = salary.templates.findIndex(t => t.id === template.id);

    if (templateKey !== -1){
        let eventKey = salary.templates[templateKey].events.findIndex(e => e.id === event.id);
        if (eventKey !== -1){
            salary.templates[templateKey].events.splice(eventKey, 1);
        }
    }

    return {...salary};
}

const removeTemplate = (salary: models.Salary, template: SalaryTemplate) => {

    let templateKey = salary.templates.findIndex(t => t.id === template.id);

    if (templateKey !== -1){
        salary.templates.splice(templateKey, 1);
    }

    return {...salary};
}

export default salary;