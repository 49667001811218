const HasPreviousRole = (role: "ROLE_USER"|"ROLE_ADMIN"|"ROLE_SUPER_ADMIN") => {
    let initialUser = localStorage.getItem('initialUser')

    if (initialUser){
        let data = JSON.parse(initialUser)
        return data.roles.includes(role);
    }

    return false;
}

export default HasPreviousRole;