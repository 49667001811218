import React, {useEffect, useState} from "react";
import {CompanyGroup} from "../../../../../../models/companyGroup";
import {Company} from "../../../../../../models/companies";
import format from "../../../../../../utils/locale";
import * as salaryApi from "../../../../../../adapters/salary";
import * as salaryActions from "../../../../../../actions/salary";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import SmallLoader from "../../../../../../utils/loader/small";
import {Salary} from "../../../../../../models/salary";
import {SWITCH_USER} from "../../../../../../adapters/xhr";
import * as authActions from "../../../../../../actions/auth";
import * as userApi from "../../../../../../adapters/user";

interface UserCompanyGroupShowInterface {
    single: CompanyGroup,
    setSingle: (companyGroup?: CompanyGroup) => void;
}

const UserCompanyGroupShow:React.FC<UserCompanyGroupShowInterface> = (props) => {

    const {single, setSingle} = props;
    const state = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(salaryActions.fetchSalaries())
        salaryApi.list({}, {headers: [JSON.stringify({'x-switch-user': state.user.single.email})]})
            .then(data => dispatch(salaryActions.fetchSalariesSuccess(data.data)))
    }, [single])

    return <>
        <div className="card shadow-sm p-1 p-md-3">
            <div className="d-flex align-items-center mb-3">
                <div className="flex-grow-1">
                    <h4>
                        {single.id} - {single.title}
                    </h4>
                </div>
                <div className="flex-grow-0">
                    <button onClick={() => setSingle(undefined)} className={'btn btn-outline-primary'}>
                        <i className={'bi bi-chevron-left'}> </i> Retour
                    </button>
                </div>
            </div>
            <div className="col-12 mb-3">
                <h4>
                    <span className={'rounded-pill p-1 bg-primary text-white me-2'}>{single.companies?.length} <i className={'bi bi-shop'}> </i></span>  Points de vente
                </h4>
                <table className={"table table-bordered"}>
                    <thead>
                        <tr>
                            <th>
                                Nom
                            </th>
                            <th>
                                Date de création
                            </th>
                            <th>
                                Ville
                            </th>
                            <th>
                                CP
                            </th>
                            <th>
                                Adresse
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {single.companies?.map((company: Company) => <tr>
                            <td>{company.title}</td>
                            <td> {company.createdAt}</td>
                            <td> {company?.address?.city}</td>
                            <td> {company?.address?.zip}</td>
                            <td> {company?.address?.address}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <h4>
                    <span className={'rounded-pill p-1 bg-primary text-white me-2'}>{single.salariesCount} <i className={'bi bi-people'}> </i></span>  Collaborateurs
                </h4>
                <div className="table-responsive">

                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Métier
                            </th>
                            <th>
                                Role
                            </th>
                            <th>
                                Date d'ajout
                            </th>
                            <th>
                                Actif
                            </th>
                            <th>
                                Compte
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Tél
                            </th>
                            <th>

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.salary.isLoading && <tr><td colSpan={100}><SmallLoader /> Chargement en cours...</td></tr>}
                        {state.salary.payload.map((salary: Salary) => <tr>
                            <td>{salary.title}</td>
                            <td> {salary.information?.job.title}</td>
                            <td> {salary.accessLevel.title}</td>
                            <td> {salary.createdAt}</td>
                            <td className={'text-center'}> {salary.activated ? <i className={'bi bi-check-circle text-success'}> </i> : <i className={'bi bi-x-circle text-danger'}> </i>}</td>
                            <td className={'text-center'}> {salary.user ? <i className={'bi bi-check-circle text-success'}> </i> : <i className={'bi bi-x-circle text-danger'}> </i>}</td>
                            <td>
                                <button className={'btn btn-sm ' + (state.auth.user.email === salary.email ? 'text-primary' : '')}
                                        onClick={() => {
                                            if (salary.user){
                                                userApi.show(salary.user.id!).then(data => dispatch(authActions.impersonateUser(data.data)))
                                            }
                                        }}
                                >
                                    {salary.email} {state.auth.user.id === salary.user?.id && <i className={'bi bi-check2-circle'}> </i>}
                                </button>
                            </td>
                            <td> {salary.tel}</td>
                            <td>
                                {salary.user?.activated}
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </>
}

export default UserCompanyGroupShow;