import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/activity";
import * as api from "../../../adapters/activity";
import '../../../utils/checkboxAndRadios/index.scss'
import * as Yup from "yup";
import {useFormik} from "formik";
import {showAlertSuccess} from "../../../actions/alert";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {axiosError} from "../../../actions/axios";
import {refresh} from "../../../actions/calendar";
import FormError from "../../../utils/formError";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import ColorPicker from "../../../utils/colorPicker";
import IconPicker from "../../../utils/iconPicker";

const ActivitySchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
    company: Yup.number().required('Champ requis'),
    icon: Yup.string().nullable(),
    color: Yup.string().nullable(),
});

export const ActivityEdit: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)


    const formik = useFormik({
        initialValues: {
            title: state.rightSidebar.data.title,
            description: state.rightSidebar.data.description,
            company: state.rightSidebar.data.company.id,
            icon: state.rightSidebar.data.icon,
            color: state.rightSidebar.data.color
        },
        validationSchema: ActivitySchema,
        onSubmit: values => {
            dispatch(submitSidebar());
            api.patch(values, parseInt(state.rightSidebar.data.id)).then(data => {
                dispatch(actions.editActivitySuccess(data.data))
                dispatch(refresh())
                dispatch(closeSidebar())
                dispatch(showAlertSuccess(data.data.title + " à bien été modifié !"))
            }).catch(error => dispatch(axiosError(error)))
        }
    })

    return <form onSubmit={formik.handleSubmit} className={'p-1 p-md-3'}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input name={'title'} id={'title'} defaultValue={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" className="form-control"/>
                <label htmlFor="title">
                    Titre
                </label>
            </div>
            <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input name={'description'} id={'description'} defaultValue={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" className="form-control"/>
                <label htmlFor="title">
                    Description
                </label>
            </div>
            <FormError errors={formik.errors} touched={formik.touched} field={'description'} />
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                fetchEntity={"company"}
                label={"Point de vente"}
                value={{label: state.rightSidebar.data.company.title, value: state.rightSidebar.data.company.id}}
                onChange={(choice) => formik.setFieldValue('company', choice?.value)}
            />
            <FormError errors={formik.errors} touched={formik.touched} field={'company'} />
        </div>
        <div className="col-12 mb-3">
            <ColorPicker onChange={(choice) => formik.setFieldValue('color', choice)} selected={formik.values.color} />
        </div>
        <div className="col-12 mb-3">
            <IconPicker onChange={(choice) => formik.setFieldValue('icon', choice)} selected={formik.values.icon} />
        </div>
        <button type={'submit'} className={'btn btn-outline-primary w-100'}>
            <i className={'bi bi-check'}> </i> Valider
        </button>
    </form>
}

export default ActivityEdit;