import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as api from "../../../adapters/template"
import * as resourceApi from "../../../adapters/template/resource"
import * as calendarTypes from "../../../constants/calendar";
import FullCalendar, {
    CalendarApi,
    DateSelectArg,
    EventClickArg,
    EventDropArg,
    SlotLabelContentArg
} from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, {EventResizeDoneArg} from "@fullcalendar/interaction";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import allLocales from "@fullcalendar/core/locales-all";
import onMouseEnterTooltip from "../../../utils/mouseOverTooltip";
import format from "../../../utils/locale";
import {CalendarTitle, renderDaySeparator, renderEvent, RenderTemplateResource} from "../../calendar/utils";
import {setPageTitle} from "../../../actions/header";
import {Template} from "../../../models/template";
import TemplateResourceSidebar, {TemplateResourceSidebarInterface} from "../resource/sidebar";
import BgSingle from "../../../utils/background/single";
import TemplateEventSidebar, {TemplateEventSidebarInterface} from "../event/sidebar";
import {setHours} from "date-fns";
import Loader from "../../../utils/loader";
import IsGranted from "../../../security/isGranted";
import * as accessRights from "../../../constants/accessRight";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EDIT_TEMPLATE} from "../../../constants/rightSidebar";
import TemplateSchedulerForm from "../scheduler/form";

const TemplateShow:React.FC = () => {

    const params:{id: string} = useParams()
    const state = useSelector((state: RootStateOrAny) => state)
    const calendarRef = React.createRef<FullCalendar>();
    const dispatch = useDispatch();
    const [template, setTemplate] = useState<Template>()
    const [ratio, setRatio] = useState<"s"|"m"|"l">(state.auth.user.calendarSettings.ratio || "l");

    const [title, setTitle] = useState('')
    const [calendarData, setCalendarData] = useState<{
        events: any[],
        resources: any[],
        presences: any[],
        timeSheets: any[],
        loading: boolean,
        start: Date,
        end: Date,
    }>({
        events: [],
        resources: [],
        presences: [],
        timeSheets: [],
        loading: false,
        start: new Date(),
        end: new Date()
    });

    const [view, setView] = useState<string>(state.auth.user.calendarSettings.defaultView);
    const [timePrecision, setTimePrecision] = useState(state.auth.user.calendarSettings.timePrecision)
    const [sortBy, setSortBy] = useState<1|2|3>(state.auth.user.calendarSettings.defaultGroupBy || 1);
    const [presence, setPresence] = useState<boolean>(state.auth.user.calendarSettings.isPresence);
    const [pickerDate, setPickerDate] = useState(new Date());

    const [hideChevronRight, setHideChevronRight] = useState(false);
    const [hideChevronLeft, setHideChevronLeft] = useState(false);

    const [templateResourceSidebarProps, setTemplateResourceSidebarProps] = useState<TemplateResourceSidebarInterface>({sidebarType: "EMPTY", trigger: 0})
    const [templateEventSidebarProps, setTemplateEventSidebarProps] = useState<TemplateEventSidebarInterface>({sidebarType: "EMPTY", trigger: 0})

    useEffect(() => {
        if (params.id){
            api.show(params.id).then(resp => {
                setTemplate(resp.data)
            })
        }
    }, [params.id])

    useEffect(() => {
        const API = calendarRef.current?.getApi();
        if (API)
        {
            if(view === calendarTypes.RESOURCE_TIMELINE_DAY_VIEW){
                API.setOption('slotMinWidth', 30)
                API.setOption('resourceAreaWidth', "15%")
            }else if (view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW){
                switch (ratio)
                {
                    case 's':
                        API.setOption('slotMinWidth', 20)
                        API.setOption('resourceAreaWidth', "5%")
                        break;
                    case 'm':
                        API.setOption('slotMinWidth', 30)
                        API.setOption('resourceAreaWidth', "10%")
                        break;
                    case 'l':
                        API.setOption('slotMinWidth', 40)
                        API.setOption('resourceAreaWidth', "15%")
                        break;
                }
            }
        }
    }, [ratio, view])


    useEffect(() => {
        let controller = new AbortController();
        if (template){
            dispatch(setPageTitle('Templates', template.title))
            fetchCalendar({controller: controller});
        }
        return () => controller.abort();
    }, [template?.id])

    useEffect(() => {
        renderDaySeparator();
    }, [template?.company, timePrecision, calendarData.loading])

    function activate(){
        if (template){
            api.activate(template.id).then(resp => setTemplate(resp.data))
        }
    }
    function deactivate(){
        if (template){
            api.deactivate(template.id).then(resp => setTemplate(resp.data))
        }
    }

    if (!template){
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return <div id={'singleContainer'} className={"d-flex flex-column bg-white col h-100 px-0" + (!params.id ? " d-none d-md-flex" : "")}>
        <div className="container-fluid flex-grow-0">
            <div className="row">
                <div className="col-auto">
                    <div className="btn-group shadow-sm calendar-view-container">
                        {<div className="dropdown">
                            <button className="btn btn-white text-primary shadow-sm dropdown-toggle dropdown-toggle-no-after-content" type="button" id="periodDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i className={'bi bi-arrows-angle-expand'}></i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="periodDropdown">
                                <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"minutes": 15}')}>
                                    15 min
                                </li>
                                <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"minutes": 30}')}>
                                    30 min
                                </li>
                                <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"hour": 1}')}>
                                    1h
                                </li>
                                <li className={'dropdown-item'} onClick={() => handleTimePrecisionChange('{"hour": 3}')}>
                                    3h
                                </li>
                                {[calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW, calendarTypes.LIST_WEEK_VIEW].includes(view) && <li className={'dropdown-item'}
                                     onClick={() => handleTimePrecisionChange('{"day": 1}')}>
                                    1 Jour
                                </li>}
                            </ul>
                        </div>}
                        <button className="btn btn-light text-primary dropdown-toggle-no-after-content dropdown-toggle border calendar-view-selector" type="button" id="defaultDropdown"
                                data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            {[calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW, calendarTypes.LIST_WEEK_VIEW].includes(view) && 'Semaine'}
                            {[calendarTypes.RESOURCE_TIMELINE_DAY_VIEW, calendarTypes.LIST_DAY_VIEW].includes(view) && 'Jour'}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                            <li>
                            <span className="dropdown-item"
                                  onClick={() => changeView(calendarTypes.RESOURCE_TIMELINE_DAY_VIEW)}>
                                Jour
                            </span>
                            </li>
                            <li>
                            <span className="dropdown-item"
                                  onClick={() => changeView(calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW)}>
                                Semaine
                            </span>
                            </li>
                        </ul>
                        {view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW && timePrecision !== calendarTypes.PRECISION_ONE_DAY && <>
                            <button className="btn btn-light text-primary shadow-sm dropdown-toggle dropdown-toggle-no-after-content" type="button" id="periodDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-aspect-ratio"></i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="periodDropdown">
                                <li className={'dropdown-item'} onClick={() => setRatio("s")}>
                                    Taille S
                                </li>
                                <li className={'dropdown-item'} onClick={() => setRatio("m")}>
                                    Taille M
                                </li>
                                <li className={'dropdown-item'} onClick={() => setRatio("l")}>
                                    Taille L
                                </li>
                            </ul>
                        </>}
                    </div>
                </div>
                <div className="col-auto">
                    <input type="checkbox" className="btn-check" id="simulate" autoComplete="off" onChange={(e) => handleSimulationChange(e.target.checked)} defaultChecked={template.simulation} key={template.id} />
                        <label className={"btn " + (template.simulation ? "btn-primary text-white" : "btn-light text-primary")} htmlFor="simulate"
                               title={template.simulation ? "Terminer la simulation" : "Simuler une période"}
                               onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                        >
                            <i className={'bi bi-front'}></i>
                        </label>
                </div>

                {template.simulation && <CalendarTitle pickerDate={pickerDate} handlePickerDateChange={handlePickerDateChange} title={title}  prevClick={prev} nextClick={next} endDate={calendarData.end} />}

                {!template.simulation && [calendarTypes.RESOURCE_TIMELINE_DAY_VIEW, calendarTypes.LIST_DAY_VIEW].includes(view) && <div className="col-auto">
                    <div className="btn-group">
                        {<button disabled={hideChevronLeft} className={'btn btn-light rounded-circle shadow-sm'}
                                 onClick={() => prev()}>
                            <i className={'bi bi-chevron-left'}> </i>
                        </button>}
                        <button className={'btn text-primary text-capitalize'} style={{width: 100}}>
                            {title}
                        </button>
                        {<button disabled={hideChevronRight} className={'btn btn-light rounded-circle shadow-sm'}
                                 onClick={() => next()}>
                            <i className={'bi bi-chevron-right'}> </i>
                        </button>}
                    </div>
                </div>}
                <div className="col-auto">
                    {/*<button className={"btn btn-light text-primary"} onClick={() => schedule()}>*/}
                    {/*    <i className={"bi bi-magic"}></i>*/}
                    {/*</button>*/}
                    <button className="btn btn-light text-primary" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasTemplateSchedulerForm" aria-controls="offcanvasTemplateSchedulerForm">
                        <i className={"bi bi-magic"}></i>
                    </button>
                </div>
                <div className="col-auto mb-2">
                    <div className="btn-group border border-1 shadow-sm">
                        <button disabled={calendarData.loading}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Trier par collaborateur"
                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                onClick={(e) => handleSortByChange(1)}
                                className={'btn ' + (sortBy === 1 ? " bg-primary text-white" : ' hover-primary')}>
                            <i className={'bi bi-people'}> </i>
                        </button>
                        <button disabled={calendarData.loading} data-bs-toggle="tooltip"
                                onClick={() => handleSortByChange(2)}
                                data-bs-placement="top"
                                title="Trier par emplacements"
                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                className={'btn ' + (sortBy === 2 ? " bg-primary text-white" : ' hover-primary')}>
                            <i className={'bi bi-geo-alt'}> </i>
                        </button>
                        <button disabled={calendarData.loading} data-bs-toggle="tooltip" data-bs-placement="top"
                                onClick={() => handleSortByChange(3)}
                                title="Trier par activités"
                                onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                                className={'btn ' + (sortBy === 3 ? " bg-primary text-white" : ' hover-primary')}>
                            <i className={'bi bi-bookmark'}> </i>
                        </button>
                    </div>
                </div>

                <div className="col"></div>

                <div className="col-auto">
                    <div className="btn-group shadow-sm">
                        <button
                            title="Ajouter un créneau"
                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                            className="btn btn-light text-primary me-2"
                            onClick={() => setTemplateEventSidebarProps(prev => ({
                            sidebarType: "EVENT_ADD",
                            trigger: prev.trigger + 1,
                            resource: template?.resources.length > 0 ? template?.resources[0] : undefined,
                            start: new Date('2021-10-04 09:00:00'),
                            end: new Date('2021-10-04 12:00:00'),
                        }))}>
                            <i className={'bi bi-calendar-plus'}></i>
                        </button>
                        <button
                            title="Collaborateurs"
                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                            className="btn btn-light text-primary me-2"
                            onClick={() => setTemplateResourceSidebarProps(prev => ({
                            sidebarType: "RESOURCE_SALARIES_ADD",
                            trigger: prev.trigger + 1
                        }))}>
                            <i className={'bi bi-people'}></i>
                        </button>
                        <button
                            title="Ajouter une ressource"
                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                            className="btn btn-light text-primary me-2"
                            onClick={() => setTemplateResourceSidebarProps(prev => ({
                                sidebarType: "RESOURCE_FLOATING_ADD",
                                trigger: prev.trigger + 1
                            }))}>
                            <i className={'bi bi-person-plus'}></i>
                        </button>
                        {IsGranted(accessRights.EDIT_TEMPLATE) && <button
                            title="Modifier le template"
                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                            className="btn btn-light text-primary me-2"
                            onClick={() => dispatch(openSidebar(FORM_EDIT_TEMPLATE, template))}>
                            <i className={'bi bi-pencil-square'}> </i>
                        </button>}
                        {IsGranted(accessRights.DELETE_TEMPLATE) && <button
                            title={template.activated ? "Archiver le template" : "désarchiver le template"}
                            onMouseEnter={(e) => onMouseEnterTooltip(e.currentTarget)}
                            className="btn btn-light text-primary"
                            onClick={() => template?.activated ? deactivate() : activate()}>
                            {template.activated ? <i className={'bi bi-dash-circle text-danger'}> </i> : <i className={'bi bi-check-circle text-success'}> </i>}
                        </button>}
                    </div>
                </div>
            </div>
        </div>
        <div className={"col-12 flex-grow-1 " + (view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW ? `fc-ratio-${ratio}` : ``)}>
            <FullCalendar
                ref={calendarRef}
                scrollTimeReset={false}
                slotMinWidth={30}
                eventMinWidth={10}
                plugins={[resourceTimelinePlugin, listPlugin, interactionPlugin]}
                events={[...calendarData.events, ...calendarData.presences]}
                resourceOrder={['order1']}
                resources={calendarData.resources}
                eventContent={renderEvent}
                initialView={view}
                resourceLabelContent={arg => <RenderTemplateResource arg={arg} sortBy={sortBy} timeSheets={calendarData.timeSheets} removeResource={removeResource} setTemplateResourceSidebarProps={setTemplateResourceSidebarProps} />}
                headerToolbar={false}
                eventClick={handleClick}
                eventDrop={handleDrop}
                editable={true}
                displayEventTime={true}
                eventResize={handleResize}
                select={handleSelect}
                selectable={true}
                initialDate={template.simulation ? new Date() : '2021-10-04'}
                slotMinTime={format(new Date(template.company.timesheet.openAt), 'HH:mm:ss')}
                slotMaxTime={format(new Date(template.company.timesheet.closeAt), 'HH:mm:ss')}
                visibleRange={template.simulation ? undefined : {
                    start: '2021-10-04',
                    end: '2021-10-10'
                }}
                slotLabelContent={slotLabelContent}
                views={{
                    resourceTimelineDay: {
                        slotDuration: {minutes: 30}
                    },
                    resourceTimelineWeek: {
                        slotDuration: JSON.parse(timePrecision),
                    }
                }}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                locales={allLocales}
                locale={'fr'}
                height={"100%"}
                resourceAreaWidth={'15%'}
                hiddenDays={state.auth.user.calendarSettings.daysOfWeek || []}
            />
        </div>
        {calendarData.loading && <Loader />}
        <TemplateResourceSidebar {...templateResourceSidebarProps} handleEventChange={handleEventChange} template={template} setTemplate={setTemplate} />
        <TemplateEventSidebar {...templateEventSidebarProps} handleEventChange={handleEventChange} template={template} />
        <TemplateSchedulerForm template={template} fetch={fetchCalendar} start={calendarData.start} end={calendarData.end} />
    </div>

    function fetchCalendar(props: {controller?: AbortController, overrideSortBy?: number, overrideTimePrecision?: string, overrideAPI?: CalendarApi, overrideSimulation?: boolean})
    {
        const API = props.overrideAPI || calendarRef.current?.getApi()

        if (API)
        {
            setTitle(template?.simulation || props.overrideSimulation ? API.view.title : format(API.getDate(), 'EEEE'))

            setCalendarData(prev => ({...prev, loading: true, start: API.view.currentStart, end: API.view.currentEnd}))
            api.fetch({
                start: API.view.currentStart,
                end: API.view.currentEnd,
                view: API.view.type,
                sortBy: props.overrideSortBy || sortBy,
                timePrecision: props.overrideTimePrecision || timePrecision,
                presence: presence,
            }, Number(params.id), props.controller?.signal)
                .then(resp => {
                    setCalendarData(prev => ({
                        events: resp.data.templateEvents,
                        resources: resp.data.resources,
                        presences: resp.data.templatePresences,
                        timeSheets: resp.data.templateTimeSheet,
                        loading: false,
                        start: prev.start,
                        end: prev.end,
                    }))
                })
        }
    }

    function refreshCalendar(resources: number[])
    {
        api.fetch({
            start: calendarData.start,
            end: calendarData.end,
            view: view,
            sortBy: sortBy,
            timePrecision: timePrecision,
            presence: presence,
            resources: resources
        }, Number(params.id))
            .then(resp => {
                setCalendarData(prev => ({
                    events: [...resp.data.templateEvents, ...prev.events.filter(e => !resources.includes(e.resource.id))],
                    resources: [...resp.data.resources, ...prev.resources.filter(e => !resources.includes(e.id))],
                    presences: [...prev.presences.map((p: any) => {
                        let _p = {...p};
                        _p.values = p.values.filter((v: any) => !(resources.includes(v.resourceId)));

                        let allValues: any[] = [];
                        for (let i in resp.data.templatePresences){
                            if (resp.data.templatePresences[i].resourceId === p.resourceId && resp.data.templatePresences[i].start === p.start){
                                allValues = [...allValues, ...resp.data.templatePresences[i].values];
                            }
                        }

                        _p.values = [..._p.values, ...allValues]

                        return _p;
                    })],
                    timeSheets: [...prev.timeSheets.filter(t => !resources.includes(t.resource.id)), ...resp.data.templateTimeSheet],
                    loading: false,
                    start: prev.start,
                    end: prev.end,
                }))
            }).then(() => true)
    }

    function handleEventChange(resource: any, previousResource?: any)
    {
        let ids = [];

        ids.push(resource.id)
        if (previousResource){

            ids.push(previousResource.id)
        }

        return refreshCalendar(ids)
    }

    function removeResource(resource: {id: number})
    {
        if (template){
            resourceApi.remove(template?.id, resource.id).then(() => {

                let copy = {...template};
                copy.resources.filter(r => r.id !== resource.id)
                setTemplate(copy);

                setCalendarData(prev => ({
                    events: [...prev.events.filter(e => resource.id !== e.resource.id)],
                    resources: [...prev.resources.filter(e => resource.id !== e.id)],
                    presences: [...prev.presences.map((p: any) => {
                        let _p = {...p};
                        _p.values = p.values.filter((v: any) => resource.id !== v.resourceId)
                        return _p;
                    })],
                    timeSheets: [...prev.timeSheets.filter(t => t.resource.id !== resource.id)],
                    loading: false,
                    start: prev.start,
                    end: prev.end,
                }))
            })
        }
    }

    function prev()
    {
        const API = calendarRef.current?.getApi()
        if (API){
            if (!template?.simulation && API.getDate() > new Date('2021-10-04 00:00:00')){
                API.prev()
                setHideChevronRight(API.getDate() >= new Date('2021-10-10 00:00:00'))
                setHideChevronLeft(API.getDate() <= new Date('2021-10-04 00:00:00'))
                fetchCalendar({});
            }else if(template?.simulation){
                API.prev()
                fetchCalendar({});
            }
        }
    }

    function next()
    {
        const API = calendarRef.current?.getApi()
        if (API){
            if (!template?.simulation && API.getDate() < new Date('2021-10-10 00:00:00')){
                API.next()
                setHideChevronRight(API.getDate() >= new Date('2021-10-10 00:00:00'))
                setHideChevronLeft(API.getDate() <= new Date('2021-10-04 00:00:00'))
                fetchCalendar({});
            }else if(template?.simulation){
                API.next()
                fetchCalendar({});
            }
        }
    }

    function changeView(_view: string)
    {
        const API = calendarRef.current?.getApi()
        if (API){
            setView(_view)
            API.changeView(_view);
            if (!template?.simulation){
                setHideChevronLeft(API.getDate() <= new Date('2021-10-04 00:00:00'))
                setHideChevronRight(API.getDate() >= new Date('2021-10-10 00:00:00'))
                setTitle(format(API.getDate(), 'EEEE'))
            }else{
                setTitle(API.view.title)
            }

            fetchCalendar({})
        }
    }

    function handleSortByChange(_sortBy: 1|2|3) {
        setSortBy(_sortBy)
        fetchCalendar({overrideSortBy: _sortBy})
    }

    function handleTimePrecisionChange(_timePrecision: string) {
        setTimePrecision(_timePrecision)
        fetchCalendar({overrideTimePrecision: _timePrecision})
    }

    function handleClick(arg: EventClickArg)
    {
        switch (arg.event.extendedProps.eventType)
        {
            case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT:
            case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION:
                setTemplateEventSidebarProps(prev => ({
                    sidebarType: "EVENT_SHOW",
                    trigger: prev.trigger + 1,
                    setTemplateEventSidebarProps: setTemplateEventSidebarProps,
                    arg: arg
                }))
                break;
        }
    }

    function handleSelect(arg: DateSelectArg)
    {
        if (arg.resource){
            switch (arg.resource.extendedProps.resourceType)
            {
                case calendarTypes.RESOURCE_TYPE_TEMPLATE:

                    let start = arg.start;
                    let end = arg.end;

                    if (view === calendarTypes.RESOURCE_TIMELINE_WEEK_VIEW && timePrecision === calendarTypes.PRECISION_ONE_DAY){
                        start = setHours(new Date(start), 9)
                        end = setHours(new Date(start), 12)
                    }

                    setTemplateEventSidebarProps(prev => ({
                        sidebarType: "EVENT_ADD",
                        trigger: prev.trigger + 1,
                        start: start,
                        end: end,
                        resource: {
                            title: arg.resource!.title,
                            id: Number(arg.resource!.id)
                        },
                        activity: arg.resource?.extendedProps.activity,
                        localisation: arg.resource?.extendedProps.localisation,
                    }));
            }
        }
    }

    function handleDrop(arg: EventDropArg)
    {
        if (arg.newResource && arg.newResource.extendedProps.resourceType === calendarTypes.RESOURCE_TYPE_TEMPLATE){
            setTemplateEventSidebarProps(prev => ({
                sidebarType: "EVENT_ADD",
                trigger: prev.trigger + 1,
                start: arg.event.start!,
                end: arg.event.end!,
                resource: {
                    title: arg.newResource!.title,
                    id: Number(arg.newResource!.id)
                },
                activity: arg.newResource?.extendedProps.activity,
                localisation: arg.newResource?.extendedProps.localisation,
            }));
        }else {
            arg.revert()
        }
    }

    function handleResize(arg: EventResizeDoneArg)
    {
        switch (arg.event.extendedProps.eventType)
        {
            case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT:
                setTemplateEventSidebarProps(prev => ({
                    sidebarType: "EVENT_EDIT",
                    trigger: prev.trigger + 1,
                    eventType: "templateEvent",
                    exDay: Number(format(arg.event.start!, 'i')),
                    eventId: arg.event.extendedProps.eventId,
                    resource: {title: arg.event.extendedProps.resource.title, id: arg.event.extendedProps.resource.id},
                    start: arg.event.start!,
                    end: arg.event.end!,
                    activity: arg.event.extendedProps.activity,
                    localisation: arg.event.extendedProps.localisation,
                    accountingType: arg.event.extendedProps.accountingType
                }))
                break;
            case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION:
                setTemplateEventSidebarProps(prev => ({
                    sidebarType: "EVENT_EDIT",
                    trigger: prev.trigger + 1,
                    eventType: calendarTypes.EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION,
                    exceptionId: arg.event.extendedProps.exceptionId,
                    resource: {title: arg.event.extendedProps.resource.title, id: arg.event.extendedProps.resource.id},
                    start: arg.event.start!,
                    end: arg.event.end!,
                    activity: arg.event.extendedProps.activity,
                    localisation: arg.event.extendedProps.localisation,
                    accountingType: arg.event.extendedProps.accountingType
                }))
                break;
        }
    }

    function handlePickerDateChange(dt: Date)
    {
        const API = calendarRef.current?.getApi()

        if (API){
            setPickerDate(dt)
            API.gotoDate(dt)
            fetchCalendar({})
        }
    }

    function handleSimulationChange(checked: boolean)
    {
        const API = calendarRef.current?.getApi()
        if (API && template){
            api.simulate(template.id, checked)
                .then(resp => {

                    setTemplate(resp.data)

                    if (API){
                        API.gotoDate(resp.data.simulation ? new Date() : new Date('2021-10-04 00:00:00'))

                        if (!resp.data.simulate){
                            setHideChevronRight(API.getDate() >= new Date('2021-10-10 00:00:00'))
                            setHideChevronLeft(API.getDate() <= new Date('2021-10-04 00:00:00'))
                        }

                        fetchCalendar({overrideAPI: API, overrideSimulation: resp.data.simulation})
                    }
                })
        }
    }

    function slotLabelContent(e: SlotLabelContentArg){

        if (e.level === 0 && e.view.type === 'resourceTimelineDay'){
            return <span className={'text-primary'}>{format(new Date(e.date.getTime()), 'HH:mm')}</span>
        }
        if (e.level === 0 && e.view.type === 'resourceTimelineWeek'){
            if (template?.simulation){
                return <span className={'text-primary'}>{format(e.date, 'EE d')}</span>
            }
            return <span className={'text-primary'}>{format(e.date, 'EE')}</span>
        }

        if (e.level === 1 && e.view.type === 'resourceTimelineWeek'){
            return <span className={'text-primary'}>{format(new Date(e.date.getTime()), 'HH:mm')}</span>
        }

        return <span className={'text-primary'}>{e.text}</span>
    }
}

export default TemplateShow;