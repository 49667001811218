import {get, post} from "../../xhr";

interface EventOfferPayload {
    description: string,
    start: Date,
    end: Date,
    salaries: number[],
    localisation?: number,
    activity?: number,
}

export function list(requestData: {start?: Date, end?: Date}, signal: AbortSignal){
    return post('event/offer/list', requestData, {signal: signal});
}

export function edit(requestData: EventOfferPayload, id: number){
    return post('event/offer/edit/' + id, requestData);
}

export function create(requestData: EventOfferPayload){
    return post('event/offer/create',  requestData);
}

export function disable(id: number){
    return get('event/offer/disable/' + id);
}

export function show(id: number){
    return get('event/offer/show/' + id);
}

export function enable(id: number){
    return get('event/offer/enable/' + id);
}