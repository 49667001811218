import * as React from "react";

import TaskGroupList from "../../../components/taskGroup/list";

const TaskGroupListPage : React.FC = () => {
    return (
        <TaskGroupList />
    );
}

export default TaskGroupListPage;