import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/booking/offer"
import * as actions from "../../../actions/booking/offer"
import {openSidebar} from "../../../actions/rightSidebar";
import {LIST_BOOKING_OFFER} from "../../../constants/rightSidebar";
import {axiosError} from "../../../actions/axios";
import {BookingOffer} from "../../../models/booking/offer";

const DashboardBookingOffer:React.FC = () => {


    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);

    let now = new Date()
    now.setDate(now.getDate() + 30);

    useEffect(() => {
        const controller = new AbortController();

        dispatch(actions.fetchBookingOffers())
        api.list({}, controller.signal)
            .then(data => dispatch(actions.fetchBookingOffersSuccess(data.data)))
            .catch(error => dispatch(axiosError(error)))

        return () => controller.abort();
    }, [])

    return <>
        <div className="card p-3 shadow-sm flex-grow-0 mb-3 cursor-pointer" onClick={() => dispatch(openSidebar(LIST_BOOKING_OFFER, {status: 'pending'}))}>
            <div className="d-flex align-items-center">
                <div className="col">
                    <span className={'h4 text-primary'}>
                        <i className={'bi bi-calendar-x'}> </i>
                        Propositions de créneaux
                    </span>
                    <div className="clearfix"> </div>
                    <span className="form-text">
                        +30 jours
                    </span>
                </div>
                <div className="col-auto align-middle align-items-center">
                    {state.bookingOffer.isLoading ? <div className="spinner-border align-middle text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : <span className={'text-primary h2'}>{state.bookingOffer.payload.filter((bookingOffer: BookingOffer) => !bookingOffer.applicants.find(a => a.validated)).length}</span>}
                </div>
            </div>
        </div>
    </>
}

export default DashboardBookingOffer;