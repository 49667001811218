import * as types from "../../constants/team";
import * as models from "../../models/team";

export const addTeam = () => {
    return {
        type: types.ADD_TEAM,
    }
}

export const addTeamInformation = () => {
    return {
        type: types.ADD_TEAM,
    }
}

export const addTeamSuccess = (team: models.Team) => {
    return {
        type: types.ADD_TEAM_SUCCESS,
        team: team,
    }
}

export const addTeamInformationSuccess = (team: models.Team) => {
    return {
        type: types.ADD_TEAM_INFORMATION_SUCCESS,
        team: team,
    }
}

export const addTeamFailure = (error: string) => {
    return {
        type: types.ADD_TEAM_FAILURE,
        team: null,
        teams: null,
        error: error
    }
}

export const editTeam = () => {
    return {
        type: types.EDIT_TEAM,
    }
}

export const editTeamSuccess = (team: models.Team) => {
    return {
        type: types.EDIT_TEAM_SUCCESS,
        team: team,
    }
}

export const fetchTeams = () => {
    return {
        type: types.FETCH_TEAMS,
    }
}

export const fetchTeamsFailure = (error: string) => {
    return {
        type: types.FETCH_TEAMS_FAILURE,
        team: null,
        teams: [],
        error: error
    }
}

export const fetchTeamsSuccess = (teams: models.Teams) => {
    return {
        type: types.FETCH_TEAMS_SUCCESS,
        team: null,
        teams: teams
    }
}


export const showTeam = () => {
    return {
        type: types.SHOW_TEAM,
    }
}
export const showTeamSuccess = (team: models.Team|null) => {
    return {
        type: types.SHOW_TEAM_SUCCESS,
        team: team
    }
}
export const showTeamFailure = (error: string) => {
    return {
        type: types.SHOW_TEAM_FAILURE,
        team: null,
        teams: null,
        error: error
    }
}