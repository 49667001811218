import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import CounterTable from "./table";
import CounterChart from "./chart";

import * as api from "../../adapters/chart";
import { setPageTitle } from "../../actions/header";
import { fetchCountersSuccess } from "../../actions/counter";
import  {axiosError } from "../../actions/axios";

const Counter: React.FC = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => state.counter)

    const [view, setView] = useState('table');
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState('')
    const [payload, setPayload] = useState<any[]>([]);
    
    useEffect(() => {
        dispatch(setPageTitle('Compteurs'))
        let controller = new AbortController();
        api.counters().then(data => {
            setPayload(data.data)
            dispatch(fetchCountersSuccess(data.data))
            setIsLoading(false)
        }).catch(error => dispatch(axiosError(error)))
        return () => controller.abort();
    }, [isLoading])

    useEffect(() => {
        if (!query){
            setPayload([...state.payload])
        }else{
            setPayload([...state.payload.filter((c: any) => c.salary.title.toLowerCase().includes(query.toLowerCase()))])
        }
    }, [query])

    return (
        <div className={'container-fluid d-flex flex-column'}>
            <div className="row">
                <div className="col-auto">
                    <div className="btn-group">
                        <button className={'btn btn-outline-primary' + (view === 'table' ? ' active text-white ' : '')} onClick={() => setView('table')}>
                            <i className={'bi bi-table'}></i> Tableau
                        </button>
                        <button className={'btn btn-outline-primary' + (view === 'chart' ? ' active text-white ' : '')} onClick={() => setView('chart')}>
                            <i className={'bi bi-bar-chart'}></i> Graphique
                        </button>
                    </div>
                </div>
                <div className="col">
                    <div className="input-group">
                        <div className="input-group-text">
                            <i className={"bi bi-search"}></i>
                        </div>
                        <input onChange={(e) => setQuery(e.target.value)} placeholder={'Rechercher un collaborateur'} type="search" className="form-control"/>
                    </div>
                </div>
            </div>

            {view === 'table' && <CounterTable payload={payload} />}
            {view === 'chart' && <CounterChart payload={payload} />}
        </div>
    )
}

export default Counter;