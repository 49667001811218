import {get} from "../../xhr";


export function createUser(id: number){
    return get('salary/user/create/' + id);
}
export function confirmUser(id: number){
    return get('salary/user/confirm/' + id);
}
export function editUserEmail(id: number){
    return get('salary/user/email/' + id);
}