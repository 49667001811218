import React, {useEffect, useState} from "react";
import BookingEditForm from "../../../../booking/edit/form";
import {EventResizeDoneArg} from "@fullcalendar/interaction";

const BookingResizeEdit:React.FC<{arg?: EventResizeDoneArg, handleSubmitSuccess: (ev: any) => any; close: () => any}> = (props) => {

    const {arg, handleSubmitSuccess, close} = props;
    const [key, setKey] = useState<number>(0);

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarBooking')! as HTMLDivElement;
        el.addEventListener('hide.bs.offcanvas',  cancelResize)

        return () => el?.removeEventListener('hide.bs.offcanvas',  cancelResize)
    }, [arg])

    const cancelResize = () => {
        if (arg && arg.oldEvent){
            arg.event.setDates(arg.oldEvent.start!, arg.oldEvent.end!);
        }
    }

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1)
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarBookingResizeEditLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <BookingEditForm
                key={key}
                values={{
                    salary: arg.event.extendedProps.salary,
                    start: arg.event.start!,
                    end: arg.event.end!,
                    accountingType: arg.event.extendedProps.accountingType,
                    company: arg.event.extendedProps.company,
                    activity: arg.event.extendedProps.activity,
                    localisation: arg.event.extendedProps.localisation,
                    recurrence: arg.event.extendedProps.recurrence,
                    description: arg.event.extendedProps.description
                }}
                eventType={ arg.event.extendedProps.eventType}
                bookingId={arg.event.extendedProps.bookingId}
                exceptionId={arg.event.extendedProps.exceptionId}
                instanceToken={arg.event.extendedProps.instanceToken}
                bookingGroupId={arg.event.extendedProps.bookingGroupId}
                bookingGroupTitle={arg.event.extendedProps.bookingGroupTitle}
                handleSubmitSuccess={handleSubmit}
            />}
        </div>
    </>
}

export default BookingResizeEdit;