import React, {useState} from "react";
import {STATUS_PENDING} from "../../../../constants/absence";
import * as accessRights from "../../../../constants/accessRight";
import * as api from "../../../../adapters/absence";
import format from "../../../../utils/locale"
import {Absence} from "../../../../models/absence";
import {Granted} from "../../../../security/granted";

const CalendarAbsenceList:React.FC<{absences: any[], handleEventChange: (ev: any) => any}> = (props) => {

    const {absences, handleEventChange} = props;
    const [isLoading, setIsLoading] = useState(false)

    function handleStatusChange(a: Absence, s: 2|3){
        setIsLoading(true)
        api.editStatus({status: s}, a.id!)
            .then(resp => handleEventChange(resp.data))
            .then(() => setIsLoading(false))
    }

    return <div className={"flex-grow-0 card w-20"}>
        <div className="card-header">
            <h4>
                Absences en attente <span className="badge bg-warning">{absences.filter(a => a.absence.status === STATUS_PENDING).length}</span>
            </h4>
        </div>
        <div className="card-body">
            <ul className={'list-group list-group-flush'}>
                {absences.filter(a => a.absence.status === STATUS_PENDING).map(a => <li className={'list-group-item'}>
                    <h4>
                        {a.absence.salary.title} | {a.absence.typeName}
                    </h4>
                    <p className={'mb-0'}>
                        Du {format(new Date(a.absence.start), 'dd/MM/uuuu')} au  {format(new Date(a.absence.end), 'dd/MM/uuuu')}
                    </p>
                    <p className="form-text">
                        Demande faite le {format(new Date(a.absence.createdAt), 'dd/MM/uuuu')}
                    </p>
                    {Granted(accessRights.EDIT_ABSENCE, undefined, a.absence) && <div className="row">
                        <div className="col">
                            <button type={'button'} className={'btn w-100 btn-light text-nowrap'} disabled={isLoading} onClick={() => handleStatusChange(a.absence, 2)}>
                                <i className={'bi bi-check text-success'}></i> Accepter
                            </button>
                        </div>
                        <div className="col">
                            <button type={'button'} className={'btn w-100 btn-light text-nowrap'} disabled={isLoading} onClick={() => handleStatusChange(a.absence, 3)}>
                                <i className={'bi bi-x text-danger'}></i> Refuser
                            </button>
                        </div>
                    </div>}
                </li>)}
            </ul>
        </div>
    </div>
}

export default CalendarAbsenceList;

