import * as React from "react"

import Counter from "../../components/counter";

const CounterPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <Counter />
        </div>
    );
}

export default CounterPage;