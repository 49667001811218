import React, {useEffect, useState} from "react";
import format from "../../../../utils/locale";
import * as api from "../../../../adapters/eventExchange";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import FullCalendar, {EventClickArg} from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import {CalendarTitle} from "../../utils";
import allLocales from "@fullcalendar/core/locales-all";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../actions/alert";
import Loader from "../../../../utils/loader";
import SmallLoader from "../../../../utils/loader/small";
import getMaxVisibilityDate from "../../../../security/getMaxDate";


interface Interface {
    arg: EventClickArg,
    handleSubmitSuccess: (ev: any) => any,
    close: () => any,
}

const CalendarEventExchange:React.FC<Interface> = (props) => {

    const {close, arg, handleSubmitSuccess} = props;

    const dispatch = useDispatch();
    const [pickerDate, setPickerDate] = useState(new Date());
    const [title, setTitle] = useState<string>('');
    const calendarRef = React.createRef<FullCalendar>();
    const [selected, setSelected] = useState<EventClickArg>()
    const [submiting, setSubmiting] = useState<boolean>(false)
    const [endDate, setEndDate] = useState<Date>(arg.view.activeEnd);

    const [jobs, setJobs] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSameDuration, setIsSameDuration] = useState<boolean>(true)
    const [slots, setSlots] = useState<{
        start: string,
        end: string,
        timeCaption: string,
        timeDiff: number,
        timeDiffCaption: string,
        salary: Salary,
        company: Company,
        eventId: number,
        initialStart: string,
    }[]>([])


    useEffect(() => fetchCalendar(), [isSameDuration]);

    function validate()
    {
        if (selected){
            setSubmiting(true)
            api.create({
                event: arg.event.extendedProps.eventId,
                exDate: arg.event.extendedProps.initialStart,
                target: selected.event.extendedProps.eventId,
                targetExDate: selected.event.extendedProps.initialStart
            }).then(() => handleSubmitSuccess({salaries: [arg.event.extendedProps.salary, selected.event.extendedProps.salary]}))
                .then(() => setSubmiting(false))
                .then(() => dispatch(showAlertSuccess('Créneau échangé')))
                .then(() => close())
        }
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventExchangeLabel">Échanger un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {selected && <div className={'h-100 d-flex flex-column'}>
                <h4 className={'flex-grow-0 mb-3'}>
                    Récapitulatif
                </h4>
                <div className="col mb-3 flex-grow-0">
                    <div className="border border-1 shadow-sm">
                        <div className="card-body">
                            <p>
                                {arg.event.extendedProps.salary.title}
                            </p>
                            <p>
                                {format(arg.event.start!, 'E dd MMMM uuuu HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}></i> {format(arg.event.end!, 'HH:mm')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col mb-3 text-center flex-grow-0">
                    <p className={'mb-0'}>
                        <i className={'bi bi-shuffle'}></i> Échange avec
                    </p>
                </div>
                <div className="col mb-3 flex-grow-0">
                    <div className="border border-1 shadow-sm">
                        <div className="card-body">
                            <p>
                                {selected.event.extendedProps.salary.title}
                            </p>
                            <p>
                                {format(selected.event.start!, 'E dd MMMM uuuu HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}></i> {format(selected.event.end!, 'HH:mm')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mb-3 flex-grow-0">
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-light w-100" onClick={() => validate()}>
                                {submiting ? <SmallLoader /> : <><i className={'bi bi-check text-success'}></i> Valider</>}
                            </button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-light w-100" onClick={() => setSelected(undefined)}>
                                <i className={'bi bi-x text-danger'}></i> Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
            {!selected && <div className={'h-100 d-flex flex-column'}>
                <div className="col flex-grow-0">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header">
                            <i className={'bi bi-filter'}></i> Filtrer
                        </div>
                        <div className="card-body">
                            <div className="form-check form-switch">
                                <input className="form-check-input" onChange={e => setIsSameDuration(e.currentTarget.checked)} type="checkbox" role="switch" id="sameDuration" defaultChecked={isSameDuration} />
                                <label className="form-check-label" htmlFor="sameDuration">Durée équivalente</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 flex-grow-0 row mb-3">
                    <div className="col-auto mx-auto">
                        <CalendarTitle pickerDate={pickerDate} handlePickerDateChange={handlePickerDateChange} title={title}  prevClick={prevClick} nextClick={nextClick} endDate={endDate} />
                    </div>
                </div>
                <div className="col-12 flex-grow-1 position-relative">
                    <FullCalendar
                        headerToolbar={false}
                        ref={calendarRef}
                        initialDate={arg.view.calendar.getDate()}
                        plugins={[listPlugin]}
                        events={slots}
                        height={'100%'}
                        eventContent={(e) => <>
                            <h4 className={'mb-0'}>
                                {e.event.extendedProps.salary.title}
                            </h4>
                            <p className="form-text mb-0">
                                {e.event.extendedProps.salary.information.job.title}
                            </p>
                        </>}
                        viewDidMount={(arg) => setTitle(arg.view.title)}
                        initialView={'listWeek'}
                        locales={allLocales}
                        locale={'fr'}
                        eventClick={setSelected}
                    />
                    {isLoading && <Loader />}
                </div>
            </div>}
        </div>
    </>


    function handlePickerDateChange(dt: Date)
    {
        if (hasApi()){
            setPickerDate(dt)
            getApi().gotoDate(dt)
            setTitle(getApi().view.title)
            setEndDate(getApi().view.activeEnd)
            fetchCalendar()
        }
    }

    function getApi(){
        return calendarRef.current!.getApi();
    }

    function hasApi(){
        return !!calendarRef.current?.getApi();
    }
    function prevClick(){
        if (hasApi()){
            getApi().prev()
            setTitle(getApi().view.title)
            setEndDate(getApi().view.activeEnd)
            fetchCalendar()
        }
    }

    function nextClick(){
        if (hasApi()){
            getApi().next()
            setTitle(getApi().view.title)
            setEndDate(getApi().view.activeEnd)
            fetchCalendar()
        }
    }

    function fetchCalendar(){

        if (hasApi()){
            setSlots([])
            setIsLoading(true)
            api.list({
                event: {
                    start: format(arg.event.start!, 'uuuu-MM-dd HH:mm:ss'),
                    end: format(arg.event.end!, 'uuuu-MM-dd HH:mm:ss'),
                    company: arg.event.extendedProps.company.id,
                    salary: arg.event.extendedProps.salary.id
                },
                isSameDuration: isSameDuration,
                jobs: jobs,
                start: format(getApi().view.activeStart, 'uuuu-MM-dd'),
                end: format(getApi().view.activeEnd, 'uuuu-MM-dd')
            })
                .then(data => {
                    setSlots(data.data)
                    setIsLoading(false);
                })
        }
    }
}

export default CalendarEventExchange;