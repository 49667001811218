import React, {ChangeEvent, useEffect, useState} from "react";
import {useFormik} from "formik";
import {RootStateOrAny, useSelector} from "react-redux";
import * as Yup from "yup";
import format from "../../../../utils/locale";
import DatePicker from "react-datepicker";
import * as recurrenceApi from "../../../../adapters/task/recurrence";
import Loader from "../../../../utils/loader";
import CustomInput from "../../../../utils/customInput";

interface TaskRecurrenceEditProps {
    setEditRecurrence: any,
}

const TaskRecurrenceEdit = (props: TaskRecurrenceEditProps) => {

    const {setEditRecurrence} = props

    const state = useSelector((state: RootStateOrAny) => state)
    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data.recurrence)
    const [initialValues, setInitialValues] = useState<any>()
    const [validationSchema, setValidationSchema] = useState<any>();

    useEffect(() => {

        let _validationSchema:any = {}
        let _initialValues:any = {}
        _initialValues.type = data.type.toString();
        _validationSchema.type = Yup.number().required();

        if (data.type > 1){
            _validationSchema.start = Yup.date().required();
            _initialValues.start = new Date(data.start);
            _validationSchema.end = Yup.date().nullable();
            _initialValues.end = data.end ? new Date(data.end) : null;
        }

        switch (data.type) {
            case 2:
                _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour de la semaine');
                _initialValues.daysOfWeek = data.daysOfWeek.map((day:number) => day.toString());
                break;
            case 3:
                _validationSchema.dayOfMonth = Yup.number().required('Veuillez choisir un jour du mois');
                _initialValues.dayOfMonth = data.dayOfMonth;
                break;
            case 4:
                _validationSchema.dayOfMonth = Yup.number().required('Veuillez choisir un jour du mois');
                _initialValues.dayOfMonth = data.dayOfMonth;
                _validationSchema.monthOfYear = Yup.number().required('Veuillez choisir un mois de l\'année');
                _initialValues.monthOfYear = data.monthOfYear;
                break;
            case 5:
                _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour de la semaine');
                _initialValues.daysOfWeek = data.daysOfWeek;
                _validationSchema.separationCount = Yup.number().required('Veuillez préciser une récurrence')
                _initialValues.separationCount = data.separationCount
                break;
        }

        setValidationSchema(Yup.object().shape({..._validationSchema}))
        setInitialValues({..._initialValues})
    }, [])

    const handleTypeChange = (type: number) => {
        let _initialValues:any = {...recurrenceForm.values}
        _initialValues.type = type.toString();
        let _validationSchema:any = {...validationSchema.fields}
        let recurrenceFields:string[] = [];

        switch (type){
            case 1:
                // ONCE
                recurrenceFields = ['type'];
                _validationSchema.type = Yup.number().required();
                break;
            case 2:
                // EACH WEEK
                recurrenceFields = ['type', 'start', 'end', 'daysOfWeek'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour de la semaine');
                break;
            case 3:
                // EACH MONTH
                recurrenceFields = ['type', 'start', 'end', 'dayOfMonth'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.dayOfMonth = Yup.number().required('Veuillez choisir un jour du mois');
                break;
            case 4:
                // EACH YEAR
                recurrenceFields = ['type', 'start', 'end', 'dayOfMonth', 'monthOfYear'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.dayOfMonth = Yup.number().required('Veuillez choisir un jour du mois');
                _validationSchema.monthOfYear = Yup.number().required('Veuillez choisir un mois de l\'année');
                break;
            case 5:
                // CUSTOM
                recurrenceFields = ['type', 'start', 'end', 'daysOfWeek', 'separationCount'];
                _validationSchema.type = Yup.number().required();
                _validationSchema.start = Yup.date().required();
                _validationSchema.end = Yup.date().nullable();
                _validationSchema.daysOfWeek = Yup.array().of(Yup.number()).min(1, 'Veuillez choisir au moins un jour de la semaine');
                _validationSchema.separationCount = Yup.number().required('Veuillez préciser une récurrence')
                break;
        }

        // DELETE UNECESSARY VALUES
        for (let key in _initialValues){
            if (!recurrenceFields.includes(key)){
                delete _initialValues[key]
            }
        }

        // SET DEFAULT VALUES
        recurrenceFields.forEach((field) => {
            if (!_initialValues.hasOwnProperty(field)){
                switch (field) {
                    case 'start':
                        _initialValues.start = new Date()
                        break;
                    case 'end':
                        _initialValues.end = undefined
                        break;
                    case 'daysOfWeek':
                        _initialValues.daysOfWeek = [format(new Date(), 'i')];
                        break;
                    case 'dayOfMonth':
                        _initialValues.dayOfMonth = format(new Date(), 'd');
                        break;
                    case 'monthOfYear':
                        _initialValues.monthOfYear = format(new Date(), 'M');
                        break;
                    case 'separationCount':
                        _initialValues.separationCount = 1;
                        break;
                }
            }
        })

        setValidationSchema(Yup.object().shape({..._validationSchema}))
        setInitialValues({..._initialValues})
    }

    const handleAllDaysOfWeekChange = (e: ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked;
        let _initialValues = {...initialValues}
        if (checked){
            _initialValues.daysOfWeek = ["1","2","3","4","5","6","7"]
        }else{
            _initialValues.daysOfWeek = []
        }

        setInitialValues({..._initialValues})
    }

    const recurrenceForm = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            recurrenceApi.edit(values, data.id).then((data) => {
                state.rightSidebar.data.recurrence = {...data.data};
                formikHelpers.setSubmitting(false)
                setEditRecurrence(false);
            });
        }
    });

    if (recurrenceForm.isSubmitting) return <Loader />

    return <>
        <div className="p-3">
            <div className="col-12 mb-2">
                <div className="row">
                    <div className="col">
                        <h4>
                            <i className="bi bi-arrow-repeat"> </i> Récurrence
                        </h4>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-light" onClick={() => setEditRecurrence(false)}>
                            <i className={'bi bi-x-lg'}> </i>
                        </button>
                    </div>
                </div>
            </div>
            <form onSubmit={recurrenceForm.handleSubmit}>
                <div className="col-12 mb-2">
                    <label htmlFor="" className={'mb-2'}>
                        Type de récurrence
                    </label>
                    <div className="form-check">
                            <input
                                defaultChecked={data.type === 1}
                                className="form-check-input"
                                type="radio"
                                name={'type'}
                                id="type_1"
                                onChange={() => handleTypeChange(1)}
                            />
                            <label className="form-check-label" htmlFor="type_1">
                                Une seule fois
                            </label>
                    </div>
                    <div className="form-check">
                            <input defaultChecked={data.type === 2}
                                   className="form-check-input"
                                   type="radio"
                                   name={'type'}
                                   id="type_2"
                                   onChange={() => handleTypeChange(2)}
                            />
                            <label className="form-check-label" htmlFor="type_2">
                                Chaque semaines
                            </label>
                    </div>
                    <div className="form-check">
                            <input defaultChecked={data.type === 3}
                                   className="form-check-input"
                                   type="radio"
                                   name={'type'}
                                   id="type_3"
                                   onChange={() => handleTypeChange(3)}
                            />
                            <label className="form-check-label" htmlFor="type_3">
                                Chaque mois
                            </label>
                    </div>
                    <div className="form-check">
                            <input defaultChecked={data.type === 4}
                                   className="form-check-input"
                                   type="radio"
                                   name={'type'}
                                   id="type_4"
                                   onChange={() => handleTypeChange(4)}
                            />
                            <label className="form-check-label" htmlFor="type_4">
                                Chaque année
                            </label>
                    </div>
                    <div className="form-check">
                            <input defaultChecked={data.type === 5}
                                   className="form-check-input"
                                   type="radio"
                                   name={'type'}
                                   id="type_5"
                                   onChange={() => handleTypeChange(5)}
                            />
                            <label className="form-check-label" htmlFor="type_5">
                                Personnalisé
                            </label>
                    </div>
                </div>
                {parseInt(recurrenceForm.values?.type) > 1 && <div className="col-12 mb-2">
                    <div className="input-group">
                        <DatePicker
                            className={'form-control'}
                            selected={recurrenceForm.values?.start}
                            customInput={<CustomInput label={'A partir du'} />}
                            selectsStart
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            endDate={recurrenceForm.values?.end}
                            onChange={(date) => recurrenceForm.setFieldValue('start', date)}
                            dateFormat={'E dd MMM uuuu'}
                        />
                        <span className="input-group-text">
                            <i className="bi bi-arrow-left-right"> </i>
                        </span>
                        <DatePicker
                            className={'form-control'}
                            selected={recurrenceForm.values?.end || undefined}
                            customInput={<CustomInput label={"Jusqu'au"} />}
                            selectsEnd
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            startDate={recurrenceForm.values?.start}
                            onChange={(date) => recurrenceForm.setFieldValue('end', date)}
                            dateFormat={'E dd MMM uuuu'}
                            isClearable={true}
                        />
                    </div>
                </div>}
                {['2','5'].includes(recurrenceForm.values?.type) && <div className={'col-12 mb-2'}>
                    <div className="form-check mb-2">
                        <input onChange={handleAllDaysOfWeekChange} className="form-check-input" type="checkbox" value="" id="daysOfWeek" />
                        <label className="form-check-label" htmlFor="daysOfWeek">
                            Tous les jours de la semaine
                        </label>
                    </div>
                    {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                        return (
                            <>
                                <input
                                    defaultChecked={initialValues.daysOfWeek?.includes((index + 1).toString())}
                                    onChange={recurrenceForm.handleChange}
                                    type="checkbox"
                                    name={'daysOfWeek'}
                                    className="btn-check"
                                    id={`daysOfWeek_${index}`}
                                    autoComplete="off"
                                    value={index + 1}
                                />
                                <label className="btn btn-sm btn-outline-primary me-1 mb-1" htmlFor={`daysOfWeek_${index}`}>
                                    {day}
                                </label>
                            </>
                        )
                    })}
                </div>}
                {['3','4'].includes(recurrenceForm.values?.type) && <div className={'col-12 mb-2'}>
                    <div className="input-group">
                        <span className={'input-group-text bg-white text-primary'}>
                            Jour du mois
                        </span>
                        <select className={'form-control'} name="dayOfMonth" onChange={recurrenceForm.handleChange}>
                            {Array.from(Array(31).keys()).map((day, index) => <option selected={day+1 == initialValues.dayOfMonth} key={index} value={day + 1}>{day + 1}</option>)}
                        </select>
                    </div>
                </div>}
                {recurrenceForm.values?.type === '4' && <div className={'col-12 mb-2'}>
                    <div className="input-group">
                        <span className={'input-group-text bg-white text-primary'}>
                            Mois de l'année
                        </span>
                        <select className={'form-control'} name="monthOfYear" onChange={recurrenceForm.handleChange} defaultValue={data.monthOfYear}>
                            {Array.from(Array(12).keys()).map((month, index) => <option selected={month+1 == initialValues.monthOfYear} key={index} value={month + 1}> {format(new Date(new Date().setMonth(month)), 'MMMM')}</option>)}
                        </select>
                    </div>
                </div>}
                {recurrenceForm.values?.type === '5' && <div className={'col-12 mb-2'}>
                    <div className="input-group">
                        <label htmlFor="">
                            Toute les
                        </label>
                        <input type="number" onChange={recurrenceForm.handleChange} name={'separationCount'} defaultValue={data.separationCount} />
                    </div>
                </div>}
                <button type={'submit'} className={'btn btn-outline-primary w-100'}>
                    Enregistrer
                </button>
                {/*{JSON.stringify(recurrenceForm.errors, null, 2)}*/}
            </form>
        </div>
    </>
}

export default TaskRecurrenceEdit;