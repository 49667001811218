import * as types from "../../../constants/booking/offer"
import * as models from "../../../models/booking/offer"
import replace from "../../../utils/arrayReplace"
import remove from "../../../utils/arrayRemove"

const bookingOffer = (state: models.initialStateInterface = models.initialState, action: {type: string, bookingOffer: models.BookingOffer, bookingOffers: models.BookingOffers, error: string}) => {

    switch (action.type) {
        case types.FETCH_BOOKING_OFFERS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_BOOKING_OFFERS_SUCCESS:
            return { payload: action.bookingOffers, single: state.single, isLoading: false, error: null };
        case types.FETCH_BOOKING_OFFERS_FAILURE:
            return { payload: action.bookingOffers, single: state.single, isLoading: false, error: action.error };

        case types.ADD_BOOKING_OFFER:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_BOOKING_OFFER_SUCCESS:
            return { payload: [action.bookingOffer, ...state.payload], single: action.bookingOffer, isLoading: false, error: null };

        case types.EDIT_BOOKING_OFFER:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_BOOKING_OFFER_SUCCESS:
            return { payload: replace(state.payload, action.bookingOffer), single: action.bookingOffer, isLoading: false, error: null };
        case types.CANCEL_BOOKING_OFFER_SUCCESS:
            return { payload: [...state.payload.filter(b => b.id !== action.bookingOffer.id)], single: null, isLoading: false, error: null };
        case types.SHOW_BOOKING_OFFER:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_BOOKING_OFFER_SUCCESS:
            return { payload: state.payload, single: action.bookingOffer, isLoading: false, error: null };
        case types.SHOW_BOOKING_OFFER_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default bookingOffer;