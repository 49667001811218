import React, {useEffect, useState} from "react";
import {Company} from "../../../../models/companies";
import * as api from "../../../../adapters/template"
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import getUser from "../../../../security/getUser";
import SmallLoader from "../../../../utils/loader/small";
import format from "../../../../utils/locale";
import {useDispatch} from "react-redux";
import {getISOWeeks, range} from "../../../../constants/global";
import * as Yup from "yup";
import {Field, useFormik} from "formik";
import FormError from "../../../../utils/formError";
import SalarySelector from "../../../salary/selector";
import {Salaries} from "../../../../models/salary";
import {Link} from "react-router-dom";

interface Interface {
    company: Company,
    start: Date,
    close: () => void;
}

const CalendarTemplateExport:React.FC<Interface> = (props) => {

    const [filterType, setFilterType] = useState(1);
    const [linkId, setLinkId] = useState(undefined);

    const [initialValues, setInitialValues] = useState({
        year: Number(format(props.start, 'uuuu')),
        week: Number(format(props.start, 'w')),
        company: props.company.id!,
        title: `Copie S.${format(props.start, 'w')} Année ${Number(format(props.start, 'uuuu'))}`,
        description: "",
        visibility: 1
    })

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
        year: Yup.number().required(),
        week: Yup.number().required(),
        company: Yup.number().required(),
        title: Yup.string().required(),
        visibility: Yup.number().required(),
    }))

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.make(values)
                .then(resp => {
                    formikHelpers.setSubmitting(false)
                    window.location.href = `#/template/${resp.data.id}`
                    props.close();
                })
        }
    })

    const handleFilterTypeChange = (filterType: number) => {
        switch (filterType){
            case 2:
                setInitialValues(prev => ({
                    week: prev.week,
                    year: prev.year,
                    company: prev.company,
                    title: prev.title,
                    description: prev.description,
                    visibility: prev.visibility,
                    job: 1
                }))

                setValidationSchema(Yup.object().shape({
                    year: Yup.number().required(),
                    week: Yup.number().required(),
                    company: Yup.number().required(),
                    title: Yup.string().required(),
                    visibility: Yup.number().required(),
                    job: Yup.number().required()
                }))
                break;
            case 3:
                setInitialValues(prev => ({
                    week: prev.week,
                    year: prev.year,
                    company: prev.company,
                    title: prev.title,
                    description: prev.description,
                    visibility: prev.visibility,
                    salaries: []
                }))
                setValidationSchema(Yup.object().shape({
                    year: Yup.number().required(),
                    week: Yup.number().required(),
                    company: Yup.number().required(),
                    title: Yup.string().required(),
                    visibility: Yup.number().required(),
                    salaries: Yup.number().required()
                }))
                break;
        }

        setFilterType(filterType)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarTemplateExportLabel">Créer un template</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        {linkId && <div className={"offcanvas-body"}>
            <div className="col-12 py-5 text-center">
                <h4 className={'mb-3'}>
                    <i className={'bi bi-check2-circle text-success'}></i> Template {formik.values.title} Créé !
                </h4>
                <Link to={`/template/${linkId}`} className={"btn btn-light text-primary w-100"} ><i className={'bi bi-arrow-right-circle'}></i> Accéder à {formik.values.title}</Link>
            </div>
        </div>}
        {!linkId && <div className="offcanvas-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col mb-3">
                        <BootstrapSelect
                            label={'Semaine'}
                            options={range(1, getISOWeeks(formik.values.year)).map(week => ({
                                label: String(week),
                                value: week
                            }))}
                            enableReinitialize={true}
                            value={{label: String(formik.values.week), value: formik.values.week}}
                            onChange={(c) => formik.setFieldValue('week', Number(c?.value))}
                            required={true}
                        />
                    </div>
                    <div className="col mb-3">
                        <BootstrapSelect
                            label={'Année'}
                            options={range(formik.values.year - 5, formik.values.year + 5).map(y => ({
                                label: String(y),
                                value: y.toString()
                            }))}
                            value={{label: String(formik.values.year), value: formik.values.year.toString()}}
                            onChange={(c) => formik.setFieldValue('year', Number(c?.value))}
                            required={true}
                        />
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        label={"Établissement"}
                        required={true}
                        value={{label: props.company.title, value: props.company.id!}}
                        options={getUser().currentSalary.companies.map((c: Company) => ({label: c.title, value: c.id}))}
                        onChange={c => formik.setFieldValue('company', c?.value)}
                    />
                </div>
                <div className="col-12 mb-3">
                    <div className="col-12 mb-3">
                        <h4>
                            Visibilité <span className="required">*</span>
                        </h4>
                        <div className="form-check mb-2">
                            <input onChange={formik.handleChange} className='form-check-input' defaultChecked={true}
                                   id={'private'} value={'1'} type={'radio'} name="visibility"/>
                            <label htmlFor="private">
                                Privée
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input onChange={formik.handleChange} className='form-check-input' value={'2'} id={"public"}
                                   type={'radio'} name="visibility"/>
                            <label htmlFor="public">
                                Publique
                            </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'visibility'}/>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="col-12 mb-3">
                        <h4>
                            Filtrer les collaborateurs
                        </h4>
                        <div className="form-check mb-2">
                            <input onChange={() => handleFilterTypeChange(1)} className='form-check-input'
                                   defaultChecked={true} id={'all'} type={'radio'} name="filter"/>
                            <label htmlFor="all">
                                Ne pas filtrer
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input onChange={() => handleFilterTypeChange(2)} className='form-check-input' value={'2'}
                                   id={"job"} type={'radio'} name="filter"/>
                            <label htmlFor="job">
                                Filtrer par métiers
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input onChange={() => handleFilterTypeChange(3)} className='form-check-input' value={'3'}
                                   id={"salaries"} type={'radio'} name="filter"/>
                            <label htmlFor="salaries">
                                Filtrer par collaborateurs/équipes
                            </label>
                        </div>
                    </div>
                </div>
                {filterType === 2 && <div className={'col-12 mb-3'}>
                    <BootstrapSelect
                        label={'Type de métier'}
                        options={[
                            {label: 'Front office', value: '1'},
                            {label: 'Back office', value: '2'}
                        ]}
                        value={{label: 'Front office', value: 1}}
                        onChange={(c) => formik.setFieldValue('job', c?.value)}
                        required={true}
                    />
                </div>}
                {filterType === 3 && <div className={'col-12 mb-3'}>
                    <SalarySelector
                        multiple={true}
                        fetchOptions={true}
                        onChange={(salaries: Salaries) => formik.setFieldValue('salaries', salaries.map(salary => salary.id))}
                        companies={[formik.values.company]}
                        initiallyOpen={true}
                        teams={true}
                    />
                </div>}
                <button className="btn w-100 btn-light" type={"submit"}>
                    {formik.isSubmitting ? <SmallLoader/> : <><i className={'bi bi-check text-success'}></i> Valider</>}
                </button>
            </form>
        </div>}
    </>
}

export default CalendarTemplateExport;