import * as React from "react";

import AbsenceCalendar from "../../../components/absence/calendar";

const AbsenceCalendarPage : React.FC = () => {
    return (
        <AbsenceCalendar />
    );
}

export default AbsenceCalendarPage;