import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../actions/staticBackdropModal"
import * as types from "../../constants/staticBackdropModal"
import AbsenceFilters from "../../components/absence/list/filter";
import {Modal} from "bootstrap";

const StaticBackdropModal:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state.staticBackdropModal)
    const dispatch = useDispatch();

    useEffect(() => {
        let myModalEl = document.getElementById('staticBackdropModal') as Element
        myModalEl.addEventListener('hide.bs.modal', () => dispatch(actions.closeModal()))

        if (state.show === true){
            openModal();
        }else if (state.show === false){
            hideModal();
        }

        return () => myModalEl.removeEventListener('hide.bs.modal', () => dispatch(actions.closeModal()))

    }, [state.show])

    const openModal = () => {
        new Modal(document.getElementById('staticBackdropModal') as Element, {
            keyboard: false
        }).show();
    }

    const hideModal = () => {
        Modal.getInstance(document.getElementById('staticBackdropModal') as Element).hide();
    }

    const ModalContent = () => {
        switch (state.item){
            case types.FORM_FILTER_ABSENCE:
                return <AbsenceFilters {...state.props} />
            default:
                return <div className={'w-100 py-5 text-center'}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
        }
    }

    const ModalTitle = () => {
        switch (state.item){
            case types.FORM_FILTER_ABSENCE:
                return <span> Filtrer les absences </span>
            default:
                return <span> Hello world </span>
        }
    }

    return <>
        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden={'true'} id={`staticBackdropModal`} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> <ModalTitle /> </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"> </button>
                    </div>
                    <div className="w-100" id={`staticBackdropModalContent`}>
                        <ModalContent />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default StaticBackdropModal;