import React, {useEffect} from "react";
import {setPageTitle} from "../../../actions/header";
import {useDispatch} from "react-redux";

const Error404 : React.FC = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle("Erreur 404"))
    }, [])

    return (
        <div className={'w-100 h-100 py-5 text-center align-middle'}>
            <div className="w-100 mt-5 text-primary">
                <i className="bi bi-compass fs-1"> </i>
            </div>
            <h1 className={'display-4'}>
                404, cette page n'existe pas !
            </h1>
        </div>
    );
}

export default Error404;