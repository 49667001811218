import React, {useEffect, useRef, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {EventClickArg} from "@fullcalendar/react";
import {openSidebar} from "../../actions/rightSidebar";
import {
    FORM_EDIT_TASK
} from "../../constants/rightSidebar";
import format from "../locale";
import {hideEventPop, refresh} from "../../actions/task/calendar";
import {showAlertSuccess} from "../../actions/alert";
import * as API from "../../adapters/task";
import {Salary} from "../../models/salary";
import * as statusApi from "../../adapters/task/status";

const TaskEventPop:React.FC = () => {

    const e:EventClickArg = useSelector((state: RootStateOrAny) => state.taskCalendar.eventClickArg)
    const displayEventPop:boolean = useSelector((state: RootStateOrAny) => state.taskCalendar.displayEventPop)
    const eventPopRef = useRef<HTMLDivElement>(null)
    const [displayCancelConfirmation, setDisplayCancelConfirmation] = useState(false);
    const [cancelType, setCancelType] = useState<1|2>(1);
    const [isLoading, setIsLoading] = useState(false)
    const [statusUpdating, setStatusUpdating] = useState(false)
    const [positions, setPositions] = useState<DOMRect>()
    const dispatch = useDispatch()


    useEffect(() => {

        document.addEventListener('click', handleBodyClick)
        setCancelType(1)

        if (e){
            setPositions(e.el.getBoundingClientRect())
        }

        return () => {
            document.removeEventListener('click', handleBodyClick)
            setPositions(undefined)
        }

    }, [e])

    useEffect(() => {
        if (positions){
            eventPopPosition();
        }
    }, [positions, displayCancelConfirmation])

    useEffect(() => {
        setDisplayCancelConfirmation(false)
    }, [e, displayEventPop])

    const eventPopPosition = () => {
        let popup = eventPopRef.current
        if (displayEventPop && popup && e && positions){
            if ((popup.clientHeight/2) + (positions.top + (positions.height/2)) > window.innerHeight){
                let diff = (popup.clientHeight/2) + (positions.top + (positions.height/2)) - window.innerHeight;
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) - diff - 15 + 'px';
            }else if ((popup.clientHeight/2) > positions.top + (positions.height/2)){
                let diff = (popup.clientHeight/2) - (positions.top + (positions.height/2));
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) + diff + 15 + 'px';
            }else{
                popup.style.top = (positions.top + (positions.height/2) - (popup.clientHeight/2)) + 'px';
            }

            if (positions.left > (320 + 5) || (window.innerWidth - positions.left - positions.width) > (320 + 5)){
                if (positions.left > (window.innerWidth - positions.left - positions.width)){
                    // LEFT
                    popup.style.left = (positions.left - 320 - 5) + 'px'
                }else{
                    //RIGHT
                    popup.style.left = (positions.left + positions.width + 5) + 'px'
                }
            }else{
                popup.style.left = (window.innerWidth - 320) / 2 + 'px';
            }
        }
    }

    const handleBodyClick = (evt: MouseEvent) => {

        let popup = eventPopRef.current!

        if (popup && displayEventPop){
            let dimensions = popup.getBoundingClientRect();

            let [xStart, xEnd] = [dimensions.left, dimensions.left + dimensions.width];
            let [yStart, yEnd] = [dimensions.top, dimensions.top + dimensions.height];

            let clickOutsideX = !(xStart <= evt.clientX && xEnd >= evt.clientX);
            let clickOutsideY = !(yStart <= evt.clientY && yEnd >= evt.clientY);

            if (clickOutsideX || clickOutsideY){
                dispatch(hideEventPop())
            }
        }
    }

    const updateStatus = (status: number) => {
        setStatusUpdating(true)
        // statusApi.edit({exDate: e.event.extendedProps.initialStart, status: status}, e.event.extendedProps.task.id).then((data) => {
        //     setStatusUpdating(false)
        // })
    }

    const EventPopContent = () => {
        if (displayEventPop){
            return <>
                <div className="col-12 mb-2">
                    <button className={'m-1 btn btn-close float-end'}  onClick={() => dispatch(hideEventPop())} />
                    <button className={'m-1 btn btn-sm shadow-sm btn-light text-primary'} onClick={() => {
                        dispatch(openSidebar(FORM_EDIT_TASK, {
                            id: e.event.extendedProps.task.id,
                            group: e.event.extendedProps.group,
                            title: e.event.title,
                            description: e.event.extendedProps.description,
                            allDay: e.event.allDay,
                            beginAt: e.event.start,
                            endAt: e.event.end,
                            salaries: e.event.extendedProps.salaries,
                            exDate: e.event.extendedProps.initialStart,
                            customFieldsValues: e.event.extendedProps.customFieldsValues,
                            recurrence: e.event.extendedProps.task.recurrence,
                            preventException: false
                        }))
                        dispatch(hideEventPop())
                    }}>
                        <i className={'bi bi-pencil-square'}> </i>
                    </button>
                    <button className={'m-1 btn btn-sm shadow-sm btn-light text-danger'} onClick={() => setDisplayCancelConfirmation(true)}>
                        <i className={'bi bi-dash-circle'}> </i>
                    </button>
                </div>
                <div className="col-12 mb-2">
                    <div className="row">
                        <div className="col">
                            <b className={'text-primary'}>
                                {e.event.title}
                            </b>
                        </div>
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className={'btn m-1 btn-light dropdown-toggle'}
                                        type="button" id={`dropdownStatus${e.event.extendedProps.task.id}`}
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    {statusUpdating &&
                                    <div className="spinner-grow spinner-grow-sm text-dark" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                    {!statusUpdating && (!e.event.extendedProps.status?.val || e.event.extendedProps.status?.val === 1) && <i className={'bi bi-clock-history text-warning'}> </i>}
                                    {!statusUpdating && e.event.extendedProps.status?.val === 2 && <i className={'bi bi-check2-circle text-success'}> </i>}
                                    {!statusUpdating && e.event.extendedProps.status?.val === 3 && <i className={'bi bi-exclamation-circle text-danger'}> </i>}
                                </button>
                                <ul style={{minWidth: '4rem'}} className="dropdown-menu text-center"
                                    aria-labelledby={`dropdownStatus${e.event.extendedProps.task.id}`}>
                                    {e.event.extendedProps.status?.val && e.event.extendedProps.status.val !== 1 && <li>
                                        <button onClick={() => updateStatus(1)}
                                                className="btn d-inline-block">
                                            <i className={'bi bi-clock-history text-warning'}> </i>
                                        </button>
                                    </li>}
                                    {e.event.extendedProps.status?.val !== 2 && <li>
                                        <button onClick={() => updateStatus(2)}
                                                className="btn d-inline-block">
                                            <i className={'bi bi-check2-circle text-success'}> </i>
                                        </button>
                                    </li>}
                                    {e.event.extendedProps.status?.val !== 3 && <li>
                                        <button onClick={() => updateStatus(3)}
                                                className="btn d-inline-block">
                                            <i className={'bi bi-exclamation-circle text-danger'}> </i>
                                        </button>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    {e.event.allDay && "Toute la journée - " + format(e.event.start!)}
                    {!e.event.allDay && format(e.event.start!, 'dd MMM uuuu HH:mm') + ' - ' + format(e.event.end!, 'HH:mm')}
                    <span className="clearfix"> </span>
                    <span>{e.event.extendedProps.task.recurrence.description}</span>
                </div>
                <div className="col-12 mb-2">
                    {e.event.extendedProps.salaries && <ul className={'list-group list-group-flush'}>
                        {e.event.extendedProps.salaries.map((salary: Salary) => <li className={'list-group-item'}>{salary.firstname} {salary.lastname}</li>)}
                    </ul>}
                </div>
            </>
        }else{
            return <></>
        }
    }

    const cancelBooking = () => {

        setIsLoading(true);

        if (e.event.extendedProps.task.recurrence.type === 1){
            API.cancel(e.event.extendedProps.task.id).then(() => cancelValidation())
        }else{
            switch (cancelType){
                case 1:
                    API.cancelOnce(e.event.extendedProps.task.id, e.event.extendedProps.initialStart).then(() => cancelValidation())
                    break;
                case 2:
                    API.cancel(e.event.extendedProps.task.id).then(() => cancelValidation())
                    break;
            }
        }
    }

    const cancelValidation = () => {
        dispatch(hideEventPop())
        dispatch(refresh());
        dispatch(showAlertSuccess('Tâche supprimé'))
        setIsLoading(false);
    }

    const CancelConfirmation = () => {
        return <>
            <div className="w-100 p-3">
                <div className="col-12 text-center mb-2">
                    Êtes vous sûr de vouloir supprimer la tâche suivante ?
                </div>
                <div className="clearfix"> </div>
                <div className="card p-2 mb-2">
                    {e.event.title}
                    <div className="col-12 mb-2">
                        {e.event.allDay && "Toute la journée - " + format(e.event.start!)}
                        {!e.event.allDay && format(e.event.start!, 'dd MMM uuuu HH:mm') + ' <i class="bi bi-arrow-left-right"> </i> ' + format(e.event.end!, 'dd MMM uuuu HH:mm')}
                    </div>
                </div>
                {e.event.extendedProps.task.recurrence.type > 1 && <>
                    <div className="col-12 mb-2">
                        <p className={'text-primary'}>
                            <i className="bi bi-arrow-repeat"> </i>
                            Tâche récurrente
                            <span className="clearfix"> </span>
                            <span>{e.event.extendedProps.task.recurrence.description}</span>
                        </p>
                        <ul className={'list-unstyled align-middle'}>
                            <li className={'mb-1'}>
                                <button className={'btn ' + (cancelType === 1 ? 'btn-primary' : 'btn-outline-primary')} onClick={() => setCancelType(1)}>
                                    {cancelType === 1 && <i className={'bi bi-check'}> </i>}   Une seule fois
                                </button>
                            </li>
                            <li className={'mb-1'}>
                                <button className={'btn ' + (cancelType === 2 ? 'btn-primary' : 'btn-outline-primary')} onClick={() => setCancelType(2)}>
                                    {cancelType === 2 && <i className={'bi bi-check'}> </i>} Toutes les occurences
                                </button>
                            </li>
                        </ul>
                    </div>
                </>}
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <button onClick={(e) => {
                                e.stopPropagation()
                                cancelBooking()
                            }} className={'btn btn-outline-danger w-100'}>
                                Oui
                            </button>
                        </div>
                        <div className="col-6">
                            <button onClick={(e) => {
                                e.stopPropagation()
                                setDisplayCancelConfirmation(false)
                            }} className={'btn btn-light w-100'}>
                                Non
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    if (!e) return <></>

   return <div ref={eventPopRef} id="eventPop" className={"card shadow p-3 position-fixed fade " + (displayEventPop ? 'show' : 'hide')} style={{width:320, transition: '0.2s', zIndex: (displayEventPop ? 9999 : -1)}}>
            {isLoading ?
                <div className={'w-100 text-center'}>
                    <div className="spinner-grow my-5 text-primary" style={{
                        width: '4rem',
                        height: '4rem',
                    }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                 : (displayCancelConfirmation ? <CancelConfirmation /> : <EventPopContent />)}
         </div>
}

export default TaskEventPop;