import React from "react";
import * as api from "../../../../adapters/event/offer/applicant";
import {EventOfferApplications} from "../../../../models/event/offer";
import {showAlertSuccess} from "../../../../actions/alert";
import {useDispatch} from "react-redux";

interface Interface {
    offerId: number
    applications: EventOfferApplications,
    handleSubmit: (ev: any) => any;
    close: () => any;
}

const CalendarOfferApplication: React.FC<Interface> = (props) => {

    const {offerId, applications, handleSubmit, close} = props;

    const dispatch = useDispatch();

    const validate = (applicationId: number) => {
        api.validate(offerId, applicationId).then(resp => {
            dispatch(showAlertSuccess('Participation validée'))
            handleSubmitSuccess(resp.data)
        })
    }

    const invalidate = (applicationId: number) => {
        api.invalidate(offerId, applicationId).then(resp => {
            dispatch(showAlertSuccess('Participation invalidée'))
            handleSubmitSuccess(resp.data)
        })
    }

    const handleSubmitSuccess = (ev: any) => {
        return Promise.all([
            handleSubmit(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarOfferApplicationLabel">Participants à l'offre de créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <ul className="list-group list-group-flush">
                {applications.map(a => <li className={'list-group-item'}>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {a.salary.title}
                            <div className="form-text">
                                {a.validated && <>Participation validée</>}
                            </div>
                        </div>
                        <div className="flex-grow-0">
                            {a.validated ? <>
                                <button type={'button'} className={'btn btn-light'} onClick={() => invalidate(a.id)}>
                                    <i className={'bi bi-x text-danger'}></i> Invalider la participation
                                </button>
                            </> : <>
                                <button type={'button'} className={'btn btn-light'} onClick={() => validate(a.id)}>
                                    <i className={'bi bi-check text-success'}></i> Valider la participation
                                </button>
                            </>}
                        </div>
                    </div>

                </li>)}
            </ul>
        </div>
    </>
}

export default CalendarOfferApplication;