import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import ReportingList from "../../components/report/list";
import ReportingShow from "../../components/report/show";
import ReportAdd from "../../components/report/add";

import { list } from "../../adapters/report";
import { fetchReports, fetchReportsSuccess } from "../../actions/report";

const ReportPage : React.FC = () => {
    const dispatch = useDispatch()

    const onFetchReports = () => {
        dispatch(fetchReports());
        list().then(resp => dispatch(fetchReportsSuccess(resp.data)))
    }

    useEffect(() => {
        onFetchReports()
    }, [])

    return (
        <>
            <div className={"container-fluid h-100"}>
                <div className="row h-100">
                    <ReportingList />
                    <ReportingShow onFetchReports={onFetchReports} />
                </div>
            </div>

            <ReportAdd />
        </>
    );
}

export default ReportPage;