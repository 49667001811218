import {post, get} from "../xhr";

interface FetchPayload {
    start: string;
    end: string;
    options?: {
        salaries?: number[];
        companies?: number[];
        isResource?: boolean;
        workingTime?: boolean;
    };
}

export function fetch(requestData: FetchPayload, signal: AbortSignal){
    return post('time_clock/booking/fetch', requestData, {signal: signal});
}

export function create(requestData: {salary?: number, company?: number, beginAt?: Date, endAt?: Date}){
    return post('time_clock/booking/create', requestData);
}

export function edit(requestData: any, id: number){
    return post(`time_clock/booking/edit/${id}`, requestData);
}

export function removeAnomaly(id: number){
    return get(`time_clock/booking/remove_anomaly/${id}`);
}

export function remove(id: number){
    return get(`time_clock/booking/remove/${id}`);
}

export function clockIn(requestData: {company: number, type: number}){
    return post('time_clock/clock_in', requestData);
}