import React, {useEffect} from "react";
import {Counter, CounterWithdrawn} from "../../../../models/counter";
import format from "../../../../utils/locale";
import {openSidebar} from "../../../../actions/rightSidebar";
import {FORM_COUNTER_SHOW} from "../../../../constants/rightSidebar";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";


const CounterTableSingle:React.FC = (props) => {

    const single = useSelector((state: RootStateOrAny) => state.counter.single);
    const dispatch = useDispatch();

    if (!single) return <></>

    return <table className={'table table-striped'}>
        <thead>
            <tr>
                <th>
                    Année
                </th>
                <th>
                    Semaine
                </th>
                <th>
                    Dates
                </th>
                <th>
                    Valeur
                </th>
                <th>
                    Retiré
                </th>
                <th>
                    #
                </th>
            </tr>
        </thead>
        <tbody>
            {single.counters.map((c: Counter) => <tr>
                <td>
                    {c.year}
                </td>
                <td>
                    {c.weekNumber}
                </td>
                <td>
                    {format(new Date(c.weekStart))} <i className={"bi bi-arrow-left-right"}></i> {format(new Date(c.weekEnd))}
                </td>
                <td>
                    {c.time} {!!c.value && <>{c.value > 0 ? <i className={'bi bi-arrow-up text-success'}></i> : <i className={'bi bi-arrow-down text-danger'}></i>}</>}
                </td>
                <td>
                    {single.countersWithdrawn.find((w: CounterWithdrawn) => w.weekNumber === c.weekNumber && w.year === c.year)?.time}
                </td>
                <th>
                    <button
                        className={'btn btn-light'}
                        onClick={() => dispatch(openSidebar(FORM_COUNTER_SHOW, {...c, ...{salary: single.salary}}))}
                    >
                        <i className={'bi bi-pencil text-primary'}></i>
                    </button>
                </th>
            </tr>)}
            <tr>
                <td colSpan={5}>
                    <h4 className={'text-primary'}>
                        Compteur
                    </h4>
                </td>
                <td>
                    {single.totalCounter}
                </td>
            </tr>
            <tr>
                <td colSpan={5}>
                    <h4 className={'text-primary'}>
                        Retiré
                    </h4>
                </td>
                <td>
                    {single.totalWithdrawn}
                </td>
            </tr>
            <tr>
                <td colSpan={5}>
                    <h4 className={'text-primary'}>
                        Payé
                    </h4>
                </td>
                <td>
                    {single.totalPaid}
                </td>
            </tr>
            <tr>
                <td colSpan={5}>
                    <h4 className={'text-primary'}>
                        Total
                    </h4>
                </td>
                <td>
                    {single.total}
                </td>
            </tr>
        </tbody>
    </table>
}

export default CounterTableSingle;