import React, {useState} from "react";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import format from '../../../utils/locale'
import * as Yup from "yup";
import {useFormik} from "formik";
import FormError from "../../../utils/formError";
import * as api from "../../../adapters/salary/template/event";
import DatePicker from "react-datepicker";
import validationClass from "../../../utils/validationClass";
import DateCustomInput from "../../../utils/customInput";
import {Salary} from "../../../models/salary";
import {Company} from "../../../models/companies";
import {AccountingTypes} from "../../../constants/booking";
import {refresh, refreshResource} from "../../../actions/calendar";
import {showAlertSuccess} from "../../../actions/alert";
import {closeSidebar, openSidebar} from "../../../actions/rightSidebar";
import {REPORTING_SHOW_WEEK} from "../../../constants/rightSidebar";
import * as reportingApi from "../../../adapters/reporting";
import {refreshSingleReporting} from "../../../actions/reporting";
import * as accessRightsTypes from "../../../constants/accessRight";
import SalarySelector from "../../salary/selector";

const EventAdd:React.FC = () => {

    const data = useSelector((state:RootStateOrAny) => state.rightSidebar.data);
    const dispatch = useDispatch();
    const [salary, setSalary] = useState<Salary>(data.salary)
    const [company, setCompany] = useState<Company>(data.company)

    const initialValues = {
        salary: salary?.id,
        start: data.start,
        end: data.end,
        accountingType: data.accountingType || 1,
        company: data.company?.id,
        activity: data.activity?.id,
        localisation: data.localisation?.id,
        daysOfWeek: data.daysOfWeek ? data.daysOfWeek.map((d: number) => String(d)) : [String(Number(format(data.start, 'i')) - 1)],
        weekNumber: Number(format(data.start, 'w')),
        year: Number(format(data.start, 'uuuu')),
        comment: ''
    }

    const validationSchema = Yup.object().shape({
        salary: Yup.number().required('Veuillez séléctionner un collaborateur dans la liste ci-dessus'),
        start: Yup.date().required(),
        end: Yup.date().required(),
        company: Yup.number().required(),
        activity: Yup.number().nullable(),
        localisation: Yup.number().nullable(),
        accountingType: Yup.number().required(),
        daysOfWeek: Yup.array().of(Yup.number()).min(1),
        weekNumber: Yup.number().required(),
        year: Yup.number().required(),
        comment: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            api.create(values).then(resp => {
                dispatch(showAlertSuccess('Créneau ajouté'))

                if (data.next){
                    switch (data.next){
                        case REPORTING_SHOW_WEEK:
                            reportingApi.fetch({
                                start: data.data.start,
                                end: data.data.end,
                                options: {
                                    salaries: [salary?.id]
                                }
                            }).then(_data => {
                                dispatch(refreshSingleReporting(_data.data[0]))
                                dispatch(openSidebar(data.next, data.data))
                            })
                            break;
                    }
                }else{
                    if (data.resourceId && data.parentId && salary){
                        dispatch(refreshResource(data.resourceId, data.parentId, salary))
                    }else{
                        dispatch(refresh());
                    }
                    dispatch(closeSidebar());
                }
            });
        }
    })

    return <form className={'p-1 p-md-3'} onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <SalarySelector
                required={true}
                fetchOptions={true}
                onChange={(s: Salary) => {
                    formik.setFieldValue('salary', s.id)
                    setSalary(s)
                }}
                accessRight={accessRightsTypes.EDIT_BOOKING}
            />
            <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                        customInput={<DateCustomInput label={'Heure de début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`start`, date)
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        startDate={formik.values.start}
                        maxDate={formik.values.end}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Heure de début"
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                </div>
                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                </div>
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Heure de fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            formik.setFieldValue(`end`, date)
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                required={true}
                label={"Type d'heure"}
                options={AccountingTypes}
                value={AccountingTypes[0]}
                onChange={(c) => formik.setFieldValue('accountingType', c?.value)}
            />
        </div>
        {formik.values.salary && <>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    required={true}
                    label={"Établissement"}
                    className={validationClass(formik.errors, formik.touched, 'company')}
                    //options={[...salary.companies.map(c => ({label: c.title, value: c.id!}))]}
                    fetchEntity="company"
                    fetchParams={{ salaries: [formik.values.salary] }}
                    value={data.company ? {label: data.company.title, value: data.company.id} : undefined}
                    onChange={(c) => formik.setFieldValue('company', c?.value)}
                    onChangeObject={(c: Company) => setCompany(company)}
                />
            </div>
            {formik.values.company &&
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'localisation')}
                            disabled={formik.isSubmitting}
                            fetchEntity={'localisation'}
                            fetchParams={{ companies: [formik.values.company] }}
                            onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                            label={"Emplacement"}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <BootstrapSelect
                            className={validationClass(formik.errors, formik.touched, 'activity')}
                            disabled={formik.isSubmitting}
                            fetchEntity={'activity'}
                            fetchParams={{ companies: [formik.values.company] }}
                            onChange={(c) => formik.setFieldValue('activity', c?.value)}
                            label={"Activité"}
                        />
                    </div>
                </div>}
        </>}
        <div className="col-12 mb-3 text-center">
            <div className="col-12 mb-3">
                <label>
                    Jours de la semaine
                </label>
                <div className="col-12">
                    <p className="form-text">
                        Semaine {formik.values.weekNumber}, Année {formik.values.year}
                    </p>
                </div>
            </div>
            {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                return (
                    <>
                        <input key={day}
                               disabled={formik.isSubmitting}
                               type="checkbox"
                               onChange={formik.handleChange}
                               name={'daysOfWeek'}
                               checked={formik.values.daysOfWeek.includes(`${index}`)}
                               className="btn-check"
                               id={`btn-check-${index}`}
                               autoComplete="off"
                               value={`${index}`} />
                        <label key={index} className="btn btn-sm btn-outline-primary me-1" htmlFor={`btn-check-${index}`}>
                            {day}
                        </label>
                    </>
                )
            })}
            <FormError touched={formik.touched} errors={formik.errors} field={'daysOfWeek'} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <textarea onChange={formik.handleChange} className="form-control" name={'comment'} placeholder="Commentaire" id="comment"
                          style={{height: 100}}></textarea>
                <label htmlFor="comment">Commentaire</label>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <button disabled={formik.isSubmitting} className={'btn btn-light w-100'} type={'button'} onClick={() => dispatch(closeSidebar())}>
                    <i className={'bi bi-x text-danger'}></i> Annuler
                </button>
            </div>
            <div className="col">
                <button type={'submit'} className={'btn btn-light w-100'} disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : <><i className={'bi bi-check text-success'}></i> Valider</>}
                </button>
            </div>
        </div>
    </form>
}

export default EventAdd;