import * as models from "../../models/companyGroup";
import {get, post} from "../xhr";

export function create(requestData: models.CompanyGroup){
    return post('company_group/create', requestData);
}

export function list(){
    return get('company_group/list');
}

export function requestModule(module: string){
    return get('company_group/module/' + module);
}

export function timeClockSetting(requestData: { setting: string, value: any }){
    return post('company_group/time_clock', requestData);
}

export function absenceSetting(requestData: { setting: string, value: any }){
    return post('company_group/absence', requestData);
}

export function accountingSetting(requestData: { setting: string, value: any }){
    return post('company_group/accounting', requestData);
}

export function modules(requestData: { setting: string, value: any }){
    return post('company_group/modules', requestData);
}

export function generalSettings(requestData: { setting: string, value: any }){
    return post('company_group/general', requestData);
}