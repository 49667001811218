import * as types from "../../constants/document";
import * as models from "../../models/document";

export const addDocument = () => {
    return {
        type: types.ADD_DOCUMENT,
    }
}

export const addDocumentSuccess = (document: models.Document) => {
    return {
        type: types.ADD_DOCUMENT_SUCCESS,
        document: document,
    }
}

export const addDocumentFailure = (error: string) => {
    return {
        type: types.ADD_DOCUMENT_FAILURE,
        document: null,
        documents: null,
        error: error
    }
}

export const editDocument = () => {
    return {
        type: types.EDIT_DOCUMENT,
    }
}

export const cancelDocumentSuccess = (document: models.Document) => {
    return {
        type: types.CANCEL_DOCUMENT_SUCCESS,
        document: document
    }
}

export const editDocumentSuccess = (document: models.Document) => {
    return {
        type: types.EDIT_DOCUMENT_SUCCESS,
        document: document
    }
}

export const editDocumentFailure = (error: string) => {
    return {
        type: types.EDIT_DOCUMENT_FAILURE,
        document: null,
        documents: null,
        error: error
    }
}

export const fetchDocuments = () => {
    return {
        type: types.FETCH_DOCUMENTS,
    }
}

export const fetchDocumentsFailure = (error: string) => {
    return {
        type: types.FETCH_DOCUMENTS_FAILURE,
        document: null,
        documents: [],
        error: error
    }
}

export const fetchDocumentsSuccess = (documents: models.Documents) => {
    return {
        type: types.FETCH_DOCUMENTS_SUCCESS,
        document: null,
        documents: documents
    }
}

export const showDocument = () => {
    return {
        type: types.SHOW_DOCUMENT,
    }
}

export const showDocumentSuccess = (document: models.Document) => {
    return {
        type: types.SHOW_DOCUMENT_SUCCESS,
        document: document
    }
}

export const showDocumentFailure = (error: string) => {
    return {
        type: types.SHOW_DOCUMENT_FAILURE,
        document: null,
        documents: null,
        error: error
    }
}