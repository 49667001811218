import {Localisations} from "../localisation";
import {Activities} from "../activity";

export interface Company {
    id?: number,
    activated: boolean,
    title: string,
    tel: string,
    email: string,
    address: CompanyAddress,
    timesheet: CompanyTimesheet,
    timeClockToken?: string,
    timeClockTimezone?: string,
    localisations?: Localisations,
    activities?: Activities,
    createdAt?: string,
    activeSubscription?: Subscription,
    sellsyId?: number,
    subscriptions: Subscription[]
}

export interface Subscription {
    id: number;
    startDate: string;
    endDate: string;
    intervalType: "monthly"|"annual";
    intervalCount: number;
    modelId: number;
    sellsyId: number;
    autoRenewal: boolean;
    canceled: boolean;
    amount: number,
    capacity: {
        used: number,
        max: number
    }
}

export interface CompanyAddress {
    id?: number,
    address: string,
    city: string,
    zip: string,

}
export interface CompanyTimesheet {
    id?: number;
    openAt: string;
    closeAt: string;
    openAtTimeStamp?: number;
    closeAtTimeStamp?: number;
    breakStart: Date|null|string;
    breakFinish: Date|null|string;
    recurrence: number;
    currentWeek: number;
    todayWeek: number;
}

export interface initialStateInterface {
    payload: Companies,
    single: Company|null,
    isLoading: boolean,
    error: object|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};

export type Companies = Company[];



