export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const CONFIRM = 'CONFIRM';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const CONFIRM_FAILURE = 'CONFIRM_FAILURE';
export const CONFIRM_TOKEN_EXIST = 'CONFIRM_TOKEN_EXIST';

export const EDIT_PASSWORD = 'EDIT_PASSWORD';
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS';
export const EDIT_PASSWORD_FAILURE = 'EDIT_PASSWORD_FAILURE';

export const LOGOUT = 'LOGOUT';

export const ADD_USER_INFORMATION = 'ADD_USER_INFORMATION';
export const ADD_USER_INFORMATION_SUCCESS = 'ADD_USER_INFORMATION_SUCCESS';
export const ADD_USER_INFORMATION_FAILURE = 'ADD_USER_INFORMATION_FAILURE';

export const EDIT_USER_INFORMATION = 'EDIT_USER_INFORMATION';
export const EDIT_USER_INFORMATION_SUCCESS = 'EDIT_USER_INFORMATION_SUCCESS';
export const EDIT_USER_INFORMATION_FAILURE = 'EDIT_USER_INFORMATION_FAILURE';

export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const UNSELECT_ACCOUNT = 'UNSELECT_ACCOUNT';
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';

export const EDIT_USER_COMPANY_GROUP = 'EDIT_USER_COMPANY_GROUP';
export const EDIT_USER_COMPANY_GROUP_SUCCESS = 'EDIT_USER_COMPANY_GROUP_SUCCESS';
export const EDIT_USER_COMPANY_GROUP_FAILURE = 'EDIT_USER_COMPANY_GROUP_FAILURE';

export const EDIT_USER_CALENDAR_SETTING_SUCCESS = "EDIT_USER_CALENDAR_SETTING_SUCCESS";

export const EDIT_SELF_SALARY = "EDIT_SELF_SALARY";

export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const EXIT_IMPERSONATE_USER = "EXIT_IMPERSONATE_USER";
export const ADD_AUTH_COMPANY = "ADD_AUTH_COMPANY";

export const REFRESH_AUTH_SUCCESS = "REFRESH_AUTH_SUCCESS";
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS";

