import accessRightChecker from "../accessRightChecker";
import * as accessRights from "../../constants/accessRight";
import {GeneralSettings} from "../../models/companyGroup";
import * as types from "../../constants/companyGroup";

const getMaxVisibilityDate = () => {

    let user = localStorage.getItem('user')

    if (user){
        let data = JSON.parse(user)

        if (!data.currentSalary)
        {
            return undefined;
        }

        if (accessRightChecker.hasGlobalRight(accessRights.SHOW_ABOVE_MAX_VISIBILITY_DATE)){
            return undefined;
        }

        let currentDate = new Date();

        const settings:GeneralSettings = data.currentSalary.companyGroup.generalSettings;

        if (settings){
            switch (settings.visibleUntil)
            {
                case types.VISIBLE_UNTIL_NO_LIMITATION:
                    return undefined;
                case types.VISIBLE_UNTIL_ONE_WEEK:
                    return new Date(currentDate.setDate(currentDate.getDate() + 7));
                case types.VISIBLE_UNTIL_TWO_WEEK:
                    return new Date(currentDate.setDate(currentDate.getDate() + 7 * 2));
                case types.VISIBLE_UNTIL_THREE_WEEK:
                    return new Date(currentDate.setDate(currentDate.getDate() + 7 * 3));
                case types.VISIBLE_UNTIL_ONE_MONTH:
                    return new Date(currentDate.setMonth(currentDate.getMonth() + 1));
                case types.VISIBLE_UNTIL_TWO_MONTH:
                    return new Date(currentDate.setMonth(currentDate.getMonth() + 2));
                case types.VISIBLE_UNTIL_THREE_MONTH:
                    return new Date(currentDate.setMonth(currentDate.getMonth() + 3));
                case types.VISIBLE_UNTIL_FOUR_MONTH:
                    return new Date(currentDate.setMonth(currentDate.getMonth() + 4));
                case types.VISIBLE_UNTIL_FIVE_MONTH:
                    return new Date(currentDate.setMonth(currentDate.getMonth() + 5));
                case types.VISIBLE_UNTIL_SIX_MONTH:
                    return new Date(currentDate.setMonth(currentDate.getMonth() + 6));
            }
        }

        return undefined;
    }
}

export default getMaxVisibilityDate;