import React from 'react';
import {useDropzone, DropzoneRootProps} from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props: DropzoneRootProps) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div<DropzoneRootProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  height: 100px;
  cursor: pointer;
`;


function StyledDropzone(props: DropzoneRootProps) {

    const {handleUploadedFile, acceptedFilesProps, multiple} = props

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: acceptedFilesProps || ['image/*', 'application/pdf'],
        multiple: multiple || false,
        maxSize: 1000000000,
        onDrop: acceptedFile => {
            if (handleUploadedFile){
                handleUploadedFile(acceptedFile);
            }
        }
    });

    const FileNames = () => {
       return <>
           {acceptedFiles.map(file => (
               <p className={'text-primary'}>
                   {file.name}
               </p>
           ))}
       </>
    }

    return (
        <div className="w-100">
            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                <input {...getInputProps()} />
                {acceptedFiles.length ? <FileNames /> : <p className={'text-center mt-3'}>Déposez votre fichier ici ou cliquez pour séléctionner</p>}
            </Container>
        </div>
    );
}

export default StyledDropzone;