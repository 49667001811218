import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {BaseEditor, createEditor, Descendant, Editor, Transforms} from "slate";
import {Slate, Editable, withReact, ReactEditor} from "slate-react";
import { withHistory, HistoryEditor } from "slate-history";
import { withLinks } from "./plugins";
import { BlockButton, LinkButton, MarkButton, Toolbar } from "./components";
import { toggleKeyboardShortcut } from "./keyboardShortcuts";
import { Element, Leaf } from "./toolbarElements";

const SlateEditor:React.FC<{initialValue?: any, onChange: (value: Descendant[]) => void, setValue: any, value: any, isSubmitting?: boolean, disabled: boolean, toolbar?: boolean, toolbarSubmit?: boolean}> = (props) => {

  const {initialValue, onChange, disabled, toolbar, toolbarSubmit, isSubmitting, setValue, value} = props;

  // Create a Slate editor.tsx object that won't change across renders.
  const editor = useRef(
    withLinks(withHistory(withReact(createEditor())))
  );

  useEffect(() => {
      // Get initial total nodes to prevent deleting affecting the loop
      let totalNodes = editor.current.children.length;

      // Remove every node except the last one
      // Otherwise SlateJS will return error as there's no content

      Transforms.insertNodes(editor.current, [
          {
              type: "paragraph",
              children: [{ text: "" }]
          }], {
          at: [editor.current.children.length],
      });

      for (let i = 0; i < totalNodes; i++) {
          Transforms.removeNodes(editor.current, {
              at: [totalNodes-i-1],
          });
      }

      // No saved content, don't delete anything to prevent errors
      if (!initialValue || initialValue.length <= 0) return;

      // Add content to SlateJS
      for (const value of initialValue ) {
          Transforms.insertNodes(editor.current, value, {
              at: [editor.current.children.length],
          });
      }

      // Remove the last node that was leftover from before
      Transforms.removeNodes(editor.current, {
          at: [0],
      });
  }, [initialValue])

  // Keep track of state for the value of the editor.tsx.
  // const [value, setValue] = useState<Descendant[]>(
  // ]); // Use type any for now. Initial state for an app would be the data passed to the component.

  // Define a rendering function based on the element passed to `props`. We use
  // `useCallback` here to memoize the function for subsequent renders.
  const renderElement = useCallback(props => <Element {...props} />, []);
  const RenderLeaf = useCallback(props => <Leaf {...props} />, []);
  const addVariable = (variable: string) => {
    editor.current.insertText(`[#${variable}]`)
  }

  return (
      <div className="row">
        <div className={'col-md-12'}>
          <Slate editor={editor.current}
                 value={initialValue || [
                {
                  type: "paragraph",
                  children: [{ text: "" }]
                }]}
                 onChange={value => onChange && onChange(value)}>
            <div>
              {toolbar && <Toolbar>
                <MarkButton format="bold" icon="type-bold"/>
                <MarkButton format="italic" icon="type-italic"/>
                <MarkButton format="underline" icon="type-underline"/>

                <BlockButton format="start" icon="text-left"/>
                <BlockButton format="center" icon="text-center"/>
                <BlockButton format="end" icon="text-right"/>
                <BlockButton format="heading-one" icon="type-h1"/>
                <BlockButton format="heading-two" icon="type-h2"/>
                <BlockButton format="heading-three" icon="type-h3"/>

                <BlockButton format="block-quote" icon="quote"/>
                <BlockButton format="numbered-list" icon="list-ol"/>
                <BlockButton format="bulleted-list" icon="list-ul"/>
                <LinkButton format="bulleted-list" icon="link"/>
                {toolbarSubmit && <button disabled={isSubmitting} type={'submit'} className={'btn btn-sm btn-outline-primary float-end'}>
                    {isSubmitting ? <>
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div></> : <><i className={'bi bi-check'}> </i> Mettre à jour</>}
                </button>}
                <div className="dropdown d-inline-block float-end">
                  <button className="btn btn-outline-dark btn-sm me-2 dropdown-toggle" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                    <i className={'bi bi-plus text-primary'}> </i> Variable
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('firstname')}>
                      <i className={'bi bi-plus text-primary'}> </i> Prénom
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('lastname')}>
                      <i className={'bi bi-plus text-primary'}> </i> Nom
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('birthdate')}>
                      <i className={'bi bi-plus text-primary'}> </i> Date de naissance
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('email')}>
                      <i className={'bi bi-plus text-primary'}> </i> Email
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('tel')}>
                      <i className={'bi bi-plus text-primary'}> </i> Tel
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('address')}>
                      <i className={'bi bi-plus text-primary'}> </i> Adresse
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('city')}>
                      <i className={'bi bi-plus text-primary'}> </i> Ville
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('zip')}>
                      <i className={'bi bi-plus text-primary'}> </i> Code postal
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('job')}>
                      <i className={'bi bi-plus text-primary'}> </i> Métier
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('contract')}>
                      <i className={'bi bi-plus text-primary'}> </i> Contrat
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('contractWorkingTime')}>
                      <i className={'bi bi-plus text-primary'}> </i> Volume d'heure contrat
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('contract_start')}>
                      <i className={'bi bi-plus text-primary'}> </i> Date de début de contrat
                    </li>
                    <li className={'list-group-item list-group-item-action text-nowrap'}
                        onClick={() => addVariable('contract_end')}>
                      <i className={'bi bi-plus text-primary'}> </i> Date de fin de contrat
                    </li>
                  </ul>
                </div>
              </Toolbar>}
              <div className="card shadow-sm">
                <Editable
                    placeholder={"Remplissez votre document"}
                    className={'card-body mh-400'}
                    readOnly={disabled}
                    onKeyDown={(event: any) => {
                      toggleKeyboardShortcut(event, editor.current);
                    }}
                    renderElement={renderElement}
                    renderLeaf={(props) => <RenderLeaf {...props} value={value} setValue={setValue} editor={editor} />}
                />
              </div>
            </div>
          </Slate>
        </div>
      </div>
  );
}

export default SlateEditor;
