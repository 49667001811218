import React from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {edit} from "../../../adapters/job";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/job";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import JobForm from "../utils/form";
import {axiosError} from "../../../actions/axios";


export const JobEdit: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)

    const JobSchema = Yup.object().shape({
        title: Yup.string().required('Champ requis'),
        type: Yup.string().required('Champ requis'),
        color: Yup.string().required('Champ requis'),
    });

    return (
        <div className={'p-3'}>
            <Formik
                initialValues={{
                    title: state.rightSidebar.data.title,
                    type:  state.rightSidebar.data.type.toString(),
                    color: state.rightSidebar.data.color,
                }}
                validationSchema={JobSchema}
                onSubmit={values => {
                    dispatch(submitSidebar());
                    edit(values, state.rightSidebar.data.id).then(data => {
                        dispatch(actions.editJobSuccess(data.data))
                        dispatch(closeSidebar())
                        dispatch(showAlertSuccess(`Métier ${data.data.title} modifié !`))
                    }).catch(error => dispatch(axiosError(error)))
                }}
            >
                {({ errors, touched, values }) => (
                    <JobForm errors={errors} touched={touched} values={values} />
                )}
            </Formik>
        </div>
    );

}

export default JobEdit;