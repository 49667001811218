import {Middleware} from "redux";
import {RootStateOrAny} from "react-redux";
import {CLOSE_SIDEBAR} from "../../constants/rightSidebar";
import {editEventAbort} from "../../actions/calendar";

export const eventEditAbort: Middleware<
    {},
    RootStateOrAny
    > = store => next => action => {
    try {
        if ([CLOSE_SIDEBAR].includes(action.type) && store.getState().calendar.pendingEvent){
            store.dispatch(editEventAbort());
        }
        return next(action)
    } catch (err) {
        console.error('Caught an exception!', err)
        throw err
    }
}