import React, {useEffect, useState} from "react";
import ResourceAddForm from "./form";
import {Template} from "../../../../models/template";

interface Interface {
    template: Template,
    setTemplate: React.Dispatch<any>,
    close: () => any,
    handleSubmitSuccess: (ev: any, previous?: any) => any
}

const ResourceAdd:React.FC<Interface> = (props) => {

    const {handleSubmitSuccess, template, close, setTemplate} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {

        let copy = {...template};
        copy.resources.push(ev)
        setTemplate({...copy})

        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [template])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarResourceAddLabel">Ajouter une ressource flottante</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <ResourceAddForm
                key={key}
                template={template}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default ResourceAdd;