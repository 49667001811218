import * as xhr from "../xhr";
import * as models from "../../models/activity";

export interface requestPayload {
    query?: string,
    offset?: number,
    companies?: number[],
}

export function select(requestData?: requestPayload){
    return xhr.post('activity/list', requestData || {});
}

export function patch(requestData: models.Activity, id: number){
    return xhr.post('activity/edit/' + id, requestData);
}

export function put(requestData: any){
    return xhr.post('activity/create',  requestData);
}

export function order(requestData: {id: number, position: number}[]){
    return xhr.post('activity/order',  requestData);
}

export function get(id: string){
    return xhr.get('activity/show/' + id);
}

export function activate(id: number){
    return xhr.get(`activity/${id}/activate`);
}
export function deactivate(id: number){
    return xhr.get(`activity/${id}/deactivate`);
}
