export const FETCH_REPORTING = 'FETCH_REPORTING';
export const FETCH_REPORTING_SUCCESS = 'FETCH_REPORTING_SUCCESS';
export const FETCH_REPORTING_FAILURE = 'FETCH_REPORTING_FAILURE';

export const SHOW_REPORTING = 'SHOW_REPORTING';
export const SHOW_REPORTING_SUCCESS = 'SHOW_REPORTING_SUCCESS';
export const SHOW_REPORTING_FAILURE = 'SHOW_REPORTING_FAILURE';

export const REFRESH_SINGLE = 'REFRESH_SINGLE';

export const getAvailableFields = () => {
    return [
        {
            title: "Temps de travail",
            camelCase: "timesheet",
            fields: [
                {
                    camelCase: "monthlyContractWorkingTime",
                    name: "Heures contrat théorique"
                },
                {
                    camelCase: "workingTime",
                    name: "Heures réellement travaillées"
                },
                {
                    camelCase: "overtime25",
                    name: "Heures supplémentaires (25%)"
                },
                {
                    camelCase: "overtime50",
                    name: "Heures supplémentaires (50%)"
                },
                {
                    camelCase: "nightTime20",
                    name: "Horaires de nuit (20%)"
                },
                {
                    camelCase: "nightTime40",
                    name: "Horaires de nuit (40%)"
                },
                {
                    camelCase: "labourDayWorkingTime",
                    name: "Horaires jours fériés travaillés"
                },
                {
                    camelCase: "sundayWorkingTime",
                    name: "Horaires dimanches"
                },
                {
                    camelCase: "fullDays",
                    name: "Jours complets"
                },
                {
                    camelCase: "presenceDays",
                    name: "Jours présents"
                },
            ],
        },
        {
            title: "Absence",
            camelCase: "absence",
            fields: [
                {
                    camelCase: "paidVacation",
                    name: "Congés payés"
                },
                {
                    camelCase: "labourDayNonWorkingTime",
                    name: "Jour fériés"
                },
                {
                    camelCase: "recovery",
                    name: "Repos"
                },
                {
                    camelCase: "rtt",
                    name: "RTT"
                },
                {
                    camelCase: "formation",
                    name: "Formations"
                },
                {
                    camelCase: "paidElse",
                    name: "Autres absences payées"
                },
                {
                    camelCase: "unpaidVacation",
                    name: "Congés sans solde"
                },
                {
                    camelCase: "unpaidElse",
                    name: "Autres absences non payées"
                },
                {
                    camelCase: "sickLeave",
                    name: "Arrêts maladie"
                },
                {
                    camelCase: "compensatedElse",
                    name: "Autres absences indemnisées"
                }
            ]
        },
        {
            title: "Variables payées",
            camelCase: "paidVariables",
            fields: [
                {
                    camelCase: "paid",
                    name: "Heures normales payées"
                },
                {
                    camelCase: "paidLowMajoration",
                    name: "Heures supplémentaires payées à 25%"
                },
                {
                    camelCase: "paidHighMajoration",
                    name: "Heures supplémentaires payées à 50%"
                }
            ]
        }
    ];
}