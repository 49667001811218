import React from "react";
import {Salary} from "../salary";
import {EventClickArg, EventDropArg} from "@fullcalendar/react";
import {ViewApi} from "@fullcalendar/common";
import {ResourceApi} from "@fullcalendar/resource-common";
import {Template} from "../template";
import {Localisation} from "../localisation";
import {Companies} from "../companies";
import {EventResizeDoneArg} from "@fullcalendar/interaction";

export interface ResourceLabelContentArg {
    resource: ResourceApi;
    date?: Date;
    view: ViewApi;
}

export interface NeedEvent {
    id: string,
    day: string,
    time: string,
    filter: 1|2,
    item: Localisation,
    need: number,
    value: number,
    type: 1|2|3
}

export type NeedEvents = NeedEvent[]

export interface PresenceEvent {
    uid: string,
    title: string,
    value: number,
    need: string,
    start: string,
    end: string,
    backgroundColor: string,
    borderColor: string,
    classNames: string,
    editable: boolean,
    icon: string,
    resourceId: string,
    children: {resourceId: string, longResourceId: string, value: number, instanceToken: string}[],
    eventType: string,
}

export type PresenceEvents = PresenceEvent[]

export interface calendarInitialStateInterface {
    start: Date,
    end: Date,
    salary?: Salary,
    resourceId: string;
    parentId: string;
    view: string,
    events: any[],
    eventsCopy: any[],
    presenceEvents: PresenceEvents,
    presenceEventsCopy: PresenceEvents,
    resources: any[],
    resourcesCopy: any[],
    bookingOffers: any[],
    tasks: any[],
    needEvents: NeedEvents,
    workingTimes: WorkingTimes,
    payroll: number,
    title: string,
    groupBy: number,
    job: number,
    eventClickArg?: EventClickArg,
    pendingEvent?: EventResizeDoneArg|EventDropArg,
    displayEventPop: boolean,
    showEventPopup: boolean,
    displayTemplateEventPop: boolean,
    isGenerated: boolean,
    displayResourceActions: boolean,
    resourceLabelContentArg?: ResourceLabelContentArg,
    resourceActionsButton?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isTemplate: boolean,
    template: Template|null,
    refresh: number,
    refreshResource: boolean,
    companies: Companies,
    loading: boolean,
}

export interface WorkingTime {
    salary: Salary,
    resource: string,
    time: string,
    timestamp: number,
    diffTimestamp: number,
    diffInterval: string,
    contractWorkingTime: string
}

export type WorkingTimes = WorkingTime[];

export const initialState: calendarInitialStateInterface = {
    start: new Date(),
    end: new Date(),
    resourceId: "",
    parentId: "",
    view: '',
    events: [],
    eventsCopy: [],
    presenceEvents: [],
    presenceEventsCopy: [],
    resources: [],
    resourcesCopy: [],
    bookingOffers: [],
    tasks: [],
    needEvents: [],
    workingTimes: [],
    payroll: 0,
    displayEventPop: false,
    showEventPopup: false,
    displayTemplateEventPop: false,
    displayResourceActions: false,
    isGenerated: false,
    groupBy: 1,
    job: 0,
    title: "",
    refresh: 0,
    refreshResource: false,
    companies: [],
    loading: false,
    isTemplate: false,
    template: null
};