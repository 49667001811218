import {Companies, Company} from "../../../models/companies";
import React, {useEffect, useState} from "react";
import * as api from "../../../adapters/company";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/companies";
import {FormikTouched} from "formik";
import {PAGINATION} from "../../../constants/global";

interface SingleCompanySelectorProps {
    onSubmit: (company: Company) => void,
    onClose: any,
    initialValue?: number,
    label?: string,
    required?: boolean,
    choices?: Companies,
}

export const SingleCompanySelector:React.FC<SingleCompanySelectorProps> = (props) => {
    const {onSubmit, label, onClose, initialValue, required, choices} = props;

    const [offset, setOffset] = useState(0)
    const [isLast, setIsLast] = useState(false)
    const [query, setQuery] = useState<string|undefined>()
    const [isLoading, setIsLoading] = useState(true)
    const [isFiltering, setIsFiltering] = useState(false)
    const [error, setError] = useState<string|undefined>()
    const [selectedCompany, setSelectedCompany] = useState<number|undefined>(initialValue)
    const [companies, setCompanies] = useState<Companies>([])

    const [scrollBottom, setScrollBottom] = useState(false)

    useEffect(() => {
        if (choices){
            setCompanies(choices)
            setIsLoading(false)
        }else{
            let container = document.getElementsByClassName('offcanvas-body')[0]!
            container.addEventListener("scroll", onScrollBottom)

            setIsLoading(true);

            api.list({query: query, offset: 0}).then(data => {
                setIsLast(data.data.length < PAGINATION);
                setCompanies(data.data)
                setIsLoading(false);
                setOffset( 1);
            })

            return () => {
                container.removeEventListener("scroll", onScrollBottom)
            }
        }
    }, [])

    useEffect(() => {
        if (choices) {
            if (!query){
                setCompanies(choices)
            }else{
                setCompanies([...choices.filter((company: Company) => company.title.toLowerCase().includes(query.toLowerCase()))])
            }
        }else{
            let timer = setTimeout(() => {
                if (typeof query != "undefined"){
                    setIsFiltering(true);
                    setOffset(0)
                    api.list({query: query, offset: 0}).then(data => {
                        setIsLast(data.data.length < PAGINATION);
                        setCompanies(data.data)
                        setIsFiltering(false);
                        setOffset( 1);
                    })
                }
            }, 1000);

            return () => clearTimeout(timer)
        }
    }, [query])

    useEffect(() => {
        if (!isLoading){
            if(offset > 0 && !isLast){
                setIsLoading(true)
                api.list({query: query, offset: offset}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    setCompanies([...companies, ...data.data])
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                })
            }
        }
    }, [scrollBottom])

    const onScrollBottom = () => {
        let elem = document.getElementsByClassName('offcanvas-body')[0]!;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom((prevState) => !prevState)
        }
    }

    return (
        <div className={'listSelector col-12'} id={"CompanySelector"}>
            <div className="w-100 mb-2">
                <div className="row">
                    <div className="col">
                        <p>
                            Séléctionner un point de vente
                        </p>
                    </div>
                    <div className="col-auto">
                        <button type={'button'} className={'btn btn-light'} onClick={() => {
                            if (initialValue || !required){
                                onClose()
                            }else{
                                setError('Veuillez choisir un entreprise pour continuer')
                            }
                        }}>
                            <i className={'bi bi-x-lg'}> </i>
                        </button>
                    </div>
                    {error && <div className={'alert alert-danger w-100 p-2'}> {error} </div>}
                </div>
                <div className="input-group">
                                    <span className="input-group-text" id="search-addon">
                                        {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : <i className="bi bi-search"> </i>}
                                    </span>
                    <input type="search" onChange={(e) => {
                        setQuery(e.target.value)
                    }} className="form-control bg-white" placeholder="Rechercher une entreprise" aria-describedby="search-addon" />
                </div>
            </div>

            <ul className={'list-group list-group-flush'}>
                {companies.map((company: Company, index: number) => {
                    return (
                        <li className={'list-group-item'} key={index}>
                            <div className="form-check">
                                <input className="form-check-input" defaultChecked={company.id ? selectedCompany === company.id : false} onChange={() => onSubmit(company)} name={'Company'} type="radio" value={company.id} id={`company${index}`} />
                                <label className="form-check-label" htmlFor={`Company${index}`} >
                                    {company.title}
                                </label>
                            </div>
                        </li>
                    )
                })}
                {isLoading && <li className={'list-group-item text-center'}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </li>}
            </ul>
            {!isLast && !isLoading && <button type={'button'} onClick={() => setScrollBottom((prevState) => !prevState)} className={'btn mb-2 btn-outline-primary w-100'}>
                Charger plus d'entreprises
            </button>}
        </div>
    )
}

interface SingleCompanySelectedProps {
    onDelete: any,
    onOpen: any,
    initialValue?: number,
    initialCompany?: Company,
    btnClassName?: string,
    onShow?: any,
}

export const SingleCompanySelected:React.FC<SingleCompanySelectedProps> = (props) => {

    const {
        onDelete,
        onOpen,
        initialValue,
        initialCompany,
        btnClassName,
        onShow
    } = props;

    const [company, setCompany] = useState<Company|undefined>(initialCompany)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (initialValue && company?.id != initialValue){
            setIsLoading(true)
            api.show(initialValue).then(data => {
                setCompany(data.data)
                setIsLoading(false)
                if (onShow){
                    onShow(data.data)
                }
            })
        }
    }, [initialValue])

    return (
        <div className="bg-light p-2">
            <div className="col-12">
                <i className={'bi bi-shop text-primary'}> </i>
                <span className={'form-text'}>
                    Point de vente
                </span>
            </div>
            <div className="col-12">
                {
                    initialValue &&
                        <span className={'p-2 me-2 btn bg-white'}>
                            <span>
                              {!isLoading && company ? company.title :
                                  <div className="spinner-grow spinner-grow-sm" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                  </div>}
                            </span>
                            <span className={'ps-2'} onClick={() => {
                                onDelete(initialValue)
                                setCompany(undefined)
                            }}>
                                <i className={'bi bi-x text-danger'}> </i>
                            </span>
                        </span>
                    }
                <span>
                    <button style={{lineHeight: '1rem'}} type={'button'} className={'btn p-0 ' + (btnClassName||'btn-light')} onClick={onOpen}>
                        {!initialValue && "Séléctionner une entreprise"} <i className={initialValue ? 'bi bi-pencil-square text-primary' : 'bi bi-plus text-primary'}> </i>
                    </button>
                </span>
            </div>
        </div>
    )
}
