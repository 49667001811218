import React, { useState } from 'react'
import { Company } from '../../../../../../models/companies'

import { Salary } from '../../../../../../models/salary'

interface IProps {
  salary: Salary;
  companies: Company[];
  onLink: (salaryId: number, companyId: number) => void;
}

const CompanySalaryLinkItem: React.FC<IProps> = ({ salary, companies, onLink }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number|undefined>(undefined)

  return (
    <div className="my-1 p-2 border rounded g-0 row align-items-center">
      <div className="col-3">
        <p className="m-0">
          {`${salary?.firstname} ${salary?.lastname}`}
        </p>
      </div>

      <div className="col-3">
        <span className={`mr-1 badge ${salary?.billingCompany ? 'bg-success' : 'bg-secondary'}`}>
          {salary?.billingCompany ? `${salary.billingCompany?.title}` : 'Aucune entreprise de facturation'}
        </span>
      </div>

      <div className="col-3">
        <select
          className="form-select"
          value={selectedCompanyId}
          onChange={(e: any) => {            
            setSelectedCompanyId(e.target.value === "0" ? undefined : parseInt(e.target.value))
          }}
        >
          <option selected={!salary?.billingCompany} value={0}>Entreprises du groupement</option>
          {companies.map(company => (
            <option selected={salary?.billingCompany?.id === company.id} value={company.id}>
              {`${company.title}`}
            </option>
          ))}
        </select>
      </div>

      <div className="col-3 d-flex justify-content-end">
        <button
          className="btn btn-primary text-white"
          disabled={!selectedCompanyId}
          onClick={() => {
            if (selectedCompanyId) {
              onLink(salary.id, selectedCompanyId)
            }
          }}
        >
          Lier pour facturation
        </button>
      </div>
    </div>
  )
}

export default CompanySalaryLinkItem