import React from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import CounterMultipleCharts from "./multiple";
import CounterSingleChart from "./single";
import {TotalCounters} from "../../../models/counter";
const CounterChart:React.FC<{payload: TotalCounters}> = (props) => {

    const {payload} = props;
    const single = useSelector((state: RootStateOrAny) => state.counter.single);

    return <>
        <div className="d-flex flex-column container-fluid py-1 py-md-3 h-100">
            <CounterMultipleCharts payload={payload} />
        </div>
        <div className="modal h-100 fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Compteurs {single?.salary?.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <CounterSingleChart />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CounterChart;