import {useEffect, useState} from "react";
import {Salary} from "../../../../../models/salary";
import * as api from "../../../../../adapters/salary"
import * as actions from "../../../../../actions/salary"
import {useDispatch} from "react-redux";
import SmallLoader from "../../../../../utils/loader/small";

const SalaryTimeClockToken = ({salary}: {salary: Salary}) => {


    const [timeClockToken, setTimeClockToken] = useState<number>(salary.timeClockToken);
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    let timer: NodeJS.Timer;
    function handleQueryChange(query: number){
       clearTimeout(timer)
       timer = setTimeout(() => {
           setLoading(true)
           setTimeClockToken(query)
           api.validateToken(salary.id, timeClockToken)
               .then(r => setIsValid(r.data.success))
               .then(() => setTimeClockToken(query))
               .then(() => setLoading(false))
       }, 1500)
    }

    function validate(){
        api.setToken(salary.id, timeClockToken)
            .then(() => {
                const copy = {...salary};
                copy.timeClockToken = timeClockToken;
                dispatch(actions.showSalarySuccess(copy))
            })
    }

    return <>
        <div className="modal fade" id="timeClockModal" tabIndex={-1} aria-labelledby="timeClockModalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="timeClockModalLabel">Modifier le code badgeuse de {salary.title}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating mb-3">
                            <input type="number" step={1} id={'code'} className="form-control" defaultValue={salary.timeClockToken} onChange={e => handleQueryChange(Number(e.target.value))} />
                            <label htmlFor="code">Code</label>
                        </div>
                        {loading ? <><SmallLoader /> Verification de la disponibilité</> : <>{isValid ? <div className={'col-12 mb-3 text-success'}>Code disponible</div> : <div className={'col-12 mb-3 text-danger'}>Code indisponible</div>}</>}
                        <button onClick={() => validate()} className="btn w-100 btn-light" disabled={timeClockToken === salary.timeClockToken || !isValid}>
                            <i className={'bi bi-check'}></i> Mettre à jour
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default SalaryTimeClockToken;