import React, {useEffect, useState} from "react";
import format from "../../../utils/locale";
import * as api from "../../../adapters/absence";

const AbsenceBalance:React.FC<{start: Date, end: Date, salary?: number}> = (props) => {

    const {start, end, salary} = props;

    const [isLoading, setIsLoading] = useState(true)
    const [nbDays, setNbDays] = useState(0)
    const [nextWorkingDay, setNextWorkingDay] = useState<string>()
    const [balanceBefore, setBalanceBefore] = useState(0)
    const [balanceAfter, setBalanceAfter] = useState(0)

    useEffect(() => {
        let controller = new AbortController();
        if (salary){
            setIsLoading(true)
            api.balance({
                start: format(start, "uuuu-MM-dd"),
                end: format(end, "uuuu-MM-dd")
            }, salary, controller.signal).then(data => {
                setNbDays(data.data.nbDays)
                setBalanceBefore(data.data.balanceBefore)
                setBalanceAfter(data.data.balanceAfter)
                setNextWorkingDay(data.data.nextWorkingDay)
                setIsLoading(false)
            })
        }

        return () => controller.abort();
    }, [start, end, salary])

    if (!salary) return <></>

    return <table className={"table form-text"}>
        <tbody>
        <tr>
            <td>
                Droits cumulés au {format(end, "dd/MM/uuuu")}
            </td>
            <td>
                {isLoading ? <div className="spinner-border spinner-border-sm text-light-s" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>: <>{balanceBefore} jour(s)</>}
            </td>
        </tr>
        <tr>
            <td>
                Jour de reprise planifié
            </td>
            <td>
                {isLoading ? <div className="spinner-border spinner-border-sm text-light-s" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>: <>{nextWorkingDay ? format(new Date(nextWorkingDay)) : <i className={'bi bi-x'}></i>}</>}
            </td>
        </tr>
        <tr>
            <td>
                Durée de l'absence
            </td>
            <td>
                {isLoading ? <div className="spinner-border spinner-border-sm text-light-s" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>: <>{nbDays} jour(s)</>}
            </td>
        </tr>
        <tr>
            <td>
                Droits restant après l'absence
            </td>
            <td>
                {isLoading ? <div className="spinner-border spinner-border-sm text-light-s" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>: <>{balanceAfter} jour(s)</>}
            </td>
        </tr>
        </tbody>
    </table>
}

export default AbsenceBalance;