import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/accessLevel";
import * as api from "../../../adapters/accessLevel";
import Loader from "../../../utils/loader";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import {axiosError} from "../../../actions/axios";

const AccessLevelSchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
});

export const AccessLevelAdd: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state.accessLevel)

    if (state.error){
        return (
            <div className='w-100 text-center alert alert-danger'>
                {state.error}
            </div>
        )
    }

    return (
            <div className='w-100'>
            <Formik
                initialValues={{
                    title: '',
                }}
                validationSchema={AccessLevelSchema}
                onSubmit={values => {
                    // same shape as initial values
                    dispatch(submitSidebar());
                    api.put(values).then(data => {
                        dispatch(actions.addAccessLevelSuccess(data.data))
                        dispatch(showAlertSuccess(data.data.title + ' à bien été ajouter !'))
                        dispatch(closeSidebar());
                    }).catch(error => dispatch(axiosError(error)))
                }}
            >
                {({ errors, touched }) => (
                    <Form className="p-3">
                        <div className="form-floating mb-3">
                            <Field
                                className={'form-control mb-2 ' + (errors.title && touched.title ? 'is-invalid' : '')}
                                name="title"
                                id={"title"}
                            />
                            <label htmlFor="title">
                                Titre <span className={'required'}>*</span>
                            </label>
                            {errors.title && touched.title ? (<div className='text-danger form-text' >{errors.title}</div>) : null}
                        </div>
                        <button className='btn btn-outline-primary w-100' type="submit">Ajouter</button>
                    </Form>
                )}
            </Formik>
            </div>
    )


}

export default AccessLevelAdd;