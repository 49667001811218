export const ADD_TEMPLATE_APPLIED = 'ADD_TEMPLATE_APPLIED';
export const ADD_TEMPLATE_APPLIED_SUCCESS = 'ADD_TEMPLATE_APPLIED_SUCCESS';
export const ADD_TEMPLATE_APPLIED_FAILURE = 'ADD_TEMPLATE_APPLIED_FAILURE';

export const ADD_TEMPLATE_APPLIED_INFORMATION = 'ADD_TEMPLATE_APPLIED_INFORMATION';
export const ADD_TEMPLATE_APPLIED_INFORMATION_SUCCESS = 'ADD_TEMPLATE_APPLIED_INFORMATION_SUCCESS';
export const ADD_TEMPLATE_APPLIED_INFORMATION_FAILURE = 'ADD_TEMPLATE_APPLIED_INFORMATION_FAILURE';

export const EDIT_TEMPLATE_APPLIED = 'EDIT_TEMPLATE_APPLIED';
export const EDIT_TEMPLATE_APPLIED_SUCCESS = 'EDIT_TEMPLATE_APPLIED_SUCCESS';
export const EDIT_TEMPLATE_APPLIED_FAILURE = 'EDIT_TEMPLATE_APPLIED_FAILURE';

export const FETCH_TEMPLATE_APPLIES = 'FETCH_TEMPLATE_APPLIES';
export const FETCH_TEMPLATE_APPLIES_SUCCESS = 'FETCH_TEMPLATE_APPLIES_SUCCESS';
export const FETCH_TEMPLATE_APPLIES_FAILURE = 'FETCH_TEMPLATE_APPLIES_FAILURE';

export const SHOW_TEMPLATE_APPLIED = 'SHOW_TEMPLATE_APPLIED';
export const SHOW_TEMPLATE_APPLIED_SUCCESS = 'SHOW_TEMPLATE_APPLIED_SUCCESS';
export const SHOW_TEMPLATE_APPLIED_FAILURE = 'SHOW_TEMPLATE_APPLIED_FAILURE';
