import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { ABSENCE_SETTINGS } from "../../../../../constants/rightSidebar";

import * as api from "../../../../../adapters/absence"
import { axiosError } from "../../../../../actions/axios";
import { openSidebar } from "../../../../../actions/rightSidebar";

import format from "../../../../../utils/locale";

export interface CounterProps {
    n: {
        start: string,
        end: string,
        tookBetween: {
            start: string,
            end: string,
        },
        cp: {
            acquired: number,
            taken: number,
            balance: number,

        }
    },
    n1: {
        start: string,
        end: string,
        tookBetween: {
            start: string,
            end: string,
        }
        cp: {
            acquired: number,
            taken: number,
            balance: number,
        }
    }
    n2: {
        start: string,
        end: string,
        tookBetween: {
            start: string,
            end: string,
        }
        cp: {
            acquired: number,
            taken: number,
            balance: number,
        }
    }
}

export const SalaryAbsenceCounter:React.FC<{ counter?: CounterProps, setCounter: React.Dispatch<any> }> = ({ counter, setCounter }) => {
    const dispatch = useDispatch();

    const salary = useSelector((state: RootStateOrAny) => state.salary.single);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true)
        api.counter(salary.id).then(data => {
            setCounter(data.data);
            setIsLoading(false);
        }).catch(error => dispatch(axiosError(error)))
    }, [salary])

    return (
        <div className="p-3 mb-3">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <div className="table-responsive">
                        <table className={'table mb-0 align-middle table-borderless table-striped placeholder wave'}>
                            <thead>
                                <tr>
                                    <th>
                                        <button
                                            onClick={() => dispatch(openSidebar(ABSENCE_SETTINGS))}
                                            className={'btn btn-light me-2'}
                                        >
                                            <i className={'bi bi-gear'}> </i>
                                        </button>
                                    </th>
                                    <th>
                                        Acquis
                                    </th>
                                    <th>
                                        Pris
                                    </th>
                                    <th>
                                        Solde
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        Année en cours (N)
                                        {counter &&
                                            <>
                                                <div className="clearfix"></div>
                                                <span className="form-text">
                                                    Acquis : Cumul des droits du {!!counter && <>{format(new Date(counter?.n.start), 'dd/MM/uuuu')} au {format(new Date(counter?.n.end), 'dd/MM/uuuu')}</>}
                                                </span>
                                                <div className="clearfix"></div>
                                                <span className="form-text">
                                                    Pris : Cumul des consommés du {!!counter && <>{format(new Date(counter?.n.tookBetween.start), 'dd/MM/uuuu')} au {format(new Date(counter?.n.tookBetween.end), 'dd/MM/uuuu')}</>}
                                                </span>
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n.cp.acquired}
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n.cp.taken}
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n.cp.balance}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Année précédente (N-1)
                                        {counter &&
                                            <>
                                                <div className="clearfix"></div>
                                                <span className="form-text">
                                                Acquis : Cumul des droits du {!!counter && <>{format(new Date(counter?.n1.start), 'dd/MM/uuuu')} au {format(new Date(counter?.n1.end), 'dd/MM/uuuu')}</>}
                                                </span>
                                                <div className="clearfix"></div>
                                                <span className="form-text">
                                                Pris : Cumul des consommés du {!!counter && <>{format(new Date(counter?.n1.tookBetween.start), 'dd/MM/uuuu')} au {format(new Date(counter?.n1.tookBetween.end), 'dd/MM/uuuu')}</>}
                                                </span>
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n1.cp.acquired}
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n1.cp.taken}
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n1.cp.balance}
                                    </td>
                                </tr>
                                <tr className={'d-none'}>
                                    <td>
                                        Solde restant (N-2)
                                        <div className="clearfix"></div>
                                        <span className="form-text">
                                        Acquis : Cumul des droits du {!!counter && <>{format(new Date(counter?.n2.start), 'dd/MM/uuuu')} au {format(new Date(counter?.n2.end), 'dd/MM/uuuu')}</>}
                                        </span>
                                        <div className="clearfix"></div>
                                        <span className="form-text">
                                        Pris : Cumul des consommés du {!!counter && <>{format(new Date(counter?.n2.tookBetween.start), 'dd/MM/uuuu')} au {format(new Date(counter?.n2.tookBetween.end), 'dd/MM/uuuu')}</>}
                                        </span>
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n2.cp.acquired}
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n2.cp.taken}
                                    </td>
                                    <td>
                                        {isLoading ? <div className={'line'}> </div> : counter?.n2.cp.balance}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex-grow-0 text-center ps-3">
                    <h4>
                        <b>Solde global</b>
                    </h4>
                    <div className="p-3 bg-light rounded-pill mb-2">
                        <div className="clearfix"> </div>
                        {counter ? counter.n.cp.balance + counter.n1.cp.balance : 0}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalaryAbsenceCounter;