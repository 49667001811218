import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {BookingOffer, BookingOfferApplicant, BookingOffers} from "../../../../models/booking/offer";
import format from "../../../../utils/locale";
import {Granted} from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight"
import * as apiOffer from "../../../../adapters/booking/offer"
import * as api from "../../../../adapters/booking/offer/applicant"
import {cancelBookingOfferSuccess, editBookingOfferSuccess} from "../../../../actions/booking/offer";
import {cancelBookingOffer, refresh} from "../../../../actions/calendar";
import {closeSidebar} from "../../../../actions/rightSidebar";

const BookingOfferSidebarShow:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const [offer, setOffer] = useState<BookingOffer>(state.rightSidebar.data.offer);
    const [canceling, setCanceling] = useState(false);
    const dispatch = useDispatch();
    const _apply = (offer: BookingOffer) => {
        api.apply(offer.id).then((data) => {
            setOffer(data.data);
            dispatch(editBookingOfferSuccess(data.data))
        })
    }

    const _cancelOffer = () => {
        setCanceling(true)
        apiOffer.disable(offer.id).then((data) => {
            dispatch(cancelBookingOfferSuccess(offer))
            dispatch(cancelBookingOffer(offer))
            dispatch(closeSidebar())
            setCanceling(false)
        })
    }

    const _cancel = (offer: BookingOffer, applicant: BookingOfferApplicant) => {
        api.cancel(offer.id, applicant.id).then((data) => {
            setOffer(data.data);
            dispatch(editBookingOfferSuccess(data.data))
        })
    }

    const _validate = (applicant: BookingOfferApplicant) => {
        api.validate(offer.id, applicant.id).then(data => {
            setOffer(data.data);
            dispatch(editBookingOfferSuccess(data.data))
            dispatch(refresh())
        })
    }
    const _invalidate = (applicant: BookingOfferApplicant) => {
        api.invalidate(offer.id, applicant.id).then(data => {
            setOffer(data.data);
            dispatch(editBookingOfferSuccess(data.data))
            dispatch(refresh())
        })
    }

    return <div className={'p-1 p-md-3'}>
        <div className="border border-1 shadow-sm mb-3 p-1 p-md-3">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <span>
                        {offer.applicants.find(a => a.validated) ? <span className={'text-success'}><i className={'bi bi-check2-circle'}> </i> Créneau attribué à {offer.applicants.find(a => a.validated)!.salary.title}</span> : <span className={'text-warning'}><i className={'bi bi-clock-history'}> </i> Créneau non attribué</span>}
                    </span>
                </div>
                <div className="flex-grow-0">
                    <div className="btn-group">
                        <button className={'btn btn-sm me-2'} disabled={canceling}>
                            <i className={'text-primary bi bi-pencil'}> </i>
                        </button>
                        <button className={'btn btn-sm'} onClick={() => _cancelOffer()} disabled={canceling}>
                            {canceling ? <div className="spinner-border spinner-border-sm text-danger" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : <i className={'text-danger bi bi-dash-circle'}> </i>}
                        </button>
                    </div>
                </div>
            </div>
            <div className="clearfix"> </div>
            <span className={'me-3 fw-bold'}>
                <i className={'bi bi-calendar text-primary'}> </i> {format(new Date(offer.beginAt), 'E dd MMMM uuuu HH:mm')} <i className="mx-2 bi bi-arrow-left-right"> </i> {format(new Date(offer.endAt), 'HH:mm')}
            </span>
            <span>
                <i className={'bi bi-shop text-primary'}> </i> {offer.company.title}
            </span>
            <div className="clearfix"> </div>
            <span>
                <i className={'bi bi-people text-primary'}> </i> {offer.applicants.length} Postulants
            </span>
            <div className="clearfix"> </div>
            <span>
                <i className={'bi bi-people text-primary'}> </i> {offer.salaries.length} Participants
            </span>
            <div className="clearfix"> </div>
            <span className={'text-light-s mb-2'}>
                {offer.description}
            </span>
            <div className="d-flex">
                {offer.salaries.find(s => s.id === state.auth.user.currentSalary.id) && !offer.applicants.find(a => a.validated) && (!offer.applicants.find(a => a.salary.id === state.auth.user.currentSalary.id) ? <button onClick={() => _apply(offer)} className={'btn flex-grow-1 btn-outline-success'}>
                    <i className={'bi bi-check'}> </i> Postuler
                </button> : <button onClick={() => _cancel(offer, offer.applicants.find(a => a.salary.id === state.auth.user.currentSalary.id)!)} className={'btn flex-grow-1 btn-primary'}>
                    <i className={'bi bi-check2'}> </i> Déjà postulé
                </button>)}
            </div>
        </div>
        {Granted(accessRights.EDIT_BOOKING) && <>
            <h4>
                <i className={'bi bi-people'}> </i> Postulants
            </h4>
        <ul className={'list-group list-group-flush'}>
            {!!offer.applicants.length ? offer.applicants.map((applicant, index) => <li key={index} className={'list-group-item'}>
                <div className="d-flex align-items-center">
                    {applicant.validated && <div className={'flex-grow-0 fs-4 pe-3'}>
                        <i className={'bi bi-check2-circle text-success'}> </i>
                    </div>}
                    <div className="flex-grow-1">
                        <h4 className={'mb-1'}>
                            {applicant.salary.title} | {applicant.salary.information?.job.title}
                        </h4>
                        <div className="clearfix"> </div>
                        <span className={'text-light-s'}>
                           Participation le {format(new Date(applicant.createdAt), "E dd MMMM uuuu")} à {format(new Date(applicant.createdAt), "HH:mm")}
                        </span>
                    </div>
                    <div className="flex-grow-0">
                        {!applicant.validated ?
                            <button onClick={() => _validate(applicant)} className={'btn btn-outline-primary'}>
                                <i className={'bi bi-check'}> </i> Valider la participation
                            </button> : <>
                                <button onClick={() => _invalidate(applicant)} className={'btn btn-outline-danger'}>
                                    <i className={'bi bi-dash-circle'}> </i> Annuler
                                </button>
                            </>}
                    </div>
                </div>
            </li>) : <li className={'list-group-item'}><i className={'bi bi-search'}> </i> Aucun postulant</li>}
        </ul>
            <h4>
                <i className={'bi bi-people'}> </i> Participants
            </h4>
            <ul className={'list-group list-group-flush'}>
                {offer.salaries.map(s => <li className={'list-group-item'}>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {s.title}
                        </div>
                        <div className="flex-grow-0">
                            {offer?.applicants.find(a => a.id === s.id) ? <><i className={"bi bi-check-lg text-success"}></i> A postulé</> : <><i className={"bi bi-x-lg text-danger"}></i> N'a pas postulé</>}
                        </div>
                    </div>
                </li>)}
            </ul>
        </>}
    </div>
}

export default BookingOfferSidebarShow;