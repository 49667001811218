export const ADD_SYNTHESIS = 'ADD_SYNTHESIS';
export const ADD_SYNTHESIS_SUCCESS = 'ADD_SYNTHESIS_SUCCESS';
export const ADD_SYNTHESIS_FAILURE = 'ADD_SYNTHESIS_FAILURE';

export const EDIT_SYNTHESIS = 'EDIT_SYNTHESIS';
export const EDIT_SYNTHESIS_SUCCESS = 'EDIT_SYNTHESIS_SUCCESS';
export const EDIT_SYNTHESIS_FAILURE = 'EDIT_SYNTHESIS_FAILURE';

export const FETCH_SYNTHESES = 'FETCH_SYNTHESES';
export const FETCH_SYNTHESES_SUCCESS = 'FETCH_SYNTHESES_SUCCESS';
export const FETCH_SYNTHESES_FAILURE = 'FETCH_SYNTHESES_FAILURE';

export const UPDATE_SYNTHESES = 'UPDATE_SYNTHESES';
export const UPDATE_SYNTHESES_SUCCESS = 'UPDATE_SYNTHESES_SUCCESS';

export const SHOW_SYNTHESIS = 'SHOW_SYNTHESIS';
export const SHOW_SYNTHESIS_SUCCESS = 'SHOW_SYNTHESIS_SUCCESS';
export const SHOW_SYNTHESIS_FAILURE = 'SHOW_SYNTHESIS_FAILURE';