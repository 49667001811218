import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {closeSidebar, openSidebar} from "../../../actions/rightSidebar";
import format from "../../../utils/locale";
import * as eventApi from "../../../adapters/event";
import * as exceptionApi from "../../../adapters/event/exception";
import {TYPE_EVENT, TYPE_EVENT_EXCEPTION} from "../../../constants/event";
import {REPORTING_SHOW_WEEK} from "../../../constants/rightSidebar";
import * as reportingApi from "../../../adapters/reporting";
import {refreshSingleReporting} from "../../../actions/reporting";
import {refresh, refreshResource} from "../../../actions/calendar";
import {showAlertSuccess} from "../../../actions/alert";

const EventCancel:React.FC = () => {

    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const [canceling, setCanceling] = useState(false)
    const dispatch = useDispatch();

    const cancel = () => {
        setCanceling(true);

        switch (data.eventType)
        {
            case TYPE_EVENT:
                eventApi.cancelOnce(data.eventId, {
                    start: data.start,
                    end: data.start,
                    accountingType: data.accountingType,
                    salary: data.salary.id,
                    company: data.company.id,
                    localisation: data.localisation?.id,
                    activity: data.activity?.id,
                    exdate: data.start
                })
                    .then(() => cancelSuccess())
                break;
            case TYPE_EVENT_EXCEPTION:
                exceptionApi.cancel(data.exceptionId)
                    .then(() => cancelSuccess())
                break;
        }
    }

    const cancelSuccess = () => {
        dispatch(showAlertSuccess('Créneau supprimé'))

        if (data.next){
            switch (data.next){
                case REPORTING_SHOW_WEEK:
                    reportingApi.fetch({
                        start: data.data.start,
                        end: data.data.end,
                        options: {
                            salaries: [data.salary.id]
                        }
                    }).then(resp => {
                        dispatch(refreshSingleReporting(resp.data[0]))
                        dispatch(openSidebar(data.next, data.data))
                    })
                    break;
            }
        }else{
            if (data.resourceId && data.parentId && data.salary){
                dispatch(refreshResource(data.resourceId, data.parentId, data.salary))
            }else{
                dispatch(refresh());
            }

            dispatch(closeSidebar());
        }
    }

    return <>
        <div className="p-1 p-md-3 h-100 d-flex align-items-center">
            <div className="flex-column col-12">
                <div className="col-12 mb-3 text-center">
                    <h4>
                        Êtes vous sur de vouloir supprimer ce créneau ?
                    </h4>
                    <p className={'text-light-s'}>
                        {format(data.start, 'E dd MMM uuuu HH:mm')} <i className={"bi bi-arrow-left-right mx-2"}></i> {format(data.end, 'E dd MMM uuuu HH:mm')}
                    </p>
                </div>
                <div className="row">
                    <div className="col">
                        <button disabled={canceling} className={"btn btn-light w-100"} onClick={() => dispatch(closeSidebar())}>
                            <i className={'bi bi-x'}></i> Annuler
                        </button>
                    </div>
                    <div className="col">
                        <button disabled={canceling} className={"btn btn-light w-100"} onClick={() => cancel()}>
                            <i className={'bi bi-dash-circle text-danger'}></i> Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default EventCancel;