import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import DatePicker from "react-datepicker";
import { Offcanvas } from "bootstrap";

import * as models from "../../../models/report";

import { getMonthOption, getMonthOptions, range } from "../../../constants/global";

import * as api from "../../../adapters/report";
import * as actions from "../../../actions/report";

import validationClass from "../../../utils/validationClass";
import DateCustomInput from "../../../utils/customInput";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import FormError from "../../../utils/formError";
import SmallLoader from "../../../utils/loader/small";
import format from "../../../utils/locale";

const ReportAdd:React.FC = () => {
    const dispatch = useDispatch();

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const [overlapping, setOverlapping] = useState<models.Reports>([])
    const [isValid, setValid] = useState(false)
    const [validating, setValidating] = useState(false)
    const [offcanvas, setOffcanvas] = useState<Offcanvas>()

    useEffect(() => {
        const el = document.getElementById('offcanvasReportAdd')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    const formik = useFormik({
        initialValues: {
            start: firstDay,
            end: lastDay,
            month: lastDay.getMonth() + 1,
            year: lastDay.getFullYear(),
            isForecast: false,
        },
        validationSchema: Yup.object().shape({
            start: Yup.date().required(),
            end: Yup.date().required(),
            month: Yup.number().required(),
            year: Yup.number().required(),
            isForecast: Yup.boolean().required(),
        }),
        onSubmit: (values, formikHelpers) => {
            api.create(values).then(resp => {
                dispatch(actions.addReportSuccess(resp.data))
                formik.setSubmitting(false)
                window.location.href = `#/report/${resp.data.id}`
                close()
            }).catch(error => {
                console.log(error)
            })
        }
    });

    useEffect(() => {
        setValid(false);
        if (formik.values.start && formik.values.end && formik.values.year && formik.values.month){
            setValidating(true);
            setOverlapping([]);
            api.isValid({month: Number(formik.values.month), year: Number(formik.values.year), start: format(formik.values.start, "uuuu-MM-dd"), end: format(formik.values.end, "uuuu-MM-dd")})
                .then(resp => {
                    setOverlapping(resp.data.overlapping)
                    setValid(!resp.data.overlapping.length);
                    setValidating(false);
                })
        }
    }, [formik.values.start, formik.values.end, formik.values.year, formik.values.month])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    return (
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasReportAdd" aria-labelledby="offcanvasReportAddLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasReportAddLabel">Ajouter un rapport</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div className="offcanvas-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            label={'Mois'}
                            enableReinitialize={true}
                            options={[...getMonthOptions()]}
                            value={getMonthOption(String(formik.values.month))}
                            onChange={(c) => formik.setFieldValue('month', c?.value)}
                            required={true}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'month'}/>
                    </div>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            label={'Année'}
                            enableReinitialize={true}
                            options={range(formik.values.year - 5, lastDay.getFullYear() + 5).map(y => ({
                                label: String(y),
                                value: y.toString()
                            }))}
                            value={{label: String(formik.values.year), value: formik.values.year.toString()}}
                            onChange={(c) => formik.setFieldValue('year', c?.value)}
                            required={true}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'year'}/>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md mb-3">
                            <DatePicker
                                disabled={formik.isSubmitting}
                                className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                                customInput={<DateCustomInput label={'Date de début'} icon={'bi bi-calendar-check text-primary'}/>}
                                selected={formik.values.start}
                                onChange={(date: Date) => {
                                    formik.setFieldValue(`start`, date)
                                    formik.setFieldTouched('start', true)
                                }}
                                selectsStart
                                startDate={formik.values.start}
                                maxDate={formik.values.end}
                                dateFormat={"E dd MMM uuuu"}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={"start"} />
                        </div>
                        <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                            <i className={'bi bi-arrow-left-right mx-1'}> </i>
                        </div>
                        <div className="col-12 col-md mb-3">
                            <DatePicker
                                disabled={formik.isSubmitting}
                                className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                                customInput={<DateCustomInput label={'Date de fin'} icon={'bi bi-calendar-check text-primary'}/>}
                                selected={formik.values.end}
                                onChange={(date: Date) => {
                                    formik.setFieldValue(`end`, date)
                                    formik.setFieldTouched('end', true)
                                }}
                                dateFormat={"E dd MMM uuuu"}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={"end"} />
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="isForecast" onChange={(e) => formik.setFieldValue("isForecast", e.target.checked)} />
                                <label className="form-check-label" htmlFor="isForecast">
                                    Inclure le prévisionnel
                                </label>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'isForecast'}/>
                    </div>
                    {validating && <div className={'mb-3'}>
                        <SmallLoader /> Validation en cours...
                    </div>}
                    {!!overlapping.length && <div className={'col-12'}>
                        <ul className={'list-group list-group-flush mb-3'}>
                            <li className="list-group-item form-text text-danger">
                                <i className={'bi bi-exclamation-circle'}></i> Les rapports ci-dessous se chevauchent :
                            </li>
                            {overlapping.map(o => <li className={"list-group-item form-text text-danger"}>
                                {getMonthOption(String(o.month))?.label} {o.year} du {format(new Date(o.start), "dd/MM/uuuu")} au {format(new Date(o.end), "dd/MM/uuuu")}
                            </li>)}
                        </ul>
                    </div>}
                    <button type={'submit'} disabled={!isValid} className={'btn btn-light w-100'}>
                        {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check'}></i> Valider</>}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ReportAdd;