import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {EventOffer, EventOfferApplication, EventOffers} from "../../../../models/event/offer";
import format from "../../../../utils/locale";
import * as accessRights from "../../../../constants/accessRight"
import * as api from "../../../../adapters/event/offer/applicant"
import * as eventOfferApi from "../../../../adapters/event/offer"
import {editEventOfferSuccess, showEventOfferSuccess} from "../../../../actions/event/offer";
import {refresh} from "../../../../actions/calendar";
import Loader from "../../../../utils/loader";
import {useParams} from "react-router-dom";
import {setPageTitle} from "../../../../actions/header";
import {Granted} from "../../../../security/granted";

const EventOfferShow:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const [offer, setOffer] = useState<EventOffer>()
    const [isLoading, setIsLoading] = useState(true)
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle('Offre de créneau'))
        eventOfferApi.show(Number(params.id)).then(data => {
            setOffer(data.data)
            dispatch(showEventOfferSuccess(data.data))
            setIsLoading(false)
        })
    }, [])

    const _apply = (offer: EventOffer) => {
        api.apply(offer.id).then((data) => {
            setOffer(data.data);
            dispatch(editEventOfferSuccess(data.data))
        })
    }
    const _cancel = (offer: EventOffer, applicant: EventOfferApplication) => {
        api.cancel(offer.id, applicant.id).then((data) => {
            setOffer(data.data);
            dispatch(editEventOfferSuccess(data.data))
        })
    }
    const _validate = (applicant: EventOfferApplication) => {
        api.validate(offer!.id, applicant.id).then(data => {
            setOffer(data.data);
            dispatch(editEventOfferSuccess(data.data))
            dispatch(refresh())
        })
    }
    const _invalidate = (applicant: EventOfferApplication) => {
        api.invalidate(offer!.id, applicant.id).then(data => {
            setOffer(data.data);
            dispatch(editEventOfferSuccess(data.data))
            dispatch(refresh())
        })
    }

    if (isLoading){
        return <Loader />
    }

    if (!offer){
        return <span className={"display-3"}> Offre non trouvé </span>
    }

    return <div className={'p-1 p-md-3'}>
        <div className="border border-1 shadow-sm mb-3 p-1 p-md-3">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <p>
                        {offer.applications.find(a => a.validated) ? <span className={'text-success'}><i className={'bi bi-check2-circle'}> </i> Créneau attribué à {offer.applications.find(a => a.validated)!.salary.title}</span> : <span className={'text-warning'}><i className={'bi bi-clock-history'}> </i> Créneau non attribué</span>}
                    </p>
                </div>
                <div className="flex-grow-0">
                    <div className="btn-group">
                        <button className={'btn btn-sm me-2'}>
                            <i className={'text-primary bi bi-pencil'}> </i>
                        </button>
                        <button className={'btn btn-sm'}>
                            <i className={'text-danger bi bi-dash-circle'}> </i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="clearfix"> </div>
            <p className={'fw-bold'}>
                <i className={'bi bi-calendar text-primary'}> </i> {format(new Date(offer.start), 'E dd MMMM uuuu HH:mm')} <i className="mx-2 bi bi-arrow-left-right"> </i> {format(new Date(offer.end), 'HH:mm')}
            </p>
            <div className="clearfix"> </div>
            {offer.activity && <p>
                <i className={'bi bi-bookmark text-primary'}> </i> {offer.activity.title}
            </p>}
            <div className="clearfix"> </div>
            {offer.localisation && <p>
                <i className={'bi bi-geo-alt text-primary'}> </i> {offer.localisation.title}
            </p>}
            <div className="clearfix"> </div>
            <p>
                <i className={'bi bi-shop text-primary'}> </i> {offer.company.title}
            </p>
            <div className="clearfix"> </div>
            <p className={'text-light-s mb-2'}>
                {offer.description}
            </p>
            {Granted(accessRights.APPLY_BOOKING_OFFER, undefined, offer) && <div className="d-flex">
                {offer.salaries.find(s => s.id === state.auth.user.currentSalary.id) && !offer.applications.find(a => a.validated) && (!offer.applications.find(a => a.salary.id === state.auth.user.currentSalary.id) ?
                    <button onClick={() => _apply(offer)} className={'btn flex-grow-1 btn-outline-success'}>
                        <i className={'bi bi-check'}> </i> Postuler
                    </button> : <button
                        onClick={() => _cancel(offer, offer.applications.find(a => a.salary.id === state.auth.user.currentSalary.id)!)}
                        className={'btn flex-grow-1 btn-primary'}>
                        <i className={'bi bi-check2'}> </i> Déjà postulé
                    </button>)}
            </div>}
        </div>
        {Granted(accessRights.EDIT_BOOKING_OFFER, undefined, offer) && <>
            <h4>
                <i className={'bi bi-people'}> </i> Postulants
            </h4>
        <ul className={'list-group list-group-flush'}>
            {!!offer.applications.length ? offer.applications.map((applicant, index) => <li key={index} className={'list-group-item'}>
                <div className="d-flex align-items-center">
                    {applicant.validated && <div className={'flex-grow-0 fs-4 pe-3'}>
                        <i className={'bi bi-check2-circle text-success'}> </i>
                    </div>}
                    <div className="flex-grow-1">
                        <h4 className={'mb-1'}>
                            {applicant.salary.title} | {applicant.salary.information?.job.title}
                        </h4>
                        <div className="clearfix"> </div>
                        <span className={'text-light-s'}>
                           Participation le {format(new Date(applicant.createdAt), "E dd MMMM uuuu")} à {format(new Date(applicant.createdAt), "HH:mm")}
                        </span>
                    </div>
                    <div className="flex-grow-0">
                        {!applicant.validated ?
                            <button onClick={() => _validate(applicant)} className={'btn btn-outline-primary'}>
                                <i className={'bi bi-check'}> </i> Valider la participation
                            </button> : <>
                                <button onClick={() => _invalidate(applicant)} className={'btn btn-outline-danger'}>
                                    <i className={'bi bi-dash-circle'}> </i> Annuler
                                </button>
                            </>}
                    </div>
                </div>
            </li>) : <li className={'list-group-item'}><i className={'bi bi-search'}> </i> Aucun postulants</li>}
        </ul>
            <h4>
                <i className={'bi bi-people'}> </i> Participants
            </h4>
            <ul className={'list-group list-group-flush'}>
                {offer.salaries.map(s => <li className={'list-group-item'}>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {s.title}
                        </div>
                        <div className="flex-grow-0">
                            {offer?.applications.find(a => a.id === s.id) && <><i className={"bi bi-check-lg text-success"}></i> A postulé</>}
                        </div>
                    </div>
                </li>)}
            </ul>
        </>}
    </div>
}

export default EventOfferShow;