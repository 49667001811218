import React, {useEffect, useState} from "react";
import {Salary} from "../../../../models/salary";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {Timetable} from "../../../../models/timetable";
import * as api from "../../../../adapters/timetable";
import {Offcanvas} from "bootstrap";
import SmallLoader from "../../../../utils/loader/small";
import SalarySelector from "../../../salary/selector";

const TimetableImport:React.FC<{targetSalary: Salary, targetTimetable: Timetable, setTimetable: React.Dispatch<any>}> = (props) => {

    const [_salary, setSalary] = useState<Salary>()
    const [timetable, setTimetable] = useState<any>()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    useEffect(() => {
        const el = document.getElementById('offcanvasTimetableImport')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function importTimetable(){
        setIsSubmitting(true)
        api.copy(timetable, props.targetTimetable.id).then(resp => {
            props.setTimetable(resp.data)
            close()
            setIsSubmitting(false);
        })
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTimetableImport" aria-labelledby="offcanvasTimetableImportLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasTimetableImportLabel">Importer un emploi du temps</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className="col-12 mb-3">
                <SalarySelector
                    fetchOptions={true}
                    required={true}
                    onChange={(s: Salary) => {
                        setSalary(s)
                        setTimetable(undefined)
                    }}
                />
            </div>
            {_salary && <div className={'col-12 mb-3'}>
                <BootstrapSelect
                    required={true}
                    enableReinitialize={true}
                    onChange={(c) => setTimetable(c?.value)}
                    fetchEntity={'timetable'}
                    fetchParams={{salary: _salary.id}}
                    label={'Timetable à importer'}
                />
            </div>}
            {_salary && timetable && <>
                <button className={'btn btn-light w-100'} onClick={() => importTimetable()}>
                    {isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </>}
        </div>
    </div>
}

export default TimetableImport;