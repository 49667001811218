import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/template/applied/exception";
import {closeSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import {SingleSalarySelected, SingleSalarySelector} from "../../../../../utils/salarySelector/single";
import validationClass from "../../../../../utils/validationClass";
import FormError from "../../../../../utils/formError";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../../../utils/customInput";
import {setHours, setMinutes} from "date-fns";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {AccountingTypes, getAccountingTypeChoice} from "../../../../../constants/booking";
import {Company} from "../../../../../models/companies";
import {Localisation} from "../../../../../models/localisation";
import {Choice} from "../../../../../utils/form/model";
import {Activity} from "../../../../../models/activity";
import {Salary} from "../../../../../models/salary";
import {showAlertSuccess} from "../../../../../actions/alert";
import {refresh, refreshResource} from "../../../../../actions/calendar";


const TemplateAppliedExceptionAdd: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const [displaySalarySelector, setDisplaySalarySelector] = useState<boolean>(false);
    const [salary, setSalary] = useState<Salary>(state.salary)
    const [company, setCompany] = useState<Company>(state.company)

    const formik = useFormik({
        initialValues: {
            salary: state.salary.id,
            beginAt: state.start,
            endAt: state.end,
            accountingType: state.accountingType,
            company: state.company.id,
            localisation: state.localisation?.id,
            activity: state.activity?.id,
        },
        validationSchema: Yup.object().shape({
            salary: Yup.number().required('Veuillez préciser un collaborateur'),
            beginAt: Yup.date().required('Veuillez préciser une date de début'),
            endAt: Yup.date().required('Veuillez préciser une date de fin'),
            accountingType: Yup.number().required("Veuillez préciser un type d'heure"),
            company: Yup.number().required('Veuillez préciser un collaborateur'),
            localisation: Yup.number().nullable(),
            activity: Yup.number().nullable(),
        }),
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar());
            if (state.isAppliedException){
                // api.edit(values, state.templateAppliedId, state.bookingInstanceId, state.exceptionInstanceId)
                //     .then(() => submitSuccess());
            }else{
                // api.create(values, state.templateAppliedId, state.bookingInstanceId, state.instanceToken)
                //     .then(() => submitSuccess());
            }
        }
    })

    const submitSuccess = () => {
        if (state.resourceId){
            dispatch(refreshResource(state.resourceId, state.parentId, state.salary))
        }else{
            dispatch(refresh());
        }
        dispatch(showAlertSuccess('Créneau mis à jour.'))
        dispatch(closeSidebar());
    }


    const handleSalaryChange = (salary: Salary) => {
        setSalary(salary)
        let _values = {...formik.values}

        if (state.company && salary.companies.find(c => c.id === state.company.id)){
            _values.company = salary.companies.find(c => c.id === state.company.id)!.id
        }else{
            _values.company = salary.companies[0].id
        }

        if (!formik.values.localisation && salary?.defaultLabels?.find(d => d.company.id === _values.company)){
            _values.localisation = salary.defaultLabels.find(d => d.company.id === _values.company)!.localisation.id
        }

        if (!formik.values.activity && salary?.defaultLabels?.find(d => d.company.id === _values.company)){
            _values.activity = salary.defaultLabels.find(d => d.company.id === _values.company)!.activity.id
        }

        formik.setValues(_values)
        setCompany(salary.companies[0])
    }

    if (!formik.values.salary || displaySalarySelector){
        return <SingleSalarySelector
            onSubmit={(data: Salary) => {
                handleSalaryChange(data)
                formik.setFieldValue('salary', data.id)
                setDisplaySalarySelector(false)
            }}
            onClose={() => setDisplaySalarySelector(false)}
            initialValue={formik.values.salary}
            required={true}
        />;
    }

    return <>
        <div className={'p-1 p-md-3'}>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3">
                    <SingleSalarySelected
                        onDelete={() => formik.setFieldValue('salary', null)}
                        onOpen={() => {
                            setDisplaySalarySelector(true)
                            formik.setFieldTouched('salary', true)
                        }}
                        initialValue={formik.values.salary}
                        initialSalary={salary}
                        validationClass={validationClass(formik.errors, formik.touched, 'salary')}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-12 col-md mb-3">
                                    <DatePicker
                                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                                        customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                                        selected={formik.values.beginAt}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(`beginAt`, date)
                                            formik.setFieldTouched('beginAt', true)
                                        }}
                                        selectsStart
                                        startDate={formik.values.beginAt}
                                        maxDate={formik.values.endAt}
                                        showTimeSelect
                                        timeIntervals={15}
                                        timeCaption="Heure de début"
                                        dateFormat="E dd MMM uuuu HH:mm"
                                        popperPlacement={"top-end"}
                                        showPopperArrow={false}
                                    />
                                </div>
                                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                                </div>
                                <div className="col-12 col-md mb-3">
                                    <DatePicker
                                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'endAt')}
                                        customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                                        showTimeSelect
                                        selected={formik.values.endAt}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(`endAt`, date)
                                            formik.setFieldTouched('endAt', true)
                                        }}
                                        selectsEnd
                                        startDate={formik.values.beginAt}
                                        endDate={formik.values.endAt}
                                        minDate={formik.values.beginAt}
                                        minTime={setHours(setMinutes(new Date(),
                                                new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getMinutes() : 0
                                            ),
                                            new Date(formik.values.endAt).setHours(0,0,0,0) === new Date(formik.values.beginAt).setHours(0,0,0,0) ? new Date(formik.values.beginAt).getHours() : 0
                                        )}
                                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                        timeIntervals={15}
                                        dateFormat="E dd MMM uuuu HH:mm"
                                        popperPlacement={"top-end"}
                                        showPopperArrow={false}
                                    />
                                </div>
                            </div>
                            <FormError touched={formik.touched} errors={formik.errors} field={'beginAt'} />
                            <FormError touched={formik.touched} errors={formik.errors} field={'endAt'} />
                        </div>
                        <div className="col-12 mb-3">
                            <BootstrapSelect
                                label={"Type d'heure"}
                                value={getAccountingTypeChoice(formik.values.accountingType)}
                                options={AccountingTypes}
                                onChange={(choice) => formik.setFieldValue('accountingType', choice!.value)}
                                required={true}
                            />
                        </div>
                        <div className="col-md-12 mb-3">
                            <BootstrapSelect
                                required={true}
                                //options={salary?.companies.map((company: Company) => ({label: company.title, value: company.id!})) || []}
                                fetchEntity="company"
                                fetchParams={{ salaries: [formik.values.salary] }}
                                value={company ? {label: company.title, value: company.id!} : undefined}
                                onChange={(choice) => {
                                    formik.setFieldValue('company', choice?.value)
                                    formik.setFieldValue('localisation', null)
                                    formik.setFieldValue('activity', null)
                                    setCompany(salary!.companies.find((company: Company) => company.id === choice?.value)!)
                                    if (formik.values.hasOwnProperty('weekLetters')) {
                                        formik.setFieldValue('weekLetters', []);
                                    }
                                }}
                                label={'Point de vente'}
                                labelIcon={'bi bi-shop text-primary me-2'}
                                className={validationClass(formik.errors, formik.touched, 'company')}
                            />
                            <FormError touched={formik.touched} errors={formik.errors} field={'company'} />
                        </div>
                        {formik.values.company && <>
                            <div className="col-md-6 mb-3">
                                <BootstrapSelect
                                    required={false}
                                    enableReinitialize={true}
                                    //options={company ? company.localisations!.map((localisation: Localisation) => ({label: localisation.title, value: localisation.id!})) : []}
                                    fetchEntity="localisation"
                                    fetchParams={{ companies: [formik.values.company] }}
                                    value={formik.values.localisation && company?.localisations ? company.localisations!.map((localisation: Localisation) => ({label: localisation.title, value: localisation.id!})).find((choice: Choice) => choice.value === formik.values.localisation) : undefined}
                                    className={validationClass(formik.errors, formik.touched, 'localisation')}
                                    onChange={(e) => formik.setFieldValue('localisation', e?.value)}
                                    label={'Localisation'}
                                    labelIcon={'bi bi-geo-alt text-primary me-2'}
                                />
                                <FormError touched={formik.touched} errors={formik.errors} field={'localisation'} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <BootstrapSelect
                                    required={false}
                                    enableReinitialize={true}
                                    //options={company ? company.activities!.map((activity: Activity) => ({label: activity.title, value: activity.id!})) : []}
                                    fetchEntity="activity"
                                    fetchParams={{ companies: [formik.values.company] }}
                                    value={formik.values.activity && company?.activities ? company.activities!.map((activity: Activity) => ({label: activity.title, value: activity.id!})).find((choice: Choice) => choice.value === formik.values.activity) : undefined}
                                    className={validationClass(formik.errors, formik.touched, 'activity')}
                                    onChange={(e) => formik.setFieldValue('activity', e?.value)}
                                    label={'Activité'}
                                    labelIcon={'bi bi-bookmark-plus text-primary me-2'}
                                />
                                <FormError touched={formik.touched} errors={formik.errors} field={'activity'} />
                            </div>
                        </>}
                        <div className="col-12 mb-3">
                            <div className="form-floating">
                                    <textarea name="description" onChange={formik.handleChange} className="form-control" placeholder="Description"
                                              id="description"></textarea>
                                <label htmlFor="description">Description</label>
                                <FormError touched={formik.touched} errors={formik.errors} field={'description'} />
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-outline-primary w-100"><i className="bi bi-check"></i>Valider</button>
            </form>
        </div>
    </>
}

export default TemplateAppliedExceptionAdd;