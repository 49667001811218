import React, {useEffect, useState} from "react";
import {Team, Teams} from "../../../../models/team";
import * as api from "../../../../adapters/team";
import {Modal} from "bootstrap";
import Loader from "../../../../utils/loader";
import arrayEquals from "../../../../utils/arrayEqual";
import SmallLoader from "../../../../utils/loader/small";


const CalendarTeamSelector:React.FC<{teams: Teams, setTeams: (teams: Teams) => void;}> = (props) => {

    const {teams, setTeams} = props;

    const [_teams, _setTeams] = useState<Teams>(teams)
    const [allTeams, setAllTeams] = useState<Teams>(teams)
    const [loading, setLoading] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [hasLoaded, setHasLoaded] = useState<boolean>(false)

    useEffect(() => {
        let controller = new AbortController();

        let el = document.getElementById("teamModal")!

        el.addEventListener("shown.bs.modal", open)
        el.addEventListener("hidden.bs.modal", close)

        if (isOpen && !hasLoaded){
            setLoading(true)
            api.list({}, controller.signal).then(data => setAllTeams(data.data))
                .then(() => setLoading(false))
                .then(() => setHasLoaded(true))
        }

        return () => {
            controller.abort();
            el.removeEventListener("shown.bs.modal", open)
            el.removeEventListener("hidden.bs.modal", close)
        }
    }, [isOpen])

    function open(){
        setIsOpen(true)
    }

    function close(){
        setIsOpen(false)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, t: Team) => {
        if (e.target.checked){
            _setTeams([..._teams, t])
        }else{
            _setTeams([..._teams.filter(_t => _t.id !== t.id)])
        }
    }

    const validate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!arrayEquals(teams, _teams)){
            setTeams([..._teams])
        }
    }

    return <div className="modal fade" id="teamModal" tabIndex={-1} aria-labelledby="teamModalLabel"
                aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="teamModalLabel">Équipes</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body position-relative">
                    {loading && <div className={'col-12 d-flex align-items-center'}><SmallLoader /> <span className={'form-text ms-3'}>Chargement des équipes...</span></div>}
                    {!loading && <ul className={'list-group list-group-flush'}>
                        {allTeams.map(t => <li className={'list-group-item'}>
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                       onChange={(e) => handleChange(e, t)}
                                       defaultChecked={!!_teams.find(_t => t.id === _t.id)}
                                       type="checkbox"
                                       id={String('team' + t.id!)}
                                />
                                <label className="form-check-label" htmlFor={String('team' + t.id!)}>
                                    {t.title}
                                </label>
                            </div>
                        </li>)}
                    </ul>}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal" aria-label="Close">
                        <i className={'bi bi-x'}> </i> Fermer
                    </button>
                    <button type="button" className="btn btn-light" onClick={(e) => validate(e)} data-bs-dismiss="modal" aria-label="Close">
                        <i className={'bi bi-check-circle text-success'}> </i> Valider
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default CalendarTeamSelector;