import {post, get} from "../xhr";
import * as models from "../../models/booking";

export function list(requestData: {start: string, end: string}){
    return post('booking/list', requestData);
}

export function resourcesList(requestData: {start: string, end: string}){
    return post('booking/resources/list', requestData);
}

export function edit(requestData: models.Booking, id: number){
    return post('booking/edit/' + id, requestData);
}

export function editDay(requestData: models.Booking, id: number){
    return post('booking/edit_day/' + id, requestData);
}

export function create(requestData: models.Booking){
    return post('booking/create',  requestData);
}

export function show(id: string){
    return get('booking/show/' + id);
}

export function showInstance(id: string, token: string){
    return get('booking/show/instance/' + id);
}

export interface BookingExceptionPayload {
    beginAt: Date,
    endAt: Date,
    accountingType: number,
    company: number,
    localisation: number,
    activity: number,
    salary?: number,
    substitute?: number,
    description?: string,
    labourDayWorked?: boolean,
    comment?: string,
}

export function createException(requestData: BookingExceptionPayload, bookingId: number, instanceToken:string){
    return post(`booking/exception/create/${bookingId}/${instanceToken}`,  requestData);
}

export function editException(requestData: BookingExceptionPayload, bookingExceptionId: number){
    return post(`booking/exception/edit/${bookingExceptionId}`,  requestData);
}

export function split(requestData: BookingExceptionPayload, bookingId: number){
    return post(`booking/split/${bookingId}`,  requestData);
}

export function splitDay(requestData: BookingExceptionPayload, bookingId: number){
    return post(`booking/split_day/${bookingId}`,  requestData);
}

export function splitBetween(requestData: BookingExceptionPayload, bookingId: number, start: string, end: string){
    return post(`booking/split_between/${bookingId}/${start}/${end}`,  requestData);
}

export function substituteException(instanceId: number, substituteId: number){
    return get(`booking/exception/substitution/${instanceId}/${substituteId}`)
}

export function cancel(instanceId: number){
    return get(`booking/cancel/${instanceId}`)
}

export function cancelAfter(instanceId: number, day: string){
    return post(`booking/cancel_after/${instanceId}`, {day: day})
}

export function cancelOnce(instanceId: number, instanceToken: string){
    return get(`booking/exception/cancel/${instanceId}/${instanceToken}`)
}
