import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/auth"
import * as api from "../../../adapters/auth";

const Confirmation: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny)  => state.auth);
    const params: {token: string, redirect?: string} = useParams();
    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    useEffect(() => {
        dispatch(actions.confirm());
        api.confirm(params.token).then((data) => {

            if (params.redirect){
                localStorage.setItem('next_route', '/password')
            }

            dispatch(actions.confirmSuccess(data.data))
            setIsConfirm(true);
        }).catch((error) => {
            dispatch(actions.confirmFailure(error.response.data.message))
        })
    }, [])

    if (state.error){
        return <>
            <div className="container">
                <div className="col-12 p-1 p-md-3">
                    <div className="card shadow-sm p-2 p-md-5">
                        <div className="alert alert-danger">
                            {state.error}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    if (state.isLoading){
        return <>
            <div className="container">
                <div className="col-12 p-1 p-md-3">
                    <div className="card shadow-sm p-2 p-md-5">
                        <div className="alert alert-info">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> Verification du token en cours
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    if (isConfirm){
        return <>
            <div className="container">
                <div className="col-12 p-1 p-md-3">
                    <div className="card shadow-sm p-2 p-md-5">
                        <div className="alert alert-success">
                            <i className={'bi bi-check2-circle'}> </i> Votre compte est activé !
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    return <></>
}

export default Confirmation;