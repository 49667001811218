import React, { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Salary } from "../../../../../../models/salary";

import * as api from "../../../../../../adapters/salary/file"
import { showAlertDanger, showAlertSuccess } from "../../../../../../actions/alert";
import { closeSidebar } from "../../../../../../actions/rightSidebar";
import { editSalarySuccess } from "../../../../../../actions/salary";

import StyledDropzone from "../../../../../../utils/fileUpload";

const SalaryDocumentStockedAdd: React.FC = () => {
    const dispatch = useDispatch();

    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single)

    const [files, setFiles] = useState<File[]>([])
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = () => {
        if (!files.length){
            dispatch(showAlertDanger('Veuillez ajouter un fichier'))
            return false;
        }

        setSubmitting(true);

        let formData = new FormData();

        formData.append('file', files[0]);

        api.add(formData, salary.id!).then((data) => {
            dispatch(editSalarySuccess(data.data));
            dispatch(showAlertSuccess('Document ajouté'))
            dispatch(closeSidebar());
        })
    }

    return (
        <>
            <div className="p-3">
                <div className="mb-3">
                    <StyledDropzone style={{height: 200}}  handleUploadedFile={(files: File[]) => setFiles(files)} />
                </div>
                
                <button type={'button'} onClick={() => handleSubmit()} disabled={!files.length || submitting} className={'btn w-100 btn-primary'}>
                    {submitting ? <div className="spinner-grow text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : 'Enregistrer'}
                </button>
            </div>
        </>
    )
}

export default SalaryDocumentStockedAdd;