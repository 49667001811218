import React, {useEffect, useState} from "react";
import * as api from "../../../../../adapters/salary/template"
import {Salary} from "../../../../../models/salary";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Loader from "../../../../../utils/loader";
import {SalaryTemplate, SalaryTemplates} from "../../../../../models/salary/template";
import {
    fetchSalaryTemplate,
    fetchSalaryTemplateSuccess,
    removeSalaryTemplateSuccess
} from "../../../../../actions/salary/template";
import {openSidebar} from "../../../../../actions/rightSidebar";
import {
    DUPLICATE_SALARY_TEMPLATE,
    LIST_SALARY_TEMPLATE,
    SHOW_SALARY_TEMPLATE
} from "../../../../../constants/rightSidebar";
import format from "../../../../../utils/locale";
import SalaryTemplateAdd from "../add";
import SalaryTemplateEdit from "../edit";

const SalaryTemplateList: React.FC = () => {

    const dispatch = useDispatch();
    const templates:SalaryTemplates = useSelector((state:RootStateOrAny) => state.salary.single.templates);
    const [isLoading, setIsLoading] = useState(false);
    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single)

    const [formOpen, setFormOpen] = useState<'edit'|'add'|'duplicate'|undefined>();
    const [formOpenIndex, setFormOpenIndex] = useState<number>();

    useEffect(() => {
            setIsLoading(true);
            dispatch(fetchSalaryTemplate());
            api.list(salary.id).then(data => {
                dispatch(fetchSalaryTemplateSuccess(data.data))
                setIsLoading(false)
            })
    }, [salary.id])

    const [canceling, setCanceling] = useState<SalaryTemplate>()
    const deactivate = (t: SalaryTemplate) => {
        setCanceling(t);
        api.deactivate(t.id).then(() => {
            dispatch(removeSalaryTemplateSuccess(t))
            setCanceling(undefined)
        })
    }

    if (isLoading) return <Loader />

    return <div className={'p-1 p-md-3 flex-grow-1'}>
            <div className={'col-12'}>
                <ul className={'list-group list-group-flush'}>
                    {templates?.map((t, index) => <li key={index} className={'list-group-item'}>
                        {formOpen === 'edit' && formOpenIndex === index && <SalaryTemplateEdit
                            templates={templates}
                            template={t}
                            salary={salary}
                            setFormOpen={setFormOpen}
                        />}
                        {!(formOpenIndex === index && formOpen === 'edit')  && <div className="col-12">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h4>
                                        {t.title}
                                    </h4>
                                    <div className="text-light-s">
                                        A partir du <b>{format(new Date(t.start || new Date()))}</b> {t.end ? <>jusqu'au <b>{format(new Date(t.end))}</b></> : <></>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <button className={'btn btn-light'}
                                            onClick={() => dispatch(openSidebar(SHOW_SALARY_TEMPLATE, {
                                                id: t.id,
                                                salary: salary,
                                                next: LIST_SALARY_TEMPLATE
                                            }))}>
                                        <i className={'bi bi-eye text-success'}></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button className={'btn btn-light'} onClick={() => {
                                        setFormOpen('edit')
                                        setFormOpenIndex(index)
                                    }}>
                                        <i className={'bi bi-pencil text-primary'}></i>
                                    </button>
                                </div>
                                <div className="col-auto"
                                     onClick={() => dispatch(openSidebar(DUPLICATE_SALARY_TEMPLATE, {
                                         template: t,
                                         templates: templates,
                                         lastEnd: maxDate(templates.filter(t => !!t.end).map(t => new Date(t.end!))),
                                         salary: salary,
                                         next: LIST_SALARY_TEMPLATE
                                     }))}>
                                    <button className={'btn btn-light'}>
                                        <i className={'bi bi-arrow-repeat text-primary'}></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button className={'btn btn-light'} disabled={!!canceling}
                                            onClick={() => deactivate(t)}>
                                        {canceling?.id === t.id ?
                                            <div className="spinner-border spinner-border-sm text-danger" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> : <i className={'bi bi-dash-circle text-danger'}></i>}
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </li>)}
                </ul>
                {formOpen === undefined && <button className={'btn btn-outline-primary w-100'}
                                                   onClick={() => setFormOpen('add')}>
                    <i className={'bi bi-plus'}></i> Ajouter un emploi du temps
                </button>}
                {formOpen === 'add' && <SalaryTemplateAdd
                    setFormOpen={setFormOpen}
                    lastEnd={templates.filter(t => !!t.end).length ? maxDate(templates.filter(t => !!t.end).map(t => new Date(t.end!))) : new Date()}
                    salary={salary}
                    templates={templates}
                />}
        </div>
    </div>
}

const maxDate = (dates: Date[]) => new Date(Math.max(...dates.map(Number)));


export default SalaryTemplateList;