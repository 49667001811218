import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import FileSaver from "file-saver";

import * as models from "../../../models/report";
import { Company } from "../../../models/companies";

import * as types from "../../../constants/report";
import { getMonthOption } from "../../../constants/global";

import * as api from "../../../adapters/report";

import getUser from "../../../security/getUser";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import SmallLoader from "../../../utils/loader/small";
import FormError from "../../../utils/formError";

const ReportDownload:React.FC = () => {
    const report: models.Report = useSelector((state: RootStateOrAny) => state.report.single)

    const [query, setQuery] = useState("")
    const [initialValues, setInitialValues] = useState<{ type: string, salaries: string[], companies: number[] }>({
        type: types.PAYROLL_EXPORT,
        salaries: [],
        companies: [],
    })

    function updateSalaries(checked: boolean)
    {
        setInitialValues({
            type: formik.values.type,
            salaries: checked ? report.salaries.map(s => String(s.id)) : [],
            companies: formik.values.companies
        });

        let checkboxes = document.querySelectorAll('.salary-checkbox') as NodeListOf<HTMLInputElement>

        for (let i = 0; i < checkboxes.length; i++){
            checkboxes[i].checked = checked;
        }
    }

    function handleCompanyChange(value?: number)
    {
        setInitialValues({
            type: formik.values.type,
            salaries: report.salaries.filter(s => formik.values.salaries.includes(String(s.id)) && (!value || s.companies.map(c => c.id).includes(value))).map(s => String(s.id)),
            companies: value ? [value] : []
        });
    }

    useEffect(() => {
        setInitialValues({
            type: types.PAYROLL_EXPORT,
            salaries: [],
            companies: [],
        })
    }, [report])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            type: Yup.string().required(),
            salaries: Yup.array().of(Yup.number()).min(1),
            companies: Yup.array().of(Yup.number()),
        }),
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api._export(values, report.id).then(resp => {
                let newBlob = new Blob([resp.data], { type: resp.headers["content-type"] })

                let filename = '';

                switch (values.type){
                    case types.PAYROLL_EXPORT:
                        filename = `variables-de-paie-${getMonthOption(String(report.month))?.label}-${report.year}.xlsx`
                        break;
                    case types.COUNTER_EXPORT:
                        filename = `compteurs-${getMonthOption(String(report.month))?.label}-${report.year}.xlsx`
                        break;
                    case types.EXPENSE_EXPORT:
                        filename = `notes-de-frais-${getMonthOption(String(report.month))?.label}-${report.year}.xlsx`
                        break;
                    case types.SIGNATURE_EXPORT:
                        filename = `émargements-${getMonthOption(String(report.month))?.label}-${report.year}.zip`
                        break;
                    case types.SILAE_AJ_EXPORT:
                        filename = `activité-journalière-${getMonthOption(String(report.month))?.label}-${report.year}.csv`
                        break;
                    case types.SILAE_ABSENCE_EXPORT:
                        filename = `absences-${getMonthOption(String(report.month))?.label}-${report.year}.csv`
                        break;
                    case types.SILAE_GLOBAL:
                        filename = `instructions-matrices-${getMonthOption(String(report.month))?.label}-${report.year}.xlsx`
                        break;
                }

                FileSaver.saveAs(newBlob, filename)
                formikHelpers.setSubmitting(false)
            })
        }
    });

    return (
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasReportExport" aria-labelledby="offcanvasReportExportLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasReportExportLabel">
                    Télécharger le rapport de {getMonthOption(String(report.month))?.label} {report.year}
                </h5>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>

            <div className="offcanvas-body">
                <form className={"d-flex flex-column h-100"} onSubmit={formik.handleSubmit}>
                    <div className="col-12 mb-3 flex-grow-0">
                        <div className="row row-cols-5">
                            <div className="col mb-3">
                                <button type={"button"} className={"btn d-flex flex-column align-items-center h-100 w-100 text-center" + (formik.values.type === types.PAYROLL_EXPORT ? " btn-success text-white " : " btn-light ")} onClick={() => formik.setFieldValue("type", types.PAYROLL_EXPORT)}>
                                    <span className="flex-grow-1 d-flex align-items-center text-center">
                                        <span className="col-12">
                                            <img src={process.env.PUBLIC_URL + "/logo/logo-blue.png"} alt="" className="w-50 mx-auto"/>
                                        </span>
                                    </span>
                                    <div className={'flex-grow-0'}>
                                        Variables de paie
                                    </div>
                                </button>
                            </div>
                            <div className="col mb-3">
                                <button type={"button"} className={"btn d-flex flex-column align-items-center h-100 w-100 text-center" + (formik.values.type === types.SIGNATURE_EXPORT ? " btn-success text-white " : " btn-light ")} onClick={() => formik.setFieldValue("type", types.SIGNATURE_EXPORT)}>
                                    <span className="flex-grow-1 d-flex align-items-center text-center">
                                        <span className="col-12">
                                            <img src={process.env.PUBLIC_URL + "/logo/logo-blue.png"} alt="" className="w-50 mx-auto"/>
                                        </span>
                                    </span>
                                    <div className={'flex-grow-0'}>
                                        Émargements
                                    </div>
                                </button>
                            </div>
                            <div className="col mb-3">
                                <button type={"button"} className={"btn d-flex flex-column align-items-center h-100 w-100 text-center" + (formik.values.type === types.COUNTER_EXPORT ? " btn-success text-white " : " btn-light ")} onClick={() => formik.setFieldValue("type", types.COUNTER_EXPORT)}>
                                    <span className="flex-grow-1 d-flex align-items-center text-center">
                                        <span className="col-12">
                                            <img src={process.env.PUBLIC_URL + "/logo/logo-blue.png"} alt="" className="w-50 mx-auto"/>
                                        </span>
                                    </span>
                                    <div className={'flex-grow-0'}>
                                        Compteurs
                                    </div>
                                </button>
                            </div>
                            <div className="col mb-3">
                                <button type={"button"} className={"btn d-flex flex-column align-items-center h-100 w-100 text-center" + (formik.values.type === types.EXPENSE_EXPORT ? " btn-success text-white " : " btn-light ")} onClick={() => formik.setFieldValue("type", types.EXPENSE_EXPORT)}>
                                    <span className="flex-grow-1 d-flex align-items-center text-center">
                                        <span className="col-12">
                                            <img src={process.env.PUBLIC_URL + "/logo/logo-blue.png"} alt="" className="w-50 mx-auto"/>
                                        </span>
                                    </span>
                                    <div className={'flex-grow-0'}>
                                        Notes de frais
                                    </div>
                                </button>
                            </div>
                            <div className="col mb-3">
                                <button type={"button"} className={"btn d-flex flex-column h-100 align-items-center w-100 text-center" + (formik.values.type === types.SILAE_AJ_EXPORT ? " btn-success text-white " : " btn-light ")} onClick={() => formik.setFieldValue("type", types.SILAE_AJ_EXPORT)}>
                                    <span className="flex-grow-1 d-flex align-items-center text-center">
                                        <span className="col-12">
                                            <img src={process.env.PUBLIC_URL + "/img/silae/silae-logo.png"} alt="" className="w-50 mx-auto"/>
                                        </span>
                                    </span>
                                    <div className={'flex-grow-0'}>
                                        Activité journalière
                                    </div>
                                </button>
                            </div>
                            <div className="col mb-3">
                                <button type={"button"} className={"btn d-flex flex-column h-100 align-items-center w-100 text-center" + (formik.values.type === types.SILAE_ABSENCE_EXPORT ? " btn-success text-white " : " btn-light ")} onClick={() => formik.setFieldValue("type", types.SILAE_ABSENCE_EXPORT)}>
                                    <span className="flex-grow-1 d-flex align-items-center text-center">
                                        <span className="col-12">
                                            <img src={process.env.PUBLIC_URL + "/img/silae/silae-logo.png"} alt="" className="w-50 mx-auto"/>
                                        </span>
                                    </span>
                                    <div className={'flex-grow-0'}>
                                        Absences
                                    </div>
                                </button>
                            </div>
                            <div className="col mb-3">
                                <button type={"button"} className={"btn d-flex flex-column h-100 align-items-center w-100 text-center" + (formik.values.type === types.SILAE_GLOBAL ? " btn-success text-white " : " btn-light ")} onClick={() => formik.setFieldValue("type", types.SILAE_GLOBAL)}>
                                    <span className="flex-grow-1 d-flex align-items-center text-center">
                                        <span className="col-12">
                                            <img src={process.env.PUBLIC_URL + "/img/silae/silae-logo.png"} alt="" className="w-50 mx-auto"/>
                                        </span>
                                    </span>
                                    <div className={'flex-grow-0'}>
                                        Matrice
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-3 flex-grow-0">
                        <BootstrapSelect
                            label={'Établissements'}
                            placeholder={"Tous les établissements"}
                            options={getUser().currentSalary.companies.map((c: Company) => ({label: c.title, value: c.id!}))}
                            onChange={c => handleCompanyChange(c?.value ? Number(c.value) : undefined)}
                        />
                    </div>

                    <div className="col-12 mb-3 text-center flex-grow-0">
                        <div className="btn-group">
                            <button type={'button'} className="btn btn-light" onClick={() => updateSalaries(true)}>
                                <i className={"bi bi-check text-success"}></i> Tout cocher
                            </button>
                            <button type={'button'} className="btn btn-light" onClick={() => updateSalaries(false)}>
                                <i className={"bi bi-x text-danger"}></i> Tout décocher
                            </button>
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'salaries'} />
                    </div>

                    <div className="col-12 flex-grow-0">
                        <div className="input-group">
                            <span className="btn btn-light">
                                <i className={'bi bi-search'}></i>
                            </span>
                            <input onChange={e => setQuery(e.target.value)} placeholder={'Rechercher un collaborateur...'} type="text" className={'form-control'}/>
                        </div>
                    </div>

                    <ul className="flex-grow-1 overflow-auto list-group list-group-flush mb-3">
                        {report.salaries.filter(s => (!query || (s.title.toLowerCase().indexOf(query.toLowerCase())  > -1)) && (!formik.values.companies.length || s.companies.map(c => c.id).includes(formik.values.companies[0]))).map(s => <li className={"list-group-item"}>
                            <div className="form-check form-switch">
                                <input name={"salaries"} value={s.id} onChange={formik.handleChange} className="form-check-input salary-checkbox" type="checkbox" id={`salary${s.id}`} />
                                <label className="form-check-label" htmlFor={`salary${s.id}`}>{s.title}</label>
                            </div>
                        </li>)}
                    </ul>
                    
                    <button disabled={formik.isSubmitting} type={'submit'} className={'btn btn-light w-100 flex-grow-0'}>
                        {formik.isSubmitting ? <><SmallLoader /> Le téléchargement peut prendre quelques minutes</> : <><i className={'bi bi-download'}></i> Télécharger</>}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ReportDownload;