import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import validationClass from "../../../utils/validationClass";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import * as types from "../../../constants/expense";
import * as Yup from "yup";
import FormError from "../../../utils/formError";
import DateCustomInput from "../../../utils/customInput";
import * as globals from "../../../constants/global";
import * as api from "../../../adapters/expense";
import * as fileApi from "../../../adapters/expense/file";
import * as actions from "../../../actions/expense";
import DatePicker from "react-datepicker";
import SmallLoader from "../../../utils/loader/small";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {closeSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import * as accessRights from "../../../constants/accessRight";
import * as models from "../../../models/expense";
import {mileageAmountCalculator} from "../utils";
import StyledDropzone from "../../../utils/fileUpload";
import {Salary} from "../../../models/salary";
import * as accessRightsTypes from "../../../constants/accessRight";
import SalarySelector from "../../salary/selector";
import {Granted} from "../../../security/granted";

const ExpenseAdd:React.FC = () => {

    const dispatch = useDispatch();
    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data);

    const [dynamicAmountProps, setDynamicAmountProps] = useState<{value?: number, disabled: boolean}>({disabled: false})
    const [files, setFiles] = useState<File[]>([])

    const [initialValues, setInitialValues] = useState<any>({
        title: "",
        type: types.TYPE_MEAL,
        dueDate: null,
        amount: "",
        currency: globals.CURRENCY_EUR,
        comment: "",
        shop: "",
        clientMeal: false,
    });

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
        title: Yup.string().required(),
        type: Yup.number().required(),
        dueDate: Yup.date().required(),
        amount: Yup.number().required(),
        currency: Yup.number().required(),
        comment: Yup.string().nullable(),
        shop: Yup.string().nullable(),
        clientMeal: Yup.boolean().required(),
    }));

    useEffect(() => {
        if (Granted(accessRights.EDIT_EXPENSE, data?.salary?.id)){
            let _initialValues: any = {...initialValues};
            let _validationSchema: any = {...validationSchema.fields};

            _initialValues.salary = data?.salary?.id || null;
            _initialValues.status = types.STATUS_ACCEPTED;

            _validationSchema.salary = Yup.number().required()
            _validationSchema.status = Yup.number().required()


            setInitialValues({..._initialValues})
            setValidationSchema(Yup.object().shape({..._validationSchema}))
        }
    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit:(values, formikHelpers) => {
            api.create(values).then(resp => {
                dispatch(actions.addExpenseSuccess(resp.data))
                return resp.data;
            })
                .then((data: models.Expense) => {
                    let formData = new FormData();

                    for(let i in files){
                        formData.append(i, files[i]);
                    }

                    return fileApi.add(formData, data.id).then(resp => dispatch(actions.editExpenseSuccess(resp.data))).then(() => true)
                })
                .then(() => dispatch(showAlertSuccess('Note de frais ajouté')))
                .then(() => dispatch(closeSidebar()))
        }
    })

    useEffect(() => {
        if (formik.values.type === types.TYPE_MILEAGE){
            setDynamicAmountProps({value: mileageAmountCalculator(types.VEHICLE_CAR, types.VEHICLE_ENGINE_THERMAL, 0, types.VEHICLE_CAR_POWER_3), disabled: true})
            formik.setFieldValue('amount', 0)
        }else{
            setDynamicAmountProps({disabled: false})
        }
    }, [formik.values.type])

    useEffect(() => {
        if (formik.values.type === types.TYPE_MILEAGE){
            setDynamicAmountProps({value: mileageAmountCalculator(formik.values.vehicle, formik.values.vehicleEngine, formik.values.distance, formik.values.vehiclePower), disabled: true})
            formik.setFieldValue('amount', mileageAmountCalculator(formik.values.vehicle, formik.values.vehicleEngine, formik.values.distance, formik.values.vehiclePower))
        }
    }, [formik.values.vehicle, formik.values.vehicleEngine, formik.values.distance, formik.values.vehiclePower])

    const handleTypeChange = (type: number) => {
        let _fields: string[] = ['title', 'type', 'dueDate', 'amount', 'currency', 'comment'];
        if (Granted(accessRights.EDIT_EXPENSE, formik.values.salary)){
            _fields.push('status', 'salary')
        }

        switch (type)
        {
            case types.TYPE_MEAL:
                _fields.push('shop', 'clientMeal');
                break;
            case types.TYPE_MILEAGE:
                _fields.push('vehicle', 'vehicleEngine', 'vehiclePower', 'distance');
                break;
        }

        mapFields(_fields, {type: type})
    }

    const handleVehicleChange = (vehicle: number) => {
        let _fields: string[] = ['title', 'type', 'dueDate', 'amount', 'currency', 'comment', 'distance', 'vehicle', 'vehicleEngine'];

        if (Granted(accessRights.EDIT_EXPENSE, formik.values.salary)){
            _fields.push('status', 'salary')
        }

        if ([types.VEHICLE_CAR, types.VEHICLE_MOTORCYCLE].includes(vehicle)){
            _fields.push('vehiclePower')
        }

        mapFields(_fields, {vehicle: vehicle})
    }

    const mapFields = (fields: string[], override?: {[key: string]: any}) => {

        let _values: any = {...formik.values}

        if (override){
            for (const [key, value] of Object.entries(override)) {
                _values[key] = value;
            }
        }

        let newValues:any = {};
        let newValidationSchema:any = {};

        for (let i in fields)
        {
            switch (fields[i])
            {
                case 'title':
                    newValues.title = _values.title || "";
                    newValidationSchema.title = Yup.string().required();
                    break;
                case 'type':
                    newValues.type = _values.type || types.TYPE_MEAL;
                    newValidationSchema.type = Yup.number().required();
                    break;
                case 'dueDate':
                    newValues.dueDate = _values.dueDate || null;
                    newValidationSchema.dueDate = Yup.date().required();
                    break;
                case 'amount':
                    newValues.amount = _values.amount || 0;
                    newValidationSchema.amount = Yup.number().required();
                    break;
                case 'currency':
                    newValues.currency = _values.currency || types.getCurrencyChoices()[0].value;
                    newValidationSchema.currency = Yup.number().required();
                    break;
                case 'comment':
                    newValues.comment = _values.comment || "";
                    newValidationSchema.comment = Yup.string().nullable();
                    break;
                case 'distance':
                    newValues.distance = _values.distance || 0;
                    newValidationSchema.distance = Yup.number().required();
                    break;
                case 'vehicle':
                    newValues.vehicle = _values.vehicle || types.VEHICLE_CAR;
                    newValidationSchema.vehicle = Yup.number().required();
                    break;
                case 'vehicleEngine':
                    newValues.vehicleEngine = _values.vehicleEngine || types.VEHICLE_ENGINE_THERMAL;
                    newValidationSchema.vehicleEngine = Yup.number().required();
                    break;
                case 'vehiclePower':
                    newValues.vehiclePower = _values.vehiclePower || types.getVehiclePowerChoices(_values.vehicle || types.VEHICLE_CAR)[0].value;
                    newValidationSchema.vehiclePower = Yup.number().required();
                    break;
                case 'shop':
                    newValues.shop = _values.shop || null;
                    newValidationSchema.type = Yup.string().nullable();
                    break;
                case 'clientMeal':
                    newValues.clientMeal = _values.clientMeal || false;
                    newValidationSchema.clientMeal = Yup.boolean().required();
                    break;
                case 'status':
                    newValues.status = _values.status || 2;
                    newValidationSchema.status = Yup.number().required();
                    break;
                case 'salary':
                    newValues.salary = _values.salary || null;
                    newValidationSchema.salary = Yup.number().required();
                    break;
            }
        }

        setValidationSchema(Yup.object().shape({...newValidationSchema}))
        setInitialValues({...newValues})
    }

    return <div className={'p-1 p-md-3'}>
        <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text"
                           id={'title'}
                           name={'title'}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           className={"form-control " + validationClass(formik.errors, formik.touched, "title")}
                    />
                    <label htmlFor="title">
                        Nom de la dépense
                    </label>
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    required={true}
                    value={types.getTypeChoices()[0]}
                    options={types.getTypeChoices()}
                    label={'Type de dépense'}
                    onChange={(choice) => handleTypeChange(Number(choice!.value))}
                    className={validationClass(formik.errors, formik.touched, 'type')}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'type'} />
            </div>
            <div className="col-12 mb-3">
                <DatePicker
                    title={'Date de la dépense'}
                    className={'form-control' + validationClass(formik.errors, formik.touched, 'dueDate')}
                    customInput={<DateCustomInput icon={'bi bi-calendar'} label={"Date de la dépense"} />}
                    onChange={(date: Date) => {
                        formik.setFieldValue('dueDate', date)
                    }}
                    dateFormat={"dd MMM uuuu"}
                    selected={formik.values.dueDate}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'dueDate'} />
            </div>
            {formik.values.type === types.TYPE_MILEAGE && <>
                <div className="col-12 mb-3">
                    <div className="form-check">
                        <input className="form-check-input" onChange={formik.handleChange} value={types.VEHICLE_ENGINE_THERMAL} type="radio" name="vehicleEngine" id="vehicleEngineThermal"  defaultChecked={true}/>
                            <label className="form-check-label" htmlFor="vehicleEngineThermal">
                                Véhicules thermiques, à hydrogène ou hybrides
                            </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={formik.handleChange} value={types.VEHICLE_ENGINE_ELECTRIC} type="radio" name="vehicleEngine" id="vehicleEngineElectric" />
                            <label className="form-check-label" htmlFor="vehicleEngineElectric">
                                Véhicules électriques
                            </label>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        required={true}
                        value={types.getVehicleChoices()[0]}
                        options={types.getVehicleChoices()}
                        label={'Type de véhicule'}
                        onChange={(choice) => handleVehicleChange(Number(choice!.value))}
                        className={validationClass(formik.errors, formik.touched, 'vehicle')}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'vehicle'} />
                </div>
                {[types.VEHICLE_CAR, types.VEHICLE_MOTORCYCLE].includes(formik.values.vehicle) && <>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            required={true}
                            enableReinitialize={true}
                            value={types.getVehiclePowerChoices(formik.values.vehicle)[0]}
                            options={types.getVehiclePowerChoices(formik.values.vehicle)}
                            label={'Puissance du véhicule'}
                            onChange={(choice) => formik.setFieldValue('vehiclePower', choice?.value)}
                            className={validationClass(formik.errors, formik.touched, 'vehiclePower')}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'vehiclePower'} />
                    </div>
                </>}
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="number"
                               id={'distance'}
                               name={'distance'}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className={"form-control " + validationClass(formik.errors, formik.touched, "distance")}
                        />
                        <label htmlFor="distance">
                            Distance (KM)
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'distance'} />
                </div>
            </>}
            <div className="col-12 mb-3">
                <div className="d-flex">
                    <div className="form-floating flex-grow-1">
                        <input type={'number'}
                               step={'any'}
                               id={'amount'}
                               name={'amount'}
                                {...dynamicAmountProps}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className={"form-control " + validationClass(formik.errors, formik.touched, "amount")}
                        />
                        <label htmlFor="amount">
                            Montant
                        </label>
                        <FormError errors={formik.errors} touched={formik.touched} field={'amount'} />
                    </div>
                    <BootstrapSelect
                        label={'Devise'}
                        disabled={true}
                        value={globals.getCurrencyChoices()[0]}
                        options={globals.getCurrencyChoices()}
                        required={true}
                        onChange={(choice) => formik.setFieldValue('currency', choice?.value)}
                    />
                </div>
            </div>
            {formik.values.type === types.TYPE_MEAL && <>
                <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input type="text"
                               id={'shop'}
                               name={'shop'}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className={"form-control " + validationClass(formik.errors, formik.touched, "shop")}
                        />
                        <label htmlFor="shop">
                            Commerçant (facultatif)
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'shop'} />
                </div>
                <div className="col-12 mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="clientMeal" name={'clientMeal'} onChange={formik.handleChange} />
                            <label className="form-check-label" htmlFor="clientMeal">
                                Ceci est un déjeuner client
                            </label>
                    </div>
                </div>
            </>}
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <textarea className="form-control" placeholder="Commentaire"
                              name={'comment'}
                              title={'comment'}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              id="comment"></textarea>
                    <label htmlFor="comment">Commentaire</label>
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'comment'} />
            </div>
            {Granted(accessRights.EDIT_EXPENSE, formik.values.salary) && <>
                <div className="col-12 mb-3">
                    <SalarySelector
                        required={true}
                        value={data?.salary}
                        fetchOptions={true}
                        onChange={(s: Salary) => formik.setFieldValue('salary', s.id)}
                        accessRight={accessRightsTypes.EDIT_EXPENSE}
                    />
                </div>
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        label={'Statut'}
                        options={types.getStatusChoices()}
                        value={types.getStatusChoices()[0]}
                        onChange={(c) => formik.setFieldValue('status', c?.value)}
                        required={true}
                    />
                </div>
            </>}
            <div className="col-12 mb-3">
                <h4>
                    Justificatifs
                </h4>
                <StyledDropzone
                    style={{height: 200}}
                    handleUploadedFile={(files: File[]) => setFiles(files)}
                    multiple={true}
                />
            </div>
            <button disabled={formik.isSubmitting} className={'btn btn-light w-100'} type={'submit'}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check-circle text-success'}></i> Valider</>}
            </button>
        </form>
    </div>
}

export default ExpenseAdd;