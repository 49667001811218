import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import {Company} from "../../../../../models/companies";
import {SalaryTemplateEvents} from "../../../../../models/salary/template";
import SalaryTemplateEventEditForm from "./form";

const SalaryTemplateEventEdit:React.FC<{arg?: EventClickArg, week: number, company: Company, handleSubmitSuccess: (ev: any) => any, events: SalaryTemplateEvents}> = (props) => {

    const {arg, company, week, events, handleSubmitSuccess} = props

    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey(prev => prev + 1)
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventDropLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <SalaryTemplateEventEditForm
                key={key}
                eventId={arg.event.extendedProps.event.id}
                start={arg.event.start!}
                end={arg.event.end!}
                daysOfWeek={arg.event.extendedProps.event.daysOfWeek}
                week={week}
                company={company}
                activity={arg.event.extendedProps.event.activity}
                localisation={arg.event.extendedProps.event.localisation}
                handleSubmitSuccess={handleSubmitSuccess}
                events={events}
            />}
        </div>
    </>
}

export default SalaryTemplateEventEdit;