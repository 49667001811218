import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import EmptyList from "../../common/EmptyList";

import { Team } from "../../../models/team";
import { FORM_TEAM_SALARY } from "../../../constants/rightSidebar";

import { openSidebar } from "../../../actions/rightSidebar";
import { editTeamSuccess } from "../../../actions/team";

import * as api from '../../../adapters/team';

import { showAlertDanger } from "../../../actions/alert";

interface IProps {
    teamData: Team;
    fetchTeamsList: () => void;
}

const TeamShow:React.FC<IProps> = ({ teamData, fetchTeamsList }) => {
    const dispatch = useDispatch();
    const { replace } = useHistory();

    const [isDeleting, setIsDeleting] = useState(false)

    const onRemoveSalary = (id: number) => {
        setIsDeleting(true)
        api.removeSalary(teamData.id, id).then(data => {
            dispatch(editTeamSuccess(data.data));
            setIsDeleting(false);
        });
    }

    const onRemoveTeam = async () => {        
        try {
            setIsDeleting(true)
            await api.remove(teamData.id)
            fetchTeamsList()
            replace('/team')
        } catch (error) {
            showAlertDanger(error?.response?.data?.message)
        } finally {
            setIsDeleting(false)
        }
    }

    return (
        <div id={'singleContainer'} className={"d-flex bg-light flex-column bg-white col-lg-9 col-md-8 h-100 px-0"}>
            <div className={'w-100 position-sticky top-0 d-flex'} style={{zIndex: 1000}}>
                <Link to={'/salary'} className={'btn btn-sm btn-light flex-grow-0 d-block d-md-none m-1'}>
                    <i className={'bi bi-chevron-left'}> </i> Retour
                </Link>
            </div>

            <div className="container-fluid py-md-3 py-1">
                <div className="container-fluid d-flex align-items-center py-2 border-bottom border-light">
                    <div className="col">
                        <p className="m-0 fw-bold">
                            Collaborateurs
                        </p>
                    </div>

                    <div className="col d-flex justify-content-end">
                        <button
                            className="btn btn-outline-primary"
                            disabled={isDeleting}
                            onClick={() => dispatch(openSidebar(FORM_TEAM_SALARY, { ...teamData }))}
                        >
                            <i className={'bi bi-plus'}> </i> Ajouter
                        </button>
                        <button
                            className="btn btn-outline-danger ms-2"
                            disabled={isDeleting}
                            onClick={onRemoveTeam}
                        >
                            <i className={'bi bi-trash'}> </i> Supprimer l’équipe
                        </button>
                    </div>
                </div>

                {teamData.salaries!.length === 0 && <EmptyList text="Aucun collaborateur n’est associé à cette équipe" />}
                {teamData.salaries!.length > 0 &&
                    <div className="py-3">
                        {teamData.salaries.map((salary) => (
                            <div key={salary.id} className="container-fluid mb-1 py-2 border rounded">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <Link to={`/salary/${salary.id}`}>
                                            {salary.title}
                                        </Link>
                                        {salary?.information?.job?.title}
                                    </div>

                                    <div className="col d-flex justify-content-end">
                                        <button disabled={isDeleting} onClick={() => onRemoveSalary(salary.id)} className={'btn btn-sm text-danger'}>
                                            <i className={'bi bi-dash-circle'}> </i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default TeamShow;