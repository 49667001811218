import React from "react";
import * as api from "../../../../adapters/booking/group"
import FormError from "../../../../utils/formError";
import DatePicker from "react-datepicker";
import validationClass from "../../../../utils/validationClass";
import DateCustomInput from "../../../../utils/customInput";
import {useFormik} from "formik";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as Yup from 'yup';
import {openSidebar, submitSidebar} from "../../../../actions/rightSidebar";

const BookingGroupAddFromBookingForm:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const initialValues: any = {
        title: "",
        salary: state.rightSidebar.data.salary.id,
        start: state.rightSidebar.data.start,
        finish: state.rightSidebar.data.end,
    }

    const validationSchema: any = Yup.object().shape({
        title: Yup.string().required(),
        salary: Yup.number().required(),
        start: Yup.date().required(),
        finish: Yup.date().required(),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(submitSidebar());
            api.create(values).then(data => {
                let _sidebarData = {...state.rightSidebar.data.data}
                _sidebarData.bookingGroupId = data.data.id;
                dispatch(openSidebar(state.rightSidebar.data.next, {..._sidebarData}))
            })
        }
    })

    return <>
        <form onSubmit={formik.handleSubmit} className={'p-1 p-md-3'}>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="form-floating">
                        <input type="text" onChange={formik.handleChange} name={'title'} className="form-control"/>
                        <label htmlFor="">
                            <i className={'bi bi-body-text text-primary'}> </i> Titre
                        </label>
                    </div>
                    <FormError touched={formik.touched} errors={formik.errors} field={'title'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="input-group mb-3">
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                        title={'Début'}
                        ariaLabelledBy={'bi bi-calendar-check text-primary'}
                        customInput={<DateCustomInput label={'Début de la période'} required={true} />}
                        selected={formik.values.start}
                        onChange={(date) => formik.setFieldValue('start', date)}
                        selectsStart
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        startDate={formik.values.start}
                        endDate={formik.values.finish}
                        dateFormat="E dd MMM uuuu"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                    <span className="input-group-text">
                        <i className={'bi bi-arrow-left-right'}> </i>
                    </span>
                    <DatePicker
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'finish')}
                        title={'Fin'}
                        ariaLabelledBy={'bi bi-calendar-check text-primary'}
                        customInput={<DateCustomInput label={'Fin de la période'} />}
                        selected={formik.values.finish}
                        onChange={(date) => formik.setFieldValue('finish', date)}
                        selectsEnd
                        startDate={formik.values.start}
                        endDate={formik.values.finish}
                        minDate={formik.values.start}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="E dd MMM uuuu"
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                    />
                </div>
                <FormError touched={formik.touched} errors={formik.errors} field={'finish'} />
                <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
            </div>
            <button type={'submit'} className={'btn btn-outline-primary w-100'}>
                <i className={"bi bi-check"}></i> Valider
            </button>
        </form>
    </>
}

export default BookingGroupAddFromBookingForm;