import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/booking/default";
import * as models from "../../../../models/booking/default";
import format from "../../../../utils/locale";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {axiosError} from "../../../../actions/axios";
import Loader from "../../../../utils/loader";
import {openSidebar} from "../../../../actions/rightSidebar";
import {FORM_EDIT_DEFAULT_BOOKING, FORM_NEW_DEFAULT_BOOKING} from "../../../../constants/rightSidebar";

const BookingDefaultList:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const [bookings, setBookings] = useState<models.DefaultBookings>([])

    const dispatch = useDispatch();

    useEffect(() => {
        setBookings([...state.job.single.defaultBookings])
    }, [state.job.single])

    return <div className={'col-12'}>
        <div className="d-flex mb-3">
            <div className="flex-grow-1">
                <h4 className={'mb-1'}>
                    Créneaux template {state.job.single.title}
                </h4>
                <div className="form-text">
                    Modèle de créneaux réutilisables
                </div>
            </div>
            <div className="flex-grow-0">
                <button onClick={() => dispatch(openSidebar(FORM_NEW_DEFAULT_BOOKING, {
                    job: state.job.single
                }))} className={'btn btn-outline-primary'}>
                    <i className={'bi bi-plus'}> </i> Ajouter
                </button>
            </div>
        </div>
        <div className="row">
            {!bookings.length ? <><i className={'bi bi-search'}> </i> Aucun créneau type</> : bookings.map(booking => <div className="col-md-6 mb-3">
                <div className="border border-1 shadow-sm p-1 p-md-3">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h4>
                                {booking.title}
                            </h4>
                            <p>
                               <i className={'bi bi-calendar-check text-primary'}> </i> {format(new Date(booking.beginAt), 'E HH:mm')} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(booking.endAt), 'E HH:mm')}
                            </p>
                            <p>
                               <i className={'bi bi-arrow-repeat text-primary'}> </i> {booking.recurrence.description}
                            </p>
                        </div>
                        <div className="flex-grow-0">
                            <button onClick={() => dispatch(openSidebar(FORM_EDIT_DEFAULT_BOOKING, {
                                booking: booking,
                                job: state.job.single
                            }))} className={'btn text-primary'}>
                                <i className={'bi bi-pencil'}> </i>
                            </button>
                            <button className={'btn text-danger'}>
                                <i className={'bi bi-dash-circle'}> </i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
}

export default BookingDefaultList;