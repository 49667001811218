import * as types from "../../constants/team"
import * as models from "../../models/team"
import replace from "../../utils/arrayReplace"

const team = (state: models.initialStateInterface = models.initialState, action: {type: string, team: models.Team, teams: models.Teams, error: string}) => {

    switch (action.type) {
        case types.FETCH_TEAMS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_TEAMS_SUCCESS:
            return { payload: action.teams, single: state.single, isLoading: false, error: null };
        case types.FETCH_TEAMS_FAILURE:
            return { payload: action.teams, single: state.single, isLoading: false, error: action.error };

        case types.ADD_TEAM:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.ADD_TEAM_SUCCESS:
            return { payload: [...state.payload, action.team], single: action.team, isLoading: false, error: null };

        case types.EDIT_TEAM:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_TEAM_SUCCESS:
            return { payload:  replace(state.payload, action.team), single: action.team, isLoading: false, error: null };
        case types.SHOW_TEAM:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_TEAM_SUCCESS:
            return { payload: state.payload, single: action.team, isLoading: false, error: null };
        case types.SHOW_TEAM_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default team;