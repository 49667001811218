import {Salary} from "../salary";
import {Absence} from "../absence";

export interface SynthesisFields {
    workingTime: number,
    accountedWorkingTime: number,
    absenceAccountedTime: number,
    overlapAbsenceAccountedTime: number,
    paidVacation: number,
    unpaidVacation: number,
    sickLeave: number,
    recovery: number,
    formation: number,
    rtt: number,
    paidElse: number,
    unpaidElse: number,
    compensatedElse: number,
    labourDayWorkingTime: number,
    labourDayNonWorkingTime: number,
    sundayWorkingTime: number,
    nightTime20: number,
    nightTime40: number,
    nightTimeDetails: { maj: "_low"|"_high", day: string, value: number }[],
    overtime: number,
    overtime25: number,
    overtime50: number,
    contractWorkingTime: number,
    monthlyContractWorkingTime: number,
    initialCounter: number,
    counter: number,
    counterSaved: number,
    counterWithdrawn: number,
    counterWithdrawnDescription: number,
    paid: number,
    paidLowMajoration: number,
    paidHighMajoration: number,
    finalCounter: number,
    fullDays: string[],
    presenceDays: string[],
    rate: number,
}

export type SynthesisDay = {
    dt: string,
    planned: any[],
    done: any[],
    nightTimeDetails: { maj: "_low"|"_high", value: number }[],
    absence: Absence,
    delta: number
    isLabourDay: boolean,
    overlapStart: boolean,
}

export type SynthesisDays = SynthesisDay[];
export interface Week {
    weekNumber: number,
    year: number,
    start: string,
    end: string
}
export type Weeks = Week[]
export type SynthesisWeek = SynthesisFields & Week & {overlapWorkingTime: number, overlapAccountedWorkingTime: number, days: SynthesisDays, overlapStart: boolean, overlapEnd: boolean}
export type SynthesisWeeks = SynthesisWeek[];

export type Synthesis = SynthesisFields & { salary: Salary, id: number, weeks: SynthesisWeeks };
export type Syntheses = Synthesis[];

export interface initialStateInterface {
    payload: Syntheses;
    updating: Syntheses;
    single: Synthesis|null;
    weeks: Weeks;
    start: Date;
    end: Date;
    isLoading: boolean;
    error: string|null;
}

export const initialState: initialStateInterface = {
    payload: [],
    updating: [],
    single: null,
    weeks: [],
    start: new Date(),
    end: new Date(),
    isLoading: true,
    error: null
};