import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {RootStateOrAny, useSelector} from "react-redux";
import format from "../../../utils/locale"
import * as Yup from "yup"
import * as type from "../../../constants/absence";
import * as api from "../../../adapters/calendar"
import {APPLICATION_PDF} from "../../../adapters/xhr";
import FileSaver from "file-saver";
import FormError from "../../../utils/formError";
import validationClass from "../../../utils/validationClass";
import BootstrapSelect from "../../../utils/bootstrapSelect";

const CalendarDownload:React.FC = () => {

    const state = useSelector((state: RootStateOrAny)  => state);
    const data = useSelector((state: RootStateOrAny)  => state.rightSidebar.data);
    const [initialValues, setInitialValues] = useState<any>({});
    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({}));

    const range = (start: number, end: number) => {
        let ret = []
        for (let i = start; i <= end; i++){
            ret.push(i)
        }
        return ret;
    }

    useEffect(() => {
        _initForm()
    }, [])

    function getISOWeeks(y: number) {
        let d,
            isLeap;

        d = new Date(y, 0, 1);
        isLeap = new Date(y, 1, 29).getMonth() === 1;

        return d.getDay() === 4 || isLeap && d.getDay() === 3 ? 53 : 52
    }

    const _initForm = () => {

        let type = '2';
        switch (data.view){
            case 'listDay':
            case 'resourceTimelineDay':
                type = '1';
                break;
            case 'listWeek':
            case 'resourceTimelineWeek':
                type = '2';
                break;
            case 'resourceTimelineMonth':
                type = '3';
                break;
        }

        const _initialValues: any = {};
        const _validationSchema: any = {};

        let fields: string[] = [];

        switch (type){
            case '1':
                fields = ['year', 'week', 'day', 'format', 'type', 'company', 'job']
                break;
            case '2':
                fields = ['year', 'week', 'format', 'type', 'company', 'job']
                break;
            case '3':
                fields = ['year', 'month', 'format', 'type', 'company', 'job']
                break;
        }

        for (let i in fields){
            switch (fields[i]) {
                case "type":
                    _initialValues.type = type;
                    _validationSchema.type = Yup.number().required();
                    break;
                case "day":
                    _initialValues.day = Number(data.day) || 1;
                    _validationSchema.day = Yup.number().required();
                    break;
                case "week":
                    _initialValues.week = data.week || format(new Date(), 'w');
                    _validationSchema.week = Yup.number().required();
                    break;
                case "month":
                    _initialValues.month = data.month || format(new Date(), 'MM');
                    _validationSchema.month = Yup.number().required();
                    break;
                case "year":
                    _initialValues.year = data.year || format(new Date(), 'uuuu');
                    _validationSchema.year = Yup.number().required();
                    break;
                case "format":
                    _initialValues.format = '1';
                    _validationSchema.format = Yup.number().required();
                    break;
                case "company":
                    _initialValues.company = data.company?.id || "";
                    _validationSchema.company = Yup.number().required();
                    break;
                case "job":
                    _initialValues.job = "all";
                    _validationSchema.job = Yup.string().required();
                    break;
            }
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const handleTypeChange = (type?: string) => {

        if (!type){
            type = '2';
        }

        const _initialValues: any = {};
        const _validationSchema: any = {};

        let fields: string[] = [];

        switch (type){
            case '1':
                fields = ['year', 'week', 'day', 'format', 'type', 'company', 'job']
                break;
            case '2':
                fields = ['year', 'week', 'format', 'type', 'company', 'job']
                break;
            case '3':
                fields = ['year', 'month', 'type', 'company', 'job']
                break;
        }

        for (let i in fields){
            switch (fields[i]) {
                case "type":
                    _initialValues.type = type;
                    _validationSchema.type = Yup.number().required();
                    break;
                case "day":
                    _initialValues.day = 1;
                    _validationSchema.type = Yup.number().required();
                    break;
                case "week":
                    _initialValues.week = format(new Date(), 'w');
                    _validationSchema.week = Yup.number().required();
                    break;
                case "month":
                    _initialValues.month = format(new Date(), 'MM');
                    _validationSchema.month = Yup.number().required();
                    break;
                case "year":
                    _initialValues.year = format(new Date(), 'uuuu');
                    _validationSchema.year = Yup.number().required();
                    break;
                case "format":
                    _initialValues.format = formik.values.format || '1';
                    _validationSchema.format = Yup.number().required();
                    break;
                case "company":
                    _initialValues.company = formik.values.company || (state.auth.user.currentSalary.companies[0].id || "");
                    _validationSchema.company = Yup.number().required();
                    break;
                case "job":
                    _initialValues.job = formik.values.job || "all";
                    _validationSchema.job = Yup.string().required();
                    break;
            }
        }

        if (_initialValues.job === "other"){
            _initialValues.jobs = [...formik.values.jobs]
            _validationSchema.jobs = Yup.array().of(Yup.number()).min(1, 'Veuillez séléctionner au moins un métier')
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))

        return true;
    }

    const handleJobChange = (job: string) => {
        let _initialValues = {...formik.values}
        let _validationFields = {...validationSchema.fields}

        _initialValues.job = job;

        if (job === 'other'){
            _initialValues.jobs =  initialValues.jobs || [];
            _validationFields.jobs = Yup.array().of(Yup.number()).min(1, 'Veuillez séléctionner au moins un métier')
        }else{
            if (_initialValues.hasOwnProperty('jobs')){
                delete _initialValues.jobs
            }
            if (_validationFields.hasOwnProperty('jobs')){
                delete _validationFields.jobs
            }
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationFields}));
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit : values => {
            api.download(values, {headers: [APPLICATION_PDF], responseType: 'arraybuffer'}).then((data) => {
                let newBlob = new Blob([data.data], { type: 'application/pdf;charset=utf-8' })
                FileSaver.saveAs(newBlob, `planning-sivan-${values.year}-${values.week ? 'week' : 'month'}-${values.week || values.month}.pdf`)
                formik.setSubmitting(false)
            }).catch(error => formik.setSubmitting(false))
        }
    });

    return (
        <form className={'row p-3'} onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <label htmlFor="" className={'me-3'}>
                    Télécharger par <span className="required">*</span>
                </label>
                <div className="form-check form-check-inline">
                    <input
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                        handleTypeChange(e.target.value)
                    }} defaultChecked={['resourceTimelineDay', 'listDay'].includes(data.view)}
                        name={'type'}
                        className="form-check-input"
                        type="radio"
                        id="daily"
                        value="1" />
                        <label className="form-check-label" htmlFor="daily">
                            Jour
                        </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                        handleTypeChange(e.target.value)
                    }} defaultChecked={['resourceTimelineWeek', 'listWeek'].includes(data.view)}
                        name={'type'}
                        className="form-check-input"
                        type="radio"
                        id="weekly"
                        value="2" />
                        <label className="form-check-label" htmlFor="weekly">
                            Semaine
                        </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        defaultChecked={data.view === 'resourceTimelineMonth'}
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                        handleTypeChange(e.target.value)
                    }}
                        name={'type'}
                        className="form-check-input"
                        type="radio"
                        id="monthly"
                        value="3" />
                        <label className="form-check-label" htmlFor="monthly">
                            Mois
                        </label>
                </div>
            </div>
            {formik.values.type === '1' && <div className="col-12 mb-3">
                <BootstrapSelect
                    label={'Jour'}
                    required={true}
                    options={[
                        {label: 'Lundi', value: 1},
                        {label: 'Mardi', value: 2},
                        {label: 'Mercredi', value: 3},
                        {label: 'Jeudi', value: 4},
                        {label: 'Vendredi', value: 5},
                        {label: 'Samedi', value: 6},
                        {label: 'Dimanche', value: 7},
                    ]}
                    value={{label: 'Lundi', value: 1}}
                    onChange={c => formik.setFieldValue('day', c?.value)}
                />
            </div>}
            <div className="col-6 mb-3">
                <BootstrapSelect
                    enableReinitialize={true}
                    options={range(new Date().getFullYear() - 10, new Date().getFullYear() + 10).map((year: number) => ({label: String(year), value: year}))}
                    onChange={(choice) => formik.setFieldValue('year', String(choice?.value))}
                    disabled={formik.isSubmitting}
                    className={validationClass(formik.errors, formik.touched, 'year')}
                    value={formik.values.year ? {label: String(formik.values.year), value: formik.values.year} : undefined}
                    label={'Année'}
                    required
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'year'} />
            </div>
            <div className="col-6 mb-3">
                {['1','2'].includes(formik.values.type) && <>
                        <BootstrapSelect
                            enableReinitialize={true}
                            options={range(1, getISOWeeks(formik.values.year || new Date().getFullYear())).map((week, index) => ({
                                label: String(week),
                                value: week
                            }))}
                            isMultiple={false}
                            onChange={(value) => formik.setFieldValue('week', String(value?.value))}
                            disabled={formik.isSubmitting}
                            className={validationClass(formik.errors, formik.touched, 'week')}
                            value={formik.values.week ? {
                                label: String(formik.values.week),
                                value: formik.values.week
                            } : undefined}
                            label={'Semaine'}
                            required
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'week'}/>
                    </>}
                {formik.values.type === '3' && <>
                        <BootstrapSelect
                            enableReinitialize={true}
                            disabled={formik.isSubmitting}
                            onChange={(choice) => formik.setFieldValue('month', String(choice?.value))}
                            name="month"
                            value={['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'].map((month, index) => ({label: month, value: String(index + 1)})).find(choice => choice.value === formik.values.month)}
                            className={'form-select' + validationClass(formik.errors, formik.touched, 'month')}
                            id="month"
                            options={['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'].map((month, index) => ({label: month, value: String(index + 1)}))}
                            label={"Mois"}
                            required={true}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'month'} />
                </>}
            </div>
            <div className="col-6 mb-3">
                <BootstrapSelect
                    fetchEntity={'company'}
                    onChange={(choice) => formik.setFieldValue('company', choice?.value)}
                    value={data.company ? {label: data.company.title, value: data.company.id} : undefined}
                    required={true}
                    label={'Point de vente'}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'company'} />
            </div>
            {['1','2'].includes(formik.values.type) && <div className="col-6 mb-3">
                <div className="form-floating">
                    <select
                        disabled={formik.isSubmitting}
                        className={'form-select' + validationClass(formik.errors, formik.touched, 'format')}
                        onChange={formik.handleChange}
                        name="format"
                        id="format">
                        <option value="1" selected={formik.values.format === '1'} > Tableau </option>
                        <option value="2" selected={formik.values.format === '2'}> Timeline </option>
                    </select>
                    <label htmlFor="">
                        Format
                    </label>
                    <FormError errors={formik.errors} touched={formik.touched} field={'format'} />
                </div>
            </div>}
            <div className="col-12 mb-3">
                <label htmlFor="">
                    Métier(s)
                </label>
                <div className="clearfix"> </div>
                <div className="form-check">
                    <input
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                            formik.handleChange(e)
                            handleJobChange(e.target.value)
                        }} defaultChecked={true}
                        name={'job'}
                        className="form-check-input"
                        type="radio"
                        id="all"
                        value="all" />
                    <label className="form-check-label" htmlFor="all">
                        Tous les métiers
                    </label>
                </div>
                <div className="form-check">
                    <input
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                            formik.handleChange(e)
                            handleJobChange(e.target.value)
                        }}
                        name={'job'}
                        className="form-check-input"
                        type="radio"
                        id="fo"
                        value="1" />
                    <label className="form-check-label" htmlFor="fo">
                        Front office
                    </label>
                </div>
                <div className="form-check">
                    <input
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                            formik.handleChange(e)
                            handleJobChange(e.target.value)
                        }}
                        name={'job'}
                        className="form-check-input"
                        type="radio"
                        id="bo"
                        value="2" />
                    <label className="form-check-label" htmlFor="bo">
                        Back office
                    </label>
                </div>
                <div className="form-check">
                    <input
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                            formik.handleChange(e)
                            handleJobChange(e.target.value)
                        }}
                        name={'job'}
                        className="form-check-input"
                        type="radio"
                        id="other"
                        value="other" />
                    <label className="form-check-label" htmlFor="other">
                        Autre
                    </label>
                </div>
            </div>
            {formik.values.job === 'other' &&
            <div className={'col-12 mb-3'}>
                <BootstrapSelect
                    fetchEntity={'job'}
                    isMultiple
                    onChangeMultiple={(choices) => formik.setFieldValue('jobs', choices.map((choice) => choice.value))}
                    label={"Métiers"}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'jobs'} />
            </div>}
            <button type={"submit"} className={'btn btn-outline-primary'}>
                {formik.isSubmitting ? <><div className="spinner-grow spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> Téléchargement en cours...</>  : 'Télécharger'}
            </button>
        </form>
    );
}

export default CalendarDownload;