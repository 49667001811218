import React, {useEffect, useState} from "react";
import {Timetable, Timetables} from "../../../models/timetable";
import {Salary} from "../../../models/salary";
import TimetableEditForm from "./form";
import {Offcanvas} from "bootstrap";

const TimetableEdit:React.FC<{
    timetables: Timetables,
    timetable: Timetable,
    salary: Salary,
    setTimetables: React.Dispatch<any>,
    setTimetable: React.Dispatch<any>,
}> = (props) => {

    const {timetables, salary, setTimetables, timetable, setTimetable } = props;
    const [offcanvas, setOffcanvas] = useState<Offcanvas>()

    useEffect(() => {
        const el = document.getElementById('offcanvasTimetableEdit')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const handleSubmit = (t: Timetable) => {

        let index = timetables.findIndex(_t => _t.id === t.id)
        if (index !== -1){
            timetables[index] = t;
            setTimetables([...timetables])
        }
        setTimetable(t)
        close()
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTimetableEdit" aria-labelledby="offcanvasTimetableEditLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasTimetableEditLabel">Modifier un emploi du temps</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <TimetableEditForm timetables={timetables} salary={salary} handleSubmit={handleSubmit} timetable={timetable} />
        </div>
    </div>
}

export default TimetableEdit;