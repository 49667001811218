import React, {useState} from "react";
import * as API from "../../../adapters/timeClock";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {showAlertSuccess} from "../../../actions/alert";
import {Company} from "../../../models/companies";

const DashboardPointing:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();
    const [type, setType] = useState<"in"|"out">();
    const [company, setCompany] = useState<number>(state.auth.user.currentSalary.companies[0].id);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const _clockIn = () => {

        if (!type || !company){
            setShowError(true)
            return false;
        }

        setIsLoading(true);

        API.clockIn({
            company: company,
            type: type === "in" ? 1 : 2,
        }).then(() => {
            dispatch(showAlertSuccess('Pointage efféctué'))
            setIsLoading(false);
            setType(undefined)
        });
    }

    return <>
        <div className="card shadow-sm p-3 mb-3">
            <div className="row">
                <div className="col">
                    <h4 className={'text-primary'}>
                        <i className={'bi bi-person-badge'}> </i> Badgeuse
                    </h4>
                    <p className={'form-text mb-0'}>
                        Choisissez une entrée ou une sortie puis validez.
                    </p>
                </div>
                <div className="col-auto">
                    <div className="row">
                        {state.auth.user.currentSalary.companies.length > 1 && <div className="col-12 col-md-auto mb-2">
                             <BootstrapSelect
                                options={state.auth.user.currentSalary.companies.map((company: Company) => ({
                                    label: company.title,
                                    value: company.id
                                }))}
                                notFloating={true}
                                required={true}
                                value={{
                                    label: state.auth.user.currentSalary.companies[0].title,
                                    value: state.auth.user.currentSalary.companies[0].id
                                }}
                                onChange={(choice) => setCompany(Number(choice?.value))}
                            />
                        </div>}
                        <div className="col-12 col-md-auto d-inline-block d-flex mb-2">
                                <button onClick={() => setType(type === "in" ? undefined : "in")} className={'btn flex-grow-1 btn-outline-primary' + (type === 'in' ? ' active' : '')}>
                                    {type === "in" && <i className={'bi bi-check-lg'}> </i>} Entrée
                                </button>
                                <button onClick={() => setType(type === "out" ? undefined : "out")} className={'btn flex-grow-1 btn-outline-danger ms-2' + (type === 'out' ? ' active' : '')}>
                                    {type === "out" && <i className={'bi bi-check-lg'}> </i>} Sortie
                                </button>
                        </div>
                        <div className="col-12 col-md-auto">
                            <button onClick={() => _clockIn()} disabled={isLoading} className={'btn flex-grow-1 btn-outline-success w-100'}>
                                <i className={'bi bi-check'}> </i> Valider
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <p className={'mb-0 float-end'}>
                {showError && !company && <>
                    <span className="form-text text-danger mb-0">
                        Veuillez séléctionner un point de vente
                    </span>
                </>}
                <span className="clearfix"> </span>
                {showError && !type && <>
                    <span className="form-text text-danger">
                        Veuillez séléctionner une entrée ou une sortie.
                    </span>
                </>}
            </p>
        </div>
    </>
}

export default DashboardPointing;