import * as types from "../../constants/staticBackdropModal"
import * as models from "../../models/statickBackdropModal"
import React from "react";

const staticBackdropModal = (state: models.initialStateInterface = models.initialState, action: {type: string, item: string, data: any, props:any, error: string}) => {

    switch (action.type) {
        case types.OPEN_MODAL:
            return { show: true, item: action.item, data: action.data, props: action.props, isLoading: false, error: null };
        case types.CLOSE_MODAL:
            return { show: false, item: state.item, data: state.data, props: state.props, isLoading: false, error: null };
        case types.SUBMIT_MODAL:
            return { show: false, item: state.item, data: state.data, props: state.props, isLoading: false, error: null };
        default:
            return state;
    }
}

export default staticBackdropModal;