import {AxiosError} from "axios";
import {AXIOS_ERROR} from "../../constants/axios";
import * as models from "../../models/axios";

const axios = (state = models.initialState, action: {type: string, error: AxiosError}) => {
    switch (action.type) {
        case AXIOS_ERROR:
            state.error = action.error
            return state;
        default:
            return state;
    }
}

export default axios;