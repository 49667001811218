import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Activity} from "../../../../../models/activity";
import * as api from "../../../../../adapters/activity/needs";
import * as Yup from "yup";
import FormError from "../../../../../utils/formError";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {editActivitySuccess} from "../../../../../actions/activity";
import {closeSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import {axiosError} from "../../../../../actions/axios";

const ActivityNeedAdd:React.FC = () => {

    const dispatch = useDispatch();
    const Activity: Activity = useSelector((state: RootStateOrAny) => state.rightSidebar.data.activity)
    const times: string[] = useSelector((state: RootStateOrAny) => state.rightSidebar.data.times)

    let start = new Date();
    start.setHours(10,0,0)

    const formik = useFormik({
        validationSchema: Yup.object().shape({
            start: Yup.string().required(),
            end: Yup.string().required(),
            days: Yup.array().of(Yup.number()).required(),
            val: Yup.number().required(),
            job: Activity.needType === 1 ? Yup.number().nullable() : Yup.number().required(),
        }),
        initialValues: {
            start: !!times.length ? times[0] : '',
            end:  !!times.length ? times[times.length - 1] : '',
            val: 1,
            days: [],
            job: undefined
        },
        onSubmit: (values) => {
            dispatch(submitSidebar())
            api.postsMany(Activity.id!, values).then(data => {
                dispatch(editActivitySuccess(data.data))
                dispatch(closeSidebar());
            }).catch(error => dispatch(axiosError(error)))
        }
    });

    return <>
        <form className={'p-3'} onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3 ">
                <div className="w-100 d-flex align-items-center">
                    <BootstrapSelect
                        label={'Heure de début'}
                        options={times.map((t) => ({label: t, value: t}))}
                        value={!!times.length ? {label: times[0], value: times[0]} : undefined}
                        onChange={(c) => formik.setFieldValue('start', c?.value)}
                        required={true}
                    />
                    <i className={'bi bi-arrow-left-right px-2'}> </i>
                    <BootstrapSelect
                        label={'Heure de fin'}
                        options={times.map((t) => ({label: t, value: t}))}
                        value={!!times.length ? {label: times[times.length - 1], value: times[times.length - 1]} : undefined}
                        onChange={(c) => formik.setFieldValue('end', c?.value)}
                        required={true}
                    />
                </div>
                <div className="w-100">
                    <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
                    <FormError touched={formik.touched} errors={formik.errors} field={'end'} />
                </div>
            </div>
            <div className={'col-12 mb-3'}>
                <label htmlFor="" className={'mb-2'}>
                    Jours de la semaine
                </label>
                <div className="align-items-center">
                    {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                        return (
                            <>
                                <input
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    name={'days'}
                                    className="btn-check"
                                    id={`btn-check-${index}`}
                                    autoComplete="off"
                                    value={`${index + 1}`}
                                />
                                <label className="btn btn-sm btn-outline-primary me-2 mb-3" htmlFor={`btn-check-${index}`}>
                                    {day}
                                </label>
                            </>
                        )
                    })}
                    <FormError touched={formik.touched} errors={formik.errors} field={'days'} />
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input name={'val'} onChange={formik.handleChange} type="number" defaultValue={1} className="form-control"/>
                    <label htmlFor="">
                        Valeur ETP
                    </label>
                </div>
                <FormError touched={formik.touched} errors={formik.errors} field={'val'} />
            </div>
            {Activity.needType === 2 && <div className="col-12 mb-3">
                <BootstrapSelect
                    label={'Métiers'}
                    required={true}
                    options={
                        Activity.needJobs?.map(job => ({label: job.title, value: job.id!}))
                    }
                    onChange={(choice) => formik.setFieldValue('job', choice?.value)}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'job'} />
            </div>}
            <button type={"submit"} className={'btn btn-outline-primary w-100'}>
                <i className={'bi bi-plus-circle'}> </i> Ajouter
            </button>
        </form>
    </>
}

export default ActivityNeedAdd;