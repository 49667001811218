import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { ALERT_DANGER, ALERT_INFO, ALERT_SUCCESS, ALERT_WARNING } from "../../constants/alert";

import './index.scss'

export const AlertSivan: React.FC = () => {
    const value = useSelector((state: RootStateOrAny) => state.alertSivan.value);
    const target = useSelector((state: RootStateOrAny) => state.alertSivan.target);
    const show = useSelector((state: RootStateOrAny) => state.alertSivan.show);

    const Info = () => {
        return (
            <div className="alert mb-0 rounded-0 fade show alert-primary d-flex align-items-center" role="alert">
                <div className="col-auto me-2">
                    <i className="bi bi-info-circle fs-4"> </i>
                </div>
                <div className="col">
                    {value}
                </div>
            </div>
        )
    }

    const Success = () => {
        return (
            <div className="alert mb-0 rounded-0 fade show alert-success d-flex align-items-center" role="alert">
                <div className="col-auto me-2">
                    <i className="bi bi-check-circle fs-4"> </i>
                </div>
                <div className="col">
                    {value}
                </div>
            </div>
        )
    }

    const Warning = () => {
        return (
            <div className="alert mb-0 rounded-0 fade show alert-warning d-flex align-items-center" role="alert">
                <div className="col-auto me-2">
                    <i className="bi bi-exclamation-triangle fs-4"> </i>
                </div>
                <div className="col">
                    {value}
                </div>
            </div>
        )
    }

    const Danger = () => {
        return (
            <div className="alert fade show alert-danger d-flex align-items-center" role="alert">
                <div className="col-auto me-2">
                    <i className="bi bi-exclamation-circle fs-4"> </i>
                </div>
                <div className="col">
                    {value}
                </div>
            </div>
        )
    }

    const CurrentAlert = () => {
        switch (target) {
            case ALERT_SUCCESS:
                return <Success/>
            case ALERT_INFO:
                return <Info/>
            case ALERT_WARNING:
                return <Warning/>
            case ALERT_DANGER:
                return <Danger/>
            default:
                return null;
        }
    }

    return (
        <div
            className={(show ? 'show' : '') + ' card shadow-sm position-absolute fade'}
                style={{
                bottom: 20,
                left: "50%",
                width: 400,
                transform: "translate(-50%, 0)",
                zIndex: 99999999
            }}
        >
            <CurrentAlert/>
        </div>
    )

}

export default AlertSivan;