import React, {useEffect, useState} from "react";
import {DateSelectArg, EventClickArg} from "@fullcalendar/react";
import CalendarTaskEdit from "../edit";
import CalendarTaskShow from "../show";
import CalendarTaskAdd from "../add";
import CalendarTaskSelect from "../add/select";
import {Salary} from "../../../../models/salary";
import {Offcanvas} from "bootstrap";

interface InterfaceTaskShow {
    sidebarType: 'TASK_SHOW',
    arg: EventClickArg,
    setCalendarTaskSidebarProps: React.Dispatch<any>
}

interface InterfaceTaskEdit {
    sidebarType: 'TASK_EDIT',
    arg: EventClickArg,
}

interface InterfaceTaskAdd {
    sidebarType: 'TASK_ADD',
    salary?: Salary
}

interface InterfaceTaskSelect {
    sidebarType: 'TASK_SELECT',
    arg: DateSelectArg,
    salary?: Salary
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarTaskSidebarInterface = {trigger: number} & (Interface | InterfaceTaskShow | InterfaceTaskEdit | InterfaceTaskAdd | InterfaceTaskSelect)

const CalendarTaskSidebar:React.FC<CalendarTaskSidebarInterface & {handleEventChange: any, referer?: any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarTask' + (props.referer ? '_' + props.referer : ''))! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    const reset = () => setIsOpen(false)

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarTask' + (props.referer ? '_' + props.referer : ''))!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content:React.FC = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "TASK_ADD":
                    return <CalendarTaskAdd key={props.referer} close={close} handleSubmitSuccess={props.handleEventChange} salary={props.salary} />
                case "TASK_SELECT":
                    return <CalendarTaskSelect key={props.referer} close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} salary={props.salary} />
                case "TASK_SHOW":
                    return <CalendarTaskShow key={props.referer} close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} setCalendarTaskSidebarProps={props.setCalendarTaskSidebarProps} />
                case "TASK_EDIT":
                    return <CalendarTaskEdit key={props.referer} close={close} handleSubmitSuccess={props.handleEventChange} arg={props.arg} />
            }
        }

        return <span key={props.referer} className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div key={props.referer} className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id={"offcanvasCalendarTask" + (props.referer ? '_' + props.referer : '')} aria-labelledby={"offcanvasCalendarTaskLabel" + (props.referer ? '_' + props.referer : '')}>
        {isOpen && <Content key={props.referer} />}
    </div>
}

function areEqual(prevProps: CalendarTaskSidebarInterface & {handleEventChange: any}, nextProps: CalendarTaskSidebarInterface & {handleEventChange: any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarTaskSidebar, areEqual);