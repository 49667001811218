import React, {useEffect, useState} from "react";

interface LoaderListProps {
    leftCircle: boolean;
    title: boolean;
    description: boolean;
    listLength: number;
}

const LoaderList:React.FC<LoaderListProps> = (props) => {

    const {description, title, leftCircle, listLength} = props;
    const [listArray, setListArray] = useState<number[]>([]);

    useEffect(() => {
        let _listArray: number[] = []
        for(let i = 0; i < listLength; i++){
            _listArray.push(i)
        }
        setListArray([..._listArray])
    }, [])

    return <ul className={"list bg-white list-group-flush placeholder wave p-1"}>
        {listArray.map(index => <li key={index} className={'list-group-item d-flex align-items-center'}>
            <span className="col">
                {title && <div className="line mb-2 w-100"> </div>}
                <div className="clearfix"> </div>
                {description && <div className="line w-75"> </div>}
            </span>
            <span className="col-auto ms-3">
                {leftCircle && <div className="circle"> </div>}
            </span>
        </li>)}
    </ul>
}

export default LoaderList;