import React, { useEffect, useState } from "react";

import { Modal } from "bootstrap";

import { Companies, Company } from "../../../../models/companies";
import getUser from "../../../../security/getUser";

interface IProps {
    companies: Companies;
    setCompanies: (companies: Companies) => void;
}

const CalendarCompanySelector:React.FC<IProps> = ({ companies, setCompanies }) => {
    const [_companies, _setCompanies] = useState<Companies>([...companies])
    const [modal, setModal] = useState<Modal>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, c: Company) => {
        let newTab: Companies = []

        if (e.target.checked) {
            newTab = [ ..._companies, c ]
        } else {
            newTab = _companies.filter(_c => _c.id !== c.id)
        }

        localStorage.setItem('defaultCompanies', JSON.stringify(newTab.map(company => company.id)))
        _setCompanies(newTab)
    }

    const validate = () => {
        setCompanies([..._companies])
        modal?.hide();
    }

    useEffect(() => {
        let modalEl = document.getElementById('companyModal') as HTMLDivElement;
        setModal(new Modal(modalEl));
    },[])

    return (
        <div className="modal fade" id="companyModal" tabIndex={-1} aria-labelledby="companyModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="companyModalLabel">Établissements</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <ul className={'list-group list-group-flush'}>
                            {getUser().currentSalary.companies.map((c: Company) => <li className={'list-group-item'}>
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        onChange={(e) => handleChange(e, c)}
                                        defaultChecked={!!_companies.find(_c => _c.id === c.id)}
                                        type="checkbox"
                                        id={String('company' + c.id!)}
                                    />
                                        <label className="form-check-label" htmlFor={String('company' + c.id!)}>
                                            {c.title}
                                        </label>
                                </div>
                            </li>)}
                        </ul>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" onClick={() => validate()}>
                            <i className={'bi bi-check-circle text-success'}> </i> Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendarCompanySelector;