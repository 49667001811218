import * as React from "react";

import TimeCLockCalendar from "../../../components/timeClock/calendar";

const TimeClockCalendarPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <TimeCLockCalendar />
        </div>
    );
}

export default TimeClockCalendarPage;