import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/expense"
import * as actions from "../../../actions/expense"
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EXPENSE_ADD, FORM_NEW_ABSENCE, PENDING_ABSENCE_LIST} from "../../../constants/rightSidebar";
import * as accessRights from "../../../constants/accessRight";
import {Expense} from "../../../models/expense";
import {axiosError} from "../../../actions/axios";
import {Granted, GrantedAny} from "../../../security/granted";
import DashboardExpenseRequestList from "./list";


const DashboardExpenseRequest:React.FC = () => {


    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        const controller = new AbortController();

        dispatch(actions.fetchExpenses())
        api.list({}, controller.signal)
            .then(data => dispatch(actions.fetchExpensesSuccess(data.data)))
            .catch(error => dispatch(axiosError(error)))

        return () => controller.abort();
    }, [])

    return <>
        <div className="btn-group w-100 shadow-sm bg-white mb-3">
            <div className="card p-3 flex-grow-1 cursor-pointer"
                 data-bs-toggle="offcanvas" data-bs-target="#offcanvasExpenseRequest" aria-controls="offcanvasExpenseRequest"
            >
                <div className="d-flex align-items-center">
                    <div className="col">
                    <span className={'h4 text-primary'}>
                        <i className={'bi bi-calendar-x'}> </i>
                        Notes de frais
                    </span>
                        <div className="clearfix"> </div>
                        <span className="form-text">
                            <i className={'bi bi-clock-history text-warning'}></i> en attente
                        </span>
                    </div>
                    <div className="col-auto align-middle align-items-center">
                        {state.expense.isLoading ? <div className="spinner-border align-middle text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <span className={'text-primary h2'}>{state.expense.payload.filter((expense: Expense) => expense.status === 1).length}</span>}
                    </div>
                </div>
            </div>
            {Granted(accessRights.EDIT_EXPENSE) && <button className="flex-grow-0 btn btn-outline-primary"
                     onClick={() => dispatch(openSidebar(FORM_EXPENSE_ADD))}>
                <i className={'bi bi-plus fs-4'}></i>
            </button>}
        </div>
        <DashboardExpenseRequestList />
    </>
}

export default DashboardExpenseRequest;