export interface Job{
    id?: number,
    title: string,
    type: string,
    global?: boolean,
    activated?: boolean,
    color: string,
    position?: number,
}

export type Jobs = Job[];

export interface initialStateInterface {
    payload: Jobs,
    single: Job|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};