import * as React from "react";

import ExportSilae from "../../../components/export/silae";

const ExportSilaePage : React.FC = () => {
    return (
        <ExportSilae />
    );
}

export default ExportSilaePage;