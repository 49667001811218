import * as React from "react";

import AccessLevelList from "../../components/accessLevel/list";
import AccessLevelShow from "../../components/accessLevel/show";

const AccessLevelPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <AccessLevelList />
            <AccessLevelShow />
        </div>
    )
}

export default AccessLevelPage;