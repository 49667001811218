import React from 'react';

interface IProps {
  text?: string;
}

const EmptyList: React.FC<IProps> = ({ text }) => {
  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="col d-flex justify-content-center align-items-center">
          <p className="m-0 text-center text-soft">
            {text || "Aucun élément dans la liste"}
          </p>
        </div>
      </div>
    </div>
  )
}

export default EmptyList