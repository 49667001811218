import React from "react";
import * as types from "../../constants/staticBackdropModal";

export const openModal = (item: string, data?: any, props?: any) => {
    return {
        type: types.OPEN_MODAL,
        item: item,
        data: data,
        props: props
    }
};

export const closeModal = () => {
    return {
        type: types.CLOSE_MODAL
    }
};