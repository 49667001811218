import * as types from "../../../constants/admin/user";
import * as models from "../../../models/auth";

export const addUser = () => {
    return {
        type: types.ADD_USER,
    }
}

export const addUserSuccess = (user: models.User) => {
    return {
        type: types.ADD_USER_SUCCESS,
        user: user,
    }
}

export const addUserFailure = (error: string) => {
    return {
        type: types.ADD_USER_FAILURE,
        error: error
    }
}

export const editUser = () => {
    return {
        type: types.EDIT_USER,
    }
}

export const editUserSuccess = (user: models.User) => {
    return {
        type: types.EDIT_USER_SUCCESS,
        user: user
    }
}

export const editUserFailure = (error: string) => {
    return {
        type: types.EDIT_USER_FAILURE,
        user: error
    }
}

export const fetchUsers = () => {
    return {
        type: types.FETCH_USERS,
    }
}

export const fetchUsersFailure = (error: string) => {
    return {
        type: types.FETCH_USERS_FAILURE,
        error: error
    }
}

export const fetchUsersSuccess = (users: models.Users) => {
    return {
        type: types.FETCH_USERS_SUCCESS,
        users: users
    }
}


export const showUser = () => {
    return {
        type: types.SHOW_USER,
    }
}
export const showUserSuccess = (user: models.User) => {
    return {
        type: types.SHOW_USER_SUCCESS,
        user: user
    }
}

export const showUserFailure = (error: string) => {
    return {
        type: types.SHOW_USER_FAILURE,
        error: error
    }
}