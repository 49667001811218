import * as types from "../../constants/activity";
import * as models from "../../models/activity";

export const addActivity = () => {
    return {
        type: types.ADD_ACTIVITY,
    }
}

export const addActivitySuccess = (activity: models.Activity) => {
    return {
        type: types.ADD_ACTIVITY_SUCCESS,
        activity: activity,
    }
}

export const addActivityFailure = (error: string) => {
    return {
        type: types.ADD_ACTIVITY_FAILURE,
        activity: null,
        activities: null,
        error: error
    }
}

export const editActivity = () => {
    return {
        type: types.EDIT_ACTIVITY,
    }
}
export const editActivitySuccess = (activity: models.Activity) => {
    return {
        type: types.EDIT_ACTIVITY_SUCCESS,
        activity: activity
    }
}
export const editActivityFailure = (error: string) => {
    return {
        type: types.EDIT_ACTIVITY,
        activity: null,
        activities: null,
        error: error
    }
}

export const fetchActivities = () => {
    return {
        type: types.FETCH_ACTIVITIES,
    }
}

export const fetchActivitiesFailure = (error: string) => {
    return {
        type: types.FETCH_ACTIVITIES_FAILURE,
        activity: null,
        activities: [],
        error: error
    }
}

export const fetchActivitiesSuccess = (activities: models.Activities) => {
    return {
        type: types.FETCH_ACTIVITIES_SUCCESS,
        activity: null,
        activities: activities
    }
}


export const showActivity = () => {
    return {
        type: types.SHOW_ACTIVITY,
    }
}
export const showActivitySuccess = (activity: models.Activity) => {
    return {
        type: types.SHOW_ACTIVITY_SUCCESS,
        activity: activity
    }
}
export const showActivityFailure = (error: string) => {
    return {
        type: types.SHOW_ACTIVITY_FAILURE,
        activity: null,
        activities: null,
        error: error
    }
}