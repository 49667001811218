const HasRole = (role: "ROLE_USER"|"ROLE_ADMIN"|"ROLE_SUPER_ADMIN") => {
    let user = localStorage.getItem('user')

    if (user){
        let data = JSON.parse(user)
        return data.roles.includes(role);
    }

    return false;
}

export default HasRole;