import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import StyledDropzone from "../../../utils/fileUpload";
import {showAlertDanger, showAlertSuccess} from "../../../actions/alert";
import {closeSidebar} from "../../../actions/rightSidebar";
import * as api from "../../../adapters/file"
import {editAbsenceSuccess} from "../../../actions/absence";

const FileAdd:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const [files, setFiles] = useState<File[]>([])
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = () => {
        let entity: string = state.rightSidebar.data.entity;
        let id: string = state.rightSidebar.data.id;

        if (!files.length){
            dispatch(showAlertDanger('Veuillez ajouter un fichier'))
            return false;
        }

        if (!entity || !id){
            dispatch(closeSidebar());
            dispatch(showAlertDanger('Une erreur est survenue ! Veuillez réésayer'))
            return false;
        }

        setSubmitting(true);

        let formData = new FormData();

        formData.append(entity, id)
        formData.append('title', files[0].name);
        formData.append('file', files[0]);

        api.create(formData, entity).then((data) => {
            switch (entity) {
                case 'absence':
                    let absence = state.absence.single;
                    absence.files.push(data.data)
                    dispatch(editAbsenceSuccess(absence));
                    break;
            }
            dispatch(showAlertSuccess('Document ajouté'))
            dispatch(closeSidebar());
        })
    }

    return  <>
                <div className="p-3">
                    <div className="mb-3">
                        <StyledDropzone style={{height: 200}}  handleUploadedFile={(files: File[]) => setFiles(files)} />
                    </div>
                    <button type={'button'} onClick={() => handleSubmit()} disabled={!files.length || submitting} className={'btn w-100 btn-primary'}>
                        {submitting ? <div className="spinner-grow text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : 'Enregistrer'}
                    </button>
                </div>
            </>
}

export default FileAdd;