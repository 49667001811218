import React, {useEffect, useState} from "react";
import {Salary} from "../../../../models/salary";
import {Offcanvas} from "bootstrap";
import {Job} from "../../../../models/job";
import TemplateSalaries from "../salaries";
import ResourceAdd from "../add/test";
import {Template} from "../../../../models/template";
import ResourceEdit from "../edit/test";

interface Interface {
    sidebarType: 'EMPTY',
}

interface InterfaceSalaryResourceAdd {
    sidebarType: 'RESOURCE_SALARIES_ADD',
}

interface InterfaceFloatingResourceAdd {
    sidebarType: 'RESOURCE_FLOATING_ADD',
}

interface InterfaceFloatingResourceEdit {
    sidebarType: 'RESOURCE_EDIT',
    floating: true,
    title: string,
    job: Job,
    contractWorkingTime: number,
    resource: {id: number}
}

interface InterfaceNotFloatingResourceEdit {
    sidebarType: 'RESOURCE_EDIT',
    floating: false,
    salary: Salary,
    resource: {id: number}
}

export type TemplateResourceSidebarInterface = {trigger: number} & (Interface | InterfaceFloatingResourceAdd | InterfaceFloatingResourceEdit | InterfaceNotFloatingResourceEdit | InterfaceSalaryResourceAdd)

const TemplateResourceSidebar:React.FC<TemplateResourceSidebarInterface & {template: Template, setTemplate: React.Dispatch<any>, handleEventChange: (resource: any, previousResource?: any) => any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasTemplateResource')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasTemplateResource')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "RESOURCE_SALARIES_ADD":
                    return <TemplateSalaries template={props.template} setTemplate={props.setTemplate} handleSubmitSuccess={props.handleEventChange} />
                case "RESOURCE_FLOATING_ADD":
                    return <ResourceAdd close={close} template={props.template} setTemplate={props.setTemplate} handleSubmitSuccess={props.handleEventChange} />
                case "RESOURCE_EDIT":
                    if (props.floating){
                        return <ResourceEdit  template={props.template} setTemplate={props.setTemplate} handleSubmitSuccess={props.handleEventChange} resource={props.resource} floating={props.floating} job={props.job} close={close} title={props.title} contractWorkingTime={props.contractWorkingTime} />
                    }else{
                        return <ResourceEdit  template={props.template} setTemplate={props.setTemplate} handleSubmitSuccess={props.handleEventChange} resource={props.resource} floating={props.floating} salary={props.salary} close={close}  />
                    }
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasTemplateResource" aria-labelledby="offcanvasTemplateResourceLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: TemplateResourceSidebarInterface & {template: Template, setTemplate: React.Dispatch<any>, handleEventChange: (resources: number[]) => any}, nextProps: TemplateResourceSidebarInterface & {template: Template, setTemplate: React.Dispatch<any>, handleEventChange: (resources: number[]) => any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(TemplateResourceSidebar, areEqual);