import React, {useEffect, useState} from "react";

interface colorPickerProps {
    onChange: (choice: string) => void,
    selected: string,
    colors?: string[]
}

const ColorPicker:React.FC<colorPickerProps> = (props) => {
    const {onChange, selected, colors} = props;

    const [items, setItems] = useState<string[]>([])

    useEffect(() => {
        if (!colors){
            setItems(['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF',
                '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115',
                '#E27300',
                '#FCC400',
                '#B0BC00',
                '#68BC00',
                '#16A5A5',
                '#009CE0',
                '#7B64FF',
                '#FA28FF',
                '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']);
        }else{
            setItems([...colors])
        }
    }, [])

    return (<div className={'col-12'}>
            <div className="row g-0">
                {items.map((color, index) => {
                    return <div className={'col-1 px-0'}>
                        <div onClick={() => onChange(color)}
                             className={'m-1' + (color === selected ? ' shadow' :  '')} style={{width: 28, height: 28, borderRadius: '50%', backgroundColor: color, color: "white", opacity: 0.8}}>
                            {color === selected ? <i style={{lineHeight: 0, fontSize: '2rem'}} className={'bi bi-check'}> </i> : ''}
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default ColorPicker;