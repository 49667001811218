import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import CounterTableSingle from "./single";
import CounterTableMultiple from "./multiple";

import { TotalCounters } from "../../../models/counter";

const CounterTable: React.FC<{payload: TotalCounters}> = (props) => {
    const { payload } = props;

    const single = useSelector((state: RootStateOrAny) => state.counter.single);

    return (
        <>
            <div className="container-fluid d-flex flex-column flex-grow-1">
                <CounterTableMultiple payload={payload} />
            </div>

            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Compteurs {single?.salary?.title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <CounterTableSingle />
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CounterTable;