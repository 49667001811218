export const ADD_BOOKING_OFFER = 'ADD_BOOKING_OFFER';
export const ADD_BOOKING_OFFER_SUCCESS = 'ADD_BOOKING_OFFER_SUCCESS';
export const ADD_BOOKING_OFFER_FAILURE = 'ADD_BOOKING_OFFER_FAILURE';

export const EDIT_BOOKING_OFFER = 'EDIT_BOOKING_OFFER';
export const EDIT_BOOKING_OFFER_SUCCESS = 'EDIT_BOOKING_OFFER_SUCCESS';
export const EDIT_BOOKING_OFFER_FAILURE = 'EDIT_BOOKING_OFFER_FAILURE';

export const FETCH_BOOKING_OFFERS = 'FETCH_BOOKING_OFFERS';
export const FETCH_BOOKING_OFFERS_SUCCESS = 'FETCH_BOOKING_OFFERS_SUCCESS';
export const FETCH_BOOKING_OFFERS_FAILURE = 'FETCH_BOOKING_OFFERS_FAILURE';

export const SHOW_BOOKING_OFFER = 'SHOW_BOOKING_OFFER';
export const SHOW_BOOKING_OFFER_SUCCESS = 'SHOW_BOOKING_OFFER_SUCCESS';
export const SHOW_BOOKING_OFFER_FAILURE = 'SHOW_BOOKING_OFFER_FAILURE';

export const CANCEL_BOOKING_OFFER_SUCCESS = 'CANCEL_BOOKING_OFFER_SUCCESS';