import * as React from "react";

import ReportingShow from "../../../components/reporting/show";

const ReportingShowPage : React.FC = () => {
    return (
        <ReportingShow />
    );
}

export default ReportingShowPage;