import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/team/manager";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as models from "../../../../../models/team";
import * as types from "../../../../../constants/team";
import {closeSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import {editTeamSuccess} from "../../../../../actions/team";
import {showAlertSuccess} from "../../../../../actions/alert";
import {Salary} from "../../../../../models/salary";
import * as accessRightsTypes from "../../../../../constants/accessRight";
import SalarySelector from "../../../../salary/selector";

const TeamAddManager:React.FC = () => {

    const dispatch = useDispatch();
    const team: models.Team = useSelector((state: RootStateOrAny) => state.team.single)

    const formik = useFormik({
        initialValues: {
            salary: null,
            rights: [...types.accessRights().map(item => item.underscoreCase)],
        },
        validationSchema: Yup.object().shape({
            salary: Yup.number().required('Veuillez selectionner un collaborateur'),
            rights: Yup.array().of(Yup.string())
        }),
        onSubmit: values => {
            dispatch(submitSidebar());
            api.create(values, team.id).then(data => {
                dispatch(editTeamSuccess(data.data))
                showAlertSuccess('Manageur ajouté !')
                dispatch(closeSidebar())
            })
        }
    })

    return <>
        <form className={'p-1 p-md-3'} onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
                <SalarySelector
                    required={true}
                    fetchOptions={true}
                    onChange={(s: Salary|undefined) => formik.setFieldValue('salary', s!.id)}
                    accessRight={accessRightsTypes.EDIT_SALARY}
                />
            </div>
            <div className="col-12 mb-3">
                <ul className={'list-group'}>
                    {types.accessRights().map((item, index) => <li key={index} className={'list-group-item'}>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <label className="form-check-label" htmlFor={item.underscoreCase}>{item.description}</label>
                            </div>
                            <div className="form-check form-switch">
                                <input defaultChecked={true} name={'rights'} onChange={formik.handleChange} value={item.underscoreCase} id={item.underscoreCase} className="form-check-input" type="checkbox" />
                            </div>
                        </div>
                    </li>)}
                </ul>
            </div>
            <button type={'submit'} className="btn btn-outline-primary w-100">
                <i className="bi bi-plus-circle"> </i> Ajouter
            </button>
        </form>
    </>
}

export default TeamAddManager;