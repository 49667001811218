import React from "react";
import KeepNoteAddForm from "../../../keepNote/create/form";

const KeepNoteAdd:React.FC<{close: () => any, handleSubmitSuccess: (ev: any[]) => any, dueDate: Date}> = (props) => {

    const {handleSubmitSuccess, close, dueDate} = props;

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarKeepNoteAddLabel">Ajouter une note</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <KeepNoteAddForm
                handleSubmitSuccess={handleSubmit}
                dueDate={dueDate}
            />
        </div>
    </>
}

export default KeepNoteAdd;