export const FETCH_TEMPLATE_CALENDAR = 'FETCH_TEMPLATE_CALENDAR';
export const FETCH_TEMPLATE_CALENDAR_SUCCESS = 'FETCH_TEMPLATE_CALENDAR_SUCCESS';
export const FETCH_TEMPLATE_CALENDAR_FAILURE = 'FETCH_TEMPLATE_CALENDAR_FAILURE';

export const SET_TEMPLATE_EVENT_CLICK_ARG = 'SET_TEMPLATE_EVENT_CLICK_ARG';

export const SHOW_TEMPLATE_EVENT_POP = 'SHOW_TEMPLATE_EVENT_POP';
export const HIDE_TEMPLATE_EVENT_POP = 'HIDE_TEMPLATE_EVENT_POP';

export const REFRESH_TEMPLATE = 'REFRESH_TEMPLATE';
export const SET_TEMPLATE_GROUP_BY = 'SET_TEMPLATE_GROUP_BY';

export const EDIT_RESOURCE_SUCCESS = 'EDIT_RESOURCE_SUCCESS';

