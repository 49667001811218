import React, {useEffect} from "react";
import * as actions from "../../../../actions/taskGroup";
import * as api from "../../../../adapters/taskGroup/customField";
import {showAlertSuccess} from "../../../../actions/alert";
import {closeSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {Field, FieldProps, Form, Formik} from "formik";
import * as Yup from "yup";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {TaskGroup} from "../../../../models/taskGroup";

const CustomFieldSchema = Yup.object().shape({
    taskGroup: Yup.number(),
    title: Yup.string().required('Veuillez préciser un titre'),
    description: Yup.string().nullable(),
    type: Yup.string(),
})

const InitialValues = {
    taskGroup: null,
    title: '',
    description: '',
    type: 1
}

const TaskGroupCustomFieldAdd: React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        InitialValues.taskGroup = state.taskGroup.single?.id
    }, [])

    return(
        <Formik
            initialValues={InitialValues}
            validationSchema={CustomFieldSchema}
            onSubmit={values => {
                dispatch(submitSidebar())
                api.create(values).then((data) => {
                    dispatch(actions.editTaskGroupSuccess(data.data))
                    dispatch(showAlertSuccess('Champ personnalisé ' + data.data.title + ' ajouté au groupe ' + state.taskGroup.single.title))
                    dispatch(closeSidebar());
                })
            }}
        >
            {({ errors, touched }) => (
                <Form className={'p-3'}>
                    <label className="form-label" htmlFor="taskGroup">
                        Groupe
                    </label>
                    <Field className='form-control mb-2' name="taskGroup">
                        {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                          }: FieldProps) => (
                            <>
                                <Select
                                    onChange={(choice) => {
                                        setFieldValue('taskGroup', choice?.value)
                                    }}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name={'taskGroup'}
                                    defaultValue={state.taskGroup.single && {label: state.taskGroup.single.title, value: state.taskGroup.single.id}}
                                    options={state.taskGroup.payload.map((taskGroup: TaskGroup) => {return {label: taskGroup.title, value: taskGroup.id}})}
                                />
                                {meta.touched &&
                                meta.error && <div className="text-danger form-text w-100">{meta.error}</div>}
                            </>
                        )}
                    </Field>
                    <label className="form-label" htmlFor="title">
                        Titre du champ
                    </label>
                    <Field className='form-control mb-2' name="title"/>
                    {errors.title && touched.title ? (<div className='text-danger form-text' >{errors.title}</div>) : null}
                    <label className="form-label" htmlFor="description">
                        Description
                    </label>
                    <Field className='form-control mb-2' name="description"/>
                    {errors.description && touched.description ? (<div className='text-danger form-text' >{errors.description}</div>) : null}
                    <label className="form-label" htmlFor="type">
                        Type de champ
                    </label>
                    <div className="w-100 mb-2">
                        <div className="form-check">
                            <Field className='form-check-input' type={'radio'} defaultChecked={true} value={"1"} name="type" id={'typeColors'}/>
                                <label className="form-check-label" htmlFor="typeColors">
                                    Couleur
                                </label>
                        </div>
                        <div className="form-check">
                            <Field className='form-check-input' type={'radio'} value={"2"} name="type" id={'typeText'}/>
                                <label className="form-check-label" htmlFor="typeText">
                                    Text libre
                                </label>
                        </div>
                        <div className="form-check">
                            <Field className='form-check-input' type={'radio'} value={"3"} name="type" id={'typeNumber'}/>
                                <label className="form-check-label" htmlFor="typeNumber">
                                    Nombre
                                </label>
                        </div>
                        <div className="form-check">
                            <Field className='form-check-input' type={'radio'} value={"4"} name="type" id={'typeDate'}/>
                                <label className="form-check-label" htmlFor="typeDate">
                                    Date
                                </label>
                        </div>
                        <div className="form-check">
                            <Field className={'form-check-input'} type={'radio'} value={"5"} name="type" id={'typeFile'}/>
                                <label className="form-check-label" htmlFor="typeFile">
                                    Fichier
                                </label>
                        </div>
                        <div className="form-check">
                            <Field className='form-check-input' type={'radio'} value={"6"} name="type" id={'typeSalary'}/>
                                <label className="form-check-label" htmlFor="typeSalary">
                                    Salarié(s)
                                </label>
                        </div>
                    </div>
                    <button className='btn btn-outline-primary w-100' type="submit">Ajouter</button>
                </Form>
            )}
        </Formik>
    )
}

export default TaskGroupCustomFieldAdd;