import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import * as actions from "../../../actions/localisation";
import * as api from "../../../adapters/localisation";
import {openSidebar} from "../../../actions/rightSidebar";
import {FORM_EDIT_LOCALISATION} from "../../../constants/rightSidebar";
import LocalisationNeedTable from "./need";
import {setPageTitle} from "../../../actions/header";
import {Localisation} from "../../../models/localisation";
import * as accessRights from "../../../constants/accessRight";
import {axiosError} from "../../../actions/axios";
import BgSingle from "../../../utils/background/single";
import {Granted} from "../../../security/granted";

export const LocalisationShow: React.FC = () => {

    const dispatch = useDispatch();
    const params: {id: string} = useParams();
    const state = useSelector((state: RootStateOrAny) => state.localisation)

    useEffect(() => {
        dispatch(actions.showLocalisation())
        if (state.payload.find((l: Localisation) => l.id === Number(params.id)) && params.id){
            dispatch(actions.showLocalisationSuccess(state.payload.find((l: Localisation) => l.id === Number(params.id))))
            dispatch(setPageTitle('Emplacements', state.payload.find((l: Localisation) => l.id === Number(params.id)).title))
        }else if(params.id){
            api.get(params.id).then(data => {
                dispatch((actions.showLocalisationSuccess(data.data)))
                dispatch(setPageTitle('Emplacements', data.data.title))
            }).catch(error => dispatch(axiosError(error)))
        }
    }, [params.id])

    function activate(){
        api.activate(Number(params.id)).then(resp => dispatch((actions.showLocalisationSuccess(resp.data))))
    }
    function deactivate(){
        api.deactivate(Number(params.id)).then(resp => dispatch((actions.showLocalisationSuccess(resp.data))))
    }

    if (!state.single || !params.id){
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    return <div id={'singleContainer'} className={'col-12 col-md-8 col-lg-9' + (!params.id ? " d-none d-md-block" : "")}>
                <div className="d-flex  p-1 p-md-3 flex-column flex-grow-1 h-100">
                    <div className="col-12 mb-3 flex-grow-0">
                        <div className="p-3 bg-white card w-100 shadow-sm">
                            <div className="row">
                                <div className="col">
                                     <span className={'text-primary'}>
                               <i className={'bi bi-shop'}> </i> {state.single.company.title}
                            </span>
                                    <div className="clearfix"> </div>
                                    <span>
                                 <i>{state.single.description}</i>
                            </span>
                                </div>
                                {Granted(accessRights.EDIT_LOCALISATION) &&  <div className="col-auto">
                                    <button className={'btn btn-outline-primary float-end'} onClick={() => {
                                        dispatch(openSidebar(FORM_EDIT_LOCALISATION, state.single))
                                    }}>
                                        <i className={'bi bi-pencil'}> </i> Modifier
                                    </button>
                                </div>}
                                {Granted(accessRights.DELETE_LOCALISATION) && <div className="col-auto">
                                    {state.single.activated ?  <button className={'btn btn-outline-danger float-end'} onClick={() => deactivate()}>
                                        <i className={'bi bi-dash-circle'}> </i> Désactiver
                                    </button> :
                                        <button className={'btn btn-outline-success float-end'} onClick={() => activate()}>
                                            <i className={'bi bi-check-circle'}> </i> Réactiver
                                        </button>}
                                </div>}
                            </div>
                        </div>
                    </div>
                    <LocalisationNeedTable />
            </div>
        </div>
}

export default LocalisationShow;