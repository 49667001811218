import * as React from "react";

import Password from "../../../components/auth/password";

const PasswordPage : React.FC = () => {
    return (
        <Password />
    )
}

export default PasswordPage;