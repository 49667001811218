import React from "react";
import {Field, FieldProps, Form, FormikErrors, FormikTouched, FormikValues} from "formik";
import {CirclePicker} from "react-color";
import ColorPicker from "../../../../utils/colorPicker";


interface jobFormProps {
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    values: FormikValues
}
const JobForm:React.FC<jobFormProps> = (props) => {

    const {values, errors, touched} = props;

    return <>
        <Form className="row g-3 needs-validation">
            <div className='col-12 mb-3'>
                <div className="form-floating">
                    <Field
                        className={'form-control' + (touched.title ? (errors.title ? ' is-invalid' : ' is-valid') : '')}
                        name="title"/>
                    <label className="form-label" htmlFor="title">
                        Titre <span className={'required'}>*</span>
                    </label>
                    {errors.title && touched.title ? (<div className='text-danger form-text' >{errors.title}</div>) : null}
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="input-group">
                    <div className="form-check form-check-inline">
                        <Field className="form-check-input" type="radio" name="type"
                               id="inlineRadio1" value="2" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Back Office</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field className="form-check-input" type="radio" name="type"
                               id="inlineRadio2" value="1" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Front Office</label>
                    </div>
                </div>
                {errors.type && touched.type ? (<div className='text-danger form-text' >{errors.type}</div>) : null}
            </div>
            <div className="col-12 mb-3">
                <Field>
                    {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                      }: FieldProps) => (
                        <div className={'text-center w-100'}>
                            <ColorPicker
                                onChange={(color: string) => setFieldValue('color', color)}
                                selected={values.color}
                            />
                        </div>
                    )}
                </Field>
                {errors.color && touched.color ? (<div className='text-danger form-text' >{errors.color}</div>) : null}
            </div>
            <button className='btn btn-outline-primary' type="submit">Enregistrer</button>
        </Form>
           </>
}


export default JobForm;