import * as types from "../../constants/counter"
import * as models from "../../models/counter"
import arrayReplace from "../../utils/arrayReplace"

const counter = (state: models.initialStateInterface = models.initialState, action:
    {
        type: string,
        payload: models.TotalCounters,
        single: models.TotalCounter,
        error: string
    }
) => {
    switch (action.type) {

        case types.FETCH_COUNTERS:
            return { payload: [], single: state.single, isLoading: true, error: null, hasChanged: false };
        case types.FETCH_COUNTERS_SUCCESS:
            return { payload: action.payload, single: state.single, isLoading: false, error: null, hasChanged: false };
        case types.FETCH_COUNTERS_FAILURE:
            return { payload: [], single: [], isLoading: false, error: action.error, hasChanged: false };

        case types.SHOW_COUNTER:
            return { payload: state.payload, single: [], isLoading: false, error: null, hasChanged: false };
        case types.SHOW_COUNTER_SUCCESS:
            return { payload: state.payload, single: action.single, isLoading: false, error: null, hasChanged: false };
        case types.SHOW_COUNTER_FAILURE:
            return { payload: state.payload, single: [], isLoading: false, error: action.error, hasChanged: false };

        case types.EDIT_COUNTER:
            return { payload: state.payload, single: state.single, isLoading: false, error: null, hasChanged: false };
        case types.EDIT_COUNTER_SUCCESS:
            return { payload: arrayReplace(state.payload, action.single), single: action.single, isLoading: false, error: null, hasChanged: !state.hasChanged };
        case types.EDIT_COUNTER_FAILURE:
            return { payload: state.payload, single: [], isLoading: false, error: action.error, hasChanged: false };
        case types.REFRESH_COUNTER:
            state.hasChanged = !state.hasChanged;
            return {...state};
       default:
            return state;
    }
}

export default counter;