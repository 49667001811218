import React, {useEffect, useState} from "react";
import FullCalendar, {DateSelectArg, EventClickArg} from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Salary} from "../../../../../models/salary";
import {Company} from "../../../../../models/companies";
import format from "../../../../../utils/locale";
import allLocales from "@fullcalendar/core/locales-all";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from '@fullcalendar/rrule'
import {Events, SalaryTemplate} from "../../../../../models/salary/template";
import {openSidebar} from "../../../../../actions/rightSidebar";
import {
    COPY_SALARY_TEMPLATE,
    LIST_SALARY_TEMPLATE, SHOW_SALARY_TEMPLATE,
} from "../../../../../constants/rightSidebar";
import {ACCOUNTING_TYPE_ABSENCE_ACCOUNTED, ACCOUNTING_TYPE_WORK_ACCOUNTED} from "../../../../../constants/booking";
import Loader from "../../../../../utils/loader";
import SalaryTemplateEventAdd from "../event/add";
import {Modal} from "bootstrap";
import SalaryTemplateEventEdit from "../event/edit";
import * as api from "../../../../../adapters/salary/template"
import SalaryTemplateCopyWeek from "./copy";

const SalaryTemplateShow:React.FC = () => {

    const dispatch = useDispatch();
    const salary: Salary = useSelector((state: RootStateOrAny) => state.rightSidebar.data.salary)
    const id: number = useSelector((state: RootStateOrAny) => state.rightSidebar.data.id)
    const [template, setTemplate] = useState<SalaryTemplate>()
    const [loading, setLoading] = useState<boolean>(false);
    const [company, setCompany] = useState<Company>(salary.companies[0]);
    const [selectArg, setSelectArg] = useState<DateSelectArg>()
    const [eventClickArg, setEventClickArg] = useState<EventClickArg>()
    const [week, setWeek] = useState(1);
    const [nightView, setNightView] = useState(false);
    const [timeRange, setTimeRange] = useState({
        minTime: format(new Date(company.timesheet.openAt), 'HH:mm:ss'),
        maxTime: format(new Date(company.timesheet.closeAt), 'HH:mm:ss'),
    });

    const [events, setEvents] = useState<Events>([])

    useEffect(() => {
        if (!template){
            setLoading(true)
            api.show(id).then((data) => {
                setTemplate(data.data)
                setEvents(data.data.events)
                setLoading(false);
            })
        }
    }, [id])

    const pad = (num: number, size: number) => {
        let ret = num.toString();
        while (ret.length < size) ret = "0" + ret;
        return ret;
    }

    const [addModal, setAddModal] = useState<Modal>()
    const [editModal, setEditModal] = useState<Modal>()
    const [copyWeekModal, setCopyWeekModal] = useState<Modal>()

    useEffect(() => {
        if (template && !loading){
            let el = document.getElementById('salaryTemplateEventAddModal')!;
            setAddModal(new Modal(el));
        }

        return () => setAddModal(undefined)
    }, [loading])

    useEffect(() => {
        if (template && !loading){
            let el = document.getElementById('salaryTemplateEventEditModal')!;
            setEditModal(new Modal(el));
        }

        return () => setEditModal(undefined)
    }, [loading])

    useEffect(() => {
        if (template && !loading){
            let el = document.getElementById('salaryTemplateCopyWeekModal')!;
            setCopyWeekModal(new Modal(el));
        }

        return () => setCopyWeekModal(undefined)
    }, [loading])

    useEffect(() => {
        if (selectArg && addModal){
            addModal.toggle();
        }
    }, [selectArg])

    useEffect(() => {
        if (eventClickArg && editModal){
            editModal.toggle();
        }
    }, [eventClickArg])

    useEffect(() => {

        if (nightView){

            let max_hours = pad(24 + (new Date(company.timesheet.openAt)).getHours(), 2);
            let max_minutes = pad((new Date(company.timesheet.openAt)).getMinutes(), 2);

            let min_hours = pad((new Date(company.timesheet.closeAt)).getHours(), 2);
            let min_minutes = pad((new Date(company.timesheet.closeAt)).getMinutes(), 2);

            setTimeRange({
                minTime: `${min_hours}:${min_minutes}:00`,
                maxTime: `${max_hours}:${max_minutes}:00`,
            });
        }else{
            setTimeRange({
                minTime: format(new Date(company.timesheet.openAt), 'HH:mm:ss'),
                maxTime: format(new Date(company.timesheet.closeAt), 'HH:mm:ss')
            })
        }

    }, [nightView])

    const cleanWeek = () => {
        let evt = [...events.filter(e => e.week !== week)];
        setEvents(evt)
        api.clean(id, week)
    }

    const [counter, setCounter] = useState<string>('00:00')
    useEffect(() => {
        setCounter(() => getCounter())
    }, [week, events])

    const getCounter = (): string => {

        if (!template) return "00:00"

        let sec_num = template.events.filter(e => e.week === week && e.company.id === company.id && [ACCOUNTING_TYPE_WORK_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_ACCOUNTED].includes(e.accountingType)).map((e) => e.duration * e.daysOfWeek.length).reduce((d, sum) => d + sum, 0);

        let hours   = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);

        let dHour = String(hours);
        let dMinutes = String(minutes);

        if (hours   < 10) {dHour = "0"+hours;}
        if (minutes < 10) {dMinutes = "0"+minutes;}

        return `${dHour}:${dMinutes}`;
    }

    if (!template || loading) return <div className={'p-3'}><Loader /></div>

    return <>
        <div className={'p-3 h-100'}>
            <div className="row mb-3 align-items-center">
                <div className="col-auto">
                    <button className={'btn btn-light'} onClick={() => dispatch(openSidebar(LIST_SALARY_TEMPLATE, {salary: salary}))}>
                        <i className={'bi bi-chevron-left'}></i> Retour
                    </button>
                </div>
                <div className="col-auto">
                    <div className="dropdown shadow-sm">
                        <button className="btn bg-white dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <i className={'bi bi-shop'}></i> {company.title}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {salary.companies.map(c => <li onClick={() => setCompany(c)} className={'dropdown-item' + (c.id === company.id ? ' active text-white' : '')}>
                                {c.id === company.id && <i className={'bi bi-check'}></i>} {c.title}
                            </li>)}
                        </ul>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="btn-group shadow-sm">
                        <button className={'btn bg-white'}>
                            Semaine
                        </button>
                        {Array.from(Array(company.timesheet.recurrence).keys()).map((i) => <button
                            onClick={() => setWeek(i + 1)}
                            className={'btn btn-outline-primary ' + (week === i + 1 ? " active text-white" : '')}>
                            {i + 1}
                        </button>)}
                    </div>
                </div>
                <div className="col-auto">
                    <div className="btn-group shadow-sm">
                        <button className={'btn bg-white'}>
                            <i className={"bi bi-clock"}></i>
                        </button>
                        <button
                            className={'btn btn-outline-primary '}>
                            {counter}
                        </button>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="d-flex">
                        <div className="form-check form-switch">
                            <input onChange={(e) => setNightView(e.target.checked)} className="form-check-input" type="checkbox" id="flexSwitchCheckNightView" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckNightView">
                                    <i className={'bi bi-moon'}></i>
                                </label>
                        </div>
                    </div>
                </div>
                <div className="col">

                </div>
                <div className="col-auto">
                    <div className="d-flex">
                        <button className={'btn btn-outline-primary me-2'} onClick={() => copyWeekModal?.toggle()}>
                            <i className={'bi bi-arrow-repeat'}></i> Dupliquer S.{week}
                        </button>
                        <button className={'btn btn-outline-danger me-2'} onClick={() => cleanWeek()}>
                            <i className={'bi bi-arrow-repeat'}></i> Supprimer S.{week}
                        </button>
                        <button className={'btn btn-outline-primary me-2'} onClick={() => {
                            dispatch(openSidebar(COPY_SALARY_TEMPLATE, {
                                next: SHOW_SALARY_TEMPLATE,
                                target: template,
                                data: {
                                    id: id,
                                    salary: salary
                                }
                            }))
                        }}>
                            <i className={'bi bi-upload'}></i> Importer
                        </button>
                    </div>
                </div>
            </div>
            <FullCalendar
                headerToolbar={false}
                plugins={[timeGridPlugin, interactionPlugin, rrulePlugin]}
                initialView={'timeGridWeek'}
                allDaySlot={false}
                height={'auto'}
                slotMinTime={timeRange.minTime}
                slotMaxTime={timeRange.maxTime}
                validRange={{
                    start: '2019-07-01',
                    end: '2017-07-07'
                }}
                initialDate={'2019-07-01'}
                locale={'fr'}
                locales={allLocales}
                selectable={true}
                events={events.filter(e => e.week === week && e.company.id === company.id).map(e => ({
                    rrule: {
                        freq: 'weekly',
                        byweekday: e.daysOfWeek,
                        dtstart: '2019-07-01 ' + e.startTime,
                        until: '2019-07-07 ' + e.startTime,
                    },
                    duration: e.duration * 1000,
                    extendedProps: {
                        event: {...e},
                        initialStart: e.start,
                        initialEnd: e.end
                    }
                }))}
                select={(e) => {
                    if (addModal){
                        setSelectArg(e)
                    }
                }}
                eventClick={(e) => {
                    if (editModal){
                        setEventClickArg(e)
                    }
                }}
                dayHeaderContent={arg => <span className={'text-primary'}>{format(arg.date, 'EEEE')}</span>}
                eventContent={e => <>
                    <b>
                        {e.timeText}
                    </b>
                    <div className="clearfix"> </div>
                    <b>
                        <i className={'bi bi-clock'}></i> {e.event.extendedProps.event.timeCaption}
                    </b>
                    {e.event.extendedProps.event.activity && <div>
                        <i className={'bi bi-bookmark'}></i> {e.event.extendedProps.event.activity.title}
                    </div>}
                    {e.event.extendedProps.event.localisation && <div>
                        <i className={'bi bi-geo-alt'}></i> {e.event.extendedProps.event.localisation.title}
                    </div>}
                </>}
            />
        </div>
        <SalaryTemplateEventAdd week={week} setEvents={setEvents} events={events} company={company} template={template} arg={selectArg} modal={addModal} />
        <SalaryTemplateEventEdit week={week} setEvents={setEvents} events={events} company={company} arg={eventClickArg} modal={editModal} />
        <SalaryTemplateCopyWeek week={week} setEvents={setEvents} company={company} template={template} modal={copyWeekModal} />
    </>
}

export default SalaryTemplateShow;