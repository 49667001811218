import * as React from "react";

import DocumentList from "../../components/document/list";
import DocumentShow from "../../components/document/show";

const DocumentPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <DocumentList />
            <DocumentShow />
        </div>
    );
}

export default DocumentPage;