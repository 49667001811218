import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import CalendarOfferEdit from "../edit";
import CalendarOfferShow from "../show";
import CalendarOfferAdd from "../add";
import {Salaries, Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import {Offcanvas} from "bootstrap";
import {EventOfferApplications} from "../../../../models/event/offer";
import CalendarOfferApplication from "../application";

interface InterfaceOfferShow {
    sidebarType: 'OFFER_SHOW',
    arg: EventClickArg,
    setCalendarOfferSidebarProps: React.Dispatch<any>
}

interface InterfaceOfferApplication {
    sidebarType: 'OFFER_APPLICATION',
    offerId: number
    applications: EventOfferApplications,
}

interface InterfaceOfferEdit {
    sidebarType: 'OFFER_EDIT',
    company: Company,
    activity?: Activity
    localisation?: Localisation,
    start: Date,
    end: Date,
    offerId: number,
    description: string,
    salaries: Salaries,
}

interface InterfaceOfferAdd {
    sidebarType: 'OFFER_ADD',
    salary?: Salary,
    company?: Company,
    localisation?: Localisation,
    activity?: Activity,
    start?: Date,
    end?: Date,
}

interface Interface {
    sidebarType: 'EMPTY',
}

export type CalendarOfferSidebarInterface = {trigger: number} & (Interface | InterfaceOfferShow | InterfaceOfferEdit | InterfaceOfferAdd | InterfaceOfferApplication)

const CalendarOfferSidebar:React.FC<CalendarOfferSidebarInterface & {handleEventChange: any}> = (props) => {

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [isOpen, setIsOpen] = useState(false)

    const reset = () => setIsOpen(false)

    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarOffer')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));

        el.addEventListener('hidden.bs.offcanvas', reset)
        return () => el.removeEventListener('hidden.bs.offcanvas', reset)
    }, [])

    useEffect(() => {
        if (isOpen){
            if (offcanvas){
                let elem = document.getElementById('offcanvasCalendarOffer')!
                offcanvas.show(elem)
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (props.trigger > 0){
            open();
        }else{
            close();
        }

    }, [props.trigger])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    function open(){
        if (!offcanvas) return;
        setIsOpen(true)
    }

    const Content = () => {
        if (offcanvas){
            switch (props.sidebarType){
                case "OFFER_EDIT":
                    return <CalendarOfferEdit key={props.trigger} close={close} handleSubmit={props.handleEventChange} company={props.company} start={props.start} end={props.end} offerId={props.offerId} description={props.description} salaries={props.salaries} activity={props.activity} localisation={props.localisation} />
                case "OFFER_SHOW":
                    return <CalendarOfferShow key={props.trigger} arg={props.arg} setCalendarOfferSidebarProps={props.setCalendarOfferSidebarProps} close={close} handleSubmit={props.handleEventChange} />
                case "OFFER_ADD":
                    return <CalendarOfferAdd key={props.trigger} close={close} salary={props.salary} handleSubmit={props.handleEventChange} company={props.company} localisation={props.localisation} activity={props.activity} start={props.start} end={props.end} />
                case "OFFER_APPLICATION":
                    return <CalendarOfferApplication key={props.trigger} close={close} applications={props.applications} offerId={props.offerId} handleSubmit={props.handleEventChange} />
            }
        }

        return <span className={'form-text text-danger'}><i className={'bi bi-exclamation-circle'}></i> Une erreur est survenue veuillez réésayer</span>
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarOffer" aria-labelledby="offcanvasCalendarOfferLabel">
        {isOpen && <Content/>}
    </div>
}

function areEqual(prevProps: CalendarOfferSidebarInterface & {handleEventChange: any}, nextProps: CalendarOfferSidebarInterface & {handleEventChange: any}){
    return prevProps.trigger === nextProps.trigger;
}

export default React.memo(CalendarOfferSidebar, areEqual);