import React, {useState} from "react";
import {Absence} from "../../../../models/absence";
import * as fileApi from "../../../../adapters/absence/file";
import {axiosError} from "../../../../actions/axios";
import {showAlertDanger, showAlertSuccess} from "../../../../actions/alert";
import FileSaver from "file-saver";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Loader from "../../../../utils/loader";
import {Granted} from "../../../../security/granted";
import * as accessRights from "../../../../constants/accessRight";
import {openSidebar} from "../../../../actions/rightSidebar";
import {FORM_NEW_ABSENCE_FILE} from "../../../../constants/rightSidebar";
import {editAbsenceSuccess} from "../../../../actions/absence";

const AbsenceFileShow:React.FC = () => {

    const absence: Absence = useSelector((state: RootStateOrAny) => state.absence.single);

    const [downloading, setDownloading] = useState<number>();
    const [deleting, setDeleting] = useState<number>();
    const [justificatifUrl, setJustificatifUrl] = useState<string>()
    const [loadingFile, setLoadingFile] = useState<boolean>(false)
    const dispatch = useDispatch();

    const displayFile = (id: number) => {

        if (!absence) return false;

        let file = absence.files?.find((file => file.id === id))
        if (file){
            setLoadingFile(true)
            fileApi.download(absence.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                let url = URL.createObjectURL(newBlob);
                setJustificatifUrl(url)
                setLoadingFile(false)

            }).catch(error => dispatch(axiosError(error)))
        }else{
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const downloadJustification = (id: number) => {

        if (!absence) return false;

        let file = absence.files?.find((file => file.id === id))
        if (file){
            setDownloading(id);
            fileApi.download(absence.id!, id).then(data => {
                let newBlob = new Blob([data.data], { type: data.headers["content-type"] })
                FileSaver.saveAs(newBlob, file!.title)
                setDownloading(undefined)
            }).catch(error => dispatch(axiosError(error)))
        }else{
            dispatch(showAlertDanger('Une erreur est survenue lors du téléchargement ! Fichier introuvable.'))
        }
    }

    const deleteJustification = (id: number) => {

        if (!absence) return false;

        let file = absence.files?.find((file => file.id === id))
        if (file){
            setDeleting(id);
            fileApi.remove(absence.id!, id).then(resp => {
                let files = [...absence.files!]
                files.splice(files.findIndex(file => file.id === id), 1);
                absence.files = [...files];
                setDeleting(undefined)
                dispatch(editAbsenceSuccess(resp.data))
                dispatch(showAlertSuccess('Fichier supprimé'))
            }).catch(error => dispatch(axiosError(error)))
        }else{
            dispatch(showAlertDanger('Une erreur est survenue lors de la supression ! Fichier introuvable.'))
        }
    }

    return <>
        <div className="col-12">
            <div className="d-flex mb-3">
                <div className="flex-grow-1">
                    Fichiers
                </div>
                <div className="flex-grow-0">
                    {Granted(accessRights.EDIT_ABSENCE, undefined, absence) && <button onClick={() => dispatch(openSidebar(FORM_NEW_ABSENCE_FILE, {
                        absence: absence
                    }))} className={'btn btn-sm w-100 btn-outline-primary ms-2'}>
                        <i className={'bi bi-plus'}> </i> <span>Ajouter</span>
                    </button>}
                </div>
            </div>
        </div>
        <ul className="list-group list-group-flush">
            {absence?.files?.map((file, index) => <>
                <li className={'list-group-item d-flex flex-column'}>
                    <h4>
                        {file.title}
                    </h4>
                    <div className="btn-group">
                        <button onClick={() => displayFile(file.id)} type="button" className="btn btn-light me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#justificatifModal">
                            <i className={'bi bi-eye'}> </i> Afficher
                        </button>
                        <button className={"btn btn-light me-2"} onClick={() => downloadJustification(file.id)}>
                            {downloading === file.id ? <div className="spinner-grow spinner-grow-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : <><i className="bi bi-download text-primary"> </i> Télécharger</>}
                        </button>
                        {Granted(accessRights.EDIT_ABSENCE, undefined, absence)  && <button className={"btn btn-light me-2"} onClick={() => deleteJustification(file.id)}>
                            {deleting === file.id ?
                                <div className="spinner-grow spinner-grow-sm"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> :
                                <><i className="bi bi-dash-circle text-danger"> </i> Supprimer</>}
                        </button>}
                    </div>
                </li>
            </>)}
        </ul>
        <div className="modal" id="justificatifModal" tabIndex={-1}>
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content h-100">
                    <div className="modal-body d-flex flex-column" id={'#modalBody'}>
                        {loadingFile && <Loader />}
                        {!loadingFile && justificatifUrl && <iframe src={justificatifUrl} width={'100%'} className={'flex-grow-1'} frameBorder={0}>
                        </iframe>}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AbsenceFileShow;