import React, {useEffect, useState} from "react";
import * as jobApi from "../../../adapters/job"
import * as localisationApi from "../../../adapters/localisation"
import * as activityApi from "../../../adapters/activity"
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Loader from "../../../utils/loader";
import {Job} from "../../../models/job";
import {Localisation} from "../../../models/localisation";
import {Activity} from "../../../models/activity";

interface Interface {
    sortBy: number,
    resources: any[],
    setCalendarData: React.Dispatch<any>
}

export const CalendarOrder:React.FC<Interface> = (props) => {

    const {sortBy, resources, setCalendarData} = props;
    const [items, setItems] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        let el = document.getElementById("offcanvasCalendarOrder")!

        el.addEventListener("shown.bs.offcanvas", open)
        el.addEventListener("hidden.bs.offcanvas", close)


        if (isOpen){
            setIsLoading(true)
            switch (sortBy){
                case 1:
                    jobApi.list().then(data => setItems(data.data.sort((a: Job, b: Job) => a.position! - b.position!))).then(() => setIsLoading(false));
                    break;
                case 2:
                    localisationApi.select().then(data => setItems(data.data.sort((a: Localisation, b: Localisation) => a.position! - b.position!))).then(() => setIsLoading(false));
                    break;
                case 3:
                    activityApi.select().then(data => setItems(data.data.sort((a: Activity, b: Activity) => a.position! - b.position!))).then(() => setIsLoading(false));
                    break;
            }
        }

        return () => {
            el.removeEventListener("shown.bs.offcanvas", open)
            el.removeEventListener("hidden.bs.offcanvas", close)
        }
    }, [sortBy, isOpen])

    function open(){
        setIsOpen(true)
    }
    function close(){
        setIsOpen(false)
    }

    const onDragStart = (e: any) => {
        e
            .dataTransfer
            .setData('text/plain', e.target.id);

        e.target.style.textColor = '#6c757d';
    }

    const onDragEnd = (e: any) => {
        e
            .dataTransfer
            .clearData();

        e.target.style.textColor = '';
    }

    function onDrop(event: any) {
        const id = event
            .dataTransfer
            .getData('text');

        const draggableElement = document.getElementById(id);
        const dropzone = event.currentTarget;

        if (id === dropzone.id){
            dropzone.style.borderBottom = "";
        }

        if (id !== dropzone.id && dropzone.parentNode.id === "dragAndDropzone" && draggableElement){
            dropzone.parentNode.insertBefore(draggableElement, dropzone.nextSibling);
            dropzone.style.borderBottom = "";
            reOrderItems();
            draggableElement.style.border = "solid #20c997 1px";
            setTimeout(() => draggableElement.style.border = '', 1000)
        }

        event
            .dataTransfer
            .clearData();
    }

    function reOrderItems() {
        let items = document.getElementsByClassName('draggable') as HTMLCollectionOf<HTMLDivElement>;
        let data = [];
        for (let i = 0; i < items.length; i++) {
            data.push({
                id: Number(items[i].dataset.target),
                position: i + 1
            })
            items[i].getElementsByClassName('counter')![0].textContent = String(i + 1);
        }

        handleDataChange(data);
    }

    const handleDataChange = (data: {id: number, position: number}[]) => {
        switch (sortBy){
            case 1:
                jobApi.order(data).then(() => handleChangeSuccess(data));
                break;
            case 2:
                localisationApi.order(data).then(() => handleChangeSuccess(data));
                break;
            case 3:
                activityApi.order(data).then(() => handleChangeSuccess(data));
                break;
        }
    }

    function handleChangeSuccess(order: {id: number, position: number}[])
    {

        let _resources = resources.map((r) =>  {

            let _r = {...r};

            if (r.hierarchy === 'parent'){
                let resourceOrder = order.find(o => o.id === r.itemId);

                if (resourceOrder){
                    _r.order1 = resourceOrder.position;
                }
            }

            return _r;
        })

        setCalendarData((prev: any) => ({...prev, resources: [..._resources]}))
    }

    function onDragOver(e: any) {
        e.preventDefault();
        e.currentTarget.style.borderBottom = "solid #727cf5 1px";
    }

    function onDragLeave(e: any) {
        e.currentTarget.style.borderBottom = "";
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" data-bs-scroll="true" tabIndex={-1} id="offcanvasCalendarOrder" aria-labelledby="offcanvasCalendarOrderLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarOrderLabel">Ordonner le planning</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className={'p-1 p-md-3 d-flex flex-column h-100'}>
                    <span className="form-text p-2 flex-grow-0">
                        Cliquez glissez les éléments pour choisir la position
                    </span>
                {isLoading ? <Loader /> : <><div className={'col-12 flex-grow-1'} id={'dragAndDropzone'}>
                    <div className="p-2"
                         onDragLeave={onDragLeave}
                         onDragOver={onDragOver}
                         onDrop={onDrop}
                    >
                    </div>
                    {items.map((item, index) => <div
                        id={`draggable-${index}`}
                        draggable={true}
                        data-target={item.id}
                        style={{backgroundColor: "white"}}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        className={'p-1 d-flex align-items-center draggable mb-1'}>
                        <div className={'flex-grow-1'}>
                            <i className={'bi bi-arrow-down-up'}> </i> {item.title}
                        </div>
                        <span className={'flex-grow-0 counter'}>
                                {index + 1}
                            </span>
                    </div>)}
                </div>
                </>}
            </div>
        </div>
    </div>
}

export default CalendarOrder;