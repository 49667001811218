import {post, get} from "../xhr";
import * as models from "../../models/taskGroup";

interface listPayload {
    offset?: number,
    query?: string
}
export function list(requestData?: listPayload){
    return post('task/group/list', requestData || {});
}

export function edit(requestData: models.TaskGroup, id: number){
    return post('task/group/edit/' + id, requestData);
}

export function create(requestData: models.TaskGroup){
    return post('task/group/create',  requestData);
}

export function show(id: string){
    return get('task/group/show/' + id);
}
export function deactivate(id: number){
    return get('task/group/deactivate/' + id);
}