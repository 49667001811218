import React, {useEffect, useState} from "react";
import {SalaryTemplate, SalaryTemplates} from "../../../../models/salary/template";
import {Salary} from "../../../../models/salary";
import SalaryTemplateAddForm from "./form";
import {Offcanvas} from "bootstrap";

const SalaryTemplateAdd:React.FC<{
    templates: SalaryTemplates,
    salary: Salary,
    setTemplates: React.Dispatch<any>,
}> = (props) => {

    const {templates, salary, setTemplates } = props;
    const [offcanvas, setOffcanvas] = useState<Offcanvas>()
    const [lastEnd, setLastEnd] = useState<Date>()

    useEffect(() => {
        const el = document.getElementById('offcanvasSalaryTemplateAdd')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    const maxDate = (dates: Date[]) => new Date(Math.max(...dates.map(Number)));

    useEffect(() => {
        if(templates.length > 0 && templates.filter(t => !t.end).length  === 0) {
            setLastEnd(maxDate(templates.map(t => new Date(t.end!))));
        }else{
            setLastEnd(undefined)
        }
    }, [templates])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const handleSubmit = (t: SalaryTemplate) => {
        setTemplates([...templates, t])
        close()
    }

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasSalaryTemplateAdd" aria-labelledby="offcanvasSalaryTemplateAddLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasSalaryTemplateAddLabel">Ajouter un emploi du temps</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <SalaryTemplateAddForm templates={templates} salary={salary} handleSubmit={handleSubmit} lastEnd={lastEnd} duplicate={false} />
        </div>
    </div>
}

export default SalaryTemplateAdd;