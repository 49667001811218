import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {openSidebar} from "../../../actions/rightSidebar";
import * as api from "../../../adapters/task"
import * as taskGroupApi from "../../../adapters/taskGroup"
import {FORM_EDIT_TASK, FORM_NEW_TASK, FORM_NEW_TASK_GROUP_CUSTOM_FIELD} from "../../../constants/rightSidebar";
import {TaskGroupCustomField} from "../../../models/taskGroup/customField";
import * as taskModels from "../../../models/task";
import format from "../../../utils/locale";
import {Salary} from "../../../models/salary";
import {CustomFieldValue} from "../../../constants/taskGroup/customField";
import {Link, useParams} from "react-router-dom";
import * as actions from "../../../actions/task";
import {showTaskGroup, showTaskGroupSuccess} from "../../../actions/taskGroup";
import {setPageTitle} from "../../../actions/header";
import {TaskGroup} from "../../../models/taskGroup";
import {Tooltip} from "bootstrap"
import {Task} from "../../../models/task";
import * as statusApi from "../../../adapters/task/status";
import * as accessRights from "../../../constants/accessRight";
import BgSingle from "../../../utils/background/single";
import {Granted, GrantedAny} from "../../../security/granted";

const TaskGroupShow:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFiltering, setIsFiltering] = useState<boolean>(false);
    const [query, setQuery] = useState<string|undefined>();
    const params: {id: string} = useParams();
    const taskGroup = useSelector((state:RootStateOrAny) => state.taskGroup.payload.find((a: TaskGroup) => a.id!.toString() === params.id))
    const [currentTab, setCurrentTab] = useState('single')
    const [statusUpdating, setStatusUpdating] = useState<boolean|string>(false)

    useEffect(() => {
        setIsLoading(true)
        dispatch(showTaskGroup());
        if (params.id){
            if (taskGroup){
                dispatch(showTaskGroupSuccess(taskGroup))
                dispatch(setPageTitle('Tâches', taskGroup.title))
            }else if(!taskGroup){
                taskGroupApi.show(params.id).then(data => {
                    dispatch(showTaskGroupSuccess(data.data))
                    dispatch(setPageTitle('Tâches', data.data.title))
                })
            }
        }

        api.list({}, params.id).then(data => {
            dispatch(actions.fetchTasksSuccess(data.data))
            setIsLoading(false)
        }).catch(error => {
            dispatch(actions.fetchTasksFailure(error.response.data.message))
        })

    }, [params.id])

    useEffect(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }, [state.task.payload])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setIsFiltering(true);
                api.list({}, params.id).then(data => {
                    dispatch(actions.fetchTasksSuccess(data.data))
                    setIsFiltering(false);
                }).catch(error => {
                    dispatch(actions.fetchTasksFailure(error.response.data.message))
                })
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    const removeTask = (task: taskModels.Task) => {
        api.cancel(String(task.id!)).then(() => dispatch(actions.deleteTaskSuccess(task)))
    }

    if (!taskGroup || !params.id) {
        return <BgSingle showLoader={!!params.id} hasId={!!params.id} />
    }

    const updateStatus = (task: Task, status: number) => {
        setStatusUpdating(task.exDate)
        if (task.status){
            statusApi.edit({exDate: task.exDate, status: status}, task.status.id, task.id!).then((data) => {
                task.status = data.data;
                dispatch(actions.editTaskSuccess(task))
                setStatusUpdating(false)
            })
        }else{
            statusApi.create({exDate: task.exDate, status: status}, task.id!).then((data) => {
                task.status = data.data;
                dispatch(actions.editTaskSuccess(task))
                setStatusUpdating(false)
            })
        }
    }

    const NavMenu = () => {
        return <div className={'w-100'}>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button onClick={() => setCurrentTab('single')} className={"nav-link text-primary" + (currentTab === 'single' ? ' active' : '')} aria-current="page" >
                        Unique {!isLoading && <span className="badge rounded-pill bg-primary">
                           {state.task.payload.filter((task: Task) => task.recurrence.type === 1).length}
                       </span>}
                    </button>
                </li>
                <li className="nav-item">
                    <button onClick={() => setCurrentTab('multiple')} className={"nav-link text-primary" + (currentTab === 'multiple' ? ' active' : '')} >
                        Multiples {!isLoading && <span className="badge rounded-pill bg-primary">
                           {state.task.payload.filter((task: Task) => task.recurrence.type > 1).length}
                       </span>}
                    </button>
                </li>
            </ul>
        </div>
    }

    return  <div id={'singleContainer'} className={'container-fluid ' + (!params.id ? " d-none d-md-block" : "")}>
        <div className="col-12 p-1 p-md-3 h-100 d-flex flex-column">
            <div className="p-1 p-md-3 card shadow-sm flex-grow-1">
                <div className="row mb-3">
                    <div className="col-auto">
                        <Link to={"/task_group"} className={'btn btn-light'}>
                            <i className={'bi bi-chevron-left'}> </i> Retour
                        </Link>
                    </div>
                    <div className="col">
                        <div className="input-group">
                                        <span className="input-group-text border-0" id="search-addon">
                                            {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> : <i className="bi bi-search"> </i>}

                                        </span>
                            <input type="search" onChange={(e) => {
                                setQuery(e.target.value)
                            }} className="form-control border-0 bg-light" placeholder="Rechercher une tâche" aria-describedby="search-addon" />
                        </div>
                    </div>
                    {GrantedAny([
                        accessRights.EDIT_TASK,
                        accessRights.EDIT_TASK_GROUP,
                        accessRights.DELETE_TASK_GROUP
                    ]) && <div className="col-auto">
                        <div className="dropdown">
                            <button className="btn btn-light dropdown-toggle dropdown-toggle-no-after-content"
                                    type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                <i className="bi bi-three-dots-vertical"> </i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton`}>
                                {Granted(accessRights.EDIT_TASK) && <li>
                                                    <span onClick={() => {
                                                        dispatch(openSidebar(FORM_NEW_TASK))
                                                    }} className="dropdown-item">
                                                      <i className={'bi bi-plus-circle'}> </i>  Ajouter une tâche
                                                    </span>
                                </li>}
                                {Granted(accessRights.EDIT_TASK_GROUP) && <li>
                                                    <span onClick={() => {
                                                        dispatch(openSidebar(FORM_NEW_TASK_GROUP_CUSTOM_FIELD))
                                                    }} className="dropdown-item">
                                                        <i className={'bi bi-code'}> </i>  Ajouter un champ personalisé
                                                    </span>
                                </li>}
                            </ul>
                        </div>
                    </div>}
                </div>
                <NavMenu />
                <div className="d-flex flex-grow-1">
                    {currentTab === 'multiple' && <div className="table-responsive flex-grow-1">
                        <table className={'table table-bordered bg-white align-middle mb-0'}>
                            <thead>
                            <tr>
                                <th>
                                    Titre
                                </th>
                                <th>
                                    recurrence
                                </th>
                                <th>
                                    Collaborateurs
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {!isLoading && state.task.payload.filter((task: Task) => task.recurrence.type > 1).length ? state.task.payload.filter((task: Task) => task.recurrence.type > 1).map((task: taskModels.Task, index: number) => ([
                                    <tr key={index}>
                                        <td>
                                            <b>
                                                {task.title}
                                            </b>
                                            <div className="clearfix"> </div>
                                            <small>
                                                <i>
                                                    {task.description}
                                                </i>
                                            </small>
                                        </td>
                                        <td>
                                            <i className="bi bi-arrow-repeat"> </i> {task.recurrence.description}
                                            {/*{task.beginAt} - {task.endAt}*/}
                                            <i className={'bi bi-clock'}> </i> {format(new Date(task.beginAt), 'HH:mm')} {task.endAt && <><i
                                            className={'bi bi-arrow-left-right'}></i> {format(new Date(task.endAt), 'HH:mm')}</>}
                                        </td>
                                        <td>
                                                {task.salaries?.map((salary: Salary, index) => {
                                                    return (
                                                        <span className={'text-capitalize'}>
                                                            {salary.firstname} {salary.lastname[0]}.{index + 1 < task.salaries.length && ', '}
                                                        </span>
                                                    )
                                                })}
                                        </td>
                                        {Granted(accessRights.EDIT_TASK, undefined, task) && <td>
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-light dropdown-toggle dropdown-toggle-no-after-content"
                                                    type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <i className="bi bi-three-dots-vertical"> </i>
                                                </button>
                                                <ul className="dropdown-menu"
                                                    aria-labelledby={`dropdownMenuButton${index}`}>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                onClick={() => dispatch(openSidebar(FORM_EDIT_TASK, {
                                                                    id: task.id,
                                                                    group: taskGroup,
                                                                    title: task.title,
                                                                    description: task.description,
                                                                    allDay: task.allDay,
                                                                    beginAt: task.beginAt,
                                                                    endAt: task.endAt,
                                                                    salaries: task.salaries,
                                                                    exDate: task.exDate,
                                                                    customFieldsValues: task.customFieldsValues,
                                                                    recurrence: task.recurrence,
                                                                    preventException: true
                                                                }))}>
                                                            <i className={'bi bi-pencil me-2'}> </i> Modifier
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item" onClick={() => removeTask(task)}>
                                                            <i className={'bi bi-dash-circle me-2'}> </i> Archiver
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>}
                                    </tr>
                                ]))
                                :
                                <tr>
                                    <td colSpan={100}>
                                        {isLoading ?
                                            <button key={0} type="button" className="list-group-item w-100 text-center"
                                                    aria-current="true" disabled>
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                            :
                                            <button key={0} type="button" className="list-group-item text-center w-100"
                                                    aria-current="true" disabled>
                                                Aucune tâche récurrente dans {taskGroup.title}
                                            </button>}
                                    </td>
                                </tr>
                            }</tbody>
                        </table>
                    </div>}

                    {currentTab === 'single' && <div className="table-responsive flex-grow-1">
                        <table className={'table text-nowrap table-bordered bg-white align-middle'}>
                            <thead>
                            <tr>
                                <th>
                                    Titre
                                </th>
                                <th>
                                    Dates
                                </th>
                                <th>
                                    Collaborateurs
                                </th>
                                <th>
                                    Statut
                                </th>
                                {taskGroup.customFields && taskGroup.customFields.map((customField: TaskGroupCustomField, index: number) => (
                                    <th className={"text-nowrap"}>
                                        <i className={'bi bi-code'}> </i>  {customField.title}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {!isLoading && state.task.payload.filter((task: Task) => task.recurrence.type === 1).length ? state.task.payload.filter((task: Task) => task.recurrence.type === 1).map((task: taskModels.Task, index: number) => ([
                                    <tr key={index}>
                                        <td>
                                            <b>
                                                {task.title}
                                            </b>
                                            <div className="clearfix"> </div>
                                            <small>
                                                <i>
                                                    {task.description}
                                                </i>
                                            </small>
                                        </td>
                                        <td>
                                            {task.allDay ? format(new Date(task.beginAt), 'E dd/MM') : format(new Date(task.beginAt), 'E dd/MM HH:mm')}
                                            {!task.allDay && task.endAt && ' - ' + format(new Date(task.endAt), 'HH:mm')}
                                            <div className="clearfix"> </div>
                                            {task.allDay && 'Toute la journée'}
                                        </td>
                                        <td>
                                            {task.salaries?.map((salary: Salary, index) => {
                                                return (
                                                    <span className={'text-capitalize'}>
                                                            {salary.firstname} {salary.lastname[0]}.{index + 1 < task.salaries.length && ', '}
                                                        </span>
                                                )
                                            })}
                                        </td>
                                        <td className={'text-center'}>
                                            <div className="dropdown">
                                                <button className={'btn dropdown-toggle'}
                                                        type="button" id={`dropdownStatus${task.id}`}
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    {statusUpdating === task.exDate &&
                                                    <div className="spinner-grow fs-2 text-dark" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>}
                                                    {statusUpdating !== task.exDate && !task.status?.val && <i className={'bi bi-clock-history text-warning fs-2'}> </i>}
                                                    {statusUpdating !== task.exDate && task.status?.val === 1 && <i className={'bi bi-clock-history text-warning fs-2'}> </i>}
                                                    {statusUpdating !== task.exDate && task.status?.val === 2 && <i className={'bi bi-check2-circle text-success fs-2'}> </i>}
                                                    {statusUpdating !== task.exDate && task.status?.val === 3 && <i className={'bi bi-exclamation-circle text-danger fs-2'}> </i>}
                                                </button>
                                                <ul style={{minWidth: '5rem'}} className="dropdown-menu text-center"
                                                    aria-labelledby={`dropdownStatus${task.id}`}>
                                                    {task.status?.val && task.status.val !== 1 && <li>
                                                        <button onClick={() => updateStatus(task, 1)}
                                                                className="btn d-inline-block">
                                                            <i className={'bi bi-clock-history text-warning fs-2'}> </i>
                                                        </button>
                                                    </li>}
                                                    {task.status?.val !== 2 && <li>
                                                        <button onClick={() => updateStatus(task, 2)}
                                                                className="btn d-inline-block">
                                                            <i className={'bi bi-check2-circle text-success fs-2'}> </i>
                                                        </button>
                                                    </li>}
                                                    {task.status?.val !== 3 && <li>
                                                        <button onClick={() => updateStatus(task, 3)}
                                                                className="btn d-inline-block">
                                                            <i className={'bi bi-exclamation-circle text-danger fs-2'}> </i>
                                                        </button>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </td>
                                        {taskGroup.customFields && taskGroup.customFields.map((customField: TaskGroupCustomField, index: number) =>
                                            <td className={'position-relative'} key={index}>
                                                <CustomFieldValue customFieldsValues={task.customFieldsValues} customField={customField}/>
                                            </td>
                                        )}
                                        {Granted(accessRights.EDIT_TASK, undefined, task) && <td>
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-light dropdown-toggle dropdown-toggle-no-after-content"
                                                    type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <i className="bi bi-three-dots-vertical"> </i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                onClick={() => dispatch(openSidebar(FORM_EDIT_TASK, {
                                                                    id: task.id,
                                                                    group: taskGroup,
                                                                    title: task.title,
                                                                    description: task.description,
                                                                    allDay: task.allDay,
                                                                    beginAt: task.beginAt,
                                                                    endAt: task.endAt,
                                                                    salaries: task.salaries,
                                                                    exDate: task.exDate,
                                                                    customFieldsValues: task.customFieldsValues,
                                                                    recurrence: task.recurrence,
                                                                    preventException: true
                                                                }))}>
                                                            <i className={'bi bi-pencil me-2'}> </i> Modifier
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item" onClick={() => removeTask(task)}>
                                                            <i className={'bi bi-dash-circle me-2'}> </i> Archiver
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>}
                                    </tr>
                                ]))
                                :
                                <tr>
                                    <td colSpan={100}>
                                        {isLoading ?
                                            <button key={0} type="button" className="list-group-item w-100 text-center"
                                                    aria-current="true" disabled>
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                            :
                                            <button key={0} type="button" className="list-group-item text-center w-100"
                                                    aria-current="true" disabled>
                                                Aucune tâche récurrente dans {taskGroup.title}
                                            </button>}
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
        </div>
}

export default TaskGroupShow;