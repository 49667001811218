import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import SalarySelector from "../../salary/selector";

import * as models from "../../../models/expense";
import { Salaries } from "../../../models/salary";

import { PAGINATION } from "../../../constants/global";
import { FORM_EXPENSE_ADD } from "../../../constants/rightSidebar";
import * as accessRights from "../../../constants/accessRight";
import * as types from "../../../constants/expense";
import * as globals from "../../../constants/global";

import * as api from "../../../adapters/expense";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import * as actions from "../../../actions/expense";
import { openSidebar } from "../../../actions/rightSidebar";

import { Granted } from "../../../security/granted";
import LoaderList from "../../../utils/loaderList";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import format from "../../../utils/locale";

const ExpenseList: React.FC = () => {
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    const expenses = useSelector((state:RootStateOrAny) => state.expense.payload)
    const single = useSelector((state:RootStateOrAny) => state.expense.single)

    const [offset, setOffset] = useState<number>(0);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fetchMore, setFetchMore] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<number[]>([1,2,3]);
    const [salaries, setSalaries] = useState<number[]>([]);
    const [expenseTypes, setExpenseTypes] = useState<number[]>(types.getTypeChoices().map(t => Number(t.value)));

    useEffect(() => {
        dispatch(setPageTitle('Notes de frais'))
        dispatch(setHelpProductSheetIds([13]))

        let controller = new AbortController();

        setIsLoading(true);

        dispatch(actions.fetchExpenses());
        api.list({types: expenseTypes, status: statuses, salaries: salaries, offset: 0}, controller.signal).then((data) => {
            dispatch(actions.fetchExpensesSuccess(data.data))
            setOffset(1);
            setIsLoading(false);
            setIsLast(data.data.length < PAGINATION);
        })

        return () => {
            dispatch(setHelpProductSheetIds([]))
            controller.abort()
        };
    }, [statuses, salaries, expenseTypes])

    useEffect(() => {
        let controller = new AbortController();

        if (!isLast && fetchMore)
        {
            api.list({offset: offset, types: expenseTypes, status: statuses, salaries: salaries}, controller.signal).then((data) => {
                dispatch(actions.fetchExpensesSuccess([...expenses, ...data.data]))
                setOffset(prevState => prevState + 1);
                setIsLoading(false);
                setIsLast(data.data.length < PAGINATION);
            })
        }

        return () => controller.abort();
    }, [fetchMore])

    return (
        <>
            <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
                <div className="w-100">
                    <div className="sticky-top bg-light d-flex">
                        <button data-bs-toggle="modal" data-bs-target="#filterModal" className='btn btn-outline-primary flex-grow-1'>
                            <i className={'bi bi-filter'}> </i>
                        </button>

                        {Granted(accessRights.EDIT_EXPENSE) &&
                            <button onClick={() => dispatch(openSidebar(FORM_EXPENSE_ADD))}
                                    className='btn btn-outline-primary flex-grow-1'>
                                <i className={'bi bi-plus-circle'}> </i>
                            </button>
                        }
                    </div>

                    {expenses.length ? expenses.map((expense: models.Expense, index: number) => ([
                            <Link to={"/expense/" + expense.id} key={index} className={"px-3 list-group-item list-group-item-action" + (single && single.id === expense.id ? " active" : '')}>
                                <div className="d-flex align-items-center">
                                    <div className="col">
                                        <h4 className={'mb-0'}> {expense.title}</h4>
                                        <div className="clearfix"> </div>
                                        <p className={'mb-0'}>
                                            {expense.salary.title}
                                        </p>
                                        <div className="clearfix"> </div>
                                        <p className={'mb-0 form-text'}>
                                            {format(new Date(expense.dueDate))}
                                        </p>
                                    </div>
                                    <div className="col-auto">
                                        <div className="d-flex flex-column">
                                            <p className="mb-0">
                                                <i className={types.getStatusIcon(expense.status)}></i> {types.STATUS_NAMES[expense.status]}
                                            </p>
                                            <div className="">
                                                {expense.amount} {globals.CurrencyIcon(expense.currency)}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Link>
                        ]))
                        :
                        !isLoading ? <button key={0} type="button" className="list-group-item text-center list-group-item-action" aria-current="true" disabled>
                            Aucune note de frais
                        </button> : ''
                    }

                    {!isLoading && !isLast && expenses.length && <button onClick={() => setFetchMore(true)} className={'list-group-item text-primary text-center list-group-item-action'}>
                        <i className={'bi bi-arrow-repeat'}> </i> Charger plus
                    </button>}

                    {isLoading && !expenses.length && <LoaderList title={true} listLength={50} description={true} leftCircle={true} />}
                    {isLoading && !!expenses.length && <LoaderList title={true} listLength={1} description={true} leftCircle={true} />}
                </div>
            </div>

            <div className="modal fade" id="filterModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Filtrer</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>

                        <div className="modal-body">
                            <div className="col-12 mb-3">
                                <BootstrapSelect
                                    isMultiple={true}
                                    label={"Statut"}
                                    values={[
                                        {label: "En attente", value: 1},
                                        {label: "Acceptée", value: 2},
                                        {label: "Refusée", value: 3},
                                    ]}
                                    options={[
                                        {label: "En attente", value: 1},
                                        {label: "Acceptée", value: 2},
                                        {label: "Refusée", value: 3},
                                    ]}
                                    labelIcon={'bi bi-shop text-primary'}
                                    onChangeMultiple={(choices) => setStatuses(choices.map(c => Number(c.value)))}
                                />
                            </div>

                            <div className="col-12 mb-3">
                                <BootstrapSelect
                                    isMultiple={true}
                                    label={"Type"}
                                    placeholder={"Tous les types"}
                                    labelIcon={'bi bi-shop text-primary'}
                                    options={types.getTypeChoices()}
                                    onChangeMultiple={(choices) => setExpenseTypes(choices.map(c => Number(c.value)))}
                                />
                            </div>

                            <div className="col-12 mb-3">
                                <SalarySelector
                                    fetchOptions={true}
                                    required={true}
                                    multiple={true}
                                    onChange={(choices: Salaries) => setSalaries(choices.map(c => Number(c.id)))}
                                    accessRight={accessRights.EDIT_ABSENCE}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseList;