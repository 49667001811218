import {get, Options, post} from "../../xhr";

export interface requestPayload {
    query?: string,
    offset?: number
}

export function list(requestData?: requestPayload, optionalHeader?: Options){
    return post('admin/salary/list', requestData || {}, optionalHeader);
}

export function show(id: number, optionalHeader?: Options){
    return get(`admin/salary/show/${id}`, optionalHeader);
}

export function sendConfirmation(id: number, optionalHeader?: Options){
    return get(`admin/salary/user/confirm/${id}`, optionalHeader);
}

export function enable(id: number, optionalHeader?: Options){
    return get(`admin/salary/user/enable/${id}`, optionalHeader);
}

export function removeUser(id: number, optionalHeader?: Options){
    return get(`admin/salary/user/remove/${id}`, optionalHeader);
}

export function disable(id: number, optionalHeader?: Options){
    return get(`admin/salary/user/disable/${id}`, optionalHeader);
}

export function updatePwd(id: number, requestData: {pwd: string}, optionalHeader?: Options){
    return post(`admin/salary/update_pwd/${id}`, requestData, optionalHeader);
}

export function addUser(id: number, optionalHeader?: Options){
    return get(`admin/salary/user/create/${id}`, optionalHeader);
}