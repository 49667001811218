import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as models from "../../../../models/synthesis";

import * as actions from "../../../../actions/synthesis";

import timeFormat from "../../../../utils/timeFormat"
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";

const SynthesisWorkingTimeTable:React.FC = (props) => {
    const dispatch = useDispatch();

    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);

    return (
        <table className="table sticky-table table-striped align-middle text-center">
            <thead>
                <tr>
                    <th>
                        Collaborateur
                    </th>
                    <th>
                        Contrat
                    </th>
                    <th title={"Heures réellement efféctuées"} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                        Travaillé <i className={'bi bi-info-circle'}></i>
                    </th>
                    <th title={"Heures en dessous de 8h supplémentaires aux heures contrat"} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                        H. supp. 25% <i className={'bi bi-info-circle'}></i>
                    </th>
                    <th title={"Heures au dessus de 8h supplémentaires aux heures contrat"} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                        H. supp. 50% <i className={'bi bi-info-circle'}></i>
                    </th>
                    <th title={"Heures comprises entre 5h <> 7h et 20h <> 22h"} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                        H. de nuit 20% <i className={'bi bi-info-circle'}></i>
                    </th>
                    <th title={"Heures comprises entre 22H <> 5h"} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                        H. de nuit 40% <i className={'bi bi-info-circle'}></i>
                    </th>
                    <th title={"Heures travaillées les jours fériés"} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                        H. jour férié travaillé <i className={'bi bi-info-circle'}></i>
                    </th>
                    <th title={"Heures travaillées le dimanche"} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                        H. dimanche <i className={'bi bi-info-circle'}></i>
                    </th>
                    <th>
                        Jours complets
                    </th>
                    <th>
                        Jours présents
                    </th>
                </tr>
            </thead>

            <tbody>
                {state.payload.map((s) =>
                    <tr key={s.id}>
                        <td className={'d-flex align-items-center'}>
                            <Link target={"_blank"} to={`/salary/${s.salary.id}`} className={'text-primary mb-0 flex-grow-1'}>
                                <i className="bi bi-box-arrow-up-right"></i> {s.salary.title}
                            </Link>
                            <button title={"Détail par jour"} onMouseEnter={e => onMouseEnterTooltip(e.target)} className="btn bg-primary text-white flex-grow-0" data-bs-toggle="modal" data-bs-target="#synthesisSignature" onClick={() => dispatch(actions.showSynthesisSuccess(s))}>
                                <i className={'bi bi-list-ul'}></i>
                            </button>
                        </td>
                        <td>
                            {timeFormat(s.monthlyContractWorkingTime, "decimal")}
                        </td>
                        <td>
                            {timeFormat(s.workingTime)}
                        </td>
                        <td>
                            {timeFormat(s.overtime25)}
                        </td>
                        <td>
                            {timeFormat(s.overtime50)}
                        </td>
                        <td>
                            {timeFormat(s.nightTime20)}
                        </td>
                        <td>
                            {timeFormat(s.nightTime40)}
                        </td>
                        <td>
                            {timeFormat(s.labourDayWorkingTime)}
                        </td>
                        <td>
                            {timeFormat(s.sundayWorkingTime)}
                        </td>
                        <td title={s.fullDays.join("\n")} onMouseEnter={e => onMouseEnterTooltip(e.target)}>
                            {s.fullDays.length}
                        </td>
                        <td title={s.presenceDays.join("\n")} onMouseEnter={e => onMouseEnterTooltip(e.target)}>
                            {s.presenceDays.length}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default SynthesisWorkingTimeTable;