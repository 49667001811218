import React, {useState} from "react";
import * as models from "../../../models/synthesis";
import format from "../../../utils/locale";
import {RootStateOrAny, useSelector} from "react-redux";
import timeFormat from "../../../utils/timeFormat";
import {
    ACCOUNTING_TYPE_WORK_ACCOUNTED,
    getAccountingTypeChoice,
    getAccountingTypeChoiceClassName
} from "../../../constants/booking";
import duration from "../../../utils/duration";
import {
    EVENT_TYPE_EVENT_EXCEPTION,
    EVENT_TYPE_TEMPLATE_APPLIED_EVENT, EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION,
    EVENT_TYPE_TEMPLATE_APPLIED_EVENT_EXCEPTION
} from "../../../constants/calendar";

const SynthesisSignature: React.FC = () => {
    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);
    
    const [displayOverlappingDays, setDisplayOverlappingDays] = useState(false)
    
    function toHHMMSS(secs: number){
        let sec_num = secs
        let hours   = Math.floor(sec_num / 3600)
        let minutes = Math.floor(sec_num / 60) % 60

        return [hours,minutes]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }

    return <>
        <div className="modal fade" id="synthesisSignature" tabIndex={-1} aria-labelledby="synthesisSignatureLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                {state.single && <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="synthesisSignatureLabel">
                            Feuille d'émargements de {state.single.salary.title}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className={"table table-sm align-middle text-center sticky-table"}>
                            <thead>

                            </thead>
                        {Object.values(state.single.weeks).map((week, index) => <tbody>
                            <tr>
                                <td className={'bg-primary text-white'}>
                                    S.{week.weekNumber}
                                </td>
                                <td colSpan={2} className={'bg-primary text-white'}>
                                    {format(new Date(week.start))} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(week.end))}
                                </td>
                                <td className={'bg-primary text-white'}>
                                    {week.overlapStart && <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="overlapStart" onChange={e => setDisplayOverlappingDays(e.target.checked)}/>
                                        <label className="form-check-label" htmlFor="overlapStart">
                                            Semaine complète
                                        </label>
                                    </div>}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Jour
                                </th>
                                <th>
                                    Planifié
                                </th>
                                <th>
                                    Travaillé
                                </th>
                                <th>
                                    Delta (h)
                                </th>
                            </tr>
                                {Object.values(week.days).filter(day => !day.overlapStart || displayOverlappingDays).map(day => <tr>
                                    <td>
                                        <div>
                                            {format(new Date(day.dt), "E dd")}
                                        </div>
                                        {day.isLabourDay && <div className={'text-success'}>
                                            Jour férié
                                        </div>}
                                        {!!day.nightTimeDetails.length && <div className={'form-text'}>
                                            <i className={'bi bi-moon'}></i> Heures de nuit
                                        </div>}
                                    </td>
                                    <td>
                                        <ul className={'list-unstyled mb-0'}>
                                            {!!day.absence && !day.planned.length && <li>
                                                <div className="form-text">
                                                    <i className={'bi bi-calendar-x'}></i> {day.absence.typeName}
                                                </div>
                                            </li>}
                                            {day.planned.map(e => <li>
                                                <div>
                                                    {e.eventType === EVENT_TYPE_EVENT_EXCEPTION && <i className={'bi bi-pencil text-primary'}></i>}
                                                    {[EVENT_TYPE_TEMPLATE_APPLIED_EVENT, EVENT_TYPE_TEMPLATE_APPLIED_EVENT_EXCEPTION, EVENT_TYPE_TEMPLATE_APPLIED_EVENT_APPLIED_EXCEPTION].includes(e.eventType) && <i className={'bi bi-front'}></i>}
                                                    {format(new Date(e.start), "HH:mm")} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(e.end), "HH:mm")} ({duration(new Date(e.start), new Date(e.end))})
                                                </div>
                                                {e.absence && <div className="form-text">
                                                    <i className={'bi bi-calendar-x'}></i> {e.absence.typeName}
                                                </div>}
                                                {e.comment && <div className="form-text">
                                                    <i className={'bi bi-quote'}></i> {e.comment}
                                                </div>}
                                                {e.accountingType && e.accountingType !== ACCOUNTING_TYPE_WORK_ACCOUNTED && <div className="form-text">
                                                    <i className={'bi bi-clock ' + getAccountingTypeChoiceClassName(e.accountingType)}></i> {getAccountingTypeChoice(e.accountingType).label}
                                                </div>}
                                                {!!e.nightTimeDetails?.length && <ul className={'list-unstyled'}>
                                                    {e.nightTimeDetails.map((d: {maj: "_low"|"_high", value: number}) => <li className={"small"}>
                                                        Majoration: {d.maj === "_low" ? "20%" : "40%"}, Durée: {toHHMMSS(d.value)}
                                                    </li>)}
                                                </ul>}
                                            </li>)}
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className={'list-unstyled mb-0'}>
                                            {day.done.map(e => <li>
                                                {format(new Date(e.start), "HH:mm")} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(e.end), "HH:mm")} ({duration(new Date(e.start), new Date(e.end), e.nonBadgedBreak)})
                                                {!!e.nonBadgedBreak && <>
                                                    <span className="clearfix"></span>
                                                    <span className="form-text">
                                                        <i className={'bi bi-arrow-down text-danger'}></i> {timeFormat(e.nonBadgedBreak)} de pause.
                                                    </span>
                                                </>}
                                                {!!e.nightTimeDetails?.length && <ul className={'list-unstyled'}>
                                                    {e.nightTimeDetails.map((d: {maj: "_low"|"_high", value: number}) => <li className={"small"}>
                                                        Majoration: {d.maj === "_low" ? "20%" : "40%"}, Durée: {toHHMMSS(d.value)}
                                                    </li>)}
                                                </ul>}
                                                {e.description && <div className="form-text">
                                                    <i className={'bi bi-quote'}></i> {e.description}
                                                </div>}
                                            </li>)}
                                        </ul>
                                    </td>
                                    <td>
                                        {timeFormat(day.delta)}
                                    </td>
                                </tr>)}
                            {week.overlapStart ? <>
                                {displayOverlappingDays ? <>
                                    <tr className={'btn-light'}>
                                        <th colSpan={4}>
                                            <table className={"table"}>
                                                <thead>
                                                    <th>
                                                        Contrat
                                                    </th>
                                                    <th>
                                                        Travaillé
                                                    </th>
                                                    <th>
                                                        H. d'absences ajoutées au volume d'heure
                                                    </th>
                                                    <th>
                                                        Total
                                                    </th>
                                                    <th>
                                                        Compteur
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <td>
                                                        {timeFormat(week.contractWorkingTime)}
                                                    </td>
                                                    <td>
                                                        {timeFormat(week.overlapWorkingTime)}
                                                    </td>
                                                    <td>
                                                        {timeFormat(week.overlapAbsenceAccountedTime)}
                                                    </td>
                                                    <td>
                                                        {timeFormat(week.overlapAccountedWorkingTime)}
                                                    </td>
                                                    <td>
                                                        {timeFormat(week.counter)}
                                                    </td>
                                                </tbody>
                                            </table>
                                        </th>
                                    </tr>
                                </> : <>
                                    <tr className={'btn-light'}>
                                        <th colSpan={4}>
                                            <table className="table">
                                                <thead>
                                                    <th>
                                                        Contrat
                                                    </th>
                                                    <th>
                                                        Travaillé
                                                    </th>
                                                    <th>
                                                        H. d'absences ajoutées au volume d'heure
                                                    </th>
                                                    <th>
                                                        Total
                                                    </th>
                                                    <th>
                                                        Compteur
                                                    </th>
                                                </thead>
                                                <tbody>
                                                <td>
                                                    <i className={'bi bi-x'}></i>
                                                </td>
                                                <td>
                                                    {timeFormat(week.workingTime)}
                                                </td>
                                                <td>
                                                    {timeFormat(week.absenceAccountedTime)}
                                                </td>
                                                <td>
                                                    {timeFormat(week.accountedWorkingTime)}
                                                </td>
                                                <td>
                                                    <i className={'bi bi-x'}></i>
                                                </td>
                                                </tbody>
                                            </table>
                                        </th>

                                    </tr>
                                </>}
                            </> : <>
                                <tr className={'btn-light'}>
                                    <th colSpan={4}>
                                        <table className="table">
                                            <thead>
                                                <th>
                                                    Contrat
                                                </th>
                                                <th>
                                                    Travaillé
                                                </th>
                                                <th>
                                                    H. d'absences ajoutées au volume d'heure
                                                </th>
                                                <th>
                                                    Total
                                                </th>
                                                <th>
                                                    Compteur
                                                </th>
                                            </thead>
                                            <tbody>
                                                <td>
                                                    {timeFormat(week.contractWorkingTime)}
                                                </td>
                                                <td>
                                                    {timeFormat(week.workingTime)}
                                                </td>
                                                <td>
                                                    {timeFormat(week.absenceAccountedTime)}
                                                </td>
                                                <td>
                                                    {timeFormat(week.accountedWorkingTime)}
                                                </td>
                                                <td>
                                                    {timeFormat(week.counter)}
                                                </td>
                                            </tbody>
                                        </table>
                                    </th>
                                </tr>
                            </>}
                                </tbody>)}
                            </table>
                    </div>
                </div>}
            </div>
        </div>
    </>
}

export default SynthesisSignature;