import {MULTIPART_FORM_DATA, post, get} from "../../xhr";


export function add(requestData: FormData, absenceId: number){
    return post(`absence/${absenceId}/file/add`, requestData, {headers: [MULTIPART_FORM_DATA], responseType: "json"})
}

export function remove(absenceId: number, fileId: number){
    return get(`absence/${absenceId}/file/${fileId}/remove`)
}

export function download(absenceId: number, fileId: number){
    return get(`absence/${absenceId}/file/${fileId}/download`, {responseType: "arraybuffer"})
}