import React, {useEffect, useState} from "react";
import * as api from '../../../../adapters/timetable'
import {Events, Timetable} from "../../../../models/timetable";
import {Company} from "../../../../models/companies";
import SmallLoader from "../../../../utils/loader/small";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../actions/alert";
import {Salary} from "../../../../models/salary";

const TimetableCopyWeek:React.FC<{
    week: number,
    timetable: Timetable,
    salary: Salary,
    setEvents: (events: Events) => void;
    company: Company;
    close: () => any;
}> = (props) => {

    const {week, setEvents, timetable, company, close} = props;

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [targets, setTargets] = useState<number[]>([])

    useEffect(() => {
        setTargets([])
    }, [week])

    const submit = () => {
        setLoading(true)
        api.copyEvents(timetable.id, week, targets).then(resp => {
            dispatch(showAlertSuccess('Semaine duplqué'))
            setEvents(resp.data.events)
            setLoading(false);
            close()
        })
    }

    const handleClick = (w: number) => {
        let _targets = targets;

        if (_targets.includes(w)){
            let index = _targets.findIndex(t => t === w);
            if (index !== -1){
                _targets.splice(index, 1);
                setTargets([..._targets])
            }
        }else{
            setTargets([..._targets, w]);
        }
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventDropLabel"> Dupliquer la S.{week} sur la semaine</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {<>
                <h4>
                    Dupliquer sur la semaine
                </h4>
                {timetable.type === 1 && <div className="btn-group mb-3">
                    {Array.from(Array(company.timesheet.recurrence).keys()).filter(i => i + 1 !== week).map((i) =>
                        <button
                            disabled={loading}
                            onClick={() => handleClick(i + 1)}
                            className={'btn mx-2' + (targets.includes(i + 1) ? " btn-primary text-white" : " btn-light")}>
                            {i + 1}
                        </button>)}
                </div>}
                {timetable.type === 2 && <div className="btn-group mb-3">
                    {Array.from(Array(timetable.recurrence).keys()).filter(i => i + 1 !== week).map((i) =>
                        <button
                            disabled={loading}
                            onClick={() => handleClick(i + 1)}
                            className={'btn mx-2' + (targets.includes(i + 1) ? " btn-primary text-white" : " btn-light")}>
                            {i + 1}
                        </button>)}
                </div>}
                <button onClick={() => submit()} disabled={!targets.length || loading} className={'btn btn-outline-success w-100'}>
                    {loading ? <SmallLoader /> : <><i className={'bi bi-check'}></i> Valider</>}
                </button>
            </>}
        </div>
        </>
}

export default TimetableCopyWeek;