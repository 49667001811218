import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../actions/activity";
import * as api from "../../../adapters/activity";
import * as Yup from "yup";
import {Formik, useFormik} from "formik";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import FormActivity from "../utils/form";
import {axiosError} from "../../../actions/axios";
import FormError from "../../../utils/formError";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import ColorPicker from "../../../utils/colorPicker";
import IconPicker from "../../../utils/iconPicker";
import {refresh} from "../../../actions/calendar";

const ActivitySchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
    description: Yup.string().required('Champ requis').max(255),
    color: Yup.string().nullable(),
    icon: Yup.string().nullable(),
    company: Yup.number().required(),
});

export const ActivityAdd: React.FC = () => {

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            company: null,
            icon: '',
            color: '',
        },
        validationSchema: ActivitySchema,
        onSubmit: values => {
            dispatch(submitSidebar());
            api.put(values).then(data => {
                dispatch(actions.addActivitySuccess(data.data))
                dispatch(refresh());
                dispatch(closeSidebar());
                dispatch(showAlertSuccess(data.data.title + ' à bien été ajouter !'))
            }).catch(error => dispatch(axiosError(error)))
        }
    })

    return <form onSubmit={formik.handleSubmit} className={'p-1 p-md-3'}>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input name={'title'} id={'title'} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" className="form-control"/>
                <label htmlFor="title">
                    Titre
                </label>
            </div>
            <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
        </div>
        <div className="col-12 mb-3">
            <div className="form-floating">
                <input name={'description'} id={'description'} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" className="form-control"/>
                <label htmlFor="title">
                    Description
                </label>
            </div>
            <FormError errors={formik.errors} touched={formik.touched} field={'description'} />
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                fetchEntity={"company"}
                label={"Point de vente"}
                onChange={(choice) => formik.setFieldValue('company', choice?.value)}
            />
            <FormError errors={formik.errors} touched={formik.touched} field={'company'} />
        </div>
        <div className="col-12 mb-3">
            <ColorPicker onChange={(choice) => formik.setFieldValue('color', choice)} selected={formik.values.icon} />
        </div>
        <div className="col-12 mb-3">
            <IconPicker onChange={(choice) => formik.setFieldValue('icon', choice)} />
        </div>
        <button type={'submit'} className={'btn btn-outline-primary w-100'}>
            <i className={'bi bi-plus-circle'}> </i> Ajouter
        </button>
    </form>


}

export default ActivityAdd;