import {Company} from "../companies";
import {Salary} from "../salary";
import {Job} from "../job";
import {Localisation} from "../localisation";
import {Activity} from "../activity";

export interface TemplateApplied {
    id: number,
    start: string,
    end: string,
    template: Template,
    appliedResources: AppliedResources,
    isPreview: boolean,
    isApplied: boolean
}

export type TemplateApplies = TemplateApplied[];

export interface AppliedResource {
    readonly id?: number,
    resource: Resource,
    salary: Salary
}
export type AppliedResources = AppliedResource[];

export interface Template {
    id: number,
    title: string,
    description: string|null,
    company: Company,
    owner?: any,
    visibility: number,
    resources: Resources,
    simulation: boolean
    activated: boolean
}

export type Templates = Template[];

export interface Resource {
    id: number,
    title: string,
    job: Job,
    salary: Salary|null
}

export type Resources = Resource[];

export interface Booking {
    id?: number,
    resourceId?: number,
    recurrence: BookingRecurrence
    beginAt: Date|string,
    endAt: Date|string,
    localisation: Localisation|null,
    activity: Activity|null
}

export type Bookings = Booking[];

export interface BookingRecurrence {
    type: string,
    days?: number[],
    weeks?: number[],
}

export interface initialStateInterface {
    payload: Templates,
    single: Template|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};