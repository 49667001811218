export const FETCH_COMPANY_GROUP = "FETCH_COMPANY_GROUP"
export const FETCH_COMPANY_GROUP_SUCCESS = "FETCH_COMPANY_GROUP_SUCCESS"
export const FETCH_COMPANY_GROUP_FAILURE = "FETCH_COMPANY_GROUP_FAILURE"

export const ADD_COMPANY_GROUP = "ADD_COMPANY_GROUP"
export const ADD_COMPANY_GROUP_SUCCESS = "ADD_COMPANY_GROUP_SUCCESS"
export const ADD_COMPANY_GROUP_FAILURE = "ADD_COMPANY_GROUP_FAILURE"

export const EDIT_COMPANY_GROUP = "EDIT_COMPANY_GROUP"
export const EDIT_COMPANY_GROUP_SUCCESS = "EDIT_COMPANY_GROUP_SUCCESS"
export const EDIT_COMPANY_GROUP_FAILURE = "EDIT_COMPANY_GROUP_FAILURE"

export const SELECT_COMPANY_GROUP = "SELECT_COMPANY_GROUP"
export const SELECT_COMPANY_GROUP_SUCCESS = "SELECT_COMPANY_GROUP_SUCCESS"
export const SELECT_COMPANY_GROUP_FAILURE = "SELECT_COMPANY_GROUP_FAILURE"

export const UNSELECT_COMPANY_GROUP = "UNSELECT_COMPANY_GROUP"

export const TYPE_PHARMACY = 1;
export const TYPE_DENTAL_CENTER = 2;
export const TYPE_MEDICAL_CENTER = 3;
export const TYPE_LABORATORY = 4;
export const TYPE_RESTORATION = 5;
export const TYPE_SPORTS_HALL = 6;
export const TYPE_BAKERY = 7;
export const TYPE_HOTEL = 8;
export const TYPE_FOOD_DISTRIBUTION = 9;

export const getVisibleUntilOptions = () => [
    {label: 'Aucune limitation', value: 0},
    {label: '1 semaine', value: 1},
    {label: '2 semaines', value: 2},
    {label: '3 semaines', value: 3},
    {label: '1 mois', value: 4},
    {label: '2 mois', value: 5},
    {label: '3 mois', value: 6},
    {label: '4 mois', value: 7},
    {label: '5 mois', value: 8},
    {label: '6 mois', value: 9},
]

export const getVisibleUntilOption = (option?: number) => [
    {label: 'Aucune limitation', value: 0},
    {label: '1 semaine', value: 1},
    {label: '2 semaines', value: 2},
    {label: '3 semaines', value: 3},
    {label: '1 mois', value: 4},
    {label: '2 mois', value: 5},
    {label: '3 mois', value: 6},
    {label: '4 mois', value: 7},
    {label: '5 mois', value: 8},
    {label: '6 mois', value: 9},
].find(o => o.value === (option || 0))

export const VISIBLE_UNTIL_NO_LIMITATION = 0;
export const VISIBLE_UNTIL_ONE_WEEK = 1;
export const VISIBLE_UNTIL_TWO_WEEK = 2;
export const VISIBLE_UNTIL_THREE_WEEK = 3;
export const VISIBLE_UNTIL_ONE_MONTH = 4;
export const VISIBLE_UNTIL_TWO_MONTH = 5;
export const VISIBLE_UNTIL_THREE_MONTH = 6;
export const VISIBLE_UNTIL_FOUR_MONTH = 7;
export const VISIBLE_UNTIL_FIVE_MONTH = 8;
export const VISIBLE_UNTIL_SIX_MONTH = 9;

export const BILLING_RATE_OPTIONS = [
    { label: "Taux normal", value: 1 },
    { label: "Taux réduit", value: 2 }
];