import * as types from "../../../constants/booking/offer";
import * as models from "../../../models/booking/offer";

export const addBookingOffer = () => {
    return {
        type: types.ADD_BOOKING_OFFER,
    }
}

export const addBookingOfferSuccess = (bookingOffer: models.BookingOffer) => {
    return {
        type: types.ADD_BOOKING_OFFER_SUCCESS,
        bookingOffer: bookingOffer,
    }
}
export const removeBookingOfferSuccess = (bookingOffer: models.BookingOffer) => {
    return {
        type: types.ADD_BOOKING_OFFER_SUCCESS,
        bookingOffer: bookingOffer,
    }
}

export const addBookingOfferFailure = (error: string) => {
    return {
        type: types.ADD_BOOKING_OFFER_FAILURE,
        bookingOffer: null,
        bookingOffers: null,
        error: error
    }
}

export const editBookingOffer = () => {
    return {
        type: types.EDIT_BOOKING_OFFER,
    }
}

export const cancelBookingOfferSuccess = (bookingOffer: models.BookingOffer) => {
    return {
        type: types.CANCEL_BOOKING_OFFER_SUCCESS,
        bookingOffer: bookingOffer
    }
}

export const editBookingOfferSuccess = (bookingOffer: models.BookingOffer) => {
    return {
        type: types.EDIT_BOOKING_OFFER_SUCCESS,
        bookingOffer: bookingOffer
    }
}

export const editBookingOfferFailure = (error: string) => {
    return {
        type: types.EDIT_BOOKING_OFFER_FAILURE,
        bookingOffer: null,
        bookingOffers: null,
        error: error
    }
}

export const fetchBookingOffers = () => {
    return {
        type: types.FETCH_BOOKING_OFFERS,
    }
}

export const fetchBookingOffersFailure = (error: string) => {
    return {
        type: types.FETCH_BOOKING_OFFERS_FAILURE,
        bookingOffer: null,
        bookingOffers: [],
        error: error
    }
}

export const fetchBookingOffersSuccess = (bookingOffers: models.BookingOffers) => {
    return {
        type: types.FETCH_BOOKING_OFFERS_SUCCESS,
        bookingOffer: null,
        bookingOffers: bookingOffers
    }
}


export const showBookingOffer = () => {
    return {
        type: types.SHOW_BOOKING_OFFER,
    }
}
export const showBookingOfferSuccess = (bookingOffer: models.BookingOffer) => {
    return {
        type: types.SHOW_BOOKING_OFFER_SUCCESS,
        bookingOffer: bookingOffer
    }
}
export const showBookingOfferFailure = (error: string) => {
    return {
        type: types.SHOW_BOOKING_OFFER_FAILURE,
        bookingOffer: null,
        bookingOffers: null,
        error: error
    }
}