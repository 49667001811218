import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux"

import EmptyList from "../../../../../common/EmptyList"
import CompanyLinkItem from "./CompanyLinkItem"

import { CompanyGroup } from '../../../../../../models/companyGroup'
import { Company } from "../../../../../../models/companies"
import { ISellsyContactCompany } from '../../../../../../@types/models/Sellsy/ISellsyContactCompany'

import { BILLING_RATE_OPTIONS } from '../../../../../../constants/companyGroup'

import { updateCompanyGroup } from '../../../../../../adapters/admin/companyGroup'
import { listContactCompanies } from "../../../../../../adapters/sellsy"
import { linkSellsyIdToCompany, createCompanySubscription, updateCompanySubscription } from "../../../../../../adapters/admin/billing"
import { showAlertSuccess, showAlertInfo, showAlertDanger } from "../../../../../../actions/alert"

interface IProps {
  selectedCompanyGroup: CompanyGroup;
  setSelectedCompanyGroup: (data: CompanyGroup) => void;
  companies: Company[];
  sellsyContactId: number;
  companyGroupBillingRate: number|null;
  onRefresh: (noLoading?: string) => void;
}

const CompanyGroupCompaniesCard: React.FC<IProps> = ({ selectedCompanyGroup, setSelectedCompanyGroup, companies, sellsyContactId, companyGroupBillingRate, onRefresh }) => {
  const dispatch = useDispatch()
  
  const [sellsyCompanies, setSellsyCompanies] = useState<ISellsyContactCompany[]>([])
  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState<boolean>(false)

  const onFetchContactCompanies = async () => {
    try {
      const { data } = await listContactCompanies({ contactId: sellsyContactId })
      
      if (data.data.length === 0) {
        dispatch(showAlertInfo('Aucune entreprise associée à ce contact côté Sellsy'))
        return
      }

      setSellsyCompanies(data.data)
    } catch (error: any) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    }
  }

  const onLinkCompanySellsyId = async (companyId: number, sellsyId: number) => {
    try {
      await linkSellsyIdToCompany(companyId, { sellsyId: sellsyId })
      onRefresh('noLoading')
      dispatch(showAlertSuccess('L’entreprise a été liée à Sellsy'))
    } catch (error: any) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    }
  }

  const onCreateCompanySubscription = async (companyId: number, data: { subscriptionModelId: number, subscriptionStartDate: Date }) => {
    try {
      setSubscriptionIsLoading(true)
      await createCompanySubscription(companyId, data)
      onRefresh('noLoading')
      dispatch(showAlertSuccess('Nouvel abonnement ajouté'))
      setSubscriptionIsLoading(false)
    } catch (error: any) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    } finally {
      setSubscriptionIsLoading(false)
    }
  }

  const onUpdateCompanySubscription = async (companyId: number, data: { subscriptionModelId: number, subscriptionStartDate: Date }) => {
    try {
      setSubscriptionIsLoading(true)
      await updateCompanySubscription(companyId, data)
      onRefresh('noLoading')
      dispatch(showAlertSuccess('L’abonnement a été modifié'))
      setSubscriptionIsLoading(false)
    } catch (error: any) {
      dispatch(showAlertDanger(error?.response?.data?.message))
    } finally {
      setSubscriptionIsLoading(false)
    }
  }

  useEffect(() => {
    onFetchContactCompanies()
  }, [])

  return (
    <div className="col-12 p-3 bg-white border rounded">
      <div className="mb-3 pb-2 border-bottom d-flex justify-content-between">
        <p className="m-0 fw-bold">
          Entreprises du compte
        </p>

        <div className='w-25'>
          <select
            className="form-select"
            value={BILLING_RATE_OPTIONS.find(el => el.value === selectedCompanyGroup.billingRate)?.value}
            onChange={async (e: any) => {            
              const response = await updateCompanyGroup(selectedCompanyGroup.id, { billingRate: e.target.value })
              setSelectedCompanyGroup(response.data)
            }}
          >
            <option>Non défini</option>
            {BILLING_RATE_OPTIONS.map(option => (
              <option selected={option?.value === selectedCompanyGroup.billingRate} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {companies.length === 0 &&
        <EmptyList text="Aucune entreprise associée" />
      }

      {companies.length > 0 &&
        <div>
          {companies.map(company => (
            <CompanyLinkItem
              key={company.id}
              company={company}
              sellsyCompanies={sellsyCompanies}
              companyGroupBillingRate={companyGroupBillingRate}
              subscriptionIsLoading={subscriptionIsLoading}
              onLink={(sellsyId: number) => {
                if (company?.id) {
                  onLinkCompanySellsyId(company.id, sellsyId)
                }
              }}
              onCreateSubscription={(data: { subscriptionModelId: number, subscriptionStartDate: Date }) => {
                if (company?.id) {
                  onCreateCompanySubscription(company.id, data)
                }
              }}
              onUpdateSubscription={(data: { subscriptionModelId: number, subscriptionStartDate: Date }) => {
                if (company?.id) {
                  onUpdateCompanySubscription(company.id, data)
                }
              }}
            />
          ))}
        </div>
      }
    </div>
  )
}

export default CompanyGroupCompaniesCard