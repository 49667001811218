import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Synthesis from "../../synthesis/tables";
import ReportDownload from "../download";
import ReportEdit from "../edit";

import * as models from "../../../models/report";
import { Company } from "../../../models/companies";
import { Syntheses } from "../../../models/synthesis";

import { getMonthOption, PAGINATION } from "../../../constants/global";
import { ACCOUNTING_SETTINGS } from "../../../constants/rightSidebar";

import * as api from "../../../adapters/report";
import * as actions from "../../../actions/report";
import * as synthesisActions from "../../../actions/synthesis";
import { setPageTitle } from "../../../actions/header";
import { openSidebar } from "../../../actions/rightSidebar";

import format from "../../../utils/locale";

interface IProps {
    onFetchReports: () => void;
}

const ReportShow:React.FC<IProps> = ({ onFetchReports }) => {
    const dispatch = useDispatch();
    const params: { id: string } = useParams();

    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.report);
    const updating: Syntheses = useSelector((state: RootStateOrAny) => state.synthesis.updating);

    const [_updating, setUpdating] = useState(false)
    const [company, setCompany] = useState<Company|undefined>()
    const [offset, setOffset] = useState(0)
    const [query, setQuery] = useState('')
    const [paginationLength, setPaginationLength] = useState(0)

    function fetchReport(report: models.Report|null, options?: {overrideOffset?: number, overrideQuery?: string})
    {
        if (!report) return;

        dispatch(synthesisActions.fetchSyntheses(new Date(report.start), new Date(report.end)))
        api.fetch(report.id, {companies: company ? [company.id!] : [], salaries: [], offset: options?.overrideOffset || offset, query: options?.overrideQuery || query}).then(resp => {
            let timeSheetData: any[] = [];

            if (company?.id) {
                for (const element of resp.data.timeSheet) {
                    if (element.salary.companies.findIndex((salaryCompany: Company) => salaryCompany.id === company.id) !== -1) {
                        timeSheetData.push(element);
                    }
                }
            } else {
                timeSheetData = resp.data.timeSheet;
            }
            
            dispatch(synthesisActions.fetchSynthesesSuccess(timeSheetData, resp.data.weeks));
            setPaginationLength(Math.ceil(resp.data.population.length / PAGINATION));
        })
    }

    function close(){
        if (state.single && !state.single.closedAt){
            setUpdating(true)
            api.close(state.single.id)
                .then(resp => dispatch(actions.editReportSuccess(resp.data)))
                .then(() => setUpdating(false))
                .catch(() => setUpdating(false))
        }
    }

    function open(){
        if (state.single && state.single.closedAt){
            setUpdating(true)
            api.open(state.single.id)
                .then(resp => dispatch(actions.editReportSuccess(resp.data)))
                .then(() => setUpdating(false))
                .catch(() => setUpdating(false))
        }
    }

    function deactivate(){
        if (state.single){
            setUpdating(true)
            api.deactivate(state.single.id)
                .then(resp => dispatch(actions.showReport()))
                .then(() => {
                    setUpdating(false)
                    onFetchReports()
                })
                .catch(() => setUpdating(false))
        }
    }

    useEffect(() => {
        if (state.single){
            dispatch(setPageTitle("Rapport", `${getMonthOption(String(state.single.month))!.label} ${state.single.year}`))
        }
    }, [state.single])

    useEffect(() => {
        if (params.id){
            dispatch(actions.showReport())
            api.show(Number(params.id))
                .then(resp => dispatch(actions.showReportSuccess(resp.data)))
        }
    }, [params.id])

    useEffect(() => {
        if (state.single){
            setOffset(0)
            setQuery('')
            fetchReport(state.single)
        }
    }, [state.single, company])

    useEffect(() => {
        if (state.single){
            fetchReport(state.single)
        }
    }, [offset])

    useEffect(() => {
        let timer = setTimeout(() => fetchReport(state.single),500)
        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if (state.single && updating.length){
            api.fetch(state.single.id, {companies: company ? [company.id!] : [], salaries: updating.map(s => s.id)}).then(resp => {
                dispatch(synthesisActions.updateSynthesesSuccess(resp.data.timeSheet));
            })
        }
    }, [updating])

    if (!state.single){
        return (
            <div id={'singleContainer'} className={"d-flex flex-column bg-white col-lg-9 col-md-8 h-100 overflow-auto"} />
        )
    }

    return (
        <div id={'singleContainer'} className={"d-flex flex-column bg-white col-lg-10 col-md-9 h-100 overflow-auto"}>
            <div className="col-12 mb-3">
                <div className="my-2 py-2 bg-primary text-white d-flex justify-content-center">
                    {getMonthOption(String(state.single.month))?.label} {state.single.year} {state.single.closedAt && <>| <i className={'bi bi-lock mx-1'}></i> Vérrouillé le {format(new Date(state.single.closedAt), "E dd MMMM uuuu")}</>}
                    <div className="clearfix mx-2">|</div>
                    <i className={'me-2 bi bi-calendar'}></i> {format(new Date(state.single.start))} <i className={"bi bi-arrow-left-right mx-2"}></i> {format(new Date(state.single.end))}
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-auto pe-3">
                        {state.single.closedAt &&
                            <button
                                className="btn btn-light mx-2"
                                onClick={() => dispatch(openSidebar(ACCOUNTING_SETTINGS))}
                            >
                                <i className={'bi bi-gear'}></i>
                            </button>
                        }

                        <button
                            className={"btn btn-light me-2 " + (state.single.closedAt ? " " : " disabled ")}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasReportExport"
                            aria-controls="offcanvasReportExport"
                        >
                            <i className={'bi bi-download'}></i> {state.single.closedAt ? <>Télécharger</> : <>Verrouiller le rapport pour pouvoir télécharger</>}
                        </button>

                        {state.single.closedAt && <>
                            <button
                                className="btn btn-light"
                                disabled={_updating}
                                onClick={() => open()}
                            >
                                <i className={'bi bi-unlock'}></i> Déverrouiller le rapport
                            </button>
                        </>}

                        {!state.single.closedAt && <>
                            <button
                                className="btn btn-light"
                                disabled={_updating}
                                onClick={() => close()}
                            >
                                <i className={'bi bi-lock'}></i> Verrouiller le rapport
                            </button>
                        </>}
                    </div>

                    <div className="col-auto ps-3 border-start">
                        <button
                            className={'btn btn-light'}
                            disabled={!!state.single.closedAt}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasReportEdit"
                            aria-controls="offcanvasReportEdit"
                        >
                            <i className={'bi bi-pencil'}></i> Modifier
                        </button>

                        <button
                            className={'btn btn-light ms-2'}
                            onClick={() => deactivate()}
                        >
                            <i className={'bi bi-dash-circle text-danger'}></i> Désactiver
                        </button>
                    </div>
                </div>
            </div>

            <Synthesis
                company={company}
                setCompany={setCompany}
                offset={offset}
                setOffset={setOffset}
                query={query}
                setQuery={setQuery}
                paginationLength={paginationLength}
            />
            <ReportDownload />
            <ReportEdit />
        </div>
    )
}

export default ReportShow;