import { FunctionComponent } from "react"

import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'

interface IProps {
  label?: string;
  placeholder?: string;
  value: string;
  disabled?: boolean;
  onChange: (phoneNumber: string) => void;
  error?: string;
}

const PhoneInputCustom: FunctionComponent<IProps> = ({ label, placeholder, value, disabled, onChange, error }) => {
  return (
    <div>
      {label &&
        <label className="block mb-2 text-sm text-gray-600">
          {label}
        </label>
      }

      <div className={`relative ${disabled ? 'opacity-50' : ''}`}>
        <PhoneInput
          containerStyle={{
            width: '100%',
            height: 60,
            borderRadius: 6,
            backgroundColor: 'transparent',
          }}
          inputStyle={{
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
          }}
          country={'fr'}
          countryCodeEditable={false}
          placeholder={placeholder || 'Votre numéro de téléphone'}
          value={value}
          disabled={disabled}
          onChange={(phone) => onChange(phone)}
        />

        {error &&
          <div className="absolute top-[-10px] right-0 z-10 max-w-full bg-red-100 border border-red-300 rounded-md shadow-sm">
            <div className="px-2 py-1">
              <p className="text-[9px]">
                {error}
              </p>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default PhoneInputCustom