import * as types from "../../constants/alert";
import * as models from "../../models/alert";
import {ALERT_DANGER, ALERT_INFO, ALERT_SUCCESS, ALERT_WARNING} from "../../constants/alert";

const alertSivan = (state: models.initialStateInterface = models.initialState, action: {type: string, value: string}) => {
    switch (action.type) {
        case types.SHOW_ALERT_SUCCESS:
            state.show = true;
            state.value = action.value;
            state.target = ALERT_SUCCESS
            return state;
        case types.SHOW_ALERT_INFO:
            state.show = true;
            state.value = action.value;
            state.target = ALERT_INFO
            return state;
        case types.SHOW_ALERT_WARNING:
            state.show = true;
            state.value = action.value;
            state.target = ALERT_WARNING
            return state;
        case types.SHOW_ALERT_DANGER:
            state.show = true;
            state.value = action.value;
            state.target = ALERT_DANGER
            return state;
        case types.HIDE_ALERT:
            state.show = false;
            state.value = '';
            state.target = ''
            return state;
        default:
            return state;
    }
}

export default alertSivan;