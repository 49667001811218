import * as types from "../../constants/document"
import * as models from "../../models/document"
import replace from "../../utils/arrayReplace"
import remove from "../../utils/arrayRemove"

const document = (state: models.initialStateInterface = models.initialState, action: {type: string, document: models.Document, documents: models.Documents, error: string}) => {

    switch (action.type) {
        case types.FETCH_DOCUMENTS:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_DOCUMENTS_SUCCESS:
            return { payload: action.documents, single: state.single, isLoading: false, error: null };
        case types.FETCH_DOCUMENTS_FAILURE:
            return { payload: action.documents, single: state.single, isLoading: false, error: action.error };

        case types.ADD_DOCUMENT:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_DOCUMENT_SUCCESS:
            return { payload: [action.document, ...state.payload], single: action.document, isLoading: false, error: null };

        case types.EDIT_DOCUMENT:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.EDIT_DOCUMENT_SUCCESS:
            return { payload: replace(state.payload, action.document), single: action.document, isLoading: false, error: null };
        case types.CANCEL_DOCUMENT_SUCCESS:
            return { payload: remove(state.payload, action.document), single: null, isLoading: false, error: null };
        case types.SHOW_DOCUMENT:
            return { payload: state.payload, single: null, isLoading: true, error: null };
        case types.SHOW_DOCUMENT_SUCCESS:
            return { payload: state.payload, single: action.document, isLoading: false, error: null };
        case types.SHOW_DOCUMENT_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        default:
            return state;
    }
}

export default document;