import {get, post} from "../../xhr";

export function edit(requestData: {value: number, weekNumber: number, year: number, salary: number, description: string}, id: number){
    return post(`counter_withdrawn/edit/${id}`, requestData)
}

export function create(requestData: {value: number, weekNumber: number, year: number, salary: number, description: string}){
    return post(`counter_withdrawn/create`, requestData)
}

export function add(salaryId: number, requestData: {value: number, weekNumber: number, year: number, description: string}){
    return post(`counter_withdrawn/add/${salaryId}`, requestData)
}