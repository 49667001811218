import {post, get, MULTIPART_FORM_DATA, Options} from "../xhr";
import * as models from "../../models/salary";

export interface requestPayload {
    query?: string,
    offset?: number,
    accessRight?: string,
    salaries?: (number|undefined)[],
    companies?: number[],
    activated?: boolean,
    jobs?: number[],
}

export function list(requestData?: requestPayload, optionalHeader?: Options){
    return post('salary/list', requestData || {}, optionalHeader);
}

export function choiceList(requestData?: requestPayload, optionalHeader?: Options){
    return post('salary/choice_list', requestData || {}, optionalHeader);
}

export function available(requestData: {beginAt: string, endAt: string, query?: string, options?: {companies?: number[], jobs?: number[]}}){
    return post('salary/available', requestData);
}

export function edit(requestData: {
    firstname: string,
    lastname: string,
    tel: string,
    email: string,
    birthdate: Date,
    companies: number[],
    address: {
        address: string,
        city: string,
        zip: number
    },
}, id: number){
    return post('salary/edit/' + id, requestData);
}
export function updateAccess(id: number, accessRightId: number){
    return get('salary/edit/' + id + '/access/' + accessRightId);
}

export function checkEmailAvailable(requestData: { email: string }){
    return post('salary/check-email-available',  requestData);
}

export function create(requestData: models.Salary){
    return post('salary/create',  requestData);
}

export function show(id: number){
    return get('salary/show/' + id);
}
export function qr(id: number){
    return get('salary/'+ id +'/qr');
}

export function feed(requestData: {start: string, end: string}, signal : AbortSignal){
    return post('salary/feed', requestData, {signal: signal});
}

export function _export(){
    return get('salary/export', {responseType: "blob"});
}

export function _import(requestData: FormData){
    return post('salary/import', requestData, {headers: [MULTIPART_FORM_DATA], responseType: "json"});
}
export function importFile(){
    return get('salary/import/file', {responseType: "blob"});
}

export function confirm(id: number){
    return get('salary/confirm/' + id);
}

export function validateToken(id: number, token: number){
    return get(`salary/${id}/validate-token/${token}`);
}

export function setToken(id: number, token: number){
    return get(`salary/${id}/update-token/${token}`);
}