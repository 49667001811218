import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {useFormik} from "formik";
import FormError from "../../../../../utils/formError";
import * as Yup from "yup";
import format from "../../../../../utils/locale";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as recurrenceApi from "../../../../../adapters/template/booking/recurrence";
import {refresh} from "../../../../../actions/template/calendar";
import {showAlertSuccess} from "../../../../../actions/alert";
import {openSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import {FORM_EDIT_TEMPLATE_BOOKING} from "../../../../../constants/rightSidebar";

const TemplateBookingRecurrenceEdit: React.FC<{setEditRecurrence:any}> = ({children, setEditRecurrence}) => {


    const state = useSelector((state:RootStateOrAny) => state)
    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        type: Yup.number().required()
    }))
    const [initialValues, setInitialValues] = useState<any>( {type: '1'})
    const dispatch = useDispatch();

    useEffect(() => {

        if(state.rightSidebar.data.recurrence.type > 1){
            let _initialValues:any = {};
            let _validationSchema:any = {};

            _initialValues = {
                type: '2',
                daysOfWeek: state.rightSidebar.data.recurrence.daysOfWeek.map((day: number) => String(day))
            }
            _validationSchema = Yup.object().shape({
                type: Yup.number().required(),
                daysOfWeek: Yup.array().of(Yup.number()).min(1, 'Veuillez séléctioner un moins un jour').required(),
            })

            setValidationSchema(Yup.object().shape({..._validationSchema}))
            setInitialValues({..._initialValues})
        }

    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            dispatch(submitSidebar())
            recurrenceApi.edit(values, state.rightSidebar.data.recurrence.id).then((data) => {
                state.rightSidebar.data.recurrence = {...data.data};
                formikHelpers.setSubmitting(false)
                dispatch(openSidebar(FORM_EDIT_TEMPLATE_BOOKING, state.rightSidebar.data))
                dispatch(showAlertSuccess('Récurrence mis à jour'))
                dispatch(refresh())
                setEditRecurrence(false);
            });
        }
    })

    const handleTypeChange = (e: BaseSyntheticEvent) => {
        let type = e.target.value
        let _validationSchema:any = {};
        let _initialValues = {...formik.values}

        switch (type) {
            case '1':
                _validationSchema = {type: Yup.string().required()}
                _initialValues.type = '1';
                if (_initialValues.hasOwnProperty('daysOfWeek')){
                    delete _initialValues.daysOfWeek;
                }
                break;
            case '2':
                _validationSchema = {
                    type: Yup.string().required(),
                    daysOfWeek: Yup.array().of(Yup.number()).min(1, 'Un jour de la semaine minimum').required('Veuillez choisir le(s) jour(s)'),
                }
                _initialValues.type = '2';
                if (state.rightSidebar.data.recurrence.daysOfWeek){
                    _initialValues.daysOfWeek = [...state.rightSidebar.data.recurrence.daysOfWeek.map((day: number) => String(day))];
                }else{
                    _initialValues.daysOfWeek = [format(state.rightSidebar.data.beginAt, 'i')];
                }
        }

        setValidationSchema(Yup.object().shape({..._validationSchema}));
        setInitialValues({..._initialValues});
    }

    const handleWeekDayChange = (e: BaseSyntheticEvent) => {
        let checked = e.target.checked;
        let _values = {...formik.values};
        let dayElements: HTMLInputElement[]|any = document.getElementsByName("daysOfWeek")
        let ret:string[] = []
        dayElements.forEach((day: HTMLInputElement) => {
            day.checked = checked
            if (checked)
                ret.push(day.value)
        })

        _values.recurrence.daysOfWeek = ret
        setInitialValues({..._values})
    }

    return <>
    <form onSubmit={formik.handleSubmit} className={'w-100 p-3'}>
        <div className="col-12">
            <div className="row">
                <div className="col">
                    <i className={'bi bi-arrow-repeat'}> </i> Récurrence
                    <div className="clearfix"> </div>
                    <span>
                        {state.rightSidebar.data.recurrence.description}
                    </span>
                </div>
                <div className="col-auto">
                    <button className={'btn btn-light'} onClick={() => setEditRecurrence(false)}>
                        <i className={'bi bi-x'}> </i>
                    </button>
                </div>
            </div>
        </div>
        <hr/>
        <div className="col-12 mb-3">
            <label htmlFor="">
                Répéter l'évènement <span className="required">*</span>
            </label>
            <div className="form-check">
                <input name={'type'} value={'1'} id={'type_1'} type={'radio'} defaultChecked={state.rightSidebar.data.recurrence.type === 1} onChange={(e) => {
                    handleTypeChange(e)
                }} />
                <label className="form-check-label ms-2" htmlFor="type_1" >
                    Une seule fois
                </label>
            </div>
            <div className="form-check">
                <input name={'type'} value={'2'} id={'type_2'} type={'radio'} defaultChecked={state.rightSidebar.data.recurrence.type === 2} onChange={(e) => {
                    handleTypeChange(e)
                }} />
                <label className="form-check-label ms-2" htmlFor="type_2">
                    Plusieurs fois
                </label>
            </div>
        </div>
        {formik.values.type === '2' &&
        <>
            <div className={'col-12 mb-3'}>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked_days"
                           onChange={handleWeekDayChange} />
                    <label className="form-check-label" htmlFor="flexCheckChecked_days">
                        Tous les jours de la semaine
                    </label>
                </div>
                {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day: string, index: number) => {
                    return (
                        <>
                            <input
                                type="checkbox"
                                onChange={formik.handleChange}
                                defaultChecked={formik.values.daysOfWeek?.includes(`${index + 1}`)}
                                name={'daysOfWeek'}
                                className="btn-check" id={`btn-check-${index}`} autoComplete="off" value={`${index + 1}`} />
                            <label className="btn btn-sm btn-outline-primary me-2 mb-2" htmlFor={`btn-check-${index}`}>
                                {day}
                            </label>
                        </>
                    )
                })}
                <FormError errors={formik.errors} touched={formik.touched} field={'daysOfWeek'} />
            </div>
        </>}
        <button type={'submit'} className={'btn btn-outline-primary w-100'}>
            <i className={'bi bi-check'}> </i> Enregistrer
        </button>
    </form>
    </>
}

export default TemplateBookingRecurrenceEdit;