import * as models from "../../../models/task/calendar"
import * as types from "../../../constants/task/calendar"
import {EventClickArg} from "@fullcalendar/react";
import React from "react";

const taskCalendar = (state: models.initialStateInterface = models.initialState, action:
    {type: string,
        events: any[],
        resources: any[],
        title: string,
        error: string,
        eventClickArg?: EventClickArg,
        displayEventPop: boolean,
        [key: string] : any}
) => {
    switch (action.type) {
        case types.FETCH_EVENTS:
            return {events: state.events, resources: state.resources,  title: state.title, displayEventPop:false,  loading: true}
        case types.FETCH_EVENTS_SUCCESS:
            return {events: action.events, resources: state.resources, title: state.title, displayEventPop:false,  loading: false}
        case types.FETCH_RESOURCES:
            return {events: state.events, resources: state.resources,  title: state.title, displayEventPop:false,  loading: true}
        case types.FETCH_RESOURCES_SUCCESS:
            return {events: state.events, resources: action.resources,  title: state.title, displayEventPop:false,  loading: false}
        case types.FETCH_TITLE_SUCCESS:
            return {events: state.events, resources: state.resources,  title: action.title, loading: false, displayEventPop: state.displayEventPop}
        case types.SHOW_EVENT_POP:
            return {events: state.events, resources: state.resources,  title: state.title, eventClickArg: action.eventClickArg, displayEventPop: true, loading: false, displayResourceActions: false}
        case types.HIDE_EVENT_POP:
            return {events: state.events, resources: state.resources,  title: state.title, eventClickArg: null, displayEventPop: false, loading: false, displayResourceActions: false}
        case types.SHOW_RESOURCE_ACTIONS:
            return {events: state.events, resources: state.resources,  title: state.title, displayEventPop: false, loading: false, displayResourceActions: true, resourceLabelContentArg: action.resourceLabelContentArg, resourceActionsButton: action.resourceActionsButton}
        case types.HIDE_RESOURCE_ACTIONS:
            return {events: state.events, resources: state.resources,  title: state.title, displayEventPop: false, loading: false, displayResourceActions: false}
        case types.REFRESH:
            state.refresh = !state.refresh;
            return state;
        default:
            return state;
    }
}

export default taskCalendar;