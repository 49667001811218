export default function(items: any[], item: any){
    let _items = [...items]
    let index = _items.findIndex((a: any) => a.id === item.id);

    if (index !== -1){
        _items.splice(index, 1)
    }

    return [..._items]
}

