import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/sellsy"
import Loader from "../../../../utils/loader";

const SellsyContactListModal = ({email, setContact}: {email: string, setContact: (contact: {
        id: number,
        email: string,
        first_name: string,
        last_name: string
    }) => void}) => {

    const [payload, setPayload] = useState<{
        "pagination": {
            "limit": number,
            "total": number,
            "count": number,
            "offset": number
        },
        data: {
            id: number,
            email: string,
            first_name: string,
            last_name: string
        }[]
    }>({data: [], pagination: {count: 0, total: 0, offset: 0, limit: 0}});

    const [fetchParams, setFetchParams] = useState<{query: string, offset: number}>({query: email, offset: 0});
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        api.list(fetchParams)
            .then(r => setPayload(r.data))
            .then(() => setLoading(false))
    }, [fetchParams])


    let timer: any;
    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
        clearTimeout(timer);
        timer = setTimeout(() => setFetchParams({query: e.target.value, offset: 0}), 1500)
    }

    return <div className="modal fade" id="SellsyContactListModal" tabIndex={-1} aria-labelledby="SellsyContactListModalLabel"
                aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="SellsyContactListModalLabel">Contacts sellsy</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <ul className="list-group list-group-flush">
                        <li className="col-12 mb-3">
                            <div className="input-group">
                                <div className="input-group-text">
                                    <i className={'bi bi-search'}></i>
                                </div>
                                <input type="text" onChange={handleQueryChange} className="form-control" placeholder={'Rechercher un client dans sellsy...'} defaultValue={email} />
                            </div>
                        </li>
                        {loading && <div className={"col-12 mb-3 text-center"}>
                            <Loader />
                        </div>}
                        {payload.data.map(contact => <li className={'list-group-item'} key={contact.email}>
                            <div className="form-check align-items-center">
                                <input onChange={() => setContact(contact)} className="form-check-input" type="radio" id={contact.email} />
                                <label className="form-check-label d-flex flex-column" htmlFor={contact.email}>
                                    <span>
                                        {contact.first_name} {contact.last_name}
                                    </span>
                                    <span className={'form-text'}>
                                        {contact.email}
                                    </span>
                                </label>
                            </div>
                        </li>)}
                    </ul>
                    {payload.pagination.total > 25 && <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            {Array.from(Array(Math.ceil(payload.pagination.total / 25)).keys()).map(i => <li className="page-item">
                                <button className="page-link" onClick={() => setFetchParams(p => ({...p, offset: i * 25}))}>
                                    {i + 1}
                                </button>
                            </li>)}
                        </ul>
                    </nav>}
                </div>
            </div>
        </div>
    </div>

}

export default SellsyContactListModal;