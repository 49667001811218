export default function timeFormat (duration: number, format?: "hour"|"decimal", addSign?: boolean){    
    if (!format){
        format = 'hour';
    }

    if (!duration) return <i className={"bi bi-x"}></i>

    let addMinus = duration < 0;

    duration = Math.abs(duration);

    let s = duration * 1000;
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let min = s % 60;
    let h = (s - min) / 60;

    if (format === 'decimal'){
        return Number(h + (min/60)).toFixed(2)
    }

    return <>{addMinus && (addSign || addSign === undefined) && <>-</>}{String(h).padStart(2, '0')}:{String(min).padStart(2, '0')}</>;
}