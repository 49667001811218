import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import * as models from "../../../models/accessLevel";

import { FORM_NEW_ACCESS_LEVEL } from "../../../constants/rightSidebar";

import * as api from "../../../adapters/accessLevel";
import * as actions from "../../../actions/accessLevel";
import { openSidebar } from "../../../actions/rightSidebar";
import { setPageTitle, setHelpProductSheetIds } from "../../../actions/header";
import { axiosError } from "../../../actions/axios";

const AccessLevelList: React.FC = () => {
    const params: {id: string} = useParams();
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny)  => state.accessLevel);

    useEffect(() => {
        dispatch(setPageTitle('Droits d\'accès'))
        dispatch(setHelpProductSheetIds([10]))

        dispatch(actions.fetchAccessLevels())
        api.select().then(data => {
            dispatch(actions.fetchAccessLevelsSuccess(data.data))
        }).catch(error => dispatch(axiosError(error)))

        return (() => {
            dispatch(setHelpProductSheetIds([]))
        })
    }, [])


    return (
        <div id={"listContainer"} className={"col-12 col-md-4 col-lg-3 px-0 h-100 bg-white border-end overflow-auto top-0 sticky-top" + (params.id ? " d-none d-md-block" : "")}>
            <div className="col-12">
                <button onClick={() => dispatch(openSidebar(FORM_NEW_ACCESS_LEVEL))} className='btn btn-outline-primary w-100'>
                    <i className={'bi bi-plus'}> </i> Ajouter
                </button>
            </div>

            {state.payload.length ? state.payload.map((accessLevel: models.AccessLevel) => ([
                <Link to={{ pathname:'/access_level/' + accessLevel.id}} className={"px-3 list-group-item list-group-item-action" + (Number(params.id) === accessLevel.id)}>
                    {accessLevel.title}
                </Link>
                ]))
                :
                <button key={0} type="button" className="list-group-item list-group-item-action" aria-current="true" disabled>
                    Aucun résultat
                </button>
            }
        </div>
    )
}

export default AccessLevelList;