import React, {useEffect, useState} from "react";
import {Salary} from "../../../models/salary";
import SalaryTemplateList from "./list";
import * as models from "../../../models/salary/template";
import SalaryTemplateShow from "./show";
import {SalaryTemplates} from "../../../models/salary/template";

const Timetable:React.FC<{salary: Salary, date?: Date}> = (props) => {
    const {salary, date} = props;
    
    const [template, setTemplate] = useState<models.SalaryTemplate>()
    const [templates, setTemplates] = useState<SalaryTemplates>([]);

    useEffect(() => {
        if (template){
            setTemplate(undefined)
        }
    }, [salary])

    return <div className={'col-12 h-100'}>
        <div className="row h-100">
            <div className="col-3 h-100">
                <SalaryTemplateList salary={salary} setTemplates={setTemplates} templates={templates} setTemplate={setTemplate} template={template} date={date} />
            </div>
            <div className="col-9 h-100">
                <SalaryTemplateShow template={template} salary={salary} setTemplates={setTemplates} setTemplate={setTemplate} templates={templates} />
            </div>
        </div>
    </div>
}

export default Timetable;