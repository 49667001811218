import {Middleware} from "redux";
import {RootStateOrAny} from "react-redux";
import {
    SHOW_ALERT_DANGER, SHOW_ALERT_INFO, SHOW_ALERT_SUCCESS, SHOW_ALERT_WARNING
} from "../../constants/alert";
import {hideAlert} from "../../actions/alert";

export const logger: Middleware<
    {},
    RootStateOrAny
    > = store => next => action => {
    console.log('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    return result
}

export const crashReporter: Middleware<
    {},
    RootStateOrAny
    > = store => next => action => {
    try {
        return next(action)
    } catch (err) {
        console.error('Caught an exception!', err)
        // throw err
    }
}

export const alertHider: Middleware<
    {},
    RootStateOrAny
    > = store => next => action => {
    try {
        if ([SHOW_ALERT_SUCCESS, SHOW_ALERT_DANGER, SHOW_ALERT_WARNING, SHOW_ALERT_INFO].includes(action.type)){
            setTimeout(() => {
                store.dispatch(hideAlert());
            }, 5000);
        }
        return next(action)
    } catch (err) {
        console.error('Caught an exception!', err)
        throw err
    }
}

