import React, {useCallback, useEffect, useRef, useState} from 'react';
import './index.scss';

const Scrollbar = ({
                       children,
                       className,
                       ...props
                   }: React.ComponentPropsWithoutRef<'div'> & {triggerResize: number, scrollBottom: (e: React.UIEvent<HTMLUListElement, UIEvent>) => void;}) => {

    const contentRef = useRef<HTMLDivElement>(null);
    const scrollTrackRef = useRef<HTMLDivElement>(null);
    const scrollThumbRef = useRef<HTMLDivElement>(null);
    const observer = useRef<ResizeObserver | null>(null);
    const [thumbHeight, setThumbHeight] = useState(20);
    const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(
        null
    );
    const [initialScrollTop, setInitialScrollTop] = useState<number>(0);
    const [isDragging, setIsDragging] = useState(false);

    function handleResize(ref: HTMLDivElement, trackSize: number) {
        const { clientHeight, scrollHeight } = ref;
        setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 20));
    }

    function handleScrollButton(direction: 'up' | 'down') {
        const { current } = contentRef;
        if (current) {
            const scrollAmount = direction === 'down' ? 200 : -200;
            current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
        }
    }

    const handleTrackClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { current: trackCurrent } = scrollTrackRef;
            const { current: contentCurrent } = contentRef;
            if (trackCurrent && contentCurrent) {
                const { clientY } = e;
                const target = e.target as HTMLDivElement;
                const rect = target.getBoundingClientRect();
                const trackTop = rect.top;
                const thumbOffset = -(thumbHeight / 2);
                const clickRatio =
                    (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
                const scrollAmount = Math.floor(
                    clickRatio * contentCurrent.scrollHeight
                );
                contentCurrent.scrollTo({
                    top: scrollAmount,
                    behavior: 'smooth',
                });
            }
        },
        [thumbHeight]
    );

    const handleThumbPosition = useCallback((e) => {

        props.scrollBottom(e)

        if (
            !contentRef.current ||
            !scrollTrackRef.current ||
            !scrollThumbRef.current
        ) {
            return;
        }
        const { scrollTop: contentTop, scrollHeight: contentHeight } =
            contentRef.current;
        const { clientHeight: trackHeight } = scrollTrackRef.current;
        let newTop = (+contentTop / +contentHeight) * trackHeight;
        newTop = Math.min(newTop, trackHeight - thumbHeight);
        const thumb = scrollThumbRef.current;
        thumb.style.top = `${newTop}px`;
    }, []);

    const handleThumbMousedown = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setScrollStartPosition(e.clientY);
        if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
        setIsDragging(true);
    }, []);

    const handleThumbMouseup = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging) {
                setIsDragging(false);
            }
        },
        [isDragging]
    );

    const handleThumbMousemove = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging && contentRef.current) {
                const {
                    scrollHeight: contentScrollHeight,
                    offsetHeight: contentOffsetHeight,
                } = contentRef.current;

                if (scrollStartPosition){
                    const deltaY =
                        (e.clientY - scrollStartPosition) *
                        (contentOffsetHeight / thumbHeight);

                    contentRef.current.scrollTop = Math.min(
                        initialScrollTop + deltaY,
                        contentScrollHeight - contentOffsetHeight
                    );
                }
            }
        },
        [isDragging, scrollStartPosition, thumbHeight]
    );

    // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
    useEffect(() => {
        if (contentRef.current && scrollTrackRef.current) {
            const ref = contentRef.current;
            const { clientHeight: trackSize } = scrollTrackRef.current;
            observer.current = new ResizeObserver(() => {
                handleResize(ref, trackSize);
            });
            observer.current.observe(ref);
            ref.addEventListener('scroll', handleThumbPosition);
            return () => {
                observer.current?.unobserve(ref);
                ref.removeEventListener('scroll', handleThumbPosition);
            };
        }
    }, [props.triggerResize]);

    // Listen for mouse events to handle scrolling by dragging the thumb
    useEffect(() => {
        document.addEventListener('mousemove', handleThumbMousemove);
        document.addEventListener('mouseup', handleThumbMouseup);
        document.addEventListener('mouseleave', handleThumbMouseup);
        return () => {
            document.removeEventListener('mousemove', handleThumbMousemove);
            document.removeEventListener('mouseup', handleThumbMouseup);
            document.removeEventListener('mouseleave', handleThumbMouseup);
        };
    }, [handleThumbMousemove, handleThumbMouseup]);

    return (
        <div className="custom-scrollbars__container">
            <div className="custom-scrollbars__content" ref={contentRef} {...props}>
                {children}
            </div>
            <div className="custom-scrollbars__scrollbar">
                <button
                    className="custom-scrollbars__button btn"
                    onMouseDown={e => {
                        e.preventDefault();
                        handleScrollButton('up')
                    }}
                >
                    <i className={"bi bi-caret-up"}></i>
                </button>
                <div className="custom-scrollbars__track-and-thumb">
                    <div
                        className="custom-scrollbars__track"
                        ref={scrollTrackRef}
                        onMouseDown={e => {
                            e.preventDefault()
                            handleTrackClick(e)
                        }}
                        style={{ cursor: isDragging ? 'grabbing' : "auto" }}
                    ></div>
                    <div
                        className="custom-scrollbars__thumb"
                        ref={scrollThumbRef}
                        onMouseDown={e => {
                            e.preventDefault()
                            handleThumbMousedown(e)
                        }}
                        style={{
                            height: `${thumbHeight}px`,
                            cursor: isDragging ? 'grabbing' : 'grab',
                        }}
                    ></div>
                </div>
                <button
                    className="custom-scrollbars__button btn"
                    onMouseDown={e => {
                        e.preventDefault();
                        handleScrollButton('down')
                    }}
                >
                    <i className={"bi bi-caret-down"}></i>
                </button>
            </div>
        </div>
    );
};

export default Scrollbar;
