import * as types from "../../constants/auth"
import  * as models from "../../models/auth"
import {User, UserCalendarSettings} from "../../models/auth";
import {Salaries, Salary} from "../../models/salary";
import {CompanyGroup} from "../../models/companyGroup";
import {exitImpersonate, impersonate} from "../../security/impersonate";
import {Company} from "../../models/companies";

const auth = (
    state: models.initialStateInterface = models.initialState,
    action: {
        type: string,
        data: models.authResponse,
        user?: models.User,
        userInformation: models.UserInformation,
        impersonated: models.User,
        account: Salary,
        accounts: Salaries,
        companyGroup: CompanyGroup,
        company: Company,
        calendarSettings: UserCalendarSettings,
        message: string
    }
) => {
    switch (action.type) {        
        case types.LOGIN:
            return { token: null, user: null, initialUser: null, isLoading: true, error: null };
        case types.REFRESH_USER_SUCCESS:
            if (action.user){
                saveUser(action.user)
                return { token: state.token, user: action.user, initialUser: state.initialUser, isLoading: false, error: null };
            }
            return state;
        case types.REFRESH_AUTH_SUCCESS:
        case types.LOGIN_SUCCESS:            
            saveAuth(action.data)
            saveInitialUser(action.data)
            return { token: action.data.token, user: action.data.user, initialUser: action.data.user, isLoading: false, error: null };
        case types.LOGIN_FAILURE:
            return { token: null, user: null, initialUser: null, isLoading: false, error: action.message };
        case types.REGISTER:
            return { token: null, user: null, initialUser: null, isLoading: true, error: null };
        case types.REGISTER_SUCCESS:
            return { token: action.data.token, user: action.data.user, initialUser: action.data.user, isLoading: false, error: null };
        case types.REGISTER_FAILURE:
            return { token: null, user: null, initialUser: null, isLoading: false, error: action.data };
        case types.LOGOUT:
            clearAuth()
            return { token: null, user: null, initialUser: null, isLoading: false, error: null };
        case types.EDIT_PASSWORD:
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: true, error: null };
        case types.EDIT_PASSWORD_FAILURE:
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: action.message  };
        case types.EDIT_PASSWORD_SUCCESS:
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null  };
        case types.CONFIRM:
            return { token: null, user: null, initialUser: null, isLoading: true, error: null };
        case types.CONFIRM_TOKEN_EXIST:
            return { token: null, user: null, initialUser: null, isLoading: false, error: null };
        case types.CONFIRM_SUCCESS:
            saveAuth(action.data)
            return { token: action.data.token, user: action.data.user, initialUser: action.data.user, isLoading: false, error: null };
        case types.CONFIRM_FAILURE:
            return { token: null, user: null, initialUser: null, isLoading: false, error: action.message };
        case types.ADD_USER_INFORMATION:
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: true, error: null };
        case types.ADD_USER_INFORMATION_SUCCESS:
            state.user!.information = {...action.userInformation}
            if(state.user!.id === state.initialUser!.id){
                state.initialUser!.information = {...action.userInformation}
            }
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        case types.EDIT_USER_INFORMATION:
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: true, error: null };
        case types.EDIT_USER_INFORMATION_SUCCESS:
            state.user!.information = {...action.userInformation}
            if(state.user!.id === state.initialUser!.id){
                state.initialUser!.information = {...action.userInformation}
            }
            saveUser(state.user!)
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        case types.SELECT_ACCOUNT:
            state.user!.currentSalary = action.account;
            if(state.user!.id === state.initialUser!.id){
                state.initialUser!.currentSalary = action.account
            }
            saveUser(state.user!)
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        case types.UNSELECT_ACCOUNT:
            state.user!.currentSalary = null;
            if(state.user!.id === state.initialUser!.id){
                state.initialUser!.currentSalary = null;
            }
            saveUser(state.user!)
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        case types.FETCH_ACCOUNTS_SUCCESS:
            state.user!.salaries = action.accounts;
            if(state.user!.id === state.initialUser!.id){
                state.initialUser!.salaries = action.accounts
            }
            saveUser(state.user!)
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        case types.EDIT_USER_COMPANY_GROUP_SUCCESS:
            state.user!.currentSalary!.companyGroup = action.companyGroup;
            saveUser(state.user!)
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        case types.EDIT_USER_CALENDAR_SETTING_SUCCESS:
            state.user!.calendarSettings = action.calendarSettings;
            if(state.user!.id === state.initialUser!.id){
                _saveInitialUser(state.user!)
                state.initialUser!.calendarSettings = action.calendarSettings
            }
            saveUser(state.user!)
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        case types.IMPERSONATE_USER:
            saveUser(action.impersonated)
            impersonate(action.impersonated)
            return { token: state.token, user: action.impersonated, initialUser: state.initialUser, isLoading: false, error: null };
        case types.EXIT_IMPERSONATE_USER:
            exitImpersonate()
            saveUser(state.initialUser!)
            return { token: state.token, user: state.initialUser, initialUser: state.initialUser, isLoading: false, error: null };
        case types.ADD_AUTH_COMPANY:
            state.user?.currentSalary?.companies.push(action.company)
            saveUser(state.user!)
            return { token: state.token, user: state.user, initialUser: state.initialUser, isLoading: false, error: null };
        default:
            return state;
    }
}

const saveAuth = (data:models.authResponse) => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    sessionStorage.setItem('authUpdated', "1");
};

const saveInitialUser = (data:models.authResponse) => {
    localStorage.setItem('initialUser', JSON.stringify(data.user));
};

const saveUser = (data: User) => {
    localStorage.setItem('user', JSON.stringify(data));
};

const _saveInitialUser = (data: User) => {
    localStorage.setItem('initialUser', JSON.stringify(data));
};

const clearAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('next_route');
    localStorage.removeItem('impersonated');
    localStorage.removeItem('initialUser');
    localStorage.removeItem('defaultCompanies');
    sessionStorage.setItem('authUpdated', "0");
}

export default auth;