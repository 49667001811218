import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import StyledDropzone from "../../../utils/fileUpload";
import {showAlertDanger, showAlertSuccess} from "../../../actions/alert";
import {closeSidebar} from "../../../actions/rightSidebar";
import * as api from "../../../adapters/expense/file"
import {editExpenseSuccess} from "../../../actions/expense";
import {Expense} from "../../../models/expense";

const ExpenseFileAdd:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state)
    let expense: Expense = state.rightSidebar.data.expense;
    const dispatch = useDispatch();
    const [files, setFiles] = useState<File[]>([])
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = () => {

        if (!files.length){
            dispatch(showAlertDanger('Veuillez ajouter un fichier'))
            return false;
        }

        setSubmitting(true);

        let formData = new FormData();

        formData.append('file', files[0]);

        api.add(formData, expense.id!).then((data) => {
            dispatch(editExpenseSuccess(data.data));
            dispatch(showAlertSuccess('Document ajouté'))
            dispatch(closeSidebar());
        })
    }

    return  <>
        <div className="p-3">
            <div className="mb-3">
                <StyledDropzone style={{height: 200}}  handleUploadedFile={(files: File[]) => setFiles(files)} />
            </div>
            <button type={'button'} onClick={() => handleSubmit()} disabled={!files.length || submitting} className={'btn w-100 btn-primary'}>
                {submitting ? <div className="spinner-grow text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> : 'Enregistrer'}
            </button>
        </div>
    </>
}

export default ExpenseFileAdd;