import React, {useEffect, useState} from "react";
import EventEditForm from "../../../../event/edit/form";
import {EventResizeDoneArg} from "@fullcalendar/interaction";

const EventResizeEdit:React.FC<{arg?: EventResizeDoneArg, handleSubmitSuccess: (ev: any) => any, close: () => any}> = (props) => {

    const {arg, handleSubmitSuccess, close} = props;
    const [key, setKey] = useState<number>(0);


    useEffect(() => {
        const el = document.getElementById('offcanvasCalendarEvent')! as HTMLDivElement;
        el.addEventListener('hide.bs.offcanvas',  cancelResize)

        return () => el?.removeEventListener('hide.bs.offcanvas',  cancelResize)
    }, [arg])

    const cancelResize = () => {
        if (arg && arg.oldEvent){
            arg.event.setDates(arg.oldEvent.start!, arg.oldEvent.end!);
        }
    }

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }


    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventResizeEditLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <EventEditForm
                key={key}
                values={{
                    salary: arg.event.extendedProps.salary,
                    start: arg.event.start!,
                    end: arg.event.end!,
                    accountingType: arg.event.extendedProps.accountingType,
                    company: arg.event.extendedProps.company,
                    activity: arg.event.extendedProps.activity,
                    localisation: arg.event.extendedProps.localisation,
                    eventType: arg.event.extendedProps.eventType,
                    eventId: arg.event.extendedProps.eventId,
                    exceptionId: arg.event.extendedProps.exceptionId,
                    comment: arg.event.extendedProps.comment,
                }}
                handleSubmitSuccess={handleSubmit}
            />}
        </div>
    </>
}

export default EventResizeEdit;