import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as API from "../../../../adapters/companyGroup"
import {GeneralSettings} from "../../../../models/companyGroup";
import format from "../../../../utils/locale";
import {editUserCompanyGroupSuccess} from "../../../../actions/auth";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import {getVisibleUntilOption, getVisibleUntilOptions} from "../../../../constants/companyGroup";
import getMaxVisibilityDate from "../../../../security/getMaxDate";

const GeneralConfig:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);
    const settings:GeneralSettings = state.auth.user.currentSalary.companyGroup.generalSettings;
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const handleChange = (setting: string, value: any) => {
        setIsLoading(true);
        API.generalSettings({
            setting: setting,
            value: value
        }).then((data) => {
            dispatch(editUserCompanyGroupSuccess(data.data))
            setIsLoading(false);
        })
    }

    return <>
        <div className="p-3">
            <div className="card p-3">
                <div className="col-12 mb-3">
                    <div className="row">
                        <div className="col">
                            <h4>
                                Langue
                            </h4>
                            <BootstrapSelect
                                notFloating={true}
                                disabled={isLoading}
                                required={true}
                                value={{label: 'Français', value: 'fr'}}
                                options={[
                                    {label: 'Français', value: 'fr'},
                                    {label: 'Anglais', value: 'en'},
                                    {label: 'Allemand', value: 'de'},
                                    {label: 'Italien', value: 'it'},
                                    {label: 'Espagnol', value: 'es'},
                                    {label: 'Hébreu', value: 'he'}
                                ]}
                                onChange={choice => handleChange('lang', choice?.value)}
                            />
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="col-12 mb-3">
                    <div className="row">
                        <div className="col">
                            <h4>
                                Visibilité du planning
                            </h4>
                            <p className="form-text">
                                Limiter la visibilité du planning au-delà de la limite précisée ci-dessous
                            </p>
                            <BootstrapSelect
                                notFloating={true}
                                disabled={isLoading}
                                required={true}
                                value={getVisibleUntilOption(settings?.visibleUntil)}
                                options={getVisibleUntilOptions()}
                                onChange={choice => handleChange('visibleUntil', choice?.value)}
                            />
                            {getMaxVisibilityDate() && <div className="form-text text-primary">
                               <i className={'bi bi-info-circle'}></i> Planning visible jusqu'au {format(getMaxVisibilityDate()!)}
                            </div>}
                        </div>
                    </div>
                </div>
                <hr className={'bg-primary'}/>
            </div>
        </div>
    </>
}

export default GeneralConfig;