export const ADD_REPORT = 'ADD_REPORT';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const ADD_REPORT_FAILURE = 'ADD_REPORT_FAILURE';

export const EDIT_REPORT = 'EDIT_REPORT';
export const EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS';
export const EDIT_REPORT_FAILURE = 'EDIT_REPORT_FAILURE';

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';

export const SHOW_REPORT = 'SHOW_REPORT';
export const SHOW_REPORT_SUCCESS = 'SHOW_REPORT_SUCCESS';
export const SHOW_REPORT_FAILURE = 'SHOW_REPORT_FAILURE';

export const PAYROLL_EXPORT = "PAYROLL_EXPORT";
export const COUNTER_EXPORT = "COUNTER_EXPORT";
export const EXPENSE_EXPORT = "EXPENSE_EXPORT";
export const SIGNATURE_EXPORT = "SIGNATURE_EXPORT";

export const SILAE_AJ_EXPORT = "SILAE_AJ_EXPORT";
export const SILAE_ABSENCE_EXPORT = "SILAE_ABSENCE_EXPORT";
export const SILAE_GLOBAL = "SILAE_GLOBAL";