import * as React from "react";

import ActivityList from "../../components/activity/list";
import ActivityShow from "../../components/activity/show";

const ActivityPage : React.FC = () => {
    return (
        <div className="w-100 h-100 d-flex flex-grow-1">
            <ActivityList />
            <ActivityShow />
        </div>
    );
}

export default ActivityPage;