import React, {useEffect, useState} from "react";
import {Activity} from "../../../../../models/activity";
import {Localisation} from "../../../../../models/localisation";
import * as Yup from "yup";
import * as calendarTypes from "../../../../../constants/calendar";
import * as api from "../../../../../adapters/template/event";
import {FormikHelpers, useFormik} from "formik";
import {showAlertSuccess} from "../../../../../actions/alert";
import {useDispatch} from "react-redux";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import FormError from "../../../../../utils/formError";
import DatePicker from "react-datepicker";
import validationClass from "../../../../../utils/validationClass";
import DateCustomInput from "../../../../../utils/customInput";
import {AccountingTypes} from "../../../../../constants/booking";
import SmallLoader from "../../../../../utils/loader/small";
import {Template} from "../../../../../models/template";
import format from "../../../../../utils/locale";
import {getTemplateDayOptions, getTemplateDayOption} from "../../../../../constants/global";
import {setHours, setMinutes} from "date-fns";

interface Interface {
    resource: {id: number, title: string}
    start: Date,
    end: Date,
    accountingType: number,
    activity?: Activity
    localisation?: Localisation,
    handleSubmitSuccess: (ev: any) => any,
    template: Template
}

interface InterfaceEvent {
    eventType: 'templateEvent',
    exDay: number,
    eventId: number,
}

interface InterfaceEventException {
    eventType: 'templateEventException',
    exceptionId: number,
}

const EventEditForm: React.FC<Interface & (InterfaceEvent | InterfaceEventException)> = (props) => {

    const {resource, start, end, accountingType, activity, localisation, handleSubmitSuccess, template} = props;

    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState<any>({
        resource: resource.id,
        start: start,
        end: end,
        accountingType: accountingType,
        activity: activity?.id,
        localisation: localisation?.id,
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        resource: Yup.number().required('Champs requis'),
        start: Yup.date().required('Champs requis'),
        end: Yup.date().required('Champs requis'),
        accountingType: Yup.number().required('Champs requis'),
        activity: Yup.number().nullable(),
        localisation: Yup.number().nullable(),
    }))


    useEffect(() => {
        switch (props.eventType)
        {
            case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT:
                setInitialValues((prev: any) => ({
                    ...prev,
                    event: props.eventId,
                    exDay: props.exDay
                }));

                setValidationSchema((prev: any) => (Yup.object().shape({
                    ...prev.fields,
                    event: Yup.number().required(),
                    exDay: Yup.number().required(),
                })));
            break;
        }
    }, [])

    const handleSubmit = (resp: any, formikHelpers: FormikHelpers<any>) => {
        Promise.all([
            handleSubmitSuccess(resp.data)
        ]).then(() => {
            dispatch(showAlertSuccess('Créneau ajouté'));
            formikHelpers.setSubmitting(false)
        })
    }

    const formik = useFormik({
       initialValues: initialValues,
       validationSchema: validationSchema,
       enableReinitialize: true,
       onSubmit: (values, formikHelpers) => {
           switch (props.eventType){
               case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT:
                   api.createException(values).then(resp => {
                       handleSubmit(resp, formikHelpers)
                   })
                   break;
               case calendarTypes.EVENT_TYPE_TEMPLATE_EVENT_EXCEPTION:
                   api.editException(values, props.exceptionId).then(resp => {
                       handleSubmit(resp, formikHelpers)
                   })
                   break;
           }
       }
    });

    return <form onSubmit={formik.handleSubmit}>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                required={true}
                label={"Resource"}
                options={template.resources.map(r => ({label: r.title, value: r.id}))}
                value={resource ? {label: resource.title, value: resource.id} : undefined}
                onChange={(c) => formik.setFieldValue('resource', c?.value)}
            />
            <FormError touched={formik.touched} errors={formik.errors} field={'resource'} />
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md">
                    <BootstrapSelect
                        label={'Jour'}
                        options={getTemplateDayOptions().map(o => ({label: o.label, value: o.value}))}
                        value={getTemplateDayOption(format(formik.values.start, 'i'))}
                        onChange={(c) => {
                            let dt = getTemplateDayOption(String(c!.value))!.dt;
                            formik.setFieldValue(`start`, setHours(setMinutes(dt, formik.values.start.getMinutes()), formik.values.start.getHours()))
                            formik.setFieldValue(`end`, setHours(setMinutes(dt, formik.values.end.getMinutes()), formik.values.end.getHours()))
                        }}
                        placeholder={''}
                        required={true}
                    />
                </div>
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'beginAt')}
                        customInput={<DateCustomInput label={'Début'} icon={'bi bi-calendar-check text-primary'}/>}
                        selected={formik.values.start}
                        onChange={(date: Date) => {
                            let _start = formik.values.start;
                            formik.setFieldValue(`start`, setHours(setMinutes(_start, date.getMinutes()), date.getHours()))
                            formik.setFieldTouched('start', true)
                        }}
                        selectsStart
                        startDate={formik.values.start}
                        maxDate={formik.values.end}
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Début"
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                    <FormError touched={formik.touched} errors={formik.errors} field={'start'} />
                </div>
                <div className="input-group-text d-none d-md-flex col-auto px-0 mb-3">
                    <i className={'bi bi-arrow-left-right mx-1'}> </i>
                </div>
                <div className="col-12 col-md mb-3">
                    <DatePicker
                        disabled={formik.isSubmitting}
                        className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                        customInput={<DateCustomInput label={'Fin'} icon={'bi bi-calendar-check text-primary'}/>}
                        showTimeSelect
                        selected={formik.values.end}
                        onChange={(date: Date) => {
                            let _end = formik.values.end;
                            formik.setFieldValue(`end`, setHours(setMinutes(_end, date.getMinutes()), date.getHours()))
                            formik.setFieldTouched('end', true)
                        }}
                        selectsEnd
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        showTimeSelectOnly={true}
                    />
                    <FormError touched={formik.touched} errors={formik.errors} field={'end'} />
                </div>
            </div>
        </div>
        <div className="col-12 mb-3">
            <BootstrapSelect
                disabled={formik.isSubmitting}
                required={true}
                label={"Type d'heure"}
                options={AccountingTypes}
                value={AccountingTypes[0]}
                onChange={(c) => formik.setFieldValue('accountingType', c?.value)}
            />
            <FormError touched={formik.touched} errors={formik.errors} field={'accountingType'} />
        </div>
        <div className="row">
            <div className="col-md-6 mb-3">
                <BootstrapSelect
                    className={validationClass(formik.errors, formik.touched, 'localisation')}
                    disabled={formik.isSubmitting}
                    //options={template.company.localisations?.map(l => ({label: l.title, value: l.id!}))}
                    fetchEntity="localisation"
                    onChange={(c) => formik.setFieldValue('localisation', c?.value)}
                    label={"Emplacement"}
                    value={localisation ? {label: localisation.title, value: localisation.id!} : undefined}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'localisation'} />
            </div>
            <div className="col-md-6 mb-3">
                <BootstrapSelect
                    className={validationClass(formik.errors, formik.touched, 'activity')}
                    disabled={formik.isSubmitting}
                    //options={template.company.activities?.map(a => ({label: a.title, value: a.id!}))}
                    fetchEntity="localisation"
                    onChange={(c) => formik.setFieldValue('activity', c?.value)}
                    label={"Activité"}
                    value={activity ? {label: activity.title, value: activity.id!} : undefined}
                />
                <FormError touched={formik.touched} errors={formik.errors} field={'activity'} />
            </div>
        </div>
        <button className={'btn btn-light w-100'}>
            {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-plus-circle text-primary'}></i> Ajouter</>}
        </button>
    </form>
}

export default EventEditForm;