import React, {useEffect, useState} from "react";
import format from "../../../../utils/locale";
import * as api from "../../../../adapters/reporting"
import {Salary} from "../../../../models/salary";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {PeriodReporting, WeeksReporting} from "../../../../models/reporting";
import {Tooltip} from "bootstrap";
import {openSidebar} from "../../../../actions/rightSidebar";
import {
    ACCOUNTING_SETTINGS, COUNTER_SETTINGS,
    FORM_COUNTER_SHOW,
    FORM_COUNTER_WITHDRAWN_ADD, FORM_COUNTER_WITHDRAWN_EDIT,
    REPORTING_SHOW_WEEK
} from "../../../../constants/rightSidebar";
import * as counterApi from "../../../../adapters/counter";
import {refreshCounter} from "../../../../actions/counter";
import * as accessRights from "../../../../constants/accessRight";
import {axiosError} from "../../../../actions/axios";
import {showReportingSuccess} from "../../../../actions/reporting";
import {Granted} from "../../../../security/granted";

const SalaryCounter:React.FC = () => {

    const date = new Date();
    const [month, setMonth] = useState<number>(date.getMonth() + 1);
    const [year, setYear] = useState<number>(date.getFullYear());
    const [isLoading, setIsLoading] = useState(true)
    const salary: Salary = useSelector((state: RootStateOrAny) => state.salary.single)
    const state = useSelector((state: RootStateOrAny) => state.counter)
    const [weeksReporting, setWeeksReporting] = useState<WeeksReporting>()
    const [reporting, setReporting] = useState<PeriodReporting>()
    const dispatch = useDispatch();
    const [counterLoading, setCounterLoading] = useState(false)
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()

    useEffect(() => {
        setStartDate(new Date(year, month - 1, 1));
        setEndDate(new Date(year, month, 0));
    }, [month, year, salary, state.hasChanged])

    useEffect(() => {
        if (startDate && endDate){
            fetchItems();
        }
    }, [startDate, endDate])

    useEffect(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })

        return () => {
            tooltipTriggerList.map(t => Tooltip.getInstance(t).hide())
        }
    }, [reporting])

    const fetchItems = () => {
        if (startDate && endDate){
            setIsLoading(true);
            api.fetch({start: format(startDate, 'uuuu-MM-dd'), end: format(endDate, 'uuuu-MM-dd'), options: {salaries: [salary.id!], isCounter: true}}).then(data => {
                dispatch(showReportingSuccess(data.data[0]))
                setWeeksReporting(data.data[0].weeks);
                setReporting(data.data[0]);
                setIsLoading(false);
            }).catch(error => dispatch(axiosError(error)))
        }
    }

    const goToPrevMonth = () => {
        if (month === 1){
            setMonth(12)
            setYear(prevState => prevState - 1);
        }else{
            setMonth(prevState => prevState - 1)
        }
    }

    const goToNextMonth = () => {
        if (month === 12){
            setMonth(1)
            setYear(prevState => prevState + 1);
        }else{
            setMonth(prevState => prevState + 1)
        }
    }

    const month_array = [
        {title: 'Janvier'},
        {title: 'Février'},
        {title: 'Mars'},
        {title: 'Avril'},
        {title: 'Mai'},
        {title: 'Juin'},
        {title: 'Juillet'},
        {title: 'Août'},
        {title: 'Séptembre'},
        {title: 'Octobre'},
        {title: 'Novembre'},
        {title: 'Décembre'},
    ];

    const saveCounter = () => {
        if (startDate && endDate){
            setCounterLoading(true)
            counterApi.saveSingle({start: format(startDate, 'uuuu-MM-dd'), end: format(endDate, 'uuuu-MM-dd')}, Number(salary.id)).then(data => {
                setCounterLoading(false)
                dispatch(refreshCounter())
            }).catch(error => dispatch(axiosError(error)));
        }
    }

    const TableLoader:React.FC<{className: "circle"|"line", length?: number}> = (props) => {

        const {className} = props;

        return <>
            <td>
                <div className={className}> </div>
            </td>
            <td>
                <div className={className}> </div>
            </td>
            <td>
                <div className={className}> </div>
            </td>
            <td>
                <div className={className}> </div>
            </td>
            <td>
                <div className={className}> </div>
            </td>
            <td>
                <div className={className}> </div>
            </td>
        </>
    }

    return <>
        <div className="card mb-3 shadow-sm d-flex flex-grow-1">
            <div className="d-flex flex-grow-1">
                <div className={"table-responsive mb-0 d-flex flex-grow-1 placeholder wave"}>
                    <table className={'table table-bordered mb-0 table-counter text-center align-middle'}>
                        <thead>
                        <tr>
                            <th className={'position-sticky border-0 left-0 bg-white'}>
                                {Granted(accessRights.EDIT_COUNTER) && <button data-bs-toggle="tooltip"
                                         onClick={() => dispatch(openSidebar(COUNTER_SETTINGS))} className={'btn btn-light'}>
                                    <i className={'bi bi-gear'}> </i> Réglages
                                </button>}
                            </th>
                            <th colSpan={14 + (isLoading ? 4 : weeksReporting?.length || 0)}>
                                <button disabled={isLoading}  className={'btn'} onClick={() => setYear(prevState => prevState - 1)}>
                                    <i className={'bi bi-chevron-double-left'}> </i>
                                </button>
                                <button disabled={isLoading} className={'btn'} onClick={() => goToPrevMonth()}>
                                    <i className={'bi bi-chevron-left'}> </i>
                                </button>
                                <span className={'px-3'}>
                                    {month_array[month - 1].title}  {year}
                                </span>
                                <button disabled={isLoading} className={'btn'} onClick={() => goToNextMonth()}>
                                    <i className={'bi bi-chevron-right'}> </i>
                                </button>
                                <button disabled={isLoading} className={'btn'} onClick={() => setYear(prevState => prevState + 1)}>
                                    <i className={'bi bi-chevron-double-right'}> </i>
                                </button>
                            </th>
                        </tr>
                        <tr>
                            <th className={'position-sticky left-0 bg-white'}>
                                {Granted(accessRights.EDIT_COUNTER, salary.id) && <><button
                                    onClick={() => saveCounter()}
                                    disabled={counterLoading}
                                    className={'btn btn-light ms-2'}>
                                    {counterLoading ? <><div className="spinner-border spinner-border-sm text-success" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> Validatien en cours...</> : <><i className={'bi bi-check2-circle text-success'}> </i> Valider le compteur</>}
                                </button>
                                </>}
                            </th>
                            {month_array.map((item, key) => {

                                let dt = new Date();
                                dt.setMonth(key);

                                if(key+1 !== month) return <></>

                               return <>{key + 1 === month && <th>
                                       <p className="form-text">
                                           Compteur initial
                                       </p>
                                   </th>}
                                    <th className={'text-capitalize' + (key + 1 === month ? ' border-top border-left border-right' : '')}
                                        colSpan={key + 1 === month ? (isLoading ? 4 : weeksReporting?.length) : 1}
                                        key={key}>
                                        <span className="dropdown">
                                            <button className="btn dropdown-toggle text-capitalize" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                {format(dt, 'MMMM')}
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                {month_array.map((_item, _key) => {
                                                    let _dt = new Date();
                                                    _dt.setMonth(_key);
                                                    return <li className={'list-group-item list-group-item-action text-capitalize'} key={_key} onClick={() => setMonth(_key + 1)}>
                                                        {format(_dt, 'MMMM')}
                                                    </li>
                                                })}
                                            </ul>
                                        </span>
                                    </th>
                                   {key + 1 === month && <th>
                                       <p className="form-text">
                                           Compteur final
                                       </p>
                                   </th>}
                                </>
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={'position-sticky text-primary left-0 bg-white'}>
                               N° Semaine
                            </td>
                            {month_array.map((item, key) => key + 1 === month ?
                                (isLoading ? <TableLoader className={'circle'} /> : <>
                                    <td> </td>
                                    {weeksReporting?.map((weekReporting, key) => <td className={!key ? 'border-left' : (key + 1 === weeksReporting?.length ? 'border-right': '')} key={key}>
                                        <span data-bs-toggle="tooltip"
                                             title={`${format(new Date(weekReporting.dates.start))} - ${format(new Date(weekReporting.dates.end))}`}
                                        >
                                            S{weekReporting.weekNumber}
                                        </span>
                                        <div className="clearfix"> </div>
                                        {!weekReporting.disabled && <button
                                            onClick={(e) => {
                                                dispatch(openSidebar(REPORTING_SHOW_WEEK, {
                                                    currentWeek: weekReporting.weekNumber,
                                                    start: format(startDate!, "uuuu-MM-dd"),
                                                    end: format(endDate!, "uuuu-MM-dd")
                                                }))
                                            }}
                                            className={'btn text-primary'}
                                        >
                                            <i className={'bi bi-eye'}></i>
                                        </button>}
                                    </td>)}
                                    <td> </td>
                                </>)
                            : key+1 === month ? <td></td> : <> </>)}
                        </tr>
                        <tr>
                            <td className={'position-sticky text-primary left-0 bg-white'}>
                                Heures retirées du compteur
                            </td>
                            {month_array.map((item, key) => key + 1 === month ?
                                (isLoading ? <TableLoader className={'line'} /> : <>
                                    <td></td>
                                    {weeksReporting?.map((weekReporting, key) => <td className={!key ? 'border-left' : (key + 1 === weeksReporting?.length ? 'border-right': '')} key={key}>

                                        {(weekReporting.counterWithdrawn && weekReporting.counterWithdrawn !== '00:00') ? <>
                                            <span>
                                                <i className={'bi bi-arrow-down text-danger'}></i>
                                                {weekReporting.counterWithdrawn}
                                                {weekReporting.counterWithdrawnStep && Granted(accessRights.EDIT_COUNTER, salary.id) &&
                                                    <>
                                                        <button
                                                            onClick={() => dispatch(openSidebar(FORM_COUNTER_WITHDRAWN_EDIT, {...weekReporting.counterWithdrawnStep}))}
                                                            className={'btn btn-sm text-primary'}>
                                                            <i className={'bi bi-pencil'}> </i>
                                                        </button>
                                                    </>}
                                            </span>
                                        </> : <>{Granted(accessRights.EDIT_COUNTER, salary.id)  &&
                                            <button className={'btn'}
                                                    onClick={() => dispatch(openSidebar(FORM_COUNTER_WITHDRAWN_ADD, {
                                                        salary: salary,
                                                        year: year,
                                                        week: weekReporting.weekNumber
                                                    }))}
                                        ><i className={'bi bi-plus-circle text-danger'}></i></button>}</>}
                                        {weekReporting.counterWithdrawnStep?.description && <>
                                            <div className="clearfix"> </div>
                                            <span className="form-text">
                                                {weekReporting.counterWithdrawnStep?.description}
                                            </span>
                                        </>}
                                    </td>)}
                                    <td></td>
                                </>)
                                : key+1 === month ? <td></td> : <> </>)}
                        </tr>
                        <tr>
                            <td className={'position-sticky text-primary left-0 bg-white'}>
                                Compteurs
                                <button data-bs-toggle="tooltip" data-bs-placement="top" title={"Compteurs enregistrés (majorées si activé)"} className={'btn btn-sm'}>
                                    <i className={'bi bi-info-circle'}> </i>
                                </button>
                                <hr className={'my-1'}/>
                                <span className={'form-text text-capitalize'}>
                                    à date
                                </span>
                            </td>
                            {month_array.map((item, key) => key + 1 === month ?
                                (isLoading ? <TableLoader className={'line'} /> : <>
                                    <td></td>
                                    {weeksReporting?.map((weekReporting, key) => <td className={!key ? 'border-left' : (key + 1 === weeksReporting?.length ? 'border-right': '')} key={key}>
                                        {(weekReporting.counterSaved && weekReporting.counterSaved !== '00:00') ? <>
                                            <span className={"text-nowrap "}>
                                                {weekReporting.counterSaved[0] === '-' ? <i className={'bi bi-arrow-down text-danger'}></i> : <i className={'bi bi-arrow-up text-success'}></i>} {weekReporting.counterSaved}
                                                {weekReporting.counterStep && Granted(accessRights.EDIT_COUNTER, salary.id) &&
                                                    <>
                                                        <button
                                                            onClick={() => dispatch(openSidebar(FORM_COUNTER_SHOW, {...weekReporting.counterStep, salary: salary}))}
                                                            className={'btn btn-sm text-primary'}>
                                                            <i className={'bi bi-pencil'}> </i>
                                                        </button>
                                                    </>}
                                            </span>
                                        </> : <i className='bi bi-x'> </i>}
                                        {(weekReporting.counter && weekReporting.counter !== weekReporting.counterSaved && weekReporting.counter !== '00:00') && <>
                                            <hr className={'my-1'}/>
                                            <span className={"form-text"}>
                                                {weekReporting.counter}
                                            </span>
                                        </>}
                                    </td>)}
                                    <td></td>
                                </>)
                                : key+1 === month ? <td></td> : <> </>)}
                            </tr>
                            <tr>
                                <td className={'position-sticky text-primary left-0 bg-white'}>
                                    Suivi évolution compteur
                                    <button data-bs-toggle="tooltip" data-bs-placement="top" title={"Suivi de l'évolution des compteurs enregistrés"} className={'btn btn-sm'}>
                                        <i className={'bi bi-info-circle'}> </i>
                                    </button>
                                </td>
                                {month_array.map((item, key) => key + 1 === month ?
                                    (isLoading ? <TableLoader className={'line'} /> : <>
                                        <td>{reporting?.counterTotalInitial}</td>
                                        {weeksReporting?.map((weekReporting, key) => <td className={!key ? 'border-left' : (key + 1 === weeksReporting?.length ? 'border-right': '')} key={key}>
                                            {(weekReporting.counterSavedTotal && weekReporting.counterSavedTotal !== '00:00') ? weekReporting.counterSavedTotal : <i className='bi bi-x'> </i>}
                                        </td>)}
                                        <td>
                                            {reporting?.counterSavedTotal && <span className={'text-nowrap'}>{reporting?.counterSavedTotal} <i className={'bi bi-check'}> </i></span>}
                                            <div className="clearfix"> </div>
                                            {reporting?.counterSavedTotal !== reporting?.counterTotal && <span className={'form-text text-nowrap'}>{reporting?.counterTotal} <i className={'bi bi-exclamation-triangle text-warning'}> </i></span>}
                                        </td>
                                    </>)
                                    : key+1 === month ? <td></td> : <> </>)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
}

export default SalaryCounter;