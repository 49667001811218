import React, {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as API from "../../../../adapters/companyGroup"
import {TimeClockSettings} from "../../../../models/companyGroup";
import {editUserCompanyGroupSuccess} from "../../../../actions/auth";


const TimeClockConfig:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const settings:TimeClockSettings = state.auth.user.currentSalary.companyGroup.timeClockSettings;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (setting: string, value: any) => {
        setIsLoading(true);
        API.timeClockSetting({
            setting: setting,
            value: value
        }).then((data) => {
            dispatch(editUserCompanyGroupSuccess(data.data))
            setIsLoading(false);
        })
    }

    return <>
        <div className="col-12 mb-2">
            <div className="p-3">
                <div className="card p-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Borner les arrivées en avance
                                </label>
                                <div className="form-text">
                                    Activer si vous ne souhaitez pas comptabiliser les arrivées en avance
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input disabled={isLoading} onChange={(e) => handleChange('before_schedule', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.beforeSchedule} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Borner les départs en retard
                                </label>
                                <div className="form-text">
                                    Activer si vous ne souhaitez pas comptabiliser les sorties en retard
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input disabled={isLoading} onChange={(e) => handleChange('after_schedule', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.afterSchedule} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Activer une tolérance sur les arrivées et les départs en retard
                                </label>
                                <div className="form-text">
                                    En dessous du seuil, l'arrivée est arrondie à l'heure planifiée.
                                    <div className="clearfix"></div>
                                    <ul>
                                        <li>
                                            Ex : Si vous séléctionnez 10 minutes.
                                            <ul>
                                                <li>
                                                    Si la personne devait arriver à 8h00 et qu'elle arrive à 8h09, l'heure borné sera 8h00 (inférieur ou égal à 10 min : <span className={'text-success'}>Bornage</span>)
                                                </li>
                                                <li>
                                                    Si la personne devait arriver à 8h00 et qu'elle arrive à 8h11, l'heure borné sera 8h11 (supérieur à 10 min : <span className="text-danger">Pas de bornage</span>)
                                                </li>
                                                <li>
                                                    Si la personne devait partir à 19h00 et qu'elle part à 19h09, l'heure borné sera 19h00 (inférieur ou égal à 10 min : <span className={'text-success'}>Bornage</span>)
                                                </li>
                                                <li>
                                                    Si la personne devait partir à 19h00 et qu'elle part à 19h11, l'heure borné sera 19h11 (supérieur à 10 min : <span className="text-danger">Pas de bornage</span>)
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="input-group">
                                    <select disabled={isLoading} onChange={(e) => handleChange('late_after', e.target.value)} className="form-control" >
                                        <option selected={settings.lateAfter === 0} value="0">0</option>
                                        <option selected={settings.lateAfter === 1} value="1">1</option>
                                        <option selected={settings.lateAfter === 2} value="2">2</option>
                                        <option selected={settings.lateAfter === 5} value="5">5</option>
                                        <option selected={settings.lateAfter === 10} value="10">10</option>
                                        <option selected={settings.lateAfter === 15} value="15">15</option>
                                        <option selected={settings.lateAfter === 20} value="20">20</option>
                                        <option selected={settings.lateAfter === 25} value="25">25</option>
                                        <option selected={settings.lateAfter === 30} value="30">30</option>
                                    </select>
                                    <span className="input-group-text">minute(s)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Activer une tolérance sur les arrivées et départs en avance
                                </label>
                                <div className="form-text">
                                    En dessous du seuil, l'arrivée est arrondie à l'heure planifiée.
                                    <div className="clearfix"></div>
                                    <ul>
                                        <li>
                                            Ex : Si vous séléctionnez 10 minutes.
                                            <ul>
                                                <li>
                                                    Si la personne devait arriver à 8h00 et qu'elle arrive à 7h51, l'heure borné sera 8h00 (inférieur ou égal à 10 min : <span className={'text-success'}>Bornage</span>)
                                                </li>
                                                <li>
                                                    Si la personne devait arriver à 8h00 et qu'elle arrive à 7h49, l'heure borné sera 7h49 (supérieur à 10 min : <span className="text-danger">Pas de bornage</span>)
                                                </li>
                                                <li>
                                                    Si la personne devait partir à 19h00 et qu'elle part à 18h51, l'heure borné sera 19h00 (inférieur ou égal à 10 min : <span className={'text-success'}>Bornage</span>)
                                                </li>
                                                <li>
                                                    Si la personne devait partir à 19h00 et qu'elle part à 18h49, l'heure borné sera 18h49 (supérieur à 10 min : <span className="text-danger">Pas de bornage</span>)
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="input-group">
                                    <select disabled={isLoading} onChange={(e) => handleChange('advance_before', e.target.value)} className="form-control" >
                                        <option selected={settings.advanceBefore === 0} value="0">0</option>
                                        <option selected={settings.advanceBefore === 1} value="1">1</option>
                                        <option selected={settings.advanceBefore === 2} value="2">2</option>
                                        <option selected={settings.advanceBefore === 5} value="5">5</option>
                                        <option selected={settings.advanceBefore === 10} value="10">10</option>
                                        <option selected={settings.advanceBefore === 15} value="15">15</option>
                                        <option selected={settings.advanceBefore === 20} value="20">20</option>
                                        <option selected={settings.advanceBefore === 25} value="25">25</option>
                                        <option selected={settings.advanceBefore === 30} value="30">30</option>
                                    </select>
                                    <span className="input-group-text">minute(s)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Badger les pauses
                                </label>
                                <div className="form-text">
                                    Activer si vous souhaitez badger les pauses
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input disabled={isLoading} onChange={(e) => handleChange('pointBreak', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.pointBreak} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {settings.pointBreak && <>
                        <hr className={'bg-primary'}/>
                        <div className="col-12">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="">
                                        Activer les bornages aux pauses
                                    </label>
                                    <div className="form-text">
                                        Activer si vous souhaitez activer les paramétrages ci-dessus aux pauses
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="form-check form-switch">
                                        <input disabled={isLoading}
                                               onChange={(e) => handleChange('break', e.target.checked)}
                                               className="form-check-input" type="checkbox"
                                               defaultChecked={settings.break}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {settings.break && <>
                            <hr className={'bg-primary'}/>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="">
                                            Activer les pauses flottantes
                                        </label>
                                        <div className="form-text">
                                            Activer si vous souhaitez que le bornage des pauses se fasse par rapport à la durée et non aux heures exactes.
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-check form-switch">
                                            <input disabled={isLoading}
                                                   onChange={(e) => handleChange('floatingBreak', e.target.checked)}
                                                   className="form-check-input" type="checkbox"
                                                   defaultChecked={settings.floatingBreak}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </>}
                    <hr className={'bg-primary'}/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="">
                                    Prendre les photos sur la badgeuse
                                </label>
                                <div className="form-text">
                                    Une mise à jour de l'application badgeuse est necessaire pour utiliser cette fonctionnalité.
                                    <div className="clearfix"></div>
                                    Pour ce faire, merci de nous contacter par mail à l'adresse contact@sivan-gestion.com
                                </div>
                                <div className="alert alert-info">
                                    Conformément aux dernières recommandations de la CNIL, lorsque cette option est activée, l'employeur s'oblige à traiter la ou les photos prises.
                                    <br/>
                                    Les photos seront conservées 30 jours puis automatiquement supprimées
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check form-switch">
                                    <input disabled={isLoading} onChange={(e) => handleChange('should_take_picture', e.target.checked)} className="form-check-input" type="checkbox" defaultChecked={settings.shouldTakePicture} />
                                </div>
                            </div>
                        </div>
                        {settings.shouldTakePicture && <div className={'col-12'}>
                            <div className="form-check">
                                <input defaultChecked={settings.pictureFrequency === 1} onChange={(e) => handleChange('picture_frequency', 1)} className="form-check-input" type="radio" name={"picture_frequency"} id="picture_frequency_1" />
                                    <label className="form-check-label" htmlFor="picture_frequency_1">
                                        Prendre les photos aléatoirement
                                    </label>
                            </div>
                            <div className="form-check">
                                <input defaultChecked={settings.pictureFrequency === 2} className="form-check-input" type="radio" onChange={(e) => handleChange('picture_frequency', 2)} name={"picture_frequency"}  id="picture_frequency_2" />
                                    <label className="form-check-label" htmlFor="picture_frequency_2">
                                        Prendre les photos à chaque badgeage
                                    </label>
                                {settings.pictureFrequency === 2 && <div className="alert alert-warning">
                                        <i className={'bi bi-exclamation-triangle'}></i> Cette option de controle n'est pas recommandé par la CNIL. Veuillez utiliser cette option en cas de force majeur seulement dans une durée limite de 3 jours.
                                    </div>}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default TimeClockConfig;