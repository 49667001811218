import * as React from "react";

import TaskGroupShow from "../../../components/taskGroup/show";

const TaskGroupShowPage : React.FC = () => {
    return (
        <TaskGroupShow />
    );
}

export default TaskGroupShowPage;