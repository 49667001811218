export const ADD_EXPENSE = 'ADD_EXPENSE';
export const ADD_EXPENSE_SUCCESS = 'ADD_EXPENSE_SUCCESS';
export const ADD_EXPENSE_FAILURE = 'ADD_EXPENSE_FAILURE';

export const EDIT_EXPENSE = 'EDIT_EXPENSE';
export const EDIT_EXPENSE_SUCCESS = 'EDIT_EXPENSE_SUCCESS';
export const EDIT_EXPENSE_FAILURE = 'EDIT_EXPENSE_FAILURE';

export const FETCH_EXPENSES = 'FETCH_EXPENSES';
export const FETCH_EXPENSES_SUCCESS = 'FETCH_EXPENSES_SUCCESS';
export const FETCH_EXPENSES_FAILURE = 'FETCH_EXPENSES_FAILURE';

export const SHOW_EXPENSE = 'SHOW_EXPENSE';
export const SHOW_EXPENSE_SUCCESS = 'SHOW_EXPENSE_SUCCESS';
export const SHOW_EXPENSE_FAILURE = 'SHOW_EXPENSE_FAILURE';

export const CANCEL_EXPENSE_SUCCESS = 'CANCEL_EXPENSE_SUCCESS';

export const TYPE_MEAL = 1;
export const TYPE_TRANSPORT = 2;
export const TYPE_HOTEL = 3;
export const TYPE_EQUIPMENT = 4;
export const TYPE_SERVICE = 5;
export const TYPE_MILEAGE = 6;
export const TYPE_ELSE = 7;

export const STATUS_PENDING = 1;
export const STATUS_ACCEPTED = 2;
export const STATUS_REFUSED = 3;

export const STATUS_NAMES: any = {
    1: 'En attente',
    2: 'Acceptée',
    3: 'Refusée'
};

export const getStatusChoices = () => {
    return [
        {value: STATUS_ACCEPTED, label: STATUS_NAMES[STATUS_ACCEPTED]},
        {value: STATUS_REFUSED, label: STATUS_NAMES[STATUS_REFUSED]},
        {value: STATUS_PENDING, label: STATUS_NAMES[STATUS_PENDING]},
    ]
}

export const getStatusChoice = (status: number) => {
    return getStatusChoices().find(c => c.value === status)
}

export const getStatusIcon = (status: number) => {
    switch (status){
        case 1:
            return 'bi bi-clock-history text-warning'
        case 2:
            return 'bi bi-check-circle text-success'
        case 3:
            return 'bi bi-x-circle text-danger'
    }
}

export const TYPE_NAMES: any = {
    1: 'Repas',
    2: 'Transport',
    3: 'Hotel/Hébergement',
    4: 'Équipement',
    5: 'Service',
    6: 'Frais kilométrique',
    7: 'Autre',
};

export const getTypeChoices = () => {
    return [
        {value: TYPE_MEAL, label: TYPE_NAMES[TYPE_MEAL]},
        {value: TYPE_TRANSPORT, label: TYPE_NAMES[TYPE_TRANSPORT]},
        {value: TYPE_HOTEL, label: TYPE_NAMES[TYPE_HOTEL]},
        {value: TYPE_EQUIPMENT, label: TYPE_NAMES[TYPE_EQUIPMENT]},
        {value: TYPE_SERVICE, label: TYPE_NAMES[TYPE_SERVICE]},
        {value: TYPE_MILEAGE, label: TYPE_NAMES[TYPE_MILEAGE]},
        {value: TYPE_ELSE, label: TYPE_NAMES[TYPE_ELSE]},
    ]
}
export const getTypeChoice = (type: number) => {
    return getTypeChoices().find(c => c.value === type)
}

export const getCurrencyChoices = () => {
    return [
        {value: 1, label: "EUR (€)"}
    ]
}

export const VEHICLE_ENGINE_THERMAL = 1;
export const VEHICLE_ENGINE_ELECTRIC = 2;

export const VEHICLE_CAR = 1;
export const VEHICLE_MOTORCYCLE = 2;
export const VEHICLE_MOPED = 3;
export const VEHICLE_BIKE = 4;


export const VEHICLE_NAMES: any = {
    1: 'Automobile',
    2: 'Motocyclette',
    3: 'Cyclomoteur',
    4: 'Vélo'
};


export const getVehicleChoices = () => {
    return [
        {value: 1, label: VEHICLE_NAMES[VEHICLE_CAR]},
        {value: 2, label: VEHICLE_NAMES[VEHICLE_MOTORCYCLE]},
        {value: 3, label: VEHICLE_NAMES[VEHICLE_MOPED]},
        {value: 4, label: VEHICLE_NAMES[VEHICLE_BIKE]},
    ]
}

export const getVehicleChoice = (vehicle: number) => {
    return getVehicleChoices().find(c => c.value === vehicle)
}

export const VEHICLE_CAR_POWER_3 = 1;
export const VEHICLE_CAR_POWER_4 = 2;
export const VEHICLE_CAR_POWER_5 = 3;
export const VEHICLE_CAR_POWER_6 = 4;
export const VEHICLE_CAR_POWER_7 = 5;

export const VEHICLE_CAR_POWER_NAMES: any = {
    1: '3 CV et -',
    2: '4 CV',
    3: '5 CV',
    4: '6 CV',
    5: '7 CV et +',
};

export const VEHICLE_MOTORCYCLE_POWER_2 = 1;
export const VEHICLE_MOTORCYCLE_POWER_5 = 2;
export const VEHICLE_MOTORCYCLE_POWER_5_AND_MORE = 3;

export const VEHICLE_MOTORCYCLE_POWER_NAMES: any = {
    1: '1 à 2 CV',
    2: '3 à 4 CV',
    3: '5 CV et +',
};

export const getVehiclePowerChoices = (vehicle: number) => {

    switch (vehicle)
    {
        case VEHICLE_CAR:
            return [
                {value: VEHICLE_CAR_POWER_3, label: VEHICLE_CAR_POWER_NAMES[VEHICLE_CAR_POWER_3]},
                {value: VEHICLE_CAR_POWER_4, label: VEHICLE_CAR_POWER_NAMES[VEHICLE_CAR_POWER_4]},
                {value: VEHICLE_CAR_POWER_5, label: VEHICLE_CAR_POWER_NAMES[VEHICLE_CAR_POWER_5]},
                {value: VEHICLE_CAR_POWER_6, label: VEHICLE_CAR_POWER_NAMES[VEHICLE_CAR_POWER_6]},
                {value: VEHICLE_CAR_POWER_7, label: VEHICLE_CAR_POWER_NAMES[VEHICLE_CAR_POWER_7]},
            ]
        case VEHICLE_MOTORCYCLE:
            return [
                {value: VEHICLE_MOTORCYCLE_POWER_2, label: VEHICLE_MOTORCYCLE_POWER_NAMES[VEHICLE_MOTORCYCLE_POWER_2]},
                {value: VEHICLE_MOTORCYCLE_POWER_5, label: VEHICLE_MOTORCYCLE_POWER_NAMES[VEHICLE_MOTORCYCLE_POWER_5]},
                {value: VEHICLE_MOTORCYCLE_POWER_5_AND_MORE, label: VEHICLE_MOTORCYCLE_POWER_NAMES[VEHICLE_MOTORCYCLE_POWER_5_AND_MORE]},
            ]
    }

    return []
}

export const getVehiclePowerChoice = (vehicle: number, vehiclePower: number) => {
    return getVehiclePowerChoices(vehicle).find(c => c.value === vehiclePower)
}

export const getVehiclePowerName = (vehicle: number, vehiclePower: number) => {

    switch (vehicle)
    {
        case VEHICLE_CAR:
           return VEHICLE_CAR_POWER_NAMES[vehiclePower]
        case VEHICLE_MOTORCYCLE:
            return VEHICLE_MOTORCYCLE_POWER_NAMES[vehiclePower]
    }

    return ""
}