import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import TaskCalendar from "../calendar/task/list";
import DashboardAbsenceRequest from "./absenceRequest";
import DashboardKeepNote from "./keepNote";
import DashboardFeed from "./feed";
import DashboardPointing from "./pointing";
import DashboardBookingOffer from "./bookingOffer";
import DashboardCounter from "./counter";
import DashboardEventOffer from "./eventOffer";
import DashboardExpenseRequest from "./expenseRequest";
import DashboardTimeClock from "./timeClock";
import CalendarSingle from "../calendar/single";

import * as accessRights from "../../constants/accessRight";

import * as api from "../../adapters/timeClock";
import * as actions from "../../actions/timeClock";
import { setPageTitle, setHelpProductSheetIds } from "../../actions/header";

import getVersion from "../../security/getVersion";
import getUser from "../../security/getUser";
import { Granted, GrantedAny } from "../../security/granted";
import HasModule from "../../security/hasModule";
import hasModule from "../../security/hasModule";
import format from "../../utils/locale";

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();

        dispatch(setPageTitle(`Tableau de bord`))
        dispatch(setHelpProductSheetIds([5, 7, 14]))

        if (Granted(accessRights.LIST_TIME_CLOCK)){
            dispatch(actions.fetchTimeClocks());
            api.fetch({start: format(new Date(), 'uuuu-MM-dd'), end: format(new Date(), 'uuuu-MM-dd'), options: {}}, controller.signal).then(data => {
                dispatch(actions.fetchTimeClocksSuccess(data.data.events));
            })
        }

        return () => {
            dispatch(setHelpProductSheetIds([]))
            controller.abort();
        }
    }, [])

    return (
        <div className="container-fluid px-0 d-block d-md-flex h-100">
            <div className={"d-flex flex-column p-3" + (Granted(accessRights.LIST_TASK_GROUP) && hasModule('task') ? " col-lg-9 " : " col w-100")}>
                {Granted(accessRights.CLOCK_IN_FROM_APP) && HasModule('timeClock') &&
                    <DashboardPointing />
                }

                <div className="col-12">
                    <div className="row">
                        {Granted(accessRights.LIST_ABSENCE) &&
                            <div className="mb-3 col-12 col-md-6 col-lg">
                                <DashboardAbsenceRequest/>
                            </div>
                        }
                        {Granted(accessRights.LIST_TIME_CLOCK) && HasModule('timeClock') &&
                            <>
                                {<DashboardTimeClock />}
                            </>
                        }
                        {Granted(accessRights.LIST_KEEP_NOTE) &&
                            <div className="mb-3 col-12 col-md-6 col-lg">
                                <DashboardKeepNote/>
                            </div>
                        }
                    </div>
                </div>

                <div className="d-flex flex-grow-1 row">
                    <div className="col-lg-8 d-flex flex-column mb-3">
                        <div className="p-3 card shadow-sm flex-grow-1">
                            <CalendarSingle
                                key={'calendar'}
                                salary={getUser().currentSalary}
                                start={new Date()}
                            />
                        </div>
                    </div>

                    <div className="col-lg-4 h-100 pb-3 d-flex flex-column">
                        {HasModule('expense') && Granted(accessRights.EDIT_EXPENSE) &&
                            <DashboardExpenseRequest />
                        }
                        {Granted(accessRights.LIST_COUNTER) &&
                            <DashboardCounter />
                        }
                        {HasModule("bookingOffer") && GrantedAny([accessRights.LIST_BOOKING_OFFER, accessRights.APPLY_BOOKING_OFFER]) &&
                            (getVersion() === 1 ? <DashboardEventOffer /> : <DashboardBookingOffer />)
                        }
                        <div className="card flex-grow-1 p-3 shadow-sm">
                            <DashboardFeed />
                        </div>
                    </div>
                </div>
            </div>

            {Granted(accessRights.LIST_TASK_GROUP) && hasModule('task') &&
                <div className="col-lg-3 d-block h-100 p-3 h-100">
                    <div className="card shadow-sm h-100 p-1 p-md-3 overflow-auto">
                        <TaskCalendar key={'list'} type={'UNCONTROLLED'} />
                    </div>
                </div>
            }
        </div>
    )
}

export default Dashboard;