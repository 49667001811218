import React, {useEffect} from "react";
import {setPageTitle} from "../../../actions/header";
import {useDispatch} from "react-redux";

const Error403 : React.FC = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle("Erreur 403"))
    }, [])

    return (
        <div className={'w-100 h-100 py-5 text-center align-middle'}>
            <div className="w-100 mt-5 text-warning">
                <i className="bi bi-exclamation-triangle fs-1"> </i>
            </div>
            <h1 className={'display-4'}>
                Acces refusé
            </h1>
            <p className={'lead'}>
                Vous ne disposez pas des droits necessaires pour accéder à cette page.
            </p>
        </div>
    );
}

export default Error403;