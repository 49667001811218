import React, {useEffect, useState} from "react";
import EventEditForm from "./form";
import {Localisation} from "../../../../models/localisation";
import {Activity} from "../../../../models/activity";
import {Template} from "../../../../models/template";

interface Interface {
    close: () => any,
    resource: {id: number, title: string}
    start: Date,
    end: Date,
    accountingType: number,
    activity?: Activity
    localisation?: Localisation,
    handleSubmitSuccess: (ev: any) => any,
    template: Template
}

 interface InterfaceEvent {
    eventType: 'templateEvent',
    exDay: number,
    eventId: number,
}

interface InterfaceEventException {
    eventType: 'templateEventException',
    exceptionId: number,
}

const EventEdit:React.FC<Interface & (InterfaceEvent | InterfaceEventException)> = (props) => {

    const {handleSubmitSuccess, close, template} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventEditLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {props.eventType === "templateEvent" && <EventEditForm
                key={key}
                resource={props.resource}
                start={props.start}
                end={props.end}
                localisation={props.localisation}
                activity={props.activity}
                template={template}
                handleSubmitSuccess={handleSubmit}
                accountingType={props.accountingType}
                eventType={props.eventType}
                eventId={props.eventId}
                exDay={props.exDay}
            />}
            {props.eventType === "templateEventException" && <EventEditForm
                key={key}
                resource={props.resource}
                start={props.start}
                end={props.end}
                localisation={props.localisation}
                activity={props.activity}
                template={template}
                handleSubmitSuccess={handleSubmit}
                accountingType={props.accountingType}
                eventType={props.eventType}
                exceptionId={props.exceptionId}
            />}
        </div>
    </>
}

export default EventEdit;