import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {PeriodReporting} from "../../../models/reporting";
import format from "../../../utils/locale"
import {ModuleSettings} from "../../../models/companyGroup";
import * as accessRights from "../../../constants/accessRight";
import {openSidebar} from "../../../actions/rightSidebar";
import {
    FORM_EVENT_EXCEPTION_ADD, FORM_EVENT_EXCEPTION_EDIT,
    REPORTING_SHOW_WEEK,
    TIME_CLOCK_BOOKING_ADD,
    TIME_CLOCK_BOOKING_EDIT
} from "../../../constants/rightSidebar";
import {Tooltip} from "bootstrap";
import {
    ACCOUNTING_TYPE_ABSENCE_ACCOUNTED,
    ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED,
    ACCOUNTING_TYPE_WORK_ACCOUNTED, ACCOUNTING_TYPE_WORK_UNACCOUNTED
} from "../../../constants/booking";
import * as counterApi from "../../../adapters/counter";
import * as reportingApi from "../../../adapters/reporting";
import {showReportingSuccess} from "../../../actions/reporting";
import {axiosError} from "../../../actions/axios";
import {refreshCounter} from "../../../actions/counter";
import * as API from "../../../adapters/reporting";
import FileSaver from "file-saver";
import {TYPE_EVENT, TYPE_EVENT_EXCEPTION} from "../../../constants/event";
import {Granted} from "../../../security/granted";

const ReportingShowWeek:React.FC = () => {

    const moduleSettings: ModuleSettings = useSelector((state: RootStateOrAny) => state.auth.user.currentSalary.companyGroup.modules);
    const periodReporting: PeriodReporting = useSelector((state: RootStateOrAny) => state.reporting.single)
    const state = useSelector((state: RootStateOrAny) => state)
    const [currentWeek, setCurrentWeek] = useState(state.rightSidebar.data.currentWeek);
    const [savingCounter, setSavingCounter] = useState<boolean>( false);
    const [savingWeekCounter, setSavingWeekCounter] = useState<number>( );
    const dispatch = useDispatch();

    useEffect(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }, []);

    const saveCounter = (_startDate: Date, _endDate: Date) => {
        if(_startDate && _endDate && state.rightSidebar.data.start && state.rightSidebar.data.end)
        {
            setSavingCounter(true)
            counterApi.saveSingle({start: format(_startDate, "uuuu-MM-dd"), end: format(_endDate, "uuuu-MM-dd")}, periodReporting.salary.id)
                .then(data => {
                    reportingApi.fetch({
                        start: state.rightSidebar.data.start,
                        end: state.rightSidebar.data.end,
                        options: {
                            salaries: [
                                periodReporting.salary.id
                            ]
                        }
                    }).then(_data => {
                        dispatch(showReportingSuccess(_data.data[0]))
                        dispatch(refreshCounter())
                        setSavingCounter(false)
                        setSavingWeekCounter(undefined)
                    })
                })
                .catch(error => dispatch(axiosError(error)))
            ;
        }
    }

    const [downloading, setIsDownloading] = useState(false);
    const download = () => {
        setIsDownloading(true)
        API.exportDays({
            start: state.rightSidebar.data.start,
            end: state.rightSidebar.data.end,
        }, periodReporting.salary.id).then(data => {
            let newBlob = new Blob([data.data], { type: 'application/pdf;charset=utf-8' })
            FileSaver.saveAs(newBlob, `reporting-days-${periodReporting.salary.id}-${state.rightSidebar.data.start}-${state.rightSidebar.data.end}.pdf`)
            setIsDownloading(false)
        })
    }

    return <>
        <div className="py-3">
            <div className="col-12 d-flex align-items-center mb-3">
                <h4 className={'text-primary flex-grow-1'}>
                    {periodReporting.salary.title}
                </h4>
                <button disabled={downloading} className={'btn'} onClick={() => download()}>
                    <i className={'bi bi-download'}></i> Télécharger
                </button>
            </div>
            <div className="table-responsive">
                <table className="nav nav-tabs mb-3 table" id="pills-tab" role="tablist">
                    <thead>
                    {periodReporting.weeks.map((reporting) => !reporting.disabled &&
                        <td className="nav-item me-2" role="presentation">
                            <button disabled={reporting.disabled} onClick={() => setCurrentWeek(reporting.weekNumber)} className={"nav-link " + (currentWeek == reporting.weekNumber ? "active" : "")} id={`pills-${reporting.weekNumber}-tab`} data-bs-toggle="pill"
                                    data-bs-target={`#pills-${reporting.weekNumber}`} type="button" role="tab" aria-controls={`pills-${reporting.weekNumber}`}
                                    aria-selected="true"> S{reporting.weekNumber}
                            </button>
                        </td>)}
                    </thead>
                </table>
            </div>
            <div className="tab-content" id="pills-tabContent">
            {periodReporting.weeks.map((reporting, index) => !reporting.disabled &&
                <>
                    <div className={"tab-pane fade show " + (currentWeek == reporting.weekNumber ? "active" : "")} id={`pills-${reporting.weekNumber}`} role="tabpanel" aria-labelledby={`pills-${reporting.weekNumber}-tab`}>
                        <div className="form-text mb-3">
                            <i className={'bi bi-calendar'}> </i> {format(new Date(reporting.dates.start))} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(new Date(reporting.dates.end))}
                        </div>
                        <table className={'table text-center align-middle table-bordered shadow-sm'}>
                            <thead>
                            <tr>
                                {moduleSettings.timeClock ? <>
                                        <th>
                                            Date
                                        </th>
                                        <th>
                                            Planifié
                                        </th>
                                        <th>
                                            Efféctué
                                        </th>
                                        <th>
                                            Delta
                                        </th>
                                        <th>
                                            #
                                        </th>
                                    </>
                                    :
                                    <>
                                        <th>
                                            Date
                                        </th>
                                        <th>
                                            Efféctué
                                        </th>
                                        <th>
                                            #
                                        </th>
                                    </>
                                }
                            </tr>
                            </thead>
                            <tbody>
                                {reporting.daysGrouped.map((day) => <tr className={!day.planned?.length && !day.done.length ? "bg-light" : ''}>
                                    <td>
                                        {format(new Date(day.title))}
                                        <div className="clearfix"> </div>
                                        {day.labourDay && <span className={'text-success'}>{day.labourDay.title}</span>}
                                    </td>
                                    {/* PLANNED */}
                                    {day.planned && <td>
                                        {!day.planned.length && <><i className={'bi bi-x'}></i> Jour non planifié</>}

                                        {day.planned.map((shift, i) => <>
                                            <>
                                                <div className={'' + (shift.absence ? 'bg-light' : '')}>
                                                    <td>
                                                        <>
                                                            {[ACCOUNTING_TYPE_WORK_ACCOUNTED, ACCOUNTING_TYPE_ABSENCE_ACCOUNTED].includes(shift.accountingType) && <i className={'bi bi-check-circle text-success me-1 form-text'}></i>}
                                                            {[ACCOUNTING_TYPE_WORK_UNACCOUNTED, ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED].includes(shift.accountingType) && <i className={'bi bi-x-circle text-danger me-1 form-text'}></i>}
                                                            {format(new Date(shift.start), 'HH:mm')}
                                                            <i className={"bi bi-arrow-left-right mx-2"}> </i>
                                                            {format(new Date(shift.end), 'HH:mm')}
                                                            <span><i className={'bi bi-stopwatch ms-2'}> </i>{shift.timeCaption}</span>
                                                        </>
                                                        {shift.exchanged && <>
                                                            <div className="clearfix"></div>
                                                            <div className="form-text">
                                                                <i className={'bi bi-shuffle'}></i> {shift.title}
                                                            </div>
                                                        </>}
                                                        {shift.absence && <>
                                                            <div className="clearfix"></div>
                                                            <span
                                                                className={'form-text'}>{shift.absence.typeName}</span>
                                                        </>}
                                                        {shift.labourDayWorked && <>
                                                            <div className="clearfix"></div>
                                                            <span className={'form-text text-success'}> Jour férie travaillé</span>
                                                        </>}
                                                        {shift.comment && <>
                                                            <div className="clearfix"> </div>
                                                            <span className={'form-text'}> <i className={'bi bi-quote'}></i>
                                                                {shift.comment}
                                                            </span>
                                                        </>}
                                                    </td>
                                                </div>
                                            </>
                                            {i + 1 < day.planned!.length && <hr className={'my-2'}/>}
                                        </>)}
                                    </td>}
                                    <td>
                                        {!day.done.length && <i className={'bi bi-x'}></i>}
                                        {day.done.map((shift, i) => {
                                            switch (shift.eventType)
                                            {
                                                case TYPE_EVENT:
                                                    return <>
                                                        {!shift.absence && <>{format(new Date(shift.start), 'HH:mm')} <i className={"bi bi-arrow-left-right mx-2"}> </i> {format(new Date(shift.end), 'HH:mm')}</>}
                                                        <span>
                                                                <i className={'bi bi-stopwatch ms-2'}> </i> {shift.timeCaption}
                                                            {Granted(accessRights.EDIT_TIME_CLOCK, periodReporting.salary.id) && <button
                                                                onClick={() => dispatch(openSidebar(FORM_EVENT_EXCEPTION_ADD, {
                                                                    start: new Date(shift.start),
                                                                    initialStart: new Date(shift.start),
                                                                    end: new Date(shift.end),
                                                                    salary: periodReporting.salary,
                                                                    accountingType: shift.accountingType,
                                                                    company: shift.company,
                                                                    eventId: shift.eventId,
                                                                    comment: shift.comment,
                                                                    key: shift.id,
                                                                    next: REPORTING_SHOW_WEEK,
                                                                    data: {
                                                                        currentWeek: currentWeek,
                                                                        start: state.rightSidebar.data.start,
                                                                        end: state.rightSidebar.data.end,
                                                                    }
                                                                }))} className={'btn btn-sm text-primary'}>
                                                                <i className={'bi bi-pencil'}> </i>
                                                            </button>}
                                                            </span>
                                                        {shift.comment && <>
                                                            <div className="clearfix"> </div>
                                                            <span className={'form-text'}> <i className={'bi bi-quote'}></i>
                                                                {shift.comment}
                                                            </span>
                                                        </>}
                                                        {shift.absence && <div className={'col-12'}>
                                                            {shift.absence.typeName}
                                                            <div className="form-text">
                                                                {format(new Date(shift.absence.start))}
                                                                <i className={'bi bi-arrow-left-right mx-2'}> </i>
                                                                {format(new Date(shift.absence.end))}
                                                            </div>
                                                        </div>}
                                                        {shift.exchanged && <>
                                                            <div className="clearfix"></div>
                                                            <div className="form-text">
                                                                <i className={'bi bi-shuffle'}></i> {shift.title}
                                                            </div>
                                                        </>}
                                                        {i + 1 < day.done.length && <hr className={'my-2'}/>}
                                                    </>
                                                case TYPE_EVENT_EXCEPTION:
                                                    return <>
                                                        {format(new Date(shift.start), 'HH:mm')} <i className={"bi bi-arrow-left-right mx-2"}> </i> {format(new Date(shift.end), 'HH:mm')}

                                                        <span>
                                                                <i className={'bi bi-stopwatch ms-2'}> </i> {shift.timeCaption}
                                                            {Granted(accessRights.EDIT_TIME_CLOCK, periodReporting.salary.id) && <button
                                                                onClick={() => dispatch(openSidebar(FORM_EVENT_EXCEPTION_EDIT, {
                                                                    start: new Date(shift.start),
                                                                    end: new Date(shift.end),
                                                                    salary: periodReporting.salary,
                                                                    company: shift.company,
                                                                    exceptionId: shift.exceptionId,
                                                                    accountingType: shift.accountingType,
                                                                    key: shift.id,
                                                                    next: REPORTING_SHOW_WEEK,
                                                                    data: {
                                                                        currentWeek: currentWeek,
                                                                        start: state.rightSidebar.data.start,
                                                                        end: state.rightSidebar.data.end,
                                                                    }
                                                                }))} className={'btn btn-sm text-primary'}>
                                                                <i className={'bi bi-pencil'}> </i>
                                                            </button>}
                                                            </span>
                                                        {shift.comment && <>
                                                            <div className="clearfix"> </div>
                                                            <span className={'form-text'}> <i className={'bi bi-quote'}></i>
                                                                {shift.comment}
                                                            </span>
                                                        </>}
                                                        {i + 1 < day.done.length && <hr className={'my-2'}/>}
                                                    </>
                                                case "timeClockBookingException":
                                                case "timeClockBooking":
                                                    return <>
                                                        <div>
                                                            {format(new Date(shift.start), 'HH:mm')} <i className={"bi bi-arrow-left-right mx-2"}> </i> {format(new Date(shift.end), 'HH:mm')}
                                                            <span>
                                                                <i className={'bi bi-stopwatch ms-2'}> </i> {shift.timeCaption}
                                                                {Granted(accessRights.EDIT_TIME_CLOCK, periodReporting.salary.id) && <button
                                                                    onClick={() => dispatch(openSidebar(TIME_CLOCK_BOOKING_EDIT, {
                                                                        beginAt: shift.start,
                                                                        endAt: shift.end,
                                                                        salary: periodReporting.salary,
                                                                        company: shift.company,
                                                                        start: state.rightSidebar.data.start,
                                                                        end: state.rightSidebar.data.end,
                                                                        id: shift.booking?.id,
                                                                        key: shift.id,
                                                                        next: REPORTING_SHOW_WEEK,
                                                                        data: {
                                                                            currentWeek: currentWeek,
                                                                            start: state.rightSidebar.data.start,
                                                                            end: state.rightSidebar.data.end,
                                                                        }
                                                                    }))} className={'btn btn-sm text-primary'}>
                                                                    <i className={'bi bi-pencil'}> </i>
                                                                </button>}
                                                            </span>
                                                            {shift.breakRemoved && <>
                                                                <div className={'clearfix'} />
                                                                <span className="form-text">Pause : <i className={'bi bi-arrow-down-circle text-danger'}></i> {shift.breakRemoved}</span>
                                                            </>}
                                                            {shift.__init && (shift.__init.start !== shift.start || shift.__init.end !== shift.end) && <span className={'form-text'}>
                                                                <span className="clearfix"> </span>
                                                                {format(new Date(shift.__init.start), 'HH:mm')} <i className={"bi bi-arrow-left-right mx-2"}> </i> {format(new Date(shift.__init.end), 'HH:mm')}
                                                                <i className={'bi bi-stopwatch ms-2'}> </i> {shift.__init.timeCaption}
                                                            </span>}
                                                            {shift.description && <>
                                                                <div className="clearfix"> </div>
                                                                <span className={'form-text'}> <i className={'bi bi-quote'}></i> {shift.description}</span>
                                                            </>}
                                                        </div>
                                                        {i + 1 < day.done.length && <hr className={'my-2'}/>}
                                                    </>
                                                case "timeClockBookingMissing":
                                                    return <div>
                                                            {((!shift.labourDay || shift.labourDayWorked) && shift.accountingType === ACCOUNTING_TYPE_WORK_ACCOUNTED && (Granted(accessRights.EDIT_TIME_CLOCK, periodReporting.salary.id)) ? <button
                                                                onClick={() => dispatch(openSidebar(TIME_CLOCK_BOOKING_ADD, {
                                                                    beginAt: shift.start,
                                                                    endAt: shift.end,
                                                                    salary: periodReporting.salary,
                                                                    company: shift.company,
                                                                    start: state.rightSidebar.data.start,
                                                                    end: state.rightSidebar.data.end,
                                                                    id: shift.booking?.id,
                                                                    key: shift.id,
                                                                    next: REPORTING_SHOW_WEEK,
                                                                    data: {
                                                                        currentWeek: currentWeek,
                                                                        start: state.rightSidebar.data.start,
                                                                        end: state.rightSidebar.data.end,
                                                                    }
                                                                }))} className={'btn btn-sm btn-outline-danger'}>
                                                                <i className={'bi bi-plus'}> </i> Badger l'absence
                                                            </button> : <i className={'bi bi-x'}> </i>)}
                                                            {shift.accountingType === ACCOUNTING_TYPE_WORK_UNACCOUNTED && <span className={'form-text'}>Temps de travail retiré du vol. heures</span>}
                                                        {shift.accountingType === ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED && <span className={'form-text'}>Absence retiré du vol. heures</span>}
                                                        {shift.accountingType === ACCOUNTING_TYPE_ABSENCE_ACCOUNTED && <span className={'form-text'}>Absence ajouté au vol. heures</span>}
                                                    </div>
                                                case "timeClockBookingAbsent":
                                                    return <>
                                                        <div className={'bg-light'}>
                                                            {shift.absence?.typeName}
                                                            <div className="clearfix"> </div>
                                                            <span className="form-text">
                                                                {format(new Date(shift.absence!.start))}
                                                                <i className={'bi bi-arrow-left-right mx-2'}> </i>
                                                                {format(new Date(shift.absence!.end))}
                                                            </span>
                                                        </div>
                                                        {i + 1 < day.done.length && <hr className={'my-2'}/>}
                                                    </>
                                                case "booking":
                                                case "bookingException":
                                                case "templateBooking":
                                                case "templateBookingException":
                                                case "templateBookingApplied":
                                                    return <>
                                                        <div className={shift.absence ? 'bg-light' : ''}>
                                                                <>
                                                                    {format(new Date(shift.start), 'HH:mm')}
                                                                    <i className={"bi bi-arrow-left-right mx-2"}> </i>
                                                                    {format(new Date(shift.end), 'HH:mm')}
                                                                    <span><i className={'bi bi-stopwatch ms-2'}> </i>{shift.timeCaption}</span>
                                                                </>
                                                                <>
                                                                    {shift.accountingType === ACCOUNTING_TYPE_WORK_UNACCOUNTED && <div className={'form-text w-100'}> <i className={'bi bi-x-circle text-danger'}></i> Temps de travail non comptabilisé</div>}
                                                                    {shift.accountingType === ACCOUNTING_TYPE_ABSENCE_UNACCOUNTED && <div className={'form-text w-100'}> <i className={'bi bi-x-circle text-danger'}></i> Absence non comptabilisé</div>}
                                                                    {shift.accountingType === ACCOUNTING_TYPE_ABSENCE_ACCOUNTED && <div className={'form-text w-100'}> <i className={'bi bi-check-circle text-success'}></i> Absence comptabilisé</div>}
                                                                    {shift.isSlotExchange && <>
                                                                        <div className="clearfix"></div>
                                                                        <div className="form-text">
                                                                            {<i className={shift.isExchange ? 'bi bi-check-circle text-success' : 'bi bi-x-circle text-danger'}></i>} {shift.isExchange ? `Échange avec ${shift.salary?.title}` : `Échangé avec ${shift.slotExchangeSalary?.title}`}
                                                                            <div className="clearfix"> </div>
                                                                            le {format(new Date(shift.slotExchangeDecodedInstance.beginAt), 'dd/MM HH:mm')} <i className={'bi bi-arrow-left-right'}></i> {format(new Date(shift.slotExchangeDecodedInstance.endAt), 'HH:mm')}
                                                                        </div>
                                                                    </>}
                                                                </>
                                                                {shift.absence && <>
                                                                    <div className="clearfix"> </div>
                                                                    <span className={'form-text'}>{shift.absence.typeName}</span>
                                                                </>}
                                                                {shift.labourDayWorked && <>
                                                                    <div className="clearfix"> </div>
                                                                    <span className={'form-text text-success'}> Jour férie travaillé</span>
                                                                </>}
                                                                {shift.description && <>
                                                                    <div className="clearfix"> </div>
                                                                    <span className={'form-text'}> <i className={'bi bi-quote'}></i> {shift.description}</span>
                                                                </>}
                                                        </div>
                                                        {i + 1 < day.done.length && <hr className={'my-2'}/>}
                                                    </>
                                                default:
                                                    return <>
                                                        <div>
                                                            <span>
                                                                Impossible d'afficher le créneau
                                                            </span>
                                                        </div>
                                                        {i + 1 < day.done.length && <hr className={'my-2'}/>}
                                                    </>

                                            }
                                        })}
                                    </td>
                                    {day.delta && <td>
                                        {day.delta[0] === '-' ? <i className={'bi bi-arrow-down-circle text-success'}></i> : <i className={'bi bi-arrow-up-circle text-success'}></i>} {day.delta}
                                        {day.initDelta && day.initDelta !== day.delta && <>
                                            <hr className={'my-1'} />
                                            <div className="form-text">
                                                {day.initDelta}
                                            </div>
                                        </>}
                                    </td>}
                                </tr>)}
                            </tbody>
                        </table>

                        <div className="table-responsive mb-3">
                            <table className={'table table-bordered align-middle text-center mb-3'}>
                                <thead>
                                <tr>
                                    <th>
                                        H. contrat
                                    </th>
                                    <th>
                                        Vol. h.
                                    </th>
                                    <th>
                                        Delta
                                    </th>
                                    <th>
                                        Absences
                                    </th>
                                    <th>
                                        Compteur
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr className={reporting.disabled ? 'bg-light' : ''}>
                                        <td>
                                            {periodReporting.salary.information?.contractWorkingTime}H
                                            {periodReporting.salary.information?.contractWorkingTime !== reporting.contractWorkingTime &&  <>
                                                <hr className={'my-1'}/>
                                                <span className="form-text">
                                                    Répartition : {reporting.contractWorkingTime}H
                                                </span>
                                            </>}

                                        </td>
                                        <td>
                                            {reporting.workingTime}
                                        </td>
                                        <td>
                                            {reporting.delta || <i className={"bi bi-x"}> </i>}
                                        </td>
                                        <td>
                                            {reporting.unaccountedAbsence !== "00:00" ? reporting.unaccountedAbsence : <i className={"bi bi-x"}> </i>}
                                        </td>
                                        <td>
                                            {!reporting.disabled && <>
                                                {reporting.counterSaved ? [<i className={reporting.counterSaved[0] === '-' ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}> </i>, reporting.counterSaved, <i className={'bi bi-check text-success'}> </i>] : <i className={'bi bi-x'}> </i>}
                                                <div className="clearfix"> </div>
                                                {reporting.counterSaved !== reporting.counter && <span>
                                                        <span className={'form-text text-danger'}>à date :</span> {reporting.counter}
                                                    </span>}
                                            </>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {Granted(accessRights.EDIT_COUNTER, periodReporting.salary.id) && <button disabled={savingWeekCounter === reporting.weekNumber || savingCounter} className={'btn btn-outline-success w-100'}
                                                                             onClick={() => {
                                                                                 setSavingWeekCounter(reporting.weekNumber)
                                                                                 saveCounter(new Date(reporting.dates.start), new Date(reporting.dates.end))
                                                                             }}>
                                {savingWeekCounter === reporting.weekNumber ?
                                    <div className="spinner-border spinner-border-sm text-success" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : [<i className={'bi bi-check2-circle text-success'}> </i>, 'Valider le compteur']}
                            </button>}
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    </>
}

export default ReportingShowWeek;