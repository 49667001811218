import * as types from "../../constants/calendar";
import * as models from "../../models/calendar";
import {EventClickArg, EventDropArg} from "@fullcalendar/react";
import {NeedEvents, ResourceLabelContentArg, WorkingTimes} from "../../models/calendar";
import React from "react";
import {Companies} from "../../models/companies";
import {Salary} from "../../models/salary";
import {Teams} from "../../models/team";
import {BookingOffer, BookingOffers} from "../../models/booking/offer";
import {Tasks} from "../../models/task";
import {EventResizeDoneArg} from "@fullcalendar/interaction";

export const fetchEvents = () => {
    return {
        type: types.FETCH_EVENTS,
    }
}

export const fetchEventsSuccess = (events: any[], workingTimes: models.WorkingTimes) => {
    return {
        type: types.FETCH_EVENTS_SUCCESS,
        events: events,
        workingTimes: workingTimes
    }
}

export const fetchAllEventsSuccess = (events: any[], presenceEvents: models.PresenceEvents, needEvents: models.NeedEvents, workingTimes: models.WorkingTimes, tasks: Tasks, bookingOffers: BookingOffers) => {
    return {
        type: types.FETCH_ALL_EVENTS_SUCCESS,
        events: events,
        presenceEvents: presenceEvents,
        needEvents: needEvents,
        workingTimes: workingTimes,
        tasks: tasks,
        bookingOffers: bookingOffers,
    }
}

export const fetchPresenceEventsSuccess = (events: any[]) => {
    return {
        type: types.FETCH_PRESENCE_EVENTS_SUCCESS,
        presenceEvents: events,
    }
}

export const fetchNeedEventsSuccess = (needEvents: NeedEvents) => {
    return {
        type: types.FETCH_NEED_EVENTS_SUCCESS,
        needEvents: needEvents,
    }
}

export const fetchEventTasksSuccess = (tasks: any[]) => {
    return {
        type: types.FETCH_TASKS_SUCCESS,
        tasks: tasks,
    }
}

export const fetchResources = () => {
    return {
        type: types.FETCH_RESOURCES,
    }
}

export const fetchResourcesSuccess = (resources: any[]) => {
    return {
        type: types.FETCH_RESOURCES_SUCCESS,
        resources: resources
    }
}
export const fetchBookingOffersSuccess = (bookingOffers: any[]) => {
    return {
        type: types.FETCH_BOOKING_OFFERS_EVENT_SUCCESS,
        bookingOffers: bookingOffers
    }
}

export const fetchTitleSuccess = (title: string, start: Date, end: Date) => {
    return {
        type: types.FETCH_TITLE_SUCCESS,
        title: title,
        start: start,
        end: end,
    }
}

export const showEventPop = (eventClickArg: EventClickArg, resourceId?: string, parentId?: string) => {
    return {
        type: types.SHOW_EVENT_POP,
        eventClickArg: eventClickArg,
        resourceId: resourceId,
        parentId: parentId
    }
}

export const showEventPopup = (eventClickArg: EventClickArg, resourceId?: string, parentId?: string) => {
    return {
        type: types.SHOW_EVENT_POPUP,
        eventClickArg: eventClickArg,
        resourceId: resourceId,
        parentId: parentId
    }
}

export const hideEventPop = () => {
    return {
        type: types.HIDE_EVENT_POP,
    }
}

export const hideEventPopup = () => {
    return {
        type: types.HIDE_EVENT_POPUP,
    }
}

export const showTemplateEventPop = (eventClickArg: EventClickArg, resourceId?: string, parentId?: string) => {
    return {
        type: types.SHOW_TEMPLATE_EVENT_POP,
        eventClickArg: eventClickArg,
        resourceId: resourceId,
        parentId: parentId
    }
}

export const hideTemplateEventPop = () => {
    return {
        type: types.HIDE_TEMPLATE_EVENT_POP,
    }
}

export const showResourceActions = (resourceLabelContentArg: ResourceLabelContentArg, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return {
        type: types.SHOW_RESOURCE_ACTIONS,
        resourceLabelContentArg: resourceLabelContentArg,
        resourceActionsButton: e
    }
}

export const hideResourceActions = () => {
    return {
        type: types.HIDE_RESOURCE_ACTIONS,
    }
}

export const cancelEvent = (e: EventClickArg) => {
    return {
        type: types.CANCEL_EVENT,
        cancelEvent: e
    }
}
export const cancelBookingOffer = (offer: BookingOffer) => {
    return {
        type: types.CANCEL_BOOKING_OFFER,
        bookingOffer: offer
    }
}
export const addBookingOffer = (offer: BookingOffer) => {
    return {
        type: types.ADD_BOOKING_OFFER,
        bookingOffer: offer
    }
}

export const cancelEvents = (e: EventClickArg) => {
    return {
        type: types.CANCEL_EVENTS,
        cancelEvent: e
    }
}

export const refresh = () => {
    return {
        type: types.REFRESH
    }
}

export const setCompanies = (companies: Companies) => {
    return {
        type: types.SET_COMPANIES,
        companies: companies
    }
}

export const setTeams = (teams: Teams) => {
    return {
        type: types.SET_TEAMS,
        teams: teams
    }
}

export const setJob = (job: number) => {
    return {
        type: types.SET_JOB,
        job: job
    }
}

export const setGroupBy = (groupBy: number) => {
    return {
        type: types.SET_GROUP_BY,
        groupBy: groupBy
    }
}

export const setView = (view: string) => {
    return {
        type: types.SET_VIEW,
        view: view
    }
}

export const setGenerated = (generated: boolean) => {
    return {
        type: types.SET_GENERATED,
        isGenerated: generated
    }
}

export const editEvent = (event: EventResizeDoneArg|EventDropArg) => {
    return {
        type: types.EVENT_EDIT,
        pendingEvent: event
    }
}

export const editEventValidate = () => {
    return {
        type: types.EVENT_EDIT_VALIDATE,
    }
}

export const editEventAbort = () => {
    return {
        type: types.EVENT_EDIT_ABORT,
    }
}

export const refreshResource = (resourceId: string, parentId: string, salary: Salary) => {
    return {
        type: types.REFRESH_RESOURCE,
        resourceId: resourceId,
        parentId: parentId,
        salary: salary,
    }
}

export const refreshResourceSuccess = (events: any[], presenceEvents: any[], workingTimes: WorkingTimes) => {
    return {
        type: types.REFRESH_RESOURCE_SUCCESS,
        events: events,
        presenceEvents: presenceEvents,
        workingTimes: workingTimes,
    }
}
