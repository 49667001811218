import {Salary} from "../salary";
import {Localisation} from "../localisation";
import {Company} from "../companies";
import {Absence} from "../absence";
import {Activity} from "../activity";
import {TimeClock} from "../timeClock";
import {Booking, BookingRecurrence} from "../booking";
import {Counter, CounterWithdrawn} from "../counter";

export interface PeriodReporting {
    salary: Salary;
    workingTime: string;
    sundayWorkingTime: string;
    labourWorkingTime: string;
    unaccountedAbsence: string;
    overTimeLow: string;
    overTimeHigh: string;
    overTime: string;
    delta: string;
    nightTime: {
        low: string;
        high: string;
    };
    monthlyRate: number;
    paidVacation: number;
    sickLeave: number;
    recovery: number;
    technicalUnemployment: number;
    unpaidVacation: number;
    rtt: number;
    specialEvent: number;
    shift: number;
    fullDays: number;
    counter: string;
    counterSaved: string;
    counterTotal: string;
    counterSavedTotal: string;
    counterTotalInitial: string;
    savedCounter: string;
    weeks: WeeksReporting;
}

export type PeriodsReporting = PeriodReporting[];

export interface WeekReporting {
    weekNumber: number;
    disabled: boolean;
    workingTime: string;
    accountedBreak: {
        id: string;
        duration: number;
        time: string;
    }[];
    unaccountedAbsence: string;
    overTime: string;
    delta: string;
    paidVacation: number;
    sickLeave: number;
    recovery: number;
    technicalUnemployment: number;
    unpaidVacation: number;
    rtt: number;
    specialEvent: number;
    nightTime: {
        low: string;
        high: string;
    };
    dates: {
        start: string;
        end: string;
    };
    days: DaysReporting;
    daysGrouped: {
        title: string,
        labourDay: { date: string, title: string },
        planned?: Plans;
        plannedDuration: string;
        done: DaysReporting,
        doneDuration: string,
        delta: string,
        initDelta: string
    }[];
    counter: string;
    counterTotal: string;
    counterSaved?: string;
    counterSavedInitial?: string;
    counterSavedTotal: string;
    counterSavedTotalInitial: string;
    counterWithdrawn: string;
    counterStep?: Counter,
    counterWithdrawnStep?: CounterWithdrawn,
    contractWorkingTime: number,
}

export type WeeksReporting = WeekReporting[];

interface Planned {
    salary: Salary;
    exchangedSalary?: Salary;
    start: string;
    end: string;
    timeCaption: string;
    comment: string;
    localisation?: Localisation;
    activity?: Activity;
    absence?: Absence;
    instanceToken?: string;
    instanceId?: number;
    isException?: boolean;
    isSlotExchange?: boolean;
    exceptionInstanceId?: string;
    bookingInstanceId?: string;
    recurrence?: BookingRecurrence;
    accountingType: 1|2|3|4;
    description?: string;
    labourDay: any,
    labourDayWorked?: boolean,
    company?: Company;
    title?: string;
    exchange?: boolean;
    exchanged?: boolean;
}

type Plans = Planned[]

export interface DayReporting {
    id: string,
    salary: Salary;
    slotExchangeDecodedInstance: {
        id: string;
        beginAt: string;
        endAt: string;
    }
    slotExchangeSalary?: Salary;
    isExchange: boolean;
    start: string;
    end: string;
    eventType: string;
    accountingType: 1|2|3|4;
    timeCaption: string;
    localisation?: Localisation;
    activity?: Activity;
    company?: Company;
    absence?: Absence;
    diff: string;
    comment: string;
    booking?: TimeClock|Booking,
    eventId?: number,
    exceptionId?: number,
    isAbsent?: boolean,
    isSlotExchange?: boolean,
    isMissing?: boolean,
    isLate?: boolean,
    labourDay?: { date: string, title: string };
    description?: string;
    labourDayWorked?: boolean;
    planned?: Planned;
    plans?: Plans;
    breakRemoved?: string;
    __init?: {
        start: string;
        end: string;
        timeCaption: string;
        diff: string
    };
    title?: string;
    exchange?: boolean;
    exchanged?: boolean;
}

export type DaysReporting = DayReporting[];


export interface initialStateInterface {
    payload: PeriodsReporting;
    single: PeriodReporting|null;
    isLoading: boolean;
    error: string|null;
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};