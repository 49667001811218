import React, {useEffect, useState} from "react";
import SignedAddForm from "./form";
import {CalendarApi} from "@fullcalendar/react";
import {setHours, setMinutes} from "date-fns";
import {Salary} from "../../../../models/salary";
import {Company} from "../../../../models/companies";
import {Offcanvas} from "bootstrap";

const SignedAdd:React.FC<{close: () => any, handleSubmitSuccess: (ev: any[]) => any, salary?: Salary, company?: Company, start?: Date, end?: Date}> = (props) => {

    const {handleSubmitSuccess, salary, company, end, start, close} = props;

    const [_start, setStart] = useState(start || new Date());
    const [_end, setEnd] = useState(end || new Date());
    const [key, setKey] = useState<number>(0);

    const handleSubmit = (ev: any) => {
        return Promise.all([
            handleSubmitSuccess(ev)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);

        if (start){
            setStart(start);
        }
        if (end){
            setEnd(end);
        }

    }, [salary, company, start, end])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarSignedAddLabel">Ajouter un badgeage</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <SignedAddForm
                key={key}
                start={_start}
                end={_end}
                initialSalary={salary}
                company={company}
                handleSubmitSuccess={handleSubmit}
            />
        </div>
    </>
}

export default SignedAdd;