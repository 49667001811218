import {post} from "../../xhr";

export function list(requestData: {job: number}){
    return post('booking/default/list', requestData);
}

interface requestPayload {
    job: number,
    beginAt: Date,
    endAt: Date,
    recurrence: {
        type: '1'|'2',
        daysOfWeek?: string[]
    }
}

export function edit(requestData: requestPayload, id: number){
    return post('booking/default/edit/' + id, requestData);
}

export function create(requestData: requestPayload){
    return post('booking/default/create',  requestData);
}

