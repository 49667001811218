import * as React from "react";

import PasswordRequest from "../../../components/auth/passwordRequest";

const PasswordRequestPage : React.FC = () => {
    return (
        <PasswordRequest />
    )
}

export default PasswordRequestPage;