export const ADD_LOCALISATION = 'ADD_LOCALISATION';
export const ADD_LOCALISATION_SUCCESS = 'ADD_LOCALISATION_SUCCESS';
export const ADD_LOCALISATION_FAILURE = 'ADD_LOCALISATION_FAILURE';

export const EDIT_LOCALISATION = 'EDIT_LOCALISATION';
export const EDIT_LOCALISATION_SUCCESS = 'EDIT_LOCALISATION_SUCCESS';
export const EDIT_LOCALISATION_FAILURE = 'EDIT_LOCALISATION_FAILURE';

export const FETCH_LOCALISATIONS = 'FETCH_LOCALISATIONS';
export const FETCH_LOCALISATIONS_SUCCESS = 'FETCH_LOCALISATIONS_SUCCESS';
export const FETCH_LOCALISATIONS_FAILURE = 'FETCH_LOCALISATIONS_FAILURE';

export const SHOW_LOCALISATION = 'SHOW_LOCALISATION';
export const SHOW_LOCALISATION_SUCCESS = 'SHOW_LOCALISATION_SUCCESS';
export const SHOW_LOCALISATION_FAILURE = 'SHOW_LOCALISATION_FAILURE';
