import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../adapters/template";
import * as appliedApi from "../../../adapters/template/applied";
import * as actions from "../../../actions/template";
import {Template} from "../../../models/template";
import Loader from "../../../utils/loader";
import format from "../../../utils/locale";
import * as calendarActions from "../../../actions/calendar";
import {closeSidebar} from "../../../actions/rightSidebar";
import {showAlertDanger} from "../../../actions/alert";
import {axiosError} from "../../../actions/axios";

const TemplateAppliedSidebarList: React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
            dispatch(actions.fetchTemplates());
            api.list().then(data => {
                dispatch(actions.fetchTemplatesSuccess(data.data))
            }).catch(error => dispatch(axiosError(error)))
    }, [])


    const chooseTemplate = (template: Template) => {
        setIsLoading(true)
        // appliedApi.create({
        //     start: state.rightSidebar.data.start,
        //     end: state.rightSidebar.data.end,
        //     template: template.id!
        // }).then(() => {
        //     dispatch(calendarActions.refresh());
        //     dispatch(closeSidebar());
        // }).catch(error => {
        //    setIsLoading(false);
        //    dispatch(showAlertDanger('Une erreur est survenue'))
        // })
    }

    if(state.template.isLoading || isLoading) return <Loader />

    return <>
        <div className="p-3">
            <div className="col-12 py-3">
                {format(state.rightSidebar.data.start)}
                <i className={'bi bi-arrow-left-right mx-3'}> </i>
                {format(state.rightSidebar.data.end)}
            </div>
            <ul className={'list-group list-group-flush'}>
                {state.template.payload.length > 0 && state.template.payload.map((template: Template) => {
                    return <>
                        <li className={'list-group-item'}>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    <span className={'h4 text-primary'}>
                                        {template.title}
                                    </span>
                                    <div className="clearfix"> </div>
                                    <span className="form-text">
                                        {template.company.title}
                                    </span>
                                </div>
                                <div className="col-auto">
                                    <button onClick={() => chooseTemplate(template)} className={'btn btn-outline-primary'}>
                                        <i className={"bi bi-eye"}> </i> Prévisualiser
                                    </button>
                                </div>
                            </div>
                        </li>
                    </>
                })}
            </ul>
        </div>
    </>;
}

export default TemplateAppliedSidebarList;