import React, {useEffect, useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";

interface Interface {
    loading: boolean;
    signed: any[],
    handleClick: (origin: string) => any
}

const DashboardTimeClockLate:React.FC<Interface> = (props) => {

    const {loading, signed, handleClick} = props;
    const state = useSelector((state: RootStateOrAny) => state);

    const [signedLate, setSignedLate] = useState<any[]>([])

    useEffect(() => {
        setSignedLate([...signed.filter((s: any) => s.isLate)])
    }, [signed])

    return <>
        <div className="card p-3 shadow-sm cursor-pointer" onClick={() => handleClick('late')}>
            <div className="d-flex align-items-center">
                <div className="col">
                    <span className={'h4 text-warning'}>
                        <i className={'bi bi-alarm'}> </i>
                        Retards
                    </span>
                    <div className="clearfix"> </div>
                    <span className="form-text">
                        + {state.auth.user.currentSalary.companyGroup.timeClockSettings?.lateAfter} minutes
                    </span>
                </div>
                <div className="col-auto align-middle align-items-center">
                    {loading ? <div className="spinner-border align-middle text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : <span className={'text-warning h2'}>{signedLate.length}</span>}
                </div>
            </div>
        </div>
    </>
}

export default DashboardTimeClockLate;