import {Salaries} from "../salary";
import {TaskGroupCustomFieldValues} from "../taskGroup/customField";
import {TaskGroup} from "../taskGroup";

export interface Task {
    id?: number,
    exDate: string,
    taskGroup: TaskGroup,
    salaries: Salaries,
    title: string,
    description: string|null,
    allDay: boolean,
    beginAt: Date,
    endAt?: Date,
    tasks?: null,
    status?: TaskStatus,
    statuses?: TaskStatuses
    customFieldsValues?: TaskGroupCustomFieldValues,
    recurrence: TaskRecurrence
}

export interface TaskStatus {
    readonly id:number,
    task: Task,
    instanceToken: string,
    val: number
}

export type TaskStatuses = TaskStatus[];

export interface PostTask {
    task: {
        id?: number,
        taskGroup?: number|null,
        salaries: number[],
        title: string,
        description: string|null,
        allDay: boolean,
        beginAt?: Date,
        endAt?: Date,
        tasks?: null,
        recurrence: TaskRecurrence
    },
    customFields:{
        [key: string]: any
    }
}

export type Tasks = Task[];

export interface TaskRecurrence {
    type: number,
    description?: string,
    start?: Date,
    end?: Date,
    daysOfWeek?: number[],
    dayOfMonth?: number,
    monthOfYear?: number,
    separationCount?: number,
}

export interface initialStateInterface {
    payload: Tasks,
    single: Task|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};