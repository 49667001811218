import {Salaries, Salary} from "../../models/salary";
import React, {useEffect, useState} from "react";
import * as salaryApi from "../../adapters/salary";
import {AxiosResponse} from "axios";
import format from "../locale";
import BootstrapSelect, {Choices} from "../bootstrapSelect";
import {PAGINATION} from "../../constants/global";
import {LIST_CHANNEL} from "../../constants/accessRight";

interface SalarySelectorProps {
    onSubmit: any,
    onClose: any,
    initialValues: number[],
    max?: number,
    fetchParams?: {
        isAvailable: boolean,
        companies: number[],
        beginAt: Date,
        endAt: Date,
    }
}

export const SalarySelector:React.FC<SalarySelectorProps> = (props) => {
   const {onSubmit, onClose, initialValues, max, fetchParams} = props;

    const [offset, setOffset] = useState(0)
    const [isLast, setIsLast] = useState(false)
    const [query, setQuery] = useState<string|undefined>()
    const [isLoading, setIsLoading] = useState(true)
    const [isFiltering, setIsFiltering] = useState(false)
    const [error, setError] = useState<string|undefined>()
    const [selectedSalaries, setSelectedSalaries] = useState<number[]>([...initialValues])
    const [salaries, setSalaries] = useState<Salaries>([]);
    const [jobs, setJobs] = useState<number[]>([]);
    const [scrollBottom, setScrollBottom] = useState(false)

    const _list = (requestData: {query: string|undefined, offset: number}):Promise<AxiosResponse<any, any>> => {
        if (fetchParams && fetchParams.isAvailable){
            return salaryApi.available({
                beginAt: format(fetchParams.beginAt, 'uuuu-MM-dd HH:mm:ss'),
                endAt: format(fetchParams.endAt, 'uuuu-MM-dd HH:mm:ss'),
                query: requestData.query,
                options: {
                    companies: fetchParams.companies
                }
            })
        }else{
            return salaryApi.list({
                query: requestData.query,
                offset: requestData.offset,
                accessRight: LIST_CHANNEL
            });
        }
    }

    useEffect(() => {

        let container = document.getElementsByClassName('offcanvas-body')[0]!
        container.addEventListener("scroll", onScrollBottom)

        setIsLoading(true);

        _list({query: query, offset: 0}).then(data => {
            setIsLast(data.data.length < PAGINATION);
            setSalaries(data.data)
            setIsLoading(false);
            setOffset( 1);
        })

        return () => {
            container.removeEventListener("scroll", onScrollBottom)
        }
    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            if (typeof query != "undefined"){
                setIsFiltering(true);
                setOffset(0)
                _list({query: query, offset: 0}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    setSalaries(data.data)
                    setIsFiltering(false);
                    setOffset( 1);
                })
            }
        }, 1000);

        return () => clearTimeout(timer)
    }, [query])

    useEffect(() => {
        if (!isLoading){
            if(offset > 0 && !isLast){
                setIsLoading(true)
                _list({query: query, offset: offset}).then(data => {
                    setIsLast(data.data.length < PAGINATION);
                    setSalaries([...salaries, ...data.data])
                    setIsLoading(false)
                    setOffset(prevState => prevState + 1)
                })
            }
        }
    }, [scrollBottom])

    const onScrollBottom = () => {
        let elem = document.getElementsByClassName('offcanvas-body')[0]!;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom((prevState) => !prevState)
        }
    }

   return (
       <div className={'listSelector col-12'} id={"CompanySelector"}>
           <div className="w-100">
               <div className="d-flex">
                   <div className="flex-grow-1 text-light-s">
                       {fetchParams && <>
                           {format(fetchParams.beginAt, "E dd MMMM uuuu HH:mm")} <i className={'bi bi-arrow-left-right mx-2'}> </i> {format(fetchParams.endAt, "E dd MMMM uuuu HH:mm")}
                       </>}
                   </div>
                   <div className="flex-grow-0">
                       <button type={'button'} className={'btn btn-light'} onClick={onClose}>
                           <i className={'bi bi-chevron-left'}> </i> Retour
                       </button>
                   </div>
               </div>
           </div>
           <div className="w-100 sticky-top bg-white p-2">
               <div className="row">
                   <div className="col">
                       <div className="form-floating">
                           <input type="search" onChange={(e) => {
                               setQuery(e.target.value)
                           }} className="form-control" placeholder="Rechercher un collaborateur" aria-describedby="search-addon" />
                           <label htmlFor="">
                               {isFiltering ? <div className="spinner-grow spinner-grow-sm" role="status">
                                   <span className="visually-hidden">Loading...</span>
                               </div> : <i className="bi bi-search"> </i>} Rechercher un collaborateur
                           </label>
                       </div>
                   </div>
                   <div className="col">
                       <BootstrapSelect
                            fetchEntity={"job"}
                            label={'Métiers'}
                            isMultiple={true}
                            onChangeMultiple={(choices: Choices) =>  setJobs(choices.map(c => Number(c.value)))}
                       />
                   </div>
               </div>
               {error && <div className={'alert alert-danger col-12'}> {error} </div>}
           </div>
           <ul className={'list-group list-group-flush'}>
               {salaries && salaries.filter(s => !jobs.length || jobs.includes(s.information?.job.id!)).map((salary: Salary, index: number) => {
                   return (
                       <li className={'list-group-item w-100'} key={index}>
                           <div className="d-flex">
                               <div className="flex-grow-1 d-flex align-items-center">
                                   <div className="me-2">
                                       <div className="color-circle col-auto">
                                           <div className="shadow-sm" style={{backgroundColor: `${salary.information?.job.color}`, opacity: 0.8}}> </div>
                                       </div>
                                   </div>
                                   <label htmlFor={`salary${index}`} className="flex-grow-1">
                                       {salary.title}
                                       <div className="clearfix"> </div>
                                       <span className={"text-light-s"}>
                                           {salary.information?.job.title}
                                       </span>
                                   </label>
                               </div>
                               <div className="flex-grow-0">
                                   <input className="form-check-input float-end" checked={selectedSalaries.includes(salary.id!)} onChange={(e) => {
                                       if(selectedSalaries.includes(salary.id!)){
                                           selectedSalaries.splice(selectedSalaries.indexOf(salary.id!), 1)
                                           setSelectedSalaries([
                                               ...selectedSalaries
                                           ]);
                                       }else{
                                           if (max && selectedSalaries.length >= max){
                                               setError('Maximum 5 collaborateurs')
                                           }else{
                                               setSelectedSalaries([...selectedSalaries, salary.id!])
                                           }
                                       }
                                   }} type="checkbox" value={salary.id} id={`salary${index}`} />
                               </div>
                           </div>
                       </li>
                   )
               })}
               {isLoading && <li className={'list-group-item text-center'}>
                   <div className="spinner-border text-primary" role="status">
                       <span className="visually-hidden">Loading...</span>
                   </div>
               </li>}
           </ul>
           {!isLast && !isLoading && <button type={'button'} onClick={() => setScrollBottom((prevState) => !prevState)} className={'btn mb-2 btn-outline-primary w-100'}>
               Charger plus de collaborateurs
           </button>}
           <div className="position-sticky w-100 bg-white p-2" style={{
               bottom: 0
           }}>
                   <button type={'button'} className={'btn btn-outline-primary w-100'}  onClick={() => onSubmit(selectedSalaries)}>
                       Valider
                   </button>
           </div>
       </div>
   )
}

interface SalarySelectedProps {
    onDelete: any,
    onOpen: any,
    initialValues: number[],
    max?: number,
    btnClassName?: string,
}

export const SalarySelected:React.FC<SalarySelectedProps> = (props) => {

    const {
        onDelete,
        onOpen,
        initialValues,
        max,
        btnClassName
    } = props;

    const [isLoading, setIsLoading] = useState(!!initialValues);
    const [salaries, setSalaries] = useState<Salaries>([])

    useEffect(() => {
        if (initialValues){
            setIsLoading(true);
            salaryApi.list({salaries: initialValues}).then((data) => {
                setSalaries(data.data)
                setIsLoading(false);
            })
        }
    }, [initialValues])

    return (
        <div className="form-floating">
            <div className="form-control h-auto">
                {initialValues && initialValues.map((id: number, index) => {
                    let salary = salaries.find((salary: Salary) => salary.id === id)
                    return (
                            <span key={index} className={'p-1 me-1 mb-1 btn btn-sm bg-white'}>
                                <span>
                                    {salary && !isLoading ? salary.title :
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>}
                                </span>
                                <span className={'ms-2 btn btn-sm'} onClick={() => onDelete(id)}>
                                    <i className={'bi bi-x'}> </i>
                                </span>
                            </span>
                    )
                })}
                <span>
                    <button type={'button'}  className={'p-1 me-1 mb-1 btn btn-sm ' + (btnClassName||'btn-light')} onClick={onOpen}>
                        {(!initialValues || !initialValues.length) && <span className={'form-text'}>Séléctionner des collaborateurs</span>} <i className={max && initialValues.length >= max ? 'bi bi-pencil' : 'bi bi-plus-circle'}> </i>
                    </button>
                </span>
            </div>
            <label htmlFor="">
               <i className={'bi bi-people text-primary'}> </i>
                <span className="form-text">
                    Collaborateurs
                </span>
            </label>
        </div>
    )
}
