import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../../../adapters/salary/template";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {openSidebar, submitSidebar} from "../../../../../actions/rightSidebar";
import {addSalaryTemplateSuccess} from "../../../../../actions/salary/template";
import DatePicker from "react-datepicker";
import validationClass from "../../../../../utils/validationClass";
import DateCustomInput from "../../../../../utils/customInput";
import FormError from "../../../../../utils/formError";
import {SalaryTemplate} from "../../../../../models/salary/template";

const SalaryTemplateDuplicate:React.FC = () => {

    const dispatch = useDispatch();
    const data = useSelector((state: RootStateOrAny) => state.rightSidebar.data)
    const formik = useFormik({
        initialValues: {
            title: '',
            start: data.lastEnd,
            end: undefined,
            salary: data.salary.id
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            start: Yup.date().required(),
            end: Yup.date().nullable(),
            salary: Yup.number().required(),
        }),
        onSubmit: values => {
            dispatch(submitSidebar());
            api.duplicate(values, data.template.id).then(resp => {
                dispatch(addSalaryTemplateSuccess(resp.data))
                if (data?.next){
                    dispatch(openSidebar(data.next, data.data))
                }
            })
        }
    })



    const overlap = () => {
        return data.templates.filter((t: SalaryTemplate) => {

            if (formik.values.start && !formik.values.end){
                return (new Date(t.start) > (formik.values.start) || (!t.end || new Date(t.end) > (formik.values.start)))
            }

            if (formik.values.start && formik.values.end && t.start && t.end){
                return (new Date(t.start) < formik.values.end! && new Date(t.end) > formik.values.start);
            }

            if (!t.end){
                return !formik.values.end || formik.values.end! > new Date(t.start)
            }

            return false;
        })
    }

    const isOverlap = !!overlap().length

    return <form className={'p-1 p-md-3'} onSubmit={formik.handleSubmit}>
        <div className="form-floating mb-3">
            <input type="text" className={'form-control'} onChange={formik.handleChange} name={'title'} />
            <label htmlFor="">
                Titre du template
            </label>
        </div>
        <div className="col-12">
            <div className="input-group mb-3">
                <DatePicker
                    className={'form-control ' + validationClass(formik.errors, formik.touched, 'start')}
                    title={'Début'}
                    ariaLabelledBy={'bi bi-calendar-check text-primary'}
                    customInput={<DateCustomInput label={'Début de la récurrence'}
                                                  required={true}/>}
                    selected={formik.values.start}
                    onChange={(date) => formik.setFieldValue('start', date)}
                    selectsStart
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    startDate={formik.values.start}
                    endDate={formik.values.end}
                    dateFormat="E dd MMM uuuu"
                    popperPlacement={"top-end"}
                    showPopperArrow={false}
                    disabled={formik.isSubmitting}
                />
                <span className="input-group-text">
                    <i className={'bi bi-arrow-left-right'}> </i>
                </span>
                <DatePicker
                    className={'form-control ' + validationClass(formik.errors, formik.touched, 'end')}
                    title={'Fin'}
                    ariaLabelledBy={'bi bi-calendar-check text-primary'}
                    customInput={<DateCustomInput label={'Fin de la recurrence'}/>}
                    selected={formik.values.end}
                    onChange={(date) => formik.setFieldValue('end', date)}
                    selectsEnd
                    startDate={formik.values.start}
                    endDate={formik.values.end}
                    minDate={formik.values.start}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable={true}
                    dateFormat="E dd MMM uuuu"
                    popperPlacement={"top-end"}
                    showPopperArrow={false}
                    disabled={formik.isSubmitting}
                />
            </div>
            <FormError errors={formik.errors} touched={formik.touched} field={'start'} />
            <FormError errors={formik.errors} touched={formik.touched} field={'end'} />
        </div>
        <div className="col-12">
            {overlap().map((item: SalaryTemplate) => <p className={'form-text text-danger'}>
                <i className={'bi bi-exclamation-circle'}></i> Incompatible avec {item.title}
            </p>)}
        </div>
        <button disabled={isOverlap} type={'submit'} className="btn w-100 btn-outline-primary">
            <i className={"bi bi-check"}></i> Valider
        </button>
    </form>
}

export default SalaryTemplateDuplicate;