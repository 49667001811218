import React, {forwardRef, useEffect, useRef, useState} from "react";
import * as companyApi from "../../adapters/company";
import * as salaryApi from "../../adapters/salary";
import * as jobApi from "../../adapters/job";
import * as taskGroupApi from "../../adapters/taskGroup";
import * as accessLevelApi from "../../adapters/accessLevel";
import * as localisationApi from "../../adapters/localisation";
import * as activityApi from "../../adapters/activity";
import * as teamApi from "../../adapters/team";
import * as timetableApi from "../../adapters/timetable";
import * as templateApi from "../../adapters/salary/template";
import {Companies} from "../../models/companies";
import {Salaries} from "../../models/salary";
import {Jobs} from "../../models/job";
import {Localisation, Localisations} from "../../models/localisation";
import {Activities, Activity} from "../../models/activity";
import {PAGINATION} from "../../constants/global";

interface Choice {
    label: string,
    value: string|number,
    icon?: string
}
export type Choices = Choice[];

interface BootstrapSelectInterface {
    label?: string;
    onTouch?: (touched: boolean) => void;
    placeholder?: string;
    name?: string;
    labelIcon?: string;
    className?:string;
    id?:string;
    required?: boolean;
    disabled?: boolean;
    enableReinitialize?: boolean;
    notFloating?: true,
    displayValue?: boolean,
    onChangeObject?: (val: any|null) => void;
    initialObjectsValues?: any[];
}

type FetchParamsType  = any
// type FetchParamsType  = salaryApi.requestPayload & companyApi.requestPayload & localisationApi.requestPayload

type BootstrapSelectOptions = | {
    options: Choices|undefined;
    fetchEntity?: never;
    fetchParams?: never;
} | {
    options?: never;
    fetchEntity: "salary";
    fetchParams?: FetchParamsType;
} | {
    options?: never;
    fetchEntity: "timetable";
    fetchParams?: FetchParamsType;
} | {
    options?: never;
    fetchEntity: "template";
    fetchParams?: FetchParamsType;
} | {
    options?: never;
    fetchEntity: "company";
    fetchParams?: FetchParamsType;
} | {
    options?: never;
    fetchEntity: "billingCompany";
    fetchParams?: FetchParamsType;
} | {
    options?: never;
    fetchEntity: "localisation"|"activity";
    fetchParams?: FetchParamsType;
} | {
    options?: never;
    fetchEntity: "job"|"accessLevel"|"team"|"taskGroup";
    fetchParams?: undefined
}

type BootstrapSelectProps = BootstrapSelectInterface & BootstrapSelectOptions & (
    | {
        isMultiple?: false;
        values?: never;
        value?: Choice;
        onChange: (val: Choice|any|null) => void;
        onChangeMultiple?: never;
        }
    | {
        isMultiple: true;
        values?: Choices;
        value?: never;
        onChangeMultiple: (val: Choices) => void;
        onChange?: never;
    })

const BootstrapSelect:React.FC<BootstrapSelectProps> = (props) => {

    const {options, displayValue, onTouch, placeholder, value, values, notFloating, id, enableReinitialize, disabled, label, labelIcon, onChange, onChangeObject, onChangeMultiple, className, name, isMultiple, required, fetchEntity, fetchParams, initialObjectsValues} = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [initialOptions, setInitialOptions] = useState([...options || []] );
    const [initialObjects, setInitialObjects] = useState<any>(initialObjectsValues || [] );
    const [filteredOptions, setFilteredOptions] = useState([...options || []] );
    const [query, setQuery] = useState<string>('');
    const [offset, setOffset] = useState(0);
    const [isLast, setIsLast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [scrollBottom, setScrollBottom] = useState<boolean>(false);
    const menuRef = useRef<HTMLUListElement>(null);
    const [_values, setValues] = useState<Choices|undefined>(values);
    const [_value, setValue] = useState<Choice|undefined>(value);
    const [offsetTop, setOffsetTop] = useState<number>(0);
    const [hasFetchedOnce, setHasFetchedOnce] = useState<boolean>(false);

    useEffect(() => {
        if (isMenuOpen){
            if (query){
                setQuery('')
            }

            document.addEventListener('click', handleBodyClick)

            let container = menuRef.current;
            if (container && fetchEntity){
                container.addEventListener('scroll', onScrollBottom);
            }

            return () => {
                document.removeEventListener('click', handleBodyClick)
                if (container && fetchEntity){
                    container.removeEventListener('scroll', onScrollBottom)
                }
            }
        }
    }, [isMenuOpen, menuRef.current])

    useEffect(() => {
        if (isMenuOpen && menuRef.current){
            let dimensions = menuRef.current.getBoundingClientRect();
            if (((dimensions.top + (offsetTop ?  Math.abs(offsetTop) : 15)) + dimensions.height) > window.innerHeight){
                setOffsetTop( 0 - dimensions.height - 15)
            }else{
                setOffsetTop( 0)
            }
        }
    }, [filteredOptions, isMenuOpen])

    useEffect(() => {
        if (fetchEntity && isMenuOpen){
            let timeout = setTimeout(() => {
                if (isMenuOpen && offset){
                    fetch(0, false)
                }
            }, 750);

            return () => clearTimeout(timeout);
        }else{
            setFilteredOptions([...initialOptions.filter((item )  => item.label.toLowerCase().includes(query.toLowerCase()))])
        }
    }, [query])

    useEffect(() => {
        if (fetchEntity && isMenuOpen){
            fetch(0,false)
        }
    }, [fetchEntity, fetchParams, isMenuOpen])

    useEffect(() => {
        if (options){
            setInitialOptions([...options]);

            if (query){
                setFilteredOptions([...options.filter((item )  => item.label.toLowerCase().includes(query.toLowerCase()))])
            }else{
                setFilteredOptions([...options])
            }
        }
    }, [options])

    useEffect(() => {
        let controller = new AbortController();
        if (scrollBottom && !isLast){
            fetch(offset, true, controller)
        }
        return () => controller.abort()
    }, [scrollBottom])

    useEffect(() => {
        if (enableReinitialize && !fetchEntity){
            if (isMultiple){
                setValues(values ? [...values] : undefined)
            }else{
                let validOption = initialOptions.find(option => option.value === _value?.value);

                if (validOption){
                    setValue(validOption)
                }else{
                    setValue(value ? {...value} : undefined)
                }
            }
        }else if(enableReinitialize && fetchEntity && _value && hasFetchedOnce){
            if (!initialOptions.find(choice => choice.value === _value.value)){
                setValue(undefined);
            }
        }
    }, [initialOptions])

    const fetchSuccess = (data: Companies|Salaries|Jobs|Localisations|Activities, _offset: number, merge?: boolean) => {

        let payload: Choices = merge ? [...initialOptions, ...data.map((item: any) => ({label: item.title, value: item.id!}))] : [...data.map((item: any) => ({label: item.title, value: item.id!}))];

        setInitialOptions([...payload])
        setInitialObjects([...data])

        setFilteredOptions([...payload
            .filter((item )  => item.label.toLowerCase().includes(query.toLowerCase()))])

        setIsLast(data.length < PAGINATION)
        setOffset(_offset + 1);
        setIsLoading(false);
        if (scrollBottom) setScrollBottom(false);
    }

    const onScrollBottom = (e: Event) => {
        let elem = e.target as HTMLUListElement;
        if (Math.ceil(elem.clientHeight + elem.scrollTop) >= elem.scrollHeight) {
            setScrollBottom(true)
        }
    }

    const fetch = (_offset: number, scrollBottom: boolean, controller?: AbortController) => {
        setIsLoading(true);
        switch (fetchEntity){
            case 'company':
                companyApi.list({query: query, offset: _offset, ids: fetchParams?.ids, salaries: fetchParams?.salaries}).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'billingCompany':
                companyApi.listBilling().then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'salary':
                salaryApi.list({query: query, offset: _offset, salaries: fetchParams?.salaries, companies: fetchParams?.companies, jobs: fetchParams?.jobs}).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'job':
                jobApi.list({query: query, offset: _offset}).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'accessLevel':
                accessLevelApi.select({query: query, offset: _offset}).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'localisation':
                localisationApi.select({query: query, offset: _offset, companies: fetchParams?.companies}).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'activity':
                activityApi.select({query: query, offset: _offset, companies: fetchParams?.companies}).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'team':
                teamApi.list({query: query, offset: _offset}, controller?.signal).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'taskGroup':
                taskGroupApi.list({query: query, offset: _offset}).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'timetable':
                timetableApi.list(fetchParams.salary).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
            case 'template':
                templateApi.list(fetchParams.salary).then((data) => {
                    fetchSuccess(data.data, _offset, scrollBottom);
                })
                break;
        }
        setHasFetchedOnce(true)
    }

    const handleBodyClick = (evt: MouseEvent) => {

        let popup = menuRef.current

        if (popup && isMenuOpen){
            let dimensions = popup.getBoundingClientRect();

            let [xStart, xEnd] = [dimensions.left, dimensions.left + dimensions.width];
            let [yStart, yEnd] = [dimensions.top, dimensions.top + dimensions.height];

            let clickOutsideX = !(xStart <= evt.clientX && xEnd >= evt.clientX);
            let clickOutsideY = !(yStart <= evt.clientY && yEnd >= evt.clientY);

            if (clickOutsideX || clickOutsideY){
                setIsMenuOpen(false)
            }
        }
    }

    const removeValue = (val: Choice) => {

        if (isMultiple){
            let __values = [..._values!]
            let index = __values.findIndex(v => v.value === val.value)
            if (index !== -1){
                __values.splice(index, 1);
            }
            onChangeMultiple!([...__values])
            setValues([...__values])
        }else{
            onChange!(null)
            setValue(undefined)
        }
    }

    const clickItem = (choice: Choice) => {
        if (isMultiple){
            if (_values){
                if (_values.find(v => v.value === choice.value)){
                    // REMOVE
                    removeValue(choice)
                }else{
                    // ADD
                    setValues([..._values, choice])
                    onChangeMultiple!([..._values, choice])
                }
            }else{
                setValues([choice])
                onChangeMultiple!([choice])
            }
        }else{
            setValue(choice)
            onChange!(choice);
            setIsMenuOpen(false);
            if (onChangeObject){
                onChangeObject(initialObjects.find((obj: any) => obj.id === choice.value))
            }
        }
    }

    return <>
        <div className="col position-relative bootstrap-custom-select" id={id} key={id}>
            <div className={"bootstrap-custom-select " + (notFloating ? '' : ' form-floating ')}>
                {[<>
                    {!isMenuOpen && <div style={notFloating ? {} : {minHeight: 'calc(3.5rem + 2px)', height: "auto"}} className={`button-group w-100 bg-light form-select ${className} ${isLoading && 'disabled'}`} onClick={(e) => {
                        e.stopPropagation()
                        if (!disabled) {
                            setIsMenuOpen(true)
                            if (onTouch){
                                onTouch(true)
                            }
                        }
                    }}>
                        {
                            isMultiple ? (_values?.length ? _values.map(choice =>
                                    <span onClick={(e) => {e.stopPropagation()}} className={'me-2'}>{choice.label}
                                        <span onClick={() => removeValue(choice)} className={'bg-light'}>
                                    <i className={'text-danger bi bi-x'}> </i>
                                </span>
                            </span>) :
                                (placeholder !== undefined ? placeholder : 'Séléctionnez des options')) :
                                (_value && displayValue !== false ? <span onClick={(e) => {e.stopPropagation()}} className={'me-2'}>
                                    {_value.label}
                                    {!required && <span onClick={() => removeValue(_value)} className={'bg-light'}>
                                        <i className={'text-danger bi bi-x'}> </i>
                                    </span>}
                                </span> : (placeholder !== undefined ? placeholder : 'Séléctionnez une option'))
                        }
                    </div>}
                    {isMenuOpen && <input
                        disabled={isLoading}
                        autoFocus={true}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (isMultiple && isMenuOpen && !!_values?.length){
                                setIsMenuOpen(false);
                            }
                        }}
                        onChange={(e) => setQuery(e.currentTarget.value)}
                        defaultValue={query}
                        type="text"
                        placeholder={'Recherchez une option'}
                        className={`form-select ${className} search-input ${isLoading && 'is-loading'}`}
                    />}
                </>, <>
                    <label className={notFloating && !label ? 'd-none' : ''} htmlFor="">
                        {!!labelIcon && <i className={labelIcon}> </i>} {!!label && label} {required && <span className={"required"}>*</span>}
                        {isMenuOpen && filteredOptions.length > 0 && isLoading && <div className="spinner-grow spinner-grow-sm ms-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </label>
                </>].sort((e, i) => {
                    return notFloating ? 1 : 0;
                })}
            </div>
            <ul ref={menuRef} style={{zIndex: 1000, top: (offsetTop ? offsetTop : 'auto')}} id={'bootstrap_select_list_group'} className={"bootstrap-custom-select-list-group overflow-auto bg-white mt-2 w-100 px-2 shadow position-absolute list-group-flush overflow-auto " + (isMenuOpen ? 'd-block' : 'd-none')} >
                {filteredOptions.length ? filteredOptions.map((option, index) => <li key={index} onClick={() => clickItem(option)} className={'list-group-item px-1 list-group-item-action'}>
                    {(_values?.find(v => v.value === option.value) || _value === option) && displayValue !== false &&<i className={'bi bi-check'}> </i>}
                    {option.label}
                </li>) : (<li className={'list-group-item px-1 disabled'}>{isLoading ? "Chargement des options" : "Aucune option disponible"}</li>)}
                {filteredOptions.length > 0 && isLoading && <li className={'list-group-item px-1 disabled'}>
                    <div className="d-flex align-items-center text-primary">
                        <strong>Chargement ...</strong>
                        <div className="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"> </div>
                    </div>
                </li>}
            </ul>
        </div>
    </>
}

export default BootstrapSelect;