import { FunctionComponent, ReactNode } from "react"

export interface ITabItem {
  label: string;
  icon?: ReactNode;
}

interface IProps {
  colsNumber: "2"|"3"|"4"|"5"|"6"|"7"|"8"|"9"|"10"|"11"|"12";
  tabItems: ITabItem[];
  selectedIndex: number;
  onClick: (tabIndex: number) => void;
}

const TabMenu: FunctionComponent<IProps> = ({ colsNumber, tabItems, selectedIndex, onClick }) => {
  const gridColsVariants = {
    "2": "col-2",
    "3": "col-3",
    "4": "col-4",
    "5": "col-5",
    "6": "col-6",
    "7": "col-7",
    "8": "col-8",
    "9": "col-9",
    "10": "col-10",
    "11": "col-11",
    "12": "col-12"
  }

  return (
    <div className={`d-flex`}>
      {tabItems.map((tabItem, key) => (
        <div
          key={key}
          className={`${gridColsVariants[colsNumber]} px-1 py-1 d-flex justify-content-center align-items-center space-x-1 cursor-pointer ${key === selectedIndex ? "border-bottom border-primary" : "border-bottom"}`}
          onClick={() => onClick(key)}
        >
          {tabItem?.icon}
          <p className={`text-center ${key === selectedIndex ? "text-primary" : "text-muted"}`}>
            {tabItem.label}
          </p>
        </div>
      ))}
    </div>
  )
}

export default TabMenu