import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as api from "../../../adapters/counter";
import FormError from "../../../utils/formError";
import {closeSidebar, openSidebar, submitSidebar} from "../../../actions/rightSidebar";
import {showAlertSuccess} from "../../../actions/alert";
import {editCounterSuccess} from "../../../actions/counter";
import {axiosError} from "../../../actions/axios";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {Counter} from "../../../models/counter";

export const CounterEdit:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state);
    const data = state.rightSidebar.data;
    const [hour, setHour] = useState<number>(Math.floor(Math.abs(data.value) / 3600));
    const [min, setMin] = useState<number>(Math.floor((Math.abs(data.value) - (hour * 3600)) / 60));
    const [markUpHour, setMarkUpHour] = useState("")
    const [markUpMin, setMarkUpMin] = useState("")

    const [initialValues, setInitialValues] = useState<any>({
        value: Math.abs(data.value),
        signe: data.value < 0 ? 'minus' : 'plus'
    });

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        value: Yup.number().min(0).required('Champs requis'),
        signe: Yup.string().required('Champs requis'),
    }));

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values => {
            dispatch(submitSidebar());
            api.edit(values, data.id).then(_data => {
                dispatch(editCounterSuccess(_data.data))
                dispatch(showAlertSuccess('Compteur mis à jour !'));

                if (data.next){
                    dispatch(openSidebar(data.next, {..._data.data.counters.find((c: Counter) => c.id === data.id), salary: _data.data.salary}))
                    return true;
                }

                dispatch(closeSidebar());
            }).catch(error => dispatch(axiosError(error)))
        })
    });

    useEffect(() => {
        let time = formik.values.value;

        if (time / 3600 > 8){
            let markUp_low = 8 * 3600 * 1.25;
            let markUp_high = (time - (8*3600)) * 1.5;
            time = markUp_low + markUp_high;
        }else{
            time *= 1.25;
        }

        let _hour = String(Math.floor(time / 3600)).length < 2 ? "0"+String(Math.floor(time / 3600)) : String(Math.floor(time / 3600));
        let _min = String(Math.floor((time - (Math.floor(time / 3600) * 3600)) / 60)).length < 2 ? "0"+String(Math.floor((time - (Math.floor(time / 3600) * 3600)) / 60)) : String(Math.floor((time - (Math.floor(time / 3600) * 3600)) / 60));

        setMarkUpHour(_hour);
        setMarkUpMin(_min);

    }, [formik.values.value])

    useEffect(() => {
        formik.setFieldValue('value', (hour || 0) * 3600 + (min || 0) * 60)
    }, [min, hour])

    return <div className={'p-3'}>
        <form className={'row'} onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="form-check">
                        <input defaultChecked={formik.values.signe === 'plus'} name={'signe'} className="form-check-input" type="radio" value="plus" onChange={formik.handleChange} id="signe_plus" />
                        <label className="form-check-label" htmlFor="signe_plus">
                            <i className={'bi bi-plus text-success'}> </i> Positif
                        </label>
                    </div>
                    <div className="form-check">
                        <input defaultChecked={formik.values.signe === 'minus'} name={'signe'} className="form-check-input" type="radio" value="minus" onChange={formik.handleChange} id="signe_minus" />
                        <label className="form-check-label" htmlFor="signe_minus">
                            <i className={'bi bi-dash text-danger'}> </i> Négatif
                        </label>
                    </div>
                    <FormError errors={formik.errors} touched={formik.touched} field={'signe'} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6">
                    <BootstrapSelect
                        label={"Heures"}
                        value={{label: String(hour || 0), value: (hour || 0)}}
                        options={Array.from(Array(99).keys()).map((n) => ({label: String(n), value: n}))}
                        onChange={(c) => setHour(Number(c?.value))}
                    />
                </div>
                <div className="col-6">
                    <BootstrapSelect
                        label={"Minutes"}
                        value={{label: String(min || 0), value: (min || 0)}}
                        options={Array.from(Array(59).keys()).map((n) => ({label: String(n), value: n}))}
                        onChange={(c) => setMin(Number(c?.value))}
                    />
                </div>
                {formik.values.signe === 'plus' && <div className="col-12">
                    <div className="form-text">
                        Valeur majorée : {markUpHour}:{markUpMin}
                    </div>
                </div>}
            </div>
            {/*<div className="row">*/}
            {/*    <div className="col-6 mb-3">*/}
            {/*        <div className="form-form">*/}
            {/*            <input onBlur={formik.handleBlur} name={'valueHour'} defaultValue={data.valueHour} onChange={formik.handleChange} className="form-control" type={'number'} />*/}
            {/*            <label htmlFor="">Delta heures</label>*/}
            {/*        </div>*/}
            {/*        <div className="form-text">Delta avec majoration : {markUpHour} </div>*/}
            {/*        <FormError errors={formik.errors} touched={formik.touched} field={'valueHour'} />*/}
            {/*    </div>*/}
            {/*    <div className="col-6 mb-3">*/}
            {/*        <div className="form-form">*/}
            {/*            <input onBlur={formik.handleBlur} name={'valueMin'} defaultValue={data.valueMin} max={59} onChange={formik.handleChange} className="form-control" type={'number'} />*/}
            {/*            <label htmlFor="">Delta minutes</label>*/}
            {/*        </div>*/}
            {/*        <div className="form-text">Delta avec majoration : {markUpMin} </div>*/}
            {/*        <FormError errors={formik.errors} touched={formik.touched} field={'valueMin'} />*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="row">*/}
            {/*    <div className="col-6 mb-3">*/}
            {/*        <div className="form-form">*/}
            {/*            <input onBlur={formik.handleBlur} defaultValue={data.paidOverTimeHour} name={'paidOverTimeHour'} onChange={formik.handleChange} className="form-control" type={'number'} />*/}
            {/*            <label htmlFor="">Heures payées</label>*/}
            {/*        </div>*/}
            {/*        <FormError errors={formik.errors} touched={formik.touched} field={'paidOverTimeHour'} />*/}
            {/*    </div>*/}
            {/*    <div className="col-6 mb-3">*/}
            {/*        <div className="form-form">*/}
            {/*            <input onBlur={formik.handleBlur} defaultValue={data.paidOverTimeMin} name={'paidOverTimeMin'} onChange={formik.handleChange} className="form-control" type={'number'} />*/}
            {/*            <label htmlFor="">Minutes payées</label>*/}
            {/*        </div>*/}
            {/*        <FormError errors={formik.errors} touched={formik.touched} field={'paidOverTimeMin'} />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <button type={'submit'} className={'btn btn-outline-primary'}>
                <i className={'bi bi-check'}> </i> Valider
            </button>
        </form>
    </div>
}

export default CounterEdit;