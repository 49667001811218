import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {openSidebar} from "../../../actions/rightSidebar";
import {EDIT_PASSWORD_FORM, FORM_NEW_USER_INFORMATION} from "../../../constants/rightSidebar";
import {setPageTitle} from "../../../actions/header";
import {CompanyGroup} from "../../../models/companyGroup";
import {selectCompanyGroup} from "../../../actions/companyGroup";
import * as actions from "../../../actions/auth";
import {Salary} from "../../../models/salary";
import * as api from "../../../adapters/auth";

const ProfileShow:React.FC = () => {

    const state = useSelector((state: RootStateOrAny) => state.auth)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle('Mon compte'))
    }, [])

    return (
        <div className={"container my-3 mx-auto d-flex flex-column"}>
            <div className="d-flex flex-column flex-grow-1">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="card p-3 shadow-sm">
                            <table className={'table table-borderless'}>
                                <tbody>
                                <tr>
                                    <td className={'text-primary h4'}>
                                        Nom d'utilisateur
                                    </td>
                                    <td>
                                        {state.user.email}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary h4'}>
                                        Mot de passe
                                    </td>
                                    <td>
                                        <button onClick={() => dispatch(openSidebar(EDIT_PASSWORD_FORM))} className={'btn btn-sm btn-outline-primary'}>
                                            <i className={'bi bi-pencil'}> </i> Modifier le mot de passe
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary h4'}>
                                        Prénom
                                    </td>
                                    <td>
                                        {state.user.information?.firstname}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary h4'}>
                                        Nom
                                    </td>
                                    <td>
                                        {state.user.information?.lastname}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'text-primary h4'}>
                                        Tél
                                    </td>
                                    <td>
                                        {state.user.information?.tel}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card shadow-sm p-3">
                            <h4>
                                Entreprise
                            </h4>
                            <div className="w-100 py-3 text-center">
                                <h2>
                                    {state.user.currentSalary.companyGroup.title}
                                </h2>
                                <button
                                    onClick={() => {
                                        localStorage.removeItem('defaultCompanies')
                                        dispatch(actions.unselectAccount())
                                    }}
                                >
                                    Quitter
                                </button>
                            </div>
                            {state.user.salaries.length > 1 && <>
                                <div className="row-cols-1 row-cols-md-2 row-cols-md-3">
                                    {state.user.salaries.map((salary: Salary) => <div className={"bg-light p-3"}>
                                        <h4 className={"mb-3 text-center"}>
                                            {salary.companyGroup?.title}
                                        </h4>
                                        <button onClick={() => {
                                            api.selectAccount(salary.id).then((data) => {
                                                dispatch(actions.selectAccount(data.data))
                                            })
                                        }} className={'btn btn-outline-primary w-100'}>
                                            <i className={'bi bi-check'}> </i> Sélectionner
                                        </button>
                                    </div>)}
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileShow;