import {Company} from "../companies";
import {Salaries} from "../salary";
import {Job, Jobs} from "../job";

export interface Localisation {
    id?: number,
    title: string,
    description: string,
    needType?: 1|2,
    needJobs?: Jobs,
    color?: string,
    icon?: string,
    company: Company,
    needs?: LocalisationNeeds,
    salaries?: Salaries,
    position?: number,
    activated?: boolean,
}

export interface LocalisationNeed {
    id?: number,
    day: number,
    time: string,
    val: number,
    job?: Job
}

export type Localisations = Localisation[];

export type LocalisationNeeds = LocalisationNeed[];

export interface initialStateInterface {
    payload: Localisations,
    single: Localisation|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};