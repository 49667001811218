import React from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Field, FieldProps, Form, Formik} from "formik";
import * as actions from "../../../actions/taskGroup";
import * as api from "../../../adapters/taskGroup";
import {showAlertSuccess} from "../../../actions/alert";
import {closeSidebar, submitSidebar} from "../../../actions/rightSidebar";
import * as Yup from "yup";
import ColorPicker from "../../../utils/colorPicker";

const TaskGroupSchema = Yup.object().shape({
    title: Yup.string().required('Champ requis'),
    description: Yup.string().nullable(),
    color: Yup.string().required(),
});

const TaskGroupAdd:React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state.taskGroup)

    if (state.error){
        return (
            <div className='w-100 text-center alert alert-danger'>
                {state.error}
            </div>
        )
    }

    return (
        <div className='w-100'>
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    color: '',
                }}
                validationSchema={TaskGroupSchema}
                onSubmit={values => {
                    // same shape as initial values
                    dispatch(submitSidebar());
                    api.create(values).then(data => {
                        dispatch(actions.addTaskGroupSuccess(data.data))
                        dispatch(showAlertSuccess(data.data.title + ' à bien été ajouter !'))
                        dispatch(closeSidebar());
                    }).catch(error => {
                        dispatch(actions.addTaskGroupFailure(error.data.response.message))
                    })
                }}
            >
                {({ errors, touched, values }) => (
                    <Form className="p-3">
                        <div className="form-floating mb-3">
                            <Field className='form-control' name="title"/>
                            <label className={(errors.title && touched.title ? 'text-danger' : '')} htmlFor="title">
                                Nom du groupe <span className={'required'}>*</span>
                            </label>
                            {errors.title && touched.title ? (<div className='text-danger form-text' >{errors.title}</div>) : null}
                        </div>
                        <div className="form-floating mb-3">
                            <Field className='form-control mb-2' name="description"/>
                            <label className={(errors.description && touched.description ? 'text-danger' : '')} htmlFor="description">
                                Description
                            </label>
                            {errors.description && touched.description ? (<div className='text-danger form-text' >{errors.description}</div>) : null}
                        </div>
                        <div className="col-12 mb-3">
                            <Field>
                                {({
                                      field, // { name, value, onChange, onBlur }
                                      form: {touched, errors, setFieldValue}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                      meta,
                                  }: FieldProps) =>
                                    <div className={'text-center w-100'}>
                                        <ColorPicker
                                            onChange={(color: string) => setFieldValue('color', color)}
                                            selected={values.color}
                                        />
                                        {errors.color && touched.color ? (<div className='text-danger form-text' >{errors.color}</div>) : null}
                                    </div>
                                }
                            </Field>
                        </div>
                        <button className='btn btn-light w-100' type="submit">
                            <i className={'bi bi-plus-circle text-success'}></i> Ajouter
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default TaskGroupAdd;