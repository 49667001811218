import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import {Timetable, TimetableEvents} from "../../../../models/timetable";
import TimetableEventEditForm from "./form";
import {Salary} from "../../../../models/salary";

const TimetableEventEdit:React.FC<{arg?: EventClickArg, salary: Salary, timetable: Timetable, week: number, handleSubmitSuccess: (ev: any) => any, events: TimetableEvents}> = (props) => {
    const {arg, timetable , salary, week, events, handleSubmitSuccess} = props
    
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey(prev => prev + 1)
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarEventDropLabel">Modifier un créneau</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {arg && <TimetableEventEditForm
                key={key}
                eventId={arg.event.extendedProps.event.id}
                start={arg.event.start!}
                end={arg.event.end!}
                daysOfWeek={arg.event.extendedProps.event.daysOfWeek}
                week={week}
                company={arg.event.extendedProps.event.company}
                activity={arg.event.extendedProps.event.activity}
                localisation={arg.event.extendedProps.event.localisation}
                handleSubmitSuccess={handleSubmitSuccess}
                events={events}
                salary={salary}
                timetable={timetable}
            />}
        </div>
    </>
}

export default TimetableEventEdit;