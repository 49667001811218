import * as types from "../../constants/reporting"
import * as models from "../../models/reporting"

const reporting = (state: models.initialStateInterface = models.initialState, action:
    {
        type: string,
        payload: models.PeriodsReporting,
        single: models.PeriodReporting,
        error: string
    }
) => {

    switch (action.type) {

        case types.FETCH_REPORTING:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_REPORTING_SUCCESS:
            return { payload: action.payload, single: action.single, isLoading: false, error: null };
        case types.FETCH_REPORTING_FAILURE:
            return { payload: [], single: null, isLoading: false, error: action.error };

        case types.SHOW_REPORTING:
            return { payload: state.payload, single: null, isLoading: false, error: null };
        case types.SHOW_REPORTING_SUCCESS:
            return { payload: state.payload, single: action.single, isLoading: false, error: null };
        case types.SHOW_REPORTING_FAILURE:
            return { payload: state.payload, single: null, isLoading: false, error: action.error };

        case types.REFRESH_SINGLE:

            let index = state.payload.findIndex(periodReporting => periodReporting.salary.id === action.single.salary.id)

            if (index !== -1){
                state.payload[index] = action.single;
            }

            return { payload: state.payload, single: action.single, isLoading: false, error: null};

        default:
            return state;
    }
}

export default reporting;