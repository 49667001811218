export interface AccessLevel {
    id: number,
    title: string,
    global?: boolean,
    rights: string[],
    accessRights: AccessRights
}

export interface AccessRight {
    id?: number,
    underscoreCase: string,
}

export type AccessLevels = AccessLevel[];
export type AccessRights = AccessRight[];

export interface initialStateInterface {
    payload: AccessLevels,
    single: AccessLevel|null,
    isLoading: boolean,
    error: string|null
}

export const initialState: initialStateInterface = {
    payload: [],
    single: null,
    isLoading: false,
    error: null
};