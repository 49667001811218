import {post, get} from "../../xhr";

interface PostMessage {
    body: string,
    attachments?: string[]|null,
}

export function list(channelId: string, offset?: number){
    return post('channel/'+ channelId +'/message/list', {offset: offset});
}

export function create(requestData: PostMessage, channelId: string){
    return post('channel/'+ channelId +'/message/create',  requestData);
}

export function remove(channelId: string, messageId: number){
    return get('channel/'+ channelId +'/message/remove/' + messageId);
}