import * as types from "../../constants/keepNote"
import * as models from "../../models/keepNote"
import replace from "../../utils/arrayReplace"

const keepNote = (state: models.initialStateInterface = models.initialState, action: {
    type: string,
    keepNote: models.KeepNote,
    keepNotes: models.KeepNotes,
    error: string}) => {

    switch (action.type) {
        case types.FETCH_KEEP_NOTES:
            return { payload: [], single: state.single, isLoading: true, error: null };
        case types.FETCH_KEEP_NOTES_SUCCESS:
            return { payload: action.keepNotes, single: state.single, isLoading: false, error: null };
        case types.FETCH_KEEP_NOTES_FAILURE:
            return { payload: action.keepNotes, single: state.single, isLoading: false, error: action.error };

        case types.ADD_KEEP_NOTE:
            return { payload: state.payload, isLoading: true, error: null };
        case types.ADD_KEEP_NOTE_SUCCESS:
            return { payload: [...state.payload, action.keepNote], single: state.single, isLoading: false, error: null };
        case types.ADD_KEEP_NOTE_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };

        case types.REMOVE_KEEP_NOTE:
            return { payload: state.payload, single: state.single, isLoading: true, error: null };
        case types.REMOVE_KEEP_NOTE_SUCCESS:
            return { payload: [...state.payload.filter(item => item.id !== action.keepNote.id)], single: state.single, isLoading: false, error: null };
        case types.REMOVE_KEEP_NOTE_FAILURE:
            return { payload: state.payload, single: state.single, isLoading: false, error: action.error };

        case types.EDIT_KEEP_NOTE:
            return { payload: state.payload , isLoading: true, error: null };
        case types.EDIT_KEEP_NOTE_SUCCESS:
            return { payload: replace(state.payload, action.keepNote), single: action.keepNote, isLoading: false, error: null };
        case types.SHOW_KEEP_NOTE:
            return { payload: state.payload, isLoading: true, error: null };
        case types.SHOW_KEEP_NOTE_SUCCESS:
            return { payload: state.payload, single: action.keepNote, isLoading: false, error: null };
        case types.SHOW_KEEP_NOTE_FAILURE:
            return { payload: state.payload, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default keepNote;