export const toHms = (s: number, displaySigne?: boolean) => {

        let signe = displaySigne ? (s < 0 ? "-" : '+') : '';
        s = s < 0 ? Math.abs(s) : s;

        let hours   = Math.floor(s / 3600);
        let minutes = Math.floor((s - (hours * 3600)) / 60);

        let h = (hours   < 10) ? "0"+hours : hours.toString();
        let m = (minutes   < 10) ? "0"+minutes : minutes.toString();

        return signe+h+':'+m;
}