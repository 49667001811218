export const ADD_JOB = 'ADD_JOB';
export const ADD_JOB_SUCCESS = 'ADD_JOB_SUCCESS';
export const ADD_JOB_FAILURE = 'ADD_JOB_FAILURE';

export const EDIT_JOB = 'EDIT_JOB';
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';
export const EDIT_JOB_FAILURE = 'EDIT_JOB_FAILURE';

export const FETCH_JOBS = 'FETCH_JOBS';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';

export const SHOW_JOB = 'SHOW_JOB';
export const SHOW_JOB_SUCCESS = 'SHOW_JOB_SUCCESS';
export const SHOW_JOB_FAILURE = 'SHOW_JOB_FAILURE';
