import React, {useEffect, useState} from "react";
import AbsenceEditForm from "../../../absence/edit/form";
import {EventClickArg} from "@fullcalendar/react";

const AbsenceEdit:React.FC<{close: () => any, arg: EventClickArg, handleSubmitSuccess: (abs: any) => any}> = (props) => {

    const {arg, handleSubmitSuccess, close} = props;

    const [key, setKey] = useState<number>(0);

    const handleSubmit = (abs: any) => {
        return Promise.all([
            handleSubmitSuccess(abs)
        ])
            .then(() => close())
            .then(() => true)
    }

    useEffect(() => {
        setKey(prevState => prevState + 1);
    }, [arg])

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarAbsenceLabel">Modifier une absence</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <AbsenceEditForm key={key} handleSubmitSuccess={handleSubmit} absence={arg.event.extendedProps.absence} />
        </div>
    </>
}

export default AbsenceEdit;