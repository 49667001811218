import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import * as api from "../../../../../adapters/template/resource";
import * as salaryApi from "../../../../../adapters/salary";
import {useFormik} from "formik";
import SmallLoader from "../../../../../utils/loader/small";
import BootstrapSelect from "../../../../../utils/bootstrapSelect";
import {useDispatch} from "react-redux";
import {showAlertSuccess} from "../../../../../actions/alert";
import {Template} from "../../../../../models/template";
import {Job} from "../../../../../models/job";
import {Salaries, Salary} from "../../../../../models/salary";
import FormError from "../../../../../utils/formError";

interface Interface {
    template: Template,
    handleSubmitSuccess: (ev: any[]) => any,
    resource: {id: number}
}

interface InterfaceFloating {
    floating: true,
    job: Job,
    title: string,
    contractWorkingTime: number,
}
interface InterfaceSalary {
    floating: false,
    salary: Salary,
}



const TemplateResourceEditForm:React.FC<Interface & (InterfaceFloating | InterfaceSalary)> = (props) => {

    const dispatch = useDispatch();
    const [salaries, setSalaries] = useState<Salaries>([])

    const [initialValues, setInitialValues] = useState<any>({
        floating: props.floating,
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        floating: Yup.boolean().required('Champs requis'),
    }))

    useEffect(() => {
        if (props.floating){
            setInitialValues({
                floating: true,
                job: props.job.id,
                title: props.title,
                contractWorkingTime: props.contractWorkingTime
            })

            setValidationSchema(Yup.object().shape({
                floating: Yup.boolean().required('Champs requis'),
                job: Yup.number().required('Champs requis'),
                title: Yup.string().required('Champs requis'),
                contractWorkingTime: Yup.number().nullable(),
            }))
        }else{
            setInitialValues({
                floating: false,
                salary: props.salary.id,
            })

            setValidationSchema(Yup.object().shape({
                floating: Yup.boolean().required('Champs requis'),
                salary: Yup.number().required('Champs requis'),
            }))
        }
    }, [props.floating])

    function handleFloatingChange(floating: boolean)
    {
        if (floating){
            setInitialValues({
                floating: true,
                job: null,
                title: "",
                contractWorkingTime: null
            })

            setValidationSchema(Yup.object().shape({
                floating: Yup.boolean().required('Champs requis'),
                job: Yup.number().required('Champs requis'),
                title: Yup.string().required('Champs requis'),
                contractWorkingTime: Yup.number().nullable(),
            }))
        }else{
            setInitialValues({
                floating: false,
                salary: null,
            })

            setValidationSchema(Yup.object().shape({
                floating: Yup.boolean().required('Champs requis'),
                salary: Yup.number().required('Champs requis'),
            }))
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.edit(values, props.template.id, props.resource.id)
                .then(resp => {
                    Promise.all([
                        props.handleSubmitSuccess(resp.data)
                    ]).then(() => {
                        dispatch(showAlertSuccess('Resource mis à jour'));
                        formikHelpers.setSubmitting(false)
                    })
                })
        }
    })

    useEffect(() => {
        if (!formik.values.floating){
            salaryApi.list({companies: [props.template.company.id!]})
                .then(resp => setSalaries(resp.data))
        }
    }, [formik.values.floating])

    return <form onSubmit={formik.handleSubmit}>

        <div className="form-check form-switch mb-3">
            <input defaultChecked={props.floating} onChange={e => handleFloatingChange(e.target.checked)} className="form-check-input" type="checkbox" id="floating" />
                <label className="form-check-label" htmlFor="floating">
                    Ressource flottante
                </label>
            <FormError errors={formik.errors} touched={formik.touched} field={'floating'} />
        </div>

        {formik.values.floating && <>
            <div className="form-floating mb-3">
                <input type="text" defaultValue={props.floating ? props.title : ''} className="form-control" name={"title"} id={"title"} onChange={formik.handleChange} />
                <label htmlFor="title">
                    Libellé
                </label>
                <FormError errors={formik.errors} touched={formik.touched} field={'title'} />
            </div>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    fetchEntity={"job"}
                    label={"Métier"}
                    value={props.floating ? {label: props.job.title, value: props.job.id!} : undefined}
                    onChange={c => formik.setFieldValue('job', c?.value)}
                    required={true}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'job'} />
            </div>
            <div className="form-floating mb-3">
                <input type="number"  defaultValue={props.floating ? props.contractWorkingTime : ''} className="form-control" step={"any"} name={"contractWorkingTime"} id={"contractWorkingTime"} onChange={formik.handleChange} />
                <label htmlFor="contractWorkingTime">
                    Heures contrat
                </label>
                <FormError errors={formik.errors} touched={formik.touched} field={'contractWorkingTime'} />
            </div>
        </>}

        {!formik.values.floating && <>
            <div className="col-12 mb-3">
                <BootstrapSelect
                    label={'Collaborateur'}
                    onChange={c => formik.setFieldValue("salary", c?.value)}
                    required={true}
                    options={salaries.filter(s => !(props.template.resources.find(r => r.salary?.id === s.id))).map(s => ({label: s.title, value: s.id}))}
                    value={props.floating ? undefined : {label: props.salary.title, value: props.salary.id}}
                    enableReinitialize={true}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'salary'} />
            </div>
        </>}

        <button className="w-100 btn btn-light" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <SmallLoader /> : <><i className={"bi bi-check text-success"}></i></>} Valider
        </button>
    </form>
}

export default TemplateResourceEditForm;