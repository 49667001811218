import * as React from "react";

import CalendarShow from "../../../components/calendar/index";

const CalendarPage : React.FC = () => {
    return (
        <div id={'calendarPage'} className={'page position-relative d-flex flex-column h-100'}>
            <CalendarShow />
        </div>
    );
}

export default CalendarPage;