import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import rootReducers from "./reducers";
import {logger, crashReporter, alertHider} from "./middleware/logger";
import {HashRouter as Router} from 'react-router-dom';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";
import {errorHandler} from "./middleware/errorHandler";
import {eventEditAbort} from "./middleware/eventEditAbort";

registerLocale('fr', fr)
setDefaultLocale('fr')

export const store = createStore(rootReducers,  applyMiddleware(crashReporter, alertHider, errorHandler, eventEditAbort));

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
              <App />
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
